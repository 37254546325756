.report-consult-lists-pdf{
  &__body-pdf{
      margin-bottom: 100px;
  }
  &__block-alert-head-pdf{
      height: 400px;
      padding: 45px 0 20px;
      text-align: center;
      font-family: MTTMilano-Bold, sans-serif;
      color: $primary-color;
      margin-bottom: 50px;
  }

  &__name-alert-head-pdf{
      font-size: 30px;
      line-height: 40px;
      margin: 0;
      text-transform: capitalize;
  }

  &__documentnumber-alert-head-pdf{
      font-size: 20px;
      line-height: 30px;
      margin: 0;
  }

  &__icon-alert-head-pdf{
      font-size: 100px;
      margin-top: 65px;
      display: block;
  }

  &__message-alert-head-pdf{
      font-size: 25px;
      line-height: 35px;
      margin: 0px 0 35px 0;
  }

  &__more-info-alert-head-pdf{
      margin: 0;
  }

  &__block-alert-body-pdf{
      width: 100%;
      padding: 0 100px;
  }

  &__body-person-array-pdf{
      padding: 0 0 20px 10px;
      border-left: 1px solid $box-comment-border-03;
  }

  &__title-person-array-pdf{
      height: 30px;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      padding: 7px 9px;
      margin-bottom: 30px;
      page-break-inside: avoid;
      page-break-after: auto;
      page-break-before: auto;
      color: $snow-white;
      background: $primary-color;
  }

  &__datainfo-array-pdf{
      margin-left: 10px;
  }

  &__datainfo-col-pdf{
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      line-height: 18px;
      min-height: 34px;
      margin-bottom: 6px;
      display: flex;
      color: $pillow-group;
      page-break-inside: auto;//avoid
      page-break-after: auto;
      page-break-before: auto;
      overflow-wrap: break-word;
      &:last-child{
          margin-bottom: 0;
      }
  }

  &__datainfo-type-pdf{
      width: 369px;
      min-width: 369px;
      padding-top: 9px;
  }

  &__datainfo-text-pdf{
      min-height: 34px;
      padding: 9px 11px 7px;
      width: 100%;
      background: $background-grey;
      color: $secondary-color;
      page-break-inside: auto;//avoid
      page-break-after: auto;
      page-break-before: auto;
      &--list{
          margin-bottom: 2px;
          &:last-child{
              margin-bottom: 0;
          }
      }
  }
  &__repeat-text-pdf{
      width: 100%;
  }
}

.compliance{
  &__dark-blue{
    .report-consult-lists-pdf{
      &__block-alert-head-pdf{
          &--red{
              background: $background-error;
          }
          &--green{
              background: rgba(119, 209, 102, 0.2);
          }
          &--yellow{
              background: rgba(241, 188, 62, 0.2);
          }
      }
      &__icon-alert-head-pdf{
          &--red{
              color: $red;
          }
          &--green{
              color: $green;
          }
          &--yellow{
              color: $yellow;
          }
      }
    }
  }
  &__steel-blue{
    .report-consult-lists-pdf{
      &__block-alert-head-pdf{
          color: $primary-color-2;
          &--red{
              background: rgba(226, 71, 81, 0.2);
          }
          &--green{
              background: rgba(0, 218, 101, 0.2);
          }
          &--yellow{
              background: $warning-background-2;
          }
      }
      &__icon-alert-head-pdf{
          &--red{
              color: $alert-color-2;
          }
          &--green{
              color: $green-color-v2;
          }
          &--yellow{
              color: $warning-yellow;
          }
      }
      &__body-person-array-pdf{
          border-color: $third-color-2;
      }
      &__title-person-array-pdf{
          background: $primary-color-2;
      }
      &__datainfo-col-pdf{
          color: $grey-icons-2;
      }
      &__datainfo-text-pdf{
          background: $background-grey-disabled-2;
          color: $text-field-color-2;
      }
    }
  }
}
