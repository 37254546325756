@import '../../../../assets/scss/colors';
.new-document{
  &.modal .modal-content {
      border-radius: 0px;
      height: 503px;
  }

  &__block-form{
      width: 100%;
      height: 416px;
      position: relative;
  }
  // &__icon-header {
  //     background-color: $snow-white;
  //     font-size: 21px;
  //     -webkit-filter: invert(1) hue-rotate(180deg) contrast(2);
  //     background: transparent;
  // }
  &__radio-button-block {
      width: 40%;
      margin: 0 auto 10px;

      display: block;
      padding-top: 75px;
  }
  &__switch-top {
      margin-top: 10px;
      margin-left: 300px;
  }
  &__check-template{
      margin-left: 29%;
  }
  &__name-document{
      width: 40%;
      margin: 0 auto 10px;
  }
  &__input-form {
      margin-left: 270px;
      width: 360px;
  }
  &__icon-close-modal {
      color: $snow-white;
      float: right;
      font-size: 20px;
      top: 4px;
      position: relative;
      &--custom{
          font-size: 25px;
          top: 0;
      }

      &:hover {
          cursor: pointer;
      }

  }
  &__error-name{
      color: $red;
      position: absolute;
      font-size: 12px;
      &--relative{
          position: relative;
      }

  }
}
.compliance{
  &__steel-blue{
    .new-document{
      &__icon-close-modal {
        color: $white-special-2;
      }
      &__error-name{
        color:$alert-color-2;
        font-family: MTTMilano, sans-serif;
      }
    }
  }

}
