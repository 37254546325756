.clients-tracking-dashboard{
  position: relative;
  float: left;
  width: 100%;
  &__block-panel-query {
      display: flex;
      align-items: center;
      width: 100%;
      height: 75px;
      border: 4px solid $result-search-quote-color;
      margin-bottom: 20px;
      &:hover {
          background-color: $background-color-states;
          cursor: pointer;
      }
      &--disabled{
          opacity: 0.5;
          &:hover {
              background-color: transparent;
              cursor: default;
          }
      }
  }

  &__icon-arrow-right {
      color: $result-search-quote-color;
      font-size: 40px;
  }

  &__block-text-query {
      display: flex;
      flex-direction: column;
      font-family: MTTMilano, sans-serif;
      font-weight: bold;
      padding-left: 24px;
  }

  &__query-text-title {
      color: $primary-color;
      font-size: 20px;
  }

  &__query-text-subtitle {
      color: $pillow-group;
      font-size: 10px;
  }

  &__block-panel {
      width: 100%;
      height: 675px;
      border: 4px solid $result-search-quote-color;
      padding: 17px 20px 0;
  }

  &__block-header-panel{
      display: inline-block;
      width: 100%;
  }

  &__icon-header{
      position: relative;
      float: left;
      font-size: 30px;
      color: $pillow-group;
  }

  &__title-header-panel{
      position: relative;
      float: left;
      top: 5px;
      margin: 0;
      color: $primary-color;
      font-family: MTTMilano-Bold , sans-serif;
      font-size: 20px;
      max-width: calc(100% - 64px);
      padding-left: 13px;
  }

  &__button-open-window{
      position: relative;
      float: right;
      background-color: transparent;
      border: none;
      outline: none;
      color: $primary-color;
      font-size: 30px;
      padding: 0;
  }

  &__block-body-panel{
      padding-left: 44px;
      position: relative;
      float: left;
      width: 100%;
      line-height: 15px;
  }

  &__line-p{
      margin-bottom: 4px;
  }

  &__fixed-text{
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 11px;
      text-transform: uppercase;
      margin-right: 3px;
  }

  &__final-result{
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 13px;
      height: 22px;
      display: inline-block;
      padding: 4px 10px;
      border-radius: 15px;
      background-color: $green-opacity-36;
  }

  &__definition-text{
      color: $pillow-group;
      font-family: MTTMilano, sans-serif;
      font-size: 13px;
  }

  &__evaluated-clients{
      color: $concept-blue;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 11px;
      text-transform: uppercase;
      margin: 0;
  }

  &__evaluated-clients-count{
      font-size: 22px;
  }

  &__bubble-count{
      display: inline-block;
      position: relative;
      font-style: normal;
      font-family: Arial, sans-serif;
      font-weight: bold;
      text-align: center;
      font-size: 11px;
      width: 33px;
      height: 18px;
      border-radius: 15px;
      margin: 6px 4px 10px 0;
      line-height: 18px;
      color: $primary-color;
      background-color: $subcontainer-next;
  }

  &__block-clients-repeat{
      margin-bottom: 9px;
      border-left: 1px solid $background-grey-other;
      padding: 4px 0 2px 9px;
      position: relative;
      left: -9px;
      min-height: 50px;
  }

  &__subtitle-clients{
      color: $pillow-group;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      margin: 0 0 4px;
  }
}

.compliance{
// &__dark-blue{
//   .clients-tracking-dashboard{
//   }
// }
&__steel-blue{
  .clients-tracking-dashboard{
    &__block-panel-query {
        border-color: $background-highlight-text-2;
        &:hover {
            background-color: $third-color-rgba-mid;
        }
    }
    &__icon-arrow-right {
        color: $result-search-quote-color;
        font-size: 40px;
    }
    &__query-text-title {
        color: $primary-color-2;
    }
    &__query-text-subtitle {
        color: $grey-icons-2;
    }
    &__block-panel {
        border-color: $background-highlight-text-2;
        height: 580px;
    }
    &__icon-header{
        color: $primary-color-2;
    }
    &__title-header-panel{
        color: $primary-color-2;
    }
    &__button-open-window{
        color: $border-hover-2;
    }
    &__fixed-text{
        color: $primary-color-2;
    }
    &__final-result{
        color: $primary-color-2;
    }
    &__definition-text{
        color: $grey-icons-2;
    }
    &__evaluated-clients{
        color: $grey-icons-2;
    }
    &__bubble-count{
        color: $primary-color-2;
    }
    &__block-clients-repeat{
        border-color: $background-grey-disabled-2;
    }
    &__subtitle-clients{
        color: $grey-icons-2;
    }
  }
}
}
