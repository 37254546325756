@mixin donut-graph-tooltip-donut($color, $borderColor, $boxShadowColor){
  color: $color;
  border-color: $borderColor;
  background-color: $snow-white;
  // box-shadow: 0 0 5px 1px $boxShadowColor;
  box-shadow: $boxShadowColor;
  border-bottom-color: $color;
  &:after{
      content: " ";
      position: absolute;
      border-top: 4px solid $color;
      border-right: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 4px solid transparent;
      height: 0;
      width: 0;
      bottom: -13px;
      left: calc(50% - 2px);
  }
}
.donut-graph{
  position: relative;
  float: left;
  width: 100%;
  min-height: 100px;
  &--center{
      text-align: center;
      padding-left: inherit !important;
  }
  &--poll{
      padding-left: 0 !important;
  }
  &__value-title{
      font-family: MTTMilano-Bold, sans-serif;
      text-transform: uppercase;
  }
  &__value-text{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 40px;
      display: block;
      line-height: 36px;
  }
  &__arc-value{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 15px;
  }
  &__legend-covid{
      color: $donut-text;
      font-family: MTTMilano, sans-serif;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 30px;
  }
  &__block-list-legend{
      position: relative;
      float: left;
      width: 100%;
      margin-top: 20px;
      display: block;
  }
  &__list-legend{
      list-style-type: square;
      font-size: 25px;
      position: relative;
      width: 450px;
      margin: 0 auto;
  }
  &__element-list-legend{
      position: relative;
      float: left;
      width: 50%;
      line-height: 16px;
  }
  &__text-element-legend{
      color: $donut-text;
      font-size: 15px;
      position: relative;
      bottom: 2px;
      left: -8px;
  }
  &__tooltip-donut {
      position: absolute;
      text-align: center;
      padding: 6px 12px;
      pointer-events: none;
      border: 1px solid ;
      border-bottom: 5px solid;
      font-family: Arial, sans-serif;
      font-size: 11px;
      min-height: 29px;
      max-width: 170px;
      &--no-show{
        visibility: hidden;
      }
  }

  &__arc-steel-blue{
      &:hover{
         cursor: pointer;
      }
  }

  &__legend{
      position: relative;
      float: left;
      margin-top: 20px;
      display: none;
      height: 344px;
      width: 383px;
      border: 1px solid;
      background-color: $snow-white;
      padding: 0 23px;
      &:before{
          content: '';
          border-left: 11px solid transparent;
          border-right: 11px solid $snow-white;
          border-top: 11px solid transparent;
          border-bottom: 11px solid transparent;
          height: 1px;
          width: 1px;
          position: absolute;
          left: -22px;
          top: 161px;
      }
      &--show{
          display: block;
      }

      &--report-state{
        height: 260px;
        margin-top: 67px;
        &:before{
          top: 118px;
        }
      }
     
  }

  &__legend-inner-triangle{
    &::before{
      content: '';
      border-left: 10px solid transparent;
      border-right: 10px solid $snow-white;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      height: 1px;
      width: 1px;
      position: absolute;
      left: -20px;
      top: 162px;
    }
    &--report-state{
      &::before{
      
        top: 119px;
      }
    }
  }

  &__container-steel-blue{
      width: 990px;
      margin: 0 auto;
      overflow: hidden;
  }

  &__dashboard-donut-header{
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      margin-bottom: 0;
      border-bottom: 1px solid;
      margin-top: 35px;
  }

  &__dashboard-donut-header-text{
      margin-bottom: 4px;
      text-align: left;
      width: fit-content;
      display: inline-block;
  }

  &__dashboard-donut-body{
      height: 225px;
      border-bottom: 1px solid;
      overflow: auto;
      margin: 0;
      padding: 10px 0 10px;
      list-style: none;
      &--no-border{
          border-bottom-color: transparent;
      }
      &--stacked-graph{
        border: none;
        height: 97px;
      }
      &--report-state{
        border-bottom: 0px;
        height: auto;
      }
  }

  &__dashboard-donut-row{
      width: 98%;
      margin-right: 2%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
  }

  &__row-left{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }

  &__row-left-dot{
    font-size: 10px;
    position: relative;
    top: -2px;
    padding-right: 6px;
  }

  &__row-left-text{
      margin: 0;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      padding-bottom: 4px;
  }

  &__row-right{
    font-family: MTTMilano-Bold, sans-serif;
    font-size: 12px;
    display: flex;
    gap: 14px;
    width: 30%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
  }

  &__row-right-text{
    margin: 0;
    text-align: right;
    display: flex;
    min-width: 40px;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
  }

  &__info-donut{
      position: absolute;
      margin: 13px 0;
      left: calc(50% - 80px);
      top: calc(50% - 43px);
      width: 160px;
      text-align: center;
      font-size: 14px;
  }
}

.compliance{
  &__dark-blue{
    .donut-graph{
      width: 100%;
      padding-left: 0%;
      &--be{
        padding: 0;
      }
      &__info-donut{
        color: $donut-text;
      }
      &__row-right{
        color: $concept-blue;
      }
      &__row-left-text{
        color: $attach-icon-grey;
      }
      &__dashboard-donut-body{
        border-bottom-color:  $primary-color;
      }
      &__dashboard-donut-header{
        border-bottom-color: $primary-color;
        color: $primary-color;
      }
      &__legend{
        border-color: $snow-white;
        &--show-report-graph, &--show-report-graph-stacked{
          border-color: #CCD1E4;
          display: block;
          &::before{
            border-right-color: #CCD1E4;
          }
          &:after{
            content: ''
          }
        }
        &--show-report-graph-stacked{
          height: 155px;
          margin-top: 128px;
          &::before{
            top: 69px;
          }
          & .donut-graph__legend-inner-triangle{
            &::before{
              top: 70px;
            }
          }
        }
      }
      &__tooltip-donut{
        @include donut-graph-tooltip-donut($primary-color, $donut-text-2, $box-shadow);
        color: $no-primary-blue;
      }
      &__arc-value{
        fill: $arc-value-color;
      }
    }
  }
  &__steel-blue{
    .donut-graph{
      width: 357px;
      margin-top: 11px;
      padding-left: 0;
      margin-right: 90px;
      &__info-donut{
        color: $white-special-2;
      }
      &__row-right{
        color: $border-hover-2;
      }
      &__row-left-text{
        color: $primary-color-2;
      }
      &__dashboard-donut-body{
        border-bottom-color:  $third-color-2;
      }
      &__dashboard-donut-header{
        border-bottom-color: $third-color-2;
        color: $border-hover-2;
      }
      &__legend{
        border-color: $third-color-2;
      }
      &__tooltip-donut{
        @include donut-graph-tooltip-donut($primary-color-2, $border-shadow-2, $donut-shadow-2);
      }
      &__arc-value{
        fill: $white-special-2;
        font-size: 20px;
      }
    }
  }
  &__icam-red{
    .donut-graph{
      &__tooltip-donut{
        @include donut-graph-tooltip-donut($custom-black-landing, $custom-black-landing, $box-shadow-icam);
      }
    }
  }
  &__gm-law{
    .donut-graph{
      &__info-donut{
        color: $border-block-gm;
      }
      &__tooltip-donut{
        @include donut-graph-tooltip-donut($custom-black-landing, $custom-black-landing, $box-shadow-gm);
      }
      &__arc-value{
        fill: $snow-white;
      }
    }
  }
  &__com-de{
    .donut-graph{
      &__info-donut{
        color: $border-block-com-de;
      }
      &__tooltip-donut{
        @include donut-graph-tooltip-donut($custom-black-landing, $custom-black-landing, $box-shadow-com-de);
      }
      &__arc-value{
        fill: $snow-white;
      }
    }
  }
  &__com-fr{
    .donut-graph{
      &__info-donut{
        color: $border-block-com-fr;
      }
      &__tooltip-donut{
        @include donut-graph-tooltip-donut($custom-black-landing, $custom-black-landing, $box-shadow-com-fr);
      }
      &__arc-value{
        fill: $snow-white;
      }
    }
  }
}

@media (max-width: 1800px){
  .donut-graph{
      &__arc-value{
          font-size: 12px;
      }
      &__info-donut{
          &--graph{
              font-size: 9px;
          }
      }
      &__value-text{
          &--graph{
              font-size: 35px;
          }
      }
  }
}


@media (max-width: 1366px){
  .compliance{
    &__dark-blue{
      .donut-graph{
        padding-left: 0%;
      }
    }
    &__steel-blue{
      .donut-graph{
        padding-left: 2%;
        margin-right: 50px;
      }
    }
  }
}

@media (max-width: 1171px){
  .compliance{
    &__dark-blue{
      .donut-graph{
        padding-left: 0%;
      }
    }
    &__steel-blue{
      .donut-graph{
        margin-right: 40px;
        padding-left: 2%;
      }
    }
  }
}
