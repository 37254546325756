.heat-map-graph{
    width: 100%;
    position: absolute;
  &__axis-title{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
  }
  &__risk-box{
      stroke: $snow-white;
      stroke-width: 2px;
      opacity: 0.2;
      &:hover{
          cursor: pointer;
      }
  }
  &__risk-value{
      fill: $snow-white;
      font-family: MTTMilano, sans-serif;
      font-weight: bold;
      font-size: 16px;
  }
  &__tooltip-box{
      background-color: $snow-white;
      border: 1px solid $subcontainer-border;
      position: absolute;
      width: 280px;
      min-height: 280px;
      z-index: 10;
      box-shadow: 0px 0px 10px 2px $bar-horizontal-grey-opacity;
      visibility: hidden;
  }

  &__tooltip-block{
      margin: 0 15px 0 15px;
      display: block;
      position: relative;
  }
  &__tooltip-triangle{
      position: relative;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $snow-white;
      border-left: 10px solid transparent;
      height: 0;
      width: 0;
      z-index: 12;
      left: 113px;
      top: -20px;
  }
  &__tooltip-graph-close{
      position: absolute;
      top: 25px;
      right: 5px;
      &:hover{
          cursor: pointer;
      }
  }
  &__tooltip-header-block{
      display: block;
      position: relative;
      // margin-top: 2px;
      // margin-bottom: 6px;
      margin: 2px 5px 6px 0px;
      &--unique{
          margin-bottom: 0;
      }
  }
  &__tooltip-graph-title{
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      margin-top: 15px;
      font-size: 18px;
      border-bottom: 1px solid $primary-color;
      padding-bottom: 3px;
      margin-bottom: 0;
      &--unique{
          margin-top: 10px;
      }
  }
  &__heatpoint-block{
      display: inline-block;
      margin-right: 8px;
  }
  &__heatpoint-dot{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      top: 1px;
  }
  &__heatpoint-text{
      display: inline-block;
      color: $bar-horizontal-task-grey;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      font-weight: bold;
      margin: 0;
      &--unique{
          margin-right: 9px;
      }
  }
  &__risk-list{
      margin: 25px 0 5px 0;
      padding: 0;
      max-height: 232px;
      overflow-y: auto;
      padding-right: 5px;
      padding-left: 10px;
      &--unique{
          margin: 0;
          padding-left: 0;
      }
  }
  &__risk-item{
      min-height: 50px;
      border-top: 1px solid $box-comment-border-02;
      list-style: none;
      display: block;
      width: 100%;
      &:last-child{
          border-bottom: 1px solid $box-comment-border-02;
      }
      &--unique{
          display: table;
          border-top: 0px;
          color: $primary-color;
          font-family: "MTTMilano", sans-serif;
          font-size: 14px;
          padding-top: 4px;
          border-bottom: 1px solid $box-comment-border-02;
      }
  }
  &__risk-item-block{
      display: table;
      height: 48px;
      width: 100%;
  }
  &__risk-item-icon{
      display: table-cell;
      vertical-align: middle;
      color: $primary-color;
      font-size: 22px;
      width: 15%;
  }
  &__risk-item-text{
      display: table-cell;
      margin: 0;
      vertical-align: middle;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      width: 85%;
      cursor: pointer;
  }
  &__icon{
      font-size: 10px;
      float: left;
      margin-right: 10px;
      margin-top: 7px;
  }
  
  &__y-axis-title{
    text-orientation: unset;
    writing-mode: vertical-lr;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    transform: rotate(180deg);
    font-family: MTTMilano-Bold, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
  }
  &__x-axis-title{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 800px;
    height: 30px;
    font-family: MTTMilano-Bold, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
  }
}
.compliance{
  &__dark-blue{
      .heat-map-graph{
          &__axis-tick-text{
              color: $primary-color;
              font-family: MTTMilano, sans-serif;
              font-size: 13px;
          }
          &__y-axis-title
          , &__x-axis-title{
              color: $primary-color;
          }

      }
  }
  &__steel-blue{
      .heat-map-graph{
          &__axis-tick-text{
              color: $white-special-2;
              font-family: Arial, sans-serif;
              font-size: 10px;
           }
           &__y-axis-title, 
           &__x-axis-title{
              opacity: 0.5;
              color: $white-special-2;
          }
      }
  }
  &__gm-law{
      .heat-map-graph{
          &__axis-x > .domain{
              stroke: $primary-color-gm;
          }
          &__axis-tick-text{
              color: $custom-black-landing;
              font-family: Arial, sans-serif;
              font-size: 10px;
          }
          &__y-axis-title, 
          &__x-axis-title{
              color: $custom-black-landing;
          }
          &__tooltip-graph-title{
              color: $custom-black-landing;
              border-bottom: 1px solid $third-color-gm;
          }
          &__heatpoint-text{
              color: $secondary-color-gm;
          }
          &__risk-item{
              border-top: 1px solid $border-block-gm;
              &:last-child{
                  border-bottom: 1px solid $border-block-gm;
              }
          }
          &__risk-item-icon{
              color: $custom-black-landing;
          }
          &__risk-item-text{
              color: $custom-black-landing;
              font-size: 14px;
          }
      }
  }
  &__com-de{
    .heat-map-graph{
        &__axis-x > .domain{
            stroke: $primary-color-com-de;
        }
        &__axis-tick-text{
            color: $custom-black-landing;
            font-family: Arial, sans-serif;
            font-size: 10px;
        }
        &__y-axis-title, 
        &__x-axis-title{
            color: $custom-black-landing;
        }
        &__tooltip-graph-title{
            color: $custom-black-landing;
            border-bottom: 1px solid $third-color-com-de;
        }
        &__heatpoint-text{
            color: $secondary-color-com-de;
        }
        &__risk-item{
            border-top: 1px solid $border-block-com-de;
            &:last-child{
                border-bottom: 1px solid $border-block-com-de;
            }
        }
        &__risk-item-icon{
            color: $custom-black-landing;
        }
        &__risk-item-text{
            color: $custom-black-landing;
            font-size: 14px;
        }
    }
}
&__com-fr{
  .heat-map-graph{
      &__axis-x > .domain{
          stroke: $primary-color-com-fr;
      }
      &__axis-tick-text{
          color: $custom-black-landing;
          font-family: Arial, sans-serif;
          font-size: 10px;
      }
      &__y-axis-title, 
      &__x-axis-title{
          color: $custom-black-landing;
      }
      &__tooltip-graph-title{
          color: $custom-black-landing;
          border-bottom: 1px solid $third-color-com-fr;
      }
      &__heatpoint-text{
          color: $secondary-color-com-fr;
      }
      &__risk-item{
          border-top: 1px solid $border-block-com-fr;
          &:last-child{
              border-bottom: 1px solid $border-block-com-fr;
          }
      }
      &__risk-item-icon{
          color: $custom-black-landing;
      }
      &__risk-item-text{
          color: $custom-black-landing;
          font-size: 14px;
      }
  }
}
}
