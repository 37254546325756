.templates-concept{
    height: 600px;
    position: relative;
    float: left;
    padding: 5px 10px;

    &__block-header{
        display: block;
        width: 100%;
        height: 45px;
        padding-top: 0px;
        padding-right: 0px;
        text-align: right;
        margin-top: 30px;
        margin-bottom: 30px;
        position: relative;
    }

    &__table{
        display: table-cell;
        vertical-align: middle;
        max-width: 0;
        width: 11%;
        height: 30px;
        text-align: left;
        &--format{
            width: 6%;
        }

        &--format-icon{
            width: 6%;
            padding-left: 9px;
        }

        &--title{
            width: 20%;
        }

        &--user-modification{
            width: 16%;
        }

        &--firm{
            width: 9%;
        }

        &--action{
            width: 5%;
            text-align: right;
        }
    }

    &__block-list-template{
        position: relative;
        max-height: 440px;
        min-height: 440px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__table-icon {
        color: $primary-color;
        font-size: 20px;
        position: relative;
        top: 3px;
    }

    &__list-menu-block {
        width: 207px;
        border: 1px solid $primary-color;
        background-color: $snow-white;
        position: absolute;
        inset: auto !important;
        transform: none !important;
        top: 5px !important;
        left: -156px !important;
        padding: 0;
        z-index: 2;
        border-radius: 0px;
        float: none;
    }
  }

  .compliance{
    &__dark-blue{
        .templates-concept{
            &__list-menu-block {
                border: 1px solid $primary-color;
            }
        }
    }
    &__steel-blue,
    &__uk-steel-blue{
        .templates-concept{
            padding: 5px 20px;
            &__table-icon {
                color: $border-hover-2;
            }
            &__list-menu-block {
                border: 1px solid $third-color-2;
                border-top-width: 2px;
            }
        }
    }
    &__icam-red{
        .templates-concept{
            padding: 5px 20px;
        }
    }
    &__gm-law{
        .templates-concept{
            padding: 5px 20px;
            &__table-icon {
                color: $primary-color-gm;
            }
            &__list-menu-block {
                border: 1px solid $primary-color-gm;
            }
        }
    }
    &__com-de{
      .templates-concept{
          padding: 5px 20px;
          &__table-icon {
              color: $primary-color-com-de;
          }
          &__list-menu-block {
              border: 1px solid $primary-color-com-de;
          }
      }
    }
    &__com-fr{
      .templates-concept{
          padding: 5px 20px;
          &__table-icon {
              color: $primary-color-com-fr;
          }
          &__list-menu-block {
              border: 1px solid $primary-color-com-fr;
          }
      }
    }
  }
