.customize-field-list{
  &__list-values-block{
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;
      // & > .dndPlaceholder{
      //     list-style: none;
      //     height: 32px;
      // }
  }
  &__add-list-value{
      position: relative;
      float: left;
      width: 100%;
      list-style-type: none;
      &--list{
          padding-bottom: 12px;
      }
  }
  &__list-value-input-block{
      position: relative;
      float: left;
      width: calc(100% - 142px);
  }
  &__default-value-block{
      position: relative;
      float: left;
      width: 142px;
  }
  &__list-value-text{
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      padding: 0 4px;
      display: inline-block;
      &--right{
          float: none;
      }
  }
  &__block-inputs{
      position: relative;
      float: left;
      width: calc(100% - 45px);
      height: 32px;
      margin-right: 5px;
      &--hightlight{
          width: calc(100% - 80px);
      }
  }
  &__list-value-input{
      border: 1px solid $attach-icon-grey;
      width: 100%;
      height: 32px;
      position: relative;
      float: left;
      &:hover{
          border: 1px solid $primary-color;
          & > .customize-field-list__option-menu{
              display: block;
          }
          & .customize-field-list__input-icon-arrow{
              position: relative;
              right: 40px;
          }
      }
      &--weight{
          width: calc(100% - 85px);
          margin-right: 5px;
      }
  }
  &__list-weight-input{
      border: 1px solid $attach-icon-grey;
      width: 80px;
      height: 32px;
      position: relative;
      float: left;
      &:hover{
          border: 1px solid $primary-color;
          & > .customize-field-list__option-menu{
              display: block;
          }
      }
  }
  &__list-input-disabled{
      & > .customize-field-list__option-menu{
          display: none !important;
      }
      background-color: $background-grey !important;
      border-color: $attach-icon-grey !important;
      color: $secondary-color !important;
      &:hover{
          cursor: not-allowed;
      }
  }
  &__input-value{
      border: none;
      height: 100%;
      padding-left: 10px;
      color: $secondary-color;
      background-color: transparent;
      font-family: Arial, sans-serif;
      font-size: 14px;
      outline:0px;
      width: calc(100% - 50px);
      // &:disabled{
      //     // background: $snow-white;
      //     &:hover{
      //         cursor: not-allowed;
      //     }
      // }
  }
  &__input-weight{
      border: none;
      height: 100%;
      padding-left: 10px;
      color: $secondary-color;
      background-color: transparent;
      font-family: Arial, sans-serif;
      font-size: 14px;
      outline:0px;
      width: calc(100% - 16px);
  }
  &__list-value-icons{
      position: absolute;
      top: 0px;
      right: 0;
      &--disabled{
          display: none;
      }
  }
  &__input-icon{
      font-size: 18px;
      color: $primary-color;
      margin: 0 1px;
      &:hover{
          cursor: pointer;
      }

  }
  &__input-icon-arrow{
      font-size: 20px;
      color: $primary-color;
      &:hover{
          cursor: pointer;
      }

  }
  &__input-icon-menu{
      font-size: 26px;
      color: $primary-color;
      position: relative;
      top: 3px;
      &:hover{
          cursor: pointer;
      }
  }
  &__block-highlight-button{
      position: relative;
      float: left;
  }
  &__color-button{
      position: relative;
      float: left;
      width: 32px;
      height: 32px;
      margin-right: 5px;
      outline: none;
      color: $snow-white;
      background-color: $green;
      border: 0px;
  }
  &__circle-selected-button{
      font-size: 18px;
      left: 7px;
      top: 7px;
      position: absolute;
  }
  &__option-menu{
      z-index: 10;
      background: $grey;
      top: 0;
      width: auto;
      height: 100%;
      background-color: $background-grey-other;
      border-radius: 15px 0 0 15px;
      padding: 6px 4px 0 20px;
      position: absolute;
      right: 0;
      overflow: hidden;
      display: none;
  }
  &__color-block{
      position: absolute;
      padding: 7px 0px;
      inset: auto !important;
      left: -214px !important;
      top: 0px !important;
      transform: none !important;
      width: 210px;
      height: 65px;
      border: 1px solid $concept-from-grey;
      background-color: $snow-white;
      box-shadow: 0 0 5px 1px $certify-black-opacity02;
      z-index: 11;
  }
  &__select-color-button{
      position: relative;
      float: left;
      width: 38px;
      height: 22px;
      margin-left: 3px;
      margin-top: 1px;
      outline: none;
      color: $snow-white;
      background-color: $green;
      border: 0px;
      border-radius: 12px;
      margin-bottom: 3px;
      &:hover{
          .customize-field-list__circle-button{
              display: inline;
          }
      }
  }
  &__circle-button{
      font-size: 9px;
      left: 7px;
      top: 7px;
      position: absolute;
      display: none;
      &--selected{
          display: inline;
      }
  }

  &__block-list{
      margin-bottom: 7px;
      display: flex;
      flex-direction: column;
  }

  &__input-text{
      width: 100%;
      padding: 1px 0 0 10px;
  }

  &__cell-block{
      position: relative;
      float: left;
      max-width: calc(100% - 55px);
      height: 20px;
      margin-top: 5px;
  }

  &__switch-fields{
    position: relative;
    float: left;
    width: 100%;
    margin-top: 10px;
    &--no-error{
      margin-bottom: 76px;
    }
  }

  &__icon-button{
      top: 4px;
      position: relative;
      padding: 1px;
      border: 1px solid transparent;
      outline: none;
      background-color: transparent;
      font-size: 20px;
      color: $primary-color;
      &:hover:not([disabled]){
          border: 1px solid $donut-text-02;
          background: $background-grey;
          cursor: pointer;
      }
      &--radio{
          border-radius: 50px;
      }

      &--disabled{
          color: $subcontainer-ground;
          cursor: not-allowed !important;
          &:hover{
              cursor: not-allowed;
          }
      }
  }

  &__text-list{
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 14px;
      text-transform: none;
      &--required-list{
          opacity: 100%;
          color: $red !important;
          background: transparent;
          border: none;
          width: 100%;
      }
  }
  &__error-block-advice{
      margin-top: 10px;
      margin-bottom: 25px;
      position: relative;
      float: left;
      width: 100%;
  }

  &__error-block{
      height: 55px;
      width: 100%;
      background-color: $background-error;
      color: $red;
      margin-top: 20px;
      margin-bottom: 25px;
      position: relative;
      float: left;
      display: table;
  }

  &__icon-close-header{
      color: $red;
      font-size: 15px;
      position: absolute;
      top: 20px;
      right: 10px;
      &:hover{
          cursor: pointer;
      }
  }
}

.compliance{
  &__steel-blue{
    .customize-field-list{
      &__icon-button{
        color: $border-hover-2;
        &:hover:not([disabled]){
            border: 1px solid $third-color-2;
            background: $block-hover-2;
        }

        &--disabled{
          color: $grey-icons-2;
        }
     }
    }
  }
  &__gm-law{
    .customize-field-list{
      &__list-value-text{
        color: $custom-black-landing;
      }
      &__list-value-input{
        border-color: $border-block-gm;
          &:hover{
              border-color: $primary-color-gm;
          }
      }
      &__input-icon{
        color: $snow-white;
      }
      &__input-icon-arrow{
        color: $primary-color-gm;
      }
      &__input-icon-menu{
        color: $primary-color-gm;
      }
      &__option-menu{
        background-color: $primary-color-gm;
      }
      &__icon-button{
        color: $primary-color-gm;
            &:hover:not([disabled]){
                border: 1px solid $primary-color-gm;
                background: $snow-white;
            }
      }
      &__text-list{
          color: $text-field-color-gm;
      }
    }
  }
  &__com-de{
    .customize-field-list{
      &__list-value-text{
        color: $custom-black-landing;
      }
      &__list-value-input{
        border-color: $border-block-com-de;
          &:hover{
              border-color: $primary-color-com-de;
          }
      }
      &__input-icon{
        color: $snow-white;
      }
      &__input-icon-arrow{
        color: $primary-color-com-de;
      }
      &__input-icon-menu{
        color: $primary-color-com-de;
      }
      &__option-menu{
        background-color: $primary-color-com-de;
      }
      &__icon-button{
        color: $primary-color-gm;
            &:hover:not([disabled]){
                border: 1px solid $primary-color-com-de;
                background: $snow-white;
            }
      }
      &__text-list{
          color: $text-field-color-com-de;
      }
    }
  }
  &__com-fr{
    .customize-field-list{
      &__list-value-text{
        color: $custom-black-landing;
      }
      &__list-value-input{
        border-color: $border-block-com-fr;
          &:hover{
              border-color: $primary-color-com-fr;
          }
      }
      &__input-icon{
        color: $snow-white;
      }
      &__input-icon-arrow{
        color: $primary-color-com-fr;
      }
      &__input-icon-menu{
        color: $primary-color-com-fr;
      }
      &__option-menu{
        background-color: $primary-color-com-fr;
      }
      &__icon-button{
        color: $primary-color-gm;
            &:hover:not([disabled]){
                border: 1px solid $primary-color-com-fr;
                background: $snow-white;
            }
      }
      &__text-list{
          color: $text-field-color-com-fr;
      }
    }
  }
}
