.field-type-email{
  &__field-type{
      width: 100%;
      display: table;
      position: relative;
      &--error{
          margin-bottom: 12px;
      }
  }
  &__alert-message{
      position: absolute;
      font-size: 12px;
      padding-top: 5px;
  }

  &__input-block{
      cursor: text;
      border-width: 3px;
      &:hover:not([disabled]) {
          // border-color: $primary-color;
          // border-width: 3px;
          & .common__tooltip-law, .common__info-icon-block, .common__tooltip-law-block {
              visibility: visible;
          }
          & .common__tooltip-info-box {
              display: block;
          }
      }
  }
}

.compliance{
  &__dark-blue{
    .field-type-email{
      &__alert-message{
        color: $red;
      }
    }
  }
  &__stee-blue{
    .field-type-email{
      &__alert-message{
        color: $alert-color-2;
      }
    }
  }
}
