.field-type-alert{

  &__alert-container{
      margin: 0 ;
      padding: 0 10px;
      //min-height: 97px;
      background-color: $background-grey-other;
      margin-top: 7px;
      list-style: none;


  }
  &__block-alert{
      background-color: $background-grey-other;
      margin: 11px 10px;
      padding: 15px 10px 5px 10px;
      font-size: 11px;
      color: $primary-color;
      & span{
          color: $repeat-pattern;
      }
  }

  &__left{
      float: left;
      margin-top: 6px;
  }
  &__block{
      padding: 15px 10px 5px 10px;
  }

  &__alert-item-menu{
      position: relative;
      float: right;
      top: 10px;
      right: 10px;


  }
  &__alert-item-menu-icon{
      color: $custom-black-landing;
      font-size: 17px;
      &--left{
          margin-right:25px;
      }
      &:hover{
          cursor: pointer;
      }
  }

  &__no-padding{
      padding: 0px;
  }

  //////////////
  /// CUSTOM ///
  //////////////

  &__block-list-alert {
      margin-top: 10px;
      padding: 0px;
  }

  &__custom-element-list-alert {
      padding-top: 16px;
      display: table;
      width: 100%;
      height: 46px;
      color: $custom-black-landing;
      font-family: MTTMilano, sans-serif;
      font-size: 11px;
      font-weight: 500;
      border-bottom: 1px solid $custom-border-grey;

      &:first-child {
          border-top: 1px solid $custom-border-grey;
      }
  }
  //////////////////
  /// Fin Custom ///
  //////////////////
}

.compliance{
  &__icam-red{
    .field-type-alert{
      &__alert-container{
        background-color: $background-grey-icam;
      }
      &__alert-item-menu-icon {
        color: $primary-color-icam;
      }
      &__alert-item-text {
        color: $text-field-color-icam;
      }

      &__alert-item-text-info {
        color: $custom-black-landing;
      }
	  &__text-description{
		color: $text-field-color-icam;
  		}
    }
  }
  &__gm-law{
    .field-type-alert{
      &__alert-container{
        background-color: $background-grey-gm;
      }
      &__alert-item-menu-icon {
        color: $primary-color-gm;
      }
      &__alert-item-text {
        color: $text-field-color-gm;
      }
      &__alert-item-text-info {
        color: $custom-black-landing;
      }
	  &__text-description{
		color: $text-field-color-gm;
 	  }
    }
  }
  &__com-de{
    .field-type-alert{
      &__alert-container{
        background-color: $background-grey-com-de;
      }
      &__alert-item-menu-icon {
        color: $primary-color-com-de;
      }
      &__alert-item-text {
        color: $text-field-color-com-de;
      }
      &__alert-item-text-info {
        color: $custom-black-landing;
      }
      &__text-description{
        color: $text-field-color-com-de;
      }
    }
  }
  &__com-fr{
    .field-type-alert{
      &__alert-container{
        background-color: $background-grey-com-fr;
      }
      &__alert-item-menu-icon {
        color: $primary-color-com-fr;
      }
      &__alert-item-text {
        color: $text-field-color-com-fr;
      }
      &__alert-item-text-info {
        color: $custom-black-landing;
      }
      &__text-description{
        color: $text-field-color-com-fr;
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue {
    .field-type-alert{
      &__alert-container{
        background-color: $background-grey-2;
      }
      &__alert-item-menu-icon {
            color: $text-field-color-2;
      }
      &__alert-item-text {

            color: $text-field-color-2;
       }
      &__alert-item-text-info {
            color: $text-field-color-2;
      }
      &__text-description{
            color: $text-field-color-2;
      }
    }
  }
}
