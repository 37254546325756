@mixin concept-window-tab-subtab-block($color) {
  border-bottom: 2px solid $color;
}

@mixin concept-window-tab-list($firstColor, $secondColor){
  border-bottom: 0;
  border: 1px solid $firstColor;
  padding: 8px 8px 0px 7px;
  border-radius: 17px;
  margin: 10px 10px 0;
  color: $secondColor;
  height: 34px;
  line-height: 18px;
}

@mixin concept-window-tab-list-active($firstColor, $secondColor) {
  color: $firstColor;
  background:  $secondColor;
}

@mixin concept-window-tab-share-button($color){
  background: $color;
  width: 23px;
  height: 23px;
  bottom: 3px;
}

.concept-window-tab {
  width: 100%;
  display: block;
  position: relative;
  float: left;
  padding: 0 10px;
  &__subtab-block {
      width: 100%;
      padding: 0;
      margin: 35px 0 10px;
      height: 50px;
      &--general{
          margin-bottom: 30px;
      }
  }

  &__subtab-list-items {
      padding: 0;
      list-style: none;
      margin: 0;
  }

  &__list {
      font-size: 12px;
      float: left;
      text-transform: uppercase;
      padding: 15px 25px 0;
      font-family: MTTMilano-Bold, sans-serif;
      &:hover {
          cursor: pointer;
      }
  }

  &__list-text {
      margin: 0;
      padding: 0;
      float: left;
      position: relative;
  }

  &__share-button {
      border: none;
      border-radius: 50%;
      position: relative;
      bottom: 4px;
      margin-left: 10px;
      float: left;
      outline: none;
      display: block;
  }

  &__share-icon {
      font-size: 15px;
      color: $snow-white;
      position: relative;
      top: 2px;
      right: 3px;
  }

  &__hours {
      display: block;
      float: right;
      position: relative;
      top: 6px;
      right: 0px;
  }

  &__icon-block-alert{
      color: $snow-white;
      background-color: $red;
      font-size: 20px;
      width: 36px;
      height: 36px;
      border-radius: 18px;
      padding: 6px 8px;
      position: relative;
      float: left;
      margin-right: 6px;
  }

  &__button-hours {
      height: 36px;
      width: auto;
      padding: 0 20px;
      border-radius: 20px;
      outline: none;
      border: none;
      background-color: $background-grey;
      position: relative;
      &:hover{
          cursor: default;
      }
  }

  &__icon-hours {
      color: $snow-white;
      font-size: 20px;
      position: relative;
      top: 1px;
  }

  &__text-hours {
      color: $snow-white;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0;
      line-height: 16px;
      position: relative;
      margin-left: 3px;
      bottom: 3px;
  }
}
.compliance{
  &__dark-blue{
      .concept-window-tab {
          &__subtab-block {
              @include concept-window-tab-subtab-block($primary-color);
              margin-top: 40px;
              border-width: 1px;
          }
          &__list {
              color: $primary-color;
              height: 50px;
              &--active{
                  border-bottom: 9px solid $primary-color;
              }
          }
          &__share-button {
              background: $primary-color;
              height: 25px;
              width: 25px;
          }
          &__share-icon {
              right: 2px;
          }
          &__button-hours {
              &--red{
                  background-color: $red;
              }
              &--grey{
                  background-color: $subcontainer-ground;
              }
          }
      }
  }
  &__steel-blue, &__uk-steel-blue{
      .concept-window-tab {
          &__subtab-block {
              @include concept-window-tab-subtab-block($third-color-2);
              margin-top: 35px;
          }
          &__list {
              @include concept-window-tab-list($third-color-2, $border-hover-2);
              &--active{
                  @include concept-window-tab-list-active($primary-color-2, $third-color-2);
              }
          }
          &__share-button {
              @include concept-window-tab-share-button($border-hover-2);
          }
          &__icon-block-alert{
              &--red{
                  background-color: $alert-color-button-2;
              }
          }
          &__button-hours {
              &--red{
                  background-color: $alert-color-2;
              }
              &--grey{
                  background-color: $secondary-color-2;
              }
          }
      }
  }
  &__uk-steel-blue{
      .concept-window-tab {
          &__subtab-block {
              background-color: $snow-white;
          }
      }
  }
  &__icam-red{
      .concept-window-tab {
          &__subtab-block {
              @include concept-window-tab-subtab-block($border-block-icam);
              background-color: $snow-white;
          }
          &__list {
              @include concept-window-tab-list($third-color-icam, $custom-black-landing);
              &--active{
                  @include concept-window-tab-list-active($primary-color-icam, $third-color-icam);
              }
          }
          &__share-button {
              @include concept-window-tab-share-button($primary-color-icam);
          }
          &__button-hours {
              &--red{
                  background-color: $alert-color-icam;
              }
              &--grey{
                  background-color: $border-block-icam;
              }
          }
      }
  }
  &__gm-law{
      .concept-window-tab {
          &__subtab-block {
              @include concept-window-tab-subtab-block($border-block-gm);
              background-color: $snow-white;
          }
          &__list {
              @include concept-window-tab-list($third-color-gm, $custom-black-landing);
              &--active{
                  @include concept-window-tab-list-active($primary-color-gm, $third-color-gm);
              }
          }
          &__share-button {
              @include concept-window-tab-share-button($primary-color-gm);
          }
          &__button-hours {
              &--red{
                  background-color: $alert-color-gm;
              }
              &--grey{
                  background-color: $border-block-gm;
              }
          }
      }
  }
  &__com-de{
    .concept-window-tab {
        &__subtab-block {
            @include concept-window-tab-subtab-block($border-block-com-de);
            background-color: $snow-white;
        }
        &__list {
            @include concept-window-tab-list($third-color-com-de, $custom-black-landing);
            &--active{
                @include concept-window-tab-list-active($primary-color-com-de, $third-color-com-de);
            }
        }
        &__share-button {
            @include concept-window-tab-share-button($primary-color-com-de);
        }
        &__button-hours {
            &--red{
                background-color: $alert-color-com-de;
            }
            &--grey{
                background-color: $border-block-com-de;
            }
        }
    }
  }
  &__com-fr{
    .concept-window-tab {
        &__subtab-block {
            @include concept-window-tab-subtab-block($border-block-com-fr);
            background-color: $snow-white;
        }
        &__list {
            @include concept-window-tab-list($third-color-com-fr, $custom-black-landing);
            &--active{
                @include concept-window-tab-list-active($primary-color-gm, $third-color-com-fr);
            }
        }
        &__share-button {
            @include concept-window-tab-share-button($primary-color-com-fr);
        }
        &__button-hours {
            &--red{
                background-color: $alert-color-com-fr;
            }
            &--grey{
                background-color: $border-block-com-fr;
            }
        }
    }
  }
}
