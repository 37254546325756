.clients-list {
  &__block-body{
      display: block;
      width: 100%;
      padding: 20px 20px 0;
      overflow: hidden;
  }

  &__icon-options {
      font-size: 20px;
      cursor: pointer;
      &--edit {
          margin: 9px 9px 0px 19px;
      }
      &--delete {
          margin: 9px 9px 0px 0px;
      }
  }

  &__options-box {
      list-style-type: none;
      margin: 0;
      padding: 0;
      color: $primary-color;
      display: inline-flex;
      margin-right: 3px;
      background-color: $background-grey-other;
      border: 0.5px solid $certify-grey-opacity;
      height: 42px;
      width: 76px;
      -webkit-border-top-left-radius: 67px;
      -webkit-border-bottom-left-radius: 67px;
      -moz-border-radius-topleft: 67px;
      -moz-border-radius-bottomleft: 67px;
      border-top-left-radius: 67px;
      border-bottom-left-radius: 67px;
      position: absolute;
      z-index: 1;
      top: 2px;
      opacity: 0;
      right: -1px;

      & .tooltip {
          &[data-popper-placement^='bottom'] {
              &>.tooltip-inner {
                  background-color: $background-grey;
                  color: $primary-color;
                  border-bottom: none;
                  border-top: 1px solid $primary-color;
                  border-left: none;
                  padding: 10px;
                  font-size: 12px;
                  font-family: 'MTTMilano', sans-serif;
                  height: auto;
                  width: 100%;
                  margin-left: 6%;
                  box-shadow: 0 0 5px 2px $certify-black-opacity;
                  opacity: 1;
                  position: relative;
                  right: 20px;
                  top: -10px;
              }
              // .tooltip-arrow {
              //     margin: -10px 0px 0px -6px !important;
              // }
          }
      }
      &--no-permisions {
          width: 55px;
          & .tooltip {
              &[data-popper-placement^='bottom'] {
                  &>.tooltip-inner {
                      right: 10px;
                  }
              }
          }
      }
  }

  &__table-body-box{
      width: 100%;
      max-height: 213px;
      overflow-y: auto;
      overflow-x: hidden;
      &--more-4-elements{
          width: calc(100% + 17px);
      }
  }

  &__table-body-element{
      position: relative;
      display: table;
      width: 100%;
      border-bottom: 1px solid $box-comment-border-2;
      &:hover {
          background-color: $background-grey;
          & .clients-list__table-body--actions{
              & .clients-list__options-box {
                  opacity: 1;
              }
          }
      }
      &--more-4-elements{
          width: calc(100% - 7px);
      }
  }

  &__table-body{
      padding: 0;
      height: 46px;
      padding-left: 5px;
      position: relative;
      display: table-cell;
      vertical-align: middle;
      max-width: 0;
      &--knowledge{
          width: calc(100% - 60px)
      }
      &--name{
          width: 47%;
      }
      &--holding{
          width: 26%;
      }
      &--cif{
          width: calc(27% - 60px);
      }
      &--actions{
          width: 60px;
      }
  }

  &__table-header-box{
      width: 100%;
      height: 36px;
      position: relative;
      display: table;
      border-bottom: 1px solid $primary-color;
      color: $primary-color;
      margin-bottom: 0;
  }

  &__table-header{
      position: relative;
      display: table-cell;
      vertical-align: middle;
      padding: 0;
      padding-left: 5px;
      &--name{
          width: 47%;
          padding-bottom: 2px;
      }
      &--holding{
          width: 26%;
      }
      &--cif{
          width: calc(27% - 35px);
          text-transform: uppercase;
      }
      &--upgrades{
          width: 18%;
      }
      &--actions{
          width: 35px;
      }
  }

  &__body-big{
      width: 100%;
      position: relative;
      margin-bottom: 1px;
      min-height: calc(100% - 251px);
  }

  &__col-v2 {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding-right: 5px;
      &--tooltip {
          max-width: 0;
      }
      &--top-2{
          position: relative;
          top: 2px;
      }
      &--first{
          padding-left: 8px;
      }
      &--name{
          width: 27%;
      }
      &--holding{
          width: 24%;
      }
      &--cif{
          width: 24%;
      }
      &--email{
          width: calc(25% - 120px);
      }
      &--email-body{
          width: calc(25% - 22px);
      }
      &--actions{
          width: 120px;
          padding-right: 0;
          position: relative;
      }
      &--actions-body{
          width: 22px;
          padding-right: 0;
          position: relative;
      }
      &--name-v3{
          width: 35%;
      }
      &--cif-v3{
          width: calc(36% - 120px);
      }
      &--external-id-v3{
          width: 29%;
      }
  }

  &__block-list-v3{
      position: relative;
      float: left;
      height: 535px;
      width: 100%;
      padding: 10px;
  }
  &__block-search-v3{
      position: relative;
      float: left;
      width: 20px;
      margin-left: 9px;
      &--100{
          width: 100%;
          margin-left: 0;
      }
  }
  &__menu-options-row-v3{
      font-size: 26px;
      white-space: nowrap;
      padding: 5px 4px 0 15px;
      &:hover{
          cursor: pointer;
      }
  }
  &__text-to-options{
      font-family: 'MTTMilano', sans-serif;
      font-size: 12px;
      font-weight: 500;
      text-decoration: underline;
      position: relative;
      bottom: 8px;
  }

  &__block-text-no-clients{
      width: 100%;
      margin-top: 80px;
      position: absolute;
      text-align: center;
      font-family: Arial, sans-serif;
      font-size: 11px;
      line-height: 16px;
      color: $secondary-color;
  }

  &__text-no-clients{
      margin: 0;
  }
}

@media (min-width: 992px) and (max-width: 1460px){
  .clients-list{
      &__table-body{
          &--name{
              width: 68%;
          }
          &--cif{
              width: calc(32% - 60px);
          }
      }
      &__table-header{
          &--name{
              width: 68%;
          }
          &--cif{
              width: calc(32% - 35px);
          }
      }
  }
}

@media (max-width: 1310px){
  .clients-list{
      &__input-search-open{
          display: none;
      }
  }
}

.compliance {
  &__dark-blue {
    .clients-list {
      &__table-body-element{
        &:hover{
          background-color: $background-color-states;
        }
      }

      &__block-text-no-clients {
        color: $secondary-color;
      }
    }
  }

  &__steel-blue {
    .clients-list {
      &__block-text-no-clients{
            color: $primary-color-2;
      }
    }
  }

  &__uk-steel-blue {
    .clients-list {
      &__block-text-no-clients{
        color: $primary-color-2;
      }

      &__body-big {
        min-height: calc(100% - 181px);
      }
    }
  }

  &__icam-red {
    .clients-list {
      &__block-text-no-clients{
        color: $custom-black-landing;
      }
    }
  }

  &__gm-law {
    .clients-list {
      &__block-text-no-clients{
        color: $custom-black-landing;
      }
    }
  }
  &__com-de {
    .clients-list {
      &__block-text-no-clients{
        color: $custom-black-landing;
      }
    }
  }
  &__com-fr {
    .clients-list {
      &__block-text-no-clients{
        color: $custom-black-landing;
      }
    }
  }
}
