.modal-more-info{
    & .modal-dialog{
        width: 550px;
        & .modal-content{
            height: auto;
            min-height: 200px;
            width: 550px;
        }
    }

    padding: 10px;

    &__body{
        padding: 47px 30px 38px 40px;
        border: 1px solid $box-border;
        color: $primary-color;
        position: relative;
    }

    &__icon-close-modal{
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 20px;
        &:hover{
            cursor: pointer;
        }
    }

    &__title{
        font-family: MTTMilano, sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 19px;
        margin: 0 0 40px;
    }

    &__list{
        padding: 0;
        list-style: none;
        margin: 0;
    }

    &__element-list{
        margin-bottom: 9px;
        position: relative;
        padding-left: 32px;
        &:last-child {
            margin-bottom: 0px;
        }
    }

    &__element-icon{
        font-size: 20px;
        position: absolute;
        left: 0;
        top: -5px;
    }

    &__element-text{
        font-family: MTTMilano, sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        position: relative;
        bottom: 4px;
        color: $concept-blue;
    }
}
.compliance{
    &__steel-blue,
    &__uk-steelblue{
        .modal-more-info{
            &__body{
                border-color: $secondary-color-2;
                color: $primary-color-2;
            }
            &__icon-close-modal{
                color: $border-hover-2;
            }
            &__element-text{
                color: $grey-icons-2;
            }
        }
    }
    &__icam-red{
        .modal-more-info{
            &__body{
                border-color: $border-black-icam;
                color: $custom-black-landing;
            }
            &__icon-close-modal{
                color: $primary-color-icam;
            }
            &__element-text{
                color: $secondary-color-icam;
            }
        }
    }
    &__gm-law{
        .modal-more-info{
            &__body{
                border-color: $border-black-gm;
                color: $custom-black-landing;
            }
            &__icon-close-modal{
                color: $primary-color-gm;
            }
            &__element-text{
                color: $secondary-color-gm;
            }
        }
    }
    &__com-de{
      .modal-more-info{
          &__body{
              border-color: $border-black-com-de;
              color: $custom-black-landing;
          }
          &__icon-close-modal{
              color: $primary-color-com-de;
          }
          &__element-text{
              color: $secondary-color-com-de;
          }
      }
  }
  &__com-fr{
    .modal-more-info{
        &__body{
            border-color: $border-black-com-fr;
            color: $custom-black-landing;
        }
        &__icon-close-modal{
            color: $primary-color-com-fr;
        }
        &__element-text{
            color: $secondary-color-com-fr;
        }
    }
}
}
