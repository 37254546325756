
.input-time{
  float: left;
  box-sizing: border-box;
  border: 1px solid $result-search-quote-color;
  overflow: hidden;
  margin: 0 5px;
  position: relative;
  height: 30px;
  width: 80px;
  bottom: 2px;

  &__time-block{
      font-size: 12px;
      font-family: Arial, sans-serif;
      height: 28px;
      width: 45px;
      padding: 6px 0 6px 4px;
  }

  &__time{
      border: none;
      background: transparent;
      outline: none;
      position: relative;
      float: left;
      color: inherit;
      padding: 2px 0 0;
      text-align: center;
      height: 16px;
      width: 16px;
  }

  &__time-separator{
      position: relative;
      float: left;
      text-align: center;

  }

  &__icon-clock{
      font-size: 16px;
      position: absolute;
      top: 6px;
      right: 7px;
      color: $attach-icon-grey;
  }
}

.compliance{
  &__dark-blue{
    .input-time{
        &:hover:not([disabled]){
            background-color: $background-color-states;
            border-color: $primary-color;
        }
        &--disabled{
            background-color: $background-grey;
        }
        &__time-block{
            color: $secondary-color;
            &--error{
                color: $alert-color;
            }
        }
    }
  }
  &__steel-blue,
  &__uk-steel-blue {
    .input-time{
        border-color: $secondary-color-2;
        &:hover:not([disabled]) {
            background-color: $third-color-2;
            border-color: $border-hover-2;
        }
        &--disabled{
            border-color: $secondary-color-2;
            background-color: $background-grey-disabled-2;
        }
        &__time-block{
            color: $primary-color-2;
            &--error{
                color: $alert-color-2;
            }
        }
        &__icon-clock{
            color: $grey-icons-2;
            opacity: 0.7;
        }
    }
  }
  &__icam-red {
    .input-time{
        border-color: $border-block-icam;
        &:hover:not([disabled]) {
            border-color: $primary-color-icam;
            background-color: $hover-block-icam;
        }
        &--disabled{
            border-color: $border-block-icam;
            background-color: $background-grey-disabled-icam;
        }
        &__time-block{
            color: $custom-black-landing;
            &--error{
                color: $alert-color-icam;
            }
        }
        &__icon-clock{
            color: $secondary-color-icam;
            opacity: 0.7;
        }
    }
  }
  &__gm-law {
    .input-time{
        border-color: $border-block-gm;
        &:hover:not([disabled]) {
            border-color: $primary-color-gm;
            background-color: $hover-block-gm;
        }
        &--disabled{
            border-color: $border-block-gm;
            background-color: $background-grey-disabled-gm;
        }
        &__time-block{
            color: $custom-black-landing;
            &--error{
                color: $alert-color-gm;
            }
        }
        &__icon-clock{
            color: $secondary-color-gm;
            opacity: 0.7;
        }
    }
  }
  &__com-de {
    .input-time{
        border-color: $border-block-com-de;
        &:hover:not([disabled]) {
            border-color: $primary-color-com-de;
            background-color: $hover-block-com-de;
        }
        &--disabled{
            border-color: $border-block-com-de;
            background-color: $background-grey-disabled-com-de;
        }
        &__time-block{
            color: $custom-black-landing;
            &--error{
                color: $alert-color-com-de;
            }
        }
        &__icon-clock{
            color: $secondary-color-com-de;
            opacity: 0.7;
        }
    }
  }
  &__com-fr {
    .input-time{
        border-color: $border-block-com-fr;
        &:hover:not([disabled]) {
            border-color: $primary-color-com-fr;
            background-color: $hover-block-com-fr;
        }
        &--disabled{
            border-color: $border-block-com-fr;
            background-color: $background-grey-disabled-com-fr;
        }
        &__time-block{
            color: $custom-black-landing;
            &--error{
                color: $alert-color-com-fr;
            }
        }
        &__icon-clock{
            color: $secondary-color-com-fr;
            opacity: 0.7;
        }
    }
  }
}
