.duplicate-document{
    &__window{
        & .modal-dialog{
            & .modal-content{
                height: 423px;
                border-radius: 0px;
            }
        }
    }
    &__form-version{
        width: 100%;
        padding: 28px 74px 0;
        height: 336px;
        display: block;
        overflow: hidden;
    }
}