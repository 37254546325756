.basic-data-modal{
  &__window{
      &.modal .modal-content {
          width: 900px;
          height: 625px;
          border-radius: 0px;
      }
  }
  &__block-body{
      width: 98%;
      margin-left: 1%;
      position: relative;
      height: 527px;
  }
  &__label{
      width: 100%;
      margin: auto;
      padding-top: 30px;
  }
  &__check-block{
      display: flex;
      margin-top: 10px;
      height: 20px;
  }
  &__form{
      margin: 0 62px;
  }

  &__buttons-block{
      bottom: 13px !important;
  }

  &__switch-container {
      margin-left: 20px;
  }
}
