.dashboard-roles-obligated-subject{
  position: relative;
  float: left;
  width: 100%;
  min-height: 70px;
  padding: 10px 20px 2px;
  margin-bottom: 20px;
  border: 1px solid $result-search-quote-color;
  background-color: $background-color-states;

  &__block-line{
      line-height: 12px;
  }

  &__subblock-line{
      display: inline-block;
      height: 20px;
      margin-right: 20px;
      margin-bottom: 8px;
  }

  &__subblock-line-right{
      position: relative;
      float: right;
  }

  &__icon-risk{
      font-size: 20px;
      color: $primary-color;
  }

  &__block-text{
      position: relative;
      bottom: 4px;
  }

  &__title-text{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      color: $primary-color;
  }

  &__subtitle-text{
      font-family: MTTMilano, sans-serif;
      font-size: 13px;
      font-weight: normal;
      color: $pillow-group;
  }

  &__bubble-count{
      height: 18px;
      width: auto;
      padding: 3px 10px;
      border-radius: 10px;
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: bold;
      line-height: 11px;
      background-color: $background-grey-other;
      color: $pillow-group;
  }

  &__button-edit{
      background-color: transparent;
      border: none;
      outline: none;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 16px;
      margin-left: 14px;
      padding: 0;
      color: $primary-color;
  }

  &__text-button{
      position: relative;
      bottom: 4px;
  }

  &__icon-button{
      font-size: 20px;
  }
}

.compliance{
  &__steel-blue, &__uk-steel-blue{
    .dashboard-roles-obligated-subject{
        border-color: $background-grey-disabled-2;
        background-color: $background-grey-disabled-2;
        &__subblock-line-right{
            color: $primary-color-2;
        }
        &__icon-risk {
            color: $primary-color-2;
        }
        &__title-text{
            color: $primary-color-2;
        }
        &__subtitle-text{
            color: $text-field-color-2;
        }
        &__bubble-count{
            background-color: $grey-icons-2;
            color: $white-special-2;
        }
        &__button-edit{
            color: $primary-color-2;
        }
        &__icon-button{
            color: $border-hover-2;
        }
    }
  }
  &__uk-steel-blue{
    .dashboard-roles-obligated-subject{
      border: none;
      box-shadow: $box-shadow-v3;
      background-color: $background-grey-disabled-2;
    }
  }
  &__icam-red{
    .dashboard-roles-obligated-subject{
        border: none;
        box-shadow: $box-shadow-icam;
        background-color: $background-grey-icam;
    }
  }
  &__gm-law{
    .dashboard-roles-obligated-subject{
      border: none;
      box-shadow: $box-shadow-gm;
      background-color: $background-grey-gm;
    }
  }
  &__com-de{
    .dashboard-roles-obligated-subject{
      border: none;
      box-shadow: $box-shadow-com-de;
      background-color: $background-grey-com-de;
    }
  }
  &__com-fr{
    .dashboard-roles-obligated-subject{
      border: none;
      box-shadow: $box-shadow-com-fr;
      background-color: $background-grey-com-fr;
    }
  }
}
