.schedule-big {
    &__right-block {
        float: right;
        height: 32px;
    }
  
    &__block-container {
        max-width: 1420px;
        margin: 0 auto;
        min-height: calc(100% - 369px);
        display: block;
    }
    &__no-padding{
        padding: 0;
    }
}