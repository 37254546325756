.concept-window {
  &__recurrent-tag{
      font-family: Arial, sans-serif;
      font-weight: bold;
      font-size: 10px;
      text-transform: uppercase;
      color: $pillow-group;
      border: 1px solid $pillow-group;
      display: inline-block;
      line-height: 7px;
      padding: 5px 8px 5px;
      position: relative;
      bottom: 2px;
      margin: 8px 7px 0;
  }
  &__icon-clip{
      font-size: 30px;
      position: relative;
      float: right;
      color: $primary-color;
      border: none;
      outline: none;
      background-color: transparent;
      margin: 0;
      padding: 0;
  }
}
