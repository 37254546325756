@import '../../../../assets/scss/colors';

@mixin menu-header-user($borderColor, $borderTopColor) {
    border: 1px solid $borderColor;
    border-top: 2px solid $borderTopColor;
    right: 0;
}

@mixin menu-header-user-block-list($textColor, $borderColor, $backgroundColor) {
    color: $textColor;
    border-color: $borderColor;
    &:hover {
        background-color: $backgroundColor;
    }
}

@mixin menu-header-user-block-name-user($color, $borderColor) {
    color: $color;
    border-bottom: 1px solid $borderColor;
}

.menu-header-user{
    position: absolute;
    z-index: 12;
    min-height: 200px;
    width: 350px;
    top: 13px;
    padding: 24px;
    border: 1px solid $subcontainer-border;
    background-color: $snow-white;
    box-shadow: 0 0 25px 10px $bar-horizontal-grey-opacity;

    &__block-button-logout{
        position: relative;
        float: left;
        width: 156px;
        padding-bottom: 1px;
        display: inline-block;
        border-bottom: 3px solid $primary-color;
        margin-left: 72px;
    }

    &__button-logout{
        position: relative;
        float: left;
        height: 32px;
        width: 156px;
        background-color: $snow-white;
        font-family: MTTMilano-Bold, sans-serif;
        font-size: 12px;
        text-align: center;
        text-transform: uppercase;
        outline: 0;
        padding: 5px;
        border: 3px solid $primary-color;
        color: $primary-color;
        &:hover {
            color: $snow-white;
            background-color: $primary-color;
        }
    }

    &__block-list {
        height: 50px;
        border-bottom: 1px solid $subcontainer-poll-border;
        text-transform: uppercase;
        font-family: MTTMilano, sans-serif;
        font-size: 12px;
        font-weight: bold;
        &:hover{
            cursor: pointer;
        }
    }

    &__block-user{
        position: relative;
        margin-bottom: 35px;
        margin-top: 40px;
        text-align: center;
        color: $bar-horizontal-task-grey;
        font-family: MTTMilano, sans-serif;
    }

    &__block-name-user{
        position: relative;
        width: 100%;
        height: 66px;
        border-bottom: 1px solid $subcontainer-poll-border;
        font-family: MTTMilano-Bold, sans-serif;
        font-size: 14px;
        color: $bar-horizontal-task-grey;
        text-align: center;
        padding: 10px 0;
    }

    &__block-user-image{
        position: relative;
        width: 140px;
        height: 140px;
        background-color: $primary-color;
        border-radius: 100px;
        margin: 0 auto 20px auto;
    }

    &__body-generic {
        position: relative;
        float: left;
        width: 100%;
        text-align: left;
    }

    &__company-user{
        font-size: 12px;
        margin-bottom: 0px;
    }

    &__icon-edit-image{
        display: inline-block;
        position: absolute;
        font-size: 28px;
        color: $primary-color;
        border: none;
        background: none;
        outline: none;
        padding: 0;
        right: -8px;
        bottom: -5px;
    }

    &__icon-list {
        margin: 11px 16px 0px 10px;
        float: left;
        display: inline-block;
        font-size: 29px;
        &:hover {
            cursor: pointer;
        }
    }

    &__name-user{
        margin-bottom: 4px;
    }

    &__text-list {
        float: left;
        display: inline-block;
        margin: 18px 0px;
        height: 14px;
        &:hover {
            cursor: pointer;
        }
    }

    &__user-name{
        font-size: 16px;
        display: block;
        margin-bottom: 5px;
        float: left;
        width: 100%;
    }
}

.compliance {
  &__dark-blue {
      & .menu-header-user {
        @include menu-header-user($subcontainer-border, $primary-color);
        &__block-list{
          @include menu-header-user-block-list($primary-color, $result-search-quote-color, $background-color-states);
        }
      }
  }

  &__uk-steel-blue, &__steel-blue {
      & .menu-header-user {
        @include menu-header-user($third-color-2, $third-color-2);
        &__block-list{
          @include menu-header-user-block-list($primary-color-2, $secondary-color-2, $third-color-2);
        }
        &__block-name-user{
          @include menu-header-user-block-name-user($grey-icons-2, $secondary-color-2);
        }
        &__icon-list{
          color: $border-hover-2;
        }
      }
  }

  &__gm-law {
      & .menu-header-user {
        @include menu-header-user($primary-color-gm, $primary-color-gm);
        &__block-list{
          @include menu-header-user-block-list($custom-black-landing, $border-block-gm, $hover-block-gm);
        }
        &__block-name-user{
          @include menu-header-user-block-name-user($secondary-color-gm, $border-block-gm);
        }
        &__icon-list{
          color: $primary-color-gm;
        }
      }
  }
  &__com-de{
    & .menu-header-user {
      @include menu-header-user($primary-color-com-de, $primary-color-com-de);
      &__block-list{
        @include menu-header-user-block-list($custom-black-landing, $border-block-com-de, $hover-block-com-de);
      }
      &__block-name-user{
        @include menu-header-user-block-name-user($secondary-color-com-de, $border-block-com-de);
      }
      &__icon-list{
        color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    & .menu-header-user {
      @include menu-header-user($primary-color-com-fr, $primary-color-com-fr);
      &__block-list{
        @include menu-header-user-block-list($custom-black-landing, $border-block-com-fr, $hover-block-com-fr);
      }
      &__block-name-user{
        @include menu-header-user-block-name-user($secondary-color-com-fr, $border-block-com-fr);
      }
      &__icon-list{
        color: $primary-color-com-fr;
      }
    }
  }


  &__icam-red {
      & .menu-header-user {
        @include menu-header-user($primary-color-icam, $primary-color-icam);
        &__block-list{
          @include menu-header-user-block-list($custom-black-landing, $border-block-icam, $hover-block-icam);
        }
        &__block-name-user{
          @include menu-header-user-block-name-user($secondary-color-icam, $border-block-icam);
        }
        &__icon-list{
          color: $primary-color-icam;
        }
      }
  }
}
