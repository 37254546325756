.associated-documentation{
  & .modal-dialog{
      & .modal-content{
          height: 548px;
          border-radius: 0px;
      }
  }

  &__block-context{
      height: 548px;
      position: relative;
      float: left;
      width: 100%;
  }

  &__block-body{
      position: relative;
      float: left;
      padding: 50px 70px 0;
      font-size: 12px;
      margin: 0;
      width: 100%;
      height: 463px;
      &--change-view{
          padding-top: 26px;
      }
      &--second-step{
          padding-top: 50px;
      }
      &--preview-associated{
          padding-top: 29px;
      }
  }

  &__block-document-selected{
      height: 42px;
      width: 100%;
      margin-bottom: 24px;
      padding: 10px 8px;
  }

  &__icon-document-selected{
      font-size: 20px;
  }

  &__name-document-selected{
      position: relative;
      bottom: 4px;
      font-size: 12px;
      font-family: Arial, sans-serif;
      margin-left: 5px;
  }

  &__block-table{
      height: 348px;
      overflow-y: auto;
  }

  &__table-header {
      position: relative;
      display: inline-block;
      padding: 0;
      padding-left: 5px;
      font-weight: bold;
      font-size: 12px;
      &--title{
          width: 20%;
      }
      &--format{
          width: 20%;
      }
      &--creation{
          width: 20%;
          &:hover{
              cursor: pointer;
          }
      }
      &--author{
          width: 20%;
      }
      &--actions{
          width: 20%;
          text-align: right;
      }
  }

  &__table-header-box{
      width: 100%;
      margin: 18px 0 0px 0;
      height: 28px;
      position: relative;
      display: block;
      border-bottom: 1px solid ;
  }

  &__table-body-box {
      width: 100%;
      margin: 0;
      height: 300px;
      padding-bottom: 40px;
      overflow-y: auto;
      overflow-x: hidden;
  }

  &__table-body-element{
      position: relative;
      display: table;
      width: 100%;
      border-bottom: 1px solid $box-comment-border-2;
  }

  &__table-icon{
      margin-left: 15px;
      font-size: 20px;
  }

  &__line-table{
      padding: 0;
      height: 44px;
      padding-left: 5px;
      position: relative;
      display: table-cell;
      vertical-align: middle;
      max-width: 0;
      &--title{
          width: 394px;
      }
      &--format{
          width: 80px;
      }
      &--creation{
          width: 100px;
      }
      &--author{
          width: 144px;
      }
      &--actions{
          width: 26px;
      }
  }

  &__icon-ellipsis {
      font-size: 27px;
      margin-right: 8px;
      height: 25px;
      width: 11px;
      padding: 0;
      border: none;
      outline: none;
      background-color: transparent;
      float: right;
  }

  &__list-menu-block {
      width: 207px;
      border: 1px solid ;
      background-color: $snow-white;
      position: absolute;
      top: 9px;
      padding: 0;
      z-index: 2;
      border-radius: 0px;
      float: none;
      margin-left: -198px!important;
  }

  &__text-step{
      position: absolute;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      bottom: 20px;
      text-transform: uppercase;
  }

  &__block-pill{
      width: 100%;
      margin-bottom: 12px;
      padding: 0;
  }

  &__spinner-loader{
      margin: 80px auto 0;
      height: 30px;
      display: block;
      position: relative;
  }

}


.compliance{
  &__dark-blue{
    .associated-documentation{
      &__icon-document-selected{
        color: $primary-color;
      }
      &__block-document-selected{
        background-color: $background-color-states;
      }
      &__table-header{
        color: $primary-color;
        font-family: Arial, sans-serif;
      }
      &__table-header-box{
        border-bottom-color: $primary-color;
        color: $primary-color;
      }
      &__table-body-element{
        border-bottom-color:$box-comment-border-2;
        &:hover {
            background-color: $background-color-states;
        }
      }
      &__table-icon{
        color: $primary-color;
      }
      &__icon-ellipsis{
        color: $primary-color;
      }
      &__list-menu-block {
        border-color: $primary-color;
      }
      &__text-step{
        opacity: 0.5;
        color: $primary-color;
      }
    }
  }
  &__steel-blue{
    .associated-documentation{
      &__block-document-selected{
        background-color: $background-grey-disabled-2;
        color: $primary-color-2;
      }
      &__table-header{
        color: $border-hover-2;
        font-family: 'MTTMilano', sans-serif;
      }
      &__table-header-box{
        border-bottom-color: $border-hover-2;
        color: $border-hover-2;
      }
      &__table-body-element{
        border-bottom-color:$secondary-color-2;
        color: $primary-color-2;
        &:hover {
            background-color: $third-color-rgba-mid;
        }
      }
      &__table-icon{
        color: $border-hover-2;
      }
      &__icon-ellipsis{
        color: $border-hover-2;
      }
      &__list-menu-block{
        border-top: 2px solid $third-color-2;
        box-shadow: 0 0 5px -1px $box-shadow-box-2;
        border-color: $third-color-2;
      }
      &__col-text{
        color: $primary-color-2;
      }
      &__text-step{
        color: $grey-icons-2;
      }
    }
  }

  &__icam-red{
    .associated-documentation{
      &__table-header-box{
        border-bottom-color: $primary-color-icam;
        color: $primary-color-icam;
      }
      &__table-body-element{
        border-bottom-color:$secondary-color-2;
        color: $custom-black-landing;
        &:hover {
            background-color: $hover-block-icam;
        }
      }
      &__table-icon{
        color: $primary-color-icam;
      }
      &__icon-ellipsis{
        color: $primary-color-icam;
      }
      &__list-menu-block{
        border-top: 2px solid $primary-color-icam;
        border-color: $primary-color-icam;
      }
      &__col-text{
        color: $custom-black-landing;
      }
      &__text-step{
        color: $secondary-color-icam;
      }
    }
  }
  &__gm-law{
    .associated-documentation{
      &__table-header-box{
        border-bottom-color: $primary-color-gm;
        color: $primary-color-gm;
      }
      &__table-body-element{
        border-bottom-color:$secondary-color-2;
        color: $custom-black-landing;
        &:hover {
            background-color: $hover-block-gm;
        }
      }
      &__table-icon{
        color: $primary-color-gm;
      }
      &__icon-ellipsis{
        color: $primary-color-gm;
      }
      &__list-menu-block{
        border-top: 2px solid $primary-color-gm;
        border-color: $primary-color-gm;
      }
      &__col-text{
        color: $custom-black-landing;
      }
      &__text-step{
        color: $secondary-color-gm;
      }
    }
  }
  &__com-de{
    .associated-documentation{
      &__table-header-box{
        border-bottom-color: $primary-color-com-de;
        color: $primary-color-com-de;
      }
      &__table-body-element{
        border-bottom-color:$secondary-color-2;
        color: $custom-black-landing;
        &:hover {
            background-color: $hover-block-com-de;
        }
      }
      &__table-icon{
        color: $primary-color-com-de;
      }
      &__icon-ellipsis{
        color: $primary-color-com-de;
      }
      &__list-menu-block{
        border-top: 2px solid $primary-color-com-de;
        border-color: $primary-color-com-de;
      }
      &__col-text{
        color: $custom-black-landing;
      }
      &__text-step{
        color: $secondary-color-com-de;
      }
    }
  }
  &__com-fr{
    .associated-documentation{
      &__table-header-box{
        border-bottom-color: $primary-color-com-fr;
        color: $primary-color-com-fr;
      }
      &__table-body-element{
        border-bottom-color:$secondary-color-2;
        color: $custom-black-landing;
        &:hover {
            background-color: $hover-block-com-fr;
        }
      }
      &__table-icon{
        color: $primary-color-com-fr;
      }
      &__icon-ellipsis{
        color: $primary-color-com-fr;
      }
      &__list-menu-block{
        border-top: 2px solid $primary-color-com-fr;
        border-color: $primary-color-com-fr;
      }
      &__col-text{
        color: $custom-black-landing;
      }
      &__text-step{
        color: $secondary-color-com-fr;
      }
    }
  }
}
