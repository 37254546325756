@mixin task-edit-button-color($firstColor, $secondColor) {
  border-color: $firstColor;
  color: $firstColor;
  &:hover{
      color: $secondColor;
      background-color: $firstColor;
  }
}

@mixin task-edit-form-color($boxShadow) {
  margin-top: 22px;
  box-shadow: $boxShadow;
  padding: 7px 9px 0;
  margin-left: 20px;
}

@mixin task-edit-text-description-color($borderColor) {
  border-color: $borderColor;
  // & .mce-toolbar-grp{
  //     border-bottom: none;
  // }
  // & .mce-edit-area{
  //     border-top: none;
  // }
}

.task-edit {
  &--concept{
      position: relative;
      float: left;
  }
  &__block-header{
      margin-bottom: 27px;
      padding: 10px 12px 0 12px;
      position: relative;
      float: left;
  }

  &__sublock-header-first{
      width: 100%;
      height: 45px;
      margin-bottom: 10px;
  }

  &__title-task-block{
      width: 33%;
      position: relative;
      float: left;
      padding-top: 8px;
      font-family: MTTMilano, sans-serif;
      font-size: 20px;

  }

  &__block-date-expirated{
      width: 34%;
      position: relative;
      float: left;
      text-align: center;
      color: $red;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      padding-top: 11px;
      & > p{
          margin: 0;
      }
  }

  &__block-user{
      position: relative;
      float: left;
      height: 45px;
      width: 33%;
  }

  &__data-user{
      position: relative;
      float: left;
      text-transform: uppercase;
      height: 45px;
      width: calc(100% - 45px);
      padding-right: 12px;
      text-align: right;
  }

  &__date-creation-edited{
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
  }

  &__create-task {
      background-color: $snow-white;
      width: 72%;
      margin-left: 14%;
      margin-top: 5%;
      box-shadow: 0 0 5px 3px $bar-horizontal-grey-opacity02;
      border: none
  }

  &__input-form {
      display: inline-block;
      width: 100%;
  }

  &__fields-block {
      display: block;
      float: left;
  }

  &__icon-circle-expirated{
      font-size: 11px;
      margin-right: 5px;
      color: $red;
  }

  &__input {
      width: 100%;
      margin: 0;
      // margin: 5px 0px;
      min-height: 50px;
      position: relative;
      float: left;
  }

  &__input-hour {
      // width: 50%;
      width: calc(50% - 2px);
      margin: 0px 0px 10px;
      height: 50px;
      position: relative;
      float: left;
      &--right{
          float: right;
      }
  }

  &__user-edited {
      margin: 0px;
      margin-top: 9px;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      line-height: 1;
  }

  &__button-close-edit {
      position: relative;
      float: right;
      height: 44px;
      width: 44px;
      right: 0px;
      font-size: 22px;
      color: $snow-white;
      padding: 0px;
      outline: none;
      border: none;
      &:before {
          padding: 7px;
          border: 2px solid transparent;
      }
      &:hover {
          cursor: pointer;
          &:before {
              border-color: $snow-white;
          }
      }
  }

  &__button-copy-text{
      background-color: transparent;
      border: none;
      outline: none;
      font-size: 14px;
      padding: 0px;
      top: 2px;
      position: relative;
      margin-left: 5px;
  }

  &__text-description {
      border: 1px solid $result-search-quote-color;
      position: relative;
      float: left;
      width: 100%;
      margin-bottom: 10px;
      &--no-editable {
          border-color: $attach-icon-grey !important;
          background: $background-grey;
      }
      // & .mce-toolbar-grp {
      //     border-bottom: 5px solid $primary-color;
      // }
      // & .mce-tinymce {
      //     border-width: 0px !important;
      // }
      // &--custom{
      //     border-color: $attach-icon-grey;
      //     // & .mce-toolbar-grp {
      //     //     border-bottom: none;
      //     // }
      //     // & .mce-edit-area{
      //     //     border-top: 1px solid $background-grey;
      //     //     // color: $background-grey;
      //     // }
      // }
  }

  &__text-description-title {
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      padding: 9px 0 9px 10px;
      display: inline-block;
  }

  &__text-description-title-custom{
      color: $custom-black-landing;
      font-family: 'lato_regular', sans-serif;
      font-size: 13px;
      padding: 9px 0 9px 10px;
      display: inline-block;
  }

  &__text-description-input {
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 14px;
      visibility: hidden;
      height: 1px;
      width: 1px;
  }

  &__type-icon{
      color: $primary-color;
      font-size: 20px;
      display: inline-block;
      margin-right: 4px;
      position: relative;
  }

  &__right{
      float: right;
      padding-right: 2px;
  }
  &__block-info{
      position: relative;
      float: left;
      width: 100%;
      padding: 20px 50px;
      margin: 11px 0 10px 0;
      &--meeting{
          margin: 5px 0;
          padding: 13px 50px;
      }
      &--none{
          display: none;
      }
      &--communication{
          display: table;
          padding: 18px 95px 18px 24px;
      }
  }
  &__icon-communication-info{
      display: table-cell;
      width: 30px;
      position: relative;
      top: 3px;
  }
  &__text-communication-info{
      display: block;
      margin: 0;
      font-family: Arial, sans-serif;
      font-size: 11px;
      line-height: 19px;
  }
  &__block-connection-data{
      position: relative;
      float: left;
      width: 100%;
      margin: 0px 0 12px;
  }
  &__icon-editable-pattern{
      font-size: 14px;
      position: absolute;
      top: 11px;
      right: 11px;
      border: none;
      outline: none;
      background-color: transparent;
      &:hover{
          cursor: pointer;
      }
      &--off{
          opacity: 0.5;
          &:hover{
              cursor: default;
          }
      }
      &--off-custom{
          display: none;
      }
  }
  &__text-repetition{
      margin-bottom: 4px;
  }
  &__text-meeting-private{
      margin-bottom: 0;
  }
  &__text-repetition-subtitle{
      font-family: Arial, sans-serif;
      font-size: 11px;
      &--bold{
          font-weight: bold;
          text-transform: uppercase;
      }

  }
  &__block-repetition-date{
      position: relative;
      float: left;
      margin-top: 5px;
  }
  &__icon-refresh-repetition{
      position: absolute;
      left: -20px;
      top: 2px;
      font-size: 15px;

  }
  &__text-repetition-data{
      font-size: 12px;
      font-family: Arial, sans-serif;
      font-weight: bold;
      position: relative;
      top: 1px;
      &--upper{
          text-transform: uppercase;
      }
  }
  &__text-connection-data{
      font-size: 11px;
      font-family: Arial, sans-serif;
      font-weight: bold;
      position: relative;
      text-transform: uppercase;
      &--url{
          &:hover{
              text-decoration: underline;
              cursor: pointer;
          }
      }
  }
  &__left{
      float: left;
  }

  &__button{
      border: 1px solid;
      background-color: transparent;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 14px;
      text-align: center;
      height: 44px;
      outline: 0;
      padding: 9px 13px 7px 13px;
  }
  &__icon-button{
      font-size: 18px;
      display: block;
      float: left;
      margin-right: 13px;
  }

  &__error-message{
      position: relative;
      float: left;
      width: 100%;
      height: 55px;
      margin-bottom: 10px;
      background-color: $background-error;
      padding: 6px 17px;
      color: $red;
  }

  &__text-alert{
      font-family: Arial, sans-serif;
      font-size: 12px;
      position: relative;
      bottom: 12px;
  }
  &__icon-alert{
      font-size: 40px;
      margin-right: 12px;
  }
  &__icon-close-header{
      font-size: 15px;
      position: absolute;
      top: 20px;
      right: 10px;
      &:hover{
          cursor: pointer;
      }
  }
  &__label-cancel{
      color: $snow-white;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      background-color: $attach-icon-grey;
      padding: 2px 5px;
      height: 15px;
      position: relative;
      text-transform: uppercase;
      bottom: 1px;
  }
  &__task-box-right{
    @extend .concept-form__concept-properties;
    // background-color: transparent;
    // width: 35%;
    // float: right;
    // position: relative;
    // top: -13px;
    padding-right: 20px;
  }
  &__field-switch-block{
      position: relative;
      float: left;
      width: 100%;
      margin-bottom: 10px;
  }
  ////////////////////////////////////////////////
  /////////////////////Custom////////////////////
  //////////////////////////////////////////////

  &__custom-container-task{
      margin: 0px;
      padding: 0px;
      width: 100%;
      position: relative;
      display: block;
      margin-bottom: 12px;
      background: $snow-white;

  }
  &__custom-task-block{
      margin: 0px auto 30px;
      padding: 0 10px;
      max-width: 1388px;
      position: relative;
      padding-top: 36px;
  }
  &__custom-properties{
      background-color: transparent;
      width: 34%;
      float: left;
      padding-right: 20px;
  }

  &__text-repetition-subtitle-custom{
      color: $custom-black-landing;
      font-family: 'lato_regular', sans-serif;
      font-size: 13px;
      &--bold{
          font-weight: bold;
          text-transform: uppercase;
      }
  }
  &__text-repetition-data-custom{
      color: $custom-secondary-color;
      font-family: 'lato_regular', sans-serif;
      font-size: 14px;
      position: relative;
      text-transform: lowercase;

  }

  &__group-title-block-custom {
      width: 100%;
      text-transform: uppercase;
      position: relative;
      padding: 16px 15px 16px 15px;
      color: $snow-white;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 16px;
      border-radius: 4px;
      background-color: $custom-secondary-color;
      height: 52px;
  }

  &__container-text-custom {
      position: relative;
      float: left;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-transform: uppercase;
      max-width: 100%;
  }

  &__block-form-task{
      position: relative;
      padding: 15px 110px 5px;
  }
  ////////////////////////////////////////////////
  /////////////////////Fin Custom////////////////////
  //////////////////////////////////////////////
}

.compliance{
  &__dark-blue{
      .task-edit {
          &__block-header{
              width: 100%;
          }
          &__title-task-block{
              color: $primary-color;
          }
          &__data-user{
              color: $primary-color;
          }
          &__fields-block {
              width: 60%;
              padding: 0 14px 12px;
          }
          &__button-close-edit {
              background-color: $primary-color;
          }
          &__block-info{
              background-color: $background-grey-other;
              color: $primary-color;
          }
          &__text-communication-info{
              color: $repeat-pattern;
          }
          &__icon-editable-pattern{
              color: $primary-color;
          }
          &__text-repetition-subtitle{
              color: $repeat-pattern;
          }
          &__text-repetition-data{
              color: $primary-color;
          }
          &__text-connection-data{
              color: $primary-color;
          }
          &__button{
              @include task-edit-button-color($primary-color, $snow-white);
              &--cancel{
                  @include task-edit-button-color($subcontainer-ground, $snow-white);
              }
          }
          &__task-box-right{
            padding-right: 10px;
            top: -30px;
          }
      }
  }
  &__steel-blue,
  &__uk-steel-blue{
      .task-edit {
          &__block-header{
              width: calc(100% - 16px);
              margin-left: 8px;
          }
          &__title-task-block{
              color: $primary-color-2;
          }
          &__data-user{
              color: $primary-color-2;
          }
          &__fields-block {
              width: 61.3%;
          }
          &__button-close-edit {
              background-color: $border-hover-2;
          }
          &__text-description {
              @include task-edit-text-description-color($secondary-color-2);
          }
          &__text-description-title {
              color: $primary-color-2;
          }
          &__type-icon{
              top: 3px;
              color: $primary-color-2;
          }
          &__block-info{
              background-color: $background-grey-disabled-2;
              color: $primary-color-2;
          }
          &__icon-editable-pattern{
              color: $border-hover-2;
          }
          &__text-repetition-subtitle{
              color: $text-field-color-2;
          }
          &__icon-refresh-repetition{
              color: $text-field-color-2;
          }
          &__text-repetition-data{
              color: $primary-color-2;
          }
          &__text-connection-data{
              color: $primary-color-2;
          }
          &__left{
              margin-left: 40px;
          }
          &__button{
              @include task-edit-button-color($border-hover-2, $snow-white);
              &--cancel{
                  @include task-edit-button-color($grey-icons-2, $snow-white);
              }
          }
          &__form{
              @include task-edit-form-color(0 0 5px -1px $box-shadow-box-2);
          }
      }
  }
  &__icam-red{
      .task-edit {
          &__block-header{
              width: calc(100% - 16px);
              margin-left: 8px;
          }
          &__title-task-block{
              color: $custom-black-landing;
          }
          &__data-user{
              color: $custom-black-landing;
          }
          &__fields-block {
              width: 61.3%;
          }
          &__button-close-edit {
              background-color: $primary-color-icam;
          }
          &__text-description {
              @include task-edit-text-description-color($border-block-icam);
          }
          &__text-description-title {
              color: $custom-black-landing;
          }
          &__type-icon{
              top: 3px;
              color: $custom-black-landing;
          }
          &__block-info{
              background-color: $background-grey-disabled-icam;
              color: $text-field-color-icam;
          }
          &__icon-editable-pattern{
              color: $primary-color-icam;
          }
          &__text-repetition-data{
              color: $custom-black-landing;
          }
          &__button{
              @include task-edit-button-color($primary-color-icam, $snow-white);
              &--cancel{
                  @include task-edit-button-color($secondary-color-icam, $snow-white);
              }
          }
          &__form{
              @include task-edit-form-color(0 0 10px 1px $border-buttons-icam);
          }
      }
  }
  &__gm-law{
      .task-edit {
          &__block-header{
              width: calc(100% - 16px);
              margin-left: 8px;
          }
          &__title-task-block{
              color: $custom-black-landing;
          }
          &__data-user{
              color: $custom-black-landing;
          }
          &__fields-block {
              width: 61.3%;
          }
          &__button-close-edit {
              background-color: $primary-color-gm;
          }
          &__text-description {
              @include task-edit-text-description-color($border-block-gm);
          }
          &__text-description-title {
              color: $custom-black-landing;
          }
          &__type-icon{
              top: 3px;
              color: $custom-black-landing;
          }
          &__block-info{
              background-color: $background-grey-disabled-gm;
              color: $text-field-color-gm;
          }
          &__text-communication-info{
              color: $text-field-color-gm;
          }
          &__icon-editable-pattern{
              color: $primary-color-gm;
          }
          &__text-repetition-data{
              color: $custom-black-landing;
          }
          &__left{
              margin-left: 20px;
          }
          &__button{
              @include task-edit-button-color($primary-color-gm, $snow-white);
              &--cancel{
                  @include task-edit-button-color($secondary-color-gm, $snow-white);
              }
          }
          &__form{
              @include task-edit-form-color(0 0 10px 1px $border-buttons-gm);
          }
      }
  }
  &__com-de{
    .task-edit {
        &__block-header{
            width: calc(100% - 16px);
            margin-left: 8px;
        }
        &__title-task-block{
            color: $custom-black-landing;
        }
        &__data-user{
            color: $custom-black-landing;
        }
        &__fields-block {
            width: 61.3%;
        }
        &__button-close-edit {
            background-color: $primary-color-com-de;
        }
        &__text-description {
            @include task-edit-text-description-color($border-block-com-de);
        }
        &__text-description-title {
            color: $custom-black-landing;
        }
        &__type-icon{
            top: 3px;
            color: $custom-black-landing;
        }
        &__block-info{
            background-color: $background-grey-disabled-com-de;
            color: $text-field-color-com-de;
        }
        &__text-communication-info{
            color: $text-field-color-com-de;
        }
        &__icon-editable-pattern{
            color: $primary-color-com-de;
        }
        &__text-repetition-data{
            color: $custom-black-landing;
        }
        &__left{
            margin-left: 20px;
        }
        &__button{
            @include task-edit-button-color($primary-color-com-de, $snow-white);
            &--cancel{
                @include task-edit-button-color($secondary-color-com-de, $snow-white);
            }
        }
        &__form{
            @include task-edit-form-color(0 0 10px 1px $border-buttons-com-de);
        }
    }
  }
  &__com-fr{
    .task-edit {
        &__block-header{
            width: calc(100% - 16px);
            margin-left: 8px;
        }
        &__title-task-block{
            color: $custom-black-landing;
        }
        &__data-user{
            color: $custom-black-landing;
        }
        &__fields-block {
            width: 61.3%;
        }
        &__button-close-edit {
            background-color: $primary-color-com-fr;
        }
        &__text-description {
            @include task-edit-text-description-color($border-block-com-fr);
        }
        &__text-description-title {
            color: $custom-black-landing;
        }
        &__type-icon{
            top: 3px;
            color: $custom-black-landing;
        }
        &__block-info{
            background-color: $background-grey-disabled-com-fr;
            color: $text-field-color-com-fr;
        }
        &__text-communication-info{
            color: $text-field-color-com-fr;
        }
        &__icon-editable-pattern{
            color: $primary-color-com-fr;
        }
        &__text-repetition-data{
            color: $custom-black-landing;
        }
        &__left{
            margin-left: 20px;
        }
        &__button{
            @include task-edit-button-color($primary-color-com-fr, $snow-white);
            &--cancel{
                @include task-edit-button-color($secondary-color-com-fr, $snow-white);
            }
        }
        &__form{
            @include task-edit-form-color(0 0 10px 1px $border-buttons-com-fr);
        }
    }
  }
  &__landing-custom{
    .task-edit {
      &__fields-block {
          border: none;
          border-radius: 4px;
          box-shadow: 0 4px 20px 0 $attachment-custom-grey-opacity;
          margin: 0;
          padding: 10px;
          width: 66%;
      }
      &__text-description {
          border-color: $attach-icon-grey;
      }
      &__block-info{
          background-color: $background-grey;
          color: $custom-black-landing;
          margin: 0 0 15px 0;
      }
    }
  }
}


@media (max-width: 1135px) {
  .task-edit {
      &__block-form-task{
          padding: 15px 75px 5px;
      }
  }
}
