.field-type-clients-selector{
  position: relative;
  margin-bottom: 10px;
  &__input-block{
      &:hover {
          background-color: transparent !important;
      }
  }
  &__find-client {
      display: block;
      font-weight: normal;
      width: 100%;
      position: relative;
      padding-left: 23px;
      &--with-label{
        padding: 0 10px;
      }
  }

  &__find-client-input {
    background-color: transparent;
    width: calc(100% - 24px);
    font-family: 'MTTMILANO', sans-serif;
    font-size: 12px;
    font-weight: 500;
    &::-webkit-input-placeholder {
        font-style: normal;
        padding: 0px;
    }
  }

  &__search-icon {
    font-size: 20px;
    position: relative;
    top: 5px;
    right: 1px;
      &--hover {
          cursor: pointer;
      }
  }

  &__list-info-client {
      width: 100%;
      margin-top: 15px;
      position: relative;
      height: 218px;
      &--hidden{
          visibility: hidden;
      }
  }

  &__alert-result {
      height: auto;
      width: fit-content;
      font-family: Arial, sans-serif;
      font-size: 13px;
      padding: 5px 10px;
      font-weight: 100;
      position: absolute;
      margin-left: 23px;
      &--license {
          width: 100%;
          z-index: 1;
          top: 0;
      }
  }

  &__client-list-block {
      width: 100%;
      display: block;
      padding: 0 0 4px 22px;
      overflow: hidden;
      overflow-y: auto;
      height: 218px;
  }

  &__client-block {
      display: block;
      width: 33%;
      float: left;
  }

  &__client-box {
      height: 55px;
      width: calc(100% - 7px);
      display: block;
      margin: 14px 0 0 2px;

      &:hover{
          cursor: pointer;
          border-radius: 30px;
      }

      &--selected{
        border-radius: 30px;
        &:hover {
            border-radius: 30px;
            cursor: pointer;
            & .field-type-clients-selector__select-client-icon--check {
                display: none;
            }
            & .field-type-clients-selector__select-client-icon--close {
                display: block;
            }
        }
      }

      &--disabled{
        &:hover {
          cursor: default;
        }
        background-color: transparent !important;
      }
  }

  &__client-bubble-box {
      height: 53px;
      width: 53px;
      background: $snow-white;
      box-shadow: 0 0 5px 1px $certify-black-opacity02;
      border-radius: 50%;
      display: table;
      float: left;
      position: relative;
      top: 1px;
      left: 1px;
      &--disabled {
        opacity: 0.5 !important;
      }
      &--disabled-red{
        box-shadow: 0 0 5px 1px $red;
        background-color: $background-error;
      }
  }

  &__border-icon {
      width: 50px;
      height: 50px;
      display: table-cell;
      vertical-align: middle;
  }

  &__client-logo {
      width: 100%;
      // height: auto;
      height: 53px;
      border-radius: 50px;
  }

  &__client-initials {
      text-align: center;
      margin: 12px 0 0 0;
      height: 15px;
      font-family: Arial, sans-serif;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
  }

  &__select-client-icon {
      font-size: 28px;
      position: relative;
      left: 15px;
      top: 13px;

      &--check {
          display: block;
      }

      &--close {
          left: 12px;
          display: none;
      }
  }

  &__client-info-block {
      display: table;
      float: left;
      width: calc(100% - 53px);
      padding-left: 17px;
  }

  &__client-name {
      width: 130px;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: bold;
      margin: 18px 0 0 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &--afiliates {
          margin-top: 11px;
      }

      &--disabled {
        opacity: 0.5 !important;
    }
  }

  &__parent-select {
      display: flex;
      justify-content: flex-start;
      width: 113px;
      height: 17px;
      color: $subcontainer-ground;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      left: -2px;
      bottom: 2px;
      top: 1px;
      span-tooltip {
        margin-top: 3px;
        margin-left: 3px;
      }
  }

  &__parent-select-icon {
      font-size: 14px;
      position: relative;
      top: 3px;
  }

  &__filials-button {
      background: none;
      border: none;
      outline: none;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
  }

  &__filials-button-icon {
      font-size: 13px;
      padding-right: 3px;
  }

  &__filials-triangle {
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-right: 5px solid;
      position: absolute;
      top: 5px;
      left: 14px;
  }

  &__list-filials {
      list-style: none;
      left: 23px;
      top: -6px;
      border-radius: 0;
      border-left: 5px solid;
      max-height: 167px;
      overflow-y: auto;
      width: 210px;
      padding-left: 4px;
      padding-right: 6px;
  }

  &__select-filial {
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: 400;
      padding: 7px 10px 5px;
      height: 30px;
      &:hover {
          cursor: pointer;

          // & > .field-type-clients-selector__select-filial-icon {
          //     display: block;
          // }
      }
      &--disabled{
        &:hover {
          cursor: default;
        }
        background-color: transparent !important;
        // & > .field-type-clients-selector__select-filial-icon {
        //   display: none !important;
        // }
      }
  }

  &__angle-icon {
      float: left;
      position: relative;
      padding-right: 5px;
      top: 2px;
      &:hover{
          cursor: pointer;
      }
      &--open {
          top: 5px;
      }

      &--close {
          top: 4px;
      }
  }

  // &__afiliated-name {
  //     float: left;
  //     width: 132px;
  //     display: block;
  //     text-overflow: ellipsis;
  //     overflow: hidden;
  //     white-space: nowrap;
  //     margin: 2px 0 0;
  // }

  // &__select-filial-icon {
  //     font-size: 16px;
  //     display: none;
  //     position: absolute;
  //     right: 9px;
  // }

  // &__child-padding {
  //     padding-left: 16px;
  // }

  &__search-box{
    width: 605px;
    height: 30px;
    float: left;
  }

  &__alphabet-button{
    padding: 5px 0px 2px;
    border: none;
    font-family: 'MTTMilano-bold', sans-serif;
    font-size: 12px;
    font-weight: 500;
    height: 28px;
    width: 19px;
    float: left;
    text-align: center;
    // &:hover:not([disabled]) {
    &:hover {
        cursor: pointer;
    }
    &--with-label{
        width: 18px;
    }
    &--first{
      width: 70px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__search-text{
    color: $pillow-group;
    font-family: 'MTTMILANO', sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding: 6px 0 0 8px;
  }

  &__find-client-label{
    border: 1px solid $result-search-quote-color;
    height: 30px;
    position: relative;
    width: 180px;
    margin: 0;
  }

  &__more-organizations-block{
    list-style: none;
    display: block;
    overflow: hidden;
    width: 100%;
    position: relative;
    top: 8px;
  }
  &__more-organizations-button{
    height: 25px;
    width: 147px;
    background-color: $result-search-quote-color;
    border-radius: 15px;
    border: none;
    margin: 0 auto;
    display: block;
    color: $pillow-group;
    font-family: 'MTTMILANO', sans-serif;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    &:hover{
        color:  $snow-white;
        background: $pillow-group
    }
  }
  &__more-organizations-icon{
    font-size: 15px;
    position: relative;
    top: 2px;
    left: 2px;
  }
}

.compliance{
  &__dark-blue{
    .field-type-clients-selector{
      // &__afiliated-name {
      //   &--disabled{
      //       opacity: $subcontainer-ground;
      //   }
      //   &--disabled-red{
      //       color: $red;
      //   }
      // }
      &__client-box{
        &:hover {
          background: $background-color-states;
        }
        &--selected{
          background: $primary-color;
          &:hover {
              background: $primary-color;
          }
        }
      }
      &__client-bubble-box {
        &--selected {
            box-shadow: none;
            background: $primary-color;
        }
      }
      &__alert-result {
        color: $alert-result;
        background-color: $background-error;
      }
      &__filials-button{
        color: $subcontainer-ground;
      }
      &__search-icon,
      &__client-initials,
      &__list-filials,
      &__filials-button-icon {
        color: $primary-color;
      }
      &__filials-triangle {
        border-right-color: $primary-color;
      }
      &__select-filial {
        color: $primary-color;
        &:hover {
            background-color: $subcontainer-border;
        }
      }
      &__select-client-icon {
        color: $snow-white;
      }
      &__client-name {
        color: $primary-color;
        &--selected {
          color: $snow-white;
          margin-top: 20px;
        }
        &--selected-parent {
          color: $snow-white;
          margin-top: 10px;
        }
      }
      &__find-client {
        margin-top: 34px;

      }
      &__find-client-input {
        color: $primary-color;
      }
      &__parent-select {
        color: $subcontainer-ground;
        &--selected {
          color: $snow-white;
        }
      }
      &__search-box{
        border-top: 1px solid $result-search-quote-color;
        border-bottom: 1px solid $result-search-quote-color;
      }
      &__alphabet-button{
        color: $primary-color;
        background: $snow-white;
        &:hover{
            background: $background-color-states;
            color: $primary-color;
        }
        &--selected{
            background: $primary-color;
            color: $snow-white;
        }

        &--disabled{
          .field-type-clients-selector__text{
              opacity: 0.5;
          }
          &:hover{
              background: $snow-white;
              cursor: default;
          }
        }
      }
      &__find-client-label{
        height: 30px;
        position: relative;
        width: 180px;
        &--with-label{
          width: 173px;
        }
        &--focused{
          border: 1px solid $result-search-quote-color;
          background-color: $background-color-states;
          box-shadow: 0 0 10px 0px $line-graph-shadow;
        }
      }
    }
  }

  &__steel-blue {
    .field-type-clients-selector{
      // &__afiliated-name {
      //   &--disabled {
      //       color: $grey-icons-2;
      //   }
      //   &--disabled-red{
      //       color: $alert-color-2;
      //   }
      // }
      &__client-box{
        &:hover {
          background-color: $third-color-2;
        }
        &--selected{
          background: $primary-color-2;
          &:hover {
              background: $primary-color-2;
          }
        }
      }
      &__client-bubble-box {
        &--selected {
          box-shadow: none;
          background: $primary-color-2;
        }
        &--disabled-red{
          box-shadow: 0 0 5px 1px $alert-color-2;
          background-color: $background-other-error-2;
        }
      }
      &__alert-result {
        color: $alert-color-2;
        background: $background-error-2;
      }
      &__search-icon,
      &__client-initials {
        color: $border-hover-2;
      }
      &__filials-button{
        color: $subcontainer-ground;
      }
      &__filials-button-icon {
        color: $border-bottom-2;
      }
      &__filials-triangle {
        border-right-color: $primary-color-2;
      }
      &__list-filials {
        border-left-color: $primary-color-2;
      }
      &__select-filial {
        color: $border-hover-2;
        &:hover {
            background-color: $third-color-2;
        }
      }
      &__select-client-icon {
        color: $white-special-2;
      }
      &__client-name {
        color: $primary-color-2;
        &--selected {
          color: $white-special-2;
          margin-top: 20px;
        }
        &--selected-parent {
          color: $white-special-2;
          margin-top: 10px;
        }

      }
      &__find-client {
        margin-top: 27px;
      }
      &__find-client-input {
        color: $primary-color-2;
        &::placeholder {
            color: $border-hover-2;
        }
      }
      &__parent-select {
        color: $grey-icons-2;
        &--selected {
          color: $snow-white;
        }
      }
      &__search-box{
        border-top: 1px solid$secondary-color-2;
        border-bottom: 1px solid$secondary-color-2;
      }
      &__alphabet-button{
        color: $primary-color-2;
        background: $snow-white;
        &:hover{
          background: $third-color-2;
          color: $primary-color-2;
        }
        &--selected{
          background: $primary-color-2;
          color: $white-special-2;
        }
        &--disabled{
          .field-type-clients-selector__text{
            opacity: 0.5;
          }
          &:hover{
            background: $snow-white;
            cursor: default;
          }
        }
      }
      &__find-client-label{
        &--focused{
          border: 1px solid $border-hover-2;
          background-color: $block-hover-2;
          box-shadow: 0 0 10px 0px $line-graph-shadow;
        }
      }
    }
  }

  &__gm-law {
    .field-type-clients-selector{
      &__client-box{
        &:hover {
          background-color: $third-color-gm;
        }
        &--selected{
          background: $primary-color-gm;
          &:hover {
              background:  $primary-color-gm;
          }
        }
      }
      &__client-bubble-box {
        &--selected {
          box-shadow: none;
          background: $primary-color-gm;
        }
      }
      &__alert-result {
        color: $alert-color-2;
        background: $background-error-2;
      }
      &__filials-button{
        color: $secondary-color-gm;
      }
      &__search-icon,
      &__client-initials,
      &__filials-button-icon{
        color: $primary-color-gm;
      }
      &__filials-triangle {
        border-right-color: $primary-color-2;
      }
      &__list-filials {
        border-left-color: $primary-color-2;
      }
      &__select-filial {
        color: $primary-color-gm;
        &:hover {
            background-color: $hover-block-gm;
        }
      }
      &__select-client-icon {
        color: $snow-white;
      }
      &__client-name {
        color: $custom-black-landing;
        &--selected {
          color: $snow-white;
          margin-top: 20px;
        }
        &--selected-parent {
          color: $snow-white;
          margin-top: 10px;
        }
      }
      &__find-client {
        margin-top: 27px;
      }
      &__find-client-input {
        border-left-color: transparent;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: $third-color-gm;
        color: $primary-color-gm;

        &::placeholder {
            color: $primary-color-gm;
        }
      }
      &__parent-select {
        color: $grey-icons-2;
        &--selected {
          color: $snow-white;
        }
      }
    }
  }
  &__com-de {
    .field-type-clients-selector{
      &__client-box{
        &:hover {
          background-color: $third-color-com-de;
        }
        &--selected{
          background: $primary-color-com-de;
          &:hover {
              background:  $primary-color-com-de;
          }
        }
      }
      &__client-bubble-box {
        &--selected {
          box-shadow: none;
          background: $primary-color-com-de;
        }
      }
      &__alert-result {
        color: $alert-color-2;
        background: $background-error-2;
      }
      &__filials-button{
        color: $secondary-color-com-de;
      }
      &__search-icon,
      &__client-initials,
      &__filials-button-icon{
        color: $primary-color-com-de;
      }
      &__filials-triangle {
        border-right-color: $primary-color-2;
      }
      &__list-filials {
        border-left-color: $primary-color-2;
      }
      &__select-filial {
        color: $primary-color-com-de;
        &:hover {
            background-color: $hover-block-com-de;
        }
      }
      &__select-client-icon {
        color: $snow-white;
      }
      &__client-name {
        color: $custom-black-landing;
        &--selected {
          color: $snow-white;
          margin-top: 20px;
        }
        &--selected-parent {
          color: $snow-white;
          margin-top: 10px;
        }
      }
      &__find-client {
        margin-top: 27px;
      }
      &__find-client-input {
        border-left-color: transparent;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: $third-color-com-de;
        color: $primary-color-com-de;

        &::placeholder {
            color: $primary-color-com-de;
        }
      }
      &__parent-select {
        color: $grey-icons-2;
        &--selected {
          color: $snow-white;
        }
      }
    }
  }
  &__com-fr {
    .field-type-clients-selector{
      &__client-box{
        &:hover {
          background-color: $third-color-com-fr;
        }
        &--selected{
          background: $primary-color-com-fr;
          &:hover {
              background:  $primary-color-com-fr;
          }
        }
      }
      &__client-bubble-box {
        &--selected {
          box-shadow: none;
          background: $primary-color-com-fr;
        }
      }
      &__alert-result {
        color: $alert-color-2;
        background: $background-error-2;
      }
      &__filials-button{
        color: $secondary-color-com-fr;
      }
      &__search-icon,
      &__client-initials,
      &__filials-button-icon{
        color: $primary-color-com-fr;
      }
      &__filials-triangle {
        border-right-color: $primary-color-2;
      }
      &__list-filials {
        border-left-color: $primary-color-2;
      }
      &__select-filial {
        color: $primary-color-com-fr;
        &:hover {
            background-color: $hover-block-com-fr;
        }
      }
      &__select-client-icon {
        color: $snow-white;
      }
      &__client-name {
        color: $custom-black-landing;
        &--selected {
          color: $snow-white;
          margin-top: 20px;
        }
        &--selected-parent {
          color: $snow-white;
          margin-top: 10px;
        }
      }
      &__find-client {
        margin-top: 27px;
      }
      &__find-client-input {
        border-left-color: transparent;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: $third-color-com-fr;
        color: $primary-color-com-fr;

        &::placeholder {
            color: $primary-color-com-fr;
        }
      }
      &__parent-select {
        color: $grey-icons-2;
        &--selected {
          color: $snow-white;
        }
      }
    }
  }
}