.box-queries {
  margin: 35px 0 48px;


  &__block-body{
      background-color: $snow-white;
      margin-left: 8px;
  }

  &__header {
      padding: 9px 10px 0;
  }

  &__header-upper {
      display: flex;
      justify-content: space-between;
  }

  &__title {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: MTTMilano-Bold, sans-serif;
  }

  &__title-icon {
      font-size: 30px;
  }

  &__title-text {
      padding-left: 10px;
      position: relative;
      top: 2px;
  }

  &__actions {
      display: flex;
  }

  &__new {
      font-size: 20px;
      cursor: pointer;
      position: relative;
      top: 2px;
  }

  &__count {
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
      height: 20px;
      border-radius: 10px;
      padding: 1px 10px 0;
      margin-left: 10px;
      position: relative;
      top: 2px;
  }

  &__header-lower {
      font-size: 12px;
      font-family: MTTMilano, sans-serif;
      border-bottom: 1px solid;
      padding: 11px 0 3px;
  }

  &__body {
      padding: 0 10px;
      max-height: 708px;
      overflow: auto;
  }

  &__query {
      display: flex;
      padding: 10px;
      border-bottom: 1px solid;
      position: relative;
      &:hover {
          & .box-queries__query-options {
              opacity: 1;
          }
      }
  }

  &__query-icon {
      font-size: 20px;
  }

  &__query-body {
      font-family: Arial, sans-serif;
      padding-left: 8px;
  }

  &__query-reference {
      font-size: 12px;
      font-weight: bold;
  }

  &__query-title {
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      padding-top: 5px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
  }

  &__query-description {
      font-size: 11px;
      padding-top: 5px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
  }

  &__query-options {
      position: absolute;
      right: 0;
      margin-top: -10px;
      opacity: 0;
      list-style-type: none;
      height: 42px;
      width: 51px;
      border-radius: 21px 0 0 21px;
      padding: 0 3px 0 0;
  }

  &__no-queries {
      color: $custom-black-landing;
      font-family: Arial, sans-serif;
      font-size: 11px;
      text-align: center;
      padding: 31px 0 5px;
      margin: 0;
  }

  &__footer {
      padding: 0 10px 10px;
  }

  &__more-button {
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      cursor: pointer;
      margin: 20px 0 10px 30px;
  }

  &__more-button-icon {
      font-size: 18px;
  }

  &__more-button-text {
      font-family: Arial, sans-serif;
      font-size: 11px;
      padding-left: 5px;
  }
}

.compliance{
  &__icam-red{
      .box-queries {
          background: linear-gradient($gradient-icam-white-red);
          box-shadow: $box-shadow-icam;
          &__title {
              color: $custom-black-landing;
          }
          &__new {
              color: $primary-color-icam;
          }
          &__count {
              color: $custom-black-landing;
              background-color: $third-color-icam;
          }
          &__header-lower {
              color: $primary-color-icam;
              border-bottom-color: $primary-color-icam;
          }
          &__query {
              border-bottom-color: $border-block-icam;
              &:hover {
                  background-color: $hover-block-icam;
              }
          }
          &__query-icon {
              color: $primary-color-icam;
          }
          &__query-reference {
              color: $custom-black-landing;
          }
          &__query-title {
              color: $secondary-color-icam;
          }
          &__query-description {
              color: $custom-black-landing;
          }
          &__query-options {
              background-color: $primary-color-icam;
              color: $snow-white;
          }
          &__more-button {
              color: $primary-color-icam;
          }
      }
  }
}
