.field-type-number {
  &__arrow-icon{
      float: right;
      font-size: 15px;
      margin-left: 4px;
  }

  &__coin-text {
      color: $subcontainer-ground;
      padding-top: 3px;
      padding-right: 8px;
      margin: 0;
      position: absolute;
      font-family: MTTMilano, sans-serif;
      font-size: 15px;
      right: 0;
      top: 0;

      &--custom{
          color: $attachment-custom-grey-other;
          font-family: 'lato_regular', sans-serif;
          font-size: 14px;
          font-weight: 500;
          top: -3px;
          right: 15px;
      }
  }

  &__span-block{
      position: absolute;
      text-transform: uppercase;
      font-family: Arial, sans-serif;
      font-weight: bold;
      font-size: 10px;
      right: 8px;
      text-align: center;
      border-bottom: solid 2px $primary-color;
      padding: 0 4px;
      color: $primary-color;

      &:hover{
          cursor: pointer;
      }
  }
}

.compliance {
&__dark-blue {
  .field-type-number {
    &__coin-text {
      color: $subcontainer-ground;
    }
  }
}

&__steel-blue {
  .field-type-number {
    &__coin-text {
      color: $grey-icons-2;
    }
  }
}

&__uk-steel-blue {
  .field-type-number {
    &__coin-text {
      color: $grey-icons-2;
    }
  }
}

&__icam-red {
  .field-type-number {
    &__coin-text {
      color: $grey-icons-2;
      font-size: 12px;
    }
  }
}

&__gm-law {
  .field-type-number {
    &__arrow-icon{
      color: $primary-color-gm;
    }

    &__coin-text {
      color: $grey-icons-2;
      font-size: 12px;
    }

    &__span-block {
      color: $primary-color-gm;
      border-bottom: solid 2px $primary-color-gm;
    }
  }
}
&__com-de {
  .field-type-number {
    &__arrow-icon{
      color: $primary-color-com-de;
    }

    &__coin-text {
      color: $grey-icons-2;
      font-size: 12px;
    }

    &__span-block {
      color: $primary-color-com-de;
      border-bottom: solid 2px $primary-color-com-de;
    }
  }
}
&__com-fr {
  .field-type-number {
    &__arrow-icon{
      color: $primary-color-com-fr;
    }

    &__coin-text {
      color: $grey-icons-2;
      font-size: 12px;
    }

    &__span-block {
      color: $primary-color-com-fr;
      border-bottom: solid 2px $primary-color-com-fr;
    }
  }
}
}
