.link-modal {
  &__window {
      & .modal-content {
          width: 479px;
          height: 330px;
          left: calc(50% - 240px);
      }
  }

  &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 38px;
      border-bottom: 1px solid $custom-color-C5C5C5;
  }

  &__header-title {
      font-size: 20px;
      font-weight: bold;
      padding: 9px 15px;
  }

  &__header-close-button {
      font-size: 10px;
      font-weight: bold;
      color: $custom-color-858585;
      padding: 14px;
      cursor: pointer;

      &:hover {
          color: $custom-color-ADADAD;
      }
  }

  &__fields-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 227px;
      padding: 20px;
  }

  &__field-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
  }

  &__field-label {
      font-weight: normal;
  }

  &__field-input {
      width: 310px;
      height: 28px;
      border: 1px solid $custom-color-C5C5C5;

      &:focus {
          border-color: $custom-color-3498DB;
      }
      &--error{
          border-color: $red;
      }
  }
  &__alert-message{
      position: absolute;
      font-size: 12px;
      padding-top: 5px;
      left: 131px;
      top: 26px;
  }

  &__field-dropdown {
      width: 310px;
      height: 28px;
      border: 1px solid $custom-color-C5C5C5;

      &:hover, &:focus {
          background-color: $custom-color-E3E3E3;
          border-color: $border-color-other;
      }
  }

  &__buttons-container {
      display: flex;
      justify-content: flex-end;
      height: 61px;
      padding: 10px;
      border-top: 1px solid $custom-color-C5C5C5;
  }

  &__button {
      &--dismiss{
          margin-left: 5px;
      }
  }
}

.compliance {
  &__dark-blue {
    .link-modal {
      &__alert-message {
        color: $red;
      }
    }
  }

  &__steel-blue {
    .link-modal {
      &__alert-message {
        color: $alert-color-2;
      }
    }
  }
}
