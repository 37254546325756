.assign-manager{
  &__form{
      height: 379px;
      position: relative;
      margin-left: 74px;
      width: calc(100% - 148px);
      margin-top: 57px;
  }
  &__info-text{
      margin-bottom: 30px;
  }
  &__block-buttons{
      display: block;
      width: 50%;
      padding-right: 72px;
      float: right;
      margin-top: 0;
      text-align: right;
  }
  &__query-box{
      display: block;
      width: 50%;
      float: left;
      padding-left: 71px;
      margin-top: 10px;
  }
  &__query-count{
      background: $third-color-icam;
      padding: 0px 11px 0px;
      color: $custom-black-landing;
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: bold;
      border-radius: 15px;
      display: inline-block;
      margin-right: 2px;
      font-style: normal;
      position: relative;
      bottom: 1px;
  }
  &__query-text{
      display: inline-block;
      color: $secondary-color-icam;
      font-family: 'MTTMilano-Bold', sans-serif;
      font-size: 10px;
      text-transform: uppercase;
  }
}

.compliance{
  &__dark-blue{
    .assign-manager{
        &__query-count{
          background: $subcontainer-border;
          color: $primary-color;
        }
    }
  }
}
