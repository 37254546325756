@mixin list-comparator-in-dropdown-block-participant($firstColor) {
  border: 1px solid $firstColor;
  border-top-width: 2px;
  padding-bottom: 0px;
}

.list-comparator-in-dropdown{
  &__block-participant{
      width: 100%;
      position: relative;
      height: 100%;
      float: left;
  }

  &__block-comparator{
      margin: 32px 10px 10px;
      width: 380px;
      height: 168px;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
  }

  &__block-list-repeat{
      width: 50%;
      &--no-border-left{
          border-left: none !important;
      }
  }

  &__icon-close {
      font-size: 10px;
      margin-right: 5px;
      margin-top: 5px;
      float: right;
      color: $primary-color;
      &:hover{
          cursor: pointer;
      }
  }

  &__title-list{
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      line-height: 27px;
      text-transform: uppercase;
      text-align: center;
      height: 27px;
  }

  &__list-elements{
      height: 139px;
      overflow-y: auto;
      text-align: center;
      padding: 8px 4px 0;
  }

  &__select-item{
      margin: 0;
      max-width: 100%;
      line-height: 32px;
      font-family: MTTMilano, sans-serif;
      font-size: 13px;
      font-weight: 500;
      color: $secondary-color;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }
}

.compliance{
  &__dark-blue{
    .list-comparator-in-dropdown{
      &__block-participant{
          border: 1px solid $primary-color;
          padding-bottom: 10px;
      }
      &__block-list-repeat{
          border: 1px solid $result-search-quote-color;
      }
      &__icon-close {
          color: $primary-color;
      }
      &__title-list{
          border-bottom: 1px solid $result-search-quote-color;
          color: $primary-color;
      }
    }
  }
  &__steel-blue, &__uk-steel-blue{
    .list-comparator-in-dropdown{
      &__block-participant{
          @include list-comparator-in-dropdown-block-participant($third-color-2);
      }
      &__block-list-repeat{
          border: 1px solid $third-color-2;
      }
      &__icon-close {
          color: $border-hover-2;
      }
      &__title-list{
          border-bottom: 1px solid $third-color-2;
          color: $primary-color-2;
      }
      &__select-item{
          color: $border-hover-2;
      }
    }
  }
  &__icam-red{
    .list-comparator-in-dropdown{
      &__block-participant{
          @include list-comparator-in-dropdown-block-participant($primary-color-icam);
      }
      &__block-list-repeat{
          border: 1px solid $third-color-icam;
      }
      &__icon-close {
          color: $primary-color-icam;
      }
      &__title-list{
          border-bottom: 1px solid $third-color-icam;
          color: $custom-black-landing;
      }
      &__select-item{
          color: $primary-color-icam;
      }
    }
  }
  &__gm-law{
    .list-comparator-in-dropdown{
      &__block-participant{
          @include list-comparator-in-dropdown-block-participant($primary-color-gm);
      }
      &__block-list-repeat{
          border: 1px solid $third-color-gm;
      }
      &__icon-close {
          color: $primary-color-gm;
      }
      &__title-list{
          border-bottom: 1px solid $third-color-gm;
          color: $custom-black-landing;
      }
      &__select-item{
          color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    .list-comparator-in-dropdown{
      &__block-participant{
          @include list-comparator-in-dropdown-block-participant($primary-color-com-de);
      }
      &__block-list-repeat{
          border: 1px solid $third-color-com-de;
      }
      &__icon-close {
          color: $primary-color-com-de;
      }
      &__title-list{
          border-bottom: 1px solid $third-color-com-de;
          color: $custom-black-landing;
      }
      &__select-item{
          color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .list-comparator-in-dropdown{
      &__block-participant{
          @include list-comparator-in-dropdown-block-participant($primary-color-com-fr);
      }
      &__block-list-repeat{
          border: 1px solid $third-color-com-fr;
      }
      &__icon-close {
          color: $primary-color-com-fr;
      }
      &__title-list{
          border-bottom: 1px solid $third-color-com-fr;
          color: $custom-black-landing;
      }
      &__select-item{
          color: $primary-color-com-fr;
      }
    }
  }
}
