@mixin template-box($backgroundColor, $boxShadowColor){
  border: none;
  border-left: 8px solid ;
  background-color: $backgroundColor;
  box-shadow: 0 0 5px 1px $boxShadowColor;
  padding-top: 1px;
}

@mixin border-box($gradientColor){
  background: linear-gradient($gradientColor);
  transform: rotate(180deg);
  width: 8px;
  position: absolute;
  left: -8px;
  top: 0px;
  height: 100%;
}

@mixin title-template($color, $borderColor){
  color: $color;
  border-bottom-color: $borderColor;
  font-size: 12px;
  font-weight: 500;
}

.concept-legal-documentation{
  &__cell-block{
      position: relative;
      float: left;
      max-width: calc(100% - 75px);
      height: 17px;
      top: 3px;
      font-family: Arial, sans-serif;
      font-size: 12px;
      &--right{
          float: right;
          padding-right: 27px;
      }
  }

  &__icon-eye{
      font-size: 22px;
      position: relative;
      bottom: 1px;
  }

  &__icons-reference-custom{
      font-size: 20px;
      margin-right: 7px;
      float: left;
      color: $attachment-custom-grey;
  }

  ////////////////////////////
  /////////////be////////////
  //////////////////////////
  // &__template-header-steel-blue{
  //     display: none;
  // }
  &__template-box {
      position: relative;
      margin: 35px 0 48px;
      &--min-height{
          height: 100px;
      }
      &--max-height{
          height: 225px;
      }
      &--expand {
          height: 600px;
      }
  }

  &__title-template{
      border-bottom: 1px solid;
      padding-bottom: 5px;
      margin: 54px 7px 0;
  }
  //////////////////////////////////////
  /////////////////Custom//////////////
  ////////////////////////////////////
  &__title-legal-custom{
      color: $custom-black-landing;
      font-family: 'lato_regular', sans-serif;
      margin: 0px;
      padding-left: 5px;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: bold;
      position: relative;
      width: calc(100% - 25px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }

  &__legal-item-custom{
      &--title-custom{
          color: $attachment-custom-grey;
          font-family: 'lato_regular', sans-serif;
          margin: 0px;
          padding-left: 5px;
          font-size: 14px;
      }
  }

  //////////////////////////////////////
  ///////////////Fin Custom////////////
  ////////////////////////////////////
}

.compliance{
  &__dark-blue{
      .concept-legal-documentation{
          &__template-box {
              border: 1px solid $box-border;
              border-top-width: 5px;
              border-top-color: $primary-color;
              //background-color: $background-grey-other;
              background-color: $result-search-quote-color;
              ::-webkit-scrollbar-track{
                  background: $webkit-scroll-legal-38;
              }

              ::-webkit-scrollbar-thumb{
                  background: $webkit-scroll-legal;
              }
          }
          &__border-box{
              display: none;
          }
          &__title-template{
              padding-left: 12px;
              text-transform: uppercase;
              border-bottom-color: $primary-color;
              font-weight: bold;
              color: $primary-color;
              font-size: 10px;
          }
      }
  }
  &__steel-blue,
  &__uk-steel-blue{
      .concept-legal-documentation{
          &__template-box {
              @include template-box($background-box-green-mid-2, $box-shadow-box-2);
          }
          &__border-box{
              @include border-box($steel-gradient-blue);
          }
          &__title-template{
              @include title-template($border-hover-2, $border-bottom-2);
          }
      }
  }
  &__icam-red{
      .concept-legal-documentation{
          &__template-box {
           @include template-box($background-box-icam, $border-buttons-icam)
          }
          &__border-box{
              @include border-box($gradient-icam-red-white);
          }
          &__title-template{
              @include title-template($primary-color-icam, $primary-color-icam);
          }
      }
  }
  &__gm-law{
      .concept-legal-documentation{
          &__template-box {
              @include template-box($background-box-gm, $border-buttons-gm)
          }
          &__border-box{
              @include border-box($gradient-gm-green-white);

          }
          &__title-template{
              @include title-template($primary-color-gm, $primary-color-gm);
          }
      }
  }
  &__com-de{
    .concept-legal-documentation{
        &__template-box {
            @include template-box($background-box-com-de, $border-buttons-com-de)
        }
        &__border-box{
            @include border-box($gradient-com-de-green-white);

        }
        &__title-template{
            @include title-template($primary-color-com-de, $primary-color-com-de);
        }
    }
  }
  &__com-fr{
    .concept-legal-documentation{
        &__template-box {
            @include template-box($background-box-com-fr, $border-buttons-com-fr)
        }
        &__border-box{
            @include border-box($gradient-com-fr-green-white);

        }
        &__title-template{
            @include title-template($primary-color-com-fr, $primary-color-com-fr);
        }
    }
  }
}

@media (max-width: $max-width-smartphone) { //Smartphone
  .concept-legal-documentation {
      &__cell-block{
          max-width: calc(100% - 150px);
      }
  }
}
/////////////////////////////////
//inc icam - MADiaz
