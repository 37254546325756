.add-assignment {
  & .modal-dialog {
      & .modal-content {
          height: 698px;
          border-radius: 0px;
      }
  }

  &__block-context {
      height: 698px;
      position: relative;
      float: left;
      width: 100%;
  }

  &__block-body {
      position: relative;
      float: left;
      padding: 0;
      font-size: 12px;
      margin: 0;
      width: 100%;
      height: 611px;
  }

  &__table-header{
      &--field-name{
          width: 47%;
      }
      &--field-name-tree{
          width: calc(100% - 150px);
      }
      &--localization{
          width: calc(53% - 70px);
      }
  }

  &__table{
      &--field-name{
          width: 47%;
          padding-right: 10px;
      }
      &--field-name-tree{
          width: calc(100% - 150px);
      }
      &--localization-name{
          width: calc(53% - 70px);
          padding-right: 10px;
      }
      &--icon-noeditable{
          width: 30px;
          .tooltip {
              &[data-popper-placement^='left'] {
                  width: 200px;
                  & > .tooltip-inner {
                      border-radius: 0;
                      border-width: 1px;
                      border-right-width: 5px;
                      text-align: left;
                      padding-left: 10px;
                  }
              }
          }
      }
  }

  &__icon-exange-noeditable{
      font-size: 20px;
      position: absolute;
      right: 6px;
      bottom: 12px;
  }

  &__icon-close-message{
      font-size: 15px;
      position: absolute;
      top: 20px;
      right: 10px;
      &:hover{
          cursor: pointer;
      }
  }

  &__select-list-with-input{
      top: 21px;
  }
}

.compliance {
  &__dark-blue {
    .add-assignment {
      &__icon-exange-noeditable{
        color: $subcontainer-ground;
      }
    }
  }

  &__steel-blue {
    .add-assignment {
      &__icon-exange-noeditable{
        color: $grey-icons-2;
      }
    }
  }
}
