.report-record-detail {
    &__block-container{
        position: relative;
        float: left;
        height: 820px;
        width: 100%;
        margin-bottom: 100px;
        border: 1px solid;
        padding: 10px;
        overflow: hidden;
        &--graph{
            height: auto;
        }

    }

    &__table-header{
        border-bottom: 1px solid;
        padding: 10px 72px 10px 0;
        display: table;
        min-height: 30px;
        height: 42px;
        width: 100%;
        position: relative;
        float: left;
    }

    &__header{
        width: 100%;
        height: 32px;
        margin: 10px 0 65px;

    }

    &__header-title{
        position: relative;
        float: left;
        bottom: 2px;
        font-family: MTTMilano-Bold, sans-serif;
        font-size: 25px;

    }

    &__header-block-buttons{
        position: relative;
        float: right;
    }

    &__header-button{
        border: none;
        outline: none;
        background-color: transparent;
        color: $primary-color;
        font-family: MTTMilano, sans-serif;
        font-size: 16px;
        &--no-first{
            margin-left: 15px;
        }
        &--disabled{
          opacity: 0.5
        }
    }

    &__text-option-view{
        position: relative;
        float: left;
    }

    &__bubble-count {
        height: 15px;
        min-width: 26px;
        background: $pillow-group;
        color: $snow-white;
        border-radius: 20px;
        font-family: Arial, sans-serif;
        font-size: 11px;
        font-weight: bold;
        font-style: normal;
        text-align: center;
        position: relative;
        float: left;
        padding: 0 7px;
        top: 2px;
        left: 3px;

    }
    &__icon-button-header{
        font-size: 20px;
        position: relative;
        top: 5px;
        margin-left: 4px;
    }

    &__table-block{
        margin-top: 27px;
        width: 100%;
    }

    &__table{
        display: table;
        width: 100%;
        padding: 0;
    }

    &__table-body{
        display: block;
        width: 100%;
        min-height: 631px;
        max-height: 631px;
        overflow: hidden;
        overflow-y: auto;
        &--scrollbar{
            width: calc(100% + 10px);
        }
    }

    &__table-row{
        display: table;
        width: 100%;
        height: 105px;
        &--documentation{
            height: 45px;
        }
    }
    &__rows{
        position: relative;
    }

    &__col{
        display: table-cell;
        text-align: left;
        padding-right: 5px;
        position: relative;
        &--body{
            padding: 12px 15px 12px 0;
        }
        &--first{
            padding-left: 8px;
        }
        &--detail{
            width: calc(56% - 430px);
        }
        &--detail-global{
            width: calc(52% - 335px);
        }
        &--organization{
            width: 12%;
        }
        &--date{
            width: 180px;
            max-width: 180px;
        }
        &--date-global{
            width: 130px;
            max-width: 130px;
        }
        &--tracing{
            width: 27%;
        }
        &--tracing-global{
            width: 16%;
        }
        &--resolution{
            width: 17%;
        }
        &--resolution-global{
            width: 10%;
        }
        &--date-resolution{
            width: 180px;
            max-width: 180px;
        }
        &--date-resolution-global{
            width: 135px;
            max-width: 135px;
        }
        &--title-documentation{
            width: 350px;
        }
        &--exportation-date{
            width: 300px;
        }
        &--exportation-date-header{
            width: 300px;
            padding-top: 1px;
        }
        &--author{
            width: 250px;
        }
        &--actions{
            vertical-align: top;
            width: 70px;
            padding: 0;
            height: calc(100% + 3px);
            position: absolute;
            right: 0;
            z-index: 10;
        }
        &--actions-documentation{
            text-align: right;
            padding: 0;
        }
    }

    &__col-actions{
        box-shadow: 0px 5px 12px $bar-horizontal-grey-opacity04;
        background-color: $background-grey-other;
        border: 1px solid $subcontainer-border;
        padding: 0;
        width: 60px;
        text-align: center;
        height: 45px;
        position: absolute;
        right: 12px;

        @supports(-moz-appearance:none) {
            right: 10px !important;
        }
    }

    &__col-actions-body{
        border-left: 1px solid $subcontainer-border;
        border-right: 1px solid $subcontainer-border;
        background-color: $background-grey-other;
        padding: 0;
        width: 60px;
        text-align: center;
        height: 100%;
        position: relative;
    }

    &__col-actions-body-shadow{
        position: absolute;
        right: 2px;
        width: 60px;
        height: 100%;
        box-shadow: 1px 13px 12px -1px rgba(86, 86, 86, 0);
        &--more{
          right: 12px;
        }
    }

    &__cell-block{
        position: relative;
        float: left;
        max-height: 66px;
        overflow: hidden;
    }

    &__table-header-action{
        top: 11px;
    }

    &__col-text{
        color: $secondary-color;
        font-family: Arial, sans-serif;
        font-size: 12px;
    }

    &__col-text-tooltip{
        position: relative;
        // line-height: 16px;
        // margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        color: $secondary-color;
        font-family: Arial, sans-serif;
        font-size: 12px;

    }

    &__icon-action-body{
        font-size: 25px;
        position: relative;
        top: 8px;
        color: $primary-color;
        &:hover{
            cursor: pointer;
            &::before{
                content: '\e9b3';
                font-size: 21px;
            }
        }

    }

    &__icon-action-body-documentation{
        font-size: 26px;
        position: relative;
        top: 7px;
        color: $primary-color;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        &:hover{
            cursor: pointer;
        }
    }

    &__list-menu-expanded{
        margin: 0;
        transform: none !important;
        inset: initial !important;
        left: initial !important;
        right: 30px !important;
        top: 11px !important;
        margin-right: 2px;
        width: 207px;
        border: 1px solid $primary-color;
        background-color: $snow-white;
        position: absolute;
        padding: 0;
        z-index: 2;
        border-radius: 0px;
        float: none;
    }
    &__rows-block{
        display: table;
        width: calc(100% - 60px);
    }
    &__table-col{
        display: table-cell;
    }
    &__date-text{
      width: calc(100% - 18px);
      float: left;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 28px;
    }
    &__subtitle-report-record-archived{
      top: 12px;
    }
}

.compliance{
    &__dark-blue{
        .report-record-detail {
            &__block-container{
                border-color: $result-search-quote-color;
            }
            &__table-header{
                border-color: $primary-color;
                color: $primary-color;
            }
            &__header{
                color: $pillow-group;
            }
            &__bubble-count {
                background-color: $pillow-group;
                color: $snow-white;
            }
            &__table-row{
                &:hover{
                    background-color: $background-color-states;
                }
            }
            &__col{
                &--header-border{
                    vertical-align: middle;
                }
                &--body-border{
                    border-bottom: 1px solid $result-search-quote-color;
                }
            }
        }
    }
    &__steel-blue{
        .report-record-detail {
            &__block-container{
                border-color: $background-highlight-text-2;
            }
            &__table-header{
                border-color: $border-hover-2;
                color: $border-hover-2;
            }
            &__header{
                color: $primary-color-2;
            }
            &__table-row{
                &:hover {
                    background-color: $third-color-rgba-mid;
                }
            }
            &__col{
                &--header-border{
                    border-bottom: 1px solid $background-highlight-text-2;
                    vertical-align: middle;
                }
                &--body-border{
                    border-bottom: 1px solid $secondary-color-2;
                }
            }
            &__col-text{
                color: $primary-color-2;
            }
            &__col-text-tooltip{
                color: $primary-color-2;
            }
            &__icon-action-body{
                color: $border-hover-2;
            }
            &__icon-action-body-documentation{
                color: $border-hover-2;
            }
            &__list-menu-expanded{
                border-color: $third-color-2;
            }
        }
    }
    &__gm-law{
      .report-record-detail{
        &__table-row{
          border-bottom: 1px solid $border-block-gm;
        }
        &__subtitle-al{
          color: $secondary-color-gm;
        }
        &__block-container{
            border: none;
            box-shadow: 0 0 6px 2px #515e6733;
            background: $snow-white;
        }
        &__header-title{
            color: #8999b8;
        }
        &__table{
            border: none;
        }
      }
    }
    &__com-de{
      .report-record-detail{
        &__table-row{
          border-bottom: 1px solid $border-block-com-de;
        }
        &__subtitle-al{
          color: $secondary-color-com-de;
        }
      }
    }
    &__com-fr{
      .report-record-detail{
        &__table-row{
          border-bottom: 1px solid $border-block-com-fr;
        }
        &__subtitle-al{
          color: $secondary-color-com-fr;
        }
      }
    }
}

@media (min-width: 1260px) and (max-width: 1430px){
    .report-record-detail {
        &__col{
            &--detail{
                width: calc(56% - 380px);
            }
            &--date{
                width: 150px;
                max-width: 150px;
            }
            &--tracing{
                width: 28%;
            }
            &--resolution{
                width: 16%;
            }
            &--date-resolution{
                width: 160px;
                max-width: 160px;
            }
        }
    }
}

@media (min-width: 1090px) and (max-width: 1260px){
    .report-record-detail {
        &__col{
            &--detail{
                width: calc(58% - 340px);
            }
            &--date{
                width: 120px;
                max-width: 120px;
            }
            &--tracing{
                width: 28%;
            }
            &--resolution{
                width: 14%;
            }
            &--date-resolution{
                width: 150px;
                max-width: 150px;
            }
        }
    }
}

@media (min-width: 965px) and (max-width: 1090px){
    .report-record-detail {
        &__col{
            &--body{
                padding-right: 10px;
            }
            &--detail{
                width: calc(62% - 320px);
            }
            &--date{
                width: 120px;
                max-width: 120px;
            }
            &--tracing{
                width: 25%;
            }
            &--resolution{
                width: 13%;
            }
            &--date-resolution{
                width: 130px;
                max-width: 130px;
            }
            &--exportation-date{
                width: 240px;
            }
        }
    }
}

@media (max-width: 965px){
    .report-record-detail {
        &__col{
            &--body{
                padding-right: 10px;
            }
            &--detail{
                width: calc(62% - 250px);
            }
            &--date{
                width: 90px;
                max-width: 90px;
            }
            &--tracing{
                width: 25%;
            }
            &--resolution{
                width: 13%;
            }
            &--date-resolution{
                width: 90px;
                max-width: 90px;
            }
            &--exportation-date{
                width: 240px;
            }
        }
    }
}

.report-record-detail-body{
    .tooltip{
        max-width: 400px !important;
    }
}
