
.input-date{
  display: flex;
  position: relative;
  width: 100%;
  padding-left: 10px;
  &__date{
      border: none;
      background: transparent;
      display: inline-block;
      outline: none;
      position: relative;
      color: inherit;
      padding: 1px 0;
      text-align: center;
      &--day{
          width: 16px;
      }
      &--month{
          width: 23px;
      }
      &--year{
          width: 28px;
      }
      &--hours{
          width: 17px;
      }
      &--minutes{
          width: 16px;
          right: 8px;
      }
      &--separator{
          right: 4px;
          bottom: 1px;
          width: 8px;
      }
      &--day-one-digit{
          width: 7px;
      }
      &--day-two-digits{
          width: 17px;
      }
      &--month-one-digit{
          width: 7px;
      }
      &--month-two-digits{
          width: 17px;
      }
      &--error{
          color: $alert-color;
      }

  }
  &__time-block{
      display: inline-flex;
      position: relative;
      color: inherit;
      margin-left: 3px;
      &--sequential{
          margin-left: 8px;
      }
  }

  &__erase-button-icon{
      font-size: 9px;
      position: relative;
      right: 4px;
      top: 0px;
      color: inherit;
  }
}
.compliance{
  &__steel-blue{
    .input-date{
      &__date{
        &--error{
          color: $alert-color-2;
        }
      }
    }
  }
  &__landing-custom{
    .input-date{
      &__date{
        &--month-sequential{
            width: 26px;
        }
        &--year-sequential{
            width: 38px;
        }
        &--year{
          width: 32px;
        }
        &--hours{
            width: 19px;
        }
        &--minutes{
            width: 19px;
            right: 7px;
        }
      }
    }
  }
}
