@mixin history-viewer-block-header-group-field($backgroundColor, $color){
  background-color: $backgroundColor;
  color: $color;
  text-transform: none;
  height: 34px;
}

.history-viewer {
  position: relative;

  &__block-header {
      display: block;
      width: 100%;
      min-height: 95px;
      background-color: $snow-white;
      padding: 25px 20px 5px 16px;
  }

  &__block-logo {
      position: relative;
      float: left;
      width: 245px;
      height: 40px;
      margin-top: 2px;
  }

  &__logo-img {
      height: 100%;
  }

  &__block-header-modified {
      position: relative;
      float: right;
      max-width: calc(100% - 245px);
      padding: 15px 0 0 0;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      text-align: right;
      color: $concept-blue;
  }

  &__text-modified-big {
      font-size: 15px;
      margin: 0 2px 0 4px;
  }

  &__text-modified-by{
      margin-left: 3px;
  }

  &__block-breadcrumb {
      min-height: 40px;
      padding: 2px 16px;
      width: 100%;
      position: relative;
      float: left;
      display: flex;
      align-items: center;
      flex-direction: row;
      background-color: $background-color-states;
  }

  &__breadcrumb-block {
      padding: 0px;
      float: left;
      width: 100%;
      margin-bottom: 0;
  }

  &__breadcrumb-item {
      position: relative;
      float: left;
      height: 20px;
      margin: 2px 0;
  }

  &__breadcrumb-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      width: 100%;
      font-family: MTTMilano, sans-serif;
      padding: 0px 20px 0 7px;
      font-size: 13px;
      position: relative;
      line-height: 23px;
  }

  &__icon-angle-right {
      position: absolute;
      font-size: 20px;
      right: -4px;
  }

  &__block-all-body {
      width: 100%;
      position: relative;
      float: left;
      padding: 30px 0px 100px;
      min-height: calc(100% - 335px);
  }

  &__block-body {
      max-width: 1020px;
      padding: 0 10px;
      margin: 0px auto;
  }

  &__block-header-body {
      width: 100%;
      position: relative;
      display: inline-block;
      height: 28px;
  }

  &__block-search {
      width: calc(100% - 435px);
      &--open {
          position: fixed;
          width: 570px;
          z-index: 100;
      }
      &--floating {
          top: 25px;
      }
  }

  &__search-input {
      padding-right: 170px;
  }

  &__block-info-search {
      position: absolute;
      right: 40px;
      top: 0;
      padding-top: 5px;
  }

  &__block-count-search {
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      margin-right: 20px;
      position: relative;
      float: left;
      line-height: 23px;
      color: $pillow-group;
  }

  &__block-arrow-buttons {
      display: inline-block;
      padding-left: 20px;
      height: 20px;
      border-left: 1px solid $result-search-quote-color;
  }

  &__button-arrow-search {
      font-size: 20px;
      position: relative;
      padding: 0;
      border: none;
      outline: none;
      background: none;
      &[disabled] {
          opacity: 0.5;
      }
      &--down {
          margin-left: 5px;
      }
  }

  &__block-buttons-header-body {
      position: relative;
      float: right;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      top: 5px;
      color: $primary-color;
  }

  &__header-button {
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0;
      margin: 0 0 0 24px;
      line-height: 12px;
  }

  &__header-button-icon {
      font-size: 20px;
      margin-left: 4px;
  }

  &__header-button-text {
      position: relative;
      bottom: 5px;
  }

  &__block-form {
      margin-top: 16px;
      position: relative;
      float: left;
      width: 100%;
  }

  &__block-header-group {
      height: 30px;
      width: 100%;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      color: $snow-white;
      background-color: $primary-color;
      display: table;
      padding: 2px 0 0 9px;
  }

  &__text-header-group {
      display: table-cell;
      vertical-align: middle;
  }

  &__block-group {
      position: relative;
      padding: 0 0 30px 10px;
      border-left: 1px solid $box-comment-border-03;
      &--subgroup {
          top: 25px;
      }
      &--field {
          padding: 0 0 15px 10px;
          width: calc(100% + 10px);
          right: 10px;
      }
  }

  &__icon-arrow-round {
      position: absolute;
      left: -8px;
      top: 9px;
      color: $primary-color;
      &:hover {
          cursor: pointer;
      }
      &--field {
          top: 11px;
      }
  }

  &__block-form-group {
      padding: 20px 0 0px 9px;
  }

  &__block-form-field {
      padding: 15px 0 0px 9px;
  }

  &__block-fields-group {
      display: table;
      width: 100%;
      padding-bottom: 8px;
      &--field {
          padding-bottom: 15px;
      }
      &--big-padding {
          padding-bottom: 30px;
      }
      &--last-field {
          padding-bottom: 0;
      }
  }

  &__block-cell-label {
      display: table-cell;
      vertical-align: top;
      width: 40%;
      padding: 8px 10px 0 0;
      &--padding-0 {
          padding-top: 0;
      }
  }

  &__block-cell-input {
      display: table-cell;
      vertical-align: text-top;
      width: 60%;
  }

  &__block-cell-inner-input-field {
      margin-left: 15px;
  }

  &__block-cell-inner-input-subfield {
      margin-left: 3px;
  }

  &__block-cell-label-field {
      display: table-cell;
      vertical-align: top;
      width: 35%;
  }

  &__block-cell-input-field {
      display: table-cell;
      vertical-align: text-top;
      width: 65%;
  }

  &__text-label {
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      line-height: 18px;
      color: $pillow-group;
  }

  &__text-value {
      min-height: 34px;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      color: $primary-color;
      display: block;
      position: relative;
      padding: 8px 10px 6px;
      background-color: $background-color-states;
      &--list {
          margin-bottom: 2px;
      }
  }

  &__text-value-italic {
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      font-style: italic;
      font-weight: 500;
      line-height: 18px;
      padding-right: 12px;
      color: $pillow-group;
  }

  &__text-value-user {
      position: relative;
      float: left;
      width: 37%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-right: 3px;
      min-height: 18px;
      &--mail {
          width: 26%;
      }
  }

  &__side-band-color {
      position: absolute;
      width: 5px;
      height: 100%;
      left: 0px;
      top: 0px;
  }

  &__text-searched {
      color: $alert-color;
      display: inline;
  }

  &__selected-search {
      background: rgba(250, 230, 30, 0.4);
      // background: $highlight-icon;
  }
}

.compliance{
  &__dark-blue{
    .history-viewer{
      &__breadcrumb-item {
          color: $primary-color;
          &--active{
              color: $pillow-group;
          }
      }
      &__button-arrow-search {
          color: $primary-color;
      }
      &__block-header-group {
          &--subgroup {
              background-color: $pillow-group;
          }
          &--field {
              @include history-viewer-block-header-group-field($background-color-states, $primary-color);
          }
      }
      &__icon-arrow-round {
          &--subgroup{
              color: $pillow-group;
          }
      }
      &__text-value {
          &--disabled {
              background-color: $background-grey;
              color: $attach-icon-grey;
          }
      }
      &__side-band-color {
          &--green{
              background-color: $green;
          }
          &--red{
              background-color: $red;
          }
          &--yellow{
              background-color: $yellow;
          }
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue{
    .history-viewer{
      &__block-header-modified {
          color: $primary-color-2;
      }
      &__block-breadcrumb {
          background-color: $third-color-2;
      }
      &__breadcrumb-item {
          color: $primary-color-2;
          &--active{
              color: $border-bottom-2;
          }
      }
      &__icon-angle-right {
          color: $primary-color-2;
      }
      &__block-count-search {
          color: $primary-color-2;
      }
      &__block-arrow-buttons {
          border-color: $border-hover-2;
      }
      &__button-arrow-search {
          color: $border-hover-2;
      }
      &__block-buttons-header-body {
          color: $border-hover-2;
      }
      &__block-header-group {
          background-color: $primary-color-2;
          &--subgroup{
              background-color: $grey-icons-2;
          }
          &--field{
              @include history-viewer-block-header-group-field($block-hover-2, $primary-color-2);
          }
      }
      &__block-group {
          border-color: $background-grey-disabled-2;
      }
      &__icon-arrow-round {
          color: $border-hover-2;
          &--subgroup{
              color: $border-hover-2;
          }
      }
      &__text-label {
          color: $grey-icons-2;
      }
      &__text-value {
          background-color: $block-hover-2;
          color: $primary-color-2;
          &--disabled {
              background-color: $background-grey-disabled-2;
              color: $text-field-color-2;
          }
      }
      &__text-value-italic {
          color: $grey-icons-2;
      }
      &__side-band-color {
          &--green{
              background-color: $green-color-v2;
          }
          &--red{
              background-color: $alert-color-2;
          }
          &--yellow{
              background-color: $warning-yellow;
          }
      }
      &__text-searched {
          color: $alert-color-2;
      }
      &__selected-search {
          background-color: $warning-background-4;
      }
    }
  }
  &__icam-red{
    .history-viewer{
      &__block-logo {
          width: 210px;
          height: 100%;
          margin: 0px;
          bottom: 14px;
      }
      &__block-header-modified {
          color: $text-field-color-icam;
      }
      &__block-breadcrumb {
          background-color: $third-color-icam;
      }
      &__breadcrumb-item {
          color: $primary-color-icam;
          &--active{
              color: $title-text-icam;
          }
      }
      &__block-count-search {
          color: $title-text-icam;
      }
      &__block-arrow-buttons {
          border-color: $primary-color-icam;
      }
      &__button-arrow-search {
          color: $primary-color-icam;
      }
      &__block-buttons-header-body {
          color: $primary-color-icam;
      }
      &__block-header-group {
          background-color: $custom-black-landing;
          &--subgroup {
              background-color: $text-field-color-icam;
          }
          &--field {
              @include history-viewer-block-header-group-field($hover-block-icam, $custom-black-landing);
          }
      }
      &__block-group {
          border-color: $background-grey-disabled-icam;
      }
      &__icon-arrow-round {
          color: $primary-color-icam;
          &--subgroup{
              color: $primary-color-icam;
          }
      }
      &__text-label {
          color: $text-field-color-icam;
      }
      &__text-value {
          background-color: $hover-block-icam;
          color: $custom-black-landing;
          &--disabled{
              background-color: $background-grey-disabled-icam;
              color: $text-field-color-icam;
          }
      }
      &__text-value-italic {
          color: $text-field-color-icam;
      }
      &__side-band-color {
          &--green {
              background-color: $green-task-icam;
          }
          &--red {
              background-color: $alert-color-icam;
          }
          &--yellow{
              background-color: $yellow;
          }
      }
      &__text-searched {
          color: $alert-color-icam;
      }
      &__selected-search {
          background-color: $yellow-task-40-icam;
      }
    }
  }
  &__gm-law{
    .history-viewer{
      &__block-text {
          color: $secondary-color-gm;
          margin-top: 9px;
          font-size: 9px;
          font-family: MTTMilano, sans-serif;
      }
      &__block-header-modified {
          color: $text-field-color-gm;
      }
      &__block-breadcrumb {
          background-color: $third-color-gm;
      }
      &__breadcrumb-item {
          color: $primary-color-gm;
          &--active{
              color: $title-text-gm;
          }
      }
      &__block-count-search {
          color: $title-text-gm;
      }
      &__block-arrow-buttons {
          border-color: $primary-color-gm;
      }
      &__button-arrow-search {
          color: $primary-color-gm;
      }
      &__block-buttons-header-body {
          color: $primary-color-gm;
      }
      &__block-header-group {
          background-color: $custom-black-landing;
          &--subgroup {
              background-color: $text-field-color-gm;
          }
          &--field {
              @include history-viewer-block-header-group-field($hover-block-gm, $custom-black-landing);
          }
      }
      &__block-group {
          border-color: $background-grey-disabled-gm;
      }
      &__icon-arrow-round {
          color: $primary-color-gm;
          &--subgroup{
              color: $primary-color-gm;
          }
      }
      &__text-label {
          color: $text-field-color-gm;
      }
      &__text-value {
          background-color: $hover-block-gm;
          color: $custom-black-landing;
          &--disabled{
              background-color: $background-grey-disabled-gm;
              color: $text-field-color-gm;
          }
      }
      &__text-value-italic {
          color: $text-field-color-gm;
      }
      &__side-band-color {
          &--green {
              background-color: $green-task-gm;
          }
          &--red {
              background-color: $alert-color-gm;
          }
          &--yellow{
              background-color: $yellow;
          }
      }
      &__text-searched {
          color: $alert-color-gm;
      }
      &__selected-search {
          background-color: $yellow-task-40-gm;
      }
    }
  }
  &__com-de{
    .history-viewer{
      &__block-text {
          color: $secondary-color-com-de;
          margin-top: 9px;
          font-size: 9px;
          font-family: MTTMilano, sans-serif;
      }
      &__block-header-modified {
          color: $text-field-color-com-de;
      }
      &__block-breadcrumb {
          background-color: $third-color-com-de;
      }
      &__breadcrumb-item {
          color: $primary-color-com-de;
          &--active{
              color: $title-text-com-de;
          }
      }
      &__block-count-search {
          color: $title-text-com-de;
      }
      &__block-arrow-buttons {
          border-color: $primary-color-com-de;
      }
      &__button-arrow-search {
          color: $primary-color-com-de;
      }
      &__block-buttons-header-body {
          color: $primary-color-com-de;
      }
      &__block-header-group {
          background-color: $custom-black-landing;
          &--subgroup {
              background-color: $text-field-color-com-de;
          }
          &--field {
              @include history-viewer-block-header-group-field($hover-block-com-de, $custom-black-landing);
          }
      }
      &__block-group {
          border-color: $background-grey-disabled-com-de;
      }
      &__icon-arrow-round {
          color: $primary-color-com-de;
          &--subgroup{
              color: $primary-color-com-de;
          }
      }
      &__text-label {
          color: $text-field-color-com-de;
      }
      &__text-value {
          background-color: $hover-block-com-de;
          color: $custom-black-landing;
          &--disabled{
              background-color: $background-grey-disabled-com-de;
              color: $text-field-color-com-de;
          }
      }
      &__text-value-italic {
          color: $text-field-color-com-de;
      }
      &__side-band-color {
          &--green {
              background-color: $green-task-com-de;
          }
          &--red {
              background-color: $alert-color-com-de;
          }
          &--yellow{
              background-color: $yellow;
          }
      }
      &__text-searched {
          color: $alert-color-com-de;
      }
      &__selected-search {
          background-color: $yellow-task-40-com-de;
      }
    }
  }
  &__com-fr{
    .history-viewer{
      &__block-text {
          color: $secondary-color-com-fr;
          margin-top: 9px;
          font-size: 9px;
          font-family: MTTMilano, sans-serif;
      }
      &__block-header-modified {
          color: $text-field-color-com-fr;
      }
      &__block-breadcrumb {
          background-color: $third-color-com-fr;
      }
      &__breadcrumb-item {
          color: $primary-color-com-fr;
          &--active{
              color: $title-text-com-fr;
          }
      }
      &__block-count-search {
          color: $title-text-com-fr;
      }
      &__block-arrow-buttons {
          border-color: $primary-color-com-fr;
      }
      &__button-arrow-search {
          color: $primary-color-com-fr;
      }
      &__block-buttons-header-body {
          color: $primary-color-com-fr;
      }
      &__block-header-group {
          background-color: $custom-black-landing;
          &--subgroup {
              background-color: $text-field-color-com-fr;
          }
          &--field {
              @include history-viewer-block-header-group-field($hover-block-com-fr, $custom-black-landing);
          }
      }
      &__block-group {
          border-color: $background-grey-disabled-com-fr;
      }
      &__icon-arrow-round {
          color: $primary-color-com-fr;
          &--subgroup{
              color: $primary-color-com-fr;
          }
      }
      &__text-label {
          color: $text-field-color-com-fr;
      }
      &__text-value {
          background-color: $hover-block-com-fr;
          color: $custom-black-landing;
          &--disabled{
              background-color: $background-grey-disabled-com-fr;
              color: $text-field-color-com-fr;
          }
      }
      &__text-value-italic {
          color: $text-field-color-com-fr;
      }
      &__side-band-color {
          &--green {
              background-color: $green-task-com-fr;
          }
          &--red {
              background-color: $alert-color-com-fr;
          }
          &--yellow{
              background-color: $yellow;
          }
      }
      &__text-searched {
          color: $alert-color-com-fr;
      }
      &__selected-search {
          background-color: $yellow-task-40-com-fr;
      }
    }
  }
  &__landing-custom{
    .history-viewer{
      &__block-header-modified {
          color: $custom-black-landing;
      }
      &__block-breadcrumb {
          background-color: $background-grey;
      }
      &__breadcrumb-item {
          color: $custom-black-landing;
          &--active-landing-custom {
              color: $custom-secondary-color;
          }
      }
      &__block-all-body {
          min-height: calc(100% - 268px);
      }
      &__block-count-search {
          color: $custom-secondary-color;
      }
      &__block-arrow-buttons {
          border-color: $custom-black-landing;
      }
      &__block-buttons-header-body {
          color: $custom-black-landing;
      }
      &__block-header-group {
          background-color: $custom-secondary-color;
          &--subgroup {
              background-color: $custom-border-grey;
              color: $attachment-custom-grey;
          }
          &--field {
              @include history-viewer-block-header-group-field($background-grey, $custom-black-landing);
          }
      }
      &__block-group {
          border-color: $custom-border-grey;
      }
      &__icon-arrow-round {
          color: $custom-black-landing;
          &--subgroup{
              color: $custom-black-landing;
          }
      }
      &__text-label {
          color: $attachment-custom-grey;
      }
      &__text-value {
          background-color: $background-grey;
          color: $custom-black-landing;
          &--disabled{
              background-color: $attachment-custom-grey-other-2;
              color: $custom-secondary-color;
          }
      }
      &__text-value-italic {
          color: $custom-secondary-color;
      }
      &__side-band-color {
          &--green{
              background-color: $green;
          }
          &--red {
              background-color: $red;
          }
          &--yellow {
              background-color: $yellow;
          }
      }
    }
  }
}

@media (max-width: 920px) {
  .history-viewer{
      &__block-header-modified{
          max-width: 100%;
          width: 100%;
          float: left;
          padding-left: 6px;
          text-align: left;
      }
  }
}

@media print {
  .history-viewer{
      padding: 0 2px;
      &__block-header-modified{
          max-width: 100%;
          width: 100%;
          float: left;
          padding-left: 6px;
          text-align: left;
      }
      &__block-all-body{
          padding: 5px 0 10px;
      }
      &__cascade{
          margin: 0;
      }
      &__block-header-body{
          display: none;
      }
      &__block-breadcrumb{
          box-shadow: 0 0 3px 1px $bar-horizontal-grey-opacity02 !important;
          margin: 5px 0 8px;
      }
      &__cascade-element-title{
          box-shadow: 0 0 3px 1px $bar-horizontal-grey-opacity02 !important;
      }
  }
}
