.calendar-month-year{
  &__block-header-calendar{
      height: 18px;
      position: relative;
  }
  &__header-text{
      position: relative;
      float: left;
      width: calc(100% - 42px);
      text-align: center;
  }
  &__block-arrow{
      position: relative;
      float: left;
      width: 21px;
  }
  &__button-angle{
      padding: 0 2px 0;
      color: $custom-black-landing;
      border: none;
      background: none;
      outline: none;
      position: relative;
      font-size: 15px;
  }

  &__row{
      display: inline-block;
      text-align: center;
      width: 33%;
  }

  &__button{
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      height: 37px;
      width: calc(100% - 10px);
      border: 1px solid ;
      background-color: transparent;
      margin: 5px;
      padding: 0;
      &--from-to-calendar{
        height: 26px;
      }
  }

}

.compliance{
  &__dark-blue{
      .calendar-month-year{
          &__button-angle{
              color: $primary-color;
          }
          &__button{
              border-color: $result-search-quote-color;
              color: $concept-blue;
              &--actual{
                  border: 2px solid $primary-color;
              }
              &--active{
                  background-color: $primary-color;
                  border-color: $primary-color;
                  color: $snow-white;
              }
              &:hover{
                  background-color: $background-color-states;
                  color: $concept-blue;
              }
          }
      }
  }
  &__icam-red{
      .calendar-month-year{
          &__button-angle{
              color: $primary-color-icam;
          }
          &__button{
              border-color: $border-block-icam;
              color: $text-field-color-icam;
              &--actual{
                  border: 2px solid $border-block-icam;
              }
              &--active{
                  background-color: $primary-color-icam;
                  border-color: $primary-color-icam;
                  color: $snow-white;
              }
              &:hover{
                  background-color: $hover-block-icam;
                  border-color: $hover-block-icam;
                  color: $text-field-color-icam;
              }
          }

      }
  }
  &__steel-blue, &__uk-steel-blue{
      .calendar-month-year{
          &__button-angle{
              color: $border-hover-2;
          }
          &__button{
              border-color: $secondary-color-2;
              color: $border-bottom-2;
              &--actual{
                  border: 2px solid $border-hover-2;
              }
              &--active{
                  background-color: $border-hover-2;
                  border-color: $border-hover-2;
                  color: $snow-white;
              }
              &:hover{
                  background-color: $third-color-2;
                  color: $border-bottom-2;
              }
          }
      }
  }
  &__gm-law{
      .calendar-month-year{
          &__button-angle{
              color: $primary-color-gm;
          }
          &__button{
              border-color: $border-block-gm;
              color: $text-field-color-gm;
              &--actual{
                  border: 2px solid $border-block-gm;
              }
              &--active{
                  background-color: $primary-color-gm;
                  border-color: $primary-color-gm;
                  color: $snow-white;
              }
              &:hover{
                  background-color: $hover-block-gm;
                  border-color: $hover-block-gm;
                  color: $text-field-color-gm;
              }
          }
      }
  }
  &__com-de{
    .calendar-month-year{
        &__button-angle{
            color: $primary-color-com-de;
        }
        &__button{
            border-color: $border-block-com-de;
            color: $text-field-color-com-de;
            &--actual{
                border: 2px solid $border-block-com-de;
            }
            &--active{
                background-color: $primary-color-com-de;
                border-color: $primary-color-com-de;
                color: $snow-white;
            }
            &:hover{
                background-color: $hover-block-com-de;
                border-color: $hover-block-com-de;
                color: $text-field-color-com-de;
            }
        }
    }
  }
  &__com-fr{
    .calendar-month-year{
        &__button-angle{
            color: $primary-color-com-fr;
        }
        &__button{
            border-color: $border-block-com-fr;
            color: $text-field-color-com-fr;
            &--actual{
                border: 2px solid $border-block-com-fr;
            }
            &--active{
                background-color: $primary-color-com-fr;
                border-color: $primary-color-com-fr;
                color: $snow-white;
            }
            &:hover{
                background-color: $hover-block-com-fr;
                border-color: $hover-block-com-fr;
                color: $text-field-color-com-fr;
            }
        }
    }
  }
  &__landing-custom{
      .calendar-month-year{
          &__button-angle{
              color: $custom-black-landing;
          }
          &__button{
              font-family: 'lato_regular', sans-serif;
              height: 49px;
              border-color: $background-grey;
              color: $custom-black-landing;
              &--from-to-calendar{
                height: 26px;
              }
              &--actual{
                  border: 2px solid transparent;
              }
              &--active{
                  background-color: $custom-black-landing;
                  color: $snow-white;
              }
              &:hover{
                  background: $custom-border-grey;
                  color: $custom-black-landing;
              }
          }
      }
  }
}
