@mixin list-event($textColor, $hoverIconBackgroundColor) {
  color: $textColor;
  &:hover{
      .schedule__multiselect-icon{
          background-color: $hoverIconBackgroundColor;
      }
  }
}

@mixin multiselect-icon($textColor, $backgroundHoverColor) {
  color: $textColor;
  &:hover{
      background-color: $backgroundHoverColor;
  }
}

@mixin calendar-subtitle($primaryColor) {
  color: $primaryColor;
  font-family: MTTMilano, sans-serif;
  font-size: 16px;
  margin-top: 25px;
}

.schedule {
  height: 818px;
  position: relative;
  padding: 10px;
  &--window{
    height: auto;
    & .fc {
      & .fc-dayGridMonth-view{
        & .fc-scrollgrid-sync-table{
          & tr{
              height: 168px;
          }
        }
      }
    }
  }

  &__buttons-views{
      top: 7px;
      margin-right: 0px;
      position: relative;
  }

  &__circles-steps{
      border-radius: 50%;
      display: inline-block;

      height: 15px;
      width: 15px;
      margin: 5px;
      &--disabled{
          background-color: $circle-steps-color;
      }
  }

  &__block-steps-circles{
      position: absolute;
      right: 30px;
      bottom: 10px;
  }

  &__block-reminders-parragraph{
      width: 100%;
      position: relative;
      float: left;
      font-family: Arial, sans-serif;
      font-size: 12px;
      margin-top: 12px;
  }

  &__block-reminders-dates{
      height: 24px;
      width: 129px;
      background-color: $event-big-when;
      margin-right: 2px;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: bold;
      position: relative;
      float: left;
      padding: 2px 6px;
  }

  &__text-parragraph{
      margin-bottom: 2px;
      line-height: 16px;
  }

  &__firm-parragraph{
      font-size: 11px;
      font-weight: bold;
  }

  &__icon-calendar{
      position: relative;
      font-size: 15px;
      margin-right: 3px;
      margin-left: 3px;
      top: 2px;
  }

  &__error {
      color: $alert-color;
      text-align: center;
      font-size: 14px;
      margin-top: 10%;
  }

  ////////////////////
  ////New Version////
  //////////////////
  &__details-header{
      margin-top: 7px;
      position: relative;
  }
  &__details-title{
      font-family: MTTMilano-Bold, sans-serif;
      line-height: 30px;
      color: $primary-color;
      font-size: 18px;
      margin: 0;
  }
  &__details-list{
      list-style: none;
      height: 570px;
      overflow: auto;
      margin: 35px 0 0 0;
      padding: 0px 5px;
      border-top: 4px solid;
      & > li {
          margin-top: 28px;
      }
  }
  &__details-close{
      font-size: 20px;
      position: absolute;
      right: 0;
      top: -4px;
      z-index: 10;
  }

  &__body{
      height: 690px;
      overflow: hidden;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      &--window{
        height: auto;
        min-height: 947px;
      }
  }
  &__calendar-wrapper{
      position: relative;
  }

  &__calendar-subtitle{
      position: absolute;
      top: 0;
      font-size: 20px;
      font-weight: normal;

  }
  &__list-event{
      display: block;
      overflow: hidden;
      width: auto;
      padding: 0;
      position: relative;
      float: left;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      margin: 2px 5px 2px 5px;
      &:hover{
          cursor:pointer;
      }
  }
  &__list-event-title{
      position: relative;
      float: left;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      margin: 4px 5px 2px 0px;
  }

  &__multiselect-icon{
      background-color: transparent;
      padding: 0 2px 2px 2px;
      margin-right: 5px;
      font-size: 20px;
  }
  &__event-option-text{
    width: calc(100% - 29px);
    display: inline-block;
    position: relative;
    top: 3px;
  }
  ////////////////////////
  /////Fin Version 2/////
  //////////////////////
  & .cal-day-column{
      overflow-y: scroll;
      height: 650px;
  }
  &__day-of-week{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 18px;
      font-weight: normal;
      text-transform: capitalize;
      line-height: 19px;
      margin: 7px 0 0;
  }
  // &__all-date{
  //     color: $primary-color;
  //     font-family: MTTMilano, sans-serif;
  //     font-size: 16px;
  //     margin-top: 25px;
  // }
  &__button{
      border: none;
      background-color: transparent;
      font-size: 20px;
      padding: 0;
      margin-top: 9px;
      &--right{
          margin-left: 5px;
      }
  }
  &__top-block{
      display: block;
      width: 100%;
      overflow: hidden;
  }
  &__title{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 18px;
      font-weight: normal;
      text-transform: capitalize;
      line-height: 30px;
      position: absolute;
      top: 0;
      color: $primary-color;
      &--event{
          position: relative;
          margin-bottom: 0;
          line-height: 13px;
          margin-top: 15px;
      }
  }
  &__block-filters{
    margin-bottom: 31px;
  }
  & .fc {
      // & .fc-scrollgrid-section > *,
      &.fc-theme-standard{
          & .fc-timeGridDay-view{
              border-top: 4px solid $result-search-quote-color;
              & table:not([cellpadding]) th, table:not([cellpadding]) td {
                padding: 0;
              }
              & th{
                  & .fc-col-header-cell{
                      display: none;
                  }
              }
              & .fc-timegrid-axis{
                  border: none;
              }
              & .fc-scrollgrid-shrink .fc-scrollgrid-shrink-frame{
                  justify-content: center;
                  text-align: center;
                  margin: 0 10px;
              }
              & .fc-timegrid-axis-cushion {
                  max-width: none;
                  padding: 0;
              }
              & .fc-day-today{
                  background-color: $snow-white;
              }
              // & .fc-scroller-liquid-absolute{
              //     overflow: hidden auto !important;
              // }
          }
          & .fc-timeGridWeek-view{
              & .fc-scrollgrid-section-header > *{
                  border: none;
              }
              & .fc-scrollgrid{
                  border:none;
                  & .fc-col-header-cell{
                      font-family: MTTMilano, sans-serif;
                      text-transform: uppercase;
                      font-weight: normal;
                      & .fc-col-header-cell-cushion{
                          text-decoration: none;
                          padding: 0;
                      }
                  }
              }
              & .fc-scrollgrid-sync-table{
                  border-left: 1px solid #ddd;
              }
              & .fc-scrollgrid-section{
                  height: 0;
                  & td, th{
                    padding: 0;
                  }
              }
              & .fc-timegrid-slots{
                  & table{
                      border-left: 1px solid #ddd;
                  }
              }
              & th .fc-day, & th .fc-timegrid-axis{
                  // border-right-color: transparent;
                  border-right: none;
                  border-left: none;
                  border-bottom-width: 4px;
              }
              & .fc-scrollgrid-shrink .fc-scrollgrid-shrink-frame{
                  justify-content: center;
                  text-align: center;
                  margin: 0 10px;
              }
              & .fc-timegrid-axis-cushion {
                  max-width: none;
                  padding: 0;
              }
              & .fc-scroller-liquid-absolute{
                  overflow: hidden auto !important;
              }
          }
          & .fc-dayGridMonth-view{
              & .fc-scrollgrid{
                  border:none;
              }
              & .fc-scrollgrid-section{
                  & td, th{
                    padding: 0;
                  }
              }
              & .fc-scrollgrid-section-header > th{
                  padding: 0;
                  border-right: none;
                  border-left: none;
                  border-bottom-width: 4px;
              }
              & .fc-col-header-cell{
                border: none;
              }
              & .fc-scrollgrid-sync-table{
                  border-left: 1px solid;
              }
              & .fc-col-header-cell-cushion{
                  font-family: MTTMilano, sans-serif;
                  text-transform: uppercase;
                  font-weight: normal;
                  text-decoration: none;
              }
              & .fc-daygrid-day{
                  background-color: $snow-white;
                  &.fc-day-today{
                    background-color: $primary-color;
                  }
                  &.fc-day-disabled,
                  &.fc-day-sat,
                  &.fc-day-sun{
                      background-color: $background-color-states;
                  }
                  & .fc-daygrid-day-number{
                      font-size: 14px;
                      padding: 2px 8px 0;
                      text-decoration: none;
                      color: $result-search-quote-color;
                  }
              }
              & .fc-daygrid-dot-event{
                  color: $primary-color;
                  background-color: $background-color-states;
                  border: 1px solid $box-border;
                  font-size: 12px;
                  font-family: Arial, sans-serif;
                  padding: 3px;
                  border-radius: 0;
                  margin-top: 1px;
                  min-height: 20px;
                  &:hover{
                      color: $primary-color;
                      background-color: $background-color-states;
                      border-color: $box-border;
                      cursor: pointer;
                  }
                  & .fc-daygrid-event-dot{
                      display: none;
                  }
                  & .fc-event-title{
                      font-weight: 400;
                  }
              }

          }
      }
      & .fc-scroller{
          overflow: hidden !important;
      }
      // & .fc-scroller-liquid-absolute{
      //     overflow: hidden auto !important;
      // }
      & .fc-toolbar.fc-header-toolbar{
          height: 67px;
          margin-bottom: 0;
      }
      & .fc-button-group{
          bottom: 15px;
          right: -2px;
      }
      & .fc-button-primary{
          background-color: transparent;
          border:none;
          outline: none;
          box-shadow: none;
          color: $primary-color;
          &.fc-prev-button .fc-icon{
              &:before{
                  content: "\e90c" !important;
                  font-family: "lf-font";
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  font-weight: normal;
                  font-size: 20px;
              }
          }

          &.fc-next-button {
              padding-left: 3px;
              & .fc-icon{
                  &:before{
                      content: "\e90d" !important;
                      font-family: "lf-font";
                      line-height: 1;
                      -webkit-font-smoothing: antialiased;
                      -moz-osx-font-smoothing: grayscale;
                      font-weight: normal;
                      font-size: 20px;
                  }
              }
          }

      }
      & .fc-button-primary:not(:disabled).fc-button-active,
      & .fc-button-primary:not(:disabled):active,
      & .fc-button-primary:not(:disabled):focus,
      & .fc-button-primary:not(:disabled):checked{
          box-shadow: none !important;
          background-color: transparent !important;
          border: none !important;
          color: inherit !important;
      }
      & .fc-timegrid-divider{
         display: none;
      }

      .fc-daygrid-day,
      .fc-timegrid-col{
          background-color: $background-color-states;
      }
  }
  & .fc-timegrid-col{
      &.fc-timegrid-axis{
          background-color: $snow-white;
      }
      &.fc-day-today{
        background-color: $snow-white;
      }
  }
  & .fc-day.fc-day-today{
      background-color: $snow-white;
  }
  & .fc-theme-standard .fc-scrollgrid,
  & .fc-theme-standard td, .fc-theme-standard th{
    border-color: $result-search-quote-color;
  }

  & .fc-timegrid-slots{
      & tr{
          height: 34px;
      }

  }
  & .fc-timegrid-cols{
      & td{
          & .fc-event{
              font-size: 12px;
              font-family: Arial, sans-serif;
              padding-bottom: 0px;
              border-radius: 0;
              margin-top: 1px;
              min-height: 20px;
              background-color: $background-color-states;
              border-color: $box-border;
              &:hover{
                  cursor: pointer;
                  background-color: $background-color-states;
                  border-color: $box-border;
              }
          }
          & .fc-event-title{
              color: $primary-color;
          }
          & .fc-timegrid-now-indicator-arrow{
              border: none;
              border-radius: 50%;
              width: 11px;
              height: 11px;
              background-color: $primary-color;

          }
      }

      & .fc-day-today{
          & .fc-timegrid-now-indicator-container{
              left: -100%;
          }
          & .fc-timegrid-now-indicator-line{
              border-color: $primary-color;
          }
      }
  }
  & .fc-event-title .fc-sticky {
      &:before {
          font-family: lf-font;
          font-size: 16px;
          font-weight: normal;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin-right: 3px;
          display: inline-block;
          vertical-align: top;
      }
  }

//  & .schedule{
  &__event{
      &--alert {
          & .fc-event-main .fc-event-title,
          & .schedule-event__when,
          &.fc-daygrid-dot-event .fc-event-title{
              &:before {
                  content: "\e9a6";
                  font-family: lf-font;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  margin-right: 3px;
                  display: inline-block;
                  vertical-align: top;
              }
          }
          & .fc-event-main .fc-event-title{
              &:after {
                  content: "\e9a6";
                  font-family: lf-font;
                  font-size: 16px;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  position: absolute;

                  right: 3px;
              }
          }
      }


      &--meeting {
          & .fc-event-main .fc-event-title,
          & .schedule-event__when,
          &.fc-daygrid-dot-event .fc-event-title{
              &:before {
                  content: "\ea63";
                  margin-top: -3px;
                  font-family: lf-font;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  margin-right: 3px;
                  display: inline-block;
                  vertical-align: top;
              }
          }
          & .fc-event-main .fc-event-title{
              &:after {
                  content: "\e9a6";
                  font-family: lf-font;
                  font-size: 16px;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  position: absolute;

                  right: 3px;
              }
          }
      }

      &--videoconference {
          &.fc-event-main .fc-event-title,
          & .schedule-event__when,
          &.fc-daygrid-dot-event .fc-event-title{
              &:before {
                  content: "\eab4";
                  font-family: lf-font;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  margin-right: 3px;
                  display: inline-block;
                  vertical-align: top;
              }
          }
          & .fc-event-main .fc-event-title{
              &:after {
                  content: "\e9a6";
                  font-family: lf-font;
                  font-size: 16px;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  position: absolute;

                  right: 3px;
              }
          }
      }


      &--evaluation {
          & .fc-event-main .fc-event-title,
          & .schedule-event__when,
          &.fc-daygrid-dot-event .fc-event-title{
              &:before {
                  content: "\e9dd";
                  font-family: lf-font;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  margin-right: 3px;
                  display: inline-block;
                  vertical-align: top;
              }
          }
          & .fc-event-main .fc-event-title{
              &:after {
                  content: "\e9a6";
                  font-family: lf-font;
                  font-size: 16px;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  position: absolute;

                  right: 3px;
              }
          }
      }

      &--periodic {
          &.fc-event-main .fc-event-title,
          & .schedule-event__when,
          &.fc-daygrid-dot-event .fc-event-title{
              &:before {
                  content: "\eac6";
                  font-family: lf-font;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  margin-right: 3px;
                  display: inline-block;
                  vertical-align: top;
              }
          }
          & .fc-event-main .fc-event-title{
              &:after {
                  content: "\e9a6";
                  font-family: lf-font;
                  font-size: 16px;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  position: absolute;

                  right: 3px;
              }
          }
      }


      &--informationRequest {
          & .fc-event-main .fc-event-title,
          & .schedule-event__when,
          &.fc-daygrid-dot-event .fc-event-title{
              &:before {
                  content: "\e94b";
                  font-family: lf-font;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  margin-right: 3px;
                  display: inline-block;
                  vertical-align: top;
              }
          }
          & .fc-event-main .fc-event-title{
              &:after {
                  content: "\e9a6";
                  font-family: lf-font;
                  font-size: 16px;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  position: absolute;

                  right: 3px;
              }
          }
      }

      &--periodic {
          &.fc-event-main .fc-event-title,
          & .schedule-event__when,
          &.fc-daygrid-dot-event .fc-event-title{
              &:before {
                  content: "\eac5";
                  font-family: lf-font;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  margin-right: 3px;
                  display: inline-block;
                  vertical-align: top;
              }
          }
          & .fc-event-main .fc-event-title{
              &:after {
                  content: "\e9a6";
                  font-family: lf-font;
                  font-size: 16px;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  position: absolute;

                  right: 3px;
              }
          }
      }


      &--other {
          .fc-event-main .fc-event-title,
          & .schedule-event__when,
          &.fc-daygrid-dot-event .fc-event-title{
              &:before {
                  content: "\e9e8";
                  font-family: lf-font;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  margin-right: 3px;
                  display: inline-block;
                  vertical-align: top;
              }
          }
          & .fc-event-main .fc-event-title{
              &:after {
                  content: "\e9a6";
                  font-family: lf-font;
                  font-size: 16px;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  position: absolute;

                  right: 3px;
              }
          }
      }

          &--periodic {
              &.fc-event-main .fc-event-title,
              & .schedule-event__when,
              &.fc-daygrid-dot-event .fc-event-title{
              &:before {
                  content: "\eaaa";
                  font-family: lf-font;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  margin-right: 3px;
                  display: inline-block;
                  vertical-align: top;
              }
          }
          & .fc-event-main .fc-event-title{
              &:after {
                  content: "\e9a6";
                  font-family: lf-font;
                  font-size: 16px;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  position: absolute;

                  right: 3px;
              }
          }
      }

      &--process {
          & .fc-event-main .fc-event-title,
          & .schedule-event__when,
          &.fc-daygrid-dot-event .fc-event-title{
              &:before {
                  content: "\ea73";
                      font-family: lf-font;
                      font-size: 16px;
                      font-weight: normal;
                      line-height: 1;
                      -webkit-font-smoothing: antialiased;
                      -moz-osx-font-smoothing: grayscale;
                      margin-right: 3px;
                      display: inline-block;
                      vertical-align: top;
              }
          }
          & .fc-event-main .fc-event-title{
              &:after {
                  content: "\e9a6";
                  font-family: lf-font;
                  font-size: 16px;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  position: absolute;

                  right: 3px;
              }
          }
      }
  }
}

.compliance{
  &__dark-blue{
      .schedule {
          &--search{
              background-color: $background-color-states;
          }
          &__circles-steps{
              background-color: $primary-color;
          }
          &__block-reminders-parragraph{
              color: $secondary-color;
          }
          &__block-reminders-dates{
              color: $primary-color;
          }
          &__details-close{
              color: $primary-color;
          }
          &__body{
              color: $primary-color;
          }
          &__calendar-subtitle{
              @include calendar-subtitle($primary-color);
              margin-top:24px;
              &--event{
                  font-size: 20px;
                  position: relative;
                  margin: 0;
                  margin-bottom: 44px;
              }
          }
          &__list-event{
              @include list-event($primary-color, $background-color-states);
          }
          &__list-event-title{
              color: $primary-color;
          }
          &__multiselect-icon{
              @include multiselect-icon($primary-color, $background-color-states);
          }
      }
  }
  &__steel-blue,
  &__uk-steel-blue{
      .schedule {
          &__details-title{
              color: $primary-color-2;
          }
          &__details-list{
              border-color: $primary-color-2;
          }
          &__details-close{
              color: $border-hover-2;
          }

          &__body{
              color: $border-hover-2;
          }
          &__title{
              color: $primary-color-2;
          }
          &__calendar-subtitle{
              @include calendar-subtitle($primary-color-2);
          }
          &__list-event{
              @include list-event($primary-color-2, $block-hover-2);
          }
          &__list-event-title{
              color: $primary-color-2;
          }
          &__multiselect-icon{
              @include multiselect-icon($border-hover-2, $block-hover-2);
          }

          & .fc {
            &.fc-theme-standard{
                & .fc-dayGridMonth-view{
                  & .fc-daygrid-day{
                      background-color: $snow-white;
                      &.fc-day-today{
                        background-color: $border-hover-2;
                      }
                      &.fc-day-disabled,
                      &.fc-day-sat,
                      &.fc-day-sun{
                          background: rgba($secondary-color-2, 0.5);
                      }
                      & .fc-daygrid-day-number{
                          color: $border-bottom-2;
                      }
                  }
                  & .fc-daygrid-dot-event{
                      color: $primary-color-2;
                      background-color: $white-special-2;
                      border: 1px solid $fc-event;
                      &:hover{
                          color: $primary-color-2;
                          background-color: $white-special-2;
                          border-color: $fc-event;
                      }
                  }
                }
                // & .fc-timeGridWeek-view{
                // }
                & .fc-timeGridDay-view{
                  border-color: $primary-color-2;
                }

            }
            .fc-daygrid-day,
            .fc-timegrid-col{
              background: rgba($secondary-color-2, 0.5);
            }
            & .fc-button-primary{
                color: $border-hover-2;
            }
          }
          & .fc-timegrid-col{
              &.fc-timegrid-axis{
                  background-color: $snow-white;
              }
              &.fc-day-today{
                background-color: $snow-white;
              }
          }
          & .fc-day.fc-day-today{
              background-color: $snow-white;
          }
          & .fc-timegrid-cols{
            & td{
                & .fc-event{
                  background-color: $white-special-2;
                  border-color: $fc-event;
                    &:hover{
                        cursor: pointer;
                        background-color: $white-special-2;
                        border-color: $fc-event;
                    }
                }

                & .fc-event-title{
                    color: $primary-color-2;
                }
                & .fc-timegrid-now-indicator-arrow{
                  background-color: $third-color-2;
                }
            }
            & .fc-day-today{
                & .fc-timegrid-now-indicator-line{
                    border-color: $third-color-2;
                }
            }
          }

          & .fc-theme-standard .fc-scrollgrid,
          & .fc-theme-standard td{
              border-color: $secondary-color-2;
          }
          & .fc-theme-standard th{
              border-color: $primary-color-2;
          }
      }
  }
  &__icam-red{
      .schedule {
          &__details-title{
              color: $custom-black-landing;
          }
          &__details-list{
              border-color: $custom-black-landing;
          }
          &__details-close{
              color: $primary-color-icam;
          }
          &__body{
              color: $primary-color-icam;
          }
          &__title{
              color: $custom-black-landing;
          }
          &__calendar-subtitle{
              @include calendar-subtitle($custom-black-landing);
          }
          &__list-event{
              @include list-event($custom-black-landing, $hover-block-icam);
          }
          &__list-event-title{
              color: $modal-question-icam;
          }
          &__multiselect-icon{
              @include multiselect-icon($primary-color-icam, $hover-block-icam);
          }
          & .fc {
            &.fc-theme-standard{
                & .fc-dayGridMonth-view{
                  & .fc-daygrid-day{
                      background-color: $snow-white;
                      &.fc-day-today{
                        background-color: $primary-color-icam;
                      }
                      &.fc-day-disabled,
                      &.fc-day-sat,
                      &.fc-day-sun{
                          background-color: $background-grey-icam;
                      }
                      & .fc-daygrid-day-number{
                          color: $third-color-icam;
                      }
                  }
                  & .fc-daygrid-dot-event{
                      color: $custom-black-landing;
                      background-color: $background-grey-disabled-icam;
                      border: 1px solid $border-black-icam;
                      &:hover{
                          color: $custom-black-landing;
                          background-color: $background-grey-disabled-icam;
                          border-color: $border-black-icam;
                      }
                  }
                }
                // & .fc-timeGridWeek-view{
                // }
                & .fc-timeGridDay-view{
                  border-color: $custom-black-landing;
                }

            }
            .fc-daygrid-day,
            .fc-timegrid-col{
              background-color: $background-grey-icam;
            }
            & .fc-button-primary{
                color: $primary-color-icam;
            }
          }
          & .fc-timegrid-col{
              &.fc-timegrid-axis{
                  background-color: $snow-white;
              }
              &.fc-day-today{
                background-color: $snow-white;
              }
          }
          & .fc-day.fc-day-today{
              background-color: $snow-white;
          }
          & .fc-timegrid-cols{
            & td{
                & .fc-event{
                  background-color: $background-grey-disabled-icam;
                  border-color: $border-black-icam;
                    &:hover{
                        cursor: pointer;
                        background-color: $background-grey-disabled-icam;
                        border-color: $border-black-icam;
                    }
                }

                & .fc-event-title{
                  color: $custom-black-landing;
                }
                & .fc-timegrid-now-indicator-arrow{
                  background-color: $primary-color-icam;
                }
            }
            & .fc-day-today{
                & .fc-timegrid-now-indicator-line{
                    border-color: $primary-color-icam;
                }
            }
          }

          & .fc-theme-standard .fc-scrollgrid,
          & .fc-theme-standard td{
              border-color: $border-block-icam;
          }
          & .fc-theme-standard th{
              border-color: $custom-black-landing;
          }
      }
  }
  &__gm-law{
      .schedule {
          &__circles-steps{
              background-color: $primary-color-gm;
          }
          &__block-reminders-parragraph{
              color: $secondary-color-gm;
          }
          &__block-reminders-dates{
              color: $primary-color-gm;
          }
          &__details-title{
              color: $custom-black-landing;
          }
          &__details-list{
              border-color: $custom-black-landing;
          }
          &__details-close{
              color: $primary-color-gm;
          }
          &__body{
              color: $primary-color-gm;
          }
          &__title{
              color: $custom-black-landing;
          }
          &__calendar-subtitle{
              @include calendar-subtitle($custom-black-landing);
          }
          &__list-event{
              @include list-event($custom-black-landing, $hover-block-gm);
          }
          &__list-event-title{
              color: $modal-question-gm;
          }
          &__multiselect-icon{
              @include multiselect-icon($primary-color-gm, $hover-block-gm);
          }

          & .fc {
            &.fc-theme-standard{
                & .fc-dayGridMonth-view{
                  & .fc-daygrid-day{
                      background-color: $snow-white;
                      &.fc-day-today{
                        background-color: $primary-color-gm;
                      }
                      &.fc-day-disabled,
                      &.fc-day-sat,
                      &.fc-day-sun{
                          background-color: $background-grey-gm;
                      }
                      & .fc-daygrid-day-number{
                          color: $third-color-gm;
                      }
                  }
                  & .fc-daygrid-dot-event{
                      color: $custom-black-landing;
                      background-color: $background-grey-disabled-gm;
                      border: 1px solid $border-black-gm;
                      &:hover{
                          color: $custom-black-landing;
                          background-color: $background-grey-disabled-gm;
                          border-color: $border-black-gm;
                      }
                  }
                }
                // & .fc-timeGridWeek-view{
                // }
                & .fc-timeGridDay-view{
                  border-color: $custom-black-landing;
                }

            }
            .fc-daygrid-day,
            .fc-timegrid-col{
              background-color: $background-grey-gm;
            }
            & .fc-button-primary{
                color: $primary-color-gm;
            }
          }
          & .fc-timegrid-col{
              &.fc-timegrid-axis{
                  background-color: $snow-white;
              }
              &.fc-day-today{
                background-color: $snow-white;
              }
          }
          & .fc-day.fc-day-today{
              background-color: $snow-white;
          }
          & .fc-timegrid-cols{
            & td{
                & .fc-event{
                  background-color: $background-grey-disabled-gm;
                  border-color: $border-black-gm;
                    &:hover{
                        cursor: pointer;
                        background-color: $background-grey-disabled-gm;
                        border-color: $border-black-gm;
                    }
                }

                & .fc-event-title{
                  color: $custom-black-landing;
                }
                & .fc-timegrid-now-indicator-arrow{
                  background-color: $primary-color-gm;
                }
            }
            & .fc-day-today{
                & .fc-timegrid-now-indicator-line{
                    border-color: $primary-color-gm;
                }
            }
          }

          & .fc-theme-standard .fc-scrollgrid,
          & .fc-theme-standard td{
              border-color: $border-block-gm;
          }
          & .fc-theme-standard th{
              border-color: $custom-black-landing;
          }
      }
  }
  &__com-de{
    .schedule {
        &__circles-steps{
            background-color: $primary-color-com-de;
        }
        &__block-reminders-parragraph{
            color: $secondary-color-com-de;
        }
        &__block-reminders-dates{
            color: $primary-color-com-de;
        }
        &__details-title{
            color: $custom-black-landing;
        }
        &__details-list{
            border-color: $custom-black-landing;
        }
        &__details-close{
            color: $primary-color-com-de;
        }
        &__body{
            color: $primary-color-com-de;
        }
        &__title{
            color: $custom-black-landing;
        }
        &__calendar-subtitle{
            @include calendar-subtitle($custom-black-landing);
        }
        &__list-event{
            @include list-event($custom-black-landing, $hover-block-com-de);
        }
        &__list-event-title{
            color: $modal-question-com-de;
        }
        &__multiselect-icon{
            @include multiselect-icon($primary-color-com-de, $hover-block-com-de);
        }

        & .fc {
          &.fc-theme-standard{
              & .fc-dayGridMonth-view{
                & .fc-daygrid-day{
                    background-color: $snow-white;
                    &.fc-day-today{
                      background-color: $primary-color-com-de;
                    }
                    &.fc-day-disabled,
                    &.fc-day-sat,
                    &.fc-day-sun{
                        background-color: $background-grey-com-de;
                    }
                    & .fc-daygrid-day-number{
                        color: $third-color-com-de;
                    }
                }
                & .fc-daygrid-dot-event{
                    color: $custom-black-landing;
                    background-color: $background-grey-disabled-com-de;
                    border: 1px solid $border-black-com-de;
                    &:hover{
                        color: $custom-black-landing;
                        background-color: $background-grey-disabled-com-de;
                        border-color: $border-black-com-de;
                    }
                }
              }
              // & .fc-timeGridWeek-view{
              // }
              & .fc-timeGridDay-view{
                border-color: $custom-black-landing;
              }

          }
          .fc-daygrid-day,
          .fc-timegrid-col{
            background-color: $background-grey-com-de;
          }
          & .fc-button-primary{
              color: $primary-color-com-de;
          }
        }
        & .fc-timegrid-col{
            &.fc-timegrid-axis{
                background-color: $snow-white;
            }
            &.fc-day-today{
              background-color: $snow-white;
            }
        }
        & .fc-day.fc-day-today{
            background-color: $snow-white;
        }
        & .fc-timegrid-cols{
          & td{
              & .fc-event{
                background-color: $background-grey-disabled-com-de;
                border-color: $border-black-com-de;
                  &:hover{
                      cursor: pointer;
                      background-color: $background-grey-disabled-com-de;
                      border-color: $border-black-com-de;
                  }
              }

              & .fc-event-title{
                color: $custom-black-landing;
              }
              & .fc-timegrid-now-indicator-arrow{
                background-color: $primary-color-com-de;
              }
          }
          & .fc-day-today{
              & .fc-timegrid-now-indicator-line{
                  border-color: $primary-color-com-de;
              }
          }
        }

        & .fc-theme-standard .fc-scrollgrid,
        & .fc-theme-standard td{
            border-color: $border-block-com-de;
        }
        & .fc-theme-standard th{
            border-color: $custom-black-landing;
        }
    }
  }
  &__com-fr{
    .schedule {
        &__circles-steps{
            background-color: $primary-color-com-fr;
        }
        &__block-reminders-parragraph{
            color: $secondary-color-com-fr;
        }
        &__block-reminders-dates{
            color: $primary-color-com-fr;
        }
        &__details-title{
            color: $custom-black-landing;
        }
        &__details-list{
            border-color: $custom-black-landing;
        }
        &__details-close{
            color: $primary-color-com-fr;
        }
        &__body{
            color: $primary-color-com-fr;
        }
        &__title{
            color: $custom-black-landing;
        }
        &__calendar-subtitle{
            @include calendar-subtitle($custom-black-landing);
        }
        &__list-event{
            @include list-event($custom-black-landing, $hover-block-com-fr);
        }
        &__list-event-title{
            color: $modal-question-com-fr;
        }
        &__multiselect-icon{
            @include multiselect-icon($primary-color-com-fr, $hover-block-com-fr);
        }

        & .fc {
          &.fc-theme-standard{
              & .fc-dayGridMonth-view{
                & .fc-daygrid-day{
                    background-color: $snow-white;
                    &.fc-day-today{
                      background-color: $primary-color-com-fr;
                    }
                    &.fc-day-disabled,
                    &.fc-day-sat,
                    &.fc-day-sun{
                        background-color: $background-grey-com-fr;
                    }
                    & .fc-daygrid-day-number{
                        color: $third-color-com-fr;
                    }
                }
                & .fc-daygrid-dot-event{
                    color: $custom-black-landing;
                    background-color: $background-grey-disabled-com-fr;
                    border: 1px solid $border-black-com-fr;
                    &:hover{
                        color: $custom-black-landing;
                        background-color: $background-grey-disabled-com-fr;
                        border-color: $border-black-com-fr;
                    }
                }
              }
              // & .fc-timeGridWeek-view{
              // }
              & .fc-timeGridDay-view{
                border-color: $custom-black-landing;
              }

          }
          .fc-daygrid-day,
          .fc-timegrid-col{
            background-color: $background-grey-com-fr;
          }
          & .fc-button-primary{
              color: $primary-color-com-fr;
          }
        }
        & .fc-timegrid-col{
            &.fc-timegrid-axis{
                background-color: $snow-white;
            }
            &.fc-day-today{
              background-color: $snow-white;
            }
        }
        & .fc-day.fc-day-today{
            background-color: $snow-white;
        }
        & .fc-timegrid-cols{
          & td{
              & .fc-event{
                background-color: $background-grey-disabled-com-fr;
                border-color: $border-black-com-fr;
                  &:hover{
                      cursor: pointer;
                      background-color: $background-grey-disabled-com-fr;
                      border-color: $border-black-com-fr;
                  }
              }

              & .fc-event-title{
                color: $custom-black-landing;
              }
              & .fc-timegrid-now-indicator-arrow{
                background-color: $primary-color-com-fr;
              }
          }
          & .fc-day-today{
              & .fc-timegrid-now-indicator-line{
                  border-color: $primary-color-com-fr;
              }
          }
        }

        & .fc-theme-standard .fc-scrollgrid,
        & .fc-theme-standard td{
            border-color: $border-block-com-fr;
        }
        & .fc-theme-standard th{
            border-color: $custom-black-landing;
        }
    }
  }
}

@media (min-width: 1201px){
  .schedule{
      &--concursal{
          height: 723px;
      }
      &__body{
          &--concursal{
              height: 582px;
              .fc-agendaDay-view{
                  .fc-time-grid-container {
                      height: 476px !important;
                  }
              }

              .fc-agendaWeek-view{
                  .fc-time-grid-container {
                      height: 462px !important;
                  }
              }

              .fc-day-grid-container {
                  height: 496px !important;
              }
          }
      }
  }
}

@media (max-width: 1425px){
  .schedule{
      &__input-search-open{
          display: none;
      }
  }
}
