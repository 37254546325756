.field-references{
  width: 100%;
  display: inline-block;
  border: 1px solid $result-search-quote-color;
  background-color: $snow-white;
  &__left-block {
      width: 27%;
      float: left;
      height: 150px;
      position: relative;
      border-right: 1px solid ;
  }
  &__rigth-block {
      width: 73%;
      float: right;
      height: 150px;
      overflow: hidden;
      position: relative;
  }

  &__triangle{
      content: '';
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-bottom-width: 19px;
      border-top-width: 19px;
      border-left: 15px solid $snow-white;
      position: absolute;
      vertical-align: middle;
      right: -14px;
      top: calc(50% - 19px);
  }

  &__triangle-border {
      content: '';
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-bottom-width: 19px;
      border-top-width: 19px;
      border-left: 15px solid ;
      position: absolute;
      vertical-align: middle;
      right: -16px;
      top: calc(50% - 19px);
  }
  &__field-text{
      font-size: 12px;
      font-weight: bold;
      margin-top: 54px;
      text-transform: uppercase;
  }
  &__icon-numeric{
      position: absolute;
      font-size: 12px;
      border: none;
      font-style: normal;
      text-align: center;
      width: 33px;
      height: 15px;
      border-radius: 12px;
      margin-right: 23px;
      color: $snow-white;
  }
  &__text-block{
      position: absolute;
      margin-left: 25px;
      height: 100%;
  }
  &__text-dependences{
      margin-left: 38px;
      font-size: 11px;
      font-weight: bold;
      margin-top: 2px;
      text-transform: uppercase;
  }
  &__list {
      padding: 0 0 0 40px;
      list-style: none;
      margin-top: 22px;
      height: 119px;
      overflow-y: auto;
  }

  &__concept-list{
      list-style: none;
      width: 100%;
      display: block;
      padding: 0;
  }
  &__parent-folder{
      font-size: 12px;
      font-weight: bold;

      font-family: MTTMilano, sans-serif;
      margin: 0;
      text-transform: uppercase;
  }
  &__folder {
      margin: -8px 0px 2px;
      font-style: italic;
      margin-bottom: -15px;
      font-weight: 400;
      text-transform: capitalize;
  }
  &__concept-name-list{
      font-size: 12px;
      width: 98%;
      display: block;
      height: 29px;
      padding: 7px 0 0 6px;
  }

  &__icon-list{

      float: left;
      padding-right: 6px;
      font-size: 20px;
      position: relative;
      bottom: 2px;
  }
  &__dependencies-list{
      margin: 0;
      font-family: Arial, sans-serif;
      font-size: 12px;
  }

  &__list-element{
      margin-bottom: 14px;
  }
  &__no-references-block{
      width: 100%;
      height: 100px;
      display: table;
  }
  &__no-references-text{
      text-align: center;
      vertical-align: middle;
      color: $secondary-color;
      display: table-cell;
      font-size: 14px;
      font-family: MTTMilano, sans-serif;
  }
}

.compliance{
  &__dark-blue{
    .field-references{
      &__left-block {
        border-right-color: $box-comment-border-02;
      }
      &__triangle-border {
        border-left-color: $box-comment-border-02;
      }
      &__field-text{
        color: $subcontainer-ground;
      }
      &__icon-numeric{
        background-color: $primary-color;
      }
      &__parent-folder, &__text-dependences{
        color: $primary-color;
      }
      &__concept-name-list{
        color: $secondary-color;
        &:hover{
            border-bottom: 1px solid $primary-color;
            color: $primary-color;
            background-color: $background-grey;
            cursor: pointer;
        }
      }
      &__icon-list{
        color: $primary-color;
      }
    }
  }
  &__icam-red{
    .field-references{
      &__left-block {
        border-right-color: $folder-subtitle;
      }
      &__triangle-border {
        border-left-color: $folder-subtitle;
      }
      &__parent-folder,
      &__text-dependences,
      &__field-text{
        color: $custom-black-landing;
      }
      &__icon-numeric{
        background-color: $custom-black-landing;
        color: $snow-white;
      }
      &__concept-name-list{
        color: $text-field-color-icam;
        &:hover{
            border-bottom: 1px solid transparent;
            color: $custom-black-landing;
            background-color: $hover-block-icam;
            cursor: pointer;
        }
      }
      &__icon-list{
        color: $primary-color-icam;
      }
    }
  }
  &__gm-law{
    .field-references{
      &__left-block {
        border-right-color: $folder-subtitle;
      }
      &__triangle-border {
        border-left-color: $folder-subtitle;
      }
      &__icon-numeric{
        background-color: $custom-black-landing;
        color: $snow-white;
      }
      &__parent-folder,
      &__text-dependences,
      &__field-text{
        color: $custom-black-landing;
      }
      &__concept-name-list{
        color: $text-field-color-gm;
        &:hover{
            border-bottom: 1px solid transparent;
            color: $custom-black-landing;
            background-color: $hover-block-gm;
            cursor: pointer;
        }
      }
      &__icon-list{
        color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    .field-references{
      &__left-block {
        border-right-color: $folder-subtitle;
      }
      &__triangle-border {
        border-left-color: $folder-subtitle;
      }
      &__icon-numeric{
        background-color: $custom-black-landing;
        color: $snow-white;
      }
      &__parent-folder,
      &__text-dependences,
      &__field-text{
        color: $custom-black-landing;
      }
      &__concept-name-list{
        color: $text-field-color-com-de;
        &:hover{
            border-bottom: 1px solid transparent;
            color: $custom-black-landing;
            background-color: $hover-block-com-de;
            cursor: pointer;
        }
      }
      &__icon-list{
        color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .field-references{
      &__left-block {
        border-right-color: $folder-subtitle;
      }
      &__triangle-border {
        border-left-color: $folder-subtitle;
      }
      &__icon-numeric{
        background-color: $custom-black-landing;
        color: $snow-white;
      }
      &__parent-folder,
      &__text-dependences,
      &__field-text{
        color: $custom-black-landing;
      }
      &__concept-name-list{
        color: $text-field-color-com-fr;
        &:hover{
            border-bottom: 1px solid transparent;
            color: $custom-black-landing;
            background-color: $hover-block-com-fr;
            cursor: pointer;
        }
      }
      &__icon-list{
        color: $primary-color-com-fr;
      }
    }
  }
}
