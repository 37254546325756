.customize-footer {
  min-height: 160px;
  display: block;
  overflow: hidden;
  background: $primary-color;
  color: $snow-white;
  position: relative;
  padding: 15px 248px 30px;
  &--type-white{
      border-top: 2px solid $custom-black-landing;
      border-bottom: 1px solid $border-modal;
  }

  &__block-logo{
      margin-top: 15px;
  }

  &__logo-footer{
      position: relative;
      float: left;
      width: calc(100% - 280px);
      &--hidden-rrss{
          width: 100%;
      }
  }

  &__social-networks{
      float: right;
      position: relative;
      width: 280px;
      margin-top: 27px;
  }

  &__footer {
      margin: 0;
      font-size: 14px;
      font-family: MTTMilano, sans-serif;
      font-weight: 500;
      position: relative;
      float: left;
  }

  &__button-icon {
      float: right;
      border: none;
      background: none;
      font-size: 25px;
      padding: 0;
      height: 25px;
      margin: 0px 11px 0;
      outline: none;
      &--first{
          margin-left: 0;
      }
      &--last{
          margin-right: 0;
      }
  }

  &__phone-text{
      text-align: center;
      font-family: MTTMilano, sans-serif;
      font-size: 26px;
      letter-spacing: 1px;
      position: relative;
      margin-bottom: 10px;
      min-height: 21px;
  }
}

@media (max-width: 1367px){
  .customize-footer{
      padding: 15px 50px 20px;
  }
}

@media (max-width: $max-width-smartphone) { //Smartphone
  .customize-footer {
      padding: 15px 10px 10px 5px;
      width: 100%;
      height: auto;

      &__button-icon {
          margin-left: 4px;
          margin-right: 4px;

          &--first {
              margin-left: 0;
          }

          &--last {
              margin-right: 0;
          }
      }

      &__img-logo {
          width: 220px;
          height: 49px;
          margin-left: -28px;
      }

      &__block-logo {
          margin-top: 0;
          width: 100%;
      }

      &__social-networks {
          margin-top: 0;
      }

      &__phone-text {
          text-align: right;
          font-size: 20px;
      }
  }
}
