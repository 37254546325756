@mixin field-type-list-extended-icon-container($textColor, $borderColor, $backgroundColor) {
  color: $textColor;
  &:hover:not([disabled]){
      border: 1px solid $borderColor;
      background: $backgroundColor;
      cursor: pointer;
  }
}

@mixin field-type-list-extended-label-list-hover($borderColor, $backgroundColor) {
  border: 1px solid $borderColor;
  background: $backgroundColor;
}

@mixin field-type-list-extended-item-selected($borderColor, $backgroundColor) {
  border: 1px solid $borderColor;
  background-color: $backgroundColor;
}

.field-type-list-extended {
  &__input-icon-custom-radio{
      font-size: 17px;
      top: 4px;
  }
  &__input-icon-custom-square{
      font-size: 17px;
  }

  &__block-list{
      width: 100%;
      padding: 7px 10px 7px 8px;
      overflow: auto;
      &--ten-elements{
          // height: 350px;
          height: 400px;
      }
      &--five-elements{
          height: 230px;
      }
      &--modal{
          max-height: 360px;
      }
      &--custom{
          padding: 17px;
      }
  }

  // &__icon-radio-select{
  //     display: inline-block;
  //     color: $primary-color;
  //     font-size: 20px;
  //     position: relative;
  //     float: left;
  //     &:hover{
  //         cursor: pointer;
  //     }
  //     &--poll{
  //         font-size: 30px;
  //     }
  //     &--disabled{
  //         color: $subcontainer-ground;
  //         &:hover{
  //             cursor: not-allowed;
  //         }
  //     }
  // }

  &__label-list{
      width: 100%;
      margin: 5px 0 4px;
      padding: 0;
      outline: none;
      border: none;
      background-color: transparent;
      text-align: left;
      &:hover:not([disabled]) {
          .field-type-list-extended__icon-container{
              @include field-type-list-extended-label-list-hover($donut-text-02, $background-grey);
              &--custom{
                  @include field-type-list-extended-label-list-hover(transparent, none);
              }
          }
      }
      &--disabled{
          cursor: not-allowed;
          &:hover{
              cursor: not-allowed;
          }
      }
  }

  &__text-element-list{
      position: relative;
      float: left;
      padding-left: 5px;
      top: 2px;
      line-height: 20px;
      width: calc(100% - 25px);
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: normal;
      margin: 0;
      &:hover{
          cursor: pointer;
      }
      &--poll{
          font-size: 14px;
          top: 6px;
          width: calc(100% - 35px);
      }
      &--custom{
          font-family: 'lato_regular', sans-serif;
          color: $custom-secondary-color;
          top: -2px;
      }

      &--disabled{
          &:hover{
              cursor: not-allowed;
          }
      }
  }
  &__icon-container{
      display: block;
      overflow: hidden;
      float: left;
      padding: 1px;
      border: 1px solid transparent;
      outline: none;
      background-color: transparent;
      font-size: 20px;
      @include field-type-list-extended-icon-container($primary-color, $donut-text-02, $background-grey);
      &--radio{
          border-radius: 50px;
      }
      &--finish-after{
          position: relative;
          top: 3px;
      }
      &--poll{
          font-size: 30px;
      }
      &--custom{
          @include field-type-list-extended-icon-container($custom-black-landing, transparent, none);
          font-size: 12px;
      }

      &--disabled{
          color: $subcontainer-ground;
          cursor: not-allowed !important;
          &:hover{
              cursor: not-allowed;
          }
      }
  }

  &__item-selected{
      position: relative;
      text-overflow: ellipsis;
      max-width: 200px;
      display: inline-block;
      margin: 1px;
      &--disabled-landing-custom{
        background: $subcontainer-ground;
      }
  }

  // &__icon-find{
  //     color: $primary-color;
  //     font-size: 19px;
  //     position: absolute;
  //     margin-right: 5px;
  //     &:hover{
  //         cursor: pointer;
  //     }

  //     &--color-steel-blue{
  //         color: $border-hover-2;
  //     }
  // }

  &__multiselect-block{
      margin: 2px 0 3px;
      padding-right: 4px;
      width: 100%;
  }
  &__margin-disabled-selected{
      border-top: 1px solid $attach-icon-grey;
      position: relative;
      float: left;
      width: 100%;
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 5px;
  }
  &__block-text{
      padding-top: 0px;
      font-size: 14px;
  }
  &__block-simple-list{
      height: 22px;
      position: relative;
      // width: 100%;
      width: calc(100% - 10px);
      margin: 1px 5px 5px 5px;
      float: left;
  }
  &__simple-list{
      height: 22px;
      position: relative;
      text-overflow: ellipsis;
      width: calc(100% - 10px);
      width: 100%;
      max-width: inherit;
      // margin: 0 5px 5px 5px;
      padding-right: 5px;
      display: block;
  }

  &__find-position{
      float: left;
      width: 100%;
      position: relative;
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 5px;
  }

  &__multiple-list{
    padding: 2px 5px;
  }

  ////////////////////////////////////
  ///////////////Custom//////////////
  ///////////////////////////////////
  &__item-selected-simple-custom{
      background-color: $background-grey;
      height: 20px;
      border: none;
      // position: relative;
      // max-width: 200px;
  }

  &__item-selected-multi-custom{
      background-color: $background-grey;
      height: 20px;
      border: none;
      position: relative;
      max-width: 200px;
      text-overflow: ellipsis;
      display: inline-block;
      margin: 0 3px;
      &--error{
          background-color: $field-type-required-color;
      }
  }

  &__icon-close-custom{
      font-size: 10px;
      padding: 0;
      top: 5px;
      right: 3px;
      color: $custom-secondary-color;
  }

  &__icon-find-custom{
      margin: 0;
      bottom: 9px;
      right: 9px;
      color:$custom-black-landing;
      border: none;
  }

}



.compliance{
  &__landing-custom {
    .field-type-list-extended {
      &__multiple-list {
        &--all {
          border: 1px solid $custom-border-grey;
        }
      }
    }
  }

  &__dark-blue{
    .field-type-list-extended {
      &__multiple-list{
        &--all{
          padding-left: 4px;
          border: 1px solid $icon-arrow;
        }
      }
      &__simple-list{
        background-color: $donut-text;
        border: 1px solid $bar-horizontal-grey-opacity02;
        padding-left: 5px;
      }
      &__block-text{
        color: $snow-white;
      }
      &__item-selected{
        @include field-type-list-extended-item-selected($bar-horizontal-grey-opacity02, $donut-text);
        &--disabled{
          background: $subcontainer-ground;
        }
      }

    }
  }
  &__steel-blue{
    .field-type-list-extended {
      &__multiple-list{
        &--all{
          padding-left: 4px;
          border: 1px solid $third-color-2;
        }
      }
      &__block-text{
        color: $text-field-color-2;
      }
      &__margin-disabled-selected{
        border-top: 1px solid $secondary-color-2;
      }
      &__item-selected{
        @include field-type-list-extended-item-selected($secondary-color-2, transparent);
        &--disabled{
          background: transparent;
        }
      }
      &__icon-container{
        @include field-type-list-extended-icon-container($border-hover-2, $third-color-2, $block-hover-2);
        &--disabled{
          color: $grey-icons-2;
        }
      }
      &__text-element-list{
        color: $primary-color-2;
      }
      &__label-list{
        &:hover:not([disabled]) {
          .field-type-list-extended__icon-container{
            @include field-type-list-extended-label-list-hover($third-color-2, $block-hover-2);
          }
        }
      }
    }
  }
  &__uk-steel-blue{
    .field-type-list-extended {
      &__multiple-list{
        &--all{
          padding-left: 4px;
          border: 1px solid $third-color-2;
        }
      }
      &__icon-container{
        @include field-type-list-extended-icon-container($border-hover-2, $third-color-2, $block-hover-2);
        &--disabled{
          color: $grey-icons-2;
        }
      }
      &__label-list{
        &:hover:not([disabled]) {
          .field-type-list-extended__icon-container{
            @include field-type-list-extended-label-list-hover($third-color-2, $block-hover-2);
          }
        }
      }
    }
  }
  &__icam-red{
    .field-type-list-extended {
      &__multiple-list{
        &--all{
          padding-left: 4px;
          border: 1px solid $third-color-icam;
        }
      }
      &__block-text{
        color: $text-field-color-icam;
      }
      &__margin-disabled-selected{
        border-top: 1px solid $border-block-icam;
      }
      &__item-selected{
        @include field-type-list-extended-item-selected($border-block-icam, transparent);
      }
      &__icon-container{
        @include field-type-list-extended-icon-container($primary-color-icam, $hover-block-icam, $hover-block-icam);
        &--disabled{
          color: $secondary-color-icam;
        }
      }
      &__text-element-list{
        color: $custom-black-landing;
      }
      &__label-list{
        &:hover:not([disabled]) {
          .field-type-list-extended__icon-container{
            @include field-type-list-extended-label-list-hover($hover-block-icam, $hover-block-icam);
          }
        }
      }
    }
  }
  &__gm-law{
    .field-type-list-extended {
      &__multiple-list{
        &--all{
          padding-left: 4px;
          border: 1px solid $third-color-gm;
        }
      }
      &__block-text{
        color: $text-field-color-gm;
      }
      &__margin-disabled-selected{
        border-top: 1px solid $border-block-gm;
      }
      &__item-selected{
        @include field-type-list-extended-item-selected($border-block-gm, transparent);
      }
      &__icon-container{
        @include field-type-list-extended-icon-container($primary-color-gm, $hover-block-gm, $hover-block-gm);
        &--disabled{
          color: $secondary-color-gm;
        }
      }
      &__text-element-list{
        color: $custom-black-landing;
      }
      &__label-list{
        &:hover:not([disabled]) {
          .field-type-list-extended__icon-container{
            @include field-type-list-extended-label-list-hover($hover-block-gm, $hover-block-gm);
          }
        }
      }
    }
  }
  &__com-de{
    .field-type-list-extended {
      &__multiple-list{
        &--all{
          padding-left: 4px;
          border: 1px solid $third-color-com-de;
        }
      }
      &__block-text{
        color: $text-field-color-com-de;
      }
      &__margin-disabled-selected{
        border-top: 1px solid $border-block-com-de;
      }
      &__item-selected{
        @include field-type-list-extended-item-selected($border-block-com-de, transparent);
      }
      &__icon-container{
        @include field-type-list-extended-icon-container($primary-color-com-de, $hover-block-com-de, $hover-block-com-de);
        &--disabled{
          color: $secondary-color-com-de;
        }
      }
      &__text-element-list{
        color: $custom-black-landing;
      }
      &__label-list{
        &:hover:not([disabled]) {
          .field-type-list-extended__icon-container{
            @include field-type-list-extended-label-list-hover($hover-block-com-de, $hover-block-com-de);
          }
        }
      }
    }
  }
  &__com-fr{
    .field-type-list-extended {
      &__multiple-list{
        &--all{
          padding-left: 4px;
          border: 1px solid $third-color-com-fr;
        }
      }
      &__block-text{
        color: $text-field-color-com-fr;
      }
      &__margin-disabled-selected{
        border-top: 1px solid $border-block-com-fr;
      }
      &__item-selected{
        @include field-type-list-extended-item-selected($border-block-com-fr, transparent);
      }
      &__icon-container{
        @include field-type-list-extended-icon-container($primary-color-com-fr, $hover-block-com-fr, $hover-block-com-fr);
        &--disabled{
          color: $secondary-color-com-fr;
        }
      }
      &__text-element-list{
        color: $custom-black-landing;
      }
      &__label-list{
        &:hover:not([disabled]) {
          .field-type-list-extended__icon-container{
            @include field-type-list-extended-label-list-hover($hover-block-com-fr, $hover-block-com-fr);
          }
        }
      }
    }
  }
}
