.report-graphs {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    margin-top: 30px;

    &--tasks {
        margin-top: 0px;
    }

    &__top-block {
        position: relative;
        float: left;
        width: 100%;
        margin-bottom: 35px;
    }

    &__bottom-block {
        position: relative;
        float: left;
        width: 100%;
        margin-top: 15px;
    }

    &__left-graph {
        display: block;
        // height: 460px;
        width: 70%;
        position: relative;
        float: left;

        &--inherent {
            width: 24%;
            border-right: 2px dotted $primary-color;
            margin-left: 4px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: stretch;
        }
    }

    &__right-graphs {
        display: block;
        width: 24%;
        float: left;
        margin-left: 6%;
        padding-left: 18px;

        &--without-map {
            border: none;
            margin: 0 calc(50% - 140px);
            padding: 0;
        }

        &--inherent {
            width: 70%;
            margin: 0;
        }
    }

    &__all-graph {
        display: block;
        height: 460px;
        width: 100%;
        position: relative;
        float: left
    }

    &__legend-header {
        width: calc(100% - 26px);
        margin: 10px 13px;
        height: 47px;
        background-color: $background-grey;
        position: relative;
        float: left;
        padding: 14px 10px;
    }

    &__block-clock-header {
        position: relative;
        float: left;
        width: calc(100% - 20px);
        height: 132px;
        margin: 17px 10px 35px 10px;
        border-bottom: 2px dotted $primary-color;
    }

    &__block-total-hour {
        position: relative;
        float: left;
        height: 100px;
        width: 445px;
        padding-left: 35px;
        border-right: 2px dotted $primary-color;
    }

    &__block-hour-states {
        position: relative;
        float: left;
        height: 100px;
        width: calc(100% - 445px);
        border-right: 2px dotted $primary-color;
        padding-left: 50px;
    }

    &__block-text-legend {
        position: relative;
        float: left;
        padding-right: 25px;
        border-bottom: 1px solid $primary-color;
        height: 24px;
    }

    &__title-medium-time {
        text-transform: uppercase;
        opacity: 0.5;
        color: $primary-color;
        font-family: MTTMilano, sans-serif;
        font-size: 12px;
        font-weight: bold;
        top: -2px;
        position: relative;
        float: left;
        margin-bottom: 4px;
        margin-top: 13px;
        width: 283px;
    }

    &__title-state-time {
        text-transform: uppercase;
        opacity: 0.5;
        color: $primary-color;
        font-family: MTTMilano, sans-serif;
        font-size: 12px;
        font-weight: bold;
        top: -2px;
        position: relative;
        float: left;
        margin-bottom: 10px;
        margin-top: 13px;
        width: 100%;
    }

    &__box-number-medium {
        position: relative;
        float: left;
        height: 36px;
        width: 29px;
        border: 1px solid $subcontainer-border;
        background-color: $number-medium-color;
        text-align: center;

        &--left {
            margin-left: 6px;
        }

        &--right {
            margin-right: 6px;
        }
    }

    &__number-medium {
        opacity: 0.5;
        color: $primary-color;
        font-family: MTTMilano, sans-serif;
        font-size: 30px;
        font-weight: bold;
        top: -2px;
        position: relative;
    }

    &__text-hours {
        position: absolute;
        display: inline-block;
        top: 34px;
        left: 2px;
        color: $subcontainer-ground;
        font-family: MTTMilano, sans-serif;
        font-size: 14px;
        font-weight: bold;

        &--states {
            top: 28px;
            font-size: 12px;
            font-weight: 500;
        }
    }

    &__box-states-repeat {
        margin: 0px 40px 16px 0px;
        position: relative;
        float: left;
    }

    &__box-number-state {
        position: relative;
        float: left;
        height: 30px;
        width: 24px;
        border: 1px solid $subcontainer-border;
        background-color: $number-medium-color;
        text-align: center;

        &--left {
            margin-left: 6px;
        }

        &--right {
            margin-right: 6px;
        }
    }

    &__number-state {
        opacity: 0.8;
        color: $snow-white;
        font-family: MTTMilano, sans-serif;
        font-size: 24px;
        font-weight: bold;
        top: -1px;
        position: relative;
    }

    &__icon-calendar {
        font-size: 19px;
        color: $primary-color;
    }

    &__icon-clock {
        color: $primary-color;
        font-size: 100px;
        margin-right: 25px;
        position: relative;
        float: left;
    }

    &__text-legend {
        opacity: 0.5;
        color: $primary-color;
        font-family: MTTMilano, sans-serif;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        display: inline-block;
        position: relative;
        top: -2px;
        margin-left: 5px;
    }

    &__col-amount {
        position: relative;
        float: right;
    }

    &__icon-legend {
        font-size: 16px;
        margin: 0 4px 0 2px;
        position: relative;
        float: left;
        bottom: 3px;
    }

    &__legend-button {
        position: relative;
        margin: 0 auto 20px;
        width: 150px;
    }

    &__legend-color-block {
        position: absolute;
        width: 8px;
        height: 181px;
        margin-top: 7px;
        background: linear-gradient(to bottom, $legend-color-red 0%, $legend-color-orange 33%, $legend-color-yellow 66%, $legend-color-green 100%);
    }

    &__residual-block {
        width: 100%;
        display: block;
        height: auto;
        overflow: hidden;
        margin-bottom: 42px;
    }

    &__inherent-block-title {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 40px);
        border-bottom: 1px solid #7F8CBB;
        margin-left: 20px;
        margin-bottom: 41px;
    }

    &__capture {
        border: none;
        background: none;
        padding: 0;
        font-size: 20px;
        color: $primary-color;
        right: 5px;
        bottom: 8px;
    }

    &__inherent-block-title-text {
        color: #7F8CBB;
        font-family: MTTMilano;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        margin-bottom: 4px;
    }

    &__esg-heat-map-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: center;
        gap: 16px;
        margin-bottom: 16px;
    }

    &__esg-heat-map-header {
        border-bottom: 1px solid var(--Main-600, #6170AB);
        margin-bottom: 16px;
        margin: 0 50px 16px;
        display: flex;
        justify-content: space-between;
    }

    &__esg-heat-map-header-title {
        font-family: MTTMilano;
        color: #7F8CBB;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
    }

    &__esg-heat-map-header-graph {
        max-width: 1006px;
        max-height: 458px;
    }
    &__investigation-state-block, 
    &__information-typo-block{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
    }
    &__information-typo-container{
        width: 94%;
        display: block;
        margin: 0 auto;
    }
}

@media (max-width: 1800px) {
    .report-graphs {
        &__left-graph {
            width: 25%;
        }

        &__right-graphs {
            width: 74%;
        }
    }
}

@media (min-width: 1560px) and (max-width: 1800px) {
    .report-graphs {
        &__left-graph {
            width: 25%;
        }

        &__right-graphs {
            width: 74%;
            margin-left: 0%;
        }
    }
}

@media (max-width: 1560px) {
    .report-graphs {
        &__left-graph {
            width: 30%;
        }

        &__right-graphs {
            width: 69%;
            margin-top: 5%;
        }
    }
}