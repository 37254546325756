.duplicate-modal{
  height: 600px;
  &__content{
      display: block;
      margin: 27px 31px 0 72px;
      height: 405px;
      padding-right: 25px;
  }

  &__block-evaluation{
      width: 100%;
      float: left;
      position: relative;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      background-color: $background-grey;
      padding: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      height: 350px;
  }

  &__block-tree{
      width: 100%;
      float: left;
      position: relative;
      // color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      // background-color: $background-grey;
      padding: 10px;
      &--children{
          padding: 0px;
          padding-left: 52px;
      }
      &--concept{
          padding: 0px;
          padding-left: 48px;
      }
      &--next-level{
          padding-left: 38px;

      }
      &--locked{
          cursor: not-allowed !important;
      }

  }

  &__icon-arrow{
      font-size: 10px;
      display: inline-block;
      // font-family: Arial, sans-serif;
      // font-weight: bold;
      bottom: 9px;
      position: relative;
      visibility: hidden;
      margin-right: 4px;
      &--visibility{
          visibility: visible;
      }
      &--branch{
          bottom: 7px;
      }
      &--field-list{
          top: 0;
      }
      &--field-list-multiple{
          top: 0;
          padding-right: 4px;
      }
      &:hover{
          cursor: pointer;
      }
  }
  &__icon{
      margin: 0px 7px 0 1px;
      display: inline-block;
      position: relative;
      &--evaluation{
          font-size: 31px;
      }
      &--children{
          font-size: 26px;
      }
      &--concept{
          font-size: 25px;
      }
      &--template{
          margin-left: 2px;
      }

  }
  &__title-evaluation{
      font-family: MTTMilano-Bold, sans-serif;
      text-transform: uppercase;
      display: inline-block;
      top: -9px;
      position: relative;
      padding: 4px 20px 4px 4px;

  }
  &__title-selected{
      background-color: $subcontainer-border;
      font-family: MTTMilano-Bold, sans-serif;
  }
  &__title-children{
      font-family: MTTMilano, sans-serif;
      top: -6px;
      position: relative;
      padding: 4px 20px 4px 4px;
      &--show{
          font-family: MTTMilano-Bold, sans-serif;
      }
  }
  &__block-option{
      width: 100%;
      position: relative;
      float: left;
      height: 50px;
      margin-bottom: 20px;
  }
  &__block-name-evaluation{
      display: inline-block;
      &:hover{
          cursor: pointer;
      }
      &--locked{
          &:hover{
              cursor: not-allowed;
          }
      }
      &--disabled{
          color: $subcontainer-ground !important;
          &:hover{
              cursor: not-allowed !important;
          }
      }
  }
  &__block-field-text{
      width: 60%;
      position: relative;
      float: left;
  }
  &__block-conservate-content{
      width: 40%;
      position: relative;
      float: left;
      right: -18px;
      margin: 2px 0;
  }
  &__subtitle-text{
      text-transform: uppercase;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 18px;
      display: block;
      color: $subcontainer-ground;
  }
  &__top-zero{
      margin-top: 0px;

  }
  &__text-toggle {
      margin-left: 42px;
  }

  &__block-title-product{
      margin-bottom: 5px;
      padding-left: 15px;
      color: $primary-color;
      font-size: 30px;
      display: inline-block;
      &:hover{
          cursor: pointer;
      }
      &--disabled{
          color: $subcontainer-ground !important;
          &:hover{
              cursor: not-allowed !important;
          }
      }
  }
  &__title-product{
      height: 24px;
      display: inline-block;
      font-size: 14px;
      font-family: MTTMilano-Bold, sans-serif;
      margin-left: 10px;
      text-transform: uppercase;
      position: relative;
      top: -7px;
      padding: 4px 20px 4px 4px;
      &--move{
          margin-left: 0;
      }
      &--selected {
          background-color: $subcontainer-border;
          opacity: 1;


      }
  }
}

.compliance{
  &__dark-blue{
    .duplicate-modal{
      &__block-evaluation{
        background-color: $background-color-states;
      }
      &__title-product{
        opacity: 0.5;
      }
    }
  }
  &__steel-blue{
    .duplicate-modal{
      &__block-evaluation{
        background-color: $background-grey-disabled-2;
        color: $primary-color-2;
      }
      &__title-selected{
        background-color: $block-hover-2;
      }
    }
  }

  &__icam-red{
    .duplicate-modal{
      &__block-evaluation{
        background-color: $background-grey-disabled-icam;
        color: $custom-black-landing;
      }
      &__icon{
        color: $custom-black-landing;
      }
      &__title-selected{
        background-color: $hover-block-icam;
        color: $primary-color-icam;
      }
      &__block-name-evaluation{
        &--disabled{
          color: $custom-black-landing !important;
        }
      }
      &__title-product{
        &--selected{
          background-color: $secondary-color-icam;
        }
      }
    }
  }
  &__gm-law{
    .duplicate-modal{
      &__block-evaluation{
        background-color: $background-grey-disabled-gm;
        color: $custom-black-landing;
      }
      &__icon{
        color: $custom-black-landing;
      }
      &__title-selected{
        background-color: $hover-block-gm;
        color: $primary-color-gm;
      }
      &__block-name-evaluation{
        &--disabled{
          color: $custom-black-landing !important;
        }
      }
      &__subtitle-text{
        color: $secondary-color-gm;
      }
      &__block-title-product{
        color: $secondary-color-gm;
      }
      &__title-product{
        &--selected{
          background-color: $secondary-color-gm;
        }
      }
    }
  }
  &__com-de{
    .duplicate-modal{
      &__block-evaluation{
        background-color: $background-grey-disabled-com-de;
        color: $custom-black-landing;
      }
      &__icon{
        color: $custom-black-landing;
      }
      &__title-selected{
        background-color: $hover-block-com-de;
        color: $primary-color-com-de;
      }
      &__block-name-evaluation{
        &--disabled{
          color: $custom-black-landing !important;
        }
      }
      &__subtitle-text{
        color: $secondary-color-com-de;
      }
      &__block-title-product{
        color: $secondary-color-com-de;
      }
      &__title-product{
        &--selected{
          background-color: $secondary-color-com-de;
        }
      }
    }
  }
  &__com-fr{
    .duplicate-modal{
      &__block-evaluation{
        background-color: $background-grey-disabled-com-fr;
        color: $custom-black-landing;
      }
      &__icon{
        color: $custom-black-landing;
      }
      &__title-selected{
        background-color: $hover-block-com-fr;
        color: $primary-color-com-fr;
      }
      &__block-name-evaluation{
        &--disabled{
          color: $custom-black-landing !important;
        }
      }
      &__subtitle-text{
        color: $secondary-color-com-fr;
      }
      &__block-title-product{
        color: $secondary-color-com-fr;
      }
      &__title-product{
        &--selected{
          background-color: $secondary-color-com-fr;
        }
      }
    }
  }
}
