.draft-evaluations{
  &__bold{
      font-family: MTTMilano-Bold, sans-serif;
  }
  &__draft-box{
      display: block;
      width: calc(100% - 140px);
      padding: 0px;
      margin: 32px auto 0;
      height: 260px;
      // overflow: hidden;
      // overflow-y: auto;
  }
  &__draft-fields{
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
  }
  &__draft-concept-block{
      padding: 0 0 0 40px;
      margin: 0;
      list-style: none;
      height: 233px;
      overflow: hidden;
      overflow-y: auto;
  }
  &__draft-concept-item{
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      text-decoration: underline;
      margin-bottom: 9px;
      &:hover{
          cursor: pointer;
      }
  }
  &__draft-concept-square{
      width: 10px;
      height: 10px;
      background: $primary-color;
      display: inline-block;
      margin-right: 5px;
  }

  &__modal-body{
      background-color: $primary-color;
      width: calc(100% - 22px);
      margin: 11px;
      height: 223px;
      display: table;
      position: relative;

  }

  &__icon-close-modal {
      color: $snow-white;
      float: right;
      font-size: 20px;
      top: 4px;
      position: relative;
      &--custom{
          font-size: 25px;
          top: 0;
      }

      &:hover {
          cursor: pointer;
      }

  }
}

.compliance{
  &__steel-blue,
  &__uk-steel-blue{
    .draft-evaluations{
      &__draft-fields{
        color: $primary-color-2;
      }
      &__draft-concept-item{
        color: $border-hover-2;
      }
      &__draft-concept-square{
        background: $primary-color-2;
      }
      &__modal-body{
        background-color: $alert-color-2;
      }
      &__icon-close-modal{
        display: none;
      }
    }
  }
  &__icam-red{
    .draft-evaluations{
      &__draft-fields{
        color: $custom-black-landing;
      }
      &__draft-concept-item{
        color: $primary-color-icam;
      }
      &__draft-concept-square{
        background: $custom-black-landing;
      }
      &__modal-body{
        background-color: $alert-color-icam;
      }
    }
  }
  &__gm-law{
    .draft-evaluations{
      &__draft-fields{
        color: $custom-black-landing;
      }
      &__draft-concept-item{
        color: $primary-color-gm;
      }
      &__draft-concept-square{
        background: $custom-black-landing;
      }
      &__modal-body{
        background-color: $alert-color-gm;
      }
    }
  }
  &__com-de{
    .draft-evaluations{
      &__draft-fields{
        color: $custom-black-landing;
      }
      &__draft-concept-item{
        color: $primary-color-com-de;
      }
      &__draft-concept-square{
        background: $custom-black-landing;
      }
      &__modal-body{
        background-color: $alert-color-com-de;
      }
    }
  }
  &__com-fr{
    .draft-evaluations{
      &__draft-fields{
        color: $custom-black-landing;
      }
      &__draft-concept-item{
        color: $primary-color-com-fr;
      }
      &__draft-concept-square{
        background: $custom-black-landing;
      }
      &__modal-body{
        background-color: $alert-color-com-fr;
      }
    }
  }
}
