.time-picker{
  position: relative;

  &__time-picker{
      height: 139px;
      width: 400px;
      display: block;
      position: absolute;
      z-index: 30;
      border: 1px solid $primary-color;
      background: $snow-white;
      top: 100%;
      left: -1px;
      outline: none;
  }

  &__time-picker-container{
      width: 122px;
      margin: 0 auto;
      display: block;
      overflow: hidden;
      position: relative;
  }
  &__time-picker-block{
      display: block;
      width: 55px;
      overflow: hidden;
      float: left;
      text-align: center;
      padding: 13px 0;
      position: relative;
      &--right{
          margin-left: 12px;
      }
  }
  &__increment-decrement-buttons{
      font-size: 15px;
      color: $primary-color;
      &:hover{
          text-decoration: none;
          cursor: pointer
      }
  }
  &__time-separator{
      position: absolute;
      left: 57px;
      top: 60px;
  }
  &__separator-top{
      margin-top: 12px;
      margin-bottom: 6px;
      height: 1px;
      width: 100%;
      background-color: $primary-color;
  }
  &__separator-bottom{
      margin-top: 6px;
      margin-bottom: 12px;
      height: 1px;
      width: 100%;
      background-color: $primary-color;
  }
  &__time-input{
      height: 30px;
      width: 55px;
      border-radius: 0;
      outline: none;
      border: none;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
      font-style: normal;
      background: none;
      text-align: center;
      padding: 2px 5px 0 5px;
      &:hover{
          background-color: $block-comment-hover;
      }
  }
}
.compliance{
  &__dark-blue{
    .time-picker{

    }
  }
  &__steel-blue{
    .time-picker{
      &__time-picker{
        border-color: $third-color-2;
      }
      &__separator-bottom, &__separator-top, &__increment-decrement-buttons{
        background-color: $border-hover-2;
      }
      &__time-input{
        color: $primary-color-2;
        &:hover{
            background-color: $third-color-2;
        }
      }
    }
  }
  &__uk-steel-blue{
    .time-picker{

    }
  }
  &__icam-red{
    .time-picker{
      &__time-picker{
        border-color: $primary-color-icam;
      }
      &__increment-decrement-buttons{
        color: $primary-color-icam;
      }
      &__separator-bottom, &__separator-top{
        background-color: $border-block-icam;
     }
      &__time-input{
        &:hover{
            color: $custom-black-landing;
            background-color: $hover-block-icam;
        }
      }
    }
  }
  &__gm-law{
    .time-picker{
      &__time-picker{
        border-color: $primary-color-gm;
      }
      &__increment-decrement-buttons{
        color: $primary-color-gm;
      }
      &__separator-bottom, &__separator-top{
        background-color: $border-block-gm;
      }
      &__time-input{
        &:hover{
            color: $custom-black-landing;
            background-color: $hover-block-gm;
        }
      }
    }
  }
  &__com-de{
    .time-picker{
      &__time-picker{
        border-color: $primary-color-com-de;
      }
      &__increment-decrement-buttons{
        color: $primary-color-com-de;
      }
      &__separator-bottom, &__separator-top{
        background-color: $border-block-com-de;
      }
      &__time-input{
        &:hover{
            color: $custom-black-landing;
            background-color: $hover-block-com-de;
        }
      }
    }
  }
  &__com-fr{
    .time-picker{
      &__time-picker{
        border-color: $primary-color-com-fr;
      }
      &__increment-decrement-buttons{
        color: $primary-color-com-fr;
      }
      &__separator-bottom, &__separator-top{
        background-color: $border-block-com-fr;
      }
      &__time-input{
        &:hover{
            color: $custom-black-landing;
            background-color: $hover-block-com-fr;
        }
      }
    }
  }
  &__landing-custom{
    .time-picker{
      &__time-picker{
          box-shadow: none;
          border-color: $attachment-custom-grey;
          height: 107px;
          width: 312px;
          background-color: $snow-white;
          &--sequential{
              top: 46px;
          }
      }
      &__time-picker-container{
          width: 157px;
      }
      &__time-picker-block{
          width: 72px;
          padding: 14px 0;
      }
      &__increment-decrement-buttons{
          font-size: 12px;
          color: $custom-black-landing;
          &--custom-bottom{
              position: relative;
              top: 3px;
          }
      }
      &__time-separator{
          left: 76px;
          top: 43px;
      }
      &__time-input{
          height: 40px;
          width: 72px;
          background-color: $background-grey;
          padding-top: 0;
          color: $custom-black-landing;
          font-family: 'lato_regular', sans-serif;
      }
    }
  }
}
