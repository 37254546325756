@mixin button-reverse($firstColor, $color) {
  color: $firstColor;
  border: 3px solid $firstColor;

  &:after {
      border: none;
  }

  &:hover:not([disabled]) {
      color: $color;
      background-color: $firstColor;
  }
}

.informative-loading-modal{
  &__window {
      & .modal-dialog {
          & .modal-content {
              height: 413px;
              border-radius: 0px;
          }
      }
  }

  &__block{
      width: calc(100% - 24px);
      overflow: hidden;
      height: 387px;
      margin: 12px;
      padding-top: 80px;
  }

  &__new-block{
      display: block;
      overflow: hidden;
      width: 100%;
      padding: 0 140px;
  }

  &__text{
      font-family: MTTMilano, sans-serif;
      font-size: 20px;
      margin: 0;
      text-align: center;
  }

  &__tab-button {
    position: absolute;
    right: 50px;
    bottom: 50px;
  }
}

.compliance{
  &__dark-blue{
    .informative-loading-modal{
      &__block{
        background: $primary-color;
        color: $snow-white;
      }
    }
  }

  &__steel-blue,
  &__uk-steel-blue{
    .informative-loading-modal{
      &__block{
        background: $primary-color-2;
        color: $white-special-2;
      }
      &__tab-button {
        @include button-reverse($white-special-2, $primary-color-2);
      }
    }
  }

  &__icam-red{
    .informative-loading-modal{
      &__block{
        background: $modal-question-icam;
        color: $snow-white;
      }
      &__tab-button {
        @include button-reverse($snow-white, $modal-question-icam);
        padding: 9px 29px;
      }
    }

  }

  &__gm-law{
    .informative-loading-modal{
      &__block{
        background: $modal-question-gm;
        color: $snow-white;
      }
      &__tab-button {
        @include button-reverse($snow-white, $modal-question-gm);
        padding: 9px 29px;
      }
    }
  }
  &__com-de{
    .informative-loading-modal{
      &__block{
        background: $modal-question-com-de;
        color: $snow-white;
      }
      &__tab-button {
        @include button-reverse($snow-white, $modal-question-com-de);
        padding: 9px 29px;
      }
    }
  }
  &__com-fr{
    .informative-loading-modal{
      &__block{
        background: $modal-question-com-fr;
        color: $snow-white;
      }
      &__tab-button {
        @include button-reverse($snow-white, $modal-question-com-fr);
        padding: 9px 29px;
      }
    }
  }

}
