.all-documentation{
  &__rectangle-info {
      min-width: 40px;
      height: 22px;
      float: left;
      top: 4px;
      background: none;
      padding: 0 4px;
  }
}

.compliance{
  &__dark-blue{
    .all-documentation{
      &__rectangle-info {
        border-color: $primary-color;
        color: $primary-color;
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue{
    .all-documentation{
      &__rectangle-info {
        border-color: $primary-color-2;
        color: $primary-color-2;
      }
    }
  }
  &__icam-red{
    .all-documentation{
      &__rectangle-info {
        border-color: $title-text-icam;
        color: $title-text-icam;
      }
    }
  }
  &__gm-law{
    .all-documentation{
      &__rectangle-info {
        border-color: $title-text-gm;
        color: $title-text-gm;
      }
    }
  }
  &__com-de{
    .all-documentation{
      &__rectangle-info {
        border-color: $title-text-com-de;
        color: $title-text-com-de;
      }
    }
  }
  &__com-fr{
    .all-documentation{
      &__rectangle-info {
        border-color: $title-text-com-fr;
        color: $title-text-com-fr;
      }
    }
  }
}
