@mixin color($color){
  color: $color;
}

@mixin documentationTitle($color){
  color: $color;
  font-family: 'MTTMilano-Bold', sans-serif;
  font-weight: normal;
}

.upload-document{
  &.modal .modal-content {
      max-width: 900px;
      height: auto;
      min-height: 504px;
      border-radius: 0px;
      width: 100%;
  }
  &--landing-custom{
      & .modal-dialog{
          margin-top: 120px;
          & .modal-content{
              max-width: 700px;
              min-height: 325px;
              border-radius: 6px;
              margin: 0 auto;
          }
      }
  }

  &__modal-content-block{
      padding: 0 71px 10px;
      display: block;
      overflow: hidden;
      width: 100%;
      margin-top: 48px;
      margin-bottom: 70px;
      &--with-list{
          margin-top: 24px;
      }
  }

  &__upload-box{
      height: 291px;
      width: 290px;
      border: 2px dashed $primary-color;
      float: left;
      margin-right: 23px;
      border-radius: 6px;
      display: block;
      overflow: hidden;
      position: relative;
      &.dz-drag-hover{
          border-color: $green;
      }
  }

  &__dropzone-text{
      font-family: MTTMilano, sans-serif;
      color: $primary-color;
      font-size: 19px;
      text-align: center;
      margin: 18px 0 103px 0;
  }
  &__drop-icon{
      font-size: 41px;
      text-align: center;
      margin-top: 32px;
      display: block;
      color: $primary-color;
  }
  &__drop-option-menu{
      background: none;
      border: none;
      outline: none;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      // margin-bottom: 6px;
      margin-left: 4px;
      position: absolute;
      bottom: 6px;
  }
  &__drop-option-menu-icon{
      font-size: 20px;
      color: $primary-color;
      position: relative;
      top: 3px;
      padding-right: 7px;
  }
  &__documentation-block{
      display: block;
      overflow: hidden;
      width: 100%;
      &--reduce{
          // width: 453px;
          width: calc(100% - 313px);
          position: relative;
          float: left;
      }
  }
  &__documentation-header{
      display: block;
      width: 100%;
      overflow: hidden;
      border-bottom: 1px solid $error-header;
      padding-bottom: 4px;
  }
  &__documentation-title{
      color: $error-header;
      font-family: MTTMilano, sans-serif;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      float: left;
      margin: 0;
      padding-top: 2px;
  }
  &__documentation-icon{
      float: right;
      padding: 0px 12px;
      border-radius: 12px;
      border: 2px solid $error-header;
      color: $error-header;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      height: 22px;

  }
  &__documentation-list{
      margin: 0;
      padding: 0;
      list-style: none;
      height: 260px;
      overflow: auto;
      &--advice-modal{
          height: 198px;
      }
  }
  &__documentation-item{
      color: $primary-color;
      display: block;
      width: 100%;
      padding: 13px 0 8px 9px;
      overflow: hidden;
      border-bottom: 1px solid $upload-document-border;
      position: relative;
      &:hover{
          background: $background-grey;
          & .upload-document__documentation-menu-options{
              display: block;
          }
          & .upload-document__documentation-menu-options-button--more{
              display: none;
          }
      }
      &--error{
          background: $documentation-item;
          &:hover{
              background: $documentation-item;
          }
      }
      &--advice{
          &:hover{
              background: none;
          }
      }
  }

  &__documentation-icon-type{
      //color: $primary-color;
      font-size: 25px;
      float: left;
      padding-right: 11px;
      padding-top: 9px;
  }
  &__documentation-document-info{
      float: left;
      margin: 6px 0 0 0;
  }
  &__documentation-document-name{
      min-height: 12px;
      width: 308px;
      opacity: 0.75;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      // &--ellipsis{
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
      // }
  }
  &__documentation-document-size{
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      margin-top: 5px;
  }

  &__documentation-menu{
      float: right;
  }

  &__documentation-menu-options{
      display: none;
      height: 42px;
      width: 73px;
      padding-left: 5px;
      background: $background-grey-other;
      border-radius: 21px 0 0 21px;
      border: 1px solid $menu-icons-border;

      &--error{
          width: 97px;
          padding-left: 16px;
      }
  }
  &__documentation-menu-options-button{
      background: none;
      border: none;
      outline: none;
      color: $primary-color;
      position: relative;
      padding: 0;
      font-size: 20px;
      top: 10px;
      &--more{
          // padding: 10px 2px 0 0;
          // font-size: 18px;
          padding-top: 5px;
      }

      &--rename{

          left: 13px;
      }
      &--rename-error{
          left: 4px;
      }
      &--delete{
          float: right;
          padding: 0 7px 0 0;
      }

  }
  &__progress-upload-block{
      position: absolute;
      z-index: 10;
      background-color: $yellow;
      height: 1px;
      bottom: 0;

      &--complete{
          background-color:$green;
      }
      &--upload-server{
          animation:  1.5s  blinkUpload infinite;
      }
      &--error{
          background-color: $red;
      }
  }
  &__progress-upload-line{
      width: 7px;
      height: 7px;
      position: absolute;
      z-index: 12;
      background-color: $yellow;
      top: -6px;
      right: -1px;
      &--complete{
          background-color:$green;
          right: 9px;
      }
      &--upload-server{
          animation:  1.5s  blinkUpload infinite;
          right: 9px;
      }
      &--error{
          background-color: $red;
          right: 9px;
      }
  }

  &__progress-upload-text{
      position: absolute;
      z-index: 12;
      bottom: -2px;
      right: 19px;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 10px;
      font-weight: 500;
      margin: 0 0 1px;
      &--error{
         color:$red;
      }
  }

  &__title-create-icon{
      color: $create-title;
      font-size: 20px;
      padding-right: 10px;
      top: 5px;
      position: relative;
  }
  &__title-create-text{
      color: $create-title;
      font-family: MTTMilano, sans-serif;
      font-size: 11px;
      font-weight: bold;
      margin: 43px 0 19px;
      text-transform: uppercase;
      padding-top: 3px;
      text-align: center;
  }

  &__new-document-form{
      height: 144px;
      width: 345px;
      margin: 0 auto;
  }
  &__upload-new-document{
      width: 203px;
  }

  &__file-upload{
      width: 0px;
      height: 0px;
      visibility: hidden;
      float: right;
  }

  &__form-block{
      height: 267px;
      margin-left: 6%;
      margin-right: 6%;
      padding-top: 7%;
  }

  &__form-up-file{
      border: 1px dotted;
      width: 100%;
      height: 56px;
      display: block;
      overflow: hidden;
      &--alert{
          margin-bottom:7px;
      }
  }

  &__input-header{
      width: 100%;
      display: block;
      overflow: hidden;
  }

  &__name-img{
      margin-left: 8px;
      margin-top: -3px;
      margin-bottom: 0;
      &--error{
          color: $red;
      }
  }

  &__up-file{
      font-size: 19px;
      position: relative;
      float: right;
      right: 0px;
      margin-top: 4px;
      color: $primary-color;
      &:hover{
          cursor: pointer;
      }

  }
  &__parent-name-box{
      height: 20px;
      overflow: hidden;
  }
  &__show-alert{
      border: 1px solid $red !important;
      background-color: $red-opacity !important;
  }


  &__radio-button-block {
      width: 100%;
      margin-bottom: 14px;
  }
  &__switch-top {
      margin-top: 10px;
      margin-left: 300px;
  }
  &__check-template{
      margin-left: 29%;
  }

  &__content-block-new-task{
      width: 360px;
      margin: 106px auto;
      display: block;
      height: 150px;
  }

  &__block-textarea{
      margin-top: 14px;
      position: relative;
      float: left;
      width: 100%;
      border: 1px solid $border-color-other;
      color: $primary-color;
      &--landing-custom{
          border-color: $custom-border-grey;
          color: $custom-black-landing;
          box-shadow: 0 1px 4px 0 $modal-help-box;
          margin: 14px 5px 0;
          width: calc(100% - 10px);
          &:hover {
              border-color: $custom-black-landing;
          }
      }
  }

  &__block-textarea-head{
      width: 100%;
      font-family: 'MTTMilano-Bold', sans-serif;
      font-size: 12px;
      padding: 7px 0 0 10px;
      &--landing-custom{
          font-family: 'lato_regular', sans-serif;
          font-size: 13px;
          font-weight: normal;
      }
  }

  &__add-comment{
      width: 100%;
      min-height: 107px;
      max-height: 107px;
      resize: none;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      outline: 0;
      padding: 7px 30px 5px 10px;
      border: none;
      &--landing-custom{
          color: $custom-black-landing;
          min-height: 143px;
          max-height: 143px;
      }
  }

  &__block-progress{
      display: block;
      margin-bottom: 20px;
  }

  ///////////////////////////////////////////////
  //////////////////CUSTOM//////////////////////
  /////////////////////////////////////////////

  &__upload-box-custom{
      height: 98px;
      width: calc(50% - 3px);
      border-radius: 8px;
      background-color: $background-grey;
      display: block;
      position: relative;
      float: left;
      color: $custom-black-landing;
      margin-right: 6px;
      &.dz-drag-hover{
          color: $green;
      }
  }

  &__drop-icon-custom{
      font-size: 35px;
      text-align: center;
      margin-top: 19px;
      display: block;
  }

  &__dropzone-text-custom{
      font-family: 'lato_regular', sans-serif;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      margin: 12px 0 0 0;
  }

  &__explore-box-custom{
      height: 98px;
      width: calc(50% - 3px);
      border: 2px solid $background-grey;
      border-radius: 8px;
      position: relative;
      float: left;
  }

  &__modal-content-block-custom{
      padding: 0 10px 10px;
      display: block;
      overflow: hidden;
      width: 100%;
      margin-bottom: 70px;
      &--with-list{
          margin-top: 24px;
      }
  }

  &__button-explore-box-custom{
      height: 96px;
      width: 336px;
      font-family: 'lato_regular', sans-serif;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      color: $attachment-custom-grey;
      padding: 0 0 7px 0;
      background-color: transparent;
      border: none;
      outline: none;
  }

  &__icon-search-explore-custom {
      font-size: 25px;
      position: relative;
      top: 5px;
      padding-right: 7px;
  }

  &__documentation-block-custom{
      display: block;
      overflow: hidden;
      position: relative;
      float: left;
      width: 100%;
      margin: 40px 0 0;
  }

  &__documentation-header-custom {
      display: block;
      width: 100%;
      overflow: hidden;
      border-bottom: 1px solid $custom-border-grey;
      padding-bottom: 4px;
  }

  &__documentation-title-custom{
      color: $custom-black-landing;
      font-family: 'lato_regular', sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      float: left;
      margin: 0;
  }

  &__documentation-icon-custom{
      color: $custom-black-landing;
      font-family: MTTMilano, sans-serif;
      font-size: 16px;
      float: right;
      position: relative;
      right: 5px;
  }

  &__documentation-list-custom{
      margin: 0;
      padding: 0;
      list-style: none;
      height: auto;
      overflow: auto;
  }

  &__documentation-item-custom{
      display: block;
      width: 100%;
      height: 33px;
      border-bottom: 1px solid $custom-border-grey;
      position: relative;
      &--updating{
          height: 46px;
      }
      &--error{
          background: $documentation-item;
          &:hover{
              background: $documentation-item;
          }
      }
  }

  &__documentation-icon-type-custom{
      color: $attachment-custom-grey;
      font-size: 15px;
      float: left;
      padding-right: 7px;
  }

  &__block-element-list-custom{
      position: relative;
      width: 100%;
      height: 29px;
  }

  &__element-list-custom{
      position: relative;
      float: left;
      width: calc(100% - 50px);
      padding-top: 9px;
  }

  &__documentation-document-name-custom{
      font-family: 'lato_regular', sans-serif;
      font-size: 13px;
      line-height: 15px;
      float: left;
      position: relative;
      top: 0.5px;
      max-width: calc(100% - 55px);
  }

  &__documentation-document-size-custom{
      font-family: Arial, sans-serif;
      font-size: 13px;
      line-height: 16px;
      position: relative;
      top: 0.5px;
      color: $attachment-custom-grey;
      float: right;
  }

  &__documentation-menu-options-button-custom {
      background: none;
      border: none;
      outline: none;
      color: $attachment-custom-grey-other;
      position: relative;
      padding: 0;
      &--delete {
          font-size: 18px;
          float: right;
          top: 8px;
          padding: 0 0px 0 5px;
      }
  }

  &__background-upload-block-custom{
      width: calc(100% - 45px);
      position: relative;
      float: left;
      z-index: 10;
      height: 3px;
      background-color: $custom-border-grey;
      margin-top: 5px;
  }

  &__progress-upload-block-custom{
      position: relative;
      background-color: $yellow;
      height: 3px;

      &--complete{
          background-color:$green
      }
      &--upload-server{
          animation:  1.5s  blinkUpload infinite;
      }
      &--error{
          background-color: $red;
      }
  }

  &__progress-upload-text-custom{
      position: relative;
      float: left;
      z-index: 12;
      color: $attachment-custom-grey-other;
      font-family: Arial, sans-serif;
      font-size: 9px;
      font-weight: 500;
      margin: 0;
      top: 1px;
      left: 6px;
      &--error{
          color:$red;
      }
  }

  &__buttons-block{
      margin-right: 10px;
  }

  ///////////////////////////////////////////////
  //////////////////FIN CUSTOM//////////////////////
  /////////////////////////////////////////////
}

.compliance{
  &__dark-blue{
    .upload-document{
      &__documentation-item{
        color: $primary-color;
        &:hover{
            background-color: $background-color-states;
        }
        &--error{
            background: $documentation-item;
            &:hover{
                background: $documentation-item;
            }
        }
      }
      &__documentation-document-name{
        color: $secondary-color;
      }
      &__documentation-document-size{
        color: $primary-color;
      }
      &__documentation-menu-options{
        background: $background-color-states;
        border: 1px solid $menu-icons-border;
      }
      &__documentation-menu-options{
        background: $background-color-states;
        border: 1px solid $menu-icons-border;
      }
      &__progress-upload-block{
        background-color: $yellow;
        &--complete{
            background-color:$green;
        }
        &--upload-server{
            animation:  1.5s  blinkUpload infinite;
        }
        &--error{
            background-color: $red;
        }
      }
      &__progress-upload-text{
        color: $primary-color;
        &--error{
            color:$red;
         }
      }
      &__progress-upload-line{
        background-color: $yellow;
        &--complete{
            background-color:$green;
        }
        &--upload-server{
            animation:  1.5s  blinkUpload infinite;
            right: 9px;
        }
        &--error{
            background-color: $red;
            right: 9px;
        }
      }
    }
  }
  &__steel-blue, &__uk-steel-blue{
    .upload-document{
      &__upload-box{
        border: 2px dotted $primary-color-2;
        &.dz-drag-hover{
            border-color: $third-color-2;
            background-color: $block-hover-2;
        }
      }
      &__dropzone-text{
        color: $primary-color-2;
      }
      &__drop-icon{
        @include color($primary-color-2);
      }
      &__drop-option-menu{
        @include color($border-hover-2);
      }
      &__drop-option-menu-icon{
        @include color($border-hover-2);
      }
      &__documentation-header{
        border-bottom: 1px solid $background-highlight-text-2;
      }
      &__documentation-title{
        @include documentationTitle($border-hover-2);
      }
      &__documentation-icon{
        background-color: $third-color-2;
        border: 2px solid $third-color-2;
        color: $primary-color-2;
      }
      &__documentation-item{
        border-bottom: 1px solid $secondary-color-2;
        color: $border-hover-2;
        &:hover{
            background-color: $block-hover-2;
        }
        &--error{
            color: $alert-color-2;
            background-color: transparent;
            // &:hover{
            //     background: rgba(226,71,81,0.2);
            // }
        }
        &--no-border{
            border: none;
        }
      }
      &__documentation-document-name{
        color: $primary-color-2;
        &--error{
            color: $alert-color-2;
        }
      }
      &__documentation-document-size{
        color: $grey-icons-2;
        &--error{
            color: $alert-color-2;
        }
      }
      &__documentation-menu-options{
        background-color: $third-color-2;
        border: 1px solid $secondary-color-2;
      }
      &__documentation-menu-options{
        background-color: $third-color-2;
        border: 1px solid $secondary-color-2;
      }
      &__documentation-menu-options-button{
        @include color($border-hover-2);
      }
      &__progress-upload-block{
        background-color: $third-color-2;
        color:$third-color-2;
        &--complete{
            background-color: $third-color-2;
        }
        &--error{
            background-color: $alert-color-2;
        }
        &--upload-server{
            animation:  1.5s  blinkUpload-2 infinite;
        }
      }
      &__progress-upload-text{
        color: $primary-color-2;
        &--error{
            color: $alert-color-2;
        }
      }
      &__progress-upload-text{
        color: $primary-color;
        &--error{
            color:$red;
         }
      }
      &__progress-upload-line{
        background-color: $third-color-2;
        &--complete{
            background-color: $third-color-2;
            right: 9px;
        }
        &--upload-server{
            background-color: $third-color-2;
            animation:  1.5s  blinkUpload-2 infinite;
            right: 9px;
        }
        &--error{
            background-color: $alert-color-2;
            right: 9px;
        }
      }
    }
  }
  &__icam-red{
    .upload-document{
      &__upload-box{
        border: 2px dotted $custom-black-landing;
        &.dz-drag-hover{
            border-color: $third-color-icam;
            background-color: $hover-block-icam;
        }
      }
      &__dropzone-text{
        @include color($primary-color-icam);
      }
      &__drop-icon{
        @include color($primary-color-icam);
      }
      &__drop-option-menu{
        @include color($primary-color-icam);
      }
      &__drop-option-menu-icon{
        @include color($primary-color-icam);
      }
      &__documentation-header{
        border-bottom: 1px solid $primary-color-icam;
      }
      &__documentation-title{
        @include documentationTitle($primary-color-icam);
      }
      &__documentation-icon{
        background-color: $third-color-icam;
        border: 2px solid $third-color-icam;
        color: $custom-black-landing;
      }
      &__documentation-item{
        border-bottom: 1px solid $border-block-icam;
        color: $primary-color-icam;
        &:hover{
            background-color: $hover-block-icam;
        }
        &--error{
            color: $alert-color-2;
            background-color: transparent;
            // &:hover{
            //     background: rgba(226,71,81,0.2);
            // }
        }
        &--no-border{
            border: none;
        }
      }
      &__documentation-document-name{
        color: $custom-black-landing;
        &--error{
            color: $alert-color-icam;
        }
      }
      &__documentation-document-size{
        color: $secondary-color-icam;
        &--error{
            color: $alert-color-icam;
        }
      }
      &__documentation-menu-options{
        background-color: $primary-color-icam;
        border: 1px solid $primary-color-icam;
      }
      &__documentation-menu-options{
        background-color: $primary-color-icam;
        border: 1px solid $primary-color-icam;
      }
      &__documentation-menu-options-button{
        @include color($snow-white);
      }
      &__progress-upload-block{
        background-color: $green-task-icam;
        color:$green-task-icam;
        &--complete{
            background-color: $green-task-icam;
        }
        &--error{
            background-color: $alert-color-icam;
        }
        &--upload-server{
            animation:  1.5s  blinkUpload-2 infinite;
        }
      }
      &__block-textarea{
        border-color: $border-block-icam;
        color: $custom-black-landing;
      }
      &__progress-upload-text{
        color: $custom-black-landing;
        &--error{
            color: $alert-color-icam;
        }
      }
      &__progress-upload-line{
        background-color: $green-task-icam;
        &--complete{
            background-color: $green-task-icam;
            right: 9px;
        }
        &--upload-server{
            background-color: $green-task-icam;
            animation:  1.5s  blinkUpload-2 infinite;
            right: 9px;
        }
        &--error{
            background-color: $alert-color-icam;
            right: 9px;
        }
      }
    }
  }
  &__gm-law{
    .upload-document{
      &__upload-box{
        border: 2px dotted $custom-black-landing;
        &.dz-drag-hover{
            border-color: $third-color-gm;
            background-color: $hover-block-gm;
        }
      }
      &__dropzone-text{
        @include color($primary-color-gm);
      }
      &__drop-icon{
        @include color($primary-color-gm);
      }
      &__drop-option-menu{
        @include color($primary-color-gm);
      }
      &__drop-option-menu-icon{
        @include color($primary-color-gm);
      }
      &__documentation-header{
        border-bottom: 1px solid $primary-color-gm;
      }
      &__documentation-title{
        @include documentationTitle($primary-color-gm);
      }
      &__documentation-icon{
        background-color: $third-color-gm;
        border: 2px solid $third-color-gm;
        color: $custom-black-landing;
      }
      &__documentation-item{
        border-bottom: 1px solid $border-block-gm;
        color: $primary-color-gm;
        &:hover{
            background-color: $hover-block-gm;
        }
        &--error{
            color: $alert-color-2;
            background-color: transparent;
            // &:hover{
            //     background: rgba(226,71,81,0.2);
            // }
        }
        &--no-border{
            border: none;
        }
      }
      &__documentation-document-name{
        color: $custom-black-landing;
        &--error{
            color: $alert-color-gm;
        }
      }
      &__documentation-document-size{
        color: $secondary-color-gm;
        &--error{
            color: $alert-color-gm;
        }
      }

      &__documentation-menu-options{
        background-color: $primary-color-gm;
        border: 1px solid $primary-color-gm;
      }
      &__documentation-menu-options{
        background-color: $primary-color-gm;
        border: 1px solid $primary-color-gm;
      }
      &__documentation-menu-options-button{
        @include color($snow-white);
      }
      &__progress-upload-block{
        background-color: $green-task-gm;
        color:$green-task-gm;
        &--complete{
            background-color: $green-task-gm;
        }
        &--error{
            background-color: $alert-color-gm;
        }
        &--upload-server{
            animation:  1.5s  blinkUpload-2 infinite;
        }
      }
      &__block-textarea{
          border-color: $border-color-other;
          color: $custom-black-landing;
      }
      &__add-comment{
          color: $text-field-color-gm;
      }
      &__progress-upload-text{
        color: $custom-black-landing;
        &--error{
            color: $alert-color-gm;
        }
      }
      &__progress-upload-line{
        background-color: $green-task-gm;
        &--complete{
            background-color: $green-task-gm;
            right: 9px;
        }
        &--upload-server{
            background-color: $green-task-gm;
            animation:  1.5s  blinkUpload-2 infinite;
            right: 9px;
        }
        &--error{
            background-color: $alert-color-gm;
            right: 9px;
        }
      }
    }
  }
  &__com-de{
    .upload-document{
      &__upload-box{
        border: 2px dotted $custom-black-landing;
        &.dz-drag-hover{
            border-color: $third-color-com-de;
            background-color: $hover-block-com-de;
        }
      }
      &__dropzone-text{
        @include color($primary-color-com-de);
      }
      &__drop-icon{
        @include color($primary-color-com-de);
      }
      &__drop-option-menu{
        @include color($primary-color-com-de);
      }
      &__drop-option-menu-icon{
        @include color($primary-color-com-de);
      }
      &__documentation-header{
        border-bottom: 1px solid $primary-color-com-de;
      }
      &__documentation-title{
        @include documentationTitle($primary-color-com-de);
      }
      &__documentation-icon{
        background-color: $third-color-com-de;
        border: 2px solid $third-color-com-de;
        color: $custom-black-landing;
      }
      &__documentation-item{
        border-bottom: 1px solid $border-block-com-de;
        color: $primary-color-com-de;
        &:hover{
            background-color: $hover-block-com-de;
        }
        &--error{
            color: $alert-color-2;
            background-color: transparent;
            // &:hover{
            //     background: rgba(226,71,81,0.2);
            // }
        }
        &--no-border{
            border: none;
        }
      }
      &__documentation-document-name{
        color: $custom-black-landing;
        &--error{
            color: $alert-color-com-de;
        }
      }
      &__documentation-document-size{
        color: $secondary-color-com-de;
        &--error{
            color: $alert-color-com-de;
        }
      }

      &__documentation-menu-options{
        background-color: $primary-color-com-de;
        border: 1px solid $primary-color-com-de;
      }
      &__documentation-menu-options{
        background-color: $primary-color-com-de;
        border: 1px solid $primary-color-com-de;
      }
      &__documentation-menu-options-button{
        @include color($snow-white);
      }
      &__progress-upload-block{
        background-color: $green-task-com-de;
        color:$green-task-com-de;
        &--complete{
            background-color: $green-task-com-de;
        }
        &--error{
            background-color: $alert-color-com-de;
        }
        &--upload-server{
            animation:  1.5s  blinkUpload-2 infinite;
        }
      }
      &__block-textarea{
          border-color: $border-color-other;
          color: $custom-black-landing;
      }
      &__add-comment{
          color: $text-field-color-com-de;
      }
      &__progress-upload-text{
        color: $custom-black-landing;
        &--error{
            color: $alert-color-com-de;
        }
      }
      &__progress-upload-line{
        background-color: $green-task-com-de;
        &--complete{
            background-color: $green-task-com-de;
            right: 9px;
        }
        &--upload-server{
            background-color: $green-task-com-de;
            animation:  1.5s  blinkUpload-2 infinite;
            right: 9px;
        }
        &--error{
            background-color: $alert-color-com-de;
            right: 9px;
        }
      }
    }
  }
  &__com-fr{
    .upload-document{
      &__upload-box{
        border: 2px dotted $custom-black-landing;
        &.dz-drag-hover{
            border-color: $third-color-com-fr;
            background-color: $hover-block-com-fr;
        }
      }
      &__dropzone-text{
        @include color($primary-color-com-fr);
      }
      &__drop-icon{
        @include color($primary-color-com-fr);
      }
      &__drop-option-menu{
        @include color($primary-color-com-fr);
      }
      &__drop-option-menu-icon{
        @include color($primary-color-com-fr);
      }
      &__documentation-header{
        border-bottom: 1px solid $primary-color-com-fr;
      }
      &__documentation-title{
        @include documentationTitle($primary-color-com-fr);
      }
      &__documentation-icon{
        background-color: $third-color-com-fr;
        border: 2px solid $third-color-com-fr;
        color: $custom-black-landing;
      }
      &__documentation-item{
        border-bottom: 1px solid $border-block-com-fr;
        color: $primary-color-com-fr;
        &:hover{
            background-color: $hover-block-com-fr;
        }
        &--error{
            color: $alert-color-2;
            background-color: transparent;
            // &:hover{
            //     background: rgba(226,71,81,0.2);
            // }
        }
        &--no-border{
            border: none;
        }
      }
      &__documentation-document-name{
        color: $custom-black-landing;
        &--error{
            color: $alert-color-com-fr;
        }
      }
      &__documentation-document-size{
        color: $secondary-color-com-fr;
        &--error{
            color: $alert-color-com-fr;
        }
      }

      &__documentation-menu-options{
        background-color: $primary-color-com-fr;
        border: 1px solid $primary-color-com-fr;
      }
      &__documentation-menu-options{
        background-color: $primary-color-com-fr;
        border: 1px solid $primary-color-com-fr;
      }
      &__documentation-menu-options-button{
        @include color($snow-white);
      }
      &__progress-upload-block{
        background-color: $green-task-com-fr;
        color:$green-task-com-fr;
        &--complete{
            background-color: $green-task-com-fr;
        }
        &--error{
            background-color: $alert-color-com-fr;
        }
        &--upload-server{
            animation:  1.5s  blinkUpload-2 infinite;
        }
      }
      &__block-textarea{
          border-color: $border-color-other;
          color: $custom-black-landing;
      }
      &__add-comment{
          color: $text-field-color-com-fr;
      }
      &__progress-upload-text{
        color: $custom-black-landing;
        &--error{
            color: $alert-color-com-fr;
        }
      }
      &__progress-upload-line{
        background-color: $green-task-com-fr;
        &--complete{
            background-color: $green-task-com-fr;
            right: 9px;
        }
        &--upload-server{
            background-color: $green-task-com-fr;
            animation:  1.5s  blinkUpload-2 infinite;
            right: 9px;
        }
        &--error{
            background-color: $alert-color-com-fr;
            right: 9px;
        }
      }
    }
  }
}

@media (max-width: $max-width-smartphone) { //Smartphone
  .upload-document {
      &--custom .modal-dialog .modal-content {
          width: auto;
      }

      &__upload-box-custom {
          width: 100%;
          margin: 0;
      }

      &__explore-box-custom {
          width: 100%;
          margin-top: 5px;
      }

      &__button-explore-box-custom {
          width: 100%;
      }

      &__documentation-title-custom {
          font-size: 13px;
      }

      &__documentation-icon-custom {
          font-size: 13px;
      }
  }
}
