.advice-modal{
  &--list{
      .modal-content {
          min-height: 385px;
          border-radius: 0px;
          max-height: 519px;
      }
  }
  .modal-content {
      width: 900px;
      height: auto;
      border-radius: 0px;
  }

  &__modal-body{
      height: 248px;
      width: 100%;
      display: table;
      position: relative;
  }

  &__buttons-block{
      position: absolute;
      right: 45px;
      bottom: 13px;
      &--custom{
          right: 8px;
      }
  }

  &__modal-body-icon{
      color: $snow-white;
      position: relative;
      top: -3px;
      font-size: 100px;
      padding-left: 46px;
  }

  &__modal-body-text{
      font-size: 18px;
      color: $snow-white;
      font-family: MTTMilano, sans-serif;
      text-align: left;
      display: table-cell;
      &--expand{
        padding-bottom: 44px;
      }
  }

  &__modal-body-text-link{
      text-decoration: underline;
      &:hover{
          cursor: pointer;
      }
  }

  &__modal-icon-block{
      display: table-cell;
      vertical-align: middle;
      width: 20%;
      height: 100%;
  }

  &__modal-text-block{
      display: table-cell;
      vertical-align: middle;
      width: 80%;
      height: 100%;
      padding-right: 45px;
      position: relative;
      bottom: 8px;
      &--expand{
        padding-top: 14px;
      }
  }

  &__document-block{
      display: block;
      width: 757px;
      margin: 17px auto 0;
  }

  &__document-block-title{
      height: 24px;
      border-bottom: 1px solid $primary-color;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      padding-left: 2px;
  }
  &__button-green-confirm {
    &:hover:not([disabled]){
        color: $green;
    }
  }
  &__review-pending-block{
    margin: 0 70px 52px;
  }
  &__review-pending-header{
    border-bottom: 1px solid $error-header;
  }
  &__review-pending-text-header{
    color: $error-header;
    font-family: MTTMilano, sans-serif;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 6px;
  }
  &__review-pending-item-block{
    border: 1px solid $result-search-quote-color;
    width: 100%;
    height: 74px;
    padding: 19px 16px;
    margin-bottom: 12px;
  }
  &__review-pending-item-title{
    color: $primary-color;
    font-family: MTTMilano, sans-serif;
    font-size: 12px;
    font-weight: bold;

  }
  &__review-pending-item-email{
    color: $pillow-group;
    font-family: MTTMilano, sans-serif;
    font-size: 12px;
    font-weight: bold;
  }
  &__review-pending-breadcrumb-icon{
    float: left;
    color: $secondary-color;
    font-size: 16px;
    padding-right: 6px;
  }
  &__review-pending-breadcrumb-text{
    float: left;
    color: $secondary-color;
    font-family: MTTMilano, sans-serif;
    font-size: 13px;
    font-weight: bold;
  }

  ////////////////////////////////////////
  /////////////////////Custom////////////
  //////////////////////////////////////
  &__custom-window{
      & .modal-dialog{
          & .modal-content{
              height: auto;
              min-height: 223px;
              width: 700px;
              border: 1px solid $border-modal;
              border-radius: 8px;
              background-color: $snow-white;
              margin: 0 auto;
          }
      }
  }
  &__custom-modal-icon-block{
      display: block;
      width: 100%;
      text-align: center;
      font-size: 65px;
      padding-top: 9px;
      height: 82px;
      margin-bottom: 12px;
      &--rotate{
          transform: rotate(180deg);
          margin-top: 15px;
          margin-bottom: -4px;
      }
      &--confirm{
          color: $advice-confirm;
      }
      & .lf-icon-exclamation-full{
          color: $advice-exclamantion;
      }
      & .lf-icon-question-full{
          color: $advice-question;
      }
      & .lf-icon-close-round-full{
          color: $advice-close;
      }
      & .lf-icon-check-round-full{
          color: $advice-confirm;
      }
      & .lf-icon-mandatory-field{
          color: $advice-close;
      }
  }
  &__custom-modal-icon-confirm{
      color: $advice-confirm;
  }
  &__custom-modal-icon-advice{
      color: $advice-exclamantion;
  }
  &__custom-modal-body-text{
      color: $custom-black-landing;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 20px;
      text-align: center;
      margin: 0 53px;
      padding-bottom: 65px;
  }
  ////////////////////////////////////////
  /////////////////////Fin Custom////////////
  //////////////////////////////////////
}

.compliance{
  &__dark-blue{
    & .advice-modal{
      &__modal-body{
        height: 223px;
        width: calc(100% - 22px);
        margin: 11px;
        &--info{
          background-color: $primary-color;
        }
        &--error{
          background-color: $red;
        }
        &--confirm{
          background-color: $green;
        }
      }
    }
  }

  &__steel-blue,
  &__uk-steel-blue{
    & .advice-modal{
      &__modal-body{
        &--info{
          background-color: $primary-color-2;
        }
        &--error{
          background-color: $alert-color-2;
        }
        &--confirm{
          background-color: $green;
        }
      }
      &__modal-body-icon{
        color: $white-special-2;
      }
      &__modal-body-text{
        color: $white-special-2;
        font-size: 20px;
      }
    }
  }
  &__icam-red{
    & .advice-modal{
      &__modal-body{
        &--info{
          background-color: $modal-question-icam;
        }
        &--error{
          background-color: $alert-color-icam;
        }
        &--confirm{
          background-color: $green;
        }
      }
      &__modal-body-icon{
        color: $snow-white;
      }
      &__modal-body-text{
        color: $snow-white;
        font-size: 20px;
      }
    }
  }
  &__gm-law{
    & .advice-modal{
      &__modal-body{
        &--info{
          background-color: $modal-question-gm;
        }
        &--error{
          background-color: $alert-color-gm;
        }
        &--confirm{
          background-color: $green;
        }
      }
      &__modal-body-icon{
        color: $snow-white;
      }
      &__modal-body-text{
        color: $snow-white;
        font-size: 20px;
      }
    }
  }
  &__com-de{
    & .advice-modal{
      &__modal-body{
        &--info{
          background-color: $modal-question-com-de;
        }
        &--error{
          background-color: $alert-color-com-de;
        }
        &--confirm{
          background-color: $green;
        }
      }
      &__modal-body-icon{
        color: $snow-white;
      }
      &__modal-body-text{
        color: $snow-white;
        font-size: 20px;
      }
    }
  }
  &__com-fr{
    & .advice-modal{
      &__modal-body{
        &--info{
          background-color: $modal-question-com-fr;
        }
        &--error{
          background-color: $alert-color-com-fr;
        }
        &--confirm{
          background-color: $green;
        }
      }
      &__modal-body-icon{
        color: $snow-white;
      }
      &__modal-body-text{
        color: $snow-white;
        font-size: 20px;
      }
    }
  }
}

@media (max-width: $max-width-smartphone) { //Smartphone
  .advice-modal {
      &__custom-window .modal-dialog .modal-content {
          width: auto;
      }

      &__custom-modal-body-text {
          margin: 0;
          padding-left: 10px;
          padding-right: 10px;
      }

      &__buttons-block {
          &--custom {
              width: 100%;
              display: inline-flex;
              justify-content: space-evenly;
              right: unset;
          }
      }
  }
}
