.field-type-template {
  &__container {
      width: 100%;
      padding: 6px 10px 10px;
      display: inline-block;
  }

  &__text-description {
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      margin-bottom: 6px;
      display: block;
      width: 100%;
      position: relative;
      float: left;
  }

  &__block-button {
      position: relative;
      text-align: right;
  }

  &__block-list-document {
      margin-top: 10px;
  }

  &__element-list-document {
      display: table;
      width: calc(100% - 20px);
      height: 40px;
      margin: 0 10px;
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: 500;
      border-bottom: 1px solid $box-comment-border-2;
      &:last-child {
          border-bottom: none;
      }
  }

  &__table-cell-document {
      width: calc(100% - 60px);
      display: table-cell;
      vertical-align: middle;
  }

  &__table-cell-options {
      width: 60px;
      display: table-cell;
      vertical-align: middle;
      text-align: right;
      font-size: 20px;
  }

  &__icon-list {
      background-color: transparent;
      outline: none;
      border: none;
      padding: 0;
      margin-left: 9px;
      &--first {
          margin-left: 0;
      }
  }
  ////////////////////////
  /////////Custom/////////
  ////////////////////////
  &__custom-input-block-field {
      border-color: $border-modal;
      border-radius: 8px;
      background-color: $snow-white;
      // box-shadow: 0 1px 4px 0 $modal-help-box;
      margin-bottom: 15px;
      position: relative;
      padding: 20px 20px 20px 15px;
      &--no-editable {
          border-color: $attachment-custom-grey-other;

          &:hover {
              cursor: not-allowed;
          }
      }
      &--required {
          border-color: $advice-close;
      }
  }

  &__custom-icon-type-header {
      position: relative;
      float: left;
      color: $custom-black-landing;
      font-size: 40px;
      bottom: 3px;
      right: 2px;
      &--no-editable {
          color: $attachment-custom-grey-other;
      }
      &--required {
          color: $advice-close;
      }
  }

  // &__cell-block-custom {
  //     font-weight: bold;
  // }

  &__custom-container {
      width: 100%;
      display: inline-block;
  }

  &__custom-element-list-document {
      display: table;
      width: 100%;
      height: 46px;
      color: $custom-black-landing;
      font-family: MTTMilano, sans-serif;
      font-size: 11px;
      font-weight: 500;
      border-bottom: 1px solid $custom-border-grey;

      &:first-child {
          border-top: 1px solid $custom-border-grey;
      }
  }

  &__custom-table-cell-document {
      width: calc(100% - 75px);
      display: table-cell;
      vertical-align: middle;
  }

  &__custom-element-list-title {
      text-transform: uppercase;
  }

  &__custom-element-list-value {
      font-family: 'lato_regular', sans-serif;
      font-size: 13px;
      font-weight: bold;
  }

  &__custom-table-cell-options {
      width: 75px;
      display: table-cell;
      vertical-align: middle;
      text-align: right;
      font-size: 19px;
  }

  &__custom-icon-list {
      background-color: transparent;
      outline: none;
      border: none;
      padding: 0;

      &--download {
          font-size: 18px;
      }

      &--first {
          margin-right: 21px;
      }
  }
  ////////////////////////
  ///////Fin Custom///////
  ////////////////////////
}
.compliance{
  &__dark-blue{
    .field-type-template {
      &__block-list-document {
        background-color: $background-grey-other;
      }
      &__element-list-document {
        color: $primary-color;
      }
      &__element-list-title {
        color: $repeat-pattern;
      }
      &__icon-list {
        color: $primary-color;
      }
    }
  }
  &__steel-blue {
    .field-type-template {
      &__icon-list {
        color: $border-hover-2;
      }
      &__text-description {
        color: $text-field-color-2;
      }
      &__block-list-document {
        background-color: $background-grey-2;
      }
      &__element-list-title {
        color: $text-field-color-2;
      }
      &__element-list-document {
        border-bottom-color: $secondary-color-2;
      }
      &__element-list-filename {
        font-weight: bold;
        color: $primary-color-2;
      }
    }
  }
  &__uk-steel-blue {
    .field-type-template {
      &__icon-list {
        color: $border-hover-2;
      }
      &__text-description {
        color: $text-field-color-2;
      }
      &__block-list-document {
        background-color: $background-grey-2;
      }
      &__element-list-title {
        color: $text-field-color-2;
      }
      &__element-list-document {
        border-bottom-color: $secondary-color-2;
      }
      &__element-list-filename {
        font-weight: bold;
        color: $primary-color-2;
      }
    }
  }
  &__icam-red{
    .field-type-template {
      &__text-description {
        color: $text-field-color-icam;
      }
      &__block-list-document {
        background-color: $background-grey-icam;
      }
      &__element-list-title {
        color: $text-field-color-icam;
      }
    }
  }
  &__gm-law{
    .field-type-template {
      &__text-description {
        color: $text-field-color-gm;
      }
      &__block-list-document {
        background-color: $background-grey-gm;
      }
      &__element-list-title {
        color: $text-field-color-gm;
      }
    }
  }
  &__com-de{
    .field-type-template {
      &__text-description {
        color: $text-field-color-com-de;
      }
      &__block-list-document {
        background-color: $background-grey-com-de;
      }
      &__element-list-title {
        color: $text-field-color-com-de;
      }
    }
  }
  &__com-fr{
    .field-type-template {
      &__text-description {
        color: $text-field-color-com-fr;
      }
      &__block-list-document {
        background-color: $background-grey-com-fr;
      }
      &__element-list-title {
        color: $text-field-color-com-fr;
      }
    }
  }
  &__landing-custom{
    .field-type-template {
      &__text-description {
        color: $attachment-custom-grey;
        font-family: MTTMilano-Bold, sans-serif;
        font-size: 14px;
        padding: 12px 15px 0 50px;
        &-no-editable {
            color: $attachment-custom-grey-other;
        }
        &--required {
            color: $custom-color-E08785;
        }
      }
    }
  }
}
