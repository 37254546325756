.new-report {
  &__block-body {
      position: relative;
      height: 451px;
  }

  &__report-form {
      width: 600px;
      display: block;
      margin: 75px auto 0px;
  }
}

.compliance{
  &__icam-red{
    .new-report {
      &__window{
        & .modal-dialog{
            & .modal-content{
                height: 400px;
            }
        }
      }

      &__block-body {
          height: 250px;
      }
    }
  }
}
