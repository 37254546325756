.no-permissions {
  font-family: MTTMilano, sans-serif;
  font-size: 18px;
  font-weight: normal;
  color: $primary-color;
  text-align: center;

  &__title {
      font-size: 26px;
      margin-top: 20px;
      margin-bottom: 7px;
  }

  &__icon {
      font-size: 140px;
      line-height: 0;
  }

  &__text {
      color: $concept-blue;
  }

  &__first-admin {
      margin-top: 23px;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 14px;
      min-height: 19px;

      & > .lf-icon-add-empty {
          font-size: 15px;
      }
  }

  &__button {
      margin-top: 30px;
      width: 100%;
      outline: none;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 13px;
      text-transform: uppercase;
      color: $snow-white;
      background-color: $primary-color;
      border: 3px solid $primary-color;
      line-height: 32px;
  }

  &__admins {
      font-family: "MTTMilano-Bold", sans-serif;
      font-size: 14px;
  }

  &__admins-title {
      margin: 20px 0 50px;
      font-size: 14px;
      text-transform: uppercase;
  }

  &__close-admins-button{
      float: right;
      font-size: 15px;
  }

  &__admins-list {
      margin-bottom: 38px;
      padding-left: 0;
      height: 204px;
      overflow-y: auto;
  }

  &__admins-list-element {
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
  }

  &__link{
      color: inherit;
      text-decoration: underline;
      &:visited, &:hover, &:focus{
          color: inherit;
      }
  }

  &__animate.ng-hide-remove {
      -webkit-transition: 0.5s ease-in-out opacity;
      -moz-transition: 0.5s ease-in-out opacity;
      -ms-transition: 0.5s ease-in-out opacity;
      -o-transition: 0.5s ease-in-out opacity;
      transition: 0.5s ease-in-out opacity;
      opacity: 0.5;
  }

  &__animate.ng-hide {
      opacity: 0;
  }
}

.compliance{
  &__steel-blue,
  &__uk-steel-blue{
    .no-permissions {
      color: $primary-color-2;
      &__text {
        color: $grey-icons-2;
      }
      &__button {
        border-color: $border-hover-2;
        background-color: $snow-white;
        color: $border-hover-2;
        &:hover{
            background-color: $border-hover-2;
            color: $snow-white;
        }
      }
      &__link{
        color: $border-hover-2;
        &:visited, &:hover, &:focus{
            color: $border-hover-2;
        }
      }
    }
  }
}
