.add-alert {
  &__window {
      & .modal-dialog {
          & .modal-content {
            min-height: 608px;
            border-radius: 0px;
            height: auto;
          }
      }

      &--reference-date {
          & .modal-dialog {
              & .modal-content {
                  height: 640px;
                  border-radius: 0px;
              }
          }
      }
  }

  &__alert-form {
      width: 485px;
      height: 393px;
      margin: 25px auto 0;
      display: block;
  }

  &__form-share {
      height: 374px;
      width: calc(100% - 142px);
      margin: 10px auto 0;
  }

  &__icon-edit {
      cursor: pointer;
      float: right;
      margin-left: 10px;
      font-size: 14px;
  }

  &__icon-visible {
      font-size: 20px;
      position: relative;
      float: left;
      bottom: 3px;
      margin-right: 3px;
  }

  &__preview {
      float: left;
      padding: 13px 0 0;
      font-size: 11px;
      font-weight: bold;
      cursor: pointer;
  }

  &__repeat-alert-form {
      width: 485px;
      height: auto;
      margin: 17px auto 0px;
  }

  &__list-option-interval {
      font-weight: 500;
      padding: 3px 0 3px 8px;
      height: 30px;
      width: 100%;
      margin: 2px 0;

      &--no-selected {
          &:hover {
              cursor: pointer;
          }
      }
  }

  &__inputs-process-next {
      margin: 7px 0 0 23px;
  }

  // &__show-text-area {
  //   height: 259px;
  // }

  &__select-simple-repeat {
      display: block;
      height: 30px;
      width: 130px;
      float: left;
      margin: 0 5px;
      position: relative;
      bottom: 2px;

      &--options {
          width: 145px;
          margin-right: 0px;
      }
  }

  &__edit-pattern {
      margin-top: 25px;
  }

  &__repeat-icon {
      position: relative;
      font-size: 20px;
      top: 4px;
      margin-right: 5px;
  }

  &__repeat-title {
      color: $subcontainer-ground;
      font-size: 12px;
      font-weight: normal;
      font-family: MTTMilano-Bold, sans-serif;
      text-transform: uppercase;
      text-align: center;
      line-height: 24px;
      margin: 0;
  }

  &__notify-switches {
      display: flex;
      margin-bottom: 10px;
  }

  &__no-first-switch {
      position: relative;
      left: 20px;
  }

  &__text-rich {
      display: block;
      width: 100%;
      overflow: hidden;
      height: 225px;
  }

  &__wizard-steps {
      display: block;
      width: 100%;
      overflow: hidden;
      margin: 0;
      height: 100px;

      &--concept-share {
          height: auto;
      }
  }

  &__reference-date {
      height: 440px;
      margin: 20px auto 0;
  }

  &__margin {
      margin-bottom: 23px;
  }

  &__periodicity-block {
      width: 100%;
      display: block;
      min-height: 69px;
      clear: both;
      border: 1px solid $result-search-quote-color;
      background-color: $snow-white;
      margin-bottom: 10px;
      margin-top: 5px;

      &--repeat-patterns {
          height: 104px;
          margin: 10px 0 0;
      }

      &--process {
          height: 132px;
      }
  }

  &__label-block {
      position: relative;
      float: left;
      max-width: calc(100% - 25px);
      padding: 5px 0 0 10px;
      line-height: 17px;
      margin-bottom: 3px;

      &--margin-8 {
          margin-bottom: 8px;
      }
  }

  &__options-notification-moment {
      clear: both;
      margin-left: 10px;
  }

  &__option-text {
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      float: left;
      margin-top: 5px;

      &--first {
          margin-left: 5px;
      }
  }
  &__configuration-step{
      min-height: 440px;
  }
}

.compliance{
  &__landing-custom {
    .add-alert {
      &__alert-form {
        width: auto;
        height: 457px;
        margin: 44px 276px 0px;
      }
    }
  }

  &__dark-blue{
    .add-alert{
      &__form-share {
        height: 374px;
      }

      &__preview {
        color: $primary-color;
      }

      &__repeat-title {
        color: $subcontainer-ground;
      }
    }
  }

  &__steel-blue, &__uk-steel-blue {
    .add-alert{
      &__form-share {
        height: 380px;
      }

      &__preview {
        color: $border-hover-2;
      }

      &__repeat-title {
        color: $grey-icons-2;
      }

      &__periodicity-block {
        border-color: $secondary-color-2;
      }

      &__option-text {
        color: $primary-color-2;
      }
    }
  }

  &__icam-red {
    .add-alert{
      &__form-share {
        height: 380px;
      }

      &__icon-edit {
        color: $primary-color-icam;
      }

      &__preview {
        color: $primary-color-icam;
      }

      &__repeat-title {
        color: $secondary-color-icam;
      }

      &__periodicity-block {
        border-color: $border-block-icam;
      }

      &__option-text {
        color: $custom-black-landing;
      }
    }
  }

  &__gm-law {
    .add-alert{
      &__alert-form{
        width: 550px;
      }
      &__form-share {
        height: 380px;
      }

      &__icon-edit {
        color: $primary-color-gm;
      }

      &__preview {
        color: $primary-color-gm;
      }

      &__repeat-title {
        color: $secondary-color-gm;
      }

      &__periodicity-block {
        border-color: $border-block-gm;
      }

      &__option-text {
        color: $custom-black-landing;
      }
    }
  }
  &__com-de {
    .add-alert{
      &__alert-form{
        width: 550px;
      }
      &__form-share {
        height: 380px;
      }

      &__icon-edit {
        color: $primary-color-com-de;
      }

      &__preview {
        color: $primary-color-com-de;
      }

      &__repeat-title {
        color: $secondary-color-com-de;
      }

      &__periodicity-block {
        border-color: $border-block-com-de;
      }

      &__option-text {
        color: $custom-black-landing;
      }
    }
  }
  &__com-fr {
    .add-alert{
      &__alert-form{
        width: 550px;
      }
      &__form-share {
        height: 380px;
      }

      &__icon-edit {
        color: $primary-color-com-fr;
      }

      &__preview {
        color: $primary-color-com-fr;
      }

      &__repeat-title {
        color: $secondary-color-com-fr;
      }

      &__periodicity-block {
        border-color: $border-block-com-fr;
      }

      &__option-text {
        color: $custom-black-landing;
      }
    }
  }
}
