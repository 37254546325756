@mixin backgroundColor($color){
  background-color: $color;
}
@mixin public-link-block-step($backgroundColor, $color, $errorBackgroundColor, $saveBackgroundColor){
  background-color: $backgroundColor;
  color: $color;
  &--error{
      background-color: $errorBackgroundColor;
  }
  &--save{
      background-color: $saveBackgroundColor;
  }
}
.modal-xl {
  width: 100%;
}

.public-link {
  width: 100%;
  height: 100%;
  padding: 10px;

  &__window {
      & .modal-dialog {
          & .modal-content {
              margin: 0 auto;
              border-radius: 0px;
              height: 756px;
          }
      }
  }

  &__block-info-concept {
      background-color: $no-primary-blue;
      position: relative;
      float: left;
      height: 733px;

      &--show {
          width: 369px;
      }

      &--noshow {
          width: 100px;
      }
  }

  &__icon-link {
      display: block;
      font-size: 100px;
      text-align: center;
      color: $snow-white;

      &--compiled {
          font-size: 62px;
      }
  }

  &__text-info-block {
      display: block;
      margin-left: 60px;
      margin-right: 60px;
  }

  &__title-info {
      text-transform: uppercase;
      color: $snow-white;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      margin: 0;
  }

  &__text-info {
      opacity: 0.7;
      color: $snow-white;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      line-height: 17px;
      margin-top: 13px;
      margin-bottom: 19px;
      padding: 0;
      counter-reset: item;
  }

  &__icon-close {
      float: right;
      font-size: 20px;
      cursor: pointer;
  }

  &__list-info {
      list-style: none;
      &:before {
          content: counter(item) ") ";
          counter-increment: item;
      }
  }

  &__list-info-messy {
      list-style: none;

      &:before {
          content: "* ";
          top: 3px;
          position: relative;
      }
  }

  &__buttons-block {
      bottom: 15px;
      text-align: right;
      position: absolute;
      right: 70px;
  }

  &__block-total-steps {
      position: relative;
      float: left;
      padding: 32px 35px 15px 57px;
      width: calc(100% - 369px);
      height: 733px;

      &--no-show {
          width: calc(100% - 100px);
      }
  }

  &__check-block {
      position: absolute;
      bottom: 21px;
      width: calc(100% - 454px);
  }

  &__block-step {
      position: relative;
      float: left;
      height: 520px;
      width: 304px;
      background-color: $background-grey;
      box-shadow: 0 0 5px 1px $block-step-shadow;
      margin-left: 33px;
      color: $primary-color;
      padding: 14px 28px 20px 28px;
      border-radius: 3px;
      &--first {
          margin-left: 0px;
      }
  }

  &__title-block-steps {
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      position: absolute;
      width: 83%;
  }


  &__block-header-step {
      height: 97px;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      &--error {
          color: $red;
      }
  }

  &__icon-header {
      font-size: 31px;
      font-weight: normal;
      position: absolute;
      left: -5%;
      top: 4px;
  }

  &__block-body-step {
      position: relative;
      float: left;
      width: 100%;
  }

  &__icon-check {
      position: relative;
      float: left;
      text-align: center;
      margin-bottom: 55px;
      width: 100%;
      opacity: 0.2;
      font-size: 100px;
      color: $primary-color;
      &--required {
          color: $alert-color-button-2;
      }
      &--saved {
          color: $icon-check-saved;
      }
  }

  &__text-step {
      color: $secondary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      line-height: 18px;
  }

  &__text-step-required {
      color: $alert-color-button-2;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      &--required{
          margin-bottom: 2px;
      }
  }
  &__text-step-info{
      color: $public-link-text-step;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
  }

  &__block-steps-scroll {
      width: 100%;
      position: relative;
      float: left;
      overflow-x: auto;
      overflow-y: hidden;
      top: 55px;
      &--published{
          top: 29px;
      }
  }

  &__block-all-steps {
      width: 986px;
      position: relative;
      float: left;
      padding-top: 3px;
      padding-left: 3px;
      height: 530px;
  }

  &__block-finish-steps {
      position: relative;
      // float: left;
      width: 757px;
      height: 100%;
      // margin-left: 120px;
      // margin-top: 55px;
      margin: 55px auto 0;
      // &--no-show {
      //     margin-left: 0px;
      // }
  }

  &__link-text{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 13px;
      padding: 0 11px;
      width: 650px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      float: left;
      margin-top: 15px;
      &--other{
          overflow: unset;
      }
  }

  &__copy-link-block {
      position: relative;
      float: left;
      display: table;
      height: 50px;
      width: 100%;

      &--init{
          width: calc(100% - 33px);
          top: -17px;
      }
  }

  &__icon-step-done {
      font-size: 40px;
      position: absolute;
      left: -67px;
  }

  &__link-description {
      font-family: MTTMilano, sans-serif;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 0px;
  }

  &__block-link-description {
      height: 95px;
      position: relative;
      float: left;
      color: $primary-color;
  }

  &__block-share-info{
      margin-top: 30px;
      position: relative;
      float: left;
      width: 100%;
      // max-height: 330px;
      // overflow-x: auto;
  }

  &__contacts-block {
      position: relative;
      float: left;
      width: 100%;
  }

  &__textarea-link-block {
      position: relative;
      float: left;
      width: 100%;
      margin-bottom: 10px;
  }

  &__block-check-notification {
      position: relative;
      float: left;
  }

  &__edit-button {
      position: absolute;
      bottom: 20px;
      right: 28px;
      border-bottom: 2px solid $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 11px;
      text-transform: uppercase;
      padding-left: 5px;
      padding-right: 14px;

      & > i {
          font-size: 18px;
          margin-right: 7px;
          vertical-align: middle;
      }

      &:hover{
          background-color: $primary-color;
          color: $snow-white;
      }
  }

  &__margin-icon{
      margin-right: 7px;
      font-size:20px;
      margin-top: -2px;
      float: left;
  }
  &__tooltip{
      width: 234px;
      & > .tooltip-inner{
          border-bottom-width: 6px ;
          border-radius: 0px ;
          text-align: left;
          width: unset ;
      }
  }

  &__button-box-reports-edit{
      position: absolute;
      bottom: 20px;
      right: 28px;
  }
  &__button-copy-link-init {
      float: right;
      margin-top: 13px;
      margin-right: 9px;
      margin-left: 9px;
  }
  &__button-copy-link {
      position: relative;
      float: right;
      margin-top: 20px;
      &--no-first{
        margin-left: 20px;
      }
  }

}

.compliance{
  &__dark-blue{
    .public-link {
      &__copy-link-block {
        color: $primary-color;
        background-color: $background-color-states;
      }
      &__icon-close {
        color: $primary-color;
      }
      &__button{
        border: none;
        height: 26px;
        margin-bottom: 3px;
        width: 100%;
        color: $primary-color;
        position: relative;
        top: 1px;
        outline: none;
        background-color: transparent;
        &:hover{
            background-color: $primary-color;
            color: $snow-white;
        }
      }
      &__icon-check{
        &--required {
            color: $alert-color-button-2;
        }

        &--saved {
            color: $icon-check-saved;
        }
      }
      &__block-header-step {
        &--error {
          color: $red;
        }
      }
      &__block-step{
        @include public-link-block-step($background-color-states, $primary-color, $red-opacity-02, $green-opacity-02);
      }
    }

  }
  &__steel-blue{
    .public-link {
      &__button{
        border: none;
        height: 28px;
        margin-bottom: 1px;
        width: 100%;
        position: relative;
        top: 1px;
        outline: none;
        background-color: transparent;
        color:$border-hover-2;
        &:hover{
            background-color: $third-color-2;

        }
      }
      &__block-link-description{
        color: $primary-color-2;
      }
      &__copy-link-block{
        background-color: $background-grey-disabled-2;
        color: $primary-color-2;
      }
      &__text-step-info{
        color: $grey-icons-2;
      }
      &__text-step-required{
        color: $alert-color-2;
      }
      &__text-step{
        color: $primary-color-2;
      }
      &__icon-check{
        color: $primary-color-2;
        &--required {
            color: $alert-color-icon-2;
        }
        &--saved {
            color: $border-hover-2;
        }
      }
      &__block-header-step{
        &--error{
            color:$alert-color-2;
        }
      }
      &__title-block-steps{
        color: $primary-color-2;
      }
      &__block-step{
        @include public-link-block-step($background-grey-disabled-2, $primary-color-2, $background-error, $block-hover-2);
      }
      &__icon-close{
        color:$border-hover-2;
      }
      &__text-info{
        color: $white-special-2;
        opacity: 0.4;
      }
      &__title-info{
        color: $white-special-2;
      }
      &__icon-link{
        color: $white-special-2;
      }
      &__block-info-concept{
        @include backgroundColor($primary-color-2)
      }
    }
  }

  &__icam-red{
    .public-link {
      &__block-link-description {
        color: $custom-black-landing;
      }
      &__icon-step-done{
        color: $primary-color-icam;
      }
      &__copy-link-block{
        background-color: $background-grey-disabled-icam;
        color: $custom-black-landing;
      }
      &__text-step-info{
        color: $secondary-color-icam;
      }
      &__text-step-required{
        color: $alert-color-icam;
      }
      &__text-step{
        color: $custom-black-landing;
      }
      &__icon-check{
        color: $text-field-color-icam;
        &--required {
            color: $alert-color-icam;
        }
        &--saved {
            color: $green-task-icam;
        }
      }
      &__title-block-steps{
        color: $custom-black-landing;
      }
      &__block-step{
        @include public-link-block-step($background-grey-disabled-icam, $custom-black-landing, $red-task-20-icam, $green-task-20-icam);
      }
      &__icon-close{
        color: $primary-color-icam;
      }
      &__block-info-concept{
        @include backgroundColor($primary-color-icam)
      }
    }
  }
  &__gm-law{
    .public-link {
      &__block-link-description {
        color: $custom-black-landing;
      }
      &__icon-step-done{
        color: $primary-color-gm;
      }
      &__copy-link-block{
        background-color: $background-grey-disabled-gm;
        color: $custom-black-landing;
      }
      &__text-step-info{
        color: $secondary-color-gm;
      }
      &__text-step-required{
        color: $alert-color-gm;
      }
      &__text-step{
        color: $custom-black-landing;
      }
      &__icon-check{
        color: $text-field-color-gm;
        &--required {
            color: $alert-color-gm;
        }
        &--saved {
            color: $green-task-gm;
        }
      }
      &__title-block-steps{
        color: $custom-black-landing;
      }
      &__block-step{
        @include public-link-block-step($background-grey-disabled-gm, $custom-black-landing, $red-task-20-gm, $green-task-20-gm);
      }
      &__icon-close{
        color: $primary-color-gm;
      }
      &__block-info-concept{
        @include backgroundColor($primary-color-gm)
      }
    }
  }
  &__com-de{
    .public-link {
      &__block-link-description {
        color: $custom-black-landing;
      }
      &__icon-step-done{
        color: $primary-color-com-de;
      }
      &__copy-link-block{
        background-color: $background-grey-disabled-com-de;
        color: $custom-black-landing;
      }
      &__text-step-info{
        color: $secondary-color-com-de;
      }
      &__text-step-required{
        color: $alert-color-com-de;
      }
      &__text-step{
        color: $custom-black-landing;
      }
      &__icon-check{
        color: $text-field-color-com-de;
        &--required {
            color: $alert-color-com-de;
        }
        &--saved {
            color: $green-task-com-de;
        }
      }
      &__title-block-steps{
        color: $custom-black-landing;
      }
      &__block-step{
        @include public-link-block-step($background-grey-disabled-com-de, $custom-black-landing, $red-task-20-com-de, $green-task-20-com-de);
      }
      &__icon-close{
        color: $primary-color-com-de;
      }
      &__block-info-concept{
        @include backgroundColor($primary-color-com-de)
      }
    }
  }
  &__com-fr{
    .public-link {
      &__block-link-description {
        color: $custom-black-landing;
      }
      &__icon-step-done{
        color: $primary-color-com-fr;
      }
      &__copy-link-block{
        background-color: $background-grey-disabled-com-fr;
        color: $custom-black-landing;
      }
      &__text-step-info{
        color: $secondary-color-com-fr;
      }
      &__text-step-required{
        color: $alert-color-com-fr;
      }
      &__text-step{
        color: $custom-black-landing;
      }
      &__icon-check{
        color: $text-field-color-com-fr;
        &--required {
            color: $alert-color-com-fr;
        }
        &--saved {
            color: $green-task-com-fr;
        }
      }
      &__title-block-steps{
        color: $custom-black-landing;
      }
      &__block-step{
        @include public-link-block-step($background-grey-disabled-com-fr, $custom-black-landing, $red-task-20-com-fr, $green-task-20-com-fr);
      }
      &__icon-close{
        color: $primary-color-com-fr;
      }
      &__block-info-concept{
        @include backgroundColor($primary-color-com-fr)
      }
    }
  }
}

@media (max-width: 1200px){
  .public-link{
      &__window{
          & .modal-dialog{
              & .modal-content{
                  // width: 800px;
                  width: 95%;
              }
          }
      }
      &__block-finish-steps {
          width: 100%;
      }
      &__icon-step-done {
          left: -50px;
      }
      &__block-check-notification{
          top: 35px;
      }
      &__buttons-block{
          right: 20px;
      }
      &__check-block{
          width: calc(100% - 388px);
      }
  }
}
@media (min-width: 576px){
  .modal-dialog {
      max-width: 1500px;
  }
}
@media (min-width: 1201px) and (max-width: 1366px) {
  .public-link{
      &__window{
          & .modal-dialog{
              & .modal-content{
                  // width: 985px;
                  width: 95%;
                  max-width: 1200px;
              }
          }
      }
      &__block-finish-steps {
          width: 675px;
      }
      &__icon-step-done {
          left: -50px;
      }
      &__buttons-block{
          right: 20px;
      }
      &__check-block{
          width: calc(100% - 388px);
      }
  }
}
@media (min-width: 1367px) and (max-width: 1500px){
  .public-link__window{
      & .modal-dialog{
          & .modal-content{
              // width: 1200px;
              width: 95%;
          }
          & .public-link__modal-size{
              width: 1231px;
          }
      }
  }

  .public-link__icon-step-done {
      left: -50px;
  }
}

@media (min-width: 1501px){
  .public-link__window{
      & .modal-dialog{
          & .modal-content{
              width: 1500px;
          }
          & .public-link__modal-size{
              width: 1231px;
          }
      }
  }
}


///////////////////////////
///inc ICAM-BJAUDENES
