@mixin menu-header-comments-color($color){
  top: 31px;
  border: 1px solid $color;
  border-top-width: 2px;
}
@mixin menu-header-comments-block-list($borderColor, $backgroundColor){
  border-top: 1px solid $borderColor;
  &:hover{
      .menu-header-comments__group-text{
          background-color: $backgroundColor;
      }
  }
}
@mixin menu-header-comments-title-header($color, $borderColor){
  color: $color;
  border-bottom: 1px solid $borderColor;
}

.menu-header-comments{
  position: absolute;
  right: 115px;
  z-index: 12;
  min-height: 200px;
  width: 350px;
  top: 32px;
  padding: 24px;
  border: 1px solid $subcontainer-border;
  background-color: $snow-white;
  box-shadow: 0 0 25px 10px $bar-horizontal-grey-opacity;

  &--comment{
      right: 117px;
  }

  &__config{
      right: 117px;
  }

  &__text-breadcrumb{
      display: block;
      margin: 4px 0px 7px;
      color: $primary-color;
      font-size: 12px;
      font-weight: 700;
  }
  &__group-text {
      position: relative;
      float: left;
      padding: 7px 10px 2px 7px;
      width: 100%;
  }
  &__filter-result{
      text-transform: uppercase;
      color: $bar-horizontal-task-grey;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      font-weight: bold;
      position: relative;
      float: left;
      height: 42px;
      margin-top: 5px;
      padding-top: 14px;
  }
  &__text-name {
      color: $bar-horizontal-task-grey;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      display: block;
      margin:0;
  }
  &__text-type{
      color: $bar-horizontal-task-grey;
      font-family: MTTMilano-Bold, sans-serif;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: bold;
      margin: 0;
  }

  &__menu-button{
      border: none;
      background: none;
      outline: none;
      margin: 10px 5px 10px 5px;
      padding: 0px;
      &--first-icon{
          margin-left: 21px;
      }
  }
  &__menu-header{
      color: $bar-horizontal-task-grey;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 14px;
      font-style: italic;
  }
  &__icon-notifications{
      font-size: 20px;
      color: $primary-color;
      margin-left: 2px;
      &:hover{
          cursor: pointer;
      }
  }
  &__icon-type-notification{
      font-size: 15px;
  }
  &__triangle-block{
      position: absolute;
      width: 0;
      height: 0;
      right: 21px;
      top: -17px;
      margin-top: -3px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid $snow-white;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
  }
  &__block-body{
      position: relative;
      float: left;
      width: 100%;
      max-height: 375px;
      overflow-y: auto;
      overflow-x: hidden;
      margin-top: 10px;
  }
  &__block-body-comments{
      position: relative;
      left: -20px;
      float: left;
      width: 110%;
      padding-left: 7px;
      max-height: 375px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 10px;
      margin-top: 10px;
  }
  &__block-line{
      position: relative;
      float: left;
      padding-left: 10px;
      width: 100%;
      border-left: 1px solid $subcontainer-border;
  }
  &__circle-icon-comment{
      font-size: 11px;
      position: absolute;
      left: -16px;
      top: -6px;
      color: $subcontainer-border;
  }
  &__block-list{
      position: relative;
      float: left;
      width: 98%;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      padding: 1px 0;
      &:hover{
          .menu-header-comments__group-list{
              visibility: visible;
          }
      }
  }
  &__group-list{
      visibility: hidden;
      position: absolute;
      float: right;
      right: 6px;
      bottom: 2px;
  }

  &__text-date{
      display: inline-block;
      color: $bar-horizontal-task-grey;
      position: relative;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0;
  }
  &__close-icon {
      float: right;
      position: relative;
      color: $primary-color;
      font-size: 13px;

      &:hover {
          cursor: pointer;
      }
  }

  &__title-header{
      position: relative;
      float: left;
      width: 100%;
      height: 28px;
      border-bottom: 1px solid $primary-color;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 18px;
      text-align: left;
      margin-bottom: 50px;
      &--notification{
          margin-bottom: 5px;
      }
      &--config{
          margin-bottom: 42px;
      }
  }
  &__breadcrumb-block{
      height: auto;
  }
}

.compliance {
  &__dark-blue {
      & .menu-header-comments {
          border-top: 2px solid $primary-color;
          &__icon-type-notification{
              color: $primary-color;
          }
          &__block-list{
              @include menu-header-comments-block-list($subcontainer-border, $background-color-states);
              &--last{
                  border-bottom: 1px solid $subcontainer-border;
              }
          }
          &__circle-icon-comment{
            &--first{
                color: $primary-color;
            }
        }
      }
  }

  &__uk-steel-blue, &__steel-blue {
      & .menu-header-comments {
          @include menu-header-comments-color($third-color-2);
          &__text-breadcrumb{
              color: $primary-color-2;
          }
          &__text-name {
              color: $grey-icons-2;
          }
          &__text-type{
              color: $grey-icons-2;
          }
          &__menu-header{
              color: $grey-icons-2;
          }
          &__icon-notifications{
              color: $border-hover-2;
          }
          &__icon-type-notification{
              color: $border-hover-2;
          }
          &__block-line{
              border-color: $third-color-2;
          }
          &__circle-icon-comment{
              color: $third-color-2;
              &--first{
                  color: $border-hover-2;
              }
          }
          &__block-list{
              @include menu-header-comments-block-list($secondary-color-2, $block-hover-2);
              &--last{
                  border-bottom: 1px solid $secondary-color-2;
              }
          }
          &__text-date{
              color: $grey-icons-2;
          }
          &__close-icon {
              color: $border-hover-2;
          }
          &__title-header{
              @include menu-header-comments-title-header($border-hover-2, $third-color-2);
          }
      }
  }

  &__gm-law {
      & .menu-header-comments {
          @include menu-header-comments-color($primary-color-gm);
          &__text-breadcrumb{
              color: $custom-black-landing;
          }
          &__text-name {
              color: $text-field-color-gm;
          }
          &__text-type{
              color: $text-field-color-gm;
          }
          &__menu-header{
              color: $secondary-color-gm;
          }
          &__icon-notifications{
              color: $primary-color-gm;
          }
          &__icon-type-notification{
              color: $primary-color-gm;
          }
          &__block-line{
              border-color: $third-color-gm;
          }
          &__circle-icon-comment{
              color: $border-block-gm;
              &--first{
                  color: $primary-color-gm;
              }
          }
          &__block-list{
              @include menu-header-comments-block-list($border-block-gm, $hover-block-gm);
              &--last{
                  border-bottom: 1px solid $border-block-gm;
              }
          }
          &__text-date{
              color: $text-field-color-gm;
          }
          &__close-icon {
              color: $primary-color-gm;
          }
          &__title-header{
              @include menu-header-comments-title-header($primary-color-gm, $third-color-gm);
          }
      }
  }
  &__com-de{
    & .menu-header-comments {
        @include menu-header-comments-color($primary-color-com-de);
        &__text-breadcrumb{
            color: $custom-black-landing;
        }
        &__text-name {
            color: $text-field-color-com-de;
        }
        &__text-type{
            color: $text-field-color-com-de;
        }
        &__menu-header{
            color: $secondary-color-com-de;
        }
        &__icon-notifications{
            color: $primary-color-com-de;
        }
        &__icon-type-notification{
            color: $primary-color-com-de;
        }
        &__block-line{
            border-color: $third-color-com-de;
        }
        &__circle-icon-comment{
            color: $border-block-com-de;
            &--first{
                color: $primary-color-com-de;
            }
        }
        &__block-list{
            @include menu-header-comments-block-list($border-block-com-de, $hover-block-com-de);
            &--last{
                border-bottom: 1px solid $border-block-com-de;
            }
        }
        &__text-date{
            color: $text-field-color-com-de;
        }
        &__close-icon {
            color: $primary-color-com-de;
        }
        &__title-header{
            @include menu-header-comments-title-header($primary-color-com-de, $third-color-com-de);
        }
    }
  }
  &__com-fr{
    & .menu-header-comments {
        @include menu-header-comments-color($primary-color-com-fr);
        &__text-breadcrumb{
            color: $custom-black-landing;
        }
        &__text-name {
            color: $text-field-color-com-fr;
        }
        &__text-type{
            color: $text-field-color-com-fr;
        }
        &__menu-header{
            color: $secondary-color-com-fr;
        }
        &__icon-notifications{
            color: $primary-color-com-fr;
        }
        &__icon-type-notification{
            color: $primary-color-com-fr;
        }
        &__block-line{
            border-color: $third-color-com-fr;
        }
        &__circle-icon-comment{
            color: $border-block-com-fr;
            &--first{
                color: $primary-color-com-fr;
            }
        }
        &__block-list{
            @include menu-header-comments-block-list($border-block-com-fr, $hover-block-com-fr);
            &--last{
                border-bottom: 1px solid $border-block-com-fr;
            }
        }
        &__text-date{
            color: $text-field-color-com-fr;
        }
        &__close-icon {
            color: $primary-color-com-fr;
        }
        &__title-header{
            @include menu-header-comments-title-header($primary-color-com-fr, $third-color-com-fr);
        }
    }
  }

  &__icam-red {
      & .menu-header-comments {
          @include menu-header-comments-color($primary-color-icam);
          &__text-breadcrumb{
              color: $custom-black-landing;
          }
          &__text-name {
              color: $text-field-color-icam;
          }
          &__text-type{
              color: $text-field-color-icam;
          }
          &__menu-header{
              color: $secondary-color-icam;
          }
          &__icon-notifications{
              color: $primary-color-icam;
          }
          &__icon-type-notification{
              color: $primary-color-icam;
          }
          &__block-line{
              border-color: $third-color-icam;
          }
          &__circle-icon-comment{
              color: $border-block-icam;
              &--first{
                  color: $primary-color-icam;
              }
          }
          &__block-list{
              @include menu-header-comments-block-list($border-block-icam, $hover-block-icam);
              &--last{
                  border-bottom: 1px solid $border-block-icam;
              }
          }
          &__text-date{
              color: $text-field-color-icam;
          }
          &__close-icon {
              color: $primary-color-icam;
          }
          &__title-header{
              @include menu-header-comments-title-header($primary-color-icam, $third-color-icam);
          }
      }
  }
}
