.qr-modal{
    &__window{
        & .modal-dialog{
            & .modal-content{
                height: 600px;
                width: 442px;
                margin: 0 auto;
            }
        }
    }

    &__related-form-info{
        font-family: MTTMilano, sans-serif;
        font-size: 18px;
        padding: 56px 74px 0 74px;
        color: $donut-text;
        padding-top: 32px;
        text-align: center;
    }

    &__body{
        box-sizing: border-box;
        height: 300px;
        width: 300px;
        border: 1px solid $result-search-quote-color;
        margin-left: 71px;
        margin-top: 16px;
        padding: 20px 10px 10px 20px;
    }

    &__buttons-block{
        width: 100%;
        text-align: center;
        margin-top: 45px;
    }
}

.compliance{
    &__steel-blue{
        .qr-modal{
            &__related-form-info{
                color: $primary-color-2;
            }

            &__body{
                border-color: $secondary-color-2;
            }
        }
    }
}
