.video-slider{
    &__breadcrumb-block{
        float: right;
        padding-top: 8px;
        padding-right: 3px;
    }

    &__info-text{
        color: $primary-color;
        font-family: MTTMilano-Bold, sans-serif;
        font-size: 16px;
        padding: 25px 14px 0;
        cursor: pointer;
    }

    &__video-block{
        display: block;
        margin: 0;
        height: 208px;
        padding: 0;
        width: 100%;
    }

    &__video-img{
        width: 281px;
        height: 159px;
        position: relative;
        z-index: 10;
        float: left;
    }

    &__video-img-photo{
        width: 281px;
        height: 159px;
        cursor: pointer;
    }

    &__video-info{
        float: right;
        width: calc(100% - 290px);
        overflow: hidden;
        height: 159px;
        background: $background-grey-other;

    }

    &__video-item{
        display: block;
        width: 100%;
        overflow: hidden;
        height: 179px;
        padding: 14px 8px 8px;
    }

    &__button-play{
        width: 59px;
        height: 59px;
        line-height: 47px;
        display: block;
        border-radius: 50px;
        outline: none;
        position: absolute;
        top: 53px;
        left: 111px;
        color: $primary-color;
        border: 6px solid $desactive-color;
        font-size: 49px;
        background-color: $desactive-color;
        padding: 0;
    }
}
