@mixin folder-concept-archived-amount($color, $borderColor, $backgroundColor){
  color: $color;
  border: 1px solid $borderColor;
  background-color: $backgroundColor;
}
@mixin folder-title-subtitle-no-expi-date($backgroundColor, $color){
  background-color: $backgroundColor;
  color: $color;
  font-weight: normal;
  text-transform: lowercase;
}
@mixin folder-background-color($backgroundColor, $color){
  background-color: $backgroundColor;
  color: $color;
}
@mixin folder-evaluation-concept-file-poll($color){
  color: $color;
  &:hover {
      cursor: auto !important;
  }
}
.folder {
width: 100%;
padding-left: 10px;
display: block;

&__block-concept {
  &--last-small {
    margin-bottom: 125px;
  }
}

&__block-title-concept {
  display: flex;
  position: relative;
  float: left;
  height: 16px;
  max-width: calc(100% - 422px);
  margin-top: 7px;
}

&__concept-title-icon {
  font-size: 18px;
  margin-left: 10px;
  padding-top: 8px;
  bottom: 8px;
  position: relative;
}

&__block-bool-fold {
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 10px;

  &--legal {
    margin-bottom: 19px;
  }
}

&__evaluation-content {
  margin: 3px 30px 0 45px;
  display: block;
}

&__evaluation-folder-list {
  padding: 10px 0 10px 50px;
  margin: 0;
  width: 100%;
  border: 1px solid $subcontainer-border;
  background-color: $background-grey;
  list-style: none;
}

&__evaluation-folder-item {
  color: $primary-color;
  font-family: MTTMilano, sans-serif;
  font-size: 15px;
  height: 20px;
  margin: 3px 0 7px;
  display: block;
}

&__evaluation-folder-icon {
  margin-right: 8px;
  font-size: 24px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

&__folder-menu {
  float: none;
  position: relative;
  display: inline-block;
  width: 30px;
  margin-left: 3px;

  &--concept-list {
    float: left;
    margin-left: 10px;
    top: 4px;
  }

  &--folder-list {
    top: -1px;
  }

  &--report-global-list {
    margin-left: 10px;
    top: 2px;
  }
}

&__evaluation-concept-list {
  margin: 0;
  width: 100%;
  background-color: $background-grey;
  list-style: none;
  margin-top: 9px;
  position: relative;
  height: 74px;
  padding: 0;
}

&__evaluation-concept-item {
  padding: 22px 0 12px 18px;
  color: $primary-color;
  font-family: MTTMilano-Bold, sans-serif;
  font-size: 16px;
  line-height: 16px;
  width: 98%;
  display: inline-block;
}

&__evaluation-concept-arrow {
  font-size: 20px;
  margin-right: 9px;
  margin-top: 3px;

  &:hover {
    cursor: pointer;
  }

  &--hidden {
    visibility: hidden;
  }
}

&__evaluation-concept-file {
  font-size: 30px;
  margin-right: 10px;
  visibility: visible;

  &:hover {
    cursor: pointer;
  }
}

&__evaluation-concept-management {
  font-size: 22px;
  margin-top: 4px;
  margin-right: 17px;

  &:hover {
    cursor: pointer;
  }

  &--published {
    color: $folder-published;
  }
}

&__evaluation-concept-publish {
  font-size: 30px;
  margin-right: 17px;

  + .tooltip {
    &[data-popper-placement^='top'] {
      & > .tooltip-inner {
        border-bottom-width: 3px;
        padding: 10px;
        font-size: 12px;
        font-family: MTTMilano, sans-serif;
        height: auto;
        width: 100%;
        box-shadow: 0 0 5px 2px $certify-black-opacity;
        opacity: 1;
        padding: 6px;
      }
    }
  }

  &--establish {
    &[data-popper-placement^='top'] {
      & > .tooltip-inner {
        padding: 8px;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  &--published {
    color: $folder-published;

    + .tooltip {
      &[data-popper-placement^='top'] {
        & > .tooltip-inner {
          border: 1px solid $folder-published;
          border-bottom: 3px solid $folder-published;
        }

        .tooltip-arrow {
          &:before {
            border-top-color: $folder-published;
          }
        }
      }
    }
  }

  &--menu-li {
    font-size: 20px;
    margin-right: 0;
  }
}

&__concept-header-button-icam {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  margin-top: 3px;
  margin-right: 15px;
  height: 25px;
  color: $custom-black-landing;

  & i {
    padding-left: 5px;
    font-size: 20px;
    color: $primary-color-icam;
  }

  &--disabled {
    opacity: 0.5;
  }
}

&__evaluation-concept-title {
  height: 18px;
  margin: 0;
}

&__title-evaluated {
  padding-top: 5px;
  margin: 0;
  background-color: $subcontainer-border;
  font-size: 11px;
  height: 25px;
  width: 173px;
  text-align: center;
  margin-top: 3px;
  text-transform: uppercase;

  &--surveyed {
    height: 22px !important;
    padding-top: 4px !important;
  }
}

&__square-item {
  height: 22px;
  min-width: 22px;
  padding: 1px 3px;
  border-left: 2.3px solid $primary-color;
  border-bottom: 2.3px solid $primary-color;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-bottom-left-radius: 3px;
  font-size: 19px;
  margin: 5px 26px 0 4px;
  font-family: Arial, sans-serif;
  font-weight: bold;

  &--hidden {
    margin-right: 66px;
  }
}

&__no-concept-block {
  height: 34px;

  &--with-archived {
    bottom: 30px;
    position: relative;
  }
}

&__no-concept-text {
  color: $secondary-color;
  text-align: center;
  font-family: MTTMilano, sans-serif;
  padding-top: 8px;
}

&__icon-document {
  font-size: 25px;
  float: left;
  color: $primary-color;
  margin-top: 2px;
  margin-right: 10px;
}

&__cell-block {
  position: relative;
  display: inline-block;
  height: 20px;
  max-width: calc(100% - 95px);
  padding: 0 4px;
  top: 1px;

  &:hover {
    cursor: pointer;
  }
}

&__col-text {
  margin: 0;
}

&__concept-menu-list {
  width: 220px;
  background-color: $snow-white;
  position: absolute;
  top: 17px;
  padding: 0;
  z-index: 15;
  border-radius: 0px;
  float: none;
  left: -59px;
  border: 1px solid $primary-color;
}

&__back-button {
  padding: 0;
  border: none;
  outline: none;
  background: none;
  margin-top: 5px;

  &--archived {
    font-size: 30px;
    margin-top: 3px;
  }
  &--archived-incident-list{
    margin-top: 0;
    font-size: 30px;
  }
  &--archived-incident-list-no-top{
    margin-top: 0;
  }

}

&__arrow-back-icon {
  font-size: 20px;
  color: $primary-color;
  padding-right: 8px;
  position: relative;
  top: 4px;
}

&__arrow-back-text {
  color: $primary-color;
  font-family: MTTMilano, sans-serif;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}

&__concept-archived-amount {
  border: 1px solid $delete-amount;
  background-color: $background-grey;
  opacity: 0.7;
  color: $primary-color;
  font-family: MTTMilano, sans-serif;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 6px;
  margin: 5px 10px 0 0;
  &--archived-incident-list{
    margin-top: 3px;
  }
}

&__title-right {
  width: 173px;
  float: right;
}

&__title-subtitle {
  font-size: 10px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  margin-top: 1px;
}

&__icon-calendar-cross {
  margin-right: 3px;
  font-size: 15px;
}

&__text-date-poll {
  position: relative;
  bottom: 2px;
}

&__pattern-tooltip-block {
  margin: 0;
  padding: 0;
  list-style: none;
}

&__pattern-tooltip-list {
  color: $primary-color;
  font-family: Arial, sans-serif;
  font-size: 11px;
  text-align: left;

  &--next {
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 11px;
  }
}

&__block-confident-folder {
  position: absolute;
  left: 10px;
  top: -7px;
}
}

.compliance {
&__dark-blue {
  .folder {
    &__evaluation-folder-list {
      border-color: $subcontainer-border;
      background-color: $background-color-states;
    }

    &__evaluation-folder-item {
      &--hidden {
        color: $subcontainer-ground;

        &:hover {
          cursor: default;
        }
      }
    }

    &__evaluation-folder-icon {
      &--hidden {
        color: $subcontainer-ground;
      }
    }

    &__evaluation-concept-list {
      background-color: $background-color-states;
      border: 1px solid $subcontainer-border;

      &--archived {
        height: 74px;
        background-color: $other-gray-03;
      }
      &--archived-report-incident{
        height: 50px;
        border: 1px solid $result-search-quote-color;
        background-color: $other-gray-03;
      }
    }

    &__evaluation-concept-file {
      color: $primary-color;

      &--poll {
        @include folder-evaluation-concept-file-poll($subcontainer-border);
      }
    }

    &__evaluation-concept-title {
      color: $primary-color;

      &--hidden {
        color: $subcontainer-ground;
      }
    }

    &__square-item {
      border-color: $primary-color;
    }

    &__icon-document {
      color: $primary-color;

      &--hidden {
        color: $subcontainer-ground;
      }
    }

    &__concept-menu-list {
      border: 1px solid $primary-color;
    }

    &__title-subtitle {
      &--no-expi-date {
        @include folder-title-subtitle-no-expi-date($folder-subtitle, $repeat-pattern);
      }

      &--expi-date-pass {
        @include folder-background-color($red-opacity-01, $red);
      }

      &--expi-date-valid {
        @include folder-background-color($green-opacity-01, $primary-color);
      }
    }

    &__arrow-back-icon,
    &__back-button,
    &__arrow-back-text {
      color: $primary-color;
    }

    &__concept-archived-amount {
      @include folder-concept-archived-amount($primary-color, $delete-amount, $background-color-states);

    }
  }
}

&__steel-blue,
&__uk-steel-blue {
  .folder {
    padding: 0;

    &__evaluation-folder-list {
      border-color: $secondary-color-2;
      background-color: $background-grey-disabled-2;
    }

    &__evaluation-folder-item {
      color: $primary-color-2;

      &--hidden {
        color: $grey-icons-2;

        &:hover {
          cursor: default;
        }
      }
    }

    &__evaluation-concept-list {
      background-color: $background-grey-disabled-2;
      border: 1px solid $secondary-color-2;

      &--archived {
        height: 74px;
        background-color: $third-color-2;
        border-color: $third-color-2;
      }
    }

    &__evaluation-concept-item {
      color: $primary-color-2;
    }

    &__evaluation-concept-file {
      color: $border-hover-2;
    }

    &__evaluation-concept-title {
      color: $primary-color-2;

      &--hidden {
        color: $grey-icons-2;
      }
    }

    &__evaluation-folder-icon {
      &--hidden {
        color: $grey-icons-2;
      }
    }

    &__no-concept-text {
      color: $primary-color-2;
      font-family: Arial, sans-serif;
      font-size: 11px;
    }

    &__square-item {
      border-color: $primary-color-2;
    }

    &__icon-document {
      color: $primary-color-2;

      &--hidden {
        color: $grey-icons-2;
      }
    }

    &__concept-menu-list {
      border: 1px solid $third-color-2;
      border-top-width: 2px;
    }

    &__block-bool-fold {
      display: none;
    }

    &__arrow-back-icon,
    &__back-button,
    &__arrow-back-text {
      color: $border-hover-2;
    }

    &__concept-archived-amount {
      @include folder-concept-archived-amount($primary-color-2, $background-grey-opacity-2, $third-color-2);
    }
  }
}

&__uk-steel-blue {
  .folder {
    &__block-concept {
      box-shadow: $box-shadow-v3;
    }
  }
}

&__icam-red {
  .folder {
    padding: 0;

    &__evaluation-concept-list {
      background-color: $background-grey-disabled-icam;

      &--archived {
        height: 74px;
        background-color: $third-color-icam;
      }
    }

    &__evaluation-concept-item {
      color: $custom-black-landing;
    }

    &__evaluation-concept-file {
      color: $primary-color-icam;

      &--poll {
        @include folder-evaluation-concept-file-poll($border-block-icam);
      }
    }

    &__evaluation-concept-title {
      color: $custom-black-landing;

      &--hidden {
        color: $secondary-color-icam;
      }
    }

    &__title-evaluated {
      background-color: $border-block-icam;
    }

    &__square-item {
      border-color: $custom-black-landing;
    }

    &__icon-document {
      color: $custom-black-landing;

      &--hidden {
        color: $secondary-color-icam;
      }
    }

    &__concept-menu-list {
      border: 1px solid $primary-color-icam;
      border-top-width: 2px;
    }

    &__block-concept {
      border: none;
      box-shadow: $box-shadow-icam;
    }

    &__arrow-back-icon,
    &__back-button,
    &__arrow-back-text {
      color: $primary-color-icam;
    }

    &__concept-archived-amount {
      @include folder-concept-archived-amount($custom-black-landing, $primary-icam-10, transparent);
      opacity: 1;
    }

    &__concept-title-icon {
      color: $custom-black-landing;
    }
  }
}

&__gm-law {
  .folder {
    padding: 0;

    &__evaluation-folder-list {
      background-color: $background-grey-disabled-gm;
      border: none;
      box-shadow: $box-shadow-gm;
    }

    &__evaluation-folder-item {
      color: $custom-black-landing;

      &--hidden {
        color: $secondary-color-gm;

        &:hover {
          cursor: default;
        }
      }
    }

    &__evaluation-concept-list {
      background-color: $background-grey-disabled-gm;
      &--archived {
        height: 74px;
        background-color: $third-color-gm;
      }
    }

    &__evaluation-concept-item {
      color: $custom-black-landing;
    }

    &__evaluation-concept-arrow {
      color: $primary-color-gm;
    }

    &__evaluation-concept-file {
      color: $primary-color-gm;

      &--poll {
        @include folder-evaluation-concept-file-poll($border-block-gm);
      }
    }

    &__evaluation-concept-title {
      color: $custom-black-landing;

      &--hidden {
        color: $secondary-color-gm;
      }
    }

    &__title-evaluated {
      background-color: $border-block-gm;
      color: $custom-black-landing;
    }

    &__square-item {
      border-color: $custom-black-landing;
    }

    &__icon-document {
      color: $custom-black-landing;

      &--hidden {
        color: $secondary-color-gm;
      }
    }

    &__concept-menu-list {
      border: 1px solid $primary-color-gm;
      border-top-width: 2px;
    }

    &__block-concept {
      border: none;
      box-shadow: $box-shadow-gm;
    }

    &__title-subtitle {
      &--no-expi-date {
        @include folder-title-subtitle-no-expi-date($background-black-10-gm, $secondary-color-gm);
      }

      &--expi-date-pass {
        @include folder-background-color($background-red-10-gm, $alert-color-gm);
      }

      &--expi-date-valid {
        @include folder-background-color($background-green-10-gm, $green-task-gm);
      }
    }

    &__arrow-back-icon,
    &__back-button,
    &__arrow-back-text {
      color: $primary-color-gm;
    }

    &__concept-archived-amount {
      @include folder-concept-archived-amount($custom-black-landing, $primary-gm-10, transparent);
      opacity: 1;
    }
  }
}
&__com-de{
  .folder {
    padding: 0;

    &__evaluation-folder-list {
      background-color: $background-grey-disabled-com-de;
      border: none;
      box-shadow: $box-shadow-com-de;
    }

    &__evaluation-folder-item {
      color: $custom-black-landing;

      &--hidden {
        color: $secondary-color-com-de;

        &:hover {
          cursor: default;
        }
      }
    }

    &__evaluation-concept-list {
      background-color: $background-grey-disabled-com-de;

      &--archived {
        height: 74px;
        background-color: $third-color-com-de;
      }
    }

    &__evaluation-concept-item {
      color: $custom-black-landing;
    }

    &__evaluation-concept-arrow {
      color: $primary-color-com-de;
    }

    &__evaluation-concept-file {
      color: $primary-color-com-de;

      &--poll {
        @include folder-evaluation-concept-file-poll($border-block-com-de);
      }
    }

    &__evaluation-concept-title {
      color: $custom-black-landing;

      &--hidden {
        color: $secondary-color-com-de;
      }
    }

    &__title-evaluated {
      background-color: $border-block-com-de;
      color: $custom-black-landing;
    }

    &__square-item {
      border-color: $custom-black-landing;
    }

    &__icon-document {
      color: $custom-black-landing;

      &--hidden {
        color: $secondary-color-com-de;
      }
    }

    &__concept-menu-list {
      border: 1px solid $primary-color-com-de;
      border-top-width: 2px;
    }

    &__block-concept {
      border: none;
      box-shadow: $box-shadow-com-de;
    }

    &__title-subtitle {
      &--no-expi-date {
        @include folder-title-subtitle-no-expi-date($background-black-10-com-de, $secondary-color-com-de);
      }

      &--expi-date-pass {
        @include folder-background-color($background-red-10-com-de, $alert-color-com-de);
      }

      &--expi-date-valid {
        @include folder-background-color($background-green-10-com-de, $green-task-com-de);
      }
    }

    &__arrow-back-icon,
    &__back-button,
    &__arrow-back-text {
      color: $primary-color-com-de;
    }

    &__concept-archived-amount {
      @include folder-concept-archived-amount($custom-black-landing, $primary-com-de-10, transparent);
      opacity: 1;
    }
  }
}
&__com-fr{
  .folder {
    padding: 0;

    &__evaluation-folder-list {
      background-color: $background-grey-disabled-com-fr;
      border: none;
      box-shadow: $box-shadow-com-fr;
    }

    &__evaluation-folder-item {
      color: $custom-black-landing;

      &--hidden {
        color: $secondary-color-com-fr;

        &:hover {
          cursor: default;
        }
      }
    }

    &__evaluation-concept-list {
      background-color: $background-grey-disabled-com-fr;

      &--archived {
        height: 74px;
        background-color: $third-color-com-fr;
      }
    }

    &__evaluation-concept-item {
      color: $custom-black-landing;
    }

    &__evaluation-concept-arrow {
      color: $primary-color-com-fr;
    }

    &__evaluation-concept-file {
      color: $primary-color-com-fr;

      &--poll {
        @include folder-evaluation-concept-file-poll($border-block-com-fr);
      }
    }

    &__evaluation-concept-title {
      color: $custom-black-landing;

      &--hidden {
        color: $secondary-color-com-fr;
      }
    }

    &__title-evaluated {
      background-color: $border-block-com-fr;
      color: $custom-black-landing;
    }

    &__square-item {
      border-color: $custom-black-landing;
    }

    &__icon-document {
      color: $custom-black-landing;

      &--hidden {
        color: $secondary-color-com-fr;
      }
    }

    &__concept-menu-list {
      border: 1px solid $primary-color-com-fr;
      border-top-width: 2px;
    }

    &__block-concept {
      border: none;
      box-shadow: $box-shadow-com-fr;
    }

    &__title-subtitle {
      &--no-expi-date {
        @include folder-title-subtitle-no-expi-date($background-black-10-com-fr, $secondary-color-com-fr);
      }

      &--expi-date-pass {
        @include folder-background-color($background-red-10-com-fr, $alert-color-com-fr);
      }

      &--expi-date-valid {
        @include folder-background-color($background-green-10-com-fr, $green-task-com-fr);
      }
    }

    &__arrow-back-icon,
    &__back-button,
    &__arrow-back-text {
      color: $primary-color-com-fr;
    }

    &__concept-archived-amount {
      @include folder-concept-archived-amount($custom-black-landing, $primary-com-fr-10, transparent);
      opacity: 1;
    }
  }
}
}
@media (min-width: 700px) and (max-width: 1400px) {
  .folder{
      &__cell-block{
          max-width: calc(100% - 96px);
      }
  }
}
