.notification-preview {
  padding: 30px 33px;

  &__header {
      text-align: right;
  }

  &__close {
      font-size: 11px;
      font-weight: bold;
      font-family: Arial, sans-serif;
      & > i {
          font-size: 13px;
          margin-left: 10px;
          vertical-align: middle;
      }
  }

  &__main {
      padding: 25px
  }

  &__html {
      max-width: 650px;
      background-color: $snow-white;
  }
}
.compliance{
  &__dark-blue{
    .notification-preview {
      background-color: $background-other-2;
      &__close {
          color: $primary-color;
      }
    }
  }
  &__steel-blue{
    .notification-preview {
      background-color: $background-grey-disabled-2;
      &__close {
          color: $border-hover-2;
      }
    }
  }
  &__gm-law{
    .notification-preview {
      background-color: $background-grey-disabled-gm;
      &__close {
          color: $primary-color;
      }
    }
  }
  &__com-de{
    .notification-preview {
      background-color: $background-grey-disabled-com-de;
      &__close {
          color: $primary-color;
      }
    }
  }
  &__com-fr{
    .notification-preview {
      background-color: $background-grey-disabled-com-fr;
      &__close {
          color: $primary-color;
      }
    }
  }
  &__icam-red{
    .notification-preview {
      background-color: $background-grey-disabled-icam;
      &__close {
          color: $primary-color;
      }
    }
  }
}
