.concursal-forms {
  &__modal-window {
      & .modal-dialog {
          & .modal-content {
              height: 666px;
          }
      }
  }

  &__content {
      margin: 50px 70px;
  }

  &__description {
      opacity: 0.5;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 20px;
  }

  &__evaluations-list-container {
      position: relative;
      border: 1px solid $result-search-quote-color;
      height: 400px;
      overflow: hidden;
  }
}
