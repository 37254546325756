.duplicate-evaluation{
  &__window{
      & .modal-dialog{
          & .modal-content{
              height: 506px;
              border-radius: 0px;
          }
      }
  }

  &__window-special{
    & .modal-dialog{
        & .modal-content{
            height: 400px;
            border-radius: 0px;
        }
    }
  }

  &__form {
      width: 485px;
      height: 355px;
      margin: 25px auto 0;
      display: block;
      &--special{
        width: calc(100% - 142px);
        margin: 35px auto 0;
        height: 219px;
      }
  }

  &__block-fields {
      display: block;
      overflow: unset;
      width: 100%;
      margin-bottom: 11px;
  }

  &__radio-button-block{
      margin-right: 31px;
  }

  &__title-block-modal{
      margin-bottom: 19px;
      line-height: 24px;
  }
}
