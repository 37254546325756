.certify-document{
    & .modal-dialog{
        & .modal-content{
            height: 295px;
            border-radius: 0px;
        }
    }

    &__block-context{
        height: 295px;
        position: relative;
        float: left;
        width: 100%;
    }

    &__block-body{
        position: relative;
        float: left;
        padding: 0;
        font-size: 12px;
        margin: 0;
        width: 100%;
        height: 210px;
    }

    &__text-info{
        margin: 40px 71px 0;
        line-height: 20px;
        font-size: 16px;
    }
}