.favorites{
  width: calc(100% - 10px);
  border: 1px solid $subcontainer-border;
  background-color: $snow-white;
  display: block;
  min-height: 504px;
  margin: 0 0 58px 10px;
  padding: 23px 52px ;

  &__list-order-block{
      display: block;
      width: 100%;
      overflow: hidden;
      padding: 20px 0 32px;
      position: relative;
      left: -10px;
      color: $primary-color;
  }
  &__list-order-icon{
      float: left;
      font-size: 20px;
      &:hover{
          cursor: pointer;
      }
  }
  &__list-order-text{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      margin: 0;
      text-transform: uppercase;
      float: left;
      padding-right: 8px;
  }
  &__list-order-button{
      border: 2px solid $subcontainer-ground;
      background-color: $background-grey;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      padding: 4px 6px;
      height: 23px;
      min-width: 100px;
      text-transform: uppercase;
      position: relative;
      bottom: 3px;
      &:hover{
          border-color: $primary-color;
          background-color: $subcontainer-border;
      }
  }
  &__favorites-list-block{
      padding: 0;
      margin: 0;
      list-style: none;
  }
  &__favorite-item{
      min-width: 96px;
      position: relative;
      border-left: 1px solid $box-comment-border-03;
      padding-left: 12px;
  }
  &__favorite-icon{
      font-size: 15px;
      color: $subcontainer-border;
      top: -6px;
      position: absolute;
      left: -8px;
  }
  &__favorite-item-block{
      display: block;
      height: auto;
      width: 100%;
      padding: 7px 7px 20px;
      border-top: 1px solid $box-comment-border-02;
  }
  &__item-title{
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      font-weight: 500;
  }
  &__item-title-span{
      &--not-visible{
          opacity: 0.5;
      }
      &:hover{
          cursor: pointer;
      }
  }
  &__route-icon{
      font-size: 20px;
      padding-right: 7px;
      float: left;
  }
  &__route-list{
      margin: 0;
      padding: 0;
      list-style: none;
      color: $pillow-group;
  }
  &__route-item{
      display: inline-block;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 13px;
  }
  &__last-access{
      clear: both;
      padding-left: 2px;
      margin: 0;
      padding-top: 2px;
      color: $pillow-group;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
  }
  &__item-menu{
      position: absolute;
      top: 6px;
      right: -3px;
  }
  &__kebab-icon{
      font-size: 27px;
      color: $primary-color;
      &:hover{
          cursor: pointer;
      }
  }
  &__options-menu{
      top: 2px;
      border: 1px solid $primary-color;
      border-radius: 0;
      padding: 0;
      width: 215px;
      min-width: 215px;
      right: 19px;
      left: initial;
  }
  &__pagination{
      margin: 0 auto;
      width: 15%;
      display: block;
      overflow: hidden;
      margin-top: 30px;
  }
  &__not-visible-color{
      color: $red !important;
  }

  &__block-not-favorites{
      padding-top: 100px;
      text-align: center;
      color: $primary-color;
      max-width: 680px;
      margin: 0 auto;
      font-family: MTTMilano, sans-serif;
  }
}

.compliance {
  &__dark-blue {
    .favorites {
      &__list-order-button {
        &--active {
          border-color:$primary-color;
          background-color: $subcontainer-border;
        }
      }

      &__favorite-item {
        &:hover {
          .favorites__favorite-icon{
              color: $primary-color;
          }
          .favorites__favorite-item-block{
              background: $picker-visibility-footer;
          }
        }
      }

      &__favorite-item-block {
        &--last{
          border-bottom: 1px solid $box-comment-border-02;
        }
      }

      &__last-access {
        &--date {
          color: $secondary-color;
          font-family: Arial, sans-serif;
        }
      }
    }
  }

  &__steel-blue {
    .favorites {
      margin-left: 0px;
      border-color: $secondary-color-2;

      &__list-order-block {
        color: $border-hover-2;
      }

      &__list-order-button {
        border-color: $third-color-2;
        background-color: transparent;

        &:hover {
            border-color: $third-color-2;
            background-color: $third-color-2;
        }

        &--active {
          background-color: $third-color-2;
        }
      }

      &__favorite-item {
        border-color: $third-color-2;
        &:hover {
            .favorites__favorite-icon{
                color: $border-hover-2;
            }
            .favorites__favorite-item-block{
                background: $block-hover-2;
            }
        }
      }

      &__favorite-icon {
        color: $secondary-color-2;
      }

      &__favorite-item-block {
        border-color: $secondary-color-2;

        &--last{
            border-bottom: 1px solid $secondary-color-2;
        }
      }

      &__item-title {
        color: $primary-color-2;
      }

      &__route-list {
        color: $text-field-color-2;
      }

      &__last-access {
        color: $primary-color-2;

        &--date {
            color: $text-field-color-2;
            font-family: Arial, sans-serif;
        }
      }

      &__kebab-icon {
        color: $border-hover-2;
      }

      &__options-menu {
        border-color: $third-color-2;
        border-top-width: 2px;
      }

      &__block-not-favorites {
        color: $primary-color-2;
      }
    }
  }
}
