.process-edit-modal {
  padding: 12px 12px 24px 12px;

  &.modal .modal-dialog {
      width: 80%;
      height: 85%
  }

  &.modal .modal-content {
      width: auto;
      height: 760px;
      border-radius: 0px;
      min-height: 550px;
  }

  &__toolbar {
      float: right;

      & button {
          height: 20px;
          margin-left: 40px;
          background-color: transparent;
          border: none;
          outline: none;
          font-family: Arial, sans-serif;
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
          vertical-align: top;

          & > i {
              margin-right: 5px;
              font-size: 17px;
              vertical-align: middle;
          }
      }
  }

  &__main {
      position: absolute;
      top: 83px;
      right: 12px;
      bottom: 85px;
      left: 12px;
      overflow: hidden;
  }

  &__footer {
      position: absolute;
      left: 12px;
      right: 12px;
      bottom: 20px;
      overflow: hidden;
      padding: 0px 65px;
  }
}

@media (max-width: 1366px) {
  .process-edit-modal {
      &.modal .modal-dialog {
          width: 95%;
          height: 100%;
          margin: 0 auto;
      }
      &.modal .modal-content {
          width: 95%;
          height: 550px;
          margin: 0 auto;
      }
  }
}
@media (max-width: 1190px) {
  .process-edit-modal {
      &__footer{
          padding: 0px 15px;
      }
  }
}
