.evaluations-list {
  padding: 10px;
  // &__block-header-buttons {
  //     position: relative;
  //     float: right;
  //     top: 3px;
  //     margin-left: 6px;
  // }

  &__column {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding-right: 5px;

      &--tooltip {
          max-width: 0;
      }

      &--first {
          padding-left: 8px;
      }

      &--name {
          width: 38%;
      }

      &--client {
          width: 30%;
      }

      &--complaints {
          width: calc(32% - 70px);
      }

      &--complaints-body {
          width: calc(32% - 22px);
      }

      &--shared {
          width: calc(32% - 70px);
      }

      &--state {
          width: 12%;
      }

      &--client-list-antiblanqueo{
          width: 100%;
          padding: 15px 5px 12px 8px;
      }

      &--client-antiblanqueo,
      &--result-antiblanqueo,
      &--modification-antiblanqueo{
          max-width: 0;
          width: 20%;
      }

      &--name-antiblanqueo{
          width: calc(40% - 90px);
      }

      &--icon-antiblanqueo{
          width: 30px;
      }

      &--actions-antiblanqueo {
          width: 60px;
          padding-right: 0;
          position: relative;
      }

      &--actions {
          width: 70px;
          padding-right: 0;
          position: relative;
      }

      &--actions-body {
          width: 22px;
          padding-right: 0;
          position: relative;
      }

      &--top-1{
          top: 1px;
          position: relative;
      }
  }

  &__block-alert-remove {
    width: 668px;
    height: 24px;
    padding: 1px 8px;
    margin-top: 1px;
    border: 1px solid;
    font-family: MTTMilano-Bold, sans-serif;
    font-size: 12px;
    position: absolute;
  }

  &__state-col {
      display: block;
      width: 100%;
      float: left;
      height: 8px;
      border-radius: 5px;
      &--color-0 {
          background-color: $red-opacity-36;
          border-color: $red;
          padding-left: 7px;
      }

      &--color-1 {
          background-color: $yellow-opacity-36;
          border-color: $yellow;
      }

      &--color-2 {
          background-color: $green-opacity-36;
          border-color: $green;
      }
  }

  &__progress-complete {
    color: $secondary-color;
    font-family: Arial, sans-serif;
    font-size: 12px;
    float: left;
  }

  &__status-icon {
    font-size: 16px;
    color: $red;
    position: relative;
    top: 12px;
    padding-left: 4px;
  }

  &__result-tag {
    padding: 6px 9px 5px;
    border-radius: 15px;
    margin: 0;
    max-width: 100%;
    height: 22px;
    position: relative;
    display: inline-block;
    line-height: 11px;
    font-family: Arial, sans-serif;
    font-size: 12px;
  }

  &__icon-blanqueo-alert{
      font-size: 20px;
      position: relative;
      top: 2px;
      color: $red;
  }

  &__table-row-client-blanqueo{
      display: block;
      border: none;
  }

  &__row-client-blanqueo{
      border-bottom: 1px solid $custom-black-landing;
      height: 46px;
  }

  &__icon-angle-button {
      padding: 0 5px 0 0;
      border: none;
      outline: none;
      background: none;
      font-size: 15px;
      position: relative;
      float: left;
      top: 1px;
      color: $primary-color;
  }

  &__block-bubble{
      position: relative;
      float: left;
      height: 15px;
      width: 26px;
      border-radius: 8px;
      padding: 1px 0;
      text-align: center;
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: bold;
      background-color: $primary-color;
      color: $snow-white;
      margin-left: 3px;
  }

  &__cell-block-blanqueo{
      max-width: calc(100% - 49px);
      float: left;
  }
}

.compliance{
  &__dark-blue{
    .evaluations-list {
      &__block-alert-remove {
          border-color: $delete-amount;
          color: $concept-blue;
      }
      &__row-client-blanqueo{
          border-color: $result-search-quote-color;
          height: 45px;
      }
    }
  }
  // &__steel-blue,
  // &__uk-steel-blue{
  //   .evaluations-list {
  //   }
  // }
  &__steel-blue{
    .evaluations-list {
      &__block-alert-remove {
          border-color: $third-color-2;
          color: $primary-color-2;
      }
      &__row-client-blanqueo{
          border-color: $secondary-color-2;
      }
      &__icon-angle-button {
          color: $border-hover-2;
      }
    }
  }
  // &__icam-red{
  //   .evaluations-list {
  //   }
  // }
  &__gm-law{
    .evaluations-list {
      &__state-col {
        &--color-0 {
            background-color: $red-task-36-gm;
            border-color: $red-task-36-gm;
        }

        &--color-1 {
            background-color: $yellow-task-36-gm;
            border-color: $yellow-task-36-gm;
        }

        &--color-2 {
            background-color: $green-task-36-gm;
            border-color: $green-task-36-gm;
        }
    }
    }
  }
  &__com-de{
    .evaluations-list {
      &__state-col {
        &--color-0 {
            background-color: $red-task-36-com-de;
            border-color: $red-task-36-com-de;
        }

        &--color-1 {
            background-color: $yellow-task-36-com-de;
            border-color: $yellow-task-36-com-de;
        }

        &--color-2 {
            background-color: $green-task-36-com-de;
            border-color: $green-task-36-com-de;
        }
    }
    }
  }
  &__com-fr{
    .evaluations-list {
      &__state-col {
        &--color-0 {
            background-color: $red-task-36-com-fr;
            border-color: $red-task-36-com-fr;
        }

        &--color-1 {
            background-color: $yellow-task-36-com-fr;
            border-color: $yellow-task-36-com-fr;
        }

        &--color-2 {
            background-color: $green-task-36-com-fr;
            border-color: $green-task-36-com-fr;
        }
    }
    }
  }
}
