.calendar-datepicker{
  // &__datepicker-container {
  //     width: 100%;
  // }
  // &__datepicker-header{
  //     height: 25px;
  //     position: relative;
  // }
  &__header-date-picker {
    display: flex;
    width: 100%;
    margin-bottom: 3px;
  }
  &__block-datepicker-title{
      display: flex;
      width: calc(100% - 45px);
      flex-direction: row;
  }
  &__block-arrows-calendar{
      display: flex;
      width: 45px;
      flex-direction: row;
      justify-content: flex-end;
  }
  // &__block-arrow{
  //     position: relative;
  //     float: left;
  //     width: 21px;
  // }
  &__datepicker-title {
      color: $primary-color;
      // font-family: MTTMilano-Bold, sans-serif;
      font-family: MTTMilano, sans-serif;
      font-weight: bold;
      font-size: 12px;
      line-height: 17px;
      border: 0;
      background: none;
      outline: none;
      text-transform: uppercase;
  }
  &__button-angle{
      padding: 0 2px 0;
      color: $custom-black-landing;
      border: none;
      background: none;
      outline: none;
      position: relative;
      font-size: 15px;
  }
  &__datepicker{
      border: none;
      .ngb{
        &-dp{
            &-header{
              display: none;
              // background: transparent;
              // padding: 0;
            }
            &-day,
            &-weekday,
            &-week-number{
                width: 35px;
                height: 20px;
                padding: 0 5px;
                // text-align: center;
            }
            &-weekday{
                font-style: normal;
                font-weight: bold;
                font-family: MTTMilano, sans-serif;
                color: $concept-blue;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &-week {
                background: transparent;
                display: flex;
                align-items: center;
                cursor: default;
            }
            &-weekdays{
              height: 25px;
            }
            &-day{
              cursor: default;
              & > .btn-light{
                  background-color: transparent;
                  height: 20px;
                  width: 20px;
                  min-width: initial;
                  padding: 0;
                  box-shadow: none;
                  border-radius: 50%;
                  outline: none;
                  color: $secondary-color;
                  line-height: 20px;
                  font-size: 11px;
                  border: 1px solid transparent;
                  &:hover:not([disabled]) {
                    background-color: $background-color-states;
                    color: $primary-color;
                    outline: none;
                  }
                  &--selected{
                    // box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
                    background-color: $primary-color;
                    color: $snow-white;
                    outline: none;
                    &:hover:not([disabled]) {
                      background-color: $background-color-states;
                      color: $primary-color;
                      outline: none;
                    }
                  }
                  // &.bg-primary{
                  //     box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
                  //     background-color: $primary-color !important;
                  //     color: $snow-white;
                  //     outline: none;
                  //   &:hover:not([disabled]) {
                  //     background-color: $background-color-states !important;
                  //     color: $primary-color;
                  //     outline: none;
                  //   }
                  // }
                  &[disabled] {
                      cursor: default;
                      opacity: 0.5;
                      // color: silver;
                  }
              }
            }
            &-today{
                & > .btn-light{
                    border: 1px solid $result-search-quote-color;
                }
            }

        }
      }
  }

  &__datepicker-big{
    border: none;
    .ngb {
      &-dp {
        &-header{
          display: none;
          // background: transparent;
          // padding: 0;
        }
        &-day,
        &-weekday,
        &-week-number {
          width: 57px;
          height: 30px;
          padding: 0 12px;
          // text-align: center;
        }

        &-weekday {
          font-style: normal;
          font-weight: bold;
          font-family: MTTMilano, sans-serif;
          color: $concept-blue;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-week {
          background: transparent;
          display: flex;
          align-items: center;
          cursor: default;
        }

        &-day{
          cursor: default;
          & > .btn-light {
            background-color: transparent;
            height: 30px;
            width: 30px;
            min-width: initial;
            padding: 0;
            box-shadow: none;
            border-radius: 50%;
            outline: none;
            color: $secondary-color;
            line-height: 28px;
            font-size: 12px;
            border: 1px solid transparent;
            &:hover:not([disabled]) {
              background-color: $background-color-states;
              color: $primary-color;
              outline: none;
            }
            &--selected{
              // box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
              background-color: $primary-color;
              color: $snow-white;
              outline: none;
              &:hover:not([disabled]) {
                background-color: $background-color-states;
                color: $primary-color;
                outline: none;
              }
            }
            // &.bg-primary {
            //   box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            //   background-color: $primary-color !important;
            //   color: $snow-white;
            //   outline: none;
            //   // border: 1px solid transparent;
            //   &:hover:not([disabled]) {
            //     background-color: $background-color-states !important;
            //     color: $primary-color;
            //     outline: none;
            //   }
            // }
            &[disabled] {
                cursor: default;
                opacity: 0.5;
                // color: silver;
            }
          }
        }
        &-today {
          & > .btn-light {
            border: 1px solid $result-search-quote-color;
          }
        }
      }
    }
  }
}

.compliance{
  &__dark-blue{
      .calendar-datepicker{
          &__button-angle{
              color: $primary-color;
          }
      }
  }
  &__icam-red{
      .calendar-datepicker{
          &__datepicker-title {
              color: $primary-color-icam;
          }
          &__button-angle{
              color: $primary-color-icam;
          }
          &__datepicker,
          &__datepicker-big{
            .ngb{
              &-dp{
                  &--weekdays{
                    border-color: $border-block-icam;
                  }
                  &-weekday{
                      color: $text-field-color-icam;
                  }
                  &-day{
                    & > .btn-light{
                        color: $custom-black-landing;
                        &:hover:not([disabled]) {
                          background-color: $hover-block-icam;
                          color: $custom-black-landing;
                        }
                        &--selected{
                          background-color: $primary-color-icam;
                          box-shadow: 0 0 5px 1px $box-shadow-box-2;
                          color: $snow-white;
                          outline: none;
                          &:hover:not([disabled]) {
                            background-color: $custom-color-E3E4E8;
                            outline: none;
                          }
                        }
                    }
                  }
                  &-today{
                      & > .btn-light{
                        border: 1px solid $primary-color-icam;
                      }
                  }
              }
            }
          }
        }
  }
  &__steel-blue, &__uk-steel-blue{
      .calendar-datepicker{
          &__datepicker-title {
              color: $border-hover-2;
          }
          &__button-angle{
              color: $border-hover-2;
          }
          &__datepicker,
          &__datepicker-big{
            .ngb{
              &-dp{
                  &--weekdays{
                      border-color: #D6D6D6;
                  }
                  &-weekday{
                      color: $border-bottom-2;
                  }
                  &-day{
                    & > .btn-light{
                        color: $primary-color-2;
                        &:hover:not([disabled]) {
                          background-color: $third-color-2;
                          color: $primary-color-2;
                        }
                        &--selected{
                          background-color: $border-hover-2;
                          color: $white-special-2;
                          outline: none;
                          &:hover:not([disabled]) {
                            background-color:  $third-color-2;
                            outline: none;
                          }
                        }
                    }
                  }
                  &-today{
                      & > .btn-light{
                        border: 1px solid $border-hover-2;
                      }
                  }
              }
            }
          }
      }
  }
  &__gm-law{
      .calendar-datepicker{
          &__datepicker-title {
              color: $primary-color-gm;
          }
          &__button-angle{
              color: $primary-color-gm;
          }
          &__datepicker,
          &__datepicker-big{
            .ngb{
              &-dp{
                  &--weekdays{
                    border-color: $border-block-gm;
                  }
                  &-weekday{
                      color: $text-field-color-gm;
                  }
                  &-day{
                    & > .btn-light{
                        color: $custom-black-landing;
                        &:hover:not([disabled]) {
                          background-color: $hover-block-gm;
                          color: $custom-black-landing;
                        }
                        &--selected{
                          background-color: $primary-color-gm;
                          box-shadow: 0 0 5px 1px $box-shadow-box-2;
                          color: $snow-white;
                          outline: none;
                          &:hover:not([disabled]) {
                            background-color: $custom-color-E3E4E8;
                            outline: none;
                          }
                        }
                    }
                  }
                  &-today{
                      & > .btn-light{
                        border: 1px solid $primary-color-gm;
                      }
                  }
              }
            }
          }
      }
  }
  &__com-de{
    .calendar-datepicker{
        &__datepicker-title {
            color: $primary-color-com-de;
        }
        &__button-angle{
            color: $primary-color-com-de;
        }
        &__datepicker,
        &__datepicker-big{
          .ngb{
            &-dp{
                &--weekdays{
                  border-color: $border-block-com-de;
                }
                &-weekday{
                    color: $text-field-color-com-de;
                }
                &-day{
                  & > .btn-light{
                      color: $custom-black-landing;
                      &:hover:not([disabled]) {
                        background-color: $hover-block-com-de;
                        color: $custom-black-landing;
                      }
                      &--selected{
                        background-color: $primary-color-com-de;
                        box-shadow: 0 0 5px 1px $box-shadow-box-2;
                        color: $snow-white;
                        outline: none;
                        &:hover:not([disabled]) {
                          background-color: $custom-color-E3E4E8;
                          outline: none;
                        }
                      }
                  }
                }
                &-today{
                    & > .btn-light{
                      border: 1px solid $primary-color-com-de;
                    }
                }
            }
          }
        }
    }
  }
  &__com-fr{
    .calendar-datepicker{
        &__datepicker-title {
            color: $primary-color-com-fr;
        }
        &__button-angle{
            color: $primary-color-com-fr;
        }
        &__datepicker,
        &__datepicker-big{
          .ngb{
            &-dp{
                &--weekdays{
                  border-color: $border-block-com-fr;
                }
                &-weekday{
                    color: $text-field-color-com-fr;
                }
                &-day{
                  & > .btn-light{
                      color: $custom-black-landing;
                      &:hover:not([disabled]) {
                        background-color: $hover-block-com-fr;
                        color: $custom-black-landing;
                      }
                      &--selected{
                        background-color: $primary-color-com-fr;
                        box-shadow: 0 0 5px 1px $box-shadow-box-2;
                        color: $snow-white;
                        outline: none;
                        &:hover:not([disabled]) {
                          background-color: $custom-color-E3E4E8;
                          outline: none;
                        }
                      }
                  }
                }
                &-today{
                    & > .btn-light{
                      border: 1px solid $primary-color-com-fr;
                    }
                }
            }
          }
        }
    }
  }
  &__landing-custom{
      .calendar-datepicker{
          &__header-date-picker{
              height: 43px;
          }
          &__datepicker-title{
              color: $custom-black-landing;
              font-family: 'lato_regular', sans-serif;
              font-weight: bold;
          }
          &__button-angle{
              color: $custom-black-landing;
          }
          &__block-month-days{
              margin: 0px 15px;
          }
          &__datepicker,
          &__datepicker-big{
            .ngb{
              &-dp{
                  &-weekdays{
                      background-color: $background-grey;
                      border: none;
                      height: 22px;
                  }
                  &-day,
                  &-weekday,
                  &-week-number {
                      font-family: 'lato_regular', sans-serif;
                      font-size: 11px;
                  }
                  &-day{
                      margin-top: 3px;
                  }
                  &-weekday{
                      color: $custom-black-landing;
                      font-weight: bold;
                  }
                  &-day{
                    & > .btn-light{
                        color: $custom-black-landing;
                        &:hover:not([disabled]) {
                          background-color: $custom-border-grey;
                          color: $custom-black-landing;
                          outline: none;
                        }
                        &--selected{
                          background-color: $custom-black-landing;
                          color: $snow-white;
                          outline: none;
                          &:hover:not([disabled]) {
                            background-color: $custom-border-grey;
                            color: $custom-black-landing;
                            outline: none;
                          }
                        }
                    }
                  }
                  &-today{
                      & > .btn-light{
                          border: 1px solid $custom-black-landing;
                      }
                  }
              }
            }
          }
          &__datepicker-big{
            .ngb{
              &-dp{
                  &-weekdays{
                      height: 28px;
                  }
                  &-day,
                  &-weekday,
                  &-week-number {
                      width: 53px;
                  }
              }
            }
          }
      }
  }
}
