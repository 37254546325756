.stacked-bar{
  &__stacked-bar-title{
    fill: #001978;
    font-family: MTTMilano-bold;
    font-size: 12px;
    text-transform: uppercase;
  }
  &__stacked-bar-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    height: 300px;
    width: 100%;
  }
  &__stacked-bar-text{
    opacity: 0.8;
    color: #FFFFFF;
    font-family: MTTMilano;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  &__bar-value{
    color: #001978;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin: 0;
  }
  &__inner-container{
    position: relative;
    width: 100%;
    height: auto;
  }
  &__stacked-tooltip-box{
    position: absolute;
    z-index: 100;
    visibility: hidden;
    &--show{
      visibility: visible;
    }
  }
  &__stacked-tooltip-container{
    box-sizing: border-box;
    height: 21px;
    width: fit-content;
    border: 1px solid rgba(0, 25, 120, 0.3);
    background-color: #FFFFFF;
    box-shadow: 0 0 5px 6px rgba(62, 59, 59, 0.16);
    margin: 0 auto;
    border-bottom: 5px solid #001978;
    border-left-color: transparent;
    border-right-color: transparent;
    padding: 0 4px;
    &::before{
      content: "";
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #001978;
      border-bottom: 5px solid transparent;
      height: 1px;
      width: 1px;
      position: absolute;
      right: 45px;
      top: 20px;
    }
  }
  &__stacked-tooltip-text{
    color: #001978;
    font-family: Arial;
    font-size: 11px;
    margin: 0;
    text-align: center;
  }
}