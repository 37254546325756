.export-modal {
    &__body-container {
        margin: 35px 70px;
    }

    &__error-message {
        color: red;
        font-size: 12px;
    }

    &__switch-container {
        display: flex;
        flex-direction: column;
    }

    &__switch {
        margin-left: unset;
        margin-top: 10px;
    }
}
