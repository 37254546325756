// dictionary - colors //
// Primary colors //
// :root {
//     --primary-color:#001978;
// }
$primary-color:#001978;
$secondary-color: #565656;
$alert-color: #ff0000;
$clients-list-color: #455286;
$desactive-color: #ddd;
$reports-list-color: #3A56C0;
$box-border: rgba(0, 25, 120, 0.42);
$background-grey: #F4F4F4;
$background-grey-other: #E5E8F1;
$bottom-border: rgba(0, 25, 135, 0.15);
//$buttons-block-color: #E7E9ED;
$menu-icons-border:rgba(191, 197, 221, 0.54);
$box-comment-border: rgba(0, 25, 120, 0.09);
$block-comment-hover: #EDEDEE;
$highlight-icon: #FFFF00;
$subcontainer-ground: #AAAAAA;
//$subcontainer-border:#CDD1E0;
$subcontainer-border:#CDD1E4;       // Se sustituye por el anterior
$state-process-prev:#B9E6B1;
$repeat-pattern: #5C6BA9;
$dot-off:#d3d3d3;
$wizard-line: #DEE0E9;
$donut-text: rgba(0, 25, 120, 0.5);
$donut-text-05: rgba(0, 25, 120, 0.05);
$donut-text-2: rgba(0,25,120,0.6);
$donut-text-02: rgba(0,25,120,0.2);
$background-color-states: #F2F3F8;
//$background-color-states: #F2F3F5;    // Se sustituye por el anterior
$background-other-2:#dedede;
$box-comment-border-2: rgba(0, 25, 120, 0.15);
$border-modal: #979797;
$advice-confirm: #417505;
$advice-exclamantion: #CF7700;
$advice-question: #4F92E0;
$advice-close:#C10F0B;
$attach-icon-grey: #989898;
//$attach-icon-grey: #A1A1A1;           //// Se sustituye por el anterior
$attachment-custom-grey: #606060;
$attachment-custom-grey-opacity: rgba(138,149,158,0.2);
$attachment-custom-grey-other: #AAAEB0;
$attachment-custom-grey-other-2:#F6F6F6;
$bar-horizontal-grey-opacity: rgba(86,86,86,0.3);
$bar-horizontal-grey-opacity02: rgba(86,86,86,0.2);
$bar-horizontal-grey-opacity04: rgba(86,86,86,0.4);
$bar-horizontal-grey-opacity05: rgba(86,86,86,0.5);
$bar-horizontal-task-grey: #9A9898;
$box-comment-border-02: rgba(0, 25, 120, 0.2);
$box-comment-border-03: rgba(0, 25, 120, 0.1);
$text-empty: #a9a9a9;
$text-readonly: #eeeeee;
$button-exit: #0F1978;
$security-pillow: #fefefe;
$pillow-group: #7F8CBB;
$security-tooltip: #fafafafa;
$table-members: rgba(213, 217, 237, 0.5);
$main-col-table: #D5D9ED;
$column-filter-border:rgba(0, 0, 0, 0.15);
$column-filter-shadow:rgba(0, 0, 0, 0.175);
$column-filter-shadow-2: rgba(0, 0, 0, 0.3);
$column-filter-shadow-45: rgba(0, 0, 0, 0.45);
$view-error-button: #AF8216;
$error-header: #121F75;
$upload-document-border: rgba(147,155,200, 0.15);
$upload-document-border-44:rgba(147,155,200,0.44);
$create-title: #ACACAC;
$shared-task-color: #014978;
$subcontainer-poll-border: rgba(0, 25, 120, 0.3);
$subcontainer-poll-border-02: rgba(0, 25, 120, 0.4);
$subcontainer-next: rgba(170, 170, 170, 0.5);
$schedule-big-body: #F8F8F8;
$fc-event: rgba(0,129,135,0.42);
$event-big-when: rgba(112,122,162,0.11);
$circle-steps-color: #ADB4C9;
$resul-report-percent: #6473AD;
$color-sibila: #aa186f;
$result-search-quote-color: #CCD1E4;
$reset-text-color:#D81F2A;
$subtitle-color: #D9DDEB;
$title-measure-color: #999999;
$report-table-lateral: #E0FFFF;
$number-medium-color:rgba(205,209,224,0.5);
$legend-color-red: #c13627;
$legend-color-orange: #f39d0a;
$legend-color-yellow: #f1c507;
$legend-color-green: #60c70c;
$block-step-shadow: rgba(64, 61, 61, 0.2);
$icon-check-saved: #0BD25E;
$public-link-text-step: #A1A4C2;
$element-transition: #D3D7E3;
$state-element-current: #7482B3;
$link-data-text: #EDF9EC;
$footer-legend-list: #E9E9E9;
$block-state-bar: #A3E7B1;
$block-state-bar-left: #00CF2A;
$picker-visibility-container: #7381B5;
$picker-visibility-data: #EBEBEB;
$picker-visibility-column: #A6A6A6;
$picker-visibility-footer: #f5f5f5;
$alert-result:#d51b1b;
$alert-result-background: #f4d6d8;
$client-logo-border: #f5f4f7;
$client-structure-block: #FEFCFC;
$modal-help-box: #D6D8D9;
$modal-help-info: #808CBC;
$modal-info-hours: #7C868C;
$menu-header-transparent: rgba(0, 0, 0, 0);
$column-filter-shadow-05: rgba(0, 0, 0, 0.5);
$column-filter-shadow-005: rgba(0, 0, 0, 0.05);
$document-container: #C5343E;
$document-container-font: #9d1f1f;
$document-container-level: #4D4D4D;
$document-container-resumen: #790000;
$legal-document-table: #CDCCCC;
$legal-document-content: #efefef;
$legal-document-border: #e7eef0;
$legal-document-layer: #F5EAE5;
$legal-document-form: #151515;
$add-layer-disabled: #CDD2E5;
$landing-button-creation: #E0E0E0;
$custom-language: #6C6C6C;
$custom-background: #E1E1E1;
$header-nav-logo: #808BBC;
$global-block-tiny: #A0A0A0;
$front-login-input: #d2d2d2;
$input-block-focus: rgba(102,175,233, 0.6);
$front-reset-pass: #333333;
$folder-subtitle: rgba(161, 161, 161, 0.1);
$folder-published: #0CCF5C;
$field-type-required-color: #F9E7E7;
$question-red: rgba(193, 15, 11, 0.2);
$question-green: rgba(0, 207, 42, 0.2);
$field-type-number-icon: #cbcbcb;
$block-number-color: #9e9e9e;
$list-block-custom: #868686;
$rich-text-color: #5565A1;
$icon-arrow: #939BC8;
$evaluation-tree-children: rgba(127, 140, 187, 0.3);
$group-heather-hightlight: #FEFCE8;
$dashboard-button: #AA1978;
$dashboard-button-2: #97D6DE;
$webkit-scroll-legal: #99A3C9;
$webkit-scroll-legal-38: rgba(153,163,201,0.38);
$box-shadow: 0 2px 5px 2px rgba(86,86,86,0.2);

// Colors landing custom
$custom-text-color: #8F8F8F;
$custom-secondary-color:#898989;
$custom-border-grey: #D8D8D8;
$certify-grey-opacity:rgba(191, 197, 221, 0.54);
$certify-black-opacity: rgba(65, 63, 63, 0.3);
$certify-black-opacity02: rgba(65, 63, 63, 0.2);
$certify-black-opacity01: rgba(65, 63, 63, 0.1);
$certify-black-opacity03: rgba(65, 63, 63, 0.5);
$comments-grey-opacity: rgba(244, 244, 244, 0.3);
$comments-other-grey: rgb(211, 210, 210);
$compliance-footer-grey: #393939;
$concept-grey: #FAFAFA;
//$concept-white: #FCFCFC;
$concept-other-grey: #D7D7D7;
$concept-from-grey: #EEEBEB;
$concept-legal-grey: #D0D3E2;
$circle-progress-grey:#E4E4E4;
$concept-blue:#6675AE;
$highlight-border: #FAE61E;
$no-primary-blue:#00137B;
$documentation-item: #F9EBEC;
$background-error: #F3D7D9; //#f4d6d8;//rgba(196, 55, 65, 0.2)
$time-picker-landing: rgba(214, 216, 217, 0.5);
$dark-gradient-blue: 0deg,  #6675AE 0%, #99A3C9 70%, #E5E8F1 100%;
$arc-value-color: rgb(255,255,255);
$evaluation-hidden-text: rgba(255,255,255,0.5);
$red-advice-landing: rgba(193, 15, 11, 0.15);
$yellow-advice-landing: rgba(207, 119, 0, 0.15);
$black-advice-landing: rgba(0, 0, 0, 0.10);


// Colors POC belgium steel-blue
$primary-color-2: #27344E;
$secondary-color-2: #CDD2E1;
$third-color-2: #B1EFD0;
$background-grey-disabled-2: #E6E8F0;
$border-hover-2:#008187;
$text-field-color-2: #677183;
$alert-color-2: #E24751;
$background-error-28: rgba(255,255,255,0.28);
// $background-error-03: rgba(255,255,255,0.3);
$background-other-error-2:rgba(226,71,81,0.2);
$background-error-2:#F9DADC; //rgba(226,71,81,0.2);
// $background-error-2: rgba(255,255,255,0.2);
$grey-icons-2: #9399A6;
$background-box-green-mid-2: rgba(172, 224, 233, 0.5);
$box-shadow-box-2: rgba(39, 52, 78, 0.4);
$border-bottom-2: #33B6BC;
$block-hover-2:  #D8F7E7;
$white-special-2: #F7F7F3;
$alert-color-button-2: #E3343F;
$background-grey-2: #F5F6F9;
$background-grey-opacity-2: rgba(39,52,78,0.1);
$background-highlight-text-2: #ACE0E9;
$green-color-v2: #00DA65;
$line-graph-shadow:rgba(85,85,85,0.3);
$other-special-white:#EEEFF1;
$alert-color-icon-2: #E2343F;
$border-color-other:#ccc;
$border-color-other-2:#90AFBD;
$background-otro-mas: #DFDFDF;
$other-gray: #AEAEAE;
$other-gray-special: #979CA8;
$border-proccess:#00127B;
$third-color-rgba-mid:rgba(177, 239, 208, 0.5);
$third-color-rgba-mid-54: rgba(177, 239, 208, 0.54);
$steel-gradient-blue:0deg, #00a7ac 0%, #97d9dc 75%, #a3e2db 94%, #aeead9 100%;
$icon-shadow-2: rgba(39,52,78,0.2);
$border-shadow-2: rgba(39,52,78,0.3);
$border-shadow-3: rgba(39,52,78,0.1);
$delete-amount: #5EC6DD;
$pink:#D48CBB;
$other-gray-03:#DFF4F8;
$donut-shadow-2: 0 0 5px 1px rgba(62,59,59,0.16);
$red-light-2: #F0A3A8;
//

// Colors UK steel-blue
$background-grey-uk: #EFF1F2;
$box-shadow-v3: 0 5px 20px 5px rgba(0,0,0,0.1);
$red-opacity-uk-36:rgba(226, 71, 81 ,0.36);
$green-opacity-uk-36: rgba(0, 218, 101, 0.36);
$yellow-opacity-uk-36: rgba(237,198,3,0.36);
$red-error-uk: #FB1926;

// Colors ICAM icam-red
$primary-color-icam: #701B31;
$secondary-color-icam: #ADB1BD;
$third-color-icam: #E2D1D6;
$text-field-color-icam: #777D8B;
$alert-color-icam: #D2333E;
$background-grey-disabled-icam: #E7E9EF;
$background-grey-icam: #F5F6F9;
$title-text-icam: #B78D98;
$border-block-icam: #CFD3DF;
$hover-block-icam: #F8F3F4;
$modal-question-icam: #A97683;
$gradient-icam-white-red: 0deg, #F0E8EA 0%, #E2D1D6 75%, #701B31 100%;
$gradient-icam-red-white: 0deg, #701B31 0%, #E2D1D6 75%, #F0E8EA 100%;
$gradient-icam-graphs: 0deg, #F5F6F9 0%, #CFD3DF 100%;
// $gradient-icam-big: 0deg, #E5E8F1 0%,#F8F3F4 5%, #F0E8EA 10%, #E2D1D6 20%, #C6A4AD 40%, #B78D98 50%, #A97683 60%, #8D495A 80%, #701B31 100%;
$yellow-task-icam: #F0BF29;
$yellow-task-50-icam: rgba(240, 191, 41, 0.5);
$yellow-task-40-icam: rgba(240, 191, 41, 0.40);
$yellow-task-36-icam: rgba(240, 191, 41, 0.36);
$yellow-task-20-icam: rgba(240, 191, 41, 0.2);
$other-yellow-icam: #D4A822;
$orange-task-icam: #FF914D;
$orange-task-50-icam: rgba(255, 145, 77, 0.5);
$orange-task-36-icam: rgba(255, 145, 77, 0.36);
$orange-task-10-icam: rgba(255, 145, 77, 0.1);
$green-task-icam: #1AB349;
$green-task-50-icam: rgba(26, 179, 73, 0.5);
$green-task-36-icam: rgba(26, 179, 73, 0.36);
$green-task-20-icam: rgba(26, 179, 73, 0.20);
$brown-task-icam: #9D8880;
$brown-task-50-icam: rgba(157, 136, 128, 0.5);
$brown-task-36-icam: rgba(157, 136, 128, 0.36);
$grey-task-icam: #ACB3C4;
$grey-task-50-icam: rgba(172, 179, 196, 0.5);
$grey-task-36-icam: rgba(172, 179, 196, 0.36);
$red-task-50-icam: rgba(210, 51, 62, 0.5);
$red-task-36-icam: rgba(210, 51, 62, 0.36);
$red-task-20-icam: rgba(210, 51, 62, 0.2);
$border-black-icam: rgba(0, 0, 0, 0.42);
$border-buttons-icam: rgba(119,125,139,0.4);
$background-box-icam: #F0E8EA;
$box-shadow-icam: 0 0 6px 2px rgba(119, 125, 139, 0.2);
$box-shadow-icam-2: 0 0 5px 6px rgba(119, 125, 139, 0.4);
//$border-header-shadow: 0 0 10px 6px rgba(119,125,139,0.2); //Se usa la clase de arriba ($box-shadow-icam)
$icam-grey-gradient: 180deg, #CFD3DF 0%, #F5F6F9 100%;
$primary-icam-10: rgba(112, 27, 49, 0.1);
$box-shadow-icam-color: rgba(119, 125, 139, 0.2);
$background-black-10-icam: rgba(133, 142, 148, 0.1);
$background-green-10-icam: rgba(26, 179, 73, 0.1);
$background-red-10-icam: rgba(210, 51, 62, 0.11);
$background-grey-05-icam: rgba(119, 125, 139, 0.05);

// Colors GM BUSINESS LAW gm-law

$primary-color-gm: #133371;
$secondary-color-gm: #858E94;
$third-color-gm: #D0D6E3;
$text-field-color-gm: #515E67;
$alert-color-gm: #D2333E;
$background-grey-disabled-gm: #DCDFE1;
$background-grey-gm: #F6F7F7;
$title-text-gm: #8999B8;
$border-block-gm: #B9BFC2;
$hover-block-gm: #F3F5F8;
$modal-question-gm: #7185AA;
$gradient-gm-white-green: 0deg, #E7EAF1 0%, #D0D6E3 75%, #133371 100%;
$gradient-gm-green-white: 0deg, #133371 0%, #D0D6E3 75%, #E7EAF1 100%;
$gradient-gm-graphs: 0deg, #F6F7F7 0%, #CFD3DF 100%;
// $gradient-gm-big: 0deg, #E5E8F1 0%,#F3F5F8 5%, #E7EAF1 10%, #D0D6E3 20%, #A1ADC6 40%, #B78D98 50%, #A97683 60%, #133371 80%, #701B31 100%;
$yellow-task-gm: #F0BF29;
$yellow-task-50-gm: rgba(240, 191, 41, 0.5);
$yellow-task-40-gm: rgba(240, 191, 41, 0.40);
$yellow-task-36-gm: rgba(240, 191, 41, 0.36);
$yellow-task-20-gm: rgba(240, 191, 41, 0.2);
$other-yellow-gm: #D4A822;
$orange-task-gm: #FF914D;
$orange-task-50-gm: rgba(255, 145, 77, 0.5);
$orange-task-36-gm: rgba(255, 145, 77, 0.36);
$green-task-gm: #1AB349;
$green-task-50-gm: rgba(26, 179, 73, 0.5);
$green-task-36-gm: rgba(26, 179, 73, 0.36);
$green-task-20-gm: rgba(26, 179, 73, 0.20);
$brown-task-gm: #9D8880;
$brown-task-50-gm: rgba(157, 136, 128, 0.5);
$brown-task-36-gm: rgba(157, 136, 128, 0.36);
$grey-task-gm: #ACB3C4;
$grey-task-50-gm: rgba(172, 179, 196, 0.5);
$grey-task-36-gm: rgba(172, 179, 196, 0.36);
$grey-icon-gm:#A1ADC6;
$red-task-50-gm: rgba(210, 51, 62, 0.5);
$red-task-36-gm: rgba(210, 51, 62, 0.36);
$red-task-20-gm: rgba(210, 51, 62, 0.2);
$border-black-gm: rgba(0, 0, 0, 0.42);
$border-buttons-gm: rgba(81, 94, 103, 0.4);
$background-box-gm: #E7EAF1;
$box-shadow-gm: 0 0 6px 2px rgba(81, 94, 103, 0.2);
$box-shadow-gm-1: 0 0 6px 2px rgba(81, 94, 103, 0.3);
$box-shadow-gm-2: 0 0 5px 6px rgba(81, 94, 103, 0.4);
$background-green-gm: rgba(26,179,73,0.2);
$background-alert-gm: rgba(210,51,62,0.2);
//$border-header-shadow: 0 0 10px 6px rgba(81, 94, 103, 0.2); //Se usa la clase de arriba ($box-shadow-gm)
$gm-grey-gradient: 180deg, #F6F7F7 0%, #DCDFE1 100%;
$primary-gm-10: rgba(72, 172, 51, 0.1);
$box-shadow-gm-color: rgba(119, 125, 139, 0.2);
$background-black-10-gm: rgba(133, 142, 148, 0.1);
$background-green-10-gm: rgba(26, 179, 73, 0.1);
$background-red-10-gm: rgba(210, 51, 62, 0.11);
$background-grey-05-gm: rgba(81,94,103,0.05);
// $event-border: rgba(0,25,120,0.42);

// Colors GERMANY 'com-de'
$primary-color-com-de: #004b93;
$secondary-color-com-de: #858E94;
$third-color-com-de: #ccdbe9;
$text-field-color-com-de: #515E67;
$alert-color-com-de: #D2333E;
$background-grey-disabled-com-de: #DCDFE1;
$background-grey-com-de: #F6F7F7;
$title-text-com-de: #80a5c9;
$border-block-com-de: #B9BFC2;
$hover-block-com-de: #f2f6fa;
$modal-question-com-de: #6693be;
$gradient-com-de-white-green: 0deg, #E7EAF1 0%, #ccdbe9 75%, #004b93 100%;
$gradient-com-de-green-white: 0deg, #004b93 0%, #ccdbe9 75%, #E7EAF1 100%;
$gradient-com-de-graphs: 0deg, #F6F7F7 0%, #CFD3DF 100%;
$yellow-task-com-de: #F0BF29;
$yellow-task-50-com-de: rgba(240, 191, 41, 0.5);
$yellow-task-40-com-de: rgba(240, 191, 41, 0.40);
$yellow-task-36-com-de: rgba(240, 191, 41, 0.36);
$yellow-task-20-com-de: rgba(240, 191, 41, 0.2);
$other-yellow-com-de: #D4A822;
$orange-task-com-de: #FF914D;
$orange-task-50-com-de: rgba(255, 145, 77, 0.5);
$orange-task-36-com-de: rgba(255, 145, 77, 0.36);
$green-task-com-de: #1AB349;
$green-task-50-com-de: rgba(26, 179, 73, 0.5);
$green-task-36-com-de: rgba(26, 179, 73, 0.36);
$green-task-20-com-de: rgba(26, 179, 73, 0.20);
$brown-task-com-de: #9D8880;
$brown-task-50-com-de: rgba(157, 136, 128, 0.5);
$brown-task-36-com-de: rgba(157, 136, 128, 0.36);
$grey-task-com-de: #ACB3C4;
$grey-task-50-com-de: rgba(172, 179, 196, 0.5);
$grey-task-36-com-de: rgba(172, 179, 196, 0.36);
$grey-icon-com-de:#99b7d4;
$red-task-50-com-de: rgba(210, 51, 62, 0.5);
$red-task-36-com-de: rgba(210, 51, 62, 0.36);
$red-task-20-com-de: rgba(210, 51, 62, 0.2);
$border-black-com-de: rgba(0, 0, 0, 0.42);
$border-buttons-com-de: rgba(81, 94, 103, 0.4);
$background-box-com-de: #e5edf4;
$box-shadow-com-de: 0 0 6px 2px rgba(81, 94, 103, 0.2);
$box-shadow-com-de-1: 0 0 6px 2px rgba(81, 94, 103, 0.3);
$box-shadow-com-de-2: 0 0 5px 6px rgba(81, 94, 103, 0.4);
$background-green-com-de: rgba(26,179,73,0.2);
$background-alert-com-de: rgba(210,51,62,0.2);
$com-de-grey-gradient: 180deg, #F6F7F7 0%, #DCDFE1 100%;
$primary-com-de-10: rgba(72, 172, 51, 0.1);
$box-shadow-com-de-color: rgba(119, 125, 139, 0.2);
$background-black-10-com-de: rgba(133, 142, 148, 0.1);
$background-green-10-com-de: rgba(26, 179, 73, 0.1);
$background-red-10-com-de: rgba(210, 51, 62, 0.11);
$background-grey-05-com-de: rgba(81,94,103,0.05);

// Colors FRANCE 'com-fr'
$primary-color-com-fr: #0d1e46;
$secondary-color-com-fr: #858E94;
$third-color-com-fr: #cfd2de;
$text-field-color-com-fr: #515E67;
$alert-color-com-fr: #D2333E;
$background-grey-disabled-com-fr: #DCDFE1;
$background-grey-com-fr: #F6F7F7;
$title-text-com-fr: #868ead;
$border-block-com-fr: #B9BFC2;
$hover-block-com-fr: #f3f4f7;
$modal-question-com-fr: #6e789c;
$gradient-com-fr-white-green: 0deg, #E7EAF1 0%, #cfd2de 75%, #0d1e46 100%;
$gradient-com-fr-green-white: 0deg, #0d1e46 0%, #cfd2de 75%, #E7EAF1 100%;
$gradient-com-fr-graphs: 0deg, #F6F7F7 0%, #CFD3DF 100%;
$yellow-task-com-fr: #F0BF29;
$yellow-task-50-com-fr: rgba(240, 191, 41, 0.5);
$yellow-task-40-com-fr: rgba(240, 191, 41, 0.40);
$yellow-task-36-com-fr: rgba(240, 191, 41, 0.36);
$yellow-task-20-com-fr: rgba(240, 191, 41, 0.2);
$other-yellow-com-fr: #D4A822;
$orange-task-com-fr: #FF914D;
$orange-task-50-com-fr: rgba(255, 145, 77, 0.5);
$orange-task-36-com-fr: rgba(255, 145, 77, 0.36);
$green-task-com-fr: #1AB349;
$green-task-50-com-fr: rgba(26, 179, 73, 0.5);
$green-task-36-com-fr: rgba(26, 179, 73, 0.36);
$green-task-20-com-fr: rgba(26, 179, 73, 0.20);
$brown-task-com-fr: #9D8880;
$brown-task-50-com-fr: rgba(157, 136, 128, 0.5);
$brown-task-36-com-fr: rgba(157, 136, 128, 0.36);
$grey-task-com-fr: #ACB3C4;
$grey-task-50-com-fr: rgba(172, 179, 196, 0.5);
$grey-task-36-com-fr: rgba(172, 179, 196, 0.36);
$grey-icon-com-fr:#9ea5bd;
$red-task-50-com-fr: rgba(210, 51, 62, 0.5);
$red-task-36-com-fr: rgba(210, 51, 62, 0.36);
$red-task-20-com-fr: rgba(210, 51, 62, 0.2);
$border-black-com-fr: rgba(0, 0, 0, 0.42);
$border-buttons-com-fr: rgba(81, 94, 103, 0.4);
$background-box-com-fr: #e7e8ee;
$box-shadow-com-fr: 0 0 6px 2px rgba(81, 94, 103, 0.2);
$box-shadow-com-fr-1: 0 0 6px 2px rgba(81, 94, 103, 0.3);
$box-shadow-com-fr-2: 0 0 5px 6px rgba(81, 94, 103, 0.4);
$background-green-com-fr: rgba(26,179,73,0.2);
$background-alert-com-fr: rgba(210,51,62,0.2);
$com-fr-grey-gradient: 180deg, #F6F7F7 0%, #DCDFE1 100%;
$primary-com-fr-10: rgba(72, 172, 51, 0.1);
$box-shadow-com-fr-color: rgba(119, 125, 139, 0.2);
$background-black-10-com-fr: rgba(133, 142, 148, 0.1);
$background-green-10-com-fr: rgba(26, 179, 73, 0.1);
$background-red-10-com-fr: rgba(210, 51, 62, 0.11);
$background-grey-05-com-fr: rgba(81,94,103,0.05);

//ICAM and IT
$blue-icam-it: #00A6DE;
//

$app-text-color: #333;

$custom-black-landing: #000000;
$snow-white: #FFFFFF;
$green: #77D166;
$red: #C43741;
$yellow: #F1BC3E;
$blue: #0000ff;
$silver: #C0C0C0;
$lime: #00FF00;
$cyan: #00FFFF;
$purple:#FF00FF;
$brown: #9A7E74;
$fuchsia: fuchsia;
$grey: grey;
$red-1: red;
$yellow-car: #a09c94;
$state-orange: #F1923E;
$option-menu-grey: #808080;
$states-process-light-grey: #929B9C;
$docu-meme: rgb(153, 51, 0);
$docu-meme-back: rgba(153, 51, 0, 0.1);
$docu-meme-MS: rgb(116, 20, 37);
$docu-meme-MS-back: rgba(116, 20, 37, 0.1);
$docu-meme-MF: rgb(10, 36, 71);
$docu-meme-MF-back: rgba(10, 36, 71, 0.1);
$docu-meme-MC: rgb(26, 69, 154);
$docu-meme-MC-back: rgba(26, 69, 154, 0.1);
$green-opacity: rgba(119,209,102, 0.5);
$green-opacity-01: rgba(119,209,102, 0.1);
$green-opacity-02: rgba(119,209,102, 0.2);
$green-opacity-08: rgba(199,237,192,0.8);
$green-opacity-15: rgba(119,209,102, 0.15);
$green-opacity-36: rgba(119,209,102, 0.36);
$red-opacity: rgba(196,55,65, 0.5);
$red-opacity-01: rgba(196,55,65, 0.1);
$red-opacity-02: rgba(196,55,65, 0.2);
$red-opacity-07: rgba(196,55,65, 0.7);
// $red-opacity-08: rgba(229,160,154,0.8);
$red-opacity-15: rgba(196,55,65, 0.15);
$red-opacity-36: rgba(196,55,65, 0.36);
$yellow-opacity: rgba(241,188,62, 0.5);
// $yellow-opacity-08: rgba(241,188,62, 0.8);
$yellow-opacity-02: rgba(241,188,62, 0.2);
$yellow-opacity-36: rgba(241,188,62, 0.36);
$yellow-opacity-01: rgba(241,188,62, 0.1);
// $orange-opacity-08: rgba(241, 146, 62, 0.8);
$orange-opacity-36: rgba(241, 146, 62, 0.36);
$orange-opacity-15: rgba(241, 146, 62, 0.15);
$yellow-opacity-15: rgba(241,188,62, 0.15);
// $brown-opacity-08: rgba(154,126, 116,0.8);
$brown-opacity-36: rgba(154,126, 116,0.36);
$brown-opacity-15: rgba(154,126, 116,0.15);
// $grey-opacity-08: rgba(146, 155, 156, 0.8);
$grey-opacity-15: rgba(146, 155, 156, 0.15);
$grey-opacity-36: rgba(146, 155, 156, 0.36);
$process-opacity-36: rgba(118, 131, 181, 0.36);
$blue-opacity-04: rgba(92, 107, 169, 0.4);
$blue-opacity-05: rgba(92, 107, 169, 0.5);
$custom-color-7683B5: #7683B5;
$custom-color-7A58AC: #7A58AC;
$custom-color-E5869D: #E5869D;
$custom-color-AF9F01: #AF9F01;
$custom-color-B87300: #B87300;
$custom-color-BC5901: #BC5901;
$custom-color-830065: #830065;
$custom-color-4A006F: #4A006F;
$custom-color-001478: #001478;
$custom-color-01417F: #01417F;
$custom-color-009598: #009598;
$custom-color-00773B: #00773B;
$custom-color-008B07: #008B07;
$custom-color-FFF201: #FFF201;
$custom-color-FFB001: #FFB001;
$custom-color-EB6F00: #EB6F00;
$custom-color-FF3734: #FF3734;
$custom-color-D011A2: #D011A2;
$custom-color-9470C0: #9470C0;
$custom-color-003FAE: #003FAE;
$custom-color-3B92D6: #3B92D6;
$custom-color-00D2D6: #00D2D6;
$custom-color-00B165: #00B165;
$custom-color-FAE61E: #FAE61E;
$custom-color-021A77: #021A77;
$custom-color-E08785: #E08785;
$custom-color-F05968: #F05968;
$custom-color-E3E4E8: #E3E4E8;
$custom-color-306AC5: #306AC5;
$custom-color-C5C5C5: #C5C5C5;
$custom-color-858585: #858585;
$custom-color-ADADAD: #ADADAD;
$custom-color-3498DB: #3498DB;
$custom-color-E3E3E3: #E3E3E3;
$custom-color-CDD0DF: #CDD0DF;
$grey-shadow-otra-mas:rgba(160, 160, 160, 0.5);
$fiscal:#003057;
$administrativo: #7C868C;
$mercantil: #0053B1;
$igualdad: #8B2432;
$penal: #430098;
$na: #FAB627;
$snow-white-opacity-20: rgba(255, 255, 255, 0.2);
$snow-white-opacity-50: rgba(255, 255, 255, 0.5);
$warning-background-2: rgba(237, 198, 3, 0.2);
$warning-background-4: rgba(237, 198, 3, 0.4);
$warning-yellow:#EDC603;
$black-opacity: rgba(0,0,0,0.1);
$black-opacity-02: rgba(0,0,0,0.2);
$black-opacity-03: rgba(0,0,0,0.3);
$box-shadow-landing: 0 0 12px 3px $black-opacity-02;
$max-width-smartphone: 730px;
// extend classes //
@mixin widget-header($background) {
    color: $background;
    &__icon {
        color: $background;
    }
    &-icon {
        color: $background;
    }
    &__search-input {
        border-color: $background;
    }
    &__pillow {
        color: $background;
        border-color: $background;
    }
    &__views {
        &__button {
            border-color: $background;
            &.selected:before {
                background-color: $background;
            }
        }
    }
    &__bottom-block{
        border-color: $background;
    }
}

@mixin add-layer($background) {
    &__bar {
        background-color: $background;
    }
    &__button {
        // background-color: $background;
        color: $background;
        &--desactive{
            background-color: $result-search-quote-color;
            cursor: default;
        }
        &:disabled {
            // background-color: $add-layer-disabled;
            color: $add-layer-disabled;
        }
    }
    &--button-desactive {
        background-color: $desactive-color;
        color: $desactive-color;
        &:hover {
            cursor: auto;
        }
    }
}
@mixin button-with-border($primary, $secondary, $backgroundColor) {
    border: 3px solid $secondary;
    color: $secondary;
    background-color:$backgroundColor;
    height: 41px;
    &:hover:not([disabled]) {
        color: $primary;
        background-color: $secondary;
    }

    &[disabled] {
        opacity: 0.5;
    }
    &:after{
        content: " ";
        border-bottom: 3px solid $secondary;
        width: calc(100% + 76px);
        display: block;
        right: 38px;
        top: 10px;
        position: relative;
    }
}

@mixin button-without-border($firstColor, $secondary, $backgroundColor){
    border: 3px solid $secondary;
    color: $secondary;
    background-color:$backgroundColor;
    height: 41px;
    &:after{
        display: none;
    }
    &:hover:not([disabled]){
        color: $firstColor;
        background-color: $secondary;
    }
}


@keyframes blinkUpload{
    0%{
        background-color: $primary-color;
    }
    24%{
        background: $clients-list-color;
    }
    60%{
        background: transparent;
    }
    100%{
        background: $primary-color;
    }

}

@keyframes blinkUpload-2{
    0%{
        background-color: $primary-color-2;
    }
    24%{
        background: $primary-color-2;
    }
    60%{
        background: transparent;
    }
    100%{
        background: $primary-color-2;
    }

}

