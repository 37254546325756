.personalized-notification-modal {
  .modal-dialog{
      & .modal-content {
          height: 565px;
      }
  }
  &__block-body{
      display: block;
      width: 100%;
      height: 473px;
      padding: 45px 71px 0;
  }

  &__block-title {
      display: block;
      width: 100%;
      margin-bottom: 28px;
  }

  &__text-rich{
      display: block;
      width: 100%;
      overflow: hidden;
      height: 235px;
      margin-top: 10px;
      // .mce-edit-area{
      //     height: 166px;
      //     > iframe{
      //         height: 100% !important;
      //     }
      // }
  }
}
