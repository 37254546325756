@import '../../../../assets/scss/colors';
.task-list {
  padding: 10px;

  &__button-task {
      color: $snow-white;
      font-size: 10px;
      background-color: $primary-color;
      font-family: MTTMilano, sans-serif;
      height: 29px;
      border: none;
      display: block;
      float: right;
      border-left: 1px solid $snow-white;
      padding: 0 20px;
      opacity: 0.75;
      outline: none;
      text-transform: uppercase;

      &--active {
          background-color: $primary-color;
          opacity: 1;
      }

      &--removed {
          background-color: $other-gray-03;
          color: $primary-color;
          opacity: 1;
      }

      &--active-removed {
          background-color: $delete-amount;
          color: $snow-white;
          opacity: 1;
      }

      &--evaluation {
          padding: 0 10px;
      }
  }

  &__expiration-date {
      display: inline;
      position: relative;
  }

  &__icon-type {
      font-size: 20px;
      color: $primary-color;
      margin-left: 6px;
      float: left;
      position: relative;
  }
  ///////////////////
  ////New Version///
  /////////////////
  &__col-new-version {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding-right: 5px;

      &--tooltip {
          max-width: 0;
      }

      &--type {
          width: 60px;
          min-width: 60px;
      }

      &--title {
          width: 16%;
      }

      &--title-7-column{
          width: 25%;
      }

      &--evaluation {
          width: 16%;
      }

      &--organization {
          width: 14%;
      }

      &--recipient {
          width: 14%;
      }

      &--recipient-7-column{
          width: 21%;
      }

      &--creation {
          width: 10%;
      }

      &--creation-7-column{
          width: 17%;
      }

      &--expiration {
          width: 10%;
      }

      &--expiration-7-column{
          width: 17%;
      }

      &--state {
          width: calc(20% - 120px);
      }

      &--state-body {
          width: calc(20% - 82px);
          padding-bottom: 2px;
      }

      &--actions {
          width: 60px;
          padding-right: 0;
          position: relative;
      }

      &--actions-body {
          width: 22px;
          padding-right: 0;
          position: relative;
      }

      &--header-top {
          // padding-top: 1px;
          top: 1px;
          position: relative;
      }
  }

  &__warning-dot-icon {
      font-size: 11px;
      color: $red;
      top: 4px;
  }
  //////////////////////
  ////Fin New Version/////
  /////////////////////
}

.compliance{
  &__steel-blue{
    .task-list {
      &__icon-type {
        color: $border-hover-2;
      }
    }
  }

  &__icam-red{
    .task-list {
      &__warning-dot-icon{
        color: $alert-color-icam;
      }
      &__icon-type {
        color: $primary-color-icam;
      }
    }
  }
  &__gm-law{
    .task-list {
      &__icon-type {
        color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    .task-list {
      &__icon-type {
        color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .task-list {
      &__icon-type {
        color: $primary-color-com-fr;
      }
    }
  }
}
