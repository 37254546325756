.subcontainer-multiple{
  display: block;
  // padding-bottom: 20px;
  background-color: $snow-white;
  border: 1px solid $subcontainer-border;
  &--custom{
      background-color: transparent;
      border: none;
  }
  &__correct-margin{
      margin: 0 10px;
  }
  &__separated{
      height: 40px;
      background-color: $snow-white;
  }

  &__table-duplicated{
      display: table;
      width: 100%;
      padding-bottom: 10px;
  }

  &__duplicated{
      display: table-cell;
      max-width: 0;
      position: relative;
  }

  &__duplicate-button{
      color: $primary-color;
      padding-top: 6px;
      text-transform: uppercase;
      height: 32px;
      // width: 65px;
      width: auto;
      border: 2px solid $primary-color;
      font-size: 10px;
      font-family: MTTMilano-Bold, sans-serif;
      line-height: 0;
      text-align: center;
      margin-bottom: 1px;
      outline: none;
      background-color: transparent;
      &:hover{
          color:$snow-white;
          background-color: $primary-color;
      }
      &:after {
          content: " ";
          border-bottom: 2px solid $primary-color;
          width: calc(100% + 16px);
          display: block;
          right: 8px;
          top: 15px;
          position: relative;
      }
  }

  &__subcontainer-block{
      margin-bottom: 0;
      &--hovered{
        &:hover{
          & .concept-form__icon-subgroup {
            padding-right: 25px;
          }
        }
      }
  }

  &__clone-menu{
      display: block;
      float: right;
      width: 51px;
      position: relative;
      bottom: 12px;
      height: 49px;
      background: $custom-secondary-color;
      border-radius: 0 4px 4px 0;
      &--expand{
          width: 284px;
      }
      &--delete{
          bottom: 29px;

      }
  }
  &__expand-button{
      display: block;
      width: 19px;
      float: left;
      height: 49px;
      background: $attachment-custom-grey;
      position: relative;
      border: none;
      outline: none;
  }
  &__expand-icon{
      color: $snow-white;
      font-size: 17px;
      position: absolute;
      top: 16px;
      left: 1px;
  }
  &__expand-text{
      width: calc(100% - 19px);
      float: left;
      color: $snow-white;
      font-family: 'MTTMilano-bold', sans-serif;
      font-size: 10px;
      letter-spacing: 0;
      text-align: center;
      padding: 10px;
      &--expand{
          width: 82px;
          border-right: 1px solid $snow-white;
          margin: 5px 0;
          height: 39px;
          padding: 5px 10px;
      }
  }
  &__num-of-copies-text{
      color: $snow-white;
      font-family: 'MTTMilano', sans-serif;
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      text-transform: initial;
      float: left;
      margin-left: 11px;
      position: relative;
      top: 16px;
      max-width: 74px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
  }
  &__copies-block{
      height: 27px;
      width: 43px;
      background: $snow-white;
      float: left;
      top: 12px;
      margin-left: 11px;
      color: $custom-black-landing;
      font-family: 'MTTMilano', sans-serif;
      font-size: 13px;
      font-weight: 500;
      padding-left: 6px;
      padding-top: 3px;
  }
  &__copies-block-icon{
      font-size: 16px;
      position: relative;
      left: 0px;
      top: 3px;
      &:hover{
          cursor: pointer;
      }
  }
  &__copies-list{
      min-width: 45px;
      min-height: 80px;
      max-width: 45px;
      width: 45px;
      background-color: $snow-white;
      border-radius: 0;
      padding: 2px 0;
      top: 25px;
      box-shadow: 0 2px 8px 0 $grey-shadow-otra-mas;
      /* border-top: none; */
      left: -1px;
  }
  &__copies-item{
      color: $custom-black-landing;
      font-family: 'MTTMilano', sans-serif;
      font-size: 12px;
      padding-left: 6px;
      padding-top: 1px;
      &:hover{
          cursor: pointer;
          background-color: $background-grey;
      }
  }
  &__copies-save-button{
      padding: 0;
      border: none;
      background: none;
      font-size: 22px;
      color: $snow-white;
      margin-top: 12px;
      padding-left: 14px;
  }
  &__delete-icon{
      color: $snow-white;
      font-size: 25px;
      position: relative;
      top: 13px;
      margin-left: 12px;
      &:hover{
          cursor: pointer;
      }
  }
}
.compliance{
  &__steel-blue{
      .subcontainer-multiple{
          &__table-duplicated{
              background-color: $background-grey-icam;
          }
          &__duplicate-button{
              border-color: $primary-color-2;
              color: $primary-color-2;
              &:hover{
                  background-color: $primary-color-2;
              }
              &:after {
                  border-color: $primary-color-2;
              }
         }
      }
  }
}
