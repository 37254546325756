.field-item {
  display: block;
  width: 100%;
  margin-bottom: 4px;

  &__button-bool {
      background-color: $snow-white;
      width: 50%;
      height: 21px;
      &:hover {
          cursor: pointer;
      }
      &--poll {
          height: 36px;
      }
      &--left {
          border-top-left-radius: 151px;
          border-bottom-left-radius: 151px;
          border-right: 1px solid;
      }
      &--right {
          border-top-right-radius: 151px;
          border-bottom-right-radius: 151px;
          border-left: 1px solid;
      }
  }

  &__answer-block {
      display: inline-flex;
      width: 100%;
      &--finish-modal{
          width: 60px;
          // margin-left: 70px;
          margin-left: 93.5px;
          margin-top: 4px;
          margin-bottom: 18px;
      }
  }

  &__bool-block {
      float: right;
      position: relative;
      top: 12px;
      padding-right: 5px;
      // padding-left: 10%;
  }

  &__bool-box {
      height: 26px;
      width: 29px;
      border: 2px solid $primary-color;
      background: $snow-white;
      // color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      float: right;
      text-transform: capitalize;
  }

  &__bool-input {
      visibility: hidden;
      height: 0;
      width: 0;
  }


  &__bool-text {
      text-align: center;
      padding: 0;
      font-size: 12px;
      margin-top: 2px;
      // margin-left: 2px;
      &--poll {
          margin-top: 7px;
          font-size: 16px;
      }
      &--finish-modal{
          font-weight: bold;
          font-size: 10px;
      }
  }

  &__block-title-bool {
      & > p {
          margin: 0;
      }
  }

  &__button-icon-video {
      border: none;
      background: transparent;
      height: 0px;
      margin-left: 5px;
      outline: 0;
  }

  &__check {
      display: none;
  }

  &__field-type-bool {
      width: 100%;
      display: table;
      min-height: 41px;
      padding-right: 3px;

      &--is-highlight-poll {
          padding-left: 10px;
          background: none
      }

      &--custom {
          min-height: 47px;
      }

      &--is-highlight-custom {
          min-height: 54px;
      }

      &:hover {
          & .common__tooltip-alert-icon--poll {
              visibility: visible
          }

          & .common__references-block {
              visibility: visible;
          }
      }
  }

  &__text-required-bool {
      color: $red !important;
      position: absolute;
      font-size: 18px;
      bottom: -2px;
      font-family: Arial, sans-serif;
  }

  &__find-position {
      position: relative;
  }

  &__global-block {
      padding: 5px 0;
      // margin-bottom: -1px;
      margin-top: -5px;
      border-top: 1px solid $result-search-quote-color;
      border-bottom: 1px solid $result-search-quote-color;
      &--highlight-landing-custom {
          padding: 0;
          border-top: 5px solid $custom-color-FFB001;
          background-color: $attachment-custom-grey-other-2;
      }
  }

  &__icon-check {
      font-size: 15px;
      position: relative;
      top: 1px;
      padding-right: 2px;

      &--multiple {
          float: left;
          top: inherit;
      }

      &--custom {
          font-size: 12px;
          top: 5px;
      }
  }

  &__icon-video {
      opacity: 0.3;
  }

  &__icon-related-info {
      position: relative;
      width: 15px;
      font-size: 15px;
      height: 15px;
      float: left;
      left: 2px;
      top: 2px;
      &--report-info{
        left: 0px;
        top: 0px;
      }

      &--poll {
          float: none;
          position: relative;
          left: 0;
          top: inherit;
          bottom: 1px;
      }

      &:hover {
          cursor: pointer;
      }
  }

  &__item-bool {
      padding: 10px 10px;
  }

  &__lfo-icon {
      position: absolute;
      top: 0;
      right: 5px;
      color: $primary-color;
      font-size: 16px;
  }

  &__max-width {
      width: calc(90% - 40px);
  }

  &__open-evaluated-concept {
      float: right;
      color: $primary-color;
      font-size: 16px;
      margin-right: 3px;

      &:hover {
          cursor: pointer;
      }
  }

  &__options-block {
      width: 70px;
      height: 25px;
      -webkit-border-radius: 151px;
      -moz-border-radius: 151px;
      border-radius: 151px;
      float: right;

      &--poll {
          float: left;
          height: 40px;
          width: 122px;
      }
  }

  // &__rich-editable-header {
  //     margin-bottom: 3px;
  // }

  &__text-label {
      position: relative;
      float: left;
      margin: 0;
      padding-left: 10px;
      max-width: 700px;
      line-height: 15px;
      &--is-highlight {
          padding-left: 5px;
      }
      &--poll {
          padding-bottom: 13px;
          padding-left: 0;
          max-width: 100%;
      }

      &--is-highlight-custom {
          font-weight: bold;
      }
  }

  &__video-text {
      font-weight: 100;
      outline: none;
  }

  &__without-required {
      float: left;
      padding-left: 5px;
  }

  &__width {
      width: 96%;
      border-bottom: 1px solid $primary-color;
      margin-left: 1%;
  }

  &__cell-block {
      position: relative;
      float: left;
      max-width: calc(100% - 33px);
      height: 17px;

      &--custom {
          color: $custom-black-landing;
          font-family: 'lato_regular', sans-serif;
          font-size: 13px;
      }
      &--no-editable {
          color: $secondary-color;
          cursor: not-allowed;
      }

      &--required {
          color: $red;
      }

  }

  &__date-input {
      display: block;
      position: absolute;
      top: 26px;
      // left: -7px;
      &--poll {
          font-size: 16px;
          top: 13px;
      }
  }

  // &__real-input-date {
  //     visibility: hidden;
  //     width: 0px;
  //     height: 0px;
  //     position: absolute;
  //     bottom: 0px;
  //     left: 0px;
  // }

  &__file-list {
      padding: 0 10px;
      margin: 0;
      list-style: none;
      overflow: auto;
      width: 100%;
      min-height: 20px;
  }

  &__file-item {
      display: block;
      width: 100%;
      margin-bottom: 2px;
      overflow: hidden;

      &:hover {
          & .field-item__file-icon {
              display: block;
          }
      }
  }

  &__file-link {
      color: $attach-icon-grey;
      float: left;
      position: relative;
      top: 3px;
      padding-right: 6px;
  }

  &__file-text {
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: 500;
      padding-right: 10px;
      float: left;
      margin: 0;
      &--custom {
          color: $custom-secondary-color;
          font-family: 'lato_regular', sans-serif;
      }
  }

  &__file-icon {
      font-size: 10px;
      position: relative;
      display: none;
      float: left;

      &:hover {
          cursor: pointer;
      }

      &--delete {
          top: 4px;
      }

      &--download {
          top: 1px;
          font-size: 14px;
          margin-right: 7px;
      }

      &--custom-delete {
          display: block;
          color: $custom-black-landing;
          top: 6px;
      }

      &--custom-download {
          display: block;
          font-size: 13px;
          color: $custom-black-landing;
          top: 4px;
          margin-right: 7px;
      }
  }

  &__up-file {
      font-size: 20px;
      top: 3px;
  }

  &__rename {
      font-size: 20px;
      top: 3px;
      right: 30px;
  }

  &__input-icon-password {
      background-color: transparent;
      outline: none;
      border: none;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 6px;
      top: 5px;
      font-size: 20px;
  }

  &__input-icon-16px {
      font-size: 16px;
  }

    &__blocker-box{
      position: absolute;
      right: -143px;
      bottom: 10px;
      box-sizing: border-box;
      height: 27px;
      width: 137px;
      background-color: $snow-white;
      box-shadow: 0 0 10px 6px $bar-horizontal-grey-opacity02;
      z-index: 200;
      padding-top: 4px;
      padding-left: 9px;
  }
  &__blocker-triangle{
      &:after{
          content: "";
          border-left: 6px solid transparent;
          border-top: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid transparent;
          position: relative;
          position: absolute;
          left: -12px;
          top: 7px;
      }
  }
  &__blocker-icon{
      font-size: 15px;
      float: left;
      padding-right: 3px;
  }
  &__blocker-text{
      font-family: "MTTMilano", sans-serif;
      font-size: 12px;
  }
  //////////////////////////////////////////////
  ///////////////////Custom////////////////////
  ////////////////////////////////////////////
  &__option-bool-custom {
      position: relative;
      float: left;
      color: $custom-secondary-color;

      &:hover {
          cursor: pointer;
      }

      &--right {
          float: right;
      }

      &--green {
          color: $block-state-bar-left;
      }

      &--red {
          color: $advice-close;
      }

      &--error {
          color: $red;
      }

      &--disabled {
          color: $subcontainer-ground;

          &:hover {
              cursor: default;
          }
      }
  }

  &__icon-bool-custom {
      font-size: 15px;
      position: relative;
      padding-left: 3px;
      float: left;
      top: 1px;
  }

  &__text-bool-custom {
      position: relative;
      float: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      // color: $custom-secondary-color;
      font-family: 'lato_regular', sans-serif;
      font-size: 13px;
      font-weight: bold;
      margin: 0;
  }

  &__input-icon-custom-number {
      top: 4px;
      font-size: 15px;
  }

  &__input-icon-custom-coin {
      font-size: 13px;
      top: 4px;
  }

  &__input-icon-custom-email {
      font-size: 19px;
      top: 3px;
      right: 6px;
  }

  &__input-icon-custom-textarea {
      font-size: 20px;
      right: 10px;
  }
  //////////////////////////////////////////////
  /////////////////Fin Custom//////////////////
  ////////////////////////////////////////////
}

// @media (max-width: $max-width-smartphone) { //Smartphone
//   // .field-item {
//   // }
// }

.uib-datepicker-popup.dropdown-menu {
  border: 1px solid $primary-color;
  border-radius: 0;
  box-shadow: none;
  padding-bottom: 0;
  z-index: 1050;
  margin-left: -1px;
  // margin-top: 1px;
}

.compliance{
  &__dark-blue{
    .field-item {
      &__blocker-box{
        border: 1px solid $red;
      }
      &__blocker-triangle{
        &:after{
            border-right-color:$red;
        }
      }
      &__blocker-icon{
         color: $red;
      }
      &__blocker-text{
         color: $red;
      }
      &__button-bool {
        border-color: $primary-color;
        color: $primary-color;
        &:hover {
            background-color: $background-grey-other;
        }
        &--selected {
            background-color: $primary-color;
            color: $snow-white;
            &:hover {
                background-color: $primary-color;
            }
        }
        &--no-editable{
            border-color: $subcontainer-ground;
            color: $subcontainer-ground;
            &:hover {
                cursor: not-allowed !important;
                background-color: $snow-white;
            }
        }
        &--selected-no-editable {
            color: $snow-white;
            background-color: $subcontainer-ground;
            &:hover {
                border-right-color: $subcontainer-ground;
                color: $snow-white;
                background-color: $subcontainer-ground;
            }
        }
        &--alert{
            background-color: $background-error;
            color: $red;
            border-color: $red;
            &:hover {
                // background-color: $background-grey-other;
                // color: $primary-color;
                background-color: $red;
                color: $snow-white;
                cursor: pointer;
            }
        }
        &--finish-right{
            border: 2px solid $primary-color;
            border-left: none;
        }
        &--finish-left{
          border: 2px solid $primary-color;
          border-right: none;
        }
        &--finish-right-no-editable{
            border: 2px solid $subcontainer-ground;
            border-left: none;
        }
        &--finish-left-no-editable{
            border: 2px solid $subcontainer-ground;
            border-right: none;
        }
      }
      &__bool-box {
          border-color: $primary-color;
          background-color: $primary-color;
          &--no-editable{
              border-color: $subcontainer-ground;
              background-color: $subcontainer-ground;
          }
          &--alert{
              border-color: $red;
              background-color: $snow-white;
          }
      }
      &__field-type-bool {
        &--is-highlight{
            background-color: $background-color-states;
            min-height: 54px;
            border-left: 5px solid $highlight-border;
        }
      }
      &__file-link {
          color: $grey-icons-2;
      }
       &__file-text {
          color: $secondary-color;
       }
       &__file-icon {
          color: $primary-color;
       }
    }
  }
  &__steel-blue, &__uk-steel-blue{
    .field-item {
      &__blocker-box{
        border: 1px solid $alert-color-2;
      }
      &__blocker-triangle{
        &:after{
            border-right-color: $alert-color-2;
        }
      }
      &__blocker-icon{
        color: $alert-color-2;
      }
      &__blocker-text{
        color: $alert-color-2;
      }
      &__button-bool {
        border-color: $border-hover-2;
        color: $primary-color-2;
        &:hover {
            background-color: $third-color-2;
        }
        &--selected{
            background-color: $border-hover-2;
            color: $white-special-2;
            &:hover {
                background-color: $border-hover-2;
            }
        }
        &--no-editable{
            border-color: $grey-icons-2;
            color: $primary-color-2;
            background-color: $background-grey-disabled-2;
            &:hover {
                cursor: not-allowed !important;
                border-color: $grey-icons-2;
                background-color: $background-grey-disabled-2;
            }
        }

        &--selected-no-editable{
            color: $white-special-2;
            background-color: $grey-icons-2;
            &:hover {
                color: $white-special-2;
                background-color: $grey-icons-2;
            }
        }
        &--alert{
            color: $alert-color-2;
            border-color: $alert-color-2;
            &:hover {
                background-color: $background-error-2;
                cursor: pointer;
            }
        }

        &--finish-right{
            border: 2px solid $border-hover-2;
            border-left: none;
        }
        &--finish-left{
            border: 2px solid $border-hover-2;
            border-right: none;
        }

        &--finish-right-no-editable{
            border: 2px solid $grey-icons-2;
            border-left: none;
        }
        &--finish-left-no-editable{
            border: 2px solid $grey-icons-2;
            border-right: none;
        }
      }
      &__bool-box {
        border-color: $border-hover-2;
        background-color: $border-hover-2;
        &--no-editable{
            border-color: $grey-icons-2;
            background-color: $grey-icons-2;
        }
        &--alert{
            border-color: $alert-color-2;
            background-color: $snow-white;
        }
      }
      &__field-type-bool {
        &--is-highlight{
            background-color: $block-hover-2;
            border-left: 5px solid $third-color-2;
        }
      }
      &__global-block {
        padding: 0;
        border-top-color: $secondary-color-2;
        border-bottom-color: $secondary-color-2;
      }
      &__icon-check {
          color: $border-hover-2;
      }

      &__cell-block {
          &--no-editable{
             color: $primary-color-2;
             cursor: not-allowed;
          }
          &--required{
             color: $alert-color-2;
          }
      }
      &__file-link {
          color: $grey-icons-2;
      }
      &__file-text {
          color: $text-field-color-2;
      }
      &__file-icon {
          color: $border-hover-2;
      }
    }
  }
  &__icam-red{
    .field-item {
      &__button-bool {
          border-color: $primary-color-icam;
          color: $custom-black-landing;
          &:hover {
              background-color: $hover-block-icam;
          }
          &--selected{
              background-color: $primary-color-icam;
              color: $snow-white;
              &:hover {
                  background-color: $primary-color-icam;
              }
          }
          &--no-editable{
              border-color: $secondary-color-icam;
              color: $secondary-color-icam;
              &:hover {
                  cursor: not-allowed !important;
                  background-color: $snow-white;
              }
          }
          &--selected-no-editable{
              color: $snow-white;
              background-color: $secondary-color-icam;
              &:hover {
                  border-right-color: $secondary-color-icam;
                  color: $snow-white;
                  background-color: $secondary-color-icam;
              }
          }
          &--alert{
              background-color: $snow-white;
              color: $alert-color-icam;
              border-color: $alert-color-icam;

              &:hover {
                  background-color: $red-task-20-icam;
                  cursor: pointer;
              }
          }
      }
      &__bool-box {
          border-color: $primary-color-icam;
          background-color: $primary-color-icam;
          &--no-editable{
              border-color: $secondary-color-icam;
              background-color: $secondary-color-icam;
          }
          &--alert{
              border-color: $alert-color-icam;
              background-color: $snow-white;
          }
      }
      &__field-type-bool {
        &--is-highlight{
            background-color: $third-color-icam;
            border-left: 5px solid $title-text-icam;
        }
      }
      &__global-block {
          padding: 0;
          border-top-color: $border-block-icam;
          border-bottom-color: $border-block-icam;
      }
      &__icon-check {
          color: $primary-color-icam;
      }
      &__cell-block {
        &--no-editable{
            color: $custom-black-landing;
            cursor: not-allowed;
        }
        &--required{
            color: $alert-color-icam;
        }
      }
      &__file-link {
          color: $secondary-color-icam;
      }
      &__file-text {
          color: $text-field-color-icam;
      }
      &__file-icon {
          color: $primary-color-icam;
      }
    }
  }
  &__gm-law{
    .field-item {
      &__button-bool {
          border-color: $primary-color-gm;
          color: $custom-black-landing;
          &:hover {
              background-color: $hover-block-gm;
          }
          &--selected{
              background-color: $primary-color-gm;
              color: $snow-white;
              &:hover {
                  background-color: $primary-color-gm;
              }
          }
          &--no-editable{
              border-color: $secondary-color-gm;
              color: $secondary-color-gm;
              &:hover {
                  cursor: not-allowed !important;
                  background-color: $snow-white;
              }
          }
          &--selected-no-editable{
              color: $snow-white;
              background-color: $secondary-color-gm;
              &:hover {
                  border-right-color: $secondary-color-gm;
                  color: $snow-white;
                  background-color: $secondary-color-gm;
              }
          }
          &--alert{
              background-color: $snow-white;
              color: $alert-color-gm;
              border-color: $alert-color-gm;
              &:hover {
                  background-color: $red-task-20-gm;
                  cursor: pointer;
              }
          }
          &--finish-right{
              border: 2px solid $primary-color-gm;
              border-left: none;
          }
          &--finish-left{
              border: 2px solid $primary-color-gm;
              border-right: none;
          }
          &--finish-right-no-editable{
              border: 2px solid $secondary-color-gm;
              border-left: none;
          }
          &--finish-left-no-editable{
              border: 2px solid $secondary-color-gm;
              border-right: none;
          }
      }
      &__bool-box {
        border-color: $primary-color-gm;
        background-color: $primary-color-gm;
        &--no-editable{
            border-color: $secondary-color-gm;
            background-color: $secondary-color-gm;
        }
        &--alert{
            border-color: $alert-color-gm;
            background-color: $snow-white;
        }
      }
      &__field-type-bool {
        &--is-highlight{
            background-color: $third-color-gm;
            border-left: 5px solid $title-text-gm;
        }
      }
      &__global-block {
          padding: 0;
          border-top-color: $border-block-gm;
          border-bottom-color: $border-block-gm;
      }
      &__icon-check {
          color: $primary-color-gm;
      }
      &__cell-block {
          &--no-editable{
              color: $custom-black-landing;
              cursor: not-allowed;
          }
          &--required{
              color: $alert-color-gm;
          }
      }
      &__file-link {
          color: $secondary-color-gm;
       }
       &__file-text {
          color: $text-field-color-gm;
       }
       &__file-icon {
          color: $primary-color-gm;
       }
    }
  }
  &__com-de{
    .field-item {
      &__button-bool {
          border-color: $primary-color-com-de;
          color: $custom-black-landing;
          &:hover {
              background-color: $hover-block-com-de;
          }
          &--selected{
              background-color: $primary-color-com-de;
              color: $snow-white;
              &:hover {
                  background-color: $primary-color-com-de;
              }
          }
          &--no-editable{
              border-color: $secondary-color-com-de;
              color: $secondary-color-com-de;
              &:hover {
                  cursor: not-allowed !important;
                  background-color: $snow-white;
              }
          }
          &--selected-no-editable{
              color: $snow-white;
              background-color: $secondary-color-com-de;
              &:hover {
                  border-right-color: $secondary-color-com-de;
                  color: $snow-white;
                  background-color: $secondary-color-com-de;
              }
          }
          &--alert{
              background-color: $snow-white;
              color: $alert-color-com-de;
              border-color: $alert-color-com-de;
              &:hover {
                  background-color: $red-task-20-com-de;
                  cursor: pointer;
              }
          }
          &--finish-right{
              border: 2px solid $primary-color-com-de;
              border-left: none;
          }
          &--finish-left{
              border: 2px solid $primary-color-com-de;
              border-right: none;
          }
          &--finish-right-no-editable{
              border: 2px solid $secondary-color-com-de;
              border-left: none;
          }
          &--finish-left-no-editable{
              border: 2px solid $secondary-color-com-de;
              border-right: none;
          }
      }
      &__bool-box {
        border-color: $primary-color-com-de;
        background-color: $primary-color-com-de;
        &--no-editable{
            border-color: $secondary-color-com-de;
            background-color: $secondary-color-com-de;
        }
        &--alert{
            border-color: $alert-color-com-de;
            background-color: $snow-white;
        }
      }
      &__field-type-bool {
        &--is-highlight{
            background-color: $third-color-com-de;
            border-left: 5px solid $title-text-com-de;
        }
      }
      &__global-block {
          padding: 0;
          border-top-color: $border-block-com-de;
          border-bottom-color: $border-block-com-de;
      }
      &__icon-check {
          color: $primary-color-com-de;
      }
      &__cell-block {
          &--no-editable{
              color: $custom-black-landing;
              cursor: not-allowed;
          }
          &--required{
              color: $alert-color-com-de;
          }
      }
      &__file-link {
          color: $secondary-color-com-de;
       }
       &__file-text {
          color: $text-field-color-com-de;
       }
       &__file-icon {
          color: $primary-color-com-de;
       }
    }
  }
  &__com-fr{
    .field-item {
      &__button-bool {
          border-color: $primary-color-com-fr;
          color: $custom-black-landing;
          &:hover {
              background-color: $hover-block-com-fr;
          }
          &--selected{
              background-color: $primary-color-com-fr;
              color: $snow-white;
              &:hover {
                  background-color: $primary-color-com-fr;
              }
          }
          &--no-editable{
              border-color: $secondary-color-com-fr;
              color: $secondary-color-com-fr;
              &:hover {
                  cursor: not-allowed !important;
                  background-color: $snow-white;
              }
          }
          &--selected-no-editable{
              color: $snow-white;
              background-color: $secondary-color-com-fr;
              &:hover {
                  border-right-color: $secondary-color-com-fr;
                  color: $snow-white;
                  background-color: $secondary-color-com-fr;
              }
          }
          &--alert{
              background-color: $snow-white;
              color: $alert-color-com-fr;
              border-color: $alert-color-com-fr;
              &:hover {
                  background-color: $red-task-20-com-fr;
                  cursor: pointer;
              }
          }
          &--finish-right{
              border: 2px solid $primary-color-com-fr;
              border-left: none;
          }
          &--finish-left{
              border: 2px solid $primary-color-com-fr;
              border-right: none;
          }
          &--finish-right-no-editable{
              border: 2px solid $secondary-color-com-fr;
              border-left: none;
          }
          &--finish-left-no-editable{
              border: 2px solid $secondary-color-com-fr;
              border-right: none;
          }
      }
      &__bool-box {
        border-color: $primary-color-com-fr;
        background-color: $primary-color-com-fr;
        &--no-editable{
            border-color: $secondary-color-com-fr;
            background-color: $secondary-color-com-fr;
        }
        &--alert{
            border-color: $alert-color-com-fr;
            background-color: $snow-white;
        }
      }
      &__field-type-bool {
        &--is-highlight{
            background-color: $third-color-com-fr;
            border-left: 5px solid $title-text-com-fr;
        }
      }
      &__global-block {
          padding: 0;
          border-top-color: $border-block-com-fr;
          border-bottom-color: $border-block-com-fr;
      }
      &__icon-check {
          color: $primary-color-com-fr;
      }
      &__cell-block {
          &--no-editable{
              color: $custom-black-landing;
              cursor: not-allowed;
          }
          &--required{
              color: $alert-color-com-fr;
          }
      }
      &__file-link {
          color: $secondary-color-com-fr;
       }
       &__file-text {
          color: $text-field-color-com-fr;
       }
       &__file-icon {
          color: $primary-color-com-fr;
       }
    }
  }
  // &__landing-custom{
  //   .field-item{
  //   }
  // }
}
