.general-report {
  &__upper-container {
      display: flex;
      justify-content: flex-end;
      padding: 20px;
  }

  &__upper-container-button {
      color: $primary-color-icam;
      cursor: pointer;
  }

  &__upper-button-text {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 16px;
      text-transform: uppercase;
  }

  &__upper-button-icon {
      padding-left: 5px;
  }

  &__list-container {
      margin: 0 20px 20px 20px;
      padding-bottom: 10px;
      box-shadow: $box-shadow-icam;
      background-color: $snow-white;
  }

  &__list-container-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
  }

  &__container-header-left {
      display: flex;
      align-items: center;
  }

  &__title {
      display: flex;
      align-items: center;
      color: $title-text-icam;
  }

  &__title-icon {
      font-size: 30px;
  }

  &__title-text {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 25px;
      padding-left: 15px;
  }

  &__container-header-dropdown {
      display: flex;
      align-items: center;
      padding: 7px 0 0 10px;
      cursor: pointer;
  }

  &__header-dropdown-text {
      font-family: MTTMilano, sans-serif;
      color: $custom-black-landing;
      text-transform: uppercase;
      font-size: 13px;
      padding-left: 10px;
      border-left: 1px solid $custom-black-landing;
  }

  &__header-dropdown-icon {
      color: $primary-color-icam;
      font-size: 15px;
      padding: 0 0 3px 5px;
  }

  &__header-dropdown-list {
      top: 25px;
      left: 20px;
  }

  &__dropdown-item-badge {
      display: inline-flex;
      justify-content: center;
      font-family: Arial, sans-serif;
      font-weight: bold;
      font-size: 11px;
      color: $custom-black-landing;
      background-color: $third-color-icam;
      height: 14px;
      padding: 0 5px;
      border-radius: 7px;
      min-width: 24px;
      margin-left: 2px;
  }

  &__header-button {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin-left: 35px;
      cursor: pointer;
  }

  &__container-header-button {
      display: flex;
      align-items: center;
      cursor: pointer;
  }

  &__header-button-text {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 16px;
      color: $custom-black-landing;
  }

  &__header-button-icon {
      font-size: 20px;
      color: $primary-color-icam;
      padding-left: 5px;
  }

  &__table {
      margin: 0 10px;
      border: 1px solid $primary-color-icam;
      font-size: 12px;
  }

  &__theader {
      font-family: MTTMilano, sans-serif;
      font-weight: bold;
  }

  &__theader-upper {
      display: flex;
      text-transform: uppercase;
      border-bottom: 1px solid $primary-color-icam;
      height: 36px;
  }

  &__theader-lower {
      display: flex;
      border-bottom: 1px solid $primary-color-icam;
      height: 57px;
      color: $primary-color-icam;
  }

  &__tbody {
      font-family: Arial, sans-serif;
      color: $custom-black-landing;
  }

  &__trow {
      display: flex;
      border-bottom: 1px solid $border-block-icam;
      height: 40px;

      &--last {
          border: none;
      }
  }

  &__tcell {
      border-right: 1px solid $primary-color-icam;
      display: flex;
      align-items: center;
      padding: 10px;

      &--first {
          min-width: 100px;
          max-width: 100px;
          text-transform: capitalize;
      }

      &--last {
          border: none;
      }

      &--year {
          background-color: $primary-color-icam;
          color: $snow-white;
          justify-content: space-between;
      }

      &--header-upper {
          background-color: $hover-block-icam;
          color: $title-text-icam;
      }

      &--header-lower {
          align-items: flex-start;
      }

      &--subcells {
          justify-content: space-between;
      }
  }

  &__tcell-icon {
      cursor: pointer;
  }

  &__tsubcell {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      line-height: 12px;
      color: $primary-color-icam;

      &--no-first {
          margin-left: 10px;
      }
  }

  &__dropdown-menu {
      color: $primary-color-icam;
      border-color: $primary-color-icam;
      border-radius: 0;
      padding: 0;
      margin: 0;
      min-width: -webkit-fill-available;
  }

  &__dropdown-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 5px;
      padding: 5px 10px;

      &:hover {
          background-color: $hover-block-icam;

          & i {
              display: block;
          }
      }
  }

  &__dropdown-icon {
      display: none;
      font-size: 16px;
  }

  & .documentation-report-list {
      border: none;
      margin: 0;

      &__table {
          padding: 0 10px 10px;
      }
  }

  &__footer {
      font-family: MTTMilano, sans-serif;
      font-weight: bold;
      font-size: 12px;
      color: $primary-color-icam;
      padding: 20px;
  }
}
