.image-editor{
  .cropper-wrapper{
    width: 765px;
    height: 340px;
    display: block;
    overflow: hidden;
    margin: 49px auto 0;
  }

  &__image-container{
      display: block;
      max-width: 100%;
  }
  &__header-options{
      display: block;
      width: 100%;
      height: 60px;
      padding: 7px 66px;
  }
  &__specifications{
      display: block;
      width: 50%;
      float: left;
  }
  &__specifications-preview-text{
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 13px;
  }
  &__preview-image{
      display: block;
      overflow: hidden;
      // margin-left: 67px;
      border: 1px solid $primary-color;
      max-width: 400px;
      max-height: 120px;
      clear: both;
  }
  &__menu-options{
      display: block;
      width: 50%;
      float: right;
  }
  &__menu-options-container{
      margin: 2px 0 0;
      padding: 0;
      list-style: none;
      float: right;
      height: 42px;
      width: 313px;
      background-color: $primary-color;
      border-radius: 5px;
  }
  &__menu-options-block{
      display: inline-block;
      border-right: 1px solid $snow-white;
      height: 42px;
      padding: 7px 0 0 2px;
      width: 66px;
      &:first-child{
        position: relative;
        bottom: 3px;
      }
      &--second{
          width: 107px
      }
      &--third{
          width: 72px
      }

      &:last-child{
          width: 64px;
          border-right: 0px solid $snow-white;
      }
  }
  &__menu-options-button{
      position: relative;
      border: none;
      background: none;
      outline: none;
      color: $snow-white;
      font-size: 18px;
      padding: 0;
      margin: 0 2px;
      &--large{
          font-size: 20px;
      }
      &--zoom-in{
          top: 4px;
          margin: 0 8px;
      }
      &--zoom-out{
          top: 4px;
      }

      &--arrow-down{
          right: 6px;
      }
      &--arrow-left{
          right: 6px;
          top: 2px;
      }
      &--arrow-right{
          right: 0px;
          top: 2px;
      }
      &--rotate-left{
          padding-left: 7px;
          transform: scaleX(-1);
          padding-right: 6px;
          top: 2px;

      }
      &--rotate-right{
          top: 2px;
      }
      &--flip-x{
          top: 2px;
          padding-left: 6px;
          padding-right: 2px;
      }
      &--flip-y{
          transform: rotate(90deg);
          top: 1px;
      }
  }
}

.compliance{
  &__icam-red{
    .image-editor{
      &__specifications-preview-text{
          color: $custom-black-landing;
      }
      &__preview-image{
          border-color: $custom-black-landing;
      }
      &__menu-options-container{
          background-color: $primary-color-icam;
      }
    }
  }
  &__gm-law{
    .image-editor{
      &__specifications-preview-text{
          color: $custom-black-landing;
      }
      &__preview-image{
          border-color: $custom-black-landing;
      }
      &__menu-options-container{
          background-color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    .image-editor{
      &__specifications-preview-text{
          color: $custom-black-landing;
      }
      &__preview-image{
          border-color: $custom-black-landing;
      }
      &__menu-options-container{
          background-color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .image-editor{
      &__specifications-preview-text{
          color: $custom-black-landing;
      }
      &__preview-image{
          border-color: $custom-black-landing;
      }
      &__menu-options-container{
          background-color: $primary-color-com-fr;
      }
    }
  }
}

