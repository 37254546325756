.clients-tracking {
  &__body{
      width: 100%;
      position: relative;
      margin-bottom: 1px;
  }

  &__container{
      max-width: 1420px;
      margin: 0 auto;
      padding: 0 10px;
  }

  &__block-container{
      height: 646px;
  }

  &__table-body{
      display: block;
      width: 100%;
      height: 460px;
  }

  &__col {
      text-align: left;
      padding-right: 5px;
      &--top-1{
          position: relative;
          top: 1px;
      }
      &--first{
          padding-left: 8px;
      }

      &--author,
      &--organization{
          width: 11%;
      }
      &--creation,
      &--modification,
      &--risk,
      &--next-tracing,
      &--end-relationship,
      &--state,
      &--recurrente{
          width: 9%;
      }
      &--creation-be,
      &--modification-be,
      &--risk-be,
      &--next-tracing-be,
      &--end-relationship-be{
          width: 13%;
      }
      &--state-be{
        width: calc(13% - 60px);
      }
      &--legal-form{
          width: calc(15% - 60px);
      }
      &--actions{
          width: 60px;
          max-width: 60px;
          padding-right: 0;
          position: relative;
      }
  }

  &__cell-block-with-icon{
      width: calc(100% - 24px);
      display: inline-block;
      top: 2px;
      padding-left: 4px;
  }

  &__icon-client-state{
      font-size: 20px;
      position: relative;
      top: 1px;
  }

  &__pagination-block{
      text-align: center;
      height: 22px;
      display: block;
      float: left;
      width: calc(100% - 360px);
      left: 180px;
      bottom: 0px;
      position: absolute;
  }

  &__header-buttons-container {
      display: inline-flex;
      font-size: 30px;
      float: right;
  }

  &__header-button {
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0 0 0 10px;
  }
}

.compliance{
  &__dark-blue{
    .clients-tracking{
      &__icon-client-state{
          &.lf-icon-check-round{
              color: $green;
          }
          &.lf-icon-alert, &.lf-icon-close-round{
              color: $red;
          }
          &.lf-icon-calendar-cross{
              color: $subcontainer-ground;
          }
      }
      &__header-button {
          color: $primary-color;
      }
    }
  }
  &__steel-blue, &__uk-steel-blue{
    .clients-tracking{
      &__icon-client-state{
          &.lf-icon-check-round{
              color: $green-color-v2;
          }
          &.lf-icon-alert{
              top: 0px;
          }
          &.lf-icon-alert, &.lf-icon-close-round{
              color: $alert-color-2;
          }
          &.lf-icon-calendar-cross{
              color: $grey-icons-2;
          }
      }
      &__header-button {
          color: $border-hover-2;
      }
    }
  }
  &__icam-red{
    .clients-tracking{
      &__header-button {
          color: $snow-white;
      }
    }
  }
  &__gm-law{
    .clients-tracking{
      &__header-button {
          color: $snow-white;
      }
    }
  }
  &__com-de{
    .clients-tracking{
      &__header-button {
          color: $snow-white;
      }
    }
  }
  &__com-fr{
    .clients-tracking{
      &__header-button {
          color: $snow-white;
      }
    }
  }
}
