.icam-access-landing{
  padding: 25px 15px;
  font-family: MTTMilano, sans-serif;

  * {
      box-sizing:border-box;
  }

  h1{
    font-size: 32px;
    margin-bottom: 15px;
  }

  &__form label {
      display: inline-block;
      width: 275px;
  }

  &__form input[type=text], select {
      float: right;
      width: 170px;
      height: 22px;
  }

  input[type=button] {
      margin: 25px 0;
      padding: 0.25em 1em;
  }

  #result {
      width: 675px;
      height: 86px;
      margin-left: 40px;
      background-color: rgb(239, 239, 239);
      vertical-align: middle;
  }

  #advice {
      width: 675px;
      font-style: italic;
      padding-left: 110px;
      padding-top: 0.25em;
  }

  #resultLink {
      margin-top: 1em;
      display: inline-block;
      width: 675px;
      overflow-wrap: break-word;
      text-decoration: underline;
  }
}
