.export-format-modal {
  &__window{
    & .modal-dialog {
      & .modal-content {
          height: auto;
      }
    }
  }
  &__close-button {
    color: $snow-white;
    float: right;
    font-size: 20px;
    top: 4px;
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  &__body-container {
    margin: 30px 70px;
  }

  &__formats-container {
    display: flex;
    align-items: stretch;
    margin-top: 30px;
    gap: 10px;
  }

  &__format {
    display: flex;
    align-items: stretch;
    padding: 15px 20px 20px 10px;
    background-color: $background-color-states;
    color: $primary-color;
    flex: 1 0 0;
  }

  &__format-radio {
    font-size: 25px;
    cursor: pointer;
  }

  &__format-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 10px;
  }

  &__format-title {
    font-family: MTTMilano-Bold, sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    padding-top: 4px;
    cursor: pointer;
  }

  &__format-text {
    font-family: MTTMilano, sans-serif;
    font-size: 15px;
    margin: 0;
  }

  &__format-icon {
    font-size: 100px;
    opacity: 0.2;
    display: flex;
    flex: 1 0 0;
    align-items: flex-end;
    justify-content: center;
  }

  &__buttons-container {
    text-align: right;
    margin-top: 20px;
  }
}

.compliance {
  &__steel-blue {
    .export-format-modal {
      &__close-button {
        color: $white-special-2;
      }

      &__format {
        background-color: $background-grey-disabled-2;
        color: $primary-color-2;
      }
    }
  }

  &__uk-steel-blue {
    .export-format-modal {
      &__close-button {
        color: $white-special-2;
      }

      &__format {
        background-color: $background-grey-disabled-2;
        color: $primary-color-2;
      }
    }
  }

  &__icam-red {
    .export-format-modal {
      &__format {
        background-color: $background-grey-disabled-icam;
        color: $custom-black-landing;
      }
    }
  }

  &__gm-law {
    .export-format-modal {
      &__format {
        background-color: $background-grey-disabled-gm;
        color: $custom-black-landing;
      }
    }
  }
  &__com-de {
    .export-format-modal {
      &__format {
        background-color: $background-grey-disabled-com-de;
        color: $custom-black-landing;
      }
    }
  }
  &__com-fr {
    .export-format-modal {
      &__format {
        background-color: $background-grey-disabled-com-fr;
        color: $custom-black-landing;
      }
    }
  }
}
