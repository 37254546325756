.law-info {
  &__content {
      display: block;
      position: relative;
      height: 410px;
      margin: 30px 25px 0px 0px;
      overflow: auto;

      &--custom {
          overflow: initial;
          margin: 30px 10px 0;
      }
  }

  &__content-header {
      width: 232px;
      display: block;
      overflow: hidden;
      border-bottom: 3px solid $primary-color;
      margin-bottom: 20px;
  }

  &__content-header-icon {
      font-size: 17px;
      color: $primary-color;
      float: left;
  }

  &__content-header-question {
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: bold;
      padding-top: 4px;
      padding-left: 7px;
      float: left;
      margin: 0;
  }

  &__content-body {
      font-family: Arial, sans-serif;
      font-size: 12px;
      margin-bottom: 10px;
      height: 315px;
      width: 751px;
      line-height: 16px;

      & h2 {
          color: $primary-color;
      }

      & ol {
          color: $primary-color;
          list-style-type: decimal;
      }

      & ul {
          color: $primary-color;
          list-style-type: square;
      }

      & li {
          color: $secondary-color;
      }

      & .common__buttons-block--modal {
          bottom: 4px;
      }

  }

  &__db-icon-holder {
      position: relative;
      display: inline-block;
      margin-right: 25px;
      vertical-align: top;

      & > i {
          font-size: 20px;
          position: absolute;
      }
  }
  ////////////////////////////////////////
  /////////////////////Custom////////////
  //////////////////////////////////////
  &__custom-window {
      & .modal-dialog {
          margin-top: 120px;

          & .modal-content {
              border-radius: 4px;
          }
      }
  }

  &__buttons-block {
      position: absolute;
      right: 40px;
      bottom: 41px;
      margin-top: 0;
      margin-bottom: 0;
  }

  &__db-icon-holder-custom {
      position: relative;
      display: inline-block;
      margin-right: 25px;
      vertical-align: top;
      bottom: 2px;

      & > i {
          font-size: 20px;
          position: absolute;
      }
  }
  ////////////////////////////////////////
  /////////////////////Fin Custom////////////
  //////////////////////////////////////
}

.compliance{
  &__steel-blue, &__uk-steel-blue{
    .law-info {
      &__content-body {
        & h2, & ol, & ul {
          color: $primary-color-2;
        }
        & li.legal-document__list-item {
            color: $secondary-color-2;
        }
      }
    }
  }
}
