.share-document{
  &--fieldcontainer{
      .modal-content {
          height: 637px;
      }
  }
  &__form-share{
      height: 340px;
      width: 86%;
      margin-top: 53px;
      margin-left: 65px;
      &--concept{
          height: 374px;
          position: relative;
      }
      &--document{
          height: 468px;
          position: relative;
          margin-left: 74px;
          width: calc(100% - 148px);
      }
      &--share-fieldcontainer{
          margin-top: 26px;
      }
  }

  &__user-icon{
      display: block;
      font-size: 15px;
      color: $primary-color;
      padding-top: 7px;
      padding-right: 1px;
      position: absolute;
      top: -23px;
      right: -3px;
  }
  // &__show-text-area{
  //     // height: 259px;
  //     // width: 86%;
  //     // margin-left: 7.2%;
  //     // resize: none;
  // }
  &__text-rich {
      display: block;
      width: 100%;
      overflow: hidden;

      &--poll{
          height: 259px;
          // & .mce-container iframe{
          //     height: 181px !important;
          // }
      }
  }
  &__info-text{
      margin-bottom: 18px;
  }

  &__block-info-message{
      height: 55px;
      width: 100%;
      background-color: $background-error;
      position: absolute;
      bottom: 84px;
      left: 0;
      padding: 8px 17px;
      color: $red;
  }

  &__icon-alert {
      font-size: 40px;
      margin-right: 12px;
      position: relative;
      float: left;
  }

  &__text-alert {
      font-family: Arial, sans-serif;
      font-size: 12px;
      position: relative;
      float: left;
      top: 4px;
      margin: 0;
      width: calc(100% - 55px);
  }

  &__check-block{
      // display: flex;
      margin-top: 10px;
      // height: 20px;
  }

}
// .compliance{
//   &__stel-blue{
//     .share-document{
//       &__text-rich{
//         .mce-panel{
//             border-color: $secondary-color-2;
//         }
//         .mce-stack-layout-item{
//             border: none;
//         }
//       }
//     }
//   }
// }
