.field-type-radio-option{
    &__block-field{
        border: 1px solid $result-search-quote-color;
        position: relative;
        float: left;
        width: 100%;
        min-height: 50px;
        padding-top: 5px;
        margin-bottom: 10px;
        &--color-steel-blue{
            border-color: $secondary-color-2;
        }
    }

    &__block-label{
        position: relative;
        float: left;
        width: 100%;
    }

    &__icon-radio-buttons {
        float: right;
        font-size: 17px;
        opacity: 0.5;
        color: $attach-icon-grey;
        margin-right: 5px;
    }

    &__input-radio{
        position: absolute;
        height: 0px;
        width: 0px;
        margin: 0 !important;
        opacity: 0;
        &:hover {
            cursor: pointer;
        }
    }

    &__label{
        position: relative;
        float: left;
        padding-left: 8px;
        font-weight: 400;
        margin: 0 5px 0 0;
        &:hover:not([disabled]) {
            cursor: pointer;
            .field-type-list-extended__icon-container{
                border: 1px solid $box-comment-border-02;
                background: $background-grey;
                &--color-steel-blue{
                    border: 1px solid $third-color-2;
                    background: $block-hover-2;
                }
            }
        }
        
        &--disabled{
            cursor: not-allowed !important;
            &:hover{
                cursor: not-allowed !important;
            }
        }
    }

    &__span-text-associated{
        font-size: 14px;
        width: auto;
        max-width: inherit;
    }
}
.compliance{
    &__steel-blue{
        .field-type-radio-option{
            &__block-field{
                border-color: $secondary-color-2;
            }
            &__label{
                &:hover:not([disabled]) {
                    .field-type-list-extended__icon-container{
                        border: 1px solid $third-color-2;
                        background: $block-hover-2;
                    }
                }
            }
        }
    }
}