@mixin template-list-items($border-color,$backgroundColor){
  border-bottom-color: $border-color;
  // height: 48px;
  // padding-top: 2px;
  height: 44px;
  padding-top: 1px;
  padding-left: 9px;
  &:hover{
      background-color:$backgroundColor;
  }
}

@mixin template-menu($backgroundColor, $borderColor,  $color){
  background-color: $backgroundColor;
  border-color: $borderColor;
  color: $color;
}

@mixin template-box($backgroundColor, $boxShadowColor){
  border: none;
  border-left: 8px solid ;
  background-color: $backgroundColor;
  box-shadow: 0 0 5px 1px $boxShadowColor;
  padding-top: 1px;
}
@mixin pillow($color, $borderColor){
  color: $color;
  border-color: $borderColor;
  background-color: $borderColor;
  font-size: 14px;
  width: 37px;
  padding-top: 0;
}
@mixin title-template($color, $borderColor){
  color: $color;
  border-bottom-color: $borderColor;
  font-size: 12px;
  font-weight: 500;
}
@mixin block-empty($color){
  color: $color;
  font-family: Arial, sans-serif;
  font-size: 11px;
  top: 69px;

}


.predefined-templates {
  width: 100%;
  display: block;
  overflow: hidden;
  &__container {
      border: 1px solid $box-border;
      border-top: 5px solid $primary-color;
      background-color: $background-grey;
      position: relative;
      min-height: 75px;
      max-height: 225px;
      margin: 35px 0 48px;
  }

  &__circle {
      position: absolute;
      width: 79px;
      height: 79px;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      background: $background-grey;
      margin-left: calc(60% - 79px);
      margin-top: -23px;
  }

  &__circle-text {
      font-size: 11px;
      margin-top: 9px;
      text-align: center;
      color: $primary-color;
      font-weight: bold;
  }

  &__icon-templates {
      font-size: 20px;
  }

  // &__icon-process {
  //     font-size: 20px;
  // }

  &__title-name {
      margin: 0;
      text-transform: uppercase;
  }

  &__template-list {
      display: block;
      list-style: none;
      overflow: auto;
      width: 100%;
      margin: 0;
      padding: 0 8px;
      height: 132px;
      &--more-3-elements{
          padding-right: 0;
      }
      &--full {
          height: auto;
      }
      &--expand{
          height: 494px;
      }
  }

  &__template-list-items {
      width: 100%;
      border-bottom: 1px solid;
      position: relative;
      &:hover{
          & .predefined-templates__template-menu {
              display: block;
          }
      }
      &--none-hover {
          &:hover {
              background-color: transparent;
          }
      }
  }

  &__icon-col {
      font-size: 20px;
      // margin-right: 7px;
      top: 10px;
      position: relative;
      float: left;
      display: block;
      width: 27px;
  }

  &__text-list {
      margin: 0;
      top: 14px;
      width: calc(100% - 30px);
      position: relative;
      float: left;
      display: block;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;

  }

  &__template-menu {
      display: none;
      width: 48px;
      height: 39px;
      border: 1px solid ;
      border-radius: 67px 0 0 67px;
      float: right;
      top: 1px;
      right: 0;
      position: absolute;
  }

  &__writing-icon {
      font-size: 33px;
      padding-top: 3px;
      float: right;
      padding-right: 3px;
      &:hover {
          cursor: pointer;
      }
  }

  &__edit-icon{
      font-size: 20px;
      float: right;
      position: relative;
      right: 8px;
      top: 10px;
      &:hover {
          cursor: pointer;
      }
  }

  &__template-box{
      position: relative;
      margin: 35px 0 48px;
      &--min-height{
          height: 100px;
      }
      &--max-height{
          height: 225px;
      }

      &--expand{
          height: 670px;
          max-height: 670px;
      }
      &--edit-comment{
          height: auto;
          max-height: none;
      }
      &--attachment-templates{
          min-height: 96px;
      }
  }
  &__change-view-icon{
      position: relative;
      float: right;
      margin-top: 9px;
      margin-right: 9px;
      font-size: 20px;
      z-index: 1;
      &:hover{
          cursor: pointer;
      }
  }
  &__template-header{
      position: absolute;
      width: 80px;
      height: 80px;
      border-radius: 50% 50% 0 0;
      background: $background-color-states;
      margin: 0 auto;
      top: -23px;
      left: calc(50% - 40px);
      text-align: center;
  }
  &__block-icon{
      font-size: 20px;
      // margin-top: 9px;
      color: $primary-color;
      position: relative;
      // left: 29px;
      top: 9px;
  }
  &__block-text{
      margin: 0;
      text-transform: uppercase;
      top: 20px;
      font-size: 11px;
      margin-top: 9px;
      text-align: center;
      color: $primary-color;
      // font-weight: bold;
      font-family: MTTMilano-Bold, sans-serif;
      &--attachment-title{
          width: 200px;
          margin-left: -64px;
      }
  }
  &__pillow{
      font-family: Arial, sans-serif;
      font-weight: bold;
      font-style: normal;
      text-align: center;
      height: 20px;
      border-radius: 12px;
      float: right;
      margin-top: 9px;
      margin-right: 7px;
      border: 1px solid ;
  }
  &__title-template{
      border-bottom: 1px solid;
      padding-bottom: 5px;
      margin: 54px 7px 0;
      line-height: 14px;
  }
  &__state-block{
      width: 98%;
      height: 73px;
      overflow-y: auto;
      overflow-x: hidden;
      margin-left: 1%;
      margin-top: 9px;
  }
  &__block-empty {
      position: absolute;
      width: 100%;
      text-align: center;
      margin-left: 0;
      padding-left: 7px;
  }
  &__historic-table{
      width: 98%;
      height: 165px;
      // overflow: auto;
      overflow: hidden;
      margin-left: 1%;
      position: relative;
      margin-top: 54px;
      &--custom{
          margin-top: 10px;
          height: 197px;
          overflow: initial;
      }
      &--custom-legal{
          margin-top: 10px;
          height: 182px;
          overflow: initial;
      }
      &--expand{
          height: 540px;
      }
  }
}
.compliance{
  &__dark-blue{
      .predefined-templates {
          &__template-list {
              height: 126px;
              &--expand{
                  height: 494px;
              }
          }
          &__template-list-items {
              border-bottom-color: $bottom-border;
              height: 42px;
              padding-left: 5px;
              &:hover {
                  background-color: $background-grey-other;
              }
          }
          &__icon-col {
              color: $primary-color;
          }
          &__template-menu {
              @include template-menu($background-grey-other, $menu-icons-border, $primary-color);
          }
          &__template-box{
              border: 1px solid $box-border;
              border-top-width: 5px;
              border-top-color: $primary-color;
              background-color: $background-color-states;
          }
          &__change-view-icon{
              color: $primary-color;
          }
          &__pillow{
              font-size: 12px;
              border-color: $primary-color;
              color: $primary-color;
              width: 35px;
              padding-top: 1.5px;
          }
           &__title-template{
              padding-left: 12px;
              text-transform: uppercase;
              border-bottom-color: $primary-color;
              font-weight: bold;
              color: $primary-color;
              font-size: 10px;
          }
          &__block-empty {
              color: $secondary-color;
              font-family: 'MTTMilano', sans-serif;
              top: 42px;
          }
      }
  }
  &__steel-blue,
  &__uk-steel-blue{
      .predefined-templates {
          &__template-list {
            height: 132px;
            &--expand{
                height: 494px;
            }
          }
          &__template-list-items {
              @include template-list-items($border-bottom-2, $block-hover-2)
          }
          &__icon-col {
              color: $border-hover-2;
          }
          &__template-menu {
              @include template-menu($third-color-2, $secondary-color-2, $border-hover-2);
              height: 41px;
          }
          &__template-box{
              @include template-box($snow-white, $box-shadow-box-2);
          }
          &__change-view-icon{
              color: $border-hover-2;
          }
          &__pillow{
              @include pillow($primary-color-2, $third-color-2);
          }
          &__title-template{
              @include title-template($border-hover-2, $border-bottom-2)
          }
          &__block-empty {
              @include block-empty($primary-color-2)
          }
      }
  }
  &__icam-red{
      .predefined-templates {
          &__template-list {
              height: 132px;
              &--expand{
                  height: 494px;
              }
          }
          &__template-list-items {
              @include template-list-items($border-block-icam, $hover-block-icam)
          }
          &__icon-col {
              color: $primary-color-icam;
          }
          &__text-list {
              color: $custom-black-landing;
          }
          &__template-menu {
              @include template-menu($primary-color-icam, $primary-color-icam, $snow-white);
              height: 41px;
          }
          &__template-box{
              @include template-box($snow-white, $border-buttons-icam);
          }
          &__change-view-icon{
              color: $primary-color-icam;
          }
          &__pillow{
              @include pillow($custom-black-landing, $third-color-icam);
          }
          &__title-template{
              @include title-template($primary-color-icam, $primary-color-icam)
          }
          &__block-empty {
              @include block-empty($custom-black-landing)
          }
      }
  }
  &__gm-law{
      .predefined-templates {
          &__template-list {
              height: 132px;
              &--expand{
                  height: 494px;
              }
          }
          &__template-list-items {
              @include template-list-items($border-block-gm, $hover-block-gm)
          }
          &__icon-col {
              color: $primary-color-gm;
          }
          &__text-list {
              color: $custom-black-landing;
          }
          &__template-menu {
              @include template-menu($primary-color-gm, $primary-color-gm, $snow-white);
              height: 41px;
          }
          &__template-box{
              @include template-box($snow-white, $border-buttons-gm);
          }
          &__change-view-icon{
              color: $primary-color-gm;
          }
          &__template-header{
              height: 30px;
              width: 500px;
              position: absolute;
              top: 0px;
              background: transparent;
              left: 0;
              margin-top: 5px;
              margin-bottom: 5px;
          }
          &__block-icon{
              font-size: 30px;
              float: left;
              color: $custom-black-landing;
              margin-left: 7px;
          }
          &__block-text{
              margin: 0;
              color: $custom-black-landing;
              font-size: 16px;
              font-family: MTTMilano, sans-serif;
              font-weight: 900;
              letter-spacing: 0;
              line-height: 16px;
              float: left;
              padding: 17px 0 0 11px;
              text-align: left;
              text-transform: none;
              &--attachment-title{
                  width: 350px;
                  margin-left: 0px;
              }
          }
          &__pillow{
              @include pillow($custom-black-landing, $third-color-gm);
          }
          &__title-template{
              @include title-template($primary-color-gm, $primary-color-gm)
          }
          &__block-empty {
              @include block-empty($custom-black-landing)
          }
      }
  }
  &__com-de{
    .predefined-templates {
        &__template-list {
            height: 132px;
            &--expand{
                height: 494px;
            }
        }
        &__template-list-items {
            @include template-list-items($border-block-gm, $hover-block-com-de)
        }
        &__icon-col {
            color: $primary-color-com-de;
        }
        &__text-list {
            color: $custom-black-landing;
        }
        &__template-menu {
            @include template-menu($primary-color-com-de, $primary-color-com-de, $snow-white);
            height: 41px;
        }
        &__template-box{
            @include template-box($snow-white, $border-buttons-com-de);
        }
        &__change-view-icon{
            color: $primary-color-com-de;
        }
        &__template-header{
            height: 30px;
            width: 500px;
            position: absolute;
            top: 0px;
            background: transparent;
            left: 0;
            margin-top: 5px;
            margin-bottom: 5px;
        }
        &__block-icon{
            font-size: 30px;
            float: left;
            color: $custom-black-landing;
            margin-left: 7px;
        }
        &__block-text{
            margin: 0;
            color: $custom-black-landing;
            font-size: 16px;
            font-family: MTTMilano, sans-serif;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 16px;
            float: left;
            padding: 17px 0 0 11px;
            text-align: left;
            text-transform: none;
            &--attachment-title{
                width: 350px;
                margin-left: 0px;
            }
        }
        &__pillow{
            @include pillow($custom-black-landing, $third-color-com-de);
        }
        &__title-template{
            @include title-template($primary-color-com-de, $primary-color-com-de)
        }
        &__block-empty {
            @include block-empty($custom-black-landing)
        }
    }
}
&__com-fr{
  .predefined-templates {
      &__template-list {
          height: 132px;
          &--expand{
              height: 494px;
          }
      }
      &__template-list-items {
          @include template-list-items($border-block-gm, $hover-block-com-fr)
      }
      &__icon-col {
          color: $primary-color-com-fr;
      }
      &__text-list {
          color: $custom-black-landing;
      }
      &__template-menu {
          @include template-menu($primary-color-com-fr, $primary-color-com-fr, $snow-white);
          height: 41px;
      }
      &__template-box{
          @include template-box($snow-white, $border-buttons-com-fr);
      }
      &__change-view-icon{
          color: $primary-color-com-fr;
      }
      &__template-header{
          height: 30px;
          width: 500px;
          position: absolute;
          top: 0px;
          background: transparent;
          left: 0;
          margin-top: 5px;
          margin-bottom: 5px;
      }
      &__block-icon{
          font-size: 30px;
          float: left;
          color: $custom-black-landing;
          margin-left: 7px;
      }
      &__block-text{
          margin: 0;
          color: $custom-black-landing;
          font-size: 16px;
          font-family: MTTMilano, sans-serif;
          font-weight: 900;
          letter-spacing: 0;
          line-height: 16px;
          float: left;
          padding: 17px 0 0 11px;
          text-align: left;
          text-transform: none;
          &--attachment-title{
              width: 350px;
              margin-left: 0px;
          }
      }
      &__pillow{
          @include pillow($custom-black-landing, $third-color-com-fr);
      }
      &__title-template{
          @include title-template($primary-color-com-fr, $primary-color-com-fr)
      }
      &__block-empty {
          @include block-empty($custom-black-landing)
      }
  }
}
}

@media (max-width: $max-width-smartphone) {
  .predefined-templates {
      &__template-box {
          &--expand {
              height: auto;
          }
      }
  }
}

/////////////////////////////////
//inc icam - MADiaz
