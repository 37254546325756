@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import "~@clientio/rappid/rappid.css";

@import './assets/scss/bootstrap-common';
@import './assets/scss/colors';
@import './assets/scss/angular-calendar';
@import './assets/scss/common';

@import './app/core/components/front-login/styles/front-login.component.scss';
@import './app/shared/components/header-poll/header-poll.component.scss';
@import './app/shared/components/header-nav/header-nav.component.scss';
@import './app/core/components/dashboard-panel/styles/dashboard-panel.component.scss';
@import './app/shared/components/compliance-footer/compliance-footer.component.scss';
@import './app/shared/components/advice-modal/styles/advice-modal.component.scss';
@import './app/shared/components/pagination-control/pagination-control.component.scss';
@import './app/core/components/clients-list/styles/clients-list.component.scss';
@import './app/core/components/client-details/client-details.component.scss';
@import './app/core/components/evaluations-list/evaluations-list.component.scss';
@import './app/shared/components/informative-loading-modal/informative-loading-modal.component.scss';
@import './app/shared/components/organization-modal/organization-modal.component.scss';
@import './app/core/components/new-evaluation/new-evaluation.component.scss';
@import './app/shared/components/wizard-steps/wizard-steps.component.scss';
@import './app/shared/components/field-item/field-item.component.scss';
@import './app/shared/components/field-type-text/styles/field-type-text.scss';
@import './app/shared/components/field-type-number/field-type-number.component.scss';
@import './app/shared/components/field-type-logo/field-type-logo.component.scss';
@import './app/shared/components/field-type-email/field-type-email.component.scss';
@import './app/shared/components/field-type-formu/styles/field-type-formu.component.scss';
@import './app/shared/components/field-type-bool/styles/field-type-bool.component.scss';
@import './app/core/components/menu-header-comments/menu-header-comments.component.scss';
@import './app/shared/components/field-type-list/styles/field-type-list.scss';
@import './app/shared/components/filter-header/filter-header.scss';
@import './app/core/components/dashboard-reports/dashboard-reports.component.scss';
@import './app/shared/components/from-to-calendar/from-to-calendar.scss';
@import './app/core/components/menu-header-user/menu-header-user.component.scss';
@import './app/core/components/line-graph/line-graph.scss';
@import './app/core/components/concept-instances-list/styles/concept-instances-list.component.scss';
@import './app/core/components/menu-header-products/menu-header-products.component.scss';
@import './app/shared/components/field-type-clients-selector/field-type-clients-selector.component.scss';
@import './app/core/components/reset-password/reset-password.component.scss';
@import './app/shared/components/filter-by-list/filter-by-list.component.scss';
@import './app/core/components/reports-list/styles/reports-list.component.scss';
@import './app/shared/components/share-document-modal/share-document-modal.component.scss';
@import './app/core/components/reset-password/reset-password.component.scss';
@import './app/core/components/task-list/task-list.component.scss';
@import './app/core/components/evaluation/evaluation.component.scss';
@import './app/shared/components/field-type-contacts/styles/field-type-contacts.scss';
@import './app/shared/components/tooltip-law/tooltip-law.component.scss';
@import './app/core/components/duplicate-modal/duplicate-modal.component.scss';
@import './app/core/components/help-modal/help-modal.component.scss';
@import './app/core/components/configure-image/configure-image.component.scss';
@import './app/core/components/image-editor/image-editor.component.scss';
@import './app/core/components/evaluation-menu/evaluation-menu.component.scss';
@import './app/core/components/help-tooltip/help-tooltip.component.scss';
@import './app/core/components/evaluation-tree/evaluation-tree.component.scss';
@import './app//core/components/evaluation-header/evaluation-header.component.scss';
@import './app/core/components/draft-evaluations/draft-evaluations.component.scss';
@import './app/core/components/folder/folder.component.scss';
@import './app/shared/components/duplicate-evaluation/duplicate-evaluation.component.scss';
@import './app/shared/components/field-type-color/field-type-color.component.scss';
@import './app/core/components/corporative-information-modal/corporative-information-modal.component.scss';
@import './app/core/components/notification-preview/notification-preview.component.scss';
@import './app/shared/components/upload-document/styles/upload-documents.component.scss';
@import './app/shared/components/process-ops-modal/process-ops-modal.component.scss';
@import './app/shared/components/process-modal/process-modal.component.scss';
@import './app/shared/components/field-type-date/field-type-date.component.scss';
@import './app/shared/components/time-picker/time-picker.component.scss';
@import './app/shared/components/personalized-notification-modal/personalized-notification-modal.component.scss';
@import './app/shared/components/task-modal/task-modal.component.scss';
@import './app/shared/components/new-document/new-document.component.scss';
@import './app/shared/components/select-simple/select-simple.component.scss';
@import './app/shared/components/field-item/field-item.component.scss';
@import './app/shared/components/new-snippet-modal/new-snippet-modal.component.scss';
@import './app/shared/components/related-information-modal/related-information-modal.component.scss';
@import './app/shared/components/law-info/law-info.component.scss';
@import './app/shared/components/legal-document/legal-document.component.scss';
@import './app/shared/components/share-instance/share-instance.component.scss';
@import './app/core/components/field-references/field-references.component.scss';
@import './app/shared/components/field-type-template/field-type-template.component.scss';
@import './app/shared/components/field-type-time/field-type-time.component.scss';
@import './app/shared/components/field-type-other/field-type-other.component.scss';
@import './app/shared/components/field-type-task/field-type-task.component.scss';
@import './app/shared/components/field-type-alert/field-type-alert.component.scss';
@import './app/shared/components/field-type-switch/field-type-switch.component.scss';
@import './app/shared/components/picker-data-simple/picker-data-simple.component.scss';
@import './app/shared/components/establish-pattern-modal/establish-pattern-modal.component.scss';
@import './app/shared/components/list-in-dropdown/list-in-dropdown.component.scss';
@import './app/shared/components/concept-list/styles/concept-list.component.scss';
@import './app/shared/components/assign-manager/assign-manager.component.scss';
@import './app/shared/components/change-state-modal/change-state-modal.component.scss';
@import './app/core/components/edit-concept/edit-concept.component.scss';
@import './app/core/components/customize-concept/customize-concept.component.scss';
@import './app/core/components/field-list/field-list.component.scss';
@import './app/shared/components/visibility/visibility.component.scss';
@import './app/core/components/picker-visibility/picker-visibility.component.scss';
@import './app/core/components/customize-fields/customize-fields.component.scss';
@import './app/core/components/customize-field-text/customize-field-text.component.scss';
@import './app/core/components/customize-field-list/customize-field-list.component.scss';
@import './app/core/components/customize-field-bool/customize-field-bool.component.scss';
@import './app/core/components/customize-field-task/customize-field-task.component.scss';
@import './app/core/components/customize-field-formula/customize-field-formula.component.scss';
@import './app/core/components/formula-setting-modal/formula-setting-modal.component.scss';
@import './app/shared/components/process-edit/process-edit.component.scss';
@import './app/shared/components/process-edit-modal/process-edit-modal.component.scss';
@import './app/core/components/templates-concept/templates-concept.component.scss';
@import './app/core/components/up-document/up-document.component.scss';
@import './app/shared/components/rename-modal/rename-modal.component.scss';
@import './app/core/components/evaluation-share/evaluation-share.component.scss';
@import './app/shared/components/advice-message/advice-message.component.scss';
@import './app/core/components/new-contact/new-contact.component.scss';
@import './app/core/components/dashboard-roles-obligated-subject/dashboard-roles-obligated-subject.component.scss';
@import './app/shared/components/concept-steps/style/concept-steps.component.scss';
@import './app/shared/components/concept-form/styles/concept-form.component.scss';
@import'./app/shared/components/finish-block-modal/finish-block-modal.component.scss';
@import './app/shared/components/subcontainer-multiple/styles/subcontainer-multiple.component.scss';
@import './app/shared/components/concept-legal-documentation/styles/concept-legal-documentation.component.scss';
@import './app/shared/components/predefined-templates/predefined-templates.component.scss';
@import './app/shared/components/box-comments/styles/box-comments.component.scss';
@import './app/shared/components/box-communication-channel/styles/box-communication-channel-default.component.scss';
@import './app/shared/components/box-queries/box-queries.component.scss';
@import './app/shared/components/icam-queries/styles/icam-queries.component.scss';
@import './app/shared/components/video-slider/video-slider.component.scss';
@import './app/shared/components/video-player/video-player.component.scss';
@import './app/core/components/process-record/styles/process-record.component.scss';
@import './app/core/components/box-query-state/box-query-state.component.scss';
@import './app/shared/components/attachments-template/styles/attachments-template-default.component.scss';
@import './app/core/components/box-protection/box-protection.component.scss';
@import './app/shared/components/attach-document-modal/attach-document-modal.component.scss';
@import './app/shared/components/concept-tab/concept-tab.component.scss';
@import './app/core/components/concept/concept.component.scss';
@import './app/core/components/roles-window/roles-window.component.scss';
@import './app/shared/components/field-type-role/field-type-role.component.scss';
@import './app/shared/components/historic-concept/historic-concept.component.scss';
@import './app/shared/components/alert-concept/alert-concept.component.scss';
@import './app/shared/components/alert-detail/alert-detail.component.scss';
@import './app/shared/components/task-concept/task-concept.component.scss';
@import './app/shared/components/participants-list/participants-list.component.scss';
@import './app/core/components/role-assignment-modal/role-assignment-modal.component.scss';
@import './app/shared/components/task-edit/styles/task-edit.component.scss';
@import './app/shared/components/task-historic/styles/task-historic.component.scss';
@import './app/core/components/global-customization/global-customization.component.scss';
@import './app/core/components/send-reminder/send-reminder.component.scss';
@import './app/shared/components/modal-more-info/modal-more-info.component.scss';
@import './app/shared/components/field-type-list-extended/field-type-list-extended.component.scss';
@import './app/shared/components/field-type-rich-text/styles/field-type-rich-text.component.scss';
@import './app/shared/components/expanded-textarea/expanded-textarea.component.scss';
@import './app/core/components/preview-customization/preview-customization.component.scss';
@import './app/shared/components/customize-footer/customize-footer.component.scss';
@import './app/core/components/new-report/new-report.component.scss';
@import './app/core/components/clients-tracking-dashboard/clients-tracking-dashboard.component.scss';
@import './app/shared/components/clients-tracking/clients-tracking.component.scss';
@import './app/core/components/instant-videoconferences/instant-videoconferences.component.scss';
@import './app/core/components/new-instant-videoconference/new-instant-videoconference.component.scss';
@import './app/core/components/snippets-reusable/snippets-reusable.component.scss';
@import './app/core/components/documentation-list/documentation-list.component.scss';
@import './app/shared/components/new-version/new-version.component.scss';
@import './app/core/components/document-historic/document-historic.component.scss';
@import './app/core/components/certify-document/certify-document.component.scss';
@import './app/core/components/request-tracking/request-tracking.component.scss';
@import './app/core/components/move-modal/move-modal.component.scss';
@import './app/core/components/duplicate-document/duplicate-document.component.scss';
@import './app/core/components/result-search/result-search.component.scss';
@import './app/core/components/onboarding/onboarding.component.scss';
@import './app/core/components/duplicate-documentation/duplicate-documentation.component.scss';
@import './app/core/components/reports/reports.component.scss';
@import './app/shared/components/field-type-onefile/field-type-onefile.component.scss';
@import './app/core/components/all-documentation/all-documentation.component.scss';
@import './app/core/components/client-evaluation/client-evaluation.component.scss';
@import './app/core/components/users-admin/users-admin.component.scss';
@import './app/core/components/report-table/styles/report-table.component.scss';
@import './app/core/components/report-graph/report-graph.component.scss';
@import './app/core/components/heat-map-graph/heat-map-graph.component.scss';
@import './app/core/components/bar-horizontal-graph/bar-horizontal-graph.component.scss';
@import './app/core/components/columns-graph/columns-graph.component.scss';
@import './app/core/components/stacked-graph/stacked-graph.component.scss';
@import './app/core/components/scatter-plot-graph/scatter-plot-graph.component.scss';
@import './app/core/components/bar-graph/bar-graph.component.scss';
@import './app/core/components/users-admin-main/users-admin-main.component.scss';
@import './app/core/components/users-admin-new-wizard/users-admin-new-wizard.component.scss';
@import './app/core/components/users-admin-person-form/users-admin-person-form.component.scss';
@import './app/core/components/users-admin-security-form/users-admin-security-form.component.scss';
@import './app/core/components/users-admin-edit/users-admin-edit.component.scss';
@import './app/core/components/users-admin-roles-list/users-admin-roles-list.component.scss';
@import './app/core/components/users-admin-group-form/users-admin-group-form.component.scss';
@import './app/shared/components/import-modal/import-modal.component.scss';
@import './app/core/components/add-assignment-modal/add-assignment-modal.component.scss';
@import './app/core/components/custom-reports/custom-reports.component.scss';
@import './app/core/components/window-list/window-list.component.scss';
@import './app/core/components/new-custom-report/new-custom-report.component.scss';
@import './app/core/components/new-custom-report-identification/new-custom-report-identification.component.scss';
@import './app/core/components/new-custom-report-configuration/new-custom-report-configuration.component.scss';
@import './app/core/components/channel-configuration/channel-configuration.component.scss';
@import './app/shared/components/qr-modal/qr-modal.component.scss';
@import './app/core/components/results-report/results-report.component.scss';
@import './app/core/components/export-result-report/export-result-report.component.scss';
@import './app/core/components/view-extended-report/view-extended-report.component.scss';
@import './app/shared/components/horizontal-poll-graph/horizontal-poll-graph.component.scss';
@import './app/core/components/new-custom-report-preview/new-custom-report-preview.component.scss';
@import './app/core/components/new-custom-report-field-assignment/new-custom-report-field-assignment.component.scss';
@import './app/core/components/alert-list-window/alert-list-window.component.scss';
@import './app/shared/components/role-error-modal/role-error-modal.component.scss';
@import './app/core/components/concept-window/concept-window.component.scss';
@import './app/core/components/concept-window-tab/concept-window-tab.component.scss';
@import './app/shared/components/field-assignments-modal/field-assignments-modal.component.scss';
@import './app/core/components/states-task-help-modal/states-task-help-modal.component.scss';
@import './app/shared/components/report-export-modal/report-export-modal.component.scss';
@import './app/core/components/blocked-edition-modal/blocked-edition-modal.component.scss';
@import './app/core/components/report-record-detail/styles/report-record-details.component.scss';
@import './app/core/components/protection-data-modal/protection-data-modal.component.scss';
@import './app/core/components/concursal-forms-modal/concursal-forms-modal.component.scss';
@import './app/core/components/favorites/favorites.component.scss';
@import './app/shared/components/documentation-report-list/documentation-report-list.component.scss';
@import './app/shared/components/exit-login/exit-login.component.scss';
@import './app/core/components/edition-comparator/edition-comparator.component.scss';
@import './app/core/components/edition-comparator-form/edition-comparator-form.component.scss';
@import './app/core/components/organization-chart/organization-chart.component.scss';
@import './app/core/components/concept-list-landing/concept-list-landing.component.scss';
@import './app/core/components/report-incidents-list/report-incidents-list.component.scss';
@import './app/core/components/evaluation-import/evaluation-import.component.scss';
@import './app/shared/components/no-permissions/no-permissions.component.scss';
@import './app/shared/components/history-viewer/history-viewer.component.scss';

@import './app/shared/components/schedule/schedule.component.scss';
@import './app/core/components/schedule-event/schedule-event.component.scss';
@import './app/core/components/upload-report/upload-report.component.scss';
@import './app/shared/components/states-process/styles/states-process.component.scss';
@import './app/shared/components/field-type-radio-option/field-type-radio-option.component.scss';
@import './app/shared/components/export-format-modal/export-format-modal.component.scss';
@import './app/core/components/schedule-big/schedule-big.component.scss';
@import './app/shared/components/migration-data/migration-data.component.scss';
@import './app/core/components/public-link/public-link.component.scss';
@import './app/core/components/public-link-poll/public-link-poll.component.scss';
@import './app/shared/components/field-type-txchk/field-type-txchk.component.scss';
@import './app/shared/components/input-date/input-date.component.scss';
@import './app/shared/components/export-modal/export-modal.component.scss';
@import './app/shared/components/generate-document/styles/generate-document.component.scss';
@import './app/core/components/landing-page/landing-page.component.scss';
@import './app/shared/components/add-alert/add-alert.component.scss';
@import './app/shared/components/input-number/input-number.component.scss';
@import './app/shared/components/field-type-consult-lists/field-type-consult-lists.component.scss';
@import './app/shared/components/consult-lists-modal/consult-lists-modal.component.scss';
@import './app/shared/components/report-consult-lists-pdf/report-consult-lists-pdf.component.scss';
@import './app/core/components/reference-document/reference-document.component.scss';
@import './app/core/components/automatic-post-process-modal/automatic-post-process-modal.component.scss';
@import './app/shared/components/rich-editor/rich-editor.component.scss';
@import './app/shared/components/task-generic/task-generic.component.scss';
@import './app/shared/components/associated-documentation/associated-documentation.component.scss';
@import './app/core/components/manage-confidential-modal/manage-confidential-modal.component.scss';
@import './app/shared/components/basic-data-modal/basic-data-modal.component.scss';
@import './app/core/components/custom-report-list/custom-report-list.component.scss';
@import './app/core/components/custom-report-field-list/custom-report-field-list.component.scss';
@import './app/shared/components/calendar-datepicker/calendar-datepicker.component.scss';
@import './app/shared/components/field-type-report/field-type-report.component.scss';
@import './app/shared/components/notification-modal/notification-modal.component.scss';
@import './app/shared/components/donut-graph/donut-graph.component.scss';
@import './app/shared/components/concept-info/styles/concept-info.component.scss';
@import './app/shared/components/calendar-month-year/calendar-month-year.component.scss';
@import './app/shared/components/rappid/rappid.component.scss';
@import './app/shared/components/filials/filials.component.scss';
@import './app/core/components/link-modal/link-modal.component.scss';
@import './app/shared/components/relationship-reason-modal/relationship-reason-modal.component.scss';
@import './app/core/components/general-report/general-report.component.scss';
@import './app/core/components/login-be-access/login-be-access.component.scss';
@import './app/core/components/icam-access-landing/icam-access-landing.component.scss';
@import './app/shared/components/list-comparator-in-dropdown/list-comparator-in-dropdown.component.scss';
@import './app/core/components/schedule-next-event/schedule-next-event.component.scss';
@import './app/shared/components/input-time/input-time.component.scss';
@import './app/core/components/available-processes/available-processes.component.scss';
@import './app/shared/components/record-audio-modal/styles/record-audio-modal.component.scss';
@import './app/shared/components/listen-record/listen-record.component.scss';
@import './app/core/components/form-configuration-modal/form-configuration-modal.component.scss';
@import './app/shared/components/stacked-bar-plot/stacked-bar-plot.component.scss';

@import './app/shared/components/tooltip-in-report/tooltip-in-report.component.scss';
