@mixin global-customization-button-color($primaryColor, $secondColor) {
  color: $primaryColor;
  border-color: $primaryColor;
  &:hover:not([disabled]){
      color: $secondColor;
      background-color: $primaryColor;
  }
}

.global-customization {
  &__block-container {
      width: 100%;
      margin: 0 auto;
      min-height: calc(100% - 369px);
      display: block;
      max-width: 1400px;
  }

  &__body {
      height: auto;
      min-height: 1480px;
      padding-bottom: 123px;
  }

  &__top-bar {
      background-color: $primary-color;
      height: 9px;
      width: 100%;
  }

  &__form-body {
      width: 800px;
      margin: 50px auto 0;
  }

  &__block-step {
      margin-top: 35px;
  }

  &__block-title-step {
      margin-bottom: 20px;
      height: 30px;
  }

  &__icon-title-step {
      font-size: 30px;
      position: relative;
      color: $public-link-text-step;
      margin-right: 5px;
  }

  &__switch-email {
      display: inline-block;
  }

  &__title-step {
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 18px;
      position: relative;
      bottom: 6px;
  }

  &__block-tiny {
      display: block;
      resize: none;
      height: auto;
      margin-bottom: 10px;
  }

  &__input-textarea {
      width: 100%;
      height: 112px;
      resize: none;
  }

  &__text-info-mail {
      color: $subcontainer-ground;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-style: italic;
      margin-bottom: 9px;
  }

  &__buttons-block {
      display: block;
      text-align: right;
      margin: 60px 55px 12px;
      position: absolute;
      width: calc(100% - 110px);
      left: 0;
  }

  &__button {
      border: 1px solid $primary-color;
      background-color: transparent;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      text-align: center;
      height: 44px;
      outline: 0;
      padding: 9px 13px 7px 13px;
      @include global-customization-button-color($primary-color, $snow-white);

      &[disabled] {
          opacity: 0.5;
      }
  }

  &__icon-button {
      font-size: 20px;
      display: block;
      position: relative;
      float: left;
      margin-right: 13px;
      bottom: 2px;
  }

  &__left {
      float: left;
  }

  &__block-custom-mail {
      height: 210px;
      width: 100%;
  }

  &__block-line-pickers-color{
    display: flex;
    justify-content: space-between;
  }

  &__block-picker-color {
      width: calc(50% - 2px);
      display: inline-block;
  }
}

.compliance{
  &__dark-blue{
    .global-customization {
      &__title-step {
          opacity: 0.5;
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue{
    .global-customization {
      &__icon-title-step {
          color: $grey-icons-2;
      }
      &__title-step {
          color: $grey-icons-2;
      }
      &__text-info-mail {
          color: $grey-icons-2;
      }
      &__button {
          @include global-customization-button-color($border-hover-2, $snow-white);
      }
    }
  }
  &__icam-red{
    .global-customization {
      &__icon-title-step {
          color: $secondary-color-icam;
      }
      &__title-step {
          color: $secondary-color-icam;
      }
      &__text-info-mail {
          color: $secondary-color-icam;
      }
      &__button {
          @include global-customization-button-color($primary-color-icam, $snow-white);
      }
    }
  }
  &__gm-law{
    .global-customization {
      &__icon-title-step {
          color: $secondary-color-gm;
      }
      &__title-step {
          color: $secondary-color-gm;
      }
      &__text-info-mail {
          color: $secondary-color-gm;
      }
      &__button {
          @include global-customization-button-color($primary-color-gm, $snow-white);
      }
    }
  }
  &__com-de{
    .global-customization {
      &__icon-title-step {
          color: $secondary-color-com-de;
      }
      &__title-step {
          color: $secondary-color-com-de;
      }
      &__text-info-mail {
          color: $secondary-color-com-de;
      }
      &__button {
          @include global-customization-button-color($primary-color-com-de, $snow-white);
      }
    }
  }
  &__com-fr{
    .global-customization {
      &__icon-title-step {
          color: $secondary-color-com-fr;
      }
      &__title-step {
          color: $secondary-color-com-fr;
      }
      &__text-info-mail {
          color: $secondary-color-com-fr;
      }
      &__button {
          @include global-customization-button-color($primary-color-com-fr, $snow-white);
      }
    }
  }
}

@media (max-width: 1440px) {
  .global-customization {
      &__block-container {
          width: calc(100% - 30px);
          margin-left: 15px;
      }
  }
}

@media (max-width: 1340px) {
  .global-customization {
      &__body{
          padding-bottom: 153px;
      }
      &__buttons-block{
          margin: 35px 55px 12px 10px;
          width: calc(100% - 65px);
      }
      &__left{
          &--previous{
              float: none;
              margin-bottom: 10px;
          }
      }
  }
}

@media (max-width: 1185px) {
  .global-customization {
      &__body{
          padding-bottom: 153px;
      }
      &__buttons-block{
          margin: 35px 55px 12px 10px;
          width: calc(100% - 65px);
      }
      &__left{
          float: none;
          margin-bottom: 10px;
      }
  }
}

@media (max-width: 1022px) {
  .global-customization {
      &__form-body{
          width: calc(100% - 80px);
          margin: 30px auto 0;
      }
  }
}

@media (max-width: $max-width-smartphone) {
  .global-customization {
      &__block-tiny {
          display: none;
      }
  }
}
