.header-poll{
  &__block{
      position: relative;
      float: left;
      width: 100%;
      height: 200px;
      color: $snow-white;
      &--public{
          height: 50px;
      }
  }

  &__block-client{
      height: 18px;
      border-left: 2px solid $snow-white;
      padding: 5px 0px 7px 11px;
      line-height: 10px;
      display: block;
      overflow: hidden;
      clear: both;
      margin-left: 268px;
  }

  &__block-img{
      position: relative;
      height: 200px;
      display: table-cell;
      vertical-align: middle;
      &--public{
          height: 50px;
      }
  }

  &__block-logo{
      position: relative;
      float: left;
      border-left: 2px solid $primary-color;
      border-bottom: 2px solid $primary-color;
      background-color: $snow-white;
      margin-left: 15px;
      height: 200px;
      width: calc(15% - 15px);
      box-shadow: 0 5px 5px -5px $certify-black-opacity;

      &:before{
          content:'';
          position: absolute;
          left: -136px;
          border-bottom: 200px solid transparent;
          border-right: 134px solid $primary-color;
      }
      &--public{
          height: 50px;
          background-color: $background-grey-other;
          border-left: 2px solid $background-grey-other;
          border-bottom: 2px solid $background-grey-other;
          &:before{
              border-bottom: 50px solid transparent;
              border-right: 48px solid $background-grey-other;
              left: -48px;
          }
      }
  }

  &__block-title{
      position: relative;
      float: left;
      height: 200px;
      width: 85%;
      box-shadow: 0 5px 5px -5px $certify-black-opacity;


      // &:after{
      //     content:'';
      //     position: absolute;
      //     border-top: 0px solid transparent;
      //     border-left: 0px solid $primary-color;
      //     border-bottom: 200px solid $primary-color;
      //     border-right: 133px solid transparent;
      //     height: 0 !important;
      //     width: 0 !important;
      // }
      &--public{
          height: 50px;
          // &:after{
          //     border-bottom: 50px solid $primary-color;
          //     border-right: 48px solid transparent;
          // }
      }
  }

  &__triangle-left{
      content:'';
      position: absolute;
      right: 0;
      border-top: 0px solid transparent;
      border-left: 0px solid $primary-color;
      border-bottom: 200px solid $primary-color;
      border-right: 133px solid transparent;
      height: 0 !important;
      width: 0 !important;
      &--public{
          border-bottom: 50px solid $primary-color;
          border-right: 48px solid transparent;
      }
  }

  &__block-title-text{
      position: relative;
      float: left;
      width: calc(100% - 133px);
      height: 200px;
      padding: 73px 0 72px 65px;
      background-color: $primary-color;
      font-family: MTTMilano, sans-serif;
      &--public{
          height: 50px;
          padding: 0;
          width: calc(100% - 48px);
      }
  }

  &__block-triangle-white{
      position: absolute;
      left: -133px;
      border-top: 0px solid $snow-white;
      border-left: 0px solid transparent;
      border-bottom: 198px solid transparent;
      border-right: 133px solid $snow-white;
      height: 0 !important;
      width: 0 !important;
  }

  &__client-img{
      max-width: 90%;
      height: auto;
  }

  &__client-subtitle{
      color: $snow-white;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 16px;
      float: left;
      position: relative;
      bottom: 2px;
  }

  &__client-title{
      text-transform: uppercase;
      float: left;
      padding-right: 10px;
      color: $snow-white;
      font-family: MTTMilano, sans-serif;
      font-size: 11px;
      font-weight: 500;
  }

  &__icon-check{
      font-size: 45px;
      margin: 0px 26px;
      position: relative;
      top: 5px;
      float: left;
  }

  &__img-lefebvre{
      float: left;
  }

  &__title-head{
      display: block;
      font-size: 25px;
      width: 81%;
      float: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 15px 0 8px;
  }
}
