.column-graph{
  position: relative;
  &__svg-background{
      position: absolute;
      left: 60px;
      top: 30px;
      background: linear-gradient(0deg, $red 0%, $yellow 40%,  $green 60%);
      z-index: 1;
  }
  &__svg{
      position: absolute;
      z-index: 10;
  }
  &__x-axxis{
      & .tick{
          & line{
              display: none;
          }
          & text{
              font-family: 'MTTMilano-bold', sans-serif;
              font-size: 12px;
              text-transform: uppercase;
              color: $primary-color;
          }

      }
  }
  &__y-axxis{
      & .tick{
          & line{
              display: none;
          }

      }
  }
}
