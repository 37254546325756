.filials{
  &__afiliated-list {
      list-style: none;
      padding: 0;
      margin: 0;
  }

  &__afiliated-name {
      position: relative;
      float: left;
      max-width: calc(100% - 40px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }
}

.compliance{
  &__dark-blue{
    .filials{
      &__afiliated-name {
        &--disabled{
            opacity: $subcontainer-ground;
        }
        &--disabled-red{
            color: $red;
        }
      }
    }
  }
  &__steel-blue {
    .filials{
      &__afiliated-name {
        &--disabled {
            color: $grey-icons-2;
        }
        &--disabled-red{
            color: $alert-color-2;
        }
      }
    }
  }
}
