.stacked-graph{
    &__stacked-title{
        font-size: 20px;
        color: $primary-color;
        margin: 27px 0 0 18px;
        padding-bottom: 1px;
        width: 400px;
        margin-left: 183px;
    }
    &__legend{
        display: block;
        padding: 0;
        margin-left: 200px;
        list-style: none;
    }
    &__legend-item{
       
        height: 30px;
        display: inline-block;
        margin-right: 16px;
    }
    &__legend-square{
        width: 9px;
        height: 9px;
        display: inline-block;
        background: $blue;
    }
}