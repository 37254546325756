@mixin users-admin-security-form-clients-list($firsColor, $secondColor, $whiteColor) {
  background-color: $whiteColor;
  &[disabled] {
      background-color: $firsColor;
  }

  &:hover:not([disabled]) {
      background-color: $secondColor;
      &.open {
          background-color: $whiteColor;
      }
  }
}

@mixin users-admin-security-form-table-row($borderColor) {
  border-bottom: 1px solid $borderColor;
}
.users-admin-security-form {
  min-height: 500px;
  width: 100%;
  display: inline-block;
  &__table-permissions{
      margin-bottom: 10px;
  }
  &__cell-block{
      height: 20px;
      width: calc(100% - 23px);
      float: right;
      position: relative;
      &--repeat{
          width: calc(100% - 21px);
      }
  }

  &__cell-block-security{
      position: relative;
      float: left;
      max-width: 100%;
      height: 17px;
  }

  &__all-client-dropdown{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 18px);
      display: inline-block;
  }

  &__clients-list-buttons{
      position: relative;
      bottom: 5px;
      float: left;
  }

  &__pillow {
      display: inline-block;
      position: relative;
      color: $security-pillow;
      font-size: 11px;
      padding: 0 10px;
      background-color: $primary-color;
      border-radius: 8px;
      top: 5px;
      margin-right: 4px;
      &--open{
          bottom: 4px;
          top: inherit;
          margin: 0;
      }

      &.group {
          background-color: $pillow-group;
      }
  }

  &__col {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    padding-right: 5px;
    max-width: 0;
    position: relative;
    &--product {
        width: calc(100% - 855px);
    }

    &--client {
        width: 275px;
        min-width: 275px;
    }

    &--permission {
        width: 125px;
        min-width: 125px;
        text-align: center;
    }

    &--permission-read {
        width: 80px;
        min-width: 80px;
        text-align: center;
    }
  }

  &__table-header-text{
    width: 100%;
    float: initial;
    text-align: center;
    padding-top: 3px;
  }

  &__info {
      position: relative;
      display: inline-block;
      width: 18px;
      &:hover {
          & > .common__tooltip-info {
              display: block;
          }
      }
  }

  &__table-row,
  &__table-row-client {
      display: table;
      width: 100%;
      height: 48px;
  }

  &__clients-list {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      max-height: 400px;
      z-index: 1;
      background-color: $background-grey;
      padding: 0;
      min-height: 47px;
      font-size: 12px;
      font-family: Arial, sans-serif;

      &--enabled:hover,
      &--open{
          box-shadow: 0 0 5px -1px $certify-black-opacity03;
          z-index: 2;
      }

  }

  &__clients-list-drop{
      padding: 16px 5px 16px 9px;
      height: 47px;
      color: $secondary-color;

      & > span {
          line-height: 17px;
      }

      &--enabled-close:hover{
        cursor: pointer;
      }
  }

  &__clients-list-search {
      position: relative;
      margin-top: 15px;
      margin-bottom: 2px;
  }

  &__clients-list-down {
      position: relative;
      float: left;
      width: 100%;
      padding: 0 5px;
  }

  &__clients-list-ul{
      list-style: none;
      padding: 0;
      margin-bottom: 4px;
      color: $primary-color;
      max-height: 283px;
      position: relative;
      float: left;
      overflow: auto;
      width: 100%;
  }

  &__clients-list-li{
      position: relative;
      float: left;
      height: 30px;
      width: 100%;
      padding: 4px 5px;
      border: 1px solid transparent;

      &:hover:not([disabled]) {
          cursor: pointer;
          &  .users-admin-security-form__icon-hover-check-list{
              visibility: visible;
          }
      }


      & > label {
          margin-bottom: 0;
          display: block;
          &:hover:not([disabled]) {
              cursor: pointer;
          }
          & > i {
              position: relative;
              top: 2px;
          }
      }
  }

  &__clients-list-li-all{
      position: relative;
      float: left;
      height: 30px;
      padding: 4px 5px;

      &:hover {
          cursor: pointer;
      }
      & > label {
          margin-bottom: 0;
          display: block;
          &:hover{
              cursor: pointer;
          }
          & > i {
              position: relative;
              top: 2px;
          }
      }
  }

  &__clients-list-count {
      position: absolute;
      top: 6px;
      right: 5px;
      padding: 0 7px;
      border-radius: 10px;
      line-height: normal;
  }

  &__clients-list-selection-text {
      color: $subcontainer-ground;
      position: relative;
      width: calc(100% - 95px);
      display: inline-block;
  }

  &__clients-list-default-text {
      color: $subcontainer-ground;
      font-style: italic;
  }

  &__table-check-icon{
      font-size: 20px;
  }

  &__info-icon{
      font-size: 18px;
  }
  &__info-text{
      position: relative;
      display: inline-block;
      padding-right: 3px;
      max-width: calc(100% - 18px);
  }
  &__angle-down-icon{
      font-size: 16px;
  }
  &__check-icon-list{
      font-size: 15px;
      padding-right: 6px;
      &:hover:not([disabled]) {
          cursor: pointer;
      }
  }

  &__client-name{
      max-width: calc(100% - 16px);
      font-size: 12px;
      font-family: Arial, sans-serif;
  }

  &__icon-button{
      position: absolute;
      top: 16px;
      right: 5px;
  }

  &__icon-hover-check-list{
      visibility: hidden;
      font-size: 16px;
  }

  &__tooltip-info{
      left: -153px;
      top: -81px;
      text-align: left;
      display: none;
      border-width: 1px;
      border-bottom-width: 4px;
      width: 265px;
      &:after {
          left: 156px;
          border-top: 5px solid $primary-color;
          border-right: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 5px solid transparent;
          top: 70px;
      }
      &:hover {
          background-color: $security-tooltip;
          border-width: 1px;
          border-bottom-width: 4px;
      }
      &--edit{
          width: 295px;
      }
  }

  &__tooltip-inner-text{
      display: block;
      overflow: hidden;
      width: 100%;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: 400;
      padding: 8px 6px 0;
  }

  &__inner-text{
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 11px;
      text-transform: none;
      padding: 6px 5px 0px 12px;
      display: block;
      overflow: auto;
      height: 71px;
  }

  &__checkbox{
      font-size: 20px;
      position: relative;
      top: 2px;
      border: none;
      padding: 1px;
      background-color: transparent;
      outline: none;
  }

  &__icon-groups{
      font-size: 20px;
      position: relative;
      top: 2px;
      color: $pillow-group;
  }

  &__buttons-right{
    float: right;
  }

  &__buttons {
    margin-top: 70px;
    text-align: right;
    & button {
        margin-left: 5px;
    }
  }
}

.compliance{
  &__dark-blue{
    .users-admin-security-form {
      &__table-row {
          @include users-admin-security-form-table-row($result-search-quote-color);
      }
      &__clients-list {
          background-color: $background-color-states;
      }
      &__clients-list-li{
            &:hover:not([disabled]) {
              border-bottom: 1px solid $primary-color;
              background-color: $concept-legal-grey;
          }
      }
      &__clients-list-li-all{
          border: 1px solid $box-comment-border-02;
          width: calc(100% - 5px);
      }
      &__clients-list-count {
          color: $primary-color;
          background-color: $concept-legal-grey;
      }
      &__check-icon-list{
          color: $primary-color;
          &[disabled] {
              color: $subcontainer-ground;
          }
      }
      &__client-name{
          color: $primary-color;
      }
      &__icon-hover-check-list{
          color: $primary-color;
      }
      &__tooltip-info{
          border-color: $subcontainer-poll-border;
          border-bottom-color: $primary-color;
      }
      &__checkbox{
          &:hover:not([disabled]){
              border: 1px solid $box-comment-border-02;
              background: $background-color-states;
          }
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue{
    .users-admin-security-form {
      &__all-client-dropdown{
          color: $text-field-color-2;
      }
      &__pillow {
          background-color: $border-hover-2;
      }
      &__table-row {
          @include users-admin-security-form-table-row($secondary-color-2);
      }
      &__clients-list {
          @include users-admin-security-form-clients-list($background-grey-disabled-2, $third-color-2, $snow-white);
      }
      &__clients-list-li{
            &:hover:not([disabled]) {
              background-color: $third-color-rgba-mid;
          }
      }
      &__clients-list-li-all{
          border-bottom: 1px solid $third-color-2;
          width: 100%;
          margin: 5px 0;
      }
      &__clients-list-count {
          color: $snow-white;
          background-color: $grey-icons-2;
      }
      &__clients-list-selection-text {
          color: $grey-icons-2;
      }
      &__clients-list-default-text {
          color: $grey-icons-2;
      }
      &__table-check-icon{
          color: $border-hover-2;
      }
      &__angle-down-icon{
          &[disabled] {
              color: $grey-icons-2;
          }
          &:not([disabled]){
              color: $border-hover-2;
          }
      }
      &__check-icon-list{
          color: $border-hover-2;
          &[disabled] {
              color: $grey-icons-2;
          }
      }
      &__client-name{
          color: $primary-color-2;
      }
      &__icon-button{
          color: $border-hover-2;
      }
      &__icon-hover-check-list{
          color: $border-hover-2;
      }
      &__tooltip-info{
          background-color: $snow-white;
          color: $primary-color-2;
          border-color: $border-shadow-2;
          border-bottom-color: $primary-color-2;
          &:after{
              border-top-color: $primary-color-2;
          }
          &:hover {
              background-color: $snow-white;
              border-color: $primary-color-2;
          }
      }
      &__checkbox{
          &:hover:not([disabled]){
              border: 1px solid $third-color-2;
              background: $block-hover-2;
          }
      }
      &__icon-groups{
          color: $primary-color-2;
      }
    }
  }
  &__icam-red{
    .users-admin-security-form {
      &__pillow {
          background-color: $primary-color-icam;
      }
      &__table-row {
          @include users-admin-security-form-table-row($border-block-icam);
      }
      &__clients-list {
          @include users-admin-security-form-clients-list($background-grey-disabled-icam, $hover-block-icam, $snow-white);
      }
      &__clients-list-ul{
          color: $primary-color-icam;
      }
      &__clients-list-selection-text {
          color: $custom-black-landing;
      }
      &__clients-list-default-text {
          color: $text-field-color-icam;
      }
      &__angle-down-icon{
          &[disabled] {
              color: $secondary-color-icam;
          }
          &:not([disabled]){
              color: $primary-color-icam;
          }
      }
      &__client-name{
          color: $primary-color-gm;
      }
      &__tooltip-info{
          background-color: $background-grey-disabled-icam;
          color: $custom-black-landing;
          border-color: $black-opacity-03;
          border-bottom-color: $custom-black-landing;

          &:after{
              border-top-color: $custom-black-landing;
          }

          &:hover {
              background-color: $background-grey-disabled-icam;
              border-color: $black-opacity-03;
          }
      }
      &__icon-groups{
          color: $title-text-icam;
      }
    }
  }
  &__gm-law{
    .users-admin-security-form {
      &__pillow {
          background-color: $primary-color-gm;
      }
      &__table-row {
          @include users-admin-security-form-table-row($border-block-gm);
      }
      &__clients-list {
          @include users-admin-security-form-clients-list($background-grey-disabled-gm, $hover-block-gm, $snow-white);
      }
      &__clients-list-ul{
          color: $primary-color-gm;
      }
      &__clients-list-selection-text {
          color: $custom-black-landing;
      }
      &__clients-list-default-text {
          color: $text-field-color-gm;
      }
      &__angle-down-icon{
          &[disabled] {
              color: $secondary-color-gm;
          }
          &:not([disabled]){
              color: $primary-color-gm;
          }
      }
      &__client-name{
          color: $primary-color-gm;
      }
      &__tooltip-info{
          background-color: $background-grey-disabled-gm;
          color: $custom-black-landing;
          border-color: $black-opacity-03;
          border-bottom-color: $custom-black-landing;

          &:after{
              border-top-color: $custom-black-landing;
          }
          &:hover {
              background-color: $background-grey-disabled-gm;
              border-color: $black-opacity-03;
          }
      }
      &__icon-groups{
          color: $title-text-gm;
      }
    }
  }
  &__com-de{
    .users-admin-security-form {
      &__pillow {
          background-color: $primary-color-com-de;
      }
      &__table-row {
          @include users-admin-security-form-table-row($border-block-com-de);
      }
      &__clients-list {
          @include users-admin-security-form-clients-list($background-grey-disabled-com-de, $hover-block-com-de, $snow-white);
      }
      &__clients-list-ul{
          color: $primary-color-com-de;
      }
      &__clients-list-selection-text {
          color: $custom-black-landing;
      }
      &__clients-list-default-text {
          color: $text-field-color-com-de;
      }
      &__angle-down-icon{
          &[disabled] {
              color: $secondary-color-com-de;
          }
          &:not([disabled]){
              color: $primary-color-com-de;
          }
      }
      &__client-name{
          color: $primary-color-com-de;
      }
      &__tooltip-info{
          background-color: $background-grey-disabled-com-de;
          color: $custom-black-landing;
          border-color: $black-opacity-03;
          border-bottom-color: $custom-black-landing;

          &:after{
              border-top-color: $custom-black-landing;
          }
          &:hover {
              background-color: $background-grey-disabled-com-de;
              border-color: $black-opacity-03;
          }
      }
      &__icon-groups{
          color: $title-text-com-de;
      }
    }
  }
  &__com-fr{
    .users-admin-security-form {
      &__pillow {
          background-color: $primary-color-com-fr;
      }
      &__table-row {
          @include users-admin-security-form-table-row($border-block-com-fr);
      }
      &__clients-list {
          @include users-admin-security-form-clients-list($background-grey-disabled-com-fr, $hover-block-com-fr, $snow-white);
      }
      &__clients-list-ul{
          color: $primary-color-com-fr;
      }
      &__clients-list-selection-text {
          color: $custom-black-landing;
      }
      &__clients-list-default-text {
          color: $text-field-color-com-fr;
      }
      &__angle-down-icon{
          &[disabled] {
              color: $secondary-color-com-fr;
          }
          &:not([disabled]){
              color: $primary-color-com-fr;
          }
      }
      &__client-name{
          color: $primary-color-com-fr;
      }
      &__tooltip-info{
          background-color: $background-grey-disabled-com-fr;
          color: $custom-black-landing;
          border-color: $black-opacity-03;
          border-bottom-color: $custom-black-landing;

          &:after{
              border-top-color: $custom-black-landing;
          }
          &:hover {
              background-color: $background-grey-disabled-com-fr;
              border-color: $black-opacity-03;
          }
      }
      &__icon-groups{
          color: $title-text-com-fr;
      }
    }
  }
}

@media (max-width: 1260px) {
  .users-admin-security-form {
    &__col {
        &--product {
            width: calc(100% - 765px);
        }

        &--client {
            width: 230px;
            min-width: 230px;
        }

        &--permission {
            width: 115px;
            min-width: 115px;
        }

        &--permission-read {
            width: 75px;
            min-width: 75px;
        }
    }
  }
}

@media (max-width: 1050px) {
  .users-admin-security-form {
    &__col {
        &--product {
            width: calc(100% - 710px);
        }

        &--client {
            width: 180px;
            min-width: 180px;
        }

        &--permission {
            width: 115px;
            min-width: 115px;
        }

        &--permission-read {
            width: 70px;
            min-width: 70px;
        }
    }
  }
}
