.report-table {
  display: block;
  border: 1px solid $primary-color;
  background-color: $snow-white;
  min-height: 760px;
  width: 100%;
  margin: 0 0 12px;
  position: relative;
  float: left;

  &__reports-header {
      margin-bottom: 0px;
      &--dynamic{
        display: flex;
        justify-content: space-between;
      }
  }

  &__block-overflow {
      max-height: 820px;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
  }

  &__block-task {
      border-bottom: 1px solid $donut-text;
      padding: 17px 0 0 2px;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      line-height: 12px;
      height: 140px;
  }

  &__button-task-text {
      text-transform: uppercase;
      font-family: Arial, sans-serif;
      font-size: 10px;
      padding-left: 10px;
      position: relative;
      top: -5px;
  }

  &__icon-numeric {
      font-size: 11px;
      border: 2px solid $snow-white;
      width: 29px;
      height: 20px;
      border-radius: 12px;
      margin-left: -14px;
      top: -6px;
      font-weight: bold;
      background-color: $subcontainer-ground;
      color: $snow-white;
      font-family: Arial, sans-serif;
      text-align: center;
      display: inline-block;
      font-style: normal;
      left: 31px;
      // &:hover{
      //     cursor: pointer;
      // }
  }

  &__icon-task {
      color: $subcontainer-ground;
      font-size: 22px;

      &--with-tasks {
          color: $primary-color;
          margin-left: 2px;

          &:hover {
              cursor: pointer;
          }
      }
  }

  &__icon-check {
      font-size: 20px;
      left: 5px;
      top: 8px;
      position: relative;
  }

  &__last-block-task {
      float: right;
  }

  &__span-task-style {
      color: $secondary-color;
  }

  &__subtitle-measure-tasks {
      text-transform: uppercase;
      background-color: $subtitle-color;
      padding: 6px;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 10px;
      display: inline-block;
  }

  &__responsive {
      overflow-x: auto;
  }

  &__table-row {
      height: 40px;
      //position: relative;
      display: inline-block;
      width: 100%;
  }

  &__table-body {
      width: 100%;
      display: block; // JRSJ 27/03/2020
      position: relative;
      overflow: hidden;
      height: 660px;
      overflow-y: auto;
      max-height: 660px;
      padding-bottom: 14px;

      scrollbar-gutter: stable;
      @supports(-moz-appearance:none) {
        scrollbar-width: thin;
      }
  }

  &__table {
      padding: 10px 10px 10px 10px;
      //width: calc(100% - 10px);          //jrsj 27/03/2020
      width: 100%;
      display: block;
      position: absolute;
      height: 720px;
      overflow-y: hidden;

      &--responsive {
          overflow-x: auto;
      }

      &--reports {
          padding-top: 5px;
      }
  }

  &__table-body-header {
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      margin: 0;

      &--report {
          min-height: 18px;
          // margin-top: 7px;
      }
  }

  &__table-col {
      display: block;
      float: left;
      position: relative;
      padding: 11px 2px 5px;
      margin-right: -1px;

      &--task-none {
          display: none;
      }
      // &--actions{
      //     position: absolute;
      //     //right: 0px;
      // }
  }

  &__title-column {
    position: relative;
    height: 17px;
  }

  &__text {
      position: absolute;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      margin: 0;
      top: 7px;
      margin-right: 86px;
      margin-left: 6px;
      padding: 2px 5px 0 0px;
  }

  &__text-options {
      position: absolute;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      //margin: 0;                    // JRSJ 31/03/2020
      //top: 3px;
      //margin-right: 86x;
      //margin-left: 6px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      // padding: 2px 5px 0 0px;
      width: 80px;
      // right: -21px;
      right: -20px;
  }

  &__table-col-opacity {
      opacity: 0.36;
  }

  &__table-header {
      border-bottom: 5px solid $primary-color;
      padding: 10px 0;
      display: block;
      min-height: 30px;
      width: 100%;
      position: relative;
      float: left;

  }

  &__task-title {
      font-weight: bold;
  }

  &__title-measure-tasks {
      text-transform: uppercase;
      color: $title-measure-color;
      font-family: MTTMilano, sans-serif;
      font-size: 16px;
      margin-bottom: 30px;
  }

  &__window-tasks {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      width: 577px;
      z-index: 10;
      background-color: $background-grey;
      box-shadow: 0 0 5px 1px $certify-black-opacity02;
      padding: 17px 24px 10px 40px;
  }

  &__table-menu {
      font-size: 20px;
      color: $primary-color;
      float: right;
  }

  &__options-block {
      margin-top: -10px;
      float: right;
      display: none;
  }

  &__menu-icons {
      float: left;
      margin-right: 5px;
      font-size: 20px;
      margin-top: 10px;
      cursor: pointer;

      &--edit {
          margin-left: 14px;
      }

      &--only {
          margin-left: 28px;
      }
  }

  &__options {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      color: $primary-color;
      display: inline-flex;
      margin-right: 3px;
      background-color: $background-grey-other;
      //border: 0.5px solid rgba(191, 197, 221, 0.54);
      height: 43px;
      width: 62px;
      border-top-left-radius: 67px;
      border-bottom-left-radius: 67px;
      // z-index: 1;
      margin-left: -78px;
      margin-top: 1px;
      float: right;
      position: relative;
      &--reports {
          width: 46px;
          margin-top: 8px;
      }
  }

  &__ellipsis-icon {
      float: right;
      //margin-right: 6px;
      width: 100%;
      text-align: center;
      color: $primary-color;
      font-size: 27px;
      margin-top: 9px;
      &--disabled{
          opacity: 0.5;
      }
  }

  &__task {
      font-size: 22px;
      font-weight: 500;
      margin-left: 10px;
      color: $subcontainer-ground;

      &--with-task {
          color: $primary-color;
      }
  }

  &__number {
      position: absolute;
      margin-top: 19px;

      &--zero {
          background-color: $primary-color
      }
  }

  &__colors {
      border-radius: 13px;
      padding: 7px 10px;
      // height: 32px;
      width: auto;
      position: relative;
      float: left;
      margin-top: -5px;
  }

  &__tasks {
      background-color: $background-grey;
      height: 925px;
      width: 577px;
      margin-top: -93px;
      position: absolute;
      margin-left: 59%;
  }

  &__title-task {
      color: $title-measure-color;
      font-size: 16px;
      font-family: MTTMilano, sans-serif;
      padding: 16px 45px;
      margin-top: 16px;
      width: 600px;
  }

  &__subtitle-task {
      background-color: $subtitle-color;
      height: 21px;
      width: 356px;
      font-size: 10px;
      color: $primary-color;
      padding: 4px;
      margin-top: 13px;
      margin-left: 45px;
  }

  &__close-icon {
      color: $primary-color;
      font-size: 10px;
      float: right;
      cursor: pointer;
  }

  &__button-task {
      border: none;
      padding: 0;
      outline: none;
      background: none;
      font-size: 18px;
      position: relative;
  }

  &__graph-button {
      border: none;
      height: 26px;
      margin-bottom: 3px;
      width: 100%;
      color: $primary-color;
      position: relative;
      top: 1px;
      outline: none;
      background-color: transparent;

      &:hover {
          background-color: $primary-color;
          color: $snow-white;
      }
  }

  &__graph-button-icon {
      font-size: 18px;
      position: relative;
      left: -2px;
      top: 2px;
  }

  &__graph-button-text {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
  }

  &__graph-button-line {
      height: 2px;
      background: $primary-color;
  }

  &__rows-container {
      width: 100%;
      display: block;
      border-bottom: 1px solid $box-comment-border-2;
      min-height: 60px;
      height: auto;
      overflow: hidden;
      &:hover {
          background: $background-grey;

          & .report-table__options-block {
              display: block;
          }
      }
      &--disabled{

          &:hover {
              & .report-table__options-block {
                  display: none;
              }
          }
      }
  }

  &__hightlight {
      height: 25px;
      position: relative;
      margin-top: 11px;
  }

  &__table-container {
      width: 100%;
      //width: 97%;
      position: relative; //JRSJ 27/03/2020
  }

  // &__today-calendar {
  //     & .common__datepicker-button {
  //         border: 1px solid $primary-color;
  //         border-radius: 50%;
  //     }
  // }

  &__date-button-check {
      background: none;
      border: none;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 11px;
      outline: none;
      padding-bottom: 7px;
  }
  // &__date-button-check-icon{
  //     color: $secondary-color;
  //     padding-right: 6px;
  //     position: relative;
  //     top: 2px;
  //     margin-top: 0;
  //     &--active{
  //         color: inherit;
  //     }
  // }
  &__date-select-block {
      height: 43px;
      width: 246px;
      display: block;
      overflow: hidden;
      background-color: $primary-color;
      color: $snow-white;
      padding: 22px 10px 0px;

      &--active {
          background-color: $background-grey;
          color: $primary-color;
          border-bottom: 1px solid $primary-color;
      }
  }

  &__date-select-block-input {
      border: none;
      float: right;
      background: transparent;
      outline: none;
  }

  &__restore-button {
      font-size: 12px;
      padding: 6px 24px;

      &:after {
          width: calc(100% + 54px);
          right: 27px;
          top: 14px;
      }
  }

  &__filter-calendar-buttons {
      margin-right: 6px;
  }

  &__filter-block {
      display: block;
      min-height: 79px;
      width: 100%;
      background-color: $background-grey;
      padding: 15px 10px;
  }

  &__filter-title-block {
      border-bottom: 1px solid $primary-color;
      height: 29px;
      width: 297px;
      margin-bottom: 11px;
  }

  &__filter-title-icon {
      font-size: 18px;
      color: $primary-color;
      display: inline-block;
      position: relative;
      top: 4px;
  }

  &__filter-title-text {
      display: inline-block;
      height: 15px;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      font-weight: 500;
      padding-left: 7px;
  }

  &__filter-list-items {
      margin: 0;
      padding: 0;
      list-style: none;
      width: calc(100% - 135px);
      display: inline-block;
  }

  &__filter-item {
      display: inline-block;
      border: 2px solid $subcontainer-ground;
      background-color: $snow-white;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 4px 27px 4px 10px;
      margin-right: 5px;
      margin-bottom: 5px;

      &:hover {
          background: $subcontainer-border;
          position: relative;
          border-color: $primary-color;

          & > .report-table__filter-item-icon {
              display: inline;
          }
      }
  }

  &__filter-item-icon {
      display: none;
      font-size: 15px;
      position: absolute;
      right: 4px;
      top: 3px;

      &:hover {
          cursor: pointer;
      }
  }

  &__filter-list {
      max-height: 182px;
      overflow-y: auto;
  }
  // JRSJ 25/03/2020  Nueva clase para mantener la columna de acciones siempre visible
  &__action-fixed {
      float: unset;
      position: absolute;
      animation: 0.1s linear all;
      //left:1300px;
      right: 10px;
      z-index: 10;

      &--header {
          width: 80px;
          height: 63px;
          background-color: $background-grey-other;
          //border-left: 1px solid rgba(64,61,61,0.1);
          //border-right: 1px solid rgba(64,61,61,0.1);
          border-right: 10px solid $snow-white;
          border-left: 10px solid $snow-white;
          //border-top: 1px solid rgba(64,61,61,0.1);
      }
  }

  &__menu-action {
      background-color: $background-grey-other;
      //border-left: 1px solid rgba(64,61,61,0.1);
      //border-right: 1px solid rgba(64,61,61,0.1);
      border-right: 10px solid $snow-white;
      border-left: 10px solid $snow-white;
      //height: calc(100% - 1px);
      height: 100%;
  }

  &__table-lateral {
      width: 40px;
      background-color: $report-table-lateral;
      position: relative;
      float: right;
      height: 100%;

      &--table-row {
          position: relative;
          height: 40px;
          display: inline-block;
      }

      &--table-col-actions {
          position: relative;
      }
  }

  &__table-parent-row {
      overflow: hidden;
      position: relative;
      width: 100%;
  }
  //ICAM
  &__header-icam {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 5px 20px;
      padding: 15px 10px;

      &--details {
          background-color: $third-color-icam;
      }
  }

  &__filter-header-icam {
      display: flex;
      align-items: center;
      background-color: $third-color-icam;
      padding: 8px;
      position: absolute;
      top: 14px;
      left: 20px;
      cursor: pointer;

      & i.report-table__fheader-icon-icam {
          color: $primary-color-icam;
          font-size: 20px;
      }

      & span.report-table__fheader-text-icam {
          color: $custom-black-landing;
          font-family: MTTMilano-Bold, sans-serif;
          font-size: 13px;
          text-transform: uppercase;
          padding-left: 5px;
      }

      & i.report-table__fheader-close-icam {
          font-size: 10px;
          color: $primary-color-icam;
          padding-left: 10px;
      }
  }

  &__fheader-dropdown-icam {
      position: absolute;
      width: 100%;
      top: 36px;
      left: 0;
      padding: 0;
      margin: 0;
      border-radius: 0;
      border: 1px solid $primary-color-icam;
      box-shadow: $box-shadow-icam;
      cursor: default;
  }

  &__fdropdown-search-icam {
      display: flex;
      align-items: center;
      padding: 5px 0;
      margin: 0 5px;
      border-bottom: 1px solid $third-color-icam;

      & input {
          border: none;
          outline: none;
          font-family: MTTMilano-Bold, sans-serif;
          font-size: 12px;
          color: $primary-color-icam;
          padding: 0;
          width: calc(100% - 20px);
      }

      & i {
          font-size: 20px;
          color: $primary-color-icam;
      }
  }

  &__fdropdown-list-icam {
      padding: 5px;
      margin: 0;
  }

  &__fdropdown-item-icam {
      display: flex;
      align-items: center;
      justify-content: space-between;
      list-style: none;
      margin: 0;
      padding: 5px 5px 5px 10px;

      & span {
          color: $primary-color-icam;
          font-family: MTTMilano, sans-serif;
          font-size: 13px;
          width: 116px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
      }

      & i {
          color: $primary-color-icam;
          font-size: 16px;
      }

      &:hover {
          background-color: $hover-block-icam;
          cursor: pointer;
          & i {
              display: block !important;
          }
      }
  }

  &__header-center-icam {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 20px;
      color: $title-text-icam;
      line-height: 0;
  }

  &__header-right-icam {
      display: flex;
  }

  &__header-button-icam {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin-left: 35px;
      cursor: pointer;

      &--left {
          margin-left: 0;
      }

      &--details {
          color: $primary-color-icam;
      }
  }

  &__button-text-icam {
      font-family: MTTMilano, sans-serif;
      font-size: 16px;
      color: $custom-black-landing;

      &--right {
          padding-left: 5px;
      }

      &--details {
          color: $primary-color-icam;
          font-size: 11px;
          text-transform: uppercase;
      }
  }

  &__button-icon-icam {
      font-size: 18px;
      color: $primary-color-icam;
      padding-left: 5px;

      &--left {
          padding-left: 0;
      }
  }

  &__table-icam {
      margin: 0 20px;
      border: 1px solid $primary-color-icam;
      font-size: 12px;
  }

  &__theader-icam {
      font-family: MTTMilano, sans-serif;
      font-weight: bold;

      &--type-2 {
          display: flex;
      }

      & .report-table__tcell-icam {
          border-bottom: 1px solid $primary-color-icam;
      }
  }

  &__tsubheader-container-icam {
      width: 100%;
      overflow: auto;
  }

  &__theader-upper-icam {
      display: flex;
      height: 36px;
  }

  &__theader-lower-icam {
      display: flex;
      height: 36px;
      color: $primary-color-icam;
  }

  &__tbody-icam {
      font-family: Arial, sans-serif;
      color: $custom-black-landing;
  }

  &__tcell-icam-icon-open{
      display: none;
  }

  &__trow-icam {
      display: flex;
      height: 40px;

      &--hover {
          &:hover{
            background-color: $hover-block-icam;
            & .lf-icon-kebab-menu{
                display: none;
            }
            & .report-table__tcell-icam-icon-open{
                display: block;
            }
          }
      }

      &--last {
          & .report-table__tcell-icam {
              border-bottom: none;
          }
      }
  }

  &__tcell-icam {
      width: 100%;
      border-right: 1px solid $primary-color-icam;
      border-bottom: 1px solid $border-block-icam;
      display: flex;
      align-items: center;
      padding: 0 10px;
      line-height: 12px;

      &--first {
          min-width: 120px;
          max-width: 120px;
          text-transform: capitalize;
      }

      &--last {
          border-right: none;
      }

      &--year {
          background-color: $primary-color-icam;
          color: $snow-white;
          justify-content: space-between;
      }

      &--year-column {
          min-width: 85px;
      }

      &--totals-column {
          min-width: 125px;
          max-width: 125px;
      }

      &--header-upper {
          background-color: $hover-block-icam;
          color: $title-text-icam;
          text-transform: uppercase;
      }

      &--header-lower {
          align-items: flex-start;
      }

      &--subcells {
          justify-content: space-between;
          overflow: auto;
      }

      &--badge {
          background-color: $third-color-icam;
          font-weight: bold;
          padding: 4px 10px;
          border-radius: 10px;
          cursor: pointer;

          &:hover {
              background-color: $hover-block-icam;
          }
      }

      &--actions {
          display: flex;
          justify-content: center;
          background-color: $background-grey-disabled-icam;
          margin-left: 5px;
          border-bottom: none;
          max-width: 65px;
          &-header {
              border-bottom: none !important;
          }

          & i {
              font-size: 20px;
              color: $primary-color-icam;
              cursor: pointer;
          }
      }
  }

  &__filter-list-class{
      width: 100%;
  }

  &__tcell-icon-icam {
      cursor: pointer;
  }

  &__tsubcell-icam {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      line-height: 12px;
      color: $primary-color-icam;

      &--no-first {
          margin-left: 10px;
      }
  }

  &__dropdown-menu-icam {
      color: $primary-color-icam;
      border-color: $primary-color-icam;
      border-radius: 0;
      padding: 0;
      margin: 0;
      min-width: -webkit-fill-available;

      &--header-type-2 {
          min-width: 124px;
          max-width: 124px;
      }
  }

  &__dropdown-item-icam {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      height: 36px;

      &:hover {
          background-color: $hover-block-icam;
          cursor: pointer;
          & i {
              display: block;
          }
      }
  }

  &__dropdown-icon-icam {
      display: none;
      font-size: 16px;
  }

  &__calendar-dropdown-icam {
      top: 35px;
      min-height: 269px;
      width: 366px;
      padding: 0;
      margin: 0;
  }

  &__footer-icam {
      font-family: MTTMilano, sans-serif;
      font-weight: bold;
      font-size: 12px;
      color: $primary-color-icam;
      padding: 20px;
  }
  &__disabled-button-graph{
      opacity: 0.5;
      cursor: context-menu;
      &:hover{
          background: transparent ;
      }
  }
  &__disabled-button{
      opacity: 0.5;
      cursor: context-menu;
      color: $primary-color;
      background-color: transparent;

  }
  &__box-block{
      display: block;
      width: 100%;
  }
  &__residual-option{
      margin: 5px 0 0 19px;
  }
  &__col-text{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 5;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    &--more-info{
      display: flex;
    }
  }
  &__list-bullet{
    padding-left: 25px;
    margin: 0;
    line-height: 16px;
  }
  &__text-dependencies{
    font-weight: 700;
  }
  &__buttons-dependencies{
    color: #2E53D6;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: block;
    &:hover{
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}

.compliance{
  &__dark-blue{
      .report-table {
          &__rows-container {
              &:hover {
                  background: $background-color-states;
              }
          }
      }
  }
  &__icam-red{
      .report-table {
          border-width: 2px;
          border-color: $border-block-icam;
          border-top: none;
      }
  }
  &__gm-law{
      .report-table {
          border-width: 2px;
          border-color: $border-block-gm;
          border-top: none;
          &__table-body {
              height: fit-content;
          }
          &__table-body-header {
              color: $custom-black-landing;
          }
          &__table-header {
              border-bottom: 1px solid $primary-color-gm;
          }
          &__options {
              color: $primary-color-gm;
              background-color: $background-grey-disabled-gm;
          }
          &__ellipsis-icon {
              color: $primary-color-gm;
          }
          &__graph-button-line {
              background: $primary-color-gm;
          }
          &__rows-container {
              border-bottom: 1px solid $border-block-gm;
              &:hover {
                  background: $hover-block-gm;
              }
          }
          &__table-container {
              border: none;
          }
          &__menu-action {
              background-color: $background-grey-disabled-gm;
          }
      }
  }
  &__com-de{
    .report-table {
        border-width: 2px;
        border-color: $border-block-com-de;
        border-top: none;
        &__table-body {
            height: fit-content;
        }
        &__table-body-header {
            color: $custom-black-landing;
        }
        &__table-header {
            border-bottom: 1px solid $primary-color-com-de;
        }
        &__options {
            color: $primary-color-com-de;
            background-color: $background-grey-disabled-com-de;
        }
        &__ellipsis-icon {
            color: $primary-color-com-de;
        }
        &__graph-button-line {
            background: $primary-color-com-de;
        }
        &__rows-container {
            border-bottom: 1px solid $border-block-com-de;
            &:hover {
                background: $hover-block-com-de;
            }
        }
        &__table-container {
            border: 1px solid $primary-color-com-de;
        }
        &__menu-action {
            background-color: $background-grey-disabled-com-de;
        }
    }
}
&__com-fr{
  .report-table {
      border-width: 2px;
      border-color: $border-block-com-fr;
      border-top: none;
      &__table-body {
          height: fit-content;
      }
      &__table-body-header {
          color: $custom-black-landing;
      }
      &__table-header {
          border-bottom: 1px solid $primary-color-com-fr;
      }
      &__options {
          color: $primary-color-com-fr;
          background-color: $background-grey-disabled-com-fr;
      }
      &__ellipsis-icon {
          color: $primary-color-com-fr;
      }
      &__graph-button-line {
          background: $primary-color-com-fr;
      }
      &__rows-container {
          border-bottom: 1px solid $border-block-com-fr;
          &:hover {
              background: $hover-block-com-fr;
          }
      }
      &__table-container {
          border: 1px solid $primary-color-com-fr;
      }
      &__menu-action {
          background-color: $background-grey-disabled-com-fr;
      }
  }
}

}
