.concept-steps{
     &__icon-position{
          position: absolute;
          top: 6px;
          left: 6px;
          &--right{
               left: 8px;
          }
     }

     &__line-step{
          display: inline-block;
          margin: 0;
          border: none;
          border-top: 1px solid $wizard-line;
          width: calc(100% - 33px);
          position: relative;
          bottom: 1px;
          opacity: 1;
          max-width: 110px;
          margin-left: 4px;
     }

     &__steps-block{
          width: 100%;
          padding: 0;
          display: flex;
          overflow: hidden;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
     }

     &__step-icon-block{
          height: 24px;
          width: 24px;
          border-radius: 50%;
          position: relative;
          display: inline-block;
          &:hover{
            cursor: pointer;
          }
          &--active,
          &--disabled{
                &:hover{
                    cursor: default;
                }
          }
     }

     &__step-item{
          max-width: 140px;
          list-style: none;
          &--3-step{
               width: 30%;
          }
          &--last{
               min-width: 24px;
               max-width: 24px;
          }
     }

     &__steps-map{
          height: 96px;
          width: 10%;
          min-width: 60px;
          display: inline-block;
          overflow: hidden;
          position: absolute;
          bottom: 25px;
          right: 0;
     }


     &__steps-map-icon{
          width: 13px;
          height: 13px;
          display: inline-block;
          font-size: 11px;
          color: $subcontainer-border;
          margin-right: 2px;
          &--video{
               font-size: 15px;
               color: $primary-color;
               margin-right: 5px;
          }
          &:hover{
               cursor: pointer;
          }
          &--not-pointer{
               &:hover{
                    cursor: default;
               }
          }
     }


     &__number{
          position: relative;
          text-align: center;
          font-family: MTTMilano-Bold, sans-serif;
          top: 3px;
          margin: 0;
     }
    ////////////////////////////////////
    /////////////Custom/////////////////
    ////////////////////////////////////


    &__custom-steps-block{
          height: 94px;
          width: 100%;
          margin: 0px 0px 25px;
          padding: 0;
          background-color: $background-grey;
          display: flex;
          overflow: hidden;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          align-items: center;
    }

     &__custom-steps-map{
          position: absolute;
          top: 3px;
          right: 6px;
          list-style: none;
     }
     &__custom-actual-step-map{
          background: $custom-secondary-color;
          border-radius: 50%;
          height: 14px;
          width: 15px;
          color: $snow-white;
          font-family: Arial, sans-serif;
          font-size: 11px;
          font-weight: bold;
          text-align: center;
          float: left;
          position: relative;
          top: 3px;
          right: 2px;
     }
     &__custom-total-steps-map{
          color: $attachment-custom-grey;
          font-family: Arial, sans-serif;
          font-size: 11px;
          font-weight: bold;
          text-align: center;
     }

     ////////////////////////////////////
    /////////////fin Custom/////////////////
    ////////////////////////////////////
}
.compliance{
     &__dark-blue{
          .concept-steps{
               &__icon{
                    &--disabled{
                         cursor: auto;
                         opacity: 0.5;
                    }
               }
               &__step-icon-block{
                    background-color:$subcontainer-border;
                    color: $pillow-group;
                    &:hover{
                         background-color: $pillow-group;
                         color: $snow-white
                    }
                    &--active{
                         background-color: $primary-color;
                         color: $snow-white;
                         &:hover{
                              background-color: $primary-color;
                         }
                    }
               }
               &__steps-map-icon{
                    &--active{
                         color: $primary-color;
                    }
               }
          }
     }
     &__steel-blue,
     &__uk-steel-blue{
          .concept-steps{
               &__icon{
                    &--disabled{
                         cursor: auto;
                         opacity: 0.5;
                    }
               }
               &__step-icon-block{
                    background-color: $secondary-color-2;
                    color: $primary-color-2;
                    &:hover{
                         background-color: $third-color-2;
                    }

                    &--active{
                         background-color: $primary-color-2;
                         color: $snow-white;
                         &:hover{
                              background-color: $primary-color-2;
                         }
                    }
               }
               &__steps-map-icon{
                    color: $secondary-color-2;
                    &--active{
                         color: $border-bottom-2;
                    }
               }
          }
     }
     &__icam-red{
          .concept-steps{
               &__icon{
                    &--disabled{
                         cursor: auto;
                         color: $secondary-color-2;
                    }
               }
               &__line-step{
                    border-top-color: $border-block-icam;
               }
               &__step-icon-block{
                    background-color: $border-block-icam;
                    color: $custom-black-landing;
                         &:hover{
                              background-color: $third-color-icam;
                         }
                    &--active{
                         background-color:$custom-black-landing;
                         color: $snow-white;
                         &:hover{
                              background-color:$custom-black-landing;
                         }
                    }
               }
               &__steps-map-icon{
                    color: $border-block-icam;
               }
          }
     }
     &__gm-law{
          .concept-steps{
               &__icon{
                    &--disabled{
                         cursor: auto;
                         color: $secondary-color-2;
                    }
               }
               &__line-step{
                    border-top-color: $border-block-gm;
               }
               &__step-icon-block{
                    background-color: $border-block-gm;
                    color: $custom-black-landing;
                        &:hover{
                              background-color: $third-color-gm;
                        }
                    &--active{
                        background-color:$custom-black-landing;
                        color: $snow-white;
                        &:hover{
                              background-color:$custom-black-landing;
                        }
                    }
              }
               &__steps-map-icon{
                    color: $border-block-gm;
                    &--active{
                         color: $custom-black-landing;
                    }
               }
          }
     }
     &__com-de{
      .concept-steps{
           &__icon{
                &--disabled{
                     cursor: auto;
                     color: $secondary-color-2;
                }
           }
           &__line-step{
                border-top-color: $border-block-com-de;
           }
           &__step-icon-block{
                background-color: $border-block-com-de;
                color: $custom-black-landing;
                    &:hover{
                          background-color: $third-color-com-de;
                    }
                &--active{
                    background-color:$custom-black-landing;
                    color: $snow-white;
                    &:hover{
                          background-color:$custom-black-landing;
                    }
                }
          }
           &__steps-map-icon{
                color: $border-block-com-de;
                &--active{
                     color: $custom-black-landing;
                }
           }
      }
    }
    &__com-fr{
      .concept-steps{
        &__icon{
          &--disabled{
            cursor: auto;
            color: $secondary-color-2;
          }
        }
        &__line-step{
          border-top-color: $border-block-com-fr;
        }
        &__step-icon-block{
          background-color: $border-block-com-fr;
          color: $custom-black-landing;
          &:hover{
            background-color: $third-color-com-fr;
          }
          &--active{
            background-color:$custom-black-landing;
            color: $snow-white;
            &:hover{
              background-color:$custom-black-landing;
            }
          }
        }
        &__steps-map-icon{
          color: $border-block-com-fr;
          &--active{
            color: $custom-black-landing;
          }
        }
      }
    }
     &__landing-custom{
        .concept-steps{
          &__step-icon-block{
              color: $snow-white;
              &:hover{
                   background-color: $custom-black-landing !important;
                   color: $snow-white !important
              }
          }
        }
     }
}
