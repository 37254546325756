@import '../../../../assets/scss/colors';

.custom-reports {
  &__add-report-button{
      display: block;
      width: 100%;
      height: 37px;
      text-align: right;
      margin-bottom: 18px;
      position: relative;
  }

  &__block-container{
      height: auto;
  }

  &__body {
      width: 100%;
  }

  &__inside-body{
      width: 100%;
      padding: 10px 0px 0px;
  }

  &__table {
      height: auto;
      margin-bottom: 0px;
      padding: 18px 0px 0 0px;
      display: block;
      background-color: transparent;
  }

  &__table-body {
      height: 460px;
      display: block;
      width: 100%;
  }

  &__error {
      color: $red-1;
      text-align: center;
      font-size: 14px;
      margin-top: 10%;
  }

  &__pagination {
      text-align: center;
      margin: 40px auto 0 auto;
      height: 22px;
      display: block;
      width: 100%;
      position: relative;
  }
}

@media (max-width: 1366px) {
  .custom-reports {
      &__block-container {
          width: 90%;
          margin-left: 5%;
      }
  }
}
