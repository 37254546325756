@mixin button-step($color){
  color:  $color;
  &:hover{
       background:  $color;
  }
  &:after {
      display: none;
  }
}
@mixin group-title-block($color) {
  min-height: 40px;
  background-color: $color;
  padding-top: 6px;
}
@mixin menu-icon($color){
  font-size: 20px;
  color: $color;
  padding: 9px 0 0 4px;
}

@mixin subgroup-menu($color) {
  margin-top: -137px;
  border:$color;
  border-top-width: 2px;
  width: 300px;
  margin-left: -309px;
}

@mixin elements-repeat-list-field($firstColor, $secondColor){
  margin-right: 3px;
  margin-left: 3px;
  padding-left: 10px;
  &:hover {
      background-color: $firstColor;
      .concept-form__subgroup-menu-line-bottom-link {
          color: $secondColor;
      }
  }
}
@mixin title-form-subgroup($firstColor, $secondColor){
  color: $firstColor;
  font-family: Arial, sans-serif;
  font-weight: bold;
  border-bottom: 1px solid $secondColor;
  padding-bottom: 8px;
  margin: 17px 10px 10px;
}

@mixin concept-block-menu-icon($firstColor, $secondColor, $thirdColor){
  box-sizing: border-box;
  height: 39px;
  width: 30px;
  border: 1px solid $firstColor;
  background-color: $secondColor;
  box-shadow: 0 0 5px 0px $thirdColor;
  margin-bottom: 7px;
}
// @mixin button($firstColor, $secondColor){
//     color: $firstColor;
//     border-color: $firstColor;
//     &:after{
//         display: none;
//     }
//     &:hover:not([disabled]){
//         background-color:$firstColor;
//         color: $secondColor;
//     }
// }
@mixin menu-share($firstColor, $secondColor){
  background: $firstColor;
  // border-color: $background-grey-disabled-2;
  color: $secondColor;
  height: 40px;
}

.concept-form {
  position: relative;
  float: left;
  margin-top: 0px;

  &__block-states-process{
      display: block;
      padding: 0;
      width: calc(100% - 40px);
      margin-left: 20px;
      position: relative;
      float: left;
  }

  &__block-form-concept{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__button-step{
      width: 78px;
      border: none;
      background: none;
      height: 40px;
      outline: none;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
      padding-top: 6px;
      color:  $subcontainer-ground;
      &:hover{
           background:  $subcontainer-ground;
           color: $snow-white;
      }

      &:after {
          content: " ";
          border-bottom: 3px solid $subcontainer-ground;
          width: calc(100% + 12px);
          display: block;
          right: 6px;
          top: 3px;
          position: relative;
      }
  }
 &__button-step-text{
      display: inline-block;
      position: relative;
      bottom: 9px;
      margin: 0;
      padding-left: 5px;
 }

  &__block-icons {
      position: absolute;
      left: 0px;
      top: 4px;
      padding: 1%;
      &--multiple{
          position: relative;
          float: left;
          top: -1px;
      }
  }
  &__without-border{
      border:none;
  }
  &__block-list-field {
      position: relative;
      &--list{
          padding-left: 10px;
      }
      // min-height: 35px;
  }
  &__subcontainer-icon{
      font-size: 8px;
      opacity: 0.5;
      color: $primary-color;
  }

  &__block-subcontainer {
      padding: 0;
      background-color: $snow-white;
      margin-bottom: 23px;
      display: table;
      width: 100%;
      &--no-border{
          background: none;
      }
      &--custom{
          padding: 0 10px;
          margin-top: 11px;
      }
      &--no-border-custom{
          background: none;
          margin: 0;
          padding-top: 10px;
      }

      &:last-child {
          margin-bottom: 0;
      }
  }

  &__subcontainer{
      display: table-cell;
      max-width: 0;
  }

  &__button-line-generate {
      height: 3px;
      width: 280px;
      background-color: $primary-color;
      margin-top: 1px;
  }

  &__circle-block-icon{
      width: 27px;
      height: 27px;
      background-color: $subcontainer-ground;
      border-radius: 50%;
      color: $snow-white;
      display: inline-block;
      position: relative;
  }

  &__clear-button {
      color:  $subcontainer-ground;
      border: 3px solid  $subcontainer-ground;
      padding-top: 6px;
  }

  &__close-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 10px;
      color: $primary-color;
      z-index: 10;
      &:hover {
          cursor: pointer;
      }
  }

  &__circle-memento {
      font-size: 17px;
      vertical-align: sub;
      &--documentation {
          margin-right: 2px;
      }
  }

  &__concept-title-name {
      height: 20px;
      width: 100%;
      color:  $subcontainer-ground;
      font-size: 13px;
      text-align: center;
      margin-top: 35px;
      margin-bottom: 19px;
  }

  &__container-fields {
      position: relative;
      width: 100%;
      height: 100%;
      display: block;
      border: 1px solid $subcontainer-border;
      margin-bottom: 9px;
      &--edit-concept {
          border: none;
      }
      &--edit-process {
          border: none;
          margin-left: 5px;
      }
      &--custom{
          min-height: 185px;
          width: 100%;
          border-radius: 4px;
          background-color: $snow-white;
          box-shadow: 0 0 20px 4px $attachment-custom-grey-opacity;
          border-color: transparent;
          padding: 10px;
          padding: 8px 8px;
      }

  }

  &__container-links-block {
      width: 100%;
      margin: 0 auto;
      display: inline-block;
      overflow: hidden;
  }

  &__delete-content-icon {
      display: none;
      font-size: 10px;
      color: $primary-color;
      padding-right: 7px;
      float: right;
      margin-top: -16px;
  }

  &__fields-block {
      display: block;
      width: calc(65% - 38px);
      float: left;
      background: $snow-white;
      padding: 0 14px 12px;
      &--edit-concept {
          padding-top: 70px;
          margin-top: 0;
          padding-left: 13px;
      }
      &--has-process{
          margin-top: 0;
      }
      &--big-window{
          padding: 0 10px 12px;
      }
  }

  &__field-title-text {
      padding: 0;
      margin-top: 3%;
  }

  &__form-concept {
      padding: 17px 9px 15px;
      background-color: $snow-white;
      &--custom{
          padding: 0;
          background-color: transparent;
          position: relative;
          width: 100%;
      }
  }

  &__form-subcontainer-concept {
      padding: 20px 0px 0px;
      &--subgroup {
          padding: 0;
          margin: 0 10px;
      }
      &--subgroup-custom {
          padding: 0;
          margin: 0;
      }
      &--subgroup-first{
          padding-top: 21px;
      }
      &--subgroup-hide{
          padding: 0;
      }
      &--multiple {
          background: transparent;
      }
  }
  &__subgroup{
      margin-left: 1%;
      width: 98%;
      height: auto;
      background-color: $snow-white;
      padding-right: 0;
      padding-bottom: 1px;
  }
  &__subgroup-steps{
      margin-left: 1%;
      width: 98%;
      height: auto;
      background-color: $snow-white;
      padding-bottom: 1px;
      &:last-child {
          padding-bottom: 10px;
          background-color: $snow-white;
      }
  }

   &__container-subfields{
        border: 1px solid $subcontainer-border;
        margin-bottom: 20px;
        &--display{
            border:none;
        }
    }
  &__generate-button {
      color: $primary-color;
      border: 3px solid $primary-color;
      width: 280px;

      &:hover {
          background-color: $primary-color;
          color: $snow-white;
      }
  }

  &__group-title-block {
      width: 100%;
      color: $snow-white;
      font-size: 12px;
      text-align: center;
      font-family: MTTMilano, sans-serif;
      text-transform: uppercase;
      position: relative;
      padding: 9px 83px 9px 100px;
      &:hover{
          .concept-form__menu-share{
              display: block;
          }
     }
  }

  &__text-title-group{
      position: relative;
      top: 5px;
      &--subgroup{
          top: 0px;
      }
      &--custom{
          top: 0px;
      }
      &--highlight{
          top: inherit;
          bottom: 3px;
          position: relative;
          & > p{
            display: inline;
          }
      }
      &--custom-highlight{
          top: inherit;
          bottom: 2px;
          position: relative;
      }

  }

  &__icon-arrow {
      margin-right: 4px;
      font-size: 23px;
      position: relative;
      top: 2px;
  }

  &__icon-edit {
      margin-left: 2%;
      color: $primary-color;
  }

  &__icon-group {
      font-size: 17px;
      padding-right: 3px;
      text-align: left;

      &:hover {
          cursor: pointer;
      }
  }

  &__icon-link {
      float: right;
      transform: rotate(46deg);
      margin-right: 6px;
      margin-top: 7px;
      color: $primary-color;
      opacity: 0.5;
  }

  &__icon-list-field {
      margin-top: 8px;
      font-size: 10px;
      height: 17px;
      width: 26px;
      background-color: $primary-color;
      color: $snow-white;
      margin-right: 10px;
  }

  &__icon-memento {
      color: $snow-white;
      margin-left: -16.5px;
      margin-right: 15px;
      font-style: inherit;
      font-size: 13px;
  }

  &__icon-subgroup {
      color: $snow-white;
      font-size: 17px;
      padding-right: 3px;
      &--modified{
          position: relative;
          top: 11px;
          margin-right: 5px;
      }
      &--laws{
          position: relative;
          bottom: 2px;
      }

      &--users {
          font-size: 25px;
          float: left;
          margin-right: 2px;
          color: $concept-other-grey;
          position: relative;
          bottom: 5px;

          &:hover {
              cursor: default;
          }
      }

      &:hover {
          cursor: pointer;
      }
  }

  &__icon-users {
      font-size: 19px;
      float: left;
      margin-right: 2px;
      color: $concept-other-grey;
  }

  &__input-hover {
      &:hover {
          background-color: $concept-grey;
          border: 1px solid $primary-color;

          & .common__input-textarea {
              background: $concept-grey;
          }

          & .common__tooltip-info-box {
              display: block;
          }

          & .common__tooltip-law, .common__info-icon-block, .common__tooltip-law-block {
              visibility: visible;
          }
      }
  }

  &__input-memento {
      display: inline-block;
      width: 71%;

      &--list {
          height: 49px;
      }
  }

  &__input-text {
      width: 250px;
  }

  &__landing-page {
      padding: 0 25px 0 9px;
      width: 100%;
      position: relative;
      &--custom{
          padding: 0;
          display: block;
      }
  }

  &__landing-page-custom{
      width: 100%;
      position: relative;
      padding: 0;
      display: block;
  }

  &__law-blocks {
      padding-left: 10px;
      list-style: none;
      margin-bottom: 0;
  }

  &__law-item {
      display: block;
      width: 100%;
      overflow: hidden;
  }

  &__law-icon {
      float: left;
      font-size: 16px;
      color: $primary-color;
      padding-right: 6px;
      &:hover {
          cursor: pointer;
      }
  }

  &__law-text {
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      margin: 1px 0 0;
      text-decoration: underline;
      &:hover {
          cursor: pointer;
      }
  }

  &__line {
      border: 0.5px solid $primary-color;
      margin-left: 14px;
  }

  &__line-bottom-block {
      position: absolute;
      // width: 103%;
      width: calc(100% - 10px);
      height: 1px;
      background-color: $primary-color;
      visibility: hidden;
      // margin-left: -19px;
      &--steps{
          margin-left: -19px;
          width: calc(100% + 9px);
      }
  }

  &__line-right {
      position: absolute;
      margin-left: 66%;
      margin-top: 6px;
  }

  &__line-left {
      position: absolute;
      margin-left: 13%;
      margin-top: 6px;
  }

  &__link-block {
      width: 100%;
      border: none;
      margin-top: 0;
      margin-bottom: 0;
      min-height: 0;
  }

  &__memento-block {
      width: 29%;
      float: right;
      margin-top: 20px;
      &--documentation {
          margin: 0;
          background: transparent;
      }

      &--coin {
          margin: 0;
          position: relative;
      }
  }

  &__memento-text {
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 13px;
      padding: 0;
      margin: 0;
      margin-top: 27px;
      margin-left: 4px;
      &--documentation {
          margin: 0;
      }
      &--documentation-link {
          margin-top: 9px;
      }
  }

  &__more-options-multiple{
    display: inline;
  }

  &__select-list {
      height: 100px;
  }

  &__subcontainer-block {
      height: 40px;
      position: relative;
      margin-bottom: 8px;
      &:hover{
        & .concept-form__submenu-share{
            display: block;
        }
      }
  }

  &__subcontainer-icons-block {
      position: absolute;
      top: 0px;
      right: 0px;
  }

  &__subcontainer-text {
      color: $snow-white;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      text-align: center;
      text-transform: uppercase;
      position: relative;
      padding-top: 12px;
      margin: 0 auto;
      width: 80%;
      &--poll {
          margin-bottom: 14px;
      }

      &--ellipsis{
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
      }

      &--highlight{
          padding-top: 5px;
      }

      &--subcontainer-highlight{
          margin-bottom: 14px;
          padding-top: 6px;
      }
  }

  &__title-evaluated {
      border: 1px solid $primary-color;
      height: 107px;
  }

  &__title-fields {
      position: relative;
  }

  &__title-text {
      font-size: 20px;
      color: $primary-color;
      height: 19px;
      margin-top: 27px;
      margin-bottom: -8px;
      margin-left: 14px;
  }

  &__title-center {
      position: absolute;
      margin-left: 32%;
      width: 35%;
  }

  &__underline {
      background-color: $custom-black-landing;
      height: 1px;
      margin: 0;
      width: 62px;
      margin-left: 47%;
      &--title {
          width: 50%;
      }
      &--subgroup {
          margin-top: 20px;
      }
  }

  &__documentation-list {
      cursor: pointer;
      background: transparent;
  }

  &__date-picker {
      position: relative;
  }

  &__icon-calendar {
      position: absolute;
      margin-top: -46px;
      color: $primary-color;
      background-color: transparent;
      border: none;
      margin-left: 95%;
  }

  &__calendar-block {
      position: relative;
  }

  &__calendar-block {
      &:hover {
          background-color: $concept-grey;
      }
  }

  &__radio-button-block {
      display: table-cell;
      margin-right: 31px;
  }

  &__radio-button {
      display: inline-table;
      margin-left: 5px;
      margin-right: 2px;
      margin-top: 15px;
      &--left {
          margin-left: 15%;
      }
  }

  &__radio-text {
      height: 23px;
      width: 200px;
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 14px;
      overflow: hidden;
      padding: 1px 0 0 10px;
  }

  &__radio-button-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      margin-top: 23px;
      margin-left: 10px;
  }

  &__radio-button-li {
      float: left;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: 500;
      margin-left: 5px;
  }

  &__radio-button-icon {
      margin-right: 4px;
      color: $primary-color;
      &--white {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 1px solid $primary-color;
          color: transparent;
      }
  }

  &__button-line-create {
      height: 3px;
      width: 200px;
      background-color: $primary-color;
      margin-top: 0px;
  }

  &__button-line {
      height: 3px;
      width: 170px;
      background-color:  $subcontainer-ground;
      margin-top: 1px;
  }

  &__clear {
      display: inline-block;
  }

  &__input-file {
      position: relative;
  }

  &__file-up {
      height: 0;
      width: 0;
      opacity: 0;
      visibility: hidden;
  }

  &__img-logo {
      position: relative;
      margin-top: 25px;
  }

  &__name-img-block {
      width: 100%;
      height: 24px;
      float: left;
      position: relative;
      margin-left: 10px;
      color: $primary-color;
      font-size: 10px;

      &:hover {
          .concept-form__buttons-file {
              visibility: visible;
          }
      }
  }

  &__name-img {
      margin-left: -8px;
      margin-bottom: 0px;
      position: relative;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: 500;
      background: transparent;
      width: auto;
      display: inline-block;
  }

  &__buttons-file {
      border: none;
      background: none;
      padding: 0;
      margin: 0 4px;
      outline: 0;
      visibility: hidden;
  }

  &__icon-directions {
      float: right;
      font-size: 20px;
      position: absolute;
      bottom: 7px;
      right: 10px;
      color: $primary-color;
      display: none;
      &--subcontainer{
          // bottom: 15px;
          bottom: -3px;
      }
  }

  &__icon-megapicker {
      float: right;
      margin-right: 6px;
      margin-top: 7px;
      color: $primary-color;
  }

  &__icon-video {
      position: absolute;
      margin-top: 28px;
      margin-left: 10px;
      color: $primary-color;
      opacity: 0.3;
  }

  &__margin-input {
      margin-left: 18px;
  }

  &__concept-properties {
      background-color: transparent;
      width: 35%;
      float: right;
      position: relative;
      padding-right: 10px;
  }

  &__concept-box-right{
      @extend .concept-form__concept-properties;
  }

  &__hide-container {
      display: none;
  }

  &__menu-icon {
      background-color: transparent;
      border: none;
      outline: none;
      &[disabled] {
          opacity: 0.5;
      }
  }

  &__subgroup-menu {
      position: relative;
      width: 342px;
      // padding-top: 20px;
      margin-left: -351px;
      margin-top: -150px;
      border: 1px solid;
      background-color: $snow-white;
      box-shadow: 0 0 5px 1px $certify-black-opacity02;
      z-index: 10;
      &--without-permission {
          margin-top: -64px;
      }
  }

  &__subgroup-menu-block {
      overflow-y: auto;
      max-height: 330px;
      // padding-bottom: 5px;
      // bottom: 10px;
      position: relative;
      margin-bottom: 5px;
  }

  &__subgroup-menu-element-list {
      min-height: 25px;
      width: calc(100% - 30px);
      padding: 10px 0;
  }

  &__subgroup-menu-line-bottom-link {
      color: $secondary-color;
      &:hover{
          cursor: pointer;
      }
      &:link {
          text-decoration: none;
      }
      &--disabled {
          cursor: auto;
      }
  }

  &__subgroup-menu-list {
      margin: 0px;
      padding: 0px;
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: bold;
      padding-top: 10px;
      padding-bottom: 5px;
      list-style-type: none;
      &--steps{
          list-style-type: decimal;
      }
  }

  &__elements-repeat-list-field{
      padding-left: 15px;
      &:hover {
          .concept-form__icon-directions {
              display: inline;
          }
      }
      &--steps{
          padding-left: 32px;
      }
  }

   &__title-form-subgroup{
      text-align: center;
      text-transform: uppercase;
      font-size: 10px;
      font-family: MTTMilano-Bold, sans-serif;
      color: $primary-color;
      margin-bottom: 35px;
      margin-top: 17px;
   }

  &__tooltip-info {
      position: absolute;
      background-color: $background-grey;
      border-top: 4px solid $primary-color;
      z-index: 10;
      top: 50px;
      left: 0px;
      padding-left: 10px;
      padding-right: 10px;
      min-width: 100px;
      display: block;
      text-align: left;
      font-family: Arial, sans-serif;
      font-size: 11px;

      &:after {
          content: '';
          border-top: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid $primary-color;
          border-left: 5px solid transparent;
          vertical-align: middle;
          position: absolute;
          left: 14px;
          top: -14px;
      }

      &--subgroup {
          top: 46px;
      }
  }

  &__tooltip-inner-text {
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 11px;
      text-transform: none;
      margin: 7px 0;

      &:hover {
          cursor: pointer;
      }
  }

  &__position-top {
      position: fixed;
      width: 100%;
      top: 0;
  }

  &__concept-block-menu-icon {
      position: relative;
      &:first-child{
          margin-top: 7px;
      }
  }

  &__duplicate-subcontainer-block {
      box-shadow: 0 0 10px 1px $bar-horizontal-grey-opacity02;
      position: absolute;
      z-index: 1001;
      background: $snow-white;
      height: 165px;
      width: 174px;
      border: 1px solid $primary-color;
      background-color: $snow-white;
      right: 0;
  }

  &__close-duplicate-subcontainer {
      font-size: 11px;
      width: 100%;
      text-align: right;
      display: block;
      color: $primary-color;
      padding: 6px 6px 20px 0;
      &:hover {
          cursor: pointer;
      }
  }

  &__duplicate-subcontainer-title {
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      margin: 0;
      text-transform: uppercase;
      text-align: center;
  }

  &__copys-block {
      height: 51px;
      width: 151px;
      background-color: $primary-color;
      margin: 0 12px 0 11px;
      padding-right: 11px;
      padding-left: 11px;
      padding-top: 20px;
      position: relative;
  }

  &__copy-text {
      color: $snow-white;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      padding-right: 14px;
      float: left;
      margin: 0;
  }

  &__copy-number {
      float: left;
      color: $snow-white;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 12px;
      padding-top: 2px;
      padding-right: 4px;
  }

  &__copy-bind {
      margin-left: -3px;
  }

  &__number-copy-button {
      height: 0;
      width: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      background: none;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 29px;
      outline: none;
      &--top {
          top: 15px;
          border-bottom: 6px solid $snow-white;
      }
      &--bottom {
          top: 29px;
          border-top: 6px solid $snow-white;
      }
  }

  &__formula{
      width: auto;
      font-size: 18px !important;
  }

  &__block-poll{
      position: relative;
      width: 100%;
      height: 638px;
      &--custom{
          width: 100%;
          position: relative;
          min-height: 638px;
      }
      &--custom-init{
          margin-bottom: 165px;
      }
  }

  &__block-front-poll{
      position: relative;
      width: 100%;
      height: 638px;
  }

  &__block-icon-questionnaire{
      width: 150px;
      height: 150px;
      position: absolute;
      border-radius: 100px;
      background-color: $background-grey-other;
      left: 31px;
      top: -33px;
      text-align: center;
      z-index: 1;
  }

  &__icon-questionnaire{
      position: relative;
      top: 30px;
      right: 4px;
      font-size: 85px;
      color: $primary-color;
      opacity: 0.1;
  }

  &__block-title-questionnaire{
      position: relative;
      // float: left;
      top: 174px;
      height: 464px;
      width: 100%;
      background-image: url("../../../../../assets/img/encuesta-dosificada.svg");
  }

  &__block-information-questionnaire{
      position: relative;
      max-width: 770px;
      margin: 0 auto;
      &--custom{
          max-width: 100%;
      }
  }

  &__title-poll{
      font-size: 38px;
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
  }

  &__description-poll{
      font-size: 22px;
      color: $concept-blue;
      font-family: MTTMilano, sans-serif;
      margin: 0;
  }

  &__button-start{
      position: absolute;
      right: 60px;
      bottom: 50px;
  }

  &__block-fields-poll{
      max-width: 800px;
      margin: 0 auto;
      position: relative;
      height: 521px;
  }

  &__block-fields-element-poll{
      position: relative;
      width: 100%;
      top: 160px;
      &--list-extended{
          top: 100px;
      }
  }

  &__block-title-group-poll{
      color: $custom-black-landing;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 18px;
      padding: 0px 80px 0 80px;
      max-width: 1400px;
      margin: 15px auto 0;
      &--subgroup{
          margin-top: 19px;
      }
  }

  &__icon-angle-separator{
      position: relative;
      font-size: 15px;
      top: 3px;
  }

  &__name-group-poll{
      display: inline-block;
      height: 21px;
      &--subgroup{
          height: 18px;
      }
  }

  &__number-group-poll{
      color: $custom-black-landing;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 18px;
  }

  &__is-hightlight{
      border-bottom: 4px solid $highlight-border;
  }
  &__buttons-steps-block{
    display: flex;
    justify-content: space-between;
    float: left;
    margin-top: 20px;
    margin-bottom: 12px;
  }
  ////////////////////////////
  //////////Custom///////////
  //////////////////////////
  &__buttons-block{
      margin: 40px 0 20px;
  }
  &__buttons-block-option{
      display: inline-block;
  }

  &__button-landing-custom-start{
      width: 240px;
  }
  &__custom-clear-button{
      background-color: transparent;
      border: none;
      text-decoration: underline;
      font-size: 14px;
      color: $custom-secondary-color;
      margin-top: 7px;
      margin-right: 8px;
  }

  &__custom-step-button{
      background: none;
      border: none;
      outline: none;
      color: $custom-black-landing;
      font-family: Arial, sans-serif;
      font-size: 13px;
      &--left{
          margin-right: 21px;
      }
  }
  &__custom-step-button-icon{
      position: relative;
      font-size: 20px;
      top: 4px;
      border-radius: 20px;
      &--prev{
          right: 5px;
      }
  }
  &__custom-step-button-text{
      padding-right: 5px;
  }

  &__group-title-block-custom {
      width: 100%;
      text-transform: uppercase;
      position: relative;
      padding: 12px 15px 12px 15px;
      color: $snow-white;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 16px;
      border-radius: 4px;
      background-color: $custom-secondary-color;
      min-height: 52px;
      &--subcontainer-multiple{
          padding-right: 0;
      }
  }

  &__subgroup-title-block-custom {
      width: 100%;
      text-transform: uppercase;
      position: relative;
      padding: 12px 15px 12px 15px;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 13px;
      border-radius: 4px;
      background-color: $custom-border-grey;
      min-height: 49px;
      color: $attachment-custom-grey;
      border: none;
      &--subcontainer-multiple{
          padding-right: 0;
      }
  }

  &__block-icon-highlight-custom{
      height: 28px;
      width: 28px;
      display: inline-block;
      border-radius: 15px;
      padding: 5px 0 0;
      text-align: center;
  }

  &__icon-arrow-custom{
      font-size: 18px;
  }

  &__block-icons-custom {
      position: relative;
      float: left;
      top: 2.5px;
      padding: 0 5px;
      height: 23px;
      &--subcontainer{
          top: 1px;
      }
  }

  &__container-text-custom{
      position: relative;
      margin: 0;
      text-transform: uppercase;
      &--no-highlight{
          top: 4px;
      }
  }

  &__cell-block-custom{
      position: relative;
  }
  &__custom-poll-block{
      width: 940px;
      margin: 0 auto;
      float: none;
  }
  &__custom-applicant-profile{
      // width: auto;
      width: 100%;
      max-width: 904px;
      padding: 0;
      margin: 0 auto;
      float: none;
  }
  &__custom-block-title-questionnaire{
      background: none;
      top: 0;
      width: 100%;
      display: block;
  }
  &__custom-description-poll{
      color: $custom-black-landing;
      font-family: MTTMilano, sans-serif;
      font-size: 18px;
      width: 700px;
      margin: 40px auto 30px;
      display: block;
      line-height: 27px;
      text-align: center;
  }
  &__custom-poll-img{
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
  }
  &__custom-block-buttom{
      display: block;
      width: 100%;
      overflow: hidden;
      text-align: center;
      margin-top: 17px;
  }
  &__custom-button-start{
      max-width: 1032px;
      margin: 50px auto 20px;
      text-align: right;
      display: block;
      overflow: hidden;
  }
  &__custom-block-fields-element-poll{
      width: 100%;
      display: block;
      margin-top: 44px;
  }
  &__custom-block-fields-poll{
      max-width: 863px;
      margin: 0 auto;
      position: relative;
      min-height: 490px;
  }
  &__block-progress-poll-custom{
      width: 323px;
      height: 323px;
      position: relative;
      border: 1px solid $custom-black-landing;
      border-radius: 162px;
      text-align: center;
      text-transform: uppercase;
      font-family: MTTMilano-Bold, sans-serif;
      padding: 56px 0px;
      margin: 65px auto 25px;
  }
  &__text-poll-custom{
      font-size: 48px;
      height: 47px;
      display: block;
      line-height: 1;
  }
  &__text-poll-completed-custom{
      font-size: 40px;
  }
  &__progress-poll-progress-custom{
      font-size: 110px;
      height: 102px;
      display: block;
      line-height: 1;
  }

  &__container-border-custom{
      box-shadow: 0 4px 20px 0 $attachment-custom-grey-opacity;
      display: table-cell;
      max-width: 0;
      border-radius: 4px;
  }

  &__container-border-custom-hide{
      margin-bottom: -15px;
      display: table-cell;
      max-width: 0;
  }

  &__container-group-fields-custom{
      width: 664px;
      padding: 15px 0px 5px;
      margin: 0 auto;
      position: relative;
  }

  &__container-subgroup-fields-custom{
      padding: 15px 100px 10px;
      &--hide{
          padding: 0 100px;
      }
  }

  &__custom-block-expiration-date-poll{
      background: none;
      border: none;
      margin: 0 auto;
      float: right;
      color: $custom-black-landing;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      display: inline-block;
      position: relative;
      width: auto;
  }

  &__icon-date-poll-custom{
      font-size: 18px;
      position: relative;
      margin-right: 8px;
  }
  &__text-date-poll-custom {
      font-size: 10px;
      text-transform: uppercase;
      font-family: Arial, sans-serif;
      font-weight: bold;
      position: relative;
      bottom: 3px;
  }
  &__icon-subgroup-custom {
      color: $attachment-custom-grey;
      font-size: 17px;
      padding-right: 3px;
      &--laws{
          position: relative;
          bottom: 4px;
      }

      &--users {
          font-size: 25px;
          float: left;
          margin-right: 2px;
          color: $concept-other-grey;
          position: relative;
          bottom: 5px;

          &:hover {
              cursor: default;
          }
      }

      &:hover {
          cursor: pointer;
      }
  }

  &__form-subcontainer-concept-custom {
      padding: 20px 0px 0px;
      &--subgroup {
          padding: 0;
          margin: 0;
      }
      &--subgroup-first{
          padding-top: 21px;
      }
      &--subgroup-hide{
          padding: 0;
      }
      &--multiple {
          background: transparent;
      }
  }

  &__icon-share-menu{
      font-size: 30px;
      position: absolute;
      right: 0;
      top: 6px;
      color: $snow-white;
  }
  &__icon-share-menu-multiple{
      font-size: 30px;
      top: 5px;
      position: relative;
      color: $snow-white;
  }
  &__menu-share{
      height: 45px;
      width: 51px;
      background: $background-grey-other;
      color: $primary-color;
      border: 0.5px solid $primary-color;
      border-top-left-radius: 67px;
      border-bottom-left-radius: 67px;
      z-index: 1;
      position: absolute;
      display: none;
      right: 0;
      top: 0;
  }
  &__submenu-share{
      height: 40px;
      width: 51px;
      background: $background-grey-other;
      color: $primary-color;
      border: 0.5px solid $pillow-group;
      border-top-left-radius: 67px;
      border-bottom-left-radius: 67px;
      z-index: 1;
      position: absolute;
      display: none;
      right: 0;
      top: 0;
  }
  &__icon-share{
      font-size: 20px;
      position: relative;
      top: 11px;
      margin-left: 11px;
      &:hover{
          cursor: pointer;
      }
  }
  &__icon-share-submenu{
      font-size: 20px;
      position: relative;
      top: 9px;
      margin-left: 16px;
      &:hover{
          cursor: pointer;
      }
  }

  &__block-button-lock{
      display: inline-block;
  }

  &__icon-down-button{
      width: 40px;
      height: 35px;
      position: absolute;
      border-left: 3px solid $red;
      font-size: 20px;
      padding-top: 8px;
      right: 0;
      top: 0;
  }

  &__list-menu-block {
      left: calc(100% - 43px);
      top: -2px;
      width: 218px;
      border: 1px solid $red;
      background-color: $snow-white;
      position: absolute;
      padding: 0;
      z-index: 2;
      border-radius: 0px;
      float: none;
  }

  &__icons{
      position: relative;
      width: 18px;
      font-size: 18px;
      height: 18px;
      float: left;
      // border: none;
      // background-color: transparent;
      // outline: none;
      margin: 0 4px 0 0;
      // padding: 0;
      &:hover{
        cursor: pointer;
      }
      &--info-subcontainer{
          color: $snow-white;
      }
  }
}

.compliance{
  &__dark-blue{
      .concept-form {
          &__block-states-process{
              padding: 12px 14px 12px;
              width: 100%;
              margin: 0;
          }
          &__button-step{
              &--active{
                  color: $primary-color;
                  &:hover{
                      background: $primary-color;
                      color: $snow-white;
                  }
                  &:after {
                      border-bottom-color: $primary-color;
                  }
              }
          }
          &__fields-block {
            &--concept{
              margin-top: 35px;
            }
          }
          &__subcontainer{
              &--with-border{
                  background: none;
                  border: 1px solid $subcontainer-border;
              }
          }
          &__circle-block-icon{
              &--active{
                  background-color: $primary-color;
              }
          }
          &__group-title-block {
              min-height: 45px;
              background-color: $primary-color;
          }
          &__icon-arrow {
              color: $highlight-icon;
          }
          &__subcontainer-block {
           background-color: $pillow-group;
          }
          &__concept-box-right {
            padding-left: 14px;
          }
          &__menu-icon {
              font-size: 25px;
              color: $primary-color;
              padding: 0 0 0 6px;
              &--bookmark {
                  font-size: 32px;
                  padding-left: 3px;
              }
          }
          &__subgroup-menu {
              border-color: $concept-from-grey;
          }
          &__elements-repeat-list-field{
              &:hover {
                  .concept-form__line-bottom-block {
                      visibility: visible;
                      opacity: 0.2;
                  }

                  .concept-form__subgroup-menu-line-bottom-link {
                      color: $primary-color;
                  }
              }
          }
          &__tooltip-info {
              background-color: $background-color-states;
          }
          &__concept-block-menu-icon {
              margin-bottom: 20px;
          }
          &__button-red-selected{
              background-color: $red;
              color: $snow-white;
          }
          &__block-button-option{
              &:hover{
                  background-color: $background-error;
              }
              &--disabled{
                  &:hover{
                      background-color: transparent;
                  }
              }
          }
          &__text-button-block{
              border-color: $red;
              color: $red;
              &--disabled{
                  color: $subcontainer-ground;
              }
          }
      }
  }
  &__steel-blue,
  &__uk-steel-blue{
      .concept-form {
          &__button-step{
              @include button-step($grey-icons-2);
              &:hover{
                color: $snow-white;
              }
              &--active{
                  color: $border-hover-2;
                  &:hover{
                      background: $border-hover-2;
                      color: $snow-white;
                  }
                  &:after {
                      display: none;
                  }
              }
          }
          &__subcontainer{
              &--with-border{
                  background-color: $background-grey-icam;
                  border: 1px solid $secondary-color-2;
              }
          }
          &__circle-block-icon{
              background-color:  $grey-icons-2;
              &--active{
                background-color: $border-hover-2;
            }
          }


          &__close-icon {
              color: $border-hover-2;
          }
          &__container-fields {
              box-shadow: 0 0 5px -1px $box-shadow-box-2;
              &--edit-concept {
                  border: none;
              }
          }
          &__fields-block {
              &--concept{
                  margin-top: 48px;
                  padding: 0 7px 12px 20px;
              }
          }
          &__group-title-block {
              @include group-title-block($primary-color-2);
          }
          &__icon-arrow {
              color: $third-color-2;
          }
          &__subcontainer-block {
              background-color: $grey-icons-2;
          }
          &__icon-directions {
              color: $border-hover-2;
              bottom: 8px;
          }
          &__concept-box-right {
              margin-top: 14px;
              padding-right: 20px;
          }
          &__menu-icon {
              @include menu-icon($border-hover-2);
          }
          &__subgroup-menu {
              @include subgroup-menu($third-color-2);
          }
          &__subgroup-menu-block {
              max-height: 175px;
          }
          &__subgroup-menu-element-list {
              padding: 9px 0;
          }
          &__subgroup-menu-line-bottom-link {
              color: $border-hover-2;
          }
          &__subgroup-menu-list {
              font-family: MTTMilano, sans-serif;
              font-size: 13px;
              font-weight: 500;
          }
          &__elements-repeat-list-field{
              @include elements-repeat-list-field($third-color-rgba-mid, $border-hover-2)
          }
          &__title-form-subgroup{
              @include title-form-subgroup($primary-color-2, $third-color-2)
          }
          &__concept-block-menu-icon {
              @include concept-block-menu-icon($secondary-color-2, $third-color-2, $box-shadow-box-2)
          }
          &__close-duplicate-subcontainer {
              color: $primary-color-2;
          }
          &__duplicate-subcontainer-title {
              color: $primary-color-2;
          }
          &__copys-block {
              background-color: $primary-color-2;
          }
          &__menu-share,
          &__submenu-share{
              @include menu-share($background-grey-disabled-2, $primary-color-2)
          }
          &__icon-share{
              top: 9px;
          }
          &__icon-down-button{
              border-color:  $alert-color-button-2;
          }
          &__button-red-selected{
              background-color: $alert-color-button-2;
              color: $snow-white;
          }
          &__block-button-option{
              &:hover{
                  background-color: $background-other-error-2;
              }
              &--disabled{
                  &:hover{
                      background-color: transparent;
                  }
              }
          }
          &__text-button-block{
              border-color: $alert-color-button-2;
              color: $alert-color-button-2;
              &--disabled{
                  color: $grey-icons-2;
              }
          }
          &__list-menu-block {
              border-color: $alert-color-button-2;
              border-top-width: 2px;
          }
      }
  }
  &__icam-red{
      .concept-form {
          &__button-step{
              color: $secondary-color-icam;
              &:hover{
                  background: $secondary-color-icam;
              }
              &:after{
                  border-bottom-color: $secondary-color-icam;
                  top: 2px;
              }
              &--active{
                  color: $primary-color-icam;
                  &:hover{
                      background: $primary-color-icam;

                  }
                  &:after{
                      border-bottom-color: $primary-color-icam;
                      top: 2px;
                  }
              }
          }
           &__subcontainer{
              &--with-border{
                  background-color: $snow-white;
                  border: 1px solid $border-block-icam;
              }
          }
          &__circle-block-icon{
              background-color:  $secondary-color-icam;
              &--active{
                  background-color: $primary-color-icam;
              }
          }
          &__close-icon {
              color: $border-hover-2;
          }
          &__container-fields {
              box-shadow: 0 0 5px -1px $box-shadow-box-2;
              &--edit-concept {
                  border: none;
              }
          }
          &__fields-block {
              &--concept{
                  margin-top: 48px;
                  padding: 0 7px 12px 20px;
              }
          }
          &__group-title-block {
              @include group-title-block($custom-black-landing);
          }
          &__icon-arrow {
              color: $third-color-icam;
          }
          &__subcontainer-block {
              background-color: $text-field-color-icam;
          }
          &__icon-directions {
              color: $primary-color-icam;
              bottom: 8px;
          }
          &__concept-box-right {
              margin-top: 14px;
              padding-right: 20px;
          }
          &__menu-icon {
              @include menu-icon($snow-white);
          }
          &__subgroup-menu {
              @include subgroup-menu($third-color-2);
          }
          &__subgroup-menu-block {
              max-height: 175px;
          }
           &__subgroup-menu-element-list {
              padding: 9px 0;
           }
          &__subgroup-menu-line-bottom-link {
              color: $primary-color-icam;
           }
          &__subgroup-menu-list {
              font-family: MTTMilano, sans-serif;
              font-size: 13px;
              font-weight: 500;
          }
          &__elements-repeat-list-field{
              @include elements-repeat-list-field($hover-block-icam, $primary-color-icam)
          }
          &__title-form-subgroup{
              @include title-form-subgroup($primary-color-2, $third-color-2)
          }
          &__concept-block-menu-icon {
              @include concept-block-menu-icon($primary-color-icam, $primary-color-icam, $border-buttons-icam)
          }
          &__close-duplicate-subcontainer {
              color: $primary-color-2;
          }
          &__duplicate-subcontainer-title {
              color: $primary-color-2;
          }
          &__copys-block {
              background-color: $primary-color-2;
          }
          &__menu-share,
          &__submenu-share{
              @include menu-share($primary-color-icam, $snow-white);
              border: none;
          }
          &__icon-share{
              top: 9px;
          }
      }
  }
  &__gm-law{
      .concept-form {
          &__button-step{
              color: $secondary-color-gm;
              &:hover{
                  background: $secondary-color-gm;
              }
              &:after{
                  border-bottom-color: $secondary-color-gm;
                  top: 2px;
              }
              &--active{
                 color: $primary-color-gm;
                 &:hover{
                     background: $primary-color-gm;
                 }
                 &:after{
                     border-bottom-color: $primary-color-gm;
                     top: 2px;
                 }
              }
          }
          &__subcontainer{
              &--with-border{
                  background-color: $snow-white;
                  border: 1px solid $border-block-gm;
              }
          }
          &__circle-block-icon{
              background-color:  $secondary-color-gm;
              &--active{
                  background-color: $primary-color-gm;
              }
          }
          &__close-icon {
              color: $border-hover-2;
          }
          &__container-fields {
              box-shadow: 0 0 5px -1px $box-shadow-box-2;
              &--edit-concept {
                  border: none;
              }
          }
          &__fields-block {
              &--concept{
                  margin-top: 48px;
                  padding: 0 7px 12px 20px;
              }
          }
          &__group-title-block {
              @include group-title-block($custom-black-landing);
          }
          &__icon-arrow {
              color: $third-color-gm;
          }
          &__subcontainer-block {
              background-color: $text-field-color-gm;
          }
          &__icon-directions {
              color: $primary-color-gm;
              bottom: 8px;
          }
          &__concept-box-right {
              margin-top: 14px;
              padding-right: 20px;
          }
          &__menu-icon {
              @include menu-icon($snow-white);
          }
          &__subgroup-menu {
              border: 1px solid $primary-color-gm;
              width: 300px;
              margin-left: -309px;
              margin-top: -137px;
              border-top-width: 2px;
          }
          &__subgroup-menu-block {
              max-height: 175px;
          }
          &__subgroup-menu-element-list {
              padding: 9px 0;
          }
          &__subgroup-menu-line-bottom-link {
              color: $primary-color-gm;
          }
          &__subgroup-menu-list {
              font-family: MTTMilano, sans-serif;
              font-size: 13px;
              font-weight: 500;
          }
          &__elements-repeat-list-field{
              @include elements-repeat-list-field($hover-block-gm, $primary-color-gm);
          }
          &__title-form-subgroup{
              @include title-form-subgroup($primary-color-2, $third-color-2);
          }
          &__concept-block-menu-icon {
              @include concept-block-menu-icon($primary-color-gm, $primary-color-gm, $border-buttons-gm);
          }
          &__close-duplicate-subcontainer {
              color: $primary-color-2;
          }
          &__duplicate-subcontainer-title {
              color: $primary-color-2;
          }
          &__copys-block {
              background-color: $primary-color-2;
          }
          &__menu-share,
          &__submenu-share{
              @include menu-share($primary-color-gm, $snow-white);
              border: none;
          }
          &__icon-share{
              top: 9px;
          }
      }
  }
  &__com-de{
    .concept-form {
        &__button-step{
            color: $secondary-color-com-de;
            &:hover{
                background: $secondary-color-com-de;
            }
            &:after{
                border-bottom-color: $secondary-color-com-de;
                top: 2px;
            }
            &--active{
               color: $primary-color-com-de;
               &:hover{
                   background: $primary-color-com-de;
               }
               &:after{
                   border-bottom-color: $primary-color-com-de;
                   top: 2px;
               }
            }
        }
        &__subcontainer{
            &--with-border{
                background-color: $snow-white;
                border: 1px solid $border-block-com-de;
            }
        }
        &__circle-block-icon{
            background-color:  $secondary-color-com-de;
            &--active{
                background-color: $primary-color-com-de;
            }
        }
        &__close-icon {
            color: $border-hover-2;
        }
        &__container-fields {
            box-shadow: 0 0 5px -1px $box-shadow-box-2;
            &--edit-concept {
                border: none;
            }
        }
        &__fields-block {
            &--concept{
                margin-top: 48px;
                padding: 0 7px 12px 20px;
            }
        }
        &__group-title-block {
            @include group-title-block($custom-black-landing);
        }
        &__icon-arrow {
            color: $third-color-com-de;
        }
        &__subcontainer-block {
            background-color: $text-field-color-com-de;
        }
        &__icon-directions {
            color: $primary-color-com-de;
            bottom: 8px;
        }
        &__concept-box-right {
            margin-top: 14px;
            padding-right: 20px;
        }
        &__menu-icon {
            @include menu-icon($snow-white);
        }
        &__subgroup-menu {
            border: 1px solid $primary-color-com-de;
            width: 300px;
            margin-left: -309px;
            margin-top: -137px;
            border-top-width: 2px;
        }
        &__subgroup-menu-block {
            max-height: 175px;
        }
        &__subgroup-menu-element-list {
            padding: 9px 0;
        }
        &__subgroup-menu-line-bottom-link {
            color: $primary-color-com-de;
        }
        &__subgroup-menu-list {
            font-family: MTTMilano, sans-serif;
            font-size: 13px;
            font-weight: 500;
        }
        &__elements-repeat-list-field{
            @include elements-repeat-list-field($hover-block-com-de, $primary-color-com-de);
        }
        &__title-form-subgroup{
            @include title-form-subgroup($primary-color-2, $third-color-2);
        }
        &__concept-block-menu-icon {
            @include concept-block-menu-icon($primary-color-com-de, $primary-color-com-de, $border-buttons-com-de);
        }
        &__close-duplicate-subcontainer {
            color: $primary-color-2;
        }
        &__duplicate-subcontainer-title {
            color: $primary-color-2;
        }
        &__copys-block {
            background-color: $primary-color-2;
        }
        &__menu-share,
        &__submenu-share{
            @include menu-share($primary-color-com-de, $snow-white);
            border: none;
        }
        &__icon-share{
            top: 9px;
        }
    }
  }
  &__com-fr{
    .concept-form {
        &__button-step{
            color: $secondary-color-com-fr;
            &:hover{
                background: $secondary-color-com-fr;
            }
            &:after{
                border-bottom-color: $secondary-color-com-fr;
                top: 2px;
            }
            &--active{
               color: $primary-color-com-fr;
               &:hover{
                   background: $primary-color-com-fr;
               }
               &:after{
                   border-bottom-color: $primary-color-com-fr;
                   top: 2px;
               }
            }
        }
        &__subcontainer{
            &--with-border{
                background-color: $snow-white;
                border: 1px solid $border-block-com-fr;
            }
        }
        &__circle-block-icon{
            background-color:  $secondary-color-com-fr;
            &--active{
                background-color: $primary-color-com-fr;
            }
        }
        &__close-icon {
            color: $border-hover-2;
        }
        &__container-fields {
            box-shadow: 0 0 5px -1px $box-shadow-box-2;
            &--edit-concept {
                border: none;
            }
        }
        &__fields-block {
            &--concept{
                margin-top: 48px;
                padding: 0 7px 12px 20px;
            }
        }
        &__group-title-block {
            @include group-title-block($custom-black-landing);
        }
        &__icon-arrow {
            color: $third-color-com-fr;
        }
        &__subcontainer-block {
            background-color: $text-field-color-com-fr;
        }
        &__icon-directions {
            color: $primary-color-com-fr;
            bottom: 8px;
        }
        &__concept-box-right {
            margin-top: 14px;
            padding-right: 20px;
        }
        &__menu-icon {
            @include menu-icon($snow-white);
        }
        &__subgroup-menu {
            border: 1px solid $primary-color-com-fr;
            width: 300px;
            margin-left: -309px;
            margin-top: -137px;
            border-top-width: 2px;
        }
        &__subgroup-menu-block {
            max-height: 175px;
        }
        &__subgroup-menu-element-list {
            padding: 9px 0;
        }
        &__subgroup-menu-line-bottom-link {
            color: $primary-color-com-fr;
        }
        &__subgroup-menu-list {
            font-family: MTTMilano, sans-serif;
            font-size: 13px;
            font-weight: 500;
        }
        &__elements-repeat-list-field{
            @include elements-repeat-list-field($hover-block-com-fr, $primary-color-com-fr);
        }
        &__title-form-subgroup{
            @include title-form-subgroup($primary-color-2, $third-color-2);
        }
        &__concept-block-menu-icon {
            @include concept-block-menu-icon($primary-color-com-fr, $primary-color-com-fr, $border-buttons-com-fr);
        }
        &__close-duplicate-subcontainer {
            color: $primary-color-2;
        }
        &__duplicate-subcontainer-title {
            color: $primary-color-2;
        }
        &__copys-block {
            background-color: $primary-color-2;
        }
        &__menu-share,
        &__submenu-share{
            @include menu-share($primary-color-com-fr, $snow-white);
            border: none;
        }
        &__icon-share{
            top: 9px;
        }
    }
  }
}

@media (max-width: 1135px) {
  .concept-form {
      &__block-title-group-poll {
          padding: 0px 20px 0 20px;
      }
      // &__container-group-fields-custom {
      //     padding: 15px 75px 5px;
      // }
  }
}
@media (min-width: 990px) and (max-width: 1090px) {
  .concept-form {
      &__container-group-fields-custom {
          width: 100%;
      }
  }
}
@media (max-width: 1024px) {
  .concept-form {
      &__fields-block {
          &--view{
              width: 100%;
          }
      }
      &__custom-poll-block{
          width: 100%;
      }
      &__custom-block-fields-poll{
          max-width: 89%;
      }
  }
}

@media (max-width: $max-width-smartphone) { //Smartphone
  .concept-form {
      &__custom-applicant-profile{
          // margin: unset;
          width: calc(100% - 30px);
          margin: 0 15px;
      }
      &__states-monitoring{
          display: none;
      }
      &__container-text-custom {
          max-width: 100%;
          text-align: center;
          float: unset;
          white-space: unset;
      }

      &__container-group-fields-custom {
          width: auto;
      }

      &__custom-buttons {
          width: calc(50% - 10px);
          padding: 0;
      }

      &__container-subgroup-fields-custom {
          padding: 15px 15px 10px;
      }
  }
}
