.evaluation-tree{
  &__block-tree {
      width: 100%;
      float: left;
      position: relative;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      padding: 0 0 0 25px;
  }
  &__block-name-children {
      display: inline-block;
      position: relative;
      float: left;
      width: 100%;
      &--open{
          border-left: 1px solid $evaluation-tree-children;
      }
  }
  &__icon-arrow {
      margin: 0px 22px 0 1px;
      display: inline-block;
      position: relative;
      float: left;
      font-size: 15px;
      color: $icon-arrow;
      top: 0;
      left: -9px;
      background-color: $snow-white;
      &--open{
          &:hover{
              cursor: pointer;
          }
      }
  }

  &__title-children{
      font-family: MTTMilano, sans-serif;
      top: 0px;
      position: relative;
      padding: 0px 20px 4px 0px;
      width: calc(100% - 45px);
      min-height: 31px;
      margin: 0;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover{
          cursor: pointer;
      }
      &--active{
          font-family: MTTMilano-Bold, sans-serif;
          &:hover{
              cursor: default;
          }
      }
      &--default{
          &:hover{
              cursor: default;
          }
      }
  }
}

.compliance{
  &__dark-blue{
    .evaluation-tree{
      &__block-name-children {
        &--open{
            border-left: 1px solid $evaluation-tree-children;
        }
      }
      &__icon-arrow {
        color: $icon-arrow;
        &:hover{
            color: $primary-color;
        }
        &--active{
          color: $primary-color;
        }
      }
      &__title-children{
        &--active{
            font-family: MTTMilano-Bold, sans-serif;
            &:hover{
                cursor: default;
            }
        }
      }
    }
  }

  &__steel-blue, &__uk-steel-blue{
    .evaluation-tree{
      &__block-tree {
        color: $primary-color-2;
      }
      &__block-name-children {
        &--open{
          border-left: 1px solid $border-hover-2;
        }
      }
      &__icon-arrow {
        color: $primary-color-2;
        &:hover{
            color: $border-hover-2;
        }
        &--active{
          color: $border-hover-2;
        }
      }
      &__title-children{
        &--active{
          font-family: MTTMilano-Bold, sans-serif;
          color: $border-hover-2;
          &:hover{
              cursor: default;
          }
        }
      }
    }
  }
  &__gm-law{
    .evaluation-tree{
      &__block-tree {
        color: $custom-black-landing;
      }
      &__block-name-children {
        &--open{
          border-left: 1px solid $primary-color-gm;
        }
      }
      &__icon-arrow {
        color: $secondary-color-gm;
        &:hover{
            color: $primary-color-gm;
        }
        &--active{
          color: $primary-color-gm;
        }
      }
      &__title-children{
        &--active{
          font-family: MTTMilano-Bold, sans-serif;
          color: $custom-black-landing;
          &:hover{
              cursor: default;
          }
        }
      }
    }
  }
  &__com-de{
    .evaluation-tree{
      &__block-tree {
        color: $custom-black-landing;
      }
      &__block-name-children {
        &--open{
          border-left: 1px solid $primary-color-com-de;
        }
      }
      &__icon-arrow {
        color: $secondary-color-com-de;
        &:hover{
            color: $primary-color-com-de;
        }
        &--active{
          color: $primary-color-com-de;
        }
      }
      &__title-children{
        &--active{
          font-family: MTTMilano-Bold, sans-serif;
          color: $custom-black-landing;
          &:hover{
              cursor: default;
          }
        }
      }
    }
  }
  &__com-fr{
    .evaluation-tree{
      &__block-tree {
        color: $custom-black-landing;
      }
      &__block-name-children {
        &--open{
          border-left: 1px solid $primary-color-com-fr;
        }
      }
      &__icon-arrow {
        color: $secondary-color-com-fr;
        &:hover{
            color: $primary-color-com-fr;
        }
        &--active{
          color: $primary-color-com-fr;
        }
      }
      &__title-children{
        &--active{
          font-family: MTTMilano-Bold, sans-serif;
          color: $custom-black-landing;
          &:hover{
              cursor: default;
          }
        }
      }
    }
  }
  &__icam-red{
    .evaluation-tree{
      &__block-name-children {
        &--open{
          border-left: 1px solid $primary-color-icam;
        }
      }
      &__icon-arrow {
        color: $secondary-color-icam;
        &:hover{
            color: $primary-color-icam;
        }
        &--active{
          color: $primary-color-icam;
        }
      }
    }
  }
}
