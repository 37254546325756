.finish-block-modal{
    &__window{
        & .modal-dialog {
            & .modal-content {
                height: auto;
                display: flex;
            }
        }
    }

    &__body{
        padding: 10px 68px 85px;
        position: relative;
        float: left;
    }

    &__button-box-option{
        text-align: center;
        text-transform: none;
        font-size: 20px;
        line-height: 20px;
        min-height: 40px;
        &--open-steel-blue{
            margin-bottom: 40px;
        }
    }

    &__button-box-text {
        position: relative;
        bottom: 5px;
        line-height: 20px;
    }

    &__contacts-import-title {
        color: $donut-text;
        font-family: MTTMilano, sans-serif;
        font-size: 18px;
        line-height: 24px;
        margin: 0 0 20px 0;
    }

    &__box-text-info{
        padding: 0 15px;
        width: 100%;
        height: 205px;
        overflow: auto;
        overflow-x: hidden;
        line-height: 18px;
        font-size: 15px;
        margin-top: 20px;
        font-family: 'MTTMilano', sans-serif;
        &--buttons{
            height: 160px;
            margin-top: 0;
        }
        &--migration-data{
          height: auto;
        }
    }

    &__option-box{
        width: 247px;
        height: 395px;
        padding: 25px 0 0;
        position: relative;
        &--center{
            margin: 0 10px;
        }
    }

    &__block-info {
        width: 60px;
        height: 21px;
        margin: 2px auto 10px;
        text-align: center;
    }

    &__button-bool{
        position: relative;
        float: left;
    }

    &__footer{
        display: block;
        position: absolute;
        right: 68px;
        bottom: 23px;
        margin-top: 0;
        margin-bottom: 0;
    }

    &__option-box-icon{
        font-size: 75px;
        margin: 10px 0 0;
        display: block;
    }

    &__button-open-schedule{
        position: relative;
        float: left;
        font-family: Arial, sans-serif;
        font-size: 11px;
        font-weight: bold;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        margin: 3px 0 0 23px;
        color: $primary-color;
    }

    &__icon-open-schedule{
        font-size: 20px;
        position: relative;
        top: 5px;
    }

    &__text-additional-info{
        margin: 12px 14px 0 23px;
        opacity: 0.5;
        color: $primary-color;
        font-family: Arial, sans-serif;
        font-size: 11px;
        font-weight: bold;
        line-height: 12px;
    }

    &__block-info-calendar{
        height: 71px;
        padding: 9px 9px 0;
        color: $pillow-group;
        font-family: Arial, sans-serif;
        font-size: 10px;
        line-height: 14px;
        background-color: $background-color-states;
        margin-bottom: 18px;
        & > p{
            margin: 0;
        }
    }

    &__block-calendar-datepicker{
        position: relative;
        float: left;
        width: 100%;
        background-color: $snow-white;
        z-index: 1;
        & .calendar-datepicker__datepicker-month, & .calendar-datepicker__datepicker-year{
            min-height: 230px;
        }
    }

    &__block-buttons-calendar{
        padding: 0 10px;
        position: absolute;
        bottom: 24px;
    }

    &__button-litle {
        padding: 5px 0 3px 0;
        height: 31px;
        font-size: 12px;
        margin-right: 0px;
        width: 110px;
        &--first{
          margin-left: 0px;
        }
    }
}
.compliance{
    &__dark-blue{
        .finish-block-modal{
            &__option-box{
                &--open-calendar{
                    padding: 0;
                    border: 1px solid $pillow-group;
                    background-color: $snow-white;
                }
            }
            &__button-open-schedule{
                &--error{
                    color: $red;
                }
            }
            &__button-litle {
                &:after {
                    width: 110px;
                    right: 3px;
                    display: block;
                    top: 7px;
                    position: relative;
                }
            }
        }
    }
    &__steel-blue, &__uk-steel-blue{
        .finish-block-modal{
            &__button-box-option{
                &--open{
                    margin-bottom: 40px;
                }
            }
            &__contacts-import-title {
                color:$grey-icons-2;
            }
            &__option-box{
                &--open-calendar{
                       border: 1px solid $secondary-color-2;
                       background-color: $snow-white;
                }
            }
            &__button-open-schedule{
                color: $primary-color-2;
                &--error{
                    color: $alert-color-2;
                }
            }
        }
    }
    &__icam-red{
        .finish-block-modal{
            &__contacts-import-title {
                color:$secondary-color-icam;
            }
            &__button-open-schedule{
                color: $text-field-color-icam;
                &--error{
                    color: $alert-color-icam;
                }
            }
        }
    }
    &__gm-law{
        .finish-block-modal{
            &__contacts-import-title {
                color:$secondary-color-gm;
            }
            &__button-open-schedule{
                color: $text-field-color-gm;
                &--error{
                        color: $alert-color-gm;
                    }
            }
        }
    }
    &__com-de{
      .finish-block-modal{
          &__contacts-import-title {
              color:$secondary-color-com-de;
          }
          &__button-open-schedule{
              color: $text-field-color-com-de;
              &--error{
                      color: $alert-color-com-de;
                  }
          }
      }
    }
    &__com-fr{
      .finish-block-modal{
          &__contacts-import-title {
              color:$secondary-color-com-fr;
          }
          &__button-open-schedule{
              color: $text-field-color-com-fr;
              &--error{
                      color: $alert-color-com-fr;
                  }
          }
      }
    }
}
