.edit-concept{
  display: block;
  width: 100%;
  height: 100%;
  &__content{
      display: block;
      width: 100%;
      height: 100%;
  }

  &__block-button-finish{
      position: absolute;
      right: 0px;
      bottom: 40px;
  }

  &__go-back-text{
      &:hover{
          cursor: pointer;
      }
  }
}
.compliance{
  &__steel-blue{
    &__concept-form{
      top: -1px;
      position: relative;
    }
  }
}
