@import '../../../../assets/scss/colors';

@mixin new-custom-report-configuration-field-selected($firstColor, $secondColor) {
  background-color: $firstColor;

  .new-custom-report-configuration__icon-type, .common__icon-ellipsis {
      color: $secondColor;
  }
}

@mixin new-custom-report-configuration-available-field($primaryColor, $secondColor) {
  &.gu-mirror {
    width: 300px !important;
    border: none !important;
    background: $primaryColor !important;
    box-shadow: 0 0 1px 1px $custom-black-landing !important;
    color: $snow-white !important;
    font-family: Arial, sans-serif !important;
    font-size: 12px !important;
    border-radius: 45px;
    transform: translate(0, 0) !important;
    .new-custom-report-configuration__icon-type{
        color: $snow-white !important;
        bottom: 1px !important;
        margin-left: 10px;
    }
    .common__icon-ellipsis, .common__col-text {
        color: $snow-white !important;
    }
    .new-custom-report-configuration__table-col--actions{
        display: none !important;
    }
    .new-custom-report-configuration__table-col--origin-report{
        width: 50px;
        max-width: 50px;
    }
    .new-custom-report-configuration__table-col--name-report{
        width: calc(100% - 115px);
    }
  }
  // &.dndDragging {
  //     width: 300px !important;
  //     border: none !important;
  //     background: $primaryColor !important;
  //     box-shadow: 0 0 1px 1px $custom-black-landing !important;
  //     color: $snow-white !important;
  //     font-family: Arial !important;
  //     font-size: 12px !important;
  //     border-radius: 45px;
  //     transform: translate(0, 0) !important;
  //     .new-custom-report-configuration__icon-type{
  //         color: $snow-white !important;
  //         bottom: 1px !important;
  //         margin-left: 10px;
  //     }
  //     .common__icon-ellipsis, .common__col-text {
  //         color: $snow-white !important;
  //     }
  //     .new-custom-report-configuration__table-col--actions{
  //         display: none !important;
  //     }
  //     .new-custom-report-configuration__table-col--origin-report{
  //         width: 50px;
  //         max-width: 50px;
  //     }
  //     .new-custom-report-configuration__table-col--name-report{
  //         width: calc(100% - 115px);
  //     }
  // }

  // &.dndDraggingSource {
  //     width: 100% !important;
  //     box-shadow: none !important;
  //     background: $secondColor !important;
  //     border-radius: 0px !important;
  //     color: initial !important;
  //     cursor: auto;
  //     .new-custom-report-configuration__icon-type{
  //         color: initial !important;
  //         bottom: 1px !important;
  //         margin-left: 6px;
  //     }
  //     .common__icon-ellipsis, .common__col-text {
  //         color: initial !important;
  //     }
  //     .new-custom-report-configuration__table-col--origin-report{
  //         width: 80px;
  //         max-width: 80px;
  //     }
  //     .new-custom-report-configuration__table-col--name-report{
  //         width: calc(100% - 145px);
  //     }
  // }
}

.new-custom-report-configuration {
  &__block-body {
      margin: 0 20px;
  }

  &__block-info{
      max-width: 800px;
      margin: 0 auto;
      position: relative;
      display: flex;
  }

  &__report-fields-selection {
      max-width: 1274px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      margin-top: 30px;
  }

  &__available-fields-container {
      margin-right: 2px;
  }

  &__fields-selected-container {
      margin-left: 2px;
  }

  &__available-fields-container, &__fields-selected-container {
      max-width: 580px;
      width: calc(50% - 30px);
  }

  &__table-container {
      width: 100%;
      height: 544px;
      border-radius: 11px;
  }

  &__table-col {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding-right: 5px;

      &--tooltip {
          max-width: 0;
      }
      &--top-1{
          position: relative;
          top: 1px;
      }
      &--top-2{
          position: relative;
          top: 2px;
      }

      &--origin-report{
          width: 80px;
          max-width: 80px;
      }

      &--name-report {
          width: calc(100% - 145px);
      }

      &--action-report {
          position: relative;
          width: 65px;
          max-width: 65px;
      }
  }

  &__table-container-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 10px;
      margin-bottom: 3px;
  }

  &__table-container-header-icon {
      color: $primary-color;
      font-size: 20px;
  }

  &__table-container-header-text {
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      margin-left: 10px;
      font-size: 20px;
  }

  &__buttons-container {
      display: flex;
      flex-direction: column;
  }

  &__field-button {
      background-color: $primary-color;
      color: $snow-white;
      width: 40px;
      height: 40px;
      border: none;
      outline: none;

      &--remove {
          margin-top: 1px;
      }
  }

  &__labels-container {
      margin-top: 10px;
  }

  &__empty-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 500px;
      border-radius: 10px;
      width: 100%;
      margin: 0;
      padding: 0;
  }


  &__empty-icon {
      font-size: 40px;
      color: $primary-color;
  }

  &__empty-text {
      color: $subcontainer-ground;
      font-style: italic;
      font-family: Arial, sans-serif;
      text-align: center;
      margin-bottom: 20px;
      max-width: 325px;
  }

  &__table {
      width: 100%;
      height: 544px;
      padding: 0;

      &--empty {
          margin: 0;
          padding: 0;
          border: none;

          // .dndPlaceholder {
          //     display: none;
          // }
      }
  }

  &__list {
      border: 2px dotted $primary-color;
      border-radius: 10px;
      margin: 0;
      height: 544px;
      padding: 20px;
      width: 100%;

      // &.dndDragover {
      //     border-color: $green;
      // }

      // .dndPlaceholder {
      //     list-style: none;
      // }

      // li:nth-child(1).dndPlaceholder {
      //     display: none;
      // }
  }

  &__move-icon {
      font-size: 21px;
      margin-right: 0px;
      margin-top: 1px;
  }

  &__block-table{
      width: 100%;
      height: 460px;
      overflow-y: auto;
      overflow-x: hidden;
      &--more-elements{
          width: calc(100% + 10px);
      }
  }

  &__field-selected {
      @include new-custom-report-configuration-field-selected($custom-color-CDD0DF, $primary-color);
  }

  &__available-field {
      cursor: pointer;
      @include new-custom-report-configuration-available-field($primary-color, $custom-color-CDD0DF);
  }

  &__icon-type{
      font-size: 20px;
      color: $primary-color;
      margin-left: 6px;
      float: left;
      position: relative;
      bottom: 1px;
  }
}

.compliance{
  &__steel-blue {
    .new-custom-report-configuration {
      &__icon-type {
        color: $border-hover-2;
      }
    }
  }

  &__icam-red {
    .new-custom-report-configuration {
      &__field-button {
        background-color: $primary-color-icam;
      }

      &__empty-icon {
        color: $custom-black-landing;
      }

      &__empty-text {
        color: $secondary-color-icam;
      }

      &__list {
        border-color: $custom-black-landing;
        // &.dndDragover {
        //     border-color: $third-color-icam;
        //     background-color: $hover-block-icam;
        //     color: $secondary-color-icam;
        // }
      }

      &__field-selected {
        @include new-custom-report-configuration-field-selected($third-color-icam, $primary-color-icam);
      }

      &__available-field {
        @include new-custom-report-configuration-available-field($custom-black-landing, $background-box-icam);
      }

      &__icon-type {
        color: $primary-color-icam;
      }
      &__table-container-header-icon,
      &__table-container-header-text {
        color: $custom-black-landing;
      }
    }
  }

  &__gm-law {
    .new-custom-report-configuration {
      &__field-button {
        background-color: $primary-color-gm;
      }

      &__empty-icon {
        color: $custom-black-landing;
      }

      &__empty-text {
        color: $secondary-color-icam;
      }

      &__list {
        border-color: $custom-black-landing;
        // &.dndDragover {
        //     border-color: $third-color-gm;
        //     background-color: $hover-block-gm;
        //     color: $secondary-color-gm;
        // }
      }

      &__field-selected {
        @include new-custom-report-configuration-field-selected($third-color-gm, $primary-color-gm);
      }

      &__available-field {
        @include new-custom-report-configuration-available-field($custom-black-landing, $background-box-gm);
      }

      &__icon-type {
        color: $primary-color-gm;
      }
      &__table-container-header-icon,
      &__table-container-header-text {
        color: $custom-black-landing;
      }
    }
  }
  &__com-de{
    .new-custom-report-configuration {
      &__field-button {
        background-color: $primary-color-com-de;
      }

      &__empty-icon {
        color: $custom-black-landing;
      }

      &__empty-text {
        color: $secondary-color-icam;
      }

      &__list {
        border-color: $custom-black-landing;
        // &.dndDragover {
        //     border-color: $third-color-com-de;
        //     background-color: $hover-block-com-de;
        //     color: $secondary-color-com-de;
        // }
      }

      &__field-selected {
        @include new-custom-report-configuration-field-selected($third-color-com-de, $primary-color-com-de);
      }

      &__available-field {
        @include new-custom-report-configuration-available-field($custom-black-landing, $background-box-com-de);
      }

      &__icon-type {
        color: $primary-color-com-de;
      }
      &__table-container-header-icon,
      &__table-container-header-text {
        color: $custom-black-landing;
      }
    }
  }
  &__com-fr {
    .new-custom-report-configuration {
      &__field-button {
        background-color: $primary-color-com-fr;
      }

      &__empty-icon {
        color: $custom-black-landing;
      }

      &__empty-text {
        color: $secondary-color-icam;
      }

      &__list {
        border-color: $custom-black-landing;
        // &.dndDragover {
        //     border-color: $third-color-com-fr;
        //     background-color: $hover-block-com-fr;
        //     color: $secondary-color-com-fr;
        // }
      }

      &__field-selected {
        @include new-custom-report-configuration-field-selected($third-color-com-fr, $primary-color-com-fr);
      }

      &__available-field {
        @include new-custom-report-configuration-available-field($custom-black-landing, $background-box-com-fr);
      }

      &__icon-type {
        color: $primary-color-com-fr;
      }
      &__table-container-header-icon,
      &__table-container-header-text {
        color: $custom-black-landing;
      }
    }
  }
}
