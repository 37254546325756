.snippets-reusable {
  &__block-container {
      width: 100%;
      margin: 0 auto;
      min-height: calc(100% - 369px);
      display: block;
      max-width: 1400px;
  }

  &__body {
      height: auto;
      min-height: 620px;
  }

  &__form-body{
      width: 800px;
      margin: 50px auto 0;
  }

  &__block-snippet {
      display: block;
      background-color: $snow-white;
      width: 100%;
      min-height: 313px;
      margin-top: 12px;
      padding: 8px;
      position: relative;
      float: left;
      border: 1px solid;
  }

  &__title-new-snippet{
      display: inline-block;
      width: calc(50% - 4px);
      vertical-align: top;
      padding-top: 5px;
  }

  &__title-new-snippet-span{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      padding: 5px 10px;
      text-transform: uppercase;
  }

  &__short-id{
      display: inline-block;
      width: 50%;
  }

  &__buttons-block {
      margin: 50px 60px 15px;
      position: relative;
      float: right;
  }

  &__snippets-container{
      width: calc(100% + 16px);
      position: relative;
      float: left;
      margin: 25px 0 40px;
      right: 8px;
  }

  &__snippets-inner-container{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-height: 800px;
      overflow: auto;
      overflow-x: hidden;
      width: 100%;
      padding: 0px 8px;
  }

  &__snippet-list-element{
      position: relative;
      width: calc(50% - 5px);
      height: 150px;
      padding: 9px 5px 8px 9px;
      margin: 5px 0;
      &:hover{
          & .common__options-row{
              visibility: visible;
          }
      }
  }

  &__title-snippet-list{
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
  }

  &__short-id-snippet-list{
      font-family: MTTMilano, sans-serif;
      font-size: 11px;
      line-height: 20px;
      margin: 0;
  }

  &__text-snippet-list{
      font-family: Arial, sans-serif;
      font-size: 11px;
      line-height: 15px;
      margin: 2px 0 0;
      padding-right: 3px;
      max-height: 91px;
      overflow: auto;
      overflow-x: hidden;
  }

  &__block-search{
      max-width: 280px;
  }
  &__text-searched{
      color: $alert-color;
      display: inline;
  }
}

.compliance{
  &__dark-blue{
    .snippets-reusable {
      &__block-snippet {
        border-color: $border-color-other;
      }
      &__title-new-snippet-span{
        background-color: $background-grey-other;
        color: $pillow-group;
      }
      &__snippet-list-element{
        background-color: $background-color-states;
        &:hover{
            background-color: $background-grey-other;
        }
      }
      &__title-snippet-list{
        color: $primary-color;
      }
      &__short-id-snippet-list{
        color: $pillow-group;
      }
      &__text-snippet-list{
        color: $secondary-color;
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue{
    .snippets-reusable {
      &__block-snippet {
        border-color: $secondary-color-2;
      }
      &__title-new-snippet-span{
        background-color: $background-highlight-text-2;
        color: $primary-color-2;
      }
      &__snippet-list-element{
        background-color: $background-grey-disabled-2;
        &:hover{
            background-color: $block-hover-2;
        }
      }
      &__title-snippet-list{
        color: $primary-color-2;
      }
      &__short-id-snippet-list{
        color: $border-bottom-2;
      }
      &__text-snippet-list{
        color: $border-hover-2;
      }
      &__text-searched{
        color: $alert-color-2;
      }
    }
  }
  &__icam-red{
    .snippets-reusable {
      &__block-snippet {
        border-color: $border-block-icam;
      }
      &__title-new-snippet-span{
        background-color: $background-box-icam;
        color: $title-text-icam;
      }
      &__snippet-list-element{
        background-color: $background-grey-icam;
        &:hover{
            background-color: $hover-block-icam;
        }
      }
      &__title-snippet-list{
        color: $primary-color-icam;
      }
      &__short-id-snippet-list{
        color: $title-text-icam;
      }
      &__text-snippet-list{
        color: $custom-black-landing;
      }
      &__text-searched{
        color: $alert-color-icam;
      }
    }
  }
  &__gm-law{
    .snippets-reusable {
      &__block-snippet {
        border-color: $border-block-gm;
      }
      &__title-new-snippet-span{
        background-color: $background-box-gm;
        color: $title-text-gm;
      }
      &__snippet-list-element{
        background-color: $background-grey-gm;
        &:hover{
            background-color: $hover-block-gm;
        }
      }
      &__title-snippet-list{
        color: $primary-color-gm;
      }
      &__short-id-snippet-list{
        color: $title-text-gm;
      }
      &__text-snippet-list{
        color: $custom-black-landing;
      }
      &__text-searched{
        color: $alert-color-gm;
      }
    }
  }
  &__com-de{
    .snippets-reusable {
      &__block-snippet {
        border-color: $border-block-com-de;
      }
      &__title-new-snippet-span{
        background-color: $background-box-com-de;
        color: $title-text-com-de;
      }
      &__snippet-list-element{
        background-color: $background-grey-com-de;
        &:hover{
            background-color: $hover-block-com-de;
        }
      }
      &__title-snippet-list{
        color: $primary-color-com-de;
      }
      &__short-id-snippet-list{
        color: $title-text-com-de;
      }
      &__text-snippet-list{
        color: $custom-black-landing;
      }
      &__text-searched{
        color: $alert-color-com-de;
      }
    }
  }
  &__com-fr{
    .snippets-reusable {
      &__block-snippet {
        border-color: $border-block-com-fr;
      }
      &__title-new-snippet-span{
        background-color: $background-box-com-fr;
        color: $title-text-com-fr;
      }
      &__snippet-list-element{
        background-color: $background-grey-com-fr;
        &:hover{
            background-color: $hover-block-com-fr;
        }
      }
      &__title-snippet-list{
        color: $primary-color-com-fr;
      }
      &__short-id-snippet-list{
        color: $title-text-com-fr;
      }
      &__text-snippet-list{
        color: $custom-black-landing;
      }
      &__text-searched{
        color: $alert-color-com-fr;
      }
    }
  }
}

@media (max-width: 1440px) {
  .snippets-reusable {
      &__block-container {
          width: calc(100% - 30px);
          margin-left: 15px;
      }
  }
}

@media (max-width: 1340px) {
  .snippets-reusable {
      &__body{
          padding-bottom: 153px;
      }
      &__buttons-block{
          margin: 35px 55px 12px 10px;
          width: calc(100% - 65px);
      }
  }
}

@media (max-width: 1185px) {
  .snippets-reusable {
      &__body{
          padding-bottom: 153px;
      }
      &__buttons-block{
          margin: 35px 55px 12px 10px;
          width: calc(100% - 65px);
      }
  }
}

@media (max-width: 1022px) {
  .snippets-reusable {
      &__form-body{
          width: calc(100% - 80px);
          margin: 20px auto;
      }
  }
}
