.window-list {
  &__bubble-count{
      right: inherit;
      top: 0;
      position: relative;
      margin-right: 6px;
      &:after{
        display: none;
      }
      &:hover{
          cursor: pointer;
      }
  }

  &__cell-block {
      padding-left: 2px;
      padding-right: 6px;
  }

  &__cell-block-text {
      max-width: calc(100% - 32px);
      position: relative;
      float: left;
      height: 17px;
  }

  &__dropdown-button{
      float: left;
  }

  &__icon-close {
      font-size: 10px;
      margin-right: 5px;
      margin-top: 5px;
      float: right;
      color: $primary-color;
      cursor: pointer;
  }

  &__icon-click {
      color: $primary-color;
      font-size: 20px;
      width: 17px;
      float: right;
      margin-right: 11px;
  }

  &__icon-header {
      font-size: 20px;
      color: $primary-color;
      text-align: center;
      display: block;
  }

  &__title-box {
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: bold;
      padding: 5px 10px 2px 10px;
      border-bottom: 3px solid $primary-color;
      text-align: center;
      text-transform: uppercase;
      width: 307px;
      margin: 0 5px 20px;
  }

  &__select-item {
      padding: 5px 0px;
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: bold;
      color: $secondary-color;
      overflow-y: auto;
      overflow-x: hidden;
      text-transform: capitalize;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
          max-width: 246px;
          padding: 0;
          margin: 0;
      }
  }

  &__select-list-item {
      overflow-y: auto;
      overflow-x: hidden;
      width: calc(100% - 5px);
      padding: 0;
      float: right;
      position: relative;
      padding-left: 12px;
      padding-right: 3px;
      margin-right: 5px;
      max-height: 155px;
  }

  &__button {
      height: 26px;
      border: none;
      outline: none;
      color: $primary-color;
      padding: 0;
      text-align: left;
      background-color: transparent;
      float: right;
  }

  &__parraghraph-block{
      position: relative;
      float: left;
      width: calc(100% - 32px);
  }

  &__select-list{
      max-height: 220px;
      padding-bottom: 10px;
      overflow: hidden;
      position: absolute;
      margin: 0;
      width: 320px;
      inset: auto !important;
      top: 20px !important;
      left: -146px !important;
      transform: none !important;
  }
}

.compliance {
  &__icam-red{
    .window-list {
      &__icon-close {
        color: $primary-color-icam;
      }

      &__icon-click {
        color: $primary-color-icam;
      }

      &__icon-header {
        color: $primary-color-icam;
      }

      &__title-box {
        color: $custom-black-landing;
        border-bottom: 1px solid $third-color-icam;
      }

      &__select-item {
        color: $custom-black-landing;
      }
    }
  }

  &__gm-law{
    .window-list {
      &__icon-close {
        color: $primary-color-gm;
      }

      &__icon-click {
        color: $primary-color-gm;
      }

      &__icon-header {
        color: $primary-color-gm;
      }

      &__title-box {
        color: $custom-black-landing;
        border-bottom: 1px solid $third-color-gm;
      }

      &__select-item {
        color: $custom-black-landing;
      }
    }
  }
  &__com-de{
    .window-list {
      &__icon-close {
        color: $primary-color-com-de;
      }

      &__icon-click {
        color: $primary-color-com-de;
      }

      &__icon-header {
        color: $primary-color-com-de;
      }

      &__title-box {
        color: $custom-black-landing;
        border-bottom: 1px solid $third-color-com-de;
      }

      &__select-item {
        color: $custom-black-landing;
      }
    }
  }
  &__com-fr{
    .window-list {
      &__icon-close {
        color: $primary-color-com-fr;
      }

      &__icon-click {
        color: $primary-color-com-fr;
      }

      &__icon-header {
        color: $primary-color-com-fr;
      }

      &__title-box {
        color: $custom-black-landing;
        border-bottom: 1px solid $third-color-com-fr;
      }

      &__select-item {
        color: $custom-black-landing;
      }
    }
  }
}
