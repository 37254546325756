.field-type-switch {
  display: flex;
  align-items: center;
  height: 20px;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-weight: unset;
  width: max-content;

  &--disabled {
      opacity: 0.5;
      cursor: default;
  }

  &__input {
      display: none;
  }

  &__icon {
      font-size: 40px;

      &--negative {
          color: $snow-white;
      }
  }

  &__icon-info{
      font-size: 18px;
      color: $primary-color;
      padding-left: 6px;
  }

  &__label {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      margin-left: 5px;
      color: $primary-color;
  }
}

.compliance{
  &__dark-blue{
    .field-type-switch{
      &__icon--selected {
        color: $primary-color;
      }

      &__icon--unselected {
        color: $subcontainer-ground;
      }
      &__icon-info{
        color: $primary-color;
      }
      &__label {
        color: $primary-color;
        &--negative {
          color: $snow-white;
        }
      }
    }
  }
  &__steel-blue, &__uk-steel-blue{
    .field-type-switch{
      &__icon{
        &--selected {
          color: $border-hover-2;
        }
        &--unselected {
          color: $grey-icons-2;
        }
        &--negative {
            color: $snow-white;
        }
      }
      &__icon-info{
        color: $primary-color-2;
      }
      &__label {
        color: $primary-color-2;
        &--negative {
          color: $snow-white;
        }
      }
    }
  }

  &__icam-red{
    .field-type-switch{
      &__icon{
        &--selected {
          color: $primary-color-icam;
        }
        &--unselected {
          color: $secondary-color-icam;
        }
        &--negative {
            color: $snow-white;
        }
      }
      &__icon-info{
        color: $custom-black-landing;
      }
      &__label {
        color: $custom-black-landing;
        &--negative {
          color: $snow-white;
        }
      }
    }
  }

  &__gm-law{
    .field-type-switch{
      &__icon{
        &--selected {
          color: $primary-color-gm;
        }
        &--unselected {
          color: $secondary-color-gm;
        }
        &--negative {
            color: $snow-white;
        }
      }
      &__icon-info{
        color: $custom-black-landing;
      }
      &__label {
        color: $custom-black-landing;
        &--negative {
          color: $snow-white;
        }
      }
    }
  }
  &__com-de{
    .field-type-switch{
      &__icon{
        &--selected {
          color: $primary-color-com-de;
        }
        &--unselected {
          color: $secondary-color-com-de;
        }
        &--negative {
            color: $snow-white;
        }
      }
      &__icon-info{
        color: $custom-black-landing;
      }
      &__label {
        color: $custom-black-landing;
        &--negative {
          color: $snow-white;
        }
      }
    }
  }
  &__com-fr{
    .field-type-switch{
      &__icon{
        &--selected {
          color: $primary-color-com-fr;
        }
        &--unselected {
          color: $secondary-color-com-fr;
        }
        &--negative {
            color: $snow-white;
        }
      }
      &__icon-info{
        color: $custom-black-landing;
      }
      &__label {
        color: $custom-black-landing;
        &--negative {
          color: $snow-white;
        }
      }
    }
  }

  &__landing-custom{
    .field-type-switch{
      &__icon{
        &--selected {
          color: $custom-black-landing;
        }
        &--unselected {
          color: $subcontainer-ground;
        }
        &--negative {
            color: $snow-white;
        }
      }
      &__icon-info{
        color: $custom-black-landing;
      }

      &__label {
        color: $custom-black-landing;
        &--negative {
          color: $snow-white;
        }
      }
    }
  }
}
