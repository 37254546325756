@mixin block-repeat-states($color){
  background: none;
  border: none;
  color: $color;
  font-family: 'MTTMilano', sans-serif;
  font-size: 14px;
  border-radius: 10px;
  padding: 2px 2px 2px 6px;
  left: -6px;
  height: 20px;
}

@mixin block-repeat-states-color-state($backgrounColor, $color){
  &:hover:not([disabled]){
      background-color: $backgrounColor;
      color: $color;
      & .states-process__icon-arrow-right,
      & .states-process__icon-arrow-left{
          font-size: 11px;
          color: $color !important;
          bottom: inherit;
          top: 3px;
          font-weight: bold;
      }
      & .lf-icon-arrow-round-right{
          &:before {
              content: "\e90d";
          }
      }
      & .lf-icon-arrow-round-left{
          &:before {
              content: "\e90c";
          }
      }
  }
}

@mixin  block-repeat-states-color($backgroundColor, $firstColor, $backgrounColor2, $borderColor, $color){
  background-color: $backgroundColor;
  &:hover {
      color: $firstColor;
      background-color: $backgrounColor2;
      border: 1px solid $red;
  }
  &[disabled]:hover{
      background-color: $borderColor;
      color: $color;
      border: 1px solid $color;
  }
}

@mixin icon-arrow($color){
  font-size: 20px;
  width: 20px;
  color: $color;
  bottom: 2px;
  // margin-right: 3px;
  // background-color: $snow-white;
  // border-radius: 10px;
}

@mixin icon-briefcase($color){
  color: $color;
  margin-left: 4px;
  font-size: 20px;
  position: relative;
  bottom: 3px;
}
@mixin text-process-active($color){
  font-size: 14px;
  text-transform: none;
  color: $color;

}
@mixin text-question($color){
  color: $color;
  font-family: Arial, sans-serif;
  font-size: 12px;
}

@mixin states-proccess-text($color){
  float: right;
  color: $color;
  font-family: 'MTTMilano', sans-serif;
  font-size: 13px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.states-process{
  width: 100%;
  position: relative;
  float: left;
  min-height: 132px;
  background-color: $background-color-states;
  padding: 10px;

  &--task{
      min-height: 94px;
  }
  &__bar-progress{
      height: 10px;
      width: 100%;
      position: relative;
      float: left;
      &--task{
        background-color: transparent;
      }
      &--last{
          border-radius: 0 6px 6px 0;
          background: $custom-border-grey;
      }
      &--first{
          border-radius: 6px 0 0 6px;
          background: $custom-border-grey;
      }
      &-custom{
        height: 9px;
      }
  }

  &__block-body{
      width: 100%;
      min-height: 80px;
      display: table;
      &--task{
          min-height: 42px;
          padding-top: 9px;
      }
      &--custom{
        width: 941px;
        margin: 0 auto;
      }
  }

  &__block-body-task{
      width: 100%;
      height: 40px;
      padding-top: 9px;
      position: relative;
  }

  &__block-header{
      width: 100%;
      height: 29px;
  }

  &__block-line-state{
      width: 100%;
      position: relative;
      float: left;
  }

  &__block-question{
      min-height: 73px;
      position: relative;
      margin-bottom: 10px;
  }

  &__block-repeat-states{
      width: auto;
      max-width: calc(100% - 5px);
      height: 18px;
      position: relative;
      float: left;
      border: 1px solid ;
      padding: 2px 5px;
      margin-bottom: 5px;
      outline: none;
      &:hover:not([disabled]){
          cursor: pointer;
      }
      &--disabled{
        opacity: 0.5;
      }
  }

  &__block-repeat-states-active{
      border: none;
      outline: none;
      background-color: transparent;
      margin-bottom: 5px;
  }

  &__block-state{
      width: 33%;
      display: table-cell;
      vertical-align: bottom;
      min-height: 80px;
      &--principal{
          width: 34%;
      }
      max-width: 0;
  }

  &__block-state-task{
      position: relative;
      height: 33px;
  }

  &__block-state-active{
      height: 23px;
      position: relative;
      bottom: 2px;
  }

  &__buttons-block{
      bottom: -10px;
      position: absolute;
      right: 0px;
  }

  &__icon-arrow-right{
      position: relative;
      float: left;
      margin-left: 5px;
      &--landing-custom{
          margin-left: 7px;
          font-size: 19px;
          bottom: 3px;
      }
      &--custom-concept-next{
          font-size: 19px;
          bottom: 1px;
          color: $attachment-custom-grey;
      }
  }

  &__icon-arrow-left{
      position: relative;
      float: left;
      margin-right: 5px;
  }

  &__icon-button-active{
      font-size: 21px;
  }

  &__icon-header-right{
      font-size: 21px;
      position: relative;
      float: right;
      &:hover{
          cursor: pointer;
      }
  }

  &__icon-help{
      font-size: 41px;
      position: relative;
      float: left;
  }

  &__icon-process{
      opacity: 0.2;
      font-size: 22px;
      margin-right: 6px;
  }

  &__text-process-active{
      position: relative;
      bottom: 5px;
      font-family: MTTMilano-Bold, sans-serif;
  }

  &__text-process-inactive{
      float: left;
      max-width: calc(100% - 19px);
  }

  &__text-question{
      position: relative;
      display: inline-block;
      margin: 0 0 0 8px;
      max-width: calc(100% - 256px);
      top: 12px;
  }

  &__title-process{
      opacity: 0.5;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      bottom: 5px;
  }

  &__title-process-state{
      font-family: 'MTTMilano-bold', sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      margin-top: 8px;
      position: relative;
      float: left;
      &--custom{
          // text-align: center;
          width: 100%;
          font-family: 'lato_regular', sans-serif;
          font-weight: bold;
          color: $custom-secondary-color;
      }
      &--actual{
          color:$custom-black-landing;
      }
  }
  &__creation-date{
    margin-top: 3px;
    float: left;
    min-height: 20px;
    color: $custom-black-landing;
    font-family: MTTMilano-Bold, sans-serif;
    font-size: 12px;
    font-weight: 500;
  }
  ////////////////////////////////////
  ///////////////Custom//////////////
  //////////////////////////////////
  &__custom{
      min-height: 127px;
      background-color: $background-grey;
      border: none;
      color: $custom-black-landing;
  }
  &__custom-block-container{
      margin: 0px auto;
      max-width: 1388px;
      position: relative;
  }
  &__custom-container{
      margin: 0px auto 30px;
      padding: 20px 10px 10px;
      max-width: 1250px;
      position: relative;
      &--two-steps{
          max-width: 940px;
      }
      &--task{
          max-width: 960px;
      }
  }
  &__custom-icon-header{
      color: $attachment-custom-grey;
      font-size: 23px;
      top: 9px;
      right: 20px;
      float: none;
      position: absolute;
      &:hover{
          cursor: pointer;
      }
  }
  &__custom-block-state-task{
      width: 235px;
      position: relative;
      float: left;
  }
  &__custom-actual-step{
      color: $custom-black-landing;
      font-family: 'lato_regular', sans-serif;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      float: left;
      position: relative;
      margin: 11px 0 0;
      line-height: 10px;
  }
  &__custom-icon-radio-next{
      float: left;
      position: relative;
      color: $custom-secondary-color;
      bottom: -1px;
      font-size: 15px;
  }
  &__icon-button-active-custom{
      font-size: 15px;
      position: relative;
      bottom: 8px;
      color: $green;
  }
  &__custom-text-process{
    color: $attachment-custom-grey;
    font-size: 10px;
    font-family: 'lato_regular', sans-serif;
    font-weight: bold;
    position: relative;
    top: 2px;
    text-transform: uppercase;
    margin-left: 5px;
    margin-right: 5px;
  }
  &__text-process-active-custom{
      position: relative;
      bottom: 11px;
      font-size: 11px;
      font-family: 'lato_regular', sans-serif;
      font-weight: bold;
      text-transform: uppercase;
      color:$custom-black-landing;
      margin-left: 6px;
  }
  &__custom-block-repeat-states{
      border: none;
      padding: 0;
      background: none;
      outline: none;
      font-family: 'lato_regular', sans-serif;
      position: relative;
      float: left;
      margin-bottom: 5px;
      color: $custom-secondary-color;
      &:hover:not([disabled]) {
          color:$custom-black-landing;
          background-color: none;
          .states-process__custom-icon-radio-next, .states-process__icon-arrow-right{
              color:$custom-black-landing;
          }
      }
  }

  &__block-all-progress-custom{
      width: 100%;
      height: 10px;
      background: $custom-border-grey;
      position: absolute;
      bottom: 0;
  }

  &__block-state-active-custom{
      height: 18px;
      position: relative;
      // bottom: 2px;
  }

  &__text-process-inactive-custom{
      font-size: 10px;
      font-family: 'lato_regular', sans-serif;
      text-transform: uppercase;
      position: relative;
      float: left;
  }

  &__icon-help-custom{
      position: relative;
      float: left;
      font-size: 53px;
      color: $advice-question;
  }

  &__text-question-custom{
      font-family: MTTMilano, sans-serif;
      font-size: 20px;
      position: relative;
      display: inline-block;
      margin: 0 0 0 8px;
      // top: 12px;
      color: $custom-black-landing;
      width: calc(100% - 61px);
  }

  &__block-question-custom{
      min-height: 127px;
      position: relative;
      max-width: 1150px;
      margin: 0 auto;
      padding: 0px 15px;
  }

  &__block-table-question-custom{
      position: relative;
      width: 100%;
      // display: table;
      min-height: 127px;
      max-width: calc(100% - 222px);
  }

  &__table-question-custom{
      // display: table-cell;
      // vertical-align: middle;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      min-height: 127px;
      padding: 10px 0;
  }

  &__buttons-block-custom{
      bottom: 10px;
      position: absolute;
      right: 0px;
  }
  &__custom-arrow-process{
    font-size: 19px;
    color: $attachment-custom-grey;
    bottom: -4px;
    position: relative;
    &--left{
      float: left;
    }
    &--right{
      float: right;
    }
  }
  ////////////////////////////////////////
  /////////////////////Fin Custom////////////
  //////////////////////////////////////
}

.compliance{
  &__dark-blue{
    .states-process{
      border: 2px dashed $subcontainer-ground;
      background-color: $background-color-states;
      color: $primary-color;
      &__bar-progress{
          background-color: $green;
          &--next{
              background-color: $primary-color;
          }
          &--prev{
              background-color: $state-process-prev;
          }
      }
      &__block-repeat-states{
        border-color: $primary-color;
        background-color: $bottom-border;
        &:hover:not([disabled]){
            color: $snow-white;
            background-color: $primary-color;
        }
      }
      &__block-state-task{
        width: 180px;
        float: left;
      }
      &__icon-arrow-left{
        margin-right: 5px;
      }
      &__icon-briefcase{
        color: $primary-color;
        font-size: 18px;
        margin-left: 10px;
      }
      &__icon-button-active{
        color: $green;
      }
      &__icon-help{
        margin-left: 32px;
      }
      &__text-process-active{
        font-size: 11px;
        text-transform: uppercase;
      }
      &__text-process-inactive{
        font-size: 10px;
        font-family: Arial, sans-serif;
        text-transform: uppercase;
        position: relative;
      }
      &__text-question{
        font-family: 'MTTMilano', sans-serif;
      }
    }
  }

  &__steel-blue, &__uk-steel-blue{
    .states-process{
      border: 1px solid $third-color-2;
      top: -1px;
      &--question{
          background: $background-grey-disabled-2;
          border: none;
      }
      &__bar-progress{
          background-color: $third-color-2;
          &--next{
              background-color: $secondary-color-2;
              border-radius: 0 15px 15px 0;
          }
          &--prev{
              background-color: $border-bottom-2;
              border-radius: 15px 0 0 15px;
          }
      }
      &__block-body-task{
        display: table;
      }
      &__block-header{
        &--question{
          height: 21px;
        }
      }
      &__block-repeat-states{
        @include block-repeat-states($primary-color-2);
        @include block-repeat-states-color-state($border-hover-2, $background-grey);
        &--previous{
          left: 0;
          padding: 2px 6px 2px 0px;
        }
        &--red{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($red, $snow-white);
          }
        }
        &--yellow-car{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($yellow, $snow-white);
          }
        }

        &--orange{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($state-orange, $snow-white);
          }
        }

        &--brown{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($brown, $snow-white);
          }
        }

        &--green {
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($green, $snow-white);
          }
        }

        &--light-grey{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($states-process-light-grey, $snow-white);
          }
        }
      }
      &__block-state-task{
        display: table-cell;
      }
      &__icon-arrow-right, &__icon-arrow-left {
        @include icon-arrow($border-hover-2);
      }
      &__icon-briefcase{
        @include icon-briefcase($border-hover-2);
      }
      &__icon-button-active{
        color: $primary-color-2;
      }
      &__icon-header-right{
        color: $border-hover-2;
      }
      &__icon-help{
        margin-left: 9px;
        color: $primary-color-2;
      }
      &__text-process-active{
        @include text-process-active($primary-color-2);
      }
      &__text-process-inactive{
        font-family: 'MTTMilano', sans-serif;
        font-size: 14px;
        text-transform: none;
        position: relative;
        bottom: 1px;
      }
      &__text-question{
        @include text-question($primary-color-2);
      }
      &__title-process{
        display: none;
      }
      &__title-process-state{
        color: $border-bottom-2;
      }
      &__states-proccess-text{
        @include states-proccess-text($primary-color-2);
      }
    }
  }

  &__icam-red{
    .states-process{
      border: 1px solid $third-color-icam;
      background-color: transparent;
      top: -1px;
      &--question{
        background: $background-grey-disabled-icam;
        border: none;
      }
      &__bar-progress{
          background-color: $third-color-icam;
          &--next{
              background-color: $border-block-icam;
              border-radius: 0 15px 15px 0;
          }
          &--prev{
              background-color: $primary-color-icam;
              border-radius: 15px 0 0 15px;
          }
      }
      &__block-body-task{
        display: table;
      }
      &__block-header{
        &--question{
          height: 21px;
        }
      }
      &__block-repeat-states{
        @include block-repeat-states($custom-black-landing);
        @include block-repeat-states-color-state($primary-color-icam, $snow-white);
        &--previous{
          left: 0;
          padding: 2px 6px 2px 0px;
        }
        &--red {
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($alert-color-icam, $snow-white);
          }
        }
        &--yellow-car {
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($yellow-task-icam, $snow-white);
          }
        }
        &--orange {
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($orange-task-icam, $snow-white);
          }
        }
        &--brown {
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($brown-task-icam, $snow-white);
          }
        }
        &--green {
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($green-task-icam, $snow-white);
          }
        }
        &--light-grey {
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($grey-task-icam, $snow-white);
          }
        }
      }
      &__block-state-task{
        display: table-cell;
      }
      &__icon-arrow-left, &__icon-arrow-right{
        @include icon-arrow($primary-color-gm);
      }
      &__icon-arrow-left{
        @include icon-arrow($primary-color-icam);
      }
      &__icon-briefcase{
        @include icon-briefcase($primary-color-icam);
      }
      &__icon-button-active{
        color: $custom-black-landing;
      }
      &__icon-header-right{
        color: $primary-color-icam;
      }
      &__icon-help{
        margin-left: 9px;
        color: $custom-black-landing;
      }
      &__text-process-active{
        @include text-process-active($custom-black-landing);
      }
      &__text-process-inactive{
        font-family: 'MTTMilano', sans-serif;
        font-size: 14px;
        text-transform: none;
        position: relative;
        bottom: 1px;
      }
      &__text-question{
        @include text-question($custom-black-landing);
      }
      &__title-process{
        display: none;
      }
      &__title-process-state{
        color: $modal-question-icam;
      }
      &__states-proccess-text{
        @include states-proccess-text($custom-black-landing);
      }
    }
  }

  &__gm-law{
    .states-process{
      border: 1px solid $third-color-gm;
      background-color: transparent;
      top: -1px;
      &--question{
        background: $background-grey-disabled-gm;
        border: none;
      }
      &__bar-progress{
          background-color: $blue-icam-it;
          &--next{
            background-color: $border-block-gm;
            border-radius: 0 15px 15px 0;
          }
          &--prev{
            background-color: $primary-color-gm;
            border-radius: 15px 0 0 15px;
          }
      }
      &__block-body-task{
        display: table;
      }
      &__block-header{
        &--question{
          height: 21px;
        }
      }
      &__block-repeat-states{
        @include block-repeat-states($custom-black-landing);
        @include block-repeat-states-color-state($primary-color-gm, $snow-white);
        &--previous{
          left: 0;
          padding: 2px 6px 2px 0px;
        }
        &--red{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($alert-color-gm, $snow-white);
          }
        }
        &--yellow-car{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($yellow-task-gm, $snow-white);
          }
        }
        &--orange{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($orange-task-gm, $snow-white);
          }
        }
        &--brown{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($brown-task-gm, $snow-white);
          }
        }
        &--green{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($green-task-gm, $snow-white);
          }
        }
        &--light-grey{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($grey-task-gm, $snow-white);
          }
        }
      }
      &__block-state-task{
        display: table-cell;
      }
      &__icon-arrow-right, &__icon-arrow-left{
        @include icon-arrow($primary-color-gm);
      }
      &__icon-briefcase{
        @include icon-briefcase($primary-color-gm);
      }
      &__icon-button-active{
        color: $custom-black-landing;
      }
      &__icon-header-right{
        color: $primary-color-gm;
      }
      &__icon-help{
        margin-left: 9px;
        color: $custom-black-landing;
      }
      &__text-process-active{
        @include text-process-active($custom-black-landing);
       }
      &__text-process-inactive{
        font-family: 'MTTMilano', sans-serif;
        font-size: 14px;
        text-transform: none;
        position: relative;
        bottom: 1px;
      }
      &__text-question{
        @include text-question($custom-black-landing);
      }
      &__title-process{
        display: none;
      }
      &__title-process-state{
        color: $modal-question-gm;
      }
      &__states-proccess-text{
        @include states-proccess-text($custom-black-landing);
      }
    }
  }
  &__com-de{
    .states-process{
      border: 1px solid $third-color-com-de;
      background-color: transparent;
      top: -1px;
      &--question{
        background: $background-grey-disabled-com-de;
        border: none;
      }
      &__bar-progress{
          background-color: $blue-icam-it;
          &--next{
            background-color: $border-block-com-de;
            border-radius: 0 15px 15px 0;
          }
          &--prev{
            background-color: $primary-color-com-de;
            border-radius: 15px 0 0 15px;
          }
      }
      &__block-body-task{
        display: table;
      }
      &__block-header{
        &--question{
          height: 21px;
        }
      }
      &__block-repeat-states{
        @include block-repeat-states($custom-black-landing);
        @include block-repeat-states-color-state($primary-color-com-de, $snow-white);
        &--previous{
          left: 0;
          padding: 2px 6px 2px 0px;
        }
        &--red{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($alert-color-com-de, $snow-white);
          }
        }
        &--yellow-car{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($yellow-task-com-de, $snow-white);
          }
        }
        &--orange{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($orange-task-com-de, $snow-white);
          }
        }
        &--brown{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($brown-task-com-de, $snow-white);
          }
        }
        &--green{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($green-task-com-de, $snow-white);
          }
        }
        &--light-grey{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($grey-task-com-de, $snow-white);
          }
        }
      }
      &__block-state-task{
        display: table-cell;
      }
      &__icon-arrow-right, &__icon-arrow-left{
        @include icon-arrow($primary-color-com-de);
      }
      &__icon-briefcase{
        @include icon-briefcase($primary-color-com-de);
      }
      &__icon-button-active{
        color: $custom-black-landing;
      }
      &__icon-header-right{
        color: $primary-color-com-de;
      }
      &__icon-help{
        margin-left: 9px;
        color: $custom-black-landing;
      }
      &__text-process-active{
        @include text-process-active($custom-black-landing);
       }
      &__text-process-inactive{
        font-family: 'MTTMilano', sans-serif;
        font-size: 14px;
        text-transform: none;
        position: relative;
        bottom: 1px;
      }
      &__text-question{
        @include text-question($custom-black-landing);
      }
      &__title-process{
        display: none;
      }
      &__title-process-state{
        color: $modal-question-com-de;
      }
      &__states-proccess-text{
        @include states-proccess-text($custom-black-landing);
      }
    }
  }
  &__com-fr{
    .states-process{
      border: 1px solid $third-color-com-fr;
      background-color: transparent;
      top: -1px;
      &--question{
        background: $background-grey-disabled-com-fr;
        border: none;
      }
      &__bar-progress{
          background-color: $blue-icam-it;
          &--next{
            background-color: $border-block-com-fr;
            border-radius: 0 15px 15px 0;
          }
          &--prev{
            background-color: $primary-color-com-fr;
            border-radius: 15px 0 0 15px;
          }
      }
      &__block-body-task{
        display: table;
      }
      &__block-header{
        &--question{
          height: 21px;
        }
      }
      &__block-repeat-states{
        @include block-repeat-states($custom-black-landing);
        @include block-repeat-states-color-state($primary-color-com-fr, $snow-white);
        &--previous{
          left: 0;
          padding: 2px 6px 2px 0px;
        }
        &--red{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($alert-color-com-fr, $snow-white);
          }
        }
        &--yellow-car{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($yellow-task-com-fr, $snow-white);
          }
        }
        &--orange{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($orange-task-com-fr, $snow-white);
          }
        }
        &--brown{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($brown-task-com-fr, $snow-white);
          }
        }
        &--green{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($green-task-com-fr, $snow-white);
          }
        }
        &--light-grey{
          .states-process__block-repeat-states--active-color{
            @include block-repeat-states-color-state($grey-task-com-fr, $snow-white);
          }
        }
      }
      &__block-state-task{
        display: table-cell;
      }
      &__icon-arrow-right, &__icon-arrow-left{
        @include icon-arrow($primary-color-com-fr);
      }
      &__icon-briefcase{
        @include icon-briefcase($primary-color-com-fr);
      }
      &__icon-button-active{
        color: $custom-black-landing;
      }
      &__icon-header-right{
        color: $primary-color-com-fr;
      }
      &__icon-help{
        margin-left: 9px;
        color: $custom-black-landing;
      }
      &__text-process-active{
        @include text-process-active($custom-black-landing);
       }
      &__text-process-inactive{
        font-family: 'MTTMilano', sans-serif;
        font-size: 14px;
        text-transform: none;
        position: relative;
        bottom: 1px;
      }
      &__text-question{
        @include text-question($custom-black-landing);
      }
      &__title-process{
        display: none;
      }
      &__title-process-state{
        color: $modal-question-com-fr;
      }
      &__states-proccess-text{
        @include states-proccess-text($custom-black-landing);
      }
    }
  }
  &__landing-custom{
    .states-process{
      &__bar-progress{
          background: $custom-border-grey;
          &--active{
              background-color: $green;
          }
      }
      &__block-body-task{
          padding: 0;
          height: 33px;
          margin-top: 39px;
      }
      &__block-line-state{
          &--task{
              padding-bottom: 5px;
          }
      }
      &__block-repeat-states{
          &--special{
              background: none;
              border: none;
              outline: none;
              color: $custom-secondary-color;
              font-family: 'lato_regular', sans-serif;
              font-size: 10px;
              font-weight: bold;
              margin: 0;
              position: relative;
              &:hover:not([disabled]){
                  background-color:transparent;
                  color: $custom-black-landing;
              }
          }
      }
    }
  }
}

@media (max-width: 1258px) {
  .states-process {
      &__text-process-inactive{
          max-width: 220px;
      }
  }
}

@media (max-width: 1165px) {
  .states-process {
      &__text-process-inactive{
          max-width: 150px;
      }
  }
}

@media (max-width: 992px){
  .states-process{
      &__custom-block-state-task{
          width: 180px;
      }
      &__custom-container{
          &--task{
              width: 740px;
          }
      }
      .states-process {
          &__text-process-inactive{
              max-width: 100px;
          }
      }
  }
}
