.field-type-logo{
  &__form-block{
      border: 1px dotted;
      width: 100%;
      //margin-top: 63px;
      // height: 88px;
      position: relative;
      margin-bottom: 10px;
      min-height: 88px;
      &--favicon{
          min-height: 50px;
         
      }
      &--disabled{
          border-color: $attach-icon-grey !important;
          background: $background-grey;
      }
      &--error{
          border: 1px solid $red !important;
          background-color: $background-error !important;
      }
  }
  &__input-text{
      width: 100%;
      display: block;
      margin: 0;
      position: relative;
      padding-top: 5px;

  }
  &__modify-logo{
      border: none;
      background: none;
      outline: none;
      position: absolute;
      right: 0;
      bottom: 10px;
      margin-right: 6px;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      font-weight: 500;
  }
  &__modify-icon{
      font-size: 20px;
      position: relative;
      top: 4px;
      padding-right: 5px;
  }
  &__logo-size{
      position: relative;
      top: -15px;
  }

  &__client-img{
      max-width: 320px;
      max-height: 50px;
      padding-top: 1px;
      margin: -10px auto 0;
      display: block;
  }

  &__img-block{
      width: 100%;
      margin-left: 0px;
      display: block;
  }
  &__img-text{
      display: block;
      width: 45%;
      position: absolute;
      bottom: 0;
  }

  &__text-logo{
      margin-left: 10px;
      color: $secondary-color;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
      white-space: nowrap;
      width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
      &--favicon{
          margin-bottom: 0px;
      }
  }

  &__input-logo {
    visibility: hidden;
    width: 0px;
    height: 0px;
    float: right;
  }
}

.compliance{
  &__steel-blue,
  &__uk-steel-blue{
    .field-type-logo{
      &__modify-logo{
          color: $primary-color-2;
      }
    }
  }
  &__icam-red{
    .field-type-logo{
      &__modify-logo{
          color: $primary-color-icam;
      }
      &__text-logo{
          color: $text-field-color-icam;
      }
    }
  }
  &__gm-law{
    .field-type-logo{
      &__modify-logo{
          color: $primary-color-gm;
      }
      &__text-logo{
          color: $text-field-color-gm;
      }
    }
  }
  &__com-de{
    .field-type-logo{
      &__modify-logo{
          color: $primary-color-com-de;
      }
      &__text-logo{
          color: $text-field-color-com-de;
      }
    }
  }
  &__com-fr{
    .field-type-logo{
      &__modify-logo{
          color: $primary-color-com-fr;
      }
      &__text-logo{
          color: $text-field-color-com-fr;
      }
    }
  }
}
