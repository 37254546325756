@mixin edition-comparator-form-block-header-group-field($backgroundColor, $color){
  background-color: $backgroundColor;
  color: $color;
  text-transform: none;
  height: 34px;
}

@mixin edition-comparator-form-text-diff($color){
  color: $color !important;
  background-color: $background-error !important;
  font-style: italic;
}

.edition-comparator-form{
  &__block-form{
      position: relative;
      float: left;
      width: 100%;
  }

  &__block-header-group{
      height: 30px;
      width: 100%;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      color: $snow-white;
      background-color: $primary-color;
      display: table;
      position: relative;
      padding: 2px 0 0 9px;
  }

  &__text-header-group{
      display: table-cell;
      vertical-align: middle;
  }

  &__block-group{
      padding: 0 0 20px 10px;
      border-left: 1px solid $box-comment-border-03;

      &--subgroup{
          padding-top: 15px;
      }
      &--first-subgroup{
          padding-top: 0;
          margin-top: 10px;
      }
      &--field{
          width: 100%;
          padding: 0 0 15px 10px;
      }
  }

  &__block-inner-group{
      position: relative;
  }

  &__block-form-group{
      padding: 20px 0 0px 9px;
  }

  &__block-form-field{
      padding: 15px 0 0px 9px;
  }

  &__block-fields-group{
      display: table;
      position: relative;
      width: 100%;
      margin-bottom: 8px;
      &--field{
          margin-bottom: 15px;
      }
      &--big-margin{
          margin-bottom: 30px;
      }
      &--last-field{
          margin-bottom: 0;
      }
  }

  &__block-fields-group-lco{
      width: 100%;
      margin-bottom: 15px;
  }

  &__block-cell-label{
      display: table-cell;
      vertical-align: top;
      width: 40%;
      padding: 8px 0px 0 0;
      &--padding-0{
          padding-top: 0;
      }
  }

  &__block-cell-input{
      display: table-cell;
      vertical-align: text-top;
      width: 60%;
  }

  &__block-cell-inner-input-field{
      padding-left: 22px;
      &--block-group-cell{
          padding-left: 12px;
      }
  }

  &__block-cell-inner-input-subfield{
      padding-left: 10px;
      &--block-group-cell{
          padding-left: 0px;
      }
  }

  &__block-cell-label-field{
      display: table-cell;
      vertical-align: top;
      width: 35%;
  }

  &__block-cell-input-field{
      display: table-cell;
      vertical-align: text-top;
      width: 65%;
  }

  &__text-label{
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      line-height: 18px;
      color: $pillow-group;
  }

  &__text-value{
      min-height: 34px;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      color: $primary-color;
      display: block;
      background-color: $background-color-states;
      &--list{
          margin-bottom: 2px;
      }

  }

  &__text-value-italic{
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      font-style: italic;
      font-weight: 500;
      line-height: 18px;
      padding-right: 12px;
      color: $pillow-group;
  }

  &__text-value-user{
      position: relative;
      float: left;
      width: 37%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-right: 3px;
      min-height: 18px;
      &--mail{
          width: 26%;
      }
  }

  &__block-text{
      position: relative;
      padding: 8px 10px 6px;
  }

  &__block-lco{
      position: relative;
      display: table;
      width: 100%;
  }

  &__side-band-color{
      position: absolute;
      width: 5px;
      height: 100%;
      left: 0px;
      top: 0px;

  }

  &__value-bind-html{
      display: inline-block;
      margin: 0;
  }



  &__block-diff-forms{
      position: absolute;
      width: 20px;
      height: 100%;
      background-color: $background-error;
      top: 0px;
      display: none;
      &--show-left{
          display: block;
          right: -63px;
      }
      &--show-right{
          display: block;
          left: -81px;
      }
      &--show-right-title{
          display: block;
          left: -72px;
      }
      &--show-right-subgroup{
          display: block;
          left: -101px;
      }
      &--show-right-title-subgroup{
          display: block;
          left: -92px;
          top: -1px;
      }
  }

  &__hidden-block{
      visibility: hidden;
  }
}

.compliance{
  &__dark-blue{
      .edition-comparator-form{
          &__block-header-group{
              &--subgroup{
                  background-color: $pillow-group;
              }
              &--field{
                  @include edition-comparator-form-block-header-group-field($background-color-states, $primary-color);
              }
          }
          &__text-value{
              &--disabled{
                  background-color: $background-grey;
                  color: $attach-icon-grey;
              }
          }
          &__side-band-color{
              &--green{
                  background-color: $green;
              }
              &--red{
                  background-color: $red;
              }
              &--yellow {
                  background-color: $yellow;
              }
          }
          &__text{
              &-diff{
                  @include edition-comparator-form-text-diff($red);
              }
          }
      }
  }
  &__steel-blue,
  &__uk-steel-blue{
      .edition-comparator-form{
          &__block-header-group{
              background-color: $primary-color-2;
              &--subgroup{
                  background-color: $grey-icons-2;
              }
              &--field{
                  @include edition-comparator-form-block-header-group-field($block-hover-2, $primary-color-2);
              }
          }
          &__block-group{
              border-color: $background-grey-disabled-2;
          }
          &__text-label{
              color: $grey-icons-2;
          }
          &__text-value{
              background-color: $block-hover-2;
              color: $primary-color-2;
              &--disabled{
                  background-color: $background-grey-disabled-2;
                  color: $text-field-color-2;
              }
          }
          &__text-value-italic{
              color: $grey-icons-2;
          }
          &__side-band-color{
              &--green{
                  background-color: $green-color-v2;
              }
              &--red{
                  background-color: $alert-color-2;
              }
              &--yellow{
                  background-color: $warning-yellow;
              }
          }
          &__text{
              &-diff{
                  @include edition-comparator-form-text-diff($alert-color-2);
              }
          }
      }
  }
  &__icam-red{
      .edition-comparator-form{
          &__block-header-group{
              background-color: $custom-black-landing;
              &--subgroup{
                  background-color: $text-field-color-icam;
              }
              &--field{
                  @include edition-comparator-form-block-header-group-field($hover-block-icam, $custom-black-landing);
              }
          }
          &__block-group{
              border-color: $background-grey-disabled-icam;
          }
          &__text-label{
              color: $text-field-color-icam;
          }
          &__text-value{
              background-color: $hover-block-icam;
              color: $custom-black-landing;
              &--disabled {
                  background-color: $background-grey-disabled-icam;
                  color: $text-field-color-icam;
              }
          }
          &__text-value-italic{
              color: $text-field-color-icam;
          }
          &__side-band-color{
              &--green{
                  background-color: $green-task-icam;
              }
              &--red{
                  background-color: $alert-color-icam;
              }
          }
          &__text{
              &-diff{
                  @include edition-comparator-form-text-diff($alert-color-icam);
              }
          }
      }
  }
  &__gm-law{
      .edition-comparator-form{
          &__block-header-group{
              background-color: $custom-black-landing;
              &--subgroup{
                  background-color: $text-field-color-gm;
              }
              &--field{
                  @include edition-comparator-form-block-header-group-field($hover-block-gm, $custom-black-landing);
              }
          }
          &__block-group{
              border-color: $background-grey-disabled-gm;
          }
          &__text-label{
              color: $text-field-color-gm;
          }
          &__text-value{
              background-color: $hover-block-gm;
              color: $custom-black-landing;
              &--disabled {
                  background-color: $background-grey-disabled-gm;
                  color: $text-field-color-gm;
              }
          }
          &__text-value-italic{
              color: $text-field-color-gm;
          }
          &__side-band-color{
              &--green{
                  background-color: $green-task-gm;
              }
              &--red{
                  background-color: $alert-color-gm;
              }
          }
          &__text{
              &-diff{
                  @include edition-comparator-form-text-diff($alert-color-gm);
              }
          }
      }
  }
  &__com-de{
    .edition-comparator-form{
        &__block-header-group{
            background-color: $custom-black-landing;
            &--subgroup{
                background-color: $text-field-color-com-de;
            }
            &--field{
                @include edition-comparator-form-block-header-group-field($hover-block-com-de, $custom-black-landing);
            }
        }
        &__block-group{
            border-color: $background-grey-disabled-com-de;
        }
        &__text-label{
            color: $text-field-color-com-de;
        }
        &__text-value{
            background-color: $hover-block-com-de;
            color: $custom-black-landing;
            &--disabled {
                background-color: $background-grey-disabled-com-de;
                color: $text-field-color-com-de;
            }
        }
        &__text-value-italic{
            color: $text-field-color-com-de;
        }
        &__side-band-color{
            &--green{
                background-color: $green-task-com-de;
            }
            &--red{
                background-color: $alert-color-com-de;
            }
        }
        &__text{
            &-diff{
                @include edition-comparator-form-text-diff($alert-color-com-de);
            }
        }
    }
  }
  &__com-fr{
    .edition-comparator-form{
        &__block-header-group{
            background-color: $custom-black-landing;
            &--subgroup{
                background-color: $text-field-color-com-fr;
            }
            &--field{
                @include edition-comparator-form-block-header-group-field($hover-block-com-fr, $custom-black-landing);
            }
        }
        &__block-group{
            border-color: $background-grey-disabled-com-fr;
        }
        &__text-label{
            color: $text-field-color-com-fr;
        }
        &__text-value{
            background-color: $hover-block-com-fr;
            color: $custom-black-landing;
            &--disabled {
                background-color: $background-grey-disabled-com-fr;
                color: $text-field-color-com-fr;
            }
        }
        &__text-value-italic{
            color: $text-field-color-com-fr;
        }
        &__side-band-color{
            &--green{
                background-color: $green-task-com-fr;
            }
            &--red{
                background-color: $alert-color-com-fr;
            }
        }
        &__text{
            &-diff{
                @include edition-comparator-form-text-diff($alert-color-gm);
            }
        }
    }
  }
  &__landing-custom{
      .edition-comparator-form{
          &__side-band-color{
              &--green{
                  background-color: $green;
              }
              &--red{
                  background-color: $red;
              }
          }
      }
  }
}

@media (max-width: 920px) {
  .edition-comparator-form{
      &__block-diff-forms{
          right: -40px;
      }
  }
}

@media print {
  .edition-comparator-form{
      &__block-header-group, &__text-value{
          box-shadow: 0 0 3px 1px $bar-horizontal-grey-opacity02 !important;
      }
      &__value-bind-html{
          *{
              color: inherit !important;
          }
      }
  }
}
