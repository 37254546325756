.customize-concept {
  position: relative;
  float: left;
  &__block-alert-instanciable-concept{
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 55px;
      background-color: $yellow-opacity-02;
      padding: 6px 17px;

  }

  &__block-text-alert{
      position: relative;
      width: 65%;
      float: left;
  }

  &__block-button-alert{
      width: 35%;
      float: right;
      position: relative;
      text-align: center;
  }

  &__icon-alert{
      font-size: 40px;
      color: $yellow;
      margin-right: 12px;

  }

  &__text-alert{
      color: $secondary-color;
      font-family: Arial , sans-serif;
      font-size: 12px;
      position: relative;
      bottom: 12px;

  }

  &__info-block-icon {
      font-size: 25px;
      color: $primary-color;

      &:hover {
          cursor: pointer;
      }
  }

  &__button-box-alert{
      width: auto;
      height: 29px;
      position: relative;
      border: 0;
      display: inline-block;
      font-size: 18px;
      top: 3px;
  }

  &__alert-button{
      border: none;
      height: 26px;
      margin-bottom: 3px;
      width: 100%;
      color: $primary-color;
      position: relative;
      top: 1px;
      outline: none;
      background-color: transparent;
      padding: 0 10px 0 6px;
      &:hover{
          color: $snow-white;
          background-color: $primary-color;
      }

      &:after{
          content: " ";
          border-bottom: 2px solid $primary-color;
          width: calc(100% + 16px);
          display: block;
          right: 6px;
          top: 2px;
          position: relative;
      }
  }


  &__triangle-box {
      width: 0;
      height: 0;
      position: absolute;
      left: 25px;
      top: 6px;
      border-top: 5px solid transparent;
      border-right: 5px solid $primary-color;
      border-bottom: 5px solid transparent;
      border-left: 5px solid transparent;

  }

  &__info-block {
      display: block;
      position: absolute;
      top: 0;
      left: 35px;
      width: 69%;
      height: 44px;
      border: 1px solid $subcontainer-poll-border;
      background: $background-color-states;
      border-left: 4px solid $primary-color;
      padding: 2px 8px 2px 12px;
      overflow: hidden;
      z-index: 9;

      &--open {
          height: 412px;
      }

  }

  &__info-block-title {
      color: $primary-color;
      font-family: Arial , sans-serif;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 4px;

  }

  &__info-steps-block {
      margin: 0;
      padding: 0;
      width: calc(100% - 14px);
      float: left;
      position: relative;
  }

  &__info-step {
      list-style: none;
      display: inline-block;
      padding-right: 15px;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 11px;

  }

  &__icon-step {
      position: relative;
      top: 3px;
      padding-right: 2px;
      font-size: 15px;
  }

  &__icon-open-info {
      color: $primary-color;
      font-size: 18px;
      position: absolute;
      right: 4px;
      &:hover {
          cursor: pointer;
      }

  }

  &__desactive-box {
      height: 264px;
      width: 100%;
      border: 2px dotted $primary-color;
      border-radius: 10px;
      margin-top: 24px;
      text-align: center;
      padding-top: 75px;
      opacity: 0.4;

      &--inner-group {
          opacity: 1;
          height: 247px;
          overflow: hidden;
      }

      &--with-fields {
          height: 100px;
          padding-top: 12px;
      }
  }

  &__desactive-icon {
      font-size: 40px;
      color: $primary-color;

  }

  &__desactive-title {
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 18px;
      margin-bottom: 2px;

  }

  &__desactive-text {
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 18px;
      margin-bottom: 1px;

  }

  &__list-info-title {
      border: 1px solid $box-comment-border-03;
      background-color: $box-comment-border-03;
      padding: 1px 10px;
      border-radius: 37px;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 10px;
      text-transform: uppercase;

  }

  &__info-list {
      padding: 0;
      margin: 0;
      margin-top: 53px;
      list-style: none;
  }

  &__list-info-text {
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 11px;
      margin-top: 7px;
      margin-bottom: 14px;

  }

  &__group-block {
      width: 100%;
      display: block;
      padding: 0;
      margin: 0;
      margin-top: 26px;
      padding-bottom: 50px;
      list-style: none;

      &--subcontainer {
          margin-top: 10px;
          padding: 0px 10px 50px;
          list-style: none;

          & .dndPlacerholder {
              display: none;
          }
      }

      & .dndPlacerholder {
          display: none;
      }
  }

  &__group {
      width: 100%;
      display: block;
      padding-bottom: 10px;
      position: relative;

      &:last-child {
          padding-bottom: 0;
      }
  }

  &__group-header {
      width: 100%;
      height: 50px;
      display: block;
      background-color: $primary-color;


      &--subcontainer {
          background-color: $pillow-group;
      }


      &--fields {
          background-color: $background-color-states;
          border: 1px solid $subcontainer-border;
      }



      &--highlight{
          background-color: $group-heather-hightlight;
      }


      &--big{
          height: 85px;
      }
      &--great-big{
          height: 148px;
      }
      // &--muzzy{
      //     height: 600px;
      // }

      &:hover {
          & > .customize-concept__group-header-right{
              & > .customize-concept__group-icon--arrow {
                  margin-right: 53px;
              }

              & > .customize-concept__group-icon--no-delete {
                  margin-right: 23px;
              }
          }

          & > .customize-concept__group-header-options {
              & > .customize-concept__group-icon--arrow {
                  margin-right: 48px;
              }

              & > .customize-concept__group-icon--no-delete {
                  margin-right: 23px;
              }
          }

          & > .customize-concept__fieldcontainer-menu {
              display: block;
          }
      }
  }

  &__group-header-left {
      display: block;
      width: 70%;
      overflow: hidden;
      float: left;
      height: 50px;
      padding-top: 1px;
  }

  &__group-header-right {
      display: block;
      width: 30%;
      overflow: hidden;
      float: right;
      height: 50px;
      position: relative;
      padding-top: 3px;
  }

  &__group-header-channel-view{
    display: flex;
    padding: 6px var(--spacing-xl, 24px) 6px 8px;
    align-items: center;
    align-self: stretch;
    height: 50px;
  }

  &__group-header-name{
      display: block;
      width: calc(100% - 101px);
      overflow: hidden;
      float: left;
      height: 50px;
      padding-top: 1px;
      &--open{
          width: calc(100% - 24px);
          height: 43px;
      }
  }

  &__group-header-options{
      display: block;
      width: 101px;
      overflow: hidden;
      float: left;
      height: 50px;
      padding-top: 3px;
      // padding-top: 1px;
      &--open{
          width: 100%;
          padding: 0px 24px 0 42px;
          height: 42px;
          padding-top: 0px;
      }
  }

  &__show-inner-icon {
      float: left;
      color: $snow-white;
      font-size: 19px;
      padding: 14px 14px 0 9px;

      &--fields {
          color: $primary-color;
      }

      &:hover {
          cursor: pointer;
      }
  }

  &__group-highlight {
      float: left;
      color: $highlight-border;
      font-size: 18px;
      padding-top: 13px;
      padding-right: 4px;
  }

  &__group-name {
      color: $snow-white;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 14px;
      float: left;
      padding-top: 13px;
      padding-right: 12px;

      &--input {
          width: calc(100% - 42px);
          margin-top: 6px;
          background: none;
          border: 1px solid $snow-white;
          padding: 7px 9px;
          outline: none;

          &:disabled {
              padding: 0;
              margin-top: 13px;
              background: none;
              border: none;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
          }
      }

      &--input-highlight {
          width: calc(100% - 72px);
      }

      &--fields {
          color: $primary-color;
          border: 1px solid $primary-color;

          &:disabled {
              border: none;
          }
      }

      &--input-private{
          width: calc(100% - 66px);
      }
      &--input-both{
          width: calc(100% - 88px);
      }
      &--field-private{
          float: left;
          width: calc(100% - 66px);
      }
      &--channel-view{
        width: 100%;
        margin: 0;
      }
  }

  &__group-icon {
      float: left;
      color: $snow-white;
      padding-top: 10px;
      font-size: 21px;
      &--more {
          float: right;
          font-size: 27px;
          padding-top: 8px;
      }

      &--more-field{
          float: right;
          font-size: 27px;
          padding-top: 8px;
          color: $primary-color;
      }


      &--arrow {
          float: right;
      }

      &--fields {
          color: $primary-color;
      }

      &:hover {
          cursor: pointer;
      }
  }

  &__block-highlight{
      float: left;
      margin: 10px 20px 0 0;
  }

  &__button-add-subgroup{
      float: right;
      background: transparent;
      border: none;
      outline: none;
      padding: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 10px;
  }

  &__add-subgroup {
      color: $snow-white;
      font-family: Arial , sans-serif;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;

      &:hover {
          cursor: pointer;
      }

      &--disabled{
          opacity: 0.5;
          &:hover{
              cursor: default;
          }
      }
  }

  &__add-subgroup-icon {
      font-size: 20px;
      color: $snow-white;

      &:hover {
          cursor: pointer;
      }

      &--disabled {
          // cursor: auto;
          opacity: 0.5;
          &:hover {
              cursor: default;
          }
      }
  }

  &__radio-button-block{
      float: left;
  }

  &__radio-text {
      float: left;
      margin-left: 6px;
      padding-top: 15px;
      margin-right: 20px;
      text-transform: uppercase;
      color: $snow-white;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;

  }

  &__fieldcontainer-menu {
      height: 42px;
      width: auto;
      padding-right: 4px;
      border: 1px solid $menu-icons-border;
      background-color: $background-grey-other;
      position: absolute;
      right: 0;
      top: 4px;
      border-radius: 50px 0 0 50px;
      display: none;
      &--field {
          top: 12px;
          right: 18px;
      }
  }

  &__group-body {
      min-height: 305px;
      display: block;
      width: 100%;
      border: 1px solid $subcontainer-border;
      border-top: 0;
      background-color: $snow-white;
      padding: 20px 12px 12px;
      position: relative;

      &--field {
          background: $snow-white;
      }

      &--properties {
          min-height: unset;
          background: $snow-white;
          margin-top: 20px;
          border-top: 1px solid $subcontainer-border;
          padding-top: 5px;
          padding-bottom: 10px;
      }

      &--form{
          float: left;
      }
  }

  &__help {
      font-size: 12px;
      font-style: italic;
      color: $subcontainer-ground;
      margin:0;
  }

  &__menu-icon {
      color: $primary-color;
      font-size: 22px;
      position: relative;
      top: 8px;

      &--writing {
          padding-left: 15px;
      }

      &--delete {
          padding-left: 3px;
      }

      &:hover {
          cursor: pointer;
      }
  }

  &__icon-close-header{
      color: $secondary-color;
      font-size: 15px;
      position: absolute;
      top: 20px;
      right: 10px;
      &:hover{
          cursor: pointer;
      }
  }

  &__icon-delete-opened {
      float: right;
      padding-right: 6px;
  }

  &__field-list-block {
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;

      // &.dndPlacerholder {
      //     list-style: none;
      // }

      // &.dndDragover {
      //     & .customize-concept__inner-info {
      //         & .customize-concept__desactive-box--inner-group {
      //             border-color: $green;
      //         }
      //     }
      // }
  }

  &__inner-info {
      list-style: none;
      margin-top: 30px;
  }

  &__field-list-block-customize {
      padding-bottom: 10px;
      margin-bottom: 0;
      padding-left: 0;
      list-style: none;

      // & .dndDragging {
      //     opacity: 0.7;
      // }

      // & .dndDraggingSource {
      //     opacity: 0.5;
      // }

      // & .dndPlaceholder {
      //     min-height: 25px !important;
      //     opacity: unset !important;
      //     width: 100%;
      // }

      // &.dndDragover {
      //     & .customize-concept__inner-info {
      //         & .customize-concept__desactive-box--inner-group {
      //             border-color: $green;
      //         }
      //     }
      // }
  }

  &__group-active {
      position: absolute;
      width: 5px;
      background: $primary-color;
      top: 0;
      height: 85px;
      left: -13px;

      &--subcontainer {
          background: $pillow-group;
      }

      &--great-big{
          height: 148px;
      }
  }

  &__buttons-block {
      display: inline-block;
      position: relative;
      float: right;
      margin: 26px 62px 0px 0px;
  }

  &__interval-icon {
      display: inline-block;
      color: $snow-white;
      font-size: 12px;
  }

  &__private-icon{
      font-size: 20px;
      color: $snow-white;
      float: left;
      padding-top: 12px;
      padding-right: 4px;
  }

  &__block-body-form{
      position: relative;
      width: 100%;
      margin-top: 20px;
      // &--header{
      //     margin-top: 55px;
      // }
  }

  &__field-item{
    list-style-type: none;
    width: 100%;
  }
}
// .dndPlaceholder{
//   opacity: 0.9;
//   height: 25px;
//   width: 100%;
// }

@media(min-width: 1000px) and (max-width: 1230px){
  .customize-concept__group-header-left{
      width: 30%;

  }
  .customize-concept__group-header-right{
      width: 70%;
  }
}
@media (min-width: 1230px) and (max-width: 1355px){
  .customize-concept__group-header-left{
      width: 45%;

  }
  .customize-concept__group-header-right{
      width: 55%;
  }
}

.compliance{
  &__gm-law{
    .customize-concept {
      &__block-alert-instanciable-concept{
          background-color: $yellow-task-36-gm;
      }
      &__icon-alert{
          color: $yellow-task-gm;
      }
      &__text-alert{
        color: $custom-black-landing;
      }
      &__info-block-icon {
            color: $primary-color-gm;
      }
      &__alert-button{
          color: $primary-color-gm;
          &:hover{
              color: $snow-white;
              background-color: $primary-color-gm;
          }

          &:after{
              border-bottom: 2px solid $primary-color-gm;
          }
          &--other{
              &:hover{
                  color: $primary-color-gm;
                  background-color: $hover-block-gm;
              }
          }
      }
      &__triangle-box {

              border-right: 5px solid $custom-black-landing;

      }
      &__info-block {
              background: $background-grey-disabled-gm;
              border-left: 4px solid $custom-black-landing;

      }
      &__info-block-title {

              color: $custom-black-landing;
          }


      &__info-step {

              color: $custom-black-landing;

      }
      &__icon-open-info {

              color: $primary-color-gm;

      }
      &__desactive-box {

              border: 2px dotted $custom-black-landing;

      }
      &__desactive-icon {

              color: $custom-black-landing;

      }
      &__desactive-title {

              color: $custom-black-landing;

      }
      &__list-info-title {

              background-color: $black-opacity;
              border: 1px solid $black-opacity;
              color: $custom-black-landing;

      }
    &__list-info-text {

              color: $custom-black-landing;

      }

      &__group-header {
          background-color: $custom-black-landing;
          &--subcontainer {
              background-color: $text-field-color-gm;
          }
          &--fields {
              background-color: $background-grey-gm;
              border: 1px solid $border-block-gm;
          }
          &--highlight{
              background-color: $background-grey-gm;
          }
      }
      &__show-inner-icon {
          &--fields{
              color: $primary-color-gm;
          }
      }
      &__group-highlight {
          color: $third-color-gm;
      }

      &__group-name {
        &--fields{
            color: $custom-black-landing;
            border: 1px solid $custom-black-landing;
            &:disabled {
                border: none;
            }
        }
      }
      &__group-icon {
        &--more-field{
            color: $primary-color-gm;
        }
        &--fields {
            color: $primary-color-gm;
        }
      }
      &__fieldcontainer-menu {
        background-color: $primary-color-gm;
        border: 1px solid $primary-color-gm;

      }
      &__group-body {
          border: 1px solid $border-block-gm;
      }
      &__help {
        color: $secondary-color-gm;
      }
      &__icon-close-header{
        color: $custom-black-landing;
      }
      &__menu-icon {
        color: $snow-white;
      }
      // &__field-list-block-customize{
      //     &.dndDragover {
      //         & .customize-concept__inner-info {
      //             & .customize-concept__desactive-box--inner-group {
      //                 border-color: $third-color-gm;
      //                 background-color: $hover-block-gm;
      //             }
      //         }
      //     }
      // }
    }
  }
  &__com-de{
    .customize-concept {
      &__block-alert-instanciable-concept{
          background-color: $yellow-task-36-com-de;
      }
      &__icon-alert{
          color: $yellow-task-com-de;
      }
      &__text-alert{
        color: $custom-black-landing;
      }
      &__info-block-icon {
            color: $primary-color-com-de;
      }
      &__alert-button{
          color: $primary-color-com-de;
          &:hover{
              color: $snow-white;
              background-color: $primary-color-com-de;
          }

          &:after{
              border-bottom: 2px solid $primary-color-com-de;
          }
          &--other{
              &:hover{
                  color: $primary-color-com-de;
                  background-color: $hover-block-com-de;
              }
          }
      }
      &__triangle-box {

              border-right: 5px solid $custom-black-landing;

      }
      &__info-block {
              background: $background-grey-disabled-com-de;
              border-left: 4px solid $custom-black-landing;

      }
      &__info-block-title {

              color: $custom-black-landing;
          }


      &__info-step {

              color: $custom-black-landing;

      }
      &__icon-open-info {

              color: $primary-color-com-de;

      }
      &__desactive-box {

              border: 2px dotted $custom-black-landing;

      }
      &__desactive-icon {

              color: $custom-black-landing;

      }
      &__desactive-title {

              color: $custom-black-landing;

      }
      &__list-info-title {

              background-color: $black-opacity;
              border: 1px solid $black-opacity;
              color: $custom-black-landing;

      }
    &__list-info-text {

              color: $custom-black-landing;

      }

      &__group-header {
          background-color: $custom-black-landing;
          &--subcontainer {
              background-color: $text-field-color-com-de;
          }
          &--fields {
              background-color: $background-grey-com-de;
              border: 1px solid $border-block-com-de;
          }
          &--highlight{
              background-color: $background-grey-com-de;
          }
      }
      &__show-inner-icon {
          &--fields{
              color: $primary-color-com-de;
          }
      }
      &__group-highlight {
          color: $third-color-com-de;
      }

      &__group-name {
        &--fields{
            color: $custom-black-landing;
            border: 1px solid $custom-black-landing;
            &:disabled {
                border: none;
            }
        }
      }
      &__group-icon {
        &--more-field,
        &--fields {
            color: $primary-color-com-de;
        }
      }
      &__fieldcontainer-menu {
        background-color: $snow-white;
        border: 1px solid $snow-white;
      }
      &__menu-icon {
        color: $primary-color-com-de;
      }
      &__group-body {
          border: 1px solid $border-block-com-de;
      }
      &__help {
        color: $secondary-color-com-de;
      }
      &__icon-close-header{
        color: $custom-black-landing;
      }

    }
  }
  &__com-fr{
    .customize-concept {
      &__block-alert-instanciable-concept{
          background-color: $yellow-task-36-com-fr;
      }
      &__icon-alert{
          color: $yellow-task-com-fr;
      }
      &__text-alert{
        color: $custom-black-landing;
      }
      &__info-block-icon {
            color: $primary-color-com-fr;
      }
      &__alert-button{
          color: $primary-color-com-fr;
          &:hover{
              color: $snow-white;
              background-color: $primary-color-com-fr;
          }

          &:after{
              border-bottom: 2px solid $primary-color-com-fr;
          }
          &--other{
              &:hover{
                  color: $primary-color-com-fr;
                  background-color: $hover-block-com-fr;
              }
          }
      }
      &__triangle-box {

              border-right: 5px solid $custom-black-landing;

      }
      &__info-block {
              background: $background-grey-disabled-com-fr;
              border-left: 4px solid $custom-black-landing;

      }
      &__info-block-title {

              color: $custom-black-landing;
          }


      &__info-step {

              color: $custom-black-landing;

      }
      &__icon-open-info {

              color: $primary-color-com-fr;

      }
      &__desactive-box {

              border: 2px dotted $custom-black-landing;

      }
      &__desactive-icon {

              color: $custom-black-landing;

      }
      &__desactive-title {

              color: $custom-black-landing;

      }
      &__list-info-title {

              background-color: $black-opacity;
              border: 1px solid $black-opacity;
              color: $custom-black-landing;

      }
    &__list-info-text {

              color: $custom-black-landing;

      }

      &__group-header {
          background-color: $custom-black-landing;
          &--subcontainer {
              background-color: $text-field-color-com-fr;
          }
          &--fields {
              background-color: $background-grey-com-fr;
              border: 1px solid $border-block-com-fr;
          }
          &--highlight{
              background-color: $background-grey-com-fr;
          }
      }
      &__show-inner-icon {
          &--fields{
              color: $primary-color-com-fr;
          }
      }
      &__group-highlight {
          color: $third-color-com-fr;
      }

      &__group-name {
        &--fields{
            color: $custom-black-landing;
            border: 1px solid $custom-black-landing;
            &:disabled {
                border: none;
            }
        }
      }
      &__group-icon {
        &--more-field,
        &--fields {
            color: $primary-color-com-fr;
        }
      }
      &__fieldcontainer-menu {
        background-color: $snow-white;
        border: 1px solid $snow-white;
      }
      &__menu-icon {
        color: $primary-color-com-fr;
      }
      &__group-body {
          border: 1px solid $border-block-com-fr;
      }
      &__help {
        color: $secondary-color-com-fr;
      }
      &__icon-close-header{
        color: $custom-black-landing;
      }
    }
  }
}
