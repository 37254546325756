.concept-instances-list {
  //////////////////////
  /////Version 2///////
  /////////////////////
  padding: 10px;
  &__col{
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding-right: 5px;
      &--tooltip {
          max-width: 0;
      }
      &--first{
          padding-left: 8px;
      }
      &--top-1{
          position: relative;
          top: 1px;
      }
      &--top-2{
          position: relative;
          top: 2px;
      }
      &--title{
          width: 20%;
      }
      &--channel{
          width: 20%;
      }
      &--author{
          width: 19%;
      }
      &--header-creation{
          width: 19%;
          padding-top: 1px;
      }
      &--creation{
          width: 19%;
      }
      &--state{
          width: calc(22% - 80px);
      }
      &--state-body{
          width: calc(22% - 22px);
      }
      &--actions{
          width: 80px;
          padding-right: 0;
          position: relative;
      }
      &--actions-body{
          width: 22px;
          padding-right: 0;
          position: relative;
      }
      &--client-uk{
          // width: 15%;
          width: 21%;
      }
      &--cif-uk{
          // width: 15%;
          width: 18%;
      }
      &--external-id-uk{
          // width: 15%;
          width: 18%;
      }
      &--process-uk{
          // width: 15%;
          width: 21%;
      }
      &--name-uk{
          // width: calc(40% - 300px);
          width: 22%;
      }
      // &--progress-uk{
      //     width: 110px;
      //     min-width: 110px;
      // }
      &--actions-uk{
          width: 150px;
          min-width: 150px;
          padding-right: 0;
          position: relative;
      }
      &--reference-icam{
          width: 98px;
          min-width: 98px;
          padding-left: 5px;
      }
      &--type-icam{
          width: 70px;
          min-width: 70px;
      }
      &--creation-icam{
          width: 105px;
          min-width: 105px;
      }
      &--state-icam{
          width: calc(78% - 433px);
          padding-right: 0;
          // width: 17%;
      }
      &--title-icam{
          width: 22%;
          // width: calc(83% - 433px);
      }
      &--alerts-icam{
          width: 65px;
          min-width: 65px;
          padding-right: 0;
          position: relative;
      }
      &--actions-icam{
          width: 55px;
          min-width: 55px;
          padding-right: 0;
          position: relative;
      }
      &--confidential{
          width: 40px;
          min-width: 40px;
          text-align: center;
      }
  }

  //////////////////////
  ////Fin Version 2/////
  /////////////////////

  //////////////////////
  /////Version UK///////
  /////////////////////
  &__progress-bar{
      width: 82px;
      height: 10px;
      border-radius: 5px;
      &--red{
          background-color: $red-opacity-uk-36;
      }
      &--green{
          background-color: $green-opacity-uk-36;
      }
      &--yellow{
          background-color: $yellow-opacity-uk-36;
      }
  }
  /////////////////////
  ///Fin Version UK///
  ///////////////////
  &__col-text-alert{
      left: 30px;
      position: relative;
  }
  &__icon-alert{
      font-size: 20px;
      position: absolute;
      color: $grey-task-36-icam;
  }
}

.compliance{
  &__icam-red{
    .concept-instances-list{
      &__icon-alert{
        &--grey{
          color: $grey-task-36-icam;
        }
        &--green{
          color: $green-task-36-icam;
        }
        &--yellow{
          color: $yellow-task-36-icam;
        }
        &--red{
          color: $red-task-36-icam;
        }
      }
    }
  }
  &__gm-law{
    .concept-instances-list{
      &__icon-alert{
        &--grey{
          color: $grey-task-36-gm;
        }
        &--green{
          color: $green-task-36-gm;
        }
        &--yellow{
          color: $yellow-task-36-gm;
        }
        &--red{
          color: $red-task-36-gm;
        }
      }
    }
  }
  &__com-de{
    .concept-instances-list{
      &__icon-alert{
        &--grey{
          color: $grey-task-36-com-de;
        }
        &--green{
          color: $green-task-36-com-de;
        }
        &--yellow{
          color: $yellow-task-36-com-de;
        }
        &--red{
          color: $red-task-36-com-de;
        }
      }
    }
  }
  &__com-fr{
    .concept-instances-list{
      &__icon-alert{
        &--grey{
          color: $grey-task-36-com-fr;
        }
        &--green{
          color: $green-task-36-com-fr;
        }
        &--yellow{
          color: $yellow-task-36-com-fr;
        }
        &--red{
          color: $red-task-36-com-fr;
        }
      }
    }
  }
}

@media (min-width: 1250px) and (max-width: 1366px){
  .concept-instances-list{
      &__col{
          &--state-icam{
              width: calc(85% - 433px);
          }
          &--title-icam{
              width: 15%;
          }
      }
  }
}
@media (min-width: 1200px) and (max-width: 1250px){
  .concept-instances-list{
      &__col{
          &--state-icam{
              width: calc(88% - 433px);
          }
          &--title-icam{
              width: 12%;
          }
      }
  }
}
@media (min-width: 850px) and (max-width: 1200px){
  .concept-instances-list{
      &__col{
          &--state-icam{
              width: calc(65% - 433px);
          }
          &--title-icam{
              width: 35%;
          }
      }
  }
}

@media (max-width: 850px){
  .concept-instances-list{
      &__col{
          &--state-icam{
              width: calc(75% - 433px);
          }
          &--title-icam{
              width: 25%;
          }
      }
  }
}
