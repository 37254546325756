@mixin modal-help-box-color($primaryColor, $secondColor, $thirdColor){
  color: $primaryColor;
  &--specific{
      background-color: $secondColor;
      color: $thirdColor;
      margin: unset;
  }
}

@mixin modal-help-box-icon-text-painted($margin, $borderColor){
  margin: $margin;
  border-bottom: 1px solid $borderColor;
}

.modal-xl{
  width: 100%;
}

.help-modal {
  padding: 15px;
  &__window {
      & .modal-dialog {
          & .modal-content {
              margin: 0 auto;
              border-radius: 0px;
              height: 560px;
              width: 940px;
          }
      }
  }

  &__header {
      height: 100px;
      position: relative;
  }

  &__header-title {
      position: absolute;
      bottom: 15px;
      margin: unset;
      font-size: 24px;
      text-transform: uppercase;
      font-family: MTTMilano-Bold, sans-serif;
      left: 23px;
  }

  &__container {
      height: 428px;
      padding: 0px 27px;
  }

  &__box {
      width: 276px;
      height: 340px;
      border: 1px solid $modal-help-box;
      float: left;
      margin-right: 13px;
      position: relative;
  }

  &__header-block{
      font-family: MTTMilano, sans-serif;
      text-align: center;
      font-size: 24px;
  }

  &__header-block-icon{
      font-size: 43px;
      display: block;
      margin-top: 20px;
  }

  &__header-block-text {
      font-weight: bold;
      margin-bottom: 4px;
      margin-top: 4px;
  }

  &__text-contact {
      font-size: 18px;
      padding: 0 5px;
      font-family: MTTMilano, sans-serif;
      text-align: center;
  }

  &__info {
      text-align: center;
      width: 100%;
      margin: auto;
      margin-top: 15px;
      padding: 0 10px;
  }

  &__info-hours {
      color: $modal-info-hours;
      text-align: center;
      font-family: MTTMilano, sans-serif;
      width: 220px;
      margin: auto;
  }

  &__btn-send-mail {
      border: 1px solid;
      border-radius: 4px;
      text-transform: uppercase;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 11px;
      padding: 6px 25px;
  }

  &__position-button {
      width: 100%;
      &--mail{
        position: absolute;
        bottom: 50px;
        text-align: center;
      }
  }

  &__mini-box {
      height: 80px;
      width: 80px;
      position: relative;
      float: left;
      border: 1px solid $modal-help-info;
      text-align: center;
      padding: 1px 0px;
      text-transform: uppercase;
      background-color: transparent;
      outline: none;

      &--text-faqs {
          text-transform: unset;
      }

      &:hover {
          cursor: pointer;
          background-color: $modal-help-info;
      }
  }

  &__mini-box-icon {
      font-size: 25px;
      display: block;
      margin-top: 9px;
  }

  &__mini-box-text {
      font-size: 10px;
      display: block;
      margin-top: 10px;
      margin-bottom: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
  }

  &__button-container {
      display: inline-flex;
      justify-content: center;
      width: 100%;
      padding: 25px;
  }

  &__help-button {
      border: 1px solid;
      border-radius: 4px;
      text-transform: uppercase;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 11px;
      padding: 6px 35px;
  }

  &__bold-text{
      font-weight: bold;
  }
}

.compliance {
  &__dark-blue {
    .help-modal {
      &__header-title {
            color: $primary-color;
      }
      &__header-icon {
        color: $primary-color;
        font-size: 18px;
        float: right;
        &:hover {
            cursor: pointer;
        }
      }
      &__header-image {
          width: 135px;
          height: unset;
          float: right;
          right: 34px;
          position: relative;
      }
      &__box {
          @include modal-help-box-color($primary-color, $primary-color, $snow-white);
      }
      &__header-block{
          font-family: MTTMilano, sans-serif;
          text-align: center;
          font-size: 24px;
          &--painted {
              @include modal-help-box-icon-text-painted(auto 20px 15px, $snow-white);
          }
      }
      &__info {
          color: $modal-help-info;
          &--hours{
              color: $modal-info-hours;
          }
      }
      &__btn-send-mail {
        color: $primary-color;
        background-color: $snow-white;
        border-color: $primary-color;

        &:hover {
            color: $snow-white;
            background-color: $primary-color;
        }
      }
      &__mini-box {
          margin: 5px 4px;
          width: 120px;
      }
      &__help-button {
          color: $snow-white;
          background-color: $primary-color;
          border-color: $primary-color;

          &:hover {
              color: $primary-color;
              background-color: $snow-white;
          }
      }
      &__block-buttons-help{
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue {
    .help-modal {
      &__header-title {
        color: $primary-color-2;
      }
      &__header-icon {
            font-size: 20px;
            color: $border-hover-2;
            top: 4px;
            right: 2px;
            position: absolute;

            &:hover {
                cursor: pointer;
            }
      }
      &__box {
          color: $primary-color-2;
          &--specific {
              border-color: $third-color-2;
              background-color: $third-color-2;
              margin: unset;
              padding: 0 16px;
          }
      }
      &__header-block{
          color: $primary-color-2;
          &--painted {
              margin: auto;
              border-bottom: 1px solid $primary-color-2;
              width: 100%;
              margin-bottom: 24px;
          }
      }
      &__header-block-icon{
          margin-top: 22px;
      }
      &__text-contact {
          margin: 0;
      }
      &__position-button {
        &--mail{
            bottom: 20px;
        }
      }
      &__info {
        color: $grey-icons-2;
        font-family: Arial, sans-serif;
        font-size: 14px;
        &--hours {
          color: $grey-icons-2;
        }
      }
      &__mini-box {
        width: 100%;
        margin: 0;
        border-color: $border-hover-2;
        color: $border-hover-2;
        margin-bottom: 11px;

        &:hover {
            color: $snow-white;
            background: $border-hover-2;
        }
      }
    }
  }
  &__steel-blue {
    .help-modal {
      &__header-image {
        height: 30px;
        width: 171px;
        right: 46px;
        top: 5px;
        position: absolute;
      }
    }
  }
  &__uk-steel-blue {
    .help-modal {
      &__header-image {
        height: 40px;
        right: 46px;
        top: 5px;
        position: absolute;
      }
    }
  }
  &__icam-red {
    .help-modal {
      &__header-title {
        color: $custom-black-landing;
      }
      &__header-icon {
        color: $custom-black-landing;
        font-size: 18px;
        float: right;
        &:hover {
            cursor: pointer;
        }
      }
      &__header-image {
          width: 135px;
          height: unset;
          float: right;
          right: 34px;
          position: relative;
      }
      &__box {
          @include modal-help-box-color($custom-black-landing, $third-color-icam, $title-text-icam);
      }
      &__header-block{
          &--painted {
              @include modal-help-box-icon-text-painted(auto 20px 15px, $title-text-icam);
          }
      }
      &__info {
        color: $secondary-color-icam;
        &--hours{
            color: $text-field-color-icam;
        }
      }
      &__info-hours {
          color: $text-field-color-icam;
      }
      &__btn-send-mail {
          color: $primary-color-icam;
          background-color: $snow-white;
          border-color: $primary-color-icam;

          &:hover {
              color: $snow-white;
              background-color: $primary-color-icam;
          }
      }
      &__mini-box {
          border-color: $primary-color-icam;
          color: $primary-color-icam;
          margin: 5px 4px;
          width: 120px;

          &:hover {
              color: $snow-white;
              background: $primary-color-icam;
          }
      }
      &__help-button {
          color: $snow-white;
          background-color: $primary-color-icam;
          border-color: $primary-color-icam;

          &:hover {
              color: $primary-color-icam;
              background-color: $snow-white;
          }
      }
      &__block-buttons-help{
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
      }
    }
  }
  &__gm-law {
    .help-modal {
      &__header-title {
        color: $custom-black-landing;
      }
      &__header-icon {
        color: $custom-black-landing;
        font-size: 18px;
        float: right;
        &:hover {
            cursor: pointer;
        }
      }
      &__header-image {
          width: 135px;
          height: unset;
          float: right;
          right: 34px;
          position: relative;
      }
      &__box {
          @include modal-help-box-color($custom-black-landing, $third-color-gm, $title-text-gm);
      }
      &__header-block{
          &--painted {
              @include modal-help-box-icon-text-painted(auto 20px 15px, $title-text-gm);
          }
      }
      &__info {
          color: $secondary-color-gm;
          &--hours{
              color: $text-field-color-gm;
          }
      }
      &__info-hours {
          color: $text-field-color-gm;
      }
      &__btn-send-mail {
          color: $primary-color-gm;
          background-color: $snow-white;
          border-color: $primary-color-gm;

          &:hover {
              color: $snow-white;
              background-color: $primary-color-gm;
          }
      }
      &__mini-box {
        border-color: $primary-color-gm;
        color: $primary-color-gm;
        margin: 5px 4px;
        width: 232px;

        &:hover {
            color: $snow-white;
            background: $primary-color-gm;
        }
      }
      &__help-button {
          color: $snow-white;
          background-color: $primary-color-gm;
          border-color: $primary-color-gm;

          &:hover {
              color: $primary-color-gm;
              background-color: $snow-white;
          }
      }
      &__block-buttons-help{
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
      }
    }
  }
  &__com-de{
    .help-modal {
      &__header-title {
        color: $custom-black-landing;
      }
      &__header-icon {
        color: $custom-black-landing;
        font-size: 18px;
        float: right;
        &:hover {
            cursor: pointer;
        }
      }
      &__header-image {
          width: 135px;
          height: unset;
          float: right;
          right: 34px;
          position: relative;
      }
      &__box {
          @include modal-help-box-color($custom-black-landing, $third-color-com-de, $title-text-com-de);
      }
      &__header-block{
          &--painted {
              @include modal-help-box-icon-text-painted(auto 20px 15px, $title-text-com-de);
          }
      }
      &__info {
          color: $secondary-color-com-de;
          &--hours{
              color: $text-field-color-com-de;
          }
      }
      &__info-hours {
          color: $text-field-color-com-de;
      }
      &__btn-send-mail {
          color: $primary-color-com-de;
          background-color: $snow-white;
          border-color: $primary-color-com-de;

          &:hover {
              color: $snow-white;
              background-color: $primary-color-com-de;
          }
      }
      &__mini-box {
        border-color: $primary-color-com-de;
        color: $primary-color-com-de;
        margin: 5px 4px;
        width: 120px;

        &:hover {
            color: $snow-white;
            background: $primary-color-com-de;
        }
      }
      &__help-button {
          color: $snow-white;
          background-color: $primary-color-com-de;
          border-color: $primary-color-com-de;

          &:hover {
              color: $primary-color-com-de;
              background-color: $snow-white;
          }
      }
      &__block-buttons-help{
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
      }
    }
  }
  &__com-fr{
    .help-modal {
      &__header-title {
        color: $custom-black-landing;
      }
      &__header-icon {
        color: $custom-black-landing;
        font-size: 18px;
        float: right;
        &:hover {
            cursor: pointer;
        }
      }
      &__header-image {
          width: 135px;
          height: unset;
          float: right;
          right: 34px;
          position: relative;
      }
      &__box {
          @include modal-help-box-color($custom-black-landing, $third-color-gm, $title-text-com-fr);
      }
      &__header-block{
          &--painted {
              @include modal-help-box-icon-text-painted(auto 20px 15px, $title-text-com-fr);
          }
      }
      &__info {
          color: $secondary-color-com-fr;
          &--hours{
              color: $text-field-color-com-fr;
          }
      }
      &__info-hours {
          color: $text-field-color-com-fr;
      }
      &__btn-send-mail {
          color: $primary-color-com-fr;
          background-color: $snow-white;
          border-color: $primary-color-com-fr;

          &:hover {
              color: $snow-white;
              background-color: $primary-color-com-fr;
          }
      }
      &__mini-box {
        border-color: $primary-color-com-fr;
        color: $primary-color-com-fr;
        margin: 5px 4px;
        width: 120px;

        &:hover {
            color: $snow-white;
            background: $primary-color-com-fr;
        }
      }
      &__help-button {
          color: $snow-white;
          background-color: $primary-color-com-fr;
          border-color: $primary-color-com-fr;

          &:hover {
              color: $primary-color-com-fr;
              background-color: $snow-white;
          }
      }
      &__block-buttons-help{
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
      }
    }
  }
}

@media (max-width: 1250px) {
  .help-modal{
      &__window{
          & .modal-dialog{
              & .modal-content{
                  width: 985px;
              }
          }
      }
      &__box{
          width: calc(33% - 7px);
      }
  }
}

@media (max-width: 992px){
  .compliance {
    &__dark-blue {
      &__box{
        height: 360px;
      }
      &__mini-box{
          width: calc(50% - 8px);
      }
      &__block-buttons-help{
        padding: 0 6px;
      }
    }
    &__steel-blue, &__uk-steel-blue {
      &__box{
            height: 410px;
      }
    }
    &__icam-red {
      &__mini-box{
            width: calc(50% - 8px);
      }
      &__block-buttons-help{
        padding: 0 6px;
      }
    }
    &__gm-law {
      &__box{
        height: 360px;
      }
      &__mini-box{
          width: calc(50% - 8px);
      }
      &__block-buttons-help{
        padding: 0 6px;
      }
    }
    &__com-de{
      &__box{
        height: 360px;
      }
      &__mini-box{
          width: calc(50% - 8px);
      }
      &__block-buttons-help{
        padding: 0 6px;
      }
    }
    &__com-fr{
      &__box{
        height: 360px;
      }
      &__mini-box{
          width: calc(50% - 8px);
      }
      &__block-buttons-help{
        padding: 0 6px;
      }
    }
  }
  .help-modal{
      &__window{
          & .modal-dialog{
              & .modal-content{
                  width: calc(100% - 20px);
              }
          }
      }
      &__container {
          padding: 0px;
      }
  }
}

@media (max-width: 767px){
  .help-modal{
      &__window{
          & .modal-dialog{
              margin: 10px 0;
              & .modal-content{
                  width: calc(100% - 8px);
                  height: 1235px;
              }
          }
      }
      &__container {
          height: 1105px;
      }
      &__box{
          width: 84%;
          height: 340px;
          margin: 10px 8% 0 8%;
      }
  }
}
