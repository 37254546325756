
.reports-list {
  // .widget {
  //     &__header {
  //         // @include widget-header($reports-list-color);
  //         @include widget-header($primary-color);
  //     }
  //     &__add-layer {
  //        @include add-layer($primary-color);
  //     }
  //     &__body{
  //         overflow: hidden;
  //     }
  // }
  &__block-list-new-version{
      height: 825px;
  }

  &__cell-block {
      position: relative;
      height: 17px;
  }
  &__col-text {
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      margin: 0;
      &--hover {
          &:hover {
              cursor: pointer;
          }
      }
      &--widthblock {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          padding-top: 5px;
      }
  }
  &__icon-search {
      margin-right: -6px;
  }
  &__menu-options {
      float: left;
      margin-right: 5px;
      font-size: 20px;
      margin-top: 7px;
      cursor: pointer;
      &--edit {
          margin-left: 19px;
      }
  }
  &__options {
      list-style-type: none;
      margin: 0;
      padding: 0;
      color: $primary-color;
      display: inline-flex;
      margin-right: 3px;
      background-color: $background-grey-other;
      border: 0.5px solid $menu-icons-border;
      height: 42px;
      width: 76px;
      border-top-left-radius: 67px;
      border-bottom-left-radius: 67px;
      z-index: 1;
      margin-left: -78px;
      float: right;
      position: relative;

      &--user-none{
          width: 55px;
      }
  }
  &__options-block {
      display: none;
      margin-top: 0px;
      margin-right: -3px;
      float: right;
      &:hover {
          display: block;
      }
  }
  &__table-body {
      display: block;
      width: 100%;
      max-height: 213px;
      overflow: hidden;
      overflow-y: auto;
      &--more-4-elements{
          width: calc(100% + 17px);
      }
  }
  &__table-col {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding-right: 5px;
      &--ellipsis{
          max-width: 0;
      }
      &--title {
          width: 33%;
      }
      &--type {
          width: 22%;
      }
      &--implantation {
          width: 20%;
          min-width: 97px;
      }
      &--modification {
          width: 17%;
          min-width: 84px;
      }
      &--actions {
          width: 8%;
          text-align: right;
          min-width: 63px;
          padding: 0px;
          // position: relative;
      }
  }
  &__table-header,
  &__table-row {
      display: table;
      width: 100%;
  }
  &__table-header {
      border-bottom: 1px solid $primary-color;
      padding-bottom: 9px;
  }
  &__table-row {
      height: 45px;
      border-bottom: 1px solid $box-comment-border-2;
      &:hover {
          background-color: $background-grey;
          .reports-list__options-block {
              display: block;
          }
      }
      &--more-4-elements{
          width: calc(100% - 7px);
      }
  }
  &__widget-body {
      display: block;
      width: 100%;
      padding: 28px 20px 0 20px;
      overflow: hidden;
  }
  &__delete-none{
      width: 55px;
  }

  /////////////////////
  //////Version 2//////
  /////////////////////

  &__principal-report-info {
      width: 40%;
      font-size: 12px;
      display: inline-block;
      position: relative;
      top: 8px;
  }

  &__principal-report-title {
      font-family: MTTMilano, sans-serif;
      font-style: italic;
      margin: 5px 0 0;
  }

  &__principal-report-name {
      font-family: Arial, sans-serif;
      font-weight: bold;
  }

  /////////////////////
  //////New Version//////
  /////////////////////
  &__col-new-version {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding-right: 5px;
      &--tooltip {
          max-width: 0;
      }
      &--first{
          padding-left: 8px;
      }
      &--header-title-v2{
          width: 42%;
          padding-top: 1px;
      }
      &--title-v2{
          width: 42%;
      }
      &--title,
      &--type,
      &--evaluation{
          width: 24%;
      }

      &--author{
          width: calc(32% - 120px);
          min-width: 150px;
      }
      &--header-creation{
          width: 13%;
          min-width: 100px;
          padding-top: 2px;
      }
      &--header-modification{
          width: 13%;
          min-width: 100px;
          padding-top: 2px;
      }
      &--creation{
          width: 13%;
          min-width: 100px;
      }
      &--modification{
          width: 13%;
          min-width: 100px;
      }
      &--modification-date{
          width: calc(28% - 70px);
      }
      &--actions{
          width: 120px;
          padding-right: 0;
          position: relative;
      }
      &--actions-body{
          width: 120px;
          padding-right: 0;
          position: relative;
      }
      &--actions-new-version{
          width: 70px;
          padding-right: 0;
          position: relative;
      }
  }

  &__principal-report{
      margin-top: 10px;
  }

  &__title-table-v2{
      margin-top: 40px;
      color: $primary-color-2;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      display: block;
      text-transform: uppercase;
  }

  &__header-button-config{
      right: 10px;
  }

  &__no-reports{
      text-align: center;
      width: 100%;
      position: relative;
      top: 80px;
      margin: 0;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 11px;
      &--no-has-templates{
          top: 151px;
      }
  }
  //////////////////////
  ////Fin New Version////
  //////////////////////
}
.compliance{
  &__dark-blue{

  }
  &__steel-blue{
    .reports-list{
      &__principal-report-title {
        color: $text-field-color-2;
      }
    }
  }
  &__icam-red{
    .reports-list{
      &__col-new-version{
        &--title,
        &--type{
            width: 36%;
        }
      }
    }

  }
  &__gm-law{
    .reports-list{
      &__col-new-version{
        &--title,
        &--type{
            width: 24%;
        }
      }
      &__principal-report-title {
        color: $text-field-color-gm;
      }
    }
  }
  &__com-de{
    .reports-list{
      &__col-new-version{
        &--title,
        &--type{
            width: 24%;
        }
      }
      &__principal-report-title {
        color: $text-field-color-com-de;
      }
    }
  }
  &__com-fr{
    .reports-list{
      &__col-new-version{
        &--title,
        &--type{
            width: 24%;
        }
      }
      &__principal-report-title {
        color: $text-field-color-com-fr;
      }
    }
  }
}
