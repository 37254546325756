.users-admin-roles-list {
  &__col-new-version {
       text-align: left;
       padding-right: 5px;
       &--header-top {
           top: 1px;
           position: relative;
       }
       &--role{
           width: 33%;
           padding-left: 8px;
       }
       &--product{
           width: 33%;
       }
       &--organization{
           width: 34%;
       }
   }
   &__table-body{
      display: block;
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
      min-height: 495px;
      max-height: 495px;
   }
}
