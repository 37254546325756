@mixin event-title($firstColor) {
    color: $firstColor;
    font-size: 16px;
    font-weight: normal;
    font-family: MTTMilano, sans-serif;
}

@mixin event-when($firstColor, $secondColor) {
    background: rgba($firstColor, 0.1);
    color: $secondColor;
}

@mixin event-description($firstColor) {
    font-size: 11px;
    color: $firstColor;
}
.schedule-event {
    font-family: Arial, sans-serif;
    font-size: 12px;
    p{
        margin-bottom: 0;
    }
    &__title {
        font-size: 14px;
        color: $primary-color;
    }

    &__when {
        color: $primary-color;
        background-color: $event-big-when;
        font-weight: bold;
        padding: 5px 30px 5px 5px;
        margin-bottom: 22px;
    }

    &__description {
        color: $secondary-color;
        clear: both;
        margin-bottom: 0;
        margin-top: 5px;
    }

    &__location {
        display: block;
        font-size: 11px;
        font-weight: bold;
        margin-top: 5px;
    }

    &__icon {
        &:after {
            font-family: "lf-font", sans-serif;
            font-style: normal !important;
            font-weight: normal !important;
            vertical-align: middle;
        }

        &.event-alert:after {
            // content: "\29";
            content: "\e9a6";
            padding: 0 2px;
            position: relative;
            bottom: 1px;
            font-size: 16px;
        }

        &.event-meeting:after {
            // content: "\35";
            content: "\ea63";
            font-size: 16px;
            position: relative;
            bottom: 3px;
        }
    }
}
.compliance{
    &__steel-blue{
        .schedule-event {
            &__title {
                @include event-title($primary-color-2);
            }
            &__when {
                @include event-when($primary-color-2, $text-field-color-2);
            }
            &__description {
                @include event-description($text-field-color-2);
            }
            &__location {
                color: $text-field-color-icam;
            }
        }
    }
    &__icam-red{
        .schedule-event {
            &__title {
                @include event-title($custom-black-landing);
            }
            &__when {
                @include event-when($text-field-color-icam, $text-field-color-icam);
            }
            &__description {
                @include event-description($text-field-color-icam);
            }
            &__location {
                color: $text-field-color-icam;
            }
        }
    }
    &__gm-law{
        .schedule-event {
            &__title {
                @include event-title($custom-black-landing);
            }
            &__when {
                @include event-when($text-field-color-gm, $text-field-color-gm);
            }
            &__description {
                @include event-description($text-field-color-gm);
            }
            &__location {
                color: $text-field-color-gm;
            }
        }
    }
    &__com-de{
      .schedule-event {
          &__title {
              @include event-title($custom-black-landing);
          }
          &__when {
              @include event-when($text-field-color-com-de, $text-field-color-com-de);
          }
          &__description {
              @include event-description($text-field-color-com-de);
          }
          &__location {
              color: $text-field-color-com-de;
          }
      }
  }
  &__com-fr{
    .schedule-event {
        &__title {
            @include event-title($custom-black-landing);
        }
        &__when {
            @include event-when($text-field-color-com-fr, $text-field-color-com-fr);
        }
        &__description {
            @include event-description($text-field-color-com-fr);
        }
        &__location {
            color: $text-field-color-com-fr;
        }
    }
  }
}
