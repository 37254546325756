.field-type-onefile{
    &__input-block {
        height: 97px;
        border-style: dotted;
        cursor: default;
        &--color-steel-blue{
            border-color: $primary-color-2;
        }
    }
    &__input-file {
        visibility: hidden;
        width: 0px;
        height: 0px;
        float: right;
    }
  }
  