.client-details{
  &__header-title{
      color: $primary-color-2;
      font-family: 'MTTMilano-Bold', sans-serif;
      font-size: 25px;
      float: left;
      width: calc(100% - 71px);
      margin: 0;
      position: relative;
      bottom: 2px;
  }
  &__header-menu{
    display: block;
      float: left;
      width: 71px;
      padding-left: 5px;
      text-align: right;
  }
  &__header-icon{
      font-size: 30px;
      color: $border-hover-2;
      cursor: pointer;
      &--trash{
          padding-left: 2px;
      }
      &:hover{
          cursor: pointer;
      }
  }
  &__body{
      display: block;
      width: 100%;
      overflow: hidden;
      padding: 30px 60px 0;
  }
  &__select-client-block{
      border-bottom: 1px solid $secondary-color-2;
      margin-bottom: 16px;
  }
  &__select-client-name{
      background: $primary-color-2;
      color: $white-special-2;
      font-family: 'MTTMilano', sans-serif;
      font-size: 14px;
      padding: 13px 11px;
      width: fit-content;
      margin: 0;
      height: 45px;
      text-transform: uppercase;
  }
  &__client-detail-block{
      width: 50%;
      display: block;
      float: left;
      margin-bottom: 26px;
      &--height{
          height: 113px;
      }
  }
  &__client-detail-block--all{
      width: 100%;
      display: block;
      float: left;
      margin-bottom: 26px;
  }
  &__property{
      color: $primary-color-2;
      font-family: 'MTTMilano-Bold', sans-serif;
      font-size: 14px;
      float: left;
      width: fit-content;
  }
  &__response{
      color: $text-field-color-2;
      font-family: 'MTTMilano', sans-serif;
      font-size: 14px;
      &--left{
          padding-left: 114px;
      }
      &--right{
          padding-left: 112px;
      }
  }

  &__propertys-address{
      margin: 0;
      line-height: 22px;
  }
}
