@import '../../../../assets/scss/colors';

.share-instance{
  &__block-body{
      display: block;
      width: 100%;
      height: 518px;
      padding: 60px 71px 0;
      &--top{
        padding-top: 2px;
      }
      &--query-product{
        margin-top: 98px;
      }
  }

  &__block-title{
      display: block;
      width: 100%;
      margin-bottom: 20px;
      &--top{
        padding-top: 11px;
      }
      &--landing{
        margin-bottom: 13px;
      }
  }

  &__block-task-disabled{
      width: 100%;
      height: auto;
      bottom: 0;
      left: 0;
      background-color: $red;
      text-align: center;
      position: absolute;
  }

  &__form-share{
      height: 340px;
      width: 100%;
      margin: 0;
  }

  &__header-box{
      &--disabled{
          opacity: 0.5;
      }
  }

  &__text-task-disabled{
      color: $snow-white;
      font-family: MTTMilano, sans-serif;
      font-size: 13px;
      opacity: 0.7;
      line-height: 32px;
  }

  &__text-rich {
      display: block;
      width: 100%;
      overflow: hidden;
      height: 270px;
      // & .mce-edit-area {
      //     //height: 231px; //Se comenta para CEN-9194
      //     width: 100%;
      //     display: block;
      // }
      // & .mce-edit-area > iframe{
      //     height: 100% !important;
      // }
  }

  &__icon-close-modal {
      color: $snow-white;
      float: right;
      font-size: 20px;
      top: 4px;
      position: relative;
      &--custom{
          font-size: 25px;
          top: 0;
      }
      &:hover {
          cursor: pointer;
      }

  }
}
.compliance{
  &__steel-blue, &__uk-steel-blue{
    .share-instance{
      &__icon-close-modal {
        color: $white-special-2;
      }
    }
  }
  &__icam-red{
    .share-instance{
      &__block-task-disabled{
        background-color: $alert-color-icam;
      }
    }
  }
  &__gm-law{
    .share-instance{
      &__block-task-disabled{
        background-color: $alert-color-gm;
      }
    }
  }
  &__com-de{
    .share-instance{
      &__block-task-disabled{
        background-color: $alert-color-com-de;
      }
    }
  }
  &__com-fr{
    .share-instance{
      &__block-task-disabled{
        background-color: $alert-color-com-fr;
      }
    }
  }
}
