.onboarding{
  max-width: 1400px;
  margin: 50px auto 100px;
  display: block;
  border: 1px solid $result-search-quote-color;
  padding: 50px;
  text-align: center;

  &__title-product{
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 25px;
      margin: 0;
  }

  &__subtitle-product{
      color: $pillow-group;
      font-family: MTTMilano, sans-serif;
      font-size: 18px;
      line-height: 24px;
      margin: 10px auto 0px;
      max-width: 1060px;
  }

  &__block-body{
      max-width: 1060px;
      margin: 0 auto;
  }

  &__title-block{
      color: $pillow-group;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      border-bottom: 1px solid $result-search-quote-color;
      padding-bottom: 7px;
      text-transform: uppercase;
      margin-top: 45px;
  }

  &__block-options-repeat{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      max-width: 790px;
      margin: 0 auto;
  }

  &__block-option{
      height: 410px;
      width: 250px;
      background-color: $background-color-states;
      padding: 15px;
      position: relative;
      &--active{
          background-color: $green-opacity-02;
      }
  }

  &__title-option{
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 20px;
      margin: 0;
      min-height: 40px;
      line-height: 20px;
  }

  &__icon-option{
      font-size: 75px;
      color: $box-comment-border-02;
      margin: 5px 0 15px;
      display: block;
      &--active{
          color: $icon-check-saved;
          opacity: 0.2;
      }
  }

  &__description-option{
      color: $secondary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      margin: 0;
  }

  &__button-configuration{
      position: absolute;
      bottom: 15px;
      right: 15px;
  }

  &__help-question{
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 14px;
      text-decoration: underline;
      margin: 40px 0 0;
  }

  &__block-alert-info{
      position: absolute;
      bottom: 15px;
      left: 15px;
  }

  &__icon-block-alert{
      color: $snow-white;
      background-color: $red;
      font-size: 17px;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      padding: 5px 5px;
      float: left;
  }

  &__help-tooltip{
      position: absolute;
      top: -66px;
      left: calc(100% + 16px);
      z-index: 9999;
  }
}

.compliance{
  &__steel-blue, &__uk-steel-blue{
    .onboarding{
        border-color: $background-highlight-text-2;
        &__title-product{
          color: $border-bottom-2;
        }
        &__subtitle-product{
          color: $grey-icons-2;
        }
        &__title-block{
          color: $grey-icons-2;
          border-color: $background-highlight-text-2;
        }
        &__block-option{
          background-color: $background-grey-disabled-2;
          &--active{
              background-color: $block-hover-2;
          }
        }
        &__title-option{
          color: $primary-color-2;
        }
        &__icon-option{
          color: $icon-shadow-2;
          &--active{
              color: $border-hover-2;
              opacity: 0.2;
          }
        }
        &__description-option{
          color: $primary-color-2;
        }
        &__help-question{
          color: $primary-color-2;
        }
        &__icon-block-alert{
          background-color: $alert-color-2;
        }
    }
  }
}

@media (max-width: 985px){
  .onboarding{
      padding: 50px 10px;
  }
}
