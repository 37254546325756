.participants-list{
    &__box-select-list{
        border-radius: 0px;
        padding: 0;
        overflow: hidden;
        position: absolute;
        margin: 0;
        width: 168px;
        max-height: 194px;
        border: none;
        transform: none !important;
        inset: auto !important;
        top: 20px !important;
        left: -1px !important;
    }
    &__cell-block{
        // padding-left: 2px;
        padding-right: 6px;
        &--more-elements{
            padding-top: 2px;
        }
    }
    &__icon-eye{
        color: $primary-color;
        font-size: 20px;
        width: 25px;
        position: relative;
        bottom: 3px;
        float: left;
        padding-left: 5px;
        &:hover{
            cursor: pointer;
        }
        &--communication{
            font-size: 17px;
            &:hover{
                cursor: default;
            }
        }
    }
    &__text-participant{
        position: relative;
        float: left;
        max-width: 100%;
        margin: 0;
        &--little{
            max-width: calc(100% - 25px);
        }
        &--more-little{
            max-width: calc(100% - 50px);
        }
        &--inherit-color{
            color: inherit;
        }
    }
}

.compliance{
  &__dark-blue{
      .participants-list{
          &__icon-eye{
              color: $primary-color;
          }
      }
  }
  &__steel-blue,
  &__uk-steel-blue{
      .participants-list{
          &__icon-eye{
              color: $border-hover-2;
          }
      }
  }
  &__icam-red{
      .participants-list{
          &__icon-eye{
              color: $primary-color-icam;
          }
      }
  }
  &__gm-law{
      .participants-list{
          &__icon-eye{
              color: $primary-color-gm;
          }
      }
  }
  &__com-de{
    .participants-list{
        &__icon-eye{
            color: $primary-color-com-de;
        }
    }
  }
  &__com-fr{
    .participants-list{
        &__icon-eye{
            color: $primary-color-com-fr;
        }
    }
  }
}
