.manage-confidential-modal{
  &__form-block{
      position: relative;
      height: 473px;
      width: calc(100% - 148px);
      margin-left: 74px;
      margin-top: 30px;
  }

  &__title-block-modal{
      margin-bottom: 15px;
  }
  &__block-field-users{
      margin-top: 15px;
      width: 100%;
      display: inline-block;
  }
}

.compliance{
  &__steel-blue{
    & .manage-confidential-modal{
      &__form-block{
        height: 468px;
      }
    }
  }
}
