.preview-customization{
  position: relative;
  &__header{
      position: relative;
      background-color: $snow-white;
      border-bottom: 10px solid $custom-black-landing;
      padding: 0 248px;
      min-height: 60px;
  }

  &__title-concept{
      height: 50px;
      position: relative;
      background-color: $primary-color;
      padding: 14px 248px;
  }

  &__text-title-concept{
      width: 210px;
      font-family: Arial, sans-serif;
      font-size: 18px;
      text-transform: uppercase;
  }

  &__body{
      position: relative;
      float: left;
      width: 100%;
      min-height: 667px;
      background-color: $snow-white;
      padding: 14px 0px;
      &--custom{
          padding: 10px 0px;
          min-height: 640px;
      }
      &--custom-procedure{
          padding: 10px 0px;
          min-height: 667px;
      }

      &--custom-sequential-finish{
          padding: 0;
          min-height: 667px;
      }
  }

  &__breadcrumb-concept{
      margin: 1px 0 10px;
      padding: 0px 248px;
  }

  &__text-breadcrumb{
      color: $attachment-custom-grey;
      font-family: Arial, sans-serif;
      font-size: 13px;
      position: relative;
      bottom: 6px;
      &--last{
          color: $custom-black-landing;
          font-weight: bold;
      }
  }

  &__icon-breadcrumb{
      color: $attachment-custom-grey;
      font-size: 23px;
      margin: 0 4px;
  }

  &__state-concept{
      height: 127px;
      position: relative;
      background-color: $background-grey;
      margin-bottom: 20px;
  }

  &__block-states-concept{
      position: relative;
      max-width: 900px;
      margin: 0 auto;
      height: 127px;
      padding: 35px 5px 0;
  }

  &__block-state{
      position: relative;
      float: left;
      width: 33%;
      &--active{
          width: 34%;
      }
  }

  &__block-state-bar{
      position: relative;
      height: 9px;
      background-color: $block-state-bar;
      &--left{
          border-top-left-radius: 4.5px;
          border-bottom-left-radius: 4.5px;
          background-color: $block-state-bar-left;
      }
      &--right{
          border-top-right-radius: 4.5px;
          border-bottom-right-radius: 4.5px;
          background-color: $custom-border-grey;
      }
  }

  &__name-state{
      color: $attachment-custom-grey;
      margin-bottom: 4px;
      &--active{
          margin-bottom: 3px;
      }
  }

  &__name-state-text{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      &--active{
          position: relative;
          font-size: 11px;
          color: $custom-black-landing;
          left: -3px;
          top: -4px;
      }
  }

  &__icon-name-state{
      font-size: 19px;
      position: relative;
      top: 4px;
      &--active{
          font-size: 20px;
          color: $block-state-bar-left;

          top: 0px;
          left: -10px;
      }
  }

  &__text-state{
      color: $attachment-custom-grey;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-align: center;
      margin-top: 7px;
      text-transform: uppercase;
      &--active{
          color: $custom-black-landing;
      }
  }

  &__concept{
      position: relative;
      float: left;
      width: 100%;
      padding: 0px 248px;
  }

  &__logo-img{
      margin: 2px 0 3px;
      height: 45px;
  }

  &__left-concept{
      position: relative;
      float: left;
      width: 33%;
  }

  &__right-concept{
      position: relative;
      float: left;
      margin-left: 20px;
      width: calc(67% - 20px);
  }

  &__side-box{
      min-height: 150px;
      background-color: $snow-white;
      box-shadow: 0 0 10px 1px $bar-horizontal-grey-opacity02;
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 21px;
  }

  &__header-side-box{
      height: 52px;
      border-radius: 4px;
      background-color: $background-grey;
      padding: 15px 10px 15px 25px;
  }

  &__icon-type-side-box{
      color: $custom-black-landing;
      font-size: 23px;
  }

  &__title-side-box{
      color: $custom-black-landing;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      position: relative;
      bottom: 4px;
      margin-left: 4px;
  }

  &__icon-options-side-box{
      color: $attachment-custom-grey;
      font-size: 20px;
      float: right;
      background-color: transparent;
      outline: none;
      border: none;
      padding: 0;
      margin-left: 12px;
  }

  &__list-templates{
      margin: 29px 0 40px;
      list-style: none;
      padding: 0;
      color: $attachment-custom-grey;
  }

  &__element-list-templates{
      border-top: 1px solid $custom-border-grey;
      border-bottom: 1px solid $custom-border-grey;
      height: 56px;
      padding: 14px 3px;
  }

  &__icon-template{
      font-size: 26px;
  }

  &__text-list-template{
      position: relative;
      bottom: 5px;
      font-size: 13px;
      font-family: Arial, sans-serif;
      padding-left: 4px;
  }

  &__text-empty{
      color: $attachment-custom-grey-other;
      font-family: Arial, sans-serif;
      font-size: 15px;
      text-align: center;
      margin-top: 25px;
      display: block;
  }

  &__block-steps{
      height: 103px;
      position: relative;
      border-radius: 8px;
      background-color: $background-grey;
      margin-bottom: 23px;
      padding: 14px;
  }

  &__list-steps{
      margin: 0 auto 5px;
      position: relative;
      width: 411px;
      height: 83px;
  }

  &__step{
      position: relative;
      float: left;
      width: 115px;
      text-align: center;
      &--right{
          float: right;
      }
  }

  &__step-circle{
      height: 43px;
      width: 43px;
      background-color: $attachment-custom-grey-other;
      border-radius: 25px;
      color: $snow-white;
      font-family: Arial, sans-serif;
      font-size: 13px;
      font-weight: bold;
      position: relative;
      margin: 0 auto;
      padding: 13px;
  }

  &__text-step{
      color: $custom-black-landing;
      font-family: Arial, sans-serif;
      font-size: 10px;
      line-height: 13px;
      display: block;
      margin-top: 3px;
      &--selected{
          font-weight: bold;
      }
  }

  &__line-step {
      display: inline-block;
      margin: 0;
      height: 1px;
      background-color: $border-modal;
      width: 238px;
      position: absolute;
      top: 21.5px;
      left: 86px;
      bottom: 12px;
  }

  &__block-group-concept{
      padding: 8px;
      position: relative;
      float: left;
      width: 100%;
      box-shadow: 0 0 10px 1px $bar-horizontal-grey-opacity02;
      min-height: 120px;
      border-radius: 4px;
  }

  &__header-group-concept{
      height: 52px;
      border-radius: 4px;
      background-color: $custom-secondary-color;
      position: relative;
      float: left;
      width: 100%;
      text-align: center;
      padding: 15px 0px;
  }

  &__title-header-group{
      color: $snow-white;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 18px;
      text-transform: uppercase;
  }

  &__body-group-concept{
      position: relative;
      float: left;
      width: 100%;
      padding: 5px;
  }

  &__info-client{
      position: relative;
      float: left;
      width: 100%;
  }

  &__info-client-left{
      position: relative;
      float: left;
      height: 175px;
      width: 187px;
      border-radius: 8px;
      background-color: $background-grey;
      text-align: center;
      padding: 66px 10px 0;
  }

  &__info-client-text{
      font-family: Arial, sans-serif;
      display: block;
      text-transform: uppercase;
      font-size: 13px;
      color: $custom-black-landing;
      &--principal{
          font-weight: bold;
      }
  }

  &__info-client-right{
      position: relative;
      float: left;
      margin: 30px;
  }

  &__data-client-text{
      font-family: Arial, sans-serif;
      text-transform: uppercase;
      font-size: 12px;
      color: $attachment-custom-grey;
      &--principal{
          font-weight: bold;
          margin-right: 4px;
      }
  }

  &__custom-buttons-block{
      margin-top: 44px;
  }

  &__custom-steps-buttons-block{
      margin-top: 48px;
  }

  &__custom-steps-map{
      position: absolute;
      top: 3px;
      right: 6px;
      list-style: none;
  }
  &__custom-actual-step-map{
      background: $custom-secondary-color;
      border-radius: 50%;
      height: 14px;
      width: 15px;
      color: $snow-white;
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: bold;
      text-align: center;
      float: left;
      position: relative;
      top: 3px;
      right: 2px;
  }
  &__custom-total-steps-map{
      color: $attachment-custom-grey;
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: bold;
      text-align: center;
  }
}

.compliance{
  &__steel-blue{
    .preview-customization{
      &__header{
          border-bottom-width: 0;
      }
    }
  }
}

@media (max-width: 1367px){
  .preview-customization{
      &__header, &__concept, &__breadcrumb-concept{
          padding: 0 50px;
      }
      &__title-concept{
          padding: 14px 50px;
      }
  }
}

@media (max-width: $max-width-smartphone) { //Smartphone
  .preview-customization {
      &__title-concept{
          padding: 14px 12px;
      }
      &__header {
          padding: 0 0;
      }

      &__logo-img {
          margin-left: 2px;
          width: 60%;
      }

      &__custom-buttons-block {
          width: 100%;
          text-align: center;
      }

      &__custom-steps-buttons-block{
          display: none;
      }
  }
}
