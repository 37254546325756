
.dashboard-reports {
  &__graph-block {
      display: block;
      width: 100%;
      height: 445px;
      position: relative;
  }

  &__graph-header {
      display: block;
      width: 100%;
      padding: 18px 29px 0 20px;
  }

  &__graph-header-title {
      float: left;
      width: fit-content;

  }

  &__header-title-icon {
      font-size: 30px;
      float: left;
      padding-right: 16px;
  }

  &__header-title-text {
      font-family: 'MTTMilano-Bold', sans-serif;
      font-size: 25px;
      position: relative;
      bottom: 3px;
      width: fit-content;
      float: left;
  }

  &__header-complaints-filter {
      display: block;
      float: left;
      border-left: 2px solid;
      font-family: 'MTTMilano', sans-serif;
      font-size: 16px;
      margin: 9px 0 0 9px;
      height: 17px;
      padding-left: 9px;
      position: relative;
      z-index: 2;

      &--right {
          float: right;
          border-left-color: transparent;
      }

      &--no-border {
          border-left-color: transparent;
          margin-left: 22px;
          &:hover {
            cursor: pointer;
          }
      }

      &--type{
          margin-top: 10px;
      }

      &:hover {
          cursor: pointer;
      }
  }

  &__header-complaints-text {
      position: relative;
      bottom: 3px;
  }

  &__header-complaints-icon {
      font-size: 15px;
      &--right {
          font-size: 20px;
      }
  }

  &__header-complaints-icon-menu {
      font-size: 20px;
      margin-left: 4px;
  }

  &__dropdown-panel {
      min-width: 20px;
      top: 20px;
      width: 172px;
      &--show{
          display: block;
      }
  }
  &__select-dates{
      float: left;
      padding-right: 5px;
  }

  &__product-info-block {
      display: block;
      width: 100%;
      overflow: hidden;
  }

  &__product-info-header {
      height: 30px;
      width: 100%;
      font-family: 'MTTMilano-Bold', sans-serif;
      font-size: 14px;
      padding-left: 28px;
      padding-top: 6px;
  }
  &__product-info-header-be{
      width: calc(100% - 399px);
      float: left;
      height: 30px;
      font-family: 'MTTMilano-Bold', sans-serif;
      font-size: 13px;
      background-color: $secondary-color-2;
      padding-left: 28px;
      padding-top: 6px;
  }


  &__product-info-list {
      height: 72px;
      width: 100%;
      background-color: $snow-white;
      margin: 0;
      padding: 0;
      list-style: none;
      display: table;
  }

  &__product-info-item {
      display: table-cell;
      position: relative;
      vertical-align: middle;
  }

  &__special-border {
      height: 60px;
      width: 8px;
      float: left;
      margin-right: 17px;

      &--be-grey{
          background: $secondary-color-2;
      }
  }

  &__product-info-column {
      font-family: 'MTTMilano', sans-serif;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 2px;
      line-height: 15px;
  }

  &__product-info-value {
      font-family: 'MTTMilano', sans-serif;
      font-size: 40px;
      top: 26px;
      position: absolute;
      left: 22px;
  }

  &__graph-body {
      padding-top: 30px;
      &--donut {
          display: block;
          width: 100%;
          overflow: hidden;
          padding-top: 0;
          position: relative;
          bottom: 22px;
      }
  }

  &__graph-box {
      &--donut {
          width: 70%;
          display: block;
          margin: 0 auto;
      }
      &--no-data{
          display: table;
          width: 100%;
          overflow: hidden;
          height: 380px;
      }
  }

  &__donut-graph {
      float: left;
      margin-top: 12px;
      margin-right: 51px;
      position: relative;
      left: -26px;
      width: 364px;
  }

  &__general-summary-container {
      display: block;
      width: 100%;
      overflow: hidden;
  }

  &__general-summary-list {
      display: table;
      width: 80%;
      margin: 0 auto;
      padding: 70px 0 79px;
      min-height: 324px;
  }

  &__general-summary-item {
      display: table-cell;
      vertical-align: bottom;
  }

  &__general-summary-box {
      border: 1px solid $white-special-2;
      height: 175px;
      width: calc(100% - 30px);
      max-width: 131px;
      display: block;
      border-radius: 25px;
      float: left;
      overflow: hidden;
      padding: 3px 5px;
      &--total {
          border: 4px solid $white-special-2;
          padding-top: 6px;
      }
  }

  //INICIO WEB-36386
  &__general-summary-list-es {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 45px 0;
      color: $primary-color-2;
      font-family: 'MTTMilano-Bold', sans-serif;
      letter-spacing: 0;
      height: 350px;
  }

  &__general-summary-list-steps {
      display: flex;
      justify-content: center;
      width: 100%;
  }

  &__general-summary-list-element {
      display: flex;
      align-items: center;
      border-style: solid;
      border-color: $white-special-2;
      border-radius: 25px;

      &--header {
          justify-content: space-evenly;
          height: 75px;
          width: 263px;
          border-width: 4px;
          margin-bottom: 10px;
      }

      &--step {
          flex-direction: column;
          justify-content: center;
          height: 174px;
          min-width: 60px;
          width: 100%;
          max-width: 130px;
          border-width: 1px;
          margin: 0 5px;
          padding: 10px 0;
      }
  }

  &__general-summary-list-element-title {
      font-size: 12px;
      text-align: center;

      &--step {
          height: 100%;
          padding: 0 15px 0 15px;
      }
  }

  &__general-summary-list-element-value {
      font-size: 30px;

      &--single {
          padding-bottom: 5px;
      }
  }

  &__general-summary-list-element-icon {
      font-size: 45px;
      opacity: 0.5;
  }

  &__general-summary-list-double-down-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top: inherit;
      width: 100%;
  }
  //FIN WEB-36386

  &__general-summary-box-title {
      color: $primary-color-2;
      font-family: 'MTTMilano-Bold', sans-serif;
      font-size: 12px;
      text-align: center;
      text-transform: uppercase;
      margin: 0;

      &--subtitle {
          text-transform: unset;
          width: 20%;
          height: 64px;
          display: table-cell;
          vertical-align: middle;
          margin: 0 auto;
      }

      &--total {
          margin-top: 0;
          line-height: 13px;
          margin-bottom: 6px;
          text-transform: unset;
      }

      &--value {
          font-size: 30px;
          text-transform: unset;
          margin: 0 0 2px;
      }
  }

  &__general-summary-box-icon {
      width: 100%;
      text-align: center;
  }

  &__general-summary-inner-icon {
      opacity: 0.5;
      color: $white-special-2;
      font-size: 49px;
  }

  &__general-summary-next-icon {
      float: left;
      position: relative;
      top: 75px;
      color: $third-color-2;
      font-size: 25px;
      left: 5px;
  }

  &__percentage-complete-block {
      float: left;
      // width: 275px;
      padding-left: 20px;
      position: relative;
      top: 2px;
      width: auto;
  }

  &__news-and-compete-block {
      float: right;
      width: calc(100% - 275px);
  }

  &__percentage-complete-icon {
      font-size: 25px;
      float: left;
      margin-right: 5px;
      position: relative;
      bottom: 3px;
  }

  &__percentage-complete-text {
      color: $white-special-2;
      font-family: 'MTTMilano-Bold', sans-serif;
      font-size: 16px;
      position: relative;
      float: left;
  }

  &__today-block {
      float: right;
      color: $white-special-2;
      font-family: 'MTTMilano-Bold', sans-serif;
      font-size: 16px;

      &--today {
          margin-right: 26px;
          margin-bottom: 5px;
      }

      &--solved {
          margin-right: 26px;
      }
  }

  &__today-block-text {
      float: left;
      position: relative;
      top: 2px;
  }

  &__today-block-value {
      float: left;
      height: 26px;
      width: 38px;
      border: 2px solid;
      border-radius: 11px;
      text-align: center;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-left: 7px;
      padding-top: 2px;
  }

  &__calendar-dropdown{
      height: 306px;
      width: 371px;
      padding: 0;
  }

  &__graph-time-block{
      margin: 0;
      padding: 0;
      list-style: none;
      position: absolute;
      right: 157px;
      z-index: 10;
      bottom: 10px;
  }
  &__graph-time-option{
      display: inline-block;
      border: 1px solid;
      border-left: 0;
      padding: 5px 8px 3px;
      font-family: 'MTTMilano-Bold', sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      &:hover{
          cursor: pointer;
      }
  }

  &__detail-text{
      width: fit-content;
      float: left;
  }

  &__detail-description{
      margin-left: 10px;
      font-size: 18px;
      color: $snow-white;
  }

  &__no-data{
      opacity: 0.5;
      font-family: 'MTTMilano', sans-serif;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
  }

  &__block-confident-report{
      position: absolute;
      top: -12px;
      right: -12px;
  }

  &__box-select-list {
      border-radius: 0px;
      border: none;
      padding: 0;
      overflow: hidden;
      position: absolute;
      margin: 0;
      width: 168px;
      max-height: 188px;
      inset: initial !important;
      left: initial !important;
      right: 28px !important;
      top: 0px !important;
      transform: none !important;
      cursor: default !important;
  }
}

.compliance{
  &__dark-blue{
    .dashboard-reports{
      &__no-data{
        color: #F7F7F3;
      }
      &__graph-time-option{
        border-color:  $primary-color;
        background-color: $background-grey-other;
        color: $primary-color;
        &.first{
            border-left: 1px solid $primary-color;
        }
        &.selected{
            background: $primary-color;
            color: $background-grey-other;
        }
      }
      &__today-block-value {
        border-color: $background-grey-other;
        background-color: $background-grey-other;
        color: $primary-color;
      }
      &__percentage-text-value,
      &__general-summary-list-element,
      &__product-info-value,
      &__product-info-column,
      &__header-complaints-icon,
      &__graph-header-title {
        color: $primary-color;
      }
      &__percentage-complete-icon,
      &__general-summary-list-element-icon {
        color: $background-grey-other;
      }
      &__special-border,
      &__graph-block {
        background: linear-gradient($dark-gradient-blue);
      }
      &__product-info-header {
        background-color: $primary-color;
        color: $snow-white;
      }
      &__product-info-header-be {
        display: none;
      }
      &__header-complaints-filter {
        margin-top: 7px;
        border-left-color: $primary-color;
        color: $primary-color;
        &--no-border {
            border-left-color: transparent;
            margin-left: 22px;
            &:hover {
              cursor: pointer;
            }
        }
      }
    }
  }

  &__steel-blue{
    .dashboard-reports{
      &__no-data{
        color: $white-special-2;
      }
      &__graph-time-option{
        border-color:  $border-hover-2;
        background-color: $third-color-2;
        color: $primary-color-2;
        &.first{
            border-left: 1px solid $border-hover-2;
        }
        &.selected{
            background: $border-hover-2;
            color: $white-special-2;
        }
      }
      &__today-block-value {
        border-color:  $third-color-2;
        background-color: $third-color-2;
        color: $primary-color-2;
      }
      &__percentage-text-value,
      &__general-summary-list-element,
      &__product-info-value,
      &__product-info-column,
      &__graph-header-title {
        color: $primary-color-2;
      }
      &__percentage-complete-icon{
        color: $third-color-2;
      }
      &__general-summary-list-element-icon{
        color: $white-special-2;
      }
      &__special-border,
      &__graph-block {
        background: linear-gradient($steel-gradient-blue);
      }
      &__product-info-header {
        background-color: $primary-color-2;
        color: $white-special-2;
        width: 399px;
        float: left;
      }
      &__header-complaints-icon {
        color: $border-hover-2;
      }
      &__header-complaints-filter {
        border-left-color: $primary-color-2;
        color: $primary-color-2;
        margin-top: 7px;
        &--no-border {
            border-left-color: transparent;
            margin-left: 22px;
            &:hover {
              cursor: pointer;
            }
        }
      }
    }
  }

  &__icam-red{
    .dashboard-reports{
      &__no-data{
        color: $secondary-color-icam;
      }
      &__graph-time-option{
        border-color:  $primary-color-icam;
        background-color: $third-color-icam;
        color: $primary-color-icam;
        &.first{
            border-left: 1px solid $primary-color-icam;
        }
        &.selected{
            background: $primary-color-icam;
            color: $snow-white;
        }
      }
      &__today-block-value {
        border-color:  $third-color-2;
        background-color: $third-color-2;
        color: $primary-color-2;
      }
      &__percentage-text-value {
        color: $primary-color;
      }
      &__percentage-complete-icon {
        color: $background-grey-other;
      }
      &__general-summary-list-element-icon{
        color: $white-special-2;
      }
      &__general-summary-list-element{
        color: $primary-color-2;
      }
      &__product-info-value {
        color: $custom-black-landing;
        font-size: 25px;
        top: 39px;
      }
      &__product-info-column {
        color: $custom-black-landing;
        font-size: 11px;
        line-height: 12px;
        font-family: 'MTTMilano-Bold', sans-serif;
      }
      &__special-border{
        background: linear-gradient($gradient-icam-white-red);
        margin-right: 8px;
      }
      &__graph-block {
        background: linear-gradient($icam-grey-gradient);
      }
      &__product-info-header {
        background-color: $custom-black-landing;
        color: $snow-white;
      }
      &__product-info-header-be {
        display: none;
      }
      &__header-complaints-icon,
      &__graph-header-title {
        color: $primary-color-icam;
      }
      &__header-complaints-filter {
        border-left-color: $custom-black-landing;
        color: $custom-black-landing;
        margin-top: 7px;
        &--no-border {
            border-left-color: transparent;
            margin-left: 22px;
            &:hover {
              cursor: pointer;
            }
        }
      }
    }
  }

  &__gm-law{
    .dashboard-reports{
      &__graph-time-option{
        border-color:  $primary-color-gm;
        background-color: $third-color-gm;
        color: $primary-color-gm;
        &.first{
            border-left: 1px solid $primary-color-gm;
        }
        &.selected{
            background: $primary-color-gm;
            color: $snow-white;
        }
      }
      &__percentage-complete-text,
      &__today-block {
        color: $primary-color-gm;
      }
      &__today-block-value {
        border-color: $third-color-gm;
        color: $custom-black-landing;
        background-color: $third-color-gm;
      }
      &__percentage-text-value {
        color: $primary-color;
      }
      &__percentage-complete-icon {
        color: $background-grey-other;
      }
      &__general-summary-list-element-icon{
        color: $grey-icon-gm;
      }
      &__general-summary-list-element{
        color: $primary-color-gm;
        border-color: $primary-color-gm ;
      }

      &__product-info-value {
        color: $primary-color-gm;
        font-size: 25px;
        top: 39px;
      }
      &__product-info-column {
        color: $primary-color-gm;
        font-size: 11px;
        line-height: 12px;
        font-family: 'MTTMilano-Bold', sans-serif;
      }
      &__special-border{
        background: $background-grey-disabled-gm;
        margin-right: 8px;
        width: 2px;
      }
      &__graph-block {
        background: linear-gradient($gm-grey-gradient);
      }
      &__product-info-header {
        background-color: $primary-color-gm;
        color: $snow-white;
      }
      &__product-info-header-be {
        display: none;
      }
      &__header-complaints-icon,
      &__graph-header-title {
        color: $primary-color-gm;
      }
      &__header-complaints-filter {
        border-left-color: $custom-black-landing;
        color: $custom-black-landing;
        margin-top: 7px;
        &--no-border {
            border-left-color: transparent;
            margin-left: 22px;
            &:hover {
              cursor: pointer;
            }
        }
      }
    }
  }
  &__com-de{
    .dashboard-reports{
      &__graph-time-option{
        border-color:  $primary-color-com-de;
        background-color: $third-color-com-de;
        color: $primary-color-com-de;
        &.first{
            border-left: 1px solid $primary-color-com-de;
        }
        &.selected{
            background: $primary-color-com-de;
            color: $snow-white;
        }
      }
      &__percentage-complete-text,
      &__today-block {
        color: $primary-color-com-de;
      }
      &__today-block-value {
        border-color: $third-color-com-de;
        color: $custom-black-landing;
        background-color: $third-color-com-de;
      }
      &__percentage-text-value {
        color: $primary-color;
      }
      &__percentage-complete-icon {
        color: $background-grey-other;
      }
      &__general-summary-list-element-icon{
        color: $grey-icon-com-de;
      }
      &__general-summary-list-element{
        color: $primary-color-com-de;
        border-color: $primary-color-com-de ;
      }

      &__product-info-value {
        color: $primary-color-com-de;
        font-size: 25px;
        top: 39px;
      }
      &__product-info-column {
        color: $primary-color-com-de;
        font-size: 11px;
        line-height: 12px;
        font-family: 'MTTMilano-Bold', sans-serif;
      }
      &__special-border{
        background: $background-grey-disabled-com-de;
        margin-right: 8px;
        width: 2px;
      }
      &__graph-block {
        background: linear-gradient($com-de-grey-gradient);
      }
      &__product-info-header {
        background-color: $primary-color-com-de;
        color: $snow-white;
      }
      &__product-info-header-be {
        display: none;
      }
      &__header-complaints-icon,
      &__graph-header-title {
        color: $primary-color-com-de;
      }
      &__header-complaints-filter {
        border-left-color: $custom-black-landing;
        color: $custom-black-landing;
        margin-top: 7px;
        &--no-border {
            border-left-color: transparent;
            margin-left: 22px;
            &:hover {
              cursor: pointer;
            }
        }
      }
    }
  }
  &__com-fr{
    .dashboard-reports{
      &__graph-time-option{
        border-color:  $primary-color-com-fr;
        background-color: $third-color-com-fr;
        color: $primary-color-com-fr;
        &.first{
            border-left: 1px solid $primary-color-com-fr;
        }
        &.selected{
            background: $primary-color-com-fr;
            color: $snow-white;
        }
      }
      &__percentage-complete-text,
      &__today-block {
        color: $primary-color-com-fr;
      }
      &__today-block-value {
        border-color: $third-color-com-fr;
        color: $custom-black-landing;
        background-color: $third-color-com-fr;
      }
      &__percentage-text-value {
        color: $primary-color;
      }
      &__percentage-complete-icon {
        color: $background-grey-other;
      }
      &__general-summary-list-element-icon{
        color: $grey-icon-com-fr;
      }
      &__general-summary-list-element{
        color: $primary-color-com-fr;
        border-color: $primary-color-com-fr ;
      }

      &__product-info-value {
        color: $primary-color-com-fr;
        font-size: 25px;
        top: 39px;
      }
      &__product-info-column {
        color: $primary-color-com-fr;
        font-size: 11px;
        line-height: 12px;
        font-family: 'MTTMilano-Bold', sans-serif;
      }
      &__special-border{
        background: $background-grey-disabled-com-fr;
        margin-right: 8px;
        width: 2px;
      }
      &__graph-block {
        background: linear-gradient($com-fr-grey-gradient);
      }
      &__product-info-header {
        background-color: $primary-color-com-fr;
        color: $snow-white;
      }
      &__product-info-header-be {
        display: none;
      }
      &__header-complaints-icon,
      &__graph-header-title {
        color: $primary-color-com-fr;
      }
      &__header-complaints-filter {
        border-left-color: $custom-black-landing;
        color: $custom-black-landing;
        margin-top: 7px;
        &--no-border {
            border-left-color: transparent;
            margin-left: 22px;
            &:hover {
              cursor: pointer;
            }
        }
      }
    }
  }
}

@media  (max-width: 1600px){
  .dashboard-reports {
      &__general-summary-list {
          width: 98%;
      }
  }
}

@media (min-width: 1531px) and (max-width: 1724px){
  .dashboard-reports{
      &__graph-body{
          &--donut{
              display: block;
              width: 100%;
              overflow: hidden;
          }
      }
      &__graph-box{
          &--donut{
              width: 80%;
              display: block;
              margin: 0 auto;
          }
      }
  }
}

@media (min-width: 1367px) and (max-width: 1530px){
  .dashboard-reports{
      &__graph-body{
          &--donut{
              display: block;
              width: 100%;
              overflow: hidden;
          }
      }
      &__graph-box{
          &--donut{
              width: 90%;
              display: block;
              margin: 0 auto;
          }
      }
      &__donut-graph{
          margin-right: 1px;
      }

  }
}

@media (min-width: 1252px) and (max-width: 1366px){
  .dashboard-reports{
      &__graph-body{
          &--donut{
              display: block;
              width: 100%;
              overflow: hidden;
          }
      }
      &__graph-box{
          &--donut{
              width: 70%;
              display: block;
              margin: 0 auto;
          }
      }
      &__donut-graph{
          margin-right: 1px;
      }

  }
}

@media (min-width: 1120px) and (max-width: 1251px){
  .dashboard-reports{
      &__graph-body{
          &--donut{
              display: block;
              width: 100%;
              overflow: hidden;
          }
      }
      &__graph-box{
          &--donut{
              width: 80%;
              display: block;
              margin: 0 auto;
          }
      }
      &__donut-graph{
          margin-right: 1px;
      }

  }
}

@media (min-width: 1015px) and (max-width: 1119px){
  .dashboard-reports{
      &__graph-body{
          &--donut{
              display: block;
              width: 100%;
              overflow: hidden;
          }
      }
      &__graph-box{
          &--donut{
              width: 90%;
              display: block;
              margin: 0 auto;
          }
      }
      &__donut-graph{
          margin-right: 1px;
      }

  }
}

@media (min-width: 998px) and (max-width: 1015px){
  .dashboard-reports{
      &__graph-body{
          &--donut{
              display: block;
              width: 100%;
              overflow: hidden;
          }
      }
      &__graph-box{
          &--donut{
              width: 100%;
              display: block;
              margin: 0 auto;
          }
      }
      &__donut-graph{
          margin-right: 1px;
          margin-left: 26px;
      }

  }
}
// @media (min-width: 1200px) and (max-width: 1400px){
//     .dashboard-reports{
//         &__graph-body{
//             max-width: 950px;
//             overflow: hidden;
//             overflow-x: auto;
//         }
//     }
// }

// @media (min-width: 900px) and (max-width: 1200px){
//     .dashboard-reports{
//         &__graph-body{
//             max-width: 800px;
//             overflow: hidden;
//             overflow-x: auto;
//         }
//     }
// }


/////////////////////////////////
//inc icam - MADiaz
