@mixin evaluation-menu-list-item-block($backgroundColor, $textColor) {
  background: $backgroundColor;
  & > .evaluation-menu__list-item-button > .evaluation-menu__list-item-icon{
      color: $textColor;
  }
}

@mixin evaluation-menu-list-item-block-hover($firstColor, $secondColor) {
  &:hover{
      background-color: $firstColor;
      & > .evaluation-menu__parent-box{
          background: $firstColor;
          color: $secondColor;
      }

      & > .evaluation-menu__list-item-open-tree{
          background: $firstColor;
          // border-right: 1px solid $secondColor;
      }
      & > .evaluation-menu__list-item-button > .evaluation-menu__list-item-icon, .evaluation-menu__icon-link, .evaluation-menu__icon-double-lock, .evaluation-menu__icon-open-window{
          color:$secondColor;
      }
  }
}

@mixin evaluation-menu-list-item-block-disabled($backgroundColor, $color) {
  background-color: $backgroundColor;
  & > .evaluation-menu__parent-box,
  & > .evaluation-menu__list-item-button{
      opacity: 0.2;
  }
  &:hover{
      cursor: initial;
      & > .evaluation-menu__parent-box{
          background-color: transparent;
          color: $color;
          & > .evaluation-menu__list-item-triangle{
              display: none;
          }
      }
      & > .evaluation-menu__list-item-open-tree{
          background-color: transparent;
      }
  }
}

@mixin evaluation-menu-list-item-home($backgroundColor, $borderColor) {
  background-color: $backgroundColor;
  border-bottom: 1px solid $borderColor;
  height: 66px;
}

@mixin evaluation-menu-list-item-icon-home($textColor) {
  color: $textColor;
}

@mixin evaluation-menu-parent-box-active($backgroundColor, $textColor) {
  background-color: $backgroundColor;
  color: $textColor;
}

.evaluation-menu{
  height: 100%;
  width: 100%;
  display: block;

  &__cell-block{
      position: relative;
      float: left;
      max-height: 33px;
      overflow: hidden;
  }

  &__col-text{
      position: relative;
      line-height: 16px;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      &--hidden{
          color:  $subcontainer-ground;
      }
  }

  &__icon-une{
      position: absolute;
      float: right;
      color: $snow-white;
      font-size: 35px;
      background: transparent;
      border: none;
      top: 14px;
      right: 3px;
      outline: 0;
      &:hover{
          color:$snow-white;
          cursor: pointer;
          text-decoration: none;
      }
      &:visited{
          color:$snow-white;
          text-decoration: none;
      }
      &:focus{
          color:$snow-white;
          text-decoration: none;
      }
      & .tooltip {
          max-width: 200px;
          &[data-popper-placement^='bottom'] {
              &>.tooltip-inner {
                  position: relative;
              }
          }
      }
  }

  &__list-block{
      padding: 0;
      margin: 0;
      height: 100%;
      width: 100%;
      background-color: $primary-color;
      display: block;
  }

  &__list-item{
      width: 100%;
      position: relative;
      float: left;
      border-bottom: 1px solid $icon-arrow;
      display: block;
      &--last{
          padding-top: 18px;
          border-bottom: 0;
      }
  }

  &__list-item-block{
      width: 100%;
      display: table;
      height: 59px;
      &:hover{
          cursor: pointer;
      }
      &--no-clickable{
          &:hover{
              cursor: default;
          }
      }
  }

  &__list-item-block-menu{
      width: 100%;
      display: table;
      height: 59px;
  }
  &__list-item-button{
      display: table-cell;
      background: none;
      border: none;
      width: 54px;
      height: 59px;
      outline: none;
      padding: 0;
      &--burger{
          height: 65px;
      }
      &--tree{
          &:hover{
              cursor: inherit;
          }
      }
  }
  &__box-home{
      background-color: $icon-arrow;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      position: relative;
      margin-left: 9px;
      margin-top: 8px;
  }
  &__list-item-icon{
      font-size: 30px;
      position: relative;
      left: 3px;
      top: 2px;
      &--invisible{
          color: $subcontainer-ground !important;
      }
  }
  &__parent-box{
      width: 354px;
      display: table-cell;
      color: $snow-white;
      vertical-align: middle;
      position: relative;
      &--home{
          height: 65px;
          padding: 0;
      }
      &--no-menu{
          padding-right: 24px;
      }
      &--hidden{
          color: $subcontainer-ground;
      }
  }
  &__list-item-triangle{
      height: 100%;
      width: 43px;
      position: absolute;
      top: 0px;
      display: table-cell;
      &--active{
          border-top: 28px solid $primary-color;
          border-left: 23px solid $primary-color;
          border-bottom: 31px solid $background-grey;
          border-right: 18px solid $background-grey;
          height: 0 !important;
          width: 0 !important;
      }
  }
  &__list-item-text{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 14px;
      line-height: 16px;
      display: block;
      text-transform: uppercase;
      margin: 0;
      padding-left: 7px;
      padding-top: 2px;
  }
  &__list-item-block-text{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 11px;
      line-height: 16px;
      display: table;
      vertical-align: middle;
      text-transform: uppercase;
      margin: 0;
      padding-left: 43px;
      padding-right: 10px;
      max-height: 35px;
      width: 100%;
      position: relative;
      &--show-block-icons{
          padding-right: 5px;
      }
  }
  &__list-item-open-tree{
      width: 42px;
      vertical-align: middle;
      display: table-cell;
  }
  &__list-item-open-tree-icon{
      margin-left: 18px;
      color: $icon-arrow;
      font-size: 10px;
      &:hover{
          cursor: pointer;
      }
      &--open{
          color: $primary-color;
      }
  }
  &__list-item-tree-icon-menu{
      margin-left: 11px;
      color: $primary-color;
  }
  &__list-item-progress{
      position: absolute;
      bottom: 0px;
      right: 0;
      font-size: 9px;
      z-index: 1;
      &--red{
          color: $red;
          background-color: $red;
      }
      &--green{
          color: $green;
          background-color: $green;
      }
      &--yellow{
          color: $yellow;
          background-color: $yellow;
      }
  }

  &__list-menu-button{
      float: right;
      padding: 0;
      margin: 0;
      border: 0;
      background: none;
      outline: none;
      color: $background-grey;
      width: 40px;
      height: 21px;
      font-size: 26px;
      &--primary{
          color: $primary-color;
      }
      &--breadcrumb{
          float: none;
          width: auto;
      }
      &--25{
          width: 25px;
      }
  }
  &__list-menu-block{
      width: 220px;
      border: 1px solid $primary-color;
      background-color: $snow-white;
      position: absolute;
      padding: 0;
      z-index: 15;
      border-radius: 0px;
      float: none;
      inset: auto !important;
      top: 17px !important;
      left: -59px !important;
      transform: none !important;
  }

  &__list-item-separator{
      height: 8px;
      width: 100%;
      background-color: $highlight-border;
      position: absolute;
      bottom: 1px;
      left: 0;
  }

  &__folder-list{
      padding: 10px 0 23px 0;
      list-style: none;
      background: $background-grey;
      position: relative;
      float: left;
      width: 100%;
  }

  &__folder-item{
      display: block;
      width: 100%;
      &--normal{
          padding-left: 47px;
          height: 31px;
      }
      &--consulta{
          margin-left: 46px;
          position: relative;
          float: left;
          width: calc(100% - 45px);
          min-height: 31px;
          border-left: 1px solid $evaluation-tree-children;
      }
      &--consulta-last{
          border-left: none;
      }
  }

  &__folder-item-icon{
      font-size: 11px;
      float: left;
      display: block;
      width: 38px;
      color: $icon-arrow;
      position: relative;
      padding-bottom: 7px;
      padding-top: 5px;
      &--hidden{
          padding-bottom: 3px;
          padding-top: 4px;
      }
      &--consulta{
          top: 0px;
          left: -8px;
          border-radius: 10px;
          background-color: $snow-white;
          padding-bottom: 0px;
          padding-top: 0px;
          width: 15px;
          height: 15px;
      }
  }

  &__folder-item-icon-line{
      height: 20.5px;
      width: 1px;
      background-color: $icon-arrow;
      position: absolute;
      top: 17.5px;
      left: 5px;
      &--no-evaluated{
          top: 16px;
          height: 16px;
      }
      &--evaluated{
          top:18px;
          height: 18px;
      }
  }
  &__folder-item-name{
      float: left;
      display: block;
      margin: 0;
      width: calc(100% - 80px);
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 4px 0 4px 5px;
      &:hover{
          cursor: pointer;
      }
      &--no-evaluated{
          padding-top: 0;
      }

      &--consulta{
          padding: 0px 0 4px 26px;
          height: 31px;
      }

      &--no-clickable{
          &:hover{
              cursor: default;
          }
      }
  }

  &__folder-item-menu{
      float: right;
      display: block;
      color: $primary-color;
      width: 40px;
      padding-top: 1px;
      padding-bottom: 5px;
  }

  &__dropdown-menu{
      width: 20px;
      display: block;
      float: right;
      &--breadcrumb{
          float: none;
          position: relative;
          left: 2px;
          top: 1px;
          // left: 7px;
          // top: 2px;
      }
  }

  &__show-hide-elements{
      float: right;
      margin-right: 31px;
      position: relative;
  }

  &__show-hide-icon{
      color: $icon-arrow;
      float: left;
      top: 2px;
      position: relative;
  }

  &__show-hide-text{
      opacity: 0.5;
      color: $snow-white;
      font-family: MTTMilano, sans-serif;
      font-size: 13px;
      float: right;
      margin-left: 10px;
      margin-bottom: 0;
  }

  &__show-hidden-block{
      margin-top: 0;
      font-weight: 400;
      margin-left: 0;
  }

  &__show-hidden-text{
      color: $evaluation-hidden-text;
      text-transform: uppercase;
      font-family: MTTMilano, sans-serif;
      margin: 0 0 0 45px;
      font-size: 11px;
      font-weight: 400;
      display: inline-block;
  }

  &__hidden-icon{
      color: $subcontainer-ground;
      font-size: 17px;
      position: relative;
      right: 3px;
  }
  &__no-evaluated-icon{
      font-size: 19px;
      position: absolute;
      right: -4px;
      background-color: $snow-white;
      top: 0px;
  }
  &__product4{
      font-size: 15px;
      &:hover{
          cursor: pointer;
      }
  }
  &__hide-no-concept{
      float: right;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: none;
      border: 2px solid $white-special-2;
      position: relative;
      margin-top: 15px;
      margin-right: 9px;
      &:hover{
          background-color: $block-hover-2;
          border-color: $block-hover-2;
          & .evaluation-menu__hide-no-concept-icon{
              color: $border-hover-2;
          }
      }
      &--active{
          background-color: $block-hover-2;
          border-color: $block-hover-2;
          & .evaluation-menu__hide-no-concept-icon{
              color: $border-hover-2;
          }
      }
      &--close-menu{
          width: 20px;
          height: 20px;
          top: -7px;
          margin-top: 0;
          margin-right: 2px;
          padding: 0;
      }
  }
  &__hide-no-concept-icon{
      font-size: 20px;
      position: relative;
      top: 2px;
      color: $white-special-2;
      &--close-menu{
          font-size: 14px;
          top: 0px;
      }
  }
  &__icon-link{
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 25px;
      color: $snow-white;
      position: absolute;
      right: 0;
      margin: 0;
      padding: 0;
      top: 19px;
  }

  &__block-tooltip-buttons{
      width: 100%;
      padding: 5px 11px;
      position: relative;
      float: left;
  }

  &__button-blanqueo{
      width: 100%;
      height: 35px;
      border-radius: 20px;
      outline: none;
      position: relative;
      border: 1px solid $custom-color-E5869D;
      color: $primary-color;
      background-color: $custom-color-E5869D;
      &:hover:not([disabled]){
        border: 1px solid $snow-white;
        box-shadow: 0 0 8px 2px $custom-color-E5869D;
      }
      &[disabled] {
        opacity: 0.5;
      }
  }

  &__icon-button-blanqueo{
      font-size: 20px;
      position: relative;
      top: 2px;
  }

  &__text-button-blanqueo{
      position: relative;
      bottom: 2px;
      font-family: MTTMilano-Bold, sans-serif;
      text-transform: uppercase;
      font-size: 12px;
  }

  &__button-open-manage-lock{
      position: absolute;
      right: 0;
      top: -1px;
      width: 50px;
      text-align: center;
      font-size: 20px;
      height: 35px;
      border-left: 2px solid $primary-color;
      padding-top: 8px;
  }

  &__block-button-lock{
      float: left;
      width: 100%;
  }

  &__list-menu-manage {
      transform: none !important;
      inset: auto !important;
      left: calc(100% - 60px) !important;
      top: 5px !important;
      width: 218px;
      border: 1px solid $red;
      background-color: $snow-white;
      position: absolute;
      padding: 0;
      z-index: 2;
      border-radius: 0px;
      float: none;
      &--tree-closed{
          left: calc(100% + 4px);
      }
  }

  &__block-task-supervision{
      padding: 20px;
      height: auto;
      width: calc(100% - 22px);
      margin: 5px 11px 15px;
      text-align: center;
      border: 1px solid $custom-color-E5869D;
      background-color: transparent;
      border-radius: 20px;
      color: $pillow-group;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 13px;
      line-height: 12px;
      position: relative;
      float: left;
      &--close-menu{
          padding: 10px 6px;
          text-align: center;
      }
  }

  &__title-task-supervision{
      color: $custom-color-E5869D;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 11px;
      text-decoration: underline;
      text-transform: uppercase;
      line-height: 12px;
      margin-bottom: 16px;
  }

  &__info-number-alerts-open {
      position: relative;
      margin: 0;
      background-color: $custom-color-E5869D;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-weight: bold;
      font-style: normal;
      font-size: 14px;
      text-align: center;
      display: inline-block;
      border: none;
      width: 37px;
      height: 20px;
      border-radius: 12px;
      line-height: 20px;
      left: 8px;
  }

  &__help-tooltip{
      position: absolute;
      top: -51px;
      left: calc(100% + 20px);
      z-index: 9999;
  }

  &__icon-notification-supervsion{
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    font-size: 20px;
  }

  &__icon-notification-meatballs{
      font-size: 30px;
      line-height: 21px;
      display: block;
      margin: 14px 0 29px;
      display: inline-block;
  }

  &__info-number-alerts{
      width: 20px;
      height: 20px;
      background-color: $custom-color-E5869D;
      display: inline-block;
      font-size: 10px;
      font-family: Arial, sans-serif;
      font-weight: bold;
      line-height: 20px;
      border-radius: 10px;
      color: $primary-color;
  }
  &__icon-double-lock{
      position: relative;
      top: 2px;
      left: 9px;
      font-size: 20px;
      opacity: 0.5;
      color: $snow-white;
  }
  &__icon-open-window{
      position: relative;
      top: 2px;
      left: 9px;
      font-size: 15px;
      color: #B78D96;
      border: none;
      outline: none;
      background-color: transparent;
      margin: 0;
      padding: 0;
      &--close-menu{
        left: initial;
        right: 2px;
        top: 22px;
      }
  }
  &__block-confident-folder{
      position: absolute;
      top: 5px;
      left: 30px;
  }

  &__confident-folder{
      width: 25px;
      height: 25px;
      display: block;
      border-radius: 14px;
      outline: none;
      font-size: 14px;
      padding: 2px 0 0;
      text-align: center;
      background-color: $snow-white;
      border: 2px solid $primary-color;
      color: $primary-color;
      &:after{
        display: none;
      }
  }

  &__box-select-list {
      border-radius: 0px;
      border: none;
      padding: 0;
      overflow: hidden;
      position: absolute;
      margin: 0;
      width: 168px;
      max-height: 188px;
      top: 0px;
      left: 28px;
      cursor: default !important;
      &--little{
          left: 21px;
      }
  }

  &__block-confident-subfolder{
      position: absolute;
      width: 18px;
      right: 0px;
      top: 3px;
  }

  &__confident-subfolder{
      width: 18px;
      height: 18px;
      display: block;
      border-radius: 10px;
      font-size: 11px;
      padding: 1px 0 0;
      text-align: center;
      border: none;
      outline: none;
      color: $snow-white;
  }
}


.compliance{
  &__dark-blue{
    .evaluation-menu{
      &__list-item{
        &--home{
          @include evaluation-menu-list-item-home($repeat-pattern, transparent);
          border-bottom: 0;
      }
      }
      &__list-item-block{
        &:hover{
          & > .evaluation-menu__parent-box{
              background-color: $background-color-states;
              color: $primary-color;
              & > .evaluation-menu__list-item-triangle{
                  border-top: 28px solid $primary-color;
                  border-left: 23px solid $primary-color;
                  border-bottom: 31px solid $background-color-states;
                  border-right: 18px solid $background-color-states;
                  height: 0 !important;
                  width: 0 !important;
              }
          }

          & > .evaluation-menu__list-item-open-tree{
              background: $background-color-states;
              border-right: 1px solid $primary-color;
          }
          & .evaluation-menu__icon-link, .evaluation-menu__icon-double-lock{
              color: $primary-color;
          }
        }
        &--active{
          & .evaluation-menu__icon-link{
              color: $primary-color;
          }
        }
        &--active-short{
          @include evaluation-menu-list-item-block($background-color-states, $primary-color);
        }
        &--disabled{
          @include evaluation-menu-list-item-block-disabled($snow-white-opacity-20, $snow-white);
        }
      }
      &__parent-box{
        color: $snow-white;
        &--active{
          @include evaluation-menu-parent-box-active($background-color-states, $primary-color);
        }
      }
      &__list-item-icon{
        &--other, &--home{
            @include evaluation-menu-list-item-icon-home($background-color-states);
        }
      }
      &__list-item-text{
          color: $icon-arrow;
      }
      &__list-item-open-tree{
        &--active{
          background: $background-color-states;
          border-right: 1px solid $primary-color;
        }
      }
      &__folder-item{
        &--normal{
            &:hover{
                & .evaluation-menu__folder-item-icon{
                    color: $primary-color;
                }
                & .evaluation-menu__confident-subfolder--active{
                    background-color: $primary-color;
                }
            }
        }
      }

      &__list-menu-button{
        &--folder{
          color: $primary-color;
        }
        &--primary{
          color: $primary-color;
        }
      }
      &__list-menu-block{
        border: 1px solid $primary-color;
      }
      &__folder-list{
        background: $background-color-states;
        border-right: 1px solid $primary-color;
      }
      &__folder-item-icon{
        color: $icon-arrow;
        &:hover{
            color: $primary-color;
        }
        &--active{
          color: $primary-color;
        }
      }
      &__button-blanqueo{
        &--finish{
            background-color: $delete-amount;
            border: 1px solid $delete-amount;
            &:hover:not([disabled]){
                box-shadow: 0 0 8px 2px $delete-amount;
            }
        }
        &--open{
          border: 1px solid $snow-white;
          box-shadow: 0 0 8px 2px $custom-color-E5869D;
        }
      }
      &__hidden-icon {
        color: $subcontainer-ground;
      }
      &__folder-item-name{
        color: $primary-color;
        &--hidden{
          color: $secondary-color;
        }
        &--active{
          font-family: MTTMilano-Bold !important;
        }
      }
      &__folder-item-icon-line{
        background-color: $icon-arrow;
      }
      &__icon-double-lock{
        &--active{
          color: $primary-color;
        }
      }
      &__confident-folder{
          &--active-short{
              background-color: $primary-color;
              border-color: $background-color-states;
              color: $snow-white;
          }
          &--active-report{
              background-color: $primary-color;
              border-color: $subcontainer-border;
              color: $snow-white;
          }
          &--hidden {
              background-color: $subcontainer-ground;
          }
      }
      &__confident-subfolder{
          &--basic{
              background-color: $primary-color;
          }
          &--active{
              background-color: $icon-arrow;
          }
          &--hidden{
              background-color: $subcontainer-ground;
          }
      }
    }
  }
  &__steel-blue{
    .evaluation-menu{
      &__list-item{
        border-color: $secondary-color-2;
        &--home{
          @include evaluation-menu-list-item-home($border-bottom-2, $primary-color-2);
      }
      }
      &__list-item-block{
        @include evaluation-menu-list-item-block-hover($third-color-2, $primary-color-2);
        &--active{
          @include evaluation-menu-list-item-block($third-color-2, $primary-color-2);
        }
        &--active-short{
          background-color: $third-color-2;
        }
        &--disabled{
          @include evaluation-menu-list-item-block-disabled($snow-white-opacity-20, $secondary-color-2);
        }
      }
      &__list-item-icon{
        &--other{
          @include evaluation-menu-list-item-icon-home($secondary-color-2);
        }
        &--home{
          @include evaluation-menu-list-item-icon-home($white-special-2);
        }
      }
      &__parent-box{
        color: $secondary-color-2;
        &--active{
          @include evaluation-menu-parent-box-active($third-color-2, $primary-color-2);
        }
      }
      &__list-item-text{
          color: $white-special-2;
      }
      &__list-item-open-tree{
        &--active{
          background: $third-color-2;
          border-right: 1px solid $primary-color-2;
        }
      }
      &__folder-item{
        &--normal{
          &:hover{
            & .evaluation-menu__folder-item-icon{
              color:  $border-hover-2;
            }
          }
        }
        &--consulta{
          border-color: $border-hover-2;
        }
      }
      &__list-menu-button{
        &--folder{
          color: $border-hover-2;
        }
        &--primary{
          color: $primary-color-2
        }
      }
      &__list-menu-block{
        border: 1px solid $third-color-2;
        border-top-width: 2px;
      }
      &__folder-list{
        background: $third-color-2;
      }
      &__folder-item-icon{
        color: $primary-color-2;
        &:hover{
          color: $border-hover-2;
        }
        &--active{
          color: $border-hover-2;
        }
      }
      &__button-blanqueo{
        color: $primary-color-2;
        border-color: $red-light-2;
        background-color: $red-light-2;
        &:hover:not([disabled]){
            box-shadow: 0 0 8px 2px $red-light-2;
        }
        &--finish{
          color: $primary-color-2;
          border-color: $border-bottom-2;
          background-color: $border-bottom-2;
          &:hover:not([disabled]){
              box-shadow: 0 0 8px 2px $border-bottom-2;
          }
        }
        &--open{
          border: 1px solid $snow-white;
          box-shadow: 0 0 8px 2px $red-light-2;
        }
      }
      &__list-menu-manage {
          border-color: $alert-color-button-2;
          border-top-width: 2px;
      }
      &__block-task-supervision{
        color: $grey-icons-2;
        border-color: $red-light-2;
      }
      &__title-task-supervision{
        color: $red-light-2;
      }
      &__info-number-alerts-open {
          background-color: $red-light-2;
          color: $primary-color-2;
      }
      &__hidden-icon{
        color: $grey-icons-2;
      }
      &__folder-item-name{
        color: $primary-color-2;
        &--hidden{
          color: $grey-icons-2;
        }
        &--active{
          font-family: MTTMilano-Bold !important;
          color: $border-hover-2;
        }
      }
      &__folder-item-icon-line{
        background-color: $border-bottom-2;
      }
      &__info-number-alerts{
        color: $primary-color-2;
        background-color: $red-light-2;
      }
      &__icon-double-lock{
        color: $white-special-2;
        &--active{
          color: $primary-color-2;
        }
      }
      &__confident-folder{
          &--hidden{
              background-color: $grey-icons-2;
          }
      }
    }
  }
  &__uk-steel-blue{
    .evaluation-menu{
      &__list-item{
        border-color: $secondary-color-2;
        &--home{
          @include evaluation-menu-list-item-home($border-bottom-2, $primary-color-2);
        }
      }
      &__list-item-block{
        @include evaluation-menu-list-item-block-hover($third-color-2, $primary-color-2);
        &--active{
          @include evaluation-menu-list-item-block($third-color-2, $primary-color-2);
        }
        &--active-short{
          background-color: $third-color-2;
        }
      }
      &__list-item-icon{
        &--other{
          @include evaluation-menu-list-item-icon-home($secondary-color-2);
        }
        &--home{
          @include evaluation-menu-list-item-icon-home($white-special-2);
        }
      }
      &__parent-box{
        color: $secondary-color-2;
        &--active{
          @include evaluation-menu-parent-box-active($third-color-2, $primary-color-2);
        }
      }
      &__list-item-text{
          color: $white-special-2;
      }
      &__list-item-open-tree{
        &--active{
          background: $third-color-2;
          border-right: 1px solid $primary-color-2;
        }
      }
      &__list-menu-button{
        &--folder{
          color: $border-hover-2;
        }
        &--primary{
          color: $primary-color-2
        }
      }
      &__folder-list{
        background: $third-color-2;
      }
      &__folder-item-icon{
        color: $primary-color-2;
        &:hover{
          color: $border-hover-2;
        }
        &--active{
          color: $border-hover-2;
        }
      }
      &__button-blanqueo{
        color: $primary-color-2;
        border-color: $red-light-2;
        background-color: $red-light-2;
        &:hover:not([disabled]){
            box-shadow: 0 0 8px 2px $red-light-2;
        }
        &--finish{
          color: $primary-color-2;
          border-color: $border-bottom-2;
          background-color: $border-bottom-2;
          &:hover:not([disabled]){
              box-shadow: 0 0 8px 2px $border-bottom-2;
          }
        }
        &--open{
          border: 1px solid $snow-white;
          box-shadow: 0 0 8px 2px $red-light-2;
        }
      }
      &__list-menu-block {
          border-color: $alert-color-button-2;
          border-top-width: 2px;
      }
      &__list-menu-manage {
          border-color: $alert-color-button-2;
          border-top-width: 2px;
      }
      &__block-task-supervision{
        color: $grey-icons-2;
        border-color: $red-light-2;
      }
      &__title-task-supervision{
        color: $red-light-2;
      }
      &__info-number-alerts-open {
          background-color: $red-light-2;
          color: $primary-color-2;
      }
      &__hidden-icon{
        color: $grey-icons-2;
      }
      &__folder-item-name{
        color: $primary-color-2;
        &--hidden{
          color: $grey-icons-2;
        }
        &--active{
          font-family: MTTMilano-Bold !important;
          color: $border-hover-2;
        }
      }
      &__folder-item-icon-line{
        background-color: $border-bottom-2;
      }
      &__info-number-alerts{
        color: $primary-color-2;
        background-color: $red-light-2;
      }
      &__icon-double-lock{
        color: $white-special-2;
        &--active{
          color: $primary-color-2;
        }
      }
      &__confident-folder{
          &--hidden{
              background-color: $grey-icons-2;
          }
      }
    }
  }
  &__icam-red{
    .evaluation-menu{
      &__list-item{
        border-color: $third-color-icam;
        &--home{
                  @include evaluation-menu-list-item-home($title-text-icam, $primary-color-icam);
              }
        }
      &__list-item-block{
        @include evaluation-menu-list-item-block-hover($hover-block-icam, $primary-color-icam);
        &--active{
          @include evaluation-menu-list-item-block($hover-block-icam, $primary-color-icam);
        }
        &--active-short{
          background-color: $hover-block-icam;
        }
      }
      &__list-item-icon{
        &--other,
        &--home{
          @include evaluation-menu-list-item-icon-home($snow-white);
        }
      }
      &__parent-box{
        color: $snow-white;
        &--active{
          @include evaluation-menu-parent-box-active($hover-block-icam, $primary-color-icam);
        }
      }
      &__list-item-text{
          color: $snow-white-opacity-50;
      }
      &__folder-item{
        &--consulta{
          border-color: $primary-color-icam;
        }
      }
      &__list-menu-button{
        &--folder{
          color: $primary-color-icam;
        }
        &--primary{
          color: $primary-color-icam;
        }
      }
      &__list-menu-block{
        border: 1px solid $primary-color-icam;
        border-top-width: 2px;
      }
      &__icon-open-window{
        &--active{
            color: $primary-color-icam;
        }
      }
    }
  }
  &__gm-law{
    .evaluation-menu{
      &__list-item{
        border-color: $third-color-gm;
        &--home{
          @include evaluation-menu-list-item-home($title-text-gm, $primary-color-gm);
        }
      }
      &__list-item-block{
        @include evaluation-menu-list-item-block-hover($hover-block-gm, $primary-color-gm);
        &--active{
          @include evaluation-menu-list-item-block($hover-block-gm, $primary-color-gm);
        }
        &--active-short{
          background-color: $hover-block-gm;
        }
      }
      &__list-item-icon{
        &--other,
        &--home{
          @include evaluation-menu-list-item-icon-home($snow-white);
        }
      }
      &__parent-box{
        color: $snow-white;
        &--active{
          @include evaluation-menu-parent-box-active($hover-block-gm, $primary-color-gm);
        }
      }
      &__list-item-text{
          color: $snow-white-opacity-50;
      }
      &__list-item-open-tree-icon{
        &--open{
          color: $primary-color-gm;
        }
      }
      &__folder-item{
        &--normal{
          &:hover{
            & .evaluation-menu__folder-item-icon{
              color:  $primary-color-gm;
            }
          }
        }
        &--consulta{
          border-color: $primary-color-gm;
        }
      }
      &__list-menu-button{
        &--folder{
          color: $primary-color-gm;
        }
        &--primary{
          color: $primary-color-gm;
        }
      }
      &__list-menu-block{
        border: 1px solid $primary-color-gm;
        border-top-width: 2px;
      }
      &__folder-list{
        background: $hover-block-gm;
      }
      &__folder-item-icon{
        color: $secondary-color-gm;
        &:hover{
          color: $primary-color-gm;
        }
        &--active{
          color: $primary-color-gm;
        }
      }
      &__folder-item-name{
        color: $custom-black-landing;
        &--hidden{
          color: $secondary-color-gm;
        }
        &--active{
          font-family: MTTMilano-Bold !important;
          color: $custom-black-landing;
        }
      }
      &__confident-folder{
          &--hidden {
              background-color: $secondary-color-gm;
          }
      }
    }
  }
  &__com-de{
    .evaluation-menu{
      &__list-item{
        border-color: $third-color-com-de;
        &--home{
          @include evaluation-menu-list-item-home($title-text-com-de, $primary-color-com-de);
        }
      }
      &__list-item-block{
        @include evaluation-menu-list-item-block-hover($hover-block-com-de, $primary-color-com-de);
        &--active{
          @include evaluation-menu-list-item-block($hover-block-com-de, $primary-color-com-de);
        }
        &--active-short{
          background-color: $hover-block-com-de;
        }
      }
      &__list-item-icon{
        &--other,
        &--home{
          @include evaluation-menu-list-item-icon-home($snow-white);
        }
      }
      &__parent-box{
        color: $snow-white;
        &--active{
          @include evaluation-menu-parent-box-active($hover-block-com-de, $primary-color-com-de);
        }
      }
      &__list-item-text{
          color: $snow-white-opacity-50;
      }
      &__list-item-open-tree-icon{
        &--open{
          color: $primary-color-com-de;
        }
      }
      &__folder-item{
        &--normal{
          &:hover{
            & .evaluation-menu__folder-item-icon{
              color:  $primary-color-com-de;
            }
          }
        }
        &--consulta{
          border-color: $primary-color-com-de;
        }
      }
      &__list-menu-button{
        &--folder{
          color: $primary-color-com-de;
        }
        &--primary{
          color: $primary-color-com-de;
        }
      }
      &__list-menu-block{
        border: 1px solid $primary-color-com-de;
        border-top-width: 2px;
      }
      &__folder-list{
        background: $hover-block-com-de;
      }
      &__folder-item-icon{
        color: $secondary-color-com-de;
        &:hover{
          color: $primary-color-com-de;
        }
        &--active{
          color: $primary-color-com-de;
        }
      }
      &__folder-item-name{
        color: $custom-black-landing;
        &--hidden{
          color: $secondary-color-com-de;
        }
        &--active{
          font-family: MTTMilano-Bold !important;
          color: $custom-black-landing;
        }
      }
      &__confident-folder{
          &--hidden {
              background-color: $secondary-color-com-de;
          }
      }
    }
  }
  &__com-fr{
    .evaluation-menu{
      &__list-item{
        border-color: $third-color-com-fr;
        &--home{
          @include evaluation-menu-list-item-home($title-text-com-fr, $primary-color-com-fr);
        }
      }
      &__list-item-block{
        @include evaluation-menu-list-item-block-hover($hover-block-com-fr, $primary-color-com-fr);
        &--active{
          @include evaluation-menu-list-item-block($hover-block-com-fr, $primary-color-com-fr);
        }
        &--active-short{
          background-color: $hover-block-com-fr;
        }
      }
      &__list-item-icon{
        &--other,
        &--home{
          @include evaluation-menu-list-item-icon-home($snow-white);
        }
      }
      &__parent-box{
        color: $snow-white;
        &--active{
          @include evaluation-menu-parent-box-active($hover-block-com-fr, $primary-color-com-fr);
        }
      }
      &__list-item-text{
          color: $snow-white-opacity-50;
      }
      &__list-item-open-tree-icon{
        &--open{
          color: $primary-color-com-fr;
        }
      }
      &__folder-item{
        &--normal{
          &:hover{
            & .evaluation-menu__folder-item-icon{
              color:  $primary-color-com-fr;
            }
          }
        }
        &--consulta{
          border-color: $primary-color-com-fr;
        }
      }
      &__list-menu-button{
        &--folder{
          color: $primary-color-com-fr;
        }
        &--primary{
          color: $primary-color-com-fr;
        }
      }
      &__list-menu-block{
        border: 1px solid $primary-color-com-fr;
        border-top-width: 2px;
      }
      &__folder-list{
        background: $hover-block-com-fr;
      }
      &__folder-item-icon{
        color: $secondary-color-com-fr;
        &:hover{
          color: $primary-color-com-fr;
        }
        &--active{
          color: $primary-color-com-fr;
        }
      }
      &__folder-item-name{
        color: $custom-black-landing;
        &--hidden{
          color: $secondary-color-com-fr;
        }
        &--active{
          font-family: MTTMilano-Bold !important;
          color: $custom-black-landing;
        }
      }
      &__confident-folder{
          &--hidden {
              background-color: $secondary-color-com-fr;
          }
      }
    }
  }
}
