.field-type-report {
  &__report-container {
      margin: 0;
      padding: 0 10px;
      background-color: $background-grey-other;
      margin-top: 7px;
      list-style: none;
  }
}

.compliance{
  &__icam-red{
    .field-type-report {
      &__report-container {
        background-color: $background-grey-icam;
      }
    }
  }
  &__gm-law{
    .field-type-report {
      &__report-container {
        background-color: $background-grey-gm;
      }
    }
  }
  &__com-de{
    .field-type-report {
      &__report-container {
        background-color: $background-grey-com-de;
      }
    }
  }
  &__com-fr{
    .field-type-report {
      &__report-container {
        background-color: $background-grey-com-fr;
      }
    }
  }
}
