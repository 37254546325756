.available-processes {
  padding: 10px;
  &__col {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding-right: 5px;
      padding-left: 8px;

      &--tooltip {
          max-width: 0;
      }
  }

  &__block-search-v3 {
      position: relative;
      float: left;
      width: 20px;
      margin-left: 9px;

      &--100 {
          width: 100%;
          margin-left: 0;
      }
  }

  &__tax-calendar {
      float: right;
  }

  &__table-row:hover .available-processes__description-container {
      opacity: 1;
  }

  &__process-cell-container {
      display: flex;
  }

  &__description-container {
      opacity: 0;
      padding-left: 10px;
  }

  &__description-icon {
      font-size: 18px;
      color: $border-hover-2;
      cursor: pointer;
  }

  &__description-text span {
      font-family: Arial ,sans-serif !important;
      font-size: 11px !important;
      color: $primary-color-2 !important;
  }
}

.compliance{
  &__uk-steel-blue{
    .available-processes {
      &__description-tooltip{
        left: 40px !important;
      }
    }
  }
}
