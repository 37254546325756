@mixin field-type-text-icon-references-border($color){
  width: 26px;
  height: 21px;
  top: 4px;
  color: $color;
  border: 2px solid $color;
}

.field-type-text {
  &__block-text-label {
      max-width: 0;
      width: calc(100% - 22px);
      display: table-cell;
  }

  &__tooltip-block {
      display: inline;
      height: 19px;
      position: relative;
      float: left;
      font-size: 14px;
      color: $primary-color;
      margin-right: -5px;
      // padding: 3px 0px 0px 0px;
      padding: 0;

      &:hover {
          cursor: pointer;
      }
      // &--law{
      //     margin-right: -5px;
      //     padding: 3px 0px 0px 0px;
      // }
      // &--law-bool{
      //     margin-top: 2px;
      //     padding: 3px 0px 0px 11px;
      // }
      &--listlaw {
          margin-right: 0;
          padding: 2px 0px 0px 0px;
      }

      &--list-lco-simple {
          margin-left: 9px;
      }
  }

  &__icon-references {
      font-size: 16px;
      float: right;
      position: absolute;
      right: 24px;
      top: 5px;
      border-radius: 10px;
      padding: 0;
      height: 16px;
      margin: 0 3px;

      &:hover {
          cursor: pointer;
      }

      &--2-icons {
          right: 52px;
      }

      &--3-icons {
          right: 80px;
      }

      &--less-top {
          top: 2px;
      }
  }

  &__icon-references-icon{
      position: absolute;
      left: 3px;
      top: 1px;
  }

  &__reference-body {
      height: auto;
      min-height: 180px;
      z-index: 35;
      position: absolute;
      top: 46px;
      background-color: $snow-white;
      width: 100%;
      border: 1px solid $border-color-other;
  }

  &__label-info {
      margin: 0;
      float: left;
      max-width: 90%;
  }

  &__icon-link-rrss {
      font-size: 22px;
      opacity: 1;
      position: absolute;
      right: 6px;
      top: 6px;
  }

  &__extend-icon {
      position: absolute;
      right: 6px;
      bottom: 5px;
      font-size: 17px;
      color: $attachment-custom-grey-other;
  }

  &__delete-content-icon-textarea {
      right: 14px;
      bottom: 0;
      float: none;
      &--has-voice-record{
        bottom: 46px !important;
      }
  }
  //////////////////////////////////////////////
  ///////////////////Custom////////////////////
  ////////////////////////////////////////////
  &__custom-sequential-label {
      color: $attachment-custom-grey;
      font-family: 'MTTMilano-Bold', sans-serif;
      font-weight: normal;
      font-size: 26px;
      text-align: center;
      margin-bottom: 70px;
  }

  &__custom-input-textarea-poll {
      height: 184px;
      width: 701px;
      border: 1px solid $custom-border-grey;
      background-color: $snow-white;
      box-shadow: 0 1px 4px 0 $modal-help-box;
      position: relative;
      margin: 0 auto;
  }

  &__custom-font-icon {
      font-size: 20px;
      top: 8px;
      right: 8px;
  }

  &__sequential-question-list {
      width: 100%;
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;
  }

  &__sequential-question-item {
      height: 47px;
      width: 221px;
      border: 1px solid $border-modal;
      border-radius: 2px;
      background-color: $background-grey;
      margin: 0 auto 17px;

      &--list {
          width: 321px;
      }

      &--green {
          border-color: $block-state-bar-left;
          background-color: $question-green;
      }

      &--red {
          border-color: $advice-close;
          background-color: $question-red;
      }

      &:hover {
          cursor: pointer;
      }
  }

  &__sequential-question-icon {
      font-size: 23px;
      position: relative;
      top: 11px;
      padding-left: 11px;
      float: left;
  }

  &__sequential-question-text {
      color: $attachment-custom-grey;
      font-family: 'MTTMilano', sans-serif;
      font-size: 20px;
      position: relative;
      top: 8px;
      padding-left: 8px;
      width: calc(100% - 39px);
      float: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &--list {
          padding-left: 14px;
      }
  }

  &__sibila {
      margin-bottom: 0;
  }

  &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 30px;
      border: 1px solid;
      margin-bottom: 10px;
  }

  &__footer-button {
      display: flex;
      align-items: center;
      border-bottom: 2px solid;
      padding: 4px 10px 5px 5px;
      cursor: pointer;
  }

  &__footer-button-icon {
      font-size: 18px;
  }

  &__footer-button-text {
      text-transform: uppercase;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 11px;
      padding-left: 5px;
  }
  &__hasAudio{
    padding-bottom: 0;
  }
  &__mic-icon{
    font-size: 16px;
    color: #001976;
    position: absolute;
    right: 28px;
    top: 6px;
    &:hover{
      cursor: pointer;
    }
    &--landing{
      top: 3px;
      color: #000;
    }
    &--no-editable-landing{
      color:#898989;
      &:hover{
        cursor: not-allowed;
      }
    }
    &--no-editable{
      color:#989898;
      &:hover{
        cursor: not-allowed;
      }
    }
  }

  &__audio-box{
    width: 100%;
    height: 42px;
    background-color: #E5E8F1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 0 10px;
    cursor: default;
    &--no-editable{
      background-color: transparent;
    }
    &--landing{
      background: #F4F4F4;
    }
    &--landing-acceptation{
      background: #D8D8D8;
    }
  }

  &__record-info{
    font-family: Arial;
    font-size: 11px;
    margin: 0;
    font-weight: bold;
      color: #001978;
    &--text{
      color: #5C6BA9;
      font-weight: normal;
    }
    &--no-editable{
      color: #989898;
    }
    &--landing{
      color: #000000;
      font-family: MTTMilano;
    }
  }

  &__audio-block{
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    &--accept-check{
      position: relative;
      left: 20px;
    }
  }
  &__audio-block-play{
    font-size: 28px;
    position: relative;
    left: 15px;
    color: #001978;
    &:hover{
      cursor: pointer;
    }
    &--landing{
      font-size: 21px;
      color: #000000;
    }
  }
  &__audio-block-download{
    font-size: 20px;
    position: relative;
    color: #001978;
    padding-right: 8px;
    &:hover{
      cursor: pointer;
    }
    &--landing{
      font-size: 15px;
      color: #000000;
    }
  }
  &__audio-block-delete{
    font-size: 20px;
    position: relative;
    color: #001978;
    &:hover{
      cursor: pointer;
    }
    &--landing{
      font-size: 15px;
      color: #000000;
    }
  }
  &__accept-check-label{
    margin: 0px 10px 14px;
    &--landing{
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row;
    }
  }
  &__refresh-animation{
    animation:spin 2000ms infinite ease-in-out;
  }
  &__transcription-block{
    height: 20px;
    background-color: #E5E8F1;
    border: 1px solid #7F8CBB;
    width: fit-content;
    padding: 3px 6px;
    margin: 10px 8px 0;
    &--landing{
      border: 1px solid #D8D8D8;
      background-color: #F4F4F4;
      margin-bottom: 10px;
    }
    &--box-communication{
      color: #7F8CBB;
    }
    &--box-communication-landing{
      border: 1px solid #D8D8D8;
      background-color: #F4F4F4;
    }
  }
  &__transcription-text{
    margin: 0;
    color: #7F8CBB;
    font-family: MTTMilano;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    &--landing{
      color: #AAAEB0;
    }
    &--box-communication-landing{
      color: #AAAEB0;
    }
  }
}

.compliance{
  &__dark-blue{
    .field-type-text {
      &__icon-references{
        &--border{
            @include field-type-text-icon-references-border($primary-color);
        }
      }
      &__icon-link-rrss{
        color: $primary-color;
      }
      &__sequential-question-item{
        background-color: $background-color-states;
      }
      &__footer{
        background-color: $background-grey;
        border-color: $result-search-quote-color;
      }
      &__footer-button{
        color: $primary-color;
        border-color: $primary-color;
        &:hover {
            background-color: $subcontainer-border;
        }

        &--disabled {
            color: $add-layer-disabled;
            border-color: $add-layer-disabled;
            cursor: default;

            &:hover {
                background-color: $background-grey;
            }
        }
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue{
    .field-type-text {
      &__icon-references{
        &--border{
            @include field-type-text-icon-references-border($border-hover-2);
        }
      }
      &__icon-link-rrss{
        color: $border-hover-2;
      }
    }
  }
  &__icam-red{
    .field-type-text {
      &__icon-references{
        &--border{
            @include field-type-text-icon-references-border($secondary-color-icam);
            &:hover {
                cursor: default;
            }
        }
      }
      &__icon-link-rrss{
        color: $primary-color-icam;
      }
      &__footer{
        background-color: $background-grey-disabled-icam;
        border-color: $border-block-icam;
      }
      &__footer-button{
        color: $primary-color-icam;
        border-color: $primary-color-icam;

        &:hover {
            background-color: $hover-block-icam;
        }

        &--disabled {
            color: $border-block-icam;
            border-color: $border-block-icam;
            cursor: default;

            &:hover {
                background-color: $background-grey-disabled-icam;
            }
        }
      }
    }
  }
  &__gm-law{
    .field-type-text {
      &__icon-references{
        &--border{
            @include field-type-text-icon-references-border($secondary-color-gm);
            &:hover {
                cursor: default;
            }
        }
      }
      &__icon-link-rrss{
        color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    .field-type-text {
      &__icon-references{
        &--border{
            @include field-type-text-icon-references-border($secondary-color-com-de);
            &:hover {
                cursor: default;
            }
        }
      }
      &__icon-link-rrss{
        color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .field-type-text {
      &__icon-references{
        &--border{
            @include field-type-text-icon-references-border($secondary-color-com-fr);
            &:hover {
                cursor: default;
            }
        }
      }
      &__icon-link-rrss{
        color: $primary-color-com-fr;
      }
    }
  }
  &__landing-custom{
    .field-type-text {
      &__icon-references{
        &--border{
            width: 20px;
            top: 4px;
        }
      }
    }
  }
}
@media (max-width: $max-width-smartphone) { //Smartphone
  .field-type-text {
    &__audio-box{
      &--landing{
        flex-direction: column;
        align-content: flex-start;
        height: auto;
        padding: 20px 10px;
        justify-content: center;
        align-items: flex-start;
      }
    }
    &__record-info{
      &--landing{
        text-transform: uppercase;
      }
    }
    &__audio-block{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      left: 0;
    }
    &__audio-block-play{
      &--landing{
        position: static;
        left: 0;
      }
    }
    &__icons-block{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 60px;
  
    }
  }
}
