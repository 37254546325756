@mixin users-admin-main-rounded-toolbar($firsColor, $secondColor, $thirdColor) {
  color: $firsColor;
  & > * {
      border-color: $firsColor;
      &:hover, &.common__selected {
          background-color: $secondColor;
          color: $thirdColor;
      }
  }
}

.users-admin-main {
  position: relative;

  &__toolbar {
      margin: 18px 0;
      height: 33px;
      & .common__switch--ok {
          float: left;

          & > label {
              margin: 0;
              margin-left: 5px;
              font-weight: 500;
          }
      }
  }

  &__toolbar-left-options {
      float: left;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      color: $subcontainer-ground;
      line-height: 28px;

      & > div {
          float: left;
          display: flex;
          align-items: center;
      }
  }

  &__toolbar-right-options {
      float: right;
      color: $primary-color;
  }

  &__rounded-toolbar {
      display: inline-block;
      padding: 0 5px;
      margin-bottom: 0;
      margin-left: 20px;
      font-size: 0;
      vertical-align: middle;
      line-height: 23px;
      color: $primary-color;

      & > * {
          float: left;
          width: 33px;
          height: 22px;
          text-align: center;
          border: 2px solid $primary-color;
          border-right-width: 1px;
          border-left-width: 1px;

          &:last-child {
              border-radius: 0 50px 50px 0px;
          }

          &:first-child {
              border-radius: 50px 0px 0px 50px;
          }
      }
  }

  &__icon-button {
      border: none;
      outline: none;
      background: none;
      height: 18px;
      width: 31px;
      padding: 2px 1px 0;
  }

  &__contacts-options-clients {
      float: left;
      cursor: pointer;
      margin: 0 18px;
      height: 31px;
      line-height: 28px;
      position: relative;
  }

  &__contacts-options-client-name {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 344px;
      display: block;
      padding-right: 49px;
      letter-spacing: 0.25px;
      position: relative;
      padding-top: 2px;
  }

  &__name-child {
      position: relative;
      float: left;
      line-height: 18px;
  }

  &__contacts-options-icon-header {
      font-size: 20px;
      color: $primary-color;
      margin: 0 11px;
      vertical-align: middle;
  }

  &__list-menu-block {
      margin: 0;
      inset: initial !important;
      left: initial !important;
      right: 27px !important;
      top: 0px !important;
      transform: none !important;
      margin-right: 2px;
      width: 207px;
      border: 1px solid $primary-color;
      background-color: $snow-white;
      position: absolute;
      padding: 0;
      z-index: 2;
      border-radius: 0px;
      float: none;
  }

  &__add {
      display: inline-block;
  }

  &__table-body{
    display: block;
    position: relative;
    width: 100%;
  }

  &__col {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding-right: 5px;
      max-width: 0;
      &--type {
          width: 75px;
          min-width: 75px;
      }

      &--name {
          width: 16%;
      }

      &--position {
          width: 16%;
      }

      &--company-name {
          width: 16%;
      }

      &--email {
          width: 16%;
      }

      &--phone1 {
          width: 12%;
      }

      &--role {
          width: calc(24% - 150px);
      }

      &--actions {
          width: 75px;
          min-width: 75px;
          position: relative;
          padding-right: 0;
      }
  }

  &__group-col{
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding-right: 5px;
      max-width: 0;
      &--name{
        width: calc(100% - 210px);;
      }
      &--members{
        width: 110px;
        min-width: 110px;
        text-align: right;
      }
      &--actions {
        width: 25px;
        min-width: 25px;
        position: relative;
      }
  }

  &__column-filter {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      font-weight: normal;
      background-color: $snow-white;
      border: 1px solid $column-filter-border;
      border-radius: 0;
      -webkit-box-shadow: 0 6px 12px $column-filter-shadow;
      box-shadow: 0 6px 12px $column-filter-shadow;
      margin: 0;
      padding: 5px 7px;
  }

  &__contacts-options-clients-list {
      font-family: Arial, sans-serif;
      font-size: 12px;
      width: calc(100% + 2px);
      left: -1px;
  }

  &__column-filter-list {
      max-height: 150px;
      overflow-y: auto;
      margin-bottom: 0;
      list-style: none;
      padding: 7px 0 0;

      & > li {
          & > label {
              font-weight: normal;
              white-space: nowrap;
              overflow-x: hidden;
              text-overflow: ellipsis;
          }
      }
  }

  &__rectangle-info {
      color: $snow-white;
      background-color: $primary-color;
  }

  &__pagination {
      // text-align: center;
      height: 22px;
      display: block;
      float: left;
      width: 100%;
      margin-top: 46px;
      position: relative;
  }

  &__rounded-toolbar-icons {
      font-size: 16px;
      position: relative;
      bottom: 1px;
      right: 1px;

      &--group {
          font-size: 20px;
          bottom: 3px;
          left: 1px;
      }
  }

  &__table-icon {
      font-size: 20px;
      color: $primary-color;
      padding: 0 15px;
  }

  &__user-group-info {
      font-size: 25px;
      position: relative;
      top: 1px;
      padding-right: 6px;
      color: $primary-color;
  }

  &__block-buttons {
      display: inline-block;
  }

  &__icon-check {
      font-size: 18px;
      position: relative;

      &:hover {
          cursor: pointer;
      }

      &--header {
          padding-left: 15px;
      }
  }
  &__icon-selected {
      right: 9px;
      top: 11px;
      position: absolute;
      font-size: 10px;
      color: $primary-color;
      z-index: 1;
      cursor: pointer;
  }
  &__table-row-group{
    &:hover{
      & .users-admin-main__user-group-info{
        display: none;
      }
    }
  }
  &__new-inline-button{
    display: inline-block;
  }
}

.compliance{
  &__dark-blue{
    .users-admin-main {
        &__rounded-toolbar {
            @include users-admin-main-rounded-toolbar($primary-color, $primary-color, $snow-white);
        }
        &__contacts-options-clients {
            border: 1px solid $primary-color;

            &:hover {
                background-color: $background-color-states;
            }
        }
        &__table-members {
            background-color: $table-members;
        }
        &__col-table-selected{
            background-color: $background-color-states;
        }
        &__table-row-group{
          background-color: $main-col-table;
        }
    }
  }
  &__steel-blue,
  &__uk-steel-blue{
    .users-admin-main {
        &__toolbar-left-options {
            color: $grey-icons-2;
        }
        &__rounded-toolbar {
            @include users-admin-main-rounded-toolbar($border-hover-2, $third-color-2, $border-hover-2);
        }
        &__contacts-options-clients {
            background-color: $third-color-2;
            color: $primary-color-2;
        }
        &__contacts-options-icon-header {
            color: $primary-color-2;
        }
        &__list-menu-block {
            border: 1px solid $third-color-2;
            border-top-width: 2px;
        }
        &__table-members {
            background-color: $background-grey-disabled-2;
        }
        &__col-table-selected{
            background-color: $third-color-rgba-mid;
        }
        &__table-row-group{
          background-color: $secondary-color-2;
        }
        &__rectangle-info {
            background-color: $primary-color-2;
        }
        &__table-icon {
            color: $border-hover-2;
        }
        &__user-group-info {
            color: $primary-color-2;
        }
        &__icon-selected {
            color: $primary-color-2;
        }
    }
  }
  &__icam-red{
    .users-admin-main {
        &__rounded-toolbar {
            @include users-admin-main-rounded-toolbar($primary-color-icam, $third-color-icam, $primary-color-icam);
        }
        &__contacts-options-client-name {
            color: $custom-black-landing;
            background-color: $third-color-icam;

            &:hover {
                background-color: $third-color-icam;
                border-color: $third-color-icam;
            }
        }
        &__contacts-options-icon-header {
            color: $primary-color-icam;
        }
        &__list-menu-block {
            border: 1px solid $primary-color-icam;
            border-top-width: 2px;
        }
        &__table-members {
            background-color: $background-grey-disabled-icam;
        }
        &__col-table-selected{
            background-color: $hover-block-icam;
        }
        &__table-row-group{
          background-color: $border-block-icam;
        }
        &__rectangle-info {
            background-color: $custom-black-landing;
            border-color: $custom-black-landing;
        }
        &__table-icon {
            color: $primary-color-icam;
        }
        &__user-group-info {
            color: $custom-black-landing;
        }
        &__icon-selected {
            color: $primary-color-icam;
        }
    }
  }
  &__gm-law{
    .users-admin-main {
        &__rounded-toolbar {
            @include users-admin-main-rounded-toolbar($primary-color-gm, $third-color-gm, $primary-color-gm);
        }
        &__contacts-options-client-name {
            color: $custom-black-landing;
            background-color: $third-color-gm;

            &:hover {
                background-color: $third-color-gm;
                border-color: $third-color-gm;
            }
        }
        &__contacts-options-icon-header {
            color: $primary-color-gm;
        }
        &__list-menu-block {
            border: 1px solid $primary-color-gm;
            border-top-width: 2px;
        }
        &__table-members {
            background-color: $background-grey-disabled-gm;
        }
        &__col-table-selected {
            background-color: $hover-block-gm;
        }
        &__table-row-group{
          background-color: $border-block-gm;
        }
        &__rectangle-info {
            background-color: $custom-black-landing;
            border-color: $custom-black-landing;
        }
        &__table-icon {
            color: $primary-color-gm;
        }
        &__user-group-info {
            color: $custom-black-landing;
        }
        &__icon-selected {
            color: $primary-color-gm;
        }
    }
  }
  &__com-de{
    .users-admin-main {
        &__rounded-toolbar {
            @include users-admin-main-rounded-toolbar($primary-color-com-de, $third-color-com-de, $primary-color-com-de);
        }
        &__contacts-options-client-name {
            color: $custom-black-landing;
            background-color: $third-color-com-de;

            &:hover {
                background-color: $third-color-com-de;
                border-color: $third-color-com-de;
            }
        }
        &__contacts-options-icon-header {
            color: $primary-color-com-de;
        }
        &__list-menu-block {
            border: 1px solid $primary-color-com-de;
            border-top-width: 2px;
        }
        &__table-members {
            background-color: $background-grey-disabled-com-de;
        }
        &__col-table-selected {
            background-color: $hover-block-com-de;
        }
        &__table-row-group{
          background-color: $border-block-com-de;
        }
        &__rectangle-info {
            background-color: $custom-black-landing;
            border-color: $custom-black-landing;
        }
        &__table-icon {
            color: $primary-color-com-de;
        }
        &__user-group-info {
            color: $custom-black-landing;
        }
        &__icon-selected {
            color: $primary-color-com-de;
        }
    }
  }
  &__com-fr{
    .users-admin-main {
        &__rounded-toolbar {
            @include users-admin-main-rounded-toolbar($primary-color-com-fr, $third-color-com-fr, $primary-color-com-fr);
        }
        &__contacts-options-client-name {
            color: $custom-black-landing;
            background-color: $third-color-com-fr;

            &:hover {
                background-color: $third-color-com-fr;
                border-color: $third-color-com-fr;
            }
        }
        &__contacts-options-icon-header {
            color: $primary-color-com-fr;
        }
        &__list-menu-block {
            border: 1px solid $primary-color-com-fr;
            border-top-width: 2px;
        }
        &__table-members {
            background-color: $background-grey-disabled-com-fr;
        }
        &__col-table-selected {
            background-color: $hover-block-com-fr;
        }
        &__table-row-group{
          background-color: $border-block-com-fr;
        }
        &__rectangle-info {
            background-color: $custom-black-landing;
            border-color: $custom-black-landing;
        }
        &__table-icon {
            color: $primary-color-com-fr;
        }
        &__user-group-info {
            color: $custom-black-landing;
        }
        &__icon-selected {
            color: $primary-color-com-fr;
        }
    }
  }
}
