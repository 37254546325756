@mixin add-comment-title($backgroundColor, $color){
  background: $backgroundColor;
  color: $color;
  padding: 5px 6px 3px 6px;
  width: fit-content;
  margin-left: 9px;
  margin-top: 5px;
}

@mixin box-comunication-channel-list-item($borderColor, $backgroundColor){
border-left-color: $borderColor;
// &:hover:not([disabled]) {
//     background: $backgroundColor;
// }
}

@mixin header-icon($color){
  font-size: 30px;
  color: $color;
  float: left;
}

@mixin box-text($color){
  margin: 0;
  color: $color;
  font-size: 16px;
  font-family: MTTMilano, sans-serif;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 16px;
  float: left;
  padding: 17px 0 0 11px;
}

.box-comunication-channel {
  &__add-comment-title{
      margin-bottom: 0;
      font-family: Arial, sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      &--landing{
        color: #000000;
        font-family: 'lato_regular';
        font-size: 11px;
      }
  }

  &__author{
      clear: both;
      text-transform: uppercase;
      font-size: 10px;
      padding-right: 6px;
      float:left;
      &--default{
        float: none;
        margin: 0;
        line-height: 11px;
      }
  }

  &__block-header-comment{
      font-family: Arial, sans-serif;
      padding: 0 5px;
      height: 40px;
      width: auto;
      display: inline-block;
  }

  &__box-icon-left{
      position: absolute;
      left: -21px;
      top: 0;
      font-size: 20px;
      width: 40px;
      height: 40px;
      text-align: center;
      padding: 7px 0;
  }

  &__comment-list{
      margin: 50px 0 0 0;
      padding: 0 5px 4px 20px;
      list-style: none;
      width: 100%;
      &--full-add-comment{
          height: 412px;
          overflow: auto;
          overflow-x: hidden;
          margin-top: 17px;
      }
      &--full-no-comment{
          height: 544px;
          overflow: auto;
          overflow-x: hidden;
      }
  }

  &__list-item{
      width: 100%;
      position: relative;
      padding-left: 19px;
      border-left: 1px solid;
      padding-bottom: 13px;
      &:has(.field-type-text__transcription-block) .box-comments__comment-text--full-screen {
        padding-bottom: 0 !important;
      }
  }

  &__paragraph{
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 0;
      float: left;
      &--default{
        line-height: 15px;
        float: none;
      }
  }
  &__read-by{
    opacity: 0.8;
    font-family: Arial;
    font-size: 10px;
    line-height: 13px;
    margin: 0;
  }
  &__read-by-icon{
    font-size: 13px;
    margin: 5px;
    position: relative;
    top: 2px;
  }
  &__audio-icon{
    font-size: 12px;
    position: relative;
    bottom: 1px;
  }
  &__info-block{
    float: right;
    position: relative;
    border: none;
    background: none;
    margin-top: 15px;
    margin-right: 8px;
    padding: 0;
    &--default{
      margin-top: 11px;
    }
  }
  &__info-icon{
    font-size: 19px;
  }
  &__count-number{
    position: absolute;
    background-color: #C10F0B;
    color: #FFFFFF;
    font-family: 'lato_regular';
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;
    padding: 2px 4px;
    border-radius: 50%;
    right: -7px;
    top: -8px;
    font-weight: bold;
    &--default{
      font-family: Arial;
      background-color: #C43741;
    }
  }

  ///////////////////////////////////
  //////////////Custom//////////////
  /////////////////////////////////

  &__custom-author-data{
      display: flex;
      width: 100%;
      float: left;
      padding-left: 7px;
      flex-direction: row;
      justify-content: space-between;
      &--customizable{
          width: calc(100% - 60px);
      }
  }
  &__pillow-container{
    position: relative;
    float: right;
  }
  &__unread-pilot{
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    top: 6px;
    right: 4px;
  }
  &__box-new{
    float: left;
    height: 10px;
    display: block;
    position: relative;
    bottom: 4px;
    &--custom{
      bottom: 2px;
      background-color: $red-advice-landing;
      height: auto;
      color: #C43741;
      bottom: 2px;
      height: 14px;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      padding: 0px 3px;
      margin-top: 2px;
    }
  }
  &__new-advise-dot{
    font-size: 5px;
  }
  &__new-advise-text{
    font-family: Arial, sans-serif;
    font-size: 10px;
    padding-left: 2px;
  }
  &__check-icon{
      font-size: 18px;
      margin-top: 6px;
      margin-right: 4px;
  }
  &__custom-author{
    padding-right: 5px;
    width: 100%;
    float: left;
    width: auto;
  }

  &__mark-custom-button{
    border: none;
    background: none;
    font-size: 18px;
    color: #AAAEB0;
    padding: 0;
  }
  &__space-between{
    margin-right: 3px;
  }

  &__new-mesaje-header-block{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__voice-recorder-button{
    border: none;
    background: none;
  }
  &__voice-recorder-icon{
    font-size: 20px;
    color: #001978;
    position: relative;
    top: 5px;
    &--landing{
      color: #000000;
      top: 0;
    }
  }
  &__text-link{
    color: #001978;
    font-family: Arial;
    font-size: 11px;
    font-weight: bold;
    border: none;
    background: none;
    text-decoration: underline;
    padding: 0 0px;
    position: relative;
    top: 5px;
    &--landing{
      color: #3B92D6;
      font-family: 'lato_regular';
      font-size: 13px;
      text-decoration: none;
      top: 0;
      position: initial;
    }
  }
  &__audio-block{
    float: left;
    background: none;
    border: none;
    padding: 0 4px 0 0;
    height: 12px;
  }
  &__custom-add-block{
    height: 38px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__custom-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__spinner-center{
    text-align: center;
  }
  &__spinner-center-text{
    height: 14px;
    color: #565656;
    font-family: MTTMilano;
    font-size: 14px;
    margin: 14px 0 0;
    &--landing{
      font-family: 'lato_regular';
      font-size: 13px;
      font-weight: bold;
    }
  }
  //pdf//
  &__container-list-pdf{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
    width: 100%;
  }
  &__element-list-pdf{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 4px 0;
    width: 100%;
    gap: 4px;
    font-family: MTTMilano, sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
  }
  &__element-header-pdf{
    display: flex;
    width: 100%;
    text-align: left;
    color: #717171;
    gap: 4px;
  }
  &__element-message-pdf{
    text-align: left;
    color: #262626;
  }
  //////
}

.compliance{
  &__dark-blue{
      .box-comunication-channel {
          &__block-text{
              margin: 10px 0 0 0;
              text-transform: uppercase;
              text-align: center;
              color: $primary-color;
              font-size: 11px;
              font-family: MTTMilano-Bold, sans-serif;
              position: absolute;
              width: 100%;
          }
          &__add-comment-title{
              background: $background-grey-other;
              color: $primary-color;
              padding: 4px 8px;
              width: auto;
              margin-left: 8px;
              display: inline-block;
          }
          &__block-header-comment{
              color: $primary-color;
          }
          &__box-icon-left{
              color: $snow-white;
              background-color: $delete-amount;
              &--manage{
                  background-color: $primary-color;
              }
          }
          &__list-item{
              @include box-comunication-channel-list-item($box-comment-border, $block-comment-hover);
              &--hovered{

                &:hover{
                  & .predefined-templates__template-menu{
                    display: block;
                  }
                  background-color: #E5E8F1;
                }
              }
          }
          &__header-icon{
              font-size: 20px;
              margin-top: 9px;
              color: $primary-color;
              position: relative;
              top: 9px;
          }
          &__template-header{
              position: absolute;
              width: 80px;
              height: 80px;
              border-radius: 50% 50% 0 0;
              background-color: $background-color-states;
              margin: 0 auto;
              top: -23px;
              left: calc(50% - 40px);
              text-align: center;
              &--concept-legal-documentation{
                  background-color: $result-search-quote-color;
              }
          }
          &__unread-pilot{
              background: $red;
          }
          &__box-new{
              color: $red;

          }
          &__info-icon{
             font-size: 21px;
             color: $primary-color;
          }
          &__read-by{
            color: $primary-color;
          }
      }
  }
  &__steel-blue,
  &__uk-steel-blue{
      .box-comunication-channel {
          &__block-text{
              @include box-text($primary-color-2);
              &--comment{
                  padding-top: 15px;
              }
          }

          &__add-comment-title{
              @include add-comment-title($background-grey-disabled-2, $primary-color-2)
          }
          &__block-header-comment{
              color: $primary-color-2;
          }
          &__box-icon-left{
              color: $snow-white;
              background-color: $border-bottom-2;
              &--manage{
                  background-color: $primary-color-2;
              }
          }
          &__list-item{
              @include box-comunication-channel-list-item($third-color-2, $block-hover-2);
              &--hovered{

                &:hover{
                  & .predefined-templates__template-menu{
                    display: block;
                  }
                  background-color: #D8F7E7;
                }
              }
          }
          &__header-icon{
              @include header-icon($primary-color-2)
          }
          &__template-header{
              display: block;
              float: left;
              padding-top: 9px;
              padding-left: 7px;
          }
           &__unread-pilot{
              background: #008187;
          }
           &__box-new{
              color: #008187;
          }
          &__info-icon{
             font-size: 21px;
             color: #008187;
          }
          &__read-by{
            color: #008187;
          }
      }
  }
  &__icam-red{
      .box-comunication-channel {
          &__block-text{
              @include box-text($custom-black-landing);
              &--comment{
                  padding-top: 15px;
              }
          }
          &__add-comment-title{
              @include add-comment-title($background-grey-disabled-icam, $custom-black-landing)
          }
          &__block-header-comment{
              background-color: $background-grey-disabled-icam;
              color: $custom-black-landing;
              &--manage{
                  background-color: $background-grey-disabled-icam;
              }
          }
          &__box-icon-left{
              color: $snow-white;
              background-color: $text-field-color-icam;
              &--manage{
                  background-color: $primary-color-icam;
              }
          }
          &__list-item{
              @include box-comunication-channel-list-item($third-color-icam, $hover-block-icam)
          }
          &__header-icon{
              @include header-icon($custom-black-landing)
          }
          &__template-header{
              display: block;
              float: left;
              padding-top: 9px;
              padding-left: 7px;
          }
          &__info-icon{
             font-size: 21px;
             color: $primary-color-icam;
          }
          &__read-by{
            color: $primary-color-icam;
          }
      }
  }
  &__gm-law{
      .box-comunication-channel {
          &__block-text{
              @include box-text($custom-black-landing);
              &--comment{
                  padding-top: 15px;
              }
          }
          &__add-comment-title{
              @include add-comment-title($background-grey-disabled-gm, $custom-black-landing)
          }
          &__block-header-comment{
              color: $custom-black-landing;
          }
          &__box-icon-left{
              color: $snow-white;
              background-color: $text-field-color-gm;
              &--manage{
                  background-color: $primary-color-gm;
              }
          }
          &__list-item{
              @include box-comunication-channel-list-item($third-color-gm, $hover-block-gm);
              &:hover{
                & .predefined-templates__template-menu{
                  display: block;
                }

              }
          }
          &__header-icon{
              @include header-icon($custom-black-landing)
          }
          &__template-header{
              display: block;
              float: left;
              padding-top: 9px;
              padding-left: 7px;
          }
          &__unread-pilot{
              background: $red;
          }
          &__box-new{
              color: $red;
          }
          &__info-icon{
             font-size: 21px;
             color: $primary-color-gm;
          }
          &__read-by{
            color: $primary-color-gm;
          }
      }
  }
  &__com-de{
    .box-comunication-channel {
        &__block-text{
            @include box-text($custom-black-landing);
            &--comment{
                padding-top: 15px;
            }
        }
        &__add-comment-title{
            @include add-comment-title($background-grey-disabled-com-de, $custom-black-landing)
        }
        &__block-header-comment{
            color: $custom-black-landing;
        }
        &__box-icon-left{
            color: $snow-white;
            background-color: $text-field-color-com-de;
            &--manage{
                background-color: $primary-color-com-de;
            }
        }
        &__list-item{
            @include box-comunication-channel-list-item($third-color-com-de, $hover-block-com-de);
            &:hover{
              & .predefined-templates__template-menu{
                display: block;
              }

            }
        }
        &__header-icon{
            @include header-icon($custom-black-landing)
        }
        &__template-header{
            display: block;
            float: left;
            padding-top: 9px;
            padding-left: 7px;
        }
        &__unread-pilot{
            background: $red;
        }
        &__box-new{
            color: $red;
        }
        &__info-icon{
           font-size: 21px;
           color: $primary-color-com-de;
        }
        &__read-by{
          color: $primary-color-com-de;
        }
    }
  }
  &__com-fr{
    .box-comunication-channel {
        &__block-text{
            @include box-text($custom-black-landing);
            &--comment{
                padding-top: 15px;
            }
        }
        &__add-comment-title{
            @include add-comment-title($background-grey-disabled-com-fr, $custom-black-landing)
        }
        &__block-header-comment{
            color: $custom-black-landing;
        }
        &__box-icon-left{
            color: $snow-white;
            background-color: $text-field-color-com-fr;
            &--manage{
                background-color: $primary-color-com-fr;
            }
        }
        &__list-item{
            @include box-comunication-channel-list-item($third-color-com-fr, $hover-block-com-fr);
            &:hover{
              & .predefined-templates__template-menu{
                display: block;
              }

            }
        }
        &__header-icon{
            @include header-icon($custom-black-landing)
        }
        &__template-header{
            display: block;
            float: left;
            padding-top: 9px;
            padding-left: 7px;
        }
        &__unread-pilot{
            background: $red;
        }
        &__box-new{
            color: $red;
        }
        &__info-icon{
          font-size: 21px;
          color: $primary-color-com-fr;
        }
        &__read-by{
          color: $primary-color-com-fr;
        }
    }
  }
  &__landing-custom{
    .box-comunication-channel {
      border-radius: 4px;
      background-color: $snow-white;
      box-shadow: 0 4px 20px 0 $attachment-custom-grey-opacity;
      min-height: 164px;
      width: 100%;
      display: block;
      padding: 9px 8px;
      margin-bottom: 21px;
      &__add-comment-title{
          color: $custom-black-landing;
          font-family: 'lato_regular', sans-serif;
          font-size: 11px;
          font-weight: bold;
          width: 100%;
          background: none;
          margin-left: 9px;
          padding: 11px 8px;
      }
      &__box-icon-left{
          color: $snow-white;
          font-size: 18px;
          width: 31px;
          height: 31px;
          border-radius: 16px;
          padding: 4px 0;
          left: -16px;
          top: -5px;
          border: 1px solid transparent;
      }
      &__comment-list{
          margin-top: 20px;
          margin-right: 0;
          height: auto;
      }
      &__list-item{
          padding-left: 12px;
          border-left-width: 2px;
          margin-left: 7px;
          width: calc(100% - 7px);
          padding-bottom: 2px;
          margin-bottom: 16px;
      }
      &__unread-pilot{
          background: $red;
          top: -5px;
          right: -5px;
      }
      &__box-new{
          color: $red;
          bottom: 2px;
      }
      &__info-icon{
        font-size: 19px;
        color: #000000;
      }
    }
  }
}

/////////////////////////////////
//inc icam - MADiaz
