.evaluation{
  height: 100%;
  width: 100%;
  display: block;
  // overflow: hidden;
  // overflow-y: auto;
  // position: relative;

  // &--float-menu{
  //     position: relative;
  // }

  &__evaluation-block{
      width: 100%;
      position: relative;
      display: table;
      // bottom: 1px;
      // margin-bottom: 1px;
      padding-bottom: 1px;
      min-height: calc(100% - 295px);
  }

  &__evaluation-menu{
      width: 408px;
      min-width: 408px;
      max-width: 408px;
      display: table-cell;
      padding-bottom: 20px;

      &--short-menu{
          width: 63px;
          min-width: 63px;
          z-index: 30;
      }
      &--expand-menu{
          width: 408px;
          min-width: 408px;
          box-shadow: 2px 5px 10px 0px $column-filter-shadow-45;
      }
  }

  &__info-block{
      max-width: 0;
      width:calc(100% - 408px);
      position: relative;
      display: table-cell;
      vertical-align: top;
      padding-bottom: 20px;
      &--large-block{
          width:calc(100% - 63px);
      }
      &--float-block{
          width:calc(100% - 63px);
          margin-left: 63px;
          float: none;
      }

  }
  &__window{
      position: relative;
      float: left;
      width:100%;
      padding: 0px 49px 0;

  }

  &__background-blocked{
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $column-filter-shadow-05;
      z-index: 9999;
  }
}

.compliance{
  &__dark-blue{
    .evaluation{
      &__evaluation-menu{
        background-color: $primary-color;
      }
    }
  }
  &__steel-blue{
    .evaluation{
      &__evaluation-block{
        min-height: calc(100% - 226px);
      }
      &__evaluation-menu{
        background-color: $primary-color-2;
      }
      &__window{
        padding: 0px 49px 0 59px;
      }
    }
  }
  &__uk-steel-blue{
    .evaluation{
      &__evaluation-block{
        min-height: calc(100% - 156px);
      }
      &__evaluation-menu{
        background-color: $primary-color-2;
      }
      &__window{
        padding: 0px 49px 0 59px;
      }
    }
  }
  &__icam-red{
    .evaluation{
      &__evaluation-menu{
        background-color: $primary-color-icam;
      }
      &__window{
        padding: 0px 49px 0 59px;
      }
    }
  }
  &__gm-law{
    .evaluation{
      &__evaluation-menu{
        background-color: $primary-color-gm;
      }
      &__window{
        padding: 0px 49px 0 59px;
      }
    }
  }
  &__com-de{
    .evaluation{
      &__evaluation-menu{
        background-color: $primary-color-com-de;
      }
      &__window{
        padding: 0px 49px 0 59px;
      }
    }
  }
  &__com-fr{
    .evaluation{
      &__evaluation-menu{
        background-color: $primary-color-com-fr;
      }
      &__window{
        padding: 0px 49px 0 59px;
      }
    }
  }
}

/* media querys */
@media (max-width: 1599px){
  .evaluation {
      &__evaluation-menu{
          width: 63px;
          min-width: 63px;
          max-width: 63px;
          &--expand-menu{
              width: 408px;
              min-width: 408px;
              max-width: 408px;
              min-height: 100%;
              z-index: 12;
              & .evaluation__evaluation-block{
                  height: 100%;
              }
          }
      }
  }
}

@media (max-width: 1366px){
  .evaluation{
      &__info-block{
          width: 100%;
          // margin-left: 0;
          // max-width: 1303px;
          &--large-block{
              padding-left: 63px;
          }
      }
      &__evaluation-menu{
          &--short-menu{
              width: 63px;
              min-width: 63px;
              max-width: 63px;
          }
          &--expand-menu{
              position: absolute;
          }
      }
      &__window{
          max-width: 1300px;
      }
  }
}
