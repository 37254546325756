.configure-image{
    &__window{
        & .modal-dialog{
            & .modal-content{
                height: 700px;
                width: 900px;
            }
        }
    }
    &__container{
        display: block;
        width: 100%;
        overflow: hidden;
        padding: 34px 67px 0;
    }
    &__title-block-modal{
        line-height: 24px;
    }
    &__block-items{
        display: block;
        width: 100%;
        margin: 20px 0 0;
        padding: 0;
        list-style: none;
        height: 445px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    &__image-item{
        width: 100%;
        background-color: $box-comment-border-03;
        padding: 7px 11px 21px;
        position: relative;
        margin-bottom: 12px;
    }
    &__image-text{
        color: $primary-color;
        font-family: MTTMilano-Bold, sans-serif;
        font-size: 15px;
        margin-bottom: 1px;
        &--image-editor{
            font-size: 13px;
        }
    }
    &__image-text-use{
        color: $primary-color;
        font-family: MTTMilano, sans-serif;
        font-size: 13px;
        margin-bottom: 16px;

    }
    &__image-edit-icon{
        position: absolute;
        color:  $primary-color;
        top: 10px;
        font-size: 20px;
        right: 12px;
        &:hover{
            cursor: pointer;
        }
    }
    &__inner-canvas{
        &--50x50{
            & canvas{
                border-radius: 50%;
            }
        }
        & canvas{
            padding: 0;
            margin: auto;
            display: block;
        }
    }
    &__thumbnail-container{
      text-align: center;
    }
}

.compliance{
  &__icam-red{
    .configure-image{
      &__image-item{
          background-color: $background-grey-disabled-icam;
      }
      &__image-text,
      &__image-text-use{
          color: $custom-black-landing;
      }
      &__image-edit-icon{
          color: $primary-color-icam;
      }
    }
  }
  &__gm-law{
    .configure-image{
      &__image-item{
          background-color: $background-grey-disabled-gm;
      }
      &__image-text,
      &__image-text-use{
          color: $custom-black-landing;
      }
      &__image-edit-icon{
          color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    .configure-image{
      &__image-item{
          background-color: $background-grey-disabled-com-de;
      }
      &__image-text,
      &__image-text-use{
          color: $custom-black-landing;
      }
      &__image-edit-icon{
          color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .configure-image{
      &__image-item{
          background-color: $background-grey-disabled-com-fr;
      }
      &__image-text,
      &__image-text-use{
          color: $custom-black-landing;
      }
      &__image-edit-icon{
          color: $primary-color-com-fr;
      }
    }
  }
}
