.upload-report {
    &__window {
        & .modal-dialog {
            & .modal-content {
                height: 400px;
                border-radius: 0px;
            }
        }
    }
    &__block-body {
        position: relative;
        float: left;
        width: 100%;
    }

    &__description {
        opacity: 0.5;
        color: $primary-color;
        font-family: MTTMilano, sans-serif;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        padding-bottom: 25px;
    }

    &__error-panel {
        color: $red;
        font-family: Arial, sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 0;
        padding-top: 5px;
    }

    &__form {
        padding: 40px 74px;
        position: relative;
        float: left;
        width: 100%;
        height: 313px;
    }
    &__name-text {
        width: calc(100% - 205px);
        display: block;
        float: left;
    }

    &__suffix-text {
        width: 200px;
        display: block;
        float: left;
        margin-left: 5px;
    }

    &__error-block {
        display: block;
        width: 100%;
        overflow: hidden;
    }

    &__error-name {
        color: $red;
        position: absolute;
        font-size: 12px;

        &--relative {
            position: relative;
        }
    }

    &__text-header {
        color: $snow-white;
        font-family: MTTMilano, sans-serif;
        font-size: 22px;
        margin: 0;
        padding-left: 16px;
        float: left;
    }

    &__buttons-block {
        position: absolute;
        right: 74px;
        bottom: 23px;
        margin-top: 0;
        margin-bottom: 0;
    }
    //////////////////////////////////
    //////////////Custom/////////////
    ////////////////////////////////
    &__custom-window {
        & .modal-dialog {
            & .modal-content {
                height: 340px;
                // width: 940px;
                border-radius: 4px;
                background-color: $snow-white;
                box-shadow: 0 10px 20px 0 $attachment-custom-grey-opacity;
                // padding: 6px 8px;
            }
        }
    }

    &__custom-block-body {
        position: relative;
        float: left;
        width: 100%;
        display: block;
        padding-bottom: 55px;
    }

    &__custom-form {
        padding: 48px 80px 0 80px;
        position: relative;
        float: left;
        width: 100%;
        height: 236px;
        display: block;

        &--with-info {
            padding-top: 15px;
        }
    }

    &__custom-text-info {
        color: $custom-black-landing;
        font-family: 'lato_regular', sans-serif;
        font-size: 14px;
        margin-bottom: 20px;
    }
}

.compliance{
    &__steel-blue{
        .upload-report {
            &__form {
                height: 308px;
            }
            &__error-name {
                color: $alert-color-2;
            }
        }
    }
}
