.users-admin-new-wizard {
  margin: 20px 30px 26px;
  position: relative;

  &__group-icon{
      font-size: 25px !important;
  }

  &__subtitle {
      clear: both;
      margin-top: 2px;
      font-size: 12px;
      text-transform: lowercase;

  }

  &__block-steps{
      position: relative;
      bottom: 14px;
  }

  &__content{
      margin: 28px auto 70px;
  }

  &__groups-row {
      margin-top: 20px;
  }

  &__buttons {
      text-align: right;
      & button {
          margin-left: 5px;
      }
  }

  &__title {
      float: left;
      color: $subcontainer-ground;
      margin: 0 0 0 10px;
      font-size: 12px;
      text-transform: uppercase;
      font-family: MTTMilano-Bold, sans-serif;
      padding-left: 25px;
      position: relative;
  }

  &__icon-title{
      font-size: 20px;
      position: absolute;
      left: 0;
      top: -6px;
  }

  &__close-link {
    color: $primary-color;
    float: right;
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
}

@media (max-width: 1260px) {
  .users-admin-new-wizard {
      margin: 20px 5px 26px;
  }
}

.compliance{
  &__steel-blue,
  &__uk-steel-blue{
    .users-admin-new-wizard {
      &__close-link {
        color: $border-hover-2;
      }
      &__title {
        color: $grey-icons-2;
      }
    }
  }
  &__icam-red{
    .users-admin-new-wizard {
      &__close-link {
        color: $primary-color-icam;
      }
      &__title {
        color: $secondary-color-icam;
        font-size: 12px;
      }
    }
  }
  &__gm-law{
    .users-admin-new-wizard {
      &__close-link {
        color: $primary-color-gm;
      }
      &__title {
        color: $secondary-color-gm;
        font-size: 12px;
      }
    }
  }
  &__com-de{
    .users-admin-new-wizard {
      &__close-link {
        color: $primary-color-com-de;
      }
      &__title {
        color: $secondary-color-com-de;
        font-size: 12px;
      }
    }
  }
  &__com-fr{
    .users-admin-new-wizard {
      &__close-link {
        color: $primary-color-com-fr;
      }
      &__title {
        color: $secondary-color-com-fr;
        font-size: 12px;
      }
    }
  }
}
