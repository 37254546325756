.evaluation-share {
  & > .modal-dialog > .modal-content {
      height: 659px;
  }

  &__wizard-steps {
      overflow: hidden;
      margin-top: 8px;
  }

  &__content {
      display: block;
      margin: 20px 72px 0px 72px;
      height: 469px;
      position: relative;
  }

  &__content-documents {
      display: block;
      padding: 0 24px 0 20px;
      height: 441px;
      position: relative;
  }

  &__comments {
      height: 180px;
  }

  &__switch-documents {
      margin-top: 10px;
  }
}
