@import '../../../../assets/scss/colors';

.new-evaluation {
  background-color: $snow-white;
  width: 100%;
  display: block;
  position: relative;
  min-height: 590px;
  &__modal-window{
    & .modal-dialog{
      max-width: 900px;
      height: 600px;
      & .modal-content{
        height: 600px;
      }
    }
  }
  &__modal-window-legal-form{
    & .modal-dialog{
        & .modal-content{
            min-height: 603px;
        }
    }
  }
  &__close-modal {
      font-size: 13px;
      color: $snow-white;
      float: right;
      border: none;
      background: none;
      padding-top: 3px;
      outline: 0;
      &--draft {
          position: absolute;
          top: 19px;
          right: 23px;
      }
  }

  &__evaluation-form {
      width: 54%;
      display: block;
      margin: 60px auto 0px;
      &--product-state {
          padding-top: 87px;
      }

      &--form-state {
          width: 86%;
      }

      &--roles{
          margin: 60px auto 0px;
          width: calc(100% - 420px);
          padding-bottom: 70px;
      }
      &--properties{
        margin: 30px auto 0px;
      }
  }

  &__block-clients{
    padding-bottom: 65px;
  }

  &__scroll-form {
      padding: 0px 19%;
      height: 250px;
      overflow-y: auto;
      overflow-x: hidden;
  }

  &__block-properties {
      margin: 5px 0;
  }

  &__buttons-block {
      display: block;
      width: 100%;
      text-align: right;
      position: absolute;
      bottom: 23px;
      padding-right: 72px;
  }
}

.compliance{
  &__dark-blue{
    .new-evaluation{
      &__evaluation-form{
        &--second-state {
          width: 90%;
          margin: 0 auto;
        }
      }
    }
  }

  &__steel-blue{
    .new-evaluation{
      &__evaluation-form{
        &--second-state {
          width: 90%;
          margin: 0 auto;
        }
      }
    }
  }

  &__gm-law{
    .new-evaluation{
      &__evaluation-form{
        &--second-state {
          width: 84%;
          margin: 0 auto;
        }
      }
    }
  }
  &__com-de{
    .new-evaluation{
      &__evaluation-form{
        &--second-state {
          width: 84%;
          margin: 0 auto;
        }
      }
    }
  }
  &__com-fr{
    .new-evaluation{
      &__evaluation-form{
        &--second-state {
          width: 84%;
          margin: 0 auto;
        }
      }
    }
  }
}
