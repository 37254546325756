.organization-chart{
    position: relative;
    &__block{
        display: block;
        overflow: auto;
        width: 878px;
        height: 459px;
        border: 1px solid $subtitle-color;
    }
    &__node-link{
        fill: none;
        stroke-width: 2;
        stroke-dasharray: 1.5;
        stroke: $primary-color;
    }
    &__node-block{
        width: 185px;
        height: 200px;
    }
    &__node-item{
        box-sizing: border-box;
        height: 59px;
        width: 185px;
        border: 2px solid ;

        border-radius: 7px;
        position: relative;
        &--parent{
            border-top-width: 5px;
        }

    }
    &__node-collapse-icon{
        position: absolute;
        top: 2px;
        left: 2px;

    }
    &__node-inner-text{
        text-align: center;
        margin-top: 19px;
        font-family: Arial, sans-serif;
        font-size: 11px;
        font-weight: bold;
        max-width: 185px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 0;

    }
    &__node-inner-percentage{
        font-family: Arial, sans-serif;
        font-size: 10px;
        text-align: center;
        margin-top: 1px;

        &--none{
            display: none;
        }
    }
    &__buttons-block{
        height: 42px;
        width: 202px;
        border: 1px solid;
        margin-left: 60px;
        margin-top: 10px;
        border-radius: 5px;
        float: left;
    }
    &__go-back-button{
        float: right;
        margin-right: 60px;
        margin-top: 10px;
    }
    &__box-buttons{
        float: left;
        height: 38px;
        border-right: 1px solid $snow-white;
        width: 66px;
        padding: 10px 8px;
        &--expand{
            width: 34px;
        }
        &--print{
            width: 34px;
            border: none;
        }


    }
    &__button{
        border: none;
        background: none;
        outline: none;
        padding: 0;
        font-size: 18px;
        color: $snow-white;
        &--increment{
            margin-right: 9px;
        }
        &--center-graph{
            margin-right: 9px;
        }
        &--expand{
            transform: rotate(-90deg);
            position: relative;
            bottom: 3px;
            left: 3px;
        }
        &--print{
            bottom: 0px;
            left: 0;
        }
    }
    &__tooltip-box{
        background-color: $snow-white;
        border: 1px solid $subcontainer-border;
        position: absolute;
        z-index: 3000;
        box-shadow: 0px 0px 10px 2px $bar-horizontal-grey-opacity;
        opacity: 0;
    }

    &__tooltip-block{
        margin: 0 15px 0 15px;
        display: block;
        position: relative;
    }
    &__tooltip-triangle{
        position: relative;
        border-top: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $snow-white;
        border-left: 10px solid transparent;
        height: 0;
        width: 0;
        z-index: 12;
        left: 113px;
        top: -20px;
    }



}
.compliance{
    &__dark-blue{
        .organization-chart{
            &__node-item{
                border-color: $primary-color;
                background-color: $background-grey;
                &--active{
                    border-color: $green;
                    background-color: $background-grey;
                }
            }
            &__node-collapse-icon{
                color: $primary-color;
            }
            &__node-inner-text{
                color: $primary-color;
            }
            &__node-inner-percentage{
                color: $primary-color;
            }
            &__buttons-block{
                border-color: $primary-color;
                background-color: $primary-color;
            }
        }
    }
    &__steel-blue{
        .organization-chart{
            &__node-item{
                border-color: $primary-color-2;
                background-color: $background-grey-disabled-2;
                &--active{
                    border-color: $third-color-2;
                    background: $block-hover-2;
                }
            }
            &__node-collapse-icon{
                color: $border-hover-2;
            }
            &__node-inner-text{
                color: $primary-color-2;
            }
            &__node-inner-percentage{
                color: $primary-color-2;
            }
            &__buttons-block{
                border-color: $border-hover-2;
                background-color: $border-hover-2;
            }
        }
    }
    &__gm-law{
        .organization-chart{
            &__node-item{
                border-color: $custom-black-landing;
                background-color: $background-grey-disabled-gm;
                color: $custom-black-landing;
                &--active{
                    border-color:$primary-color-gm;
                    background: $hover-block-gm;
                }
            }
            &__node-collapse-icon{
                color: $primary-color-gm;
            }
            &__node-inner-text{
                color: $primary-color-gm;
            }
            &__node-inner-percentage{
                color: $primary-color-gm;
            }
            &__buttons-block{
                border-color: $primary-color-gm;
                background-color: $primary-color-gm;
            }
            &__graph-container{
                path{
                    stroke: $custom-black-landing;
                }
            }
        }
    }
    &__com-de{
      .organization-chart{
          &__node-item{
              border-color: $custom-black-landing;
              background-color: $background-grey-disabled-com-de;
              color: $custom-black-landing;
              &--active{
                  border-color:$primary-color-com-de;
                  background: $hover-block-com-de;
              }
          }
          &__node-collapse-icon{
              color: $primary-color-com-de;
          }
          &__node-inner-text{
              color: $primary-color-com-de;
          }
          &__node-inner-percentage{
              color: $primary-color-com-de;
          }
          &__buttons-block{
              border-color: $primary-color-com-de;
              background-color: $primary-color-com-de;
          }
          &__graph-container{
              path{
                  stroke: $custom-black-landing;
              }
          }
      }
  }
  &__com-fr{
    .organization-chart{
        &__node-item{
            border-color: $custom-black-landing;
            background-color: $background-grey-disabled-com-fr;
            color: $custom-black-landing;
            &--active{
                border-color:$primary-color-com-fr;
                background: $hover-block-com-fr;
            }
        }
        &__node-collapse-icon{
            color: $primary-color-com-fr;
        }
        &__node-inner-text{
            color: $primary-color-com-fr;
        }
        &__node-inner-percentage{
            color: $primary-color-com-fr;
        }
        &__buttons-block{
            border-color: $primary-color-com-fr;
            background-color: $primary-color-com-fr;
        }
        &__graph-container{
            path{
                stroke: $custom-black-landing;
            }
        }
    }
}
}
