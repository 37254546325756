@mixin field-type-rich-editable-block($borderColor, $backgroundColor){
  &:hover:not([disabled]) {
  // &:hover{
      background-color: $backgroundColor;
      border-color: $borderColor;
      & .tox .tox-edit-area__iframe{
          background-color: $backgroundColor;
      }
      // .rich-editor__body-tinymce{
      //     background-color: $backgroundColor;
      // }
  }
}

@mixin field-type-rich-editable-block-required($firstColor, $secondColor, $thirdColor){
  & .tox .tox-edit-area__iframe{
    background-color: $firstColor;
  }
  & .tox-toolbar__primary{
    border-color: $secondColor;
  }
}

@mixin field-type-rich-text-inner($color, $textColor, $primaryColor){
  color: $color;

  & h1, h2, b, strong {
      color: $color;
  }

  & h3, h4, h5, h6 {
      color: $textColor;
  }

  & p {
      & a {
          color: $primaryColor;
      }
  }

  & ul li {
      color: $color;
  }

  & ol li {
      color: $color;

      & span {
          color: $color;
      }
  }

  & ul {
      list-style-image: url('../../../../../assets/img/bullet-1-steel-blue.png');

      & ul {
          list-style-image: url('../../../../../assets/img/bullet-2-steel-blue.png');

          & ul {
              list-style-image: url('../../../../../assets/img/bullet-3-steel-blue.png');
          }
      }
  }
}

.field-type-rich-text{
  &__container {
      width: 100%;
      margin: 0 auto;
      display: inline-block;
      overflow: hidden;
      position: relative;
  }

  &__menu-share{
      display: block;
      float: right;
      right: 10px;
      top: 10px;
      height: 44px;
      margin-bottom: 24px;
      margin-top: 0;
      margin-right: -2px;
      z-index: 10;
      width: 10%;
      position: absolute;
      &:hover {
          & .field-type-rich-text__menu-share-list {
              display: block;
          }
      }
  }

  &__menu-share-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      color: $primary-color;
      background-color: $background-grey-other;
      border: 0.5px solid $menu-icons-border;
      height: 44px;
      width: 56px;
      border-radius: 67px 0 0 67px;
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0px;
      display: none;
  }

  &__menu-options{
      font-size: 22px;
      margin: 8px 0 0 20px;
      &:hover{
        cursor: pointer;
      }
  }

  &__menu-share-icon {
      position: relative;
      right: -3px;
      top: 0px;
      &:hover {
          cursor: pointer;
      }
  }

  &__menu-kebab-icon {
      top: 8px;
      right: 5px;
      position: relative;
      &:hover {
          cursor: pointer;
      }
  }

  &__rich-block {
      color: $secondary-color;
      padding-top: 20px;
      padding-bottom: 20px;
      background-color: $background-color-states;
      min-height: 100px;
      height: 100%;
      width: 100%;
      border: none;
      margin-top: 0;
      margin-bottom: 0;

      &:hover {
          & .common__tooltip-law, .common__info-icon-block, .common__tooltip-law-block {
              visibility: visible;
          }
      }

      &--sequential {
          max-height: 330px;
          overflow-y: auto;
      }

      &--no-margin {
          padding-top: 15px;
          padding-bottom: 15px;
          min-height: 95px;
      }

      &--no-margin-height-135 {
          @extend .field-type-rich-text__rich-block--no-margin;
          min-height: 135px;
      }
  }

  &__rich-text {
      font-size: 12px;
      // background-color: #F2F3F5;

      & b {
          color: $primary-color;
          font-family: MTTMilano-Bold, sans-serif;
          font-weight: normal;
          font-size: 13px;
      }

      & h1 {
          font-size: 12px;
          text-transform: uppercase;
      }

      & h1, h2 {
          line-height: 50px;
          margin: 0;
          color: $primary-color;
      }

      & h3, h4, h5, h6 {
          line-height: 50px;
          margin: 0;
          color: $rich-text-color;
      }

      & p {
          & a {
              text-decoration: underline;
              color: $primary-color;
          }
      }

      & ul, ol {
          padding-left: 15px;
      }

      & ul {
          list-style-image: url('../../../../../assets/img/squad1.jpg');

          & ul {
              list-style-image: url('../../../../../assets/img/squad2.jpg');

              & ul {
                  list-style-image: url('../../../../../assets/img/squad3.jpg');
              }
          }
      }

      & ul li {
          margin-top: 14px;
      }

      & ol li {
          font-weight: bold;
          margin-top: 14px;

          & span {
              font-weight: normal;
          }
      }

      &--indent-rich {
          font-family: Arial, sans-serif;
          font-size: 11px;
          padding-left: 15px;
          line-height: 20px;
      }

      &--circle {
          list-style-type: circle;
          list-style-image: none !important;
      }

      &--disc {
          list-style-type: disc;
          list-style-image: none !important;
      }

      &--square {
          list-style-type: square;
          list-style-image: none !important;
      }

      &--nohighlight {
          font-family: Arial, sans-serif;
          color: $secondary-color;
          line-height: 20px;
          padding: 8px 20px 0 10px;
          display: inline-block;
          & h2, h3 {
              font-size: 14px;
          }

          & h4 {
              font-size: 13px;
          }

          & h5 {
              font-size: 12px;
          }

          & h6 {
              font-size: 11px;
              font-weight: bold;
          }

          & em, a {
              line-height: 12.5px;
          }

          & span {
              & span {
                  font-family: Arial !important;
                  font-size: inherit !important;
                  line-height: inherit !important;
              }
          }

          & strong {
              font-weight: bold;
              font-family: Arial, sans-serif;
              color: $legal-document-form;
          }
      }

      &--ishighlight {
          font-family: MTTMilano, sans-serif;
          font-size: 16px;
          color: $primary-color;
          line-height: 22px;
          padding: 8px 60px 0 60px;
          display: inline-block;
          & h1 {
              font-size: 14px;
          }

          & h2 {
              font-size: 17px;
          }

          & h3 {
              font-size: 16px;
          }

          & h4 {
              font-size: 15px;
          }

          & h5 {
              font-size: 14px;
          }

          & h6 {
              font-size: 13px;
              font-weight: bold;
          }

          & span {
              & span {
                  font-family: MTTMilano !important;
                  font-size: initial !important;
              }
          }

          & strong {
              color: $primary-color;
              font-family: MTTMilano-Bold, sans-serif;
              font-weight: normal;
          }

          .field-item__rich-text--indent-rich {
              font-family: MTTMilano, sans-serif;
              font-size: 14px;
          }
      }
  }

  &__rich-text-icon {
      float: left;
      position: absolute;
      left: 30px;
      font-size: 25px;
      color: $primary-color;
      top: calc(100% + 15px);
  }

  &__rich-text-custom {
      font-family: 'lato_regular', sans-serif;

      & h1 {
          font-size: 16px;
          font-family: MTTMilano-Bold, sans-serif;
          text-transform: uppercase;
      }

      & h2 {
          font-size: 13px;
          font-family: MTTMilano-Bold, sans-serif;
          text-transform: uppercase;
      }

      & h3 {
          font-size: 13px;
          font-family: MTTMilano, sans-serif;
          text-transform: uppercase;
      }

      & h4 {
          font-size: 12px;
          font-family: MTTMilano-Bold, sans-serif;
          text-transform: uppercase;
      }

      & h5 {
          font-size: 13px;
          font-family: 'lato_regular', sans-serif;
      }

      & h6 {
          font-size: 11px;
          font-family: 'lato_regular', sans-serif;
      }

      & h1, h2, h3, h4, h5, h6 {
          line-height: 50px;
          margin: 0;
          color: $custom-black-landing;
      }

      & p {
          color: $custom-secondary-color;
          font-family: 'lato_regular', sans-serif;
          font-size: 14px;
          & a {
              text-decoration: underline;
              color: $custom-color-3B92D6;
          }
      }

      & b {
          color: $custom-secondary-color;
          font-family: 'lato_regular', sans-serif;
          font-size: 14px;
          font-weight: bold;
      }

      & strong {
          color: $custom-secondary-color;
          font-family: 'lato_regular', sans-serif;
          font-size: 14px;
          font-weight: bold;
      }

      & ul, ol {
          padding-left: 15px;
      }
      & ul li {
          color: $custom-secondary-color;
          font-family: 'lato_regular', sans-serif;
          font-size: 14px;
          margin-top: 14px;
      }

      & ol li {
          color: $custom-secondary-color;
          font-family: 'lato_regular', sans-serif;
          font-size: 14px;
      }

      &--indent-rich {
          font-family: Arial, sans-serif;
          font-size: 11px;
          padding-left: 15px;
          line-height: 20px;
      }

      &--circle {
          list-style-type: circle;
          list-style-image: none !important;
      }

      &--disc {
          list-style-type: disc;
          list-style-image: none !important;
      }

      &--square {
          list-style-type: square;
          list-style-image: none !important;
      }

      &--nohighlight {
          font-family: Arial, sans-serif;
          line-height: 20px;
          display: inline-block;
          padding: 6px 20px 0 10px;
          & em, a {
              line-height: 12.5px;
          }

          & span {
              & span {
                  font-family: Arial !important;
                  font-size: inherit !important;
                  line-height: inherit !important;
              }
          }
      }

      &--ishighlight {
          line-height: 22px;
          display: inline-block;
          padding: 6px 60px 0 32px;

          & h5, h6 {
              font-weight: bold;
          }

          & span {
              & span {
                  font-family: Arial !important;
                  font-size: initial !important;
              }
          }

          .field-item__rich-text-custom--indent-rich {
              font-family: 'lato_regular', sans-serif;
              font-size: 14px;
          }
      }
  }

  &__rich-text-custom-icon {
      position: absolute;
      left: 8px;
      top: calc(100% + 7px);
      font-size: 15px;
      display: inline-block;
      border-radius: 15px;
      padding: 3px 0 0 0;
      text-align: center;
      height: 21px;
      width: 21px;
      background-color: $custom-black-landing;
      color: $snow-white;
  }

  &__rich-editable-block {
      display: flex;
      flex-direction: column;
  }

  &__rich-editable-block-required {
    @include field-type-rich-editable-block-required($background-error, $red, $background-color-states);
  }

  // &__rich-editable-editor {
  //     & div.mce-tinymce {
  //         border: none;
  //     }
  // }

  &__rich-editable-icon {
      top: 6px;
      right: 6px;
  }
}

.compliance{
  &__dark-blue{
    .field-type-rich-text{
      &__rich-editable-block {
        @include field-type-rich-editable-block($primary-color, $background-color-states);
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue{
    .field-type-rich-text{
      &__menu-share-list {
          color: $primary-color-2;
      }
      &__rich-block {
        background-color: $background-grey-disabled-2;
        color: $primary-color-2;
        &--ishighlight {
            background-color: $background-highlight-text-2;
            // color: $primary-color-2;
        }
      }
      &__rich-text {
        @include field-type-rich-text-inner($primary-color-2, $border-hover-2, $border-hover-2);
      }
      &__rich-text-icon{
        color: $primary-color-2;
      }
      &__rich-editable-block {
        @include field-type-rich-editable-block($border-hover-2, $third-color-2);
      }
      &__rich-editable-block-required {
        @include field-type-rich-editable-block-required($background-other-error-2, $alert-color-2, $third-color-2);
      }
    }
  }
  &__icam-red{
    .field-type-rich-text{
      &__menu-share-list {
          color: $custom-black-landing;
      }
      &__rich-block {
        background-color: $background-grey-disabled-icam;
        color: $custom-black-landing;
        &--ishighlight{
            background-color: $third-color-icam;
            // color: $custom-black-landing;
        }
      }
      &__rich-text {
        @include field-type-rich-text-inner($custom-black-landing, $text-field-color-icam, $primary-color-icam);
      }
      &__rich-text-icon{
        color: $custom-black-landing;
      }
      &__rich-editable-block {
        @include field-type-rich-editable-block($primary-color-icam, $hover-block-icam);
      }
      &__rich-editable-block-required {
        @include field-type-rich-editable-block-required($red-task-20-icam, $alert-color-icam, $hover-block-icam);
      }
    }
  }
  &__gm-law{
    .field-type-rich-text{
      &__menu-share-list {
          color: $custom-black-landing;
      }
      &__rich-block {
        background-color: $background-grey-disabled-gm;
        color: $custom-black-landing;
        &--ishighlight{
              background-color: $third-color-gm;
              // color: $custom-black-landing;
          }
      }
      &__rich-text {
        @include field-type-rich-text-inner($custom-black-landing, $text-field-color-icam, $primary-color-icam);
      }
      &__rich-text-icon{
        color: $custom-black-landing;
      }
      &__rich-editable-block {
        @include field-type-rich-editable-block($primary-color-gm, $hover-block-gm);
      }
      &__rich-editable-block-required {
        @include field-type-rich-editable-block-required($red-task-20-gm, $alert-color-gm, $hover-block-gm);
      }
    }
  }
  &__com-de{
    .field-type-rich-text{
      &__menu-share-list {
          color: $custom-black-landing;
      }
      &__rich-block {
        background-color: $background-grey-disabled-com-de;
        color: $custom-black-landing;
        &--ishighlight{
              background-color: $third-color-com-de;
              // color: $custom-black-landing;
          }
      }
      &__rich-text {
        @include field-type-rich-text-inner($custom-black-landing, $text-field-color-icam, $primary-color-icam);
      }
      &__rich-text-icon{
        color: $custom-black-landing;
      }
      &__rich-editable-block {
        @include field-type-rich-editable-block($primary-color-com-de, $hover-block-com-de);
      }
      &__rich-editable-block-required {
        @include field-type-rich-editable-block-required($red-task-20-com-de, $alert-color-com-de, $hover-block-com-de);
      }
    }
  }
  &__com-fr{
    .field-type-rich-text{
      &__menu-share-list {
          color: $custom-black-landing;
      }
      &__rich-block {
        background-color: $background-grey-disabled-com-fr;
        color: $custom-black-landing;
        &--ishighlight{
              background-color: $third-color-com-fr;
              // color: $custom-black-landing;
          }
      }
      &__rich-text {
        @include field-type-rich-text-inner($custom-black-landing, $text-field-color-icam, $primary-color-icam);
      }
      &__rich-text-icon{
        color: $custom-black-landing;
      }
      &__rich-editable-block {
        @include field-type-rich-editable-block($primary-color-com-fr, $hover-block-com-fr);
      }
      &__rich-editable-block-required {
        @include field-type-rich-editable-block-required($red-task-20-com-fr, $alert-color-com-fr, $hover-block-com-fr);
      }
    }
  }
  &__landing-custom{
    .field-type-rich-text{
      &__rich-block{
          background-color: $attachment-custom-grey-other-2;
          border: none;
          margin-top: 0;
          margin-bottom: 0;
          min-height: 100px;
          height: 100%;
          padding-top: 15px;
          padding-bottom: 10px;
          color: $custom-black-landing;
          width: 100%;
      }
      &__container {
          margin-bottom: 10px;
          margin-top: 12px;
          box-shadow: 0 1px 4px 0 $modal-help-box;
          border: 1px solid $custom-border-grey;
      }
      &__rich-editable-block {
          margin-bottom: 0;
          &:hover:not([disabled]) {
              & .common__tooltip-law, .common__info-icon-block, .common__tooltip-law-block {
                  visibility: visible;
              }
          }
      }
      &__rich-editable-block-required {
          margin-bottom: 0;
          // & div.mce-stack-layout-item {
          //     background-color: $background-error !important;
          // }

          // &:hover:not([disabled]) {
          //     & div.mce-stack-layout-item {
          //         background-color: $background-grey !important;
          //     }
          // }
      }
      &__has-audio-landing{
        .rich-editor{
          border: none;
        }
      }
    }
  }
}

@media (max-width: $max-width-smartphone) { //Smartphone
  .field-type-rich-text {
      &__rich-text-custom {
          &--ishighlight {
              padding-right: 32px;
          }
      }
  }
}
