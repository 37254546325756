.historic-concept{
    min-height: 600px;
    &__historic-header {
        display: block;
        width: 100%;
        height: 50px;
        text-align: left;
        position: relative;
        padding: 5px 10px 0 10px;
        &--access-historic{
            padding-top: 0;
        }
    }

    &__table{
        display: block;
        visibility: hidden;
        &--show-title {
          visibility: visible;
        }
    }

    &__table-body{
        width: 100%;
        display: block;
        // height: 537px;
        height: 495px;
        // height: 900px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__table-col{
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        max-width: 0;
        padding-right: 7px;
        &--top-1{
            position: relative;
            top: 1px;
        }
        &--element{
            width: 23%;
        }
        &--type-operation{
            width: 16%;
        }
        &--doc{
            width: 16%;
        }
        &--header-date-operation{
            width: 16%;
            padding-top: 2px;
        }
        &--date-operation{
            width: 16%;
        }
        &--user{
            width: 20%;
        }
        &--menu{
            width: 10%;
            text-align: right;
            position: relative;
        }
        &--type{
            width: 90px;
            min-width: 90px;
            padding-left: 10px;
        }
        &--header{
            &:hover{
                cursor: pointer;
            }
        }
        &--name-access{
            width: 30%;
        }
        &--type-access{
            width: 25%;
        }
        &--header-date-access{
            width: calc(45% - 90px);
            padding-top: 2px;
        }
        &--date-access{
            width: calc(45% - 90px);
        }
    }

    &__select-list {
        width: 100%;
        border-radius: 0px;
        border: 1px solid $primary-color;
        padding: 0;
        max-height: 129px;
        overflow-y: auto;
        overflow-x: hidden;

        &--filter {
            padding: 18px 0 20px 4px;
            list-style: none;
            margin: 0;
        }

        &--clickable {
            height: 120px;
            overflow: hidden;
        }
    }


    &__icon-find{
        font-size: 26px;
        color: $primary-color;
        &:hover{
            cursor:pointer;
        }
    }

    &__list-operation{
        max-height: 125px;
        margin-top: 8px;
        display: block;
        overflow: hidden;
        width: 100%;
    }

    &__list-user{
        margin: 8px 12px 0px 14px;
        display: block;
        overflow: hidden;
        color: $secondary-color;
        font-family: Arial, sans-serif;
        font-size: 12px;
        width: 220px;
        font-weight: 400;
        padding: 0;
        &:hover{
            cursor:pointer;
        }
        &--tooltip-info{
            color: inherit;
            margin: 0;
            width: 100%;
        }
        &--report-table{
            margin: 0;
            width: 246px;
        }
    }

    &__menu-icon{
        color: $subcontainer-ground;
        margin-right: 3px;
        float: right;
        &:hover{
            cursor: pointer;
        }
    }
    &__menu-block{
        border: 1px solid $menu-icons-border;
        background-color: $background-grey-other;
        border-radius: 20px 0 0 20px;
        height: 42px;
    }

    &__button-eye{
        border: none;
        background: none;
        font-size: 18px;
        padding: 9px 4px 9px 14px;
        outline: 0;
        &--custom{
            color: $custom-black-landing;
        }
    }

    &__select-concept{
        font-family: Arial, sans-serif;
        font-size: 10px;
        color: $primary-color;
        margin-left: 12px;
        margin-bottom: 5px;
    }

    &__list-block{
        width: 220px
    }

    &__select-list-text{
        display: block;
        color: $primary-color;
        font-family: Arial, sans-serif;
        font-size: 12px;
        margin-left: 14px;
        span{
            top: -3px;
            position: relative;
        }
    }

    &__select-list-action{
        padding-left: 10px;
        span{
            top: -3px;
            position: relative;
        }
    }

    &__access-head-block{
        height: 50px;
        position: relative;
        width: 100%;
    }

    &__back-button{
        padding: 0;
        border: none;
        outline: none;
        left: 14px;
        background: none;
        margin-top: 10px;
        position: relative;
    }
    &__access-count{
        border: 1px solid ;
        font-family: 'MTTMilano-Bold', sans-serif;
        font-size: 12px;
        padding: 2px 6px;
        position: relative;
        right: 20px;
        float: right;
    }

    &__cell-block{
        height: 16px;
    }

    &__table-icon {
        font-size: 20px;
        position: relative;
        left: 15px;
    }

    &__button-border-none{
        bottom: 3px;
    }

    &__cell-block-icon{
        max-width: calc(100% - 25px);
        float: left;
    }

    &__block-icon-eye{
        float: left;
    }

    &__box-comparator-participants{
        border-radius: 0px;
        padding: 0;
        left: -185px;
        overflow: hidden;
        position: absolute;
        margin: 0;
        top: 20px;
        border: none;
        width: 400px;
        height: 211px;
    }
  }

.compliance{
    &__dark-blue{
        .historic-concept{
            &__historic-header {
                &--access-historic {
                    padding: 0;
                }
            }
            &__table{
                padding: 10px 10px 10px 10px;
                width: 100%;
            }
            &__table-body{
                &--more-11-elements{
                    width: calc(100% + 10px);
                }
            }
            &__access-head-block{
                background-color: $subcontainer-border;
            }
            &__access-count{
                border-color: $primary-color;
                background-color: $snow-white;
                opacity: 0.7;
                color: $primary-color;
                top: 10px;
            }
            &__table-icon {
                color: $primary-color;
            }
        }
    }
    &__steel-blue,
    &__uk-steel-blue{
        .historic-concept{
            &__historic-header {
                padding: 12px 0 0 0;
                width: calc(100% - 40px);
                margin-left: 20px;
                text-align: right;
            }
            &__table{
                width: calc(100% - 40px);
                margin-left: 20px;
                padding: 25px 0 0 0;
                &--top{
                    padding-top: 11px;
                }
            }
            &__table-body{
                &--more-11-elements{
                    width: calc(100% + 21px);
                    padding-right: 14px;
                }
            }
            &__access-head-block{
                background-color: $third-color-2;
            }
            &__back-button{
                float:left;
            }
            &__access-count{
                top: 12px;
                background: none;
                border-color:$primary-color-2;
                color: $primary-color-2;
            }
            &__table-icon {
                color: $border-hover-2;
            }
        }
    }
    &__icam-red{
        .historic-concept{
            &__historic-header {
                padding: 12px 0 0 0;
                width: calc(100% - 40px);
                margin-left: 20px;
                text-align: right;
            }
            &__table{
                width: calc(100% - 40px);
                margin-left: 20px;
                padding: 25px 0 0 0;
                &--top{
                    padding-top: 11px;
                }
            }
            &__table-body{
                &--more-11-elements{
                    width: calc(100% + 21px);
                    padding-right: 14px;
                }
            }
            &__access-head-block{
                background-color: $third-color-icam;
            }
            &__back-button{
                float:left;
            }
            &__access-count{
                top: 12px;
                background: none;
                border-color: $primary-color-icam;
                color: $custom-black-landing;
            }
            &__table-icon {
                color: $primary-color-icam;
            }
        }
    }
    &__gm-law{
        .historic-concept{
            &__historic-header {
                padding: 12px 0 0 0;
                width: calc(100% - 40px);
                margin-left: 20px;
                text-align: right;
            }
            &__table{
                width: calc(100% - 40px);
                margin-left: 20px;
                padding: 25px 0 0 0;
                &--top{
                    padding-top: 11px;
                }
            }
            &__table-body{
                &--more-11-elements{
                    width: calc(100% + 21px);
                    padding-right: 14px;
                }
            }
            &__access-head-block{
                background-color: $third-color-gm;
            }
            &__back-button{
                float:left;
            }
            &__access-count{
                top: 12px;
                background: none;
                border-color: $primary-color-icam;
                color: $custom-black-landing;
            }
            &__table-icon {
                color: $primary-color-gm;
            }
        }
    }

    &__com-de{
      .historic-concept{
          &__historic-header {
              padding: 12px 0 0 0;
              width: calc(100% - 40px);
              margin-left: 20px;
              text-align: right;
          }
          &__table{
              width: calc(100% - 40px);
              margin-left: 20px;
              padding: 25px 0 0 0;
              &--top{
                  padding-top: 11px;
              }
          }
          &__table-body{
              &--more-11-elements{
                  width: calc(100% + 21px);
                  padding-right: 14px;
              }
          }
          &__access-head-block{
              background-color: $third-color-com-de;
          }
          &__back-button{
              float:left;
          }
          &__access-count{
              top: 12px;
              background: none;
              border-color: $primary-color-icam;
              color: $custom-black-landing;
          }
          &__table-icon {
              color: $primary-color-com-de;
          }
      }
  }
  &__com-fr{
    .historic-concept{
        &__historic-header {
            padding: 12px 0 0 0;
            width: calc(100% - 40px);
            margin-left: 20px;
            text-align: right;
        }
        &__table{
            width: calc(100% - 40px);
            margin-left: 20px;
            padding: 25px 0 0 0;
            &--top{
                padding-top: 11px;
            }
        }
        &__table-body{
            &--more-11-elements{
                width: calc(100% + 21px);
                padding-right: 14px;
            }
        }
        &__access-head-block{
            background-color: $third-color-com-fr;
        }
        &__back-button{
            float:left;
        }
        &__access-count{
            top: 12px;
            background: none;
            border-color: $primary-color-icam;
            color: $custom-black-landing;
        }
        &__table-icon {
            color: $primary-color-com-fr;
        }
    }
}
}
