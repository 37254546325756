.field-list{

  &__advisor-text-block{
      display: block;
      width: 100%;
      overflow: hidden;
      margin-top: 54px;
      padding: 0 6px;
  }
  &__advisor-inner-text{
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      text-align: center;
      border-bottom: 1px solid $primary-color;
      padding-bottom: 4px;
  }

  &__template-box{
      position: relative;
      margin: 35px 0 48px;
      border: 1px solid $box-border;
      border-top-width: 5px;
      border-top-color: $primary-color;
      background-color: $background-color-states;
      height: 940px;
      max-height: 940px;
      &--poll{
          height: 565px;
          max-height: 565px;
      }
   }

  &__list {
      padding: 12px 0px 12px 10px;
      font-size: 12px;
      height: 47px;
      width: 100%;
      position: relative;
      border-bottom: 1px solid $bottom-border;
      list-style-type: none;
      &:hover {
          background-color: $background-grey-other;
          cursor: pointer;
          & > .predefined-templates__template-menu {
              display: block;
          }
      }
      &.gu-mirror {
        height: 47px !important;
        width: 278px !important;
        border: 1px solid $primary-color;
        background-color: $primary-color !important;
        box-shadow: 0 0 1px 1px $custom-black-landing !important;
        color: $snow-white !important;
        font-family: Arial !important;
        font-size: 12px !important;
        border-radius: 50px !important;
        transform: translate(0, 0) !important;
        // &:hover,
        // &:focus,
        // &:active{
        //   cursor: not-allowed;
        // }
        & > .field-list__icons-file {
            color: $snow-white !important;
        }
      }
      // &.dndDragging {
      //     height: 47px !important;
      //     width: 278px !important;
      //     border: 1px solid $primary-color;
      //     background-color: $primary-color !important;
      //     box-shadow: 0 0 1px 1px $custom-black-landing !important;
      //     color: $snow-white !important;
      //     font-family: Arial !important;
      //     font-size: 12px !important;
      //     border-radius: 50px !important;
      //     transform: translate(0, 0) !important;

      //     & > .field-list__icons-file {
      //         color: $snow-white !important;
      //     }
      // }

      // &.dndDraggingSource {
      //     height: 47px !important;
      //     width: 100% !important;
      //     border: 0 !important;
      //     border-bottom: 1px solid $bottom-border !important;
      //     box-shadow: none !important;
      //     background-color: $background-grey !important;
      //     border-radius: 0px !important;
      //     color: $front-reset-pass !important;
      //     cursor: auto;

      //     & > .field-list__icons-file {
      //         font-size: 20px !important;
      //         color: $primary-color !important;
      //     }
      // }
  }

  &__field-list{
      display: block;
      list-style: none;
      overflow: auto;
      width: 100%;
      margin: 0;
      padding: 0 8px;
      // height: 705px;
      height: 846px;
      &--poll{
          height: 390px;
          height: 470px;
      }
  }

  &__icons-file {
      font-size: 20px;
      color: $primary-color;
      margin-right: 7px;
      float: left;
      display: block;
  }

  &__text-list {
      margin-top: 4px;
      float: left;
      display: block;
      width: calc(100% - 27px);
  }
  &__parragraph-element{
      margin-top: 4px;
      float: left;
      display: block;
      width: calc(100% - 27px);
  }
}
.compliance{
  &__gm-law{
    .field-list{
      &__advisor-inner-text{
          color: $primary-color-gm;
          border-bottom: 1px solid $primary-color-gm;
          font-size: 12px;
          text-transform: none;
          text-align: left;
      }
      &__template-box{
          border: none;
          border-left: 8px solid ;
          background-color: $snow-white;
          box-shadow: 0 0 5px 1px $border-buttons-gm;
          padding-top: 1px;
      }
      &__list{
          border-bottom: 1px solid $border-block-gm;
          &.dndDragging {
              border: 1px solid $custom-black-landing;
              background-color: $custom-black-landing !important;
              box-shadow: 0 0 1px 1px $custom-black-landing !important;
              & > .field-list__icons-file {
                  color: $snow-white !important;
              }
              & > .field-list__text-list {
                  color: $snow-white !important;
              }
          }
          &.dndDraggingSource {
              background-color: $background-grey-disabled-gm !important;
              color: $primary-color-gm !important;
              box-shadow: none !important;

              & > .field-list__icons-file {
                  color: $primary-color-gm !important;
              }
              & > .field-list__text-list {
                  color: $custom-black-landing !important;
              }
          }
          &:hover {
              background-color: $hover-block-gm;
          }
      }
      &__icons-file {
        color: $primary-color-gm;
      }
      &__text-list {
        color: $custom-black-landing;
      }
    }
  }
  &__com-de{
    .field-list{
      &__advisor-inner-text{
          color: $primary-color-com-de;
          border-bottom: 1px solid $primary-color-com-de;
          font-size: 12px;
          text-transform: none;
          text-align: left;
      }
      &__template-box{
          border: none;
          border-left: 8px solid ;
          background-color: $snow-white;
          box-shadow: 0 0 5px 1px $border-buttons-com-de;
          padding-top: 1px;
      }
      &__list{
          border-bottom: 1px solid $border-block-com-de;
          &.dndDragging {
              border: 1px solid $custom-black-landing;
              background-color: $custom-black-landing !important;
              box-shadow: 0 0 1px 1px $custom-black-landing !important;
              & > .field-list__icons-file {
                  color: $snow-white !important;
              }
              & > .field-list__text-list {
                  color: $snow-white !important;
              }
          }
          &.dndDraggingSource {
              background-color: $background-grey-disabled-com-de !important;
              color: $primary-color-com-de !important;
              box-shadow: none !important;

              & > .field-list__icons-file {
                  color: $primary-color-com-de !important;
              }
              & > .field-list__text-list {
                  color: $custom-black-landing !important;
              }
          }
          &:hover {
              background-color: $hover-block-com-de;
          }
      }
      &__icons-file {
        color: $primary-color-com-de;
      }
      &__text-list {
        color: $custom-black-landing;
      }
    }
  }
  &__com-fr{
    .field-list{
      &__advisor-inner-text{
          color: $primary-color-com-fr;
          border-bottom: 1px solid $primary-color-com-fr;
          font-size: 12px;
          text-transform: none;
          text-align: left;
      }
      &__template-box{
          border: none;
          border-left: 8px solid ;
          background-color: $snow-white;
          box-shadow: 0 0 5px 1px $border-buttons-com-fr;
          padding-top: 1px;
      }
      &__list{
          border-bottom: 1px solid $border-block-com-fr;
          &.dndDragging {
              border: 1px solid $custom-black-landing;
              background-color: $custom-black-landing !important;
              box-shadow: 0 0 1px 1px $custom-black-landing !important;
              & > .field-list__icons-file {
                  color: $snow-white !important;
              }
              & > .field-list__text-list {
                  color: $snow-white !important;
              }
          }
          &.dndDraggingSource {
              background-color: $background-grey-disabled-com-fr !important;
              color: $primary-color-com-fr !important;
              box-shadow: none !important;

              & > .field-list__icons-file {
                  color: $primary-color-com-fr !important;
              }
              & > .field-list__text-list {
                  color: $custom-black-landing !important;
              }
          }
          &:hover {
              background-color: $hover-block-com-fr;
          }
      }
      &__icons-file {
        color: $primary-color-com-fr;
      }
      &__text-list {
        color: $custom-black-landing;
      }
    }
  }

}
