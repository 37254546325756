.concept-info{

  &__content{
      display: block;
      margin: 27px 31px 0 72px;
      height: 494px;
  }

  &__content-body{
      font-family: Arial, sans-serif;
      font-size: 12px;

      &--block{
          max-height: 421px;
          overflow-y: auto;
          overflow-x: hidden;
          padding: 5px 5px 0 0;
      }

      & p{
          color: $secondary-color;
      }
      & h2{
          color: $primary-color;
      }
      & h5{
          color: $primary-color;
          font-size: 10px;
          text-transform: uppercase;
      }
      & ol{
          color: $primary-color;
          list-style-type:decimal;
      }

      & ul{
          color: $primary-color;
          list-style-type:square;
      }
      & li{
          color: $secondary-color;
      }
  }

  &__content-header{
      width: 232px;
      display: block;
      overflow: hidden;
      border-bottom: 3px solid $primary-color;
      margin-bottom: 20px;
  }

  &__content-header-icon{
      font-size: 17px;
      color: $primary-color;
      float: left;
  }

  &__content-header-question{
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: bold;
      padding-top: 4px;
      padding-left: 7px;
      float: left;
      margin: 0;
  }

  ////////////////////////////////////////
  /////////////////////Custom////////////
  //////////////////////////////////////
  &__custom-window{
      & .modal-dialog{
          margin-top: 120px;
          & .modal-content{
              height: 440px;
              border-radius: 6px;
              margin: 0 auto;
          }
      }
  }

  &__content-body-custom{
      max-height: 421px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 5px 5px 0 0;
      color: $custom-black-landing;
      font-family: 'lato_regular', sans-serif;
      font-size: 16px;

      & h5{
          font-size: 10px;
          text-transform: uppercase;
      }
      & ol{
          list-style-type:decimal;
      }

      & ul{
          list-style-type:square;
      }
  }

  ////////////////////////////////////////
  /////////////////////Fin Custom////////////
  //////////////////////////////////////
}
