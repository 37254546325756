.field-type-formu {
  &__block-label-encodings {
      position: relative;
      display: inline-block;
      width: calc(100% - 25px);
      padding-left: 5px;
      vertical-align: middle;

      &--custom {
          width: calc(100% - 26px);
      }
  }

  &__circle {
      height: 20px;
      width: 20px;
      // left: 10px;
      // top:13px;
      border-radius: 50px;
      position: relative;
      display: inline-block;
      background-color: $secondary-color;
      vertical-align: middle;
      &--custom {
          height: 22px;
          width: 22px;
      }
  }

  &__formula {
      font-size: 12px !important;
      padding: 8px 0 13px 10px;

      // &:hover {
      //     .field-type-formu__info-formu {
      //         position: relative;
      //         // visibility: visible;
      //         top: -13px;
      //     }
      // }
  }

  &__info-formu {
      position: relative;
      // visibility: hidden;s
      display: inline-block;
      width: 5px;
      top: 6px;
  }

  &__result-text-formu {
      width: auto;
      font-weight: bold;
      position: relative;
  }

  &__text-label-formula {
      color: $secondary-color;
      max-width: none !important;
      position: relative;
      float: left;
      font-family: MTTMilano-Bold, sans-serif;
      font-weight: 500;
  }

  &__input-icon-formu {
      font-size: 16px;
      opacity: 1;
  }

  &__input-block-formu {
      border-width: 2px;
  }
  ///////////////////////////////////////
  /////////////////Custom///////////////
  /////////////////////////////////////
  &__custom-label {
      font-family: 'lato_regular', sans-serif;
      font-size: 14px;
      color: $custom-black-landing;
  }
}
.compliance{
  &__dark-blue{
    .field-type-formu {
      &__text-label-formula {
        &--no-editable {
          color: $secondary-color;
          cursor: not-allowed;
      }
      }
    }
  }
  &__steel-blue{
    .field-type-formu {
      &__circle {
        background-color: $primary-color-2;
      }
      &__text-label-formula {
        &--no-editable {
          color: $primary-color-2;
          cursor: not-allowed;
        }
      }
    }
  }
  &__icam-red, &__gm-law, &__com-de, &__com-fr{
    .field-type-formu {
      &__circle {
        background-color: $custom-black-landing;
      }
      &__text-label-formula {
        &--no-editable {
          color: $custom-black-landing;
          cursor: not-allowed;
        }
      }
    }
  }

  &__landing-custom{
    .field-type-formu {
      &__text-label-formula {
        &--no-editable {
          color: $custom-black-landing;
          font-family: 'lato_regular', sans-serif;
          font-size: 13px;
        }
      }
    }
  }
}
