.compliance-footer {
    width: 100%;
    height: 200px;
    display: block;
    overflow: hidden;
    background-color: $primary-color;
    float: left;
    position: relative;

    padding: 40px 242px 20px 254px;
    color: $snow-white;

    &--public {
        height: auto;
        min-height: 100px;
        padding: 35px 12% 29px 15%;
    }

    &__icon-logo {
        padding: 0;
    }

    &__footer-block{
        padding: 0;
    }

    &__footer {
        &--relative {
            position: relative;
            float: left;
        }
        &--v2{
            margin-top: 25px;
            margin-left: 0;
            font-size: 14px;
            font-family: MTTMilano, sans-serif;
            font-weight: 500;
        }
        &--v3{
            margin-left: 30px;
            font-size: 14px;
            font-family: MTTMilano, sans-serif;
            font-weight: 500;
            top: 6px;
        }
        &--without-logo{
            margin-top: 0;
        }
    }

    &__footer-public {
        margin-top: 10px;
        font-size: 14px;
        color: $snow-white;
        font-family: MTTMilano, sans-serif;
        font-weight: 500;
    }

    &__rrss-block{
        float: right;
        text-align: right;
        padding: 0;
    }

    &__button-icon {

        border: none;
        background: none;
        color: $snow-white;
        font-size: 30px;
        margin: 0px 7px 0;
        outline: none;
        &:hover,
        &:visited,
        &:focus,
        &:active{
            cursor: pointer;
            color: inherit;
            text-decoration: none;
        }

    }

    &__icon-call {
        font-size: 20px;
        margin-left: 5px;
        margin-right: 5px;
        position: relative;
        top: 4px;
    }

    &__contact {
        color: $snow-white;
        font-family: MTTMilano, sans-serif;
        font-size: 14px;
        font-weight: 900;
        float: right;
        letter-spacing: 2px;
        margin-top: 30px;

        &----underline {
            text-decoration: underline;
        }
    }

    &__disclaimer-text {
        font-size: 13.4px;

        font-family: MTTMilano, sans-serif;
        margin: 35px 0 0;
        font-weight: 500;
        text-decoration: none;
        color: inherit;

        &--link {
            &:hover {
                color: inherit;
                cursor: pointer;

                text-decoration: underline;
            }

            &:visited {
                color: inherit;

            }

            &:focus {
                color: inherit;

            }
        }

    }



    &__link-page-item {
        display: inline-block;

        margin: 35px 0 0;

    }


    &__block-img-ideo {
        position: relative;
        float: right;
    }

    &__img-ideo {
        width: 229px;
    }
    &__top-block{
      display: flex;
      flex-direction: row;
      align-content: space-between;
      justify-content: space-around;
      align-items: stretch;
    }
    &__last-connection{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      float: right;
    }
}
.compliance{
  &__steel-blue{
    .compliance-footer{
      background: $primary-color-2;
      color: $white-special-2;
      // float: left;
      padding: 15px 248px 20px;
      height: 130px;
      &__disclaimer-text{
          margin: 0;
      }
      &__block-logo{
          height: 40px;
          margin-top: 15px;
      }
      &__img-logo{
        height: 40px;
      }
    }
  }
  &__uk-steel-blue{
    .compliance-footer{
      background: transparent;
      padding: 15px;
      height: 60px;
      border-top: 1px solid $secondary-color-2;
      color: $primary-color-2;
      &__disclaimer-text{
          margin: 0;
          &--link {
            text-decoration: underline;
          }
      }
      &__block-logo{
          position: relative;
          float: left;
      }
      &__img-logo{
        height: 30px;
      }
    }
  }
  &__icam-red{
    .compliance-footer{
      background-color: $custom-black-landing;
    }
  }
  &__gm-law{
    .compliance-footer{
      background-color: $primary-color-gm;
    }
  }
  &__com-de{
    .compliance-footer{
      background-color: $primary-color-com-de;
    }
  }
  &__com-fr{
    .compliance-footer{
      background-color: $primary-color-com-fr;
    }
  }
}

@media (min-width: 900px) and (max-width: 1366px){
    .compliance-footer{
        padding: 40px 40px 20px 40px;
        &--public{
            padding: 35px 0 29px 0;
        }
        &--v2{
            padding: 15px 100px 20px;
        }
    }
}

@media (max-width: 900px){
    .compliance-footer{
        padding: 40px 20px 20px 20px;
        &--v2{
            padding: 15px 30px 20px;
        }
    }
}

@media (max-width: $max-width-smartphone) { //Smartphone
    .compliance-footer {
        &__link-page-item {
            margin-top: 25px;
            width: auto;
        }
    }
}

@media print {
    .compliance-footer{
        &__img-logo{
            filter: drop-shadow(2px 2px 2px black);
        }
    }
}
