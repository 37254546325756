.users-admin {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &__container {
      width: 100%;
      margin: 0 auto;
      min-height: calc(100% - 369px);
      display: block;
      max-width: 1400px;
  }

  &__main {
      position: relative;
      height: auto;
      overflow-y: initial;
      padding: 10px;
      float: left;
      width: 100%;
      // &--color-steel-blue{
      //     padding: 10px 0;
      // }
  }
}

@media (max-width: 1430px) {
  .users-admin {
      &__container {
          padding: 0 10px;
      }
  }
}
