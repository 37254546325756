.new-custom-report-preview {
  &__block-body {
      display: flex;
      justify-content: space-around;
      margin: 0 13px;
      border: 1px solid $primary-color;
      padding: 5px;
      max-width: 1250px;
      overflow-y: hidden;
      overflow-x: hidden;
      max-height: 600px;
  }

  &__table-container {
      display: block;
      overflow-y: auto;
      overflow-x: auto;
      width: 100%;
      padding-bottom: 10px;
  }

  &__table-header-info {
      display: flex;
      position: sticky;
      top: 0;
      z-index: 9;
  }

  &__table-header-info-fields {
      background-color: $background-color-states;
      width: 245px;
      height: 30px;
      padding: 5px;
      font-size: 12px;
      font-family: MTTMilano-Bold, sans-serif;
      color: $pillow-group;

      &--total-width {
          width: 100%;
      }

      &--calc-width {
          width: calc(100% - 250px);
          margin-left: 5px;
      }
  }

  &__table-header-info-field-p{
      margin: 0;
  }

  &__table {
      display: table;
      position: relative;
      min-width: 100%;
  }

  &__origins {
      box-shadow: 0px 5px 15px $column-filter-shadow-05;
      background-color: $background-grey-other;
      border: 1px solid $subcontainer-border;
      border-radius: 5px;
      padding-top: 19px;
      width: 60px;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 20px;
      height: 100%;
  }

  &__origins-header {
      background-color: $background-grey-other;
      padding-top: 10px;
      width: 60px;
      text-align: center;
      // margin-bottom: 20px;
      // height: 43px;
      // position: sticky;
      height: 46px;
      position: absolute;
      right: 0;
      // left: calc(100% - 60px);
  }

  &__origins-header-p {
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
  }

  &__origins-element {
      padding-top: 2px;
      height: 41px;
      position: relative;
  }

  &__origins-icon-container {
      height: 46px;
      position: sticky;
      right: 0;
      left: calc(100% - 60px);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      background-color: $background-grey-other;
      color: $primary-color;
  }

  &__tooltip-left{
    .tooltip-inner {
        border-radius: 0;
        border-right-width: 3px !important;
    }
  }

  &__origins-icon {
      font-size: 20px;
  }

  &__table-header {
      display: flex;
      border-bottom: 1px solid $primary-color;
      width: 100%;
      margin-top: 12px;
      position: relative;
      color: $primary-color;
  }

  &__table-header-element {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 250px;
      min-width: 250px;
      padding-right: 5px;
      height: 45px;
  }

  &__table-header-icon {
      cursor: pointer;
      font-size: 20px;
  }

  &__table-header-p {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      display: inline;
      margin: 0 0 0 5px;
      width: calc(100% - 25px);
  }

  &__table-body-container {
      overflow-x: visible;
      overflow-y: visible;
      height: auto;
      display: block;
      width: 100%;
  }

  &__col-body {
      height: 46px;
      display: flex;
      width: 100%;
      position: relative;
      border-bottom: 1px solid $box-comment-border-2;
  }

  &__table-element {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding-top: 12px;
      padding-right: 5px;
      width: 250px;
      min-width: 250px;
  }

  &__table-icon {
      font-size: 16px;
      position: relative;
  }

  &__table-p {
      display: inline-block;
      width: calc(100% - 21px);
      margin-left: 5px;
      color: $secondary-color;
      font-size: 12px;
      font-family: Arial, sans-serif;
      position: relative;
      top: 3px;
  }
}

.compliance{
  &__icam-red {
    .new-custom-report-preview {
      &__block-body {
        border-color: $border-block-icam;
      }

      &__table-header-info-fields {
        background-color: $background-grey-icam;
        color: $secondary-color-icam;
      }

      &__origins-header {
        background-color: $background-grey-disabled-icam;
      }

      &__origins-header-p {
        color: $primary-color-icam;
      }

      &__origins-icon-container {
        background-color: $background-grey-disabled-icam;
        color: $primary-color-icam;
      }

      &__table-header {
        border-color: $primary-color-icam;
        color: $primary-color-icam;
      }

      &__col-body {
        border-color: $border-block-icam;
      }

      &__table-icon {
        color: $secondary-color-icam;
      }

      &__table-p {
        color: $custom-black-landing;
      }
    }
  }

  &__gm-law {
    .new-custom-report-preview {
      &__block-body {
        border-color: $border-block-gm;
      }

      &__table-header-info-fields {
        background-color: $background-grey-gm;
        color: $secondary-color-gm;
      }

      &__origins-header {
        background-color: $background-grey-disabled-gm;
      }

      &__origins-header-p {
        color: $primary-color-gm;
      }

      &__origins-icon-container {
        background-color: $background-grey-disabled-gm;
        color: $primary-color-gm;
      }

      &__table-header {
        border-color: $primary-color-gm;
        color: $primary-color-gm;
      }

      &__col-body {
        border-color: $border-block-gm;
      }

      &__table-icon {
        color: $secondary-color-gm;
      }

      &__table-p {
        color: $custom-black-landing;
      }
    }
  }
  &__com-de{
    .new-custom-report-preview {
      &__block-body {
        border-color: $border-block-com-fr;
      }

      &__table-header-info-fields {
        background-color: $background-grey-com-fr;
        color: $secondary-color-com-fr;
      }

      &__origins-header {
        background-color: $background-grey-disabled-com-fr;
      }

      &__origins-header-p {
        color: $primary-color-com-fr;
      }

      &__origins-icon-container {
        background-color: $background-grey-disabled-com-fr;
        color: $primary-color-com-fr;
      }

      &__table-header {
        border-color: $primary-color-com-fr;
        color: $primary-color-com-fr;
      }

      &__col-body {
        border-color: $border-block-com-fr;
      }

      &__table-icon {
        color: $secondary-color-com-fr;
      }

      &__table-p {
        color: $custom-black-landing;
      }
    }
  }
  &__com-fr{
    .new-custom-report-preview {
      &__block-body {
        border-color: $border-block-com-fr;
      }

      &__table-header-info-fields {
        background-color: $background-grey-com-fr;
        color: $secondary-color-com-fr;
      }

      &__origins-header {
        background-color: $background-grey-disabled-com-fr;
      }

      &__origins-header-p {
        color: $primary-color-com-fr;
      }

      &__origins-icon-container {
        background-color: $background-grey-disabled-com-fr;
        color: $primary-color-com-fr;
      }

      &__table-header {
        border-color: $primary-color-com-fr;
        color: $primary-color-com-fr;
      }

      &__col-body {
        border-color: $border-block-com-fr;
      }

      &__table-icon {
        color: $secondary-color-com-fr;
      }

      &__table-p {
        color: $custom-black-landing;
      }
    }
  }
}
