
@mixin concept-tab-list($firstColor, $secondColor){
  border-bottom: 0;
  border: 1px solid $firstColor;
  padding: 8px 8px 0px 7px;
  border-radius: 17px;
  margin: 10px 10px 0;
  color: $secondColor;
  line-height: 18px;
  height: 34px;
}

@mixin list-active($firstColor, $secondColor) {
  color: $firstColor;
  background:  $secondColor;
}

@mixin amount-info($firstColor, $secondColor) {
  color: $firstColor;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border: 0;
  background: $secondColor;
  padding: 2px 15px 2px 15px;
  bottom: 3px;
  height: 22px;
}

@mixin menu-header($firstColor, $secondColor) {
  border-color: $firstColor;
  background: $secondColor;
  margin-right: 20px;
  margin-top: 10px;
  height: 34px;
}

@mixin share-button($color){
  background: $color;
  width: 23px;
  height: 23px;
  bottom: 3px;
}

@mixin subtab-block($color) {
  height: 57px;
  border: 2px solid $color;
  border-bottom: 0;
  // border-top: 0;
}

@mixin concept-list-text-block-color($color){
  border-top: 2px solid $color;
  border-left: 2px solid $color;
  height: 60px;
}

@mixin concept-list-text-block-white($primaryColor){
  background-color: $primaryColor;
  &:after{
      content: '';
      right: -37px;
      position: absolute;
      top: 2px;
      border-top: 0px solid transparent;
      border-left: 0px solid $primaryColor;
      border-bottom: 56px solid $primaryColor;
      border-right: 37px solid transparent;
  }
}

@mixin concept-list-text-block-inactive($primaryColor, $hoverColor){
  background-color: $primaryColor;
  height: 58px;
  & > .concept-tab__triangle-block {
      & > .concept-tab__triangle-color {
          fill: $primaryColor;
      }
  }
  &:after{
      border-left-color: $primaryColor;
      border-bottom: 54px solid $primaryColor;
  }
  &:hover {
      &:after{
          border-left-color: $hoverColor;
          border-bottom-color: $hoverColor;
      }
  }
}

@mixin concept-list-text-title($color){
  margin: 17px 0 0px 18px;
  font-family: "MTTMilano-bold";
  color: $color;
  border-color: transparent;
  height: 47px;
  position: relative;
  top: 8px;
}

@mixin separate-line($backgroundColor) {
  border: 2px solid $backgroundColor;
  display: block;
  width: calc(100% - 44px);
  margin: 7px 24px 0 20px;
  bottom: 0px;
  position: relative;
  opacity: 1;
  z-index: 1;
}

@mixin text-block-inactive($color){
  &:hover {
      cursor: pointer;
      background-color: $color;
      & > .concept-tab__close-icon {
          display: block;
      }

      & > .concept-tab__triangle-block {
          & > .concept-tab__triangle-color {
              fill: $color;
          }
      }
  }
}

.concept-tab {
  width: 100%;
  display: block;
  position: relative;
  float: left;

  &__tab-items-block {
      height: 58px;
      width: 100%;
      padding: 0;
      margin: 0;
  }

  &__block-button-tabs {
      width: 30px;
      left: 64%;
      position: absolute;
      z-index: 11;
      top: 0;
  }

  &__block-list-concept {
      position: relative;
      margin: 5px 0px;
      height: 22px;

      &:hover {
          color: $primary-color;

          .concept-tab__line-bottom-block {
              visibility: visible;
              opacity: 0.2;
          }

          .concept-tab__icon-directions {
              display: inline;
              bottom: 0px;
          }
      }

      &--disabled {
          &:hover {
              font-weight: bold;

              .concept-tab__line-bottom-block {
                  visibility: hidden;
                  opacity: 0.2;
              }
          }
      }
  }

  &__button-more-tabs {
      outline: 0;
      background: transparent;
      border: none;
      color: $primary-color;
      font-size: 16px;
      position: relative;
      top: 4px;
  }
  &__hours {
      display: block;
      float: right;
      margin-top: 9px;
      margin-right: 10px;
  }
  &__button-hours {
      box-sizing: border-box;
      height: 36px;
      min-width: 110px;
      width: auto;
      padding: 0 20px;
      border-radius: 20px;
      border: none;
      background-color: $background-grey-icam;
      &:hover{
          cursor: default;
      }
  }

  &__icon-hours {
      color: $snow-white;
      font-size: 20px;
      position: relative;
      top: 1px;
  }

  &__text-hours {
      color: $snow-white;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0;
      line-height: 16px;
      position: relative;
      margin-left: 3px;
      bottom: 3px;
  }

  &__cell-block {
      position: relative;
      display: inline-block;
      height: 17px;
      bottom: 2px;
      width: calc(100% - 44px);
      vertical-align: middle;

      &:hover {
          cursor: pointer;
      }
  }

  &__col-text {
      font-family: Arial, sans-serif;
      font-size: 12px;
      margin: 0;
      padding-left: 7px;
      &--bold {
          font-weight: bolder;
          padding-left: 0px;
      }
  }

  &__doc-number {
      position: absolute;
      margin-top: 12px;
      margin-left: 5px;
  }

  &__expedient-text {
      padding-top: 2px;
  }

  &__general-tab {
      position: relative;
      width: 147px;
  }

  &__icon-createtask-disabled {
      color: $secondary-color;
      &:hover {
          cursor: auto;
      }
  }

  &__icon-directions {
      float: right;
      font-size: 20px;
      position: absolute;
      bottom: 0px;
      right: 5px;
      color: $primary-color;
      display: none;
  }

  &__icon-element-list {
      font-size: 14px;
      width: 18px;
      position: relative;
      display: inline-block;
      top: 2px;
      left: -1px;
  }

  &__icon-position {
      z-index: 10;
  }

  &__line-bottom-block {
      position: absolute;
      width: 98%;
      height: 1px;
      background-color: $primary-color;
      visibility: hidden;
  }

  &__line-color {
      height: 1px;
      background: $primary-color;
      width: calc(100% - 18px);
      margin-left: 18px;
      margin-top: 1px;
  }

  &__list {
      font-size: 12px;
      float: left;
      text-transform: uppercase;
      padding: 15px 25px 0;
      font-family: MTTMilano-Bold, sans-serif;
      &:hover {
          cursor: pointer;
      }
  }

  &__list-text {
      margin: 0;
      padding: 0;
      float: left;
      position: relative;
  }

  &__amount-info {
      position: relative;
      float: left;
      border-radius: 12px;
      margin-left: 5px;
  }

  &__menu-all-block {
      overflow-y: auto;
      min-height: 220px;
      bottom: 10px;
      position: relative;
      padding-top: 10px;
      width: 98%;
  }

  &__menu-all-concepts {
      position: absolute;
      left: -348px;
      top: -12px;
      width: 344px;
      // padding-top: 20px;
      border: 1px solid $concept-from-grey;
      background-color: $snow-white;
      box-shadow: 0 0 5px 1px $certify-black-opacity02;
      z-index: 10;
  }

  &__menu-all-concepts-list {
      list-style: none;
      margin: 0px;
      padding: 0px;
      padding-left: 17px;
      font-family: Arial, sans-serif;
      font-size: 11px;
      color: $secondary-color;
  }

  &__menu-general {
      margin-top: 3px;

      &--numeric {
          margin-left: 12px;
      }

      &--documentation {
          position: relative;
          width: 200px;
      }

      &--task {
          position: relative;
          width: 115px;
      }

      &:hover {
          cursor: pointer;
      }
  }

  &__menu-general-task {
      margin-left: 10px;
  }

  &__menu-header {
      display: block;
      float: right;
      border: 1px solid;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
  }

  &__menu-law {
      padding: 0px 4px;
  }

  &__menu-li {
      display: inline-block;
      text-align: center;
      font-size: 20px;
      height: 23px;
      &:hover {
          cursor: pointer
      }

      &--disabled{
          opacity: 0.5;
          &:hover{
              cursor: context-menu;
          }
      }
  }

  &__menu-li-icon {
      &--active {
          color: $folder-published;
      }
  }

  &__share-button {
      border: none;
      border-radius: 50%;
      position: relative;
      bottom: 4px;
      margin-left: 8px;
      float: left;
      outline: none;
      display: none;
      &--show {
          display: block;
      }
  }

  &__share-icon {
      font-size: 15px;
      color: $snow-white;
      position: relative;
      top: 2px;
      right: 3px;
  }

  &__warning-icon {
      font-size: 15px;
      color: $snow-white;
      position: relative;
      top: 1px;
  }

  &__menu-ul {
      list-style: none;
      display: block;
      text-align: center;
      padding: 0 0 0 8px;
      margin: 0;
      min-width: 40px;
  }

  &__more-options {
      float: right;
      margin-top: 2px;
  }

  &__tab-block-ellipsis {
      position: relative;
      margin-top: 27px;
      margin-left: 18px;
      visibility: hidden;

      &--inactive {
          margin-top: 34px;
          margin-left: 8px;
      }

      &--visible {
          visibility: visible;
      }
  }

  &__subtab-block {
      width: 100%;
      padding: 0;
      margin: 0;
  }

  &__subtab-list-items {
      padding: 0;
      list-style: none;
  }

  &__tabs-list {
      display: inline-block;
      width: 65%;
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
      // bottom: 1px;
  }

  &__text-documentation {
      margin-left: -10px;
  }

  &__text-documentation-number {
      margin-left: -30px;
  }

  &__text-doc {
      margin-left: -20px;
  }

  &__text-task {
      margin-left: -20px
  }

  &__text-task-number {
      position: relative;
      margin-left: -36px;
  }

  &__tooltip-info {
      position: absolute;
      background-color: $background-grey;
      border-top: 4px solid $primary-color;
      z-index: 10;
      top: 193px;
      margin-left: 21px;
      padding-left: 10px;
      padding-right: 10px;
      min-width: 100px;
      display: block;
      text-align: left;
  }

  &__without-number {
      width: 135px;
  }

  &__concept-list {
      height: 58px;
      width: 100px;
      display: inline-block;
      &--active-1 {
          width: calc(100%);
      }

      &--active-2 {
          width: calc(100% - 100px);
      }

      &--active-3 {
          width: calc(100% - 200px);
      }

      &--active-4 {
          width: calc(100% - 300px);
      }

      &--active-5 {
          width: calc(100% - 400px);
      }

      &--active-max {
          width: calc(100% - 500px);
      }
  }

  &__concept-list-text-block {
      position: relative;
      width: calc(100% - 37px);
      height: 58px;
  }

  &__concept-list-text-title {
      font-size: 12px;
      margin: 34px 0 5px 18px;

      &--inactive {
          font-size: 12px;
      }

      &--ellipsis {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
      }

      &--active {
          font-size: 20px;
          border-bottom: 1px solid;
          padding-bottom: 1px;
      }
  }

  &__close-icon {
      position: absolute;
      top: 7px;
      right: 2px;
      font-size: 15px;
      display: none;
      z-index: 10;
      opacity: 0.4;
      cursor: pointer;
      &--active {
          display: block;
          opacity: 1;
      }
  }

  &__triangle-block {
      width: 38px;
      height: 58px;
      position: absolute;
      top: -1px;
      right: -38px;

  }

  &__triangle-color {
      fill: transparent;
  }

  &__other-buttons-top {
      float: right;
      position: relative;
      height: 58px;
      margin-top: 0;
      margin-right: 0;
      width: 35%;
  }

  &__block-other-buttons{
      display: flex;
      justify-content: flex-end;
      float: right;
      height: 35px;
      position: relative;
      top: 11px;
  }

  &__scoring-block{
      float: right;
      position: relative;
      padding: 20px 20px 8px 0;
      color: $pillow-group;
      text-transform: uppercase;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      line-height: 16px;
  }

  &__icon-circle-scoring{
      font-size: 10px;
      margin-right: 3px;
  }
  &__button-rename-concept{
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 20px;
      padding: 0;
      margin-left: 4px;
      position: relative;
      top: 2px;
  }
}

.compliance{
  &__dark-blue{
    .concept-tab {
      &__hours {
          margin-top: 7px;
      }
      &__button-hours {
        &--red{
            border: 1px solid $red;
            background-color: $red;
        }
        &--grey{
            background-color: $subcontainer-ground;
        }
      }
      &__list {
          color: $primary-color;
          height: 50px;
        &--active{
            border-bottom: 9px solid $primary-color;
        }
      }
      &__amount-info {
        top: -4px;
        font-size: 12px;
        padding: 2px 12px 0px 12px;
        border: 2px solid $primary-color;
      }
      &__menu-header {
        border-color: $menu-icons-border;
        background: $background-grey-other;
        height: 38px;
        margin-top: 6px;
      }
      &__menu-li {
        color: $primary-color;
        margin: 6px 4px;
      }
      &__share-button {
        background: $primary-color;
        height: 25px;
        width: 25px;
      }
      &__share-icon {
        right: 2px;
      }
      &__subtab-block {
        border: 1px solid $primary-color;
        border-bottom: 2px solid $primary-color;
        height: 51px;
        border-top: 0;
      }
      &__subtab-list-items {
        margin: 0;
      }
      &__concept-list {
        &--inactive{
              border-bottom: 1px solid $primary-color;
          }
        }
      &__concept-list-text-block {
        border: 1px solid $primary-color;
        border-right-color: transparent;
        border-bottom-color: transparent;
        &--inactive{
            @include text-block-inactive($upload-document-border-44);
        }
      }
      &__concept-list-text-title {
        margin: 27px 0 0 18px;
        color: $primary-color;
        border-color: $primary-color;
        height: 30px;
      }
      &__close-icon {
        color: $primary-color;
      }
      &__triangle-block {
        right: -39px;
      }
      &__triangle-line {
          stroke: $primary-color;
          stroke-width: 1px;
      }
      &__other-buttons-top {
        border-bottom: 1px solid $primary-color;
      }
      &__separate-line{
        display: none;
      }
      &__scoring-block{
        padding: 17px 15px 17px 0;
      }
      &__button-rename-concept{
          color: $primary-color-2;
      }
    }
  }
  &__steel-blue, &__uk-steel-blue{
    .concept-tab {
      &__button-hours {
        &--red{
            background-color: $alert-color-2;
        }
        &--grey{
            background-color: $secondary-color-2;
        }
      }
      &__list {
        @include concept-tab-list($third-color-2, $border-hover-2);
        &--active{
            @include list-active($primary-color-2, $third-color-2)
        }
      }
      &__amount-info {
        @include amount-info($primary-color-2, $third-color-2);
        &--active {
            background-color: $border-hover-2;
            color: $white-special-2;
        }
      }
      &__menu-header {
        @include menu-header($secondary-color-2, $third-color-2);
      }
      &__menu-li {
        color: $border-hover-2;
        margin: 4px 2px;
      }
      &__share-button {
        @include share-button($border-hover-2);
      }
      &__subtab-block {
        @include subtab-block($secondary-color-2)
      }
      &__subtab-list-items {
        margin: 0 0 0 10px;
        display: block;
        width: calc(100% - 10px);
        overflow: hidden;
      }
      &__concept-list-text-block {
        @include concept-list-text-block-color($secondary-color-2);
        @include concept-list-text-block-white($snow-white);
        &--inactive{
          @include text-block-inactive($block-hover-2);
          @include concept-list-text-block-inactive($snow-white, $block-hover-2);
        }
      }
      &__concept-list-text-title {
        @include concept-list-text-title($primary-color-2);
      }
      &__close-icon {
        color: $secondary-color-2;
      }
      &__triangle-line{
          stroke: $secondary-color-2;
          stroke-width: 2px;
      }
      &__separate-line{
        @include separate-line($third-color-2);
      }
      &__scoring-block{
        color: $grey-icons-2;
      }
      &__button-rename-concept{
          color: $primary-color-2;
      }
    }
  }
  &__uk-steel-blue{
    .concept-tab {
      &__button-hours {
        &--red{
            background-color: $alert-color-2;
        }
        &--grey{
            background-color: $secondary-color-2;
        }
      }
      &__subtab-block {
        @include subtab-block($secondary-color-2);
        background-color: $snow-white;
      }
    }
  }
  &__icam-red{
    .concept-tab {
      &__button-hours {
        &--green{
            border: 1px solid $green-task-icam;
            background-color: $green-task-icam;
        }
        &--red{
            border: 1px solid $alert-color-icam;
            background-color: $alert-color-icam;
        }
        &--yellow{
            border: 1px solid $yellow-task-icam;
            background-color: $yellow-task-icam;
        }
        &--grey{
            border: 1px solid $secondary-color-icam;
            background-color: $secondary-color-icam;
        }
      }
      &__list {
        @include concept-tab-list($third-color-icam, $custom-black-landing);
        &--active{
          @include list-active($primary-color-icam, $third-color-icam)
        }
      }
      &__amount-info {
        @include amount-info( $custom-black-landing, $third-color-icam);
        &--active {
            background-color: $primary-color-icam;
            color: $snow-white;
        }
      }
      &__menu-header {
        @include menu-header($primary-color-icam, $primary-color-icam);
      }
      &__menu-li {
        color: $snow-white;
        margin: 4px 2px;
      }
      &__share-button {
        @include share-button($primary-color-icam);
      }
      &__warning-icon {
        right: 3px;
      }
      &__subtab-block {
        @include subtab-block($secondary-color-2);
        background-color: $snow-white;
      }
      &__subtab-list-items {
        margin: 0 0 0 10px;
        display: block;
        width: calc(100% - 10px);
        overflow: hidden;
      }
      &__concept-list-text-block {
        @include concept-list-text-block-color($border-block-icam);
        @include concept-list-text-block-white($snow-white);
        &--inactive{
            @include text-block-inactive($hover-block-icam);
            @include concept-list-text-block-inactive($background-grey-disabled-icam, $hover-block-icam);
        }
      }
      &__concept-list-text-title {
        @include concept-list-text-title($custom-black-landing);
      }
      &__close-icon {
        color: $primary-color-icam;
      }
      &__triangle-line{
        stroke: $border-block-icam;
        stroke-width: 2px;
        fill: $snow-white;
      }
      &__separate-line {
        @include separate-line($third-color-icam);
      }
      &__button-rename-concept{
          color: $primary-color-icam;
      }
    }
  }
  &__gm-law{
    .concept-tab {
      &__button-hours {
        &--green{
            border: 1px solid $green-task-gm;
            background-color: $green-task-gm;
        }
        &--red{
            border: 1px solid $alert-color-gm;
            background-color: $alert-color-gm;
        }
        &--yellow{
            border: 1px solid $yellow-task-gm;
            background-color: $yellow-task-gm;
        }
        &--grey{
            border: 1px solid $secondary-color-gm;
            background-color: $secondary-color-gm;
        }
      }
      &__list {
        @include concept-tab-list($third-color-gm, $custom-black-landing);
        &--active{
          @include list-active($primary-color-gm, $third-color-gm)
        }
      }
      &__amount-info {
        @include amount-info( $custom-black-landing, $third-color-gm);
        &--active {
            background-color: $primary-color-gm;
            color: $snow-white;
        }
      }
      &__menu-header {
        @include menu-header($primary-color-gm, $primary-color-gm);
      }
      &__menu-li {
        color: $snow-white;
        margin: 4px 2px;
      }
      &__share-button {
        @include share-button($primary-color-gm);
      }
      &__warning-icon {
        right: 3px;
      }
      &__subtab-block {
        @include subtab-block($border-block-gm);
        background-color: $snow-white;
      }
      &__subtab-list-items {
        margin: 0 0 0 10px;
        display: block;
        width: calc(100% - 10px);
        overflow: hidden;
      }
      &__concept-list-text-block {
            @include concept-list-text-block-color($border-block-gm);
            @include concept-list-text-block-white($snow-white);
        &--inactive{
            @include text-block-inactive($hover-block-gm);
            @include concept-list-text-block-inactive($background-grey-disabled-gm, $hover-block-gm);
        }
      }
      &__concept-list-text-title {
        @include concept-list-text-title($custom-black-landing);
      }
      &__close-icon {
        color: $primary-color-gm;
      }
      &__triangle-line{
        stroke: $border-block-gm;
        stroke-width: 2px;
        fill: $snow-white;
      }
      &__separate-line{
        @include separate-line($third-color-gm);
      }
      &__button-rename-concept{
          color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    .concept-tab {
      &__button-hours {
        &--green{
            border: 1px solid $green-task-com-de;
            background-color: $green-task-com-de;
        }
        &--red{
            border: 1px solid $alert-color-com-de;
            background-color: $alert-color-com-de;
        }
        &--yellow{
            border: 1px solid $yellow-task-com-de;
            background-color: $yellow-task-com-de;
        }
        &--grey{
            border: 1px solid $secondary-color-com-de;
            background-color: $secondary-color-com-de;
        }
      }
      &__list {
        @include concept-tab-list($third-color-com-de, $custom-black-landing);
        &--active{
          @include list-active($primary-color-com-de, $third-color-com-de)
        }
      }
      &__amount-info {
        @include amount-info( $custom-black-landing, $third-color-com-de);
        &--active {
            background-color: $primary-color-com-de;
            color: $snow-white;
        }
      }
      &__menu-header {
        @include menu-header($primary-color-com-de, $primary-color-com-de);
      }
      &__menu-li {
        color: $snow-white;
        margin: 4px 2px;
      }
      &__share-button {
        @include share-button($primary-color-com-de);
      }
      &__warning-icon {
        right: 3px;
      }
      &__subtab-block {
        @include subtab-block($border-block-com-de);
        background-color: $snow-white;
      }
      &__subtab-list-items {
        margin: 0 0 0 10px;
        display: block;
        width: calc(100% - 10px);
        overflow: hidden;
      }
      &__concept-list-text-block {
            @include concept-list-text-block-color($border-block-com-de);
            @include concept-list-text-block-white($snow-white);
        &--inactive{
            @include text-block-inactive($hover-block-com-de);
            @include concept-list-text-block-inactive($background-grey-disabled-com-de, $hover-block-com-de);
        }
      }
      &__concept-list-text-title {
        @include concept-list-text-title($custom-black-landing);
      }
      &__close-icon {
        color: $primary-color-com-de;
      }
      &__triangle-line{
        stroke: $border-block-com-de;
        stroke-width: 2px;
        fill: $snow-white;
      }
      &__separate-line{
        @include separate-line($third-color-com-de);
      }
      &__button-rename-concept{
          color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .concept-tab {
      &__button-hours {
        &--green{
            border: 1px solid $green-task-com-fr;
            background-color: $green-task-com-fr;
        }
        &--red{
            border: 1px solid $alert-color-com-fr;
            background-color: $alert-color-com-fr;
        }
        &--yellow{
            border: 1px solid $yellow-task-com-fr;
            background-color: $yellow-task-com-fr;
        }
        &--grey{
            border: 1px solid $secondary-color-com-fr;
            background-color: $secondary-color-com-fr;
        }
      }
      &__list {
        @include concept-tab-list($third-color-com-fr, $custom-black-landing);
        &--active{
          @include list-active($primary-color-com-fr, $third-color-com-fr)
        }
      }
      &__amount-info {
        @include amount-info( $custom-black-landing, $third-color-com-fr);
        &--active {
            background-color: $primary-color-com-fr;
            color: $snow-white;
        }
      }
      &__menu-header {
        @include menu-header($primary-color-com-fr, $primary-color-com-fr);
      }
      &__menu-li {
        color: $snow-white;
        margin: 4px 2px;
      }
      &__share-button {
        @include share-button($primary-color-com-fr);
      }
      &__warning-icon {
        right: 3px;
      }
      &__subtab-block {
        @include subtab-block($border-block-com-fr);
        background-color: $snow-white;
      }
      &__subtab-list-items {
        margin: 0 0 0 10px;
        display: block;
        width: calc(100% - 10px);
        overflow: hidden;
      }
      &__concept-list-text-block {
            @include concept-list-text-block-color($border-block-com-fr);
            @include concept-list-text-block-white($snow-white);
        &--inactive{
            @include text-block-inactive($hover-block-com-fr);
            @include concept-list-text-block-inactive($background-grey-disabled-com-fr, $hover-block-com-fr);
        }
      }
      &__concept-list-text-title {
        @include concept-list-text-title($custom-black-landing);
      }
      &__close-icon {
        color: $primary-color-com-fr;
      }
      &__triangle-line{
        stroke: $border-block-com-fr;
        stroke-width: 2px;
        fill: $snow-white;
      }
      &__separate-line{
        @include separate-line($third-color-com-fr);
      }
      &__button-rename-concept{
          color: $primary-color-com-fr;
      }
    }
  }
}


@media screen and (max-width:1366px){
    .concept-tab__list{
      // margin-left: 22px;
      // margin-right: -14px;
      margin-right: 0px;
    }
  }

  @media screen and (max-width: 58em) {
    .concept-tab{
      &__tab-link-text-block{
        display: none;
      }
      &__tab-link{
        :before {
          margin-right: 0;
        }
      }
    }
  }

  @media (max-width: 1350px){
    .concept-tab{
      &__list{
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
