
.blocked-edition-modal {
  & .modal-content {
      height: 755px;
      border-radius: 0px;
  }

  &__body{
      padding: 44px 70px 0;
  }

  &__title-block-modal{
      line-height: 22px;
      margin-bottom: 22px;
  }
}
