.up-document{
    &.modal .modal-content {
        width: 900px;
        height: 403px;
        border-radius: 0px;
    }
    &__file-upload{
        width: 0px;
        height: 0px;
        visibility: hidden;
        float: right;
    }

    &__form-block{
        height: 267px;
        margin-left: 6%;
        margin-right: 6%;
        padding-top: 7%;
    }

    &__form-up-file{
        border: 1px dotted;
        width: 100%;
        height: 56px;
        display: block;
        overflow: hidden;
        position: relative;
        &--alert{
            margin-bottom:7px;
        }
    }

    &__name-img{
        margin-left: 10px;
        margin-top: 9px;
        margin-bottom: 0;
        line-height: 14px;
        &--ellipsis{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &__parent-name-box{
        overflow: hidden;
        width: 100%;
    }
    &__show-alert{
        border: 1px solid $red !important;
        background-color: $red-opacity-02 !important;
    }
    &__icon-close-modal {
        color: $snow-white;
        float: right;
        font-size: 20px;
        top: 4px;
        position: relative;
        &--custom{
            font-size: 25px;
            top: 0;
        }
        &:hover {
            cursor: pointer;
        }
    }
}
.compliance{
    &__steel-blue{
        .up-document{
            &__form-up-file{
                border: 1px solid $secondary-color-2;
                &:hover{
                    background-color: $third-color-2;
                    border: 1px solid $border-hover-2;
                }
            }
            &__name-img{
                color: $text-field-color-2;
            }
            &__icon-close-modal {
                color: $white-special-2;
            }
        }
    }
}