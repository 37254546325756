.customize-field-bool{
  &__associate-color{
      display: inline-block;
      font-family: MTTMilano-Bold, sans-serif;
      text-transform: uppercase;
      margin: 0px 12px 4px 48px;
      font-weight: bold;
      font-size: 12px;
      color: $primary-color;
  }
  &__block-button-inline{
      display: inline-block;

  }
  &__block-associate-color{
      position: relative;
      display: inline-block;
      bottom: 10px;
      left: 20px;
      &--disabled{
          opacity: 0.5;
      }
  }

  &__switch{
      top: 6px;
      position: relative;
      color: $primary-color;
      font-size: 21px;
      padding: 0;

      &:hover{
          cursor: pointer;
      }
      &[disabled]{
          &:hover{
              cursor: default;
          }
      }
  }

  &__field-text-weight{
      display: inline-block;
      width: calc(50% - 3px);
      &--right{
          float: right;
      }
  }
}

.compliance{
  &__gm-law{
      &__switch{
          color: $primary-color-gm;
      }
  }
  &__com-de{
    &__switch{
        color: $primary-color-com-de;
    }
}
&__com-fr{
  &__switch{
      color: $primary-color-com-fr;
  }
}
}
