@mixin attachments-template-documents-table($textColor, $borderColor, $backgroundColor) {
  color: $textColor;
  border-bottom-color: $borderColor;
  &:hover{
      background-color: $backgroundColor;
  }
}

@mixin attachments-template-documents-table-color() {
height: 48px;
padding-top: 2px;
}

@mixin attachments-template-documents-table-options($backgroundColor, $borderColor, $textColor) {
background-color: $backgroundColor;
border-color: $borderColor;
color: $textColor;
}

.attachments-template {
&__documents-table {
    position: relative;
    float: left;
    width: 100%;
    height: 42px;
    font-family: Arial, sans-serif;
    font-size: 12px;
    border-bottom: 1px solid;
    &:hover {
        .attachments-template__options-block {
            opacity: 1;
        }
    }
    &--col-6 {
        width: 60%;
        height: 20px;
        padding-top: 15px;
        padding-left: 10px;
        position: relative;
        float: left;

    }
    &--col-6-custom{
        color: $attachment-custom-grey;
        font-family: 'lato_regular', sans-serif;
        font-size: 13px;
        padding-top: 10px;
    }

    &--col-2 {
        width: 20%;
        position: relative;
        float: left;
        text-align: center;

        &--document {
            padding-top: 15px;
            display: inline-block;
        }
    }
}
&__documents-table-icon{
    font-size: 22px;
    position: relative;
    float: left;
    top: -6px;
    margin-right: 8px;
    &--custom{
        color: $attachment-custom-grey;
        top: -3px;
    }
}
&__documents-table-options {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    margin-right: 3px;
    border: 0.5px solid ;
    height: 42px;
    width: auto;
    // width: 76px;
    border-top-left-radius: 67px;
    border-bottom-left-radius: 67px;
    z-index: 1;
    margin-left: -78px;
    float: right;
    position: relative;
    &--historic{
        opacity: 0;
        // width: 50px;
    }
}

&__documents-block{
    width: 98%;
    height: 152px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 1%;
    border-top: 1px solid $primary-color;
    padding: 0;
    list-style: none;
    margin-bottom: 10px;
    margin-top: 57px;
    &--expand{
        height: 541px;
    }
}

&__menu-options {
    float: right;
    margin-right: 6px;
    font-size: 20px;
    margin-top: 8px;
    cursor: pointer;
    &--edit {
        margin-left: 7px;
    }
}

&__name-document {
    width: calc(100% - 30px);
}

&__options-block {
    opacity: 0;
    margin-top: 0px;
    margin-right: -3px;
    float: right;

    &:hover {
        opacity: 1;
    }
}
//////////////////////////////////////////////////////////////////////
////////////////////////Custom block/////////////////////////////////
////////////////////////////////////////////////////////////////////
&--custom{
    border-radius: 4px;
    background-color: $snow-white;
    box-shadow: 0 4px 20px 0 $attachment-custom-grey-opacity;
    min-height: 164px;
    width: 100%;
    display: block;
    padding: 9px 8px 2px;
    margin-bottom: 21px;
}

&__custom-header{
    height: 52px;
    width: 100%;
    border-radius: 4px;
    background-color: $background-grey;
    display: block;
}
&__custom-add-icon{
    color: $custom-black-landing;
    font-size: 23px;
    float: left;
    padding: 14px 10px 0 18px;
}
&__custom-header-title{
    color: $custom-black-landing;
    font-family: 'MTTMilano-Bold', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    padding-top: 17px;
    float: left;
}
&__custom-maximize-icon{
    margin-top: 17px;
    font-size: 18px;
    color: $custom-black-landing;
}
&__custom-upload-icon{
    top: 6px;
    color: $custom-black-landing;
    &--disabled{
        opacity: 0.5
    }
}
&__custom-lock-to-manager{
    display: flex;
    width: 419px;
    height: 42px;
    padding: 0px 24px 0px 8px;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    background: rgba(207, 119, 0, 0.15);
    margin: 8px 0;
}
&__custom-lock-to-manager-icon{
    font-size: 30px;
    color: #CF7700;
}
&__custom-lock-to-manager-text{
    color: #262626;
    font-family: "MTTMilano";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
}

&__bubble-count-custom{
    margin: 17px 7px 0 0;
}

&__custom-no-documents{
    font-family: 'lato_regular', sans-serif;
    color: $attachment-custom-grey-other;
    font-size: 15px;
    text-align: center;
    display: flex;
    height: 50px;
    padding: var(--spacing-md, 16px) var(--spacing-xl, 24px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    margin-top: 0;
    &--with-top{
        margin-top: 29px;
    }
}

&__custom-documents-item{
    height: 53px;
    padding-top: 6px;
    border-color: $custom-border-grey;
    position: relative;
    float: left;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 12px;
    border-bottom: 1px solid $custom-border-grey;

    &:hover {
        background-color: $attachment-custom-grey-other-2;
        color: $custom-black-landing;
        .attachments-template__options-block {
            opacity: 1;
        }
        .attachments-template__document-table-col-6-custom,  .attachments-template__document-table-col-2-custom{
            color: $custom-black-landing;
        }
    }
}

&__document-table-col-6-custom {
    width: 60%;
    height: 20px;
    padding-left: 10px;
    position: relative;
    float: left;
    color: $attachment-custom-grey;
    font-family: 'lato_regular', sans-serif;
    font-size: 13px;
    padding-top: 10px;
}

&__document-table-col-8-custom{
    width: 80%;
}

&__document-table-col-2-custom{
    width: 20%;
    position: relative;
    float: left;
    color: $attachment-custom-grey;
    text-align: center;
}

&__custon-document-info{
    padding-top: 12px;
    display: inline-block;
}

&__documents-table-options-custom{
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    color: $custom-black-landing;
    display: inline-block;
    background-color: $attachment-custom-grey-other-2;
    height: 42px;
    width: auto;
    z-index: 1;
    margin-right: 3px;
    margin-left: -78px;
    padding-left: 10px;
    float: right;
    position: relative;
}

&__menu-options-custom {
    float: right;
    margin-right: 6px;
    font-size: 22px;
    margin-top: 7px;
    cursor: pointer;

    &--edit {
        font-size: 21px;
    }

    &:last-child{
        margin-right: 12px;
    }
}

/////////////////////////////////////////
/////////////Fin Custom block////////////
/////////////////////////////////////////
}

.compliance{
&__dark-blue{
    .attachments-template {
        &__documents-table {
            @include attachments-template-documents-table($secondary-color, $bottom-border, $background-grey-other);
            height: 42px;
        }
        &__documents-table-icon{
            color: $primary-color;
        }
        &__documents-table-options {
            @include attachments-template-documents-table-options($background-grey-other, $menu-icons-border, $primary-color);
        }
        &__menu-options {
            color: $primary-color;
        }
        &__name-document {
            color: $primary-color;
        }
    }
}
&__steel-blue,
&__uk-steel-blue{
    .attachments-template {
        &__documents-table {
            @include attachments-template-documents-table($primary-color-2, $border-bottom-2, $block-hover-2);
            @include attachments-template-documents-table-color();
        }
        &__documents-table-icon{
            color: $border-hover-2;
        }
        &__documents-table-options {
            @include attachments-template-documents-table-options($secondary-color-2, $third-color-2, $block-hover-2);
        }
        &__menu-options {
            color: $border-hover-2;
        }
        &__name-document {
            color:$primary-color-2;
        }
    }
}
&__icam-red{
    .attachments-template {
        &__documents-table {
            @include attachments-template-documents-table($custom-black-landing, $border-block-icam, $hover-block-icam);
            @include attachments-template-documents-table-color();
        }
        &__documents-table-icon{
            color: $primary-color-icam;
        }
        &__documents-table-options {
            @include attachments-template-documents-table-options($primary-color-icam, $primary-color-icam, $snow-white);
        }
    }
}
&__gm-law{
    .attachments-template {
        &__documents-table {
            @include attachments-template-documents-table($custom-black-landing, $border-block-gm, $hover-block-gm);
            @include attachments-template-documents-table-color();
        }
        &__documents-table-icon{
            color: $primary-color-gm;
        }
        &__documents-table-options {
            @include attachments-template-documents-table-options($primary-color-gm, $primary-color-gm, $snow-white);
        }
    }
}
&__com-de{
  .attachments-template {
      &__documents-table {
          @include attachments-template-documents-table($custom-black-landing, $border-block-com-de, $hover-block-com-de);
          @include attachments-template-documents-table-color();
      }
      &__documents-table-icon{
          color: $primary-color-com-de;
      }
      &__documents-table-options {
          @include attachments-template-documents-table-options($primary-color-com-de, $primary-color-com-de, $snow-white);
      }
  }
}
&__com-fr{
  .attachments-template {
      &__documents-table {
          @include attachments-template-documents-table($custom-black-landing, $border-block-com-fr, $hover-block-com-fr);
          @include attachments-template-documents-table-color();
      }
      &__documents-table-icon{
          color: $primary-color-com-fr;
      }
      &__documents-table-options {
          @include attachments-template-documents-table-options($primary-color-com-fr, $primary-color-com-fr, $snow-white);
      }
  }
}
&__landing-custom{
    .attachments-template{
        &__documents-block{
            margin-top: 30px;
            //margin-bottom: 0;
            height: 162px;
            border-top: 3px solid $border-modal;
            &--expand{
                height: 541px;
            }
        }
    }
}
}

@media (max-width: $max-width-smartphone) { //Smartphone
.attachments-template {
    &__custom-header {
        display: inline-flex;
        height: auto;
    }

    &__custom-header-title {
        width: 100%;
    }

    &__custom-documents-list {
        height: auto;
    }
}
}
