.exit-login{
  height: 100%;
  width: 100%;
  position: relative;
  float: left;
  &__container{
      min-height: calc(100% - 260px);
      &--public{
          position: relative;
          float: left;
          width: 100%;
          min-height: calc(100% - 150px);
      }
  }
  &__info {
      font-size: 15px;
      font-family: MTTMilano, sans-serif;
      text-align: center;
      width: 100%;
      padding: 0;
  }
  &__calendar{
      font-size: 170px;
      color: $primary-color;
      padding-left: 140px;
      margin-top: 18px;
      margin-bottom: 19px;
      width: 100%;
  }
  &__invitation-block-img{
      display: block;
      height: 50px;
      margin: 0 auto;
  }
  &__title-invitation{
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-weight: 600;
      font-size: 15px;
      margin: 0 0 4px;
      &--public{
          font-size: 21px;
      }
      &--custom{
          color: $custom-black-landing;
          font-family: MTTMilano , sans-serif;
          font-size: 15px;
          font-weight: 500;
          text-align: center;
          padding-top: 5px;
          padding-bottom: 13px;
      }
  }
  &__information-contact{
      color:  $concept-blue;
      font-family: MTTMilano, sans-serif;
      font-weight: 500;
      font-size: 16px;
      &--icon-text{
          margin-top: 17px;
          padding: 0px 50px;
          text-align: center;
          width: 100%;
      }
      &--custom{
          color: $custom-black-landing;
          text-align: center;
          padding-top: 30px;
      }
      &--custom-meeting{
          margin-bottom: 46px;
      }
      &--custom-expired-poll{
          padding: 0;
          margin: 0;
          height: 21px;
          width: 100%;
          margin-top: 14px;
      }
  }
  &__text-information{
      margin: 0;
      line-height: 18px;
      &--second{
          margin-bottom: 10px;
          line-height: 21px;
          min-height: 54px;
      }
  }

  &__button-copy{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      outline: 0;
      border: 3px solid $custom-black-landing;
      height: 38px;
      width: auto;
      max-width: 415px;
      border-radius: 4px;
      box-shadow: 0 1px 1px 0 $modal-help-box;
      padding: 7px 30px 9px;
      &:hover{
          background-color: $custom-secondary-color !important;
          color: $snow-white !important;
          border-color: $custom-secondary-color !important;
      }
      &:focus{
          color: $snow-white;
      }
  }

  &__button-share{
      outline: none;
      border: none;
      background: transparent;
      font-size: 20px;
      display: block;
      position: absolute;
      right: 7px;
      bottom: 25px;
      color: $custom-black-landing;
      &--user-mail{
          bottom: 47px;
      }
  }

  &__button-send-custom{
      outline: none;
      border: none;
      background: transparent;
      font-size: 20px;
      display: block;
      position: absolute;
      right: 36px;
      bottom: 25px;
      color: $custom-black-landing;
      &--user-mail{
          bottom: 47px;
      }
  }

  &__email-item-block{
      margin-top: 5px;
      margin-left: 5px;
      &--others{
          margin-top: 10px;
      }
  }
  &__email-item{
      padding: 4px 10px 6px;
      border: 1px solid $front-login-input;
      border-radius: 14px;
      color: $modal-info-hours;
      font-family: 'MTTMilano' , sans-serif;
      font-size: 15px;
      font-weight: 500;
      &:hover{
          border: 1px solid $primary-color;
          cursor: pointer;
      }
  }
  &__email-item-icon{
      position: relative;
      font-size: 20px;
      top: 5px;
      padding-left: 5px;
      &:hover{
          color: $primary-color;
      }

  }

  &__custom-container-block{
      display: block;
      width: 100%;
  }

  &__custom-share-block{
      width: 100%;
      display: block;
      position: relative;
      min-height: 469px;
      background-color: $snow-white;
  }

  &__custom-invitation-block{
      min-height: 434px;
      width: 460px;
      border-radius: 4px;
      background-color: $snow-white;
      box-shadow: 0 4px 20px 0 $attachment-custom-grey-opacity;
      margin: 203px auto 30px;
      display: block;
      padding: 25px 10px 10px;
      &--share{
          min-height: 504px;
      }
      &--meeting{
          min-height: auto;
      }
  }

  &__custom-block-no-share{
      position: relative;
      width: 100%;
      height: 100%;
  }

  &__custom-share-content{
      padding: 0 35px;
      width: 100%;
      margin-bottom: 10px;
  }

  &__custom-share-return-button{
      border: none;
      background-color: transparent;
      outline: none;
      color: $custom-black-landing;
      font-family: MTTMilano, sans-serif;
      font-size: 16px;
      text-transform: lowercase;
      padding: 0;
      position: absolute;
      left: 0;
      top: -10px;
  }

  &__custom-share-return-icon{
      position: relative;
      font-size: 19px;
      outline: none;
  }

  &__custom-share-return-text{
      position: relative;
      bottom: 3px;
  }

  &__custom-share-title{
      color: $custom-black-landing;
      font-family: MTTMilano, sans-serif;
      font-size: 24px;
      font-weight: normal;
      text-align: center;
      margin-top: 48px;
      margin-bottom: 12px;
      &--without-img{
          margin-top: 98px;
      }
  }

  &__custom-share-text{
      color: $custom-black-landing;
      font-family: MTTMilano, sans-serif;
      font-size: 16px;
      font-weight: normal;
      text-align: center;
      min-height: 60px;
      margin-bottom: 6px;
      line-height: 18px;
      &--user-mail{
          min-height: 65px;
      }
  }
  &__custom-result-invitation{
      margin-top: 56px;
      width: 100%;
      display: block;
      text-align: center;
      &--bottom-64{
          margin-bottom: 64px;
      }
      &--shared{
          margin-top: 85px;
          margin-bottom: 34px;
      }
  }
  &__custom-result-icon{
      font-size: 69px;
      color: $custom-black-landing;
  }
  &__custom-information-block{
      min-height: 111px;
      width: 100%;
      border-radius: 8px;
      background-color: $background-grey;
      text-align: center;
      bottom: 0;
      display: block;
      overflow: hidden;
      &--public{
          padding: 12px 26px 14px;
      }
      &--shared{
          padding: 34px 26px 26px;
          & .exit-login__title-invitation{
              font-size: 21px;
              color: $custom-black-landing;
              padding-top: 7px;
              width: 70%;
              margin: 0 auto;
          }
      }
      &--meeting{
          padding: 10px 26px 26px;
      }
      &--user-mail{
          min-height: 157px;
          padding-top: 18px;
      }
  }

  &__custom-share-emails-block {
      display: block;
      width: calc(100% - 30px);
      height: 82px;
      margin: 0 15px;
      box-shadow: 0 1px 4px 0 $modal-help-box;
      border: 1px solid $custom-border-grey;
  }

  &__custom-share-emails-icon {
      width: 48px;
      background-color: $custom-border-grey;
      padding-top: 22px;
      text-align: center;
      font-size: 29px;
      color: $custom-black-landing;
      float: left;
      height: 80px;
  }

  &__custom-share-emails-content{
      float: left;
      width: calc(100% - 48px);
      background-color: $snow-white;
      height: 80px;
      overflow-y: auto;
  }

  &__custom-share-emails-textarea{
      width: 100%;
      height: 73px;
      border: none;
      resize: none;
      padding-top: 11px;
      outline: none;
      padding-left: 10px;
      color: $custom-secondary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      &--one-item{
          margin-top: 5px;
          height: 38px;
      }
      &::-webkit-input-placeholder{
          font-style: normal;
          padding: 0px;
      }
  }

  &__custom-share-info-text{
      margin: 20px 20px 0;
      color: $custom-black-landing;
      font-family: MTTMilano, sans-serif;
      font-size: 13px;
      text-align: center;
      line-height: 17px;
  }

  &__poll-expired-info-custom{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      color: $custom-black-landing;
      margin-top: 16px;
      line-height: 28px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-top: 26px;
  }

  &__footer-title-custom{
      width: 100%;
      float: left;
      text-align: center;
  }

  &__footer-text-custom{
      font-size: 24px;
      color: $custom-black-landing;
      font-family: MTTMilano-Bold, sans-serif;
      margin-bottom: 3px;
      margin-top: -14px;
  }
}

@media (max-width: $max-width-smartphone) {
  .exit-login {
      &__custom-invitation-block {
          width: auto;
          margin: 0;
      }

      &__button-copy {
          &--custom {
              height: auto;
          }
      }
  }
}
