.attach-document-modal{
    &__attach-error{
        color: $red;
    }
  
    &__body{
        padding: 25px 70px 25px 70px;
        position: relative;
        height: 528px;
    }
  
    &__block-origin{
        width: 49.75%;
        float: left;
        margin-right: 0.5%;
        &--right{
            width: 49.75%;
            float: left;
        }
    }
  
    &__block-create-document{
        width: 100%;
        float: left;
    }
  
  
  }
  