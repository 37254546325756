.video-player{
    &__breadcrumb-block{
        padding-top: 30px;
        padding-right: 3px;
        margin-left: 85%;
    }

    &__description-block{
        display: block;
        overflow: hidden;
        width: 100%;
        padding-top: 23px;
    }

    &__iframe{
        width: 85%;
        height: 460px;
    }

    &__modal{
        & .modal-dialog{
            & .modal-content{
                min-height: 603px;
                height:auto;
                border-radius: 0px;
            }
        }
    }

    &__video-container{
        text-align: center;
        margin-top: 5%;
    }

    &__wrapper{
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;
        & iframe {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 90%;
            height: 100%;
            border: 0;
            margin-left: 5%;
        }
    }
}