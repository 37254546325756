.concept-list {
  &__table {
      margin-top: 12px;
  }

  &__cell-block {
      position: relative;
      height: 17px;

      &--subcontainer-multiple {
          width: calc(100% - 118px);
          float: left;
          display: block;
      }

      &--subcontainer-multiple-expand {
          width: calc(100% - 284px);
      }
  }

  &__table-col {
      display: table-cell;
      vertical-align: middle;
      max-width: 0;

      &--top-1 {
          position: relative;
          top: 1px;
      }

      &--top-2 {
          position: relative;
          top: 2px;
      }

      &--surname-collegiate-icam {
          width: 19%;
          padding-left: 4px;
      }

      &--name-collegiate-icam {
          width: 13%;
      }

      &--email-collegiate-icam {
          width: 19%;
      }

      &--user-collegiate-icam {
          width: 10%;
      }

      &--state-collegiate-icam {
          width: 10%;
      }

      &--registration-collegiate-icam {
          width: 12%;
      }

      &--header-title {
          width: 22%;
          padding-top: 1px;
          padding-left: 4px;
      }

      &--title {
          width: 22%;
          padding-left: 4px;
          padding-right: 5px;
      }

      &--confidential {
          width: 40px;
          min-width: 40px;
          text-align: center;
      }

      &--extension {
          width: 75px;
          min-width: 75px;
          padding-right: 0px;
      }

      &--author {
          width: 19%;
          padding-right: 5px;
      }

      &--header-creation-date {
          width: 13%;
          padding-top: 2px;
      }

      &--creation-date {
          width: 13%;
          padding-right: 5px;
      }

      &--state {
          width: calc(35% - 210px);
          min-width: 60px;
          padding-right: 5px;
      }

      &--state-uk {
          width: calc(35% - 120px);
          min-width: 60px;
          padding-right: 5px;
      }

      &--state-global-report {
          width: calc(35% - 195px);
          min-width: 60px;
          padding-right: 5px;
      }

      &--header-state {
          width: calc(35% - 210px);
          min-width: 60px;
          margin-top: 0;
      }

      &--header-state-uk {
          width: calc(35% - 120px);
          min-width: 60px;
          margin-top: 0;
      }

      &--header-reference {
          width: 90px;
          min-width: 90px;
          padding-top: 2px;
      }

      &--reference {
          width: 90px;
          min-width: 90px;
      }

      &--actions {
          width: 80px;
          min-width: 80px;
          position: relative;
      }

      &--actions-header {
          width: 80px;
          min-width: 80px;
          padding-right: 4px;
      }
      //query-table
      &--reference-query {
          width: 10%;
          padding-right: 4px;
          padding-left: 5px;
      }

      &--type-query {
          width: 6%;
          padding-right: 4px;
      }

      &--entry-query {
          width: 110px;
          min-width: 110px;
          padding-right: 4px;
      }

      &--state-query {
          width: 11%;
          padding-right: 4px;
      }

      &--manager-query {
          width: 11%;
          padding-right: 4px;
      }

      &--user-query {
          width: 8%;
          padding-right: 4px;
      }

      &--name-query {
          width: calc(35% - 427px);
          padding-right: 4px;
      }

      &--matter-query {
          width: 9%;
          padding-right: 4px;
      }

      &--modification-query {
          width: 110px;
          min-width: 110px;
          padding-right: 4px;
      }

      &--priority-query {
          width: 80px;
          min-width: 80px;
      }

      &--alerts-query {
          width: 80px;
          min-width: 80px;
      }

      &--actions-query {
          width: 57px;
          min-width: 57px;
          position: relative;
      }
      //digitization-table
      &--title-digitization {
          width: calc(40% - 97px);
          padding-left: 5px;
      }

      &--reference-digitization {
          width: 21%;
          padding-right: 4px;
      }

      &--pages-digitization {
          width: 21%;
      }

      &--date-digitization {
          width: 18%;
      }
      //publication-table
      &--type-publication {
          width: 65px;
          min-width: 65px;
          padding-left: 5px;
      }

      &--title-publication {
          width: calc(49% - 407px);
      }

      &--isbn-signature-publication {
          width: 12%;
          padding-right: 4px;
      }

      &--catalog-publication {
          width: 17%;
          padding-right: 4px;
      }

      &--author-publication {
          width: 13%;
          padding-right: 4px;
      }

      &--year-publication {
          width: 115px;
          min-width: 115px;
          padding-right: 4px;
      }

      &--date-publication {
          width: 130px;
          min-width: 130px;
      }

      &--percentage-publication {
          width: 9%;
      }
      //poll-table{
      &--title-poll {
          width: calc(36% - 120px);
          padding-right: 4px;
          padding-left: 4px;
      }

      &--reference-poll {
          width: 12%;
          padding-right: 4px;
      }

      &--colegiado-poll {
          width: 14%;
          padding-right: 4px;
      }
  }

  &__reference-title{
      width: calc(100% - 32px);
      position: relative;
      float: left;
  }

  &__tooltip-reference{
      & .concept-list__subtitle-tooltip{
          margin: 0;
          font-family: MTTMilano-Bold, sans-serif;
      }
      & .concept-list__priority-block-tooltip{
          margin-top: 10px;
      }
  }

  &__reference-tooltip{
    font-weight: normal;
  }

  &__pagination-block {
      text-align: center;
      height: 22px;
      display: block;
      float: left;
      width: 100%;

      &--visibility {
          margin-top: 23px;
          margin-bottom: 20px;
      }
  }

  &__table-foot {
      text-align: center;
      padding-top: 21px;
      display: table;
      width: 100%;
      position: relative;
      z-index: 1;

      &--no-items {
          top: 32px;
      }

      &--has-archived {
          top: 0px;
      }
  }

  &__table-config {
      background-color: $snow-white;
      width: 100%;
      border: 1px solid $subcontainer-border;
      border-top: none;
      padding: 10px;

      &--report-height {
          height: 98px;
      }
  }

  &__icon-stage {
      margin-left: 2px;
  }

  &__icon-list {
      margin-right: 10px;
      color: $primary-color;
  }

  &__block-info-archive {
      position: relative;

      &--absolute {
          position: absolute;
          float: left;
          top: 18px;
      }
  }

  &__archive-button {
      float: left;
  }

  &__archived-evaluated-amount {
      opacity: 0.7;
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      font-weight: normal;
      float: left;
      margin: 4px 10px 0;
  }

  &__box-select-list {
    border-radius: 0px;
    border: none;
    padding: 0;
    overflow: hidden;
    position: absolute;
    margin: 0;
    width: 168px;
    max-height: 194px;
    inset: auto !important;
    top: 6px !important;
    left: unset !important;
    right: 34px !important;
    transform: none !important;
  }

  &__icon-confidential {
      color: $primary-color;
      font-size: 20px;
      position: relative;

      &:hover {
          cursor: pointer;
      }
  }

  &__body-state {
      padding-top: 1px;
  }

  &__calendar-dropdown {
      top: 35px;
      height: 270px;
      width: 371px;
      padding: 0;
      z-index: 2;
      &--modification {
          left: -90px;
      }
  }

  &__priority-mark {
      position: absolute;
      left: 59px;
      width: 10px;
      height: 45px;
      background-color: $orange-task-icam;
  }

  &__concept-state {
      padding: 4px 9px 2px;
      border-radius: 15px;
      margin: 0;
      width: auto;
      position: relative;
      float: left;
      color: $custom-black-landing;
      background-color: $background-grey-disabled-icam;
      font-family: Arial, sans-serif;
      font-size: 12px;

      &--activo {
          background-color: $green-task-36-icam;
      }

      &--baja {
          background-color: $red-task-36-icam;
      }

      &--inactivo {
          background-color: $orange-task-36-icam;
      }
  }
  ///////////////////////
  ///Informes globales///
  ///////////////////////
  &__table-icon {
      color: $primary-color;
      font-size: 22px;
      position: relative;
      // left: 22px;
      top: 1px;
  }

  &__center-text {
      text-align: center;
  }

  &__options-row {
      z-index: inherit;
  }

  &__icon-certified {
      color: $primary-color;
      position: relative;
      font-size: 22px;
      top: 3px;
  }

  &__ellipsis-disabled {
      opacity: 0.4;
  }

  &__has-archived {
      color: $secondary-color;
      text-align: center;
      width: 471px;
      margin: 0 auto;
      line-height: 14px;
      font-size: 14px;
      font-family: MTTMilano, sans-serif;
  }
  &__anonymized-concept-icon{
    font-size: 17px;
    margin-top: 3px;
    margin-right: 10px;
  }
  &__row-anonimyzed{
      background-color: $background-grey;
  }
  /////////////////////////
  //Fin Informes globales//
  /////////////////////////
}

.compliance{
  &__dark-blue{
    .concept-list {
      &__table-config {
        border-color: $subcontainer-border;
        &--archived {
          border-color: $subcontainer-border;
        }
      }
      &__icon-certified, &__table-icon, &__icon-confidential, &__archived-evaluated-amount {
        color: $primary-color;
      }
      &__calendar-dropdown {
        height: 290px;
      }
    }
  }
  &__steel-blue{
    .concept-list {
      &__table-config {
        border-color: $secondary-color-2;
        &--archived{
          border-color: $third-color-2;
        }
      }
      &__archived-evaluated-amount {
        color: $grey-icons-2;
      }
      &__icon-certified, &__table-icon, &__icon-confidential {
        color: $border-hover-2;
      }

    }
  }
  &__uk-steel-blue{
    .concept-list {
      &__table-config {
        border: none;
      }
      &__archived-evaluated-amount {
        color: $grey-icons-2;
      }
      &__icon-certified, &__table-icon, &__icon-confidential {
        color: $border-hover-2;
      }

    }
  }
  &__icam-red{
    .concept-list {
      &__table-config {
        border: none;
      }
      &__archived-evaluated-amount {
        color: $secondary-color-icam;
      }
      &__icon-certified, &__table-icon, &__icon-confidential {
        color: $primary-color-icam;
      }
      &__calendar-dropdown {
        height: 290px;
      }
    }
  }
  &__gm-law{
    .concept-list {
      &__table-config {
        border: none;
      }
      &__archived-evaluated-amount {
        color: $secondary-color-gm;
      }
      &__icon-certified, &__table-icon, &__icon-confidential {
        color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    .concept-list {
      &__table-config {
        border: none;
      }
      &__archived-evaluated-amount {
        color: $secondary-color-com-de;
      }
      &__icon-certified, &__table-icon, &__icon-confidential {
        color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .concept-list {
      &__table-config {
        border: none;
      }
      &__archived-evaluated-amount {
        color: $secondary-color-com-fr;
      }
      &__icon-certified, &__table-icon, &__icon-confidential {
        color: $primary-color-com-fr;
      }
    }
  }
}


@media (max-width: 1640px){
  .concept-list{
      &__table-col {
           //query-table
          &--reference-query{
              width: 7%;
          }
          &--type-query{
              width: 7%;
          }
          &--entry-query{
              width: 100px;
              min-width: 100px;
          }
          &--state-query{
              width: 9%;
          }
          &--manager-query{
              width: 9%;
          }
          &--user-query{
              width: 8%;
          }
          &--name-query{
              width: calc(42% - 417px);
          }
          &--matter-query{
              width: 8%;
          }
      }
  }
}

@media (max-width: 1503px){
  .concept-list{
      &__table-col {
          //publication-table
          &--type-publication{
              width: 60px;
              min-width: 60px;
          }
          &--title-publication{
              width: calc(53% - 392px);
          }
          &--isbn-signature-publication{
              width: 11%;
          }
          &--catalog-publication{
              width: 15%;
          }
          &--author-publication{
              width: 12%;
          }
          &--percentage-publication{
              width: 9%;
          }
      }
  }
}

@media (max-width: 1440px){
  .concept-list{
      &__table-col {
           //query-table
          &--entry-query{
              width: 85px;
              min-width: 85px;
          }
          &--state-query{
              width: 8%;
          }
          &--manager-query{
              width: 8%;
          }
          &--name-query{
              width: calc(44% - 402px);
          }
      }
  }
}

@media (max-width: 1424px){
  .concept-list{
      &__table-col {
          //publication-table
          &--title-publication{
              width: calc(57% - 392px);
          }
          &--isbn-signature-publication{
              width: 10%;
          }
          &--catalog-publication{
              width: 14%;
          }
          &--author-publication{
              width: 11%;
          }
          &--percentage-publication{
              width: 8%;
          }
      }
  }
}

@media (max-width: 1366px){
  .concept-list{
      &__table-col {
           //query-table
          &--entry-query{
              width: 100px;
              min-width: 100px;
          }
          &--state-query{
              width: 9%;
          }
          &--manager-query{
              width: 9%;
          }
          &--name-query{
              width: calc(42% - 417px);
          }
      }
  }
}

@media (max-width: 1100px){
  .concept-list{
      &__table-col {
           //query-table
          &--entry-query{
              width: 85px;
              min-width: 85px;
          }
          &--state-query{
              width: 8%;
          }
          &--manager-query{
              width: 8%;
          }
          &--user-query{
              width: 7%;
          }
          &--name-query{
              width: calc(46% - 402px);
          }
          &--matter-query{
              width: 7%;
          }
      }
  }
}

@media (max-width: 1016px){
  .concept-list{
      &__table-col {
          //publication-table
          &--type-publication{
              width: 55px;
              min-width: 55px;
          }
          &--title-publication{
              width: calc(61% - 372px);
          }
          &--isbn-signature-publication{
              width: 9%;
          }
          &--catalog-publication{
              width: 13%;
          }
          &--author-publication{
              width: 10%;
          }
          &--year-publication{
              width: 108px;
              min-width: 108px;
          }
          &--date-publication{
              width: 112px;
              min-width: 112px;
          }
          &--percentage-publication{
              width: 7%;
          }
      }
  }
}

@media (max-width: 900px){
  .concept-list{
      &__block-info-archive{
          &--absolute{
              position: relative;
          }
      }
  }
}

