@mixin product-color(){
  &--fiscal {
      background-color: $fiscal;
      &:hover {
          cursor: pointer;
          background-color: $result-search-quote-color;
          & .dashboard-panel__product-block-selected {
            background-color: $result-search-quote-color;
          }
      }
  }

  &--administrativo {
      background-color: $administrativo;
      &:hover {
          cursor: pointer;
          background-color: $result-search-quote-color;
          & .dashboard-panel__product-block-selected {
              background-color: $result-search-quote-color;
          }
      }
  }

  &--penal {
      background-color: $penal;
      &:hover {
          cursor: pointer;
          background-color: $result-search-quote-color;
          & .dashboard-panel__product-block-selected {
              background-color: $result-search-quote-color;
          }
      }
  }

  &--mercantil {
      background-color: $mercantil;
      &:hover {
          cursor: pointer;
          background-color: $result-search-quote-color;
          & .dashboard-panel__product-block-selected {
              background-color: $result-search-quote-color;
          }
      }
  }

  &--igualdad {
      background-color: $igualdad;
      &:hover {
          cursor: pointer;
          background-color: $result-search-quote-color;

          & .dashboard-panel__product-block-selected {
              background-color: $result-search-quote-color;
          }
      }
  }

  &--n-a {
      background-color: $na;
      &:hover {
          cursor: pointer;
          background-color: $result-search-quote-color;

          & .dashboard-panel__product-block-selected {
              background-color: $result-search-quote-color;
          }
      }
  }
}


@mixin dashboard-panel-button-header($firstColor, $secondColor, $thirdColor, $fourthColor){
  border: none;
  outline: none;
  height: 36px;
  background-color: $firstColor;
  color: $secondColor;
  font-family: MTTMilano-Bold, sans-serif;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  padding: 0;
  &:hover:not([disabled]){
      background-color: $thirdColor;
      color: $fourthColor;
  }
  &:disabled{
      opacity: 0.5;
  }
}

.dashboard-panel {
  display: block;
  width: 100%;
  height: 100%;
  // overflow: hidden;
  // overflow-y: auto;

  &__container {
      display: block;
      width: 100%;
      margin-bottom: 60px;

      &--no-margin {
          margin-bottom: 0;
      }
  }

  &__task-list {
      display: none;

      &--show {
          display: block;
      }
  }

  &__legal-news-button {
      float: right;
      position: relative;
      right: -10px;
      background: none;
      height: 34px;
      border: 2px solid $primary-color;
      width: 260px;
      color: $primary-color;

      &:hover {
          color: $snow-white;
          background-color: $primary-color;

          & .dashboard-panel__legal-news-pillow {
              color: $primary-color;
              background-color: $snow-white;
          }
      }
  }

  &__legal-news-icon {
      font-size: 26px;
      float: left;
      position: relative;
      left: 3px;
      top: 2px;
  }

  &__legal-news-text {
      font-family: MTTMilano-bold, sans-serif;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 1px;
      float: left;
      padding-left: 11px;
      padding-top: 6px;
  }

  &__legal-news-pillow {
      top: 3px;
      background: $primary-color;
      color: $snow-white;
  }
  //////////////////////
  /////New Version///////
  /////////////////////
  &__container-block-v2 {
      width: 100%;
      position: relative;
      display: table;
      margin-bottom: 1px;
      min-height: calc(100% - 225px);
  }

  &__container-menu-left-v2 {
      display: table-cell;
      width: 100px;
      padding: 50px 0;
      vertical-align: top;
  }

  &__button-clients-v2 {
      text-align: center;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      margin-bottom: 20px;
      text-transform: uppercase;

      &:hover {
          cursor: pointer;
      }
  }

  &__product-block-v2 {
      height: 60px;
      width: 60px;
      margin: 0 auto;
      text-align: center;
      text-transform: uppercase;
      margin-top: 40px;
      font-family: MTTMilano, sans-serif;
      font-size: 22px;
      padding-top: 16px;
      position: relative;
      color: $snow-white;
      &--selected{
        font-size: 28px;
        padding-top: 12px;
        font-family: MTTMilano-Bold, sans-serif;
        &:hover {
          cursor: default;
        }
      }
  }

  &__product-block-selected {
      height: 60px;
      width: 10px;
      position: absolute;
      left: -20px;
      top: 0px;
      &--fiscal{
          background-color: $fiscal;
      }
      &--administrativo{
          background-color: $administrativo;
      }
      &--penal{
          background-color: $penal;
      }
      &--mercantil{
          background-color: $mercantil;
      }
      &--igualdad{
          background-color: $igualdad;
      }
      &--n-a{
          background-color: $na;
      }
  }

  &__icon-clients-v2 {
      font-size: 50px;
      display: block;
  }

  &__container-new-version {
      display: table-cell;
      width: calc(100% - 100px);
      margin-bottom: 0;
      padding: 0 10px;

  }

  &__principal-block-new-version {
      max-width: 1400px;
      margin: 40px auto 0px;
      display: block;
  }

  &__block-header-title-product {
      position: relative;
      float: left;
      width: 100%;
      height: 36px;
      border-bottom: 1px solid;
      margin-bottom: 20px;
  }

  &__block-title-product {
      position: relative;
      float: left;
      width: calc(100% - 532px);
  }

  &__text-title-product {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 18px;
      position: relative;
      top: 5px;
  }

  &__block-graph-reports-v2 {
      position: relative;
      float: left;
      // display: inline-block;
      width: 100%;
      height: 555px;
      border: 1px solid;
      margin-bottom: 50px;
      &--folder {
          float: none;
          height: auto;
      }
  }

  &__block-evaluations-and-instances-list-v2 {
      position: relative;
      float: left;
      width: calc(50% - 10px);
  }

  &__block-tasks-list-v2,
  &__evaluations-list,
  &__instances-list {
      position: relative;
      float: left;
      width: 100%;
      border: 1px solid;
  }

  &__block-tasks-list-v2 {
    height: 400px;
  }

  &__evaluations-list{
    height: 820px;
    &--no-templates {
        height: 725px;
    }
  }

  &__instances-list {
    height: 675px;
    &--no-templates {
      margin-top: 20px;
      height: 75px;
    }
  }

  &__block-tasks-list-v2 {
      margin-top: 20px;
      margin-bottom: 100px;
      &--denunc{
          margin-bottom: 10px;
      }
  }

  &__schedule-list-new-version {
      position: relative;
      float: left;
      // display: inline-block;
      height: 820px;
      margin-left: 20px;
      width: calc(50% - 10px);
      border: 1px solid;
  }

  &__block-right-reports-schedule {
      position: relative;
      float: left;
      // display: inline-block;
      height: 820px;
      margin-left: 20px;
      width: calc(50% - 10px);
  }

  &__text-organizations-v2 {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 100px;
  }
  //////////////////////
  ////Fin New Version/////
  /////////////////////

  //////////////////
  ////Version 3////
  ////////////////
  &__container-block-v3{
      width: 100%;
      position: relative;
      float: left;
      min-height: calc(100% - 225px);
      padding: 30px 10px 100px;
  }
  &__container-v3-buttons{
      width: 100%;
      max-width: 1400px;
      margin: 0px auto;
      margin-bottom: 50px;
  }
  &__container-v3{
      width: 100%;
      max-width: 1400px;
      margin: 0px auto;
      border-top: 1px solid $third-color-2;
  }

  &__block-50-percent-v3{
      margin-top: 22px;
      position: relative;
      float: left;
      height: 535px;
      width: calc(50% - 10px);
      box-shadow: $box-shadow-v3;
      background-color: $snow-white;
      &--right{
          // margin-left: 20px;
          float: right;
      }
  }
  &__block-100-percent-v3{
      position: relative;
      float: left;
      height: 400px;
      width: 100%;
      box-shadow: $box-shadow-v3;
      margin-top: 25px;
      background-color: $snow-white;
  }
  ////////////////////
  ///Fin Version 3///
  //////////////////

  //////////////////
  ///Version ICAM///
  //////////////////
  &__block-right-header{
      float: right;
      width: auto;
      bottom: 1px;
      position: relative;
  }
  &__button-show-documentation{
      width: 282px;
      float: left;
  }
  &__button-access-evaluation{
      @include dashboard-panel-button-header($primary-color-icam, $snow-white, $hover-block-icam, $primary-color-icam);
      height: 40px;
      padding: 6px 0 10px 15px;
      width: 132px;
      float: right;
  }
  &__icon-button-header{
      font-size: 20px;
      position: relative;
      top: 4px;
      margin-left: 5px;
  }
  ////////////////////
  //Fin Version ICAM//
  ////////////////////
  &__reload-button{
    float: right;
    background: none;
    border: none;
    color: $primary-color-icam;
    font-size: 20px;
    padding: 0;
    display: block;
    margin: 7px 10px 0 0;
  }

  ////////////////////////////////////////
  ///////////////Angular 2///////////////
  //////////////////////////////////////

}
.compliance{
&__dark-blue{
  .dashboard-panel{
      &__container-menu-left-v2 {
          &--color {
              background-color: $background-grey-other;
              box-shadow: -4px 13px 10px 6px $bar-horizontal-grey-opacity05;
          }
      }
      &__button-clients-v2{
          &--color {
              color: $pillow-group;
          }
      }
      &__icon-clients-v2 {
          padding-bottom: 8px;
      }
      &__product-block-v2{
          @include product-color();
      }
      &__block-header-title-product {
          border-color: $result-search-quote-color;
      }
      &__button-show-documentation{
          @include dashboard-panel-button-header($subcontainer-ground, $snow-white, $background-color-states, $primary-color);
          height: 36px;
          padding: 0;
      }
      &__block-graph-reports-v2,
      &__evaluations-list,
      &__instances-list,
      &__block-tasks-list-v2,
      &__schedule-list-new-version {
          border-color: $result-search-quote-color;
      }
      &__text-title-product {
        color: $primary-color;
      }

  }
}

&__steel-blue{
    .dashboard-panel{
        &__container-menu-left-v2 {
            &--color {
                background-color: $primary-color-2;
            }
        }
        &__button-clients-v2{
            &--color {
                color: $secondary-color-2;
            }
        }
        &__product-block-v2 {
            background-color: $background-highlight-text-2;
            color: $border-bottom-2;
            &:hover {
                cursor: pointer;
            }
            &--selected {
                background-color: $border-bottom-2;
                color: $white-special-2;
            }
        }
        &__product-block-selected {
            background-color: $border-bottom-2;
        }
        &__block-header-title-product {
            border-color: $third-color-2;
        }
        &__button-show-documentation{
            @include dashboard-panel-button-header($grey-icons-2, $white-special-2, $block-hover-2, $primary-color-2);
            width: auto;
            padding: 0 14px;
        }
        &__block-graph-reports-v2,
        &__evaluations-list,
        &__instances-list,
        &__block-tasks-list-v2,
        &__schedule-list-new-version {
            border-color: $background-highlight-text-2;
        }
        &__text-title-product {
          color: $border-bottom-2;
      }
      }
  }


&__uk-steel-blue{
    .dashboard-panel{
        &__button-show-documentation{
            @include dashboard-panel-button-header($grey-icons-2, $white-special-2, $block-hover-2, $primary-color-2);
            height: 40px;
            width: auto;
            padding: 0 14px;
            float: right;
            margin-top: 10px;
            &:hover:not([disabled]){
                background-color: $grey-icons-2;
                color: $white-special-2;
            }
        }
    }
}

&__icam-red{
    .dashboard-panel{
        &__container-new-version {
            width: 100%;
        }
        &__block-header-title-product {
            border-color: $third-color-icam;
            height: 40px;
        }
        &__block-title-product{
          width: calc(100% - 444px);
        }
        &__button-show-documentation{
            @include dashboard-panel-button-header($secondary-color-icam, $snow-white, $hover-block-icam, $primary-color-icam);
            height: 40px;
            padding: 6px 0 10px 15px;
        }
        &__block-graph-reports-v2{
            border: none;
            box-shadow: $box-shadow-icam;
        }
        &__evaluations-list,
        &__instances-list,
        &__block-tasks-list-v2,
        &__schedule-list-new-version {
            border: none;
            background-color: $snow-white;
            box-shadow: $box-shadow-icam;
        }
        &__text-title-product{
          color: $title-text-icam;
        }
    }
}

&__gm-law{
    .dashboard-panel{
        &__container-menu-left-v2 {
            &--color {
                background-color: $background-grey-disabled-gm;
                box-shadow: -4px 13px 10px 6px $box-shadow-gm-color;
            }
        }
        &__icon-clients-v2 {
            color: $secondary-color-gm;
        }
        &__product-block-v2{
            @include product-color();
        }
        &__container-new-version {
            width: 100%;
        }
        &__block-header-title-product {
            border-color: $third-color-gm;
        }
        &__button-show-documentation{
            @include dashboard-panel-button-header($secondary-color-gm, $snow-white, $hover-block-gm, $primary-color-gm);
            height: 36px;
            padding: 6px 0 10px 15px;
        }
        &__block-graph-reports-v2{
            border: none;
            box-shadow: $box-shadow-gm;
        }
        &__evaluations-list,
        &__instances-list,
        &__block-tasks-list-v2{
            border: none;
            background-color: $snow-white;
            box-shadow: $box-shadow-gm-1;
        }
        &__schedule-list-new-version{
            border: none;
            background-color: $snow-white;
            box-shadow: $box-shadow-gm;
        }

        &__text-title-product{
          color: $title-text-gm;
      }
    }
  }
  &__com-de{
    .dashboard-panel{
        &__container-menu-left-v2 {
            &--color {
                background-color: $background-grey-disabled-com-de;
                box-shadow: -4px 13px 10px 6px $box-shadow-com-de-color;
            }
        }
        &__icon-clients-v2 {
            color: $secondary-color-com-de;
        }
        &__product-block-v2{
            @include product-color();
        }
        &__container-new-version {
            width: 100%;
        }
        &__block-header-title-product {
            border-color: $third-color-com-de;
        }
        &__button-show-documentation{
            @include dashboard-panel-button-header($secondary-color-com-de, $snow-white, $hover-block-com-de, $primary-color-com-de);
            height: 36px;
            padding: 6px 0 10px 15px;
        }
        &__block-graph-reports-v2{
            border: none;
            box-shadow: $box-shadow-com-de;
        }
        &__evaluations-list,
        &__instances-list,
        &__block-tasks-list-v2{
            border: none;
            background-color: $snow-white;
            box-shadow: $box-shadow-com-de-1;
        }
        &__schedule-list-new-version{
            border: none;
            background-color: $snow-white;
            box-shadow: $box-shadow-com-de;
        }

        &__text-title-product{
          color: $title-text-com-de;
      }
    }
  }
  &__com-fr{
    .dashboard-panel{
        &__container-menu-left-v2 {
            &--color {
                background-color: $background-grey-disabled-com-fr;
                box-shadow: -4px 13px 10px 6px $box-shadow-com-fr-color;
            }
        }
        &__icon-clients-v2 {
            color: $secondary-color-com-fr;
        }
        &__product-block-v2{
            @include product-color();
        }
        &__container-new-version {
            width: 100%;
        }
        &__block-header-title-product {
            border-color: $third-color-com-fr;
        }
        &__button-show-documentation{
            @include dashboard-panel-button-header($secondary-color-com-fr, $snow-white, $hover-block-com-fr, $primary-color-com-fr);
            height: 36px;
            padding: 6px 0 10px 15px;
        }
        &__block-graph-reports-v2{
            border: none;
            box-shadow: $box-shadow-com-fr;
        }
        &__evaluations-list {
            height: 400px;
        }
        &__evaluations-list,
        &__instances-list,
        &__block-tasks-list-v2{
            border: none;
            background-color: $snow-white;
            box-shadow: $box-shadow-com-fr-1;
        }
        &__block-right-reports-schedule {
            height: 400px;
            margin-top: 0;
        }
        &__schedule-list-new-version{
            border: none;
            background-color: $snow-white;
            box-shadow: $box-shadow-com-fr;
        }

        &__text-title-product{
          color: $title-text-com-fr;
      }
    }
  }

}

@media (max-width: 1200px){
  .dashboard-panel{
      &__block-evaluations-and-instances-list-v2{
          width: 100%;
      }
      &__schedule-list-new-version,
      &__block-right-reports-schedule {
          width: 100%;
          margin-left: 0;
          margin-top: 20px;
      }

      &__block-50-percent-v3{
          width: 100%;
          &--right{
              margin-top: 25px;
              float: left;
          }
      }
  }
}
