@mixin header-error($backgroundColor, $color){
  font-family: MTTMilano, sans-serif;
  font-size: 13px;
  height: 51px;
  background-color: $backgroundColor;
  color: $color;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 10px;
  &--dropdown{
      height: 35px;
  }
}

.from-to-calendar{
&__dropdown-period{
    display: block;
    height: 35px;
    width: 100%;
    font-family: 'MTTMilano', sans-serif;
    font-size: 13px;
    font-weight: 500;
    padding: 9px;

}
&__header{
    display: block;
    width: 100%;
    height: 54px;
    overflow: hidden;
    position: relative;
    padding-top: 16px;
    border-bottom: 1px solid;
    font-weight: normal;

    &--dropdown{
        height: 38px;
        padding-top: 0;
    }
}

&__header-error{
    position: absolute;
    bottom: 1px;
    left: 1px;
    width: calc(100% - 2px);
    & > p{
        margin: 0;
    }
}

&__header-complaints-icon {
    font-size: 15px;
}

&__date{
    font-family: 'MTTMilano', sans-serif;
    font-size: 13px;
    text-align: center;
    width: 50%;
    float: left;
    margin: 0;

}
&__arrow-icon{
    position: absolute;
    font-size: 25px;
    left: 175px;
}

&__days-left{
    font-family: MTTMilano, sans-serif;
    font-size: 13px;
    text-align: center;
    width: 100%;
    margin: 1px 0 0;
    position: relative;
    float: left;
}
&__body{
    display: block;
    width: 100%;
    border-bottom: 1px solid ;
    height: 194px;
}
&__calendar{
    display: block;
    width: 50%;
    float: left;
    padding: 0 5px 0px 6px;
    border: 0;
    border-right: 1px solid;
    height: 194px;
    border-radius: 0;
    &:last-child{
        border-right: none;
    }
    & .ngb-dp{
        &-day,
        &-weekday,
        &-week-number {
            width: 24px;
        }
        &-weekdays{
            background: none;
        }
        &-weekday{
            text-align: center;
            font-family: MTTMilano, sans-serif;
            font-style: unset;
            color: $concept-blue;
            font-size: 12px;
            font-weight: 700;
            line-height: 10px;
            height: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-day{
            cursor: pointer;
            height: 20px;
            text-align: center;
        }
    }
}
&__calendar-header{
    height: 25px;
    position: relative;
    padding-top: 1px;
    &--month-year{
        padding-top: 6px;
    }
}
&__table-calendar{
  width: 100%;
}
&__header-text{
    position: relative;
    float: left;
    width: calc(100% - 34px);
    &--center{
        text-align: center;
    }
}
&__block-arrows-calendar{
    position: relative;
    float: right;
    width: 34px;
    top: 5px;
}
&__block-arrow{
    position: relative;
    float: left;
    width: 17px;
}
&__month-year-button{
    font-family: Arial, sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    width: calc(100% - 4px);
    border: 1px solid ;
    background-color: transparent;
    padding: 0;
    margin: 2px;
    height: 26px;
}
&__datepicker-title{
    position: relative;
    font-size: 10px;
}
&__calendar-title-button{
    font-family: 'MTTMilano-Bold', sans-serif;
    font-size: 10px;
    margin-top: 7px;
    border: none;
    background: none;
    outline: none;
    text-transform: uppercase;
    padding: 0;
    text-align: left;
    width: 83%;
}
&__buttons-block{
    text-align: right;
    display: block;
    width: 100%;
    overflow: hidden;
    padding: 3px 6px 2px 0;
}
&__button-angle{
    padding: 0;
    color: $custom-black-landing;
    border: none;
    background: none;
    outline: none;
    position: relative;
    font-size: 15px;
}
&__button-restore{
    border: none;
    outline: none;
    background-color: transparent;
    color: $custom-secondary-color;
    font-family: MTTMilano, sans-serif;
    font-size: 13px;
    font-weight: 500;
    float: left;
    position: relative;
    margin-left: 7px;
    padding: 0;
}

&__block-buttons-right{
    float: right;
    position: relative;
}

&__icon-filter-restore{
    font-size: 18px;
    top: 3px;
    position: relative;
    margin-right: 3px;
}

&__row{
    width: 20px;
    height: 20px;
    padding: 0px 2px;
}
&__underline{
    &--from{
        border-radius: 50% 0 0 50% ;
    }
    &--to{
        border-radius: 0 50% 50% 0;
    }
}

&__hidden{
    visibility: hidden;
}

&__custom-select{
    position: relative;
}

&__custom-select-label{
    cursor: pointer;
    position: absolute;
    right: 0;
    display:flex;
    flex-direction:row;
}

&__header-complaints-text{
    position: relative;
    bottom: 2px;
    margin-right: 10px;
}

&__select-ops{
    max-height: 254px;
    width: 185px;
    border: 1px solid;
    border-top: 2px solid;
    top: 26px;
    right: -10px;
    overflow: auto;
    height: 257px;
    display: none;
    z-index: 20;
    position: absolute;
    padding-left: 8px;
    transform: translate(176px, 24px) !important;
    border-radius: 0;
    &.active{
        display:inline-block;
    }
    & > div{
        padding:7px 14px;
        white-space:nowrap;
        overflow-x:hidden;
        text-overflow:ellipsis;
    }

}

&__backdrop{
    position:fixed;
    top:0;left:0;
    width:100%;
    height:100%;
    display:none;
    &.active{
        display:block;
    }
}

&__month-year-view{
  position: relative;
}

&__block-day{
    width: 20px;
    height: 20px;
    position: relative;
}

&__current-day{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid;
    position: absolute;
}

&__current-button{
    left: 0;
}
&__block-select-calendar-smartphone{
    display: none;
}
&__button{
  font-size: 15px;
}
&__block-month-calendar{
  margin-top: 5px;
}
&__day-box{
    width: 24px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
&__day{
    width: 20px;
    height: 20px;
    padding: 3px 0 0 0;
    border: 1px solid transparent;
    border-radius: 10px;
    font-family: Arial, sans-serif;
    font-size: 10px;
    &:hover:not([disabled]) {
      outline: none;
    }
}
&__selected-day{
  outline: none;
  border: 1px solid transparent;
  border-radius: 50%;
}
&__today{
  border-radius: 50%;
}

}

.compliance{
&__dark-blue{
  .from-to-calendar{
    &__dropdown-period{
        background-color: $primary-color;
        color: $background-grey-other;
    }
    &__header{
      border-color: $background-grey-other;
    }
    &__header-error{
      @include header-error($red-opacity-02, $red);
    }
    &__header-complaints-icon {
      color: $background-grey-other;
    }
    &__date,
    &__arrow-icon,
    &__calendar-title-button{
      color: $primary-color;
    }
    &__days-left{
      color: $primary-color;
    }
    &__current-day{
      border-color: $primary-color;
    }
    &__month-year-button{
        border-color: $result-search-quote-color;
        color: $concept-blue;
        &-current{
            border: 2px solid $primary-color;
        }
        &-active{
            background-color: $primary-color;
            border-color: $primary-color;
            color: $snow-white;
        }
        &:hover{
            background-color: $background-color-states;
            color: $concept-blue;
        }
    }
    &__button-angle{
        color: $primary-color;
    }

    &__underline{
      background-color: $background-grey-other;
    }
    &__calendar{
      border-right-color: $background-grey-other;
      & .ngb-dp-header{
        display: none;
      }
    }
    &__body{
      border-bottom-color: $background-grey-other;
    }
    &__selected-day{
      background-color:  $primary-color;
      box-shadow: 0 0 5px 1px $box-shadow-box-2;
      color: $snow-white;
      &:hover{
        cursor:pointer;
        background-color: $custom-color-E3E4E8;
      }
    }
    &__today{
      border: 1px solid  $primary-color;
    }
  }
}

&__steel-blue, &__uk-steel-blue{
  .from-to-calendar{
    &__dropdown-period{
      background-color: $third-color-2;
      color: $border-hover-2;
    }
    &__header{
      border-color: $secondary-color-2;
    }
    &__header-error{
      @include header-error($background-error-2 , $alert-color-2);
    }
    &__header-complaints-icon,
    &__calendar-title-button {
      color: $border-hover-2;
    }
    &__date,
    &__arrow-icon{
      color: $primary-color-2;
    }
    &__days-left{
      color: $primary-color-2;
    }
    &__current-day{
      border-color: $border-hover-2;
    }
    &__month-year-button{
        border-color: $secondary-color-2;
        color: $border-bottom-2;
        &-current{
            border: 2px solid $border-hover-2;
        }
        &-active{
            background-color: $border-hover-2;
            border-color: $border-hover-2;
            color: $snow-white;
        }
        &:hover{
            background-color: $third-color-2;
            color: $border-bottom-2;
        }
    }
    &__button-angle{
        color: $border-hover-2;
    }

    &__underline{
      background-color: $block-hover-2;
    }
    &__calendar{
      border-right-color: $secondary-color-2;
      .ngb{
        &-dp{
          &--weekdays{
            border-color: #D6D6D6;
          }
          &-weekday{
              color: $border-bottom-2;
          }
        }
      }
    }
    &__day{
      color: $primary-color-2;
      &:hover:not([disabled]) {
        background-color: $third-color-2;
        color: $primary-color-2;
      }
    }
    &__body{
      border-bottom-color: $secondary-color-2;
    }
    &__selected-day{
      background-color: $border-hover-2;
      box-shadow: 0 0 5px 1px $box-shadow-box-2;
      color: $white-special-2;
      &:hover{
        cursor:pointer;
        background-color:  $third-color-2;
      }
    }
    &__today{
      border: 1px solid  $third-color-2;
    }
  }
}

&__icam-red{
  .from-to-calendar{
    &__dropdown-period{
      background-color: $primary-color-icam;
      color: $snow-white;
    }
    &__header{
      border-color: $secondary-color-icam;
    }
    &__header-error{
      @include header-error($red-task-20-icam, $alert-color-icam);
    }
    &__header-complaints-icon {
      color: $snow-white;
    }
    &__date,
    &__arrow-icon{
      color: $custom-black-landing;
    }
    &__days-left{
      color: $custom-black-landing;
    }
    &__current-day{
      border-color: $primary-color-icam;
    }
    &__month-year-button{
        border-color: $border-block-icam;
        color: $text-field-color-icam;
        &-current{
            border: 2px solid $border-block-icam;
        }
        &-active{
            background-color: $primary-color-icam;
            border-color: $primary-color-icam;
            color: $snow-white;
        }
        &:hover{
            background-color: $hover-block-icam;
            border-color: $hover-block-icam;
            color: $text-field-color-icam;
        }
    }
    &__button-angle{
        color: $primary-color-icam;
    }

    &__underline{
      background-color: $hover-block-icam;
    }
    &__calendar-title-button{
      color: $primary-color-icam;
    }
    &__calendar{
      border-right-color: $border-block-icam;
      .ngb{
        &-dp{
            &--weekdays{
              border-color: $border-block-icam;
            }
            &-weekday{
                color: $text-field-color-icam;
            }
        }
      }
    }
    &__body{
      border-bottom-color: $primary-color-icam;
    }
    &__day{
      color: $custom-black-landing;
      &:hover:not([disabled]) {
        background-color: $hover-block-icam;
        color: $custom-black-landing;
      }
    }
    &__selected-day{
      background-color: $primary-color-icam;
      box-shadow: 0 0 5px 1px $box-shadow-box-2;
      color: $snow-white;
      &:hover{
        cursor:pointer;
        background-color: $custom-color-E3E4E8;

      }
    }
    &__today{
      border: 1px solid $primary-color-icam;
    }
  }
}

&__gm-law{
  .from-to-calendar{
    &__dropdown-period{
      background-color: $primary-color-gm;
      color: $snow-white;
    }
    &__header{
      border-color: $secondary-color-gm;
    }
    &__header-error{
      @include header-error($red-task-20-gm, $alert-color-gm);
    }
    &__header-complaints-icon {
      color: $snow-white;
    }
    &__date,
    &__arrow-icon{
      color: $custom-black-landing;
    }
    &__days-left{
      color: $custom-black-landing;
    }
    &__current-day{
      border-color: $primary-color-gm;
    }
    &__month-year-button{
        border-color: $border-block-gm;
        color: $text-field-color-gm;
        &-current{
            border: 2px solid $border-block-gm;
        }
        &-active{
            background-color: $primary-color-gm;
            border-color: $primary-color-gm;
            color: $snow-white;
        }
        &:hover{
            background-color: $hover-block-gm;
            border-color: $hover-block-gm;
            color: $text-field-color-gm;
        }
    }
    &__button-angle{
        color: $primary-color-gm;
    }

    &__underline{
      background-color: $hover-block-gm;
    }
    &__calendar-title-button{
      color: $primary-color-gm;
    }
    &__calendar{
      border-right-color: $border-block-gm;
      .ngb{
        &-dp{
            &--weekdays{
              border-color: $border-block-gm;
            }
            &-weekday{
                color: $text-field-color-gm;
            }
        }
      }
    }
    &__body{
      border-bottom-color: $primary-color-gm;
    }
    &__day{
      color: $custom-black-landing;
      &:hover:not([disabled]) {
        background-color: $hover-block-gm;
        color: $custom-black-landing;
      }
    }
    &__selected-day{
      background-color: $primary-color-gm;
      box-shadow: 0 0 5px 1px $box-shadow-box-2;
      color: $snow-white;
      &:hover{
        cursor:pointer;
        background-color: $custom-color-E3E4E8;

      }
    }
    &__today{
      border: 1px solid $primary-color-gm;
    }
  }

}
&__com-de{
  .from-to-calendar{
    &__dropdown-period{
      background-color: $primary-color-com-de;
      color: $snow-white;
    }
    &__header{
      border-color: $secondary-color-com-de;
    }
    &__header-error{
      @include header-error($red-task-20-com-de, $alert-color-com-de);
    }
    &__header-complaints-icon {
      color: $snow-white;
    }
    &__date,
    &__arrow-icon{
      color: $custom-black-landing;
    }
    &__days-left{
      color: $custom-black-landing;
    }
    &__current-day{
      border-color: $primary-color-com-de;
    }
    &__month-year-button{
        border-color: $border-block-com-de;
        color: $text-field-color-com-de;
        &-current{
            border: 2px solid $border-block-com-de;
        }
        &-active{
            background-color: $primary-color-com-de;
            border-color: $primary-color-com-de;
            color: $snow-white;
        }
        &:hover{
            background-color: $hover-block-com-de;
            border-color: $hover-block-com-de;
            color: $text-field-color-com-de;
        }
    }
    &__button-angle{
        color: $primary-color-com-de;
    }

    &__underline{
      background-color: $hover-block-com-de;
    }
    &__calendar-title-button{
      color: $primary-color-com-de;
    }
    &__calendar{
      border-right-color: $border-block-com-de;
      .ngb{
        &-dp{
            &--weekdays{
              border-color: $border-block-com-de;
            }
            &-weekday{
                color: $text-field-color-com-de;
            }
        }
      }
    }
    &__body{
      border-bottom-color: $primary-color-com-de;
    }
    &__day{
      color: $custom-black-landing;
      &:hover:not([disabled]) {
        background-color: $hover-block-com-de;
        color: $custom-black-landing;
      }
    }
    &__selected-day{
      background-color: $primary-color-com-de;
      box-shadow: 0 0 5px 1px $box-shadow-box-2;
      color: $snow-white;
      &:hover{
        cursor:pointer;
        background-color: $custom-color-E3E4E8;

      }
    }
    &__today{
      border: 1px solid $primary-color-com-de;
    }
  }

}

&__com-fr{
  .from-to-calendar{
    &__dropdown-period{
      background-color: $primary-color-com-fr;
      color: $snow-white;
    }
    &__header{
      border-color: $secondary-color-com-fr;
    }
    &__header-error{
      @include header-error($red-task-20-com-fr, $alert-color-com-fr);
    }
    &__header-complaints-icon {
      color: $snow-white;
    }
    &__date,
    &__arrow-icon{
      color: $custom-black-landing;
    }
    &__days-left{
      color: $custom-black-landing;
    }
    &__current-day{
      border-color: $primary-color-com-fr;
    }
    &__month-year-button{
        border-color: $border-block-com-fr;
        color: $text-field-color-com-fr;
        &-current{
            border: 2px solid $border-block-com-fr;
        }
        &-active{
            background-color: $primary-color-com-fr;
            border-color: $primary-color-com-fr;
            color: $snow-white;
        }
        &:hover{
            background-color: $hover-block-com-fr;
            border-color: $hover-block-com-fr;
            color: $text-field-color-com-fr;
        }
    }
    &__button-angle{
        color: $primary-color-com-fr;
    }

    &__underline{
      background-color: $hover-block-com-fr;
    }
    &__calendar-title-button{
      color: $primary-color-com-fr;
    }
    &__calendar{
      border-right-color: $border-block-com-fr;
      .ngb{
        &-dp{
            &--weekdays{
              border-color: $border-block-com-fr;
            }
            &-weekday{
                color: $text-field-color-com-fr;
            }
        }
      }
    }
    &__body{
      border-bottom-color: $primary-color-com-fr;
    }
    &__day{
      color: $custom-black-landing;
      &:hover:not([disabled]) {
        background-color: $hover-block-com-fr;
        color: $custom-black-landing;
      }
    }
    &__selected-day{
      background-color: $primary-color-com-fr;
      box-shadow: 0 0 5px 1px $box-shadow-box-2;
      color: $snow-white;
      &:hover{
        cursor:pointer;
        background-color: $custom-color-E3E4E8;

      }
    }
    &__today{
      border: 1px solid $primary-color-com-fr;
    }
  }

}


&__landing-custom{
  .from-to-calendar{
    &__header{
      border-color: $custom-black-landing;
    }
    &__header-error{
      @include header-error(#F3CFCF, $advice-close);
    }
    &__date, &__arrow-icon{
      color: $custom-black-landing;
    }
    &__days-left{
      color: $custom-black-landing;
      font-family: 'lato_regular', sans-serif;
      font-weight: bold;
      font-size: 10px;
    }
    &__body{
      border-bottom-color: $custom-black-landing;
      height: 188px;
    }
    &__calendar{
      border-right-color: $custom-black-landing;
      height: 187px;
    }
    &__calendar-header{
      height: 33px;
      padding-top: 7px;
    }
    &__block-arrows-calendar{
      top: 0px;
    }
    &__month-year-button{
        font-family: 'lato_regular', sans-serif;
        border-color: $background-grey;
        color: $custom-black-landing;
        &-current{
            background: $custom-border-grey;
            color: $custom-black-landing;
            border: 2px solid transparent;
        }
        &-active{
            background-color: $custom-black-landing;
            color: $snow-white;
        }
        &:hover{
            background: $custom-border-grey;
            color: $custom-black-landing;
        }
    }
    &__calendar-title-button{
      color: $custom-black-landing;
      margin: 0;
      font-family: 'lato_bold', sans-serif;
    }
    &__button-angle{
        color: $custom-black-landing;
    }
    &__block-arrows-calendar{
       top: 0px;
    }
    &__underline{
      background-color: $custom-border-grey;
    }
    &__current-day{
      color: $custom-black-landing;
      border-color: $custom-black-landing;
    }
    &__buttons-block{
      padding: 6px 6px 0 0;
    }
    &__calendar{
      .ngb{
        &-dp{
            &-weekdays{
                border: none;
            }
            &-day,
            &-weekday,
            &-week-number {
                font-family: 'lato_regular', sans-serif;
                font-size: 10px;
            }
            &-weekday{
                background-color: $background-grey;
                color: $custom-black-landing;
                font-weight: bold;
                height: 25px;
            }
        }
      }
    }
    &__day{
      color: $custom-black-landing;
      &:hover:not([disabled]) {
        background-color: $custom-border-grey;
        color: $custom-black-landing;
      }
    }
    &__today{
        border: 1px solid $custom-black-landing;
    }
    &__selected-day{
        background-color: $custom-black-landing;
        color: $snow-white;
        outline: none;
        &:hover:not([disabled]) {
          background-color: $custom-border-grey;
          color: $custom-black-landing;
          outline: none;
        }
    }
  }
}
}


select.from-to-calendar__select,.my-select-mobile-input{
opacity:0;
position:absolute;
z-index:10;
display:none;
&.active{
    display:block;
}
}

@media (max-width: $max-width-smartphone) { //Smartphone
.compliance{
  &__landing-custom{
    .from-to-calendar {
        &__header{
            padding: 6px 7px 0;
        }
        &__body{
            height: 230px;
        }
        &__block-select-calendar-normal{
            display: none;
        }
        &__block-select-calendar-smartphone{
            display: block;
        }
        &__datepicker-title{
            font-size: 14px;
            top: 3px;
        }
        &__calendar-title-button{
            font-size: 12px;
        }
        &__header-text{
            width: calc(100% - 52px);
        }
        &__block-arrows-calendar{
            width: 52px;
            bottom: 5px;
            top: initial;
        }
        &__days-left{
            font-size: 14px;
            line-height: 10px;
            margin: 8px 0 0;
        }
        &__calendar{
            border: none;
            width: 100%;
            height: 229px;
            &--hidden-mobile{
                display: none;
            }
        }
        &__button-date{
            border-radius: 0;
            background-color: transparent;
            color: $custom-secondary-color;
            height: 25px;
            width: 50%;
            border: 1px solid $custom-secondary-color;
            font-size: 13px;
            font-family: MTTMilano, sans-serif;
            position: relative;
            float: left;
            padding: 0px;
            &--selected{
                color: $snow-white;
                background-color: $custom-black-landing;
                border-color: $custom-black-landing;
            }
        }
        &__button-angle{
            font-size: 24px;
            height: 29px;
        }
        &__month-year-button{
            height: 35px;
        }
        &__row{
            width: 28px;
            height: 28px;
            padding: 0px;
        }
        &__block-day{
            width: 28px;
            height: 28px;
        }
        &__current-day{
            width: 28px;
            height: 28px;
        }

        // &__underline-comparated{
        //     &--from{
        //         border-radius: 50% 0 0 50% ;
        //     }
        //     &--to{
        //         border-radius: 0 50% 50% 0;
        //     }
        // }

        &__block-buttons-right{
            width: 100%;
            float: left;
        }

        &__buttons-block{
            padding: 6px;
        }

        &__button-restore{
            margin: 10px 0 0;
        }
    }
  }
}
}
