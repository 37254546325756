.request-tracking{
    & .modal-dialog{
        & .modal-content{
            height: 503px;
            border-radius: 0px;
        }
    }

    &__block-context{
        height: 503px;
        position: relative;
        float: left;
        width: 100%;
    }

    &__block-body{
        position: relative;
        float: left;
        padding: 0;
        font-size: 12px;
        margin: 0;
        width: 100%;
        height: 418px;
    }

    &__block-table{
        margin: 30px 71px 0;
        color: $primary-color;
    }

    &__block-header-table{
        display: table;
        width: 100%;
        height: 30px;
        border-bottom: 1px solid $primary-color;
    }

    &__block-body-table{
        display: block;
        width: 100%;
        height: 330px;
        // height: 185px;
        overflow-x: hidden;
        overflow-y: auto;
        color: $secondary-color;
        font-family: Arial, sans-serif;
        font-size: 12px;
        position: relative;
        // &--more-elements{
        //     width: calc(100% + 20px);
        // }
        &--scroll{
            width: calc(100% + 15px);
        }
    }

    &__col-text{
        height: 15px;
    }

    &__row-body{
        width: 100%;
        display: table;
        border-bottom: 1px solid $box-comment-border-2;
        min-height: 46px;
        line-height: 1;
        // &--more-elements{
        //     width: calc(100% - 10px);
        // }
        &:hover{
            background: $background-grey;
            & .request-tracking__options-box {
                opacity: 1;
            }
        }
        &--hidden{
            visibility: hidden;
        }
        &--scroll{
            width: calc(100% - 5px);
        }
    }

    &__col{
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        position: relative;
        &--request{
            width: 190px;
        }
        &--header-date{
            width: 75px;
            padding-top: 2px;
        }
        &--date{
            width: 75px;
        }
        &--user{
            width: 190px;
        }
        &--state{
            width: 95px;
        }
        &--state-body{
            vertical-align: top;
            padding: 7px 0;
        }
        &--audit-header{
            width: 131px;
        }
        &--audit{
            width: 206px;
        }
        &--action{
            position: absolute;
            display: block;
            right: 0;
        }
        &--action-header{
            width: 75px;
        }
        &--top{
            vertical-align: top;
            padding-top: 14px;
        }
        &--top-one{
            vertical-align: top;
            padding-top: 17px;
        }
        &--only-first{
            vertical-align: middle;
            // top: 4px;
        }
        &--only-first-audit{
            vertical-align: middle;
            padding-top: 14px;
        }
    }

    &__icon-type-request{
        font-size: 20px;
        color: $primary-color;
        position: absolute;
        left: 5px;
        &--more{
            top: 10px;
        }
        &--one{
            top: 13px;
        }
    }

    &__text-col-request{
        margin-left: 30px;
    }

    &__icon-ellipsis {
        position: relative;
        top: 9px;
    }

    &__options-box {
        list-style-type: none;
        margin: 0;
        padding: 0;
        color: $primary-color;
        display: inline-flex;
        margin-right: 3px;
        background-color: $background-grey-other;
        border: 0.5px solid $menu-icons-border;
        height: 42px;
        width: 55px;
        -webkit-border-top-left-radius: 67px;
        -webkit-border-bottom-left-radius: 67px;
        -moz-border-radius-topleft: 67px;
        -moz-border-radius-bottomleft: 67px;
        border-top-left-radius: 67px;
        border-bottom-left-radius: 67px;
        position: absolute;
        z-index: 1;
        top: 2px;
        opacity: 0;
        right: -1px;
    }

    &__icon-options {
        font-size: 20px;
        cursor: pointer;
        margin: 9px 9px 0px 19px;
    }

    &__text-not-available{
        color: $subcontainer-ground;
        font-family: Arial, sans-serif;
        font-size: 12px;
    }

    &__text-not-available{
        color: $subcontainer-ground;
        font-family: Arial, sans-serif;
        font-size: 12px;
    }

    &__button-download-audit{
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        margin: 0;
        display: block;
        position: relative;
        bottom: 2px;
        height: 24px;
    }

    &__text-download-audit{
        color: $primary-color;
        font-family: Arial, sans-serif;
        font-size: 12px;
        position: relative;
        float: left;
    }

    &__icon-download-audit{
        font-size: 20px;
        color: $primary-color;
        position: relative;
        float: left;
        bottom: 5px;
        margin-left: 3px;
    }

    &__list-element-user{
        max-width: 180px;
        height: 24px;
    }

    &__element-users{
        height: 24px;
        display: block;
    }

    &__block-img{
        position: relative;
        width: 100%;
        text-align: center;
    }

    &__block-state{
        color: $secondary-color;
        font-family: Arial, sans-serif;
        font-size: 12px;
        line-height: 15px;
        margin: 0;
        position: relative;
        display: inline-block;
        top: 1px;
        max-width: 90px;
    }

  }
