.rename-modal{
  &__window{
      & .modal-dialog{
          & .modal-content{
              height: 400px;
              border-radius: 0px;
          }
      }
  }
  // &__edit-icon{
  //     color: $snow-white;
  //     font-size: 30px;
  //     float: left;
  // }
  &__block-body{
      position: relative;
      float: left;
      width: 100%;
  }
  &__form{
      padding: 75px 74px 0;
      position: relative;
      float: left;
      width: 100%;

      &--related{
          padding-top: 6px;
      }
      &--relationship{
          padding-top: 40px;
      }
  }
  // &__label{
  //     width: 100%;
  //     margin: 38px auto;
  //     position: relative;
  // }
  &__name-text{
      width: calc(100% - 205px);
      display: block;
      float: left;
  }
  &__suffix-text{
      width: 200px;
      display: block;
      float: left;
      margin-left: 5px;
  }
  &__error-block{
      display: block;
      width: 100%;
      overflow: hidden;
  }
  &__error-name{
      color: $red;
      position: absolute;
      font-size: 12px;
      &--relative{
          position: relative;
      }

  }
  &__text-header{
      color: $snow-white;
      font-family: MTTMilano, sans-serif;
      font-size: 22px;
      margin: 0;
      padding-left: 16px;
      float: left;
  }
  &__buttons-block{
      position: absolute;
      right: 74px;
      bottom: 23px;
      margin-top: 0;
      margin-bottom: 0;
  }

  //////////////////////////////////
  //////////////Custom/////////////
  ////////////////////////////////
  &__custom-window{
      & .modal-dialog{
          & .modal-content{
              height: 340px;
              // width: 940px;
              border-radius: 4px;
              background-color: $snow-white;
              box-shadow: 0 10px 20px 0 $attachment-custom-grey-opacity;
              // padding: 6px 8px;
          }
      }
  }
  &__custom-block-body{
      position: relative;
      float: left;
      width: 100%;
      display: block;
      padding-bottom: 55px;
  }
  &__custom-form{
      padding: 48px 80px 0 80px;
      position: relative;
      float: left;
      width: 100%;
      height: 236px;
      display: block;
      &--with-info{
          padding-top: 15px;
      }
  }

  &__custom-text-info{
      color: $custom-black-landing;
      font-family: 'lato_regular', sans-serif;
      font-size: 14px;
      margin-bottom: 20px;
  }
  &__related-form-info{
      font-family: 'MTTMilano', sans-serif;
      font-size: 18px;
      padding: 56px 74px 0 74px;
      color: $donut-text;
  }
}

.compliance{
  &__steel-blue{
    .rename-modal{
        &__error-name{
            color: $alert-color-2;
        }
    }
  }
}

@media (max-width: $max-width-smartphone) { //Smartphone
  .rename-modal {
      &__custom-window .modal-dialog .modal-content {
          width: auto;
          min-height: 210px;
          height: auto;
      }

      &__custom-form {
          padding: 10px;
      }

      &__custom-block-body {
          padding-bottom: 10px;
      }
      &__buttons-block-custom{
          text-align: center;
      }
  }
}
