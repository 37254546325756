.login-be-access{
  padding: 25px 15px;
  font-family: MTTMilano, sans-serif;
  &__h1{
    font-size: 32px;
    margin-bottom: 15px;
  }
  &__h2{
    font-size: 24px;
    margin-bottom: 25px;
  }
  &__link{
    text-decoration: underline;
  }
}
