.migration-data{
  &__window{
    & .modal-dialog {
        & .modal-content {
            height: auto;
            display: flex;
        }
    }
  }
  &__box-icon{
      font-size: 117px;
      position: relative;

      &--down{
          top: 4px;
      }
  }

  &__footer{
      float: right;
      padding-right: 66px;
      padding-top: 56px;
      text-align: right;
  }

  &__contacts-import-title {
      color: $donut-text;
      font-family: MTTMilano, sans-serif;
      font-size: 18px;
      font-weight: 500;
      margin: 60px 0 20px;

      &--migration-title {
          margin-top: 45px;
      }
  }
  &__button-option-box{
    max-height: 68px;
    text-align: left;
    min-height: 42px;
    overflow: hidden;
    padding-top: 2px;
    &:hover{
      cursor: pointer;
    }
  }

  &__option-box{
    height: 300px;
    width: 246px;
    background-color: $background-color-states;
    float: left;
    padding: 18px 8px 20px 10px;
  }
}

.compliance {
  &__steel-blue {
    .migration-data {
      &__contacts-import-title {
        color: $grey-icons-2;
      }
    }
  }

  &__uk-steel-blue {
    .migration-data {
      &__contacts-import-title {
        color: $grey-icons-2;
      }
    }
  }

  &__icam-red {
    .migration-data {
      &__contacts-import-title {
        color:$secondary-color-icam;
      }
    }
  }

  &__gm-law {
    .migration-data {
      &__contacts-import-title {
        color:$secondary-color-gm;
      }
    }
  }
  &__com-de {
    .migration-data {
      &__contacts-import-title {
        color:$secondary-color-com-de;
      }
    }
  }
  &__com-fr {
    .migration-data {
      &__contacts-import-title {
        color:$secondary-color-com-fr;
      }
    }
  }
}
