.visibility {
  display: block;
  float: left;
  position: relative;
  height: 50px;
  padding-top: 1px;
  width: calc(100% - 66px);
  margin: 0 24px 0 42px;
  &--field{
      width: 100%;
      margin: 0;
      float: none;
  }
}
