.move-modal{
  & .modal-dialog{
      & .modal-content{
          height: 600px;
          border-radius: 0px;
      }
  }

  &__block-context{
      height: 600px;
      position: relative;
      float: left;
      width: 100%;
  }

  &__block-body{
      position: relative;
      float: left;
      padding: 24px 71px 0;
      font-size: 12px;
      margin: 0;
      width: 100%;
      height: 513px;
      &--error{
          padding-top: 0px;
          height: 470px;
      }
  }

  &__block-button-create-folder{
      float: right;
      width: auto;
      height: 29px;
      position: relative;
      border: 0;
      // display: inline-block;
      font-size: 18px;
      &--new{
          width: 100%;
      }
  }

  &__block-error-create-folder{
      margin-top: 10px;
      width: 98%;
      margin-left: 1%;
      height: 55px;
      color: $red;
      background-color: $background-error;
      padding: 6px 10px 10px 20px;
      font-family: Arial, sans-serif;
      font-size: 12px;
  }

  &__block-first-level{
      margin-left: 45px;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
  }

  &__block-none-folder{
      text-align: center;
      color: $box-comment-border-02;
      margin-top: 85px;
  }

  &__block-tree{
      width: 100%;
      height: 353px;
      margin-top: 27px;
      background-color: $background-grey;
      padding: 10px 11px 10px;
      position: relative;
      float: left;
      &--error{
          margin-top: 10px;
      }
  }

  &__block-tree-folders{
      overflow-y: auto;
      overflow-x: hidden;
      height: 315px;
  }

  &__button-new-folder{
      border: none;
      height: 26px;
      margin-bottom: 3px;
      width: 100%;
      color: $primary-color;
      position: relative;
      top: 1px;
      outline: none;
      background-color: transparent;
      padding: 0 10px 0 6px;
      &:hover {
          background-color: $subcontainer-border;
      }
      &:after{
          content: " ";
          border-bottom: 2px solid $primary-color;
          width: calc(100% + 16px);
          display: block;
          right: 6px;
          top: 2px;
          position: relative;
      }
  }

  &__icon-big-folder{
      font-size: 100px;
  }

  &__icon-close-error{
      font-size: 15px;
      top: 14px;
      float: right;
      position: relative;
  }

  &__icon-warning{
      font-size: 40px;
      margin-right: 10px;
  }

  &__input-new-folder {
      bottom: 1px;
      border: 1px solid $primary-color;
      outline: none;
      position: relative;
      height: 29px;
      width: calc(100% - 70px);
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      padding: 3px 0 0 7px;
      margin-right: 7px;
      &--error{
          border-color: $red;
          color: $red;
          background-color: $background-error !important;
      }
  }


  &__text-error{
      position: relative;
      bottom: 12px;
  }

  &__text-none-folder{
      font-size: 20px;
      font-weight: 500;
      font-family: MTTMilano, sans-serif;
  }

  &__title-folder {
      font-family: MTTMilano, sans-serif;
      display: inline-block;
      top: -9px;
      position: relative;
      padding: 4px 20px 4px 7px;
      &--show{
          font-family: MTTMilano-Bold, sans-serif;
      }
  }

  &__title-tree{
      color: $subcontainer-ground;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
  }

  &__title-product{
      height: 24px;
      display: inline-block;
      font-size: 14px;
      font-family: MTTMilano-Bold, sans-serif;
      color: $primary-color;
      opacity: 0.5;
      margin-left: 10px;
      text-transform: uppercase;
      position: relative;
      top: -7px;
      padding: 4px 20px 4px 12px;
      &--move{
          margin-left: 0;
      }
      &--selected {
          background-color: $subcontainer-border;
          opacity: 1;
      }
  }
  &__title-selected{
      background-color: $subcontainer-border;
      font-family: MTTMilano-Bold, sans-serif;
  }

  &__icon-close-modal {
      color: $snow-white;
      float: right;
      font-size: 20px;
      top: 4px;
      position: relative;
      &--custom{
          font-size: 25px;
          top: 0;
      }

      &:hover {
          cursor: pointer;
      }
  }
  &__block-title-product{
      margin-bottom: 5px;
      padding-left: 15px;
      color: $primary-color;
      font-size: 30px;
      display: inline-block;
      &:hover{
          cursor: pointer;
      }
      &--disabled{
          color: $subcontainer-ground !important;
          &:hover{
              cursor: not-allowed !important;
          }
      }
  }
}

.compliance{
  &__dark-blue{
      .move-modal{
        &__block-tree{
            background-color: $background-color-states;
        }
      }
  }
  &__steel-blue,
  &__uk-steel-blue{
      .move-modal{
          &__block-first-level{
              color: $primary-color-2;
          }
          &__block-none-folder{
              color: $icon-shadow-2;
          }
          &__block-tree{
              background-color: $background-grey-disabled-2;
          }
          &__button-new-folder{
              color: $border-hover-2;
              height: 29px;
              margin-bottom: 1px;
              &:hover{
                  background-color: $third-color-2;
              }
              &:after{
                  content: " ";
                  border-bottom: 2px solid $border-hover-2;
                  width: calc(100% + 16px);
                  display: block;
                  right: 6px;
                  top: 2px;
                  position: relative;
              }
          }
          &__icon-close-error{
              background-color: $background-error-2;
              color: $alert-color-2;
          }
          &__input-new-folder {
              background-color: $third-color-2;
              color: $text-field-color-2;
              border: 1px solid $border-hover-2;
          }
          &__title-tree{
              color: $grey-icons-2;
          }
          &__title-product{
              color: $grey-icons-2;
          }
          &__title-selected{
              background-color: $block-hover-2;
          }
          &__icon-close-modal {
              color: $white-special-2;
          }
          &__block-title-product{
              color: $grey-icons-2;
          }
      }
  }
  &__icam-red{
      .move-modal{
          &__block-first-level{
              color: $custom-black-landing;
          }
          &__block-none-folder{
              color: $black-opacity-02;
          }
          &__block-tree{
              background-color: $background-grey-disabled-icam;
          }

          &__button-new-folder{
              color: $primary-color-icam;
              height: 29px;
              margin-bottom: 1px;
              &:hover{
                  background-color: $hover-block-icam;
              }
              &:after{
                  content: " ";
                  border-bottom: 2px solid $primary-color-icam;
                  width: calc(100% + 16px);
                  display: block;
                  right: 6px;
                  top: 2px;
                  position: relative;
              }
          }
          &__icon-close-error{
              background-color: $background-error-2;
              color: $alert-color-icam;
          }
          &__input-new-folder {
              background-color: $hover-block-icam;
              color: $text-field-color-icam;
              border: 1px solid $primary-color-icam;
          }
          &__title-tree{
              color: $secondary-color-icam;
          }
          &__title-product{
              color: $secondary-color-icam;
              opacity: 1;
          }
          &__title-selected{
              background-color: $hover-block-icam;
              color: $primary-color-icam;
          }
      }
  }
  &__gm-law{
      .move-modal{
          &__block-first-level{
              color: $custom-black-landing;
          }
          &__block-none-folder{
              color: $black-opacity-02;
          }
          &__block-tree{
              background-color: $background-grey-disabled-gm;
          }
          &__button-new-folder{
              color: $primary-color-gm;
              height: 29px;
              margin-bottom: 1px;
              &:hover{
                  background-color: $hover-block-gm;
              }
              &:after{
                  content: " ";
                  border-bottom: 2px solid $primary-color-gm;
                  width: calc(100% + 16px);
                  display: block;
                  right: 6px;
                  top: 2px;
                  position: relative;
              }
          }
          &__icon-close-error{
              background-color: $background-error-2;
              color: $alert-color-gm;
          }
          &__input-new-folder {
              background-color: $hover-block-gm;
              color: $text-field-color-gm;
              border: 1px solid $primary-color-gm;
          }
          &__title-tree{
              color: $secondary-color-gm;
          }
          &__title-product{
              color: $secondary-color-gm;
              opacity: 1;
          }
          &__title-selected{
              background-color: $hover-block-gm;
              color: $primary-color-gm;
          }
      }
  }
  &__com-de{
    .move-modal{
        &__block-first-level{
            color: $custom-black-landing;
        }
        &__block-none-folder{
            color: $black-opacity-02;
        }
        &__block-tree{
            background-color: $background-grey-disabled-com-de;
        }
        &__button-new-folder{
            color: $primary-color-com-de;
            height: 29px;
            margin-bottom: 1px;
            &:hover{
                background-color: $hover-block-com-de;
            }
            &:after{
                content: " ";
                border-bottom: 2px solid $primary-color-com-de;
                width: calc(100% + 16px);
                display: block;
                right: 6px;
                top: 2px;
                position: relative;
            }
        }
        &__icon-close-error{
            background-color: $background-error-2;
            color: $alert-color-com-de;
        }
        &__input-new-folder {
            background-color: $hover-block-com-de;
            color: $text-field-color-com-de;
            border: 1px solid $primary-color-com-de;
        }
        &__title-tree{
            color: $secondary-color-com-de;
        }
        &__title-product{
            color: $secondary-color-com-de;
            opacity: 1;
        }
        &__title-selected{
            background-color: $hover-block-com-de;
            color: $primary-color-com-de;
        }
    }
}
&__com-fr{
  .move-modal{
      &__block-first-level{
          color: $custom-black-landing;
      }
      &__block-none-folder{
          color: $black-opacity-02;
      }
      &__block-tree{
          background-color: $background-grey-disabled-com-fr;
      }
      &__button-new-folder{
          color: $primary-color-com-fr;
          height: 29px;
          margin-bottom: 1px;
          &:hover{
              background-color: $hover-block-com-fr;
          }
          &:after{
              content: " ";
              border-bottom: 2px solid $primary-color-com-fr;
              width: calc(100% + 16px);
              display: block;
              right: 6px;
              top: 2px;
              position: relative;
          }
      }
      &__icon-close-error{
          background-color: $background-error-2;
          color: $alert-color-com-fr;
      }
      &__input-new-folder {
          background-color: $hover-block-com-fr;
          color: $text-field-color-com-fr;
          border: 1px solid $primary-color-com-fr;
      }
      &__title-tree{
          color: $secondary-color-com-fr;
      }
      &__title-product{
          color: $secondary-color-com-fr;
          opacity: 1;
      }
      &__title-selected{
          background-color: $hover-block-com-fr;
          color: $primary-color-com-fr;
      }
  }
}
}
