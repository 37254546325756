@mixin result-search-block-categorie($primaryColor){
  border-color: $primaryColor;
  color: $primaryColor;
  &:hover{
      cursor: pointer;
      background-color: $primaryColor;
      color: $snow-white;
  }
}

@mixin result-search-block-categorie-selected($primaryColor){
  background-color: $primaryColor;
  color: $snow-white;
}

@mixin result-search-block-title-search($primaryColor){
  color: $primaryColor;
  border-color: $primaryColor;
}

@mixin result-search-button-orderby($firstColor, $secondColor){
  color: $firstColor;
  border: 1px solid $secondColor;
  background-color: transparent;
  &:hover{
      background-color: $secondColor;
  }
}

@mixin result-search-name-document($firstColor, $secondColor){
  color: $firstColor;
  .result-search__name-document--red{
      color: $secondColor;
  }
}

@mixin result-search-results-repeat($borderColor, $backgroundColor, $color){
  border-color: $borderColor;
  &:hover {
      background-color: $backgroundColor;
      .result-search__icon-circle {
          color: $color;
      }
      .result-search__block-icons-result {
          visibility: visible;
      }
  }
}

.result-search {
  &__table {
      width: 5%;
      text-align: right;
      display: table-cell;
      height: 30px;
      vertical-align: middle;
      border: none;
  }

  &__list-menu-block {
      margin-left: -200px;
      width: 207px;
      border: 1px solid $primary-color;
      background-color: $snow-white;
      position: absolute;
      padding: 0;
      z-index: 2;
      border-radius: 0px;
      float: none;
      transform: none !important;
      inset: auto !important;
      top: 5px !important;
      left: -200px !important;
  }

  &__block-lists {
      width: 100%;
      position: relative;
      float: left;
      padding: 0px;

      &--juridica {
          margin-top: 64px;
      }
  }
  &__block-results {
      width: 100%;
      position: relative;
      float: left;
      padding: 0px;
  }

  &__settings-block {
      min-height: 1102px;
      width: 317px;
      background-color: $background-grey-disabled-2;
      position: relative;
      float: left;
      margin: 0px 90px 0px 0px;
  }

  &__block-icon-filter {
      position: relative;
      right: 0px;
      top: 0px;
      height: 42px;
      width: 56px;
      display: block;
      float: right;
      background-color: $background-grey-other;
      border: 1px solid $menu-icons-border;
      -webkit-border-top-left-radius: 100px;
      -webkit-border-bottom-left-radius: 100px;
      -moz-border-radius-topleft: 100px;
      -moz-border-radius-bottomleft: 100px;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      color: $primary-color;
  }

  &__block-search {
      width: 100%;
  }

  &__results-content-block {
      position: relative;
      float: right;
      width: calc(100% - 428px);
      padding-right: 5px;
      min-height: 1102px;
  }

  &__none-results-element {
      position: relative;
      float: left;
      width: 100%;
      height: 800px;
      border-top: 1px solid $box-comment-border-02;
      padding-top: 12px;
  }

  &__none-results-element-span {
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 16px;
  }

  &__results-repeat {
      position: relative;
      float: left;
      width: 100%;
      min-height: 94px;
      padding-left: 8px;
      border-bottom: 1px solid $box-comment-border-02;
      &--first {
          border-top: 1px solid $box-comment-border-02;
      }

      &:last-child {
          .result-search__line-circle {
              height: calc(100% - 5px);
          }
      }

      &--collapsed {
          min-height: 36px;
          max-height: 36px;
          height: 36px;
      }

      &--juridica {
          height: 100%;
          min-height: 94px;
      }
  }

  &__result-title {
      position: relative;
      float: left;
      width: 100%;
      margin-top: 2px;
      margin-bottom: 10px;
  }

  &__icon-circle {
      display: block;
      position: absolute;
      top: -6.5px;
      left: -17px;
      font-size: 11px;
      color: $box-comment-border-02;
  }

  &__line-circle {
      position: absolute;
      top: 5px;
      left: -11.8px;
      height: calc(100% - 11px);
      width: 1px;
      background-color: $primary-color;
      &--collapsed {
          height: 25px;
      }
  }

  &__result-content {
      position: relative;
      float: left;
      width: 100%;
  }

  &__result-content-element {
      position: relative;
      float: left;
      margin-right: 15px;
  }

  &__block-icons-result {
      position: absolute;
      right: 6px;
      top: 6px;
      color: $primary-color;
      font-size: 20px;
      visibility: hidden;
      width: 30px;
  }

  &__icon-list-result {
      background-color: transparent;
      border: none;
      outline: none;
      padding: 1px;
      font-weight: bold;
  }

  &__body-setting {
      width: 100%;
      position: relative;
      float: left;
      padding: 10px;
  }

  &__body-setting-header {
      width: 100%;
      position: relative;
      float: left;
      padding: 10px 10px 0;
  }

  &__enable-deleted-container {
      padding: 10px 10px 10px 0;
      float: left;
  }

  &__block-title-search {
      position: relative;
      float: left;
      width: 100%;
      margin-top: 15px;
      border-bottom: 1px solid $primary-color;
      color: $primary-color;
      &--juridica {
          margin-top: 67px;
      }
  }

  &__icon-search {
      font-size: 24px;
  }

  &__block-subtitle-search {
      position: relative;
      float: left;
      width: 100%;
      margin-top: 27px;
      margin-bottom: 15px;
      font-family: MTTMilano, sans-serif;
      font-size: 16px;
      color: $bar-horizontal-task-grey;
  }

  &__title-text {
      font-family: MTTMilano, sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 16px;
      top: -5px;
      left: 10px;
      position: relative;
  }

  &__block-search-results {
      position: relative;
      float: left;
      width: 100%;
  }

  &__block-header-search {
      position: relative;
      float: left;
      width: 100%;
      height: 155px;
      &--documentation {
          height: 77px;
      }
  }

  &__block-header-categories {
      position: relative;
      float: left;
      width: 100%;
      height: 50px;
      margin-bottom: 40px;
  }

  &__block-select-categories {
      border: 2px solid $primary-color;
      height: 50px;
      position: relative;
      float: left;
  }

  &__block-categorie {
      position: relative;
      float: left;
      height: 46px;
      background-color: transparent;
      text-align: center;
      font-size: 12px;
      font-family: MTTMilano, sans-serif;
      color: $primary-color;
      text-transform: uppercase;
      padding: 8px 34px;
      width: auto;
      border-right: 2px solid $primary-color;
      &--last {
          border: none;
      }
  }

  &__icon-juridica {
      font-size: 19px;
  }

  &__text-cat-name {
      font-size: 10px;
      font-weight: bold;
      margin: 1px;
      text-transform: uppercase;
  }

  &__block-pill-total {
      position: absolute;
      float: right;
      font-size: 12px;
      font-weight: bold;
      font-family: MTTMilano, sans-serif;
      color: $primary-color;
      text-transform: uppercase;
      right: 15px;
      top: 19px;
  }

  &__none-results-content-block {
      position: relative;
      float: left;
      width: 100%;
      height: 680px;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      text-align: center;
      line-height: 25px;
      padding-top: 100px;
  }

  &__none-primary-text {
      font-size: 34px;
      margin-top: 10px;
      margin-bottom: 5px;
  }

  &__none-secondary-text {
      font-size: 24px;
  }

  &__icon-none-result {
      font-size: 80px;
      font-family: Arial, sans-serif;
  }

  &__button-filter {
      font-size: 25px;
      border: none;
      background: none;
      outline: none;
      margin: 9px 5px 7px 18px;
      padding: 0px;

      &--delete {
          cursor: pointer;
      }

      &--clear {
          cursor: initial;
      }
  }

  &__pill-button {
      border: 2px solid $primary-color;
      border-radius: 40px;
      text-align: center;
      display: inline-block;
      width: 40px;
      font-size: 14px;
      font-family: Arial, sans-serif;
      margin-left: 5px;
  }

  &__block-select-order {
      position: relative;
      float: left;
      width: 100%;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      font-weight: bold;
      color: $primary-color;
      text-transform: uppercase;
      &--documentation {
          margin-top: 18px;
      }
  }

  &__block-orderby {
      position: relative;
      float: left;
      width: auto;
  }

  &__block-text-orderby {
      position: relative;
      float: left;
      left: -22px;
      &--relevance {
          left: 1px;
          top: 3px;
      }
  }

  &__icon-arrow-order {
      font-size: 20px;
      &:hover {
          cursor: pointer;
      }
  }

  &__text-orderby {
      position: relative;
      top: -4px;
      margin-right: 8px;
  }

  &__button-orderby {
      height: 23px;
      min-width: 100px;
      border: 2px solid $subcontainer-ground;
      background-color: $background-grey;
      text-align: center;
      padding-top: 3px;
      font-size: 10px;
      outline: 0;
      position: relative;
      top: -4px;
      color: $primary-color;
      text-transform: uppercase;
      margin: 0 2px;
      &--relevance {
          cursor: initial;
      }
  }

  &__block-collapse {
      position: relative;
      float: right;
      right: 0px;
  }

  &__button-collapse {
      border: none;
      outline: none;
      background-color: transparent;
      padding-left: 0px;
      padding-right: 0px;
  }

  &__text-order-collapse {
      position: relative;
      top: -4px;
  }

  &__title-header {
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      margin-right: 3px;
  }

  &__title-text-search {
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      margin-left: 4px;
  }

  &__name-document {
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      font-weight: bold;
      @include result-search-name-document($primary-color, $alert-color);
      &--juridica {
          cursor: pointer;
      }
  }

  &__is-deleted {
      color: $primary-color;
      background-color: $other-gray-03;
      font-size: 10px;
      margin-left: 20px;
      padding: 3px;
  }

  &__pagination {
      text-align: center;
      position: relative;
      float: left;
      margin: 100px auto 0;
      min-height: 22px;
      display: block;
      width: 100%;
      bottom: 0;
  }

  &__spinner {
      width: 10px;
  }

  &__block-lists-element {
      font-family: MTTMilano, sans-serif;
      color: $primary-color;
      font-size: 12px;
      height: 50px;
      font-weight: bold;
      cursor: pointer;
      &--selected {
          background-color: $subcontainer-border;
      }
      &:hover {
          border-bottom: 1px solid $subcontainer-border;
      }
  }

  &__block-lists-text {
      padding-top: 7px;
      padding-left: 10px;
  }

  &__block-lists-number {
      padding-left: 10px;
  }

  &__juridica-filter-selected-block {
      height: 50px;
      width: 6px;
      background-color: $primary-color;
      position: absolute;
      left: -9px;
  }

  &__result-juridica-entradilla {
      margin-top: 10px;
  }

  &__result-juridica-title {
      margin-top: 10px;
  }

  &__result-juridica-resumen {
      margin-top: 10px;
      margin-bottom: 20px;
      display: flex;
  }

  &__name-entradilla {
      opacity: 0.5;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      margin-right: 3px;
  }

  &__name-resumen {
      color: $secondary-color;
  }

  &__result-quote-open {
      color: $result-search-quote-color;
      font-size: 40px;
      margin: 0 20px;
  }
  ////////////////////////
  ///////Version 2////////
  ////////////////////////
  &__header-v2 {
      margin-bottom: 10px;
  }
  ////////////////////////
  ///////Fin Version 2////////
  ////////////////////////
  &__other-rules {
      color: $text-field-color-icam;
      margin: 22px 0 13px 1px;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      text-transform: uppercase;
  }

  &__totals-block {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      float: right;
  }

  &__totals-text {
      width: fit-content;
      float: left;
      text-transform: uppercase;
      margin-right: 10px;
      margin-top: 7px;
  }

  &__highlight-block {
      width: 100%;
      display: block;
      margin: 0;
      overflow: hidden;
      padding-top: 2px;
      padding-bottom: 20px;
  }

  &__highlight-qoute-block {
      width: 50px;
      float: left;
  }

  &__highlights-text {
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;

      &--red {
          color: $alert-color;
      }

      & em {
          color: $alert-color;
          font-style: normal;
      }
  }

  &__highlights-quote {
      font-size: 40px;
      color: $background-grey-icam;
  }
}


.compliance{
  &__dark-blue{
    .result-search{
      &__settings-block {
        background-color: $background-color-states;
      }
      &__none-results-element-span {
        opacity: 0.4;
      }
      &__results-repeat {
        @include result-search-results-repeat($box-comment-border-02, $background-color-states, $primary-color);
      }
      &__line-circle {
        opacity: 0.09;
      }
      &__block-title-search {
        @include result-search-block-title-search($primary-color);
      }
      &__block-categorie {
        @include result-search-block-categorie($primary-color);
        &--selected{
            @include result-search-block-categorie-selected($primary-color);
        }
      }
      &__none-secondary-text {
        opacity: 0.5;
      }
      &__button-orderby {
        background-color: $background-color-states;
        &--selected{
            border-color: $primary-color;
            background-color: $subcontainer-border;
        }
      }
      &__title-header{
        opacity: 0.5;
      }
      &__totals-block {
        color: $primary-color;
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue{
    .result-search{
      &__list-menu-block {
        border-color: $third-color-2;
        border-top-width: 2px;
      }
      &__settings-block {
        background-color: $background-grey-disabled-2;
      }
      &__block-icon-filter {
        background-color: $secondary-color-2;
        border-color: $secondary-color-2;
        color: $primary-color-2;
        &--delete{
            background-color: $third-color-2;
        }
      }
      &__none-results-element {
        border-color: $secondary-color-2;
      }
      &__none-results-element-span {
        color: $grey-icons-2;
      }
      &__results-repeat {
        @include result-search-results-repeat($secondary-color-2, $block-hover-2, $border-hover-2);
      }
      &__icon-circle {
        color: $secondary-color-2;
      }
      &__line-circle {
        background-color: $third-color-2;
      }
      &__block-title-search {
        @include result-search-block-title-search($primary-color-2);
      }
      &__block-subtitle-search, &__other-rules {
        color: $grey-icons-2;
      }
      &__block-select-categories {
        border-color: $primary-color-2;
      }
      &__block-categorie {
        @include result-search-block-categorie($primary-color-2);
        &--selected{
            @include result-search-block-categorie-selected($primary-color-2);
        }
      }
      &__none-results-content-block {
        color: $primary-color-2;
      }
      &__none-secondary-text {
        color: $grey-icons-2;
      }
      &__block-select-order {
        color: $border-hover-2;
      }
      &__button-orderby {
        @include result-search-button-orderby($border-hover-2, $third-color-2);
        &--selected {
            background-color: $third-color-2;
        }
      }
      &__title-header {
        color: $primary-color-2;
      }
      &__title-text-search {
        color: $text-field-color-2;
      }
      &__name-document {
        @include result-search-name-document($primary-color-2, $alert-color-2);
      }
      &__is-deleted {
        background-color: $secondary-color-2;
        color: $primary-color-2;
      }
      &__totals-block {
        color: $primary-color-2;
      }
    }
  }
  &__icam-red{
    .result-search{
      &__list-menu-block {
        border-color: $primary-color-icam;
        border-top-width: 2px;
      }
      &__settings-block {
        background-color: $background-grey-disabled-icam;
      }
      &__block-icon-filter {
        background-color: $primary-color-icam;
        border-color: $primary-color-icam;
        color: $snow-white;
      }
      &__none-results-element {
        border-color: $border-block-icam;
      }
      &__none-results-element-span {
        color: $secondary-color-icam;
      }
      &__results-repeat {
        @include result-search-results-repeat($border-block-icam, $hover-block-icam, $primary-color-icam);
      }
      &__icon-circle {
        color: $border-block-icam;
      }
      &__line-circle {
        background-color: $third-color-icam;
      }
      &__block-title-search {
        @include result-search-block-title-search($custom-black-landing);
      }
      &__block-subtitle-search, &__other-rules {
        color: $text-field-color-icam;
      }
      &__block-select-categories {
        border-color: $custom-black-landing;
      }
      &__block-categorie {
        @include result-search-block-categorie($custom-black-landing);
        &--selected{
            @include result-search-block-categorie-selected($custom-black-landing);
        }
      }
      &__none-results-content-block {
        color: $custom-black-landing;
      }
      &__none-secondary-text {
        color: $secondary-color-icam;
      }
      &__block-select-order {
        color: $primary-color-icam;
      }
      &__button-orderby {
        @include result-search-button-orderby($primary-color-icam, $third-color-icam);
        &--selected {
            background-color: $third-color-icam;
        }
      }
      &__title-header {
        color: $custom-black-landing;
      }
      &__title-text-search {
        color: $secondary-color-icam;
      }
      &__name-document {
        @include result-search-name-document($custom-black-landing, $alert-color-icam);
      }
      &__is-deleted {
        background-color: $third-color-icam;
        color: $primary-color-icam;
      }
      &__totals-block {
        color: $title-text-icam;
      }
      &__highlights-text {
        color: $custom-black-landing;
        &--red {
          color: $alert-color-icam;
        }
      }
    }
  }
  &__gm-law{
    .result-search{
      &__list-menu-block {
        border-color: $primary-color-gm;
        border-top-width: 2px;
      }
      &__settings-block {
        background-color: $background-grey-disabled-gm;
      }
      &__block-icon-filter {
        background-color: $primary-color-gm;
        border-color: $primary-color-gm;
        color: $snow-white;
      }
      &__none-results-element {
        border-color: $border-block-gm;
      }
      &__none-results-element-span {
        color: $secondary-color-gm;
      }
      &__results-repeat {
        @include result-search-results-repeat($border-block-gm, $hover-block-gm, $primary-color-gm);
      }
      &__icon-circle {
        color: $border-block-gm;
      }
      &__line-circle {
        background-color: $third-color-gm;
      }
      &__block-icons-result {
        color: $primary-color-gm;
      }
      &__block-title-search {
        @include result-search-block-title-search($custom-black-landing);
      }
      &__block-subtitle-search, &__other-rules {
        color: $text-field-color-gm;
      }
      &__block-select-categories {
        border-color: $custom-black-landing;
      }
      &__block-categorie {
        @include result-search-block-categorie($custom-black-landing);
        &--selected{
            @include result-search-block-categorie-selected($custom-black-landing);
        }
      }
      &__none-results-content-block {
        color: $custom-black-landing;
      }
      &__none-secondary-text {
        color: $secondary-color-gm;
      }
      &__block-select-order {
        color: $primary-color-gm;
      }
      &__button-orderby {
        @include result-search-button-orderby($primary-color-gm, $third-color-gm);
        &--selected {
            background-color: $third-color-gm;
        }
      }
      &__title-header {
        color: $custom-black-landing;
      }
      &__title-text-search {
        color: $secondary-color-gm;
      }
      &__name-document {
        @include result-search-name-document($custom-black-landing, $alert-color-gm);
      }
      &__block-lists-element {
        color: $primary-color-gm;
        &--selected {
            background-color: $border-block-gm;
        }
        &:hover {
            border-bottom: 1px solid $border-block-gm;
        }
      }
      &__block-lists-text {
        color: $custom-black-landing;
      }
      &__block-lists-number {
        color: $custom-black-landing;
      }
      &__juridica-filter-selected-block {
        background-color: $primary-color-gm;
      }
      &__name-resumen {
        color: $secondary-color-gm;
      }
      &__result-quote-open {
        color: $border-block-gm;
      }
      &__totals-block {
        color: $title-text-gm;
      }
    }
  }
  &__com-de{
    .result-search{
      &__list-menu-block {
        border-color: $primary-color-com-de;
        border-top-width: 2px;
      }
      &__settings-block {
        background-color: $background-grey-disabled-com-de;
      }
      &__block-icon-filter {
        background-color: $primary-color-com-de;
        border-color: $primary-color-com-de;
        color: $snow-white;
      }
      &__none-results-element {
        border-color: $border-block-com-de;
      }
      &__none-results-element-span {
        color: $secondary-color-com-de;
      }
      &__results-repeat {
        @include result-search-results-repeat($border-block-com-de, $hover-block-com-de, $primary-color-com-de);
      }
      &__icon-circle {
        color: $border-block-com-de;
      }
      &__line-circle {
        background-color: $third-color-com-de;
      }
      &__block-icons-result {
        color: $primary-color-com-de;
      }
      &__block-title-search {
        @include result-search-block-title-search($custom-black-landing);
      }
      &__block-subtitle-search, &__other-rules {
        color: $text-field-color-com-de;
      }
      &__block-select-categories {
        border-color: $custom-black-landing;
      }
      &__block-categorie {
        @include result-search-block-categorie($custom-black-landing);
        &--selected{
            @include result-search-block-categorie-selected($custom-black-landing);
        }
      }
      &__none-results-content-block {
        color: $custom-black-landing;
      }
      &__none-secondary-text {
        color: $secondary-color-com-de;
      }
      &__block-select-order {
        color: $primary-color-com-de;
      }
      &__button-orderby {
        @include result-search-button-orderby($primary-color-com-de, $third-color-com-de);
        &--selected {
            background-color: $third-color-com-de;
        }
      }
      &__title-header {
        color: $custom-black-landing;
      }
      &__title-text-search {
        color: $secondary-color-com-de;
      }
      &__name-document {
        @include result-search-name-document($custom-black-landing, $alert-color-com-de);
      }
      &__block-lists-element {
        color: $primary-color-com-de;
        &--selected {
            background-color: $border-block-com-de;
        }
        &:hover {
            border-bottom: 1px solid $border-block-com-de;
        }
      }
      &__block-lists-text {
        color: $custom-black-landing;
      }
      &__block-lists-number {
        color: $custom-black-landing;
      }
      &__juridica-filter-selected-block {
        background-color: $primary-color-com-de;
      }
      &__name-resumen {
        color: $secondary-color-com-de;
      }
      &__result-quote-open {
        color: $border-block-com-de;
      }
      &__totals-block {
        color: $title-text-com-de;
      }
    }
  }
  &__com-fr{
    .result-search{
      &__list-menu-block {
        border-color: $primary-color-com-fr;
        border-top-width: 2px;
      }
      &__settings-block {
        background-color: $background-grey-disabled-com-fr;
      }
      &__block-icon-filter {
        background-color: $primary-color-com-fr;
        border-color: $primary-color-com-fr;
        color: $snow-white;
      }
      &__none-results-element {
        border-color: $border-block-com-fr;
      }
      &__none-results-element-span {
        color: $secondary-color-com-fr;
      }
      &__results-repeat {
        @include result-search-results-repeat($border-block-com-fr, $hover-block-com-fr, $primary-color-com-fr);
      }
      &__icon-circle {
        color: $border-block-com-fr;
      }
      &__line-circle {
        background-color: $third-color-com-fr;
      }
      &__block-icons-result {
        color: $primary-color-com-fr;
      }
      &__block-title-search {
        @include result-search-block-title-search($custom-black-landing);
      }
      &__block-subtitle-search, &__other-rules {
        color: $text-field-color-com-fr;
      }
      &__block-select-categories {
        border-color: $custom-black-landing;
      }
      &__block-categorie {
        @include result-search-block-categorie($custom-black-landing);
        &--selected{
            @include result-search-block-categorie-selected($custom-black-landing);
        }
      }
      &__none-results-content-block {
        color: $custom-black-landing;
      }
      &__none-secondary-text {
        color: $secondary-color-com-fr;
      }
      &__block-select-order {
        color: $primary-color-com-fr;
      }
      &__button-orderby {
        @include result-search-button-orderby($primary-color-com-fr, $third-color-com-fr);
        &--selected {
            background-color: $third-color-com-fr;
        }
      }
      &__title-header {
        color: $custom-black-landing;
      }
      &__title-text-search {
        color: $secondary-color-com-fr;
      }
      &__name-document {
        @include result-search-name-document($custom-black-landing, $alert-color-com-fr);
      }
      &__block-lists-element {
        color: $primary-color-com-fr;
        &--selected {
            background-color: $border-block-com-fr;
        }
        &:hover {
            border-bottom: 1px solid $border-block-com-fr;
        }
      }
      &__block-lists-text {
        color: $custom-black-landing;
      }
      &__block-lists-number {
        color: $custom-black-landing;
      }
      &__juridica-filter-selected-block {
        background-color: $primary-color-com-fr;
      }
      &__name-resumen {
        color: $secondary-color-com-fr;
      }
      &__result-quote-open {
        color: $border-block-com-fr;
      }
      &__totals-block {
        color: $title-text-com-fr;
      }
    }
  }
}
