.role-assignment-modal{
  & .modal-content {
      height: auto;
      min-height: 400px;
  }

  &__body-form{
      padding: 30px 70px 0;
  }

  &__block-fields{
      width: 485px;
      margin: 25px auto 85px;
  }
}
