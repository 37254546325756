@mixin field-type-contacts-block-header-section-active($backgroundColor, $textColor) {
  background-color: $backgroundColor;
  color: $textColor;
  &:hover{
      cursor: default;
  }
}

@mixin field-type-contacts-contact-list-to-share-color($textColor, $backgroundColor) {
  color: $textColor;
  &:hover{
      background-color: $backgroundColor;
  }
}

@mixin field-type-contacts-list-find-icon($textColor) {
  color: $textColor;
  top: 3px;
}

@mixin field-type-contacts-icon-button-color($backgroundColor, $textColor) {
  &:hover{
      background-color: $backgroundColor;
      color: $textColor;
  }
}

@mixin field-type-contacts-icon-button-active($primaryColor, $secondColor) {
  background-color: $primaryColor;
  color: $secondColor;
  border-color: $primaryColor;
}

@mixin field-type-contacts-icon-size($primaryColor, $secondColor) {
  color: $primaryColor;
  border: 2px solid $secondColor;
  background: $secondColor;
  margin-top: 0px;
  margin-right: 5px;
}

@mixin field-type-contacts-icon-size-color($borderColor, $backgroundColor) {
  border-color: $borderColor;
  background-color: $backgroundColor;
}

@mixin field-type-contacts-icons-contacts-color($primaryColor, $secondColor) {
  border-color: $primaryColor;
  color: $primaryColor;
  &:hover{
      background-color: $secondColor;
      color: $primaryColor;
  }
}

@mixin field-type-contacts-icons-contacts-selected($primaryColor, $secondColor) {
  background-color: $primaryColor;
  color: $secondColor;
}

@mixin field-type-contacts-search-color($borderColor, $textColor) {
  border-bottom-color: $borderColor;
  color: $textColor;
  font-family: MTTMilano, sans-serif;
  &::-webkit-input-placeholder {
      color: $textColor;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
  }
}

@mixin field-type-contacts-select-box($backgroundColor, $textColor) {
  background-color: $backgroundColor;
  border-color: $backgroundColor;
  color: $textColor;
}

@mixin field-type-contacts-select-list($primaryColor, $secondColor) {
  border-top-width: 2px;
  border-color: $primaryColor;
  box-shadow: $secondColor;
}

.field-type-contacts{
  &__block-header-multiple{
      // width: 330px;
      width: 364px;
      height: 50px;
  }

  &__block-header-simple{
      width: 100%;
      min-height: 43px;
  }

  &__box-dropdown{
      top: 41px;
      margin-left: 12px;
      margin-right: 12px;
      width: 149px;
      min-width: 149px;
      position: absolute;
      color: $primary-color;
      text-transform: capitalize;
      font-size: 12px;
      font-family: Arial, sans-serif;
      padding: 10px 5px 3px 5px;
      box-shadow: 0 0 5px 1px $certify-black-opacity02;
  }

  &__body-contacts{
      padding: 6px 5px 5px 3px;
  }

  &__body-contacts-simple{
      padding: 6px 5px 5px 3px;
      border-top: 1px solid $primary-color;
      background-color: $snow-white;
      &--landing-custom {
          border-color: $custom-black-landing;
      }
  }

  &__contact-block{
      display: block;
      min-height: 50px;
      border: 1px solid $primary-color;
      background-color: $snow-white;
      // margin-bottom: 10px;
      position: absolute;
      overflow: hidden;
      top: 0;
      width: 100%;
      height: 365px;
      z-index: 10;
      padding: 0px 0px 5px 0px;
      box-shadow: 0 2px 5px 5px $bar-horizontal-grey-opacity02;
      &--user{
          border-top-width: 6px;
          padding: 12px 12px 9px 12px;
      }
      &--roles{
          height: 323px;
      }
  }

  &__contacts{
      width: 100%;
      height: 50px;
      position: relative;
      padding-left: 7px;
      margin-bottom: 4px;
  }

  &__header-nav{
      width: 100%;
      display: block;
      height: 50px;
      border-bottom: 6px solid $primary-color;
      &--simple{
          border: none !important;
      }
  }

  &__item-selected{
      height: 19px;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: 500;
      width: calc(100% - 20px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0;
      color: $secondary-color;
      padding: 2px 0 0px;

      &--multiple{
          text-transform: capitalize;
          font-size: 11px;
          padding: 2px 13px 0 5px;
          width: 100%;
          max-width: 300px;
      }
  }

  &__name-selected{
      position: relative;
      padding-left: 9px;
      width: calc(100% - 75px);
      display: flex;
  }

  &__text-list{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      padding-left: 10px;
      display: table-cell;
      vertical-align: middle;
  }

  // &__selected-item{
  //     text-transform: capitalize;
  //     margin: 2px 0 0 0px;
  //     font-size: 11px;
  //     padding: 0 13px 0 5px;
  // }

  &__icons{
      padding: 0px;
      top: 3px;
      position: relative;
      font-size: 16px;
      &--role{
          left: 2px;
      }
  }

  &__icons-contact{
      padding-right: 12px;
      display: table-cell;
      vertical-align: middle;
      text-align: right;
      right: 14px;
  }

  &__icons-search{
      position: relative;
      float: right;
      // text-align: right;
  }

  &__icon-button{
      display: inline-block;
      border: 2px solid $snow-white;
      // border-right-width: 1px;
      border-left-width: 1px;
      border-right-width: 0px;
      padding: 0px;
      width: 33px;
      height: 24px;
      text-align: center;
      &:hover{
          @include field-type-contacts-icon-button-color($snow-white, $primary-color);
          cursor: pointer;
      }
      &--first{
          border-radius: 115px 0 0 115px;
          // border-right: none;
          border-left-width: 2px;
      }
      &--last{
          border-radius: 0 115px 115px 0;
          // border-left: none;
          border-right-width: 2px;
      }
      &--unique{
          border-radius: 115px;
          border-width: 2px;
      }
  }

  &__icon-option-left{
      cursor: pointer;
      font-size: 19px;
      vertical-align: -4px;
  }

  &__icon-option{
      vertical-align: -3px;
      font-size: 16px;
      cursor: pointer;
  }

  &__block-header-section{
      float: left;
      display: table;
      padding: 5px 0 0 0;
      height: 44px;
      color: $primary-color;
      width: 220px;
      &:hover{
          cursor: pointer;
      }
      &--selection{
          width: 144px;
      }
  }

  &__selected-icons{
      width: 75px;
      // height: 44px;
      float: right;
      &--multiple{
          width: 95px;
      }
  }

  &__options-icon{
      display: table-cell;
      vertical-align: middle;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      position: relative;
      padding-left: 10px;
  }

  &__label-block{
      position: relative;
  }

  &__input-block-text{
      color: $primary-color;
      font-size: 12px;
      font-weight: bold;
      font-family: MTTMilano, sans-serif;
      text-transform: uppercase;
      width: 100%;
      &:hover{
          & .common__tooltip-law, .common__info-icon-block, .common__tooltip-law-block {
              visibility: visible;
              // display: block;
          }
          & .common__tooltip-info-box {
              display: block;
          }
      }
  }

  &__icon-label{
      position: absolute;
      right: 6px;
      top: 6px;
      font-size: 15px;
      cursor: pointer;
      &--disabled{
          cursor: not-allowed;
          opacity: 0.3;
      }
  }

  &__block-contacts{
      display: flex;
      width: 100%;
      min-height: 22px;
  }

  &__block-elements{
      padding: 0 0 0 9px;
      position: relative;
      width: 100%;
      &--simple{
          top: 1px;
      }
  }

  &__multiselect-block{
      list-style: none;
      margin: 0;
      display: block;
      float: left;
      width: 100%;
      padding: 0;
      line-height: 6px;
  }

  // &__header-icon{
  //     border: 1.5px solid $primary-color;
  //     padding: 5px 5px 2px 5px;
  //     &:hover{
  //         background-color: $primary-color;
  //         color: $snow-white;
  //         cursor: pointer;
  //     }
  //     &--close{
  //         color: #AAAAAA;
  //         border: 1.5px solid #AAAAAA;
  //         &:hover{
  //             background-color: #AAA;
  //             color: $snow-white;
  //             cursor: pointer;
  //         }
  //     }

  // }

  &__title-contact-list{
      color: $subcontainer-ground;
      font-size: 10px;
      font-weight: bold;
      margin-bottom: 0;
      font-family: Arial, sans-serif;
      text-transform: uppercase;
      float: left;
      position: relative;
      top: 10px;
      &--simple{
          width: 100%;
      }
  }

  &__search-multiple{
      width: 100%;
      position: relative;
      height: 25px;
      border: none;
      border-bottom: 1px solid $primary-color;
      right: 0px;
      font-size: 12px;
      padding: 3px 22px 3px 6px;
      outline: none;
      &::-webkit-input-placeholder{
          font-style: normal;
          padding: 0px;
      }
  }

  &__search-simple-block{
      width: calc(100% - 5px);
      position: relative;
      float: left;
      &--2-elements{
          width: calc(100% - 73px);
      }
      &--3-elements{
          width: calc(100% - 103px);
      }
  }

  &__search-simple{
      width: 100%;
      position: relative;
      height: 25px;
      border: none;
      border-bottom: 1px solid $primary-color;
      float: left;
      font-size: 12px;
      padding: 3px 22px 3px 6px;
      outline: none;
      &::-webkit-input-placeholder{
          font-style: normal;
          padding: 0px;
      }
  }

  &__contact-book{
      list-style: none;
      padding-left: 0;
      margin-bottom: 0px;
  }

  // &__line-separator{
  //     border-bottom: 1px solid #AAAAAA;
  //     margin-top: 2px;
  //     list-style: none;
  //     margin-left: 4px;
  //     width: 63%;
  //     margin-bottom: 5px;
  // }

  &__toggle-all {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
    font-size: 12px;
    height: 29px;
    padding-left: 6px;
    cursor: pointer;
    border: 1px solid;
  }

  &__icon-all {
    font-size: 20px;
  }

  &__text-all {
    margin-left: 10px;
  }

  &__contact-list-to-share{
      font-family: Arial, sans-serif;
      font-size: 12px;
      // height: 34px;
      display: block;
      overflow: hidden;
      position: relative;
      height: 29px;
      padding-left: 7px;
      &:hover{
          // background-color: $background-grey;
          cursor: pointer;
      }

      &--simple{
          &:hover{
              & > .field-type-contacts__select-icon {
                  display: block;
              }
          }
      }
  }

  &__icons-contacts{
      display: inline-block;
      bottom: 1px;
      width: 33px;
      height: 26px;
      border: 2px solid $primary-color;
      border-right-width: 0px;
      position: relative;
      text-align: center;
      border-color: $primary-color;
      color: $primary-color;
      &:hover{
          background-color: $primary-color;
          color: $snow-white;
          cursor: pointer;
      }
      &--first{
          border-radius: 115px 0 0 115px;
      }
      &--last{
          border-radius: 0 115px 115px 0;
          border-right-width: 2px;
      }
      &--disabled{
        color: $subcontainer-ground !important;
          &:hover{
              cursor: default;
              background: none;
              border: 0;
          }
      }
  }

  &__checkbox-icon-all {
    font-size: 15px;
  }

  &__checkbox-size-icon{
      font-size: 15px;
      position: relative;
      top: 2px;
  }

  &__icon-contact{
      font-size: 20px;
      margin-left: 5px;
      margin-right: 5px;
      position: relative;
      top: 4px;
  }

  &__text-contact{
      position: relative;
      top: 0px;
  }

  &__selection-icons{
      float: right;
      margin-right: 5px;
      margin-top: 5px;
      width: 100%;
      // height: 44px;
      text-align: right;
      position: relative;
  }

  &__list-find-icon{
      font-size: 20px;
      color: $primary-color;
      position: absolute;
      right: 0px;
      top: 0px;
      &:hover{
          cursor: pointer;
      }
  }

  &__select-box{
      position: relative;
      left: 8px;
      top: 0px;
      margin: 0;
      background: $primary-color;
      color: $snow-white;
      font-family: Arial, sans-serif;
      font-weight: bold;
      font-style: normal;
      font-size: 12px;
      text-align: center;
      display: inline-block;
      border: 2px solid $primary-color;
      width: 33px;
      height: 19px;
      border-radius: 12px;
      line-height: 1.42857;
  }

  &__inner-box{
      display: block;
      overflow-y: auto;
      width: 100%;
      height: 267px;
      overflow-x: hidden;
      padding: 0 3px 0 0px;
      &--simple, &--multiple{
          height: 241px;
      }
      &--sms{
          height: 190px;
      }
      &--custom-sms, &--roles{
          height: 205px;
      }
  }

  &__error-text{
      background: $red-opacity-02;
      height: 27px;
      width: 174px;
      margin: 0;
      padding: 6px 4px 4px 10px;
      color: $red;
      font-family: Arial, sans-serif;
      font-size: 12px;
      margin-top: 9px;
      margin-left: 3px;
  }

  &__add-new-title-block{
      display: block;
      overflow: hidden;
      width: 100%;
      color: $subcontainer-ground;
  }

  &__add-new-icon{
      font-size: 17px;
      float: left;
      padding-right: 8px;
  }

  &__add-new-title-text{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
  }

  &__block-icon-sign{
      position: absolute;
      top: 6px;
      right: 20px;
  }

  &__select-list{
      width: 174px;
      border-radius: 0px;
      border: 1px solid $primary-color;
      padding: 0;
      padding-bottom: 10px;
      min-height: 204px;
      max-height: 296px;
      overflow: hidden;
      float: right;
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 11;
      background-color: $snow-white;
  }

  &__title-icon{
      height: 24px;
      font-family: Arial, sans-serif;
      font-size: 10px;
      background: $primary-color;
      color: $snow-white;
      padding: 3px;
      border-top: 3px solid $primary-color;
      margin: 0px 12px 25px 12px;
      &--red{
          background: $red;
      }
      &--green{
          background: $green;
      }
      &--grey{
          background: $landing-button-creation;
      }
  }
  &__title-icon-title{
      float: right;
      text-transform: capitalize;
      font-weight: bold;
  }

  &__title-icon-icon-quantity{
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      border: 2px solid $snow-white;
      width: 27px;
      height: 15px;
      border-radius: 12px;
      margin-right: 0px;
      margin-left: 5px;
      color: $primary-color;
      float: right;
      background-color: $snow-white;
      line-height: 12px;
      text-align: center;
      display: inline-block;
      position: relative;
      bottom: 1px;
  }

  &__title-icon-icon-filter{
      font-size: 20px;
      padding-left: 3px;
      border: none;
      background: none;
      outline: 0;
      position: relative;
      bottom: 4px;
  }

  &__title-box{
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      padding: 6px 25px 2px;
      width: 160px;
      margin-left: 5px;
      margin-bottom: 2px;
      text-align: center;
      text-transform: uppercase;
  }

  &__icon-close{
      font-size: 10px;
      font-weight: bold;
      margin-right: 5px;
      margin-top: 5px;
      float: right;
      cursor: pointer;
  }

  &__signed-user-icon{
      font-size: 10px;
      color: $green;
      margin-right: 5px;
  }

  &__cancel-user-icon{
      font-size: 10px;
      color: $red;
      margin-right: 5px;
  }

  &__icon{
      padding-right: 5px;
      font-size: 14px;
      &--red{
          color: $red;
      }
      &--green{
          color: $green;
      }
      &--grey{
          color: $landing-button-creation;
      }
  }

  &__select-item{
      padding: 5px 0px;
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: bold;
      color: $secondary-color;
      overflow-y: auto;
      overflow-x: hidden;
      text-transform: capitalize;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }

  &__select-list-item{
      overflow-y: auto;
      overflow-x: hidden;
      width: 146px;
      padding: 0;
      max-height: 210px;
      float: right;
      position: relative;
      margin-left: 13px;
      margin-right: 13px;
  }

  &__subline-box{
      position: absolute;
      height: 1px;
      background: $primary-color;
      margin: 2px -3px 0px -3px;
  }

  // &__no-editable {
  //     background: $background-grey;
  //     //border-color: $attach-icon-grey;
  //     border-color: $result-search-quote-color;
  //     color: $secondary-color;
  //     &:hover{
  //         cursor: not-allowed;
  //         //border-color: $attach-icon-grey !important;
  //         border-color: $result-search-quote-color !important;
  //         background: $background-grey !important;
  //     }
  // }

  &__elementlist-dropdown{
      padding: 5px 3px;
      &:hover{
          cursor: pointer;
          .field-type-contacts__select-icon-dropdown{
              display: inline;
          }
      }
  }

  &__select-icon-dropdown{
      position: absolute;
      right: 8px;
      font-size: 15px;
      float: right;
      display: none;
      padding: 0 1px;
  }

  &__select-icon {
      font-size: 16px;
      float: right;
      display: none;
      padding: 6px 0px;
      margin-right: 10px;
  }

  &__block-contact-name{
      text-transform: initial;
      width: 34%;
      position: relative;
      float: left;
      height: 22px;
  }

  &__block-contact-phone{
      text-transform: initial;
      width: calc(33% - 25px);
      position: relative;
      float: left;
      height: 22px;
  }

  &__icon-selected{
      position: relative;
      float: left;
      top: 1px;
      font-size: 15px;
      color: $secondary-color;
  }

  // &__multiselect{
  //     overflow: visible !important ;
  // }

  &__block-info-message{
      height: 55px;
      width: 100%;
      background-color: $background-grey-other;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px 17px;
      color: $primary-color;
  }

  &__icon-alert {
      font-size: 35px;
      // color: $primary-color;
      margin-right: 12px;
  }

  &__text-alert{
      font-family: Arial, sans-serif;
      font-size: 12px;
      position: relative;
      bottom: 12px;
      color: $repeat-pattern;
  }

  &__icon-close-header {
      font-size: 15px;
      position: absolute;
      top: 20px;
      right: 10px;
      &:hover{
          cursor: pointer;
      }
  }

  &__icon-size{
      display: inline-block;
      position: relative;
      font-style: normal;
      font-family: Arial, sans-serif;
      font-weight: bold;
      text-align: center;
      font-size: 12px;
      border: 2px solid $primary-color;
      width: 31px;
      height: 16px;
      border-radius: 12px;
      margin-right: 14px;
      float: right;
      margin-top: 10px;
      background-color: transparent;
      line-height: 14px;
      &:hover{
          cursor: pointer;
      }
      &--signed{
        @include field-type-contacts-icon-size($snow-white, $green);
      }
      &--cancel{
        @include field-type-contacts-icon-size($snow-white, $red);
      }
      &--all{
        @include field-type-contacts-icon-size($snow-white, $primary-color);
      }
      &--pending{
        @include field-type-contacts-icon-size($snow-white, $landing-button-creation);
      }
  }

  //////////////////////////////////////////////
  ///////////////////Custom////////////////////
  ////////////////////////////////////////////
  &__block-buttons-show-custom{
      position: relative;
      float: left;
      width: 66px;
      border: none;
      border-bottom: 1px solid $custom-black-landing;
      border-left: 1px solid $custom-black-landing;
      height: 39px;
      padding: 10px 7px;
      text-align: center;
      &--bubble{
          padding: 11px 16px;
          &:hover,  &:focus, &:active{
              cursor: pointer;
              background-color: $attachment-custom-grey;
              .common__bubble-count-custom{
                  color: $snow-white;
                  border-color: $snow-white;
              }
          }
      }
      &--bubble-selected{
          background-color: $attachment-custom-grey;
          .common__bubble-count-custom{
              color: $snow-white;
              border-color: $snow-white;
          }
          &:hover,  &:focus, &:active{
              background-color: $snow-white;
              .common__bubble-count-custom{
                  color: $attachment-custom-grey;
                  border-color: $attachment-custom-grey;
              }
          }
      }
      &--exit-contact{
          width: 30px;
      }
      &--exit-contact-extend{
          width: 54px;
          padding: 10px 6px;
      }
  }

  &__icon-show-custom{
      background: transparent;
      border: none;
      outline: none;
      font-size: 17px;
      color: $custom-secondary-color;
      padding: 0;
      margin: 0 3px;
      &--selected{
          color: $custom-black-landing;
      }
      &--close{
          font-size: 12px;
          &:hover{
              color: $custom-black-landing;
              &:before {
                  content: "\e934";
              }
          }
      }
      &--confirm{
          font-size: 12px;
          &:hover{
              color: $custom-black-landing;
              &:before {
                  content: "\e92c";
              }
          }
      }
  }

  &__contact-list-to-share-custom{
      color: $custom-secondary-color;
      font-family: 'lato_regular', sans-serif;
      font-size: 14px;
      font-weight: normal;
      height: 40px;
      padding: 9px 0 9px 7px;
      display: block;
      overflow: hidden;
      position: relative;
      &:hover{
          background-color: $background-grey;
          cursor: pointer;
      }
  }

  &__icon-contact-custom{
      font-size: 18px;
      margin-left: 5px;
      margin-right: 5px;
      &--simple{
          position: relative;
          top: 2px;
      }
  }

  &__inner-box-custom{
      display: block;
      overflow-y: auto;
      width: 100%;
      height: 165px;
      overflow-x: hidden;
      padding: 0;
  }

  &__icon-selected-custom{
      position: relative;
      float: left;
      top: 1px;
      font-size: 19px;
      color: $custom-black-landing;
  }

  &__error-text-custom{
      color: $advice-close;
      font-family: 'lato_regular', sans-serif;
      font-size: 14px;
      font-weight: normal;
      margin: 55px 0 0 20px;
  }

  &__body-contacts-custom{
      padding: 6px 5px 0px 3px;
      position: relative;
      float: left;
      width: 100%;
  }

  &__title-contact-list-custom{
      color: $custom-secondary-color;
      font-family: 'lato_regular', sans-serif;
      font-size: 10px;
      font-weight: normal;
      margin-bottom: 0;
      text-transform: uppercase;
      float: left;
      position: relative;
      top: 10px;
  }

  //////////////////////////////////////////////
  ///////////////////Fin Custom////////////////////
  ////////////////////////////////////////////
}

.compliance{
&__dark-blue{
  .field-type-contacts{
    &__toggle-all {
      color: $primary-color;
      border-color: $icon-arrow;

      &:hover {
          background-color: $background-color-states;
      }
    }
    &__contact-list-to-share{
      color: $primary-color;
      border-bottom: 1px solid transparent;
      &:hover{
          background-color: $background-color-states;
          border-bottom: 1px solid $primary-color;
      }
      &--disabled{
          color: $subcontainer-ground;
          &:hover{
              cursor: default;
              background: none;
              border: 0;
          }
      }
    }
    &__elementlist-dropdown{
      border-bottom: 1px solid transparent;
      &:hover{
          border-bottom: 1px solid $subcontainer-poll-border;
      }
    }
    &__icon-button{
        &--active{
            @include field-type-contacts-icon-button-active($snow-white, $primary-color);
        }
        &--no-parent-active{
            border-color: $primary-color;
        }
    }
    &__icons-contacts{
      &--selected{
        @include field-type-contacts-icons-contacts-selected($primary-color, $snow-white);
      }
    }
    &__select-box{
        &--container{
            background-color: $picker-visibility-container;
            border-color: $picker-visibility-container;
        }

        &--active{
            @include field-type-contacts-select-box($snow-white, $primary-color);
        }
    }
    &__search-multiple{
        @include field-type-contacts-search-color($primary-color, $primary-color);
    }
    &__search-simple{
      @include field-type-contacts-search-color($primary-color, $primary-color);
    }
    &__selection-icons{
      &--simple{
        margin-top: 2px;
      }
    }
    &__block-header-section{
        &--active{
            @include field-type-contacts-block-header-section-active($primary-color, $snow-white);
        }
    }
  }
}
&__steel-blue, &__uk-steel-blue{
  .field-type-contacts{
    &__add-new-title-block{
        color: $grey-icons-2;
    }
    &__body-contacts-simple{
      border-color: $border-hover-2;
    }
    &__contact-block{
      border-color: $third-color-2;
      border-top-color: $primary-color-2;
    }
    &__toggle-all {
      @include field-type-contacts-contact-list-to-share-color($border-hover-2, $third-color-rgba-mid);
    }
    &__contact-list-to-share{
      @include field-type-contacts-contact-list-to-share-color($border-hover-2, $third-color-rgba-mid);
      &--disabled{
          color: $subcontainer-ground;
          &:hover{
              cursor: default;
              background: none;
              border: 0;
          }
      }
    }
    &__elementlist-dropdown{
      @include field-type-contacts-contact-list-to-share-color($border-hover-2, $third-color-2);
    }
    &__error-text{
      background: $background-other-error-2;
      color: $alert-color-2;
    }
    &__icon-search{
      top: 0px;
    }
    &__icon-selected{
      color: $text-field-color-2;
    }
    &__icon-size{
      &--signed{
        @include field-type-contacts-icon-size-color($green-color-v2, $green-color-v2);
      }
      &--cancel{
        @include field-type-contacts-icon-size-color($alert-color-2, $alert-color-2);
      }
      &--all{
        @include field-type-contacts-icon-size-color($border-hover-2, $border-hover-2);
      }
      &--pending{
        @include field-type-contacts-icon-size-color($grey-icons-2, $grey-icons-2);
      }
    }
    &__icon-button{
        @include field-type-contacts-icon-button-color($block-hover-2, $primary-color-2);
        &--active{
            @include field-type-contacts-icon-button-active($third-color-2, $primary-color-2);
        }
        &--no-parent-active{
            border-color: $primary-color-2;
        }
    }
    &__icons-contacts{
      @include field-type-contacts-icons-contacts-color($border-hover-2, $third-color-2);
      &--selected{
        @include field-type-contacts-icons-contacts-selected($border-hover-2, $snow-white);
      }
    }
    &__header-nav{
        background-color: $third-color-2;
        border-color: $primary-color-2;
    }
    &__item-selected{
      color: $text-field-color-2;
    }
    &__list-find-icon{
      @include field-type-contacts-list-find-icon($border-hover-2);
    }
    &__select-box{
         @include field-type-contacts-select-box($primary-color-2, $white-special-2);
        &--active{
            @include field-type-contacts-select-box($third-color-2, $primary-color-2);
        }
    }
    &__search-simple{
      @include field-type-contacts-search-color($third-color-2, $border-hover-2);
    }
    &__select-item{
      color: $border-hover-2;
    }
    &__select-list{
      @include field-type-contacts-select-list($third-color-2, 0 0 25px 6px $bar-horizontal-grey-opacity);
    }
    &__selection-icons{
      margin-top: 9px;
      &--simple{
          margin-top: 6px;
      }
    }
    &__title-box{
      color: $primary-color-2;
    }
    &__search-multiple{
        @include field-type-contacts-search-color($third-color-2, $border-hover-2);
    }
    &__title-contact-list{
      color: $text-field-color-2;
    }
    &__title-icon{
      @include field-type-contacts-icon-size-color($border-hover-2, $border-hover-2);
      &--green{
        @include field-type-contacts-icon-size-color($border-hover-2, $green-color-v2);
      }
      &--grey{
        @include field-type-contacts-icon-size-color($border-hover-2, $grey-icons-2);
      }
      &--red{
        @include field-type-contacts-icon-size-color($border-hover-2, $alert-color-2);
      }
    }
    &__block-header-section{
        color: $primary-color-2;
        &--active{
            @include field-type-contacts-block-header-section-active($primary-color-2, $snow-white);
        }
    }
  }
}
&__icam-red{
  .field-type-contacts{
    &__add-new-title-block{
      color: $secondary-color-icam;
    }
    &__body-contacts-simple{
      border-color: $primary-color-icam;
    }
    &__contact-block{
      border-color: $primary-color-icam;
      border-top-color: $custom-black-landing;
    }
    &__toggle-all {
      @include field-type-contacts-contact-list-to-share-color($custom-black-landing, $hover-block-icam);
    }
    &__contact-list-to-share{
      @include field-type-contacts-contact-list-to-share-color($custom-black-landing, $hover-block-icam);
      &--disabled{
          color: $subcontainer-ground;
          &:hover{
              cursor: default;
              background: none;
              border: 0;
          }
      }
    }
    &__elementlist-dropdown{
      @include field-type-contacts-contact-list-to-share-color($primary-color-icam, $hover-block-icam);
    }
    &__icon-close{
        color: $primary-color-icam;
    }
    &__icon-contact{
      color: $primary-color-icam;
    }
    &__icon-search{
      top: 0px;
    }
    &__icon-selected{
      color: $custom-black-landing;
    }
    &__icon-size{
      &--signed{
        @include field-type-contacts-icon-size-color($green-task-icam, $green-task-icam);
      }
      &--cancel{
        @include field-type-contacts-icon-size-color($alert-color-icam, $alert-color-icam);
      }
      &--all{
        @include field-type-contacts-icon-size-color($primary-color-icam, $primary-color-icam);
      }
      &--pending{
        @include field-type-contacts-icon-size-color($secondary-color-icam, $secondary-color-icam);
      }
    }
    &__icon-button{
        @include field-type-contacts-icon-button-color($hover-block-icam, $custom-black-landing);
        &--active{
            @include field-type-contacts-icon-button-active($third-color-icam, $custom-black-landing);
        }
        &--no-parent-active{
            border-color: $custom-black-landing;
        }
    }
    &__icons-contacts{
      @include field-type-contacts-icons-contacts-color($primary-color-icam, $hover-block-icam);
      &--selected{
        @include field-type-contacts-icons-contacts-selected($third-color-icam, $primary-color-icam);
      }
    }
    &__header-nav{
        background-color: $hover-block-icam;
        border-color: $custom-black-landing;
    }
    &__item-selected{
      color: $text-field-color-icam;
    }
    &__list-find-icon{
      @include field-type-contacts-list-find-icon($primary-color-icam);
    }
    &__select-box{
        @include field-type-contacts-select-box($custom-black-landing, $snow-white);
        &--active{
            @include field-type-contacts-select-box($third-color-icam, $custom-black-landing);
        }
    }
    &__search-simple{
      @include field-type-contacts-search-color($third-color-icam, $primary-color-icam);
    }
    &__select-icon{
      color: $primary-color-icam;
    }
    &__select-item{
      color: $primary-color-icam;
    }
    &__select-list{
      @include field-type-contacts-select-list($primary-color-icam, $box-shadow-icam);
    }
    &__selection-icons{
      margin-top: 9px;
      &--simple{
          margin-top: 6px;
      }
    }
    &__title-box{
        color: $custom-black-landing;
    }
    &__search-multiple{
        @include field-type-contacts-search-color($third-color-icam, $primary-color-icam);
    }
    &__title-contact-list{
      color: $secondary-color-icam;
    }
    &__title-icon{
      @include field-type-contacts-icon-size-color($primary-color-icam, $primary-color-icam);
      &--green{
        @include field-type-contacts-icon-size-color($green-task-icam, $green-task-icam);
      }
      &--grey{
        @include field-type-contacts-icon-size-color($secondary-color-icam, $secondary-color-icam);
      }
      &--red{
        @include field-type-contacts-icon-size-color($alert-color-icam, $alert-color-icam);
      }
    }
    &__title-icon-icon-quantity{
        color: $custom-black-landing;
    }
    &__block-header-section{
        color: $custom-black-landing;
        &--active{
            @include field-type-contacts-block-header-section-active($custom-black-landing, $snow-white);
        }
    }
    &__checkbox-icon-all {
        color: $primary-color-icam;
    }

    &__checkbox-size-icon{
        color: $primary-color-icam;
    }
  }
}
&__gm-law{
  .field-type-contacts{
    &__add-new-title-block{
      color: $secondary-color-gm;
    }
    &__body-contacts-simple{
      border-color: $primary-color-gm;
    }
    &__contact-block{
      border-color: $primary-color-gm;
      // border-top-color: $custom-black-landing;
    }
    &__toggle-all {
      @include field-type-contacts-contact-list-to-share-color($custom-black-landing, $hover-block-gm);
    }
    &__contact-list-to-share{
      @include field-type-contacts-contact-list-to-share-color($custom-black-landing, $hover-block-gm);
      &--disabled{
          color: $subcontainer-ground;
          &:hover{
              cursor: default;
              background: none;
              border: 0;
          }
      }
    }
    &__elementlist-dropdown{
      @include field-type-contacts-contact-list-to-share-color($primary-color-gm, $hover-block-gm);
    }
    &__icon-close{
      color: $primary-color-gm;
    }
    &__icon-contact{
      color: $primary-color-gm;
    }
    &__icon-search{
      top: 0px;
    }
    &__icon-selected{
      color: $custom-black-landing;
    }
    &__icon-size{
      &--signed{
        @include field-type-contacts-icon-size-color($green-task-gm, $green-task-gm);
      }
      &--cancel{
        @include field-type-contacts-icon-size-color($alert-color-gm, $alert-color-gm);
      }
      &--all{
        @include field-type-contacts-icon-size-color($primary-color-gm, $primary-color-gm);
      }
      &--pending{
        @include field-type-contacts-icon-size-color($secondary-color-gm, $secondary-color-gm);
      }
    }
    &__icon-button{
        @include field-type-contacts-icon-button-color($hover-block-gm, $custom-black-landing);
        &:hover{
            @include field-type-contacts-icon-button-color($third-color-gm, $primary-color-gm);
            cursor: pointer;
        }
        &--active{
            @include field-type-contacts-icon-button-active($third-color-gm, $custom-black-landing);
        }
        &--no-parent-active{
            border-color: $custom-black-landing;
        }
    }
    &__icons-contacts{
      @include field-type-contacts-icons-contacts-color($primary-color-gm, $hover-block-gm);
      &--selected{
        @include field-type-contacts-icons-contacts-selected($third-color-gm, $primary-color-gm);
      }
    }
    &__header-nav{
        background-color: $hover-block-gm;
        border-color: $custom-black-landing;
    }
    &__item-selected{
      color: $text-field-color-gm;
    }
    &__list-find-icon{
      @include field-type-contacts-list-find-icon($primary-color-gm);
    }
    &__select-box{
        @include field-type-contacts-select-box($custom-black-landing, $snow-white);
        &--container{
            background-color: $primary-color-gm;
            border-color: $primary-color-gm;
        }
        &--active{
            @include field-type-contacts-select-box($third-color-gm, $custom-black-landing);
        }
    }
    &__search-simple{
      @include field-type-contacts-search-color($third-color-gm, $primary-color-gm);
    }
    &__select-icon{
      color: $primary-color-gm;
    }
    &__select-item{
      color: $primary-color-gm;
    }
    &__select-list{
      @include field-type-contacts-select-list($primary-color-gm, $box-shadow-gm);
    }
    &__selection-icons{
      margin-top: 9px;
      &--simple{
          margin-top: 6px;
      }
    }
    &__title-box{
      color: $custom-black-landing;
    }
    &__search-multiple{
      @include field-type-contacts-search-color($third-color-gm, $primary-color-gm);
    }
    &__title-contact-list{
      color: $secondary-color-gm;
    }
    &__title-icon{
      @include field-type-contacts-icon-size-color($primary-color-gm, $primary-color-gm);
      &--green{
        @include field-type-contacts-icon-size-color($green-task-gm, $green-task-gm);
      }
      &--grey{
        @include field-type-contacts-icon-size-color($secondary-color-gm, $secondary-color-gm);
      }
      &--red{
        @include field-type-contacts-icon-size-color($alert-color-gm, $alert-color-gm);
      }
    }
    &__title-icon-icon-quantity{
        color: $custom-black-landing;
    }
    &__block-header-section{
        color: $custom-black-landing;
        &--active{
            @include field-type-contacts-block-header-section-active($custom-black-landing, $snow-white);
        }
    }
    &__checkbox-icon-all {
        color: $primary-color-gm;
    }
    &__checkbox-size-icon{
        color: $primary-color-gm;
    }
  }
}
&__com-de{
  .field-type-contacts{
    &__add-new-title-block{
      color: $secondary-color-com-de;
    }
    &__body-contacts-simple{
      border-color: $primary-color-com-de;
    }
    &__contact-block{
      border-color: $primary-color-com-de;
      // border-top-color: $custom-black-landing;
    }
    &__toggle-all {
      @include field-type-contacts-contact-list-to-share-color($custom-black-landing, $hover-block-com-de);
    }
    &__contact-list-to-share{
      @include field-type-contacts-contact-list-to-share-color($custom-black-landing, $hover-block-com-de);
      &--disabled{
          color: $subcontainer-ground;
          &:hover{
              cursor: default;
              background: none;
              border: 0;
          }
      }
    }
    &__elementlist-dropdown{
      @include field-type-contacts-contact-list-to-share-color($primary-color-com-de, $hover-block-com-de);
    }
    &__icon-close{
      color: $primary-color-com-de;
    }
    &__icon-contact{
      color: $primary-color-com-de;
    }
    &__icon-search{
      top: 0px;
    }
    &__icon-selected{
      color: $custom-black-landing;
    }
    &__icon-size{
      &--signed{
        @include field-type-contacts-icon-size-color($green-task-com-de, $green-task-com-de);
      }
      &--cancel{
        @include field-type-contacts-icon-size-color($alert-color-com-de, $alert-color-com-de);
      }
      &--all{
        @include field-type-contacts-icon-size-color($primary-color-com-de, $primary-color-com-de);
      }
      &--pending{
        @include field-type-contacts-icon-size-color($secondary-color-com-de, $secondary-color-com-de);
      }
    }
    &__icon-button{
        @include field-type-contacts-icon-button-color($hover-block-com-de, $custom-black-landing);
        &:hover{
            @include field-type-contacts-icon-button-color($third-color-com-de, $primary-color-com-de);
            cursor: pointer;
        }
        &--active{
            @include field-type-contacts-icon-button-active($third-color-com-de, $custom-black-landing);
        }
        &--no-parent-active{
            border-color: $custom-black-landing;
        }
    }
    &__icons-contacts{
      @include field-type-contacts-icons-contacts-color($primary-color-com-de, $hover-block-com-de);
      &--selected{
        @include field-type-contacts-icons-contacts-selected($third-color-com-de, $primary-color-com-de);
      }
    }
    &__header-nav{
        background-color: $hover-block-com-de;
        border-color: $custom-black-landing;
    }
    &__item-selected{
      color: $text-field-color-com-de;
    }
    &__list-find-icon{
      @include field-type-contacts-list-find-icon($primary-color-com-de);
    }
    &__select-box{
        @include field-type-contacts-select-box($custom-black-landing, $snow-white);
        &--container{
            background-color: $primary-color-com-de;
            border-color: $primary-color-com-de;
        }
        &--active{
            @include field-type-contacts-select-box($third-color-com-de, $custom-black-landing);
        }
    }
    &__search-simple{
      @include field-type-contacts-search-color($third-color-com-de, $primary-color-com-de);
    }
    &__select-icon{
      color: $primary-color-com-de;
    }
    &__select-item{
      color: $primary-color-com-de;
    }
    &__select-list{
      @include field-type-contacts-select-list($primary-color-com-de, $box-shadow-com-de);
    }
    &__selection-icons{
      margin-top: 9px;
      &--simple{
          margin-top: 6px;
      }
    }
    &__title-box{
      color: $custom-black-landing;
    }
    &__search-multiple{
      @include field-type-contacts-search-color($third-color-com-de, $primary-color-com-de);
    }
    &__title-contact-list{
      color: $secondary-color-com-de;
    }
    &__title-icon{
      @include field-type-contacts-icon-size-color($primary-color-com-de, $primary-color-com-de);
      &--green{
        @include field-type-contacts-icon-size-color($green-task-com-de, $green-task-com-de);
      }
      &--grey{
        @include field-type-contacts-icon-size-color($secondary-color-com-de, $secondary-color-com-de);
      }
      &--red{
        @include field-type-contacts-icon-size-color($alert-color-com-de, $alert-color-com-de);
      }
    }
    &__title-icon-icon-quantity{
        color: $custom-black-landing;
    }
    &__block-header-section{
        color: $custom-black-landing;
        &--active{
            @include field-type-contacts-block-header-section-active($custom-black-landing, $snow-white);
        }
    }
    &__checkbox-icon-all {
        color: $primary-color-com-de;
    }
    &__checkbox-size-icon{
        color: $primary-color-com-de;
    }
  }
}
&__com-fr{
  .field-type-contacts{
    &__add-new-title-block{
      color: $secondary-color-com-fr;
    }
    &__body-contacts-simple{
      border-color: $primary-color-com-fr;
    }
    &__contact-block{
      border-color: $primary-color-com-fr;
      // border-top-color: $custom-black-landing;
    }
    &__toggle-all {
      @include field-type-contacts-contact-list-to-share-color($custom-black-landing, $hover-block-com-fr);
    }
    &__contact-list-to-share{
      @include field-type-contacts-contact-list-to-share-color($custom-black-landing, $hover-block-com-fr);
      &--disabled{
          color: $subcontainer-ground;
          &:hover{
              cursor: default;
              background: none;
              border: 0;
          }
      }
    }
    &__elementlist-dropdown{
      @include field-type-contacts-contact-list-to-share-color($primary-color-com-fr, $hover-block-com-fr);
    }
    &__icon-close{
      color: $primary-color-com-fr;
    }
    &__icon-contact{
      color: $primary-color-com-fr;
    }
    &__icon-search{
      top: 0px;
    }
    &__icon-selected{
      color: $custom-black-landing;
    }
    &__icon-size{
      &--signed{
        @include field-type-contacts-icon-size-color($green-task-com-fr, $green-task-com-fr);
      }
      &--cancel{
        @include field-type-contacts-icon-size-color($alert-color-com-fr, $alert-color-com-fr);
      }
      &--all{
        @include field-type-contacts-icon-size-color($primary-color-com-fr, $primary-color-com-fr);
      }
      &--pending{
        @include field-type-contacts-icon-size-color($secondary-color-com-fr, $secondary-color-com-fr);
      }
    }
    &__icon-button{
        @include field-type-contacts-icon-button-color($hover-block-com-fr, $custom-black-landing);
        &:hover{
            @include field-type-contacts-icon-button-color($third-color-com-fr, $primary-color-com-fr);
            cursor: pointer;
        }
        &--active{
            @include field-type-contacts-icon-button-active($third-color-com-fr, $custom-black-landing);
        }
        &--no-parent-active{
            border-color: $custom-black-landing;
        }
    }
    &__icons-contacts{
      @include field-type-contacts-icons-contacts-color($primary-color-com-fr, $hover-block-com-fr);
      &--selected{
        @include field-type-contacts-icons-contacts-selected($third-color-com-fr, $primary-color-com-fr);
      }
    }
    &__header-nav{
        background-color: $hover-block-com-fr;
        border-color: $custom-black-landing;
    }
    &__item-selected{
      color: $text-field-color-com-fr;
    }
    &__list-find-icon{
      @include field-type-contacts-list-find-icon($primary-color-com-fr);
    }
    &__select-box{
        @include field-type-contacts-select-box($custom-black-landing, $snow-white);
        &--container{
            background-color: $primary-color-com-fr;
            border-color: $primary-color-com-fr;
        }
        &--active{
            @include field-type-contacts-select-box($third-color-com-fr, $custom-black-landing);
        }
    }
    &__search-simple{
      @include field-type-contacts-search-color($third-color-com-fr, $primary-color-com-fr);
    }
    &__select-icon{
      color: $primary-color-com-fr;
    }
    &__select-item{
      color: $primary-color-com-fr;
    }
    &__select-list{
      @include field-type-contacts-select-list($primary-color-com-fr, $box-shadow-com-fr);
    }
    &__selection-icons{
      margin-top: 9px;
      &--simple{
          margin-top: 6px;
      }
    }
    &__title-box{
      color: $custom-black-landing;
    }
    &__search-multiple{
      @include field-type-contacts-search-color($third-color-com-fr, $primary-color-com-fr);
    }
    &__title-contact-list{
      color: $secondary-color-com-fr;
    }
    &__title-icon{
      @include field-type-contacts-icon-size-color($primary-color-com-fr, $primary-color-com-fr);
      &--green{
        @include field-type-contacts-icon-size-color($green-task-com-fr, $green-task-com-fr);
      }
      &--grey{
        @include field-type-contacts-icon-size-color($secondary-color-com-fr, $secondary-color-com-fr);
      }
      &--red{
        @include field-type-contacts-icon-size-color($alert-color-com-fr, $alert-color-com-fr);
      }
    }
    &__title-icon-icon-quantity{
        color: $custom-black-landing;
    }
    &__block-header-section{
        color: $custom-black-landing;
        &--active{
            @include field-type-contacts-block-header-section-active($custom-black-landing, $snow-white);
        }
    }
    &__checkbox-icon-all {
        color: $primary-color-com-fr;
    }
    &__checkbox-size-icon{
        color: $primary-color-com-fr;
    }
  }
}
}
@media (max-width: 1022px){
  .field-type-contacts{
      &__block-header-multiple{
          width: 300px;
      }
      &__block-header-section{
          width: 150px;
      }
  }
}
