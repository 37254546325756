
.wizard-steps {
  &__top{
      margin-top: 25px;
  }
  &__wizard-text {
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      display: table;
      position: relative;
      width: 140px;
      right: 58px;
      padding: 9px 3px 0;
      text-align: center;
  }
  &__col-text {
      position: relative;
      line-height: 14px;
      margin: 0;
      text-transform: capitalize;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
  }
}

.compliance{
  &__dark-blue{
    .wizard-steps {
      &__wizard-text {
        color:$secondary-color;
        &--active{
            font-family: MTTMilano-Bold, sans-serif;
        }
      }
    }
  }

  &__steel-blue,
  &__uk-steel-blue{
    .wizard-steps {
      &__wizard-text {
        color: $primary-color-2;
        &--active{
            font-family: MTTMilano-Bold, sans-serif;
            color: $border-bottom-2;
        }
     }
    }
  }

  &__icam-red{
    .wizard-steps {
      &__wizard-text {
        color: $secondary-color-icam;
        &--active{
            font-family: MTTMilano-Bold, sans-serif;
            color: $custom-black-landing;
        }
      }
    }
  }

  &__gm-law{
    .wizard-steps {
      &__wizard-text {
        color: $secondary-color-gm;
        &--active{
            font-family: MTTMilano-Bold, sans-serif;
            color: $custom-black-landing;
        }
      }
    }
  }
  &__com-de{
    .wizard-steps {
      &__wizard-text {
        color: $secondary-color-com-de;
        &--active{
            font-family: MTTMilano-Bold, sans-serif;
            color: $custom-black-landing;
        }
      }
    }
  }
  &__com-fr{
    .wizard-steps {
      &__wizard-text {
        color: $secondary-color-com-fr;
        &--active{
            font-family: MTTMilano-Bold, sans-serif;
            color: $custom-black-landing;
        }
      }
    }
  }
}
