.field-type-role{
  &__container{
      position: relative;
      float: left;
      width: 100%;
      padding: 11px;
      min-height: 75px;
      background-color: transparent;
      border: 1px solid;
      margin-bottom: 10px;
      border-color: $result-search-quote-color;
  }

  &__block-header{
      width: 100%;
      margin-bottom: 5px;
      min-height: 30px;
      color: $pillow-group;
      display: flex;
      align-items: center;
  }

  &__icon-head{
      position: relative;
      float: left;
      font-size: 30px;
      margin-right: 14px;
  }

  &__title-rol {
      line-height: 24px;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 18px;
      display: flex;
      flex: 1 0 0;
  }

  &__info-no-users{
      text-align: center;
      margin: 0;
      font-family: Arial, sans-serif;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 16px;
      color: $pillow-group;
  }

  &__table-header{
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      height: 40px;
  }

  &__table-body{
      display: block;
      width: 100%;
      max-height: 230px;
      &--more-elements{
          overflow-x: hidden;
          overflow-y: auto;
          width: calc(100% + 10px);
      }
  }

  &__table-row{
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      height: 46px;
      &:hover:not([disabled]) {
          & .common__options-row {
              visibility: visible;
          }
          & .common__icon-ellipsis {
              visibility: hidden;
          }
      }
  }

  &__col {
      text-align: left;
      padding-right: 5px;
      &--top-1{
          position: relative;
          top: 1px;
      }
      &--first{
          padding-left: 5px;
      }

      &--user,
      &--mail,
      &--phone{
          width: 22%;
      }
      &--organizations{
          width: calc(34% - 60px);
      }
      &--actions{
          width: 60px;
          max-width: 60px;
          padding-right: 0;
          position: relative;
      }
  }

  &__block-participants{
      position: relative;
      top: 1px;
  }

  &__options-row {
      height: 43px;
      top: -8px;
  }
}

.compliance{
  &__steel-blue,
  &__uk-steel-blue{
    .field-type-role{
      &__container{
        border-color: $background-highlight-text-2;
      }
      &__block-header,
      &__info-no-users{
        color: $primary-color-2;
      }
    }
  }
  &__icam-red{
    .field-type-role{
      &__container{
        border-color: $border-block-icam;
      }
      &__block-header,
      &__info-no-users{
        color: $title-text-icam;
      }
    }
  }
  &__gm-law{
    .field-type-role{
      &__container{
        border-color: $border-block-gm;
      }
      &__block-header,
      &__info-no-users{
        color: $title-text-gm;
      }
    }
  }
  &__com-de{
    .field-type-role{
      &__container{
        border-color: $border-block-com-de;
      }
      &__block-header,
      &__info-no-users{
        color: $title-text-com-de;
      }
    }
  }
  &__com-fr{
    .field-type-role{
      &__container{
        border-color: $border-block-com-fr;
      }
      &__block-header,
      &__info-no-users{
        color: $title-text-com-fr;
      }
    }
  }
}
