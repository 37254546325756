.report-incidents-list {
    &__table-header{
        display: table;
        width: 100%;
        height: 42px;
    }
    &__col{
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        padding-right: 5px;
        &--tooltip {
            max-width: 0;
        }
        &--first{
            padding-left: 8px;
        }
        &--title{
            width: 27%;
        }
        &--title-header{
            width: 27%;
            padding-top: 1px;
        }
        &--author{
            width: 24%;
        }
        &--creation-date{
            width: 24%;
        }
        &--modification-date{
            width: calc(25% - 120px);
        }
        &--modification-date-body{
            width: calc(25% - 22px);
        }
        &--actions{
            width: 120px;
            padding-right: 0;
            padding-bottom: 1px;
            position: relative;
        }
        &--actions-body{
            width: 22px;
            padding-right: 0;
            position: relative;
        }
    }
}

.compliance{
  &__dark-blue{
    .report-incidents-list {
      &__table-header{
          color: $primary-color;
          border-bottom: 1px solid $primary-color;
      }

    }
  }
  &__steel-blue{
    .report-incidents-list {
      &__table-header{
          color: $border-hover-2;
          border-bottom: 1px solid $background-highlight-text-2;
      }
    }
  }
  &__gm-law{
    .report-incidents-list {
      &__table-header{
          color: $primary-color-gm;
          border-bottom: 1px solid $primary-color-gm;
      }
      &__report-archived{
        background-color: $third-color-gm;
      }
      
    }
  }
}
