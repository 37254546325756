@mixin duplicate-documentation-table-container-evaluation($borderColor){
  margin-top: 29px;
  border-top: 1px solid $borderColor;
}
@mixin duplicate-documentation-table-row-hover($firstColor, $secondColor){
  &:hover:not([disabled]),
  &:hover[disabled=false] {
      background-color:$secondColor;
      color: $firstColor;
      cursor: pointer;
      .duplicate-documentation__cell-block--organization, .duplicate-documentation__table--creation-concept, .duplicate-documentation__block-hidden-hover{
          opacity: 0;
      }
      .duplicate-documentation__text-explore{
          opacity: 1;
      }
      .common__col-text, .duplicate-documentation__icon-product-scan{
          color: $firstColor;
      }
  }
}
.duplicate-documentation {
  & .modal-dialog {
      & .modal-content {
          height: 642px;
          border-radius: 0px;
      }
  }

  &__block-context {
      height: 642px;
      position: relative;
      float: left;
      width: 100%;
  }

  &__block-body {
      position: relative;
      float: left;
      padding: 0;
      font-size: 12px;
      margin: 0;
      width: 100%;
      height: 557px;
  }

  &__block-breadcrumbs {
      width: 100%;
      height: 67px;
      color: $primary-color;
      text-align: left;
      padding-top: 5px;
      position: relative;
      float: left;
      overflow: auto;
  }

  &__block-breadcrumbs-text {
      position: relative;
      width: calc(100% - 41px);
      float: left;
      // top: -1px;
  }

  &__block-input-search {
      width: auto;
      position: relative;
      margin-bottom: 85px;
  }

  &__block-input-search-step-1 {
      width: calc(100% - 160px);
      position: relative;
      margin: 0 auto 25px;
  }

  &__block-search-init {
      padding: 130px 155px 0;
      text-align: center;
      position: relative;
  }

  &__block-search-result {
      padding: 0 70px;
      text-align: center;
      position: relative;
      height: 557px;
  }

  &__block-table-results {
      width: 100%;
      height: 428px;
      padding: 10px;
      border: 1px solid $box-comment-border-2;
      position: relative;
  }

  &__cell-block {
      position: relative;
      height: 17px;
      width: calc(100% - 25px);
  }

  &__count-documentation {
      font-weight: bold;
      font-size: 10px;
      color: $primary-color;
      position: absolute;
      text-transform: uppercase;
      top: 2px;
      right: 0;
  }

  &__dropdown-filter {
      height: 31px;
      width: 344px;
      border: 1px solid $subcontainer-ground;
      top: 0;
      position: relative;
      margin-bottom: 32px;
      display: table;
      cursor: pointer;

      &:hover, &--open {
          border: 1px solid $primary-color;
          background-color: $background-grey;
      }
      &--nomargin {
          margin-bottom: 0 !important;
      }
  }

  &__icon-angle-right {
      position: absolute;
      right: 6px;
      top: 11px;
      color: $primary-color;
      font-size: 19px;
  }

  &__icon-filter {
      font-size: 20px;
      color: $primary-color;
      width: 20px;
      position: absolute;
      left: 10px;
  }

  &__icon-map {
      margin-left: 15px;
      font-size: 20px;
      margin-right: 6px;
      position: relative;
      bottom: 5px;
      height: 20px;
      float: left;
  }

  &__icon-product-scan{
      color: $subcontainer-ground;
      font-size: 25px;
      position: relative;
      // bottom: 4px;
      left: 12px;
      top: 1px;
  }

  &__list-item {
      margin: 0 9px;
      padding: 0;
      display: table;
      height: 30px;
      width: calc(100% - 10px);

      &:hover {
          .duplicate-documentation__select-icon {
              display: block;
          }
      }
  }

  &__pagination {
      text-align: center;
      margin: 22px auto 0;
      height: 22px;
      display: block;
      position: relative;
      float: left;
      width: 100%;

      &__count {
          float: right;
          font-weight: bold;
          font-size: 10px;
          color: $primary-color;
          text-transform: uppercase;
      }
  }

  &__select-icon {
      font-size: 16px;
      float: right;
      display: none;
      padding: 0 1px;
      position: absolute;
      right: 0;
      top: 6px;
  }

  &__select-list {
      width: 344px;
      margin: 0 0 0 -1px;
      overflow-y: auto;
      overflow-x: hidden;
      top: 29px;
      left: 0px;
  }

  &__spinner-loader {
      width: 30px;
      margin-top: 80px;
      position: absolute;
  }

  &__symbol-minus {
      position: relative;
      float: left;
      bottom: 1px;
      font-size: 14px;
      margin: 0 0px 0 4px;
  }

  &__table-container {
      width: 100%;
      position: relative;
      float: left;
  }

  &__table-header{
      padding-top: 2px;
  }

  &__table-body {
      width: 100%;
      display: block;
      position: relative;
      overflow-y: hidden;
      overflow-x: hidden;
      height: 264px;
      max-height: 264px;
      &--more-elements{
          width: calc(100% + 10px);
          overflow-y: auto;
      }
  }

  &__table {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      // position: relative;
      max-width: 0;

      &--multiple-selection {
          width: 40px;
          min-width: 40px;
          padding-top: 4px;
      }

      &--format {
          width: 80px;
          min-width: 80px;
      }

      &--title {
          width: 210px;
          min-width: 210px;
      }

      &--title-only {
          width: 542px;
          min-width: 542px;
      }

      &--implantation, &--organization, &--author-documentation {
          width: 166px;
          min-width: 166px;
      }

      &--creation-date {
          width: 74px;
          min-width: 74px;
      }

      &--icon-scan {
          width: 50px;
          min-width: 50px;
      }

      &--implantation-scan, &--organization-scan, &--title-concept {
          width: 343px;
          min-width: 343px;
      }

      &--implantation-explore {
          width: 686px;
          min-width: 686px;
      }

      &--author-concept {
          width: 123px;
          min-width: 123px;
      }

      &--creation-concept {
          width: 95px;
          min-width: 95px;
      }

      &--icon-right {
          width: 29px;
          min-width: 29px;
      }

      &--title-documentation {
          width: 322px;
          min-width: 322px;
      }

      &--icon-right-documentation {
          width: 54px;
          min-width: 54px;
      }

      &--reference-query{
          width: 116px;
          min-width: 116px;
      }

      &--type-query{
          width: 108px;
          min-width: 108px;
      }

      &--name-query{
          width: 182px;
          min-width: 182px;
      }

      &--mattern-query{
          width: 252px;
          min-width: 252px;
      }

      &--title-publication{
          width: 340px;
          min-width: 340px;
      }

      &--type-publication{
          width: 97px;
          min-width: 97px;
      }

      &--isbn-publication{
          width: 221px;
          min-width: 221px;
      }

      &--title-digitization{
          width: 437px;
          min-width: 437px;
      }
      &--surname-collegiate{
          width: 210px;
          min-width: 210px;
      }

      &--name-collegiate{
          width: 210px;
          min-width: 210px;
      }

      &--number-collegiate{
          width: 238px;
          min-width: 238px;
      }
  }

  &__table-row {
      height: 44px;
      display: table;
      width: 100%;
      border-bottom: 1px solid $result-search-quote-color;
      position: relative;
  }

  &__text-breadcrumb {
      display: inline-block;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }

  &__text-explore {
      opacity: 0;
      padding: 0 6px;
      position: absolute;
      right: 26px;
      bottom: 14px;
      font-size: 10px;
      font-family: Arial, sans-serif;
      font-weight: bold;
      text-transform: uppercase;
  }

  &__text-search {
      opacity: 0.5;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 18px;
      margin-bottom: 20px;
  }

  &__text-search-header {
      opacity: 0.5;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 18px;
      &--no-elements {
          position: absolute;
          bottom: 138px;
          width: 736px;
      }
  }

  &__title-block-filter {
      display: table-cell;
      vertical-align: middle;
      color: $subcontainer-ground;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
  }

  &__title-breadcrumb {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 13px;
      position: relative;
      float: left;
      bottom: 3px;
      display: block;
      right: 2px;
      height: 17px;
      max-width: 228px;
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0;

      &:hover {
          cursor: pointer;
      }

      &--title {
          text-transform: uppercase;
          right: 0px;
      }

      &--no-cursor {
          &:hover {
              cursor: default;
          }
      }
  }

  &__title-name-filter {
      float: left;
      left: 37px;
      position: relative;
  }

  &__underlined-text {
      text-decoration: underline;
  }

  &__icon-close-modal {
      color: $snow-white;
      float: right;
      font-size: 20px;
      top: 4px;
      position: relative;
      &--custom{
          font-size: 25px;
          top: 0;
      }

      &:hover {
          cursor: pointer;
      }
  }

  &__filter-icon {
      color: $subcontainer-ground;
      margin-left: 6px;
      float: left;
      font-size: 16px;

      &:hover {
          cursor: pointer;
      }

      &--no-float {
          float: none;
          display: inline-block;
          margin: 0;
      }

      &--custom{
          color: $custom-black-landing;
          font-size: 20px;
      }

      &--active-custom {
          color: $custom-black-landing !important;
      }
  }
  &__table-icon {
      color: $primary-color;
      font-size: 20px;
      position: relative;
      top: 2px;
      left: 22px;
      &--folder {
          font-size: 24px;
      }
  }
  &__input-block {
      width: 250px;
      min-height: 33px;
      display: table-cell;
      border: none;
      padding-top: 0;
  }
}
.compliance{
  &__dark-blue{
      .duplicate-documentation {
          &__dropdown-filter {
              &:hover,
              &--open{
                  border: 1px solid $primary-color;
                  background-color: $background-color-states;
              }
          }
          &__table-container {
              &--evaluation{
                  @include duplicate-documentation-table-container-evaluation($primary-color);
              }
          }
          &__table-row {
              @include duplicate-documentation-table-row-hover($primary-color, $main-col-table);
              &--selected{
                  background-color: $background-color-states;
              }
          }
          &__select-list-with-input {
              margin: 0px;
              width: 248px;
              top: 30px;
              border-radius: 0;
              border: 1px solid $primary-color;
              &--report-table {
                  padding: 0;
              }
              &--by-list {
                  margin: 2px 2px 0 0;
                  padding: 0;
                  max-height: 133px;
                  width: auto;
                  top: 0;
                  overflow-y: auto;
                  border: none;
              }
              &--top-23{
                  top: 23px;
              }

              &--width-200{
                  width: 200px;
              }
          }
          &__filter-icon {
              &--active{
                  color: $primary-color;
              }
              color: $subcontainer-ground;
          }
          &__table-icon {
              color: $primary-color;
          }
      }
  }
  &__steel-blue,
  &__uk-steel-blue{
      .duplicate-documentation {
          &__block-breadcrumbs {
              color:$border-bottom-2;
          }
          &__cell-block {
              color:$border-hover-2;
          }
          &__count-documentation {
              color:$border-bottom-2;
          }
          &__dropdown-filter {
              border: 2px solid $third-color-2;
              background-color: $third-color-2;
              &:hover,
              &--open{
                  border: 2px solid $third-color-2;
                  background-color: $third-color-2;
              }
          }
          &__icon-angle-right {
              color:$border-hover-2;
          }
          &__icon-filter {
              color: $border-hover-2;
          }
          &__icon-map {
              color: $primary-color-2
          }
          &__icon-product-scan{
              color: $primary-color-2;
          }
          &__list-item {
              margin: 0 5px;
              padding: 0 9px;
              &:hover{
                  font-weight: normal;
              }
          }
          &__select-icon {
              color: $border-hover-2;
          }
          &__select-list {
              border: 1px solid $third-color-2;
          }
          &__symbol-minus {
              color: $primary-color-2;
          }
          &__table-container {
              &--evaluation{
                  @include duplicate-documentation-table-container-evaluation($background-highlight-text-2);
              }
          }

          &__table-row {
              border-color: $secondary-color-2;
              @include duplicate-documentation-table-row-hover($primary-color-2, $block-hover-2);
              &--selected{
                  background-color: $block-hover-2;
                  color: $primary-color-2;
              }
          }
          &__text-search {
              color: $grey-icons-2;
              opacity: 1;
          }
          &__text-search-header {
              color: $grey-icons-2;
              opacity: 1;
          }
          &__title-block-filter {
              color: $primary-color-2;
          }
          &__title-breadcrumb {
              font-family: MTTMilano, sans-serif;
              &--title{
                  text-transform: uppercase;
                  right: 0px;
              }
              &--no-cursor{
                  &:hover{
                      cursor: default;
                  }
              }
          }
          &__icon-close-modal {
              color: $white-special-2;
          }
          &__select-list-with-input {
              margin: 0px;
              width: 248px;
              top: 30px;
              border-radius: 0;
              border: 1px solid $third-color-2;
              box-shadow: 0 0 5px 6px $box-shadow-box-2;
              &--report-table {
                  padding: 0;
              }
              &--by-list {
                  margin: 2px 2px 0 0;
                  padding: 0;
                  max-height: 133px;
                  width: auto;
                  top: 0;
                  overflow-y: auto;
                  border: none;
              }
              &--top-23{
                  top: 23px;
              }

              &--width-200{
                  width: 200px;
              }
          }
          &__last{
              color: $primary-color-2;
          }
          &__filter-icon {
              &--active{
                  color: $border-hover-2;
              }
              color: $secondary-color-2;
          }
          &__table-icon {
              color:$border-hover-2;
          }
      }
  }
  &__uk-steel-blue{
      .duplicate-documentation {
          &__table-body {
              &--product{
                  height: 332px;
                  max-height: 332px;
              }
          }
      }
  }
  &__icam-red{
      .duplicate-documentation {
          &__block-breadcrumbs {
              color: $primary-color-icam;
          }
          &__block-table-results {
              border-color: $border-block-icam;
          }
          &__count-documentation {
              color: $modal-question-icam;
          }
          &__icon-angle-right {
              color: $primary-color-icam;
          }
          &__icon-product-scan{
              color: $custom-black-landing;
          }
          &__symbol-minus {
              color: $custom-black-landing;
          }
           &__table-container {
              &--evaluation{
                  @include duplicate-documentation-table-container-evaluation($primary-color-icam);
              }
          }
          &__table-row {
              border-color: $border-block-icam;
              @include duplicate-documentation-table-row-hover($primary-color-icam, $hover-block-icam);
              &--selected{
                  background-color: $hover-block-icam;
                  color: $custom-black-landing;
              }
          }
          &__text-search {
              color: $secondary-color-icam;
              opacity: 1;
          }
          &__text-search-header {
              color: $secondary-color-icam;
              opacity: 1;
          }
          &__last{
              color: $custom-black-landing;
          }
          &__table-icon {
              color: $primary-color-icam;
          }
      }
  }
  &__gm-law{
      .duplicate-documentation {
          &__block-breadcrumbs {
              color: $primary-color-gm;
          }
          &__block-table-results {
              border-color: $border-block-gm;
          }
          &__count-documentation {
              color: $modal-question-icam;
              color: $modal-question-gm;
          }
          &__dropdown-filter {
              border: 2px solid $third-color-gm;
              background-color: $third-color-gm;
              &:hover, &--open{
                  border: 2px solid $third-color-gm;
                  background-color: $third-color-gm;
              }
          }
          &__icon-angle-right {
              color: $primary-color-gm;
          }
          &__icon-filter {
              color: $primary-color-gm;
          }
          &__icon-product-scan{
              color: $custom-black-landing;
          }
          &__symbol-minus {
              color: $custom-black-landing;
          }
          &__table-container {
              @include duplicate-documentation-table-container-evaluation($primary-color-gm);
          }
          &__table-row {
              border-color: $border-block-gm;
              @include duplicate-documentation-table-row-hover($primary-color-gm, $hover-block-gm);
              &--selected{
                  background-color: $hover-block-gm;
                  color: $custom-black-landing;
              }
          }
          &__text-search {
              color: $secondary-color-icam;
              opacity: 1;
          }
          &__text-search-header {
              color: $secondary-color-icam;
              opacity: 1;
          }
          &__title-name-filter {
              color: $custom-black-landing;
          }
          &__last{
              color: $custom-black-landing;
          }
          &__table-icon {
              color: $primary-color-gm;
          }
          }
  }
  &__com-de{
    .duplicate-documentation {
        &__block-breadcrumbs {
            color: $primary-color-com-de;
        }
        &__block-table-results {
            border-color: $border-block-com-de;
        }
        &__count-documentation {
            color: $modal-question-icam;
            color: $modal-question-com-de;
        }
        &__dropdown-filter {
            border: 2px solid $third-color-com-de;
            background-color: $third-color-com-de;
            &:hover, &--open{
                border: 2px solid $third-color-com-de;
                background-color: $third-color-com-de;
            }
        }
        &__icon-angle-right {
            color: $primary-color-com-de;
        }
        &__icon-filter {
            color: $primary-color-com-de;
        }
        &__icon-product-scan{
            color: $custom-black-landing;
        }
        &__symbol-minus {
            color: $custom-black-landing;
        }
        &__table-container {
            @include duplicate-documentation-table-container-evaluation($primary-color-com-de);
        }
        &__table-row {
            border-color: $border-block-com-de;
            @include duplicate-documentation-table-row-hover($primary-color-com-de, $hover-block-com-de);
            &--selected{
                background-color: $hover-block-com-de;
                color: $custom-black-landing;
            }
        }
        &__text-search {
            color: $secondary-color-icam;
            opacity: 1;
        }
        &__text-search-header {
            color: $secondary-color-icam;
            opacity: 1;
        }
        &__title-name-filter {
            color: $custom-black-landing;
        }
        &__last{
            color: $custom-black-landing;
        }
        &__table-icon {
            color: $primary-color-com-de;
        }
        }
  }
  &__com-fr{
    .duplicate-documentation {
        &__block-breadcrumbs {
            color: $primary-color-com-fr;
        }
        &__block-table-results {
            border-color: $border-block-com-fr;
        }
        &__count-documentation {
            color: $modal-question-icam;
            color: $modal-question-com-fr;
        }
        &__dropdown-filter {
            border: 2px solid $third-color-com-fr;
            background-color: $third-color-com-fr;
            &:hover, &--open{
                border: 2px solid $third-color-com-fr;
                background-color: $third-color-com-fr;
            }
        }
        &__icon-angle-right {
            color: $primary-color-com-fr;
        }
        &__icon-filter {
            color: $primary-color-com-fr;
        }
        &__icon-product-scan{
            color: $custom-black-landing;
        }
        &__symbol-minus {
            color: $custom-black-landing;
        }
        &__table-container {
            @include duplicate-documentation-table-container-evaluation($primary-color-com-fr);
        }
        &__table-row {
            border-color: $border-block-com-fr;
            @include duplicate-documentation-table-row-hover($primary-color-com-fr, $hover-block-com-fr);
            &--selected{
                background-color: $hover-block-com-fr;
                color: $custom-black-landing;
            }
        }
        &__text-search {
            color: $secondary-color-icam;
            opacity: 1;
        }
        &__text-search-header {
            color: $secondary-color-icam;
            opacity: 1;
        }
        &__title-name-filter {
            color: $custom-black-landing;
        }
        &__last{
            color: $custom-black-landing;
        }
        &__table-icon {
            color: $primary-color-com-fr;
        }
        }
  }
}
