.icam-queries{
    &__custom-expand{
        min-height: 600px;
        height: 600px;
    }
    &__notifications-block{
        &--expand{
            height: 505px;
        }
    }
    &__pillow{
        font-family: 'MTTMilano-Bold', sans-serif;
        text-align: center;
        border-radius: 12px;
        float: right;
        border: 1px solid;
        font-size: 11px;
        padding: 1px 10px 0;
        position: relative;
        top: 16px;
        margin-right: 8px;
    }
    &__date-col{
        float: right;
        padding-right: 27px;
        top: -2px;
    }

    &__template-menu{
        margin: 0;
        padding: 0;
        width: 78px;
    }
    &__button{
        border: none;
        background: none;
        font-size: 18px;
        padding: 9px 4px 0px 14px;
        outline: 0;
        float: left;
        &:marker{
            display: none;
        }
    }
    &__button-send{
        padding-left: 7px;
    }
}
