// @mixin filter-by-list-select-list-with-input($borderColor, $top) {
//     border-color: $borderColor;
//     border-top-width: 2px;
//     top: $top;
// }

@mixin filter-by-list-list-user($textColor, $backgroundColor, $fontSize) {
  color: $textColor;
  font-size: $fontSize;
  &:hover{
      .common__multiselect-select-icon{
          background: $backgroundColor;
      }
  }
}

.filter-by-list{
  &__block-header{
      position: relative;
      // top: 1px;
      width: auto;
      &:hover:not([disabled]) {
          cursor: pointer;
      }
  }
  &__select-list-with-input{
      // width: 248px;
      // top: 27px;
      max-height: 182px;
      overflow: hidden;
      border-radius: 0;
      // border: 1px solid $primary-color;
      // margin: 0;
      // &--top-21{
      //     top: 21px;
      // }
      // &--top-24{
      //     top: 24px;
      // }
      // &--top-29{
      //     top: 29px;
      // }
  }

  &__list-filter-text{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0;
      position: relative;
      top: 1px;
      // line-height: 1.42857;
      &--v2{
          top: 3px;
      }
  }

  &__list-user{
      display: block;
      overflow: hidden;
      width: auto;
      margin: 0;
      font-weight: normal;
      padding: 8px 12px 0px 14px;
      font-family: Arial, sans-serif;
      outline: none;
      &:hover{
          cursor:pointer;
      }

      &--landing-custom{
          @include filter-by-list-list-user($custom-secondary-color, $background-color-states, 12px);
      }

      &--my-option{
          height: 36px;
          margin: 0px 2px 0 5px;
          padding: 9px;
          border-bottom: 1px solid $primary-color;
      }
  }
  // &__select-list-with-input-steel-blue, &__select-list-with-input-uk-steel-blue{
  //     @include filter-by-list-select-list-with-input($third-color-2, 29px);
  // }
  // &__select-list-with-input-steel-blue-filter, &__select-list-with-input-uk-steel-blue-filter{
  //     @include filter-by-list-select-list-with-input($third-color-2, 27px);
  // }
  // &__select-list-with-input-icam-red{
  //     @include filter-by-list-select-list-with-input($primary-color-icam, 29px);
  // }
  // &__select-list-with-input-icam-red-filter{
  //     @include filter-by-list-select-list-with-input($primary-color-icam, 27px);
  // }
  // &__select-list-with-input-landing-custom-filter{
  //     border-color: $custom-black-landing;
  // }

  &__icon-circle{
      font-size: 8px;
      position: relative;
      float: left;
      top: 5px;
      margin-right: 4px;
  }
}
.compliance{
  &__dark-blue{
    .filter-by-list{
      &__list-user{
        @include filter-by-list-list-user($secondary-color, $background-color-states, 12px);
        &--my-option{
          border-bottom: 1px solid $primary-color;
        }
        &--active{
          color: $primary-color;
        }
      }
    }
  }
  &__steel-blue{
    .filter-by-list{
      &__list-user{
        @include filter-by-list-list-user($border-hover-2, $block-hover-2, 13px);
        padding: 6px 12px 0px 12px;
        font-family: MTTMilano, sans-serif;
        &--my-option{
          border-bottom: 1px solid $primary-color-2;
        }
        &--active{
          color: $primary-color-2;
        }
      }
    }
  }
  &__uk-steel-blue{
    .filter-by-list{
      &__list-user{
        @include filter-by-list-list-user($border-hover-2, $block-hover-2, 13px);
        padding: 6px 12px 0px 12px;
        font-family: MTTMilano, sans-serif;
        &--my-option{
          border-bottom: 1px solid $primary-color-2;
        }
        &--active{
          color: $primary-color-2;
        }
      }
      &__icon-circle{
        &--red{
          color: $red-opacity-uk-36;
        }
        &--yellow{
            color: $yellow-opacity-uk-36;
        }
        &--green{
            color: $green-opacity-uk-36;
        }
      }
    }
  }
  &__icam-red{
    .filter-by-list{
      &__list-user{
        @include filter-by-list-list-user($primary-color-icam, $hover-block-icam, 13px);
        padding: 6px 12px 0px 12px;
        font-family: MTTMilano, sans-serif;
      }
      &__icon-circle{
        &--red{
          color: $red-task-36-icam;
        }
        &--yellow{
            color: $yellow-task-36-icam;
        }
        &--green{
            color: $green-task-36-icam;
        }
        &--grey{
            color: $grey-task-36-icam;
        }
      }
    }
  }
  &__gm-law{
    .filter-by-list{
      &__list-user{
        @include filter-by-list-list-user($primary-color-gm, $hover-block-gm, 13px);
        padding: 6px 12px 0px 12px;
        font-family: MTTMilano, sans-serif;
        &--my-option{
          border-bottom: 1px solid $primary-color-gm;
        }
        &--active{
          color: $primary-color-gm;
        }
      }
      &__icon-circle{
        &--red{
          color: $red-task-36-gm;
        }
        &--yellow{
            color: $yellow-task-36-gm;
        }
        &--green{
            color: $green-task-36-gm;
        }
        &--grey{
            color: $grey-task-36-gm;
        }
      }
    }
  }
  &__com-de{
    .filter-by-list{
      &__list-user{
        @include filter-by-list-list-user($primary-color-com-de, $hover-block-com-de, 13px);
        padding: 6px 12px 0px 12px;
        font-family: MTTMilano, sans-serif;
        &--my-option{
          border-bottom: 1px solid $primary-color-com-de;
        }
        &--active{
          color: $primary-color-com-de;
        }
      }
      &__icon-circle{
        &--red{
          color: $red-task-36-com-de;
        }
        &--yellow{
            color: $yellow-task-36-com-de;
        }
        &--green{
            color: $green-task-36-com-de;
        }
        &--grey{
            color: $grey-task-36-com-de;
        }
      }
    }
  }
  &__com-fr{
    .filter-by-list{
      &__list-user{
        @include filter-by-list-list-user($primary-color-com-fr, $hover-block-com-fr, 13px);
        padding: 6px 12px 0px 12px;
        font-family: MTTMilano, sans-serif;
        &--my-option{
          border-bottom: 1px solid $primary-color-com-fr;
        }
        &--active{
          color: $primary-color-com-fr;
        }
      }
      &__icon-circle{
        &--red{
          color: $red-task-36-com-fr;
        }
        &--yellow{
            color: $yellow-task-36-com-fr;
        }
        &--green{
            color: $green-task-36-com-fr;
        }
        &--grey{
            color: $grey-task-36-com-fr;
        }
      }
    }
  }
}
