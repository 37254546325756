.field-assignments-modal {
  &__window {
      & .modal-dialog {
          & .modal-content {
              height: 674px;
              border-radius: 0px;
          }
      }
  }

  &__block-body {
      height: 542px;
      margin: 50px 71px 0;
  }

  &__form-block {
      padding: 10px 11px;
      width: 100%;
      height: 430px;
      border: 1px solid $custom-black-landing;
  }

  ::placeholder {
      color: $secondary-color;
      font-weight: bold;
      font-size: 12px;
      padding: 0;
      font-family: Arial, sans-serif;
      font-style: normal;
  }

  &__custom-field-name {
      padding: 9px;
      height: 85px;
      color: $primary-color;
      border-bottom: 1px solid $blue-opacity-05;
  }

  &__header-title {
      font-size: 18px;
      font-family: MTTMilano-Bold, sans-serif;
      position: relative;
      bottom: 5px;
  }

  &__field-name-icon {
      position: relative;
      font-size: 30px;
      margin-right: 9px;
  }

  &__custom-field-name-text {
      font-size: 18px;
      font-family: MTTMilano-Bold, sans-serif;
      position: relative;
      bottom: 5px;
  }

  &__assignments-list {
      height: 323px;
      overflow: auto;
      overflow-x: hidden;
  }

  &__assignment{
      padding-top: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid $blue-opacity-04;
  }

  &__assignment-title {
      padding-left: 8px;

      span {
          margin-left: 14px;
          color: $primary-color;
          font-size: 13px;
          vertical-align: text-bottom;
      }
  }

  &__assignment-path {
      padding-left: 8px;
      padding-top: 3px;

      span {
          margin-left: 14px;
          color: $repeat-pattern;
          font-size: 13px;
          vertical-align: text-bottom;
      }
  }

  &__assignment-title-icon {
      color: $primary-color;
      font-size: 19px;
  }

  &__assignment-path-icon {
      color: $repeat-pattern;
      font-size: 19px;
  }
}

.compliance {
  &__dark-blue {
    .field-assignments-modal {
      &__form-block {
        border-color: $subcontainer-border;
      }
    }
  }

  &__icam-red {
    .field-assignments-modal {
      &__form-block {
        border-color: $border-block-icam;
      }

      &__custom-field-name {
        color: $title-text-icam;
        border-color: $primary-color-icam;
      }

      &__custom-field-name-text {
        color: $primary-color-icam;
      }

      &__assignment {
        border-color: $border-block-icam;
      }

      &__assignment-title {
        span {
          color: $custom-black-landing;
        }
      }

      &__assignment-title-icon {
        color: $primary-color-icam;
      }

      &__assignment-path {
        color: $secondary-color-icam;
        span {
          color: $secondary-color-icam;
        }
      }

      &__assignment-path-icon {
        color: $secondary-color-icam;
      }
    }
  }

  &__gm-law {
    .field-assignments-modal {
      &__form-block {
        border-color: $border-block-gm;
      }

      &__custom-field-name {
        color: $title-text-gm;
        border-color: $primary-color-gm;
      }

      &__custom-field-name-text {
        color: $primary-color-gm;
      }

      &__assignment {
        border-color: $border-block-gm;
      }

      &__assignment-title {
        span {
          color: $custom-black-landing;
        }
      }

      &__assignment-title-icon {
        color: $primary-color-gm;
      }

      &__assignment-path {
        color: $secondary-color-gm;
        span {
          color: $secondary-color-gm;
        }
      }

      &__assignment-path-icon {
        color: $secondary-color-icam;
      }
    }
  }
  &__com-de {
    .field-assignments-modal {
      &__form-block {
        border-color: $border-block-com-de;
      }

      &__custom-field-name {
        color: $title-text-com-de;
        border-color: $primary-color-com-de;
      }

      &__custom-field-name-text {
        color: $primary-color-com-de;
      }

      &__assignment {
        border-color: $border-block-com-de;
      }

      &__assignment-title {
        span {
          color: $custom-black-landing;
        }
      }

      &__assignment-title-icon {
        color: $primary-color-com-de;
      }

      &__assignment-path {
        color: $secondary-color-com-de;
        span {
          color: $secondary-color-com-de;
        }
      }

      &__assignment-path-icon {
        color: $secondary-color-icam;
      }
    }
  }
  &__com-fr {
    .field-assignments-modal {
      &__form-block {
        border-color: $border-block-com-fr;
      }

      &__custom-field-name {
        color: $title-text-com-fr;
        border-color: $primary-color-com-fr;
      }

      &__custom-field-name-text {
        color: $primary-color-com-fr;
      }

      &__assignment {
        border-color: $border-block-com-fr;
      }

      &__assignment-title {
        span {
          color: $custom-black-landing;
        }
      }

      &__assignment-title-icon {
        color: $primary-color-com-fr;
      }

      &__assignment-path {
        color: $secondary-color-com-fr;
        span {
          color: $secondary-color-com-fr;
        }
      }

      &__assignment-path-icon {
        color: $secondary-color-icam;
      }
    }
  }
}
