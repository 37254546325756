.edition-comparator{
  position: relative;
  &__block-header{
      display: block;
      width: 100%;
      min-height: 95px;
      background-color: $snow-white;
      padding: 25px 20px 5px 16px;
  }

  &__block-logo{
      position: relative;
      float: left;
      width: 245px;
      height: 40px;
      margin-top: 2px;
  }

  &__logo-img {
      height: 100%;
  }

  &__block-text{
      color: $secondary-color-gm;
      margin-top: 9px;
      font-size: 9px;
      font-family: MTTMilano, sans-serif;
  }

  &__block-header-modified{
      position: relative;
      float: right;
      max-width: calc(100% - 245px);
      padding: 15px 0 0 0;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 15px;
      text-transform: uppercase;
      text-align: right;
      color: $concept-blue;
  }

  &__block-breadcrumb{
      min-height: 40px;
      padding: 10px 16px 0;
      width: 100%;
      position: relative;
      float: left;
      background-color: $background-color-states;
  }

  &__breadcrumb-block{
      padding: 0px;
      float: left;
      width: 100%;
      margin-bottom: 0;
  }

  &__breadcrumb-item{
      position: relative;
      float: left;
  }

  &__breadcrumb-text{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      width: 100%;
      font-family: MTTMilano, sans-serif;
      padding: 0px 20px 0 7px;
      font-size: 13px;
      position: relative;
      line-height: 22px;
  }

  &__icon-angle-right{
      position: absolute;
      font-size: 20px;
      right: -4px;
  }

  &__block-body{
      width: 100%;
      position: relative;
      float: left;
      padding: 30px 15px 100px 10px;
      min-height: calc(100% - 335px);
  }

  &__block-header-body{
      width: 100%;
      position: relative;
      display: inline-block;
      height: 28px;
  }

  &__block-buttons-header-body{
      position: relative;
      float: right;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      top: 5px;
      color: $primary-color;
  }

  &__button-print {
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0px;
      line-height: 12px;
      &--center{
          margin: 0 20px;
      }
  }

  &__button-header-icon{
      font-size: 20px;
      margin-left: 2px;
  }

  &__button-header-text{
      position: relative;
      bottom: 5px;
  }

  &__block-comparator{
      padding-top: 25px;
      display: flex;
      justify-content: space-between;
  }

  &__block-body-form{
      width: calc(50% - 42px);
      padding: 10px;
      background: rgba(242,243,248,0.3);
      box-shadow: 0 0 10px 6px $bar-horizontal-grey-opacity02;
      position: relative;
  }

  &__block-field-historic-list{
      width: 50%;
      position: relative;
      float: left;
  }

  &__button-compare{
      margin-left: 10px;
      position: relative;
      top: 15px;
  }
}
.compliance{
  &__dark-blue{
      .edition-comparator{
          &__breadcrumb-item{
              color: $primary-color;
              &--active{
                  color: $pillow-group;
              }
          }
      }
  }
  &__steel-blue,
  &__uk-steel-blue{
      .edition-comparator{
          &__block-header-modified{
              color: $primary-color-2;
          }
          &__block-breadcrumb{
              background-color: $third-color-2;
          }
          &__breadcrumb-item{
              color: $primary-color-2;
              &--active{
                  color: $border-bottom-2;
              }
          }
          &__icon-angle-right{
              color: $primary-color-2;
          }
          &__block-buttons-header-body{
              color: $border-hover-2;
          }
      }
  }
  &__icam-red{
      .edition-comparator{
          &__block-logo{
              height: 70px;
              margin-left: 18px;
              margin-top: 0;
              bottom: 13px;
          }
          &__block-header-modified{
              color: $text-field-color-icam;
          }
          &__block-breadcrumb{
              background-color: $third-color-icam;
          }
          &__breadcrumb-item{
              color: $primary-color-icam;
              &--active{
                  color: $title-text-icam;
              }
          }
          &__block-buttons-header-body{
              color: $primary-color-icam;
          }
      }
  }
  &__gm-law{
      .edition-comparator{
          &__block-header-modified{
              color: $text-field-color-gm;
          }
          &__block-breadcrumb{
              background-color: $third-color-gm;
          }
          &__breadcrumb-item{
              color: $primary-color-gm;
              &--active{
                  color: $title-text-gm;
              }
          }
          &__block-buttons-header-body{
              color: $primary-color-gm;
          }
      }
  }
  &__com-de{
    .edition-comparator{
        &__block-header-modified{
            color: $text-field-color-com-de;
        }
        &__block-breadcrumb{
            background-color: $third-color-com-de;
        }
        &__breadcrumb-item{
            color: $primary-color-com-de;
            &--active{
                color: $title-text-com-de;
            }
        }
        &__block-buttons-header-body{
            color: $primary-color-com-de;
        }
    }
  }
  &__com-fr{
    .edition-comparator{
        &__block-header-modified{
            color: $text-field-color-com-fr;
        }
        &__block-breadcrumb{
            background-color: $third-color-com-fr;
        }
        &__breadcrumb-item{
            color: $primary-color-com-fr;
            &--active{
                color: $title-text-com-fr;
            }
        }
        &__block-buttons-header-body{
            color: $primary-color-com-fr;
        }
    }
  }
}

@media (max-width: 920px) {
  .edition-comparator{
      &__block-body-form{
          width: calc(50% - 20px);
      }
  }
}

@media print {
  @page {size: landscape}
  .edition-comparator{
      padding: 0 4px;
      // width: 975px;
      &__block-body{
          padding: 5px 0 10px;
      }
      &__block-breadcrumb{
          box-shadow: 0 0 3px 1px $bar-horizontal-grey-opacity02 !important;
      }
      &__block-header-body, &__button-compare{
          display: none;
      }
      &__block-comparator{
          padding: 25px 0px 0px;
      }
      &__block-body-form{
          width: calc(50% - 5px);
          // max-width: calc(50% - 5px);
          box-shadow: 0 0 3px 1px $bar-horizontal-grey-opacity02 !important;
      }
  }
}
