.organization-modal{
  &__window {
      & .modal-dialog {
          & .modal-content {
              height: 674px;
              border-radius: 0px;
          }
      }
  }

  &__block-body {
      height: 539px;
      margin: 0 41px;
  }

  &__form-block {
      padding: 0 33px;
      width: 100%;
      height: 520px;
      overflow: auto;
  }

  &__input {
      display: block;
      float: left;

      &--all {
          width: 100%;
      }

      &--input-left {
          width: 67%;
          padding-right: 0.5%;
      }

      &--input-right {
          width: 33%;
      }

      &--input-center {
          width: 33%;
      }

      &--center-block {
          padding: 0 0.5%;
          width: 34%;
      }

      &--half-left {
          width: 49.75%;
          margin-right: 0.25%;
      }

      &--half-right {
          width: 49.75%;
          margin-left: 0.25%;
      }
  }

  ::placeholder {
      color: $secondary-color;
      font-weight: bold;
      font-size: 12px;
      padding: 0;
      font-family: Arial, sans-serif;
      font-style: normal;
  }

  &__edit-text {
      float: right;
      margin-right: 25px;
      margin-top: 10px;
      cursor: pointer;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
  }

  &__alert-icon {
      color: $snow-white;
      padding-right: 7px;
      position: relative;
      font-size: 20px;
      top: 5px;
  }

  &__edit-block {
      position: absolute;
      top: 11px;
      right: 81px;
      border-right: 1px solid $snow-white;
      height: 50px;
  }

  &__block-business-group {
      display: inline-block;
      width: 100%;
      overflow: hidden;
  }

  &__holding-block {
      display: block;
      width: calc(100% - 18px);
      margin: 25px 9px;
      height: 473px;
  }

  &__cancel-button {
    margin-right: 10px;
  }
}

.modal-content{
  width: 900px;
  height: 603px;
}

.compliance {
&__dark-blue {
  .organization-modal {
    &__edit-text {
      color: $snow-white;
    }
  }
}

&__steel-blue {
  .organization-modal {
    &__edit-text {
      color: $white-special-2;
    }
  }
}

&__uk-steel-blue {
  .organization-modal {
    &__window{
      & .modal-dialog {
          & .modal-content {
              height: 600px;
          }
      }
      &--band-error {
          & .modal-dialog {
              & .modal-content {
                  height: 664px;
              }
          }
      }
    }

    &__block-body {
      height: 506px;
      margin-top: 21px;
    }

    &__form-block--error {
      height: 583px;
    }
  }
}

&__gm-law, &__com-de, &__com-fr {
  .organization-modal {
    &__edit-text {
      color: $snow-white;
    }
  }
}
}
