.alert-detail{
  &__field-interval {
      display: block;
      overflow: hidden;
      width: 100%;
      padding-left: 29px;
  }

  &__form{
      width: 315px;
      height: 342px;
      margin: 0 auto;
      display: block;
      margin-top: 110px;
  }

  &__interval {
      position: relative;
      margin-top: 10px;
  }

  &__interval-text{
      display: inline-block;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: normal;
      width: 90%;
      margin-left: 20px;
  }

  &__interval-icon {
      display: inline-block;
      color: $primary-color;
      font-size: 20px;
      position: absolute;
      left: 0px;
      opacity: 0.5;
  }

  &__radio-button-block {
      display: inline-block;
      margin-left: 27px;
  }

  &__switch {
      width: 288px;
      position: relative;
      margin-left: 0;
  }

  &__time-block{
      width: calc(65% - 4px);
      margin-left: 4px;
      position: relative;
      float: left;
  }

  &__units-block{
      width: 35%;
      position: relative;
      float: left;
  }

  &__check-interval {
      visibility: hidden;
      height: 1px;
      width: 1px;
      cursor: pointer;
  }
}
