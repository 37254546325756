.new-snippet-modal {
  &__window {
      & .modal-dialog {
          & .modal-content {
              height: 622px;
          }
      }
  }

  &__window--new-fragment {
      & .modal-dialog {
          & .modal-content {
              height: 377px;
          }
      }
  }

  &__block-body {
      position: relative;
      height: 505px;
      &--new-fragment {
          height: 260px;
      }
  }

  &__snippet-form {
      width: calc(100% - 140px);
      margin: 40px auto 0;
  }

  &__short-id {
      display: inline-block;
      width: 50%;
  }
}
