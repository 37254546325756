.alert-concept{
    min-height: 600px;
    &__table{
      display: block;
      padding: 25px 10px 10px 10px;
      width: 100%;
    }
    &__table-col{
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        // position: relative;
        max-width: 0;
        &--top-1{
            position: relative;
            top: 1px;
        }
        &--top-3{
            position: relative;
            top: 3px;
        }
        &--origin{
            width: 19%;
        }
        &--origin-window{
            width: 23%;
        }
        &--title{
            width: calc(37% - 70px);
        }
        &--title-window{
            width: 19%;
        }
        &--creator{
            width: 15%;
        }
        &--creator-window{
            width: 17%;
        }
        &--destin{
            width: 15%;
            font-size: 12px;
            font-family: Arial, sans-serif;
        }
        &--destin-window{
            width: 17%;
            font-size: 12px;
            font-family: Arial, sans-serif;
        }
        &--date-alert{
            width: 14%;
        }
        &--date-alert-window{
            width: calc(24% - 70px);
        }
        &--action-header{
            text-align: right;
            width: 70px;
            max-width: 70px;
        }
        &--action-table{
            width: 70px;
            max-width: 70px;
            right: 0;
            position: relative;
        }
        &--header{
            &:hover{
                cursor: pointer;
            }
        }
    }
    &__table-header{
        border-bottom: 5px solid $primary-color;
        padding-bottom: 9px;
        width: 100%;
        display: table;
    }
    &__table-body{
        width: 100%;
        display: block;
        position: relative;
    }

    &__block-icon-source{
        display: inline-block;
        position: relative;
        bottom: 2px;
    }

    &__cell-block-source{
        display: inline-block;
        max-width: calc(100% - 36px);
        padding-left: 4px;
    }

    &__icon-source{
      font-size: 20px;
      position: relative;
      top: 1px;
    }
}
.compliance{
    &__dark-blue{
        .alert-concept{
            &__icon-source{
                color: $primary-color;
            }
        }
    }
    &__steel-blue,
    &__uk-steel-blue{
        .alert-concept{
            &__icon-source{
                 color: $border-hover-2;
            }
        }
    }
    &__icam-red{
        .alert-concept{
            &__icon-source{
                color: $primary-color-icam;
            }
        }
    }
    &__gm-law{
        .alert-concept{
            &__icon-source{
                 color: $primary-color-gm;
            }
        }
    }
    &__com-de{
      .alert-concept{
          &__icon-source{
               color: $primary-color-com-de;
          }
      }
    }
    &__com-fr{
      .alert-concept{
          &__icon-source{
               color: $primary-color-com-fr;
          }
      }
    }
}

@media (max-width: 1480px){
    .alert-concept{
        &__table-col{
            &--menu{
                width: 8%;
            }
            &--menu-no-permision{
                width: 6%;
                text-align: right;
            }
        }
    }
}
