.filter-header {
  height: 36px;
  width: 250px;
  padding: 0;
  margin: 0;
  position: relative;
  float: right;

  &__icon-selected {
      right: 9px;
      top: 13px;
      position: absolute;
      font-size: 10px;
      color: $snow-white;
      z-index: 1;
      cursor: pointer;
      &--duplicate{
          top: 11px;
      }
  }
  &__search {
      height: 25px;
      position: relative;
      margin-top: 5px;
  }
  &__child-padding{
      padding-left: 25px;
      display: inline-block;
      width: 100%;
  }
  &__block-name-child{
      position: relative;
      float: left;
      max-width: calc(100% - 40px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }
  &__name-parent{
      color: $subcontainer-ground;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      padding-left: 6px;
  }

  //////////////////////
  /////Version 2///////
  /////////////////////
  &__buttons-block{
      height: 36px;
      width: 250px;
      display: block;
      padding: 0;
      margin: 0;
      position: relative;
  }

  &__select-list-client{
      width: 250px;
      box-shadow: 0 0 25px 6px $bar-horizontal-grey-opacity;
      padding: 8px 2px 0;
      max-height: 210px;
      top: 36px;
      border-radius: 0px !important;
  }

  &__button-filter{
      background-color: transparent;
      border: none;
      outline: none;
      text-transform: uppercase;
      height: 36px;
      // padding: 0 0 0 10px;
      padding: 5px 0 0 9px;
      position: relative;
      width: 100%;
  }

  &__icons-v2{
      float: left;
      font-size: 20px;
      margin-right: 7px;
  }

  &__text-v2{
    font-family: MTTMilano-Bold, sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    width: calc(100% - 27px);
    position: relative;
    float: left;
    text-align: left;
    &--client{
        width: calc(100% - 47px);
    }
  }

  &__list-items-v2{
      height: 30px;
      padding: 7px 4px;
    &--filials {
      font-size: 11px !important;
    }
  }

  &__list-products-scroll{
      height: 160px;
      margin-bottom: 0;
      overflow-y: auto;
      overflow-x: hidden;
      border-radius: 0px;
      padding: 0;
      max-height: 160px;
      padding-bottom: 7px;
  }

  &__angle-icon {
      float: left;
      position: relative;
      padding-right: 5px;
      top: 2px;
  }
  //////////////////////
  /////Fin Version 2///////
  /////////////////////
}

.compliance{
  &__dark-blue{
    .filter-header{
      &__text-v2{
        color: $snow-white;
      }
      &__button-filter{
        background-color: $primary-color;
        color: $snow-white
      }
    }
  }
  &__steel-blue{
    .filter-header{
      &__select-list-client{
        border: 1px solid $third-color-2;
        margin-top: 5px;
        border-top-width: 2px;
        left: 0;
      }
      &__name-parent{
        color: $grey-icons-2;
        font-family: MTTMilano-Bold, sans-serif;
        font-weight: normal;
        font-size: 12px;
      }
      &__name-child{
        color: $border-hover-2;
        font-family: MTTMilano, sans-serif;
        font-weight: normal;
        font-size: 13px;
      }
      &__button-filter{
        background-color: $third-color-2;
        color: $primary-color-2;
      }
      &__icon-selected{
        color: $primary-color-2;
      }
    }
  }
  &__gm-law{
    .filter-header{
      &__text-v2{
        color: $custom-black-landing;
      }
      &__icons-v2{
        color: $primary-color-gm;
      }
      &__select-list-client{
        border: 1px solid $primary-color-gm;
        margin-top: 5px;
        border-top-width: 2px;
        left: 0;
      }
      &__button-filter{
        background-color: $third-color-gm;
        color: $custom-black-landing;
      }
      &__icon-selected{
        color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    .filter-header{
      &__text-v2{
        color: $custom-black-landing;
      }
      &__icons-v2{
        color: $primary-color-com-de;
      }
      &__select-list-client{
        border: 1px solid $primary-color-com-de;
        margin-top: 5px;
        border-top-width: 2px;
        left: 0;
      }
      &__button-filter{
        background-color: $third-color-com-de;
        color: $custom-black-landing;
      }
      &__icon-selected{
        color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .filter-header{
      &__text-v2{
        color: $custom-black-landing;
      }
      &__icons-v2{
        color: $primary-color-com-fr;
      }
      &__select-list-client{
        border: 1px solid $primary-color-com-fr;
        margin-top: 5px;
        border-top-width: 2px;
        left: 0;
      }
      &__button-filter{
        background-color: $third-color-com-fr;
        color: $custom-black-landing;
      }
      &__icon-selected{
        color: $primary-color-com-fr;
      }
    }
  }
}
