@mixin common-background-hover($backgroundColor){
  &:hover{
      background-color:$backgroundColor;
  }
}

@mixin common-block-concept-color($color){
  &--border{
    border: 2px solid $color;
    border-top: 0;
    margin-top: -2px;
  }
}

@mixin common-button($firstColor, $secondColor){
  color: $firstColor;
  border-color: $firstColor;
  &:after{
      display: none;
  }
  &:hover:not([disabled]){
      background-color:$firstColor;
      color: $secondColor;
  }
}

@mixin common-find-in-dropdown-color($borderColor, $textColor) {
  border-left: none;
  border-bottom-color: $borderColor;
  color: $textColor;
  font-family: MTTMilano, sans-serif;
  font-size: 12px;
  font-weight: 500;
  &::-webkit-input-placeholder {
      color: $textColor;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
  }
}

@mixin common-find-in-dropdown-create-list($firstColor, $secondColor, $thirdColor) {
  border: 1px solid $firstColor;
  background-color: $secondColor;
  height: 29px;

  &::-webkit-input-placeholder {
      color: $thirdColor;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
  }
}

@mixin common-input-block-open-color($firstColor, $secondColor) {
  background-color: $firstColor;
  border-color: $secondColor;
  .common__input-text,
  .common__tooltip-alert-icon,
  .common__delete-content-icon {
      color: $secondColor;
  }
}

@mixin common-language-item($textColor, $backgroundColor) {
  padding: 7px 2px;
  color: $textColor;
  font-family: MTTMilano, sans-serif;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover{
      background: $backgroundColor;
  }
}

@mixin common-multiselect-dropdown-item-with-input($firstColor, $secondColor, $thirdColor) {
  border-bottom-color: $firstColor;
  color: $secondColor;
  > .common__dropdown-inner-input {
      &::-webkit-input-placeholder {
          color: $thirdColor;
          font-family: MTTMilano, sans-serif;
      }

      &::-ms-input-placeholder {
          color: $thirdColor;
          font-family: MTTMilano, sans-serif;
      }

      &::placeholder {
          color: $thirdColor;
          font-family: MTTMilano, sans-serif;
      }
  }
}

@mixin common-multiselect-select-icon($firstColor, $secondColor) {
  color: $firstColor;

  &:hover {
      background: $secondColor;
  }
}

@mixin common-options-row($backgroundColor, $textColor, $borderColor) {
  background-color: $backgroundColor;
  color: $textColor;
  border-color: $borderColor;
}

@mixin common-scrollbar($primaryColor, $secondColor){
  ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
  }

  ::-webkit-scrollbar-track {
      background: $primaryColor;
  }

  ::-webkit-scrollbar-thumb {
      background: $secondColor;
  }

  ::-webkit-scrollbar-thumb:hover {
      background: $secondColor;
  }

  .mce-content-body{
      ::-webkit-scrollbar {
          width: 10px;
          height: 10px;
      }

      ::-webkit-scrollbar-track {
          background: $primaryColor;
      }

      ::-webkit-scrollbar-thumb {
          background: $secondColor;
      }

      ::-webkit-scrollbar-thumb:hover {
          background: $secondColor;
      }
  }
}

@mixin common-select-item-color($textColor, $backgroundColor) {
  color: $textColor;
  &:hover:not([disabled]) {
      border: none;
      cursor: pointer;
      font-weight: bold;
      background: $backgroundColor;

      & > .common__select-icon {
          display: inline-block;
      }
  }
}

@mixin common-select-item-error($backgroundColor, $textColor) {
  background: $backgroundColor !important;
  color: $textColor;
  border: 1px solid $textColor !important;
  &:hover {
      font-weight: normal;
  }
}

@mixin common-select-list-with-input($borderColor) {
  border-color: $borderColor;
  border-top-width: 2px;
  // top: $top;
}

@mixin common-show-alert-color($firstColor, $secondColor, $thirdColor) {
  border-color: $firstColor !important;
  background-color: $secondColor !important;

  &:hover {
      background-color: $thirdColor !important;
  }
}

@mixin common-table-row($borderColor, $backgroundHoverColor) {
  border-color: $borderColor;
  &:hover:not([disabled]){
      background-color: $backgroundHoverColor;
  }
}

@mixin common-table-row-selected-item($backgroundHoverColor) {
  background-color: $backgroundHoverColor;
  & .common__options-row {
      visibility: visible;
  }
}

@mixin common-tooltip-dropdown-color($primaryColor, $secondColor, $thirdColor) {
  color: $primaryColor;
  background-color: $secondColor;
  border-color: $thirdColor;
  border-left-color: $primaryColor;

  &:after {
      border-right-color: $primaryColor;
  }
  &:hover {
      border-color: $primaryColor;
  }
}

html,
body {
  height: 100%;
  cursor: default;
}

body{
  overflow: hidden;
      // &__view {
    // height: 100%;
    // width: 100%;
    // display: block;
    // overflow: auto;
    // overflow-x: hidden;
// }
}

a {
  color: inherit;
  &:hover {
      color: inherit;
  }
  &:visited {
      color: inherit;
  }
  &:focus {
      color: inherit;
  }
}

.uib-datepicker-popup,
.uib-datepicker-popup :focus {
  outline: none;
}

.highlight {
  background-color: #EFD2D4;
}

.common {
  &--strong {
      font-weight: bold;
  }

  &__app {
      display: block;
      width: 100%;
      height: 100%;
  }

  &__back-link {
      float: right;
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      margin-right: 41px;
      border: none;
      background: none;
      outline: none;
  }

  &__back-link-icon{
      font-size: 14px;
      margin-left: 9px;
  }

  &__block-button-close-window {
      position: relative;
      width: 100%;
      height: 24px;
      margin: 25px 0;
      padding: 0 15px;
  }

  &__block-window-view{
      width: 100%;
      position: relative;
      margin-bottom: 1px;
      min-height: calc(100% - 251px);
  }

  &__container-window-view{
      max-width: 1420px;
      margin: 0 auto;
      padding: 0 10px;
  }

  &__block-concept {
      display: block;
      background-color: $snow-white;
      width: 100%;
      margin: 0 0 12px;
  }

  &__block-footer-table {
      width: calc(100% - 20px);
      display: inline-flex;
      justify-content: space-between;
      align-items: flex-end;
      position: absolute;
      bottom: 10px;
  }

  &__block-footer-total {
      font-family: Arial, sans-serif;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 11px;
      text-align: right;
      width: 94px;
      float: right;
      position: absolute;
      right: 0;
      bottom: -5px;

      &--bottom-2 {
          bottom: -2px;
      }
  }

  &__block-icon {
      font-size: 20px;
      margin-top: 9px;
      color: $primary-color;
      position: relative;
      left: 29px;
      top: 9px;
  }

  &__block-icon-close-modal-custom {
      margin: 12px 10px 2px 10px;
      height: 18px;
      text-align: right;
  }

  &__block-input-custom {
      height: 39px;
      padding: 15px 0 0 0;
      border: none;
      border-top: 1px solid $custom-border-grey;
      border-bottom: 1px solid $custom-border-grey;
      width: 100%;
      position: relative;

      &--black {
          border: none;
          border-bottom: 1px solid $custom-black-landing;
      }

      &--contact-simple {
          width: calc(100% - 96px);
          float: left;
      }

      &--contact-multiple {
          width: calc(100% - 186px);
          float: left;
      }
  }

  &__block-html-info {
      & > p {
          margin: 0;
      }
  }

  &__block-list-new-version {
      position: relative;
      float: left;
      height: 700px;
      width: 100%;
      margin-bottom: 100px;
      border: 1px solid;
      padding: 10px;
      background-color: $snow-white;

      &--height-auto {
          height: auto;
      }

      &--open-task {
          height: auto;
          min-height: 700px;
      }
  }

  &__block-option-list {
      box-shadow: 0 0 25px 6px $bar-horizontal-grey-opacity;
      background-color: $snow-white;
      position: absolute;
      top: 6px;
      left: 0px;
      width: 160px;
      padding: 6px 5px;
      border: 1px solid;
      border-top-width: 2px;
      border-radius: 0;
      z-index: 11;
      &--center {
          left: 50%;
      }
  }

  &__block-search {
      position: relative;
      float: left;
      width: 180px;
  }

  &__box-text-info {
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 22px;
      margin: 0;
      text-align: left;
      padding-left: 35px;
  }

  &__button {
      background-color: transparent;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 14px;
      font-weight: normal;
      text-align: center;
      text-transform: uppercase;
      outline: 0;
      padding: 9px 35px 7px 35px;
      margin-left: 3px;//para solucionar perdida de espacio entre botones
      &--cancel-replay {
          color: #AAAAAA;
          border: 3px solid #AAAAAA;

          &:hover {
              color: $snow-white;
              background-color: #AAAAAA;
          }
      }

      &--active {
          border: 3px solid $primary-color;
          color: $primary-color;
          height: 41px;

          &:hover:not([disabled]) {
              color: $snow-white;
              background-color: $primary-color;
          }

          &[disabled] {
              opacity: 0.5;
          }

          &:after {
              content: " ";
              border-bottom: 3px solid $primary-color;
              width: calc(100% + 76px);
              display: block;
              right: 38px;
              top: 10px;
              position: relative;
          }
      }

      &--active-view {
          border: 3px solid $primary-color;
          color: $primary-color;

          &:hover {
              color: $snow-white;
              background-color: $primary-color;
          }
      }

      &--add-pattern {
          border: 3px solid $primary-color;
          color: $primary-color;

          &:hover {
              color: $snow-white;
              background-color: $primary-color;
          }
      }

      &--create-form {
          border: 3px solid $primary-color;
          color: $primary-color;

          &:hover {
              color: $snow-white;
              background-color: $primary-color;
          }
      }

      &--disabled {
          &:hover {
              color: $primary-color;
              background-color: $snow-white;
              cursor: default;
          }
      }

      &--active-disabled {
          border-color: rgba(0,25,120, 0.5);
          color: rgba(0,25,120, 0.5);
          cursor: default;

          &:hover {
              border-color: rgba(0,25,120, 0.5);
              color: rgba(0,25,120, 0.5);
              background-color: transparent;
          }
      }

      &--blue {
          border: 3px solid $primary-color;
          color: $primary-color;

          &:hover {
              color: $snow-white;
              background-color: $primary-color;
          }
      }

      &--auto {
          @include button-with-border($snow-white, $primary-color, transparent);
      }

      &--clean {
          @include button-with-border($snow-white, #aaa, transparent);
      }

      &--clean-all {
          @include button-with-border($snow-white, #aaa, transparent);
      }

      &--white {
          @include button-with-border( $primary-color, $snow-white, transparent);
      }

      &--white-red {
          @include button-with-border( $alert-color, $snow-white, transparent);
      }

      &--red {
        position: relative;
        @include button-with-border($snow-white, $red, transparent);
      }

      &--grey {
          @include button-with-border( $primary-color, #aaa, transparent);
      }

      &--grey-white {
          @include button-with-border( $snow-white, #aaa, transparent);
      }

      &--grey-red {
          @include button-with-border( $alert-color, #aaa, transparent);
      }

      &--green {
          @include button-with-border( #77D166, $snow-white, #77D166);
      }

      &--litle-question {
          padding: 5px 23px 3px 23px;
          width: 74px;
          height: 31px;
          font-size: 12px;
          margin-right: 0px;

          &:after {
              width: 74px;
              display: block;
              right: 26px;
              top: 7px;
              position: relative;
          }
      }

      &--litle {
          padding: 5px 23px 3px 23px;
          height: 31px;
          font-size: 12px;
          margin-right: 0px;

          &:after {
              width: calc(100% + 52px);
              display: block;
              right: 26px;
              top: 7px;
              position: relative;
          }
      }

      &--disabled-opacity {
          opacity: 0.5;

          &:hover {
              background-color: transparent;
              cursor: default;
          }
      }

      &--separating-margin {
          margin-left: 7px;
      }

      &--icon-down{
        padding: 9px 52px 7px 12px;
        &.dropdown-toggle{
          &:after{
            border-top: 0;
            border-right: 0;
            border-left: 0;
            margin: 0;
            // vertical-align: 0;
            display: none;
          }
        }
      }

      &--from-to {
          font-size: 12px;
          padding: 4px 18px 4px 18px;
          height: 30px;
      }
  }

  &__button-border-none {
      border: none;
      height: 26px;
      margin-bottom: 3px;
      width: auto;
      color: $primary-color;
      position: relative;
      bottom: 2px;
      outline: none;
      background-color: transparent;
      padding: 0 10px 0 6px;

      &--left {
          float: left;
      }

      &:hover:not([disabled]) {
          background-color: $subcontainer-border;
      }

      &:after {
          content: " ";
          border-bottom: 2px solid $primary-color;
          width: calc(100% + 16px);
          display: block;
          right: 6px;
          top: 3px;
          position: relative;
      }

      &[disabled] {
          opacity: 0.5;

          &:hover {
              cursor: inherit;
          }
      }
  }
  &__tooltip-report-text{
    color: #001978;
    font-family: "Helvetica";
    font-size: 11px;
    font-weight: 400;
    margin: 0;
  }
  &__tooltip-report-box{
    display: flex;
    max-width: 200px;
    padding: var(--spacing-xs, 8px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xxs, 4px);
  }
  &__tooltip-report-inner-box{
    padding: 0 15px;
    margin: 0; 
  }

  &__button-landing-custom{
      border: 1px solid transparent;
      outline: none;
      border-radius: 4px;
      background-color: $snow-white;
      box-shadow: 0 2px 4px 0px $bar-horizontal-grey-opacity02;
      color: $custom-black-landing;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      font-weight: 900;
      text-align: center;
      text-transform: uppercase;
      margin-right: 15px;
      padding: 10px 30px;
      height: 38px;
      min-width: 100px;

      &--send{
          &:hover:not([disabled]){
              background-color:$custom-secondary-color !important;
              color: $snow-white !important;
              border-color:$custom-secondary-color !important;
          }
      }

      &--cancel{
          // margin-right: 0;
          &:hover:not([disabled]){
              background-color: $snow-white !important;
              color:$custom-secondary-color !important;
              border-color:$custom-secondary-color !important;
          }
      }

      &--little{
          height: 26px;
          min-width: auto;
          margin-right: 8px;
          padding: 5px 20px;
          font-size: 11px;
          font-weight: 500;
      }

      &--last{
          margin-right: 0;
      }

      &[disabled]{
          opacity: 0.5;
          &:hover{
              cursor: inherit;
          }
      }

      &--disabled{
          color: $snow-white !important;
          background-color: $attachment-custom-grey-other !important;
          opacity: 1 !important;
      }

      &--required{
          background-color: $advice-close !important;
      }
  }

  &__buttons-block {
      display: block;
      margin-top: 20px;
      margin-bottom: 12px;
      text-align: right;

      &--customize {
          margin-bottom: 0;
      }

      &--modal {
          position: absolute;
          left: 71px;
          right: 71px;
          bottom: 23px;
          margin-top: 0;
          margin-bottom: 0;
      }
      &--modal-relative {
          position: relative;
          padding-right: 71px;
          margin-bottom: 23px;
      }

      &--attach {
          bottom: 25px;
          position: absolute;
          right: 80px;
      }

      &--modal-2 {
          position: absolute;
          // right: 86px;
          right: 74px;
          bottom: 23px;
          margin-top: 0;
          margin-bottom: 0;
      }

      &--add-alert {
          position: absolute;
          left: 80px;
          right: 74px;
          bottom: 23px;
          margin-top: 0;
          margin-bottom: 0;
      }

      &--new-contact {
          position: absolute;
          bottom: 0;
          right: 12px;
      }

      &--init {
          bottom: 41px;
      }

      &--custom {
          margin-top: 28px;
      }

      &--modal-custom {
          position: absolute;
          right: 80px;
          bottom: 30px;
          margin: 0;
      }

      &--modal-landing-custom{
          position: absolute;
          right: 0px;
          bottom: 23px;
          margin-top: 0;
          margin-bottom: 0;
      }
  }

  &__button-box-icon {
      font-size: 25px;
      padding-right: 5px;
  }

  &__button-box-none {
      color: $primary-color;
      border: none;
      padding: 0;
      margin: 8px 10px;
      width: 20px;
      height: 20px;
      outline: none;
      background-color: transparent;
      font-size: 20px;
  }

  &__button-box-option {
      background: none;
      border: none;
      outline: none;
      color: $primary-color;
      font-family: MTTMilano-bold, sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      padding: 0;
  }

  &__button-box-reports {
      width: 27px;
      height: 32px;
      display: inline-block;
      border-bottom: 2px solid $primary-color;
      font-size: 18px;

      &--space-between {
          margin-right: 4px;
      }

      &--upload {
          font-size: 17px;
          margin-right: 5px;
      }

      &--caap {
          width: 123px;
          height: 29px;
          position: relative;
          bottom: 4px;
          border: 0;
      }

      &--caap-report {
          position: absolute;
          right: 15px;
          bottom: 20px;
          width: auto;
          height: 29px;
          border: 0;
      }

      &--edit {
          width: auto;
          position: absolute;
          bottom: 20px;
          right: 28px;
      }
  }

  &__button-box-text {
      position: relative;
      bottom: 5px;
      margin-left: 4px;
  }

  &__button-checked {
      color: $primary-color;
      border: 2px solid $primary-color;
      padding: 2px 0;
      text-align: center;
      width: 26px;
      height: 26px;
      outline: none;
      background-color: transparent;
      font-size: 18px;
      display: inline-block;
      @extend .span__auto-ajust;
      &:hover:not([disabled]) {
          background-color: $primary-color;
          color: $snow-white;
          cursor: pointer;
      }

      &[disabled] {
          opacity: 0.5;
      }

      &--32 {
          width: 32px;
          height: 32px;
          font-size: 20px;
      }
  }

  &__button-close {
      color: #AAAAAA;
      border: 2px solid #AAAAAA;
      padding: 2px 0;
      text-align: center;
      width: 26px;
      height: 26px;
      outline: none;
      background-color: transparent;
      font-size: 18px;
      display: inline-block;

      &:hover {
          background-color: #AAA;
          color: $snow-white;
          cursor: pointer;
      }

      &:after {
          content: " ";
          border-bottom: 2px solid #AAAAAA;
          width: calc(100% + 4px);
          display: block;
          right: 2px;
          top: 5px;
          position: relative;
      }
  }

  &__button-generic {
      outline: 0;
      text-align: center;
      border: 0px;
      background: transparent;
  }

  &__cell-block {
      position: relative;
      height: 17px;
      max-width: 100%;
      &--padding-right {
          padding-right: 5px;
      }

      &--process-state {
          float: left;
          max-width: calc(100% - 135px);
          margin-left: 5px;
          cursor: default;
      }
  }

  &__checkbox {
      color: $primary-color;

      &:hover:not([disabled]) {
          cursor: pointer;
      }

      &[disabled] {
          color: #AAAAAA;
      }
  }

  &__col-text {
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      margin: 0;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &--cursor {
          cursor: pointer;
      }

      &--blue {
          color: $primary-color;
          font-family: MTTMilano-Bold, sans-serif;
      }
  }

  &__concept-menu {
      background-color: $snow-white;
      width: 38px;
      height: 145px;
      float: left;
      box-shadow: 0 0 5px 1px rgba(65, 63, 63, 0.2);
      margin-top: 4px;
      z-index: 11;

      &--without-permission {
          height: 54px;
      }
  }

  &__concept-menu-fixed {
      position: relative;
      float: left;
      width: 38px;
      height: 100px;
      margin-top: 37px;
  }

  &__concept-menu-fixed-scroll {
      transition: top 0.2s ease-in-out;

      &--first {
          top: 0px;
          position: fixed;
      }
  }

  &__contacts-import-icon {
      font-size: 100px;
      text-align: center;
      margin: 18px 0 0;

      &--disabled {
          opacity: 0.1;
      }
  }

  &__container {
      margin-right: auto;
      margin-left: auto;
      padding-left: 15px;
      padding-right: 15px;
      height: 100%;

      &:before,
      &:after {
          content: " ";
          display: table;
      }

      &:after {
          clear: both;
      }
  }

  &__create{
    display: inline-block;
      &--edit-concept{
          display: block;
          float: right;
      }
      &--subcontainer{
          padding-top: 11px;
          margin-right: 11px;
      }
  }

  &__cursor-pointer {
      &:hover {
          cursor: pointer;
      }
  }

  &__datepicker-col {
      width: 57px;
      text-align: center;
      font-family: MTTMilano, sans-serif;
      &--from-to {
          width: 25px;
      }
  }

  &__datepicker-container {
      width: 400px;

      &--from-to {
        // width: 174px;
        width: 100%;
      }
  }

  &__datepicker-header {
      height: 68px;

      &--month {
          height: 40px;
      }
  }

  &__datepicker-header-tab {
      border-bottom: 1px solid #D6D6D6;
  }

  &__datepicker-previous-next {
      border: none;
      background: transparent !important;
      font-size: 13px;
      outline: none;
  }

  &__textarea-simple {
      width: 100%;
      height: 100px;
      resize: none;
      outline: none;
      border: 1px solid $border-color-other;
      padding: 5px 8px;
  }

  &__text-muted {
      color: $result-search-quote-color;
  }

  &__delete-content-icon {
      display: none;
      font-size: 10px;
      position: absolute;
      bottom: 6px;
      right: 6px;
      z-index: 10;

      &:hover {
          cursor: pointer;
      }

      &--plus {
          top: 9px;
          right: 28px;
      }

      &--contact-visibility {
          visibility: hidden;
          bottom: -1px;
      }
  }

  &__description-tooltip-info-custom {
      display: block;
      overflow: hidden;
      overflow-y: auto;
      width: 100%;
      max-height: 123px;
      color: $custom-black-landing;
      font-family: 'lato_regular', sans-serif;
      font-size: 11px;
      padding: 0 25px 0 0;
  }

  &__documents-block {
      width: 98%;
      height: 152px;
      overflow-y: auto;
      overflow-x: hidden;
      margin-left: 1%;
      border-top: 1px solid $primary-color;
      padding: 0;
      list-style: none;
      margin-bottom: 10px;
      margin-top: 57px;

      &--expand {
          height: 541px;
      }
  }

  &__dropdown {
      position: absolute;
      display: block;
      width: 100%;
      top: 25px;

      &--filter-header {
          top: -1px;
      }
  }

  &__dropdown-initial {
      display: block;
      width: 100%;
      position: initial;

      &--flex {
          display: flex;
      }
  }

  &__dropdown-arrow-box {
      overflow: hidden;
      width: 8px;
      display: inline-block;
      height: 12px;
  }

  &__dropdown-inner-input {
      width: 100%;
      border: none;
      outline: none;
      background-color: transparent;

      &::-webkit-input-placeholder {
          font-style: normal;
          padding: 0px;
      }
  }

  &__dropdown-selector-button{
    display: flex;
    width: 160px;
    padding: 6px 6px 6px 10px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $result-search-quote-color;
    background: #FFF;
    &:hover:not([disabled]) {
      border: 1px solid $primary-color;
      background: $background-color-states;
    }
    &[disabled] {
      background: $background-grey;
    }
  }

  &__ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }

  &__ellipsis-100 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    margin: 0;
  }

  &__error-button-list {
      width: auto;
      background: none;
      border: none;
      border-bottom: 2px solid $red;
      color: $red;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      float: right;
      padding: 1px 4px;
      text-transform: uppercase;
      outline: 0;
      bottom: 3px;
      position: relative;
  }

  &__error {
      background-color: $background-error !important;

      &:hover {
          background-color: $background-grey !important;
      }
  }

  &__filter-icon {
      color: $subcontainer-ground;
      margin-left: 2px;
      float: left;
      font-size: 16px;

      // &:hover:not([disabled]) {
      &:hover {
          cursor: pointer;
      }
      &--disabled{
        &:hover {
          cursor: initial;
        }
      }

      &--no-float {
          float: none;
          display: inline-block;
          margin: 0;
      }

      &--active {
          color: $primary-color;
      }

      // &--landing-custom {
      //     font-size: 20px;
      // }

      // &--active-landing-custom {
      //     color: $custom-black-landing;
      // }
  }

  &__column-loader {
      float: left;
      width: 12px;
      height: 12px;
      margin-left: 6px;
  }

  &__field-list {
      display: block;
      list-style: none;
      overflow: auto;
      width: 100%;
      margin: 0;
      padding: 0 8px;
      height: 600px;
  }

  &__find-dropdown-block {
      display: block;
      width: 100%;
      overflow: hidden;
      padding: 6px;
      position: relative;
  }

  &__find-dropdown-icon-add {
      color: $primary-color;
      font-size: 18px;
      position: absolute;
      right: 8px;
      top: 14px;
  }

  &__find-dropdown-icon-find {
      color: $primary-color;
      font-size: 20px;
      position: absolute;
      right: 2px;

      &:hover {
          cursor: pointer
      }

      &--picker-visibility {
          top: initial;
          bottom: 13px;
          right: 0px;
          float: right;
      }
  }

  &__find-in-dropdown {
      display: inline-block;
      outline: none;
      border-top: 0px;
      border-right: 0px;
      border-bottom: 1px solid $primary-color;
      border-left: 1px solid $primary-color;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      padding-left: 5px;
      background: transparent;
      height: 24px;
      width: 100%;

      &::-webkit-input-placeholder {
          font-style: normal;
          padding: 0px;
      }

      &--create {
          border: 1px solid $primary-color;
          background-color: $background-color-states;
          height: 29px;
          width: 97%;
      }

      &--picker-visibility {
          max-width: 210px;
      }

  }

  &--flipV {
      -moz-transform: scaleY(-1);
      -o-transform: scaleY(-1);
      -webkit-transform: scaleY(-1);
      transform: scaleY(-1);
      filter: FlipV;
      -ms-filter: "FlipV";
  }

  &__header-button-add-new-version {
      font-size: 30px;
      outline: none;
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0;
      position: relative;
      float: right;
      right: 0;

      &--upload {
          font-size: 33px;
          padding-right: 9px
      }

      &--more-icons{
          padding-right: 10px;
      }

      &[disabled] {
          opacity: 0.5;
      }
  }

  &__header-new-version {
      width: 100%;
      height: 50px;
      padding: 10px;
  }

  &__header-icon-new-version {
      font-size: 30px;
      position: relative;
      float: left;
      top: 0;
      margin-right: 20px;
  }

  &__header-textarea {
      border: 1px solid $border-color-other;
      border-bottom: none;
      margin-top: 10px;
      position: relative;
      width: 100%;
  }

  &__header-title-new-version {
      position: relative;
      float: left;
      bottom: 2px;
      font-family: MTTMilano, sans-serif;
      font-size: 25px;
      font-weight: 900;
      max-width: calc(100% - 150px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }

  &__height-100 {
      height: 100%;
  }

  &__historic-table {
      width: 98%;
      height: 165px;
      overflow: auto;
      margin-left: 1%;
      position: relative;
      margin-top: 54px;

      &--expand {
          height: 540px;
      }
  }

  &__icon-button {
      padding: 0;
      border: none;
      outline: none;
      background: none;

      &--encircled {
          border: 1px solid $primary-color;
          border-radius: 10px;
      }

      &[disabled] {
          opacity: 0.5;
      }
  }

  &__icon-button-search {
      font-size: 20px;
      position: relative;
      top: 5px;
      right: -4px;
      &--open {
          right: 4px;
          position: absolute;
      }
  }

  &__icon-button-underlined {
      font-size: 18px;
      position: relative;
      top: 2px;
      &--top-4 {
          top: 4px;
      }
  }

  &__icon-close-modal {
      color: $snow-white;
      float: right;
      font-size: 20px;
      top: 4px;
      position: relative;

      &--custom {
          font-size: 25px;
          top: 0;
      }

      &:hover {
          cursor: pointer;
      }
  }

  &__icon-close-modal-custom {
      outline: none;
      border: none;
      padding: 0;
      background-color: transparent;
      font-size: 18px;
      color: $custom-black-landing;

      &:hover {
          cursor: pointer;
      }
  }

  &__icon-close-round-custom-info {
      position: absolute;
      right: 5px;
      top: 5px;
      font-size: 14px;
      background-color: transparent;
      border: none;
      padding: 0;
      outline: none;

      &:hover {
          cursor: pointer;
      }
  }

  &__icon-ellipsis {
      float: right;
      color: $primary-color;
      font-size: 27px;
      margin-right: -5px;
      border: none;
      background-color: transparent;
      outline: none;
      padding: 0;

      &--disabled {
          opacity: 0.2;
      }
  }

  &__icon-header {
      font-size: 30px;
      color: $snow-white;

      &--custom {
          font-size: 25px;
          color: inherit;
      }
  }

  &__icon-item {
      list-style: none;
      border-bottom: 1px solid lightgrey;
      margin: 6px;
  }

  &__icon-link {
      &:hover {
          text-decoration: none;
          color: unset;
      }
  }

  &__icon-list-is-hightlist {
      position: absolute;
      margin-left: -696px;
      font-size: 10px;
      margin-top: 30px;
      color: white;
  }

  &__inherited-text-tooltip{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: inherit;
  }

  &__inner-list {
      width: 100%;
      border-radius: 0px;
      list-style: none;
      padding-left: 8px;
      display: block;
  }

  &__input-block-label {
      position: relative;
      width: 100%;
      height: 19px;
  }

  &__input-block {
      width: 100%;
      display: block;
      min-height: 50px;
      border: 1px solid $result-search-quote-color;
      background-color: $snow-white;
      // background-color: transparent;
      margin-bottom: 10px;
      position: relative;
      padding-top: 5px;

      &:hover {
          & .common__delete-content-icon {
              display: block;
          }
      }

      &--textarea-multiline {
          position: relative;
          width: 100%;
          display: block;
          min-height: 80px;
          margin-bottom: 10px;
          padding-top: 5px;
          padding-bottom: 6px;
          font-weight: normal;

          &:hover {
              background-color: $background-color-states;
              border-color: $primary-color;

              & .common__tooltip-info-box {
                  display: block;
              }

              & .common__delete-content-icon {
                  display: block;
                  bottom: 14px;
                  right: 14px;
              }

              & .common__tooltip-law, .common__info-icon-block, .common__tooltip-law-block {
                  visibility: visible;
              }
          }
      }

      &--field {
          &:hover {
              background-color: $background-color-states;
              border-color: $primary-color;

              & .common__delete-content-icon {
                  color: $primary-color;
              }

              & .common__input-textarea {
                  background: $background-color-states;
              }

              & .common__tooltip-info-box {
                  display: block;
              }

              & .common__tooltip-law, .common__info-icon-block, .common__tooltip-law-block {
                  visibility: visible;
              }
          }
      }

      &--open {
          background: $background-color-states;
      }
      &--visibility {
          &:hover {
              // background: $background-color-states;
              // border-color: $primary-color;
              & .common__tooltip-info-box {
                  display: block;
              }
              & .common__tooltip-law, .common__info-icon-block, .common__tooltip-law-block {
                  visibility: visible;
              }
          }
      }

      &--poll-view {
          border: none;
          background: none;

          &:hover {
              border: none;
              background: none;

              & .common__references-block {
                  visibility: visible;
              }

              & .common__related-block {
                  visibility: visible;
              }

              & .common__tooltip-alert-icon--poll {
                  visibility: visible;
              }
          }
      }

      &--show-references {
          margin-bottom: 0;
          border-bottom: 0;
      }

      &--custom-field {
          border-color: $custom-border-grey;
          background-color: $snow-white;
          box-shadow: 0 1px 4px 0 $modal-help-box;
          margin-bottom: 15px;
          position: relative;

          &:hover {
              border-color: $custom-black-landing;
              background: none;
              &:hover{
                  & .common__tooltip-law, .common__info-icon-block, .common__tooltip-law-block {
                      visibility: visible;
                  }
              }
          }
      }

      &--custom-field-fixed {
          border-color: $custom-border-grey;
          background-color: $snow-white;
          box-shadow: 0 1px 4px 0 $modal-help-box;
          margin-bottom: 15px;
          position: relative;

      }

      &--custom-list {
          border-color: $custom-border-grey;
          background-color: $snow-white;
          margin-bottom: 15px;
          position: relative;

          &:hover {
              border-color: $custom-black-landing;
              background-color: $snow-white;

              & .common__tooltip-law, .common__info-icon-block, .common__tooltip-law-block {
                  visibility: visible;
              }

              & .common__tooltip-info-box {
                  display: block;
              }
          }
      }

      &--open-custom {
          border-color: $custom-black-landing;
      }

      &--border-none{
          border: none;
          margin: 0;
      }
  }

  &__input-no-editable {
      border-color: $attach-icon-grey;
      background: $background-grey;

      &:hover {
          border-color: $attach-icon-grey;
          background: $background-grey;
          cursor: not-allowed;

          & .common__delete-content-icon {
              display: none;
          }

          & .common__select-readonly[readonly]:hover {
              cursor: not-allowed;
          }

          & .common__input-textarea {
              cursor: not-allowed;
          }
      }
  }

  &__input-poll {
      display: block;
      height: 50px;
      width: 100%;
      border: 1px solid $custom-border-grey;
      box-shadow: 0 1px 4px 0 $modal-help-box;
      position: relative;
      margin-top: 5px;

      &--list {
          height: unset;
      }

      &--list-extend {
          height: unset;
          padding-top: 20px;
      }

      &--open{
          border-color: $custom-black-landing;
      }

      &:hover {
          border-color: $custom-black-landing;
      }
  }

  &__input-text-poll {
      color: $concept-blue;
      font-family: MTTMilano, sans-serif;
      font-size: 22px;
      font-weight: 500;
      text-transform: none;
      padding-left: 0;
      display: block;
      width: 100%;
      &--email {
          float: none;
      }

      &--error {
          color: $red;
      }
  }

  &__icon-textarea-multiline {
      &:after {
          color: $primary-color;
          opacity: 1;
      }
  }

  &__input-content {
      border: none;
      padding-left: 10px;
      outline: 0;
      width: calc(100% - 50px);
      font-weight: 500;
      background: transparent;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;

      &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px $snow-white inset;
          margin-top: -3px;
      }

      &--poll {
          font-size: 16px;
          padding-top: 13px;
      }

      &--description-formu {
          padding-left: 3px;
      }

      &--from-to {
          margin: 0;
          display: flex;
          position: relative;
          width: 100%;
          padding: 1px 0 1px 10px;
      }

      &--no-name {
          color: $red;
          font-weight: 500;
      }
  }

  &__input-content-no-editable {
      color: $secondary-color;
      cursor: not-allowed !important;

      &:hover {
          cursor: not-allowed !important;
      }
  }

  &__input-content-alert {
      color: $red;

      &::placeholder {
          color: $red;
          font-style: italic;
      }
  }

  &__input-nocontent {
      color: red;
  }

  &__input-icon {
      float: right;
      position: absolute;
      right: 5px;
      top: 6px;
      padding-right: 0px;
      padding-top: 0px;
      font-size: 14px;
      opacity: 0.5;
      color: $attach-icon-grey;
      background-color: transparent;
      border: none;
      outline: none;

      &[disabled] {
          &:hover {
              cursor: inherit;
          }
      }

      &--active {
          color: $primary-color;
          font-size: 16px;
          opacity: 1;

          &:hover:not([disabled]) {
              cursor: pointer;
          }
      }

      &--active-no-editable {
          opacity: 0.5;
          color: $attach-icon-grey;
          font-size: 16px;
          cursor: not-allowed !important;
      }

      &--custom-icon {
          font-size: 15px;
          color: $custom-black-landing;
          top: 4px;
      }
  }

  &__input-icon-active {
    opacity: 1;
    font-size: 16px;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    &:hover {
        cursor: pointer;
    }

    &--disabled {
      opacity: 0.5;
      font-size: 16px;
      &:hover {
          cursor: not-allowed;
      }
    }
  }

  &__input-text {
      min-height: 20px;
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      font-weight: 500;

      &--multiple {
          width: calc(100% - 54px);
      }

      &--secondary {
          color: $pillow-group;
      }

      &--video {
          width: 100%;
          float: none;
      }
  }

  &__info-icon-block {
      position: relative;
      display: inline-block;
      bottom: 14px;
      width: 18px;
      margin-left: 4px;
      visibility: hidden;

      &--visible {
          visibility: visible;
      }
  }

  &__input-textarea {
      border: none;
      resize: none;
      width: 100%;
      height: 140px;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      display: block;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      font-weight: 400;
      outline: none;
      background: transparent;

      &--expanded {
          height: 380px;
          padding-bottom: 0;
      }

      &--resize {
          resize: vertical;
          min-height: 80px;
      }

      &--disabled {
          background-color: $snow-white;

          &:hover {
              cursor: not-allowed !important;
          }
      }

      &--poll {
          height: 110px;
          border: 1px solid $attach-icon-grey;
          background-color: $snow-white;
          font-size: 14px;
          padding: 5px 12px 11px;
      }

      &--custom {
          color: $custom-secondary-color;
          font-family: 'lato_regular', sans-serif;
          font-size: 14px;
          padding-right: 18px;
      }

      &--custom-sequential {
          width: 100%;
          height: 182px;
          padding: 53px 39px 31px 28px;
          color: $custom-black-landing;
          font-family: 'lato_regular', sans-serif;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 21px;
          outline: none;
          background: transparent;
          border: none;
          resize: none;
      }
  }

  &__input-textarea-special {
      width: 100%;
      // height: 182px;
      resize: none;
      position: relative;
      float: left;
      height: auto;
      &--with-margin-bottom{
        margin-bottom: 10px;
      }
      // &--expanded {
      //     height: 359px;
      // }
  }

  &__input-text-placeholder {
      color: $primary-color;
      font-size: 12px;
      font-weight: bold;
      padding: 6px 0px 0px 10px;
      margin: 0px;
      float: left;
      height: 23px;
  }

  &__is-hightLigth {
      width: 15%;
      border-radius: 120px;
  }

  &__label-block {
      position: relative;
      float: left;
      width: calc(100% - 10px);
      padding-left: 10px;
      line-height: 17px;
      margin-bottom: 3px;

      &--margin-0 {
          margin: 0;
      }

      &--one-icon {
          max-width: calc(100% - 25px);
      }

      &--one-icon-bubble {
          max-width: calc(100% - 58px);
      }

      &--two-icon {
          max-width: calc(100% - 50px);
      }

      &--two-big-icon {
          max-width: calc(100% - 65px);
      }

      &--two-icon-bubble {
          max-width: calc(100% - 83px);
      }

      &--three-icon {
          max-width: calc(100% - 75px);
      }

      &--four-icon {
          max-width: calc(100% - 100px);
      }

      &--four-icon-bubble {
          max-width: calc(100% - 165px);
      }
  }

  &__label-selector-button{
    color: $secondary-color;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &__language-item {
      // width: calc(100% - 20px);
      width: 100%;
      height: 30px;
      position: relative;
      // margin-right: 20px;

      &:hover {
          cursor: pointer;

          & .common__language-icon {
              display: block;
          }
      }

      &--custom {
          padding: 2px 3px 9px 16px;
          height: 26px;
          margin: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &:hover {
              background-color: $custom-background;
          }
      }
  }

  &__language-icon {
      position: absolute;
      right: 11px;
      top: 8px;
      display: none;
      font-size: 16px;
  }

  &__list-dropdown-option {
      height: 47px;
      margin: 0;
      list-style: none;

      &--sibila {
          & .common__button-option-list-dropdown {
              color: $snow-white;
              background-color: $pink;
          }

          & .common__icon-option-list-dropdown {
              color: $snow-white;
          }
      }

      @include common-background-hover($background-grey);
  }

  &__bubble-count {
      height: 15px;
      width: 26px;
      background: $primary-color;
      color: $snow-white;
      border-radius: 20px;
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: bold;
      font-style: normal;
      text-align: center;
      position: absolute;
      right: 28px;
      top: 5px;
      padding-top: 1px;
      &--right-6 {
          right: 6px;
      }

      &--top-7 {
          top: 7px;
      }

      &--disabled {
          background: $secondary-color;
      }
  }

  &__bubble-count-custom {
      float: right;
      margin: 0;
      height: 16px;
      border: 1px solid $attachment-custom-grey;
      color: $attachment-custom-grey;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 11px;
      font-style: normal;
      width: 33px;
      border-radius: 12px;
      text-align: center;
      line-height: 14px;
      font-weight: normal;
      position: relative;
  }

  &__button-option-list-dropdown {
      border: 0;
      background: 0;
      font-family: MTTMilano, sans-serif;
      line-height: 0;
      text-align: left;
      outline: 0;
      height: 47px;
      border-bottom: 1px solid $primary-color;
      margin: 0 12px;
      width: calc(100% - 24px);
      position: relative;
      color: $no-primary-blue;
      font-size: 13px;
      display: table;
      padding: 0 0 0 6px;

      &--last {
          border-bottom: 0px !important;
      }

      &--expanded {
          padding-right: 6px;
      }

      &--not-visible {
          color: $red !important;
          cursor: default !important;
      }
  }

  &__icon-option-list-dropdown {
      font-size: 21px;
      margin-right: 7px;
      color: $primary-color;
      position: relative;
      text-align: left;
      display: table-cell;
      vertical-align: middle;
      top: inherit;
      width: 28px;
      max-width: 28px;
  }

  &__text-menu-option-dropdown {
      position: relative;
      width: calc(100% - 37px);
      max-width: calc(100% - 37px);
      line-height: 14px;
      display: inline-block;
      display: table-cell;
      vertical-align: middle;
      top: 0px;
  }

  &__loader-modal {
      height: 412px;
      border-radius: 0px;
      width: 900px;
      margin: 30px auto;
      padding-top: 1px;
      background-color: $snow-white;
      opacity: 1;
      display: block;
      z-index: 1050;
  }

  &__loader-modal-block {
      width: calc(100% - 24px);
      overflow: hidden;
      height: 388px;
      background: $primary-color;
      margin: 12px;
      padding-top: 80px;
  }

  &__loader-modal-new-block {
      margin-bottom: 89px;
      display: block;
      overflow: hidden;
      width: 100%;
  }

  &__loader-modal-text {
      color: $snow-white;
      font-family: MTTMilano, sans-serif;
      font-size: 20px;
      margin: 0;
      text-align: center;
  }

  &__menu-expanded-little {
      width: 144px;
      min-width: 144px;
      border: 1px solid $primary-color;
      border-top-width: 5px;
      background-color: $snow-white;
      position: absolute;
      top: calc(100% + 5px);
      left: calc(50% - 72px);
      padding: 0;
      border-radius: 0px;
      float: none;
      z-index: 1001;

      &:after {
          content: '';
          border-top: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid $primary-color;
          border-left: 5px solid transparent;
          top: -15px;
          left: calc(50% - 3px);
          position: absolute;
      }

      &--list {
          top: calc(100% - 5px);
      }
  }

  &__menu-expanded-little-option {
      display: table;
      width: 100%;
      padding: 0 5px;
      @include common-background-hover($background-color-states);
  }

  &__menu-expanded-little-option-item {
      height: 28px;
      font-family: Arial, sans-serif;
      font-size: 11px;
      border-bottom: 1px solid $box-comment-border-02;
      color: $primary-color;
      display: table-cell;
      position: relative;
      vertical-align: middle;
      padding-left: 3px;
      line-height: 13px;

      &--last {
          border: none;
      }
  }

  &__menu-options-row {
      font-size: 20px;
      margin: 9px 3px 0;

      &:hover {
          cursor: pointer;
      }
  }

  &__menu-block-dropdown {
      width: 0px;
      padding: 0;
      margin: 0;
      border: 0px;
      &--less-margin {
        transform: translate3d(0px, 23px, 0px) !important;
      }
  }

  &__modal-body {
      display: block;
      width: 100%;
      overflow: hidden;
      margin-top: 50px;
      padding-left: 71px;
      padding-right: 73px;
  }

  &__modal-body-text {
      color: $snow-white;
      font-size: 18px;
      font-weight: inherit;
      text-align: center;
      height: 125px;
      width: 300px;
      margin-left: 300px;
  }

  &__modal-header-block {
      height: 65px;
      width: calc(100% - 20px);
      margin: 10px;
      display: block;
      overflow: hidden;
      padding: 18px 23px;
      position: relative;
      background-color: $primary-color;

      &--little-margin-bottom {
          margin-bottom: 1px;
      }

      &--margin-bottom {
          margin: 0 0 15px 0;
          width: 100%;
      }

      &--margin-0 {
          margin: 0;
          width: 100%;
      }

      &--custom-margin {
          height: 52px;
          border-radius: 4px;
          background-color: $custom-secondary-color;
          padding: 14px 15px 14px 23px;
      }
  }

  &__show-alert-email {
      border: 3px solid $red;
      background-color: $background-error;
      margin-bottom: 30px;

      &:hover {
          background-color: $background-grey;
          border: 3px solid $red;
      }
  }

  &__multiselect-block {
      padding-left: 4px;
      list-style: none;
      margin: 0;
      display: block;
      overflow: hidden;
      width: 97%;
      float: left;
      line-height: 6px;

      &--left-10 {
          padding-left: 10px;
      }

      &--search {
          height: auto;
          margin-top: 25px;
          background-color: $background-color-states;
          width: 100%;
          padding: 4px 6px 4px 0;
          border-top: 1px solid $custom-black-landing;
          margin-bottom: 0px;
      }
  }

  &__multiselect-delete-icon {
      color: $primary-color;
      font-size: 8px;
      position: absolute;
      top: 2px;
      right: 2px;

      &--search {
          font-size: 12px;
          color: $primary-color;
          font-weight: bold;
          top: 4px;
      }

      &--contacts {
          font-size: 10px;
          right: 8px;
          top: 3px;
          visibility: hidden;
      }

      &--simple-contact {
          bottom: 0px;
          font-size: 10px;
          top: initial;
          right: 8px;
          visibility: hidden;
      }

      &--custom {
          top: 4px;
          font-size: 10px;
          color: $custom-black-landing;
      }

      &:hover {
          cursor: pointer;
      }
  }

  &__multiselect-dropdown-arrow {
      position: relative;
      bottom: 1px;
      padding-right: 3px;
  }

  &__multiselect-dropdown-item {
      margin: 0 12px 0px 11px;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: bold;
      padding: 10px 0;

      &--with-input {
          border-bottom: 1px solid $primary-color;
          position: relative;
          padding: 7px 0 6px 7px;
          height: 32px;
          margin: 0 4px 0px 5px;
      }

      &--filter-item {
          font-weight: 400;
          color: $secondary-color;
          padding: 0;
      }

      &--margin-0 {
          margin: 0;
      }
  }

  &__multiselect-inner-childs {
      display: block;
      overflow: hidden;
      width: 100%;
  }

  &__multiselect-item {
      display: inline-block;
      height: 21px;
      border: 1px solid rgba(86, 86, 86, 0.2);
      background-color: transparent;
      position: relative;
      margin: 1px;
      max-width: 100%;
      line-height: 16px;
      &--is-highlight {
          border-radius: 120px;
          border: 2px solid #F1BC3E;
          margin-right: 10px;
      }

      &--custom {
          padding-top: 1px;
          border-color: transparent;
          background: $background-grey;
          margin-right: 4px;
      }

      &--contacts {
          border: none;
          width: 100%;
          margin: 0;
          height: 19px;

          &:hover:not([disabled]) {
              .common__multiselect-delete-icon--contacts {
                  visibility: visible;
              }

              .common__multiselect-delete-icon--simple-contact {
                  visibility: visible;
              }

              .common__delete-content-icon--contact-visibility {
                  visibility: visible;
              }
          }
      }

      &--search {
          display: block;
          border: none !important;
          width: 100%;
          margin: 9px 2px 9px;
      }
  }

  &__multiselect-item-juri {
      border: 0px;
      margin: 0 7px 4px;
  }

  &__multiselect-select-icon {
      float: left;
      background-color: transparent;
      padding: 1px 2px;
      margin-right: 5px;
      @include common-multiselect-select-icon($primary-color, $background-color-states);

      &--big {
          font-size: 15px;
      }

      &--date-button-check-icon {
          color: $secondary-color;
          position: relative;
          top: 2px;
          margin-top: 0;
      }

      &--active {
          color: inherit;
      }
  }

  &__multiselect-text {
      height: 19px;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      display: inline-block;
      font-weight: 500;
      padding-right: 13px;
      padding-left: 5px;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0;

      &--no-padding-left {
          padding-left: 0;
      }

      &--email {
          max-width: calc(100% - 15px);
      }

      &--custom {
          color: $custom-black-landing;
          font-family: Arial, sans-serif;
          font-size: 10px;
          padding-right: 19px;
      }

      &--custom-contact {
          color: $custom-secondary-color;
          font-family: 'lato_regular', sans-serif;
          font-size: 14px;
      }

      &--icon-custom {
          max-width: calc(100% - 19px);
      }
  }

  &__multiselect-text-juri {
      color: $secondary-color;
      font-size: 12px !important;
      text-decoration: underline;
      font-family: Arial !important;
  }

  &__name-related-field {
      margin-left: 7px;
      color: $primary-color;
      font-size: 12px;
      font-weight: bold;

      &--other {
          margin-left: -15px;
          position: absolute;
          top: 55px;
      }
  }

  &__no-visible {
      visibility: hidden;
  }

  &__option-box {
      height: 270px;
      width: 248px;
      // background-color: $box-comment-border-03;
      background-color: $background-color-states;
      float: left;
      padding: 18px 20px 20px;

      &--two-elements {
          position: relative;
          width: 370px;
          height: 301px;
          padding: 18px 8px 20px 10px;
      }

      &--lc-modal {
          position: relative;
          width: 370px;
          height: 306px;
          padding: 0 22px;
      }

      &--center {
          margin: 0 11px;
      }

      &--migration {
          padding: 18px 8px 20px 10px;
      }

      &--right {
          margin-right: 22px
      }
  }

  &__options-block {
      display: inline-block;
      height: 16px;
      border-left: 2px solid;
      margin-left: 12px;
      position: relative;
      float: left;
      top: 8px;

      &--steel-color {
          border-color: $primary-color-2;
      }
  }

  &__options-row {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: inline-flex;
      height: 43px;
      width: auto;
      border-radius: 67px 0 0 67px;
      z-index: 1;
      right: 0;
      visibility: hidden;
      top: 1px;
      position: absolute;
      padding-left: 10px;
      border: 0.5px solid;
  }

  &__options-row-flex{
      @extend .common__options-row;
      top: -8px;
  }

  &__order-by {
      color: #AAAAAA;
      margin-left: 0px;
      font-size: 15px;
      cursor: pointer;

      &--top-2 {
          position: relative;
          top: 2px;
      }
  }

  &__pagination-block{
      text-align: center;
      height: 22px;
      display: block;
      float: left;
      width: calc(100% - 360px);
      left: 180px;
      bottom: 0px;
      position: absolute;
  }

  &__pagination-count {
      float: right;
      font-weight: bold;
      font-size: 11px;
      color: $primary-color;
      text-transform: uppercase;
      position: relative;
      top: 2px;
  }

  &__paragraph-0 {
      margin: 0;
  }

  ////PDF view////
  &__pdf-hidde-element{
      display: none !important;
  }

  // Solo para ver en la aplicación
  // &__pdf-block-view{
  //     left: 0;
  //     position: fixed;
  //     top: 0;
  //     width: 100%;
  //     height: 100%;
  //     background-color: white;
  //     z-index: 1;
  // }

  &__pdf-block-header{
      display: block;
      width: 100%;
      height: 90px;
      &--subtitle{
          height: 70px;
      }
  }

  &__pdf-block-logo {
      position: relative;
      float: left;
      width: 245px;
      height: 40px;
      margin-top: 2px;
  }

  &__pdf-logo-img {
      height: 100%;
  }

  &__pdf-block-header-modified {
      position: relative;
      float: right;
      max-width: calc(100% - 245px);
      padding: 15px 0 0 0;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      text-align: right;
      color: $concept-blue;
  }

  &__pdf-header-text-modified {
      font-size: 15px;
      padding: 0 2px;
  }

  &__pdf-header-more-info{
      margin-right: 7px;
  }

  &__pdf-body{
      display: block;
      width: 100%;
  }

  &__pdf-title-block{
      height: 45px;
      background-color: $background-color-states;
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      align-items: center;
      padding: 0px 12px 0px 16px;
      &--space-between{
        justify-content: space-between;
      }
  }

  &__pdf-subtitle-block{
      height: 24px;
      background-color: $background-color-states;
      color: $concept-blue;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      line-height: 14px;
      display: flex;
      align-items: center;
      padding-left: 12px;
  }

  &__pdf-total{
      text-transform: uppercase;
      font-size: 12px;
  }

  &__pdf-table{
      display: table;
      page-break-inside: auto;
      width: 100%;
      padding: 0;
  }

  &__pdf-group-table-header{
      display: table-header-group;
  }

  &__pdf-table-header {
      width: 100%;
      height: 45px;
      display: table-row;
      color: $primary-color;
  }

  &__pdf-table-header-text {
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      margin: 0;
      float: left;
  }

  &__pdf-table-body{
      display: table-row-group;
  }

  &__pdf-col{
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding: 0 5px 0 10px;
      width: 20%;
      max-width: 0;
      border: 1px solid $result-search-quote-color;
      overflow-wrap: break-word;
  }

  &__pdf-table-row {
      width: 100%;
      display: table-row;
      page-break-inside: avoid;
      page-break-after: auto;
      // page-break-before: auto;
      height: 44px;
  }

  &__pdf-col-text {
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      margin: 0;
  }


  &__pdf-body-figma{
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 32px;
      gap: 32px;
  }
  &__pdf-block-header-figma{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 12px;
      width: 100%;
      margin-bottom: 2px;
  }
  &__pdf-block-logo-figma{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 70px;
      padding: var(--spacing-md) 0px var(--spacing-md) 0px;
      gap: 0px;
      border-bottom: 2px solid #99A3C9;
  }
  &__pdf-logo-img-figma{
      height: 38px;
  }
  &__pdf-block-header-modified-figma{
      padding: 0 32px;
      display: flex;
      gap: 4px;
      font-family: MTT Milano, sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
      color: #717171;
  }
  &__pdf-title-block-figma{
      font-family: MTT Milano, sans-serif;
      font-size: 22px;
      font-weight: bold;
      line-height: 26px;
      text-align: left;
  }
  //////////Fin PDF//////////

  &__pillow {
      color: #0426A9;
      font-family: Arial, sans-serif;
      font-weight: bold;
      text-align: center;
      font-size: 12px;
      border: 2px solid $primary-color;
      width: 33px;
      height: 19px;
      border-radius: 12px;
      float: right;
      margin-top: 7px;
      margin-right: 7px;
  }

  &__pill-button {
      position: relative;
      display: inline-block;
      height: 24px;
      width: 44px;
      color: $snow-white;
      border: 2px solid $snow-white;
      border-radius: 12px;
      text-align: center;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      line-height: 20px;
      background-color: $snow-white;

      &--green {
          border: 2px solid #77D166;
          background-color: $snow-white;
          color: #77D166;

          &:hover {
              cursor: pointer;
              background: rgba(119, 209, 102, 0.2);
              color: #77D166;
          }

          &.common__pill-button--selected {
              border: 2px solid #77D166;
              background-color: #77D166;
              color: $snow-white;
          }
      }

      &--red {
          border: 2px solid $red;
          background-color: $snow-white;
          color: $red;

          &:hover {
              cursor: pointer;
              background-color: $background-error;
              color: $red;
          }

          &.common__pill-button--selected {
              border: 2px solid $red;
              background-color: $red;
              color: $snow-white;
          }
      }

      &--disable {
          border: 2px solid #AAAAAA;
          background-color: $snow-white;
          color: #AAAAAA;

          &:hover {
              cursor: not-allowed;
              background-color: $snow-white;
              color: #AAAAAA;
          }
      }

      &--disable-selected {
          border: 2px solid #AAAAAA;
          background-color: #AAAAAA;

          &:hover {
              color: #AAAAAA;
              background-color: #AAAAAA;
              cursor: not-allowed;
          }
      }

      &--poll {
          height: 40px;
          width: 120px;
          border-radius: 151px;
          font-size: 16px;
          padding-top: 5px;
      }

      &--poll-error {
          background: $background-error !important;
      }
  }

  &__pill-button-right {
      margin-left: 5px;
  }

  &__position {
      position: relative;
  }

  &__preloader-holder {
      width: 46px;
      height: 46px;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;

      &--loader-modal {
          height: 80px;
          width: 80px;
          position: relative;
          top: 0;
      }
  }

  &__preloader-icon {
      font-size: 46px;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
      animation: puls-be 2000ms infinite ease-in-out;
      color: $snow-white;

      &--modal {
          font-size: 80px;
          top: calc(50% + 35px);
          position: absolute;
      }
  }

  &__primary-color-font {
      color: $primary-color;
  }

  &__radio-icons-block {
      display: inline-block;
  }

  &__radio-input-circle-is-highlight {
      color: $snow-white;
      padding: 4px;
      font-size: 10px;
      float: left;
  }

  &__radio-list-block {
      display: block;
      width: 100%;
      overflow: hidden;
      padding: 6px;
  }

  &__radio-option-icon {
      color: $primary-color;
      font-size: 16px;
      display: none;
  }

  &__radio-option-input {
      visibility: hidden;
      height: 0;
      width: 0;
      margin: 0;
      padding: 0;

      & + .common__radio-icons-block {
          & > .circle {
              display: block;
          }

          & > .dot {
              display: none;
          }
      }

      &:checked {
          & + .common__radio-icons-block {
              & > .circle {
                  display: none;
              }

              & > .dot {
                  display: block;
              }
          }
      }
  }

  &__radio-option-icon-is-highlight {
      margin-right: 35px;
  }

  &__radio-option-input-list {
      visibility: hidden;
      height: 0;
      width: 0;
      margin: 0;
      padding: 0;

      &:checked {
          background-color: $primary-color;
      }
  }

  &__radio-option-text {
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: 400;
      display: block;
      width: 100%;
      margin-bottom: 0;

      &--list-is-highlight {
          cursor: pointer;
      }

      &--list-is-highlight-checked {
          background-color: #F1BC3E;
          color: $snow-white;
          border-radius: 120px;
      }
  }

  &__rectangle-info {
      height: 22px;
      min-width: 40px;
      border: 2px solid $primary-color;
      background-color: $snow-white;
      color: #0426A9;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
      text-align: center;
      border-radius: 17px;
      display: inline-block;
      position: relative;
      font-style: normal;
      top: -4px;

      &--share-document {
          background-color: $primary-color;
          color: $snow-white;
      }

      &--report-table-task {
          background-color: $primary-color;
          color: $snow-white;
          font-size: 13px;
      }
  }

  &__related-fields-block {
      width: 100%;
      display: block;
      border: 1px solid $attach-icon-grey;
      background-color: $snow-white;
      margin-bottom: 10px;
      border-top: 0;
      padding: 0;
      list-style: none;
      overflow: hidden;
  }

  &__related-fields-block-list {
      width: 88%;
      display: block;
      background-color: $snow-white;
      margin-bottom: 10px;
      border-top: 0;
      padding: 0;
      list-style: none;
      overflow: hidden;
      margin-left: 54px;
  }

  &__related-field-info {
      width: 99%;
      height: 21px;
      margin: 7px;
  }

  &__related-field-info-text {
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 10px;
      background-color: $background-grey;
      text-transform: uppercase;
      height: 21px;
      padding-top: 6px;
      padding-left: 3px;
  }

  &__related-list-block {
      display: inline-block;
      float: right;
      width: 70%;
      height: 100%;
      border-left: 1px solid $attach-icon-grey;
      position: relative;
  }

  &__related-field-item {
      display: block;
      width: 100%;
      overflow: hidden;
  }

  &--rotate90 {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
  }

  &--rotate270 {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg) scaleX(-1);
  }

  &__search-icon {
      font-size: 18px;
      bottom: 6px;
      position: absolute;
      right: 0;

      &--reset {
          &:hover {
              cursor: pointer;
          }
      }
  }

  &__search-input {
      position: relative;
      width: 100%;
      height: 30px;
      font-size: 12px;
      border: 1px solid;
      outline: none;
      padding: 4px;
      font-family: MTTMilano, sans-serif;

      &::-webkit-input-placeholder {
          font-style: normal;
          padding: 0px;
      }
  }

  &__select-arrow {
      font-size: 18px;
      color: $primary-color;
      padding-right: 5px;
      outline: 0;
      height: 25px;
      position: absolute;
      right: 0;
      top: 4px;
      z-index: 4;

      &:hover {
          cursor: pointer;
      }

      &--no-editable {
          display: none;

          &:hover {
              cursor: default;
          }
      }

      &--l-co {
          font-size: 16px;
          top: 13px;
      }
  }

  &__select-icon {
      font-size: 16px;
      float: right;
      display: none;
      padding: 0 3px;

      &--list {
          padding: 0 3px 0 0;
      }

      &--select-list-check {
          position: absolute;
          right: 14px;
          top: 8px;
      }

      &--select-list-view {
          position: absolute;
          right: 40px;
          top: 7px;
          font-size: 18px;
      }

      &--select {
          position: absolute;
          right: 32px;
      }
  }

  &__select-info-icon {
      font-size: 16px;
      float: left;
      display: block;
      padding-right: 5px;
  }

  &__select-list {
      border-radius: 0px;
      border: 1px solid $primary-color;
      padding: 0;
      max-height: 170px;
      width: calc(100% + 2px);
      left: -1px;

      &--clickable {
          height: 145px;
          overflow: hidden;
          margin: 0;
          transform: none !important;
          inset: auto !important;
          left: -1px !important;
          top: 100% !important;
      }

      &--no-shadow {
          box-shadow: none;
      }

      &--list-block {
          max-height: 245px;
          overflow: hidden;
          position: absolute;
          left: -1px;
          top: 48px;
          margin: 0;
      }
  }

  &__select-all {
    height: 34px;
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    padding: 10px 2px 10px 10px;
    border: 1px solid;
    position: relative;
    cursor: pointer;

    &:hover {
        font-weight: 700;

        & > .common__select-icon {
            display: inline-block;
        }
    }

    &--other {
        border-left: none;
        border-right: none;
    }
  }

  &__select-all-custom {
    width: 100%;
    position: relative;
    float: left;
    border-bottom: 1px solid $box-comment-border-02;
    cursor: pointer;

    &:hover {
        background-color: $background-grey;

        & > .common__select-icon {
            display: inline-block;
        }
    }
  }

  &__select-item {
      height: 34px;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: 400;
      padding: 10px 11px;
      position: relative;


      &:hover:not([disabled]) {
          border-bottom: 1px solid $primary-color;
          cursor: pointer;
          font-weight: 700;
          background: $background-grey;

          & > .common__select-icon {
              display: inline-block;
          }
      }

      // &--uppercase {
      //     text-transform: uppercase;
      // }

      &--tree {
          font-weight: 600;
      }

      &--tree-with-children{
        &:hover:not([disabled]) {
            & > .common__select-icon {
                display: none;
            }
        }
      }

      &--list {
          padding: 10px 3px 10px 11px;
      }

      &--whitout-padding {
          padding: 0px;
      }

      // &--poll {
      //     font-size: 14px;
      //     position: relative;
      //     bottom: 4px;
      // }

      &--error {
          background: $background-error !important;
          margin: 0;
          padding-left: 10px;
          color: $red;

          &:hover {
              font-weight: 700;
          }
      }

      &--disabled {
          color: $secondary-color;

          &:hover {
              cursor: initial;
              background-color: transparent;
              border: none;
              font-weight: initial;
          }
      }
  }

  &__select-item-custom {
      width: 100%;
      position: relative;
      float: left;

      &:hover {
          font-weight: bold;
          color: $custom-black-landing;
          border: none;
          background-color: $background-grey;
          cursor: pointer;

          & > .common__select-icon {
              display: inline-block;
          }
      }
  }

  &__select-item-empty {
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: 400;
  }

  &__select-item-result-empty {
      height: 34px;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: 400;
      padding: 10px 11px;
      position: relative;
      cursor: unset;
  }

  &__select-list-item {
      height: 28px;
      padding-top: 7px;
      padding-bottom: 7px;
      position: relative;

      &:hover {
          border-bottom: 1px solid #F5F5F5;

          & > .common__select-icon {
              display: block;
          }
      }
  }

  &__select-list-item-text {
      font-family: Arial, sans-serif;
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: calc(100% - 50px);
      padding: 10px 11px;
  }

  &__select-list-item-text-custom {
      font-family: 'lato_regular', sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: $custom-secondary-color;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: calc(100% - 50px);
      padding: 10px 11px;
      position: relative;
      float: left;

      &--no-name {
          color: $red;
      }
  }

  &__select-list-item-text-color {
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: 400;

      &--no-name {
          color: $red;
      }
  }

  &__select-list-with-input {
      margin: 0px;
      width: 248px;
      // top: 27px;
      // top: 6px !important;
      border-radius: 0;
      border: 1px solid $primary-color;
      // transform: translate(-1px, 28px) !important;
      padding: 5px 0;
      transform: none !important;
      inset: initial !important;
      top: 27px !important;
      &--report-table {
          padding: 0;
      }

      &--width-200 {
          width: 200px;
      }

      &--auto {
          left: 0 !important;
          top: 100% !important;
          width: 100%;
      }
      &--left{
        right: 27px !important;
      }
  }

  &__select-list-with-input-by-list {
      border-radius: 0;
      margin: 2px 2px 0 0;
      padding: 0;
      max-height: 133px;
      width: auto;
      top: 0;
      overflow-y: auto;
  }

  &__select-list-with-input-by-list-schedule {
      border-radius: 0;
      margin: 5px 0 0 0;
      padding: 0;
      max-height: 50px;
      width: auto;
      overflow-y: auto;
}

  &__select-readonly {
      &[readonly] {
          background: transparent;

          &:hover {
              cursor: pointer;
          }
      }
  }

  // &__select-readonly-open {
  //     &[readonly] {
  //         background: $background-grey;
  //     }
  // }

  &__selected-option {
      border: none;
      background: none;
      outline: none;
      width: 100%;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 11px;
      text-transform: uppercase;
      padding: 0px 0 0px 6px;
      position: relative;
      bottom: 1px;
      height: 16px;
      &--weight-normal {
          font-family: MTTMilano, sans-serif;
      }
      &:after {
        display: none !important;
      }
  }

  &__select-option-icon-angle-down{
    font-size: 15px;
    color: $primary-color;
    padding-left: 3px;
    position: relative;
    top: 2px;
  }

  &__separated-time {
      position: absolute;
      margin-left: -11px;
      top: 32px;
      font-size: 11px;
  }

  &__show-alert {
      border-color: $red !important;
      background-color: $background-error !important;

      &:hover {
          background-color: $background-grey !important;
      }
  }

  &__state-block {
      width: 98%;
      height: 73px;
      overflow-y: auto;
      overflow-x: hidden;
      margin-left: 1%;
      margin-top: 9px;
  }

  &__switch {
      position: relative;
      display: inline-block;
      height: 20px;
      margin-bottom: 0;
      margin-left: 4%;
      font-family: MTTMilano-Bold, sans-serif;
      font-weight: normal;

      &--top {
          margin-top: 5px;
          margin-left: 0;
      }

      &--left {
          margin-left: 0;
      }

      &--margin-0 {
          margin: 0;
      }

      & .common__switch-input {
          opacity: 0;
          width: 0;
          height: 0;
          outline: 0;
      }
  }

  &__switch-icon {
      font-size: 40px;
      float: left;
      position: relative;
      bottom: 0px;
      color: #AAAAAA;
      width: 40px;
      height: 20px;

      &:hover {
          cursor: pointer;
      }

      &--active {
          color: $primary-color;
      }

      &--active-white {
          color: $snow-white;
      }

      &--desactive {
          color: #D3D3D3;
      }

      &--error {
          color: $red;
      }

      &--disabled {
          opacity: 0.5;

          &:hover {
              cursor: default;
          }
      }

      &::before {
          position: relative;
          bottom: 9px;
      }
  }

  &__switch-text-input {
      color: $primary-color;
      font-size: 12px;
      font-family: MTTMilano-Bold, sans-serif;
      display: inline-block;
      position: relative;
      top: 1px;
      margin: 0 25px 12px 5px;
      text-transform: uppercase;

      &:hover {
          cursor: pointer;
      }

      &--ellipsis {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: calc(100% - 48px);
      }

      &--disabled {
          cursor: auto;
          opacity: 0.5;

          &:hover {
              cursor: default;
          }
      }

      &--center-top {
          top: initial;
          bottom: 3px;
      }

      &--top-2 {
          top: 2px;
      }

      &--top-3 {
          top: 3px;
      }

      &--white {
          color: $snow-white;
          opacity: 0.5;
          margin: 0 0 0 5px;
      }

      &--margin-0 {
          margin: 0 0 0 5px;
      }

      &--error {
          color: $red;
      }
  }

  &__switch--ok {
      @extend .common__switch;
      margin-left: 0;

      & > label {
          color: $primary-color;
          text-transform: uppercase;
          margin: 2px 0 0 80px;
          margin-left: 50px;
          font-weight: 500;
      }

      &:hover {
          cursor: pointer;
      }
  }

  &__switch-slider-round {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 41px;
      background-color: $snow-white;
      -webkit-transition: .4s;
      transition: .4s;
      border: 2px solid #aaa;
      border-radius: 34px;

      &--blue {
          border-color: $primary-color;
      }

      &--view {
          margin-left: -7px;
          cursor: context-menu;
      }

      &--custom-highlight-subcontainer,
      &--custom-highlight {
          border-color: transparent;
      }

      &:before {
          position: absolute;
          content: "";
          height: 14px;
          width: 14px;
          left: 1px;
          bottom: 1px;
          background-color: #aaa;
          -webkit-transition: .4s;
          transition: .4s;
          border-radius: 50%;
      }

      &--disabled {
          opacity: 0.5;
          cursor: auto;

          &:before {
              background-color: $secondary-color;
          }
      }
  }

  &__switch-text {
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      padding-left: 41px;
      text-transform: uppercase;
  }

  &__switch-top {
      margin-top: 10px;
      margin-left: 0px;
  }

  &__table-new-version {
      display: block;
      width: 100%;
      padding: 0;
  }

  &__table-body {
      display: block;
      width: 100%;
      min-height: 230px;
      max-height: 230px;
      overflow: hidden;
      overflow-y: auto;

      &--8-elements {
          min-height: 368px;
          max-height: 368px;
      }

      &--10-elements {
          min-height: 460px;
          max-height: 460px;
      }

      &--11-elements {
          min-height: 506px;
          max-height: 506px;
      }

      &--12-elements {
          min-height: 542px;
          max-height: 542px;
      }

      &--14-elements {
        min-height: 644px;
        max-height: 644px;
      }

      &--more-elements {
          width: calc(100% + 10px);
      }
  }

  &__table-block {
      margin-top: 27px;
      width: 100%;

      &--top-15 {
          margin-top: 15px;
      }
  }

  &__table-header {
      display: table;
      width: 100%;
      border-bottom: 1px solid;
      height: 40px;
  }

  &__table-header-text {
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      margin: 0;
      float: left;
      width: auto;
      &--right {
          float: right;
      }

      &--center {
          float: none;
          position: relative;
          top: 10px;
      }

      &--format-text {
          float: none;
          padding-left: 8px;
          display: inline-block;
      }

      &--ellipsis {
          max-width: calc(100% - 21px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
      }

      &--ellipsis-filter-order {
          max-width: calc(100% - 18px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
      }

      &--filter{
          max-width: calc(100% - 18px);
      }

      &--order{
          max-width: calc(100% - 15px);
      }

      &--filter-order{
          max-width: calc(100% - 32px);
      }

      &--custom {
          color: $custom-black-landing;
          position: relative;
          top: 4px;
      }

      &--top-0 {
          top: 0;
      }

      &--actions {
          float: none;
      }
  }

  &__table-header-flex{
      @extend .common__table-header;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
  }

  &__table-row {
      display: table;
      width: 100%;
      height: 46px;
      border-bottom: 1px solid;

      &:hover:not([disabled]) {
          & .common__options-row {
              visibility: visible;
          }
      }
      &--disabled{
        &:hover:not([disabled]) {
            & .common__options-row {
                visibility: hidden;
            }
        }
      }
  }

  &__table-row-flex{
      @extend .common__table-row;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
  }

  &__table-display {
      display: table;
      width: 100%;
  }

  &__table-cell-tooltip {
      width: 100%;
      float: none;
      display: table-cell;
      max-width: 0;
      position: relative;
      vertical-align: middle;
  }

  &__task-header {
      display: block;
      width: 100%;
      height: 37px;
      padding-top: 5px;
      padding-right: 10px;
      text-align: right;
      margin-bottom: 18px;
      position: relative;

      &--documentation {
          padding-right: 0px;
          padding-top: 0px;
      }
  }

  &__text-button-underlined {
      text-transform: uppercase;
      font-size: 11px;
      font-family: MTTMilano-Bold, sans-serif;
      position: relative;
      @extend .span__auto-ajust;
  }

  &__text-search {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;

      &:hover {
          cursor: pointer;
      }
  }

  &__textarea-input {
      border: none;
      width: 98%;
      padding: 5px;
      height: calc(99% - 23px);
      margin: 0 1% 1%;
      outline: 0;
      font-weight: 400;
      resize: none;
  }

  &__text-header {
      color: $snow-white;
      font-family: MTTMilano, sans-serif;
      font-size: 22px;
      margin: 0;
      padding-left: 16px;
      position: absolute;

      &--custom {
          font-size: 18px;
          font-family: MTTMilano-Bold, sans-serif;
          padding-left: 10px;
          text-transform: uppercase;
          color: inherit;
      }
  }

  &__text-required {
      color: $red !important;
      font-family: Arial, sans-serif;
      font-size: 18px;
      width: 7px;
      position: relative;
      height: 12px;
      margin: 0 0 0 3px;
      line-height: 0;
      font-weight: 700;

      &--sequential-view {
          bottom: 5px;
      }

      &--max-width {
          position: absolute;
          display: inline-block;
      }
  }

  &__text-rich-block {
      display: block;
      width: 100%;
      text-align: justify;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 11px;
      padding-left: 10px;
      margin-bottom: 10px;
  }

  &__text-url {
      text-decoration: none;

      &:hover {
          color: inherit;
          cursor: pointer;
          text-decoration: underline;
      }

      &:visited {
          color: inherit;
      }

      &:focus {
          color: inherit;
      }
  }

  &__title-block-modal {
      color: $donut-text;
      font-family: MTTMilano, sans-serif;
      font-size: 18px;
      margin: 0;
      line-height: 19px;
  }

  &__title-box {
      height: 19px;
      width: 72px;
      color: $secondary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 22px;
      line-height: 16px;
      display: inline-block;
      margin-right: 5px;
  }

  &__title-list {
      color: $primary-color;
      font-size: 12px;
      margin: 0;
      margin-top: 40px;
      margin-left: 7px;
  }

  &__title-related-info {
      display: inline-block;
      float: left;
      width: 25%;
      vertical-align: middle;
  }

  &__tooltip-alert-icon {
      color: $primary-color;
      position: absolute;
      z-index: 2;
      font-size: 18px;
      padding: 0;
      outline: none;
      border: none;
      margin: 0;
      background-color: transparent;
      &:hover {
          cursor: pointer;
      }

      &--custom {
          top: 1px;
          font-size: 15px;
          color: $custom-black-landing;
          padding: 0;
          outline: none;
          border: none;
          margin: 0 0 0 3px;
      }

      &--poll {
          font-size: 24px;
          position: relative;
      }
  }

  &__tooltip-dropdown {
      position: absolute;
      margin: 0px;
      width: 248px;
      min-height: 60px;
      border: 1px solid rgba(0, 25, 120, 0.3);
      border-left: 4px solid $primary-color;
      background-color: $background-grey;
      padding: 0px;
      border-radius: 0;
      transform: none !important;
      inset: initial !important;
      top: -4px !important;
      left: 25px !important;
      &:after {
          content: '';
          border-top: 5px solid transparent;
          border-right: 5px solid $primary-color;
          border-bottom: 5px solid transparent;
          border-left: 5px solid transparent;
          vertical-align: middle;
          left: -14px;
          position: absolute;
          top: 7px;
      }
  }

  &__tooltip-info-custom-icon-poll {
      width: 24px;
      position: relative;
      margin: 0 auto;
  }

  &__tooltip-dropdown-custom {
      position: absolute;
      top: 14px !important;
      margin: 0px;
      width: 260px;
      min-height: 50px;
      max-height: 170px;
      left: -122px !important;
      border: 1px solid $custom-black-landing;
      border-radius: 4px;
      background-color: #F6F6F6;
      padding: 22px 7px 22px 15px;

      &--poll {
          left: calc(50% - 130px);
          top: 48px;
      }

      &:after {
          content: '';
          border-top: 0px solid transparent;
          border-right: 14px solid transparent;
          border-bottom: 14px solid #F6F6F6;
          border-left: 14px solid transparent;
          left: 115px;
          position: absolute;
          top: -14px;
      }

      &:before {
          content: '';
          border-top: 0px solid transparent;
          border-right: 14px solid transparent;
          border-bottom: 14px solid $custom-black-landing;
          border-left: 14px solid transparent;
          left: 115px;
          position: absolute;
          top: -15px;
      }
  }

  &__tooltip-info {
      position: absolute;
      background-color: $background-grey;
      border: 1px solid rgba(0, 25, 120, 0.3);
      border-left: 4px solid $primary-color;
      z-index: 20;
      top: -5px;
      left: 30px;
      display: block;
      min-height: 71px;
      width: 256px;
      box-shadow: 0 0 5px 1px rgba(65, 63, 63, 0.16);
      padding-bottom: 7px;

      &:after {
          content: '';
          border-top: 5px solid transparent;
          border-right: 5px solid $primary-color;
          border-bottom: 5px solid transparent;
          border-left: 5px solid transparent;
          vertical-align: middle;
          left: -14px;
          position: absolute;
          top: 7px;
      }

      &:hover {
          background-color: #fafafafa;
          border: 1px solid $primary-color;
          border-left: 4px solid $primary-color;
      }
  }

  &__tooltip-info-box {
      display: none;

      &--show {
          display: block;
      }
  }

  &__tooltip-inner-text {
      font-family: MTTMilano;
      font-size: 11px;
      text-transform: none;
      padding: 6px 5px 0px 12px;
      display: block;
      overflow: auto;
  }

  &__tooltip-law {
      visibility: hidden;
      float: left;
      position: relative;
      margin: -3px 0px 0px 5px;

      &--custom {
          margin: 2px 0px 0px 5px;
      }
  }

  &__tooltip-law-block {
      float: none;
      visibility: hidden;
      font-size: 14px;
      color: $primary-color;
      margin: 0px;
      display: inline-block;
      height: 14px;

      &:hover {
          cursor: pointer;
      }

      &--visible {
          visibility: visible;
      }
  }

  &__tooltip-law-show {
      float: none;
      visibility: visible;
      font-size: 14px;
      color: $primary-color;
      margin: 0px;
      display: inline-block;
      height: 14px;

      &:hover {
          cursor: pointer;
      }

      &--custom {
          position: relative;
          top: 2px;
      }
  }

  &__total-number-element {
      margin-left: 5px;
  }

  &__range-number-element{
      text-transform: initial;
      margin: 0 3px;
  }

  &__triangle-related-field {
      &:after {
          content: '';
          width: 0;
          height: 0;
          border: 0 solid transparent;
          border-bottom-width: 22px;
          border-top-width: 15px;
          border-left: 13px solid $attach-icon-grey;
          position: absolute;
          vertical-align: middle;
          top: 40%;
      }
  }

  &__triangle-related-field-border {
      &:after {
          content: '';
          width: 0;
          height: 0;
          border: 0 solid transparent;
          border-bottom-width: 22px;
          border-top-width: 15px;
          border-left: 13px solid $snow-white;
          position: absolute;
          margin-left: -1px;
          top: 40%;
          vertical-align: middle;
      }
  }

  &__underlined-button {
      position: relative;
      font-family: MTTMilano-Bold, sans-serif;
      text-transform: uppercase;
      color: $primary-color;
      background-color: transparent;
      text-align: center;
      border: 3px solid $primary-color;
      padding: 5px 25px;
      outline: none;

      &:before {
          content: " ";
          position: absolute;
          bottom: -7px;
          left: -3px;
          right: -3px;
          height: 3px;
          background-color: $primary-color;
      }

      &:hover {
          background-color: $primary-color;
          color: white;
      }

      &.no-hover:hover {
          color: $primary-color;
          background-color: transparent;
      }

      &[disabled],
      &.gray {
          color: #AAAAAA;
          border-color: #AAAAAA;

          &:before {
              background-color: #AAAAAA;
          }

          &:hover {
              color: $snow-white;
              background-color: #AAAAAA;
          }
      }

      &.thin {
          border-width: 2px;

          &:before {
              bottom: -5px;
              left: -2px;
              right: -2px;
              height: 2px;
          }
      }

      &.icon {
          border-width: 2px;

          &:before {
              bottom: -5px;
              left: -2px;
              right: -2px;
              height: 2px;
          }
          padding: 0 2px;
          outline: none;
      }

      &--negative {
          color: white;
          border-color: white;

          &:before {
              background-color: white;
          }
      }
  }

  &__underlined-title {
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 16px;
      border-bottom: 3px solid $primary-color;
      padding-bottom: 4px;
      padding-right: 1em;
      font-weight: bold;
      text-transform: uppercase;

      & > i {
          margin-right: 5px;
          vertical-align: middle;
      }

      &--smaller {
          font-size: 10px;
      }
  }

  &__view {
      height: 100%;
      width: 100%;
      display: block;
      overflow: auto;
      overflow-x: hidden;
  }

  &__window-footer-bottom{
      overflow: auto;
      width: 100%;
      min-height: 100%;
      position: relative;
      padding-bottom: 200px;
  }

  &__footer-bottom{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 200px;
  }

  &__width-100 {
      width: 100%;
  }

  input:checked + .common__switch-slider-round {
      background-color: $primary-color;
      border-color: $primary-color;

      &--view {
          background-color: #8093db !important;
          border-color: #8093db !important;
      }

      &:before {
          -webkit-transform: translateX(21px);
          -ms-transform: translateX(21px);
          transform: translateX(21px);
          background: $snow-white;
      }
  }

  &__panel {
      display: block;
      width: 100%;
      height: 100%;
  }

  &__tiny-link {
      padding: 0 4px;
      text-decoration: none !important;

      &:hover {
          cursor: pointer;
      }

      &--no-hover {
          opacity: 0.5;

          &:hover {
              cursor: default;
          }
      }
  }

  &__tiny-link-span {
      color: $primary-color !important;
      font-family: Arial, sans-serif;
      font-size: 14px;
      text-decoration: underline;
  }

  &__tiny-link-icon {
      position: relative;
      bottom: 4px;
      left: 3px;
      font-size: 12px;

      &--blue {
          color: $primary-color;
      }

      &--blue-form {
          bottom: 0;
          font-size: 16px;
          color: $primary-color;
      }
  }

  &__inline-warning {
      width: 100%;
      display: block;
      background: rgba(241,188,62,0.2);
      height: 55px;

      &__icon {
          color: #F1BC3E;
          font-size: 40px;
          position: relative;
          top: 7px;
          left: 16px;
          float: left;
      }

      &__text {
          color: $secondary-color;
          font-family: Arial, sans-serif;
          font-size: 12px;
          padding: 19px 0 0 30px;
          float: left;
          margin: 0;
      }

      &__close {
          float: right;
          position: relative;
          top: 20px;
          font-size: 15px;
          right: 12px;
          color: $secondary-color;
          cursor: pointer;
      }
  }

  &__related-block {
      visibility: hidden;
  }

  &__references-block {
      visibility: hidden;
  }

  &__hide {
      display: none !important;
  }

  &__line-through {
      text-decoration: line-through;
  }
}

i.common--rotate90:before {
  @extend .common--rotate90
}

// .mce-content-body{
//     overflow: auto;
//     height: auto;
// }

@keyframes pulse {
  0% {
      transform: translate(-50%, -50%) rotate(-180deg) scale(1)
  }
  10% {
      transform: translate(-50%, -50%) rotate(0deg) scale(0.8)
  }
  40% {
      transform: translate(-50%, -50%) rotate(0deg) scale(0.8)
  }
  80% {
      transform: translate(-50%, -50%) rotate(180deg) scale(1)
  }
  100% {
      transform: translate(-50%, -50%) rotate(180deg) scale(1)
  }
}

@keyframes puls-be {
  0% {
      transform: translate(-50%, -50%) rotate(-180deg) scale(1.25)
  }
  25% {
      transform: translate(-50%, -50%) rotate(0deg) scale(1.25)
  }
  50% {
      transform: translate(-50%, -50%) rotate(0deg) scale(1.25)
  }
  75% {
      transform: translate(-50%, -50%) rotate(180deg) scale(1.25)
  }
  100% {
      transform: translate(-50%, -50%) rotate(180deg) scale(1.25)
  }
}

.btn-breadcrumb {
  &.btn {
      padding: 6px 12px 6px 24px;
      &:first-child {
          padding: 6px 6px 6px 10px;
      }
      &:last-child {
          padding: 6px 18px 6px 24px;
      }
  }
  & .btn-active {
      &:hover{
          cursor: auto;
      }
      &.btn-default:not(:last-child) {
          &:after {
              border-left: 10px solid $primary-color;
          }
          &:before {
              border-left: 10px solid $primary-color;
          }
      }
  }
}

.btn-default,
.btn-default:hover,
.btn-default:active,
.btn-default:active:hover,
.btn-default:focus {
  background: $background-grey;
  background-color: $background-grey;
  border-color: $subcontainer-border;
  color: $primary-color;
  box-shadow: none;
}

.btn-active,
.btn-active:hover,
.btn-active:active:hover,
.btn-active:focus {
  background: $primary-color;
  color: $snow-white;
  border-color: $primary-color;
}

// .tooltip-arrow {
//     margin: 0 !important;
// }
.compliance{
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.mat-tooltip {
border: none;
padding: 10px;
font-size: 12px;
font-family: MTTMilano, sans-serif;
margin-left: 0;
box-shadow: 0 0 5px 2px rgba(65, 63, 63, 0.3);
opacity: 1;
&::after {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #767676;
}
&.below {
  overflow: initial;
  margin-top: 2px;
  &:after {
    top: -6px;
    right: calc(50% - 3.5px);
    transform: rotate(0);
  }
}

&.above {
  overflow: initial;
  margin-bottom: 6px;
  &:after {
    bottom: -6px;
    right: calc(50% - 3.5px);
    transform: rotate(180deg);
  }
}

&.right {
  overflow: initial;
  margin-left: 8px;
  &:after {
    left: -8px;
    top: calc(50% - 3.5px);
    transform: rotate(270deg);
  }
}

&.left {
  overflow: initial;
  margin-right: 8px;
  &:after {
    right: -8px;
    top: calc(50% - 3.5px);
    transform: rotate(90deg);
  }
}
}

.tooltip.show{
opacity: 1;
}

.tooltip {
  max-width: 600px !important;
  &[data-popper-placement^='top'] {
      .tooltip-arrow {
          // left: calc(50% - 5px);
          &:before{
            border-top-color: $primary-color;
          }
      }
  }
  &[data-popper-placement^='bottom'] {
      .tooltip-arrow {
          // left: calc(50% - 5px);
          &:before{
            border-bottom-color: $primary-color;
          }
      }
      &>.tooltip-inner {
          background-color: $background-color-states;
          color: $primary-color;
          border-bottom: none;
          border-top: 1px solid $primary-color;
          border-left: none;
          padding: 10px;
          font-size: 12px;
          font-family: MTTMilano, sans-serif;
          height: auto;
          width: 100%;
          margin-left: 0;
          box-shadow: 0 0 5px 2px rgba(65, 63, 63, 0.3);
          opacity: 1;
      }
  }
  &[data-popper-placement^='left'] {
      .tooltip-arrow {
          // margin: -5px 0 0px 0px !important;
          &:before{
            border-left-color: $primary-color;
          }
      }
      &>.tooltip-inner {
          background-color: $background-color-states;
          color: $primary-color;
          border: none;
          border-right: 1px solid $primary-color;
          padding: 5px;
          font-size: 12px;
          font-family: MTTMilano, sans-serif;
          height: auto;
          width: 100%;
          margin-left: -0px;
          box-shadow: 0 0 5px 2px rgba(65, 63, 63, 0.3);
          opacity: 1;
      }
  }
  &[data-popper-placement^='right'] {
      .tooltip-arrow {
          // margin: -5px 0 0px 0px !important;
          &:before{
            border-right-color: $primary-color;
          }
      }
      &>.tooltip-inner {
          background-color: $background-color-states;
          color: $primary-color;
          border: none;
          border-left: 1px solid $primary-color;
          padding: 5px;
          font-size: 12px;
          font-family: MTTMilano, sans-serif;
          height: auto;
          width: 100%;
          margin-left: -0px;
          box-shadow: 0 0 5px 2px rgba(65, 63, 63, 0.3);
          opacity: 1;
      }
  }
  &>.tooltip-inner {
      background-color: $background-color-states;
      color: $primary-color;
      border-bottom: 1px solid $primary-color;
      border-left: none;
      padding: 10px;
      font-size: 12px;
      font-family: MTTMilano, sans-serif;
      height: auto;
      width: 100%;
      max-width: 100%;
      box-shadow: 0 0 5px 2px rgba(65, 63, 63, 0.3);
      opacity: 1;
  }
  &.in {
      filter: alpha(opacity=100);
      opacity: 1;
  }
}

.player {
  width: 100% !important;
  height: 100% !important;
}

.modal-dialog {
margin-top: 112px;
}
.modal-dialog-centered{
margin: 0 auto;
}
.modal-content{
  border-radius: 0;
  border-color: transparent;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
.dropdown-toggle{
&:after{
    display: none;
    // border:0;
    // border-color: transparent;
}
}
@include common-scrollbar(rgba(205, 209, 224, 0.38), $subcontainer-border);

::-webkit-input-placeholder {
  border: none;
  padding-left: 10px;
  height: 22px;
  outline: 0;
  width: 90%;
  font-weight: 500;
  background: transparent;
  color: #AAAAAA;
  font-family: Arial, sans-serif;
  font-style: italic;
  font-size: 12px;
  letter-spacing: 0.5px;
  margin-left: -29px;
}

@media (min-width: 992px) {
.modal-dialog {
    width: 900px;
    --bs-modal-width: 900px
}
}

@media (max-width: 991px) {
.modal-dialog {
    width: calc(100% - 40px);
    margin: 10px auto;
    & .modal-content{
        width: 100%;
    }
}
}

@media (max-width: $max-width-smartphone) { //Smartphone
  .common {
      &__buttons-block {
          &--modal-custom {
              position: initial;
          }
      }
      &__button-landing-custom {
        width: calc(50% - 10px);
        padding: 0;
        margin-right: 1px;
        &--little{
          width: calc(50% - 6px);
        }
      }
      &__block-icon-close-modal-custom {
          display: none;
      }

      &__datepicker-container {
          width: 100%;
      }
      &__icon-ellipsis{
        &--landing-page{
            display: none;
        }
      }
      &__text-header {
          &--custom {
              font-size: 15px;
              padding-top: 3px;
          }
      }
      // &__datepicker-button {
      //     &--selected-landing-custom {
      //         &:active,
      //         &:focus,
      //         &:hover {
      //             background-color: $custom-black-landing;
      //             color: $snow-white;
      //         }
      //     }
      //     &--selected-comparated{
      //         &-landing-custom {
      //             background-color: $custom-black-landing;
      //         }
      //     }
      // }
  }

  .uib-datepicker-popup.dropdown-menu {
      width: 100%;
  }
}

.cdk-global-overlay-wrapper, .cdk-overlay-container { z-index: 9999!important; }
.joint-paper-scroller{
overflow: auto !important;
}


.compliance{
margin: 0;
width: 100%;
height: 100%;
display: block;
overflow: hidden;

&__dark-blue{
  .compliance__body{
    background-color: $snow-white;
  }
  .common{
      &__tooltip-info {
        background-color: $background-color-states;
      }

      &__tooltip-dropdown {
        @include common-tooltip-dropdown-color($primary-color, $background-color-states, rgba(0, 25, 120, 0.3));
      }

      &__text-header {
        color: $snow-white;
      }

      &__table-body {
        min-height: 225px;
        max-height: 225px;

        &--8-elements {
          min-height: 368px;
          max-height: 368px;
        }

        &--10-elements {
            min-height: 450px;
            max-height: 450px;
        }

        &--11-elements {
            min-height: 495px;
            max-height: 495px;
        }

        &--12-elements {
            min-height: 542px;
            max-height: 542px;
        }

        &--14-elements {
          min-height: 630px;
          max-height: 630px;
        }
      }

      &__pagination-count {
          color: $primary-color;
      }

      &__switch-icon {
        &--active {
          color: $primary-color;
        }
      }

      &__show-alert {
        @include common-show-alert-color($red, $background-error, $background-color-states);

        &--fixed {
            @include common-show-alert-color($red, $snow-white, $snow-white);
        }
      }

      &__select-option-icon-angle-down {
        color: $primary-color;

        &--box {
          color: $primary-color;
          &-disabled {
            color: #989898 !important;
          }
        }
      }

      &__select-list-item-text {
        &--no-name {
          color: $red;
        }
      }

      &__select-all {
        color: $primary-color;
        border-color: $box-comment-border-02;

        &:hover {
            background: $background-grey;
        }
      }

      &__select-item {
        &:hover:not([disabled]) {
          background: $background-color-states;
        }
        &--check-all{
            border: 1px solid $box-comment-border-02;
            height: 30px;
            padding: 7px 3px 7px 10px;
            margin-top: 5px;

            &:hover {
                border: 1px solid $box-comment-border-02;
            }
        }
      }

      &__preloader-icon {
        animation: pulse 2000ms infinite ease-in-out;
      }

      &__preloader-holder {
        &--loader {
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 46 46' fill='green' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E\a             %3Cpolygon fill='%23fff' points='17.604 39.498 39.607 39.498 39.607 4.504 44.008 4.504 44.008 44 17.604 44'%3E%3C/polygon%3E\a             %3Cpolygon fill='%23fff' points='13.201 26.475 30.806 26.475 30.806 30.025 13.201 30.025'%3E%3C/polygon%3E\a             %3Cpolygon fill='%23fff' points='13.201 13.473 30.806 13.473 30.806 17.027 13.201 17.027'%3E%3C/polygon%3E\a             %3Cpolygon fill='%23fff' points='4.402 39.498 0 39.498 0 0 26.404 0 26.404 4.504 4.402 4.504'%3E%3C/polygon%3E\a             %3C/svg%3E	");
          animation: pulse 2000ms infinite ease-in-out;
        }
      }

      &__order-by {
        color: #AAAAAA;

        &--active {
          color: $primary-color;
        }
      }

      &__option-box {
        &--disabled {
          background-color: $donut-text-05;
        }
      }

      &__multiselect-block {
        &--search {
          background-color: $background-color-states;
          border-color: $result-search-quote-color;
        }
      }

      &__multiselect-item {
        &--no-name {
          border-color: $red;
        }
      }

      &__multiselect-delete-icon {
        &--multiple-extended,
        &--simple {
          color: $snow-white;
        }
      }

      &__modal-header-block {
        background-color: $primary-color;
      }

      &__bubble-count {
        &--disabled {
          background: $secondary-color;
        }
      }

      &__list-dropdown-option {
        @include common-background-hover($background-color-states);

        &--selected {
            background-color: $background-color-states;
        }
      }

      &__input-text {
        &--no-editable {
          color: $secondary-color;
          cursor: not-allowed;
        }

        &--required {
          color: $red;
        }
      }

      &__input-text-placeholder {
        text-transform: uppercase;

        &--no-editable {
          color: $secondary-color !important;
          cursor: not-allowed !important;
        }
      }

      &__input-icon-active {
        color: $primary-color;
        opacity: 1;
        &--disabled {
          color: $attach-icon-grey;
        }
      }

      &__input-content {
        color: $secondary-color;

          &:hover {
              &:-webkit-autofill {
                  -webkit-box-shadow: 0 0 0px 1000px #fafafafa inset;
              }
          }
      }

      &__input-no-editable {
          border-color: $result-search-quote-color;

          &:hover {
              border-color: $result-search-quote-color;
          }
      }

      &__input-block {
        &:hover:not([disabled]) {
          background-color: $background-color-states;
          border-color: $primary-color;

          & .common__delete-content-icon {
              color: $primary-color;
          }

          & .common__input-textarea {
              background: $background-color-states;
          }

          & .common__tooltip-info-box {
              display: block;
          }

          & .common__tooltip-law, .common__info-icon-block, .common__tooltip-law-block, & .common__info-icon-block--in-report {
              visibility: visible;
          }

          & .common__input-content:-webkit-autofill {
              -webkit-box-shadow: 0 0 0px 1000px #fafafafa inset;
          }
        }
        &:hover{
          & .common__info-icon-block--in-report {
            visibility: visible;
          }
        }
        &--visibility {
            background-color: $snow-white;
        }
        &--fixed {
          border-color: $result-search-quote-color;
          &:hover:not([disabled]) {
            border-color: $result-search-quote-color;
            background-color: initial;
          }
        }
      }

      &__input-block-open {
        background-color: $background-color-states;
        border-color: $primary-color;

        & .common__delete-content-icon {
            color: $primary-color;
        }

        & .common__input-textarea {
            background: $background-color-states;
        }
      }

      &__language-item {
        @include common-language-item($primary-color, $background-color-states);
          padding: 7px 0;

          &:hover {
              border-bottom: 1px solid $result-search-quote-color;
          }
      }

      &__find-in-dropdown {
        &--create-list {
          border: 1px solid $primary-color;
          background-color: $background-color-states;
          height: 29px;
        }
      }

      &__filter-icon {
          color: $subcontainer-ground;

          &--active {
              color: $primary-color;
          }
      }

      &__datepicker-col {
          color: $concept-blue;
      }

    &__button {
      &--white-red {
        @include button-with-border($red, $snow-white, transparent);
      }
      &--icon-down{
        padding: 9px 52px 7px 12px;
        &:after{
            display: block;
            width: calc(100% + 70px);
            right: 15px;
        }
      }
    }

    &__button-border-none {
      &--error {
        color: $red;

        &:after {
            content: " ";
            border-bottom: 2px solid $red;
        }

        &:hover:not([disabled]) {
            background-color: $red-opacity-02;
        }
      }
    }

    &__button-box-icon {
      color: $primary-color;
    }

    &__button-checked {
      &:after {
          content: " ";
          border-bottom: 2px solid $primary-color;
          width: calc(100% + 4px);
          display: block;
          right: 2px;
          top: 5px;
          position: relative;
      }
      &--30 {
          width: 29px;
          height: 29px;

          &:after {
              top: 7px;
          }
      }
      &--container {
          color: #7381B5;
          border-color: #7381B5;

          &:hover:not([disabled]) {
              background-color: #7381B5;
          }

          &:after {
              border-bottom-color: #7381B5;
          }
      }
    }

    &__button-option-list-dropdown {
      color: $no-primary-blue;
    }

    &__header-new-version{
      background-color: $background-grey-other;
      color: $pillow-group;

      &--deleted{
        background-color: $other-gray-03;
      }
    }

    &__options-row {
      @include common-options-row($background-grey-other, $primary-color, $result-search-quote-color);
      height: 42px;
    }

    &__menu-options-row{
      margin: 8px 3px 0;

      &--view {
        margin: 8px 5px 0px 5px;
      }
    }

    &__block-list-new-version {
        border-color: $result-search-quote-color;
        &--search{
            background-color: $background-color-states;
        }
    }

    &__header-button-add-new-version {
      color: $primary-color;

      &--process {
        color: $primary-color;
      }
    }

    &__table-header {
      border-color: $primary-color;
      color: $primary-color;
    }

    &__table-row {
      @include common-table-row($result-search-quote-color, $background-color-states);
      height: 45px;

      &--selected-item {
        @include common-table-row-selected-item($background-color-states);
      }
    }

    &__col-text {
      color: $secondary-color;

      &--disabled {
          color: $subcontainer-ground;
      }
    }

    &__table-header-text {
      color: $primary-color;
    }

    &__concept-menu-fixed {
      margin: 0;
    }

    &__contacts-import-icon {
      color: $box-comment-border-02;

      &--green {
          color: $green-opacity;
      }

      &--red {
          color: $red-opacity;
      }

      &--yellow {
          color: $yellow-opacity;
      }
    }

    &__icon-header {
      color: $snow-white;
    }

    &__icon-ellipsis {
      color: $primary-color;
    }

    &__icon-option-list-dropdown {
      color: $primary-color;
    }

    &__search-input {
      background-color: $background-color-states;
      border-color: $result-search-quote-color;
      color: $primary-color;

      &::-webkit-input-placeholder {
          color: $primary-color;
      }
    }

    &__text-search {
      color: $primary-color;
    }

    &__icon-button-search {
      color: $primary-color;
    }

    &__block-footer-total {
      color: $primary-color;
    }

    &__block-concept {
      &--border{
        border: 1px solid $primary-color;
      }
    }

    &__block-option-list {
      border-color: $subcontainer-border;
      border-top: 2px solid $primary-color;
    }
  }

  .mat-tooltip {
    background-color: $background-color-states;
    color: $primary-color;
    &::after {
      border-bottom-color:$primary-color;
    }

    &.below {
      border-top: 1px solid $primary-color;
    }

    &.above {
      border-bottom: 1px solid $primary-color;
    }

    &.right {
      border-left: 1px solid $primary-color;
    }

    &.left {
      border-right: 1px solid $primary-color;
    }
  }
}

&__steel-blue{
  .compliance__body{
    background-color: $snow-white;
  }
  @include common-scrollbar($secondary-color-2, $primary-color-2);
  .mat-tooltip {
      background-color: $background-grey-disabled-2;
      color: $primary-color-2;

      &::after {
        border-bottom-color:$primary-color-2;
      }

      &.below {
        border-top: 1px solid $primary-color-2;
      }

      &.above {
        border-bottom: 1px solid $primary-color-2;
      }

      &.right {
        border-left: 1px solid $primary-color-2;
      }

      &.left {
        border-right: 1px solid $primary-color-2;
      }
    }
  .tooltip {
      &[data-popper-placement^='bottom'] {
          .tooltip-arrow {
              &:before{
                border-bottom-color: $primary-color-2;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-2;
              color: $primary-color-2;
              border-top-color: $primary-color-2;
          }
      }
      &[data-popper-placement^='top'] {
          .tooltip-arrow {
              &:before{
                border-top-color: $primary-color-2;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-2;
              color: $primary-color-2;
              border-bottom-color: $primary-color-2;
          }
      }
      &[data-popper-placement^='left'] {
          .tooltip-arrow {
              &:before{
                border-left-color: $primary-color-2;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-2;
              color: $primary-color-2;
              border-right-color: $primary-color-2;
          }
      }
      &[data-popper-placement^='right'] {
          .tooltip-arrow {
              &:before{
                border-right-color: $primary-color-2;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-2;
              color: $primary-color-2;
              border-left-color: $primary-color-2;
          }
      }
      &>.tooltip-inner {
          background-color: $background-grey-disabled-2;
          color: $primary-color-2;
          border-bottom-color: $primary-color-2;
      }
  }

  .uib-datepicker-popup.dropdown-menu{
      border: 1px solid $third-color-2;
      box-shadow: 0 0 5px 2px $certify-black-opacity;
  }
  .common{
      &__dropdown-selector-button{
        border: 1px solid $secondary-color-2;
        &:hover:not([disabled]) {
          border: 1px solid $border-hover-2;
          background: $third-color-2;
        }
        &[disabled] {
          background: $background-grey-disabled-2;
        }
      }
      &__label-selector-button{
        color: $text-field-color-2;
      }
      &__underlined-title {
        color: $primary-color-2;
        border-bottom-color: $primary-color-2;
      }

      &__tooltip-info {
        background-color: $snow-white;
        color: $primary-color-2;
        border-color: rgba(39,52,78,0.3);
        border-left-color: $primary-color-2;

        &:after {
            border-right-color: $primary-color-2;
        }
      }

      &__tooltip-dropdown {
        @include common-tooltip-dropdown-color($primary-color-2, $snow-white, rgba(39,52,78,0.3));
      }

      &__tooltip-alert-icon {
        color: $border-hover-2;
      }

      &__title-block-modal {
        color: $grey-icons-2;
      }

      &__text-required {
        color: $alert-color-2 !important;
      }

      &__text-header {
        color: $white-special-2;
      }

      &__task-header {
        width: calc(100% - 40px);
        margin-left: 20px;
        padding-right: 0;
        padding-top: 11px;
      }

      &__table-cell-tooltip {
        color: $primary-color-2;

        &--second {
            color: $border-hover-2;
        }
      }

      &__switch-text-input {
        color: $primary-color-2;
      }

      &__pagination-count {
          color: $border-bottom-2;
      }

      &__switch-icon {
        &--inactive {
          color: $grey-icons-2;
        }

        &--active {
            color: $border-hover-2;
        }
      }

      &__show-alert {
        @include common-show-alert-color($alert-color-2, $background-other-error-2, transparent);

        &--fixed {
            @include common-show-alert-color($alert-color-2, $snow-white, $snow-white);
        }
      }

      &__select-option-icon-angle-down {
        color: $border-hover-2;

        &--box {
          color: $border-hover-2;
        }
      }

      &__select-list-with-input {
        @include common-select-list-with-input($third-color-2);
        top: 29px !important;

        &--filter {
            @include common-select-list-with-input($third-color-2);
        }
      }

      &__select-list-item-text {
        &--no-name {
          color: $alert-color-2;
        }
      }

      &__select-all {
       @include common-select-item-color($primary-color-2, $third-color-rgba-mid);
      }

      &__select-item {
        @include common-select-item-color($primary-color-2, $third-color-rgba-mid);
        &--check-all{
            border-bottom: 1px solid $third-color-2;
            height: 30px;
            padding: 7px 3px 7px 10px;
            margin-top: 5px;

            &:hover {
                border-bottom: 1px solid $third-color-2;
            }
        }
        &--error {
            @include common-select-item-error($background-error-2, $alert-color-2);
        }
      }

      &__select-list {
        border-color: $border-hover-2;
      }

      &__select-icon {
        color: $border-hover-2;
      }

      &__select-arrow {
        color: $border-hover-2;
      }

      &__search-icon {
        color: $border-hover-2;
      }

      &__preloader-icon {
        animation: puls-be 2000ms infinite ease-in-out;
      }

      &__preloader-holder {
        &--loader {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='Capa_1' data-name='Capa 1' viewBox='0 0 256 256'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23F7F7F3;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EICONOS FINALES-TRAZADOS%3C/title%3E%3Cpath class='cls-1' d='M244.74,109.21,225.85,128.1A102.92,102.92,0,0,0,123.22,31v2h0V44.76h0v2A87.08,87.08,0,0,1,210,128.45l-19.24-19.24-11,11,38,38,38-38Z'/%3E%3Cpath class='cls-1' d='M132.78,211.24h0v-2A87.08,87.08,0,0,1,46,127.56l19.24,19.23,11-11-38-38-38,38,11,11L30.15,127.9a102.92,102.92,0,0,0,102.63,97.15v-2h0Z'/%3E%3C/svg%3E");
          animation: puls-be 2000ms infinite ease-in-out;
        }
      }

      &__order-by {
        color: $secondary-color-2;

        &--active {
            color: $border-hover-2;
        }
      }

      &__option-box {
        background-color: $background-grey-disabled-2;
      }

      &__multiselect-block {
        &--search {
          background-color: $background-grey-disabled-2;
          border-color: $secondary-color-2;
        }
      }

      &__multiselect-item {
        border-color: 1px solid $secondary-color-2;
        background-color: transparent;

        &--no-name {
            border-color: $alert-color-2;
        }
      }

      &__multiselect-text {
        color: $text-field-color-2;
      }

      &__multiselect-select-icon {
        @include common-multiselect-select-icon($border-hover-2, $block-hover-2);
      }

      &__multiselect-delete-icon {
        &--simple {
          color: $border-hover-2;
          font-size: 10px;
          top: 5px;

        }

        &--multiple {
            color: $border-hover-2;
        }

        &--multiple-extended {
          color: $border-hover-2;
        }
      }

      &__multiselect-dropdown-item {
        &--with-input {
          @include common-multiselect-dropdown-item-with-input($third-color-2, $text-field-color-2, $border-hover-2);
        }
      }

      &__modal-header-block {
        background-color: $primary-color-2;
        height: 70px;
        padding: 22px 23px;
      }

      &__menu-expanded-little {
        border-color: $third-color-2;

        &:after {
            border-color: $third-color-2;
        }
      }

      &__menu-expanded-little-option {
        @include common-background-hover($block-hover-2);
      }

      &__menu-expanded-little-option-item {
        color: $border-hover-2;
        border-color: $third-color-2;
      }

      &__bubble-count {
        background-color: $border-hover-2;

        &--disabled {
            background: $grey-icons-2;
        }
      }

      &__list-dropdown-option {
        @include common-background-hover($block-hover-2);

        &--selected {
            background-color: $block-hover-2;
        }
      }

      &__input-text {
        color: $primary-color-2;

        &--no-editable {
            color: $primary-color-2;
            cursor: not-allowed;
        }

        &--required {
          color: $alert-color-2;
        }
      }

      &__input-text-placeholder {
        color: $primary-color-2;

        &--no-editable {
          color: $secondary-color-2 !important;
          cursor: not-allowed !important;
        }
      }

      &__input-icon {
        color: $grey-icons-2;
        opacity: 0.7;
      }

      &__input-icon-active {
        color: $border-hover-2;
        opacity: 1;
        &--disabled {
          color: $grey-icons-2;
          opacity: 0.7;
        }
      }

      &__input-content {
        color: $text-field-color-2;

        &:hover {
          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px $third-color-2 inset;
          }
        }
      }

      &__input-content-alert {
        color: $alert-color-2;

          &::placeholder {
              color: $alert-color-2;
              font-style: italic;
          }
      }

      &__input-content-no-editable {
        color: $text-field-color-2;
      }

      &__input-no-editable {
        border-color: $secondary-color-2;
        background-color: $background-grey-disabled-2;

        &:hover {
          border-color: $secondary-color-2;
          background-color: $background-grey-disabled-2;
        }
      }

      &__input-block {
        border-color: $secondary-color-2;

        &:hover:not([disabled]) {
          //background-color: $third-color-2;
          //border-color: $border-hover-2;

          .common__tooltip-alert-icon,
          .common__input-text, .common__delete-content-icon {
              color: $border-hover-2;
          }

          & .common__tooltip-info-box {
              display: block;
          }

          & .common__tooltip-law, .common__info-icon-block, .common__tooltip-law-block {
              visibility: visible;
          }

          & .common__input-content:-webkit-autofill {
              -webkit-box-shadow: 0 0 0px 1000px $third-color-2 inset;
          }
        }

        &--fixed {
          border-color: $secondary-color-2;
          &:hover:not([disabled]) {
            .common__tooltip-alert-icon,
            .common__delete-content-icon {
                color: $border-hover-2;
                border-color: $secondary-color-2;
                background-color: initial;
            }
          }
        }
      }

      &__input-block-open {
        @include common-input-block-open-color($third-color-2, $border-hover-2);
      }

      &__header-textarea {
        border-color: $secondary-color-2;
      }

      &__language-item {
        @include common-language-item($border-hover-2, $third-color-rgba-mid);
      }

      &__find-in-dropdown {
        @include common-find-in-dropdown-color($third-color-2, $border-hover-2);

        &--create-list {
          @include common-find-in-dropdown-create-list($border-hover-2, $third-color-2, $border-hover-2);
        }
      }

      &__find-dropdown-icon-find {
          color: $border-hover-2;
          top: 2px;
      }

      &__filter-icon {
          color: $secondary-color-2;

          &--active {
              color: $border-hover-2;
          }
      }

      &__error-button-list {
          border-color: $alert-color-2;
          color: $alert-color-2;
      }

      &__delete-content-icon {
          color: $border-hover-2;
      }

      &__text-muted {
          color: $secondary-color-2;
      }

      &__textarea-simple {
          border-color: $secondary-color-2;
      }

      &__datepicker-col {
          color: $border-bottom-2;
      }

      &__datepicker-title {
          color: $border-hover-2;
      }

      // &__datepicker-button {
      //     color: $primary-color-2;

      //     &[disabled] {
      //         cursor: default;
      //         color: $secondary-color-2;
      //     }

      //     &:hover {
      //         background-color: $third-color-2;
      //         color: $primary-color-2;
      //         outline: none;
      //     }

      //     &--current {
      //         border: 1px solid $border-hover-2;
      //     }

      //     &--selected {
      //         background-color: $border-hover-2;
      //         color:  $white-special-2;
      //     }
      // }

      &__checkbox {
          color: $border-hover-2;

          &[disabled] {
              color: $grey-icons-2;
          }
      }

    &__button {
      &--white{
          @include button-without-border($primary-color-2, $white-special-2, transparent);
      }
      &--white-red {
          @include button-without-border($alert-color-button-2, $snow-white, transparent);
      }
      &--red{
          position: relative;
          @include button-without-border($snow-white, $alert-color-button-2, transparent);
      }
      &--grey{
          @include button-without-border($primary-color-2, $grey-icons-2, transparent);
      }
      &--grey-red{
          @include button-without-border($alert-color-2, $grey-icons-2, transparent);
      }
      &--clean {
        @include common-button($grey-icons-2, $snow-white);
      }

      &--active {
        @include common-button($border-hover-2, $snow-white);
      }
    }

    &__button-border-none {
      color: $border-hover-2;

      &:after {
          content: " ";
          border-bottom: 2px solid $border-hover-2;
      }

      &:hover:not([disabled]) {
          background-color: $block-hover-2;
      }

      &--error {
        color: $alert-color-2;

        &:after {
            content: " ";
            border-bottom: 2px solid $alert-color-2;
        }

        &:hover:not([disabled]) {
            background-color: $background-error-2;
        }
      }
    }

    &__buttons-block {
        &--margin{
            margin-right: 20px;
        }
    }

    &__button-box-icon {
      color: $border-hover-2;
    }

    &__button-box-option {
      color: $primary-color-2;
    }

    &__button-checked {
      border-width: 1px;
      @include common-button($border-hover-2, $snow-white);
    }

    &__button-close {
      border-width: 1px;
      @include common-button($grey-icons-2, $snow-white);
    }

    &__button-option-list-dropdown {
      color: $border-hover-2;
      border-color: $third-color-2;
    }

    &__header-new-version{
      background-color: $background-highlight-text-2;
      color: $primary-color-2;

      &--deleted{
        background-color: $third-color-2;
      }
    }

    &__options-row {
      @include common-options-row($third-color-2, $border-hover-2, $secondary-color-2);
    }

    &__menu-options-row{
      &--view {
        margin: 9px 5px 0px 5px;
      }
    }

    &__block-list-new-version {
        border-color: $background-highlight-text-2;
        &--search{
            background-color: $background-grey-disabled-2;
        }
    }

    &__header-button-add-new-version {
      color: $border-hover-2;

      &--process {
        color: $border-hover-2;
      }
    }

    &__table-header {
      border-color: $background-highlight-text-2;
      color: $border-hover-2;
    }

    &__table-row {
      @include common-table-row($secondary-color-2, $third-color-rgba-mid);

      &--selected-item {
        @include common-table-row-selected-item($third-color-rgba-mid);
      }
    }

    &__col-text {
      color: $primary-color-2;
    }

    &__table-header-text {
      color: $border-hover-2;
    }

    &__concept-menu-fixed-scroll {
      background: none;
      box-shadow: none;
    }

    &__contacts-import-icon {
      color: $icon-shadow-2;

      &--green {
          color: $green-opacity;
      }

      &--red {
          color: $red-opacity;
      }

      &--yellow {
          color: $yellow-opacity;
      }
    }

    &__icon-close-modal {
      color: $white-special-2;
    }

    &__icon-ellipsis {
      color: $border-hover-2;
    }

    &__icon-header {
      color: $white-special-2;
    }

    &__icon-option-list-dropdown {
      color: $border-hover-2;
    }

    &__icon-button-search {
      color: $border-hover-2;
    }

    &__search-input {
      background-color: $block-hover-2;
      border-color: $border-hover-2;
      color: $border-hover-2;

      &::-webkit-input-placeholder {
          color: $border-hover-2;
      }
    }

    &__text-search {
      color: $border-hover-2;
    }

    &__back-link {
        color: $border-hover-2;
    }

    &__block-footer-total {
      color: $primary-color-2
    }

    &__block-concept {
      @include common-block-concept-color($secondary-color-2);
    }

    &__block-option-list {
      border-color: $third-color-2;
    }

    &__box-text-info {
      color: $primary-color-2;
    }
    &__window-footer-bottom{
        padding-bottom: 130px;
    }
    &__footer-bottom{
        height: 130px;
    }
    ////PDF view////
    &__pdf-block-header-modified {
        color: $grey-icons-2;
    }

    &__pdf-title-block{
        background-color: $background-grey-disabled-2;
        color: $primary-color-2;
    }

    &__pdf-subtitle-block{
        background-color: $background-grey-disabled-2;
        color: $text-field-color-2;
    }

    &__pdf-table-header {
        color: $border-hover-2;
    }

    &__pdf-table-header-text {
        color: $border-hover-2;
    }

    &__pdf-col{
        border-color: $secondary-color-2;
    }

    &__pdf-col-text {
        color: $primary-color-2;
    }
    //////////Fin PDF//////////
  }
}

&__uk-steel-blue{
  .compliance__body{
    background-color: $background-grey-uk;
  }
  @include common-scrollbar($secondary-color-2, $primary-color-2);
  .mat-tooltip {
      background-color: $background-grey-disabled-2;
      color: $primary-color-2;

      &::after {
        border-bottom-color:$primary-color-2;
      }

      &.below {
        border-top: 1px solid $primary-color-2;
      }

      &.above {
        border-bottom: 1px solid $primary-color-2;
      }

      &.right {
        border-left: 1px solid $primary-color-2;
      }

      &.left {
        border-right: 1px solid $primary-color-2;
      }
    }
  .tooltip {
      &[data-popper-placement^='bottom'] {
          .tooltip-arrow {
              &:before{
                border-bottom-color: $primary-color-2;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-2;
              color: $primary-color-2;
              border-top-color: $primary-color-2;
          }
      }
      &[data-popper-placement^='top'] {
          .tooltip-arrow {
              &:before{
                border-top-color: $primary-color-2;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-2;
              color: $primary-color-2;
              border-bottom-color: $primary-color-2;
          }
      }
      &[data-popper-placement^='left'] {
          .tooltip-arrow {
              &:before{
                border-left-color: $primary-color-2;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-2;
              color: $primary-color-2;
              border-right-color: $primary-color-2;
          }
      }
      &[data-popper-placement^='right'] {
          .tooltip-arrow {
              &:before{
                border-right-color: $primary-color-2;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-2;
              color: $primary-color-2;
              border-left-color: $primary-color-2;
          }
      }
      &>.tooltip-inner {
          background-color: $background-grey-disabled-2;
          color: $primary-color-2;
          border-bottom-color: $primary-color-2;
      }
  }

  .uib-datepicker-popup.dropdown-menu{
      border: 1px solid $third-color-2;
      box-shadow: 0 0 5px 2px $certify-black-opacity;
  }

  .common{
      &__underlined-title {
        color: $primary-color-2;
        border-bottom-color: $primary-color-2;
      }

      &__tooltip-dropdown {
        @include common-tooltip-dropdown-color($primary-color-2, $snow-white, rgba(39,52,78,0.3));
      }

      &__tooltip-alert-icon {
        color: $border-hover-2;
      }

      &__title-block-modal {
        color: $grey-icons-2;
      }

      &__text-required {
        color: $alert-color-2 !important;
      }

      &__text-header {
        color: $white-special-2;
      }

      &__task-header {
        width: calc(100% - 40px);
        margin-left: 20px;
        padding-right: 0;
        padding-top: 11px;
      }

      &__switch-text-input {
        color: $primary-color-2;
      }

      &__pagination-count {
          color: $border-bottom-2;
      }

      &__switch-icon {
        &--inactive {
          color: $grey-icons-2;
        }

        &--active {
            color: $border-hover-2;
        }
      }

      &__show-alert {
        @include common-show-alert-color($alert-color-2, $background-other-error-2, transparent);

        &--fixed {
            @include common-show-alert-color($alert-color-2, $snow-white, $snow-white);
        }
      }

      &__select-option-icon-angle-down {
        color: $border-hover-2;

        &--box {
          color: $border-hover-2;
        }
      }

      &__select-list-with-input {
        @include common-select-list-with-input($third-color-2);
        top: 29px !important;

        &--filter {
            @include common-select-list-with-input($third-color-2);
        }
      }

      &__select-list-item-text {
        &--no-name {
          color: $alert-color-2;
        }
      }

      &__select-all {
        @include common-select-item-color($primary-color-2, $third-color-rgba-mid);
      }

      &__select-item {
        @include common-select-item-color($primary-color-2, $third-color-rgba-mid);
        &--check-all{
            border-bottom: 1px solid $third-color-2;
            height: 30px;
            padding: 7px 3px 7px 10px;
            margin-top: 5px;

            &:hover {
                border-bottom: 1px solid $third-color-2;
            }
        }
        &--error {
            @include common-select-item-error($background-error-2, $alert-color-2);
        }
      }

      &__select-list {
        border-color: $border-hover-2;
      }

      &__select-icon {
        color: $border-hover-2;
      }

      &__select-arrow {
        color: $border-hover-2;
      }

      &__search-icon {
        color: $border-hover-2;
      }

      &__order-by {
        color: $secondary-color-2;

        &--active {
            color: $border-hover-2;
        }
      }

      &__option-box {
        background-color: $background-grey-disabled-2;
      }

      &__multiselect-block {
        &--search {
          background-color: $background-grey-disabled-2;
          border-color: $secondary-color-2;
        }
      }

      &__multiselect-item {
        border-color: 1px solid $secondary-color-2;
        background-color: transparent;

        &--no-name {
            border-color: $alert-color-2;
        }
      }

      &__multiselect-text {
        color: $text-field-color-2;
      }

      &__multiselect-select-icon {
        @include common-multiselect-select-icon($border-hover-2, $block-hover-2);
      }

      &__multiselect-delete-icon {
        &--simple {
          color: $border-hover-2;
          font-size: 10px;
          top: 5px;

        }

        &--multiple {
            color: $border-hover-2;
        }

        &--multiple-extended {
          color: $border-hover-2;
        }
      }

      &__multiselect-dropdown-item {
        &--with-input {
          @include common-multiselect-dropdown-item-with-input($third-color-2, $text-field-color-2, $border-hover-2);
        }
      }

      &__modal-header-block {
        background-color: $primary-color-2;
        height: 70px;
        padding: 22px 23px;
      }

      &__menu-expanded-little {
        border-color: $third-color-2;

        &:after {
            border-color: $third-color-2;
        }
      }

      &__menu-expanded-little-option {
        @include common-background-hover($block-hover-2);
      }

      &__menu-expanded-little-option-item {
        color: $border-hover-2;
        border-color: $third-color-2;
      }

      &__bubble-count {
        background-color: $border-hover-2;

        &--disabled {
            background: $grey-icons-2;
        }
      }

      &__list-dropdown-option {
        @include common-background-hover($block-hover-2);

        &--selected {
            background-color: $block-hover-2;
        }
      }

      &__input-text {
        color: $primary-color-2;

        &--no-editable {
            color: $primary-color-2;
            cursor: not-allowed;
        }

        &--required {
          color: $alert-color-2;
        }
      }

      &__input-text-placeholder {
        color: $primary-color-2;

        &--no-editable {
          color: $secondary-color-2 !important;
          cursor: not-allowed !important;
        }
      }

      &__input-icon {
        color: $grey-icons-2;
        opacity: 0.7;
      }

      &__input-icon-active {
        color: $border-hover-2;
        opacity: 1;
        &--disabled {
          color: $grey-icons-2;
          opacity: 0.7;
        }
      }

      &__input-content {
        color: $text-field-color-2;

        &:hover {
          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px $third-color-2 inset;
          }
        }
      }

      &__input-content-alert {
        color: $alert-color-2;

          &::placeholder {
              color: $alert-color-2;
              font-style: italic;
          }
      }

      &__input-content-no-editable {
        color: $text-field-color-2;
      }

      &__input-no-editable {
        border-color: $secondary-color-2;
        background-color: $background-grey-disabled-2;

        &:hover {
          border-color: $secondary-color-2;
          background-color: $background-grey-disabled-2;
        }
      }

      &__input-block {
        border-color: $secondary-color-2;

        &:hover:not([disabled]) {
          background-color: $third-color-2;
          border-color: $border-hover-2;

          .common__tooltip-alert-icon,
          .common__input-text, .common__delete-content-icon {
              color: $border-hover-2;
          }

          & .common__tooltip-info-box {
              display: block;
          }

          & .common__tooltip-law, .common__info-icon-block, .common__tooltip-law-block {
              visibility: visible;
          }

          & .common__input-content:-webkit-autofill {
              -webkit-box-shadow: 0 0 0px 1000px $third-color-2 inset;
          }
        }

        &--fixed {
          border-color: $secondary-color-2;
          &:hover:not([disabled]) {
            .common__tooltip-alert-icon,
            .common__delete-content-icon {
                color: $border-hover-2;
                border-color: $secondary-color-2;
                background-color: initial;
            }
          }
        }
      }

      &__header-title-new-version {
        font-family: MTTMilano-Bold, sans-serif;
        font-weight: normal;
      }

      &__header-textarea {
        border-color: $secondary-color-2;
      }

      &__language-item {
        @include common-language-item($border-hover-2, $third-color-rgba-mid);
      }

      &__filter-icon {
          color: $secondary-color-2;

          &--active {
              color: $border-hover-2;
          }
      }

      &__textarea-simple {
          border-color: $secondary-color-2;
      }

    &__button {
      &--white{
          @include button-without-border($primary-color-2, $white-special-2, transparent);
      }
      &--white-red {
          @include button-without-border($alert-color-button-2, $snow-white, transparent);
      }
      &--red{
          position: relative;
          @include button-without-border($snow-white, $alert-color-button-2, transparent);
      }
      &--grey{
          @include button-without-border($primary-color-2, $grey-icons-2, transparent);
      }
      &--grey-red{
          @include button-without-border($alert-color-2, $grey-icons-2, transparent);
      }

      &--clean {
        @include common-button($grey-icons-2, $snow-white);
      }

      &--active {
        @include common-button($border-hover-2, $snow-white);
      }
    }

    &__button-border-none {
      color: $border-hover-2;

      &:after {
          content: " ";
          border-bottom: 2px solid $border-hover-2;
      }

      &:hover:not([disabled]) {
          background-color: $block-hover-2;
      }

      &--error {
        color: $alert-color-2;

        &:after {
            content: " ";
            border-bottom: 2px solid $alert-color-2;
        }

        &:hover:not([disabled]) {
            background-color: $background-error-2;
        }
      }
    }

    &__buttons-block {
        &--margin{
            margin-right: 20px;
        }
    }

    &__button-box-icon {
      color: $border-hover-2;
    }

    &__button-box-option {
      color: $primary-color-2;
    }

    &__button-checked {
      border-width: 1px;
      @include common-button($border-hover-2, $snow-white);
    }

    &__button-close {
      border-width: 1px;
      @include common-button($grey-icons-2, $snow-white);
    }

    &__button-option-list-dropdown {
      color: $border-hover-2;
      border-color: $third-color-2;
    }

    &__header-new-version{
      color: $primary-color-2;
    }

    &__options-row {
      @include common-options-row($third-color-2, $border-hover-2, $secondary-color-2);
    }

    &__block-list-new-version {
      border: none;
      box-shadow: $box-shadow-v3;
      &--search{
          background-color: $background-grey-disabled-2;
      }
    }

    &__header-button-add-new-version {
      color: $border-hover-2;

      &--process {
        color: $border-hover-2;
      }
    }

    &__table-header {
      border-color: $background-highlight-text-2;
      color: $border-hover-2;
    }

    &__table-row {
      @include common-table-row($secondary-color-2, $third-color-rgba-mid);

      &--selected {
        background-color: $third-color-2;
      }

      &--selected-item {
        @include common-table-row-selected-item($third-color-rgba-mid);
      }
    }

    &__col-text {
      color: $primary-color-2;
    }

    &__table-header-text {
      color: $border-hover-2;
    }

    &__concept-menu-fixed-scroll {
      background: none;
      box-shadow: none;
    }

    &__contacts-import-icon {
      color: $icon-shadow-2;

      &--green {
          color: $green-opacity;
      }

      &--red {
          color: $red-opacity;
      }

      &--yellow {
          color: $yellow-opacity;
      }
    }

    &__icon-close-modal {
      color: $white-special-2;
    }

    &__icon-ellipsis {
      color: $border-hover-2;
    }

    &__icon-header {
      color: $white-special-2;
    }

    &__icon-option-list-dropdown {
      color: $border-hover-2;
    }

    &__search-input {
      background-color: $block-hover-2;
      border-color: $border-hover-2;
      color: $border-hover-2;

      &::-webkit-input-placeholder {
          color: $border-hover-2;
      }
    }

    &__icon-button-search {
      color: $border-hover-2;
    }

    &__back-link {
        color: $border-hover-2;
    }

    &__block-footer-total {
      color: $primary-color-2
    }

    &__block-concept {
      @include common-block-concept-color($secondary-color-2);
    }

    &__block-option-list {
      border-color: $third-color-2;
    }

    &__box-text-info {
      color: $primary-color-2;
    }
    &__window-footer-bottom{
      padding-bottom: 60px;
    }
    &__footer-bottom{
      height: 60px;
    }
    ////PDF view////
    &__pdf-block-header-modified {
        color: $grey-icons-2;
    }

    &__pdf-title-block{
        background-color: $background-grey-disabled-2;
        color: $primary-color-2;
    }

    &__pdf-subtitle-block{
        background-color: $background-grey-disabled-2;
        color: $text-field-color-2;
    }

    &__pdf-table-header {
        color: $border-hover-2;
    }

    &__pdf-table-header-text {
        color: $border-hover-2;
    }

    &__pdf-col{
        border-color: $secondary-color-2;
    }

    &__pdf-col-text {
        color: $primary-color-2;
    }
    //////////Fin PDF//////////
  }
}

&__icam-red{
  .compliance__body{
    background-color: $background-grey-icam;
  }
  @include common-scrollbar($border-block-icam, $custom-black-landing);
  .mat-tooltip {
      background-color: $background-grey-disabled-icam;
      color: $custom-black-landing;

      &::after {
        border-bottom-color:$primary-color-icam;
      }

      &.below {
        border-top: 1px solid $primary-color-icam;
      }

      &.above {
        border-bottom: 1px solid $primary-color-icam;
      }

      &.right {
        border-left: 1px solid $primary-color-icam;
      }

      &.left {
        border-right: 1px solid $primary-color-icam;
      }
    }
  .tooltip {
      &[data-popper-placement^='bottom'] {
          .tooltip-arrow {
              &:before{
                border-bottom-color: $primary-color-icam;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-icam;
              color: $custom-black-landing;
              border-top-color: $primary-color-icam;
          }
      }
      &[data-popper-placement^='top'] {
          .tooltip-arrow {
              &:before{
                border-top-color: $primary-color-icam;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-icam;
              color: $custom-black-landing;
              border-bottom-color: $primary-color-icam;
          }
      }
      &[data-popper-placement^='left'] {
          .tooltip-arrow {
              &:before{
                border-left-color: $primary-color-icam;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-icam;
              color: $custom-black-landing;
              border-right-color: $primary-color-icam;
          }
      }
      &[data-popper-placement^='right'] {
          .tooltip-arrow {
              &:before{
                border-right-color: $primary-color-icam;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-icam;
              color: $custom-black-landing;
              border-left-color: $primary-color-icam;
          }
      }
      &>.tooltip-inner {
          background-color: $background-grey-disabled-icam;
          color: $custom-black-landing;
          border-bottom-color: $primary-color-icam;
      }
  }

  .uib-datepicker-popup.dropdown-menu{
      border: 1px solid $primary-color-icam;
      box-shadow: 0 0 5px 2px $certify-black-opacity;
  }

  .common{
      &__inline-warning {
        background: rgba($yellow-task-icam, 0.2);

        &__text {
          color: $custom-black-landing;
        }

        &__icon, &__close {
          color: $yellow-task-icam;
        }
      }

      &__underlined-title {
        color: $custom-black-landing;
        border-bottom-color: $custom-black-landing;
      }

      &__tooltip-dropdown {
        @include common-tooltip-dropdown-color($custom-black-landing, $background-grey-disabled-icam, rgba(0,0,0,0.3));
      }

      &__tooltip-alert-icon {
        color: $primary-color-icam;
      }

      &__title-block-modal {
        color: $secondary-color-icam;
      }

      &__text-required {
        color: $alert-color-icam !important;
      }

      &__task-header {
        width: calc(100% - 40px);
        margin-left: 20px;
        padding-right: 0;
        padding-top: 11px;
      }

      &__switch-text-input {
        color: $custom-black-landing;
      }

      &__pagination-count {
          color: $modal-question-icam;
      }

      &__switch-icon {
        &--inactive {
          color: $secondary-color-icam;
        }

        &--active {
            color: $primary-color-icam;
        }
      }

      &__show-alert {
        @include common-show-alert-color($alert-color-icam, $red-task-20-icam, $snow-white);

        &--fixed {
            @include common-show-alert-color($alert-color-icam, $snow-white, $snow-white);
        }
      }

      &__select-option-icon-angle-down {
        color: $primary-color-icam;

        &--box {
            color: $snow-white;
        }
      }

      &__select-list-with-input {
        @include common-select-list-with-input($primary-color-icam);
        top: 29px !important;

        &--filter {
            @include common-select-list-with-input($primary-color-icam);
        }
      }

      &__select-all {
       @include common-select-item-color($custom-black-landing, $hover-block-icam);
      }

      &__select-item {
        @include common-select-item-color($custom-black-landing, $hover-block-icam);

        &--error {
            @include common-select-item-error($red-task-20-icam, $alert-color-icam);
        }
      }

      &__select-list {
        border-color: $primary-color-icam;
      }

      &__select-icon {
        color: $primary-color-icam;
      }

      &__select-arrow {
        color: $primary-color-icam;
      }

      &__search-icon {
        color: $primary-color-icam;
      }

      &__order-by {
        color: $secondary-color-icam;

        &--active {
            color: $primary-color-icam;
        }
      }

      &__options-block {
        color: $snow-white;
      }

      &__option-box {
        background-color: $background-grey-disabled-icam;
      }

      &__multiselect-item {
        border: 1px solid $border-block-icam;
        background-color: transparent;

        &--no-name {
            color: $alert-color-icam;
        }
      }

      &__multiselect-text {
        color: $text-field-color-icam;
      }

      &__multiselect-select-icon {
        @include common-multiselect-select-icon($primary-color-icam, $hover-block-icam);
      }

      &__multiselect-delete-icon {
        &--simple {
          color: $primary-color-icam;
          font-size: 10px;
          top: 5px;
        }

        &--multiple {
            color: $primary-color-icam;
        }

        &--multiple-extended {
          color: $primary-color-icam;
        }
      }

      &__multiselect-dropdown-item {
        &--with-input {
          @include common-multiselect-dropdown-item-with-input($third-color-icam, $text-field-color-icam, $primary-color-icam);
        }
      }

      &__modal-header-block {
        background-color: $primary-color-icam;
        height: 70px;
        padding: 22px 23px;
      }

      &__menu-expanded-little {
        border-color: $primary-color-icam;

        &:after {
            border-color: $primary-color-icam;
        }
      }

      &__menu-expanded-little-option {
        @include common-background-hover($hover-block-icam);
      }

      &__menu-expanded-little-option-item {
        color: $primary-color-icam;
        border-color: $third-color-icam;
      }

      &__bubble-count {
        background-color: $primary-color-icam;

        &--disabled {
            background: $secondary-color-icam;
        }
      }

      &__list-dropdown-option {
        @include common-background-hover($hover-block-icam);

        &--selected {
            background-color: $hover-block-icam;
        }
      }

      &__input-text {
        color: $custom-black-landing;

        &--no-editable {
          color: $custom-black-landing;
          cursor: not-allowed;
        }

        &--required {
          color: $alert-color-icam;
        }

        &--secondary {
          color: $secondary-color-icam;
        }
      }

      &__input-text-placeholder {
        color: $custom-black-landing;

        &--no-editable {
            color: $custom-black-landing !important;
            cursor: not-allowed !important;
        }
      }

      &__input-icon {
        color: $secondary-color-icam;
          opacity: 0.7;

          &[disabled] {
            color: $secondary-color-icam;
          }
      }

      &__input-icon-active {
        color: $primary-color-icam;
        opacity: 1;
        &--disabled {
          color: $secondary-color-icam;
        }
      }

      &__input-content {
        color: $text-field-color-icam;
      }

      &__input-content-alert {
        color: $alert-color-icam;

          &::placeholder {
              color: $alert-color-icam;
              font-style: italic;
          }
      }

      &__input-content-no-editable {
        color: $text-field-color-icam;
      }

      &__input-no-editable {
          border-color: $border-block-icam;
          background-color: $background-grey-disabled-icam;

          &:hover {
              border-color: $border-block-icam;
              background-color: $background-grey-disabled-icam;
          }
      }

      &__input-block {
        border-color: $border-block-icam;

        &:hover:not([disabled]) {
          border-color: $primary-color-icam;
          background-color: $hover-block-icam;

          .common__tooltip-alert-icon,
          .common__input-text, .common__delete-content-icon {
              color: $primary-color-icam;
          }
        }

        &--fixed {
          border-color: $border-block-icam;
          &:hover:not([disabled]) {
            border-color: $border-block-icam;
            background-color: initial;
          }
        }
      }

      &__input-block-open {
        @include common-input-block-open-color($hover-block-icam, $primary-color-icam);
      }

      &__header-textarea {
        border-color: $border-block-icam;
      }

      &__language-item {
        @include common-language-item($primary-color-icam, $hover-block-icam);
      }

      &__find-in-dropdown {
        @include common-find-in-dropdown-color($third-color-icam, $primary-color-icam);

        &--create-list {
          @include common-find-in-dropdown-create-list($primary-color-icam, $hover-block-icam, $text-field-color-icam);
        }
      }

      &__find-dropdown-icon-find {
          color: $primary-color-icam;
          top: 2px;
      }

      &__filter-icon {
          color: $secondary-color-icam;

          &--active {
              color: $primary-color-icam;
          }
      }

      &__error-button-list {
          border-color: $alert-color-icam;
          color: $alert-color-icam;
      }

      &__delete-content-icon {
          color: $primary-color-icam;
      }

      &__textarea-simple {
          border-color: $border-block-icam;
      }

      &__datepicker-col {
          color: $text-field-color-icam;
      }

      &__datepicker-header-tab {
          border-bottom: 1px solid $border-block-icam;
      }

      &__datepicker-title {
          color: $primary-color-icam;
      }

      // &__datepicker-button {
      //     color: $custom-black-landing;

      //     &[disabled] {
      //         cursor: default;
      //         color: $secondary-color-icam;
      //     }

      //     &:hover {
      //         background-color: $hover-block-icam;
      //         color: $custom-black-landing;
      //         outline: none;
      //     }

      //     &--current {
      //         border: 1px solid $primary-color-icam;
      //     }

      //     &--selected {
      //         background-color: $primary-color-icam;
      //     }
      // }

      &__checkbox {
          color: $primary-color-icam;

          &[disabled] {
              color: $secondary-color-icam;
          }
      }

    &__button {
      &--white{
          @include button-without-border($modal-question-icam, $snow-white, transparent);
          padding: 9px 29px;
      }
      &--white-red {
          @include button-without-border($alert-color-icam, $snow-white, transparent);
      }
      &--grey{
          @include button-without-border($modal-question-icam, $secondary-color-icam, transparent);
          padding: 9px 29px;
      }
      &--grey-red{
          @include button-without-border($alert-color-icam, $secondary-color-icam, transparent);
      }

      &--clean {
        @include common-button($secondary-color-icam, $snow-white);
      }

      &--active {
        @include common-button($primary-color-icam, $snow-white);
      }
    }

    &__button-border-none {
      color: $primary-color-icam;

      &:after {
          content: " ";
          border-bottom: 2px solid $primary-color-icam;
      }

      &:hover:not([disabled]) {
          background-color: $hover-block-icam;
      }

      &--error {
        color: $alert-color-icam;

        &:after {
            content: " ";
            border-bottom: 2px solid $alert-color-icam;
        }

        &:hover:not([disabled]) {
            background-color: $hover-block-icam;
        }
      }
    }

    &__button-box-icon {
      color: $primary-color-icam;
    }

    &__button-box-option {
      color: $custom-black-landing;
    }

    &__button-checked {
      border-width: 1px;
      @include common-button($primary-color-icam, $snow-white);
    }

    &__button-close {
      border-width: 1px;
      @include common-button($secondary-color-icam, $snow-white);
    }

    &__button-option-list-dropdown {
      color: $primary-color-icam;
      border-color: $third-color-icam;
    }

    &__header-new-version{
      background-color: $primary-color-icam;
      color: $title-text-icam;
    }

    &__options-row {
      @include common-options-row($primary-color-icam, $snow-white, $primary-color-icam);
    }

    &__block-list-new-version {
      border: none;
      box-shadow: $box-shadow-icam;
      &--search{
          background-color: $background-grey-icam;
      }
    }

    &__header-button-add-new-version {
      color: $snow-white;

      &--process {
        color: $primary-color-icam;
      }
    }

    &__table-header {
      border-color: $primary-color-icam;
      color: $primary-color-icam;
    }

    &__table-row {
      @include common-table-row($border-block-icam, $hover-block-icam);

      &--priority {
        background-color: $orange-task-10-icam;
      }

      &--selected-item {
          @include common-table-row-selected-item($hover-block-icam);
      }
    }

    &__col-text {
      color: $custom-black-landing;

      &--disabled {
          color: $background-grey-disabled-icam;
      }

      &--url {
          color: $primary-color-icam;
      }
    }

    &__table-header-text {
      color: $primary-color-icam;
    }

    &__concept-menu-fixed-scroll {
      background: none;
      box-shadow: none;
    }

    &__contacts-import-icon {
      color: $custom-black-landing;
      opacity: 0.2;

      &--green {
          color: $green-task-50-icam;
      }

      &--red {
          color: $red-task-50-icam;
      }

      &--yellow {
          color: $yellow-task-50-icam;
      }
    }

    &__icon-ellipsis {
      color: $primary-color-icam;
    }

    &__icon-option-list-dropdown {
      color: $primary-color-icam;
    }

    &__icon-button-search {
      color: $primary-color-icam;
    }

    &__search-input {
      background-color: $hover-block-icam;
      border-color: $primary-color-icam;
      color: $primary-color-icam;

      &::-webkit-input-placeholder {
          color: $primary-color-icam;
      }
    }

    &__text-search {
      color: $primary-color-icam;
    }

    &__back-link {
        color: $primary-color-icam;
    }

    &__block-footer-total {
      color: $custom-black-landing;
    }

    &__block-concept {
      @include common-block-concept-color($border-block-icam);
    }

    &__block-option-list {
      border-color: $primary-color-icam;
      box-shadow: $box-shadow-icam;
    }

    &__box-text-info {
      color: $custom-black-landing;
    }

    ////PDF view////

    &__pdf-block-logo {
        width: 210px;
        height: 100%;
        margin: 0px;
        bottom: 14px;
    }

    &__pdf-block-header-modified {
        color: $text-field-color-icam;
    }

    &__pdf-title-block{
        background-color: $background-grey-disabled-icam;
        color: $custom-black-landing;
    }

    &__pdf-subtitle-block{
        background-color: $background-grey-disabled-icam;
        color: $modal-question-icam;
    }

    &__pdf-table-header {
        color: $primary-color-icam;
    }

    &__pdf-table-header-text {
        color: $primary-color-icam;
    }

    &__pdf-col{
        border-color: $primary-color-icam;
    }

    &__pdf-col-text {
        color: $custom-black-landing;
    }
    //////////Fin PDF//////////
  }
}

&__gm-law{
  .compliance__body{
    background-color: $background-grey-gm;
  }
  @include common-scrollbar($border-block-gm, $custom-black-landing);
  .mat-tooltip {
      background-color: $background-grey-disabled-gm;
      color: $custom-black-landing;

      &::after {
        border-bottom-color:$primary-color-gm;
      }

      &.below {
        border-top: 1px solid $primary-color-gm;
      }

      &.above {
        border-bottom: 1px solid $primary-color-gm;
      }

      &.right {
        border-left: 1px solid $primary-color-gm;
      }

      &.left {
        border-right: 1px solid $primary-color-gm;
      }
    }
  .tooltip {
      &[data-popper-placement^='bottom'] {
          .tooltip-arrow {
              &:before{
                border-bottom-color: $primary-color-gm;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-gm;
              color: $custom-black-landing;
              border-top-color: $primary-color-gm;
          }
      }
      &[data-popper-placement^='top'] {
          .tooltip-arrow {
              &:before{
                border-top-color: $primary-color-gm;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-gm;
              color: $custom-black-landing;
              border-bottom-color: $primary-color-gm;
          }
      }
      &[data-popper-placement^='left'] {
          .tooltip-arrow {
              &:before{
                border-left-color: $primary-color-gm;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-gm;
              color: $custom-black-landing;
              border-right-color: $primary-color-gm;
          }
      }
      &[data-popper-placement^='right'] {
          .tooltip-arrow {
              &:before{
                border-right-color: $primary-color-gm;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-gm;
              color: $custom-black-landing;
              border-left-color: $primary-color-gm;
          }
      }
      &>.tooltip-inner {
          background-color: $background-grey-disabled-gm;
          color: $custom-black-landing;
          border-bottom-color: $primary-color-gm;
      }
  }

  .uib-datepicker-popup.dropdown-menu{
      border: 1px solid $primary-color-gm;
      box-shadow: 0 0 5px 2px $certify-black-opacity;
  }

  .common{
      &__dropdown-selector-button{
        border: 1px solid $border-block-gm;
        &:hover:not([disabled]) {
          border: 1px solid $primary-color-gm;
          background: $hover-block-gm;
        }
        &[disabled] {
          background: $background-grey-disabled-gm;
        }
      }
      &__inline-warning {
        background: rgba($yellow-task-gm, 0.2);

        &__text {
          color: $custom-black-landing;
        }

        &__icon, &__close {
          color: $yellow-task-gm;
        }
      }
      &__label-selector-button{
        color: $text-field-color-gm;
      }
      &__underlined-title {
        color: $custom-black-landing;
        border-bottom-color: $custom-black-landing;
      }

      &__tooltip-dropdown {
        @include common-tooltip-dropdown-color($custom-black-landing, $background-grey-disabled-gm, rgba(0,0,0,0.3));
      }

      &__tooltip-alert-icon {
        color: $primary-color-gm;
      }

      &__title-block-modal {
        color: $secondary-color-gm;
      }

      &__text-required {
        color: $alert-color-gm !important;
      }

      &__text-button-underlined {
        color: $custom-black-landing;
        text-align: center;
        &--mrspan {
          margin-right: 5px;
        }
      }

      &__task-header {
        width: calc(100% - 40px);
        margin-left: 20px;
        padding-right: 0;
        padding-top: 11px;
      }

      &__switch-text-input {
        color: $custom-black-landing;
      }

      &__pagination-count {
          color: $modal-question-gm;
      }

      &__switch-icon {
        &--inactive {
          color: $secondary-color-gm;
        }

        &--active {
            color: $primary-color-gm;
        }

        &--active-subgroup {
            color: $snow-white;
        }
      }

      &__show-alert {
        @include common-show-alert-color($alert-color-gm, $red-task-20-gm, $snow-white);

        &--fixed {
            @include common-show-alert-color($alert-color-gm, $snow-white, $snow-white);
        }
      }

      &__select-option-icon-angle-down {
        color: $primary-color-gm;

        &--box {
            color: $primary-color-gm;
            &-disabled {
              color: $secondary-color-gm !important;
            }
        }
      }

      &__select-list-with-input {
        @include common-select-list-with-input($primary-color-gm);
        top: 29px !important;

        &--filter {
            @include common-select-list-with-input($primary-color-gm);
        }
      }

      &__select-all {
        @include common-select-item-color($custom-black-landing, $hover-block-gm);
      }

      &__select-item {
        @include common-select-item-color($custom-black-landing, $hover-block-gm);

        &--error {
            @include common-select-item-error($red-task-20-gm, $alert-color-gm);
        }
      }

      &__select-list {
        border-color: $primary-color-gm;

        &--custom {
            border-color: $border-block-gm;
        }
      }

      &__select-icon {
        color: $primary-color-gm;
      }

      &__select-arrow {
        color: $primary-color-gm;
      }

      &__search-icon {
        color: $primary-color-gm;
      }

      &__pill-button {
        &--green {
          background-color: $green-task-gm;
          border: 2px solid $green-task-gm;

          &:hover {
              cursor: pointer;
              background: rgba(119, 209, 102, 0.2);
              color: $green-task-gm;
          }

          &.common__pill-button--selected {
              border: 2px solid $green-task-gm;
              background-color: $green-task-gm;
              color: $snow-white;
          }
        }

        &--red {
            border: 2px solid $alert-color-gm;
            background-color: $snow-white;
            color: $alert-color-gm;

            &:hover {
                cursor: pointer;
                background-color: $background-error;
                color: $alert-color-gm;
            }

            &.common__pill-button--selected {
                border: 2px solid $alert-color-gm;
                background-color: $alert-color-gm;
                color: $snow-white;
            }
        }
      }

      &__order-by {
        color: $secondary-color-gm;

        &--active {
            color: $primary-color-gm;
        }
      }

      &__options-block {
        color: $snow-white;
      }

      &__option-box {
        background-color: $background-grey-disabled-gm;
      }

      &__multiselect-item {
        border: 1px solid $border-block-gm;
        background-color: transparent;

        &--no-name {
            color: $alert-color-gm;
        }
      }

      &__multiselect-text {
        color: $text-field-color-gm;
      }

      &__multiselect-select-icon {
        @include common-multiselect-select-icon($primary-color-gm, $hover-block-gm);
      }

      &__multiselect-delete-icon {
        &--simple {
          color: $primary-color-gm;
          font-size: 10px;
          top: 5px;
        }

        &--multiple {
            color: $primary-color-gm;
        }

        &--multiple-extended {
            color: $primary-color-gm;
        }
      }

      &__multiselect-dropdown-item {
        &--with-input {
          @include common-multiselect-dropdown-item-with-input($third-color-gm, $text-field-color-gm, $primary-color-gm);
        }
      }

      &__modal-header-block {
        background-color: $primary-color-gm;
        height: 70px;
        padding: 22px 23px;
      }

      &__menu-expanded-little {
        border-color: $primary-color-gm;

        &:after {
            border-color: $primary-color-gm;
        }
      }

      &__menu-expanded-little-option {
        @include common-background-hover($hover-block-gm);
      }

      &__menu-expanded-little-option-item {
        color: $primary-color-gm;
        border-color: $third-color-gm;
      }

      &__bubble-count {
        background-color: $primary-color-gm;

        &--disabled {
            background: $secondary-color-gm;
        }
      }

      &__list-dropdown-option {
        @include common-background-hover($hover-block-gm);

        &--selected {
            background-color: $hover-block-gm;
        }
      }

      &__input-text {
        color: $custom-black-landing;

        &--no-editable {
          color: $custom-black-landing;
          cursor: not-allowed;
        }

        &--required {
          color: $alert-color-gm;
        }

        &--secondary {
          color: $secondary-color-gm;
        }
      }

      &__input-text-placeholder {
        color: $custom-black-landing;
        font-family: MTTMilano, sans-serif;

        &--no-editable {
            color: $custom-black-landing !important;
            cursor: not-allowed !important;
        }
      }

      &__input-icon {
        color: $secondary-color-gm;
          opacity: 0.7;

          &[disabled] {
            color: $secondary-color-gm;
          }
      }

      &__input-icon-active {
        color: $primary-color-gm;
        opacity: 1;
        &--disabled {
          color: $secondary-color-gm;
        }
      }

      &__input-content {
        color: $text-field-color-gm;
      }

      &__input-content-alert {
        color: $alert-color-gm;

          &::placeholder {
              color: $alert-color-gm;
              font-style: italic;
          }
      }

      &__input-content-no-editable {
        color: $text-field-color-gm;
      }

      &__input-no-editable {
          border-color: $border-block-gm;
          background-color: $background-grey-disabled-gm;

          &:hover {
              border-color: $border-block-gm;
              background-color: $background-grey-disabled-gm;
          }
      }

      &__input-block {
        border-color: $border-block-gm;

        &:hover:not([disabled]) {
          border-color: $primary-color-gm;
          background-color: $hover-block-gm;

          .common__tooltip-alert-icon,
          .common__input-text, .common__delete-content-icon {
              color: $primary-color-gm;
          }
        }

        &--fixed {
          border-color: $border-block-gm;
          &:hover:not([disabled]) {
            border-color: $border-block-gm;
            background-color: initial;
          }
        }
      }

      &__input-block-open {
        @include common-input-block-open-color($hover-block-gm, $primary-color-gm);
      }

      &__header-textarea {
        border-color: $border-block-gm;
      }

      &__language-item {
        @include common-language-item($primary-color-gm, $hover-block-gm);
      }

      &__find-in-dropdown {
        @include common-find-in-dropdown-color($third-color-gm, $primary-color-gm);

        &--create-list {
          @include common-find-in-dropdown-create-list($primary-color-gm, $hover-block-gm, $text-field-color-gm);
        }
      }

      &__find-dropdown-icon-find {
          color: $primary-color-gm;
          top: 2px;
      }

      &__filter-icon {
          color: $secondary-color-gm;

          &--active {
              color: $primary-color-gm;
          }
      }

      &__error-button-list {
          border-color: $alert-color-gm;
          color: $alert-color-gm;
      }

      &__delete-content-icon {
          color: $primary-color-gm;
      }

      &__textarea-simple {
          border-color: $border-block-gm;
      }

      &__datepicker-col {
          color: $text-field-color-gm;
      }

      &__datepicker-header-tab {
          border-bottom: 1px solid $border-block-gm;
      }

      &__datepicker-title {
          color: $primary-color-gm;
      }

      // &__datepicker-button {
      //     color: $custom-black-landing;

      //     &[disabled] {
      //         cursor: default;
      //         color: $secondary-color-gm;
      //     }

      //     &:hover {
      //         background-color: $hover-block-gm;
      //         color: $custom-black-landing;
      //         outline: none;
      //     }

      //     &--current {
      //         border: 1px solid $primary-color-gm;
      //     }

      //     &--selected {
      //         background-color: $primary-color-gm;
      //     }
      // }

      &__checkbox {
          color: $primary-color-gm;

          &[disabled] {
              color: $secondary-color-gm;
          }
      }

    &__button {
      &--white{
          @include button-without-border($modal-question-gm, $snow-white, transparent);
          padding: 9px 29px;
      }
      &--white-red {
          @include button-without-border($alert-color-gm, $snow-white, transparent);
      }
      &--grey{
          @include button-without-border($modal-question-gm, $secondary-color-gm, transparent);
          padding: 9px 29px;
      }
      &--grey-red{
          @include button-without-border($alert-color-gm, $secondary-color-gm, transparent);
      }

      &--clean {
        @include common-button($secondary-color-gm, $snow-white);
      }

      &--active {
        @include common-button($primary-color-gm, $snow-white);
      }
    }

    &__button-border-none {
      color: $primary-color-gm;

      &:after {
          content: " ";
          border-bottom: 2px solid $primary-color-gm;
      }

      &:hover:not([disabled]) {
          background-color: $hover-block-gm;
      }

      &--error {
        color: $alert-color-gm;

        &:after {
            content: " ";
            border-bottom: 2px solid $alert-color-gm;
        }

        &:hover:not([disabled]) {
            background-color: $hover-block-gm;
        }
      }
    }

    &__button-box-icon {
      color: $primary-color-gm;
    }

    &__button-box-option {
      color: $custom-black-landing;
    }

    &__button-checked {
      border-width: 1px;
      @include common-button($primary-color-gm, $snow-white);
    }

    &__button-close {
      border-width: 1px;
      @include common-button($secondary-color-gm, $snow-white);
    }

    &__button-generic {
      color: $primary-color-gm;
    }

    &__button-option-list-dropdown {
      color: $primary-color-gm;
      border-color: $third-color-gm;
    }

    &__header-new-version{
      background-color: $primary-color-gm;
      color: $title-text-gm;
    }

    &__options-row {
      @include common-options-row($primary-color-gm, $snow-white, $primary-color-gm);
    }

    &__block-list-new-version {
      border: none;
      box-shadow: $box-shadow-gm;
      &--search{
          background-color: $background-grey-gm;
      }
    }

    &__header-button-add-new-version {
      color: $snow-white;

      &--process {
        color: $primary-color-gm;
      }
    }

    &__table-header {
      border-color: $primary-color-gm;
      color: $primary-color-gm;
    }

    &__table-row {
      @include common-table-row($border-block-gm, $hover-block-gm);

      &--selected-item {
        @include common-table-row-selected-item($hover-block-gm);
      }
    }

    &__col-text {
      color: $custom-black-landing;

      &--disabled {
          color: $background-grey-disabled-gm;
      }

      &--url {
          color: $primary-color-gm;
      }
    }

    &__table-header-text {
      color: $primary-color-gm;
    }

    &__concept-menu-fixed-scroll {
      background: none;
      box-shadow: none;
    }

    &__contacts-import-icon {
      color: $custom-black-landing;
      opacity: 0.2;

      &--green {
          color: $green-task-50-gm;
      }

      &--red {
          color: $red-task-50-gm;
      }

      &--yellow {
          color: $yellow-task-50-gm;
      }
    }

    // &__icon-button {
    //   width: 100%;
    //   height: 18px;
    //   text-align: right;
    // }

    &__icon-button-search {
      color: $primary-color-gm;
    }

    &__icon-ellipsis {
      color: $primary-color-gm;
    }

    &__icon-option-list-dropdown {
      color: $primary-color-gm;
    }

    &__search-input {
      background-color: $hover-block-gm;
      border-color: $primary-color-gm;
      color: $primary-color-gm;

      &::-webkit-input-placeholder {
          color: $primary-color-gm;
      }
    }

    &__text-search {
      color: $primary-color-gm;
    }

    &__back-link {
        color: $primary-color-gm;
    }

    &__block-footer-total {
      color: $custom-black-landing;
    }

    &__block-concept {
      @include common-block-concept-color($border-block-gm);
    }

    &__block-option-list {
      border-color: $primary-color-gm;
      box-shadow: $box-shadow-gm;
    }

    &__box-text-info {
      color: $custom-black-landing;
    }

    ////PDF view////
    &__pdf-block-header-modified {
        color: $text-field-color-gm;
    }

    &__pdf-title-block{
        background-color: $background-grey-disabled-gm;
        color: $custom-black-landing;
    }

    &__pdf-subtitle-block{
        background-color: $background-grey-disabled-gm;
        color: $modal-question-gm;
    }

    &__pdf-table-header {
        color: $primary-color-gm;
    }

    &__pdf-table-header-text {
        color: $primary-color-gm;
    }

    &__pdf-col{
        border-color: $primary-color-gm;
    }

    &__pdf-col-text {
        color: $custom-black-landing;
    }
    //////////Fin PDF//////////
  }
}
&__com-de{
  .compliance__body{
    background-color: $background-grey-com-de;
  }
  @include common-scrollbar($border-block-com-de, $custom-black-landing);
  .mat-tooltip {
      background-color: $background-grey-disabled-com-de;
      color: $custom-black-landing;

      &::after {
        border-bottom-color:$primary-color-com-de;
      }

      &.below {
        border-top: 1px solid $primary-color-com-de;
      }

      &.above {
        border-bottom: 1px solid $primary-color-com-de;
      }

      &.right {
        border-left: 1px solid $primary-color-com-de;
      }

      &.left {
        border-right: 1px solid $primary-color-com-de;
      }
    }
  .tooltip {
      &[data-popper-placement^='bottom'] {
          .tooltip-arrow {
              &:before{
                border-bottom-color: $primary-color-com-de;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-com-de;
              color: $custom-black-landing;
              border-top-color: $primary-color-com-de;
          }
      }
      &[data-popper-placement^='top'] {
          .tooltip-arrow {
              &:before{
                border-top-color: $primary-color-com-de;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-com-de;
              color: $custom-black-landing;
              border-bottom-color: $primary-color-com-de;
          }
      }
      &[data-popper-placement^='left'] {
          .tooltip-arrow {
              &:before{
                border-left-color: $primary-color-com-de;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-com-de;
              color: $custom-black-landing;
              border-right-color: $primary-color-com-de;
          }
      }
      &[data-popper-placement^='right'] {
          .tooltip-arrow {
              &:before{
                border-right-color: $primary-color-com-de;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-com-de;
              color: $custom-black-landing;
              border-left-color: $primary-color-com-de;
          }
      }
      &>.tooltip-inner {
          background-color: $background-grey-disabled-com-de;
          color: $custom-black-landing;
          border-bottom-color: $primary-color-com-de;
      }
  }

  .uib-datepicker-popup.dropdown-menu{
      border: 1px solid $primary-color-com-de;
      box-shadow: 0 0 5px 2px $certify-black-opacity;
  }

  .common{
      &__inline-warning {
        background: rgba($yellow-task-com-de, 0.2);

        &__text {
          color: $custom-black-landing;
        }

        &__icon, &__close {
          color: $yellow-task-com-de;
        }
      }

      &__underlined-title {
        color: $custom-black-landing;
        border-bottom-color: $custom-black-landing;
      }

      &__tooltip-dropdown {
        @include common-tooltip-dropdown-color($custom-black-landing, $background-grey-disabled-com-de, rgba(0,0,0,0.3));
      }

      &__tooltip-alert-icon {
        color: $primary-color-com-de;
      }

      &__title-block-modal {
        color: $secondary-color-com-de;
      }

      &__text-required {
        color: $alert-color-com-de !important;
      }

      &__text-button-underlined {
        color: $custom-black-landing;
        text-align: center;
        &--mrspan {
          margin-right: 5px;
        }
      }

      &__task-header {
        width: calc(100% - 40px);
        margin-left: 20px;
        padding-right: 0;
        padding-top: 11px;
      }

      &__switch-text-input {
        color: $custom-black-landing;
      }

      &__pagination-count {
          color: $modal-question-com-de;
      }

      &__switch-icon {
        &--inactive {
          color: $secondary-color-com-de;
        }

        &--active {
            color: $primary-color-com-de;
        }

        &--active-subgroup {
            color: $snow-white;
        }
      }

      &__show-alert {
        @include common-show-alert-color($alert-color-com-de, $red-task-20-com-de, $snow-white);

        &--fixed {
            @include common-show-alert-color($alert-color-com-de, $snow-white, $snow-white);
        }
      }

      &__select-option-icon-angle-down {
        color: $primary-color-com-de;

        &--box {
            color: $snow-white;
        }
      }

      &__select-list-with-input {
        @include common-select-list-with-input($primary-color-com-de);
        top: 29px !important;

        &--filter {
            @include common-select-list-with-input($primary-color-com-de);
        }
      }

      &__select-all {
        @include common-select-item-color($custom-black-landing, $hover-block-com-de);
      }

      &__select-item {
        @include common-select-item-color($custom-black-landing, $hover-block-com-de);

        &--error {
            @include common-select-item-error($red-task-20-com-de, $alert-color-com-de);
        }
      }

      &__select-list {
        border-color: $primary-color-com-de;

        &--custom {
            border-color: $border-block-com-de;
        }
      }

      &__select-icon {
        color: $primary-color-com-de;
      }

      &__select-arrow {
        color: $primary-color-com-de;
      }

      &__search-icon {
        color: $primary-color-com-de;
      }

      &__pill-button {
        &--green {
          background-color: $green-task-com-de;
          border: 2px solid $green-task-com-de;

          &:hover {
              cursor: pointer;
              background: rgba(119, 209, 102, 0.2);
              color: $green-task-com-de;
          }

          &.common__pill-button--selected {
              border: 2px solid $green-task-com-de;
              background-color: $green-task-com-de;
              color: $snow-white;
          }
        }

        &--red {
            border: 2px solid $alert-color-com-de;
            background-color: $snow-white;
            color: $alert-color-com-de;

            &:hover {
                cursor: pointer;
                background-color: $background-error;
                color: $alert-color-com-de;
            }

            &.common__pill-button--selected {
                border: 2px solid $alert-color-com-de;
                background-color: $alert-color-com-de;
                color: $snow-white;
            }
        }
      }

      &__order-by {
        color: $secondary-color-com-de;

        &--active {
            color: $primary-color-com-de;
        }
      }

      &__options-block {
        color: $snow-white;
      }

      &__option-box {
        background-color: $background-grey-disabled-com-de;
      }

      &__multiselect-item {
        border: 1px solid $border-block-com-de;
        background-color: transparent;

        &--no-name {
            color: $alert-color-com-de;
        }
      }

      &__multiselect-text {
        color: $text-field-color-com-de;
      }

      &__multiselect-select-icon {
        @include common-multiselect-select-icon($primary-color-com-de, $hover-block-com-de);
      }

      &__multiselect-delete-icon {
        &--simple {
          color: $primary-color-com-de;
          font-size: 10px;
          top: 5px;
        }

        &--multiple {
            color: $primary-color-com-de;
        }

        &--multiple-extended {
            color: $primary-color-com-de;
        }
      }

      &__multiselect-dropdown-item {
        &--with-input {
          @include common-multiselect-dropdown-item-with-input($third-color-com-de, $text-field-color-com-de, $primary-color-com-de);
        }
      }

      &__modal-header-block {
        background-color: $primary-color-com-de;
        height: 70px;
        padding: 22px 23px;
      }

      &__menu-expanded-little {
        border-color: $primary-color-com-de;

        &:after {
            border-color: $primary-color-com-de;
        }
      }

      &__menu-expanded-little-option {
        @include common-background-hover($hover-block-com-de);
      }

      &__menu-expanded-little-option-item {
        color: $primary-color-com-de;
        border-color: $third-color-com-de;
      }

      &__bubble-count {
        background-color: $primary-color-com-de;

        &--disabled {
            background: $secondary-color-com-de;
        }
      }

      &__list-dropdown-option {
        @include common-background-hover($hover-block-com-de);

        &--selected {
            background-color: $hover-block-com-de;
        }
      }

      &__input-text {
        color: $custom-black-landing;

        &--no-editable {
          color: $custom-black-landing;
          cursor: not-allowed;
        }

        &--required {
          color: $alert-color-com-de;
        }

        &--secondary {
          color: $secondary-color-com-de;
        }
      }

      &__input-text-placeholder {
        color: $custom-black-landing;
        font-family: MTTMilano, sans-serif;

        &--no-editable {
            color: $custom-black-landing !important;
            cursor: not-allowed !important;
        }
      }

      &__input-icon {
        color: $secondary-color-com-de;
          opacity: 0.7;

          &[disabled] {
            color: $secondary-color-com-de;
          }
      }

      &__input-icon-active {
        color: $primary-color-com-de;
        opacity: 1;
        &--disabled {
          color: $secondary-color-com-de;
        }
      }

      &__input-content {
        color: $text-field-color-com-de;
      }

      &__input-content-alert {
        color: $alert-color-com-de;

          &::placeholder {
              color: $alert-color-com-de;
              font-style: italic;
          }
      }

      &__input-content-no-editable {
        color: $text-field-color-com-de;
      }

      &__input-no-editable {
          border-color: $border-block-com-de;
          background-color: $background-grey-disabled-com-de;

          &:hover {
              border-color: $border-block-com-de;
              background-color: $background-grey-disabled-com-de;
          }
      }

      &__input-block {
        border-color: $border-block-com-de;

        &:hover:not([disabled]) {
          border-color: $primary-color-com-de;
          background-color: $hover-block-com-de;

          .common__tooltip-alert-icon,
          .common__input-text, .common__delete-content-icon {
              color: $primary-color-com-de;
          }
        }

        &--fixed {
          border-color: $border-block-com-de;
          &:hover:not([disabled]) {
            border-color: $border-block-com-de;
            background-color: initial;
          }
        }
      }

      &__input-block-open {
        @include common-input-block-open-color($hover-block-com-de, $primary-color-com-de);
      }

      &__header-textarea {
        border-color: $border-block-com-de;
      }

      &__language-item {
        @include common-language-item($primary-color-com-de, $hover-block-com-de);
      }

      &__find-in-dropdown {
        @include common-find-in-dropdown-color($third-color-com-de, $primary-color-com-de);

        &--create-list {
          @include common-find-in-dropdown-create-list($primary-color-com-de, $hover-block-com-de, $text-field-color-com-de);
        }
      }

      &__find-dropdown-icon-find {
          color: $primary-color-com-de;
          top: 2px;
      }

      &__filter-icon {
          color: $secondary-color-com-de;

          &--active {
              color: $primary-color-com-de;
          }
      }

      &__error-button-list {
          border-color: $alert-color-com-de;
          color: $alert-color-com-de;
      }

      &__delete-content-icon {
          color: $primary-color-com-de;
      }

      &__textarea-simple {
          border-color: $border-block-com-de;
      }

      &__datepicker-col {
          color: $text-field-color-com-de;
      }

      &__datepicker-header-tab {
          border-bottom: 1px solid $border-block-com-de;
      }

      &__datepicker-title {
          color: $primary-color-com-de;
      }

      // &__datepicker-button {
      //     color: $custom-black-landing;

      //     &[disabled] {
      //         cursor: default;
      //         color: $secondary-color-com-de;
      //     }

      //     &:hover {
      //         background-color: $hover-block-com-de;
      //         color: $custom-black-landing;
      //         outline: none;
      //     }

      //     &--current {
      //         border: 1px solid $primary-color-com-de;
      //     }

      //     &--selected {
      //         background-color: $primary-color-com-de;
      //     }
      // }

      &__checkbox {
          color: $primary-color-com-de;

          &[disabled] {
              color: $secondary-color-com-de;
          }
      }

    &__button {
      &--white{
          @include button-without-border($modal-question-com-de, $snow-white, transparent);
          padding: 9px 29px;
      }
      &--white-red {
          @include button-without-border($alert-color-com-de, $snow-white, transparent);
      }
      &--grey{
          @include button-without-border($modal-question-com-de, $secondary-color-com-de, transparent);
          padding: 9px 29px;
      }
      &--grey-red{
          @include button-without-border($alert-color-com-de, $secondary-color-com-de, transparent);
      }

      &--clean {
        @include common-button($secondary-color-com-de, $snow-white);
      }

      &--active {
        @include common-button($primary-color-com-de, $snow-white);
      }
    }

    &__button-border-none {
      color: $primary-color-com-de;

      &:after {
          content: " ";
          border-bottom: 2px solid $primary-color-com-de;
      }

      &:hover:not([disabled]) {
          background-color: $hover-block-com-de;
      }

      &--error {
        color: $alert-color-com-de;

        &:after {
            content: " ";
            border-bottom: 2px solid $alert-color-com-de;
        }

        &:hover:not([disabled]) {
            background-color: $hover-block-com-de;
        }
      }
    }

    &__button-box-icon {
      color: $primary-color-com-de;
    }

    &__button-box-option {
      color: $custom-black-landing;
    }

    &__button-checked {
      border-width: 1px;
      @include common-button($primary-color-com-de, $snow-white);
    }

    &__button-close {
      border-width: 1px;
      @include common-button($secondary-color-com-de, $snow-white);
    }

    &__button-generic {
      color: $primary-color-com-de;
    }

    &__button-option-list-dropdown {
      color: $primary-color-com-de;
      border-color: $third-color-com-de;
    }

    &__header-new-version{
      background-color: $primary-color-com-de;
      color: $title-text-com-de;
    }

    &__options-row {
      @include common-options-row($primary-color-com-de, $snow-white, $primary-color-com-de);
    }

    &__block-list-new-version {
      border: none;
      box-shadow: $box-shadow-com-de;
      &--search{
          background-color: $background-grey-com-de;
      }
    }

    &__header-button-add-new-version {
      color: $snow-white;

      &--process {
        color: $primary-color-com-de;
      }
    }

    &__table-header {
      border-color: $primary-color-com-de;
      color: $primary-color-com-de;
    }

    &__table-row {
      @include common-table-row($border-block-com-de, $hover-block-com-de);

      &--selected-item {
        @include common-table-row-selected-item($hover-block-com-de);
      }
    }

    &__col-text {
      color: $custom-black-landing;

      &--disabled {
          color: $background-grey-disabled-com-de;
      }

      &--url {
          color: $primary-color-com-de;
      }
    }

    &__table-header-text {
      color: $primary-color-com-de;
    }

    &__concept-menu-fixed-scroll {
      background: none;
      box-shadow: none;
    }

    &__contacts-import-icon {
      color: $custom-black-landing;
      opacity: 0.2;

      &--green {
          color: $green-task-50-com-de;
      }

      &--red {
          color: $red-task-50-com-de;
      }

      &--yellow {
          color: $yellow-task-50-com-de;
      }
    }

    // &__icon-button {
    //   width: 100%;
    //   height: 18px;
    //   text-align: right;
    // }

    &__icon-button-search {
      color: $primary-color-com-de;
    }

    &__icon-ellipsis {
      color: $primary-color-com-de;
    }

    &__icon-option-list-dropdown {
      color: $primary-color-com-de;
    }

    &__search-input {
      background-color: $hover-block-com-de;
      border-color: $primary-color-com-de;
      color: $primary-color-com-de;

      &::-webkit-input-placeholder {
          color: $primary-color-com-de;
      }
    }

    &__text-search {
      color: $primary-color-com-de;
    }

    &__back-link {
        color: $primary-color-com-de;
    }

    &__block-footer-total {
      color: $custom-black-landing;
    }

    &__block-concept {
      @include common-block-concept-color($border-block-com-de);
    }

    &__block-option-list {
      border-color: $primary-color-com-de;
      box-shadow: $box-shadow-com-de;
    }

    &__box-text-info {
      color: $custom-black-landing;
    }

    ////PDF view////
    &__pdf-block-header-modified {
        color: $text-field-color-com-de;
    }

    &__pdf-title-block{
        background-color: $background-grey-disabled-com-de;
        color: $custom-black-landing;
    }

    &__pdf-subtitle-block{
        background-color: $background-grey-disabled-com-de;
        color: $modal-question-com-de;
    }

    &__pdf-table-header {
        color: $primary-color-com-de;
    }

    &__pdf-table-header-text {
        color: $primary-color-com-de;
    }

    &__pdf-col{
        border-color: $primary-color-com-de;
    }

    &__pdf-col-text {
        color: $custom-black-landing;
    }
    //////////Fin PDF//////////
  }
}
&__com-fr{
  .compliance__body{
    background-color: $background-grey-com-fr;
  }
  @include common-scrollbar($border-block-com-fr, $custom-black-landing);
  .mat-tooltip {
      background-color: $background-grey-disabled-com-fr;
      color: $custom-black-landing;

      &::after {
        border-bottom-color:$primary-color-com-fr;
      }

      &.below {
        border-top: 1px solid $primary-color-com-fr;
      }

      &.above {
        border-bottom: 1px solid $primary-color-com-fr;
      }

      &.right {
        border-left: 1px solid $primary-color-com-fr;
      }

      &.left {
        border-right: 1px solid $primary-color-com-fr;
      }
    }
  .tooltip {
      &[data-popper-placement^='bottom'] {
          .tooltip-arrow {
              &:before{
                border-bottom-color: $primary-color-com-fr;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-com-fr;
              color: $custom-black-landing;
              border-top-color: $primary-color-com-fr;
          }
      }
      &[data-popper-placement^='top'] {
          .tooltip-arrow {
              &:before{
                border-top-color: $primary-color-com-fr;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-com-fr;
              color: $custom-black-landing;
              border-bottom-color: $primary-color-com-fr;
          }
      }
      &[data-popper-placement^='left'] {
          .tooltip-arrow {
              &:before{
                border-left-color: $primary-color-com-fr;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-com-fr;
              color: $custom-black-landing;
              border-right-color: $primary-color-com-fr;
          }
      }
      &[data-popper-placement^='right'] {
          .tooltip-arrow {
              &:before{
                border-right-color: $primary-color-com-fr;
              }
          }
          &>.tooltip-inner {
              background-color: $background-grey-disabled-com-fr;
              color: $custom-black-landing;
              border-left-color: $primary-color-com-fr;
          }
      }
      &>.tooltip-inner {
          background-color: $background-grey-disabled-com-fr;
          color: $custom-black-landing;
          border-bottom-color: $primary-color-com-fr;
      }
  }

  .uib-datepicker-popup.dropdown-menu{
      border: 1px solid $primary-color-com-fr;
      box-shadow: 0 0 5px 2px $certify-black-opacity;
  }

  .common{
      &__inline-warning {
        background: rgba($yellow-task-com-fr, 0.2);

        &__text {
          color: $custom-black-landing;
        }

        &__icon, &__close {
          color: $yellow-task-com-fr;
        }
      }

      &__underlined-title {
        color: $custom-black-landing;
        border-bottom-color: $custom-black-landing;
      }

      &__tooltip-dropdown {
        @include common-tooltip-dropdown-color($custom-black-landing, $background-grey-disabled-com-fr, rgba(0,0,0,0.3));
      }

      &__tooltip-alert-icon {
        color: $primary-color-com-fr;
      }

      &__title-block-modal {
        color: $secondary-color-com-fr;
      }

      &__text-required {
        color: $alert-color-com-fr !important;
      }

      &__text-button-underlined {
        color: $custom-black-landing;
        text-align: center;
        &--mrspan {
          margin-right: 5px;
        }
      }

      &__task-header {
        width: calc(100% - 40px);
        margin-left: 20px;
        padding-right: 0;
        padding-top: 11px;
      }

      &__switch-text-input {
        color: $custom-black-landing;
      }

      &__pagination-count {
          color: $modal-question-com-fr;
      }

      &__switch-icon {
        &--inactive {
          color: $secondary-color-com-fr;
        }

        &--active {
            color: $primary-color-com-fr;
        }

        &--active-subgroup {
            color: $snow-white;
        }
      }

      &__show-alert {
        @include common-show-alert-color($alert-color-com-fr, $red-task-20-com-fr, $snow-white);

        &--fixed {
            @include common-show-alert-color($alert-color-com-fr, $snow-white, $snow-white);
        }
      }

      &__select-option-icon-angle-down {
        color: $primary-color-com-fr;

        &--box {
            color: $snow-white;
        }
      }

      &__select-list-with-input {
        @include common-select-list-with-input($primary-color-com-fr);
        top: 29px !important;

        &--filter {
            @include common-select-list-with-input($primary-color-com-fr);
        }
      }

      &__select-all {
        @include common-select-item-color($custom-black-landing, $hover-block-com-fr);
      }

      &__select-item {
        @include common-select-item-color($custom-black-landing, $hover-block-com-fr);

        &--error {
            @include common-select-item-error($red-task-20-com-fr, $alert-color-com-fr);
        }
      }

      &__select-list {
        border-color: $primary-color-com-fr;

        &--custom {
            border-color: $border-block-com-fr;
        }
      }

      &__select-icon {
        color: $primary-color-com-fr;
      }

      &__select-arrow {
        color: $primary-color-com-fr;
      }

      &__search-icon {
        color: $primary-color-com-fr;
      }

      &__pill-button {
        &--green {
          background-color: $green-task-com-fr;
          border: 2px solid $green-task-com-fr;

          &:hover {
              cursor: pointer;
              background: rgba(119, 209, 102, 0.2);
              color: $green-task-com-fr;
          }

          &.common__pill-button--selected {
              border: 2px solid $green-task-com-fr;
              background-color: $green-task-com-fr;
              color: $snow-white;
          }
        }

        &--red {
            border: 2px solid $alert-color-com-fr;
            background-color: $snow-white;
            color: $alert-color-com-fr;

            &:hover {
                cursor: pointer;
                background-color: $background-error;
                color: $alert-color-com-fr;
            }

            &.common__pill-button--selected {
                border: 2px solid $alert-color-com-fr;
                background-color: $alert-color-com-fr;
                color: $snow-white;
            }
        }
      }

      &__order-by {
        color: $secondary-color-com-fr;

        &--active {
            color: $primary-color-com-fr;
        }
      }

      &__options-block {
        color: $snow-white;
      }

      &__option-box {
        background-color: $background-grey-disabled-com-fr;
      }

      &__multiselect-item {
        border: 1px solid $border-block-com-fr;
        background-color: transparent;

        &--no-name {
            color: $alert-color-com-fr;
        }
      }

      &__multiselect-text {
        color: $text-field-color-com-fr;
      }

      &__multiselect-select-icon {
        @include common-multiselect-select-icon($primary-color-com-fr, $hover-block-com-fr);
      }

      &__multiselect-delete-icon {
        &--simple {
          color: $primary-color-com-fr;
          font-size: 10px;
          top: 5px;
        }

        &--multiple {
            color: $primary-color-com-fr;
        }

        &--multiple-extended {
            color: $primary-color-com-fr;
        }
      }

      &__multiselect-dropdown-item {
        &--with-input {
          @include common-multiselect-dropdown-item-with-input($third-color-com-fr, $text-field-color-com-fr, $primary-color-com-fr);
        }
      }

      &__modal-header-block {
        background-color: $primary-color-com-fr;
        height: 70px;
        padding: 22px 23px;
      }

      &__menu-expanded-little {
        border-color: $primary-color-com-fr;

        &:after {
            border-color: $primary-color-com-fr;
        }
      }

      &__menu-expanded-little-option {
        @include common-background-hover($hover-block-com-fr);
      }

      &__menu-expanded-little-option-item {
        color: $primary-color-com-fr;
        border-color: $third-color-com-fr;
      }

      &__bubble-count {
        background-color: $primary-color-com-fr;

        &--disabled {
            background: $secondary-color-com-fr;
        }
      }

      &__list-dropdown-option {
        @include common-background-hover($hover-block-com-fr);

        &--selected {
            background-color: $hover-block-com-fr;
        }
      }

      &__input-text {
        color: $custom-black-landing;

        &--no-editable {
          color: $custom-black-landing;
          cursor: not-allowed;
        }

        &--required {
          color: $alert-color-com-fr;
        }

        &--secondary {
          color: $secondary-color-com-fr;
        }
      }

      &__input-text-placeholder {
        color: $custom-black-landing;
        font-family: MTTMilano, sans-serif;

        &--no-editable {
            color: $custom-black-landing !important;
            cursor: not-allowed !important;
        }
      }

      &__input-icon {
        color: $secondary-color-com-fr;
          opacity: 0.7;

          &[disabled] {
            color: $secondary-color-com-fr;
          }
      }

      &__input-icon-active {
        color: $primary-color-com-fr;
        opacity: 1;
        &--disabled {
          color: $secondary-color-com-fr;
        }
      }

      &__input-content {
        color: $text-field-color-com-fr;
      }

      &__input-content-alert {
        color: $alert-color-com-fr;

          &::placeholder {
              color: $alert-color-com-fr;
              font-style: italic;
          }
      }

      &__input-content-no-editable {
        color: $text-field-color-com-fr;
      }

      &__input-no-editable {
          border-color: $border-block-com-fr;
          background-color: $background-grey-disabled-com-fr;

          &:hover {
              border-color: $border-block-com-fr;
              background-color: $background-grey-disabled-com-fr;
          }
      }

      &__input-block {
        border-color: $border-block-com-fr;

        &:hover:not([disabled]) {
          border-color: $primary-color-com-fr;
          background-color: $hover-block-com-fr;

          .common__tooltip-alert-icon,
          .common__input-text, .common__delete-content-icon {
              color: $primary-color-com-fr;
          }
        }

        &--fixed {
          border-color: $border-block-com-fr;
          &:hover:not([disabled]) {
            border-color: $border-block-com-fr;
            background-color: initial;
          }
        }
      }

      &__input-block-open {
        @include common-input-block-open-color($hover-block-com-fr, $primary-color-com-fr);
      }

      &__header-textarea {
        border-color: $border-block-com-fr;
      }

      &__language-item {
        @include common-language-item($primary-color-com-fr, $hover-block-com-fr);
      }

      &__find-in-dropdown {
        @include common-find-in-dropdown-color($third-color-com-fr, $primary-color-com-fr);

        &--create-list {
          @include common-find-in-dropdown-create-list($primary-color-com-fr, $hover-block-com-fr, $text-field-color-com-fr);
        }
      }

      &__find-dropdown-icon-find {
          color: $primary-color-com-fr;
          top: 2px;
      }

      &__filter-icon {
          color: $secondary-color-com-fr;

          &--active {
              color: $primary-color-com-fr;
          }
      }

      &__error-button-list {
          border-color: $alert-color-com-fr;
          color: $alert-color-com-fr;
      }

      &__delete-content-icon {
          color: $primary-color-com-fr;
      }

      &__textarea-simple {
          border-color: $border-block-com-fr;
      }

      &__datepicker-col {
          color: $text-field-color-com-fr;
      }

      &__datepicker-header-tab {
          border-bottom: 1px solid $border-block-com-fr;
      }

      &__datepicker-title {
          color: $primary-color-com-fr;
      }

      // &__datepicker-button {
      //     color: $custom-black-landing;

      //     &[disabled] {
      //         cursor: default;
      //         color: $secondary-color-com-fr;
      //     }

      //     &:hover {
      //         background-color: $hover-block-com-fr;
      //         color: $custom-black-landing;
      //         outline: none;
      //     }

      //     &--current {
      //         border: 1px solid $primary-color-com-fr;
      //     }

      //     &--selected {
      //         background-color: $primary-color-com-fr;
      //     }
      // }

      &__checkbox {
          color: $primary-color-com-fr;

          &[disabled] {
              color: $secondary-color-com-fr;
          }
      }

    &__button {
      &--white{
          @include button-without-border($modal-question-com-fr, $snow-white, transparent);
          padding: 9px 29px;
      }
      &--white-red {
          @include button-without-border($alert-color-com-fr, $snow-white, transparent);
      }
      &--grey{
          @include button-without-border($modal-question-com-fr, $secondary-color-com-fr, transparent);
          padding: 9px 29px;
      }
      &--grey-red{
          @include button-without-border($alert-color-com-fr, $secondary-color-com-fr, transparent);
      }

      &--clean {
        @include common-button($secondary-color-com-fr, $snow-white);
      }

      &--active {
        @include common-button($primary-color-com-fr, $snow-white);
      }
    }

    &__button-border-none {
      color: $primary-color-com-fr;

      &:after {
          content: " ";
          border-bottom: 2px solid $primary-color-com-fr;
      }

      &:hover:not([disabled]) {
          background-color: $hover-block-com-fr;
      }

      &--error {
        color: $alert-color-com-fr;

        &:after {
            content: " ";
            border-bottom: 2px solid $alert-color-com-fr;
        }

        &:hover:not([disabled]) {
            background-color: $hover-block-com-fr;
        }
      }
    }

    &__button-box-icon {
      color: $primary-color-com-fr;
    }

    &__button-box-option {
      color: $custom-black-landing;
    }

    &__button-checked {
      border-width: 1px;
      @include common-button($primary-color-com-fr, $snow-white);
    }

    &__button-close {
      border-width: 1px;
      @include common-button($secondary-color-com-fr, $snow-white);
    }

    &__button-generic {
      color: $primary-color-com-fr;
    }

    &__button-option-list-dropdown {
      color: $primary-color-com-fr;
      border-color: $third-color-com-fr;
    }

    &__header-new-version{
      background-color: $primary-color-com-fr;
      color: $title-text-com-fr;
    }

    &__options-row {
      @include common-options-row($primary-color-com-fr, $snow-white, $primary-color-com-fr);
    }

    &__block-list-new-version {
      border: none;
      box-shadow: $box-shadow-com-fr;
      &--search{
          background-color: $background-grey-com-fr;
      }
    }

    &__header-button-add-new-version {
      color: $snow-white;

      &--process {
        color: $primary-color-com-fr;
      }
    }

    &__table-header {
      border-color: $primary-color-com-fr;
      color: $primary-color-com-fr;
    }

    &__table-row {
      @include common-table-row($border-block-com-fr, $hover-block-com-fr);

      &--selected-item {
        @include common-table-row-selected-item($hover-block-com-fr);
      }
    }

    &__col-text {
      color: $custom-black-landing;

      &--disabled {
          color: $background-grey-disabled-com-fr;
      }

      &--url {
          color: $primary-color-com-fr;
      }
    }

    &__table-header-text {
      color: $primary-color-com-fr;
    }

    &__concept-menu-fixed-scroll {
      background: none;
      box-shadow: none;
    }

    &__contacts-import-icon {
      color: $custom-black-landing;
      opacity: 0.2;

      &--green {
          color: $green-task-50-com-fr;
      }

      &--red {
          color: $red-task-50-com-fr;
      }

      &--yellow {
          color: $yellow-task-50-com-fr;
      }
    }

    // &__icon-button {
    //   width: 100%;
    //   height: 18px;
    //   text-align: right;
    // }

    &__icon-button-search {
      color: $primary-color-com-fr;
    }

    &__icon-ellipsis {
      color: $primary-color-com-fr;
    }

    &__icon-option-list-dropdown {
      color: $primary-color-com-fr;
    }

    &__search-input {
      background-color: $hover-block-com-fr;
      border-color: $primary-color-com-fr;
      color: $primary-color-com-fr;

      &::-webkit-input-placeholder {
          color: $primary-color-com-fr;
      }
    }

    &__text-search {
      color: $primary-color-com-fr;
    }

    &__back-link {
        color: $primary-color-com-fr;
    }

    &__block-footer-total {
      color: $custom-black-landing;
    }

    &__block-concept {
      @include common-block-concept-color($border-block-com-fr);
    }

    &__block-option-list {
      border-color: $primary-color-com-fr;
      box-shadow: $box-shadow-com-fr;
    }

    &__box-text-info {
      color: $custom-black-landing;
    }

    ////PDF view////
    &__pdf-block-header-modified {
        color: $text-field-color-com-fr;
    }

    &__pdf-title-block{
        background-color: $background-grey-disabled-com-fr;
        color: $custom-black-landing;
    }

    &__pdf-subtitle-block{
        background-color: $background-grey-disabled-com-fr;
        color: $modal-question-com-fr;
    }

    &__pdf-table-header {
        color: $primary-color-com-fr;
    }

    &__pdf-table-header-text {
        color: $primary-color-com-fr;
    }

    &__pdf-col{
        border-color: $primary-color-com-fr;
    }

    &__pdf-col-text {
        color: $custom-black-landing;
    }
    //////////Fin PDF//////////
  }
}
&__landing-custom {
  @include common-scrollbar($custom-border-grey, $attachment-custom-grey-other);

  .tooltip {
      &[data-popper-placement^='top']{
          .tooltip-arrow {
              // left: calc(50% - 5px);
              &:before{
                border-top-color: $custom-secondary-color;
              }
              &:after {
                  content: '';
                  border-top: 5px solid $snow-white !important;
                  border-right: 5px solid transparent !important;
                  border-bottom: 0px solid transparent !important;
                  border-left: 5px solid transparent !important;
                  left: calc(50% - 5px) !important;
                  position: absolute !important;
                  top: -1px !important;
                  // bottom: 2px !important;
              }
          }
      }
      &[data-popper-placement^='bottom'] {
          .tooltip-arrow {
              // left: calc(50% - 5px);
              &:before{
                border-bottom-color: $custom-secondary-color;
              }
              &:after {
                  content: '';
                  border-top: 0px solid transparent !important;
                  border-right: 5px solid transparent !important;
                  border-bottom: 5px solid $snow-white !important;
                  border-left: 5px solid transparent !important;
                  left: calc(50% - 5px) !important;
                  position: absolute !important;
                  bottom: -1px !important;
              }
          }
      }
      &[data-popper-placement^='left'] {
          .tooltip-arrow {
              // top: 50%;
              &:before{
                border-left-color: $custom-secondary-color;
              }
              &:after {
                  content: '';
                  border-top: 5px solid transparent !important;
                  border-right: 0px solid transparent !important;
                  border-bottom: 5px solid transparent !important;
                  border-left: 5px solid $snow-white !important;
                  left: inherit;
                  top: calc(50% - 5px);
                  position: absolute !important;
                  right: 2px !important;
              }
          }
      }
      &[data-popper-placement^='right'] {
          .tooltip-arrow {
              // top: 50%;
              &:before{
                border-right-color: $custom-secondary-color;
              }
              &:after {
                  content: '';
                  border-top: 5px solid transparent !important;
                  border-right: 5px solid $snow-white !important;
                  border-bottom: 5px solid transparent !important;
                  border-left: 0px solid transparent !important;
                  top: calc(50% - 5px);
                  position: absolute !important;
                  left: 2px !important;
              }
          }
      }
      .tooltip-inner {
          border: 1px solid $custom-secondary-color !important;
          background-color: $snow-white !important;
          box-shadow: 0 0 5px 2px $certify-black-opacity !important;
          color: $attachment-custom-grey !important;
          font-family: Arial !important;
          font-size: 10px !important;
          border-radius: 0 !important;
          padding: 5px 10px;
          margin-left: 0;
      }
  }

  .uib-datepicker-popup.dropdown-menu{
      border: 1px solid $custom-black-landing;
      box-shadow: none;
      margin-top: 0px;
  }

  .common__input-block--poll-view{
      .uib-datepicker-popup.dropdown-menu{
          border: 1px solid $custom-black-landing;
          box-shadow: none;
          margin-top: -1px;
          margin-left: 0px;
      }
  }

  .common{
    &__block-option-list {
      border-color: $custom-black-landing;
      box-shadow: $box-shadow-landing;
      border-width: 1px;
    }
    &__bubble-count{
        background: none;
        border: 1px solid $custom-black-landing;
        color: $attachment-custom-grey;
        font-family: MTTMilano, sans-serif;
        padding: 0;
        // &--disabled {
        //     background: $secondary-color;
        // }
    }
    &__col-text{
      color: $attachment-custom-grey;
      font-size: 13px;
      font-family: 'lato_regular', sans-serif;
    }

    &__datepicker-col {
      width: 57px;
      color: $custom-black-landing;
      font-family: 'lato_regular', sans-serif;
      font-size: 12px;
      font-weight: bold;
    }

    &__datepicker-header-tab {
      background-color: $background-grey;
      border: none;
      height: 22px;
    }
    &__icon-ellipsis {
        color: $custom-black-landing;
    }
    &__input-block-open {
        border-color: $custom-black-landing;
        background: none;
    }
    &__find-in-dropdown{
        border: none;
        padding-left: 15px;
        width: 100%;
        color: $custom-secondary-color;

        &::-webkit-input-placeholder {
            color: $custom-secondary-color;
            font-family: 'lato_regular', sans-serif;
            font-size: 10px;
        }
    }
    &__find-dropdown-block {
        border-bottom: 1px solid $attachment-custom-grey;
        height: 39px;
    }
    &__find-dropdown-icon-find{
        color: $custom-black-landing;
        top: 4px;
        &--extend {
            color: $custom-black-landing;
        }
    }
    &__filter-icon {
        font-size: 20px;
        &--active{
            color: $custom-black-landing;
        }
    }
    &__header-button-add-new-version {
      color: $snow-white;
    }
    &__info-icon-block{
        width: 15px;
    }
    &__input-text{
        color: $custom-black-landing;
        font-family: 'lato_regular', sans-serif;
        font-size: 13px;
        &--no-editable {
            color: $attachment-custom-grey-other;
            cursor: not-allowed;
        }

        &--required {
            color: $advice-close;
        }
    }
    &__order-by {
        color: #AAAAAA;
        font-size: 20px;
        &--active{
            color: $custom-black-landing;
        }
    }
    &__tooltip-law-block {
        position: relative;
        top: 2px;
    }
    &__input-icon-active {
      font-size: 15px;
      color: $custom-black-landing;
      top: 4px;
      opacity: 1;
      &--disabled{
          color: $attach-icon-grey;
          opacity: 0.5;
      }
    }
    &__delete-content-icon {
        font-size: 12px;
        color: $custom-secondary-color;
        right: 14px;
        bottom: 7px;
        &--contacts {
            font-size: 12px;
            color: $custom-secondary-color;
            bottom: 2px;
        }
    }
    &__input-content{
        color: $custom-secondary-color;
        font-family: 'lato_regular', sans-serif;
        font-size: 14px;
    }
    &__input-content-no-editable {
        color: $custom-secondary-color;
    }
    &__multiselect-select-icon {
        color: $custom-black-landing;
    }
    &__multiselect-dropdown-item {
        &--with-input{
            @include common-multiselect-dropdown-item-with-input($custom-black-landing, $custom-black-landing, $custom-black-landing);
            > .common__dropdown-inner-input {
                &::-webkit-input-placeholder,
                &::-ms-input-placeholder,
                &::placeholder {
                    font-family: Arial, sans-serif;
                }
            }
        }
    }
    &__pagination-count {
        color: $custom-black-landing;
    }
    &__select-all {
      margin-top: 5px;
      padding-top: 6px;
      font-family: 'lato_regular', sans-serif;
      font-size: 14px;
      border-color: $attachment-custom-grey;

      &:hover {
          background: $background-grey;
      }
    }
    &__select-item{
      font-family: 'lato_regular', sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: $custom-secondary-color;
      padding-top: 6px;

      &:hover {
          color: $custom-black-landing;
          border: none;
      }

      &--custom-first {
          &:hover {
              border-top: 1px solid transparent;
          }
      }
    }
    &__select-arrow {
        font-size: 15px;
        color: $custom-black-landing;
    }
    &__select-icon {
        font-size: 13px;
        top: 5px;
        &--select-list-check {
            position: relative;
            right: 14px;
            top: 14px;
            font-size: 12px;
        }
    }
    &__select-list {
        border-color: $custom-black-landing;
        z-index: 1000;
        background-color: $snow-white;
    }
    &__select-option-icon-angle-down{
        font-size: 12px;
        color: $custom-black-landing;
    }
    &__select-list-with-input {
        border-color: $custom-black-landing;
        box-shadow: $box-shadow-landing;
    }
    &__window-footer-bottom{
        padding-bottom: 160px;
    }
    &__footer-bottom{
        height: auto;
        min-height: 160px;
    }
    ////PDF view////
    &__pdf-block-header-modified {
        color: $custom-black-landing;
    }

    &__pdf-table-header-text {
        color: $custom-black-landing;
    }

    &__pdf-col-text {
        color: $attachment-custom-grey;
        font-size: 13px;
        font-family: 'lato_regular', sans-serif;
    }
    //////////Fin PDF//////////
  }
  &-big{
    .common{
      // @extend .common__datepicker-header-tab--landing-custom;
      height: 28px;
    }
  }
}
}



//ng2-dragula drag-and-drop
/* in-flight clone */
.gu-mirror {
position: fixed !important;
margin: 0 !important;
z-index: 9999 !important;
opacity: 0.8;
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
filter: alpha(opacity=80);
pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
-webkit-user-select: none !important;
-moz-user-select: none !important;
-ms-user-select: none !important;
user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
opacity: 0.2;
-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
filter: alpha(opacity=20);
}
