@import '../../../../assets/scss/colors';

.new-custom-report-field-assignment {
  &__block-body {
      margin: auto;
      width: 800px;
  }

  &__field-assignment-container {
      margin: auto;
      margin-top: 35px;
  }

  &__table-container {
      width: 800px;
      padding: 10px 2px 19px 2px;
      margin: auto;
      border: 1px solid $custom-black-landing;
      font-size: 12px;
  }

  &__header-title-block {
      height: 40px;
      position: relative;
      float: left;
      width: 100%;
      padding: 0px 8px 8px;
  }

  &__header-icon {
      position: relative;
      font-size: 30px;
      margin-right: 8px;
  }

  &__header-title {
      font-size: 18px;
      font-family: MTTMilano-Bold, sans-serif;
      position: relative;
      bottom: 5px;
  }

  &__body {
      width: 100%;
      height: 330px;
      overflow: hidden;
  }

  &__body-container {
      padding: 10px;
      height: 330px;
      overflow-y: auto;
  }

  &__body-empty-container {
      width: 100%;
      height: 295px;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  &__body-empty-span {
      color: $subcontainer-ground;
      font-family: Arial, sans-serif;
      font-style: italic;
      font-size: 12px;
      width: 285px;
  }

  &__field-column{
      height: 62px;
      width: 100%;
      border-bottom: 1px solid $box-comment-border-02;
      padding: 6px;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      position: relative;
      &:first-child{
          border-top: 1px solid $box-comment-border-02;
      }
      &:hover{
          background-color: $background-grey;
          & .new-custom-report-field-assignment__tongue-actions{
              & .new-custom-report-field-assignment__options-box {
                  opacity: 1;
              }
          }
      }
  }

  &__icon-type{
      font-size: 16px;
      margin-right: 6px;
      position: relative;
      float: left;
      top: 1px;
      &.lf-icon-switch-right{
          top: 3px;
      }
  }

  &__field-name-line{
      width: calc(100% - 60px);
      position: relative;
      float: left;
      height: 20px;
  }

  &__path-line{
      color: $pillow-group;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 13px;
      margin-top: 7px;
      width: calc(100% - 60px);
      position: relative;
      float: left;
  }

  &__icon-marker-path{
      font-size: 20px;
      position: relative;
      float: left;
      left: -2px;
      margin-right: 2px;
  }

  &__tongue-actions{
      padding: 0;
      height: 42px;
      width: 56px;
      position: absolute;
      right: 0;
      top: 4px;
  }

  &__options-box {
      list-style-type: none;
      margin: 0;
      color: $primary-color;
      display: inline-flex;
      background-color: $background-grey-other;
      border: 0.5px solid $certify-grey-opacity;
      height: 42px;
      width: 56px;
      -webkit-border-top-left-radius: 67px;
      -webkit-border-bottom-left-radius: 67px;
      -moz-border-radius-topleft: 67px;
      -moz-border-radius-bottomleft: 67px;
      border-top-left-radius: 67px;
      border-bottom-left-radius: 67px;
      position: absolute;
      padding: 9px 0 0 23px;
      z-index: 1;
      top: -2px;
      right: 0px;
      opacity: 0;
  }

  &__cell-block{
      position: relative;
      height: 17px;
      float: left;
      max-width: calc(100% - 22px);
      &--path{
          top: 3px;
      }
  }

  &__col-text{
      max-width: 100%;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }
}

.compliance{
  &__dark-blue {
    .new-custom-report-field-assignment {
      &__table-container {
        color: $primary-color;
        border-color: $subcontainer-border;
        box-shadow: 0 0 10px 1px $bar-horizontal-grey-opacity02;
      }

      &__field-column {
        &:hover {
          background-color: $background-color-states;
        }
      }
    }
  }

  &__icam-red {
    .new-custom-report-field-assignment {
      &__table-container {
        border-color: $border-block-icam;
        color: $title-text-icam;
      }

      &__body-empty-span {
        color: $secondary-color-icam;
      }

      &__field-column {
        color: $custom-black-landing;
        border-bottom: 1px solid $border-block-icam;
        &:first-child{
              border-top: 1px solid $primary-color-icam;
        }
        &:hover{
            background-color: $hover-block-icam;
        }
      }

      &__icon-type {
        color: $primary-color-icam;
      }

      &__path-line {
        color: $secondary-color-icam;
      }

      &__options-box {
        color: $snow-white;
        background-color: $primary-color-icam;
        border: 0.5px solid $primary-color-icam;
      }
    }
  }

  &__gm-law {
    .new-custom-report-field-assignment {
      &__table-container {
        border-color: $border-block-gm;
        color: $title-text-gm;
      }

      &__body-empty-span {
        color: $secondary-color-gm;
      }

      &__field-column {
        color: $custom-black-landing;
        border-bottom: 1px solid $border-block-gm;
        &:first-child{
              border-top: 1px solid $primary-color-gm;
        }
        &:hover{
            background-color: $hover-block-gm;
        }
      }

      &__icon-type {
        color: $primary-color-gm;
      }

      &__path-line {
        color: $secondary-color-gm;
      }

      &__options-box {
        color: $snow-white;
        background-color: $primary-color-gm;
        border: 0.5px solid $primary-color-gm;
      }
    }
  }
  &__com-de {
    .new-custom-report-field-assignment {
      &__table-container {
        border-color: $border-block-com-de;
        color: $title-text-com-de;
      }

      &__body-empty-span {
        color: $secondary-color-com-de;
      }

      &__field-column {
        color: $custom-black-landing;
        border-bottom: 1px solid $border-block-com-de;
        &:first-child{
              border-top: 1px solid $primary-color-com-de;
        }
        &:hover{
            background-color: $hover-block-com-de;
        }
      }

      &__icon-type {
        color: $primary-color-com-de;
      }

      &__path-line {
        color: $secondary-color-com-de;
      }

      &__options-box {
        color: $snow-white;
        background-color: $primary-color-com-de;
        border: 0.5px solid $primary-color-com-de;
      }
    }
  }
  &__com-fr {
    .new-custom-report-field-assignment {
      &__table-container {
        border-color: $border-block-com-fr;
        color: $title-text-com-fr;
      }

      &__body-empty-span {
        color: $secondary-color-com-fr;
      }

      &__field-column {
        color: $custom-black-landing;
        border-bottom: 1px solid $border-block-com-fr;
        &:first-child{
              border-top: 1px solid $primary-color-com-fr;
        }
        &:hover{
            background-color: $hover-block-com-fr;
        }
      }

      &__icon-type {
        color: $primary-color-com-fr;
      }

      &__path-line {
        color: $secondary-color-com-fr;
      }

      &__options-box {
        color: $snow-white;
        background-color: $primary-color-com-fr;
        border: 0.5px solid $primary-color-com-fr;
      }
    }
  }
}
