.concept {
  position: relative;
  float: left;
  display: block;
  width: 100%;
  height: 100%;

  &__cell-block {
      position: relative;
      display: inline-block;
      height: 17px;
      bottom: 2px;
      max-width: calc(100% - 54px);
      vertical-align: middle;

      &:hover {
          cursor: pointer;
      }
  }

  &__content {
      float: left;
      display: block;
      width: 100%;
      height: 100%;
  }

  &__cursor-hover {
      &:hover {
          cursor: pointer;
      }
  }

  &__go-back {
      margin: 14px 0 38px 0px;
      width: 40%;
      float: left;
  }

  &__go-back-button {
      background: none;
      border: none;
      outline: none;
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      text-align: left;
      width: 95%;
      padding: 0;

      &:hover {
          cursor: default;
      }

  }

  &__go-back-icon {
      font-size: 40px;
      color: $primary-color;
      position: relative;
      top: 8px;
      margin-right: 9px;

      &:hover {
          cursor: pointer;
      }
  }

  &__no-evaluated {
      display: flex;
      align-items: center;
      border: none;
      background: none;
      height: 35px;

  }

  &__no-evaluated-icon {
      font-size: 20px;
      padding-right: 5px;
  }

  &__no-evaluated-text {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
  }

  &__no-full-menu {
      background-color: $alert-color;
  }

  &__other-buttons {
      display: flex;
      justify-content: flex-end;
      float: right;
      margin-top: 23px;

      &--top {
          position: relative;
          height: 58px;
          margin-top: 0;
          width: 35%;
          margin-right: 0;
          border-bottom: 1px solid $primary-color;
          padding-bottom: 10px;
          display: inline-block;
      }
  }

  &__tab-button {
      display: flex;
      align-items: center;
      height: 35px;
      color: $snow-white;
      border: none;
      border-radius: 17px;
      margin-left: 5px;
      &[disabled] {
          opacity: 0.5;
      }
  }

  &__tab-button-icon {
      font-size: 20px;
  }

  &__tab-button-text {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
  }
}

.compliance{
  &__dark-blue{
      .concept {
          &__no-evaluated-icon {
              color: $primary-color;
          }
          &__no-evaluated-text {
              color: $primary-color;
          }
          &__tab-button {
              background-color: $subcontainer-ground;
              padding-right: 10px;
              &--back {
                  background-color: $subcontainer-ground;
                  padding-right: 10px;
              }

              &--next {
                  background-color: $primary-color;
                  padding-left: 10px;
              }
          }
      }
  }
  &__steel-blue, &__uk-steel-blue{
      .concept {
          &__go-back-button {
              color: $primary-color-2;
          }
          &__go-back-icon {
              color: $border-hover-2;
          }
          &__tab-button {
              &--back{
                  background-color: $grey-icons-2;
                  padding-right: 10px;
              }
              &--next{
                  background-color: $border-hover-2;
                  padding-left: 10px;
              }
          }
      }
  }
  &__icam-red{
      .concept {
          &__go-back-button {
              color: $custom-black-landing;
          }
          &__go-back-icon {
              color: $primary-color-icam;
          }
      }
  }
  &__gm-law{
      .concept {
          &__go-back-button {
              color: $custom-black-landing;
          }
          &__go-back-icon {
              color: $primary-color-gm;
          }
          &__no-evaluated {
              color: $primary-color-gm;
          }
          &__no-evaluated-icon {
              color: $primary-color-gm;
          }
          &__no-evaluated-text {
              color: $custom-black-landing;
          }
          &__tab-button {
              &--back{
                  background-color: $secondary-color-gm;
                  padding-right: 10px;
              }
              &--next{
                  background-color: $primary-color-gm;
                  padding-left: 10px;
              }
          }
      }
  }
  &__com-de{
    .concept {
        &__go-back-button {
            color: $custom-black-landing;
        }
        &__go-back-icon {
            color: $primary-color-com-de;
        }
        &__no-evaluated {
            color: $primary-color-com-de;
        }
        &__no-evaluated-icon {
            color: $primary-color-com-de;
        }
        &__no-evaluated-text {
            color: $custom-black-landing;
        }
        &__tab-button {
            &--back{
                background-color: $secondary-color-com-de;
                padding-right: 10px;
            }
            &--next{
                background-color: $primary-color-com-de;
                padding-left: 10px;
            }
        }
    }
  }
  &__com-fr{
    .concept {
        &__go-back-button {
            color: $custom-black-landing;
        }
        &__go-back-icon {
            color: $primary-color-com-fr;
        }
        &__no-evaluated {
            color: $primary-color-com-fr;
        }
        &__no-evaluated-icon {
            color: $primary-color-com-fr;
        }
        &__no-evaluated-text {
            color: $custom-black-landing;
        }
        &__tab-button {
            &--back{
                background-color: $secondary-color-com-fr;
                padding-right: 10px;
            }
            &--next{
                background-color: $primary-color-com-fr;
                padding-left: 10px;
            }
        }
    }
  }
}

@media (max-width: 900px) {
  .concept {
      &__tab-button-text {
          display: none;
      }
  }
}
