.concept-list-landing{
  position: relative;
  &__table{
      width: 100%;
      display: block;
  }
  &__table-header{
      width: 100%;
      display: table;
      border-bottom: 5px solid $custom-border-grey;
      font-size: 13px;
      color: $custom-black-landing;
      font-family: Arial, sans-serif;
      font-weight: bold;
      padding: 0 0 0 12px;
      height: 40px;
  }

  &__table-col{
      display: table-cell;
      vertical-align: middle;
      max-width: 0;
      padding-right: 7px;
      &--title{
          width: 23%;
      }
      &--reference, &--creation-date, &--modification-date, &--step{
          width: 18%;
      }
      &--bottom-2{
          position: relative;
          bottom: 2px;
      }

      &--top-1{
          position: relative;
          top: 1px;
      }

      &--actions{
          width: 5%;
          padding: 0;
          text-align: right;
      }
  }

  &__table-row{
      font-family: 'lato_regular', sans-serif;
      display: table;
      width: 100%;
      position: relative;
      font-size: 13px;
      border-bottom: 1px solid $custom-border-grey;
      padding: 0 0 0 12px;
      height: 44px;
      &:hover{
          background-color: $background-grey;
          & > .concept-list-landing__table-col--actions > .concept-list-landing__options{
              opacity: 1;
          }

          & .common__icon-ellipsis{
              opacity: 0;
          }

          & .common__col-text--landing-custom{
              color: $custom-black-landing;
          }
      }
  }

  &__calendar-dropdown {
      top: 35px;
      height: 282px;
      width: 371px;
      padding: 0;
      margin: 0;
      z-index: 2;
  }

  &__pagination-block{
      text-align: center;
      height: 22px;
      display: block;
      position: relative;
      float: left;
      width: 100%;
  }

  &__table-foot{
      text-align: center;
      padding-top: 21px;
      display: table;
      width: 100%;
      position: relative;
  }

  &__options{
      list-style: none;
      height: 40px;
      margin-bottom: 0;
      opacity: 0;
      width: 86px;
      position: absolute;
      right: 0;
      top: 2px;
      background-color: transparent;
      border: none;
      border-radius: 0;
      z-index: 1;
  }

  &__menu-options {
      float: left;
      margin-right: 6px;
      font-size: 22px;
      color: $custom-black-landing;
      margin-top: 9px;
      &:hover{
          cursor: pointer;
      }
      &--edit{
          margin-left: 25px;
          margin-right: 10px;
      }
  }
  &__anonymatum-icon{
      float: right;
      margin-right: 6px;
      font-size: 22px;
      color: $attachment-custom-grey-other;
  }
  &__row-anonimyzed{
      & p {
          color: $attachment-custom-grey-other;
      }
  }
  &__table-title{
      float: left;
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
}

@media (max-width: $max-width-smartphone) {
  .concept-list-landing{
      &__table{
          overflow-x: auto;
          overflow-y: hidden;
      }
      &__table-header, &__table-row{
          width: 800px;
      }
      &__table-body{
          height: 440px;
      }
      &__table-row{
          & .common__icon-ellipsis{
              opacity: 0;
          }
      }
      &__table-col{
          &--creation-date, &--modification-date{
              width: 17%;
          }
          &--step{
              width: 20%;
          }
      }
      &__options{
          opacity: 1;
      }
      &__calendar-dropdown {
          height: auto;
          min-height: 282px;
          width: 210px;
      }
  }
}
