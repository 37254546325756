@mixin icon-numeric-nav($borderColor, $textColor, $backgroundColor) {
  border-color: $borderColor;
  color: $textColor;
  background-color: $backgroundColor;
}
@mixin menu-button-user($backgroundColor, $textColor, $afterColor) {
  background-color: $backgroundColor;
  color: $textColor;
  &:after{
      color: $afterColor;
  }
}
@mixin search-input($borderColoor, $textColor, $focusColorBackground, $placeholderColor) {
  border-color: $borderColoor;
  color: $textColor;
  &::-webkit-input-placeholder{
      color: $placeholderColor;
  }
  &:focus{
      background-color: $focusColorBackground;
  }
}

.header-nav {
  display: block;
  width: 100%;
  height: 95px;
  background-color: $snow-white;
  border-bottom: 1px solid $primary-color;
  &__block-logo{
      // display: inline-block;
      position: relative;
      float: left;
      width: 245px;
  }
  &__block-text{
      color: $secondary-color-gm;
      margin-top: 9px;
      font-size: 9px;
      font-family: "MTTMilano-Medium", sans-serif;
  }

  &__block-central{
      // display: inline-block;
      position: relative;
      float: left;
      // margin: 0 auto;
      // text-align: center;
      width: calc(100% - 490px);
      top: 26px;
      height: 42px;
      // &--view{
      //     width: calc(100% - 656px);
      // }
  }

  &__block-central-align{
      margin: 0 auto;
      position: relative;
      width: 1027px;
      height: 42px;
      //border-right: 1px solid $subcontainer-border;
      &--without-one-element{
          width: 985px;
      }
      &--without-language-and-one{
          width: 846px;
      }
      // &--without-two-element{
      //     width: 940px;
      // }
  }

  &__block-button {
      display: inline-block;
      position: relative;
      margin-left: 15px;
      bottom: 24px;
      &--user {
          margin-right: 18px;
          bottom: 0;
      }

      &--to-user {
          margin-left: 10px;
      }
  }

  &__block-group-buttons{
      display: inline-block;
      position: relative;
      top: 4px;
      &--hidden-language{
          top: 9px;
      }
  }

  &__block-central-button {
      display: inline-block;
      position: relative;
      margin-left: 15px;
      color: $primary-color;
  }

  &__block-menus{
      position: relative;
      float: right;
      width: 245px;
      &--view{
          width: 405px;
      }
  }

  &__block-search {
      position: relative;
      height: 42px;
      width: 675px;
      float: left;
      &--documentation{
          width: 300px;
          display: block;
          overflow: hidden;
          margin-bottom: 6px;
      }
  }

  &__box-menus {
      // display: contents;
      right: 0;
      width: 0;
      height: 0;
      border: 0px;
      margin: 0;
      padding: 0;
  }

  &__button-text {
      position: relative;
      // bottom: 3px;
      // font-family: MTTMilano-Bold, sans-serif;
      text-transform: uppercase;
      font-family: MTTMilano, sans-serif;
      font-size: 30px;
  }


  &__icon-block {
      display: inline-block;
      width: 86px;
      overflow: hidden;

      &:hover {
          cursor: pointer;
      }

      &--comment {
          margin-right: 7px;
      }

      &--bell {
          margin-right: 24px;
      }
  }

  &__icon-header {
      font-size: 23px;
      &--videoconference{
          font-size: 26px;
      }

  }

  &__icon-numeric-nav{
      border: 2px solid $primary-color;
      color: $primary-color;
      background-color: $snow-white;
      width: 45px;
      height: 24px;
      border-radius: 12px;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
      display: inline-block;
      // top: -5px;
      line-height: 20.5px;
      text-align: center;
      // position: relative;
      font-style: normal;
      position: absolute;
      top: 0;
      right: 0px;
  }

  &__icon-button {
      position: absolute;
      right: 18px;
      top: 9px;
      width: 24px;
      padding: 0;
      border: none;
      outline: none;
      background: none;
      &--duplicate{
          right: 10px;
      }
      &--documentation{
          right: 13px;
          top: 7px;

      }
  }

  &__icon-search {
      font-size: 24px;
      color: $primary-color;
  }

  &__icons {
      float: right;
      margin-top: 14px;
      margin-right: 12px;
      color: $primary-color;
      height: 80px;
  }

  &__icons-block {
      float: left;
  }

  &__icons-nav {
      cursor: pointer;

      &--user {
          margin-right: 28px;
          text-transform: uppercase;
          font-size: 11px;
          vertical-align: 2px;
          margin-left: 9px;
      }
  }

  &__logo {
      margin: 0;
      color: $header-nav-logo;
      margin-bottom: -7px;
      font-family: MTTMilano-Bold, sans-serif;
      font-weight: bold;

      &--color {
          color: $primary-color;
      }
  }

  &__logo-compliance {
      height: 40px;
      margin-top: 27px;
      margin-left: 16px;
  }

  &__logo-compliance-img {
      // height: 40px;
      height: 100%;
      &:hover {
          cursor: pointer;
      }

      &--disabled {
          &:hover {
              cursor: default;
          }
      }
  }

  &__logo-user{
      width: 100%;
      height: auto;
      position: relative;
  }

  &__menu-button {
      border: none;
      background: none;
      outline: none;
      padding: 0;
      position: relative;
      &--notifications{
          width: 72px;
          text-align: left;
      }
      &::after{
          display: none;
      }
  }

  &__menu-button-user{
      height: 67px;
      width: 67px;
      background-color: $background-grey-other;
      color: $pillow-group;
      border: none;
      border-radius: 50%;
      outline: none;
      float: left;
      position: relative;

      &.dropdown-toggle::after {
        border-top: 0px;
        border-right: 0;
      }
      // &::after{
      //     content: '\e90b';
      //     font-family: "lf-font";
      //     position: absolute;
      //     right: -18px;
      //     top: 25px;
      // }
    &--logo{
        background-color: transparent !important;
    }
  }

  &__icon-button-user{
    position: absolute;
    right: -18px;
    top: 27px;
  }

  &__menu-icon {
      display: inline-block;
      margin-left: 21px;
      position: relative;
      color: $primary-color;
      font-size: 19px;
      bottom: 14px;
      margin-right: 17px;
  }

  &__menus-block {
      width: 0px;
      padding: 0;
      margin: 0;
      border: 0px;
      left: -62px;
  }

  &__menu-products {
    width: 0px;
    padding: 0;
    margin: 0;
    border: 0px;
    left: 0px !important;
}

  &__block-option-list{
      top: 26px;
  }

  &__name {
      text-transform: uppercase;
      font-size: 11px;
      margin-top: 10px;
      margin-right: 20px;
  }

  &__rectangle-info {
      top: -7px;
      margin-bottom: 0;
  }

  // ::-webkit-input-placeholder {
  //     font-size: 16px;
  //     font-family: MTTMilano, sans-serif;
  //     color: $primary-color;
  //     font-style: normal;
  //     padding: 0px;
  // }

  &__search-input {
      position: relative;
      width: 99%;
      height: 42px;
      color: $primary-color;
      font-size: 16px;
      border: 1px solid $subcontainer-border;
      outline: none;
      background-color: transparent;
      padding: 7px 40px 6px 16px;
      font-family: MTTMilano, sans-serif;

      &--duplicate{
          width: 100%;
      }
      &--with-text {
          padding: 7px 66px 6px 16px;
      }

      &::-webkit-input-placeholder{
          font-style: normal;
          font-size: 16px;
          font-family: MTTMilano, sans-serif;
          padding: 0px;
          color: $primary-color;
      }
      &:focus {
          box-shadow: 0 0 5px 2px $certify-black-opacity02;
          background-color: $background-grey;
      }
  }
  // button:active {
  //     background-color: $primary-color;
  //     color: $snow-white;
  // }

  &__preview {
      position: relative;
      display: inline-block;
      height: 42px;
      top: 32px;
      font-family: MTTMilano-Bold, sans-serif;
      text-transform: uppercase;
      font-size: 24px;
      color: $primary-color;
  }

  // &__block-window{
  //     background-color: $snow-white;
  //     margin-top: 25px;
  //     margin: 0;
  //     width: 100%;
  //     // height: 100%;
  //     display: block;
  //     overflow: hidden;
  //     position: relative;
  //     // z-index: 1;
  //     // background-image: url(../assets/img/trama.jpg);
  // }
  &__translates-list-block{
      width: 120px;
      display: inline-block;
      height: 42px;
      border-left: 1px solid $subcontainer-border;
      margin-left: 16px;
      position: relative;
  }
  &__select-language-button{
      border: none;
      background: none;
      outline: none;
      width: 100%;
      color: $primary-color;
      font-family: 'MTTMilano-Bold', sans-serif;
      font-size: 13px;
      text-transform: uppercase;
      padding: 0;
      margin-top: 13px;
      position: relative;
      top: 0px;
      &::after{
          display: none;
      }
  }
  &__select-language-icon{
      font-size: 15px;
      color: $primary-color;
      padding-left: 3px;
      position: relative;
      top: 2px;
      &--custom{
          font-size: 12px;
          color: $custom-black-landing;
      }
  }
  &__language-list{
      box-shadow: 0 0 25px 6px $bar-horizontal-grey-opacity;
      background-color: $snow-white;
      position: absolute;
      top: 27px;
  }
  &__select-language-short{
      display: none;
  }

  ///////////////////
  ////Version UK////
  /////////////////
  &__icon-circle-notification{
      font-size: 11px;
      color: $third-color-2;
      position: absolute;
      top: 0;
  }
////////////////////////////////////////////
/////////////////Custom////////////////////
//////////////////////////////////////////
  &__custom-translates-list-block{
      float: right;
      display: block;
      margin-right: 6px;
      border: none;
      // width: 101px;
      height: 42px;
      margin-left: 12px;
      position: relative;
      &--front-login{
          width: auto;
      }
  }
  &__custom-select-language-button{
      border: none;
      background: none;
      outline: none;
      // width: 100%;
      text-transform: uppercase;
      padding: 0;
      margin-top: 13px;
      position: relative;
      top: 3px;
      color: $custom-language;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      float: right;
      width: auto;
      margin-right: 8px;
  }

  &__custom-language-list{
      min-height: 80px;
      min-width: 150px;
      max-width: 150px;
      width: 150px;
      background-color: $snow-white;
      border-radius: 0;
      box-shadow: 0 2px 35px 0 $column-filter-shadow-05;
      padding: 2px 0;
      // left: -46px;
      left: initial;
      right: -17px;
      // left: -100%;
      &:before{
          content: '';
          height: 1px;
          width: 1px;
          border-top: 5px solid transparent;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid $snow-white;
          position: absolute;
          top: -10px;
          right: 17px;
      }
      &--block{
        max-height: 116px;
        overflow: auto;
      }
  }
  &__custom-language-block{
      display: block;
      max-height: 116px;
      overflow: auto;
  }
  &__custom-language-option{
      height: 22px;
      color: $custom-language;
      font-family: Arial, sans-serif;
      font-size: 9px;
      font-weight: bold;
      &--specific{
          font-weight: 500;
      }
  }
}


.compliance {
&__dark-blue {
    & .header-nav {
        border-color: $primary-color;
        &__search-input {
            @include search-input($subcontainer-border, $primary-color, $background-color-states, $primary-color);
        }
        &__icon-search {
            color: $primary-color;
        }
        &__block-central-button {
            color: $primary-color;
        }
        &__icon-numeric-nav{
            @include icon-numeric-nav($primary-color, $primary-color, $snow-white);
        }
        &__translates-list-block{
            border-color: $subcontainer-border;
        }
        &__select-language-button{
            color: $primary-color;
        }
        &__select-language-icon{
            color: $primary-color;
            &--box{
                color: $primary-color;
            }
        }
        &__icons{
            color: $primary-color;
        }
        &__menu-button-user{
            @include menu-button-user($background-grey-other, $pillow-group, $primary-color);
        }
        &__language-list{
            min-width: 218px;
            padding: 27px 24px 22px;
            left: -18px;
            &:before{
                content: '';
                height: 1px;
                width: 1px;
                border-top: 10px solid transparent;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid $snow-white;
                position: absolute;
                top: -20px;
                right: 23px;
            }
        }
    }
}

&__uk-steel-blue, &__steel-blue {
    & .header-nav {
        border-color: $secondary-color-2;
        &__search-input {
            @include search-input($secondary-color-2, $primary-color-2, $block-hover-2, $primary-color-2);
            &:focus {
                border-color: $border-hover-2;
                box-shadow: 0 0 7px 1px $certify-black-opacity02;
                color: $border-hover-2;
            }
        }
        &__icon-search {
            color: $border-hover-2;
        }
        &__block-central-button {
            color: $border-hover-2;
        }
        &__icon-numeric-nav{
            @include icon-numeric-nav($third-color-2, $primary-color-2, $third-color-2);
        }
        &__translates-list-block{
            border-color: $secondary-color-2;
        }
        &__select-language-button{
            color: $primary-color-2;
        }
        &__select-language-icon{
            color: $border-hover-2;
            &--box{
                color: $border-hover-2;
            }
        }
        &__icons{
            color: $border-hover-2;
        }
        &__menu-button-user{
            @include menu-button-user($third-color-2, $primary-color-2, $border-hover-2);
        }
        &__language-list{
             // margin-top: 29px;
            border: 1px solid $third-color-2;
            left: 0px;
            width: 175px;
            padding: 10px 5px;
            border-top-width: 2px;
        }
    }
}

&__uk-steel-blue {
    & .header-nav {
        &__menu-button--notifications {
            width: 36px;
        }
    }
}

&__gm-law {
    & .header-nav {
        border: none;
        box-shadow: $box-shadow-gm;
        &__search-input {
            @include search-input($border-block-gm, $primary-color-gm, $hover-block-gm, $custom-black-landing);
            background: $snow-white;
            &:focus {
                border-color: $primary-color-gm;
                color: $primary-color-gm;
            }
        }
        &__icon-search {
            color: $primary-color-gm;
        }
        &__block-central-button {
            color: $primary-color-gm;
        }
        &__icon-numeric-nav{
            @include icon-numeric-nav($third-color-gm, $custom-black-landing, $third-color-gm);
        }
        &__select-language-icon{
            color: $primary-color-gm;
        }
        &__icons{
            color: $primary-color-gm;
        }
        &__menu-button-user{
            @include menu-button-user($third-color-gm, $custom-black-landing, $primary-color-gm);
        }
    }
}

&__com-de {
  & .header-nav {
      border: none;
      box-shadow: $box-shadow-com-de;
      &__search-input {
          @include search-input($border-block-com-de, $primary-color-com-de, $hover-block-com-de, $custom-black-landing);
          background: $snow-white;
          &:focus {
              border-color: $primary-color-com-de;
              color: $primary-color-com-de;
          }
      }
      &__icon-search {
          color: $primary-color-com-de;
      }
      &__block-central-button {
          color: $primary-color-com-de;
      }
      &__icon-numeric-nav{
          @include icon-numeric-nav($third-color-com-de, $custom-black-landing, $third-color-com-de);
      }
      &__select-language-icon{
          color: $primary-color-com-de;
      }
      &__icons{
          color: $primary-color-com-de;
      }
      &__menu-button-user{
          @include menu-button-user($third-color-com-de, $custom-black-landing, $primary-color-com-de);
      }
  }
}
&__com-fr {
  & .header-nav {
      border: none;
      box-shadow: $box-shadow-com-fr;
      &__search-input {
          @include search-input($border-block-com-fr, $primary-color-com-fr, $hover-block-com-fr, $custom-black-landing);
          background: $snow-white;
          &:focus {
              border-color: $primary-color-com-fr;
              color: $primary-color-com-fr;
          }
      }
      &__icon-search {
          color: $primary-color-com-fr;
      }
      &__block-central-button {
          color: $primary-color-com-fr;
      }
      &__icon-numeric-nav{
          @include icon-numeric-nav($third-color-com-fr, $custom-black-landing, $third-color-com-fr);
      }
      &__select-language-icon{
          color: $primary-color-com-fr;
      }
      &__icons{
          color: $primary-color-com-fr;
      }
      &__menu-button-user{
          @include menu-button-user($third-color-com-fr, $custom-black-landing, $primary-color-com-fr);
      }
  }
}

&__icam-red {
    & .header-nav {
        border: none;
        box-shadow: $box-shadow-icam;
        &__logo-compliance {
            height: 70px;
            margin-top: 11px;
            margin-left: 18px;
        }
        &__search-input {
            @include search-input($border-block-icam, $primary-color-icam, $hover-block-icam, $custom-black-landing);
            background: $snow-white;
            &:focus {
                border-color: $primary-color-icam;
                color: $primary-color-icam;
            }
        }
        &__icon-search {
            color: $primary-color-icam;
        }
        &__block-central-button {
            color: $primary-color-icam;
        }
        &__icon-numeric-nav{
            @include icon-numeric-nav($third-color-icam, $custom-black-landing, $third-color-icam);
        }
        &__select-language-icon{
            color: $primary-color-icam;
        }
        &__icons{
            color: $primary-color-icam;
        }
        &__menu-button-user{
            @include menu-button-user($third-color-icam, $custom-black-landing, $primary-color-icam);
        }
    }
}
&__icam-red, &__gm-law {
    & .header-nav {
        &__select-language-button{
            color: $custom-black-landing;
        }
        &__select-language-icon--box{
            color: $snow-white;
        }
    }
}
}

@media (min-width: 1401px) and (max-width: 1500px){
  .header-nav {
      &__block-central-align{
          width: 898px;
          &--without-one-element{
              width: 850px;
          }
          &--without-language-and-one{
              width: 711px;
          }
          // &--without-two-element{
          //     width: 805px;
          // }
      }
      &__block-search {
          width: 540px;
      }
  }
}

@media (min-width: 1366px) and (max-width: 1400px){
  .header-nav {
      &__block-central-align{
          width: 885px;
          &--without-one-element{
              width: 850px;
          }
          &--without-language-and-one{
              width: 702px;
          }
          // &--without-two-element{
          //     width: 805px;
          // }
      }
      &__block-search {
          width: 531px;
      }

  }
}
@media (min-width: 1150px) and (max-width: 1366px){
.header-nav {
    &__block-central-align{
        width: 715px;
        &--without-one-element{
            width: 670px;
        }
        &--without-language-and-one{
            width: 573px;
        }
        // &--without-two-element{
        //     width: 625px;
        // }
    }
    &__block-search {
        width: 400px;
    }
    &__translates-list-block{
        width: 80px;
    }
    &__select-language-short{
        display: inline-block;
    }
    &__select-language{
        display: none;
    }
}
.compliance{
  &__dark-blue {
      & .header-nav {
        &__language-list{
            left: -40px;
        }
      }
  }
}
}
@media (min-width: 1000px) and (max-width: 1190px){
.header-nav {
    &__block-central-align{
        width: 550px;
        &--without-one-element{
            width: 515px;
        }
        &--without-language-and-one{
            width: 525px;
        }
        // &--without-two-element{
        //     width: 470px;
        // }
    }
    &__block-search {
        width: 225px;
        &--without-language{
            width: 335px;
        }
    }
    &__translates-list-block{
        width: 80px;
    }

    &__select-language-short{
        display: inline-block;
    }
    &__select-language{
        display: none;
    }
}
.compliance{
  &__dark-blue {
      & .header-nav {
        &__language-list{
            left: -40px;
        }
      }
  }
}
}

@media (max-width: 1030px){
.header-nav {
    &__block-central-align{
        width: 455px;
        &--without-one-element{
            width: 420px;
        }
        &--without-language-and-one{
            width: 314px;
        }
        // &--without-two-element{
        //     width: 380px;
        // }
    }
    &__block-search {
        width: 142px;
    }
    &__translates-list-block{
        width: 80px;
    }

    &__select-language-short{
        display: inline-block;
    }
    &__select-language{
        display: none;
    }
}
.compliance{
  &__dark-blue {
      & .header-nav {
        &__language-list{
            left: -40px;
        }
      }
  }
}
}

@media (max-width: $max-width-smartphone) { //Smartphone
  .header-nav {
      &__custom-translates-list-block {
          width: auto;
      }
  }
}
