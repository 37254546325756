@mixin add-comment-text($borderColor, $color){
  height: 96px;
  padding: 9px 13px;
  border: 1px solid $borderColor;
  color: $color;
}

@mixin add-comment-title($backgroundColor, $color){
  background: $backgroundColor;
  color: $color;
  padding: 5px 6px 3px 6px;
  width: fit-content;
  margin-left: 9px;
  margin-top: 5px;
}
@mixin list-item($borderColor, $backgroundColor){
  border-left-color: $borderColor;
  &:hover{
      background: $backgroundColor;
  }
}
@mixin menu($backgroundColor, $borderColor, $color){
  background-color: $backgroundColor;
  border-color: $borderColor;
  color: $color;
}

.box-comments {
  width: 100%;
  display: block;

  &__header-block{
      display: block;
      width: 100%;
      height: 50px;
      position: relative;

  }
  &__circle{
      position: absolute;
      width: 79px;
      height: 79px;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      background: $background-grey;
      left: calc(60% - 79px);
      bottom: 0;
  }

  &__add-comment-block{
      display: block;
      width: 100%;
      overflow: hidden;
      padding-bottom: 20px;
  }

  &__cancel-comment-button{
      height: 33px;
      width: 108px;
      float: right;
      background: none;
      border: none;
      border-bottom: 3px solid $secondary-color;
      padding: 0;
      margin-right: 9px;
      position: relative;
      z-index: 10;
      &:hover{
          .box-comments__cancel-comment-button-text{
              background: $secondary-color;
              color: $snow-white;
          }
      }
      &--fullScreen{
          margin-bottom: 42px;
      }
  }

  &__cancel-comment-button-text{
      height: 29px;
      border: 3px solid $secondary-color;
      width: 100%;
      margin-bottom: 1px;
      color: $secondary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-align: center;
      text-transform: uppercase;
      padding-top: 4px;
  }

  &__add-comment-text{
      margin: 8px 8px 6px 8px;
      width: 96%;
      resize: none;
      font-family: Arial, sans-serif;
      font-size: 12px;
      outline:0;
  }

  &__add-comment-title{
      margin-bottom: 0;
      font-family: Arial, sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      &--custom{
          color: $custom-black-landing;
          font-family: 'lato_regular', sans-serif;
          font-size: 11px;
          font-weight: bold;
          width: 100%;
          background: none;
          margin-left: 9px;
          padding: 11px 8px;
      }
  }

  &__add-icon{
      float: right;
      font-size: 20px;
      margin-top: 9px;
      margin-right: 8px;
      position: relative;
      z-index: 5;

      &--custom{
          color: $custom-black-landing;
          top: 6px;
      }
      &:hover{
          cursor: pointer;
      }
      &--disabled{
        opacity: 0.5;
      }
  }

  &__block-empty{
      position: relative;
      text-align: center;
      color: $secondary-color;
      font-family: MTTMilano, sans-serif;
      top: 38px;
      left: 37px;
  }

  &__author{
      text-transform: uppercase;
      font-size: 10px;
      margin-bottom: 4px;
      font-weight: bold;
      width: fit-content;
      margin-top: 6px;
      padding: 4px 8px;
  }

  &__comment-block{
      position: relative;
      width: 97%;
      float: right;
      margin-right: 0;
      border-left: 1px solid $comments-grey-opacity;
      padding: 16px;
      margin-top:-66px;
      &:first-child{
          margin-top: 0;
      }
  }
  &__comment-list{
      margin: 45px 0 0 0;
      padding: 0 5px 5px 10px;
      list-style: none;
      width: 100%;
      &--full-add-comment{
          height: 412px;
          overflow: auto;
          overflow-x: hidden;
          margin-top: 17px;
      }
      &--full-no-comment{
          height: 544px;
          overflow: auto;
          overflow-x: hidden;
      }
      &--custom{
          margin-top: 0px;
          height: auto;
          padding-top: 20px;
      }
  }

  &__comment-item{
      margin-right: 11px;
  }

  &__list-item{
      width: 100%;
      position: relative;
      padding-left: 22px;
      border-left: 1px solid;
      padding-bottom: 13px;
      &:hover{
          & > .box-comments__options-block{
              opacity: 1;
          }
      }
      &--custom{
          padding-left: 12px;
          border-left-width: 2px;
          margin-left: 7px;
          width: calc(100% - 7px);
          padding-bottom: 2px;
          margin-bottom: 16px;
          &:hover{
              background: $snow-white;
          }
      }
  }

  &__comment-list-block{
      width: 100%;
      position: relative;
      float: left;
      font-size: 12px;
      color: $primary-color;
  }

  &__comment-text{
      padding: 0 0 0 8px;
      font-size: 12px;
      font-family: Arial, sans-serif;
      position: relative;
      margin: 0 0 2px;
      padding-left: 8px;
      &--only-last{
          max-height: 85px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
      }
      &--communication-channel{
          margin: 5px 0 2px;
      }
      &--full-screen{
          padding-bottom: 32px;
      }
  }
  &__comment-bubble{
      position: absolute;
      top: 1px;
      left: -6px;
      font-size: 12px;
      &--custom{
          font-size: 10px;
          top: 0px;
      }
  }

  &__comment-overflow{
      overflow-y: auto;
      overflow-x: hidden;
  }

  &__date-block{
      margin-top: 30px;
      padding: 4px 0px;
      &--first{
          margin-top: 10px;
      }
  }

  &__full{
      z-index: 10;
  }

  &__paragraph{
      font-weight: bold;
      padding-left: 8px;
      font-size: 12px;
      margin-bottom: 0;
      &--time{
          font-weight: normal;
          padding-left: 0;
      }
  }

  &__read-more-block{
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: bold;
      line-height: 0;
      border: none;
      background: none;
      display: block;
      outline: none;
  }

  &__read-more-icon{
      font-size: 18px;
  }

  &__read-more-text{
      position: relative;
      bottom: 3px;
  }

  &__vertical-block{
      border-left: 1px solid $comments-other-grey;
      padding: 2px 15px 17px;
      margin-left: -17.5px;
      margin-top: -8px;
      &--last{
          border:none;
      }
      &--first{
          border-left: 1px solid $comments-other-grey;
      }
  }
  &__options-block{
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0;
  }
  &__options-block-list{
      width: 47px;
  }
  &__edit-comment{
      display: block;
      width: 100%;
      height: 133px;

  }
  &__button-save{
      height: 32px;
      width: 109px;
      font-size: 12px;
      padding: 0;
      margin-right: 11px;
  }
  &__button-cancel{
      height: 32px;
      width: 127px;
      font-size: 12px;
      padding: 0;
      margin-right: 9px;
  }
  //////////////////////////////////////
  /////////////////Custom//////////////
  ////////////////////////////////////
  &__custom-header-block{
      border-bottom: 2px solid $border-modal;
      display: block;
      width: 100%;
      overflow: hidden;
      padding-top: 3px;
  }
  &__custom-block-edit-comment{
      display: block;
      width: 100%;
      height: 234px;
      &--edit{
          height: 195px;
      }
      &--open{
          height: 214px;
      }
  }
  &__custom-author-data{
      display: block;
      // width: 50%;
      width: calc(100% - 60px);
      float: left;
  }
  &__custom-author{
      color: $custom-black-landing;
      font-family: 'lato_regular', sans-serif;
      font-size: 11px;
      font-weight: bold;
      background: none;
      text-transform: uppercase;
      margin-bottom: 2px;
  }
  &__custom-date{
      color: $attachment-custom-grey;
      font-family: 'lato_regular', sans-serif;
      font-size: 11px;
      font-weight: bold;
      margin-bottom: 6px;
      &--hour{
          font-weight: 500;
      }
  }
  &__custom-author-menu{
      display: block;
      // width: 50%;
      width: 60px;
      float: right;
      position: relative;
      top: 5px;
  }
  &__custom-trash-comment{
      font-size: 18px;
      color: $attachment-custom-grey-other;
      float: right;
      position: relative;
      top: 2px;
      margin-left: 13px;
      &:hover{
          cursor: pointer;
          color: $custom-black-landing;
      }
  }
  &__custom-edit-comment{
      font-size: 22px;
      color: $attachment-custom-grey-other;
      float: right;
      &:hover{
          cursor: pointer;
          color: $custom-black-landing;
      }
  }
  &__custom-comment-text{
      color: $attachment-custom-grey-other;
      font-family: 'lato_regular', sans-serif;
      line-height: 23px;
      padding: 0;
      margin: 12px 0 4px;
      font-size: 13px;
      &--only-last{
          max-height: 65px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          position: relative;
      }
  }
  &__custom-add-comment-text{
      height: 118px;
      width: 100%;
      border-radius: 8px;
      background-color: $background-grey;
      box-shadow: inset 0 1px 5px 0 $custom-border-grey;
      border: none;
      padding: 5px 15px;
      color: $attachment-custom-grey;
      font-family: 'lato_regular', sans-serif;
      font-size: 13px;
      line-height: 23px;
      resize: none;
      margin-bottom: 15px;
      outline: none;
  }

  &__buttons-block{
      padding-right: 9px;
      text-align: right;
  }
  ///////////////////////////////////////
  /////////////BE///////////////////////
  ////////////////////////////////////////
  &__menu{
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      display: block;
      margin-right: 3px;
      border: 1px solid;
      height: 42px;
      width: 76px;
      border-top-left-radius: 67px;
      border-bottom-left-radius: 67px;
      z-index: 1;
      margin-left: -78px;
      float: right;
      position: relative;
  }
}

.compliance{
  &__dark-blue{
      .box-comments {
          &__add-comment-icon{
              font-size: 11px;
              color: $primary-color;
              float: left;
              padding-left: 8px;
              padding-right: 8px;
              margin-top: 5px;
          }
          &__add-comment-text{
              height: 78px;
              border: 0;
              border-bottom: 1px solid $primary-color;
              padding: 17px 26px;
              color: $secondary-color;
          }
          &__add-comment-title{
              background-color: $background-grey-other;
              color: $primary-color;
              padding: 4px 8px;
              display: inline-block;
              width: auto;
          }
          &__add-icon{
              color: $primary-color;
          }
          &__author{
              background-color: $background-grey-other;
              color: $primary-color;
          }
          &__list-item{
              @include list-item($box-comment-border, $block-comment-hover);
          }
          &__comment-text{
              color: $secondary-color;
          }
          &__comment-bubble{
              color: $dot-off;
              &--first{
                  opacity: 1;
                  color: $primary-color;
              }
          }
          &__paragraph{
              color: $primary-color;
          }
          &__read-more-block{
              color: $primary-color;
          }
          &__menu{
              @include menu($background-grey-other, $menu-icons-border, $primary-color);
          }
      }
  }
  &__steel-blue,
  &__uk-steel-blue{
      .box-comments {
          &__add-comment{
              display: none;
          }

          &__add-comment-text{
              @include add-comment-text($secondary-color-2, $text-field-color-2);
          }
          &__add-comment-title{
              color: $primary-color;
              @include add-comment-title($background-grey-disabled-2, $primary-color-2)
          }
          &__add-icon{
              color: $border-hover-2;
          }
          &__author{
              color: $grey-icons-2
          }
          &__list-item{
              @include list-item($third-color-2, $block-hover-2);
          }
          &__comment-text{
              color: $primary-color-2;
          }
          &__comment-bubble{
              color:$secondary-color-2;
              &--first{
                  opacity: 1;
                  color: $border-hover-2;
              }
          }
          &__paragraph{
              color: $primary-color-2;
          }
          &__read-more-block{
              color: $border-hover-2;
          }
          &__menu{
              @include menu($secondary-color-2, $secondary-color-2, $border-hover-2);
          }
      }
  }
  &__icam-red{
    .box-comments {
        &__add-comment {
            display: none;
        }
        &__add-comment-text{
            @include add-comment-text($border-block-icam, $text-field-color-icam);
        }
        &__add-comment-title{
            @include add-comment-title($background-grey-disabled-icam, $custom-black-landing)
        }
        &__add-icon{
            color: $primary-color-icam;
        }
        &__author{
            color: $secondary-color-icam;
        }
        &__list-item{
            @include list-item($third-color-icam, $hover-block-icam);
        }
        &__comment-text{
            color:  $custom-black-landing;
        }
        &__comment-bubble{
            color: $border-block-icam;
            &--first{
                opacity: 1;
                color: $primary-color-icam;
            }
        }
        &__paragraph{
            color: $custom-black-landing;
        }
        &__read-more-block{
            color: $primary-color-icam;
        }
        &__edit-comment{
            height: 157px;
        }
        &__menu{
            @include menu($primary-color-icam, $primary-color-icam, $snow-white);
        }
    }
  }
  &__gm-law{
      .box-comments {
          &__add-comment{
              display: none;
          }

          &__add-comment-text{
              @include add-comment-text($border-block-gm, $text-field-color-gm);
          }
          &__add-comment-title{
              @include add-comment-title($background-grey-disabled-gm, $custom-black-landing)
          }
          &__add-icon{
              color: $primary-color-gm;
          }
          &__author{
              color: $secondary-color-gm;
          }
          &__list-item{
              @include list-item($third-color-gm, $hover-block-gm);
          }
          &__comment-text{
              color:  $custom-black-landing;
          }
          &__comment-bubble{
              color: $border-block-gm;
              &--first{
                  opacity: 1;
                  color: $primary-color-gm;
              }
          }
          &__paragraph{
              color: $custom-black-landing;
          }
          &__read-more-block{
              color: $primary-color-icam;
              color: $primary-color-gm;
          }
          &__menu{
              @include menu($primary-color-gm, $primary-color-gm, $snow-white);
          }
      }
  }
  &__com-de{
    .box-comments {
        &__add-comment{
            display: none;
        }

        &__add-comment-text{
            @include add-comment-text($border-block-com-de, $text-field-color-com-de);
        }
        &__add-comment-title{
            @include add-comment-title($background-grey-disabled-com-de, $custom-black-landing)
        }
        &__add-icon{
            color: $primary-color-com-de;
        }
        &__author{
            color: $secondary-color-com-de;
        }
        &__list-item{
            @include list-item($third-color-com-de, $hover-block-com-de);
        }
        &__comment-text{
            color:  $custom-black-landing;
        }
        &__comment-bubble{
            color: $border-block-com-de;
            &--first{
                opacity: 1;
                color: $primary-color-com-de;
            }
        }
        &__paragraph{
            color: $custom-black-landing;
        }
        &__read-more-block{
            color: $primary-color-icam;
            color: $primary-color-com-de;
        }
        &__menu{
            @include menu($primary-color-com-de, $primary-color-com-de, $snow-white);
        }
    }
  }
  &__com-fr{
    .box-comments {
        &__add-comment{
            display: none;
        }

        &__add-comment-text{
            @include add-comment-text($border-block-com-fr, $text-field-color-com-fr);
        }
        &__add-comment-title{
            @include add-comment-title($background-grey-disabled-com-fr, $custom-black-landing)
        }
        &__add-icon{
            color: $primary-color-com-fr;
        }
        &__author{
            color: $secondary-color-com-fr;
        }
        &__list-item{
            @include list-item($third-color-com-fr, $hover-block-com-fr);
        }
        &__comment-text{
            color:  $custom-black-landing;
        }
        &__comment-bubble{
            color: $border-block-com-fr;
            &--first{
                opacity: 1;
                color: $primary-color-com-fr;
            }
        }
        &__paragraph{
            color: $custom-black-landing;
        }
        &__read-more-block{
            color: $primary-color-icam;
            color: $primary-color-com-fr;
        }
        &__menu{
            @include menu($primary-color-com-fr, $primary-color-com-fr, $snow-white);
        }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1355px){
  .box-comments{
      &__comment-block{
          margin-top: -58%;
          padding: 10px;
      }
  }
}

@media(min-width: 1000px) and (max-width: 1450px){
  .box-comments{
      &__comment-block{
          &--field{
              position: relative;
              width: 97%;
              float: right;
              margin-right: -12%;
              border-left: 1px solid $comments-grey-opacity;
              padding: 0px;
              height: 100%;
              margin-top: -2px;
          }
      }
  }
}


/////////////////////////////////
//inc icam - MADiaz
