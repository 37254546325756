.channel-configuration {
    &__block {
        border: 1px solid $custom-black-landing;
        min-height: 248px;
        width: 100%;
        // margin: 50px 0;
        margin: 0 0 50px 0;
    }

    &__header {
        padding: 9px 20px 0;
        height: 60px;
        width: 100%;
    }

    &__header-title {
        position: relative;
        float: left;
    }

    &__icon-title {
        font-size: 30px;
        position: relative;
        top: 5px;
        margin-right: 12px;
    }

    &__name-title {
        margin: 0;
        display: inline;
        font-family: MTTMilano, sans-serif;
        font-size: 25px;
        font-weight: 900;
    }

    &__header-options {
        font-family: MTTMilano-Bold, sans-serif;
        font-weight: 500;
        position: relative;
        float: right;
        top: 14px;
    }

    &__header-option-list {
        position: relative;
        float: left;

        &:hover {
            cursor: pointer;
        }

        &--margin-left {
            margin-left: 30px;
        }
    }

    &__header-options-icon {
        font-size: 20px;
        position: relative;
        top: 5px;
        margin-left: 4px;
    }

    &__block-info-corporative-header{
        padding-left: 28px;
        height: 78px;
    }

    &__text-info-corporative-header{
        color: $attach-icon-grey;
        font-family: MTTMilano, sans-serif;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;
    }

    &__body {
        height: 190px;
        width: 100%;
        position: relative;
        padding: 0 13px;
        display: block;
        margin: 6px 0 8px;
    }

    &__body-option {
        position: relative;
        float: left;
        height: 190px;
        max-width: 264px;
        background-color: $background-color-states;
        padding: 4px 5px;
        margin: 0 5px;
        width: calc(20% - 10px);
    }

    &__title-box {
        font-family: MTTMilano-Bold, sans-serif;
        font-size: 16px;
        margin: 4px 0;
        height: 35px;
    }

    &__text-no-configurate {
        margin: 0px 10px 0px 0px;
        font-family: Arial, sans-serif;
        font-size: 11px;
    }

    &__block-configurate {
        max-height: 138px;
        overflow: auto;
        position: relative;
        width: 100%;
    }

    &__text-task-paragraph {
        font-family: MTTMilano, sans-serif;
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 3px;
    }

    &__text-task-configuration {
        font-family: Arial, sans-serif;
        font-size: 12px;
    }

    &__col-list {
        height: 46px;
        font-family: Arial, sans-serif;
        font-size: 12px;
        border-bottom: 1px solid $custom-black-landing;
        width: 100%;
        padding: 12px 1px;
        &:first-child {
            border-top: 1px solid $custom-black-landing;
        }
        &--more-elements {
            width: calc(100% - 2px);
        }
    }

    &__icon-list {
        font-size: 20px;
        margin-right: 8px;
        position: relative;
        float: left;
    }

    &__cell-block {
        width: calc(100% - 28px);
        float: left;
        top: 3px;
    }

    &__col-text {
        margin: 0;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &__box-color-corporative {
        height: 20px;
        width: 20px;
        border: 1px solid $background-grey-disabled-2;
        position: relative;
        float: left;
        margin-right: 1px;
        top: 7px;
        &--header{
            top: 0;
        }
    }

    &__block-logo {
        position: relative;
        width: 100%;
        clear: both;
        top: 15px;
    }

    &__img-logo {
        height: 30px;
        &--header{
            margin-left: 20px;
        }
    }

    &__text-configurate-information {
        font-family: MTTMilano, sans-serif;
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 20px;
    }

    &__protection-data-text{
        max-height: 135px;
        padding-right: 14px;
        color: $attach-icon-grey;
        font-family: MTTMilano, sans-serif;
        font-size: 12px;
        line-height: 20px;
        overflow-y: auto;
        overflow-wrap: break-word;
        overflow-x: hidden;
    }
  }

.compliance{
    &__dark-blue{
        .channel-configuration {
            &__block {
                border-color: $result-search-quote-color;
                color: $pillow-group;
            }
            &__header-options {
                color: $primary-color;
            }
            &__title-box {
                color: $primary-color;
            }
            &__text-task-paragraph {
                color: $attach-icon-grey;
            }
            &__text-task-configuration {
                color: $primary-color;
            }
            &__col-list {
                border-color: $result-search-quote-color;
                &:first-child {
                    border-color: $result-search-quote-color;
                }
            }
            &__icon-list {
                color: $primary-color;
            }
            &__text-configurate-information {
                color: $secondary-color;
            }
        }
    }
    &__steel-blue{
        .channel-configuration {
            &__block {
                border-color: $background-highlight-text-2;
                color: $primary-color-2;
            }
            &__title-box {
                color: $border-bottom-2;
            }
            &__text-task-paragraph {
                color: $grey-icons-2;
            }
            &__text-task-configuration {
                color: $primary-color-2;
            }
            &__col-list {
                border-color: $secondary-color-2;
                &:first-child {
                    border-color: $secondary-color-2;
                }
            }
            &__icon-list {
                color: $border-hover-2;
            }
            &__text-configurate-information {
                color: $grey-icons-2;
            }
        }
    }
    &__icam-red{
        .channel-configuration {
            &__block {
                background-color: $snow-white;
                border: none;
                box-shadow: $box-shadow-icam;
                min-height: 265px;
            }
            &__header-title {
                color: $title-text-icam;
            }
            &__header-options {
                color: $custom-black-landing;
            }
            &__header-options-icon {
                color: $primary-color-icam;
            }
            &__title-box {
                color: $primary-color-icam;
            }
            &__text-task-paragraph {
                color: $secondary-color-icam;
            }
            &__text-task-configuration {
                color: $custom-black-landing;
            }
            &__col-list {
                border-color: $border-block-icam;
                &:first-child {
                    border-color: $border-block-icam;
                }
            }
            &__icon-list {
                color: $primary-color-icam;
            }
            &__text-configurate-information {
                color: $secondary-color-icam;
            }
            &__body-option {
                width: calc(25% - 10px);
                max-width: 332px;
            }
        }
    }
    &__gm-law{
        .channel-configuration {
            &__block {
                background-color: $snow-white;
                border: none;
                box-shadow: $box-shadow-gm;
                padding-bottom: 1px;
            }
            &__header-title {
                color: $title-text-gm;
            }
            &__header-options {
                color: $custom-black-landing;
            }
            &__header-options-icon {
                color: $primary-color-icam;
                color: $primary-color-gm;
            }
            &__title-box {
                color: $primary-color-gm;
            }
            &__text-task-paragraph {
                color: $secondary-color-gm;
            }
            &__text-task-configuration {
                color: $custom-black-landing;
            }
            &__col-list {
                border-color: $border-block-gm;
                &:first-child {
                    border-color: $border-block-gm;
                }
            }
            &__icon-list {
                color: $primary-color-gm;
            }
            &__text-configurate-information {
                color: $secondary-color-gm;
            }
        }
    }
    &__com-de{
      .channel-configuration {
          &__block {
              background-color: $snow-white;
              border: none;
              box-shadow: $box-shadow-com-de;
              padding-bottom: 1px;
          }
          &__header-title {
              color: $title-text-com-de;
          }
          &__header-options {
              color: $custom-black-landing;
          }
          &__header-options-icon {
              color: $primary-color-icam;
              color: $primary-color-com-de;
          }
          &__title-box {
              color: $primary-color-com-de;
          }
          &__text-task-paragraph {
              color: $secondary-color-com-de;
          }
          &__text-task-configuration {
              color: $custom-black-landing;
          }
          &__col-list {
              border-color: $border-block-com-de;
              &:first-child {
                  border-color: $border-block-com-de;
              }
          }
          &__icon-list {
              color: $primary-color-com-de;
          }
          &__text-configurate-information {
              color: $secondary-color-com-de;
          }
      }
  }
  &__com-fr{
    .channel-configuration {
        &__block {
            background-color: $snow-white;
            border: none;
            box-shadow: $box-shadow-com-fr;
            padding-bottom: 1px;
        }
        &__header-title {
            color: $title-text-com-fr;
        }
        &__header-options {
            color: $custom-black-landing;
        }
        &__header-options-icon {
            color: $primary-color-icam;
            color: $primary-color-com-fr;
        }
        &__title-box {
            color: $primary-color-com-fr;
        }
        &__text-task-paragraph {
            color: $secondary-color-com-fr;
        }
        &__text-task-configuration {
            color: $custom-black-landing;
        }
        &__col-list {
            border-color: $border-block-com-fr;
            &:first-child {
                border-color: $border-block-com-fr;
            }
        }
        &__icon-list {
            color: $primary-color-com-fr;
        }
        &__text-configurate-information {
            color: $secondary-color-com-fr;
        }
    }
}
}
