@import '../../../../assets/scss/colors';
@mixin color($color) {
  color: $color;
}

.corporative-information-modal{
  width: 100%;
  display: block;
  overflow: hidden;
  //min-height: 630px;
  min-height: 685px;
  &__window {
      & .modal-dialog {
          & .modal-content {
              //min-height: 630px;
              min-height: 685px;
              border-radius: 0px;
              height: 799px;
          }
      }
  }

  &--show-notification{
      background: $background-otro-mas;
  }

  &__body-block{
      height: 625px;
      width: 100%;
      display: block;
      position: relative;
      float: left;
  }

  &__block-body-fields{
      //padding: 35px 37px 25px;
      padding: 20px 70px 25px;
      width: 100%;
      height: 100%;
      position: relative;
      float: left;
  }

  &__buttons-block {
      display: block;
      width: calc(100% - 140px);
      text-align: right;
      position: absolute;
      //bottom: 20px;
      bottom: 25px;
      padding-right: 0px;
      right: 70px;
  }

  &__steps-block{
      position: relative;
      float: left;
      width: 100%;
  }

  &__block-company{
      margin-top: 10px;       // JRSJ 11/01/2021
      width: 100%;
      position: relative;
      float: left;
  }

  &__configure-company-block{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
  }
  &__configure-company-inner-block{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 4px;
  }
  &__half-block{
    width: 50%;
  }

  &__textarea-block{
      position: relative;
      float: left;
      height: 290px;
      width: 50%;
      padding-left: 5px;
  }

  &__show-text-area{
      height: 251px;
      width: 86%;
      margin-left: 7.2%;
      resize: none;
  }

  &__block-textarea{
      height: 100%;
      // & .mce-panel{
      //     & .mce-stack-layout-item.mce-first{
      //         background-color: $snow-white;
      //     }
      // }
      // & .mce-btn{
      //     background: $snow-white;
      // }
      // & .mce-edit-area {
      //     border-top: transparent;
      // }
  }

  &__block-text-rich{
      height: 220px;
      // & .mce-panel{
      //     & .mce-stack-layout-item.mce-first{
      //         background-color: $snow-white;
      //     }
      // }
      // & .mce-btn{
      //     background: $snow-white;
      // }
      // & .mce-edit-area {
      //     height: 183px;
      //     width: 100%;
      //     display: block;
      //     border-top: transparent;
      // }
      // & .mce-edit-area > iframe{
      //     height: 100% !important;
      //     // width: calc(100% - 1px);
      // }
  }

  &__button-model-block{
      float: left;
      position: relative;
      margin-top: 11px;
      &:hover{
          cursor: pointer;
      }
      &--disabled{
          opacity: 0.5;
          &:hover{
              cursor: default;
          }
      }

  }

  &__button-vision{
      border: 0;
      outline: 0;
      background: transparent;
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: bold;
      color: $primary-color;
      padding-left: 5px;
  }
  &__icon-vision{
      font-size: 20px;
      color: $primary-color;
      position: relative;
      float: left;
  }

  &__form-block{
      height: 460px;
      //height: 435px;
      // overflow: auto;
      width: 100%;
      // padding: 20px 38px 0 40px;
      padding: 0;
      &--configure-mail{
          padding: 0px 28px 0px 40px;
      }
  }

  &__block-configure-mail{
      width: 100%;
      position: relative;
      float: left;
      &--active{
          height: 435px;
      }
  }

  &__block-fields-left{
      position: relative;
      float: left;
      width: 50%;
      padding-right: 5px;
  }

  &__block-fields-right{
      position: relative;
      float: left;
      width: 50%;
      padding-left: 5px;
  }

  &__information-mail{
      margin: 20px 0px;
      color: $create-title;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-style: italic;
      line-height: 16px;
      &--new-channel{
        margin: 20px 10px;
      }

  }

  &__block-line-data{
      position: relative;
      float: left;
      width: 100%;
      max-height: 70px;
      &--some-errors{
          max-height: 92px;
      }
  }

  &__error-name{
      color: $red;
      position: relative;
      top: -10px;
      left: 2px;
      font-size: 12px;
  }

  &__buttons-checked{
      width: 100%;
      padding-top: 8px;
      position: relative;
      float: left;
  }

  &__link-block{
      width: 100%;
      border: none;
      margin-top: 0;
      min-height: 0;
      position: relative;
      background-color: $background-grey-other;
      color: $primary-color;
      margin-bottom: 7px;
  }

  &__margin-check{
      margin-bottom: 20px;
  }

  &__custom-config{
      width: 60%;
      width: calc(100% - 250px);
      float: left;
      font-size: 12px;
  }
  &__custom-config-right{
      //width: 40%;
      float: right;
      bottom: 0;
      // margin-top: -3px;
  }
  &__info-text{
      // padding-top: 14px;
      // font-family: MTTMilano, sans-serif;
      line-height: 15px;
      //padding-right: 60px;
      padding-left: 72px;
      font-size: 12px;
      height: 55px;
      display: table-cell;
      vertical-align: middle;
      padding-right: 20px;
      font-family: Arial, sans-serif;
      &--with-global{
          padding-right: 10px;
      }
  }


  &__icon{
      float: left;
      position: absolute;
      left: 18px;
      margin-top: 10px;
      font-size: 35px;
      color: $primary-color;
  }
  &__graph-button-line{
      height: 2px;
      background: $primary-color;

  }
  &__no-border{
    & .rich-editor .tox-toolbar__primary{
      border-bottom: 0px;
      background: none;
    }
  }
  &__bottom-box-especial{
    overflow: hidden;
    padding-bottom: 11px;
    &--error{
      border-color: #C43741;
      background-color: #F3D7D9;
      & .common__input-text{
        color: #C43741;
      }
    }
  }
  &__auth-user-box{
      width: calc(100% - 20px);
      display: block;
      background-color: $background-grey-other;
      clear: both;
      margin: 10px 10px 0 10px;
      height: 42px;
  }
  &__auth-user-info{
      color: $repeat-pattern;
      font-family: Arial, sans-serif;
      font-size: 11px;
      float: left;
      margin:13px 0 0 11px;
  }
  &__auth-user-email{
      font-weight: bold;
      color: $primary-color;
      padding-left: 4px;
  }
  &__auth-user-delete{
      float: right;
      font-size: 20px;
      color: $primary-color;
      margin:9px 10px 0 0;
      &:hover{
          cursor: pointer;
      }
  }
  &__button-bottom{
      margin-bottom: 10px;
  }
  &__explain-text{
    color: #565656;
    font-family: Arial;
    font-size: 12px;
    margin: 25px 10px 3px 10px;
}
}
.compliance{
  &__dark-blue{
    .corporative-information-modal{
      &__color{
        @include color($snow-white);
      }
    }
  }
  &__steel-blue{
    .corporative-information-modal{
      &__graph-button-line{
        background-color: $border-hover-2;
      }
      &__color{
        @include color($white-special-2);
      }
      &__link-block{
        color: $primary-color-2;
        background-color: $background-grey-disabled-2;
      }
      &__information-mail{
        color: $grey-icons-2;
      }
      &__icon-vision{
        color: $border-hover-2;
      }
      &__button-vision{
        color: $border-hover-2;
      }
    }
  }
  &__icam-red{
    .corporative-information-modal{
      &__link-block{
        color: $custom-black-landing;
        background-color: $background-grey-disabled-icam;
      }
      &__icon-vision{
        color: $primary-color-icam;
      }
      &__button-vision{
        color: $primary-color-icam;
      }
    }
  }
  &__gm-law{
    .corporative-information-modal{
      &__link-block{
        color: $custom-black-landing;
        background-color: $background-grey-disabled-gm;
      }
      &__icon-vision{
        color: $primary-color-gm;
      }
      &__button-vision{
        color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    .corporative-information-modal{
      &__link-block{
        color: $custom-black-landing;
        background-color: $background-grey-disabled-com-de;
      }
      &__icon-vision{
        color: $primary-color-com-de;
      }
      &__button-vision{
        color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .corporative-information-modal{
      &__link-block{
        color: $custom-black-landing;
        background-color: $background-grey-disabled-com-fr;
      }
      &__icon-vision{
        color: $primary-color-com-fr;
      }
      &__button-vision{
        color: $primary-color-com-fr;
      }
    }
  }
}
