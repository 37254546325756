.generate-document {
    // width: 100%;
    & .modal-dialog {
        & .modal-content {
            height: 400px;
        }
    }

    &--custom{
        & .modal-dialog{
            margin-top: 120px;
            & .modal-content{
                // width: 940px;
                height: 340px;
                border-radius: 6px;
                margin: 0 auto;
            }
        }
    }

    &__delete-icon{
        margin-top: -25px;
    }

    &__template-select{
        width: calc(100% - 142px);
        height: 249px;
        margin: 74px auto 0;
    }

    &__text{
        font-size: 18px;
        font-family: MTTMilano, sans-serif;
        line-height: 24px;
    }

    ////////////////////////////
    ///////////Custom//////////
    //////////////////////////

    &__custom-text{
        color: $custom-black-landing;
        font-family: 'lato_regular', sans-serif;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
    }

    &__custom-template-select{
        width: calc(100% - 142px);
        height: 221px;
        margin: 25px auto 0;
    }

    ////////////////////////////
    /////////Fin Custom////////
    //////////////////////////
}

.compliance{
  &__dark-blue{
    .generate-document {
      &__text{
          color: $donut-text;
      }
    }
  }
  &__steel-blue{
    .generate-document {
      &__text{
          color: $grey-icons-2;
      }
    }
  }
}
