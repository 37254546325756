@mixin task-historic-documents-table-color($firstColor, $secondColor, $thirdColor) {
    border-bottom-color: $firstColor;
    color: $secondColor;
    height: 48px;
    padding-top: 2px;
    padding-left: 9px;
    &:hover{
        background-color: $thirdColor;
    }
}

@mixin task-historic-header-col-color($firstColor) {
    color: $firstColor;
    font-family: MTTMilano, sans-serif;
    font-size: 12px;
    font-weight: 500;
}

.task-historic{
    width: 100%;
    display: block;
    // overflow: hidden;

    &__documents-table{
        display: table;
        width: 100%;
        position: relative;
        float: left;
        height: 42px;
        font-family: Arial, sans-serif;
        font-size: 12px;
        border-bottom: 1px solid ;
        &:hover{
            .attachments-template__documents-table-options--historic{
                 opacity: 1;
            }
        }
        &--document{
            padding-left: 10px;
            padding-top: 15px;
            float: left;
        }
    }

    &__header-col{
        display: table-cell;
        vertical-align: middle;
        &--2{
            width: 20%;
        }
        &--3{
            width: 30%;
        }
        &--4{
            width: 40%;
        }
        &--5{
            width: 50%;
        }
        &--title{
            margin: 0px;
            padding-left: 5px;
        }
    }

    &__historic-block{
        display: block;
        width: 100%;
        overflow: hidden;
        height: 144px;
        overflow-y: auto;
        &--custom{
            height: 159px;
        }
        &--expand{
            height: 541px;
        }
        &--more-elements{
            width: calc(100% + 10px);
        }
    }

    &__historic-table{
        width: 98%;
        height: 152px;
        overflow: hidden;
        margin-left: 1%;
        position: relative;
        float: left;
        margin-top: 17px;
    }

    &__historic-table-header{
        display: table;
        width: 100%;
        border-bottom: 1px solid;
        height: 20px;
        font-size: 13px;
        &--custom{
            padding-bottom: 6px;
            border-bottom: 2px solid $border-modal;
        }
        &--custom-header{
            padding-top: 11px;
        }
    }

    &__menu-options{
        float: right;
        font-size: 18px;
        cursor: pointer;
    }

    &__options-block {
        opacity: 0;
        margin-top: 0px;
        margin-right: -3px;
        float: right;
    }

    &__icon-eye{
        height: 19px;
        padding-right: 5px;
        padding-left: 1px;
        font-size: 24px;
    }

    &__ul-icon{
        width: 50px !important;
    }
}
.compliance{
    &__dark-blue{
        .task-historic{
            &__documents-table{
                border-bottom-color: $bottom-border;
                color: $secondary-color;
                height: 42px;
                &:hover {
                    background-color: $background-grey-other;
                }
            }
            &__documents-table-text{
                padding-left: 10px;
                float: left;
                height: 14px;
                position: relative;
            }
            &__header-col{
                &--title{
                  color: $app-text-color;
                }
            }
            &__historic-block{
                height: 133px;
            }
            &__historic-table-header{
                border-bottom-color: $primary-color;
            }
        }
    }
    &__steel-blue, &__uk-steel-blue{
        .task-historic{
            &__documents-table{
                @include task-historic-documents-table-color($border-bottom-2, $primary-color-2, $block-hover-2);
            }
            &__header-col{
                &--title{
                  @include task-historic-header-col-color($border-hover-2);
                }
            }
            &__historic-table-header{
                border-bottom-color: $border-bottom-2;
            }
        }
    }
    &__icam-red{
        .task-historic{
            &__documents-table{
                @include task-historic-documents-table-color($border-block-icam, $custom-black-landing, $hover-block-icam);
            }
            &__header-col{
                &--title{
                  @include task-historic-header-col-color($primary-color-icam);
                }
            }
            &__historic-table-header{
                border-bottom-color: $primary-color-icam;
            }
        }
    }
    &__gm-law{
        .task-historic{
            &__documents-table{
                @include task-historic-documents-table-color($border-block-gm, $custom-black-landing, $hover-block-gm);
            }
            &__header-col{
                &--title{
                  @include task-historic-header-col-color($primary-color-gm);
                }
            }
            &__historic-table-header{
                border-bottom-color: $primary-color-gm;
            }
        }
    }
    &__com-de{
      .task-historic{
          &__documents-table{
              @include task-historic-documents-table-color($border-block-com-de, $custom-black-landing, $hover-block-com-de);
          }
          &__header-col{
              &--title{
                @include task-historic-header-col-color($primary-color-com-de);
              }
          }
          &__historic-table-header{
              border-bottom-color: $primary-color-com-de;
          }
      }
    }
    &__com-fr{
      .task-historic{
          &__documents-table{
              @include task-historic-documents-table-color($border-block-com-fr, $custom-black-landing, $hover-block-com-fr);
          }
          &__header-col{
              &--title{
                @include task-historic-header-col-color($primary-color-com-fr);
              }
          }
          &__historic-table-header{
              border-bottom-color: $primary-color-com-fr;
          }
      }
    }
    &__landing-custom{
        .task-historic{
          &__documents-table{
              height: 53px;
              border-bottom: 1px solid $box-comment-border-2;
              &:hover{
                  background-color: $attachment-custom-grey-other-2;
                  .task-historic__options-block{
                      opacity: 1;
                  }
              }
          }
          &__documents-table-text{
              color: $attachment-custom-grey;
              font-family: 'lato_regular', sans-serif;
              font-size: 13px;
              padding-top: 0;
              padding-left: 5px;
          }
          &__header-col{
              &--title{
                  color: $custom-black-landing;
                  font-family: 'lato_regular', sans-serif;
                  margin: 0px;
                  padding-left: 5px;
                  font-size: 14px;
              }
          }
        }
    }
}
