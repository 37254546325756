.listen-record{

  &__window{
    & .modal-dialog{
      width: 435px;
        & .modal-content{
            height: 118px;
            width: 412px;
            margin: 0 auto;
        }
    }
  }
  &__button-close-box{
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 12px 0 0;
  }
  &__button-close{
    border: none;
    background: none;
    padding: 0;
  }
  &__icon-close{
    font-size: 16px;
    color: #001978;
  }

  &__body-box{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-content: center;
    align-items: center;
    background-color: #F2F3F8;
    height: 74px;
    width: 396px;
    margin: 7px auto 0;
    border-radius: 4px;
    padding: 12px;
  }

  &__play-button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    background: #001978;
    border: none;
  }
  &__play-button-icon{
    font-size: 28px;
    color: #ffffff;
  }
  &__player-time-block{
    width: 240px;
    margin: 0 40px 0 20px;
  }
  &__modal-button{
    border: none;
    background: none;
    padding: 0 9px 0 0;
    font-size: 20px;
    color: #001978;
    &--right{
      padding: 0;
    }
    &--landing{
      color: #000000;
    }
  }

}

@media (max-width: $max-width-smartphone) { //Smartphone
  .listen-record{
    &__window{
      & .modal-dialog{
        height: 128px;
        width: 355px;
        display: flex;
        margin-top: 50px;
        & .modal-content{
          margin: 0 auto;
          width: 355px;
          height: 128px;
        }
      }
    }
    &__body-box{
      width: 338px;
    }
    &__close-icon{
      font-size: 16px;
    }
    &__player-time-block{
      width: 155px;
    }
    &__close-icon{
      font-size: 20px;
    }
  }
}
