.customize-fields{
  display: block;
  width: 100%;
  padding: 8px 18px 0;
  position: relative;
  &__field-type-icon{
      position: absolute;
      top: 25px;
      left: -5px;
      color: $attach-icon-grey;
      font-size: 16px;
  }
  &__field-private{
      font-size: 20px;
      color: $primary-color;
      float: left;
      margin-top: 12px;
      padding-right: 4px;
  }
  &__body-field-container{
      height: 455px;
      overflow: auto;
      overflow-x: hidden;
      scrollbar-gutter: stable;
      padding-right: 5px;
  }
}
.compliance{
  &__gm-law{
    .customize-fields{
      &__field-type-icon{
        color: $secondary-color-gm;
      }
      &__field-private{
        color: $custom-black-landing;
      }
    }
  }
  &__com-de{
    .customize-fields{
      &__field-type-icon{
        color: $secondary-color-com-de;
      }
      &__field-private{
        color: $custom-black-landing;
      }
    }
  }
  &__com-fr{
    .customize-fields{
      &__field-type-icon{
        color: $secondary-color-com-fr;
      }
      &__field-private{
        color: $custom-black-landing;
      }
    }
  }
}
