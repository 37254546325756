.field-type-task{
  &__task-container{
      margin: 0 ;
      padding: 0 10px;
      min-height: 97px;
      background-color: $background-grey-other;
      margin-top: 7px;
      list-style: none;
  }
  &__task-item{
      display: block;
      width: 100%;
      position: relative;
      padding: 12px 0px 10px;

      &--border{
          border-bottom: 1px solid $upload-document-border;
      }
  }
  &__task-item-menu{
      position: absolute;
      right: -3px;
      top: 10px;
  }
  &__task-item-menu-icon{
      color: $primary-color;
      font-size: 20px;
      &--custom{
          color: $custom-black-landing;
      }
      &--custom-left{
          padding-right: 16px;
      }
      &:hover{
          cursor: pointer;
      }
      &--left{
          padding-right: 2px;
      }
  }
  &__task-item-text{
      margin: 0 0 3px;
      color: $repeat-pattern;
      font-family: Arial, sans-serif;
      font-size: 11px;
      max-width: 90%;
      &--capitalize{
          text-transform: capitalize;
      }
      &--clear{
          clear: both;
      }
      &--custom{
          color: $custom-black-landing;
          font-family: 'MTTMilano', sans-serif;
          font-size: 11px;
          margin: 0 0 5px;
          text-transform: uppercase;
      }
      &--info-custom{
          color: $custom-black-landing;
          font-family: 'lato_regular', sans-serif;
          font-size: 11px;
          font-weight: bold;
          text-transform: none;
      }
  }

  &__task-item-text-info{
      font-family: Arial, sans-serif;
      color: $primary-color;
      font-weight: bold;
      font-size: 11px;
  }
}

.compliance{
  &__icam-red{
    .field-type-task{
      &__task-container{
        background-color: $background-grey-icam;
      }
      &__task-item-menu-icon{
        color: $primary-color-icam;
      }
      &__task-item-text{
        color: $text-field-color-icam;
      }
      &__task-item-text-info{
        color: $custom-black-landing;
      }

    }
  }
  &__gm-law{
    .field-type-task{
      &__task-container{
        background-color: $background-grey-gm;
      }
      &__task-item-menu-icon{
        color: $primary-color-gm;
      }
      &__task-item-text{
        color: $text-field-color-gm;
      }
      &__task-item-text-info{
        color: $custom-black-landing;
      }
    }
  }
  &__com-de{
    .field-type-task{
      &__task-container{
        background-color: $background-grey-com-de;
      }
      &__task-item-menu-icon{
        color: $primary-color-com-de;
      }
      &__task-item-text{
        color: $text-field-color-com-de;
      }
      &__task-item-text-info{
        color: $custom-black-landing;
      }
    }
  }
  &__com-fr{
    .field-type-task{
      &__task-container{
        background-color: $background-grey-com-fr;
      }
      &__task-item-menu-icon{
        color: $primary-color-com-fr;
      }
      &__task-item-text{
        color: $text-field-color-com-fr;
      }
      &__task-item-text-info{
        color: $custom-black-landing;
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue {
    .field-type-task{
      &__task-container{
        background-color: $background-grey-2;
      }
      &__task-item-menu-icon{
        color: $text-field-color-2;
      }
      &__task-item-text{
        color: $text-field-color-2;
      }
      &__task-item-text-info{
        color: $text-field-color-2;
      }
    }
  }
}








