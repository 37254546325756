.related-information-modal {
  & .modal-dialog {
      & .modal-content {
          height: 642px;
          border-radius: 0px;
      }
  }

  &--big {
      & .modal-dialog {
          & .modal-content {
              height: 731px;
              border-radius: 0px;
              width: 1200px;
          }
      }
  }


  &__block-context {
      height: 642px;
      position: relative;
      float: left;
      width: 100%;
  }

  &__block-body {
      position: relative;
      float: left;
      font-size: 12px;
      margin: 0;
      width: 100%;
      height: 557px;
      padding: 40px 75px 0;

      &--big {
          width: 920px;
          height: 640px;
      }
  }

  &__block-body-left {
      width: calc(100% - 920px);
      position: relative;
      float: left;
      padding: 0px 25px 0 35px;
      margin-top: 40px;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      border-right: 1px dotted $blue;
  }

  &__block-breadcrumbs {
      width: 100%;
      height: 40px; // JRSJ 07/10/2020  Se reduce el tamaño ya que se quita la ruta completa de las breadcrumbs, solo queda el nombre del concepto
      color: $primary-color;
      text-align: left;
      padding-top: 5px;
      position: relative;
      float: left;
      overflow: auto;
  }

  &__block-breadcrumbs-text {
      position: relative;
      width: calc(100% - 38px);
      float: left;
      top: -1px;
  }

  &__block-title-selected {
      border-bottom: 3px solid $primary-color;
      margin-bottom: 20px;
  }

  &__element-left {
      height: 18px;
      line-height: 16px;
      margin: 8px 0;
      position: relative;
      float: left;
      width: 100%;
      color: $subcontainer-ground;

      &:hover {
          cursor: pointer;
          color: $primary-color;
      }
  }

  &__icon-map {
      font-size: 20px;
      margin-right: 3px;
      position: relative;
      bottom: 5px;
      height: 20px;
      float: left;
  }

  &__icon-subject {
      font-size: 18px;
      color: $primary-color;
      position: relative;
      float: left;
  }

  &__block-rich-text {
      background-color: $background-color-states;
      padding: 21px 25px 10px 10px;
      position: relative;
      float: left;
      font-family: Arial, sans-serif;
      font-size: 14px;
      line-height: 21px;
      color: $secondary-color;
      max-height: 350px;
      overflow: auto;
      width: 100%;
      min-height: 354px;
  }

  &__product-name {
      opacity: 0.5;
      color: $snow-white;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      float: right;
      position: relative;
      margin-right: 15px;
      text-transform: uppercase;
      top: 6px;
  }

  &__symbol-minus {
      position: relative;
      float: left;
      bottom: 1px;
      font-size: 14px;
      margin: 0 0px 0 4px;
  }

  &__text-breadcrumb {
      display: inline-block;
      max-width: 700px; // JRSJ 28/10/2020  WEB-30646 Migas en popup de información relacionada
      text-overflow: ellipsis;
      overflow: hidden;
  }

  &__text-related-left {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      float: left;
      top: 3px;
      left: 2px;
      max-width: calc(100% - 18px);
  }

  &__title-breadcrumb {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 13px;
      position: relative;
      float: left;
      bottom: 3px;
      display: block;
      right: 2px;
      height: 17px;
      border: none;
      outline: none;
      background-color: transparent;
      text-decoration: underline;
      padding: 0;

      &:hover {
          cursor: default;
      }

      &--title {
          text-transform: uppercase;
          right: 0px;
      }
  }

  &__title-selected {
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 1px;
  }

  &__subtitle-selected {
      opacity: 0.5;
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      margin-bottom: 5px;
  }

  &__title-text-rich {
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      background: none;
  }
}

.compliance {
  &__dark-blue {
    .related-information-modal {
      &__text-related-left--selected {
        color: $primary-color;
      }
    }
  }

  &__steel-blue, &__uk-steel-blue {
    .related-information-modal {
      &__block-body-left {
        color: $primary-color-2;
      }

      &__block-breadcrumbs {
        color: $primary-color-2;
      }

      &__block-title-selected {
        border-bottom-color: $primary-color-2;
      }

      &__icon-subject {
        color: $primary-color-2;
      }

      &__text-related-left--selected {
        color: $primary-color-2;
      }

      &__title-selected {
        color: $primary-color-2;
      }

      &__subtitle-selected {
        color: $primary-color-2;
      }

      &__title-text-rich {
        color: $primary-color-2;
      }
    }
  }

  &__landing-custom{
    .related-information-modal {
      & .modal-dialog {
          margin-top: 120px;

          & .modal-content {
              border-radius: 4px !important;
          }
      }
    }
  }
}

@media print {
  .related-information-modal__title-breadcrumb {
      font-size: 17px;
  }

  .related-information-modal__block-rich-text {
      max-height: none;
      background-color: transparent;
      font-size: 18px;
  }
  a[href]:after {
      content: none;
  }
}

@media (min-width: 1220px){
  .related-information-modal{
      &--big{
          & .modal-dialog{
              width: 1200px;
          }
      }
  }
}

@media (max-width: 1219px){
  .related-information-modal{
      &--big{
          width: 100%;
          & .modal-dialog{
              width: 98%;
              & .modal-content{
                  width: 100%;
              }
          }
      }
      &__block-body-left {
          width: 250px;
      }
      &__block-body--big {
          width: calc(100% - 250px);
      }
  }
}

@media (max-width: 991px){
  .related-information-modal{
      & .modal-dialog{
          width: 98%;
          & .modal-content{
              width: 100%;
          }
      }
  }
}
