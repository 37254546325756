.modal-xl {
  width: 100%;
}

.public-link-poll{
  width: 100%;
  height: 100%;
  padding: 10px;

  &__window {
      & .modal-dialog {
          & .modal-content {
              margin: 0 auto;
              border-radius: 0px;
              height: 756px;
          }
      }
  }

  &__block-info-concept {
      background-color: $no-primary-blue;
      position: relative;
      float: left;
      height: 733px;
      &--show {
          width: 369px;
      }
      &--noshow {
          width: 100px;
      }
  }

  &__block-header-title{
      position: relative;
      text-transform: uppercase;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 18px;
      line-height: 20px;
      color: $primary-color;
  }

  // &__block-textarea{
  //     height: 100%;
  //     & .mce-panel{
  //         & .mce-stack-layout-item.mce-first{
  //             background-color: $snow-white;
  //         }
  //     }
  //     // & .mce-btn{
  //     //     background: $snow-white;
  //     // }
  //     & .mce-edit-area {
  //         border-top: transparent;
  //     }
  //     & .mce-edit-area > iframe{
  //         width: calc(100% - 1px) !important;
  //     }
  // }

  &__form-block-corporative{
      min-height: 435px;
      // overflow: hidden;
      width: 100%;
  }

  &__icon-link {
      display: block;
      font-size: 100px;
      text-align: center;
      color: $snow-white;
      &--compiled {
          font-size: 62px;
      }
  }

  &__icon-header-title{
      font-size: 30px;
      font-weight: normal;
      position: relative;
  }

  &__text-header-title{
      position: relative;
      bottom: 6px;
      left: 15px;
  }

  &__text-info-block {
      display: block;
      margin-left: 60px;
      margin-right: 60px;
  }

  &__title-info {
      text-transform: uppercase;
      color: $snow-white;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      margin: 0;
  }

  &__text-info {
      opacity: 0.7;
      color: $snow-white;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      line-height: 17px;
      margin-top: 13px;
      margin-bottom: 19px;
      padding: 0;
      counter-reset: item;
  }

  &__icon-close {
      float: right;
      color: $primary-color;
      font-size: 20px;
      &:hover {
          cursor: pointer;
      }
  }
  &__buttons-block {
      bottom: 15px;
      text-align: right;
      position: absolute;
      right: 60px;
  }

  &__block-total-steps {
      position: relative;
      float: left;
      padding: 32px 60px 15px 57px;
      width: calc(100% - 369px);
      height: 733px;

      &--no-show {
          width: calc(100% - 100px);
      }
  }

  &__check-block {
      position: absolute;
      bottom: 21px;
      width: calc(100% - 454px);
  }

  &__title-block-steps {
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      position: absolute;
      width: 83%;
  }

  &__switch{
      float: left;
      margin-left: 0px;
  }

  &__block-steps-scroll {
      border: 1px solid $box-comment-border-03;     // JRSJ 18/01/2021
      width: 100%;
      position: relative;
      float: left;
      // overflow-x: auto;
      // overflow-y: hidden;
      top: 36px;                                  // JRSJ 18/01/2021
      //padding: 85px 70px 0;
      padding: 13px 70px 0;
      &--published{
          // padding-top: 53px !important;        // JRSJ 19/01/2021
          top: 0px !important;
      }
  }

  &__block-finish-steps {
      position: relative;
      // float: left;
      width: 757px;
      height: 100%;
      // margin-left: 120px;
      // margin-top: 55px;
      margin: 55px auto 0;
      // &--no-show {
      //     margin-left: 0px;
      // }
  }

  &__link-text{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 13px;
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      padding: 0 11px;
      text-overflow: ellipsis;
      height: 20px;
      margin: 15px 0px 15px 0px;
      white-space: nowrap;
      width: calc(100% - 465px);
      &--step-1{
          width: 100%;
      }
  }

  &__copy-link-block {
      position: relative;
      float: left;
      display: table;
      height: 50px;
      width: 100%;
      &--init{
          width: calc(100% - 33px);
          top: -17px;
      }
  }

  &__icon-step-done {
      font-size: 40px;
      position: absolute;
      left: -67px;
  }

  &__link-description {
      font-family: MTTMilano, sans-serif;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 0px;
  }

  &__block-link-description {
      height: 95px;
      position: relative;
      float: left;
      color: $primary-color;
  }

  &__button-copy-link {
      position: relative;
      // width: 140px;
      float: right;
      margin-top: 20px;
      &--no-first{
        margin-left: 20px;
      }
  }

  &__contacts-block {
      position: relative;
      float: left;
      width: 100%;
      margin-top: 30px;
  }

  &__textarea-link-block {
      position: relative;
      float: left;
      width: 100%;
      margin-bottom: 10px;
  }

  &__block-check-notification {
      position: relative;
      float: left;
  }

  &__icon{
      float: left;
      position: absolute;
      left: 18px;
      // margin-top: 8px;
      margin-top: 10px;
      font-size: 35px;
  }

  &__button-unpublish-link {
      position: relative;
      // width: 140px;
      float: right;
      top: 8px;
      margin-right: 12px;
  }

  &__steps{
      // width: 51%;
      // width: 444px;
      width: 100%;
      margin:auto;
  }

  &__custom-config{
      width: 60%;
      width: calc(100% - 270px);
      float: left;
      font-size: 12px;
  }

  &__info-text{
      // font-family: MTTMilano, sans-serif;
      line-height: 15px;
      padding-left: 72px;
      font-size: 12px;
      height: 55px;
      display: table-cell;
      vertical-align: middle;
      padding-right: 20px;
      margin-top: 15px;
      font-family: Arial, sans-serif;
      &--with-global{
          padding-right: 10px;
      }
  }

  // &__edit-button {
  //     position: absolute;
  //     bottom: 20px;
  //     right: 28px;
  //     border-bottom: 2px solid $primary-color;
  //     font-family: MTTMilano-Bold, sans-serif;
  //     font-size: 11px;
  //     text-transform: uppercase;
  //     padding-left: 5px;
  //     padding-right: 14px;

  //     & > i {
  //         font-size: 18px;
  //         margin-right: 7px;
  //         vertical-align: middle;
  //     }

  //     &:hover{
  //         background-color: $primary-color;
  //         color: $snow-white;
  //     }
  // }
}

.compliance{
  &__dark-blue{
    .public-link-poll{
      &__copy-link-block {
        color: $primary-color;
        background-color: $background-color-states;
      }
    }
  }
  &__steel-blue{
    .public-link-poll{
      &__block-link-description{
        color: $primary-color-2;
      }
      &__copy-link-block{
        background-color: $background-grey-disabled-2;
        color: $primary-color-2;
      }
      &__title-block-steps{
        color: $primary-color-2;
      }
      &__icon-close {
        color:$border-hover-2;
      }
      &__block-info-concept{
        background-color: $primary-color-2;
      }
      &__link-text{
        color: $primary-color-2;
      }
    }
  }
  &__uk-steel-blue{
    .public-link-poll{
    }
  }
  &__icam-red{
    .public-link-poll{
      &__block-link-description{
        color: $custom-black-landing;
      }
      &__icon-step-done{
        color: $primary-color-icam;
      }
      &__copy-link-block{
        background-color: $background-grey-disabled-icam;
        color: $custom-black-landing;
      }
      &__title-block-steps{
        color: $custom-black-landing;
      }
      &__icon-close{
        color: $primary-color-icam;
      }
      &__block-info-concept{
        background-color: $primary-color-icam;
      }

      &__link-text{
            color: $custom-black-landing;
      }
    }
  }
  &__gm-law{
    .public-link-poll{
      &__block-link-description{
        color: $custom-black-landing;
      }
      &__icon-step-done{
        color: $primary-color-gm;
      }
      &__copy-link-block{
        background-color: $background-grey-disabled-gm;
        color: $custom-black-landing;
      }
      &__title-block-steps{
        color: $custom-black-landing;
      }
      &__icon-close{
        color: $primary-color-gm;
      }
      &__block-info-concept{
        background-color: $primary-color-gm;
      }
      &__link-text{
        color: $custom-black-landing;
      }

    }
  }
  &__com-de{
    .public-link-poll{
      &__block-link-description{
        color: $custom-black-landing;
      }
      &__icon-step-done{
        color: $primary-color-com-de;
      }
      &__copy-link-block{
        background-color: $background-grey-disabled-com-de;
        color: $custom-black-landing;
      }
      &__title-block-steps{
        color: $custom-black-landing;
      }
      &__icon-close{
        color: $primary-color-com-de;
      }
      &__block-info-concept{
        background-color: $primary-color-com-de;
      }
      &__link-text{
        color: $custom-black-landing;
      }

    }
  }
  &__com-fr{
    .public-link-poll{
      &__block-link-description{
        color: $custom-black-landing;
      }
      &__icon-step-done{
        color: $primary-color-com-fr;
      }
      &__copy-link-block{
        background-color: $background-grey-disabled-com-fr;
        color: $custom-black-landing;
      }
      &__title-block-steps{
        color: $custom-black-landing;
      }
      &__icon-close{
        color: $primary-color-com-fr;
      }
      &__block-info-concept{
        background-color: $primary-color-com-fr;
      }
      &__link-text{
        color: $custom-black-landing;
      }

    }
  }
}

@media (max-width: 1200px){
  .public-link-poll{
      &__window{
          & .modal-dialog{
              & .modal-content{
                  // width: 800px;
                  width: 95%;
              }
          }
      }
      &__block-finish-steps {
          width: 100%;
      }
      &__icon-step-done {
          left: -50px;
      }
      &__block-check-notification{
          top: 35px;
      }
      &__block-steps-scroll{
          padding-bottom: 15px;
          &--show-info{
              padding: 85px 0px 0;
          }
      }
      &__buttons-block{
          right: 20px;
      }
      &__check-block{
          width: calc(100% - 388px);
      }
  }
}

@media (max-width: 1270px){
  .public-link-poll{
      &__check-block{
          &--show-info{
              width: 100%;
              bottom: 60px;
          }
      }
  }
}

@media (min-width: 1201px) and (max-width: 1366px) {
  .public-link-poll{
      &__window{
          & .modal-dialog{
              & .modal-content{
                  // width: 985px;
                  width: 95%;
                  max-width: 1200px;
              }
          }
      }
      &__block-finish-steps {
          width: 675px;
      }
      &__icon-step-done {
          left: -50px;
      }
      &__block-steps-scroll{
          &--show-info{
              //padding: 85px 0px 0;
              padding: 10px 0px 0;
          }
      }
      &__buttons-block{
          right: 20px;
      }
      &__check-block{
          width: calc(100% - 388px);
      }
      &__link-text{
          &--short{
              width: 370px;
          }
      }
      // &__steps{
      //     // width: 56% !important;
      //     &--more-info{
      //         width: 66% !important;
      //     }
      // }

  }
}
@media (min-width: 1367px) and (max-width: 1500px){
  .public-link-poll__window{
      & .modal-dialog{
          & .modal-content{
              // width: 1200px;
              width: 95%;
          }
          & .public-link-poll__modal-size{
              width: 1231px;
          }
      }
  }
  // .public-link-poll__steps{
  //     width: 66% !important;
  // }

  .public-link-poll__icon-step-done {
      left: -50px;
  }
}
@media (min-width: 1501px){
  .public-link-poll__window{
      & .modal-dialog{
          & .modal-content{
              width: 1500px;
          }
          & .public-link-poll__modal-size{
              width: 1231px;
          }
      }
  }
}

@media (max-width: 987px){
  .public-link-poll{
      &__check-block{
          width: 100% ;
          bottom: 60px;
      }
  }
}

@media (max-width: 1140px){
  .public-link-poll{
      &__check-block{
          &--published{
              width: 100%;
              bottom: 60px;
          }
      }
  }
}

@media (max-width: 1470px){
  .public-link-poll{
      &__check-block{
          &--show-info-published{
              width: 100%;
              bottom: 60px;
          }
      }
  }
}
