@import '../../../../assets/scss/colors';

.new-custom-report {
  &__block-body {
      margin: 0 41px;
  }

  &__header {
      height: 107px;
      display: flex;
      justify-content: space-between;
  }

  &__new-report-header {
      margin: 16px 20px 20px 56px;
  }

  &__wizzard-header {
      text-align: center;
      width: 100%;
      position: absolute;
      top: 20px;
  }

  &__close-header {
      width: 195px;
      float: right;
      margin-right: 30px;
  }

  &__close-icon-header {
      color: $primary-color;
      margin-top: 13px;
      font-size: 17px;
  }

  &__predefined-report-header {
      margin: 16px 20px 20px 56px;
      display: flex;
  }


  &__predefined-report-header-labels {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 3px;
  }

  &__predefined-report-icon-header {
      font-size: 19px;
      color: $subcontainer-ground;
  }

  &__predefined-report-label-header {
      margin-left: 8px;
      font-family: MTTMilano-Bold, sans-serif;
      color: $subcontainer-ground;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 16px;

      &--alone{
          position: relative;
          bottom: 2px;
      }
  }

  &__predefined-report-label-header-subtitle {
      margin-left: 8px;
      font-family: MTTMilano-Bold, sans-serif;
      color: $primary-color;
      font-size: 11px;
      text-transform: uppercase;
  }

  &__block-info{
      width: 100%;
  }

  &__body-block{
      position: relative;
      width: 100%;
      float: right;
  }

  &__buttons-block {
      margin-top: 40px;
      margin-right: 40px;
  }
}

.compliance{
  &__icam-red {
    .new-custom-report{
      &__close-icon-header {
        color: $primary-color-icam;
      }
    }
  }

  &__gm-law {
    .new-custom-report{
      &__close-icon-header {
        color: $primary-color-gm;
      }

      &__predefined-report-icon-header {
        color: $secondary-color-gm;
      }

      &__predefined-report-label-header {
        color: $secondary-color-gm;
      }

      &__predefined-report-label-header-subtitle {
        color: $custom-black-landing;
      }
    }
  }
  &__com-de{
    .new-custom-report{
      &__close-icon-header {
        color: $primary-color-com-de;
      }

      &__predefined-report-icon-header {
        color: $secondary-color-com-de;
      }

      &__predefined-report-label-header {
        color: $secondary-color-com-de;
      }

      &__predefined-report-label-header-subtitle {
        color: $custom-black-landing;
      }
    }
  }
  &__com-fr{
    .new-custom-report{
      &__close-icon-header {
        color: $primary-color-com-fr;
      }

      &__predefined-report-icon-header {
        color: $secondary-color-com-fr;
      }

      &__predefined-report-label-header {
        color: $secondary-color-com-fr;
      }

      &__predefined-report-label-header-subtitle {
        color: $custom-black-landing;
      }
    }
  }
}
