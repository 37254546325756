.export-result-report{
  & .modal-dialog{
      & .modal-content{
          height: 605px;
          border-radius: 0px;
      }
  }

  &__block-body{
      padding: 60px 265px 0;
      position: relative;
      height: 440px;
  }

  &__block-switch{
      margin: 5px 0;
      display: block;
      position: relative;
      float: left;
      width: 100%;
      height: 21px;
      margin-top: 8px;
  }

  &__block-export-fields-open{
      position: absolute;
      top: 93px;
      width: 100%;
      height: 510px;
      z-index: 1;
      padding: 0 71px;
      background-color: $snow-white;
  }

  &__block-selection-fields{
      float: right;
      background-color: $background-grey-other;
      color: $primary-color;
      text-transform: uppercase;
      font-size: 10px;
      font-family: Arial, sans-serif;
      // width: 100px;
      height: 21px;
      padding: 4px 4px 4px 10px;
  }
}
.compliance{
  &__icam-red{
      .export-result-report{
          &__block-selection-fields{
              color: $custom-black-landing;
              background-color: $background-grey-disabled-icam;
          }
      }
  }
  &__gm-law{
      .export-result-report{
          &__block-selection-fields{
              color: $custom-black-landing;
              background-color: $background-grey-disabled-gm;
          }
      }
  }
  &__com-de{
    .export-result-report{
        &__block-selection-fields{
            color: $custom-black-landing;
            background-color: $background-grey-disabled-com-de;
        }
    }
}
&__com-fr{
  .export-result-report{
      &__block-selection-fields{
          color: $custom-black-landing;
          background-color: $background-grey-disabled-com-fr;
      }
  }
}
}
