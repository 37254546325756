.relationship-reason-modal{
  &__window{
      & .modal-dialog{
          & .modal-content{
              height: 390px;
              border-radius: 0px;
          }
      }
  }
}
