.process-general-task {
  padding: 12px 12px 24px 12px;

  &__window {
      & .modal-content {
          // height: 669px;
          height: 755px;
          position: relative;
          border-radius: 0px;
      }
  }
  // & .common__modal-header {
  //     margin: 0;
  //     width: auto;
  // }
  &__body {
      padding: 19px 60px 0;
      height: 580px;
      overflow-y: auto;
  }

  &__field-wrapper {
      margin: 5px 0px;
      min-height: 50px;
      position: relative;
      width: 100%;

      &--with-borders {
          border: 1px solid $border-color-other;
      }
  }

  &__field-switch{
      margin-top: 20px;
      display: block;
  }

  &__description {
      height: auto;

      & textarea {
          height: 125px;
      }

      // & .mce-panel {
      //     border: none;
      // }
  }

  &__states-block {
      margin: 5px 0px;
      position: relative;
      width: 100%;
      height: auto;
      border: 1px solid;
  }

  &__states-title-steel-blue{
      color: $primary-color-2;
      font-family: 'MTTMilano-Bold', sans-serif;
      font-size: 14px;
      text-transform: none;
  }

  &__states-list{
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      max-height: 66px;
      overflow-y: auto;
  }

  &__states-item{
      display: inline-block;
      border: 2px solid;
      border-radius: 15px;
      padding: 0 24px;
      margin-left: 10px;
      margin-bottom: 9px;
  }

  &__footer {
      position: absolute;
      bottom: 24px;
      left: 73px;
      right: 73px;
      text-align: right;
      // & .common__underlined-button {
      //     margin-left: 3px;
      // }
  }
  &__separate-block{
      margin: 20px 0;
  }
  &__separate-switch-element{
      margin-bottom: 10px;
      display: block;
  }
  &__container-border{
     border: 1px solid #CCD1E4;
  }
  &__container-top{
    top: -10px
  }
  &__subcontainer-icons-block {
      position: absolute;
      top: 9px;
      right: 7px;
  }

}
.compliance{
  &__dark-blue{
    .process-general-task {
      &__states-block {
        background-color: $background-color-states;
        border-color: $border-color-other;
      }
      &__states-item{
        border-color:$subcontainer-ground;
      }

    }
  }
  &__steel-blue{
    .process-general-task {
      &__field-wrapper {
        border-left-color: transparent;
      }
      &__states-block {
        border-color: $secondary-color-2;
        background: $background-grey-disabled-2;
      }
      &__states-item{
        color: $primary-color-2;
        font-family: Arial, sans-serif;
        font-size: 12px;
        border-color: $border-color-other-2;
        text-align: center;
      }
    }
  }
  &__icam-red{
    .process-general-task {
      &__states-block {
        border-color: $border-block-icam;
        background: $background-grey-disabled-icam;
    }
    }
  }
  &__gm-law{
    .process-general-task {
      &__states-block {
        border-color: $border-block-gm;
        background: $background-grey-disabled-gm;
      }
    }
  }
  &__com-de{
    .process-general-task {
      &__states-block {
        border-color: $border-block-com-de;
        background: $background-grey-disabled-com-de;
      }
    }
  }
  &__com-fr{
    .process-general-task {
      &__states-block {
        border-color: $border-block-com-fr;
        background: $background-grey-disabled-com-fr;
      }
    }
  }
}
