@mixin field-type-list-numerical-list-item-color($firstColor, $secondColor, $thirdColor, $fourthColor) {
  border-color: $firstColor;
  color: $secondColor;
  &:hover{
      background-color: $thirdColor;
      // border-left: 1px solid;
      border-color: $fourthColor;
      color: $secondColor;
  }
}

@mixin field-type-list-numerical-list-item-selected($backgroundColor, $borderColor, $textColor) {
  background-color: $backgroundColor;
  border-color: $borderColor;
  color: $textColor;
}

@mixin field-type-list-numerical-list-item-custom($borderColor, $backgroundColor) {
  &:hover{
      border-color: $borderColor !important;
      background-color: $backgroundColor !important;
  }
}

@mixin field-type-list-numerical-list-item-disabled($borderColor, $textColor, $backgroundColor) {
  border-color: $borderColor;
  color: $textColor !important;
  background-color: $backgroundColor !important;
  cursor: not-allowed;
  &:hover{
      cursor: not-allowed;
      color: $textColor !important;
      background-color: $backgroundColor !important;
      border-color: $borderColor !important;
  }
}

// @mixin field-type-list-numerical-list-item-selected-disabled($borderColor, $textColor, $backgroundColor) {

// }

.field-type-list {
    &__block-buttons {
        width: 100%;
        height: 100%;
        // padding: 20px 10px 16px;
        padding: 5px;
        // display: table;
        // min-height: 364px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
    }

    &__block-inner-buttons {
        // display: table-cell;
        width: 33%;
        padding-right: 2px;

        &:last-child {
            margin-right: 0px;
        }
    }

    &__list-isbutton {
        // width: 100%;
        // position: relative;
        // height: 100%;
        // background-color: $background-grey-icam;
        // box-shadow: $box-shadow-icam;
        // border-radius: 2px;
        // padding-bottom: 98px;

        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        height: 100%;
        // background-color: $background-grey-icam;
        box-shadow: $box-shadow-icam;
        border-radius: 2px;
        padding: 20px 20px 70px;
        background-color: $snow-white;
        &--selected {
            background-color: $third-color-icam;
        }

        &--disabled {
            background-color: $background-grey;
        }

    }

    // &__block-inner-buttons {
    //     width: 100%;
    //     padding: 6px;

    //     &--icam-red {
    //         padding: 2px;
    //     }
    // }

    &__coming-icon {
        width: 80px;
        margin-bottom: 32px;
    }

    &__title-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        border-radius: 25px;
        font-size: 25px;
        margin-bottom: 20px;
        background-color: $third-color-icam;
        color: $primary-color-icam;

        &--less-info {
            height: 36px;
            width: 36px;
            min-width: 36px;
            font-size: 20px;
            border-radius: 18px;
        }

        &--selected {
            color: $snow-white;
            background-color: $primary-color-icam;
        }

        &--disabled {
            color: $snow-white;
            background-color: $custom-secondary-color;
        }
    }

    &__list-content {
        display: flex;
        flex-direction: column;
        height: auto;
        margin-bottom: 7px;
        width: 100%;
        position: relative;
        &--less-info {
            flex-direction: row;
            align-items: center;
        }
    }

    &__block-template {
        width: 100%;
        display: flex;
    }

    &__button {
        height: 40px;
        width: 100%;
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        bottom: 0;
        background-color: $background-grey-disabled-icam;
        color: $custom-black-landing;
        border: 1px solid $secondary-color-icam;

        &[disabled] {
            cursor: inherit;
        }
    }

    &__block-icon {
        position: absolute;
        bottom: 48px;
        text-align: center;
        width: 100%;
    }

    &__icon {
        // display: flex;
        opacity: 0.1;
        font-size: 50px;
        overflow: hidden;
        color: $custom-black-landing;
        // justify-content: center;
        // margin-bottom: 8px;
    }

    &__title {
        position: relative;
        color: $primary-color-icam;
        font-family: Arial, sans-serif;
        font-size: 20px;
        font-weight: bold;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;


        &--disabled {
            color: $custom-secondary-color;
        }
    }

    &__description {
        margin: 4px 0 13px;
        color: $custom-black-landing;
        font-style: italic;
        font-weight: bold;
        line-height: 20px;
        border-bottom: 1px solid $primary-color-icam;
        padding-bottom: 6px;
        font-family: Arial, sans-serif;
        font-size: 12px;
        height: 44px;
        position: relative;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__list-elements {
        margin: 0px;
        padding: 0px;
        padding-left: 15px;
        font-size: 11px;
        font-family: Arial, sans-serif;
        color: $custom-black-landing;
        font-weight: normal;
        line-height: 20px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        &--disabled {
            color: $custom-secondary-color;
        }

        &--level-2 {
            list-style-type: circle;
            margin-left: 10px;
        }
    }

    &__icon-button {
        font-size: 20px;
        top: 10px;
        left: 10px;
        position: absolute;
        visibility: hidden;
    }

    &__input-block {
        &:hover {
            & .common__tooltip-info-box {
                display: block;
            }

            & .common__tooltip-law, .common__info-icon-block, .common__tooltip-law-block {
                visibility: visible;
            }
        }
    }

    // &__input-block-search {
    //     overflow: auto;
    // }

    &__block-list {
        display: inline-block;
    }

    &__block-text {
        max-width: 100%;
        width: auto;
        position: relative;
        padding-top: 2px;
        &--search {
            font-family: Arial, sans-serif;
            font-size: 12px;
        }

        &--numelements {
            max-width: calc(100% - 34px);
            padding-right: 2px;
        }
    }

    &__block-number {
        line-height: 12px;
        overflow: hidden;
        display: inline-block;
        max-width: 100%;
        width: auto;
        font-family: MTTMilano, sans-serif;
        font-size: 12px;
        position: relative;
        height: 18px;
        color: $block-number-color;
        font-weight: 500;
        padding: 2px 5px 0 0px;
        margin: 0;

        &--list {
            margin-left: 5px;
            float: left;
            overflow: initial;
        }

        &--selected {
            float: left;
            overflow: initial;
            padding-right: 0px;
            margin-left: 5px;
        }
    }

    &__list-block {
        max-height: 245px;
        overflow: hidden;
        position: absolute;
        width: calc(100% + 2px);
        float: none;
        margin: 0;
        transform: none !important;
        inset: auto !important;
        left: -1px !important;
        top: 100% !important;

        &--tree-list {
            height: 242px;
        }

        // &--search {
        //     // margin-top: 20px;
        //     max-height: auto;
        // }
        // &--poll{
        //     top: 46px;
        // }
        &--xs {
            width: 120px;
            min-width: 120px;
        }

        &--extended{
            top: 50px;
        }

        &--custom {
            border-color: $list-block-custom;
        }
    }

    &__block-list-ellipsis {
        height: 20px;
        // width: 100%;
        overflow: hidden;
        position: relative;
    }

    &__select-list {
        width: 100%;
        max-height: 180px;
        overflow: auto;
        &--tree{
          max-height: 240px;
        }
    }

    &__block-element-list {
        padding: 0 5px;
    }

    &__input-text {
        height: 20px;
        color: $primary-color;
        font-family: MTTMilano-Bold, sans-serif;
        font-size: 12px;
        padding: 1px 0 0 10px;
        text-transform: uppercase;
        float: left;
        // width: 90%;
        width: calc(100% - 22px);

        &--no-editable {
            color: $secondary-color;
            cursor: not-allowed !important;
        }
    }

    &__icon-link {
        font-size: 16px;
        color: $primary-color;
        position: absolute;
        right: 0;
        top: 2px;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0 5px 0 0;

        &--disabled {
            color: $secondary-color;
            opacity: 0.5;

            &:hover {
                cursor: not-allowed;
            }
        }
    }

    &__law-icon {
        font-size: 18px;
        position: relative;
        padding-left: 11px;
        // top: 3px;
    }

    &__item-text {
        position: relative;
        bottom: 2px;
        padding-left: 3px;
    }

    &__is-user-value {
        font-size: 16px;
        position: relative;
        padding-left: 2px;
        &--custom {
            font-size: 15px;
            top: 1px;
        }
    }

    &__list-ishighlight {
        list-style: none;
        display: inline-block;
        margin: 0;
        padding: 2px 0 0 5px;
        // &--poll{
        //     padding: 0;
        // }
        &--custom {
            padding: 0;
        }
    }

    &__element-list-ishighlight {
        display: inline-block;
        position: relative;
        margin: 5px;

        &--custom {
            margin: 0 3px 5px 7px;
            // &:first-child{
            //     margin-left: 10px;
            // }
        }
    }

    &__input-content-highlight {
        border: none;
        padding-left: 15px;
        padding-right: 15px;
        height: 24px;
        outline: 0;
        color: $secondary-color;
        font-family: Arial, sans-serif;
        font-size: 14px;
        font-weight: 500;
        background: transparent;
        border-radius: 120px;
        min-width: 120px;

        &--poll {
            height: 40px;
        }

        &--color-5EC6DD {
            &:hover:not([disabled]) {
                color: $snow-white !important;
                border-color: transparent !important;
                background-color: $delete-amount !important;
            }
        }

        &--color-7683B5 {
            &:hover:not([disabled]) {
                color: $snow-white !important;
                border-color: transparent !important;
                background-color: $custom-color-7683B5 !important;
            }
        }

        &--color-77D166 {
            &:hover:not([disabled]) {
                color: $snow-white !important;
                border-color: transparent !important;
                background-color: $green !important;
            }
        }

        &--color-7A58AC {
            &:hover:not([disabled]) {
                color: $snow-white !important;
                border-color: transparent !important;
                background-color: $custom-color-7A58AC !important;
            }
        }

        &--color-929B9C {
            &:hover:not([disabled]) {
                color: $snow-white !important;
                border-color: transparent !important;
                background-color: $states-process-light-grey !important;
            }
        }

        &--color-9A7E74 {
            &:hover:not([disabled]) {
                color: $snow-white !important;
                border-color: transparent !important;
                background-color: $brown !important;
            }
        }

        &--color-C43741 {
            &:hover:not([disabled]) {
                color: $snow-white !important;
                border-color: transparent !important;
                background-color: $red !important;
            }
        }

        &--color-E5869D {
            &:hover:not([disabled]) {
                color: $snow-white !important;
                border-color: transparent !important;
                background-color: $custom-color-E5869D !important;
            }
        }

        &--color-F1923E {
            &:hover:not([disabled]) {
                color: $snow-white !important;
                border-color: transparent !important;
                background-color: $state-orange !important;
            }
        }

        &--color-F1BC3E {
            &:hover:not([disabled]) {
                color: $snow-white !important;
                border-color: transparent !important;
                background-color: $yellow !important;
            }
        }

        // &--color-select-5EC6DD {
        //     border-color: transparent !important;
        //     background-color: $delete-amount !important;
        // }

        // &--color-select-7683B5 {
        //     border-color: transparent !important;
        //     background-color: $custom-color-7683B5 !important;
        // }

        // &--color-select-77D166 {
        //     border-color: transparent !important;
        //     background-color: $green !important;
        // }

        // &--color-select-7A58AC {
        //     border-color: transparent !important;
        //     background-color: $custom-color-7A58AC !important;
        // }

        // &--color-select-929B9C {
        //     border-color: transparent !important;
        //     background-color: $states-process-light-grey !important;
        // }

        // &--color-select-9A7E74 {
        //     border-color: transparent !important;
        //     background-color: $brown !important;
        // }

        // &--color-select-C43741 {
        //     border-color: transparent !important;
        //     background-color: $red !important;
        // }

        // &--color-select-E5869D {
        //     border-color: transparent !important;
        //     background-color: $custom-color-E5869D !important;
        // }

        // &--color-select-F1923E {
        //     border-color: transparent !important;
        //     background-color: $state-orange !important;
        // }

        // &--color-select-F1BC3E {
        //     border-color: transparent !important;
        //     background-color: $yellow !important;
        // }

        &--disabled {
            opacity: 0.6;
            cursor: not-allowed;

            &:hover {
                cursor: not-allowed;
            }
        }
    }

    &__circle-button-text {
        font-size: 9px;
        top: 8px;
        left: 6px;
        position: absolute;
    }

    &__input-block-numerical-scale {
        min-height: 65px;

        &:hover {
            & .common__info-icon-block, .common__tooltip-law-block {
                visibility: visible;
            }
        }
    }

    &__numerical-list-block {
        display: block;
        padding: 0;
        list-style: none;
        margin: 2px 11px 5px;
        height: 30px;
        width: calc(100% - 22px);

        &--custom {
            position: relative;
            float: left;
            height: inherit;
            // min-height: 30px;
            margin: 0px 11px 1px;
        }

        &--poll {
            margin: 0;
            overflow: hidden;
        }
    }

    &__numerical-list-item {
        display: block;
        // position: relative;
        text-align: center;
        box-sizing: border-box;
        //border: 1px solid $attach-icon-grey;
        border: 1px solid $result-search-quote-color;
        background-color: $snow-white;
        color: $primary-color;
        font-family: MTTMilano-Bold, sans-serif;
        font-weight: normal;
        font-size: 14px;
        // border-left: 1px solid transparent;
        float: left;
        padding-top: 4px;
        padding-bottom: 5px;
        // &:first-child{
        //     border-left: 1px solid #A1A1A1;
        // }
        &:hover {
            cursor: pointer;
            background: $background-grey;
            color: $primary-color;
        }

        &--poll {
            padding-top: 14px;
            padding-bottom: 15px;
        }

        &--custom {
            height: 20px;
            border-radius: 10px;
            background-color: $custom-border-grey;
            color: $custom-black-landing;
            font-family: Arial, sans-serif;
            font-size: 13px;
            text-align: center;
            margin: 2px 10px 2px 0;
            padding: 0 22px;
        }

        &--custom-AF9F01 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-AF9F01);
        }

        &--custom-B87300 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-B87300);
        }

        &--custom-BC5901 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-BC5901);
        }

        &--custom-C10F0B {
            @include field-type-list-numerical-list-item-custom(transparent, $advice-close);
        }

        &--custom-830065 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-830065);
        }

        &--custom-4A006F {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-4A006F);
        }

        &--custom-001478 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-001478);
        }

        &--custom-01417F {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-01417F);
        }

        &--custom-009598 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-009598);
        }

        &--custom-00773B {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-00773B);
        }

        &--custom-008B07 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-008B07);
        }

        &--custom-000000 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-black-landing);
        }

        &--custom-FFF201 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-FFF201);
        }

        &--custom-FFB001 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-FFB001);
        }

        &--custom-EB6F00 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-EB6F00);
        }

        &--custom-FF3734 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-FF3734);
        }

        &--custom-D011A2 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-D011A2);
        }

        &--custom-9470C0 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-9470C0);
        }

        &--custom-003FAE {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-003FAE);
        }

        &--custom-3B92D6 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-3B92D6);
        }

        &--custom-00D2D6 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-00D2D6);
        }

        &--custom-00B165 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-color-00B165);
        }

        &--custom-00CF2A {
            @include field-type-list-numerical-list-item-custom(transparent, $block-state-bar-left);
        }

        &--custom-898989 {
            @include field-type-list-numerical-list-item-custom(transparent, $custom-secondary-color);
        }

        &--disabled-landing-custom {
            @include field-type-list-numerical-list-item-disabled($custom-border-grey, $custom-black-landing, $custom-border-grey);
        }
    }

    &__level {
        &--2 {
            padding-left: 20px;
        }

        &--3 {
            padding-left: 30px;
        }

        &--4 {
            padding-left: 40px;
        }

        &--5 {
            padding-left: 50px;
        }

        &--6 {
            padding-left: 60px;
        }

        &--7 {
            padding-left: 70px;
        }

        &--8 {
            padding-left: 80px;
        }

        &--9 {
            padding-left: 90px;
        }

        &--10 {
            padding-left: 100px;
        }
    }

    &__tree-list {
        padding-left: 20px;
    }
    // &__input-content-filled{
    //     width: 94%;
    //     float: left;
    // }
    &__multiple-text-list {
        float: left;
        max-width: calc(100% - 40px);
        padding-left: 3px;

        &--numelements {
            max-width: calc(100% - 66px);
        }

        &--numelements-middle {
            max-width: calc(100% - 72px);
        }

        &--numelements-big {
            max-width: calc(100% - 78px);
        }

        &--user-value {
            max-width: calc(100% - 102px);
        }
    }

    &__simple-text-list {
        float: left;
        max-width: calc(100% - 22px);
        padding-left: 3px;

        &--numelements {
            max-width: calc(100% - 50px);
        }

        &--numelements-middle {
            max-width: calc(100% - 58px);
        }

        &--numelements-big {
            max-width: calc(100% - 65px);
        }

        &--user-value {
            max-width: calc(100% - 87px);
        }
    }

    &__text-selected-simple {
        max-width: calc(100% - 20px);
        margin: 3px 0 0;
        float: left;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 0 0 10px;
        color: $secondary-color;
        font-family: Arial, sans-serif;
        font-size: 12px;
        font-weight: 500;
        min-height: 18px;

        &--numelements {
            max-width: calc(100% - 37px);
        }

        &--numelements-middle {
            max-width: calc(100% - 47px);
        }

        &--numelements-big {
            max-width: calc(100% - 57px);
        }
    }

    &__icon-angle-filials{
        position: relative;
        float: left;
        margin-right: 2px;
        font-size: 16px;
        visibility: hidden;
        &--visible{
        visibility: visible;
        }
    }
    &__disabled-item{
      &:hover{
        border: none !important;
      }
    }
    &__disabled-item,
    &__disabled-item-all{
        color: $subcontainer-ground;
        &:hover{
            cursor: default;
            background: none !important;
            font-weight: normal !important;
        }
    }
    &__block-buttons-addnew{
        display: inline-block;
        position: relative;
        top: 2px;
        left: 4px;
    }
    &__add-new-block{
        position: relative;
        // top: -2px;
        bottom: 2px;
    }
    // &__item-selected{
    //     background: rgba(0,25,120,0.5) !important;    // JRSJ 06/08/2020  WEB-29748
    // }
    &__block-tree-selected{
      border: 1px solid #CCD1E4;
      border-top: 0;
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 8px;
      position: relative;
      bottom: 10px;
    }
    &__text-tree-selected{
      margin: 0;
      font-size: 14px;
      font-family: MTTMilano;
      line-height: 18px;
      color: #717171;
      display: flex;
      flex-direction: column;
      gap: 4px;
      &--selected{
        color: $primary-color;
        font-family: MTTMilano-Bold;
        flex-direction: row;
        gap: 2px;
      }
    }
    &__block-tree-selected-multiple{
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    //////////////////////////////////////////////
    ////////////////////////Custom///////////////
    ////////////////////////////////////////////
    &__input-block-custom-numerical-scale {
        min-height: 50px;
        // height: 49px;
    }

    &__block-list-elements {
        width: 100%;
        display: table;
    }

    &__highlight-block-sequential {
        text-align: center;
    }

    &__service-legend {
        position: absolute;
        display: inline-flex;
        align-items: center;
        color: $snow-white;
        background-color: $modal-question-icam;
        height: 24px;
        border: 1px solid $modal-question-icam;
        border-radius: 12px;
        padding: 0 3px 0 10px;
        right: 20px;
        z-index: 10;

        & span {
            font-size: 10px;
            padding-right: 5px;
        }

        & i {
            font-size: 18px;
        }

        &--less-info {
            top: 5px;
            right: 5px;
            height: 16px;
            padding: 0 2px 0 5px;
            & i {
                font-size: 12px;
            }
        }

        &:hover:not([disabled]) {
            background-color: $third-color-icam;
            color: $primary-color-icam;
            border-color: $primary-color-icam;
        }
    }

    &__info-block {
        display: inline-flex;
        align-items: center;
        position: relative;
        float: right;
        color: $primary-color-icam;
        cursor: pointer;

        & span {
            font-size: 10px;
            text-transform: uppercase;
            margin-right: 5px;
        }

        & i {
            font-size: 15px;
        }
    }

    &__sequential-numerical-block {
        display: block;
        margin: 0 auto;
        width: calc(100% - 267px);
        overflow: hidden;
    }

    &__sequential-numerical {
        width: 69px;
        height: 69px;
        border-radius: 150px;
        color: $attachment-custom-grey;
        font-family: 'MTTMilano', sans-serif;
        font-size: 25px;
        font-weight: 500;
        line-height: 67px;
        margin-right: 19px;
        border: 1px solid transparent;
        float: left;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            cursor: pointer;
        }
    }
    &__tree-select-dot{
      height: 6px;
      width: 6px;
      border-radius: 50%;
      position: relative;
      top: 4px;
      left: -2px;
    }
  //////////////////////////////////////////////
  ////////////////////////Fin Custom///////////////
  ////////////////////////////////////////////
}

.compliance{
  &__dark-blue{
    .field-type-list{
      &__is-user-value{
        color: $attach-icon-grey;
      }
      &__numerical-list-item{
        &:hover {
          background-color: $background-color-states;
        }
        &--selected {
          @include field-type-list-numerical-list-item-selected($primary-color, $result-search-quote-color, $snow-white);
        }
        &--disabled {
          @include field-type-list-numerical-list-item-disabled($result-search-quote-color, $secondary-color, $background-grey);
        }
        &--selected-disabled {
          @include field-type-list-numerical-list-item-disabled($result-search-quote-color, $snow-white, $subcontainer-ground);
        }
        &--error {
          border-color: $red !important;
          background-color: $background-error !important;
          color: $red;
        }
      }
      &__block-text{
        &--search{
          color: $primary-color;
        }
        &--no-name {
          color: $red;
        }
      }
      &__tree-select-dot{
        background: $primary-color;
      }
    }
  }
  &__steel-blue{
    .field-type-list{
      &__is-user-value{
        color: $text-field-color-2;
      }
      &__numerical-list-item{
        @include field-type-list-numerical-list-item-color($secondary-color-2, $primary-color-2, $third-color-2, $border-hover-2);
        &--selected {
          @include field-type-list-numerical-list-item-selected($border-hover-2, $border-hover-2, $snow-white);
        }
        &--disabled {
          @include field-type-list-numerical-list-item-disabled($secondary-color-2, $primary-color-2, $snow-white);
        }
        &--selected-disabled-steel-blue {
          @include field-type-list-numerical-list-item-disabled($grey-icons-2, $snow-white, $grey-icons-2);
        }
        &--error-steel-blue {
          border-color: $alert-color-2;
          background-color: $snow-white;
          color: $alert-color-2;
        }
      }
      &__block-text{
        color: $text-field-color-2;
        &--no-name {
          color: $alert-color-2;
        }
      }
      &__input-block {
        &:hover {
          .common__multiselect-block--search {
              background-color: $block-hover-2;
              border-color: $border-hover-2;
          }
        }
      }
      &__tree-select-dot{
        background: $primary-color-2;
      }
    }
  }
  &__uk-steel-blue{
    .field-type-list{
      &__block-text{
        color: $text-field-color-2;
        &--no-name {
          color: $alert-color-2;
        }
      }
      &__block-number{
        color: $grey-icons-2;
      }
      &__tree-select-dot{
        background: $primary-color-2;
      }
    }
  }
  &__icam-red{
    .field-type-list{
      &__list-isbutton {
          &--less-info {
              padding: 10px 10px 25px;
          }
      }
      &__is-user-value{
        color: $secondary-color-icam;
      }
      &__numerical-list-item{
        @include field-type-list-numerical-list-item-color($border-block-icam, $custom-black-landing, $hover-block-icam, $primary-color-icam);
        &--selected{
          @include field-type-list-numerical-list-item-selected($primary-color-icam, $primary-color-icam, $snow-white);
        }
        &--disabled {
          @include field-type-list-numerical-list-item-disabled($border-block-icam, $custom-black-landing, transparent);
        }
        &--selected-disabled {
          @include field-type-list-numerical-list-item-disabled($secondary-color-icam, $snow-white, $secondary-color-icam);
      }
      }
      &__text-selected-simple{
        color: $text-field-color-icam;
      }
      &__button{
        &:hover:not([disabled]) {
          background-color: $hover-block-icam;
          color: $primary-color-icam;
          border-color: $primary-color-icam;

          & .field-type-list__icon-button {
              visibility: visible;
          }
        }
        &--selected {
          background-color: $third-color-icam;
          color: $primary-color-icam;
          border-color: $third-color-icam;

          & .field-type-list__icon-button {
              visibility: visible;
          }
        }
        &--disabled {
          background-color: $background-grey-disabled-icam;
          color: $secondary-color-icam;
          border-color: $background-grey-disabled-icam;
        }
        &--selected-disabled {
          background-color: $secondary-color-icam;
          border-color: $secondary-color-icam;
          color: $background-grey-disabled-icam;

          & .field-type-list__icon-button {
              visibility: visible;
          }
        }
      }
      &__block-text{
        color: $custom-black-landing;
        &--no-name {
          color: $alert-color-icam;
        }
      }
      &__block-number{
        color: $grey-icons-2;
      }
      &__title {
        &--less-info{
            font-size: 14px;
            margin-left: 10px;
        }
      }
      &__tree-select-dot{
        background: $primary-color-icam;
      }
    }
  }
  &__gm-law{
    .field-type-list{
      &__icon-link{
        color: $primary-color-gm;
      }
      &__is-user-value{
        color: $secondary-color-gm;
      }
      &__numerical-list-item{
        @include field-type-list-numerical-list-item-color($border-block-gm, $custom-black-landing, $hover-block-gm, $primary-color-gm);
        &--selected {
          @include field-type-list-numerical-list-item-selected($primary-color-gm, $primary-color-gm, $snow-white);
        }
        &--disabled {
          @include field-type-list-numerical-list-item-disabled($border-block-gm, $custom-black-landing, transparent);
        }
      }
      &__text-selected-simple{
        color: $text-field-color-gm;
      }
      &__button{
        &:hover:not([disabled]) {
          background-color: $hover-block-gm;
          color: $primary-color-gm;
          border-color: $primary-color-gm;

          & .field-type-list__icon-button {
              visibility: visible;
          }
        }
        &--selected {
          background-color: $third-color-gm;
          color: $primary-color-gm;
          border-color: $third-color-gm;

          & .field-type-list__icon-button {
              visibility: visible;
          }
        }
        &--disabled {
          background-color: $background-grey-disabled-gm;
          color: $secondary-color-gm;
          border-color: $background-grey-disabled-gm;
        }
        &--selected-disabled {
          background-color: $secondary-color-gm;
          border-color: $secondary-color-gm;
          color: $background-grey-disabled-gm;

          & .field-type-list__icon-button {
              visibility: visible;
          }
        }
      }
      &__block-text{
        color: $custom-black-landing;
        &--no-name {
          color: $alert-color-gm;
        }
      }
      &--selected-disabled {
        @include field-type-list-numerical-list-item-disabled($secondary-color-gm, $snow-white, $secondary-color-gm);
      }
      &__tree-select-dot{
        background:  $primary-color-gm;
      }
    }
  }
  &__com-de{
    .field-type-list{
      &__icon-link{
        color: $primary-color-com-de;
      }
      &__is-user-value{
        color: $secondary-color-com-de;
      }
      &__numerical-list-item{
        @include field-type-list-numerical-list-item-color($border-block-com-de, $custom-black-landing, $hover-block-com-de, $primary-color-com-de);
        &--selected {
          @include field-type-list-numerical-list-item-selected($primary-color-com-de, $primary-color-com-de, $snow-white);
        }
        &--disabled {
          @include field-type-list-numerical-list-item-disabled($border-block-com-de, $custom-black-landing, transparent);
        }
      }
      &__text-selected-simple{
        color: $text-field-color-com-de;
      }
      &__button{
        &:hover:not([disabled]) {
          background-color: $hover-block-com-de;
          color: $primary-color-com-de;
          border-color: $primary-color-com-de;

          & .field-type-list__icon-button {
              visibility: visible;
          }
        }
        &--selected {
          background-color: $third-color-com-de;
          color: $primary-color-com-de;
          border-color: $third-color-com-de;

          & .field-type-list__icon-button {
              visibility: visible;
          }
        }
        &--disabled {
          background-color: $background-grey-disabled-com-de;
          color: $secondary-color-com-de;
          border-color: $background-grey-disabled-com-de;
        }
        &--selected-disabled {
          background-color: $secondary-color-com-de;
          border-color: $secondary-color-com-de;
          color: $background-grey-disabled-com-de;

          & .field-type-list__icon-button {
              visibility: visible;
          }
        }
      }
      &__block-text{
        color: $custom-black-landing;
        &--no-name {
          color: $alert-color-com-de;
        }
      }
      &--selected-disabled {
        @include field-type-list-numerical-list-item-disabled($secondary-color-com-de, $snow-white, $secondary-color-com-de);
      }
      &__tree-select-dot{
        background:  $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .field-type-list{
      &__icon-link{
        color: $primary-color-com-fr;
      }
      &__is-user-value{
        color: $secondary-color-com-fr;
      }
      &__numerical-list-item{
        @include field-type-list-numerical-list-item-color($border-block-com-fr, $custom-black-landing, $hover-block-com-fr, $primary-color-com-fr);
        &--selected {
          @include field-type-list-numerical-list-item-selected($primary-color-com-fr, $primary-color-com-fr, $snow-white);
        }
        &--disabled {
          @include field-type-list-numerical-list-item-disabled($border-block-com-fr, $custom-black-landing, transparent);
        }
      }
      &__text-selected-simple{
        color: $text-field-color-com-fr;
      }
      &__button{
        &:hover:not([disabled]) {
          background-color: $hover-block-com-fr;
          color: $primary-color-com-fr;
          border-color: $primary-color-com-fr;

          & .field-type-list__icon-button {
              visibility: visible;
          }
        }
        &--selected {
          background-color: $third-color-com-fr;
          color: $primary-color-com-fr;
          border-color: $third-color-com-fr;

          & .field-type-list__icon-button {
              visibility: visible;
          }
        }
        &--disabled {
          background-color: $background-grey-disabled-com-fr;
          color: $secondary-color-com-fr;
          border-color: $background-grey-disabled-com-fr;
        }
        &--selected-disabled {
          background-color: $secondary-color-com-fr;
          border-color: $secondary-color-com-fr;
          color: $background-grey-disabled-com-fr;

          & .field-type-list__icon-button {
              visibility: visible;
          }
        }
      }
      &__block-text{
        color: $custom-black-landing;
        &--no-name {
          color: $alert-color-com-fr;
        }
      }
      &--selected-disabled {
        @include field-type-list-numerical-list-item-disabled($secondary-color-com-fr, $snow-white, $secondary-color-com-fr);
      }
      &__tree-select-dot{
        background:  $primary-color-com-fr;
      }
    }
  }
  &__landing-custom{
    .field-type-list{
      &__block-inner-buttons {
          width: 50%;
          padding: 6px;
      }
      &__block-text {
          color: $custom-black-landing;
          font-family: Arial, sans-serif;
          font-size: 10px;
          text-align: center;
          padding-right: 16px;
          padding-top: 5px;
          line-height: 10px;
          &--error{
              color: $red;
          }
      }
      &__input-content-highlight {
          height: 20px;
          min-width: 67px;
          border-radius: 10px;
          font-family: Arial, sans-serif;
          font-size: 10px;
          text-align: center;
          &--sequential {
              height: 30px;
              min-width: 80px;
              border-radius: 15px;
              font-size: 16px;
              line-height: 16px;
              border: 1px solid transparent;
          }
      }
      &__button {
          background-color: $snow-white;
          color: $custom-black-landing;
          border: 1px solid $custom-black-landing;
          border-radius: 4px;
          height: 30px;
          width: 160px;
          bottom: 20px;
          &:hover:not([disabled]) {
              color: $custom-black-landing;
              background-color: $third-color-icam;
          }
          &--selected {
              background-color: $custom-black-landing;
              color: $snow-white;
              border-color: $custom-black-landing;

              & .field-type-list__icon-button {
                  visibility: visible;
              }
          }

          &--disabled {
              background-color: $snow-white;
              color: $custom-text-color;
              border-color: $background-grey-disabled-icam;
          }

          &--selected-disabled {
              background-color: $custom-secondary-color;
              color: $snow-white;
              border-color: $custom-secondary-color;

              & .field-type-list__icon-button {
                  visibility: visible;
              }
          }
      }
      &__list-isbutton {
          &--less-info {
              padding-bottom: 40px;
          }
      }
      &__icon-button {
          top: 4px;
      }
      &__title {
        &--less-info{
            font-size: 16px;
            margin-left: 10px;
        }
      }
      &__tree-select-dot{
        background: $custom-black-landing;
      }
    }
  }
}




