.users-admin-person-form {
  &__form{
    max-width: 830px;
    margin: auto;
    position: relative;
    width: 100%;
  }

  &__block-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }

  &__block-field-100-percent{
    width: 100%;
  }

  &__block-field-50-percent{
    width: 50%;
    padding: 0 1px;
  }

  &__block-field-40-percent{
    width: 40%;
  }

  &__block-field-33-percent{
    width: 33%;
    padding: 0 1px;
  }

  &__block-field-25-percent{
    width: 25%;
    padding: 0 1px;
  }

  &__block-field-21-percent{
    width: 21%;
    padding: 0 1px;
  }

  &__block-field-17-percent{
    width: 17%;
    padding: 0 1px;
  }

  &__block-field-init{
    padding: 0 1px 0 0;
  }

  &__block-field-finish{
    padding: 0 0 0 1px;
  }

  &__groups-row {
      margin: 6px 0 20px 0;
      display: inline-block;
      width: 100%;
  }
}
