@import '../../../../assets/scss/colors';
.process-modal, .process-edit-modal {

  &--normal {
      padding: 12px 12px 24px 12px;
  }

  &.modal .modal-dialog {
      width: 80%;
      max-width: 80%;
      height: 85%
  }

  &.modal .modal-content {
      width: auto;
      height: 100%;
      border-radius: 0px;
  }

  &__header {
      position: relative;
      height: 70px;
      padding: 20px 19px;
      font-family: MTTMilano, sans-serif;
      font-size: 20px;
      color:  $snow-white;
      background-color: $primary-color;

      &.error {
          background-color: $red;
      }

      & > i {
          font-size: 27px;
          vertical-align: middle;
      }
  }

  &__toolbar {
      float: right;
      font-family: MTTMilano, sans-serif;
      font-size: 20px;

  }

  &__button-header{
      height: 20px;
      margin-left: 40px;
      background-color: transparent;
      border: none;
      outline: none;
      // font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      vertical-align: top;
  }

  &__icon-button-header{
      font-family: Arial, sans-serif;
      font-size: 17px;
      margin-right: 7px;
      position: relative;
      top: 4px;
  }

  &__vline {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -7px;
      width: 1px;
      height: 44px;
      margin: 0 20px;
      background-color: $snow-white;
      overflow: hidden;
  }

  &__toolbar &__close {
      margin-left: 0;
      margin-right: 10px;
      text-transform: none;

      & > span {
          position: relative;
          top: 7px;
          margin-right: 5px;
      }
      // & > i {
      //     font-size: 15px;
      //     margin-left: 5px;
      // }
  }

  &__main {
      position: absolute;
      top: 83px;
      right: 12px;
      bottom: 75px;
      left: 12px;
      overflow: hidden;

      &--full {
          bottom: 12px;
      }
      & .joint-paper .joint-type-app-link path {
          cursor: default;
      }
  }

  &__docs-toolbar {
      position: absolute;
      top: 15px;
      right: 125px;
      z-index: 1;
  }

  &__next {
      &__layer {
          width: 400px;
          margin: 0 auto;
      }
      &__fields {
          clear: both;
      }
  }
  &__error {
      margin-left: 35px;
      padding: 0px 50px;
      line-height: 39px;
      background-color: $red;
      color: $snow-white;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      cursor: pointer;

      &-icon {
          margin-right: 5px;
          font-size: 16px;
          position: relative;
          top: 2px;
          // vertical-align: middle;
      }
  }


  &__description {
      &__layer {
          width: 70%;
          margin: 0 auto;
      }

      &__text {
          clear: both;
      }
  }

  &__references {
      position: absolute;
      top: 45px;
      right: 50px;
      bottom: 0;
      left: 25px;
  }



  &__state-button {
      height: 17px;
      background-color: transparent;
      border: none;
      outline: none;
      font-family: Arial, sans-serif;
      padding: 0;
  }

  &__state-box {
      position: absolute;
      z-index: 1;
      cursor: default;

      &__menu {
          position: absolute;
          top: 15px;
          right: 0;

          &__handler {
              font-size: 12px;
              margin-right: 4px;
          }

          &__buttons {
              display: none;
              text-align: right;
              margin-top: -7px;
              padding-top: 6px;
              padding-right: 4px;
              width: 37px;
              height: 32px;
              background-color: $background-grey-other;
              border: 0.5px solid $menu-icons-border;
              border-top-left-radius: 40%;
              border-bottom-left-radius: 40%;
          }

          &:hover &__handler {
              display: none;
          }

          &:hover &__buttons {
              display: block;
          }
      }

      &__icons {
          position: absolute;
          bottom: 0;
          right: 0;

          & i {
              margin: 0 3px;
          }
      }
  }

  &__link-data {
      position: absolute;
      z-index: 1;
      margin-top: -16px;
      margin-left: -7px;
      width: 29px;
      height: 29px;
      border-radius: 50%;
      background-color: $snow-white;
      // border: 2px solid $green;
      line-height: 31px;
      cursor: help;

      & > i {
          position: relative;
          left: 2.5px;
          top: 3px;
          font-size: 24px;
      }

      &:hover &__text,
      &__text--show  {
          display: block;
      }

  }

  &__link-icons {
      position: absolute;
      z-index: 1;
      margin-top: 6px;
      height: 17px;
      background-color: $snow-white;
  }

  &__footer {
      position: absolute;
      bottom: 30px;
      left: 0;
      right: 0;
      font-family: Arial, sans-serif;

      & .common__switch {
          margin-left: 0;
          cursor: pointer;
      }

      &__legend {
          position: absolute;
          left: 35px;
          bottom: 0;
          font-size: 10px;
          color: $secondary-color;
          z-index: 1;

          &__label {
              text-transform: uppercase;
              color: $subcontainer-ground;
              margin-left: 45px;
              line-height: 22px;
          }

          &__list {
              margin-top: 10px;
              padding: 10px 40px;
              line-height: 24px;
              list-style: none;
              border: 1px solid $footer-legend-list;
              box-shadow: 0 0 1px 0px $certify-black-opacity01;
              background-color: $snow-white;

              & > li > i {
                  margin-right: 5px;
                  vertical-align: middle;

                  &.fa-ellipsis-v {
                      min-width: 17px;
                      text-align: center;
                      font-size: 14px;
                  }
              }
          }
      }

      &__buttons {
          float: right;
          margin-right: 70px;

          & > button:first-child {
              margin-right: 2px;
          }
      }
  }

  &__has-task {
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      color: $subcontainer-ground;
      text-transform: uppercase;

      & > i {
          vertical-align: middle;
          font-size: 20px;
          margin-right: 5px;
      }
  }

  &__title-header {
      padding-left: 16px;
  }

  &__pdf-body{
      width: 100%;
      height: auto;
      min-height: 700px;
      border: 1px solid $result-search-quote-color;
      position: relative;
      .joint-paper{
          margin: 10px;
      }
  }
  &__pdf-inner-body{
      width: 100%;
      position: relative;
  }
  &__joint-paper{
      left: 0 !important;
      top: 0 !important;
      position: relative;
  }
}

.compliance{
  &__dark-blue{
    .process-modal, .process-edit-modal {
      &--preview {
        & .process-modal__header, .modal-content, .joint-paper-background, .process-modal__footer__legend__list {
            background-color: $background-otro-mas;
            color: $primary-color;
        }

        & .process-modal__vline {
            background-color: $primary-color;
        }
      }
      &__toolbar {
        color: $snow-white;
      }
      &__main {
        & .joint-paper g[selected="true"] .topborder{
          fill: $green;
        }
        & .joint-paper g[current="true"] .box-rect{
          stroke: $green;
          stroke-width: 2;
        }
      }
      &__state-icon {
        &--green{
          font-size: 19px;
          color: $green;
        }
        &--blue{
            font-size: 17px;
            color: $primary-color;
        }
        &--gray{
          font-size: 17px;
          color: $other-gray;
        }
      }
    }
    .joint-viewport g[selected=true] .topborder{
        fill: $green;
    }
    .joint-viewport g[current=true] .box-rect{
        stroke: $green;
        stroke-width: 2;
    }
  }
  &__steel-blue, &__uk-steel-blue{
    .process-modal, .process-edit-modal {
      &--preview{
        & .process-modal__header, .modal-content, .joint-paper-background {
            background-color: $background-grey-disabled-2;
            color: $primary-color-2;
        }
        & .process-modal__footer__legend__list{
            background-color: $background-grey-disabled-2;
            color: $primary-color-2;
            border-color: $border-proccess;
        }

        // & .process-modal__vline {
        //     background-color: $third-color-2;
        // }
      }
      &__toolbar {
        color: $white-special-2;
      }

      &__main {
        & .joint-paper g[selected="true"] .topborder{
          fill: $third-color-2;
        }
        & .joint-paper g[current="true"] .box-rect{
          stroke: $third-color-2;
          stroke-width: 2;
        }
      }
      &__next {
        &__layer {
          margin: 49px auto 0;
        }
      }
      &__state-icon {
        &--green{
          font-size: 19px;
          color: $border-hover-2;
        }
        &--blue{
          font-size: 17px;
          color: $border-hover-2;
        }
        &--gray{
          font-size: 17px;
          color: $other-gray-special;
        }
      }
      &__pdf-body{
          border-color: $secondary-color-2;
      }
    }
    .joint-viewport g[selected=true] .topborder{
        fill: $third-color-2;
    }
    .joint-viewport g[current=true] .box-rect{
        stroke: $third-color-2;
        stroke-width: 2;
    }
  }
  &__icam-red{
    .process-modal, .process-edit-modal {
      &--preview{
        & .process-modal__header, .modal-content, .joint-paper-background {
            background-color: $background-grey-disabled-icam;
            color: $custom-black-landing;

        }
        & .process-modal__footer__legend__list{
            background-color: $background-grey-disabled-icam;
            color: $custom-black-landing;
            border-color: $border-block-icam;
        }

        // & .process-modal__vline {
        //     background-color: $third-color-2;
        // }
      }

      &__toolbar {
        color: $snow-white;
      }
      &__next {
        &__layer {
          margin: 49px auto 0;
        }
      }
      &__state-icon {
        &--green{
          font-size: 19px;
          color: $primary-color-icam;
        }
        &--blue{
          font-size: 17px;
          color: $primary-color-icam;
        }
        &--gray{
          font-size: 17px;
          color: $secondary-color-icam;
        }
      }
      &__pdf-body{
          border-color: $primary-color-icam;
      }
    }
    .joint-paper g .topborder{
        fill: $border-block-icam;
    }
    .joint-viewport g .box-rect{
        fill: $snow-white;
    }
    .joint-viewport g[selected=true] .topborder{
        fill: $primary-color-icam;
    }
    .joint-viewport g[current=true] .topborder{
        fill: $third-color-icam;
    }
    .joint-viewport g[current=true] .box-rect{
        stroke: $third-color-icam;
        stroke-width: 2;
    }
  }
  &__gm-law{
    .process-modal, .process-edit-modal {
      &--preview{
        & .process-modal__header, .modal-content, .joint-paper-background {
            background-color: $background-grey-disabled-gm;
            color: $custom-black-landing;

        }
        & .process-modal__footer__legend__list{
            background-color: $background-grey-disabled-gm;
            color: $custom-black-landing;
            border-color: $border-block-gm;
        }
      }
      &__toolbar {
        color: $snow-white;
      }
      &__next {
        &__layer {
          margin: 49px auto 0;
        }
      }
      &__state-icon {
        &--green{
          font-size: 19px;
          color: $primary-color-gm;
        }
        &--blue{
            font-size: 17px;
            color: $primary-color-gm;
        }
        &--gray{
            font-size: 17px;
            color: $secondary-color-gm;
        }
      }
      &__pdf-body{
            border-color: $primary-color-gm;
      }
    }
    .joint-paper g .topborder{
        fill: $border-block-gm;
    }
    .joint-viewport g .box-rect{
        fill: $snow-white;
    }
    .joint-viewport g[selected=true] .topborder{
        fill: $primary-color-gm;
    }
    .joint-viewport g[current=true] .topborder{
        fill: $third-color-gm;
    }
    .joint-viewport g[current=true] .box-rect{
        stroke: $third-color-gm;
        stroke-width: 2;
    }
  }
  &__com-de{
    .process-modal, .process-edit-modal {
      &--preview{
        & .process-modal__header, .modal-content, .joint-paper-background {
            background-color: $background-grey-disabled-com-de;
            color: $custom-black-landing;

        }
        & .process-modal__footer__legend__list{
            background-color: $background-grey-disabled-com-de;
            color: $custom-black-landing;
            border-color: $border-block-com-de;
        }
      }
      &__toolbar {
        color: $snow-white;
      }
      &__next {
        &__layer {
          margin: 49px auto 0;
        }
      }
      &__state-icon {
        &--green{
          font-size: 19px;
          color: $primary-color-com-de;
        }
        &--blue{
            font-size: 17px;
            color: $primary-color-com-de;
        }
        &--gray{
            font-size: 17px;
            color: $secondary-color-com-de;
        }
      }
      &__pdf-body{
            border-color: $primary-color-com-de;
      }
    }
    .joint-paper g .topborder{
        fill: $border-block-com-de;
    }
    .joint-viewport g .box-rect{
        fill: $snow-white;
    }
    .joint-viewport g[selected=true] .topborder{
        fill: $primary-color-com-de;
    }
    .joint-viewport g[current=true] .topborder{
        fill: $third-color-com-de;
    }
    .joint-viewport g[current=true] .box-rect{
        stroke: $third-color-com-de;
        stroke-width: 2;
    }
  }
  &__com-fr{
    .process-modal, .process-edit-modal {
      &--preview{
        & .process-modal__header, .modal-content, .joint-paper-background {
            background-color: $background-grey-disabled-com-fr;
            color: $custom-black-landing;

        }
        & .process-modal__footer__legend__list{
            background-color: $background-grey-disabled-com-fr;
            color: $custom-black-landing;
            border-color: $border-block-com-fr;
        }
      }
      &__toolbar {
        color: $snow-white;
      }
      &__next {
        &__layer {
          margin: 49px auto 0;
        }
      }
      &__state-icon {
        &--green{
          font-size: 19px;
          color: $primary-color-com-fr;
        }
        &--blue{
            font-size: 17px;
            color: $primary-color-com-fr;
        }
        &--gray{
            font-size: 17px;
            color: $secondary-color-com-fr;
        }
      }
      &__pdf-body{
            border-color: $primary-color-com-fr;
      }
    }
    .joint-paper g .topborder{
        fill: $border-block-com-fr;
    }
    .joint-viewport g .box-rect{
        fill: $snow-white;
    }
    .joint-viewport g[selected=true] .topborder{
        fill: $primary-color-com-fr;
    }
    .joint-viewport g[current=true] .topborder{
        fill: $third-color-com-fr;
    }
    .joint-viewport g[current=true] .box-rect{
        stroke: $third-color-com-fr;
        stroke-width: 2;
    }
  }
}
.joint-paper-scroller>.paper-scroller-background{
  margin: 5px;
}
