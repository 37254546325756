@mixin picker-visibility-tab-selected($firstColor, $secondColor){
  color: $firstColor;
  background-color: $secondColor;
  cursor: default;

  // & .badge {
  //     color: $secondColor;
  // }
}

// @mixin picker-visibility-select-count($backgroundColor, $textColor) {
//     background-color: $backgroundColor;
//     border-color: $backgroundColor;
//     // color: $textColor;
// }
.picker-visibility {
  border: 1px solid $primary-color;
  background-color: $snow-white;
  z-index: 5;
  position: absolute;
  overflow: hidden;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 3px 1px $subcontainer-poll-border-02;
  &__header {
      font-size: 12px;
      border-bottom: 6px solid $primary-color;
      height: 50px;
      line-height: 44px;
      width: 100%;
  }

  &__header-block{
      padding: 0;
      height: 44px;
  }

  &__tab {
      padding: 0 15px;
      cursor: pointer;
      text-transform: uppercase;
  }

  &__search-wrapper {
      width: 45%;
      display: inline-block;
      position: relative;
      padding-left: 15px;
  }

  &__search {
      display: initial;
      height: 30px;
      padding-right: 30px;
      line-height: initial;
      border: 1px solid $primary-color;
      border-radius: 15px;
  }

  &__main {
      position: relative;
      width: 100%;
      float: left;
      background-color: $snow-white;
  }

  &__table {
      margin: 0px 15px 0px 15px;
      height: 305px;
      width: calc(100% - 30px);
      display: block;
  }

  &__table-header{
      border-bottom: 1px solid $primary-color;
      width: 100%;
      display: table;
      height: 35px;
  }

  &__table-col{
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      max-width: 0;
      font-size: 12px;
      padding-right: 15px;
      &--field{
          width: 60%;
      }
      &--value{
          width: calc(40% - 42px);
      }
      &--include{
          width: 42px;
          min-width: 42px;
          padding: 0;
          text-align: center;
      }
  }

  &__table-body {
      width: 100%;
      display: block;
      position: relative;
      height: 276px;
      overflow: hidden;
  }

  &__data {
      table-layout: fixed;
      width: 100%;

      &--loading tbody tr {
          border-bottom: none;
          height: 10em;
          text-align: center;
      }

      & tr {
          border-bottom: 1px solid $picker-visibility-data;
          overflow: hidden;
          text-overflow: ellipsis;
      }

      & th {
          color: $primary-color;
      }

      & td {
          color: $secondary-color;
          height: 46px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:first-child {
              color: $primary-color;
          }
      }

      & tfoot tr {
          border-bottom: none;
      }
  }

  &__column-filter {
      position: absolute;
      background-color: $snow-white;
      border: 1px solid $primary-color;
      padding: 15px 4px;
      font-size: 12px;
      font-weight: normal;
      z-index: 1;

      &__icon {
          color: $picker-visibility-column;

          &.open {
              color: $primary-color;
          }
      }

      &__header {
          position: relative;

          > input {
              width: 100%;
              border: none;
              padding-left: 8px;
              border-bottom: 1px solid $primary-color;
              padding-right: 20px;
          }

          > button {
              position: absolute;
              top: 0;
              right: 0;
          }
      }

      &__list {
          padding-left: 8px;
          padding-right: 8px;
          margin: 15px 0 0 0;
          max-height: 100px;
          overflow-y: auto;
          line-height: 1.5em;

          & > li {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              & > label {
                  cursor: pointer;
                  font-weight: normal;

                  & > .common__checkbox-button {
                      display: none;
                  }
              }
          }
      }
  }

  &__pagination {
      padding-top: 2em;
      padding-bottom: 15px;
      text-align: center;
  }

  &__pagination-button {
      font-size: 12px;
      text-transform: uppercase;

      &:disabled, &:disabled:hover {
          color: $attach-icon-grey !important;
          background-color: transparent !important;
      }

      & i {
          font-size: 19px;
      }

      & span {
          position: relative;
          top: -2px;
          line-height: 19px;
      }
  }

  // &__pagination-pages {
  //     display: inline-block;
  //     vertical-align: middle;
  //     margin: 0 38px;
  //     padding-left: 0;
  //     list-style: none;
  // }

  // &__pagination-page {
  //     float: left;
  //     vertical-align: middle;
  //     min-width: 18px;
  //     padding: 0 5px;
  //     cursor: pointer;

  //     &.current {
  //         color: $secondary-color;
  //         font-weight: bold;
  //         border-bottom: 4px solid $primary-color;
  //         cursor: pointer;
  //     }

  //     &.separator {
  //         cursor: pointer;
  //     }
  // }

  // switch-button button {
  //     height: 18px;
  // }

  &__edit-item-layer {
      margin: 0px 15px 0px 15px;
      height: 305px;
      width: calc(100% - 30px);
  }
  &__editing-title{
      color: $subcontainer-ground;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-style: italic;
      padding: 27px 0 10px 0;
  }

  &__edit-item {
      &__group-col {
          position: relative;
      }

      &__addon-button {
          position: absolute;
          margin-right: 11%;
      }
  }

  &__operator-col {
      height: 50px;
      width: 109px;
      display: block;
      border: 1px solid $attach-icon-grey;
      background-color: $snow-white;
  }

  &__footer {
      background-color: $picker-visibility-footer;
      padding: 15px 0 10px 0;
      width: 100%;
      position: relative;
      float: left;
      color: $primary-color;
  }

  &__footer-options {
      float: right;
  }

  &__footer-info{
      font-family: Arial, sans-serif;
      font-size: 11px;
      line-height: 20px;
      width: 75%;
      position: relative;
      float: left;
      padding: 0 15px;
  }
  &__footer-buttons{
      width: 25%;
      position: relative;
      float: left;
      padding: 0 15px;
  }
  &__border-button{
      height: 20px;
      width: 40px;
      font-size: 10px;
      font-weight: bold;
      padding: 2px;
      outline: none;
      text-transform: uppercase;
      position: relative;
      float: left;
      background-color: $snow-white;
      color: $primary-color;
      border: 2px solid $primary-color;

      &:focus{
          outline: none;
      }
      &--left{
          border-radius: 15px 0px 0px 15px;
      }
      &--right{
          border-radius: 0px 15px 15px 0px;
      }
  }

  &__block-header-search{
      position: relative;
      float: right;
      width: calc(100% - 305px);
      text-align: right;
      height: 44px;
  }

  &__block-buttons-save{
      position: relative;
      float: right;
      margin-right: 6px;
      margin-left: 15px;
      padding-top: 3px;
      height: 44px;
  }

  &__block-table-pagination{
      padding: 0;
  }
  &__condition-block{
      display: block;
      width: 100%;
      margin-left: 64px;
  }
  &__condition-item{
      width: 120px;
      margin-right: 10px;
      display: block;
      float: left;
      &--date{
          width: 135px;
      }
  }
  &__condition-buttons{
      position: relative;
      float: left;
      top: 11px;
      left: 10px;
  }
}

.compliance{
  &__dark-blue{
    .picker-visibility {
      &--container{
          border-color: $picker-visibility-container;
      }
      &__header {
          background-color: $snow-white;
          &--container{
              border-bottom-color: $picker-visibility-container;
          }
      }
      &__tab {
          &--selected{
              @include picker-visibility-tab-selected($snow-white, $primary-color);
          }
          &--selected-container{
              @include picker-visibility-tab-selected($snow-white, $picker-visibility-container);
          }
      }
      &__border-button{
        &--active{
            color: $snow-white;
            background-color: $primary-color;
        }

      }
    }
  }
  &__gm-law{
    .picker-visibility {
        border-color: $primary-color-gm;
        box-shadow: $box-shadow-gm;
        &__header {
            border-color: $custom-black-landing;
            background-color: $hover-block-gm;
        }
        &__tab {
            &--selected{
                @include picker-visibility-tab-selected($snow-white, $custom-black-landing);
            }
            &--selected-container{
                @include picker-visibility-tab-selected($snow-white, $primary-color-gm);
            }
        }
        &__table-header{
            border-color: $primary-color-gm;
        }
        &__footer {
             background-color: $background-grey-disabled-gm;
             color: $custom-black-landing;
        }
        &__border-button{
            color: $custom-black-landing;
            border-color: $primary-color-gm;
            &--active{
              color: $snow-white;
              background-color: $primary-color-gm;
            }
        }
    }
  }
  &__com-de{
    .picker-visibility {
        border-color: $primary-color-com-de;
        box-shadow: $box-shadow-com-de;
        &__header {
            border-color: $custom-black-landing;
            background-color: $hover-block-com-de;
        }
        &__tab {
            &--selected{
                @include picker-visibility-tab-selected($snow-white, $custom-black-landing);
            }
            &--selected-container{
                @include picker-visibility-tab-selected($snow-white, $primary-color-com-de);
            }
        }
        &__table-header{
            border-color: $primary-color-com-de;
        }
        &__footer {
             background-color: $background-grey-disabled-com-de;
             color: $custom-black-landing;
        }
        &__border-button{
            color: $custom-black-landing;
            border-color: $primary-color-com-de;
            &--active{
              color: $snow-white;
              background-color: $primary-color-com-de;
            }
        }
    }
  }
  &__com-fr{
    .picker-visibility {
        border-color: $primary-color-com-fr;
        box-shadow: $box-shadow-com-fr;
        &__header {
            border-color: $custom-black-landing;
            background-color: $hover-block-com-fr;
        }
        &__tab {
            &--selected{
                @include picker-visibility-tab-selected($snow-white, $custom-black-landing);
            }
            &--selected-container{
                @include picker-visibility-tab-selected($snow-white, $primary-color-com-fr);
            }
        }
        &__table-header{
            border-color: $primary-color-com-fr;
        }
        &__footer {
             background-color: $background-grey-disabled-com-fr;
             color: $custom-black-landing;
        }
        &__border-button{
            color: $custom-black-landing;
            border-color: $primary-color-com-fr;
            &--active{
              color: $snow-white;
              background-color: $primary-color-com-fr;
            }
        }
    }
  }
}
