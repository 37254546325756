.new-instant-videoconference {
  &__window {
      & .modal-dialog {
          margin: 110px auto 0;

          & .modal-content {
              height: 745px;
          }
      }
  }

  &__block-body {
      display: block;
      width: 100%;
      height: 518px;
      padding: 24px 71px 0;
  }

  &__block-title {
      display: block;
      width: 100%;
      margin-bottom: 20px;
  }

  &__form {
      height: 440px;
      width: 100%;
      overflow-y: auto;
      margin: 0;
  }

  &__option-box {
      background-color: $box-comment-border-03;
      float: left;
      width: 370px;
      height: 301px;
      padding: 18px 8px 20px 10px;
  }

  &__text-rich {
      display: block;
      width: 100%;
      overflow: hidden;
      height: 191px;
      margin-bottom: 10px;
  }

  &__title-option {
      opacity: 0.5;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 18px;
      margin: 0;
      line-height: 19px;
  }

  &__switch-invitation {
      display: inline-block;
  }

  &__connection-data {
      background-color: $box-comment-border-03;
      float: left;
      width: 100%;
      height: 301px;
      padding: 100px 21px;
  }

  &__icon-connect {
      font-size: 100px;
      color: $primary-color;
      opacity: 0.2;
      position: relative;
      float: left;
  }

  &__info-connection-data {
      position: relative;
      float: left;
      padding: 12px 0 0 35px;
  }

  &__title-connection-data {
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 16px;
      margin: 0;
      text-transform: uppercase;
  }

  &__credentials-connection-data {
      color: $repeat-pattern;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 16px;
      margin: 0 0 3px 0;
  }

  &__credentials-information {
      font-family: MTTMilano, sans-serif;
      color: $primary-color;
  }

  &__icon-copy {
      font-size: 20px;
      color: $primary-color;
      position: relative;
      top: 5px;

      &:hover {
          cursor: pointer;
      }
  }
}

.compliance{
  &__gm-law{
    .new-instant-videoconference{
      &__title-option {
          color: $custom-black-landing;
      }
      &__connection-data {
          background-color: $background-grey-disabled-gm;
      }
      &__icon-connect {
          color: $custom-black-landing;
      }
      &__title-connection-data {
          color: $custom-black-landing
      }
      &__credentials-connection-data {
          color: $text-field-color-gm;
      }
      &__credentials-information {
          color: $text-field-color-gm;
      }
      &__icon-copy {
          color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    .new-instant-videoconference{
      &__title-option {
          color: $custom-black-landing;
      }
      &__connection-data {
          background-color: $background-grey-disabled-com-de;
      }
      &__icon-connect {
          color: $custom-black-landing;
      }
      &__title-connection-data {
          color: $custom-black-landing
      }
      &__credentials-connection-data {
          color: $text-field-color-com-de;
      }
      &__credentials-information {
          color: $text-field-color-com-de;
      }
      &__icon-copy {
          color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .new-instant-videoconference{
      &__title-option {
          color: $custom-black-landing;
      }
      &__connection-data {
          background-color: $background-grey-disabled-com-fr;
      }
      &__icon-connect {
          color: $custom-black-landing;
      }
      &__title-connection-data {
          color: $custom-black-landing
      }
      &__credentials-connection-data {
          color: $text-field-color-com-fr;
      }
      &__credentials-information {
          color: $text-field-color-com-fr;
      }
      &__icon-copy {
          color: $primary-color-com-fr;
      }
    }
  }
}
