.landing-page {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  &__block-body {
      position: relative;
      margin: 40px auto 30px;
      padding: 0 10px;
      max-width: 1388px;

      &--poll-no-public {
          margin-top: 0px;
      }

      &--custom {
          margin: 0px auto 30px;
      }

      &--custom-task {
          margin: 0px auto;
          max-width: 100%;
          padding: 0;
      }

      &--procedure {
          padding: 7px 10px !important;
          border-radius: 4px;
          background-color: $snow-white;
          box-shadow: 0 4px 20px 0 $attachment-custom-grey-opacity;
      }
  }

  &__block-expiration-date-poll {
      position: relative;
      float: right;
      width: 100%;
  }

  &__edit-concept {
      position: absolute;
      color: $primary-color;
      z-index: 20;
      bottom: 6px;
      right: 69px;
      font-size: 16px;

      &:hover {
          cursor: pointer;
      }
  }

  &__container {
      display: block;
      width: 100%;
      min-height: calc(100% - 400px);
      padding-bottom: 80px;
      position: relative;
      float: left;

      &--public {
          min-height: calc(100% - 150px);
      }

      &--custom {
          padding-bottom: 0;
      }

      &--custom-task {
          padding-bottom: 30px;
      }

      &--sequential {
          padding-bottom: 0;
      }
  }

  &__ellipsis-icon {
      color: $primary-color;
      position: absolute;
      font-size: 27px;
      top: 15px;
      right: 0px;
      padding: 5px 0px 0px 0px;
      height: 35px;

      &:hover {
          cursor: pointer;
      }

      &--active {
          background-color: $primary-color;
          color: $background-grey;
      }
  }

  &__icon-date-poll {
      top: 5px;
      position: relative;
      margin-right: 8px;
      font-size: 18px;
  }

  &__expiration-period-text{
      margin-right: 4px;
  }

  &__button-to-concept-text {
      position: relative;
      bottom: 4px;
      padding-left: 2px;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: normal;
      font-family: Arial, sans-serif;
  }

  &__block-header-right{
      position: relative;
      float: right;
      bottom: 3px;
      &--open{
          bottom: 10px;
      }
  }

  &__block-search{
      position: relative;
      float: left;
      top: 2px;
      font-size: 12px;
      color: $snow-white;
      padding: 0px 8px 6px 10px;
      border-bottom: 1px solid $snow-white;
      &--open{
          padding: 6px;
          border: 1px solid $snow-white;
          height: 44px;
          top: 0px;
      }
  }

  &__text-search{
      &:hover{
          cursor: pointer;
      }
  }

  &__icon-button-search {
      position: relative;
      top: 4px;
      padding: 0;
      margin-left: 7px;
      border: none;
      outline: none;
      background: none;
      font-size: 18px;
      &--open {
          right: 10px;
          top: 10px;
          position: absolute;
      }
  }

  &__search-input {
      position: relative;
      // width: 100%;
      width: 240px;
      height: 30px;
      font-size: 12px;
      border: none;
      border-bottom: 1px solid $snow-white;
      outline: none;
      font-family: MTTMilano, sans-serif;
      background-color: transparent;
      border-radius: 0;
      padding: 4px;

      &::-webkit-input-placeholder {
          font-style: normal;
          padding: 0px;
          color: $snow-white;
      }
  }

  &__button-add-list{
      margin-left: 15px;
  }

  &__progress-complete {
      width: 100%;
      height: 9px;
      position: absolute;
      background-color: $custom-border-grey;
      border-radius: 5px;
      opacity: 1;

      &--status-0 {
          background-color: $red-opacity-36;
      }

      &--status-1 {
          background-color: $yellow-opacity-36;
      }

      &--status-2 {
          background-color: $green-opacity-36;
      }
  }

  &__progress-percent {
      position: absolute;
      height: 9px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
  }

  //////////////////////////////////
  ///////////////Custom////////////
  ////////////////////////////////
  &__custom-text-title {
      font-family: Arial, sans-serif;
      font-size: 18px;
      text-align: left;
      margin: 0;
      text-transform: uppercase;
      float: left;

      &--ellipsis {
          width: calc(100% - 706px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
      }
  }

  &__custom-creation {
      color: $custom-black-landing;
      font-family: Arial, sans-serif;
      font-size: 11px;
      float: left;
      margin: 3px 0 0;

      &--created-and-date {
          font-weight: bold;
      }
  }

  &__custom-button-to-concept {
      float: left;
      box-sizing: border-box;
      height: 33px;
      width: 180px;
      border: 1px solid $snow-white;
      border-radius: 4.5px;
      background: none;
      color: $snow-white;
      margin-left: 19px;
      position: relative;
      bottom: 7px;
      outline: none;

      &:hover {
          background-color: $custom-secondary-color;
          color: $snow-white;
          border-color: $snow-white;
      }
  }

  &__custom-button-to-concept-icon {
      font-size: 23px;
      position: relative;
      top: 1px;
  }

  &__custom-breadcrumb {
      display: inline-block;
  }

  &__custom-breadcrumb-text {
      color: $attachment-custom-grey;
      font-family: Arial, sans-serif;
      font-size: 13px;
  }

  &__custom-breadcrumb-icon {
      color: $attachment-custom-grey;
      font-size: 23px;
      position: relative;
      top: 5px;
      padding-left: 1px;
      padding-right: 9px;
  }

  &__block-right-procedures {
      position: relative;
      float: right;
  }

  &__button-procedures-custom {
      height: 33px;
      color: $snow-white;
      position: relative;
      float: left;
      outline: none;
      border-radius: 4.5px;
      background-color: $background-error-28;
      border: 1px solid $snow-white;
      bottom: 7px;

      &--applicant-profile {
          margin-right: 8px;
      }

      &:hover:not([disabled]) {
          background-color: $custom-secondary-color;
          color: $snow-white;
          border-color: $snow-white;
      }

      &[disabled] {
          opacity: 0.5;
      }
  }

  &__text-button-procedures {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
      position: relative;
      bottom: 1.5px;
      margin-left: 4px;
  }

  &__icons-header-custom {
      font-size: 15px;
      outline: none;
      background-color: transparent;
      padding: 0;
      position: relative;
      float: left;
      margin-left: 25px;
      border-radius: 20px;
      height: 25px;
      width: 25px;
      border: 2px solid $snow-white;
      bottom: 2px;
      text-align: center;

      &--border-none {
          border: none;
          font-size: 25px;
          margin-left: 15px;

          &:hover:not([disabled]) {
              &::before {
                  content: '\ea7a';
              }
          }
      }

      &:hover:not([disabled]) {
          cursor: pointer;
          background-color: $custom-secondary-color;
          color: $snow-white;
          border-color: $custom-secondary-color;
      }

      &[disabled] {
          opacity: 0.5;
      }
  }

  &__header-block-custom {
      height: 52px;
      width: 100%;
      border-radius: 4px;
      color: $snow-white;
      background-color: $custom-secondary-color;
      display: block;
      padding: 14px 22px;
      &--open{
          padding: 14px 4px 14px 22px;
      }
  }

  &__icon-procedures-custom {
      font-size: 23px;
      margin-right: 10px;
  }

  &__title-procedures-custom {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 18px;
      text-transform: uppercase;
      line-height: 22px;
      display: inline-block;
      position: relative;
  }

  &__table-config-custom {
      background-color: $snow-white;
      width: 100%;
      padding: 0px 15px 10px;
      margin-top: 34px;
  }

  &__block-button-exit-custom {
      max-width: 1388px;
      margin: 0 auto;
      text-align: right;
      position: relative;
      right: 0px;
  }

  &__container-progress-poll-custom {
      height: 127px;
      width: 100%;
      position: relative;
      background-color: $background-grey;
      padding: 23px 0;
  }

  &__inside-block-progress-poll-custom {
      margin: 0 auto;
      position: relative;
      max-width: 1052px;
      padding: 0 10px;
  }

  &__block-progress-poll-custom {
      width: 81px;
      height: 81px;
      float: left;
      position: relative;
      border: 1px solid $custom-black-landing;
      border-radius: 41px;
      text-align: center;
      text-transform: uppercase;
      font-family: MTTMilano-Bold, sans-serif;
      line-height: 14px;
      padding: 25px 0px;
  }

  &__progress-poll-progress-custom {
      font-size: 25px;
  }

  &__text-poll-progress-custom {
      font-size: 10px;
      display: inline-block;
  }

  &__progress-bar-custom {
      width: calc(100% - 90px);
      float: right;
      position: relative;
      margin-left: 9px;
      height: 9px;
  }

  &__block-100-progress-poll {
      width: calc(100% - 90px);
      float: right;
      position: relative;
      text-align: right;
      color: $attachment-custom-grey;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 13px;
      margin-top: 20px;
  }

  &__block-expiration-date-poll-init-custom {
      position: relative;
      height: 51px;
      background: none;
      border: none;
      width: 940px;
      margin: 0 auto;
      text-align: right;
      padding-right: 21px;
      color: $custom-secondary-color;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      top: 36px;
  }

  &__custom-description-poll {
      font-family: MTTMilano, sans-serif;
      width: 940px;
      display: block;
      text-align: center;
      margin: 40px auto 30px;
      padding: 0 10px;
      max-width: 1388px;
      color: $custom-black-landing;
      line-height: 21px;
      font-size: 16px;
  }

  &__footer-banner {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 140px;
      background-color: $third-color-icam;
  }

  &__footer-banner-left {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 90%;

      & img {
          height: 116px;
      }
  }
  &__text-date-pull {
      text-transform: uppercase;
      font-size: 10px;
      color: $custom-secondary-color;
  }
  &__footer-banner-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
  }

  &__banner-center-text {
      font-family: MTTMilano, sans-serif;
      font-size: 20px;
      color: $primary-color-icam;
  }

  &__banner-center-button {
      display: flex;
      align-items: center;
      border: 1px solid $primary-color-icam;
      border-radius: 3px;
      height: 25px;
      margin-top: 20px;
      box-shadow: $box-shadow-icam;
      cursor: pointer;

      &:focus {
          text-decoration: none;
      }

      &:hover {
          text-decoration: none;
          color: $custom-secondary-color;
          border-color: $custom-secondary-color;

          & i, span {
              color: $custom-secondary-color;
          }
      }
  }

  &__banner-button-icon {
      font-size: 20px;
      color: $primary-color-icam;
  }

  &__banner-button-text {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 11px;
      color: $primary-color-icam;
      text-transform: uppercase;
      padding: 0 25px 0 5px;
  }

  &__footer-banner-right {
      width: 90%;
  }

  &__button-logout{
      border: none;
      background: none;
      color: $primary-color-icam;
      float: right;
      margin-top: 11px;
      margin-left: 17px;
      height: 22px;
      width: 22px;
      padding: 0;
  }

  &__block-languages{
      display: inline-block;
  }
  &__custom-buttons-behind{
      margin-right: 0;
  }
  //////////////////////////////////
  ///////////////Fin Custom////////////
  ////////////////////////////////
}

@media (min-width: 900px) and (max-width: 1420px){
  .landing-page{
      &__block-body{
          padding: 0 25px;
      }

      &__block-title{
          &:after{
              transform: perspective(6.5px) rotateX(0.4deg) translateZ(-0.65px);
          }
      }
      &__custom-description-poll{
          padding: 0 50px;
      }
  }
  .header-poll{
      &__client-img{
          padding-top: 65px;
      }
  }
}

@media (max-width: 1200px){
  .landing-page{
      &__show-procedures{
          left: 10px;
      }
      &__block-body {
          padding: 0 10px;
      }
  }
}

@media (max-width: 1024px) {
  .landing-page {
      &__custom-description-poll{
          width: 100%;
      }
  }
}

@media (max-width: 940px){
  .landing-page{
      &__show-procedures{
          left: 20px;
      }
  }
}

// .body-landing-custom{
//     @include common-scrollbar($custom-border-grey, $attachment-custom-grey-other);

//     .tooltip {
//         &[data-popper-placement^='top']{
//             .tooltip-arrow {
//                 left: calc(50% - 5px);
//                 border-top-color: $custom-secondary-color;
//                 &:before {
//                     content: '';
//                     border-top: 5px solid $attachment-custom-grey-other-2 !important;
//                     border-right: 5px solid transparent !important;
//                     border-bottom: 0px solid transparent !important;
//                     border-left: 5px solid transparent !important;
//                     left: calc(50% - 5px) !important;
//                     position: absolute !important;
//                     bottom: 1px !important;
//                 }
//             }
//         }
//         &[data-popper-placement^='bottom'] {
//             .tooltip-arrow {
//                 left: calc(50% - 5px);
//                 border-bottom-color: $custom-secondary-color;
//                 &:before {
//                     content: '';
//                     border-top: 0px solid transparent !important;
//                     border-right: 5px solid transparent !important;
//                     border-bottom: 5px solid $attachment-custom-grey-other-2 !important;
//                     border-left: 5px solid transparent !important;
//                     left: calc(50% - 5px) !important;
//                     position: absolute !important;
//                     top: 1px !important;
//                 }
//             }
//         }
//         &[data-popper-placement^='left'] {
//             .tooltip-arrow {
//                 top: 50%;
//                 border-left-color: $custom-secondary-color;
//                 &:before {
//                     content: '';
//                     border-top: 5px solid transparent !important;
//                     border-right: 0px solid transparent !important;
//                     border-bottom: 5px solid transparent !important;
//                     border-left: 5px solid $attachment-custom-grey-other-2 !important;
//                     left: inherit;
//                     top: calc(50% - 5px);
//                     position: absolute !important;
//                     right: 1px !important;
//                 }
//             }
//         }
//         &[data-popper-placement^='right'] {
//             .tooltip-arrow {
//                 top: 50%;
//                 border-right-color: $custom-secondary-color;
//                 &:before {
//                     content: '';
//                     border-top: 5px solid transparent !important;
//                     border-right: 5px solid $attachment-custom-grey-other-2 !important;
//                     border-bottom: 5px solid transparent !important;
//                     border-left: 0px solid transparent !important;
//                     top: calc(50% - 5px);
//                     position: absolute !important;
//                     left: 1px !important;
//                 }
//             }
//         }
//         .tooltip-inner {
//             border: 1px solid $custom-secondary-color !important;
//             background-color: $snow-white !important;
//             box-shadow: 0 0 5px 2px $certify-black-opacity !important;
//             color: $attachment-custom-grey !important;
//             font-family: Arial !important;
//             font-size: 10px !important;
//             border-radius: 0 !important;
//             padding: 5px 10px;
//             margin-left: 0;
//         }
//     }

//     .uib-datepicker-popup.dropdown-menu{
//         border: 1px solid $custom-black-landing;
//         box-shadow: none;
//         margin-top: 0px;
//     }

//     .common__input-block--poll-view{
//         .uib-datepicker-popup.dropdown-menu{
//             border: 1px solid $custom-black-landing;
//             box-shadow: none;
//             margin-top: -1px;
//             margin-left: 0px;
//         }
//     }
//     @include common-pagination($custom-black-landing, $custom-black-landing, $subcontainer-ground);
// }

@media (min-width: $max-width-smartphone) { //No-Smartphone
  .landing-page {
      &__block-arc-progress {
          display: none;
      }
  }
}

@media (max-width: $max-width-smartphone) { //Smartphone
  .landing-page {
      &__banner-center-text{
          text-align: center;
          margin: 0 52px;
      }
      &__footer-banner-left{
          display: none;
      }
      &__footer-banner-right{
          display: none;
      }
      &__custom-text-title{
          &--ellipsis-special{
              width: calc(100% - 250px);
          }
      }
      &__text-button-procedures--hidden-mobile{
          display: none;
      }

      &__container-progress-poll-custom {
          height: 140px;
      }

      &__block-progress-poll-custom {
          display: none;
      }

      &__svg-arc {
          height: 100px;
          transform: rotate(-90deg);
          width: 100px;
      }

      &__background-arc {
          fill: none;
          stroke: $circle-progress-grey;
          stroke-width: 1.6;
      }

      &__progress-arc {
          fill: none;
          stroke: $background-grey;
          stroke: var(--color);
          stroke-dasharray: 100 100;
          stroke-dashoffset: var(--dashoffset);
          stroke-linecap: round;
          stroke-width: 1.6;
      }

      &__block-arc-progress {
          display: block;
          overflow: hidden;
          margin: 0 auto;
          width: max-content;
      }

      &__elements {
          position: absolute;
          margin-top: 33px;
          width: 100px;
          line-height: 14px;
          text-align: center;
          font-family: MTTMilano-Bold, sans-serif;
      }

      &__text-poll-progress-custom {
          font-size: 12px;
          text-transform: uppercase;
      }

      &__block-body {
          & div.row:first-child {
              display: flex;
              flex-direction: column-reverse;
          }
          &--procedure{
              margin: 40px 15px 30px;
          }

          &--custom-task {
              padding: 0;
          }
      }

      &__custom-text-title {
          position: absolute;
          font-family: MTTMilano-Bold, sans-serif;
      }

      &__progress-complete {
          display: none;
      }

      &__progress-percent {
          display: none;
      }

      &__block-100-progress-poll {
          display: none;
      }

      &__button-logout {
          margin-right: 5px;
          margin-left: 5px;
      }

      &__custom-buttons-behind{
          margin-right: 15px;
      }

      &__title-procedures-custom{
          max-width: calc(100% - 72px);
          &--icam{
              max-width: calc(100% - 102px);
          }
          &--open{
              display: none;
          }
      }

      &__block-header-right{
          &--open{
              width: calc(100% - 33px);
          }
      }

      &__block-search{
          &--close{
              border: none;
              padding: 0;
          }
          &--open{
              width: 100%;
          }
      }

      &__text-search{
          display: none;
      }

      &__icon-button-search {
          top: 1px;
          line-height: 22px;
          &--open {
              top: 10px;
              line-height: initial;
          }
      }

      &__search-input{
          width: 100%;
      }

      &__button-add-list{
          margin-left: 10px;
      }
  }
}

@media print {
  .landing-page{
      &__block-body {
          & div.row:first-child {
              display: block;
          }
      }
      &__custom-text-title{
          &--ellipsis{
              width: 100%;
          }
      }
      &__block-right-procedures{
          display: none;
      }
  }
  .concept-form{
      &__container-fields{
          &--custom{
              border: 1px solid $custom-border-grey;
          }
      }
  }
  .common{
      &__buttons-block{
          display: none;
      }
  }
  .attachments-template{
      &--custom{
          border: 1px solid $custom-border-grey;
      }
  }
  .states-process{
      &__custom{
          border: 1px solid $custom-border-grey;
      }
  }
}
