.tooltip-law{
    &__block-icon{
        position: relative;
        width: auto;
    }

    &__button{
        height: auto;
        width: auto;
        border: none;
        background: none;
        padding: 0;
        z-index: 1;
        position: relative;
    }

    &__icons{
        position: relative;
        width: 18px;
        font-size: 18px;
        height: 18px;
        float: left;
        margin-right: 4px;
        &--container{
            color: $snow-white;
            width: 21px;
            font-size: 20px;
            height: 21px;
            margin-right: 6px;
        }
    }

    &__tooltip-inner-text {
        text-align: left;
        margin: 4px 0;
        font-family: Arial, sans-serif;
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
            cursor: pointer;
        }
    }

    &__tooltip-inner-text-custom {
        text-align: left;
        margin: 4px 0;
        font-weight: normal;
        font-style: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
            cursor: pointer;
        }
    }
}

.compliance {
  &__steel-blue {
    .tooltip-law {
      &__icons {
        color: $border-hover-2;
        &--container{
            color: $snow-white;
        }
      }
    }
  }

  &__icam-red {
    .tooltip-law {
      &__icons {
        color: $primary-color-icam;
        &--container{
            color: $snow-white;
        }
      }
    }
  }

  &__gm-law {
    .tooltip-law {
      &__icons {
        color: $primary-color-gm;
        &--container{
            color: $snow-white;
        }
      }
    }
  }
  &__com-de {
    .tooltip-law {
      &__icons {
        color: $primary-color-com-de;
        &--container{
            color: $snow-white;
        }
      }
    }
  }
  &__com-fr {
    .tooltip-law {
      &__icons {
        color: $primary-color-com-fr;
        &--container{
            color: $snow-white;
        }
      }
    }
  }

  &__landing-custom {
    .tooltip-law {
      &__icons {
          width: 13px;
          font-size: 13px;
          height: 13px;
          margin-right: 3px;
          color: $custom-black-landing;
          &--container{
              width: 23px;
              font-size: 22px;
              height: 23px;
              margin-right: 10px;
              color: inherit;
          }
          &--button-field{
              width: 22px;
              font-size: 22px;
              height: 22px;
              margin-right: 3px;
              color: $custom-black-landing;
          }
          &--sequential-poll{
              float: none;
              width: 10px;
              margin-right: 0;
              top: 2px;
              left: -4px;
          }
      }
    }
  }
}
