@mixin establish-pattern-week-day-color($borderColor, $color){
  border-color: $borderColor;
  color: $color;
}
@mixin establish-pattern-week-day-hover($borderColor, $backgroundColor){
  &:hover{
      border-color: $borderColor;
      background-color: $backgroundColor;
      cursor: pointer;
  }
}
@mixin establish-pattern-week-day-selected($primaryColor, $secondColor){
  background-color: $primaryColor;
  border-color: $primaryColor;
  color: $secondColor;
  &:hover{
      border-color: $primaryColor;
      background-color: $primaryColor;
      color: $secondColor;
      cursor: default;
  }
}
.establish-pattern{
  &__window {
      & .modal-dialog {
          & .modal-content {
              height: 655px;
              border-radius: 0px;
          }
      }
      &--concept{
          & .modal-dialog {
              & .modal-content {
                  height: 766px;

              }
          }
      }
  }

  &__body{
      padding: 15px 71px 25px 71px;
      position: relative;
      height: 568px;
  }
  &__body-expiration{
      padding: 45px 71px 70px 71px;
      position: relative;
      height: 511px;
  }
  &__periodicity-box{
      height: 182px;
      border: 1px solid $box-comment-border-03;
      background-color: $snow-white;
      display: block;
      width: 100%;
      padding: 8px;
  }
  &__periodicity-title{
      float: left;
      margin: 5px 0 0 1px;
      background-color: $background-grey-other;
      color: $repeat-pattern;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      padding: 3px 9px;
      text-transform: uppercase;
  }
  &__periodicity-list{
      width: 376px;
      display: block;
      float: right;
  }
  &__periodicity-block{
      width: 100%;
      display: block;
      height: 106px;
      clear: both;
      border: 1px solid $result-search-quote-color;
      background-color: $snow-white;
      &--repeat-interval{
          height: 141px;
      }
  }
  &__periodicity-block-title{
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      float: left;
      margin: 4px 0px 3px 10px;


  }
  &__periodicity-asterisk{
      color: $red;
      font-family: Arial, sans-serif;
      font-size: 18px;
      font-weight: bold;
  }
  &__periodicity-list-icon{
      opacity: 0.7;
      color: $attach-icon-grey;
      position: relative;
      float: right;
      top: 5px;
      right: 6px;
  }
  &__list-option{
      width: 100%;
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;
  }

  &__block-list-interval{
      display: block;
      margin: 2px 0 2px 8px;
      width: 100%;
      height: 30px;
      position: relative;
      float: left;
      &--middle{
          bottom: 3px;
      }
  }

  &__list-option-interval{
      list-style: none;
      font-weight: 500;
      padding: 3px 0;
      margin: 0;
      height: 30px;
      &--finish-after{
          padding: 0;
      }
      &:hover{
          cursor: pointer;
      }
  }

  &__option-item{
      padding-left: 8px;
      font-weight: 500;
      width: 100%;
      margin-bottom: 5px;
  }
  &__input-radio{
      visibility: hidden;
      height: 0;
      width: 0;
  }

  &__option-text{
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      float: left;
      margin: 5px 0 0 5px;
      &--cap{
          text-transform: capitalize;
      }
  }
  &__arrow-up{
      right: 5px;
      font-size: 10px;
      top: 2px;
  }
  &__arrow-down{
      right: 5px;
      font-size: 10px;
      bottom: 1px;
  }
  &__repeat-interval{
      border: 1px solid $box-comment-border-03;
      background-color: $snow-white;
      display: block;
      width: 100%;
      margin-top: 10px;
      padding: 8px;
      &--instances{
          height: 105px;
      }
  }

  &__week-day-list{
      list-style: none;
      float: left;
      margin: 0;
      position: relative;
      padding: 0px 0 0 2px;
      bottom: 2px;
  }
  &__week-day{
      float: left;
      border: 1px solid $custom-black-landing;
      text-transform: uppercase;
      padding: 6px 0;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      margin: 0 4px;
      width: 46px;
      &:first-child{
          margin-left: 2px;
      }
      &:last-child{
          margin-right: 0px;
      }

  }

  &__week-day-icon{
      font-size: 10px;
      color: $background-grey;
  }
  &__kind-moment-select{
      display: block;
      height: 30px;
      width: 150px;
      float: left;
      margin-left: 6px;
      position: relative;
      bottom: 3px;
      &--time{
          margin-right: 6px;
          width: 200px;
      }
  }

  &__instances-properties-block{
      display: block;
      width: 100%;
      clear: both;
      padding-top: 11px;
  }
  &__field-name{
      display: block;
      width: calc(50% - 3px);
      float: left;
      margin-right: 3px;
  }
  &__field-list{
      display: block;
      float: left;
      width: calc(50% - 2px);
      margin-left: 2px;
  }
   &__error-block{
      height: 55px;
      width: 100%;
      background-color: $background-error;
      color: $red;
      margin-top: 8px;
      margin-bottom: 6px;
      position: relative;
      display: table;
      z-index: 1;
  }
  &__icon-error{
      font-size: 40px;
      padding-left: 19px;
      display: table-cell;
      vertical-align: middle;
      width: 72px;
      &--info{
          color: $primary-color;
      }
  }
  &__text-error{
      font-family: Arial, sans-serif;
      font-size: 12px;
      position: relative;
      width: calc(100% - 72px);
      display: table-cell;
      vertical-align: middle;
      padding-right: 25px;
      text-align: left;
  }
  &__icon-close{
      font-size: 15px;
      position: absolute;
      top: 20px;
      right: 10px;
      &:hover{
          cursor: pointer;
      }
  }

}

.compliance{
  &__dark-blue{
    .establish-pattern{
      &__week-day{
          @include establish-pattern-week-day-color($result-search-quote-color, $primary-color);
          @include establish-pattern-week-day-hover($primary-color, $background-color-states);
          &--selected{
              @include establish-pattern-week-day-selected($repeat-pattern, $snow-white);
          }
      }
      &__error-block{
        &--info{
            background-color: $background-grey-other;
            color: $primary-color;
        }
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue{
    .establish-pattern{
      &__periodicity-box{
        border-color: $background-highlight-text-2;
      }
      &__periodicity-title{
        background-color: $background-highlight-text-2;
        color: $primary-color-2;
      }
      &__periodicity-block{
        border-color: $secondary-color-2;
      }
      &__periodicity-block-title{
        color: $primary-color-2;
      }
      &__periodicity-asterisk{
        color: $alert-color-2;
      }
      &__periodicity-list-icon{
        color: $grey-icons-2;
      }
      &__option-text{
        color: $primary-color-2;
      }
      &__repeat-interval{
        border-color: $background-highlight-text-2;
      }
      &__week-day{
          @include establish-pattern-week-day-color($secondary-color-2, $primary-color-2);
          @include establish-pattern-week-day-hover($border-hover-2, $third-color-2);
          &--selected{
              @include establish-pattern-week-day-selected($border-hover-2, $snow-white);
          }
      }
      &__error-block{
          color: $alert-color-2;
          background-color: $background-other-error-2;
          &--info{
              background-color: $background-grey-disabled-2;
              color: $primary-color-2;
          }
       }
    }
  }
  &__icam-red{
    .establish-pattern{
      &__periodicity-box{
        border-color: $border-block-icam;
      }
      &__periodicity-title{
        background-color: $background-box-icam;
        color: $title-text-icam;
      }
      &__periodicity-block{
        border-color: $border-block-icam;
      }
      &__periodicity-block-title{
        color: $custom-black-landing;
      }
      &__periodicity-asterisk{
        color: $alert-color-icam;
      }
      &__option-text{
        color: $custom-black-landing;
        margin-top: 4px;
        font-size: 13px;
      }
      &__periodicity-list-icon{
        color: $secondary-color-icam;
      }
      &__week-day{
          @include establish-pattern-week-day-color($border-block-icam, $custom-black-landing);
          @include establish-pattern-week-day-hover($primary-color-icam, $hover-block-icam);
        &--selected{
            @include establish-pattern-week-day-selected($primary-color-icam, $snow-white);
        }
      }
      &__error-block{
          color: $alert-color-icam;
          background: rgba($alert-color-icam, 0.2);
          &--info{
              color: $alert-color-icam;
              background: rgba($alert-color-icam, 0.2);
          }
      }
    }
  }
  &__gm-law{
    .establish-pattern{
      &__periodicity-box{
        border-color: $border-block-gm;
      }
      &__periodicity-title{
        background-color: $background-box-gm;
        color: $title-text-gm;
      }
      &__periodicity-block{
        border-color: $border-block-gm;
      }
      &__periodicity-block-title{
        color: $custom-black-landing;
      }
      &__periodicity-asterisk{
        color: $alert-color-gm;
      }
      &__option-text{
        color: $custom-black-landing;
        margin-top: 4px;
        font-size: 13px;
      }
      &__periodicity-list-icon{
        color: $secondary-color-gm;
      }
      &__week-day{
          @include establish-pattern-week-day-color($border-block-gm, $custom-black-landing);
          @include establish-pattern-week-day-hover($primary-color-gm, $hover-block-gm);
          &--selected{
              @include establish-pattern-week-day-selected($primary-color-gm, $snow-white);
          }
      }
      &__error-block{
          color: $alert-color-gm;
          background: rgba($alert-color-gm, 0.2);
          &--info{
              color: $alert-color-gm;
              background: rgba($alert-color-gm, 0.2);
          }
      }
    }
  }
  &__com-de{
    .establish-pattern{
      &__periodicity-box{
        border-color: $border-block-com-de;
      }
      &__periodicity-title{
        background-color: $background-box-com-de;
        color: $title-text-com-de;
      }
      &__periodicity-block{
        border-color: $border-block-com-de;
      }
      &__periodicity-block-title{
        color: $custom-black-landing;
      }
      &__periodicity-asterisk{
        color: $alert-color-com-de;
      }
      &__option-text{
        color: $custom-black-landing;
        margin-top: 4px;
        font-size: 13px;
      }
      &__periodicity-list-icon{
        color: $secondary-color-com-de;
      }
      &__week-day{
          @include establish-pattern-week-day-color($border-block-com-de, $custom-black-landing);
          @include establish-pattern-week-day-hover($primary-color-com-de, $hover-block-com-de);
          &--selected{
              @include establish-pattern-week-day-selected($primary-color-com-de, $snow-white);
          }
      }
      &__error-block{
          color: $alert-color-com-de;
          background: rgba($alert-color-com-de, 0.2);
          &--info{
              color: $alert-color-com-de;
              background: rgba($alert-color-com-de, 0.2);
          }
      }
    }
  }
  &__com-fr{
    .establish-pattern{
      &__periodicity-box{
        border-color: $border-block-com-fr;
      }
      &__periodicity-title{
        background-color: $background-box-com-fr;
        color: $title-text-com-fr;
      }
      &__periodicity-block{
        border-color: $border-block-com-fr;
      }
      &__periodicity-block-title{
        color: $custom-black-landing;
      }
      &__periodicity-asterisk{
        color: $alert-color-com-fr;
      }
      &__option-text{
        color: $custom-black-landing;
        margin-top: 4px;
        font-size: 13px;
      }
      &__periodicity-list-icon{
        color: $secondary-color-com-fr;
      }
      &__week-day{
          @include establish-pattern-week-day-color($border-block-com-fr, $custom-black-landing);
          @include establish-pattern-week-day-hover($primary-color-com-fr, $hover-block-com-fr);
          &--selected{
              @include establish-pattern-week-day-selected($primary-color-com-fr, $snow-white);
          }
      }
      &__error-block{
          color: $alert-color-com-fr;
          background: rgba($alert-color-com-fr, 0.2);
          &--info{
              color: $alert-color-com-fr;
              background: rgba($alert-color-com-fr, 0.2);
          }
      }
    }
  }
}
