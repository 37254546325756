.view-extended-report{
    padding: 80px 40px 40px;
    & .modal-dialog{
        margin-top: 0;
        & .modal-content{
            height: 1350px;
            border-radius: 0px;
        }
    }

    &__button-exit{
        background-color: transparent;
        border: none;
        outline: none;
        color: $button-exit;
        font-family: Arial, sans-serif;
        font-size: 11px;
        font-weight: bold;
        float: right;
        position: absolute;
        right: 45px;
        top: 30px;
    }

    &__block-questions {
        display: block;
        width: 100%;
        padding: 0;
        margin-bottom: 27px;
    }

    &__block-title-question {
        color: $concept-blue;
        font-family: MTTMilano, sans-serif;
        font-size: 14px;
        line-height: 20px;
    }

    &__icon-zoom-out{
        font-size: 20px;
        position: relative;
        top: 6px;
        margin-left: 3px;
    }

    &__bottom-info-block {
        position: relative;
        float: right;
        right: 5px;
        margin-top: 5px;
    }

    &__poll-amount-icon {
        // color: $concept-blue;
        position: relative;
        float: left;
        margin-right: 7px;
        font-size: 20px;
    }

    &__poll-amount-text {
        float: left;
        // color: $concept-blue;
        font-family: 'MTTMilano-Bold', sans-serif;
        font-size: 12px;
        margin: 0;
        &--left{
            margin-right: 10px;
        }
    }
}
.compliance{
    &__dark-blue{
        .view-extended-report{
            &__bottom-info-block {
                color: $concept-blue;
            }
        }
    }
    &__gm-law{
        .view-extended-report{
            &__button-exit{
                color: $custom-black-landing;
            }
            &__block-title-question {
                color: $text-field-color-gm;
            }
            &__bottom-info-block {
                color: $primary-color-gm;
            }
        }
    }
    &__com-de{
      .view-extended-report{
          &__button-exit{
              color: $custom-black-landing;
          }
          &__block-title-question {
              color: $text-field-color-com-de;
          }
          &__bottom-info-block {
              color: $primary-color-com-de;
          }
      }
  }
  &__com-fr{
    .view-extended-report{
        &__button-exit{
            color: $custom-black-landing;
        }
        &__block-title-question {
            color: $text-field-color-com-fr;
        }
        &__bottom-info-block {
            color: $primary-color-com-fr;
        }
    }
}
}
