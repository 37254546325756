.instant-videoconferences {
  &__form-body{
      margin-top: 25px;
  }

  &__button-add{
      right: 4px;
      float: right;
      position: relative;
      display: block;
  }

  &__block-table{
      margin: 40px 0 0;
      color: $primary-color;
      position: relative;
      float: left;
      width: 100%;
  }

  &__col {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      position: relative;
      line-height: 16px;
      max-width: 0;
      &--top-1{
          top: 1px;
      }
      &--title{
          width: 24%;
          padding-left: 5px;
      }
      &--participant{
          width: 16%;
          min-width: 135px;
      }
      &--date{
          width: 16%;
          min-width: 150px;
      }

      &--location{
          width: 18%;
          min-width: 110px;
      }

      &--url{
          width: 26%;
          min-width: 145px;
      }
      &--body{
          padding-right: 10px;
      }
  }

  &__block-body-table {
      display: block;
      width: 100%;
      height: 460px;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      position: relative;
  }

  &__row-body-hidden{
      visibility: hidden;
  }

  &__pagination-block {
      text-align: center;
      height: 22px;
      display: block;
      position: relative;
      float: left;
      width: 100%;
      margin-top: 46px;
  }

  &__col-text{
      max-width: calc(100% - 27px);
      position: relative;
      float: left;
  }

  &__col-text-link{
      max-width: calc(100% - 27px);
      position: relative;
      float: left;
      color: $primary-color;
      &:hover{
          text-decoration: underline;
          cursor: pointer;
      }
  }

  &__icon-copy{
      font-size: 20px;
      color: $primary-color;
      position: relative;
      bottom: 3px;
      left: 4px;
      &:hover{
          cursor: pointer;
      }
  }

  &__block-img{
      position: relative;
      width: 100%;
      text-align: center;
  }

  &__spinner-loader {
      width: 30px;
      margin-top: 80px;
      position: absolute;
  }
}
