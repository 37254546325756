.states-task-help{
  &__window{
      & .modal-dialog{
          & .modal-content{
              border-radius: 0px;
          }
      }
  }
  &__block-image{
      position: relative;
      width: 100%;
      padding: 0 20px;
  }
  &__image{
      width: 100%;
  }

  &__custom-window{
      & .modal-dialog{
          & .modal-content{
              height: 560px;
              width: 1180px;
              margin: 0 auto;
              border-radius: 4px;
              background-color: $snow-white;
              box-shadow: 0 4px 20px 0 $attachment-custom-grey-opacity;
          }
      }
  }
  &__custom-container{
      display: block;
      width: 100%;
      overflow: hidden;

  }
  &__icon-close-modal {
      color: $snow-white;
      float: right;
      font-size: 20px;
      top: 4px;
      position: relative;

      &:hover {
          cursor: pointer;
      }
  }
}

.compliance {
  &__steel-blue {
    .states-task-help {
      &__icon-close-modal {
        color: $white-special-2;
      }
    }
  }
  &__landing-custom{
    .states-task-help {
      &__block-image {
          width: 87%;
          margin: 50px auto 0;
          padding: 0;
      }
    }
  }
}
