.automatic-post-process-modal{
  & .modal-dialog{
      & .modal-content{
          height: 455px;
      }
  }

  &__form{
      padding: 47px 71px 0;
      width: 100%;
      height: 363px;
  }

  &__block-title{
      display: block;
      width: 100%;
      margin-bottom: 28px;
  }
}
