.field-type-txchk {
  min-height: 45px;
  border: 1px solid $primary-color;
  display: table;
  width: 100%;
  padding: 6px 0 0 38px;
  margin-bottom: 14px;
  position: relative;

  &:hover {
      & .common__tooltip-law, .common__info-icon-block, .common__tooltip-law-block {
          visibility: visible;
          // display: block;
      }
  }

  &__accept-terms-input {
      font-size: 14px;
      width: 22px;
      height: 19px;
      position: absolute;
      top: 6px;
      left: 6px;
      margin: 0;

      &:hover:not([disabled]) {
          cursor: pointer;
      }

      &--custom {
          top: 8px;
          left: 8px;
      }
  }

  &__accept-terms-icon {
      font-size: 18px;
      padding: 1px;
      outline: none;
      background-color: transparent;
      color: $primary-color;
      border: 1px solid transparent;
      &:hover:not([disabled]) {
          cursor: pointer;
          border: 1px solid $box-comment-border-02;
          background: $background-grey;
      }
  }

  &__accept-terms-text {
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      display: table-cell;
      vertical-align: top;
      padding-right: 5px;

      &--disabled {
          color: $secondary-color;
      }
  }

  &__required-icon {
      float: none;
      font-size: 14px;
  }

  &__option-box {
      float: left;
      margin: 0 2px 2px;
      padding: 6px 6px 5px 16px;
      font-family: Arial, sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      position: relative;
      &--process-ops {
          margin-top: 5px;
      }
  }

  &__asterisk {
      font-size: 18px;
      font-family: Arial, sans-serif;
      font-weight: bold;
      position: absolute;
      height: 16px;
      top: 4px;
      left: 6px;
  }

  &__tooltip-law {
      padding: 0 6px 0 5px;
      position: relative;
      bottom: 3px;
      font-size: 14px;
      // color: $primary-color;
      float: left;
  }

  &__tooltip-law-custom {
      padding: 0 3px 0 5px;
      position: relative;
      top: 3px;
      font-size: 15px;
      color: $custom-black-landing;
      float: left;
  }

  &__info-related {
      margin-left: 5px;
  }

  &__related-info {
      height: 18px;
  }
}

.compliance{
  &__dark-blue{
    .field-type-txchk {
        &--error{
          border-color: $red;
        }
        &--disabled{
            // border-color: $secondary-color;
            border-color: $attach-icon-grey;
            background: $background-grey;
        }
        &__accept-terms-icon {
          color: $primary-color;
          &:hover:not([disabled]) {
              border: 1px solid $box-comment-border-02;
              background: $background-color-states;
          }
          &--error{
              color: $red;
          }
          &--disabled{
                color: $subcontainer-ground;
                &:hover {
                    cursor: not-allowed;
                }
            }
        }
        &__option-box {
          &--required {
              background-color: $background-error;
              color: $red;
          }
          &--error {
              color: $snow-white;
              background: $red;
          }
          &--legal-references {
              background-color: $box-comment-border-03;
              color: $primary-color;
              padding: 6px 2px 0 5px;

              &:hover {
                  cursor: pointer;
              }
          }
        }
    }
  }
  &__steel-blue,
  &__uk-steel-blue {
    .field-type-txchk {
      border-color: $secondary-color-2;
      &--error{
          border-color: $alert-color-2;
      }
      &--disabled{
          border-color: $secondary-color-2;
          background-color: $background-grey-disabled-2;
      }
      &__accept-terms-icon {
          color: $border-hover-2;
          &:hover:not([disabled]) {
              border: 1px solid $third-color-2;
              background: $block-hover-2;
          }

        &--error{
            color: $alert-color-2;
        }
        &--disabled{
            color: $grey-icons-2;
        }
      }
      &__accept-terms-text {
        color: $primary-color-2;
      }
      &__option-box {
          &--required {
              background-color: $background-error-2;
              color: $alert-color-2;
          }

          &--error {
              color: $snow-white;
              background: $alert-color-2;
          }
          &--legal-references {
              background-color: $background-grey-opacity-2;
              color: $primary-color-2;
              padding: 6px 2px 0 5px;

              &:hover {
                  cursor: pointer;
              }
          }
      }
    }
  }
  &__icam-red{
    .field-type-txchk {
        border-color: $border-block-icam;
        &--error{
          border-color: $alert-color-icam;
        }
        &--disabled{
          background: $background-grey-disabled-icam;
        }
        &__accept-terms-icon {
          color: $primary-color-icam;
          &:hover:not([disabled]) {
              border: 1px solid $background-box-icam;
              background: $hover-block-icam;
          }
          &--error{
            color: $alert-color-icam;
          }
          &--disabled{
              color: $secondary-color-icam;
              &:hover {
                  cursor: not-allowed;
              }
          }
        }
        &__accept-terms-text {
          color: $custom-black-landing;
        }
        &__option-box {
          &--required {
              background-color: $red-task-20-icam;
              color: $alert-color-icam;
          }
          &--error {
              color: $snow-white;
              background: $alert-color-icam;
          }
          &--legal-references {
              background: rgba(119,125,139,0.1);
              color: $text-field-color-icam;
              padding: 6px 2px 0 5px;

              &:hover {
                  cursor: pointer;
              }
          }
        }
    }
  }
  &__gm-law{
    .field-type-txchk {
        border-color: $border-block-gm;
        &--error{
          border-color: $alert-color-gm;
        }
        &--disabled{
          background: $background-grey-disabled-gm;
        }
        &__accept-terms-icon {
            color: $primary-color-gm;
            &:hover:not([disabled]) {
                border: 1px solid $background-box-gm;
                background: $hover-block-gm;
            }
            &--error{
                color: $alert-color-gm;
            }
            &--disabled{
                color: $secondary-color-gm;

                &:hover {
                    cursor: not-allowed;
                }
            }
        }
        &__accept-terms-text {
          color: $custom-black-landing;
        }
        &__option-box {
          &--required {
              background-color: $red-task-20-gm;
              color: $alert-color-gm;
          }
          &--error {
              color: $snow-white;
              background: $alert-color-gm;
          }
          &--legal-references {
              background: rgba(119,125,139,0.1);
              color: $text-field-color-gm;
              padding: 6px 2px 0 5px;

              &:hover {
                  cursor: pointer;
              }
          }
        }
    }
  }
  &__com-de{
    .field-type-txchk {
        border-color: $border-block-com-de;
        &--error{
          border-color: $alert-color-com-de;
        }
        &--disabled{
          background: $background-grey-disabled-com-de;
        }
        &__accept-terms-icon {
            color: $primary-color-com-de;
            &:hover:not([disabled]) {
                border: 1px solid $background-box-com-de;
                background: $hover-block-com-de;
            }
            &--error{
                color: $alert-color-com-de;
            }
            &--disabled{
                color: $secondary-color-com-de;

                &:hover {
                    cursor: not-allowed;
                }
            }
        }
        &__accept-terms-text {
          color: $custom-black-landing;
        }
        &__option-box {
          &--required {
              background-color: $red-task-20-com-de;
              color: $alert-color-com-de;
          }
          &--error {
              color: $snow-white;
              background: $alert-color-com-de;
          }
          &--legal-references {
              background: rgba(119,125,139,0.1);
              color: $text-field-color-com-de;
              padding: 6px 2px 0 5px;

              &:hover {
                  cursor: pointer;
              }
          }
        }
    }
  }
  &__com-fr{
    .field-type-txchk {
        border-color: $border-block-com-fr;
        &--error{
          border-color: $alert-color-com-fr;
        }
        &--disabled{
          background: $background-grey-disabled-com-fr;
        }
        &__accept-terms-icon {
            color: $primary-color-com-fr;
            &:hover:not([disabled]) {
                border: 1px solid $background-box-com-fr;
                background: $hover-block-com-fr;
            }
            &--error{
                color: $alert-color-com-fr;
            }
            &--disabled{
                color: $secondary-color-com-fr;

                &:hover {
                    cursor: not-allowed;
                }
            }
        }
        &__accept-terms-text {
          color: $custom-black-landing;
        }
        &__option-box {
          &--required {
              background-color: $red-task-20-com-fr;
              color: $alert-color-com-fr;
          }
          &--error {
              color: $snow-white;
              background: $alert-color-com-fr;
          }
          &--legal-references {
              background: rgba(119,125,139,0.1);
              color: $text-field-color-com-fr;
              padding: 6px 2px 0 5px;

              &:hover {
                  cursor: pointer;
              }
          }
        }
    }
  }
  &__landing-custom {
    .field-type-txchk {
        box-sizing: border-box;
        height: 101px;
        border: 1px solid $custom-border-grey;
        background-color: $snow-white;
        box-shadow: 0 1px 4px 0 $modal-help-box;
        padding: 8px 10px 8px 34px;

        &:hover {
            border: 1px solid $custom-black-landing;
        }
        &--disabled {
            border-color: $attach-icon-grey;
            background: $background-grey;
            &:hover {
                border-color: $attach-icon-grey;
            }
        }
        &--error{
            border-color: $red;
        }
        &__accept-terms-icon {
            font-size: 12px;
            color: $custom-black-landing;
            &:hover {
                background: none;
            }
            &--error{
                color: $red;
            }
            &--disabled{
                color: $subcontainer-ground;

                &:hover {
                    cursor: not-allowed;
                }
            }
            &--poll {
                font-size: 20px;
            }
        }
        &__accept-terms-text {
            color: $custom-black-landing;
            font-family: 'lato_regular', sans-serif;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
            padding-right: 10px;
            &--poll {
                // color: $concept-blue;
                font-size: 18px;
            }

            &--error-poll {
                color: $red;
            }
        }
        &__asterisk {
            top: 5px;
        }
        &__option-box {
          &--required {
            text-transform: capitalize;
            height: 20px;
            border-radius: 30px;
            font-family: 'lato_regular', sans-serif;
            background-color: $field-type-required-color;
            color: $advice-close;
            text-align: center;
            padding: 1px 7px 0px 16px;
          }

          &--error {
            color: $snow-white;
            background: $red;
          }

          &--legal-references {
              height: 20px;
              background-color: $background-grey;
              border-radius: 20px;
              font-family: 'lato_regular', sans-serif;
              color: $custom-black-landing;
              padding: 1px 0px 1px 6px;

              &:hover {
                  cursor: pointer;
              }
          }
        }
    }
  }
}
