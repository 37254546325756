.new-contact{
  &__col-left{
      margin-right: 0.5%;
  }

  &__col-2{
      display: block;
      overflow:hidden;
      float:left;
      width: 49.75%;
      height: 54px;
      margin-bottom: 6px;

  }

  &__col-3{
      display: block;
      overflow:hidden;
      float:left;
      width: 34.5%;
      height: 54px;
      margin-bottom: 6px;
      margin-right: 0.5%;
  }

  &__col-3-100{
      display: block;
      overflow:hidden;
      float:left;
      width: 33%;
      height: 54px;
      margin-bottom: 6px;
      margin-right: 0.5%;
      &--right{
          margin-right: 0;
      }
  }

  &__col-4{
      display: block;
      overflow:hidden;
      float:left;
      width: 24.55%;
      height: 54px;
      margin-bottom: 6px;
      &--right{
          margin-right: 0%;
      }
      &--country{
          width: 24.45%;
          margin-right: 0.55%;
      }

  }

  &__col-5{
      display: block;
      overflow:hidden;
      float:left;
      width: 14.75%;
      height: 54px;
      margin-bottom: 6px;
      margin-right: 0.5%;

  }

  &__col-6{
      display: block;
      overflow:hidden;
      float:left;
      width: 19.75%;
      height: 54px;
      margin-bottom: 6px;
  }
}
