.reset-password {
    & .modal-dialog{
        & .modal-content{
            height: 503px;
        }
    }

    &__reset-password-form{
        width: 314px;
        margin: 0px auto;
    }

    &__body-form{
        position: relative;
        float: left;
        width: 100%;
    }

    &__info-block{
        padding: 11px 71px 21px;
        width: 100%;
        position: relative;
        float: left;
    }

    &__text-no-security{
        color: $reset-text-color;
        font-family: MTTMilano, sans-serif;
        font-size: 14px;
        font-weight: 500;
    }
}


.compliance {
  &__uk-steel-blue, &__steel-blue {
      & .reset-password{
        &__text-no-security{
          color: $alert-color-2;
        }
      }
  }

  &__gm-law {
      & .reset-password{
        &__text-no-security{
          color: $alert-color-gm;
        }
      }
  }
  &__com-de{
    & .reset-password{
      &__text-no-security{
        color: $alert-color-com-de;
      }
    }
  }
  &__com-fr{
    & .reset-password{
      &__text-no-security{
        color: $alert-color-com-fr;
      }
    }
  }

  &__icam-red {
      & .reset-password{
        &__text-no-security{
          color: $alert-color-icam;
        }
      }
  }
}
