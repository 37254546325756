.bar-graph{
  width: 100%;
  margin-top: 15px;
  position: relative;
  float: left;
  &__x-axxis{
      path{
          stroke: none;
      }
      .tick{
          text{
              fill: $primary-color;
              font-family: Arial, sans-serif;
              font-size: 10px;
              text-transform: uppercase;
          }
      }
  }

  &__bar-value{
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      &--no-margin{
        margin: 0;
      }
  }
}


@media (min-width: 1171px) and (max-width: 1366px){
  .bar-graph{
      margin-top: 10px;
  }
}

@media (max-width: 1171px){
  .bar-graph{
      margin-top: 5px;
  }
}

.compliance{
  &__gm-law{
      .bar-graph{
          &__x-axxis{
              .tick{
                  text{
                      fill: $custom-black-landing;
                  }
              }
          }
          &__bar-value{
              fill: $custom-black-landing;
          }
      }
  }
  &__com-de{
    .bar-graph{
        &__x-axxis{
            .tick{
                text{
                    fill: $custom-black-landing;
                }
            }
        }
        &__bar-value{
            fill: $custom-black-landing;
        }
    }
  }
  &__com-fr{
    .bar-graph{
        &__x-axxis{
            .tick{
                text{
                    fill: $custom-black-landing;
                }
            }
        }
        &__bar-value{
            fill: $custom-black-landing;
        }
    }
  }
}
