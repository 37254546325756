.field-type-color{
  position: relative;
  width: 100%;
  min-height: 50px;
  overflow: hidden;
  margin-bottom: 10px;
  &--open{
      overflow: visible;
  }
  &__input-block{
      // height: 50px;
      // max-height: 300px;
      // position: absolute;
      z-index: 10;
      margin: 0;
      // top: -40px;
      &--open{
          min-height: 300px;
          position: absolute;
          background-color: $snow-white;
          &:hover:not([disabled]){
            background-color: $snow-white !important;
          }
      }
  }
  &__color-selected-palette{
      display: block;
      height: 20px;
      width: 20px;
      position: absolute;
      top: 6px;
      right: 31px;
      border: 1px solid transparent;
  }
  &__palette-picker-block{
      visibility: hidden;
      &--visible{
          visibility: visible;
          position: relative;
          float: left;
          margin-top: 5px;
      }
      & input[type=range] {
          width: 375px;
          -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
          background: transparent; /* Otherwise white in Chrome */
      }

      & input[type=range]::-webkit-slider-thumb {
          -webkit-appearance: none;
        }

      & input[type=range]:focus {
          outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
        }

      & input[type=range]::-ms-track {
          width: 100%;
          background: transparent;
          border-color: transparent;
          color: transparent;
          cursor: pointer;
        }

        /* Special styling for WebKit/Blink */
      & input[type=range]::-webkit-slider-thumb{
          padding-top: 8px;
          -webkit-appearance: none;
          border: 1px solid $custom-black-landing;
          border-top: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid $custom-black-landing;
          border-left: 10px solid transparent;
          height: 0;
          width: 0;
          cursor: pointer;
      }
  }

  &__bright-saturation-block{
      width: 216px;
      height: 216px;
      display: block;
      position: relative;
      float: left;
      margin: 9px 8px 10px 10px;
      // margin: 9px 8px 10px 5px;
      cursor: pointer;
  }

  &__bright-saturation-selector-block{
      position: absolute;
      border-radius: 50%;
      height: 15px;
      width: 15px;
      border: 1px solid $snow-white;
      outline: none;
  }

  &__bright-saturation-selector{
      position: absolute;
      border-radius: 50%;
      height: 13px;
      width: 13px;
      border: 2px solid $custom-black-landing;
      background-color: $column-filter-shadow-005;
      outline: none;
      padding: 0;


  }
  &__right-block{
      display: block;
      // width: 135px;
      width: 132px;
      margin-top: 60px;
      float:left;
  }
  &__hex-block{
      height: 50px;
      width: 100%;
      //border: 1px solid $attach-icon-grey;
      border: 1px solid $result-search-quote-color;
      padding: 5px 9px 0;
      margin-top: 10px;
  }
  &__title-block-color{
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      font-weight: normal;
  }

  &__hex-hastag{
      float: left;
      font-weight: 400;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 14px;
  }
  &__input-text-color{
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 14px;
      width: 100%;
      position: relative;
      font-weight: normal;
      border: none;
      outline: none;
      background-color: transparent;
      top: 1px;

  }

  &__box-color-selected{
      display: block;
      height: 50px;
      // width: 133px;
      width: 132px;
      float: left;
      text-align: center;
      border: 1px solid transparent;
  }

  &__text-error-selected{
      font-size: 12px;
      margin: 16px 0px;
      display: inline-block;
      color: $red;
      font-family: Arial, sans-serif;

  }
  &__rgb-box{
      float: left;
      height: 50px;
      // width: 45px;
      width: 44px;
      //border: 1px solid $attach-icon-grey;
      border: 1px solid $result-search-quote-color;
      padding: 5px 0 0 9px;

      &--r{
          border-right: 0px;
      }
      &--b{
          border-left: 0px;
      }
  }

  &__range-block{
      display: block;
      width: 100%;
      // overflow: hidden;
      float: left;
      height: 25px;
      // padding-left: 5px;
      padding-left: 10px;
      margin-bottom: 10px;
  }

  &__range-line-color{
      height:8px;
      width: 356px;
      display:block;
      background: linear-gradient(to left,$alert-color,$highlight-icon,$lime,$cyan,$blue,$purple,$alert-color);
      &:hover{
          cursor: pointer;
      }
  }
  &__range-input{
      height: 8px;
      margin-left: -10px;
      top: -8px;
      position: relative;
      float: left;
      z-index: 1;
      &:hover{
          cursor: pointer;
      }
  }

  // &__show-alert {
  //     border: 1px solid #C43741 !important;
  //     background-color: $background-error !important;
  // }

  &__input-text{
      width: calc(100% - 61px);
  }

  &__icon-palette{
      font-size: 20px;
      color: $primary-color;
      opacity: 1;
      position: absolute;
      right: 6px;
      top: 6px;
      &:hover{
          cursor: pointer;
      }
  }

  &__block-buttons{
      float: right;
      right: 5px;
      position: relative;
  }
}


.compliance{
  &__dark-blue{
    .field-type-color{
      &__box-color-selected{
        //border-color: $attach-icon-grey;
        border-color: $result-search-quote-color;
        &--error{
          border-color:  $red;
        }
      }
      &__title-block-color{
        text-transform: uppercase;
      }
      &__bright-saturation-selector{
        border-color: $primary-color;
      }
      &__color-selected-palette{
        //border-color: $attach-icon-grey;
        border-color: $result-search-quote-color;
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue{
    .field-type-color{
      &__icon-palette{
        color: $border-hover-2;
      }
      &__rgb-box{
        border-color: $secondary-color-2;
      }
      &__text-error-selected{
        color: $alert-color-2;
      }
      &__box-color-selected{
        border-color: $secondary-color-2;
        &--error{
          border-color: $alert-color-2;
        }
      }
      &__input-text-color{
        color: $text-field-color-2;
      }
      &__title-block-color{
        color: $primary-color-2;
      }
      &__hex-block{
        border-color: $secondary-color-2;
      }
      &__bright-saturation-selector{
        border-color: $primary-color-2;
      }
      &__color-selected-palette{
        border-color: $secondary-color-2;
      }
    }
  }
  &__icam-red{
    .field-type-color{
      &__icon-palette{
        color: $primary-color-icam;
      }
      &__title-block-color{
        color: $custom-black-landing;
      }
      &__hex-block{
        border-color: $border-block-icam;
      }
    }


  }
  &__gm-law{
    .field-type-color{
      &__icon-palette{
        color: $primary-color-gm;
      }
      &__title-block-color{
        color: $custom-black-landing;
      }
      &__hex-block{
        border-color: $border-block-gm;
      }
    }
  }
  &__com-de{
    .field-type-color{
      &__icon-palette{
        color: $primary-color-com-de;
      }
      &__title-block-color{
        color: $custom-black-landing;
      }
      &__hex-block{
        border-color: $border-block-com-de;
      }
    }
  }
  &__com-fr{
    .field-type-color{
      &__icon-palette{
        color: $primary-color-com-fr;
      }
      &__title-block-color{
        color: $custom-black-landing;
      }
      &__hex-block{
        border-color: $border-block-com-fr;
      }
    }
  }
}
