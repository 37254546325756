.form-configuration-modal{
  & .modal-dialog{
      & .modal-content{
          height: 755px;
          border-radius: 0px;
      }
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  &__body-container-group{
    padding: 20px 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }
  &__container-group{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    padding: 0px 50px 0px 60px;
    align-self: stretch;
    height: 557px;
    overflow: auto;
    overflow-x: hidden;
    scrollbar-gutter: stable;
  }
  &__container-group-inner{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__group-header {
    @extend .customize-concept__group-header;
    height: 84px;
    padding: 6px 24px 6px 8px;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
  &__input-group-name{
    @extend .customize-concept__group-name;
    background: none;
    border: 1px solid #FFFFFF;
    padding: 7px 9px;
    outline: none;
    height: 38px;
  }
  &__block-button-add-group{
    display: flex;
    padding: var(--spacing-xxxs, 2px) 0px var(--spacing-xxxs, 2px) var(--spacing-xxl, 32px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-nule, 0px);
    align-self: stretch;
  }
  &__button-add-subgroup{
    @extend .customize-concept__button-add-subgroup;
    margin: 0;
  }
  &__group-container{
    display: flex;
    min-height: 305px;
    padding: var(--spacing-lg, 20px) 12px 12px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    overflow: auto;
    overflow-x: hidden;
    border-radius: var(--corner-radius-0, 0px);
    border-right: 1px solid #CDD1E4;
    border-bottom: 1px solid #CDD1E4;
    border-left: 1px solid #CDD1E4;
    background: var(--Neutral-White-000, #FFF);
  }
  &__list-menu-block{
    @extend .evaluation-menu__list-menu-block;
    top: 24px !important;
    right: 0px !important;
    left: initial !important;
  }
  &__body-container-field{
    width: 100%;
  }
  &__container-field{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    padding: 30px 70px 30px 70px;
    align-self: stretch;
    max-height: 668px;
  }
  &__container-footer-group{
    padding: 0px 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    &--with-fields{
      justify-content: space-between;
    }
  }
  &__container-report-channel{
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }
  &__label-report-channel{
    color: $secondary-color;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  &__container-advice{
    width: 100%;
  }
}
