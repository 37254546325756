.reference-document-modal{
  & .modal-dialog{
      & .modal-content{
          height: 755px;
          border-radius: 0px;
      }
  }

  &__body{
      width: 100%;
      padding: 10px 10px 0;
      position: relative;
      float: left;
  }

  &__block-form{
      width: 100%;
      padding: 14px 61px 0;
      position: relative;
      float: left;
      &--data-protection-view{
          padding-top: 20px;
          height: 580px;
          overflow: auto;
          overflow-x: hidden;
      }
  }

  &__table-header-box{
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 40px;
      border-bottom: 1px solid $primary-color;
      color: $primary-color;
  }

  &__table-body-box {
      width: 100%;
      height: 460px;
      overflow-y: auto;
      overflow-x: hidden;
      &--data-protection-view{
          max-height: 138px;
          height: auto;
      }
      &--more-elements{
          width: calc(100% + 10px);
      }
  }

  &__table-icon{
      margin-left: 15px;
      font-size: 20px;
  }

  &__col-table{
      padding: 0;
      padding-right: 5px;
      &--first{
          padding-left: 5px;
      }
      &--title{
          width: 45%;
      }
      &--format{
          width: 10%;
      }
      &--creation{
          width: 15%;
      }
      &--author{
          width: calc(30% - 65px);
      }
      &--actions{
          position: relative;
          width: 65px;
          padding: 0;
      }
  }

  &__icon-header-table{
      position: relative;
      float: left;
      font-size: 30px;
      margin-right: 10px;
  }

  &__title-header-table{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 18px;
      font-weight: normal;
      position: relative;
      bottom: 4px;
  }

  &__block-header-language{
      position: relative;
      float: left;
      width: 185px;
      text-align: right;
  }

  &__block-dropdown-language{
      position: relative;
      float: left;
      bottom: 4px;
      text-align: right;
      width: 121px;
  }

  &__header-button-add-new-version{
      float: initial;
  }

  &__menu-block-language{
      width: 0px;
      padding: 0;
      margin: 0;
      border: 0px;
      left: 4px;
      top: 10px;
  }

  &__block-info-language{
      position: relative;
      float: left;
      width: 100%;
      background-color: $background-color-states;
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      padding: 20px 35px 20px 10px;
      margin-top: 3px;
  }

  &__text-info-language{
      color: $legal-document-form;
      font-family: Arial, sans-serif;
      font-size: 12px;
      line-height: 20px;
      margin-top: 13px;
  }
  &__body-container-form-configuration{
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  }
  &__field-form-container{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__tag-info{
      font-family: Arial, sans-serif;
      font-weight: bold;
      font-size: 10px;
      text-transform: uppercase;
      color: $pillow-group;
      border: 1px solid $pillow-group;
      display: inline-block;
      line-height: 7px;
      padding: 5px 8px 5px;
      position: relative;
  }
}

.compliance{
  &__dark-blue{
    .reference-document-modal{
      &__table-icon{
        color: $primary-color;
      }
    }
  }
  &__steel-blue{
    .reference-document-modal{
      &__table-header-box{
        border-color: $background-highlight-text-2;
        color: $border-hover-2;
      }
      &__table-icon{
        color: $border-hover-2;
      }
      &__block-info-language{
        background-color: $background-grey-disabled-2;
        color: $primary-color-2;
      }
      &__text-info-language{
        color: $primary-color-2;
      }
    }
  }
  &__icam-red{
    .reference-document-modal{
      &__table-header-box{
        border-color: $primary-color-icam;
        color: $primary-color-icam;
      }
      &__table-icon{
        color: $primary-color-icam;
      }
    }
  }
  &__com-fr-law{
    .reference-document-modal{
      &__table-header-box{
        border-color: $primary-color-gm;
        color: $primary-color-gm;
      }
      &__table-icon{
        color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    .reference-document-modal{
      &__table-header-box{
        border-color: $primary-color-com-de;
        color: $primary-color-com-de;
      }
      &__table-icon{
        color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .reference-document-modal{
      &__table-header-box{
        border-color: $primary-color-com-fr;
        color: $primary-color-com-fr;
      }
      &__table-icon{
        color: $primary-color-com-fr;
      }
    }
  }
}
