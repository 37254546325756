@mixin results-report-header-block($backgroundColor, $color){
  background-color: $backgroundColor;
  color: $color;
  border-color: transparent;
}

@mixin results-report-block-field-graphic($borderColor, $shadowColor){
  border: 1px solid $borderColor;
  border-top-width: 2px;
  box-shadow: $shadowColor;
}

@mixin results-report-icon-change-question($color){
  color: $color;
  &:hover{
      cursor: pointer;
  }
}
@mixin results-report-element-list-fields($color, $backgroundColor){
  color: $color;
  &:hover{
      color: $color;
      background-color: $backgroundColor;
      cursor: pointer;
      & .results-report__icon-directions{
          visibility: visible;
      }
  }
}
.results-report{
  &__header-block{
      width: 100%;
      border: 1px solid $subcontainer-border;
      background-color: $background-grey-other;
      color: $primary-color;
      margin-top: 9px;
      position: relative;
      height: 74px;
      padding: 0;
  }

  &__body-block{
      background-color: $snow-white;
      width: 100%;
      min-height: 570px;
      padding: 10px;
      position: relative;
  }

  &__body-block-without-results{
      background-color: $snow-white;
      width: 100%;
      height: 55px;
      border: 1px solid $subcontainer-border;
      border-top: none;
      padding: 10px;
      position: relative;
  }

  &__back-button{
      position: absolute;
      top: 25px;
      left: 21px;
      height: 20px;
      padding: 0;
      border: none;
      outline: none;
      background: none;
      z-index: 1;
      color: $primary-color;
  }

  &__arrow-back-icon {
      font-size: 20px;
      padding-right: 8px;
      position: relative;
      top: 0;
  }

  &__arrow-back-text{
      font-family: MTTMilano, sans-serif;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      bottom: 3px;
  }

  &__icon-send-excel{
      font-size: 30px;
      color: $primary-color;
      position: absolute;
      right: 18px;
      top: 22px;
      padding: 0;
      background-color: transparent;
      border: none;
      outline: none;
      z-index: 1;
  }

  &__info-header{
      position: relative;
      width: 424px;
      height: 22px;
      text-align: center;
      margin: 25px auto;
  }

  &__info-total-poll{
      border: 1px solid $box-comment-border-03;
      font-family: Arial, sans-serif;
      font-size: 10px;
      width: 222px;
      height: 22px;
      padding: 2px 5px;
      position: relative;
      float: left;
      text-align: left;
      letter-spacing: 0.5px;
  }

  &__other-info-poll{
      position: relative;
      float: right;
  }

  &__number-poll{
      background-color: $primary-color;
      color: $snow-white;
      padding-top: 1.4px;
      border-radius: 25px;
      margin-left: 5px;
      width: 28px;
      height: 15px;
      text-align: center;
      display: inline-block;
  }

  &__info-date-expirate{
      position: relative;
      float: left;
      margin-left: 20px;
      border-bottom: 1px solid $box-comment-border-03;
      width: 180px;
      text-align: left;
      padding: 2px 5px 0px;
  }

  &__icon-calendar{
      font-size: 15px;
  }

  &__block-dates{
      display: inline;
      text-transform: uppercase;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 11px;
      margin-left: 3px;
      bottom: 2px;
      position: relative;
  }

  &__block-graphics{
      display: block;
      margin: 20px 0 0px;
      position: relative;
      width: 100%;
      min-height: 371px;
      padding: 0 60px 0 60px;
  }
  &__dropdown-block{
      width: 100%;
      display: block;
      height: 44px;
  }
  &__disggregate-block{
      width: calc(100% - 280px);
      display: block;
      height: 40px;
      background-color: $donut-text;
      margin-right: 10px;
  }
  &__disggregate-close-icon{
      float: right;
      font-size: 20px;
      color: $snow-white;
      padding-top: 9px;
      padding-right: 9px;
      &:hover{
          cursor: pointer;
      }
  }

  &__block-graphics-index{
      display: block;
      position: relative;
      width: 270px;
      height: 40px;
      color: $snow-white;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
      float: right;
      &--disaggregated{
          width: 218px;
      }
  }

  &__count-questions{
      position: relative;
      float: left;
      width: 62px;
      height: 40px;
      background-color: $donut-text;
      padding: 11px 0;
      text-align: center;
  }

  &__separator-count{
      margin: 0 4px;
  }

  &__disggregated-item{
      padding-top: 6px;
      padding-left: 5px;
      font-family: MTTMilano, sans-serif;
      font-size: 13px;
      font-weight: 500;
      &:hover{
          border-bottom: none;
      }
  }

  &__select-graphics-index{
      height: 40px;
      width: 208px;
      border: 1px solid $primary-color;
      background-color: $snow-white;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      float: left;
      padding: 13px 20px 13px 13px;
      &:hover{
          cursor: pointer;
      }
  }

  &__icon-open-select{
      position: absolute;
      right: 7px;
      font-size: 15px;
  }

  &__block-field-graphics{
      position: absolute;
      width: 270px;
      max-height: 504px;
      top: 40px;
      background-color: $snow-white;
      box-shadow: $box-shadow;
      z-index: 2;
      padding: 5px;
      margin: 0;
      border-radius: 0;
      &--disaggregated{
          width: 208px;
      }
  }

  &__block-list-fields{
      list-style: none;
      margin: 0px;
      padding: 0px;
      max-height: 484px;
      overflow-x: auto;
  }

  &__element-list-fields{
      @include results-report-element-list-fields($primary-color, $background-grey);
      position: relative;
      font-family: Arial, sans-serif;
      font-size: 11px;
      padding: 6px 5px 6px 5px;
      &--disaggregated{
          padding: 2px 5px 3px 5px;
      }
  }

  &__element-list-text{
      position: relative;
      width: calc(100% - 25px);
  }

  &__icon-directions{
      font-size: 20px;
      position: absolute;
      bottom: 3px;
      right: 5px;
      // color: $primary-color;
      visibility: hidden;
  }

  &__block-global-question{
      margin: 5px 0 40px 0;
      display: block;
      width: 100%;
  }

  &__block-questions{
      display: block;
      width: 100%;
      padding: 0 60px;
  }

  &__header-question{
      position: relative;
      width: 100%;
      color: $attach-icon-grey;
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 1px;
      height: 22px;
      margin-bottom: 5px;
  }

  &__name-group-poll{
      height: 16px;
      position: relative;
      float: left;
      top: 3px;
  }

  &__icon-angle-separator{
      position: relative;
      float: left;
      font-size: 15px;
      top: 3px;
  }

  &__block-title-question{
      color: $concept-blue;
      font-family: MTTMilano, sans-serif;
      font-size: 22px;
      line-height: 26px;
  }

  &__block-option-more-selected{
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      margin-top: 5px;
  }

  &__icon-change-question{
      color: $subcontainer-ground;
      font-size: 60px;
      position: absolute;
      top: 85px;
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
      z-index: 1;
      &:hover{
          cursor: auto;
      }
      &--left{
          left: -14px;
      }
      &--right{
          right: -14px;
      }
  }

  &__block-info-graph{
      position: relative;
      float: left;
      width: 100%;
      max-width: 380px;
      height: 350px;
      border: 1px solid $subcontainer-border;
      margin: 0;
      color: $primary-color;
  }

  &__triangle{
      width: 22px;
      height: 12px;
      position: absolute;
      left: calc(50% - 11px);
      top: -12px;
  }
  &__triangle-left, &__triangle-right{
      stroke:$subcontainer-border;
      stroke-width:1;
  }

  &__triangle-bottom{
      stroke:$snow-white;
      stroke-width:2;
  }
  &__order-icon{
      position: absolute;
      right: 5px;
      font-size: 14px;
      color: $subcontainer-ground;
      top: 35px;
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
  }
  &__block-table{
      position: relative;
      width: calc(100% - 50px);
      margin: 0 auto;
      margin-top: 35px;
  }
  &__info-graph-table-header{
      display: table;
      width: 100%;
      border-bottom: 1px solid $primary-color;
  }

  &__info-graph-table-body{
      display: table;
      width: 100%;
      min-height: 21px;
      &--overflow{
          width: calc(100% - 15px);
      }
  }

  &__header-text{
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      font-weight: 500;
      margin: 0 0 3px;
  }
  &__info-graph-cols{
      display: table-cell;
      &--options-body{
          width: calc(100% - 80px);
          padding: 0 5px;
      }
      &--options-header{
          width: calc(100% - 70px);
      }
      &--answers{
          width: 70px;
      }
      &--icon-color{
          width: 10px;
      }

  }
  &__dot-icon{
      font-size: 10px;
  }
  &__poll{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      &--percent{
          display: inline-block;
          width: 30px;
          margin: 0;
      }
      &--answer{
          display: inline-block;
          width: 30px;
          margin: 0;
          text-align: center;
      }
  }

  &__block-flex-graphs{
      width: 100%;
      display: flex;
      justify-content: space-between;
      &--bool, &--bool-responsive{
          max-width: 795px;
      }
  }

  &__block-bar-graph{
      position: relative;
      float: left;
      width: 380px;
      margin-left: 20px;
      min-height: 2px;
      &--multiple{
          width: 730px;
      }
  }

  &__block-donut-graph{
      position: relative;
      float: left;
      width: 364px;
      min-width: 364px;
      min-height: 2px;
      margin-left: 20px;
      bottom: 7px;
  }
  &__answer-block{
      display: block;
      width: 100%;
      height: 250px;
      overflow: auto;
      &--overflow{
          width: calc(100% + 25px);
      }
  }
  &__bottom-info-block{
      display: block;
      width: calc(100% - 50px);
      border-top: 1px solid $box-comment-border-02;
      margin: 0 auto;
      padding-top: 10px;
      position: relative;
      height: 40px;
  }
  &__poll-amount-icon{
      // color: $concept-blue;
      position: relative;
      float: left;
      font-size: 20px;
  }

  &__bottom-info-block-text{
      position: relative;
      float: left;
      margin-left: 8px;
      width: calc(100% - 28px);
  }

  &__poll-amount-text{
      float: left;
      font-family: 'MTTMilano-Bold', sans-serif;
      font-size: 12px;
      margin: 0;
      &--left{
          margin-right: 10px;
      }
  }

  &__spinner-loader{
      width: 30px;
      margin: 120px auto 0;
      position: relative;
      text-align: center;
  }
  &__maximize-modal-button{
      height: 31px;
      width: 31px;
      background-color: $primary-color;
      border: 0;
      position: absolute;
      bottom: 15px;
      right: 0;
      z-index: 10;
      outline: none;
      &--multiple{
          bottom: 8px;
      }
  }
  &__maximize-modal-icon{
      color: $snow-white;
      font-size: 20px;
      position: relative;
      top: 2px;
  }

  &__disgregated-title{
      color: $snow-white;
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      width: 146px;
      float: left;
      padding-top: 14px;
      padding-left: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }
}

.compliance{
  &__dark-blue{
      .results-report{
          &__body-block{
              border: 1px solid $subcontainer-border;
              border-top: none;
          }
          &__number-poll{
              &--pending{
                  background-color: $red;
                  margin: 0;
              }
              &--answered{
                  background-color: $green;
                  margin: 0;
              }
          }
          &__block-dates{
              color: $donut-text;
          }
          &__element-list-fields{
              color: $secondary-color;
              border-bottom: 1px solid transparent;
              &:hover{
                  border-bottom: 1px solid $box-comment-border-02;
                  font-weight: bold;
              }
          }
          &__icon-change-question{
              &--active{
                  @include results-report-icon-change-question($primary-color);
              }
          }
          &__order-icon{
              &--selected{
                  color: $primary-color;
              }
          }
          &__poll{
              &--question{
                  color: $bar-horizontal-task-grey;
              }
              &--percent{
                  color: $resul-report-percent;
              }
              &--answer{
                  color: $bar-horizontal-task-grey;
              }
          }
          &__bottom-info-block{
              color: $concept-blue;
          }
      }
  }
  &__icam-red{
      .results-report{
          &__header-block{
              @include results-report-header-block($third-color-icam, $custom-black-landing);
          }
          &__back-button{
              color: $primary-color-icam;
          }
          &__icon-send-excel{
              color: $primary-color-icam;
          }
          &__info-total-poll{
              border-color: $black-advice-landing;
          }
          &__number-poll{
              background-color: $custom-black-landing;
              &--pending{
                  background-color: $alert-color-icam;
                  margin: 0;
              }
              &--answered{
                  background-color: $green-task-icam;
                  margin: 0;
              }
          }
          &__info-date-expirate{
              border-color: $black-advice-landing;
          }
          &__count-questions{
              background-color: $custom-black-landing;
          }
          &__select-graphics-index{
              border-color: $custom-black-landing;
              color: $custom-black-landing;
          }
          &__block-field-graphics{
              @include results-report-block-field-graphic($primary-color-icam, $box-shadow-icam);
          }
          &__element-list-fields{
              @include results-report-element-list-fields($primary-color-icam, $hover-block-icam);
          }
          &__header-question{
              color: $primary-color-icam;
          }
          &__block-title-question{
              color: $text-field-color-icam;
          }
          &__block-option-more-selected{
              color: $secondary-color-icam;
          }
          &__icon-change-question{
              color: $secondary-color-icam;
              &--active{
                  @include results-report-icon-change-question($primary-color-icam);
              }
          }
          &__block-info-graph{
              border-color: $third-color-icam;
              color: $primary-color-icam;
          }
          &__triangle-left, &__triangle-right{
              stroke: $third-color-icam;
          }
          &__order-icon{
              border-color: $third-color-icam;
              &--selected{
                  color: $primary-color-icam;
              }
          }
          &__info-graph-table-header{
              border-color: $third-color-icam;
          }
          &__poll{
              &--question{
                  color: $custom-black-landing;
              }
          }

          &__bottom-info-block{
              border-color: $primary-color-icam;
          }
          &__maximize-modal-button{
              background-color: $custom-black-landing;
          }
      }
  }
  &__gm-law{
      .results-report{
          &__header-block{
              @include results-report-header-block($third-color-gm, $custom-black-landing);
          }
          &__back-button{
              color: $primary-color-gm;
          }

          &__icon-send-excel{
              color: $primary-color-gm;
          }
          &__info-total-poll{
              border-color: $black-advice-landing;
          }
          &__number-poll{
              background-color: $custom-black-landing;
              &--pending{
                  background-color: $alert-color-gm;
                  margin: 0;
              }
              &--answered{
                  background-color: $green-task-gm;
                  margin: 0;
              }
          }
          &__info-date-expirate{
              border-color: $black-advice-landing;
          }
          &__count-questions{
              background-color: $custom-black-landing;
          }
          &__select-graphics-index{
              border-color: $custom-black-landing;
              color: $custom-black-landing;
          }
          &__block-field-graphics{
              @include results-report-block-field-graphic($primary-color-gm, $box-shadow-gm);
          }
          &__element-list-fields{
             @include results-report-element-list-fields($primary-color-gm, $hover-block-gm);
          }
          &__header-question{
              color: $primary-color-gm;
          }
          &__block-title-question{
              color: $text-field-color-gm;
          }
          &__block-option-more-selected{
              color: $secondary-color-gm;
          }
          &__icon-change-question{
              color: $secondary-color-gm;
              &--active{
                  @include results-report-icon-change-question($primary-color-gm);
              }
          }
          &__block-info-graph{
              border-color: $third-color-gm;
              color: $primary-color-gm;
          }
          &__triangle-left, &__triangle-right{
              stroke: $third-color-gm;
          }
          &__order-icon{
              border-color: $third-color-gm;
              &--selected{
                  color: $primary-color-gm;
              }
          }
          &__info-graph-table-header{
              border-color: $third-color-gm;
          }
          &__poll{
              &--question{
                  color: $custom-black-landing;
              }
          }
          &__bottom-info-block{
              border-color: $primary-color-gm;
          }
          &__maximize-modal-button{
              background-color: $custom-black-landing;
          }
      }
  }
  &__com-de{
    .results-report{
        &__header-block{
            @include results-report-header-block($third-color-com-de, $custom-black-landing);
        }
        &__back-button{
            color: $primary-color-com-de;
        }

        &__icon-send-excel{
            color: $primary-color-com-de;
        }
        &__info-total-poll{
            border-color: $black-advice-landing;
        }
        &__number-poll{
            background-color: $custom-black-landing;
            &--pending{
                background-color: $alert-color-com-de;
                margin: 0;
            }
            &--answered{
                background-color: $green-task-com-de;
                margin: 0;
            }
        }
        &__info-date-expirate{
            border-color: $black-advice-landing;
        }
        &__count-questions{
            background-color: $custom-black-landing;
        }
        &__select-graphics-index{
            border-color: $custom-black-landing;
            color: $custom-black-landing;
        }
        &__block-field-graphics{
            @include results-report-block-field-graphic($primary-color-com-de, $box-shadow-com-de);
        }
        &__element-list-fields{
           @include results-report-element-list-fields($primary-color-com-de, $hover-block-com-de);
        }
        &__header-question{
            color: $primary-color-com-de;
        }
        &__block-title-question{
            color: $text-field-color-com-de;
        }
        &__block-option-more-selected{
            color: $secondary-color-com-de;
        }
        &__icon-change-question{
            color: $secondary-color-com-de;
            &--active{
                @include results-report-icon-change-question($primary-color-com-de);
            }
        }
        &__block-info-graph{
            border-color: $third-color-com-de;
            color: $primary-color-com-de;
        }
        &__triangle-left, &__triangle-right{
            stroke: $third-color-com-de;
        }
        &__order-icon{
            border-color: $third-color-com-de;
            &--selected{
                color: $primary-color-com-de;
            }
        }
        &__info-graph-table-header{
            border-color: $third-color-com-de;
        }
        &__poll{
            &--question{
                color: $custom-black-landing;
            }
        }
        &__bottom-info-block{
            border-color: $primary-color-com-de;
        }
        &__maximize-modal-button{
            background-color: $custom-black-landing;
        }
    }
  }
  &__com-fr{
    .results-report{
        &__header-block{
            @include results-report-header-block($third-color-com-fr, $custom-black-landing);
        }
        &__back-button{
            color: $primary-color-com-fr;
        }

        &__icon-send-excel{
            color: $primary-color-com-fr;
        }
        &__info-total-poll{
            border-color: $black-advice-landing;
        }
        &__number-poll{
            background-color: $custom-black-landing;
            &--pending{
                background-color: $alert-color-com-fr;
                margin: 0;
            }
            &--answered{
                background-color: $green-task-com-fr;
                margin: 0;
            }
        }
        &__info-date-expirate{
            border-color: $black-advice-landing;
        }
        &__count-questions{
            background-color: $custom-black-landing;
        }
        &__select-graphics-index{
            border-color: $custom-black-landing;
            color: $custom-black-landing;
        }
        &__block-field-graphics{
            @include results-report-block-field-graphic($primary-color-com-fr, $box-shadow-com-fr);
        }
        &__element-list-fields{
           @include results-report-element-list-fields($primary-color-com-fr, $hover-block-com-fr);
        }
        &__header-question{
            color: $primary-color-com-fr;
        }
        &__block-title-question{
            color: $text-field-color-com-fr;
        }
        &__block-option-more-selected{
            color: $secondary-color-com-fr;
        }
        &__icon-change-question{
            color: $secondary-color-com-fr;
            &--active{
                @include results-report-icon-change-question($primary-color-com-fr);
            }
        }
        &__block-info-graph{
            border-color: $third-color-com-fr;
            color: $primary-color-com-fr;
        }
        &__triangle-left, &__triangle-right{
            stroke: $third-color-com-fr;
        }
        &__order-icon{
            border-color: $third-color-com-fr;
            &--selected{
                color: $primary-color-com-fr;
            }
        }
        &__info-graph-table-header{
            border-color: $third-color-com-fr;
        }
        &__poll{
            &--question{
                color: $custom-black-landing;
            }
        }
        &__bottom-info-block{
            border-color: $primary-color-com-fr;
        }
        &__maximize-modal-button{
            background-color: $custom-black-landing;
        }
    }
  }
}

@media (min-width: 1700px) and (max-width: 1759px){
  .results-report{
      &--list-multiple{
          .results-report__info-graph-cols--options-header, .results-report__info-graph-table-body--overflow{
              width: 100%;
          }
          .results-report__info-graph-cols--options-body{
              width: calc(100% - 10px);
          }
          .results-report__info-graph-cols--answers, .results-report__order-icon{
              display: none;
          }
          .results-report__bottom-info-block-text{
              bottom: 6px;
          }
          .results-report__poll-amount-text{
              float: none;
          }
          .results-report__block-table, .results-report__bottom-info-block{
              width: calc(100% - 20px);
          }
          .results-report__answer-block--overflow{
              width: calc(100% + 10px);
          }
      }
  }
}

@media (min-width: 1367px) and (max-width: 1699px){
  .results-report{
      &--list-multiple{
          .results-report__block-info-graph{
              position: absolute;
              top: 365px;
              max-width: 100%;
              width: 100%;
              right: 0;
              height: auto;
          }
          .results-report__block-graphics{
              min-height: 407px;
          }
          .results-report__info-graph-cols--answers, .results-report__order-icon, .results-report__info-graph-table-header{
              display: none;
          }
          .results-report__triangle{
              left: 10px;
          }
          .results-report__block-table{
              height: auto;
              position: relative;
              float: right;
              width: calc(100% - 186px);
              border-left: 1px solid $box-comment-border-02;
              min-height: 48px;
              margin: 2px 0;
          }
          .results-report__answer-block{
              height: auto;
              max-height: 80px;
              padding: 2px 5px;
              &--overflow{
                  width: 100%;
              }
          }
          .results-report__info-graph-table-body{
              display: inline;
              margin-right: 5px;
          }
          .results-report__bottom-info-block{
              position: relative;
              float: left;
              width: 186px;
              margin: 0;
              border: none;
              height: 50px;
              padding-left: 5px;
          }
          .results-report__poll-amount-icon{
              top: 7px;
          }
          .results-report__block-donut-graph, .results-report__block-bar-graph{
              margin: 0;
          }
          .results-report__block-flex-graphs{
              display: block;
          }
          .results-report__block-bar-graph{
              margin: 0 auto;
              float: none;
          }
          .results-report__title-option-footer{
              display: block;
          }
      }
  }
}

@media (min-width: 1253px) and (max-width: 1313px){
  .results-report{
      &--list-multiple{
          .results-report__info-graph-cols--options-header, .results-report__info-graph-table-body--overflow{
              width: 100%;
          }
          .results-report__info-graph-cols--options-body{
              width: calc(100% - 10px);
          }
          .results-report__info-graph-cols--answers, .results-report__order-icon{
              display: none;
          }
          .results-report__bottom-info-block-text{
              bottom: 6px;
          }
          .results-report__poll-amount-text{
              float: none;
          }
          .results-report__block-table, .results-report__bottom-info-block{
              width: calc(100% - 20px);
          }
          .results-report__answer-block--overflow{
              width: calc(100% + 10px);
          }
      }
  }
}

@media (max-width: 1252px){
  .results-report{
      &--list-multiple{
          .results-report__block-info-graph{
              position: absolute;
              top: 365px;
              max-width: 100%;
              width: 100%;
              right: 0;
              height: auto;
          }
          .results-report__block-graphics{
              min-height: 407px;
          }
          .results-report__info-graph-cols--answers, .results-report__order-icon, .results-report__info-graph-table-header{
              display: none;
          }
          .results-report__triangle{
              left: 10px;
          }
          .results-report__block-table{
              height: auto;
              position: relative;
              float: right;
              width: calc(100% - 186px);
              border-left: 1px solid $box-comment-border-02;
              min-height: 48px;
              margin: 2px 0;
          }
          .results-report__answer-block{
              height: auto;
              max-height: 80px;
              padding: 2px 5px;
              &--overflow{
                  width: 100%;
              }
          }
          .results-report__info-graph-table-body{
              display: inline;
              margin-right: 5px;
          }
          .results-report__bottom-info-block{
              position: relative;
              float: left;
              width: 186px;
              margin: 0;
              border: none;
              height: 50px;
              padding-left: 5px;
          }
          .results-report__poll-amount-icon{
              top: 7px;
          }
          .results-report__block-donut-graph, .results-report__block-bar-graph{
              margin: 0;
          }
          .results-report__block-flex-graphs, .results-report__title-option-footer{
              display: block;
          }
          .results-report__block-bar-graph{
              margin: 0 auto;
              float: none;
          }
      }
  }
}

@media (min-width: 1625px) and (max-width: 1695px){
  .results-report{
      &--list-simple, &--numerical-scale{
          .results-report__info-graph-cols--options-header, .results-report__info-graph-table-body--overflow{
              width: 100%;
          }
          .results-report__info-graph-cols--options-body{
              width: calc(100% - 10px);
          }
          .results-report__info-graph-cols--answers, .results-report__order-icon{
              display: none;
          }
          .results-report__bottom-info-block-text{
              bottom: 6px;
          }
          .results-report__poll-amount-text{
              float: none;
          }
          .results-report__block-table, .results-report__bottom-info-block{
              width: calc(100% - 20px);
          }
          .results-report__answer-block--overflow{
              width: calc(100% + 10px);
          }
      }
  }
}

@media (min-width: 1367px) and (max-width: 1624px){
  .results-report{
      &--list-simple, &--numerical-scale{
          .results-report__block-info-graph{
              position: absolute;
              top: 365px;
              max-width: 100%;
              width: 100%;
              right: 0;
              height: auto;
          }
          .results-report__block-graphics{
              min-height: 407px;
          }
          .results-report__info-graph-cols--answers, .results-report__order-icon, .results-report__info-graph-table-header{
              display: none;
          }
          .results-report__triangle{
              left: 10px;
          }
          .results-report__block-table{
              height: auto;
              position: relative;
              float: right;
              width: calc(100% - 186px);
              border-left: 1px solid $box-comment-border-02;
              min-height: 48px;
              margin: 2px 0;
          }
          .results-report__answer-block{
              height: auto;
              max-height: 80px;
              padding: 2px 5px;
              &--overflow{
                  width: 100%;
              }
          }
          .results-report__info-graph-table-body{
              display: inline;
              margin-right: 5px;
          }
          .results-report__bottom-info-block{
              position: relative;
              float: left;
              width: 186px;
              margin: 0;
              border: none;
              height: 50px;
              padding-left: 5px;
          }
          .results-report__poll-amount-icon{
              top: 7px;
          }
          .results-report__block-donut-graph, .results-report__block-bar-graph{
              margin: 0;
          }
          .results-report__title-option-footer{
              display: block;
          }
      }
  }
}

@media (min-width: 1281px) and (max-width: 1340px){
  .results-report{
      &--list-simple, &--numerical-scale{
          .results-report__info-graph-cols--options-header, .results-report__info-graph-table-body--overflow{
              width: 100%;
          }
          .results-report__info-graph-cols--options-body{
              width: calc(100% - 10px);
          }
          .results-report__info-graph-cols--answers, .results-report__order-icon{
              display: none;
          }
          .results-report__bottom-info-block-text{
              bottom: 6px;
          }
          .results-report__poll-amount-text{
              float: none;
          }
          .results-report__block-table, .results-report__bottom-info-block{
              width: calc(100% - 20px);
          }
          .results-report__answer-block--overflow{
              width: calc(100% + 10px);
          }
      }
  }
}

@media (max-width: 1280px){
  .results-report{
      &--list-simple, &--numerical-scale{
          .results-report__block-info-graph{
              position: absolute;
              top: 365px;
              max-width: 100%;
              width: 100%;
              right: 0;
              height: auto;
          }
          .results-report__block-graphics{
              min-height: 407px;
          }
          .results-report__info-graph-cols--answers, .results-report__order-icon, .results-report__info-graph-table-header{
              display: none;
          }
          .results-report__triangle{
              left: 10px;
          }
          .results-report__block-table{
              height: auto;
              position: relative;
              float: right;
              width: calc(100% - 186px);
              border-left: 1px solid $box-comment-border-02;
              min-height: 48px;
              margin: 2px 0;
          }
          .results-report__answer-block{
              height: auto;
              max-height: 80px;
              padding: 2px 5px;
              &--overflow{
                  width: 100%;
              }
          }
          .results-report__info-graph-table-body{
              display: inline;
              margin-right: 5px;
          }
          .results-report__bottom-info-block{
              position: relative;
              float: left;
              width: 186px;
              margin: 0;
              border: none;
              height: 50px;
              padding-left: 5px;
          }
          .results-report__poll-amount-icon{
              top: 7px;
          }
          .results-report__block-donut-graph, .results-report__block-bar-graph{
              margin: 0;
          }

          .results-report__title-option-footer{
              display: block;
          }
      }
      .results-report__icon-change-question--right{
          right: -11px;
      }
      .results-report__icon-change-question--left{
          left: -11px;
      }
      .results-report__icon-change-question{
          font-size: 40px;
      }
      .results-report__block-questions{
          padding: 0 30px;
      }
      .results-report__block-graphics{
          padding: 0 30px;
      }
  }
}

@media (max-width: 975px){
  .results-report{
      &--list-simple, &--numerical-scale{
          .results-report__block-flex-graphs{
              display: block;
          }
          .results-report__block-graphics{
              min-height: 760px;
          }
          .results-report__block-info-graph{
              top: 715px;
          }
          .results-report__block-donut-graph, .results-report__block-bar-graph{
              margin: 0 auto;
              float: none;
          }
      }
  }
}

@media (min-width: 820px) and (max-width: 880px){
  .results-report{
      &--bool{
          .results-report__info-graph-cols--options-header, .results-report__info-graph-table-body--overflow{
              width: 100%;
          }
          .results-report__info-graph-cols--options-body{
              width: calc(100% - 10px);
          }
          .results-report__info-graph-cols--answers, .results-report__order-icon{
              display: none;
          }
          .results-report__bottom-info-block-text{
              bottom: 6px;
          }
          .results-report__poll-amount-text{
              float: none;
          }
          .results-report__block-table, .results-report__bottom-info-block{
              width: calc(100% - 20px);
          }
          .results-report__answer-block--overflow{
              width: calc(100% + 10px);
          }
      }
  }
}

@media (max-width: 819px){
  .results-report{
      &--bool{
          .results-report__block-info-graph{
              position: absolute;
              top: 365px;
              max-width: calc(100% - 60px);
              width: calc(100% - 60px);
              right: 30px;
              height: auto;
          }
          .results-report__block-graphics{
              min-height: 407px;
          }
          .results-report__info-graph-cols--answers, .results-report__order-icon, .results-report__info-graph-table-header{
              display: none;
          }
          .results-report__triangle{
              left: 10px;
          }
          .results-report__block-table{
              height: auto;
              position: relative;
              float: right;
              width: calc(100% - 186px);
              border-left: 1px solid $box-comment-border-02;
              min-height: 48px;
              margin: 2px 0;
          }
          .results-report__answer-block{
              height: auto;
              max-height: 80px;
              padding: 2px 5px;
              &--overflow{
                  width: 100%;
              }
          }
          .results-report__info-graph-table-body{
              display: inline;
              margin-right: 5px;
          }
          .results-report__bottom-info-block{
              position: relative;
              float: left;
              width: 186px;
              margin: 0;
              border: none;
              height: 50px;
              padding-left: 5px;
          }
          .results-report__poll-amount-icon{
              top: 7px;
          }
          .results-report__block-flex-graphs, .results-report__title-option-footer{
              display: block;
          }
          .results-report__block-donut-graph{
              margin: 0 auto;
              float: none;
          }
      }
  }
}

@media (min-width: 1367px) and (max-width: 1699px){
  .results-report{
      .results-report__block-questions, .results-report__block-graphics{
          padding: 0 45px;
      }
  }
}

@media (max-width: 1115px){
  .results-report{
      &__arrow-back-text{
          &--extra{
              display: none;
          }
      }
  }
}

@media (min-width: 1367px) and (max-width: 1430px){
  .results-report{
      &--bool, &--list-simple, &--list-multiple, &--numerical-scale{
          .results-report__arrow-back-text--extra{
              display: none;
          }
      }
  }
}
