@import '../../../../assets/scss/colors';

@mixin select-simple-open($borderColor, $backgroundColor){
  border-color: $borderColor;
  background-color: $backgroundColor;
}

.select-simple{
  border: 1px solid $attach-icon-grey;
  height: 30px;

  &__select-simple-box{
      width: 100%;
      display: block;
      box-sizing: border-box;
      position: relative;
  }
  &__input-select{
      width: calc(100% - 21px);
      border: none;
      height: 28px;
      background: none;
      outline: none;
      font-size: 12px;
      padding-left: 5px;
      &:hover{
        cursor: pointer;
      }
      &--disabled{
          &:hover{
              cursor: auto;
          }
      }
  }
  &__select-simple-icon{
      width: 16px;
      color: $attach-icon-grey;
      position: absolute;
      top: 8px;
      right: 5px;
      background-color: transparent;
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
      &:hover:not([disabled]) {
          cursor: pointer;
      }
  }
  &__block-option-list{
      top: 0;
      left: -1px;
      box-shadow: 0 3px 6px 2px $bar-horizontal-grey-opacity;
  }
}
.compliance{
  &__dark-blue{
    .select-simple{

      border-color: $result-search-quote-color;
      &:hover:not([disabled]){
          @include select-simple-open($primary-color, $background-color-states);
      }

      &--disabled{
          border-color: $result-search-quote-color;
          background-color: $background-grey;
      }
      &--open{
          @include select-simple-open($primary-color, $background-color-states);
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue {
    .select-simple{
      border-color: $secondary-color-2;
      &:hover:not([disabled]) {
          @include select-simple-open($border-hover-2, $third-color-2);
      }
      &--disabled{
          border-color: $secondary-color-2;
          background-color: $background-grey-disabled-2;
      }
      &--open{
          @include select-simple-open($border-hover-2, $third-color-2);
      }

    }
  }
  &__icam-red{
    .select-simple{
      border-color: $border-block-icam;
      &:hover:not([disabled]) {
          @include select-simple-open($primary-color-icam, $hover-block-icam);
      }
      &--disabled{
          border-color: $border-block-icam;
          background-color: $background-grey-disabled-icam;
      }
      &--open{
        @include select-simple-open($primary-color-icam, $hover-block-icam);
      }
    }
  }
  &__gm-law{
    .select-simple{
      border-color: $border-block-gm;
      &:hover:not([disabled]) {
          @include select-simple-open($primary-color-gm, $hover-block-gm);
      }

      &--disabled{
          border-color: $border-block-gm;
          background-color: $background-grey-disabled-gm;
      }
      &--open{
          @include select-simple-open($primary-color-gm, $hover-block-gm);
      }
    }
  }
  &__com-de{
    .select-simple{
      border-color: $border-block-com-de;
      &:hover:not([disabled]) {
          @include select-simple-open($primary-color-com-de, $hover-block-com-de);
      }

      &--disabled{
          border-color: $border-block-com-de;
          background-color: $background-grey-disabled-com-de;
      }
      &--open{
          @include select-simple-open($primary-color-com-de, $hover-block-com-de);
      }
    }
  }
  &__com-fr{
    .select-simple{
      border-color: $border-block-com-fr;
      &:hover:not([disabled]) {
          @include select-simple-open($primary-color-com-fr, $hover-block-com-fr);
      }

      &--disabled{
          border-color: $border-block-com-fr;
          background-color: $background-grey-disabled-com-fr;
      }
      &--open{
          @include select-simple-open($primary-color-com-fr, $hover-block-com-fr);
      }
    }
  }
}
