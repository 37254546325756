.report-export{
  &__window{
      & .modal-dialog{
          & .modal-content{
              height: 503px;
              border-radius: 0px;
          }
      }
  }
  &__content{
      display: block;
      padding: 67px 293px 0 293px;
      width: 100%;
      height: 418px;
  }
  &__block-select{
      width: 100%;
      position: relative;
      float: left;
      margin: 5px 0;
  }
}
