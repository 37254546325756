.field-type-other{

  &__block-information{
      width: 100%;
      vertical-align: middle;
      height: 35px;
      margin-top: 5px;
  }

  &__block-related-fields {
      // display: inline-block;
      width: 100%;
      border: 1px solid $subcontainer-poll-border;
      background-color: $snow-white;
      box-shadow: 0px 0px 0px -4px $menu-header-transparent;
      border-top: none;
      height: 100%;
      display: flex;
      position: relative;
  }

  &__detail-info{
      padding: 0 0;
  }

  &__description-block{
      height: 155px;
  }

  &__edit-icon{
      font-size: 13px;
      padding-left: 5px;
      &:hover{
          cursor: pointer;
      }
  }

  &__field-view{
      border-left: none;
      border-right: none;
  }

  &__icon-nav{
      color: $field-type-number-icon;
      float: right;
      font-size: 12px;
      margin-top: 8px;
      cursor: pointer;
      &--disabled{
          visibility: hidden;
      }
      &--enabled{
          visibility: visible;
      }
  }

  &__information{
      display: table-cell;
      width: 100%;
      vertical-align: middle;
      height: 35px;
      margin-top: 5px;
      max-width: 0;
      &--cursor-default{
          cursor:default;
      }
  }

  &__input-class{
      background: transparent;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 97%;
      &::-webkit-input-placeholder {
          padding-left: 0px;
      }
  }

  &__label-block {
      height: auto;
      margin-bottom: 11px;
      margin-top: -10px;
  }

  &__list-block{
      padding: 0;
      height: 106px;
      overflow-y: auto;
  }

  &__list-info{
      margin-bottom: 8px;
      &:first-child{
          margin-top: 21px;
      }
      &:last-child{
          margin-bottom: 8px;
      }
  }

  &__name-related-field {
      margin-left: 11px;
      color: $primary-color;
      font-size: 12px;
      font-weight: bold;
      float: left;
      // width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 180px;
      &:hover{
          cursor: pointer;
      }
  }

  &__number-icon {
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 17px;
      text-align: center;
      position: relative;
      font-style: normal;
      border-radius: 12px;
      float: right;
      background-color: $field-type-number-icon;
      color: $snow-white;
      margin: 6px 8px 0 8px;
      height: 15px;
      width: 26px;

  }

  &__open-evaluated-concept{
      float: right;
      color: $primary-color;
      font-size: 16px;
      margin-right: 9px;
      margin-top: 9px;
  }

  &__options-block{
      margin-right: 5px;
      width: 85px;
      position: absolute;
      right: 0;
  }

  &__related{
      display: block;
      float: right;
      width: 70%;
      height: 100%;
      position: relative;
      margin-top: 9px;
  }

  &__related-field{
      margin-left: 14px;
      color: $primary-color;
      font-size: 12px;
      font-weight: bold;
  }

  &__related-fields-block-list{
      list-style: none;
      // margin-left: -12px;
      padding: 0px 5px 0px 32px;
      float: left;
      width: 73%;
  }

  &__related-list-block{
      display: inline-block;
      padding: 0;
      position: relative;
      width: 100%;
      min-height: 75px;
      &--no-editable{
          cursor: not-allowed !important;
      }
  }

  &__related-list-block-list{
      width: 97%;
      display: block;
      background-color: $snow-white;
      border-top: 0;
      padding: 0;
      list-style: none;
      overflow: hidden;
      margin-left: 0;
      margin-bottom: 0;
  }

  &__related-number{
      height: 15px;
      margin-top: -3px;
  }

  &__show-information {
      display: table;
      position: relative;
      width: 100%;
  }

  &__text-no-instances{
      border: none;
      padding-left: 10px;
      height: 22px;
      outline: 0;
      width: 90%;
      font-weight: 500;
      background: transparent;
      color: $subcontainer-ground;
      font-family: Arial, sans-serif;
      font-style: italic;
      font-size: 12px;
      letter-spacing: 0.5px;
      cursor: unset;
      margin-left: -8px;
  }

  &__title-list{
      color: $primary-color;
      font-size: 11px;
      margin: 0;
      margin-left:11px;
      &--multiple{
          font-weight: 100;
      }
  }

  &__title-related-info{
      float: left;
      width: 25%;
      padding: 0;
      height: 100%;
      position: relative;
      display: table;
      border-right: 0.8px solid $box-comment-border-02;
      padding-right: 10px;


      &--custom{
          border: none;
          width: 28%;
      }
  }

  &__title-text{
      margin-left: 14px;
  }

  &__triangle-related-field{
      &:after {
          content: '';
          width: 0;
          height: 0;
          border: 0 solid transparent;
          border-bottom-width: 20px;
          border-top-width: 20px;
          border-left: 15px solid $box-comment-border-02;
          position: absolute;
          vertical-align: middle;
          // margin-left: 213px;
          margin-left: 100%;
          opacity: 0.6;
          top: calc(50% - 20px);
      }
  }

  &__triangle-related-field-border{
      &:after{
          content: '';
          width: 0;
          height: 0;
          border: 0 solid transparent;
          border-bottom-width: 19px;
          border-top-width: 19px;
          border-left: 15px solid $snow-white;
          position: absolute;
          vertical-align: middle;
          // margin-left: 211px;
          margin-left: calc(100% - 1px);
          // top: 35%;
          top: calc(50% - 19px);
      }
  }
  &__related-text{
      float: left;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: bold;
      padding-left: 4px;
      margin-bottom: 0;
      color: $subcontainer-ground;
      position: relative;
      top: 2px;
      max-width: 315px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }
  &__related-title{
      float: left;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 11px;
      text-transform: uppercase;
      margin-bottom: 0;
      margin-top: 1px;
      border-bottom: 1px solid $attach-icon-grey;
      padding: 1.5px;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }

  &__block-input-search{
      position: relative;
      display: inline-block;
      width: calc(100% - 30px);
      &--multiple {
          width: calc(100% - 60px);
      }
      &--block-100{
          width: 100%;
      }
      &--no-l-co{
          width: 100%;
      }

      // &--create-list {
      //     border: 1px solid $primary-color;
      //     background-color: $background-grey;
      //     height: 29px;
      //     width: 94%;
      // }
  }

  // &__margen{
  //     right: 30px !important;
  // }

  ////////////////////////////////////
  ///////////////Custom//////////////
  ///////////////////////////////////
  &__custom{
      box-shadow: 0 1px 4px 0 $modal-help-box;
  }

  &__label-block-custom{
      height: auto;
      margin-bottom: 11px;
      margin-top: -15px;
      position: relative;
  }

  &__options-block-custom {
      margin-top: 3px;
      width: 85px;
      position: absolute;
      right: 0;
  }

  &__icon-nav-custom{
      color: $custom-black-landing;
      float: right;
      font-size: 15px;
      cursor: pointer;
      &--disabled{
          visibility: hidden;
      }
      &--enabled{
          visibility: visible;
      }
  }

  &__info-block-custom{
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      border: 1px solid $custom-border-grey;
      border-top: none;
  }

  &__related-title-custom{
      float: left;
      text-transform: uppercase;
      margin-bottom: 0;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: $custom-black-landing;
      font-family: 'lato_regular', sans-serif;
      font-size: 11px;
  }

  &__related-text-custom{
      float: left;
      padding-left: 4px;
      margin-bottom: 0;
      position: relative;
      max-width: 315px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: $custom-secondary-color;
      font-family: 'lato_regular', sans-serif;
      font-size: 14px;
      bottom: 2px;
  }

  &__block-list-custom{
      padding: 20px 5px 20px 32px;
      display: table-cell;
      vertical-align: middle;
      float: left;
      width: 72%;
  }

  &__related-fields-block-list-custom{
      list-style: none;
      width: 100%;
      border-left: 1px solid $custom-background;
      padding: 5px 0 5px 15px;
      margin: 0;
      min-height: 35px;
  }

  &__list-info-custom{
      margin-bottom: 8px;
      height: 22px;
      padding: 4px 0;
  }

  &__title-list-custom{
      margin: 0;
      margin-left: 11px;
      color: $border-modal;
      font-family: 'lato_regular', sans-serif;
      font-size: 13px;
  }

  &__name-related-field-custom {
      margin: 4px 0 0 11px;
      color: $custom-black-landing;
      font-family: 'lato_regular', sans-serif;
      font-size: 14px;
      float: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 180px;
      &--no-name{
          color: $red;
      }
  }

  &__list-type{
      margin: 0;
  }
  ////////////////////////////////////
  ///////////////Fin Custom//////////////
  ///////////////////////////////////

  &__relationship-block{
      margin-top: 16px;
  }
  &__relationship-text{
      float: left;
      width: fit-content;
      padding-right: 9px;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 11px;
      margin-bottom: 0;
  }
  &__relationship-icon{
      font-size: 20px;
      color: $primary-color;
      position: relative;
      bottom: 6px;
      &:hover{
          cursor: pointer;
      }
  }
  &__relationship-observation{
      min-height: 20px;
      background: $background-grey-other;
      position: relative;
      bottom: 8px;
      margin: 0;
      color: $repeat-pattern;
      font-family: Arial, sans-serif;
      font-size: 11px;
      padding: 1px 6px 3px;
  }
}



.compliance{
  &__dark-blue{
    .field-type-other{
      &__related-list-block{
        &--no-editable{
            color: $secondary-color;
            cursor: not-allowed !important;
            background: $background-grey;
        }
      }
      &__triangle-related-field-border{
        &--no-editable{
          &:after{
              border-left: 15px solid $background-grey;
          }
        }
      }
      &__block-input-search{
        bottom: 1px;
        &--add{
          bottom: 3px;
        }
      }
    }
  }
  &__steel-blue{
    .field-type-other{
      &__edit-icon{
        color: $border-hover-2;
      }
      &__block-related-fields {
        border-color: $secondary-color-2;
      }
      &__icon-nav{
        &--next, &--prev{
              color: $border-hover-2;
          }
      }
      &__name-related-field {
        &--no-name{
          color: $alert-color-2;
          font-weight: 600;
        }
        color: $primary-color-2;
      }
      &__number-icon {
        background-color: $grey-icons-2;
      }
      &__title-list{
        color: $primary-color-2;
      }
      &__title-related-info{
        border-color: $secondary-color-2;
      }
      &__triangle-related-field{
        &:after {
            border-left-color: $secondary-color-2;
        }
      }
      &__related-text{
        color: $grey-icons-2;
      }
      &__related-title{
        color: $primary-color-2;
        border-color: $primary-color-2;
      }
      &__block-input-search,
      &__block-input-search--add{
        bottom: 4px;
      }
      &__relationship-text{
        color: $primary-color-2
      }
      &__relationship-icon{
        color: $border-hover-2;
      }
      &__relationship-observation{
        background-color: $background-grey-disabled-2;
        color: $primary-color-2
      }
    }
  }
  &__icam-red{
    .field-type-other{
      &__edit-icon{
        color: $primary-color-icam;
      }
      &__block-related-fields {
        border-color: $secondary-color-2;
      }
      &__icon-nav{
        &--next{
              color: $secondary-color-icam;
          }
        &--prev{
              color: $primary-color-icam;
          }
        }
      &__name-related-field {
        &--no-name{
          color: $alert-color-2;
          font-weight: 600;
      }
        color: $custom-black-landing;
      }
      &__number-icon {
        background-color: $secondary-color-icam;
      }
      &__title-list{
        color: $custom-black-landing;
      }
      &__title-related-info{
        border-color: $secondary-color-2;
      }
      &__triangle-related-field{
        &:after {
            border-left-color: $secondary-color-2;
        }
      }
      &__related-text{
        color: $text-field-color-icam;
      }
      &__related-title{
        color: $custom-black-landing;
        border-color: $border-block-icam;
      }
      &__block-input-search,
      &__block-input-search--add{
        bottom: 4px;
      }
      &__relationship-text{
        color: $custom-black-landing
      }
      &__relationship-icon{
        color: $primary-color-icam;
      }
      &__relationship-observation{
        background-color: $background-grey-disabled-icam;
        color: $custom-black-landing
      }
    }
  }
  &__gm-law{
    .field-type-other{
      &__edit-icon{
        color: $primary-color-gm;
      }
      &__block-related-fields {
        border-color: $secondary-color-2;
      }
      &__icon-nav{
        &--next{
              color: $secondary-color-gm;
          }
         &--prev{
              color: $primary-color-gm;
          }
        }
      &__name-related-field {
        &--no-name{
          color: $alert-color-2;
          font-weight: 600;
        }
          color: $custom-black-landing;
      }
      &__number-icon {
        background-color: $secondary-color-gm;
      }
      &__title-list{
        color: $custom-black-landing;
      }
      &__title-related-info{
        border-color: $secondary-color-2;
      }
      &__triangle-related-field{
        &:after {
            border-left-color: $secondary-color-2;
        }
      }
      &__related-text{
        color: $text-field-color-gm;
      }
      &__related-title{
        color: $custom-black-landing;
        border-color: $border-block-gm;
      }
      &__block-input-search,
      &__block-input-search--add{
        bottom: 4px;
      }
      &__relationship-text{
        color: $custom-black-landing
      }
      &__relationship-icon{
        color: $primary-color-gm;
      }
      &__relationship-observation{
        background-color: $background-grey-disabled-gm;
        color: $custom-black-landing
      }
    }
  }
  &__com-de{
    .field-type-other{
      &__edit-icon{
        color: $primary-color-com-de;
      }
      &__block-related-fields {
        border-color: $secondary-color-2;
      }
      &__icon-nav{
        &--next{
              color: $secondary-color-com-de;
          }
         &--prev{
              color: $primary-color-com-de;
          }
        }
      &__name-related-field {
        &--no-name{
          color: $alert-color-2;
          font-weight: 600;
        }
          color: $custom-black-landing;
      }
      &__number-icon {
        background-color: $secondary-color-com-de;
      }
      &__title-list{
        color: $custom-black-landing;
      }
      &__title-related-info{
        border-color: $secondary-color-2;
      }
      &__triangle-related-field{
        &:after {
            border-left-color: $secondary-color-2;
        }
      }
      &__related-text{
        color: $text-field-color-com-de;
      }
      &__related-title{
        color: $custom-black-landing;
        border-color: $border-block-com-de;
      }
      &__block-input-search,
      &__block-input-search--add{
        bottom: 4px;
      }
      &__relationship-text{
        color: $custom-black-landing
      }
      &__relationship-icon{
        color: $primary-color-com-de;
      }
      &__relationship-observation{
        background-color: $background-grey-disabled-com-de;
        color: $custom-black-landing
      }
    }
  }
  &__com-fr{
    .field-type-other{
      &__edit-icon{
        color: $primary-color-com-fr;
      }
      &__block-related-fields {
        border-color: $secondary-color-2;
      }
      &__icon-nav{
        &--next{
              color: $secondary-color-com-fr;
          }
         &--prev{
              color: $primary-color-com-fr;
          }
        }
      &__name-related-field {
        &--no-name{
          color: $alert-color-2;
          font-weight: 600;
        }
          color: $custom-black-landing;
      }
      &__number-icon {
        background-color: $secondary-color-com-fr;
      }
      &__title-list{
        color: $custom-black-landing;
      }
      &__title-related-info{
        border-color: $secondary-color-2;
      }
      &__triangle-related-field{
        &:after {
            border-left-color: $secondary-color-2;
        }
      }
      &__related-text{
        color: $text-field-color-com-fr;
      }
      &__related-title{
        color: $custom-black-landing;
        border-color: $border-block-com-fr;
      }
      &__block-input-search,
      &__block-input-search--add{
        bottom: 4px;
      }
      &__relationship-text{
        color: $custom-black-landing
      }
      &__relationship-icon{
        color: $primary-color-com-fr;
      }
      &__relationship-observation{
        background-color: $background-grey-disabled-com-fr;
        color: $custom-black-landing
      }
    }
  }
}
