.box-query-state{
  &__progress-bar-custom{
      height: 10px;
      width: 100%;
      background-color: $custom-border-grey;
      border-radius: 5px;
  }
  &__progress-selected-bar-custom{
      width: 33%;
      height: 10px;
      border-radius: 5px;
      background-color: $green;
      &--in-process{
          margin-left: 33.5%;
      }
      &--finished{
          margin-left: 67%;
      }
  }
  &__block-status{
      text-align: center;
      text-transform: uppercase;
      color: $custom-black-landing;
      font-family: 'lato_regular', sans-serif;
      font-size: 11px;
      font-weight: bold;
      margin-top: 23px;
  }
  &__icon-status-progress{
      font-size: 15px;
      color: $green;
      position: relative;
      top: 2px;
  }
}
