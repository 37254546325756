@mixin box($backgroundColor, $color){
  background-color: $backgroundColor;
  color: $color;
}

.advice-message{
  &__box{
      height: 55px;
      width: 100%;
      margin-top: 10px;
      position: relative;
      // float: left;
      display: table;
      &--no-margin{
          margin: 0;
      }
      &--margin-bottom{
        margin-bottom: 10px;
      }
  }
  &__icon-type{
      font-size: 40px;
      padding-left: 19px;
      display: table-cell;
      vertical-align: middle;
      width: 72px;

  }
  &__text{
      font-family: Arial, sans-serif;
      font-size: 12px;
      position: relative;
      width: calc(100% - 72px);
      display: table-cell;
      vertical-align: middle;
      padding-right: 25px;
      padding-top: 2px;
      text-align: left;
  }
  &__icon-close{
      font-size: 15px;
      position: absolute;
      top: 20px;
      right: 10px;
      &:hover{
          cursor: pointer;
      }
  }
}

.compliance{
  &__dark-blue{
    .advice-message{
      &__box{
        &--info{
          @include box($background-grey-other, $primary-color)
        }
        &--error{
          @include box($background-error, $red)
        }
        &--warning{
          @include box($yellow-opacity-02, $yellow)
        }
      }
      &__text{
        &--warning{
          color: $secondary-color;;
        }
      }
    }
  }
  &__steel-blue, &__uk-steel-blue{
    .advice-message{
      &__box{
        &--info{
            @include box($background-grey-disabled-2, $primary-color-2)
        }
        &--error{
            @include box($background-error-2, $alert-color-2)
        }
        &--warning{
            @include box($warning-background-2,$warning-yellow)
        }
      }
      &__text{
        &--warning{
          color: $primary-color-2;
        }
      }
    }
  }
  &__icam-red{
    .advice-message{
      &__box{
        &--info{
          @include box($background-grey-disabled-icam, $custom-black-landing)
        }
        &--error{
          @include box($red-task-20-icam, $alert-color-icam)
        }
        &--warning{
          @include box($yellow-task-20-icam, $yellow-task-icam)
        }
      }
      &__text{
        &--warning{
          color: $custom-black-landing;
        }
      }
    }
  }
  &__gm-law{
    .advice-message{
      &__box{
        &--info{
          @include box($background-grey-disabled-gm, $custom-black-landing)
        }
        &--error{
          @include box($red-task-20-icam, $alert-color-icam)
        }
        &--warning{
          @include box($yellow-task-20-icam, $yellow-task-icam)
        }
      }
      &__text{
        &--warning{
          color: $custom-black-landing;
        }
      }
    }
  }
  &__com-de{
    .advice-message{
      &__box{
        &--info{
          @include box($background-grey-disabled-com-de, $custom-black-landing)
        }
        &--error{
          @include box($red-task-20-com-de, $alert-color-com-de)
        }
        &--warning{
          @include box($yellow-task-20-com-de, $yellow-task-com-de)
        }
      }
      &__text{
        &--warning{
          color: $custom-black-landing;
        }
      }
    }
  }
  &__com-fr{
    .advice-message{
      &__box{
        &--info{
          @include box($background-grey-disabled-com-fr, $custom-black-landing)
        }
        &--error{
          @include box($red-task-20-com-fr, $alert-color-com-fr)
        }
        &--warning{
          @include box($yellow-task-20-com-fr, $yellow-task-com-fr)
        }
      }
      &__text{
        &--warning{
          color: $custom-black-landing;
        }
      }
    }
  }
  &__landing-custom{
    .advice-message{
      &__box{
        &--info{
          @include box($black-advice-landing, $custom-black-landing);
          height: 42px;
        }
        &--error{
          @include box($red-advice-landing, $advice-close);
          height: 42px;
        }
        &--warning{
          @include box($yellow-advice-landing, $advice-exclamantion);
          height: 42px;
        }
      }

      &__icon-type{
          font-size: 30px;
          width: 51px;
          padding-left: 9px;
      }

      &__text{
          width: calc(100% - 51px);
          color: $custom-black-landing;
          padding-right: 15px;
          font-family: MTTMilano, sans-serif;
          font-size: 13px;
          line-height: 15px;
      }
    }
  }
}

@media (max-width: $max-width-smartphone) { //Smartphone
  .compliance{
    &__landing-custom{
      .advice-message{
        &__text {
            padding: 5px 5px 5px 0;
        }
      }
    }
  }
}
