@import '../../../../assets/scss/colors';

.menu-header-products {
  position: absolute;
  right: 100px;
  width: 350px;
  top: 32px;
  padding: 20px;

  &__header {
      display: flex;
      justify-content: space-between;
  }

  &__logo {
      margin-top: 10px;
  }

  &__close-button {
      font-size: 13px;
      height: 13px;
      cursor: pointer;
  }

  &__title {
      display: flex;
      align-items: center;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      width: 100%;
      height: 40px;
      border-bottom: 1px solid;
  }

  &__products-list {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      margin: 20px 0 0;
      padding: 0;
  }

  &__product {
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  &__product-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      border-radius: 25px;
      font-size: 27px;
      cursor: pointer;
  }

  &__product-label {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;
      margin-top: 5px;
  }
}

.compliance {
  &__dark-blue {
    & .menu-header-products {
      background-color: $snow-white;
      box-shadow: 0 0 25px 10px $bar-horizontal-grey-opacity;

      &__close-button {
        color: $primary-color;
      }

      &__title {
        color: $primary-color;
        border-bottom-color: $primary-color;
      }

      &__product-icon {
        color: $snow-white;
        background-color: $primary-color;
      }

      &__product-label {
        color: $primary-color;
      }
    }
  }

  &__gm-law {
    & .menu-header-products {
      background-color: $snow-white;
      box-shadow: 0 0 25px 10px $bar-horizontal-grey-opacity;

      &__close-button {
        color: $primary-color-gm;
      }

      &__title {
        color: $primary-color-gm;
        border-bottom-color: $primary-color-gm;
      }

      &__product-icon {
        color: $snow-white;
        background-color: $primary-color-gm;
      }

      &__product-label {
        color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    & .menu-header-products {
      background-color: $snow-white;
      box-shadow: 0 0 25px 10px $bar-horizontal-grey-opacity;

      &__close-button {
        color: $primary-color-com-de;
      }

      &__title {
        color: $primary-color-com-de;
        border-bottom-color: $primary-color-com-de;
      }

      &__product-icon {
        color: $snow-white;
        background-color: $primary-color-com-de;
      }

      &__product-label {
        color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    & .menu-header-products {
      background-color: $snow-white;
      box-shadow: 0 0 25px 10px $bar-horizontal-grey-opacity;

      &__close-button {
        color: $primary-color-com-fr;
      }

      &__title {
        color: $primary-color-com-fr;
        border-bottom-color: $primary-color-com-fr;
      }

      &__product-icon {
        color: $snow-white;
        background-color: $primary-color-com-fr;
      }

      &__product-label {
        color: $primary-color-com-fr;
      }
    }
  }
}
