@mixin task-concept-process-task($textColor) {
  color: $textColor;
  width: calc(100% - 40px);
  margin-left: 20px;
}
@mixin task-concept-pr-task-property-value-state($backgroundColor) {
  margin-top: 3px;
  box-sizing: border-box;
  background-color: $backgroundColor;
  color: $snow-white;
  border-radius: 15px;
  padding: 2px 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-height: 20px;
  max-width: 100%;
  font-family: Arial, sans-serif;
}
@mixin task-concept-task-state-color($backgroundColor, $textColor) {
  background-color: $backgroundColor;
  color: $textColor;
}
@mixin task-concept-task-state-process($backgroundColor) {
  background-color: $backgroundColor;
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // display: -webkit-box;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
}

.task-concept {
  min-height: 600px;

  &__icon-user-login {
      display: inline-block;
      font-size: 16px;
      color: $primary-color;
      margin-right: 7px;
  }

  &__options{
      visibility: visible;
      padding: 0 6px 0 16px;
      right: 12px;
  }


  &__search-icon {
      font-size: 18px;
      bottom: 6px;
      position: absolute;
      right: 0;
      &--reset{
          &:hover{
              cursor: pointer;
          }
      }
  }

  &__table {
      display: block;

      &--responsive {
          overflow-x: auto;
      }
  }

  &__table-body {
      width: 100%;
      display: block;
      position: relative;

      &--title {
          visibility: hidden;
      }

      &--show-title {
          visibility: visible;
      }
  }

  &__table-body-header {
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      line-height: 16px;
      margin: 0;
      display: inline-block;
      &--inline {
          display: inline-block;
          margin-left: 3px;
      }

      &--report {
          min-height: 45px;
      }

      &--round-number {
          padding: 0px 9px 0px 9px;
          border-radius: 15px;
          opacity: 0.8;
          margin: 0;
          margin-right: 5px;
          width: auto;
          float: left;
          color: white;
          display: inline-flex;
          background: $primary-color;
          &:hover{
              cursor: pointer;
          }
      }
  }

  &__table-col {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      margin-top: 11px;
      max-width: 0;
      font-size: 12px;

      &--type {
          width: 17%;
      }

      &--type-header {
          width: 17%;
          margin-top: 0;

          &:hover {
              cursor: pointer;
          }
      }

      &--title {
          width: 15%;

          &:hover {
              cursor: pointer;
          }
      }

      &--title-header {
          width: 15%;
          margin-top: 0;
          &:hover {
              cursor: pointer;
          }
      }

      &--creation {
          width: 9%;
      }

      &--creation-header {
          width: 9%;
          margin-top: 0;
          padding-top: 2px;
      }

      &--creator {
          width: 17%;
      }

      &--creator-header {
          width: 17%;
          margin-top: 0;
          &:hover {
              cursor: pointer;
          }
      }

      &--responsible {
          width: 14%;
      }

      &--responsible-header {
          width: 14%;
          margin-top: 0;
      }

      &--state {
          width: 14%;
          padding-top: 1px;
      }

      &--state-head {
          width: 14%;
          margin-top: 0;

          &:hover {
              cursor: pointer;
          }
      }

      &--expiration {
          width: 8%;
          position: relative;
      }

      &--expiration-header {
          width: 8%;
          position: relative;
          margin-top: 0;
          padding-top: 2px;
      }
      &--menu {
          width: 5%;
          position: relative;
      }

      &--pad-left {
          padding-left: 10px;
      }

      &--action-header {
          width: 5%;
          text-align: right;
          margin-top: 0;
      }
  }

  &__table-display {
      height: 34px;
  }

  &__task-header {
      display: block;
      width: 100%;
      height: 37px;
      padding-top: 5px;
      padding-right: 10px;
      text-align: right;
      margin-bottom: 18px;
      position: relative;

      &--documentation {
          padding-right: 0px;
          padding-top: 0px;
      }
  }

  &__task-state {
      padding: 4px 9px 2px;
      border-radius: 15px;
      margin: 0;
      width: auto;
      position: relative;
      float: left;
      // padding-right: 10px;
      // color: $custom-black-landing;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      // &--ellipsis{
      //     text-overflow: ellipsis;
      //     overflow: hidden;
      //     white-space: nowrap;
      //     max-width: 100%;
      // }
  }

  &__text-type {
      display: inline-block;
      max-width: calc(100% - 44px);
      position: relative;
      bottom: 2px;
  }

  &__type-icon {
      font-size: 20px;
      display: inline-block;
      margin: 0 5px;
      position: relative;
      bottom: 2px;
      &--meeting{
          bottom: 4px;
      }
  }

  &__warning-dot-icon {
      font-size: 11px;
      color: $red;
      top: 4px;
  }

  &__process-list-icon {
      margin-right: 4px;
      font-size: 20px;
  }

  &__process-task {
      margin-left: 16px;
      margin-right: 16px;
      &__heading {
          font-family: MTTMilano-Bold, sans-serif;
          font-size: 14px;
          text-transform: uppercase;
          margin-bottom: 4px;
          &__creation {
              font-family: Arial, sans-serif;
              float: right;
              font-size: 12px;
              text-transform: uppercase;
          }
      }

      &__data {
          font-family: Arial, sans-serif;
          font-weight: bold;
          font-size: 12px;
          background-color: $background-grey;
          width: 100%;
          display: block;

          &__title {
              font-size: unset;
              font-weight: normal;
              font-style: italic;
              opacity: 0.75;
              margin-top: 0;
              margin-bottom: 2px;
              display: table;
              width: 100%;
          }

          &__column {
              float: left;
              & > i {
                  vertical-align: middle;
              }

              &--recipient {
                  width: 25%;
              }

              &--expiration-date {
                  width: 20%;
                  margin-top: -2px;
              }

              &--current-state {
                  width: 47%;
                  margin-top: -12px;
              }
          }
      }
  }
  &__pr-task-process-block{
      height: 70px;
      display: block;
      width: 100%;
      padding:20px 0 0 35px;
  }

  &__pr-task-process-title {
      opacity: 0.75;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      font-style: italic;
      font-weight: 500;
      margin: 0 0 4px;
  }

  &__pr-task-process-table {
      display: table;
      width: calc(100% - 80px);
  }

  &__pr-task-process-column {
      // display: table-cell;
      position: relative;
      float: left;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      margin: 0;
      // vertical-align: middle;
      &--dest {
          width: 35%;
      }

      &--venc {
          width: 20%;
      }

      &--state {
          // width: 45%;
          width: calc(45% - 84px);
          font-weight: normal;
      }
  }

  &__column-actions {
      float: right;
      bottom: 8px;
      width: 84px;
      height: 42px;
      position: relative;
      margin: 0;
  }

  &__pr-task-property-label {
      float: left;
      margin-right: 4px;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: bold;
      &--recipient{
          max-width: 97px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      }
      &--state {
          margin-top: 6px;
      }
  }

  &__participant-list-process{
      position: relative;
      float: left;
      width: calc(100% - 101px);
      bottom: 2px;
  }

  &__pr-task-property-value {
      float: left;
  }

  &__pr-task-property-icon-calendar {
      float: left;
      font-size: 20px;
      padding-right: 7px;
      bottom: 2px;
      position: relative;
  }

  &__pr-task-property-icon-circle {
      float: left;
      font-size: 21px;
      padding-right: 4px;
      position: relative;
      top: 2px;
  }

  &__pr-task-property-block-state {
      position: relative;
      float: left;
      top: 5px;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-weight: bold;
  }
  &__state-block{
      display: block;
      width: 100%;
      margin-top: 2px;
  }
  &__custom-report-list--no-border {
      border: none;
  }
}

.compliance{
  &__dark-blue{
    .task-concept {
      &__table {
          padding: 10px 10px 10px 10px;
          width: 100%;
      }
      &__task-state {
        &--yellow {
            @include task-concept-task-state-color($yellow-opacity-36, $secondary-color);
        }

        &--orange {
            @include task-concept-task-state-color($orange-opacity-36, $secondary-color);
        }

        &--red {
            @include task-concept-task-state-color($red-opacity-36, $secondary-color);
        }

        &--brown {
            @include task-concept-task-state-color($brown-opacity-36, $secondary-color);
        }

        &--green {
            @include task-concept-task-state-color($green-opacity-36, $secondary-color);
        }

        &--grey {
            @include task-concept-task-state-color($grey-opacity-36, $secondary-color);
        }

        &--process {
            @include task-concept-task-state-process($process-opacity-36);
        }
      }
      &__type-icon {
        color: $primary-color;
      }
      &__process-task {
        color: $primary-color;
      }
      &__pr-task-process-block{
        background-color: $background-color-states;
      }
      &__pr-task-process-title {
        color: $primary-color;
      }
      &__pr-task-process-column {
        color:$primary-color;
      }
      &__pr-task-property-value {
        &--state{
            @include task-concept-pr-task-property-value-state($donut-text);
        }
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue{
    .task-concept {
      &__search-icon {
        color: $border-hover-2;
      }
      &__table {
        margin-left:20px;
        padding: 29px 0 0 0;
        width: calc(100% - 40px);
      }
      &__task-state {
        &--yellow{
            @include task-concept-task-state-color($yellow-opacity-uk-36, $primary-color-2);
        }
        &--orange{
            @include task-concept-task-state-color($orange-opacity-36, $primary-color-2);
        }
        &--red{
            @include task-concept-task-state-color(rgba($alert-color-2, 0.36), $primary-color-2);
        }
        &--brown{
            @include task-concept-task-state-color($brown-opacity-36, $primary-color-2);
        }
        &--green{
            @include task-concept-task-state-color(rgba($green-color-v2, 0.36), $primary-color-2);
        }
        &--grey{
            @include task-concept-task-state-color(rgba($border-color-other-2, 0.36), $primary-color-2);
        }
        &--process{
            @include task-concept-task-state-process(rgba($background-highlight-text-2,0.36));
            color: $primary-color-2;
        }
      }
      &__type-icon {
        color: $border-hover-2;
      }
      &__process-task {
        @include task-concept-process-task($primary-color-2);
        &__heading {
            &__creation {
                position: relative;
                bottom: -9px;
            }
        }
      }
      &__pr-task-process-block{
        background-color: $background-grey-disabled-2;
      }
      &__pr-task-process-title {
        color: $text-field-color-2;
      }
      &__pr-task-process-column {
        color: $primary-color-2;
      }
      &__pr-task-property-value {
        &--state{
            @include task-concept-pr-task-property-value-state($background-highlight-text-2);
        }
      }
    }
  }
  &__icam-red{
    .task-concept {
      &__search-icon {
        color: $primary-color-icam;
      }
      &__table {
        margin-left:20px;
        padding: 29px 0 0 0;
        width: calc(100% - 40px);
      }
      &__task-state {
        &--yellow{
          @include task-concept-task-state-color($yellow-task-36-icam, $custom-black-landing);
        }
        &--orange{
          @include task-concept-task-state-color($orange-task-36-icam, $custom-black-landing);
        }
        &--red{
          @include task-concept-task-state-color($red-task-36-icam, $custom-black-landing);
        }
        &--brown{
          @include task-concept-task-state-color($brown-task-36-icam, $custom-black-landing);
        }
        &--green{
          @include task-concept-task-state-color($green-task-36-icam, $custom-black-landing);
        }
        &--grey{
          @include task-concept-task-state-color($grey-task-36-icam, $custom-black-landing);
        }
      }
      &__process-task {
        @include task-concept-process-task($custom-black-landing);
      }
      &__pr-task-process-block{
        background-color: $background-grey-disabled-icam;
      }
      &__pr-task-property-value {
        &--state{
            @include task-concept-pr-task-property-value-state($custom-black-landing);
        }
      }
    }
  }
  &__gm-law{
    .task-concept {
      &__search-icon {
        color: $primary-color-gm;
      }
      &__table {
        margin-left:20px;
        padding: 29px 0 0 0;
        width: calc(100% - 40px);
      }
      &__table-body-header {
        color: $custom-black-landing;
      }
      &__task-state {
        &--yellow{
            @include task-concept-task-state-color($yellow-task-36-gm, $custom-black-landing);
        }
        &--orange{
            @include task-concept-task-state-color($orange-task-36-gm, $custom-black-landing);
        }
        &--red{
            @include task-concept-task-state-color($red-task-36-gm, $custom-black-landing);
        }
        &--brown{
            @include task-concept-task-state-color($brown-task-36-gm, $custom-black-landing);
        }
        &--green{
            @include task-concept-task-state-color($green-task-36-gm, $custom-black-landing);
        }
        &--grey{
            @include task-concept-task-state-color($grey-task-36-gm, $custom-black-landing);
        }
        &--process{
            @include task-concept-task-state-process(rgba($background-highlight-text-2,0.36));
            color: $primary-color-2;
        }
        &--process{
            @include task-concept-task-state-process($process-opacity-36);
        }
      }
      &__type-icon {
        color: $custom-black-landing;
      }
      &__process-task {
        @include task-concept-process-task($custom-black-landing);
      }
      &__pr-task-process-block{
        background-color: $background-grey-disabled-gm;
      }

      &__pr-task-property-value {
        &--state{
            @include task-concept-pr-task-property-value-state($custom-black-landing);
        }
      }
    }
  }
  &__com-de{
    .task-concept {
      &__search-icon {
        color: $primary-color-com-de;
      }
      &__table {
        margin-left:20px;
        padding: 29px 0 0 0;
        width: calc(100% - 40px);
      }
      &__table-body-header {
        color: $custom-black-landing;
      }
      &__task-state {
        &--yellow{
            @include task-concept-task-state-color($yellow-task-36-com-de, $custom-black-landing);
        }
        &--orange{
            @include task-concept-task-state-color($orange-task-36-com-de, $custom-black-landing);
        }
        &--red{
            @include task-concept-task-state-color($red-task-36-com-de, $custom-black-landing);
        }
        &--brown{
            @include task-concept-task-state-color($brown-task-36-com-de, $custom-black-landing);
        }
        &--green{
            @include task-concept-task-state-color($green-task-36-com-de, $custom-black-landing);
        }
        &--grey{
            @include task-concept-task-state-color($grey-task-36-com-de, $custom-black-landing);
        }
        &--process{
            @include task-concept-task-state-process(rgba($background-highlight-text-2,0.36));
            color: $primary-color-2;
        }
        &--process{
            @include task-concept-task-state-process($process-opacity-36);
        }
      }
      &__type-icon {
        color: $custom-black-landing;
      }
      &__process-task {
        @include task-concept-process-task($custom-black-landing);
      }
      &__pr-task-process-block{
        background-color: $background-grey-disabled-com-de;
      }

      &__pr-task-property-value {
        &--state{
            @include task-concept-pr-task-property-value-state($custom-black-landing);
        }
      }
    }
  }
  &__com-fr{
    .task-concept {
      &__search-icon {
        color: $primary-color-com-fr;
      }
      &__table {
        margin-left:20px;
        padding: 29px 0 0 0;
        width: calc(100% - 40px);
      }
      &__table-body-header {
        color: $custom-black-landing;
      }
      &__task-state {
        &--yellow{
            @include task-concept-task-state-color($yellow-task-36-com-fr, $custom-black-landing);
        }
        &--orange{
            @include task-concept-task-state-color($orange-task-36-com-fr, $custom-black-landing);
        }
        &--red{
            @include task-concept-task-state-color($red-task-36-com-fr, $custom-black-landing);
        }
        &--brown{
            @include task-concept-task-state-color($brown-task-36-com-fr, $custom-black-landing);
        }
        &--green{
            @include task-concept-task-state-color($green-task-36-com-fr, $custom-black-landing);
        }
        &--grey{
            @include task-concept-task-state-color($grey-task-36-com-fr, $custom-black-landing);
        }
        &--process{
            @include task-concept-task-state-process(rgba($background-highlight-text-2,0.36));
            color: $primary-color-2;
        }
        &--process{
            @include task-concept-task-state-process($process-opacity-36);
        }
      }
      &__type-icon {
        color: $custom-black-landing;
      }
      &__process-task {
        @include task-concept-process-task($custom-black-landing);
      }
      &__pr-task-process-block{
        background-color: $background-grey-disabled-com-fr;
      }

      &__pr-task-property-value {
        &--state{
            @include task-concept-pr-task-property-value-state($custom-black-landing);
        }
      }
    }
  }
}

@media (max-width: 1480px){
  .task-concept{
      &__table-body-header{
          &--status{
              font-size: 11.5px;
          }
      }
  }
}

