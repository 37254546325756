.evaluation-import {
  display: block;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  &__main {
      background-color: $snow-white;
      padding-top: 2%;
      padding-bottom: 6%;
  }

  &__dialog {
      width: 450px;
      margin: 0 auto;
      border: 1px solid $primary-color;
      padding: 26px 49px 0 49px;
      font-family: "MTTMilano-Medium" , sans-serif;
      font-size: 18px;
      color: $primary-color;
      line-height: 20px;
      text-align: center;

      &__title {
          font-size: 26px;
          line-height: 29px;
          margin-top: 0;
          margin-bottom: 0;
      }

      &__icon {

          & > .lf-icon-key {
              display: inline-block;
              font-size: 140px;
              line-height: 76px;
              margin-top: 40px;
              margin-bottom: 44px;
          }

          & > .lf-icon-label-validate, & > .lf-icon-face-sad, .lf-icon-clock {
              display: inline-block;
              font-size: 107px;
              line-height: 101px;
              margin-top: 39px;
              margin-bottom: 20px;
          }

          & > .lf-icon-warning {
              display: inline-block;
              font-size: 161px;
              line-height: 140px;
              margin-top: 41px;
              margin-bottom: 18px;
          }
      }

      &__text {
          color: $concept-blue;
          letter-spacing: -1px;
      }

      &__button {
          padding: 15px 45px;
          border: 1px solid $primary-color;
          font-family: "MTTMilano-Bold" , sans-serif;
          font-size: 13px;
          color: $primary-color;
          text-transform: uppercase;
          background-color: $snow-white;
          line-height: 19px;
          height: 42px;
          padding: 0;
          &--active {
              color: $snow-white;
              background-color: $primary-color;
          }
      }

      &__buttons {
          margin-top: 49px;

          &--b1 > button {
              display: block;
              width: 100%;
          }

          &--b2 {
              overflow: hidden;

              & > button {
                  float: left;
                  width: 48%;
                  margin-left: 4%;
              }

              & > button:first-child {
                  margin-left: 0;
              }
          }
      }

      &__footer {
          margin: 45px -49px 0 -49px;
          padding: 25px 40px 22px 40px;
          background-color: $background-grey-other;
          font-size: 21px;
          line-height: 30px;
          letter-spacing: -1px;
      }

      &__contact {
          font-size: 18px;
          line-height: 30px;
      }

      &__phone {
          color: $concept-blue;
      }
  }
}
