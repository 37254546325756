.send-reminder{
    &__block-body{
        display: block;
        width: 100%;
        height: 518px;
        padding: 60px 71px 0;
    }

    &__title-option-steel-blue{
        color: $grey-icons-2;
        opacity: 1;
    }
}