.process-edit {
    padding: 32px 12px 44px 12px;
    display: block;
    overflow: hidden;
    min-height: 528px;
    margin: 0;
    border: 1px solid $primary-color;
    background: $snow-white;

    &--modal {
        border: none;
        min-height: unset;
        height: 100%;
        padding: 48px 64px 0;

        & .process-edit {
            margin-top: 0;
            top: 0;
            bottom: 0px;
            min-height: unset;
        }
    }

    &__block-drawzone {
        display: block;
        width: calc(60% - 1px);
        float: left;
        position: relative;
        height: 100%;
        margin: 0;
        padding: 0;
        // background: transparent;
        &--modal-process {
            width: calc(65% - 18px);
        }
    }

    &__process-properties {
        width: 35%;
        float: right;
        position: relative;
        // overflow-y: auto;

        // & .mce-flow-layout {
        //     white-space: nowrap;
        // }
    }

    &__block-properties {
        border: 1px solid $box-border;
        border-top: 5px solid $primary-color;
        background-color: $background-color-states;
        position: relative;
        margin: 0px;
        height: 430px;
        padding: 0 15px 15px;
    }

    &__howto {
        position: relative;
        height: 45px;

        & .customize-concept__info-block {
            width: 525px;
        }
    }

    &__main {
        position: relative;
        border: 2px dotted $primary-color;
        margin-top: 25px;
        border-radius: 15px;
        background-color: $snow-white;
        overflow: hidden;
        height: 450px;
        &--show-help{
            & .joint-paper-scroller{
                overflow: hidden;
            }
        }
        &--modal{
            margin-top: 0;
            height: 530px;
        }
    }

    &__main-help {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translate(0, -50%);
        color: $primary-color;
        font-family: MTTMilano, sans-serif;
        font-size: 18px;
        text-align: center;
    }

    &__text-strong{
        font-family: MTTMilano-Bold, sans-serif;
        font-weight: 500;
    }

    &__toolbar {
        padding: 0;
        position: absolute;
        top: 50%;
        left: 7px;
        transform: translate(0, -50%);
        list-style: none;
        color: $snow-white;
        background-color: $primary-color;
        border-radius: 7px;

        & > li {
            & > button {
                font-size: 18px;
                width: 42px;
                height: 33px;
                padding-top: 3px;
            }
        }
    }

    &__toolbar-separator {
        border-bottom: 1px solid $snow-white;
    }

    @media screen and (max-height: "820px") {
        &__toolbar {
            top: 60px;
            transform: none;
        }
    }

    &__alert {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        height: 55px;
        padding: 6px 20px;
        // color: $primary-color;
        background-color: $green-opacity-02;
        font-size: 12px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        color: $primary-color;
        &--error {
            background-color: $red-opacity-02;
            color: $red;
        }
        &__icon {
            font-size: 41px;
            margin-top: 7px;
            margin-right: 13px;
        }

        &__text {
            position: relative;
            top: -12px;
        }

        &__close {
            float: right;
            margin-top: 13px;
            font-size: 15px;
        }
    }

    &__properties {
        position: relative;
        margin-top: 54px;
    }

    &__description {
        position: relative;
        margin-bottom: 14px;
    }

    &__description-placeholder {
        position: absolute;
        bottom: 235px;
        left: 10px;
        right: 0;
        font-family: Arial, sans-serif;
        font-size: 12px;
        font-style: italic;
        color: $subcontainer-ground;
        pointer-events: none;
    }

    &__buttons {
        position: relative;
        margin-top: 20px;
        text-align: right;
        font-size: 12px;

        & > button {
            margin-left: 5px;
        }

        @media (max-width: 1710px) {
            & > button {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        @media (max-width: 1620px) {
            & > button {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        @media (max-width: 1530px) {
            & > button {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        @media (max-width: 1454px) {
            & > button {
                padding-left: 6px;
                padding-right: 6px;
            }
        }

        @media (max-width: 1367px) {
            & > button {
                padding-left: 4px;
                padding-right: 4px;
            }
        }

        @media (max-width: 1190px) {
            & > button {
                padding-left: 3px;
                padding-right: 3px;
            }
        }

        @media (max-width: 1090px) {
            & > button {
                padding-left: 2px;
                padding-right: 2px;
            }
        }


        & > button:first-child {
            margin-left: 0px;
        }
    }

    & .joint-paper {
        border-radius: 15px;

        & g[source="true"] .topborder{
            fill: $primary-color;
        }

        & .joint-halo.joint-theme-default .handle {
            color: $primary-color;
            font-family: 'lf-font' !important;
            background: none;
            font-size: 20px;

            &.remove:before {
                content: '\e9ba';
            }

            &.fork:before {
                content: '\ea7f';
            }

            &.clone:before {
                content: '\e9b5';
            }

            &.unlink:before {
                content: '\e943';
            }

            &.link:before {
                content: '\e9ef';
            }

            &.rotate:before {
                content: '\e9cd';
            }
        }

        & .joint-selection.joint-theme-default .handle {
            color: $primary-color;
            font-family: 'lf-font' !important;
            background: none;
            font-size: 20px;

            &.remove:before {
                content: '\e9ba';
            }

            &.rotate:before {
                content: '\e9cd';
            }

            &.resize:before {
                content: '\e916';
            }
        }
    }

  }

  .compliance{
    &__steel-blue,
    &__uk-steel-blue{
        .process-edit {
            &--no-modal{
                border: 2px solid $secondary-color-2;
                border-top: 0;
            }
            &__main-help {
                color: $primary-color-2;
            }
            &__toolbar {
                background-color: $primary-color-2;
            }
            & .joint-paper {
              & g[source="true"] .topborder{
                  fill: $primary-color-2;
              }
            }
        }
    }
    &__gm-law{
        .process-edit {
            &--no-modal{
                border: 2px solid $border-block-gm;
                border-top: 0;
            }
            &__block-properties {
                border: none;
                border-left: 8px solid ;
                background-color: $snow-white;
                box-shadow: 0 0 5px 1px $border-buttons-gm;
                padding-top: 1px;
            }
            &__main {
                border: 2px dotted $custom-black-landing;
            }
            &__main-help {
                color: $custom-black-landing;
            }
            &__toolbar {
                background-color: $primary-color-gm;
            }
            &__alert {
                color: $custom-black-landing;
                background-color: $background-green-gm;
                &--error{
                    background-color: $background-alert-gm;
                    color: $alert-color-gm;
                }
            }
            & .joint-paper {
              & g[source="true"] .topborder{
                  fill: $border-block-gm;
              }
            }
            &__buttons {
                & .joint-paper {
                    fill: $border-block-gm;
                    & .joint-halo.joint-theme-default .handle{
                        color: $primary-color-gm
                    }
                    & .joint-selection.joint-theme-default .handle {
                        color: $primary-color-gm
                    }
                }
            }

        }
    }
    &__com-de{
      .process-edit {
          &--no-modal{
              border: 2px solid $border-block-com-de;
              border-top: 0;
          }
          &__block-properties {
              border: none;
              border-left: 8px solid ;
              background-color: $snow-white;
              box-shadow: 0 0 5px 1px $border-buttons-com-de;
              padding-top: 1px;
          }
          &__main {
              border: 2px dotted $custom-black-landing;
          }
          &__main-help {
              color: $custom-black-landing;
          }
          &__toolbar {
              background-color: $primary-color-com-de;
          }
          &__alert {
              color: $custom-black-landing;
              background-color: $background-green-com-de;
              &--error{
                  background-color: $background-alert-com-de;
                  color: $alert-color-com-de;
              }
          }
          & .joint-paper {
            & g[source="true"] .topborder{
                fill: $border-block-com-de;
            }
          }
          &__buttons {
              & .joint-paper {
                  fill: $border-block-com-de;
                  & .joint-halo.joint-theme-default .handle{
                      color: $primary-color-com-de
                  }
                  & .joint-selection.joint-theme-default .handle {
                      color: $primary-color-com-de
                  }
              }
          }

      }
  }
  &__com-fr{
    .process-edit {
        &--no-modal{
            border: 2px solid $border-block-com-fr;
            border-top: 0;
        }
        &__block-properties {
            border: none;
            border-left: 8px solid ;
            background-color: $snow-white;
            box-shadow: 0 0 5px 1px $border-buttons-com-fr;
            padding-top: 1px;
        }
        &__main {
            border: 2px dotted $custom-black-landing;
        }
        &__main-help {
            color: $custom-black-landing;
        }
        &__toolbar {
            background-color: $primary-color-com-fr;
        }
        &__alert {
            color: $custom-black-landing;
            background-color: $background-green-com-fr;
            &--error{
                background-color: $background-alert-com-fr;
                color: $alert-color-com-fr;
            }
        }
        & .joint-paper {
          & g[source="true"] .topborder{
              fill: $border-block-com-fr;
          }
        }
        &__buttons {
            & .joint-paper {
                fill: $border-block-com-fr;
                & .joint-halo.joint-theme-default .handle{
                    color: $primary-color-com-fr
                }
                & .joint-selection.joint-theme-default .handle {
                    color: $primary-color-com-fr
                }
            }
        }

    }
}
}

  @media (max-width: 1890px) {
    .process-edit {
        &__block-properties{
            max-height: 83%;
        }
        &__properties{
            max-height: 83%;
            overflow: hidden;
            overflow-y: auto;
        }
        // &__process-properties {
        //     & .mce-flow-layout {
        //         white-space: normal;
        //     }
        // }
    }
  }


  @media (max-width: 1190px) {
    .process-edit {
        &--modal{
            padding: 48px 15px 0;
        }
    }
  }
