.field-type-bool{
  &__input-text-bool {
      width: 85%;
      vertical-align: middle;
      display: table-cell;
      padding: 0px;
      text-transform: none;
      position: relative;
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      font-weight: 500;
  }

  &__field-bool-answer {
      width: 15%;
      vertical-align: middle;
      display: table-cell;
      &--color {
          width: 10%;
      }
  }
}

.compliance{
  &__steel-blue,
  &__uk-steel-blue{
    .field-type-bool{
      &__input-text-bool {
          color: $primary-color-2;
          padding: 4px 0;
      }
    }
  }
  &__icam-red{
    .field-type-bool{
      &__input-text-bool {
          color: $custom-black-landing;
      }
    }
  }
  &__gm-law{
    .field-type-bool{
      &__input-text-bool {
          color: $custom-black-landing;
      }
    }
  }
  &__com-de{
    .field-type-bool{
      &__input-text-bool {
          color: $custom-black-landing;
      }
    }
  }
  &__com-fr{
    .field-type-bool{
      &__input-text-bool {
          color: $custom-black-landing;
      }
    }
  }
  &__landing-custom{
    .field-type-bool{
      &__input-text-bool{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        width: 100%;
        height: 47px;
      }
      &__field-bool-answer {
          padding: 0px;
          width: 90px;
          &--poll {
              display: block;
              width: 100%;
              padding: 0;
          }
      }
    }
  }
}
