body{
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
}

p{
  margin: 0 0 10px;
}

a{
  text-decoration: none;
}

.pull-right{
  float: right;
  // float: right !important;
}

.pull-left{
  float: left;
  // float: left !important;
}

.row{
  align-items: flex-start;
}

.span__auto-ajust{
  margin-left: 4px;
}

.dropdown-item{
  padding: 0;
  &:hover,
  &:focus,
  &:active{
    background-color: transparent;
  }
}
