.roles-window {
  &__container {
      height: auto;
      min-height: 600px;
      padding-bottom: 40px;
  }
  &__form-body{
      max-width: 800px;
      margin: 50px auto 122px;
  }
  &__block-roles-repeat{
      margin-top: 10px;
  }
}
