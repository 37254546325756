.picker-data-simple{
  &__block-date-interval{
      width: 150px;
      height: 30px;
      // border: 1px solid $attach-icon-grey;
      display: inline-block;
      position: relative;
      bottom: 3px;
      padding-left: 5px;
      border: 1px solid $result-search-quote-color;
      &--open{
          border-color: $primary-color;
      }
  }
  &__input-date-interval{
      top: 6px;
      padding: 0;
      position: relative;
  }
  &__icon-calendar-interval{
      background-color: transparent;
      border: none;
      outline: none;
      float: right;
      position: absolute;
      right: 5px;
      top: 6px;
      padding: 0px;
      color: $primary-color;
      font-size: 16px;
      opacity: 1;
      &:hover{
          cursor: pointer;
      }
  }
  &__datepicker-box {
      position: absolute;
      z-index: 20;
      background: #fff;
      border-radius: 0;
      border: 1px solid $primary-color;
      transform: none !important;
      inset: auto !important;
      top: 28px !important;
      left: -1px !important;
      width: 403px;
  }
}

.compliance{
  &__dark-blue{
    .picker-data-simple{
      &__block-date-interval{
        &--disabled{
            background-color: $background-grey;
        }
      }
      &__icon-calendar-interval{
        &--disabled{
            color: $attach-icon-grey;
            &:hover{
                cursor: default;
            }
        }
      }
    }
  }
  &__steel-blue, &__uk-steel-blue{
    .picker-data-simple{
      &__block-date-interval{
        border-color: $secondary-color-2;
        &--disabled{
          background-color: $background-grey-disabled-2;
      }
      }
      &__icon-calendar-interval{
          color: $border-hover-2;
        &--disabled{
          color: $grey-icons-2;
          &:hover{
              cursor: default;
          }
        }
      }
    }
  }
  &__icam-red{
    .picker-data-simple{
      &__block-date-interval{
        &--disabled{
          background-color: $background-grey-disabled-icam;
      }
        border-color: $secondary-color-2;
      }
      &__icon-calendar-interval{
        color: $primary-color-icam;
        &--disabled{
            color: $secondary-color-icam;
            &:hover{
                cursor: default;
            }
        }
      }
    }
  }
  &__gm-law{
    .picker-data-simple{
      &__block-date-interval{
        &--disabled{
          background-color: $background-grey-disabled-gm;
        }
        border-color: $secondary-color-2;
      }
      &__icon-calendar-interval{
          color: $primary-color-gm;
          &--disabled{
              color: $secondary-color-gm;
              &:hover{
                  cursor: default;
              }
          }
      }
    }
  }
  &__com-de{
    .picker-data-simple{
      &__block-date-interval{
        &--disabled{
          background-color: $background-grey-disabled-com-de;
        }
        border-color: $secondary-color-2;
      }
      &__icon-calendar-interval{
          color: $primary-color-com-de;
          &--disabled{
              color: $secondary-color-com-de;
              &:hover{
                  cursor: default;
              }
          }
      }
    }
  }
  &__com-fr{
    .picker-data-simple{
      &__block-date-interval{
        &--disabled{
          background-color: $background-grey-disabled-com-fr;
        }
        border-color: $secondary-color-2;
      }
      &__icon-calendar-interval{
          color: $primary-color-com-fr;
          &--disabled{
              color: $secondary-color-com-fr;
              &:hover{
                  cursor: default;
              }
          }
      }
    }
  }
}
