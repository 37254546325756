.horizontal-poll-graph{
    position: relative;
    &__graph{
        background-color: $donut-text-05;
        border-left: 3px solid $primary-color;
        border-bottom: 3px solid $primary-color;
        // &--div{
        //     width: 720px;
        //     height: 1073px;
        // }

    }
    &__x-ticks{
        & .tick{
            & line{
                stroke-width: 1px;
                stroke: $column-filter-shadow-005;
            }
            & text{
              color: transparent;
            }
        }
        & path{
            stroke-width: 1px;
            stroke: $column-filter-shadow-005;
        }
    }
    &__y-ticks{
        & path{
            stroke-width: 1px;
            stroke: $column-filter-shadow-005;
        }
    }
    &__bar-block{
        &:hover{
            & .horizontal-poll-graph__legend-block--more-elements{
                display: block;
            }
        }
    }
    &__legend-block{
        display: block;
        width: 100%;
        margin: auto;
        min-height: 22px;
        &--more-elements{
            display: none;
        }
        &--more-elements-modal{
            min-height: 16px;
        }

    }
    &__percent-block{
        color: $primary-color;
        font-family: 'MTTMilano-bold', sans-serif;
        font-size: 14px;
        padding: 2px 10px 1px;
        float: left;
        &--small{
            font-size: 11px;
        }
    }
    &__answers-block{
        height: 22px;
        width: 30px;
        background-color: $primary-color;
        color: $snow-white;
        font-family: 'MTTMilano', sans-serif;
        font-size: 12px;
        text-align: center;
        float: left;
        padding-top: 3px;
        &--small{
            font-size: 11px;
            padding-top: 1px;
            height: 16px;
        }
    }
    &__separate-line{
        stroke-width: 1px;
        stroke: $column-filter-shadow-005;
    }
    &__full-bar{
        fill: transparent;
    }
    &__tooltip-block{
        position: absolute;
        visibility: hidden;
        z-index: 2;
    }
    &__tooltip-content{
        max-width: 170px;
        border: 1px solid $subcontainer-poll-border;
        background-color: $snow-white;
        box-shadow: $box-shadow;
        padding: 3px 11px 2px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $no-primary-blue;
        font-family: Arial, sans-serif;
        font-size: 11px;
    }

    &__tooltip-triangle{
        position: absolute;
        right: -13px;
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
        border-left: 5px solid $primary-color;
        border-right: 5px solid transparent;
        top: 7px;
    }
    &__y-label{
        color: $bar-horizontal-task-grey;
        font-family: 'MTTMilano-bold', sans-serif;
        font-size: 12px;
        text-align: right;
        max-width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 10px;
        &--graph-report{
            max-width: 258px;
            margin-right: 20px;
        }
    }
    &__capture{
        border: none;
        background: none;
        padding: 0;
        font-size: 20px;
        color: $primary-color;
        position: absolute;
        right: 5px;
        bottom: 8px;
        &--result-report{
            right: -17px;
            bottom: 3px;
        }
    }
}

.compliance{
    &__dark-blue{
        .horizontal-poll-graph{
            &__bar-block{
                &:hover{
                    & .horizontal-poll-graph__full-bar{
                        fill: $box-comment-border-2;
                    }
                }
            }
        }
    }
    &__icam-red{
        .horizontal-poll-graph{
            &__graph{
                background-color: $background-grey-05-icam;
                border-color: $custom-black-landing;
            }
            &__bar-block{
                &:hover{
                    & .horizontal-poll-graph__full-bar{
                        fill: $primary-icam-10;
                    }
                }
            }
            &__percent-block{
                color: $custom-black-landing;
            }
            &__answers-block{
                background-color: $primary-color-icam;
            }
            &__tooltip-content{
                color: $custom-black-landing;
                border-color: $custom-black-landing;
                box-shadow: $box-shadow-icam;
            }
            &__y-label{
                color: $secondary-color-icam;
            }
        }
    }
    &__gm-law{
        .horizontal-poll-graph{
            &__graph{
                background-color: $background-grey-05-gm;
                border-color: $custom-black-landing;
            }
            &__bar-block{
                &:hover{
                    & .horizontal-poll-graph__full-bar{
                        fill: $primary-gm-10;
                    }
                }
            }
            &__percent-block{
                color: $custom-black-landing;
            }
            &__answers-block{
                background-color: $primary-color-gm;
            }
            &__tooltip-content{
                color: $custom-black-landing;
                border-color: $custom-black-landing;
                box-shadow: $box-shadow-gm;
            }
            &__y-label{
                color: $secondary-color-gm;
            }
        }
    }
    &__com-de{
      .horizontal-poll-graph{
          &__graph{
              background-color: $background-grey-05-com-de;
              border-color: $custom-black-landing;
          }
          &__bar-block{
              &:hover{
                  & .horizontal-poll-graph__full-bar{
                      fill: $primary-com-de-10;
                  }
              }
          }
          &__percent-block{
              color: $custom-black-landing;
          }
          &__answers-block{
              background-color: $primary-color-com-de;
          }
          &__tooltip-content{
              color: $custom-black-landing;
              border-color: $custom-black-landing;
              box-shadow: $box-shadow-com-de;
          }
          &__y-label{
              color: $secondary-color-com-de;
          }
      }
  }
  &__com-fr{
    .horizontal-poll-graph{
        &__graph{
            background-color: $background-grey-05-com-fr;
            border-color: $custom-black-landing;
        }
        &__bar-block{
            &:hover{
                & .horizontal-poll-graph__full-bar{
                    fill: $primary-com-fr-10;
                }
            }
        }
        &__percent-block{
            color: $custom-black-landing;
        }
        &__answers-block{
            background-color: $primary-color-com-fr;
        }
        &__tooltip-content{
            color: $custom-black-landing;
            border-color: $custom-black-landing;
            box-shadow: $box-shadow-com-fr;
        }
        &__y-label{
            color: $secondary-color-com-fr;
        }
    }
}
}
