﻿.users-admin-import {
  &__window {
      & > .modal-dialog {
          width: 909px;
          height: 614px;

          & > .modal-content {
              width: auto;
              height: 100%;
          }
      }
  }

  &__body-select-file {
      padding: 98px 67px 0 67px;
  }

  &__index-box {
      float: right;
      border-bottom: 2px solid $primary-color;
  }

  &__body-analized {
      padding: 20px 66px 70px;
  }

  &__index-button {
      border: none;
      background: none;
      outline: none;
      height: 26px;
      color: $primary-color;
      margin-bottom: 1px;
      padding-top: 3px;
      &:hover {
          background-color: $subcontainer-border;
      }
  }

  &__index-icon {
      font-size: 20px;
  }

  &__index-text {
      font-family: MTTMilano, sans-serif;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      bottom: 5px;
  }

  &__contacts-import-title {
      color: $donut-text;
      font-family: MTTMilano, sans-serif;
      font-size: 18px;
      font-weight: 500;
      margin: 60px 0 20px;
      &--migration-title {
          margin-top: 45px;
      }
  }

  &__contacts-import-amount {
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 50px;
      font-weight: bold;
      margin: 0;
  }

  &__contacts-import-amount-text {
      font-size: 16px;
      position: relative;
      bottom: 25px;
      text-transform: uppercase;
      left: -8px;
  }

  &__contacts-import-icon {
      font-size: 100px;
      text-align: center;
      margin: 18px 0 0;
  }

  &__contacts-block {
      position: absolute;
      width: 233px;
      z-index: 12;
      background-color: $snow-white;
      box-shadow: 0 0 5px 1px $certify-black-opacity03;
      max-height: 484px;
      overflow-y: auto;
      right: 71px;
      padding: 5px;
      min-height: 168px;
      overflow-x: hidden;
  }

  &__contacts-header {
      height: 50px;
      background-color: $primary-color;
      position: relative;
      &--green {
          background-color: $green;
      }
      &--red {
          background-color: $red;
      }
  }

  &__contacts-close-icon {
      color: $snow-white;
      font-size: 10px;
  }

  &__filter-block {
      display: block;
      width: 100%;
      padding: 23px 6px 0;
  }

  &__filter-button {
      border: none;
      background: none;
      outline: none;
      &--close {
          position: absolute;
          right: 3px;
          top: 0;
          padding: 0;
          z-index: 12;
      }
  }

  &__filter-icon {
      color: $snow-white;
      font-size: 20px;
  }

  &__amount-text {
      float: right;
      color: $snow-white;
      font-family: Arial, sans-serif;
      font-size: 10px;
      margin: 2px 0 0;
  }

  &__amount-num {
      background-color: $snow-white;
      color: $primary-color;
      border-radius: 10px;
      font-weight: bold;
      text-align: center;
      padding: 2px 11px;
      float: right;
      margin-left: 7px;
      position: relative;
      bottom: 2px;
  }

  &__drop-filter-list {
      width: 100.4%;
      border-radius: 0;
      top: 48px;
      padding: 5px;
      &--import-modal{
        transform: translate(0px, 50px) !important;
      }
  }

  &__drop-filter-item {
      height: 32px;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 11px;
      padding: 10px 5px 0;
      position: relative;
      &:hover {
          background-color: $background-grey;
          & .users-admin-import__drop-filter-icon {
              display: block;
          }
      }
  }

  &__drop-filter-text {
      padding-right: 3px;
  }

  &__drop-filter-icon {
      font-size: 20px;
      position: absolute;
      right: 4px;
      bottom: 5px;
      display: none;
  }

  &__contact-search-block {
      margin-top: 20px;
      border-bottom: 1px solid $primary-color;
      border-left: 1px solid $primary-color;
      width: 100%;
  }

  &__contact-input {
      border: none;
      outline: none;
      padding: 0 0 0px 6px;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      width: calc(100% - 22px);
  }

  &__seach-icon {
      font-size: 18px;
      color: $primary-color;
  }

  &__contact-list {
      margin: 0;
      padding: 0;
      list-style: none;
  }

  &__contact-item {
      height: 34px;
      padding-top: 4px;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 11px;
      width: 223px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }

  &__contact-icon {
      font-size: 15px;
      padding-right: 4px;
      &--green {
          color: $green;
      }
      &--red {
          color: $red;
      }
      &--list-error {
          color: $red;
          font-size: 17px;
          position: relative;
          top: 3px;
      }
      &--list-warning {
          color: $yellow;
          font-size: 17px;
          position: relative;
          top: 3px;
      }
  }

  &__view-errors-button {
      border: none;
      background: none;
      outline: none;
      font-size: 20px;
      color: $red;
      width: 100%;
      &--yellow {
        color: $view-error-button;
      }
  }

  &__view-errors-button-text {
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      bottom: 5px;
  }

  &__errors-block {
      display: block;
      width: calc(100% - 140px);
      margin: 70px 70px 0;
      height: 400px;
      &--warning {
          margin: 10px;
          width: calc(100% - 20px);
      }
  }

  &__advice-message{
      width: 100%;
      height: 55px;
      display: block;
  }

  &__errors-block-elements{
      position: relative;
      width: 100%;
      margin-top: 30px;
      &--warning {
          width: calc(100% - 120px);
          margin: 30px 60px 0;
      }
  }

  &__errors-header {
      width: 100%;
      display: block;
      border-bottom: 1px solid $primary-color;
      color: $primary-color;
      height: 28px;
  }

  &__errors-title {
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      margin: 0;
      text-transform: uppercase;
      padding-top: 8px;
      float: left;
  }

  &__errors-pill {
      width: 40px;
      height: 22px;
      float: right;
      top: 3px;
  }

  &__error-list {
      margin: 0;
      padding: 0;
      display: block;
      list-style: none;
      max-height: 317px;
      overflow: auto;

      &--modal {
          height: 146px;
      }
  }

  &__error-item {
      padding: 14px 11px 17px;
      clear: both;
      width: 100%;
      display: table;
      border-bottom: 1px solid $box-comment-border-2;
      color: $secondary-color;
  }

  &__contact-error-name {
      display: table-cell;
      width: 30%;
      font-family: Arial, sans-serif;
      font-size: 12px;
      position: relative;
      bottom: 3px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 0;
  }

  &__contact-error-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: table-cell;
      width: 70%;
      vertical-align: middle;
  }

  &__contact-error-item {
      font-family: Arial, sans-serif;
      font-size: 12px;
      margin: 0;

      &--error {
          color: $red;
      }
      &--warning {
          color: $view-error-button;
      }
  }

  &__footer {
      position: absolute;
      bottom: 0;
      left: 67px;
      right: 67px;
      padding-bottom: 22px;
      text-align: right;
  }
}

.compliance{
&__dark-blue{
  .users-admin-import {
    &__contacts-import-icon {
        color: $box-comment-border-02;
        &--green {
            color: $green-opacity;
        }
        &--red {
            color: $red-opacity;
        }
        &--yellow {
            color: $yellow-opacity;
        }
    }
    &__drop-filter-item {
        border-bottom: 1px solid transparent;
        &:hover{
            background-color: $background-color-states;
            border-bottom: 1px solid $primary-color;
        }
    }
  }
}
// &__steel-blue,
&__uk-steel-blue{
  .users-admin-import {
    &__contacts-import-amount,
    &__contacts-import-amount-text {
        color: $primary-color-2;
    }
    &__errors-header {
        border-bottom: 1px solid $border-hover-2;
    }
    &__contacts-import-icon {
        color: $background-grey-opacity-2;
        &--green {
            color: $green-opacity;
        }
        &--red {
            color: $red-opacity;
        }
        &--yellow {
            color: $yellow-opacity;
        }
    }
    &__contacts-header {
        background-color: $primary-color-2;
    }
    &__amount-num {
        color: $primary-color-2;
    }
    &__contact-search-block {
        border-bottom: 1px solid $third-color-2;
        border-left: none;
    }
    &__contact-input {
        &::-webkit-input-placeholder{
            color: $border-hover-2;
        }
    }
    &__seach-icon {
        color: $border-hover-2;
    }
    &__errors-title {
        color: $border-hover-2;
    }
  }
}
&__icam-red{
  .users-admin-import {
    &__index-button {
        color: $primary-color-icam;
        &:hover {
            background-color: $hover-block-icam;
        }
    }
    &__contacts-import-title {
        color: $secondary-color-icam;
    }
    &__contacts-import-amount,
    &__contacts-import-amount-text {
        color: $custom-black-landing;
    }
    &__view-errors-button {
        color: $alert-color-icam;
        &--yellow {
            color: $other-yellow-icam;
        }
    }
    &__errors-header {
        border-color: $primary-color-icam;
        color: $primary-color-icam;
    }
    &__error-item {
        color: $custom-black-landing;
        border-bottom-color: $border-block-icam;
    }
    &__contacts-import-icon {
        &--green {
            color: $green-task-50-icam;
        }
        &--red {
            color: $red-task-50-icam;
        }
        &--yellow {
            color: $yellow-task-50-icam;
        }
    }
    &__contact-icon {
        &--list-error {
            color: $alert-color-icam;
        }
        &--list-warning {
            color: $other-yellow-icam;
        }
    }
    &__contact-error-item {
        &--error{
            color: $alert-color-gm;
        }
        &--warning{
            color: $other-yellow-gm;
        }
    }
  }
}
&__gm-law{
  .users-admin-import {
    &__index-box {
        border-bottom: 2px solid $primary-color-gm;
    }
    &__index-button {
        color: $primary-color-gm;
        &:hover {
            background-color: $hover-block-gm;
        }
    }
    &__contacts-import-title {
        color: $secondary-color-gm;
    }
    &__contacts-import-amount,
    &__contacts-import-amount-text {
        color: $custom-black-landing;
    }
    &__view-errors-button {
        color: $alert-color-gm;
        &--yellow {
            color: $other-yellow-gm;
        }
    }
    &__errors-header {
        border-color: $primary-color-gm;
        color: $primary-color-gm;
    }
    &__error-item {
        color: $custom-black-landing;
        border-bottom-color: $border-block-gm;
    }
    &__contacts-import-icon {
        &--green {
            color: $green-task-50-gm;
        }
        &--red {
            color: $red-task-50-gm;
        }
        &--yellow {
            color: $yellow-task-50-gm;
        }
    }
    &__contacts-block {
        border-top: 2px solid $primary-color-gm;
    }
    &__contacts-header {
        background-color: $primary-color-gm;
        &--green {
            background-color: $green-task-icam !important;
        }
        &--red {
            background-color: $alert-color-gm !important;
        }
    }
    &__amount-num {
        color: $custom-black-landing;
    }
    &__drop-filter-item {
        color: $primary-color-gm;
        &:hover{
            background-color: $hover-block-gm;
        }
    }
    &__contact-search-block {
        border-bottom: 1px solid $primary-color-gm;
        border-left: none;
    }
    &__contact-input {
        font-family: MTT-Milano, sans-serif;
        font-weight: bold;
        color: $primary-color-gm;
        &::-webkit-input-placeholder{
            color: $primary-color-gm;
        }
    }
    &__seach-icon {
        color: $primary-color-gm;
    }
    &__contact-item {
        color: $primary-color-gm;
    }
    &__contact-icon {
        &--list-error {
            color: $alert-color-gm;
        }
        &--list-warning {
            color: $other-yellow-gm;
        }
    }
    &__contact-error-item {
        &--error{
            color: $alert-color-gm;
        }
        &--warning{
            color: $other-yellow-gm;
        }
    }
  }
}
&__com-de{
  .users-admin-import {
    &__index-box {
        border-bottom: 2px solid $primary-color-com-de;
    }
    &__index-button {
        color: $primary-color-com-de;
        &:hover {
            background-color: $hover-block-com-de;
        }
    }
    &__contacts-import-title {
        color: $secondary-color-com-de;
    }
    &__contacts-import-amount,
    &__contacts-import-amount-text {
        color: $custom-black-landing;
    }
    &__view-errors-button {
        color: $alert-color-com-de;
        &--yellow {
            color: $other-yellow-com-de;
        }
    }
    &__errors-header {
        border-color: $primary-color-com-de;
        color: $primary-color-com-de;
    }
    &__error-item {
        color: $custom-black-landing;
        border-bottom-color: $border-block-com-de;
    }
    &__contacts-import-icon {
        &--green {
            color: $green-task-50-com-de;
        }
        &--red {
            color: $red-task-50-com-de;
        }
        &--yellow {
            color: $yellow-task-50-com-de;
        }
    }
    &__contacts-block {
        border-top: 2px solid $primary-color-com-de;
    }
    &__contacts-header {
        background-color: $primary-color-com-de;
        &--green {
            background-color: $green-task-icam !important;
        }
        &--red {
            background-color: $alert-color-com-de !important;
        }
    }
    &__amount-num {
        color: $custom-black-landing;
    }
    &__drop-filter-item {
        color: $primary-color-com-de;
        &:hover{
            background-color: $hover-block-com-de;
        }
    }
    &__contact-search-block {
        border-bottom: 1px solid $primary-color-com-de;
        border-left: none;
    }
    &__contact-input {
        font-family: MTT-Milano, sans-serif;
        font-weight: bold;
        color: $primary-color-com-de;
        &::-webkit-input-placeholder{
            color: $primary-color-com-de;
        }
    }
    &__seach-icon {
        color: $primary-color-com-de;
    }
    &__contact-item {
        color: $primary-color-com-de;
    }
    &__contact-icon {
        &--list-error {
            color: $alert-color-com-de;
        }
        &--list-warning {
            color: $other-yellow-com-de;
        }
    }
    &__contact-error-item {
        &--error{
            color: $alert-color-com-de;
        }
        &--warning{
            color: $other-yellow-com-de;
        }
    }
  }
}
&__com-fr{
  .users-admin-import {
    &__index-box {
        border-bottom: 2px solid $primary-color-com-fr;
    }
    &__index-button {
        color: $primary-color-com-fr;
        &:hover {
            background-color: $hover-block-com-fr;
        }
    }
    &__contacts-import-title {
        color: $secondary-color-com-fr;
    }
    &__contacts-import-amount,
    &__contacts-import-amount-text {
        color: $custom-black-landing;
    }
    &__view-errors-button {
        color: $alert-color-com-fr;
        &--yellow {
            color: $other-yellow-com-fr;
        }
    }
    &__errors-header {
        border-color: $primary-color-com-fr;
        color: $primary-color-com-fr;
    }
    &__error-item {
        color: $custom-black-landing;
        border-bottom-color: $border-block-com-fr;
    }
    &__contacts-import-icon {
        &--green {
            color: $green-task-50-com-fr;
        }
        &--red {
            color: $red-task-50-com-fr;
        }
        &--yellow {
            color: $yellow-task-50-com-fr;
        }
    }
    &__contacts-block {
        border-top: 2px solid $primary-color-com-fr;
    }
    &__contacts-header {
        background-color: $primary-color-com-fr;
        &--green {
            background-color: $green-task-icam !important;
        }
        &--red {
            background-color: $alert-color-com-fr !important;
        }
    }
    &__amount-num {
        color: $custom-black-landing;
    }
    &__drop-filter-item {
        color: $primary-color-com-fr;
        &:hover{
            background-color: $hover-block-com-fr;
        }
    }
    &__contact-search-block {
        border-bottom: 1px solid $primary-color-com-fr;
        border-left: none;
    }
    &__contact-input {
        font-family: MTT-Milano, sans-serif;
        font-weight: bold;
        color: $primary-color-com-fr;
        &::-webkit-input-placeholder{
            color: $primary-color-com-fr;
        }
    }
    &__seach-icon {
        color: $primary-color-com-fr;
    }
    &__contact-item {
        color: $primary-color-com-fr;
    }
    &__contact-icon {
        &--list-error {
            color: $alert-color-com-fr;
        }
        &--list-warning {
            color: $other-yellow-com-fr;
        }
    }
    &__contact-error-item {
        &--error{
            color: $alert-color-com-fr;
        }
        &--warning{
            color: $other-yellow-com-fr;
        }
    }
  }
}
}
