.consult-lists-modal{
  & .modal-dialog{
      & .modal-content{
          height: 650px;
      }
  }
  &__body{
      position: relative;
      width: calc(100% - 142px);
      margin: 25px auto 0;
  }
  &__title-block-modal{
      height: 69px;
  }
  &__form-block{
      width: 100%;
      height: 395px;
      display: flex;
      border: 1px solid $result-search-quote-color;
      color: $primary-color;
  }
  &__left-block-form{
      width: 275px;
      background: $background-color-states;
      padding: 29px 19px 0;
      font-family: MTTMilano-Bold, sans-serif;
  }
  &__right-block-form{
      width: calc(100% - 275px);
      padding: 20px 0 20px 20px;
  }
  &__header-name{
      height: 116px;
  }
  &__name-text{
      margin: 0;
      line-height: 20px;
      font-size: 16px;
      text-transform: uppercase;
  }
  &__identity-text{
      font-size: 12px;
      margin: 0;
      text-transform: uppercase;
  }
  &__block-icon-answer{
      text-align: center;
  }
  &__icon-answer{
      font-size: 100px;
  }
  &__mesasge-answer{
      font-size: 14px;
      line-height: 16px;
      margin-top: 14px;
  }
  &__body-detail-header{
      margin-bottom: 14px;
  }
  &__body-detail-answer{
      height: 354px;
      overflow-y: auto;
      overflow-x: hidden;
  }
  &__detail-name-text{
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 4px;
  }
  &__title-person-array{
      background: $result-search-quote-color;
      height: 30px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      line-height: 14px;
      padding-left: 12px;
  }

  &__body-detail-form{
      padding-right: 20px;
  }

  &__body-person-array{
      margin-top: 8px;
  }

  &__icon-info-detail{
      font-size: 15px;
      background: transparent;
      border: none;
      outline: none;
      margin: 0 10px 0 0;
      padding: 0;
  }

  &__datainfo-array{
      border: 1px solid $result-search-quote-color;
      border-top: none;
  }
  &__datainfo-col{
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      line-height: 14px;
      min-height: 21px;
      border-bottom: 1px solid $result-search-quote-color;
      display: flex;
      overflow-wrap: break-word;
      &:last-child{
          border: none;
      }
  }

  &__datainfo-type{
      border-right: 1px solid $result-search-quote-color;
      color: $pillow-group;
      width: 110px;
      min-width: 110px;
      padding: 4px;
  }
  &__datainfo-text{
      padding: 4px;
  }
  &__datainfo-list-text{
      margin-bottom: 10px;
      &:last-child{
          margin-bottom: 0px;
      }
  }
}

.compliance{
  &__dark-blue{
    .consult-lists-modal{
      &__left-block-form{
          &--red{
              background: $background-error;
          }
          &--green{
              background: rgba(119, 209, 102, 0.2);
          }
          &--yellow{
              background: rgba(241, 188, 62, 0.2);
          }
      }
      &__icon-answer{
          &--red{
              color: $red;
          }
          &--green{
              color: $green;
          }
          &--yellow{
              color: $yellow;
          }
      }
      &__datainfo-text{
          color: $secondary-color;
      }
    }
  }
  &__steel-blue{
    .consult-lists-modal{
      &__form-block{
          color: $primary-color-2;
          border-color: $secondary-color-2;
      }
      &__left-block-form{
          &--red{
              background: rgba(226, 71, 81, 0.2);
          }
          &--green{
              background: rgba(0, 218, 101, 0.2);
          }
          &--yellow{
              background: $warning-background-2;
          }
      }
      &__icon-answer{
          &--red{
              color: $alert-color-2;
          }
          &--green{
              color: $green-color-v2;
          }
          &--yellow{
              color: $warning-yellow;
          }
      }
      &__title-person-array{
          background: $secondary-color-2;
      }
      &__datainfo-array{
          border-color: $secondary-color-2;
      }
      &__datainfo-col{
          border-color: $secondary-color-2;
      }
      &__datainfo-type{
          border-color: $secondary-color-2;
          color: $grey-icons-2;
      }
    }
  }
}
