.help-tooltip{
  &__info-advanced-block{
      width: 460px;
      height: auto;
      min-height: 166px;
      background-color: #D7F4F8;
      padding: 30px 26px 10px 65px;
      color: $primary-color;
      position: relative;
      &:after {
          content: " ";
          position: absolute;
          top: 71px;
          left: -20px;
          border-top: 10px solid transparent;
          border-right: 10px solid #D7F4F8;
          border-bottom: 10px solid transparent;
          border-left: 10px solid transparent;
          z-index: 9999;
      }


  }

  &__close-info-advanced-block{
      position: absolute;
      top: 11px;
      right: 11px;
      background-color: transparent;
      outline: none;
      border: none;
      font-size: 12px;
      font-weight: bold;
      color: $primary-color;
      margin: 0;
      padding: 0;

  }

  &__icon-head-info-advanced{
      position: absolute;
      left: 23px;
      top: 21px;
      font-size: 30px;
  }

  &__title-head-info-advanced{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 15px;
      line-height: 20px;
      margin: 0;
      text-align: left;
  }

  &__text-head-info-advanced{
      font-family: MTTMilano, sans-serif;
      font-size: 13px;
      line-height: 19px;
      margin: 2px 0 18px;
      text-align: left;
  }

  &__block-buttons{
    height: 26px;
  }

  &__button-hide-all-info-advanced{
      color: $pillow-group;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      line-height: 20px;
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
      float: left;

  }
}
.compliance{
  &__steel-blue, &__uk-steel-blue{
    .help-tooltip{
      &__button-hide-all-info-advanced{
        color: $grey-icons-2;
      }
      &__close-info-advanced-block{
        color: $primary-color-2;
      }
      &__info-advanced-block{
        background-color: $third-color-2;
        color: $primary-color-2;
        &:after {
            border-right-color: $third-color-2;
        }
      }
    }
  }
}
