.modal-xl{
  width: 100%;
}

.formula-settings-modal{
  width: 100%;
  height: 100%;
  padding: 10px;
  &__window{
      & .modal-dialog{
          & .modal-content{
              margin: 0 auto;
              border-radius: 0px;
              height: 761px;
          }
      }
  }

  &__group-body-help{
      margin-top: 25px;
      width: 40%;
      padding-left: 20px;
      position: relative;
      float: left;
      font-family: Arial, sans-serif;
  }
  &__footer{
      width: 100%;
      float: left;
  }
  &__sc-buttons{
      float: right;
      width:50%;
  }

  &__icon-search{
      position: absolute;
      right: 0;
      top: 0;
      font-size: 23px;
      margin-right: -9px;
      color: $primary-color;
  }

  // &__title-block{
  //     height: 70px;
  //     width: 100%;
  //     background-color: $primary-color;
  //     display: block;
  //     overflow: hidden;
  //     padding: 21px 15px 21px 21px;
  //     margin-bottom: 12px;
  // }

  &__block-result-text {
      position: relative;
      width: 100%;
      float: left;
      max-width: 100%;
  }

  &__result-block {
      width: 100%;
      height: 504px;
      background-color: $background-grey-other;
      display: inline-block;
      margin-top: 5px;
      padding: 8px 10px 10px 10px;
  }

  &__close-pharentesis{
      float: right !important;

  }
  &__item{
      width: 100%;
      &--symbol{
          width: 20px;
          height: 20px;
          color: $snow-white;
          position: relative;
          float: left;
          margin-top: 15px;
          text-align: center;
          background-color: $primary-color;
      }
      &--fields{
          width:240px;
          position: relative;
          margin: 5px;
          padding: 10px;
          float: left;
          background: $background-grey-other;
      }
      &--symbol-full{
          display: inline-block;
          width: 20px;
          height: 20px;
          color: $snow-white;
          position: relative;
          float: left;
          margin-top: 15px;
          text-align: center;
          background-color: $primary-color;
      }
      &--full{
          display: inline-block;
      }
      &--line-jump{
          width: 250px;
          display: block;
      }
  }
  &__formula-col{
      float: left;
      margin-top: 60px;
      width: 4%;
  }
  &__panel-right{
      float: right;
      height: 579px;
      width: 48%;
      position: relative;
  }

  &__panel-left{
      height: 519px;
      float: left;
      width: 48%;
      position: relative;
  }

  &__panel-input{
      position: relative;
      top: 10px;
      height: unset;
  }

  &__panel{
      height: 100%;
      border: 1px solid $primary-color;
      color: $primary-color;
      position: relative;
      padding: 10px 5px 5px 15px;
  }

  &__block-header-right{
      width: 100%;
      position: relative;
      height: 45px;
      padding-right: 10px;
  }

  &__block-search{
      position: absolute;
      right: 10px;
      top: -8px;
      width: 50%;
  }

  &__search-input{
      position: relative;
      top: 4px;
      left: 7px;
      width: 100%;
      height: 22px;
      color: $primary-color;
      font-size: 12px;
      border: 1px solid $primary-color;
      border-top: none;
      border-right: none;
      outline: none;
      font-family: Arial, sans-serif;
      background-color: transparent;
      padding: 9px;
      border-color: $primary-color;
      font-family: MTTMilano, sans-serif;
      &::-webkit-input-placeholder{
          font-style: normal;
          padding: 0px;
      }
  }

  &__symbol-list {
      margin: 0;
      display: contents;
      list-style: none;
  }

  &__symbol-item {
      width: 40px;
      height: 40px;
      margin: auto;
      margin-top: 2%;
      position: relative;
      background: $primary-color;
      color: $snow-white;
      text-align: center;
      overflow: hidden;
      &:hover {
          cursor: pointer;

      }

      // &[disabled] {
      //     background: $secondary-color;
      //     cursor: default;
      // }
  }

  &__symbol-icon {
      position: relative;
      top: 11px;
      font-size: 18px;
  }

  &__block-tree{
      width: 100%;
      float: left;
      position: relative;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      padding: 3px 0 0 18px;
      border-bottom: 1px solid transparent;
      // height: 30px;
      &--children{
          margin-left: 13px;
          width: calc(100% - 13px);
          padding-left: 5px;
      }
      &--children-subgroup{
          padding-left: 5px;
      }
      &--children, &--children-subgroup{
          &:hover{
              background-color: $background-grey;
              border-bottom: 1px solid $box-comment-border-02;
              .formula-settings-modal__text-hidden{
                  visibility: visible;
              }
          }
      }
  }


  &__block-field{
      width: 100%;
      float: left;
      position: relative;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      padding-left: 0px;
      padding-right: 10px;
      overflow-y: auto;
      overflow-x: hidden;

      &--left{
          height: 450px;
      }

      &--right{
          height: 514px;
          margin-top: unset;
      }
  }

  &__icon-item{
      font-size: 16px;
      margin: 0;
      display: inline-block;
      position: relative;
      color: $attach-icon-grey;
      // vertical-align: middle;
      top: 2px;
  }

  &__icon{
      margin: 0 10px;
      display: inline-block;
      position: relative;
      color: $attach-icon-grey;
      vertical-align: middle;
      top: 1px;
      &--operator{
          margin: 0px;
          color: $snow-white;
          font-family: Arial, sans-serif;
          font-weight: normal;
          top: 1px;
          font-size: 15px;
      }

      &--operator-simple{
          margin: 0px;
          color: $primary-color;
          font-family: Arial, sans-serif;
          font-weight: normal;
          padding: 0 2px;
          right: 1px;
          top: -1px;
          font-size: 14px;
      }
  }
  &__block-text{
      display: inline-block;
      height: 20px;
  }

  &__subtitle-text{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 15px;
      display: inline-block;
  }


  &__title-children{
      font-family: MTTMilano, sans-serif;
      display: inline-block;
      position: relative;
      padding: 4px 5px 4px 7px;
      width: calc(100% - 72px);
      &--show{
          font-family: MTTMilano-Bold, sans-serif;
          width: 100%;
          padding: 4px 20px 4px 7px;
      }
  }

  &__block-name-group{
      // font-weight: bold;
      display: inline-block;
      width: calc(100% - 14px);
      &:hover{
          cursor: pointer;
      }
  }

  &__block-name-fields{
      width:98%;
      display: inline-block;
      &:hover{
          cursor: pointer;
      }
  }

  &__error-formula{
      display: block;
      background-color: $background-error;
      position: absolute;
      width: 96%;
      bottom: 15px;
      color:$red;
      left: 2%;
      height: 55px;
      &--title-text{
          font-family: MTTMilano, sans-serif;
          top: 20px;
          left: 13px;
          display: inline;
          font-size: 13px;
          position: relative;
      }
      &--icon{
          font-size: 40px;
          float: left;
          // font-weight: bold;
          margin-bottom: 8px;
          margin-top: 8px;
          margin-left: 20px;
      }
      &--close-icon{
          font-size: 15px;
          float: right;
          border: none;
          background: none;
          top: 19px;
          position:relative;
          display: block;
          right: 7px;
      }
  }

  &__encoding {
      &__value-field{
          float: left;
          width: 48%;
          position:relative;
      }
  }

  &__title-group{
      font-family: MTTMilano-Bold, sans-serif;
      // text-transform: uppercase;
      display: inline-block;
      position: relative;
      padding: 4px 20px 4px 7px;

  }

  &__block-icons-right{
      float: right;
  }

  &__button-view{
      text-transform: uppercase;
      // font-weight: bold;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 11px;
      // float: right;
      height: 30px;
      // margin-top: 5px;
      padding: 5px 10px 5px 10px;
      text-align: center;
      background-color: transparent;
      outline: none;
      border: none;
      border-bottom: 2px solid $primary-color;
      position: relative;
      bottom: 2px;
      &:hover{
          cursor: pointer;
          background-color: $subcontainer-border;
      }
  }

  &__icon-view{
      top: 2px;
      right: 2px;
      position: relative;
      font-size: 14px;
  }

  &__icon-arrow{
      font-size: 10px;
      display: inline-block;
      position: relative;
      visibility: hidden;
      &--visibility{
          visibility: visible;
      }
      &--field-list{
          top: 0;
      }
      &--field-list-multiple{
          top: 0;
          padding-right: 4px;
      }
      &:hover{
          cursor: pointer;
      }
  }

  &__text-result{
      display: inline;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 14px;
      padding-left: 3px;
  }

  &__text-hidden{
      visibility: hidden;
      text-transform: uppercase;
      float: right;
      font-family: Arial, sans-serif;
      font-weight: bold;
      font-size: 10px;
      position: absolute;
      right: 3px;
      top: 6px;
      border-bottom: 2px solid $primary-color;
      padding: 0 4px;
  }

  &__arrow-icon{
      float: right;
      font-size: 15px;
      // position: relative;
      // top: 2px;
      margin-left: 4px;
  }

  &__block-group{
      display: block;
      position: relative;
      float: left;
      width: 100%;
  }
}

.compliance{
  &__gm-law{
      .formula-settings-modal{
          &__icon-search{
              color: $primary-color-gm;
              font-size: 19px;
          }
          &__result-block {
              background-color: $background-grey-disabled-gm;
          }
          &__panel{
              border: 1px solid $border-block-gm;
              color: $primary-color-gm;
          }
          &__search-input{
              color: $primary-color-gm;
              border-left: none;
              border-bottom: 1px solid $third-color-gm;
              &::-webkit-input-placeholder{
                  font-style: normal;
                  padding: 0px;
                  color: $primary-color-gm;
              }
          }
          &__symbol-item {
              background: $primary-color-gm;
          }
          &__block-tree{
              color: $custom-black-landing;
              &--children,
              &--children-subgroup {
                  &:hover{
                      background-color: $hover-block-gm;
                      border-bottom: 1px solid $border-block-gm;
                      .formula-settings-modal__text-hidden{
                          visibility: visible;
                      }
                  }
              }
          }
          &__block-field{
              color: $primary-color-gm;
          }
          &__icon-item{
              color: $secondary-color-gm;
          }
          &__icon{
              &--operator-simple{
                  color: $custom-black-landing;
              }
          }
          &__item{
            &--fields{
                background: $background-grey-disabled-gm;
            }
          }
          &__block-text{
              background-color: $background-box-gm;
          }
          &__subtitle-text{
              color: $title-text-gm;
          }
          &__error-formula{
              background-color: $background-alert-gm;
              color: $alert-color-gm;
          }
          &__title-group{
              color: $custom-black-landing;
          }
          &__button-view{
              border-bottom: 2px solid $primary-color-gm;
              &:hover{
                  cursor: pointer;
                  background-color: $hover-block-gm;
              }
          }
          &__icon-arrow{
              color: $primary-color-gm;
          }
          &__text-result{
              color: $custom-black-landing;
          }
          &__text-hidden{
              border-bottom: 2px solid $primary-color-gm;
              color: $primary-color-gm;
          }
          &__arrow-icon{
              color: $primary-color-gm;
          }
      }
  }
  &__com-de{
    .formula-settings-modal{
        &__icon-search{
            color: $primary-color-com-de;
            font-size: 19px;
        }
        &__result-block {
            background-color: $background-grey-disabled-com-de;
        }
        &__panel{
            border: 1px solid $border-block-com-de;
            color: $primary-color-com-de;
        }
        &__search-input{
            color: $primary-color-com-de;
            border-left: none;
            border-bottom: 1px solid $third-color-com-de;
            &::-webkit-input-placeholder{
                font-style: normal;
                padding: 0px;
                color: $primary-color-com-de;
            }
        }
        &__symbol-item {
            background: $primary-color-com-de;
        }
        &__block-tree{
            color: $custom-black-landing;
            &--children,
            &--children-subgroup {
                &:hover{
                    background-color: $hover-block-com-de;
                    border-bottom: 1px solid $border-block-com-de;
                    .formula-settings-modal__text-hidden{
                        visibility: visible;
                    }
                }
            }
        }
        &__block-field{
            color: $primary-color-com-de;
        }
        &__icon-item{
            color: $secondary-color-com-de;
        }
        &__icon{
            &--operator-simple{
                color: $custom-black-landing;
            }
        }
        &__item{
          &--fields{
              background: $background-grey-disabled-com-de;
          }
        }
        &__block-text{
            background-color: $background-box-com-de;
        }
        &__subtitle-text{
            color: $title-text-com-de;
        }
        &__error-formula{
            background-color: $background-alert-com-de;
            color: $alert-color-com-de;
        }
        &__title-group{
            color: $custom-black-landing;
        }
        &__button-view{
            border-bottom: 2px solid $primary-color-com-de;
            &:hover{
                cursor: pointer;
                background-color: $hover-block-com-de;
            }
        }
        &__icon-arrow{
            color: $primary-color-com-de;
        }
        &__text-result{
            color: $custom-black-landing;
        }
        &__text-hidden{
            border-bottom: 2px solid $primary-color-com-de;
            color: $primary-color-com-de;
        }
        &__arrow-icon{
            color: $primary-color-com-de;
        }
    }
  }
  &__com-fr{
    .formula-settings-modal{
        &__icon-search{
            color: $primary-color-com-fr;
            font-size: 19px;
        }
        &__result-block {
            background-color: $background-grey-disabled-com-fr;
        }
        &__panel{
            border: 1px solid $border-block-com-fr;
            color: $primary-color-com-fr;
        }
        &__search-input{
            color: $primary-color-com-fr;
            border-left: none;
            border-bottom: 1px solid $third-color-com-fr;
            &::-webkit-input-placeholder{
                font-style: normal;
                padding: 0px;
                color: $primary-color-com-fr;
            }
        }
        &__symbol-item {
            background: $primary-color-com-fr;
        }
        &__block-tree{
            color: $custom-black-landing;
            &--children,
            &--children-subgroup {
                &:hover{
                    background-color: $hover-block-com-fr;
                    border-bottom: 1px solid $border-block-com-fr;
                    .formula-settings-modal__text-hidden{
                        visibility: visible;
                    }
                }
            }
        }
        &__block-field{
            color: $primary-color-com-fr;
        }
        &__icon-item{
            color: $secondary-color-com-fr;
        }
        &__icon{
            &--operator-simple{
                color: $custom-black-landing;
            }
        }
        &__item{
          &--fields{
              background: $background-grey-disabled-com-fr;
          }
        }
        &__block-text{
            background-color: $background-box-com-fr;
        }
        &__subtitle-text{
            color: $title-text-com-fr;
        }
        &__error-formula{
            background-color: $background-alert-com-fr;
            color: $alert-color-com-fr;
        }
        &__title-group{
            color: $custom-black-landing;
        }
        &__button-view{
            border-bottom: 2px solid $primary-color-com-fr;
            &:hover{
                cursor: pointer;
                background-color: $hover-block-com-fr;
            }
        }
        &__icon-arrow{
            color: $primary-color-com-fr;
        }
        &__text-result{
            color: $custom-black-landing;
        }
        &__text-hidden{
            border-bottom: 2px solid $primary-color-com-fr;
            color: $primary-color-com-fr;
        }
        &__arrow-icon{
            color: $primary-color-com-fr;
        }
    }
  }
}


@media (max-width: 992px){
  .formula-settings-modal__window{
      & .modal-dialog{
          & .modal-content{
              width: 800px;
          }
      }
  }
}
@media (min-width: 992px) and (max-width: 1250px) {
  .formula-settings-modal__window{
      & .modal-dialog{
          & .modal-content{
              width: 985px;
          }
      }
  }
}
@media (min-width: 1251px) and (max-width: 1500px){
  .formula-settings-modal__window{
      & .modal-dialog{
          & .modal-content{
              width: 1200px;
          }
      }
  }
}
@media (min-width: 1501px){
  .formula-settings-modal__window{
      & .modal-dialog{
          & .modal-content{
              width: 1500px;
          }
      }
  }
}
