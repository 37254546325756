.field-type-consult-lists {
  &__container {
      width: 100%;
      padding: 6px 10px 10px;
      display: inline-block;
  }

  &__text-description {
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      margin-bottom: 6px;
      display: block;
      width: 100%;
      position: relative;
      float: left;
  }

  &__block-button {
      position: relative;
      text-align: right;
  }

  // &__block-list-document {
  //     margin-top: 10px;
  // }

  &__element-list-document {
      display: table;
      width: 100%;
      height: 40px;
      padding: 0 10px;
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: 500;
      background: $background-grey-other;
      margin-top: 10px;
  }

  &__table-cell-document {
      width: calc(100% - 60px);
      display: table-cell;
      vertical-align: middle;
  }

  &__table-cell-options {
      width: 60px;
      display: table-cell;
      vertical-align: middle;
      text-align: right;
      font-size: 20px;
  }

  &__icon-list {
      background: transparent;
      outline: none;
      border: none;
      padding: 0;

      &--first {
          margin-right: 3px;
      }
  }
}

.compliance{
  &__dark-blue{
    .field-type-consult-lists {
      &__element-list-document {
          &--red{
              background: $background-error;
          }
          &--green{
              background: rgba(119, 209, 102, 0.2);
          }
          &--yellow{
              background: rgba(241, 188, 62, 0.2);
          }
      }
      &__element-list-title {
          color: $repeat-pattern;
      }
      &__element-list-filename {
          color: $repeat-pattern;
      }
      &__icon-list {
          color: $primary-color;
      }
    }
  }
  &__steel-blue, &__uk-steel-blue{
    .field-type-consult-lists {
      &__text-description {
          color: $text-field-color-2;
      }
      &__element-list-document {
          &--red{
              background: rgba(226, 71, 81, 0.2);
          }
          &--green{
              background: rgba(0, 218, 101, 0.2);
          }
          &--yellow{
              background: $warning-background-2;
          }
      }
      &__element-list-title {
          color: $text-field-color-2;
      }
      &__element-list-filename {
          font-weight: bold;
          color: $primary-color-2;
      }
      &__icon-list {
          color: $border-hover-2;
      }
    }
  }
  &__icam-red{
    .field-type-consult-lists {
      &__text-description {
          color: $text-field-color-icam;
      }
      &__element-list-title {
          color: $text-field-color-icam;
      }
    }
  }
  &__gm-law{
    .field-type-consult-lists {
      &__text-description {
          color: $text-field-color-gm;
      }
      &__element-list-title {
          color: $text-field-color-gm;
      }
    }
  }
  &__com-de{
    .field-type-consult-lists {
      &__text-description {
          color: $text-field-color-com-de;
      }
      &__element-list-title {
          color: $text-field-color-com-de;
      }
    }
  }
  &__com-fr{
    .field-type-consult-lists {
      &__text-description {
          color: $text-field-color-com-fr;
      }
      &__element-list-title {
          color: $text-field-color-com-fr;
      }
    }
  }
}
