.alert-list-window {
    &__body{
        width: 100%;
        position: relative;
        margin-bottom: 1px;
    }

    &__container{
        max-width: 1420px;
        margin: 0 auto;
        padding: 0 10px;
    }

    &__block-container{
        height: 646px;
    }

    &__col {
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        padding-right: 5px;
        &--tooltip {
            max-width: 0;
        }
        &--top-1{
            position: relative;
            top: 1px;
        }
        &--first{
            padding-left: 8px;
        }

        &--title,
        &--origin,
        &--creator,
        &--destination,
        &--alert-date{
            width: 20%;
        }
    }

    &__pagination-block{
        text-align: center;
        height: 22px;
        display: block;
        float: left;
        width: calc(100% - 360px);
        left: 180px;
        bottom: 0px;
        position: absolute;
    }

    &__icon-origin {
      font-size: 20px;
      width: 20px;
      position: relative;
      float: left;
      bottom: 1px;
  }

  &__parragrapht-with-icon{
      position: relative;
      float: left;
      padding-left: 10px;
      width: calc(100% - 20px);
      top: 2px;
  }
}

.compliance{
  &__dark-blue{
    .alert-list-window{
      &__icon-origin {
        color: $primary-color;
      }
    }
  }
  &__steel-blue, &__uk-steel-blue{
    .alert-list-window{
      &__icon-origin {
        color: $border-hover-2;
      }
    }
  }
  &__icam-red{
    .alert-list-window{
      &__icon-origin {
        color: $primary-color-icam;
      }
    }
  }
  &__gm-law{
    .alert-list-window{
      &__icon-origin {
        color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    .alert-list-window{
      &__icon-origin {
        color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .alert-list-window{
      &__icon-origin {
        color: $primary-color-com-fr;
      }
    }
  }

}
