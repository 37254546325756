.custom-report-field-list{
  &__table-col {
      // display: table-cell;
      // vertical-align: middle;
      text-align: left;
      padding-right: 5px;

      // &--tooltip {
      //     max-width: 0;
      // }
      &--top-1{
          position: relative;
          top: 1px;
      }
      &--top-2{
          position: relative;
          top: 2px;
      }
      &--is-custom-report-header, &--is-custom-report {
          width: 10%;
      }

      &--report-name-report-header, &--report-name-report {
          width: 25%;
      }

      &--report-fields-header, &--report-fields {
          width: 55%;
      }

      &--action-report-header, &--action-report {
          width: 10%;
          position: relative;
      }
  }
}
