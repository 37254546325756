@mixin process-ops-modal-wrapper($borderColor){
  border: 1px solid $borderColor;
  margin-top: 10px;
}
.process-ops-modal {
  &__window {
      & .modal-content {
          height: 755px;
          border-radius: 0px;
      }
  }

  & .widget {
      &__add-layer {
          @include add-layer($primary-color);
          z-index: 3;
      }

      &__header {
          @include widget-header($primary-color);
          padding-left: 0px;
          margin-top: 20px;
          &__title{
              @include widget-header($primary-color);
              font-size: 17px;
          }
          &__icon{
              top: 2px;
              font-size: 20px;
          }
      }
  }

  &__body {
      width: 100%;
      padding: 10px 10px 0;
  }

  &__body-inner{
      width: 100%;
      height: 564px;
      padding: 28px 61px 0;
      overflow: auto;
      overflow-x: hidden;
      &--automatic-process{
          padding: 0px 61px;
      }
  }

  &__wrapper {
      background-color: transparent;
      padding: 10px 10px 0;
      min-height: 75px;
      position: relative;
      float: left;
      width: 100%;
      &--task{
          max-height: 153px;
      }
      &--three-elements{
          max-height: 242px;
      }
  }

  &__process-box-title{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      position: relative;
      top: 4px;
      clear: both;
  }

  &__table{
      padding: 20px 0px 5px;
      position: relative;
      float: left;
      width: 100%;
  }

  &__table-header{
      padding-bottom: 5px;
      height: 30px;
  }

  &__table-body-task{
      position: relative;
      float: left;
      width: 100%;
  }

  &__table-body {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 138px;
      display: block;
      width: 100%;
      &--more-elements{
          width: calc(100% + 10px);
      }
  }

  &__table-col{
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding-right: 6px;

      &--tooltip {
          max-width: 0;
      }
      &--title{
          width: 39%;
          padding-left: 5px;
      }
      &--creator{
          width: 20%;

      }
      &--destination{
          width: 20%;
      }
      &--date-creation{
          width: calc(21% - 30px);
      }
      &--title-alert{
          width: 20%;
          padding-left: 5px;
      }
      &--step{
          width: 19%;
      }
      &--creator-alert{
          width: 20%;
      }
      &--destination-alert{
          width: 20%;
      }
      &--date-alert{
          width: calc(21% - 30px);
      }
      &--step-process{
          width: 39%;
          padding-left: 5px;
      }
      &--creator-process{
          width: 31%;
      }
      &--date-process{
          width: calc(30% - 30px);
      }
      &--actions{
          width: 30px;
          min-width: 30px;
          right: 0;
          padding: 0;
          position: relative;
      }
  }

  &__button-icon-visible{
      font-size: 25px;
      &::before{
          position: relative;
          left: 0px;
      }
  }

  // &__inside{
  // }

  &__header-icon{
      font-size: 30px;
      float: left;
  }

  &__header-title{
      font-family: MTTMilano-Bold, sans-serif;
      height: 18px;
      font-size: 18px;
      margin-left: 13px;
      top: 3px;
      line-height: 24px;
      position: relative;
  }

  &__title-block{
      position: relative;
      float: left;
      margin-right: 7px;
  }

  &__table-body-blocked-states{
      position: relative;
      float: left;
      width: 100%;
      margin: 10px 0 3px;
  }

  &__blocked-list{
      display: block;
      overflow: hidden;
      width: auto;
      padding: 0;
      position: relative;
      float: left;
      font-family: Arial, sans-serif;
      font-size: 12px;
      margin: 2px 5px 2px 5px;
  }

  &__icon-blocked-list{
      float: left;
      background-color: transparent;
      padding: 0 2px 2px 2px;
      margin-right: 5px;
      font-size: 20px;
  }

  &__text-blocked-list{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0;
      position: relative;
      top: 3px;
  }
  &__container-border{
      border: 1px solid $result-search-quote-color;
  }
}

.compliance{
  &__dark-blue{
    .process-ops-modal{
      &__wrapper{
        @include process-ops-modal-wrapper($result-search-quote-color);
      }
      &__process-box-title{
        color: $pillow-group;
      }
      &__table-row{
        &:hover{
            background-color: $background-grey-other;
        }
      }
      &__title-block{
        color: $pillow-group;
      }
      &__icon-blocked-list {
        color: $subcontainer-ground;
      }
      &__blocked-list{
        color: $secondary-color;
      }
    }
  }
  &__steel-blue{
    .process-ops-modal{
      &__wrapper {
        @include process-ops-modal-wrapper($background-highlight-text-2);
      }
      &__process-box-title{
        color: $grey-icons-2;
      }
      &__table-col{
        padding-left: 15px;
      }
      &__title-block{
        color: $primary-color-2;
      }
      &__icon-blocked-list {
        color: $grey-icons-2;
      }
      &__blocked-list{
        color: $text-field-color-2;
      }
    }
  }
  &__uk-steel-blue{
    .process-ops-modal{
      &__wrapper {
        @include process-ops-modal-wrapper($background-highlight-text-2);
      }
      &__process-box-title{
        color: $grey-icons-2;
      }
      &__table-col{
        padding-left: 15px;
     }
      &__title-block{
        color: $primary-color-2;
      }
      &__blocked-list{
        color: $text-field-color-2;
      }
    }
  }
  &__icam-red{
    .process-ops-modal{
      &__wrapper{
        @include process-ops-modal-wrapper($border-block-icam);
      }
      &__process-box-title{
        color: $secondary-color-icam;
      }
      &__title-block{
        color: $title-text-icam;
      }
      &__icon-blocked-list {
        color: $secondary-color-icam;
      }
      &__blocked-list{
        color: $custom-black-landing;
      }

    }
  }
  &__gm-law{
    .process-ops-modal{
      &__wrapper{
        @include process-ops-modal-wrapper($border-block-gm);
      }
      &__process-box-title{
        color: $secondary-color-gm;
      }
      &__title-block{
        color: $title-text-gm;
      }
      &__icon-blocked-list {
        color: $secondary-color-gm;
      }
      &__blocked-list{
        color: $custom-black-landing;
      }

    }
  }
  &__com-de{
    .process-ops-modal{
      &__wrapper{
        @include process-ops-modal-wrapper($border-block-com-de);
      }
      &__process-box-title{
        color: $secondary-color-com-de;
      }
      &__title-block{
        color: $title-text-com-de;
      }
      &__icon-blocked-list {
        color: $secondary-color-com-de;
      }
      &__blocked-list{
        color: $custom-black-landing;
      }

    }
  }
  &__com-fr{
    .process-ops-modal{
      &__wrapper{
        @include process-ops-modal-wrapper($border-block-com-fr);
      }
      &__process-box-title{
        color: $secondary-color-com-fr;
      }
      &__title-block{
        color: $title-text-com-fr;
      }
      &__icon-blocked-list {
        color: $secondary-color-com-fr;
      }
      &__blocked-list{
        color: $custom-black-landing;
      }

    }
  }

}
