.field-type-time{
  position: relative;
  &__input-block-time {
      position: relative;
      cursor: text;
      display: inline-block;
  }
  &__icon-arrow{
      cursor: pointer;

  }
  &__block-time-hover{
      position: absolute;
      float: right;
      top: 1px;
      right: 43px;
      width: 20px;
      min-width: 99px;
      background: transparent;
      border: transparent;
      height: 50px;
      display: inline-block;
      z-index: 2;
  }
  &__time-hover{
      position: relative;
      float: left;
      margin: 0px 1px;
  }
  &__time-hover-none{
      display: none;
  }
  &__separated-time{
      position: relative;
      top: 12px;
      font-size: 17px;
  }
  &__input-content{
      border: none;
      margin: 1px;
      height: 19px;
      outline: 0;
      width: 40px;
      border-top: 1px solid $primary-color;
      border-bottom: 1px solid $primary-color;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: 500;
      background: transparent;
  }
  &__time-hover-position{
      display: block;
      text-align: center;
      color: $primary-color;
  }
  &__block-time-whitouthover{
      position: absolute;
      bottom: 0px;
      float: left;
      width: 100%;
  }
  &__input-content-time-hour{
      width: 25px;
      font-size: 12px;
      display: inline-block;
  }
  &__input-content-time-minute{
      display: inline-block;
      width: 25px;
      font-size: 12px;
      padding-left: 0px;
      margin-left: -5px;
  }
  &__separated-time-whitouthover{
      margin-right: 6px;
      font-size: 11px;
  }
  &__delete-content-icon{
      right: 7px;
      bottom: 3px;
      font-size: 10px;
  }
  &__block-hover{
      width: 100%;
      display: block;
      position: relative;
  }
}
.compliance{
  &__steel-blue, &__uk-steel-blue{
    .field-type-time{
      &__time-hover-position{
          color: $primary-color-2;
          border-color: $primary-color-2;
      }
    }
  }
  &__icam-red{
    .field-type-time{
      &__time-hover-position{
          color: $primary-color-icam;
          border-color: $border-block-icam;
          &--text{
              color: $custom-black-landing;
          }
        }
      &__block-time-hover{
          color: $custom-black-landing;
      }
    }
  }
  &__gm-law{
    .field-type-time{
      &__time-hover-position{
          color: $primary-color-gm;
          border-color: $border-block-gm;
          &--text{
              color: $custom-black-landing;
          }
      }
      &__block-time-hover{
          color: $custom-black-landing;
      }
    }
  }
  &__com-de{
    .field-type-time{
      &__time-hover-position{
          color: $primary-color-com-de;
          border-color: $border-block-com-de;
          &--text{
              color: $custom-black-landing;
          }
      }
      &__block-time-hover{
          color: $custom-black-landing;
      }
    }
  }
  &__com-fr{
    .field-type-time{
      &__time-hover-position{
          color: $primary-color-com-fr;
          border-color: $border-block-com-fr;
          &--text{
              color: $custom-black-landing;
          }
      }
      &__block-time-hover{
          color: $custom-black-landing;
      }
    }
  }
  &__landing-custom{
    .field-type-time{
      &__time-hover-position{
        color: $custom-black-landing;
        border-color: $custom-black-landing;
      }
    }
  }
}
