.legal-document {
  &__list-container {
      width: 20%;
      // position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      // z-index: 3;
      position: relative;
      float: left;
  }

  &__container-right{
    position: relative;
    float: left;
    width: 80%;
  }

  &__list {
      margin-left: 25px;
      padding-top: 15px;
      padding-left: 0;
      list-style: none;
      border-right: 1px dotted $custom-black-landing;
      font-size: 10px;
      line-height: 3em;
      font-family: Arial, sans-serif;
      color: $subcontainer-ground;
      overflow: auto;
  }

  &__list-item {
      display: inline-block;
      width: 100%;
      &:hover{
          cursor: pointer;
      }

      > i {
          position: relative;
          float: left;
          width: 17px;
          font-size: 17px;
          margin-right: 5px;
          vertical-align: middle;
      }

      &.selected {
          color: $primary-color;
          font-weight: bold;
      }

      &:hover {
          color: $primary-color;
      }



      &--custom {
          &.selected {
              color: $custom-black-landing;
              font-weight: normal;
          }

          &:hover {
              color: $custom-black-landing;
          }
      }
  }

  &__col-text{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 22px);
      position: relative;
      float: left;
      line-height: 17px;
  }

  &__error {
      color: $alert-color;
      font-size: 13px;
      padding-top: 1em;

      & > i {
          vertical-align: middle;
      }
  }

  &__block-document-container {
      // width: 80%;
      top: 0;
      right: 25px;
      bottom: 0;
      overflow: auto;
      padding: 20px 30px 0 50px;
  }

  &__document-container {
      &.legi, &.juri, &.comm, &.actu {
          & .legal-document__body {
              & ul {
                  list-style: none;
              }

              & .repealed {
                  display: inline-block;
                  color: $snow-white;
                  background-color: $document-container;
                  font-size: 10px;
                  font-weight: bold;
                  text-transform: uppercase;
                  padding: 5px 8px;
                  line-height: 1.3em;

                  & > i {
                      font-size: 12px;
                      margin-right: 8px;
                      vertical-align: middle;
                  }
              }

              & .fuente {
                  color: $document-container-font;
                  font-size: 1.1em;
              }

              & .nivel-seccion {
                  & h1 {
                      color: $document-container-level;
                      font-size: 1.2em;
                      font-weight: bold;
                  }

                  & h2 {
                      font-size: 1.1em;
                      line-height: 1.3em;
                      color: $document-container-level;
                      font-weight: bold;
                  }

                  & h3 {
                      color: $document-container-level;
                      font-size: 12px;
                      font-weight: normal;
                      margin-bottom: 5px;
                  }

                  & p {
                      color: $document-container-level;
                  }
              }

              & .strong {
                  font-weight: bold;
              }

              & .enfasis {
                  font-weight: bold;
              }

              & .subrayado {
                  text-decoration: underline;
              }

              & .resumenActualidad {
                  color: $document-container-resumen;
                  font-weight: bold;
                  font-size: 1.066em;
                  margin-bottom: 3mm;
                  margin-top: 3mm;
                  display: block;
              }

              & .divrefActualidadList {
                  display: inline-table;
              }

              & .refActualidad, & .refActualidadList {
                  float: left;
                  display: list-item;
                  width: 100%;
                  color: $blue;
                  font-weight: bold;
                  font-family: Arial, Helvetica, sans-serif;
                  margin-left: 20px;
                  margin-bottom: 2px;
              }

              & .refActualidad a, & .refActualidadList a {
                  color: $blue;
                  font-weight: bold;
                  font-family: Arial, Helvetica, sans-serif;
              }

              & .itemLetra {
                  list-style-type: upper-latin;
                  display: list-item;
                  margin: 20px;
              }

              & table[border] {
                  font-size: 10px;
                  margin: 0px;
                  border: 1px solid $legal-document-table;
                  border-collapse: collapse;

                  & td, & th {
                      border: 1px solid $legal-document-table;
                      padding: 5px;

                      & p {
                          margin-bottom: 0;
                      }
                  }
              }
          }
      }

      &.meme, &.mexp, &.prac {
          & .not-visible {
              visibility: hidden;
          }

          & .common__underlined-title {
              color: $docu-meme;
              border-bottom-color: $docu-meme;
          }

          & .legal-document__body {
              padding-left: 60px;

              & h3 {
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: $docu-meme;
                  margin-bottom: 5px;

                  &.marginal {
                      position: absolute;
                      left: 0px;
                      margin-top: -4px;
                      width: 43px;
                      background: $docu-meme-back;
                      padding: 4px 4px 3px 4px;
                      display: block;
                      &::after {
                          content: " ";
                          position: absolute;
                          top: 0;
                          right: -10px;
                          border-width: 10px;
                          border-style: solid;
                          border-left-color: $docu-meme-back;
                          border-top-color: transparent;
                          border-bottom-color: transparent;
                          border-right: none;
                      }
                  }
              }

              & h4 {
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: $secondary-color;
                  margin-bottom: 5px;
              }

              & > .rmem {
                  position: absolute;
                  left: 75px;
                  margin-top: 20px;
                  width: 43px;
                  line-height: 1em;
                  white-space: nowrap;

                  &.has-title {
                      margin-top: 20px;
                  }

                  &.has-fond {
                      margin-top: 0;
                  }

                  &.consecutiveRmem {
                      margin-top: 30px;
                  }
              }

              & .motrep {
                  font-weight: bold;
              }

              & .al {
                  br {
                      display: none;
                  }
              }

              & .ital {
                  font-style: italic;
              }

              & .preci {
                  font-size: 12.75px;
                  margin-left: 2em;
                  padding-top: 10px;
              }

              & .preci:before {
                  content: "Precisiones\A";
                  position: relative;
                  top: -8px;
                  white-space: pre;
                  /* clear: both; */
                  font-weight: bold;
                  background-color: $legal-document-content;
                  padding: 6px;
                  /* margin-top: -6px; */
              }

              & .ex-t {
                  display: inline-block;
                  background-color: $legal-document-content;
                  border: 1px solid $legal-document-border;
                  color: $custom-black-landing;
                  padding: 2px 8px;
                  font-family: lato_black , sans-serif;
                  font-weight: bold;
                  margin-bottom: 10px;
                  font-size: 1em;
                  margin-top: 10px;
              }

              & .rtab {
                  max-width: 100%;
                  margin: 0 auto;
                  display: block;
              }

              & ul {
                  counter-reset: list;
                  padding-left: 1.4em;

                  & > li {
                      list-style: none;
                      position: relative;
                  }

                  & > li:before {
                      content: counter(list) ") ";
                      counter-increment: list;
                      position: absolute;
                      left: -1.4em;
                      font-weight: bold;
                  }

                  &.alphapar > li::before {
                      content: counter(list, lower-alpha) ") ";
                  }

                  &.alphapargr > li::before {
                      content: counter(list, lower-alpha) ") ";
                  }

                  &.puce > li::before {
                      content: " ";
                      top: 4px;
                      width: 10px;
                      height: 10px;
                      background-color: $subcontainer-border;
                  }

                  &.tir {
                      & > li::before {
                          content: "-";
                          counter-increment: list;
                          position: absolute;
                          left: -1.4em;
                          font-weight: bold;
                      }
                  }

                  &.rien {
                      padding-left: 0;

                      & > li:before {
                          content: none;
                      }
                  }
              }
          }

          & .actum {
              font-size: 13px;

              & p {
                  margin-bottom: 0;

                  &.al {
                      margin-bottom: 8px;
                      font-style: italic;
                  }
              }

              & .tinfo {
                  & .rmem {
                      color: $secondary-color;
                      margin-top: 8px;
                      font-size: 0.857em;

                      & + .rmem {
                          margin-top: 0;
                      }
                  }

                  & .al:hover {
                      cursor: pointer;
                      text-decoration: underline;
                  }
              }

              & .tinfo, & .refdoc {
                  color: $advice-confirm;
              }

              & .layer {
                  display: none;
                  margin-left: 20px;
                  border-left: 1px dotted $legal-document-layer;
                  padding-left: 20px;
              }

              & .icon-update {
                  font-size: 9px;
                  margin-bottom: 10px;

                  & > i {
                      font-size: 18px;
                      margin-right: 8px;
                  }
              }

              & .t, .last-notice {
                  font-weight: bold;
              }

              & .resumen {
                  font-style: italic;
                  margin-top: 8px;
              }

              & .refdoc {
                  margin-top: 8px;
              }
          }
      }

      &.MS {
          & .common__underlined-title {
              color: $docu-meme-MS;
              border-bottom-color: $docu-meme-MS;
          }

          & .legal-document__body h3 {
              color: $docu-meme-MS;
              background-color: $docu-meme-MS-back;

              &::after {
                  border-top-color: transparent;
                  border-bottom-color: transparent;
                  border-left-color: $docu-meme-MS-back;
              }
          }
      }

      &.MF {
          & .common__underlined-title {
              color: $docu-meme-MF;
              border-bottom-color: $docu-meme-MF;
          }

          & .legal-document__body h3 {
              color: $docu-meme-MF;
              background-color: $docu-meme-MF-back;

              &::after {
                  border-top-color: transparent;
                  border-bottom-color: transparent;
                  border-left-color: $docu-meme-MF-back;
              }
          }
      }

      &.MC {
          & .common__underlined-title {
              color: $docu-meme-MC;
              border-bottom-color: $docu-meme-MC;
          }

          & .legal-document__body h3 {
              color: $docu-meme-MC;
              background-color: $docu-meme-MC-back;

              &::after {
                  border-top-color: transparent;
                  border-bottom-color: transparent;
                  border-left-color: $docu-meme-MC-back;
              }
          }
      }

      &.form {
          color: $legal-document-form;

          & .legal-document__body {
              & h1, & h2 {
                  display: none;
              }
          }

          & h3 {
              color: $primary-color;
              font-size: 12px;
              font-weight: normal;
              text-transform: uppercase;
              line-height: 18px;
              margin-top: 14px;

              & > .nivd-fond {
                  color: $modal-info-hours;
              }

              & > .rmem.nivd-fond {
                  margin-top: 15px;
                  display: inline-block;
              }
          }

          & .preci {
              padding-left: 15px;
          }

          & .preci, & .efl-introesp {
              padding-top: 5px;
              padding-bottom: 5px;
          }

          & .preci-t {
              font-size: 0.7142857142857143em;
              font-weight: 700;
              text-transform: uppercase;
              color: $primary-color;
              background-color: $add-layer-disabled;
              padding: 4px 8px;
              margin-bottom: 15px;
              margin-right: 8px;
              display: inline-block;
          }

          & .p-content {
              font-weight: bold;
              margin-right: 0px;
              margin-bottom: 15px;
          }

          & .p-content {
              font-weight: normal;
              margin-right: 0px;
              margin-bottom: 15px;
          }

          & .efl-lettre, & .pouvoir {
              border: 1px solid $subcontainer-border;
              padding: 16px 21px 0 13px;
          }

          & .efl-dat, & .efl-sign {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: end;
              -ms-flex-pack: end;
              justify-content: flex-end;
          }

          & .efl-dat {
              margin-top: 22px;
          }

          & .efl-sign {
              margin: 44px 0;
          }

          & .efl-introesp, & .efl-tart {
              font-weight: bold;
          }

          & .tpouvoir {
              font-weight: bold;
              text-transform: uppercase;
              text-align: center;
          }

          & .txt {
              margin-bottom: 8px;
              font-weight: normal;
          }

          & .txtbold {
              font-weight: bold;
          }

          & .var {
              font-style: italic;
              font-weight: lighter;

              &:before {
                  content: "\""
              }

              &:after {
                  content: "\""
              }
          }

          & ul {
              list-style: none;
              padding-left: 20px;
          }

          & ul.nopar {
              counter-reset: list;

              & > li {
                  position: relative;
              }

              & > li:before {
                  content: counter(list) ") ";
                  counter-increment: list;
                  position: absolute;
                  left: -1.7em;
              }
          }
          /*& ul.rien {
              list-style: none;
              padding-left: 20px;
          }*/
          & ul.puce {
              list-style: disc;
          }
      }

      &.meme, &.mexp, &.form, &.prac {
          & table {
              width: 100%;

              &.border-all {
                  border: 1px solid $legal-document-form;
                  border-collapse: collapse;
              }

              & th, & td {
                  padding: 5px;
                  line-height: 1.38em;
              }

              & th {
                  font-weight: bold;
                  background-color: $background-grey;

                  &.border-bottom {
                      border-bottom: 1px solid $legal-document-form;
                  }

                  &.border-right {
                      border-right: 1px solid $legal-document-form;
                  }
              }

              & td {
                  &.border-bottom, &.borderbottomright {
                      border-bottom: 1px solid $subcontainer-border;
                  }

                  &.border-right, &.borderbottomright {
                      border-right: 1px solid $subcontainer-border;
                  }
              }

              & p {
                  margin-bottom: 0;
              }

              &.s {
                  & th, & td {
                      padding: 10px 7px;
                      border: none;
                      background-color: $background-grey;
                  }
              }
          }
      }

      &.form {
          & table {
              margin-top: 1em;
              margin-bottom: 2em;

              &.border-all {
                  border-color: $subcontainer-border;
                  border-collapse: collapse;
              }
          }
      }

      &.memo {
          & .legal-document__body {
              color: $app-text-color;

              & h1 {
                  font-weight: bold;
                  line-height: 19px;
                  background-color: $custom-color-306AC5;
                  font-size: 14px;
                  background-repeat: no-repeat;
                  background-size: 78px 30px;
                  width: 78px !important;
                  height: 30px;
                  padding: 5px 10px 5px 20px;
                  margin: 1em 1em 0.5em 0;
              }

              & h2 {
                  font-size: 14px;
                  margin: 0;
                  line-height: 28px;
                  color: $app-text-color;
                  padding-left: 20px;

                  &.consecutive {
                      background: none;
                      padding-left: 0;
                  }
              }

              & ul {
                  padding-left: 1em;

                  &.pres-tir {
                      list-style-type: none;

                      & > li {
                          text-indent: -1em;
                      }

                      & > li:before {
                          content: "-";
                          padding-right: 9px;
                      }
                  }

                  &.pres-nogr {
                      list-style-type: decimal;

                      & > li::marker {
                          font-weight: bold;
                      }
                  }
              }

              & a {
                  color: $custom-color-306AC5;
                  text-decoration: none;
                  pointer-events: none;
              }

              & .valign-top {
                  vertical-align: top;
              }

              & .align-center {
                  text-align: center;
              }

              & .align-justify {
                  text-align: justify;
              }

              & .memo-points {
                  display: block;
              }

              & table.frame-all, table.frame-none {
                  border: 1px solid black;
                  border-collapse: collapse;

                  & td {
                      border: 1px solid black;
                      padding: 5px;

                      & > span {
                          display: block;
                      }
                  }
              }
          }
      }
  }

  &__body {
      font-family: Arial, sans-serif;
      font-size: 12px;
      line-height: 20px;
      color: $secondary-color;
      text-align: justify;
      position: relative;
      &--custom {
          font-family: 'lato_regular', sans-serif;
          font-size: 14px;
          max-height: 335px;
          overflow: auto;
          padding: 0 45px 0 0;
          margin-top: 5px;
      }

      & .align-left {
          text-align: left;
      }

      & .align-justify {
          text-align: justify;
      }

      & .align-center {
          text-align: center;
      }

      & .align-right {
          text-align: right;
      }
  }

  &__icon-bookmarks {
      position: relative;
      bottom: 2px;
  }
  // JRSJ 27/11/2020 WEB-31927 [Centinela] Referencias
  &__no-uppercase {
      text-transform: none;
  }
  ////////////////////////////////////////
  /////////////////////Custom////////////
  //////////////////////////////////////
  &__list-container-custom {
      width: 185px;
      position: relative;
      float: left;
      padding: 0;
  }

  &__list-custom {
      margin: 0;
      list-style: none;
      padding: 7px 20px;
      line-height: 30px;
      color: $border-modal;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 14px;
      overflow: auto;
      border-radius: 8px;
      background-color: $attachment-custom-grey-other-2;
      // max-height: 395px;
      height: 438px;
  }

  &__container-right-custom {
      width: calc(100% - 185px);
      position: relative;
      float: left;
      padding-left: 38px;

      ::-webkit-scrollbar {
          width: 10px;
          height: 10px;
      }

      ::-webkit-scrollbar-track {
          background: $custom-border-grey !important;
      }

      ::-webkit-scrollbar-thumb {
          background: $attachment-custom-grey-other !important;
      }

      ::-webkit-scrollbar-thumb:hover {
          background: $attachment-custom-grey-other !important;
      }
  }

  &__title-document-custom {
      min-height: 40px;
      padding: 9px 15px;
      border-radius: 4px;
      background-color: $custom-border-grey;
      color: $custom-black-landing;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 20px;
      margin: 0;
  }

  &__document-container-custom {
      &.form {
          & h3 {
              color: $custom-black-landing;
          }
      }
  }
  ////////////////////////////////////////
  /////////////////////Fin Custom////////////
  //////////////////////////////////////
}

.compliance{
  &__steel-blue,
  &__uk-steel-blue{
    .legal-document {
      &__list-item {
        &.legal-document__list-item.selected {
            color: $primary-color-2;
        }

        &:hover {
            color: $primary-color-2;
        }
      }
      &__document-container{
        &.memo{
          & .legal-document__body{
            & h1, &.h1{
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

@media print{
  body {
      height: auto;
  }
  .marginal {
    border: 1px solid $docu-meme-back;
  }
  .legal-document__block-document-container{
    right: 0;
  }
  .legal-document__document-container,
  .legal-document__document-container-custom {
      width: auto;
      position: relative;
      // position: static;
      overflow: visible;
      padding: 0;
  }
  .legal-document__body--custom {
        font-family: Arial, sans-serif;
        max-height: none;
    }
}
