.scatter-plot-graph{
  &__scatter-plot-graph{
      top:-50px;
      position: relative;
  }

  &__axis-tick-text{
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 13px;
  }
  &__axis-title{
      text-transform: uppercase;
      fill: $primary-color;
      font-size: 12px;
      font-family: MTTMilano-Bold, sans-serif;
  }

  &__bar-value{
      fill: $snow-white;
      // fill: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
      display: inline-block;
      border: 1px solid $primary-color;
      &:hover{
          cursor: pointer;
      }
  }

  &__percent-value{
      fill: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
  }

  &__bar-horizontal-graph{
      margin-top: 31px;
  }

  &__box-rect{
      width: 30px;
      height: 22px;
      fill: $primary-color;
      &:hover{
          cursor: pointer;
      }
  }
  &__box-text{
      font-size: 14px;
      font-family: Arial, sans-serif;
      z-index: 10;
      font-weight: bold;
      fill : $snow-white;
      stroke-width: 0px;
      &:hover{
          cursor: pointer;
      }
  }

  &__tooltip-box{
      background-color: $snow-white;
      border: 1px solid $subcontainer-border;
      position: absolute;
      width: 280px;
      min-height: 280px;
      z-index: 10;
      box-shadow: 0px 0px 10px 2px $bar-horizontal-grey-opacity;
      visibility: hidden;
  }
  &__tooltip-block{
      margin: 0 15px 0 15px;
      display: block;
      position: relative;
  }
  &__tooltip-triangle{
      position: relative;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $snow-white;
      border-left: 10px solid transparent;
      height: 0;
      width: 0;
      z-index: 12;
      left: 118px;
      top: -20px;
  }
  &__tooltip-graph-close{
      position: absolute;
      top: 25px;
      right: 0;
      &:hover{
          cursor: pointer;
      }
  }
  &__tooltip-header-block{
      display: block;
      position: relative;
      margin-top: 2px;
      margin-bottom: 2px;
  }
  &__tooltip-graph-title{
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      margin-top: 15px;
      font-size: 18px;
      border-bottom: 1px solid $primary-color;
      padding-bottom: 3px;
      margin-bottom: 0;
      text-transform: capitalize;
  }
  &__task-list{
      margin: 0px 0 21px 0;
      padding: 0;
      color: $bar-horizontal-task-grey;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      font-weight: bold;
  }
  &__task-item{
      height: 22px;
      list-style: none;
      width: 100%;
      position: relative;
  }

  &__task-text{
      display: inline-block;
      margin: 10px 0 0 0;
  }

  &__circle-icon{
      font-size: 10px;
  }

  &__icon-total{
      font-size: 20px;
  }

  &__text-total{
      display: inline-block;
      position: relative;
      top: -4px;
  }
  &__task-text-percent{
      position: absolute;
      right: 0;
      top: 9px;
  }

  &__block-total-tasks{
      border-top: 1px solid $box-comment-border-02;
      border-bottom: 1px solid $box-comment-border-02;
      height: 50px;
      padding-top: 15px;
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      font-weight: bold;
  }
}
