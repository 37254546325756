.document-historic{
    &__window {
        & .modal-dialog {
            & .modal-content {
                height: 503px;
            }
        }
    }

    &__historic-table-body{
        display: block;
        width: 100%;
        height: 277px;
        overflow-y: auto;
        &--more-elements{
            width: calc(100% + 10px);
        }
    }

    &__historic-col{
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        &--user{
            width: 35%;
        }
        &--header-date{
            width: 25%;
            padding-top: 2px;
        }
        &--date{
            width: 25%;
        }
        &--action{
            width: 40%;
            max-width: 0;
        }
    }
    &__action-text-block{
      width: 100%;
      display: block;
      overflow: hidden;
      &--with-participants{
        float: left;
        position: relative;
        top: 4px;
        width: calc(100% - 32px);
      }
    }

    &__historic-table-icon-order{
        font-size: 9px;
    }

    &__table-eye-icon{
        padding-right: 7px;
        color: $primary-color;
        font-size: 18px;
        &:hover{
            cursor: pointer;
        }
    }

    &__box-select-list {
        border-radius: 0px;
        border: none;
        padding: 0;
        max-height: 194px;
        overflow: hidden;
        margin: 0;
        width: 168px;
        position: absolute;
        left: unset;
        right: 68px;
        top: 23px;
    }

    &__icon-close-modal {
        color: $snow-white;
        float: right;
        font-size: 20px;
        top: 4px;
        position: relative;
        &--custom{
            font-size: 25px;
            top: 0;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__icon-visible{
        font-size: 22px;
        position: relative;
        // bottom: 4px;
        margin-left: 5px;
        &:hover {
            cursor: pointer;
        }
    }

    &__text-participants{
        float: left;
        position: relative;
        top: 4px;
    }
}
.compliance{
    &__dark-blue{
        .document-historic{
            &__icon-visible{
                color: $primary-color;
            }
        }
    }
    &__steel-blue, &__uk-steel-blue{
        .document-historic{
            &__icon-close-modal {
                color: $white-special-2;

            }
            &__icon-visible{

                color: $border-hover-2;
            }
        }
    }
}
