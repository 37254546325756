@mixin evaluation-header-block-icon-box($borderColor, $textColor) {
  margin-right: 10px;
  bottom: 4px;
  border: 1px solid $borderColor;
  background-color: transparent;
  color: $textColor;
}

@mixin evaluation-header-client-title($textColor) {
  color: $textColor;
  font-family: MTTMilano, sans-serif;
  font-size: 12px;
}

@mixin evaluation-header-evaluation-title($textColor) {
  color: $textColor;
  font-family: MTTMilano-Bold, sans-serif;
  float: right;
}

@mixin evaluation-header-breadcrumb-item-new-new-version-active($textColor) {
  color: $textColor;
  &:hover{
      cursor: default;
  }
}

@mixin evaluation-header-element-route-list-last-without-concept($textColor) {
  &--last-without-concept{
      border-left: none;
      padding-top: 0;
      .evaluation-header__icon-arrow-element{
          left: -6px;
      }
      &:hover{
          cursor: default;
          background-color: transparent;
          border-bottom: none;
          font-weight: normal;
          color: $textColor;
      }
  }
}

.evaluation-header{
  display: block;
  margin-top: 20px;
  width: 100%;
  padding: 0px 40px 0 15px;
  &__alert-icon{
      font-size: 20px;
      position: absolute;
      top: 4px;
      left: 5px;
  }

  &__block-info{
      width: 438px;
      display: block;
      overflow: hidden;
      min-height: 74px;
  }

  &__block-icon-box{
      height: 30px;
      width: 30px;
      float: right;
      border-radius: 50%;
      position: relative;
      border: none;
      outline: none;
      &:hover{
          cursor: pointer;
      }

      &--blanqueo{
          top: -7px;
          margin-right: 6px;
          float: none;
      }
  }

  // &__breadcrumb-block{
  //     display: block;
  //     width: calc(100% - 448px);
  //     height: 55px;
  //     float: left;
  //     margin-top: 26px;
  //     margin-bottom: 10px;
  // }

  &__breadcrumb-concept{
      width: auto;
  }

  // &__breadcrumb-icon{
  //     color: $primary-color;
  //     font-size: 30px;
  //     margin-right: 4px;
  //     float: left;
  //     margin-top: 6px;
  // }

  &__breadcrumb-item {
      text-align: left;
      padding: 6px 11px 5px 21px;
      width: auto;
      max-width: auto;
      // visibility: hidden;
      &:first-child{
          padding: 6px 11px 5px 11px;
          border-radius: 0;
      }
      &--concept{
          position: relative;
          float: left;
          width: auto;
          padding-left: 10px;
          padding-right: 0px;
          list-style-type: none;
      }
      &--without-concept{
          &:after {
              content: " ";
              display: block;
              width: 0;
              height: 0;
              border-top: 22px solid transparent;
              border-bottom: 22px solid transparent;
              border-left: 10px solid $background-grey;
              position: absolute;
              top: 50%;
              margin-top: -22px;
              left: 100%;
              z-index: 3;
          }
          &:before {
              content: " ";
              display: block;
              width: 0;
              height: 0;
              border-top: 22px solid transparent;
              border-bottom: 22px solid transparent;
              border-left: 10px solid $subcontainer-border;
              position: absolute;
              top: 50%;
              margin-top: -22px;
              margin-left: 1px;
              left: 100%;
              z-index: 3;
          }
      }
      &--without-concept-last{
          &:hover{
              cursor: pointer !important;
          }
          &:after, &:before {
              border-top: 21px solid transparent;
              border-bottom: 21px solid transparent;
              margin-top: -21px;
          }
      }
      // &--visible{
      //     visibility: visible;
      // }
      &--active{
          &:hover{
              cursor: pointer !important;
          }
      }
  }

  &__breadcrumb-list-block{
      padding: 0px;
      margin-bottom: 12px;
      position: relative;
      float: left;
      width: calc(100% - 34px);
  }

  &__breadcrumb-list{
      padding: 0px;
      float: left;
      width: 100%;
      height: 43px;
      max-height: 43px;
      margin-bottom: 0;
      opacity: 1;
      &--no-concept{
          margin-left: 11px;
      }
      &--hidden{
          opacity: 0;
      }
  }

  &__breadcrumb-text{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      width: 100%;
      font-family: MTTMilano, sans-serif;
      padding: 6px 12px 0 8px;
      font-size: 14px;
      position: relative;
      height: 25px;
      &--concept{
          float: left;
          color: $primary-color;
          font-style: italic;
          padding: 7px 5px 0 25px;
          font-family: MTTMilano-Bold, sans-serif;
      }
      &--report{
          text-transform: capitalize;
      }
      &--with-icon{
          padding-top: 3px;
      }
  }

  &__cell-block{
      position: relative;
      height: 19px;
      text-align: right;
  }

  &__client-title-block{
      position: relative;
      height: 20px;
      float: left;
      width: 100%;
      padding-top: 4px;
  }

  &__client-title{
      clear: both;
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 18px;
      text-align: right;
      margin: 0;
  }

  &__evaluated-concept-icon{
      float: left;
      position: absolute;
      font-size: 23px;
      top: 8px;
      left: 9px;
      color: $primary-color;
  }

  &__evaluation-title{
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 22px;
      margin-top: 0px;
      margin-bottom: 0px;
      position: relative;
      height: 26px;
      text-align: right;
      &--consult{
          opacity: 0.5;
      }
  }

  &__icon-invisible{
      color: $subcontainer-ground;
      font-size: 17px;
      position: relative;
      top: 3px;
      margin-right: 5px;
  }

  &__icon-menu{
      float: right;
      margin-right: 19px;
      margin-top: 4px;
  }

  // &__menu{
  //     position: absolute;
  //     top: 9px;
  //     z-index: 4;
  //     right: 0px;
  // }

  &__progress-bar{
      height: 11px;
      width: 61%;
      float: right;
      margin-top: 8px;
      position: relative;
  }

  &__progress-bar-block{
      float: right;
      display: block;
      position: relative;
      height: 30px;

  }

  &__progress-complete{
      opacity: 0.36;
      height: 11px;
      width: 100%;
      position: absolute;
      &--status-0{
          background-color:$red-opacity-36;
      }
      &--status-1{
          background-color:$yellow-opacity-36;
      }
      &--status-2{
          background-color:$green-opacity-36;
      }
  }

  &__progress-percent{
      height: 11px;
      position: absolute;
      max-width: 100%;
      &--status-0{
          background-color:$red;
      }
      &--status-1{
          background-color:$yellow;
      }
      &--status-2{
          background-color:$green;
      }
  }

  &__progress-percent-number{
      height: 15px;
      font-family: MTTMilano, sans-serif;
      font-size: 22px;
      line-height: 16px;
      float: right;
      margin-right: 9px;
      margin-bottom: 0;
      margin-left: 8px;
      padding-top: 6px;
      &--status-0{
          color:$red;
      }
      &--status-1{
          color:$yellow;
      }
      &--status-2{
          color:$green;
      }
  }
  &__progress-percent-text{
      text-transform: none;
      font-size: 11px;
  }
  &__show-route{
      position: relative;
      float: left;
      left: 10px;
      top: 10px;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      color: $primary-color;
      text-transform: uppercase;
      line-height: 10px;
      &:hover{
          cursor: pointer;
      }
      &--concept{
          left: 0;
      }
  }
  &__double-arrow-route{
      font-size: 15px;
      position: relative;
      top: 2px;
      left: 2px;
  }
  &__block-route{
      position: relative;
      float: left;
      width: 100%;
      top: 21px;
      left: 10px;
      &--concept{
          left: 0;
      }
  }

  &__modal-route{
      position: relative;
      width: 342px;
      border: 1px solid $concept-from-grey;
      background-color: $snow-white;
      box-shadow: 0 0 5px 1px $certify-black-opacity02;
      z-index: 10;
      // padding-top: 16px;
  }

  &__route-list{
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
  }
  &__block-list-route{
      max-height: 330px;
      padding-bottom: 5px;
      position: relative;
      overflow-y: auto;
      margin-bottom: 5px;
  }
  &__element-route-list{
      margin-left: 42px;
      border-left: 1px solid $evaluation-tree-children;
      width: calc(100% - 42px);
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-size: 11px;
      position: relative;
      padding: 5px 0 5px 12px;
      min-height: 24px;
      &:hover{
          background-color: $background-grey;
          color: $primary-color;
          font-weight: bold;
          border-bottom: 1px solid $evaluation-tree-children;
          padding-bottom: 4px;
          cursor: pointer;
          .evaluation-header__icon-directions {
              display: inline;
          }
          .evaluation-header__icon-arrow-element{
              color: $primary-color;
          }
      }
      // &--last{

      // }
      &--first{
          padding-top: 0;
      }
  }

  &__concept-route-list{
      margin-left: 42px;
      margin-top: 5px;
      color: $secondary-color;
      font-family: Arial, sans-serif;
      font-weight: bold;
      font-size: 11px;
      position: relative;
      padding-left: 12px;
  }

  &__icon-arrow-element{
      position: absolute;
      font-size: 12px;
      left: -7px;
      top: 5px;
      color: $pillow-group;
      background-color: $snow-white;
      &--first, &--last{
          top: 0;
      }
  }

  &__icon-directions {
      float: right;
      font-size: 20px;
      position: absolute;
      bottom: 3px;
      right: 10px;
      color: $primary-color;
      display: none;
  }

  &__icon-map-marker-route{
      position: absolute;
      color: $custom-black-landing;
      font-size: 15px;
      left: -7px;
      top: 0;
  }

  &__name-element-route{
      max-width: calc(100% - 42px);
      display: block;
      &--first{
          top: -5px;
          position: relative;
      }
  }

  ////////////////////////
  ///////New Version////////
  ////////////////////////
  &__block-new-version{
      display: block;
      width: 100%;
      // height: 100px;
      min-height: 100px;
      margin-bottom: 20px;
      padding: 20px 49px 0 59px;
      &--blanqueo{
          padding-top: 12px;
          min-height: 100px;
      }
      &--consulta{
          min-height: 80px;
      }
  }

  &__block-container-new-version{
      position: relative;
      float: left;
      width: 100%;
      border-bottom: 1px solid $third-color-2;
  }

  &__block-info-new-version{
      width: 100%;
      display: block;
  }

  &__client-title-block-new-version{
      position: relative;
      height: 11px;
      float: left;
      width: 100%;
  }

  &__breadcrumb-block-new-version{
      display: block;
      width: 100%;
      // height: 33px;
      min-height: 33px;
      position: relative;
      float: left;
  }

  &__breadcrumb-list-block-new-version{
      padding: 0px;
      position: relative;
      float: left;
      width: calc(100% - 20px);
      // height: 16px;
      min-height: 16px;
      top: 7px;
  }

  &__breadcrumb-list-new-version{
      padding: 0px;
      float: left;
      width: 100%;
      // height: 16px;
      min-height: 16px;
      // max-height: 16px;
      margin-bottom: 0;
  }

  &__breadcrumb-item-new-new-version{
      position: relative;
      float: left;
      list-style: none;
      &:hover{
          cursor: pointer;
      }
  }

  &__breadcrumb-icon-new-version{
      font-size: 20px;
      float: left;
  }

  &__breadcrumb-text-new-version{
      display: inline-block;
      width: 100%;
      font-family: MTTMilano, sans-serif;
      padding: 0px 20px 0 10px;
      font-size: 13px;
      position: relative;
      min-height: 17px;
      &--with-icon{
          padding-left: 5px;
      }
      &--last{
          padding-right: 30px;
      }
  }

  &__breadcrumb-text-span{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      min-height: 17px;
      position: relative;
      float: left;
      width: 100%;
      &--one-icon{
          width: calc(100% - 23px);
      }
      &--two-icon{
          width: calc(100% - 45px);
      }
  }

  &__icon-angle-right-new-version{
      position: absolute;
      font-size: 20px;
      right: -5px;
      bottom: 6px;
      &:hover{
          cursor: default;
      }
  }

  &__dropdown-menu-new-version{
      position: absolute;
      right: 0;
      z-index: 4;
      bottom: 7px;
      width: 25px;
  }

  &__block-show-route{
      position: relative;
      float: left;
      width: 100%;
  }

  &__show-route-new-version{
      float: left;
      z-index: 2;
      position: absolute;
      top: 1px;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      color: $primary-color;
      text-transform: uppercase;
      line-height: 10px;
      &:hover{
          cursor: pointer;
      }
  }

  &__block-route-new-version{
      position: absolute;
      float: left;
      width: 100%;
      top: 19px;
  }

  &__icon-invisible-new-version{
      color: $subcontainer-ground;
      font-size: 17px;
      position: relative;
      float: left;
      margin-right: 5px;
  }
  ////////////////////////
  ////Fin New Version////
  //////////////////////

  &__block-info-blanqueo{
    width: 100%;
    display: block;
    margin-bottom: 1px;
  }

  &__block-left-blanqueo{
    display: inline-block;
    width: calc(65% - 37px);
    vertical-align: top;
    position: relative;
    &--locked{
        padding-left: 41px;
    }
  }

  &__block-right-blanqueo{
    display: inline-block;
    width: calc(35% + 33px);
  }

  &__button-blanqueo{
    background-color: $red;
    color: $snow-white;
    font-family: MTTMilano-Bold, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    height: 30px;
    padding: 5px 0px;
    border-radius: 20px;
    outline: none;
    margin-right: 5px;
    position: relative;
    float: left;
    width: 145px;
    text-align: center;
    &--result{
        width: 125px;
    }
  }

  &__icon-button-blanqueo{
    font-size: 20px;
    margin-right: 5px;
  }

  &__text-button-blanqueo{
    position: relative;
    bottom: 4px;
  }

  &__result-block-blanqueo{
    border: 1px solid $red;
    background-color: transparent;
    color: $subcontainer-ground;
    font-family: MTTMilano, sans-serif;
    font-size: 13px;
    border-radius: 20px;
    display: inline-block;
    padding-right: 15px;
    max-width: 100%;
    &--locked{
        max-width: calc(100% - 46px);
    }
    &--unadmitted{
        max-width: calc(100% - 150px);
    }
  }

  &__block-text-blanqueo{
    width: auto;
    max-width: calc(100% - 130px);
    position: relative;
    float: left;
    top: 7px;
    height: 17px;
  }

  &__result-text-blanqueo{
    width: calc(100% + 5px);
    margin: 0;
  }

  &__block-icon-alert{
    position: relative;
    float: left;
    width: 36px;
    margin-right: 10px;
  }

  &__icon-alert{
    color: $snow-white;
    background-color: $red;
    font-size: 20px;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    display: inline-block;
    padding: 6px 8px;
    top: -2px;
    left: 0;
    position: relative;
  }

  &__recurrent-tag{
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
    color: $pillow-group;
    border: 1px solid $pillow-group;
    display: inline-block;
    line-height: 7px;
    padding: 5px 8px 5px;
    position: relative;
    bottom: 2px;
    margin-right: 3px;
  }
  &__block-confident{
    position: relative;
    float: left;
    margin-right: 5px;
    width: 18px;
  }
}

.compliance{
  &__dark-blue{
    .evaluation-header{
      &__block-icon-box{
        background-color: $red-opacity-15;
        color: $red;
      }
      &__evaluation-title{
        height: 26px;
        text-transform: initial;
        &--blanqueo{
            line-height: 30px;
            height: 30px;
        }
      }
      &__progress-bar{
        width: 280px;
      }
      &__element-route-list{
        &:hover{
            background-color: $background-color-states;
        }
        @include evaluation-header-element-route-list-last-without-concept($secondary-color);
      }
      &__block-new-version{
        padding: 16px 49px 0 59px;
        min-height: 119px;
        &--concept{
          padding-left: 49px;
        }
        &--blanqueo{
            padding-top: 12px;
            min-height: 100px;
        }
        &--consulta{
            min-height: 80px;
        }
      }
      &__block-container-new-version{
        border-color: $result-search-quote-color;
      }
      &__client-title-block-new-version{
        height: 15px;
      }
      &__breadcrumb-item-new-new-version{
        color: $primary-color;
        &--active{
            @include evaluation-header-element-route-list-last-without-concept($secondary-color);
        }
        &--hidden {
          color: $subcontainer-ground;
        }
      }
      &__block-client{
            height: 15px;
            padding-top: 0;
      }
      &__breadcrumb-icon-new-version{
        color: $primary-color;
      }
    }
  }

  &__steel-blue{
    .evaluation-header{
      &__block-icon-box{
        @include evaluation-header-block-icon-box($alert-color-button-2, $alert-color-2);
      }
      &__client-title{
        @include evaluation-header-client-title($primary-color-2);
      }
      &__evaluation-title{
        @include evaluation-header-evaluation-title($primary-color-2);
        &--blanqueo{
            line-height: 30px;
            height: 30px;
        }
      }
      &__block-container-new-version{
        border-color: $third-color-2;
      }
      &__breadcrumb-item-new-new-version{
        color: $border-bottom-2;
        &--active{
            @include evaluation-header-breadcrumb-item-new-new-version-active($primary-color-2);
        }
        &--hidden{
            color: $grey-icons-2;
        }
      }
      &__breadcrumb-icon-new-version{
        color: $primary-color-2;
      }
      &__icon-angle-right-new-version{
        color: $primary-color-2;
      }
    }
  }
  &__uk-steel-blue{
    .evaluation-header{
      &__block-icon-box{
        @include evaluation-header-block-icon-box($alert-color-button-2, $alert-color-2);
      }
      &__client-title{
        @include evaluation-header-client-title($primary-color-2);
      }
      &__evaluation-title{
        @include evaluation-header-evaluation-title($primary-color-2);
      }
      &__block-new-version{
        min-height: 90px;
        margin: 0;
        &--consulta{
            min-height: 80px;
        }
      }
      &__block-container-new-version{
        border: none;
      }
      &__breadcrumb-item-new-new-version{
        color: $border-bottom-2;
        &--active{
            @include evaluation-header-breadcrumb-item-new-new-version-active($primary-color-2);
        }
        &--hidden{
            color: $grey-icons-2;
        }
      }
      &__breadcrumb-icon-new-version{
        color: $primary-color-2;
      }
      &__icon-angle-right-new-version{
        color: $primary-color-2;
      }
    }
  }
  &__icam-red{
    .evaluation-header{
      &__block-icon-box{
        @include evaluation-header-block-icon-box($red-task-50-icam, $alert-color-icam);
      }
      &__client-title{
        @include evaluation-header-client-title($custom-black-landing);
      }
      &__evaluation-title{
        @include evaluation-header-evaluation-title($custom-black-landing);
      }
      &__block-container-new-version{
        border-color: $third-color-icam;
      }
      &__breadcrumb-item-new-new-version{
        color: $primary-color-icam;
        &--active{
            @include evaluation-header-breadcrumb-item-new-new-version-active($title-text-icam);
        }
      }
      &__breadcrumb-icon-new-version{
        color: $primary-color-icam;
      }
    }
  }
  &__gm-law{
    .evaluation-header{
      &__block-icon-box{
        border: 1px solid $red-task-50-gm;
        background-color: transparent;
        color: $alert-color-gm;
      }
      &__client-title{
        @include evaluation-header-client-title($custom-black-landing);
      }
      &__evaluation-title{
        @include evaluation-header-evaluation-title($custom-black-landing);
        @include evaluation-header-evaluation-title($custom-black-landing);
        position: relative;
        top: 8px;
      }
      &__progress-bar{
        height: 6px;
        width: 280px;
        margin-top: 13px;
        margin-left: 20px;
      }
      &__progress-bar-block{
        overflow: hidden;
        padding: 0;
        clear: both;
      }
      &__progress-complete{
        height: 5px;
        background-color: $border-block-gm;
      }
      &__progress-percent{
        height: 5px;
        border-radius: 10px;
        background-color: $third-color-gm;
      }
      &__progress-percent-number{
        height: 30px;
        width: 120px;
        border: 1px solid $third-color-gm;
        border-radius: 15px;
        color: $custom-black-landing;
        font-family: 'MTTMILANO-BOLD', sans-serif;
        font-size: 16px;
        text-align: center;
      }
      &__block-new-version{
        min-height: 132px;
        &--consulta{
            min-height: 80px;
        }
      }
      &__block-container-new-version{
        border-color: $third-color-gm;
      }
      &__breadcrumb-item-new-new-version{
        color: $primary-color-gm;
        &--active{
            @include evaluation-header-breadcrumb-item-new-new-version-active($title-text-gm);
        }
      }
      &__breadcrumb-icon-new-version{
        color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    .evaluation-header{
      &__block-icon-box{
        border: 1px solid $red-task-50-com-de;
        background-color: transparent;
        color: $alert-color-com-de;
      }
      &__client-title{
        @include evaluation-header-client-title($custom-black-landing);
      }
      &__evaluation-title{
        @include evaluation-header-evaluation-title($custom-black-landing);
        @include evaluation-header-evaluation-title($custom-black-landing);
        position: relative;
        top: 8px;
      }
      &__progress-bar{
        height: 6px;
        width: 280px;
        margin-top: 13px;
        margin-left: 20px;
      }
      &__progress-bar-block{
        overflow: hidden;
        padding: 0;
        clear: both;
      }
      &__progress-complete{
        height: 5px;
        background-color: $border-block-com-de;
      }
      &__progress-percent{
        height: 5px;
        border-radius: 10px;
        background-color: $third-color-com-de;
      }
      &__progress-percent-number{
        height: 30px;
        width: 120px;
        border: 1px solid $third-color-com-de;
        border-radius: 15px;
        color: $custom-black-landing;
        font-family: 'MTTMILANO-BOLD', sans-serif;
        font-size: 16px;
        text-align: center;
      }
      &__block-new-version{
        min-height: 132px;
        &--consulta{
            min-height: 80px;
        }
      }
      &__block-container-new-version{
        border-color: $third-color-com-de;
      }
      &__breadcrumb-item-new-new-version{
        color: $primary-color-com-de;
        &--active{
            @include evaluation-header-breadcrumb-item-new-new-version-active($title-text-com-de);
        }
      }
      &__breadcrumb-icon-new-version{
        color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .evaluation-header{
      &__block-icon-box{
        border: 1px solid $red-task-50-com-fr;
        background-color: transparent;
        color: $alert-color-com-fr;
      }
      &__client-title{
        @include evaluation-header-client-title($custom-black-landing);
      }
      &__evaluation-title{
        @include evaluation-header-evaluation-title($custom-black-landing);
        @include evaluation-header-evaluation-title($custom-black-landing);
        position: relative;
        top: 8px;
      }
      &__progress-bar{
        height: 6px;
        width: 280px;
        margin-top: 13px;
        margin-left: 20px;
      }
      &__progress-bar-block{
        overflow: hidden;
        padding: 0;
        clear: both;
      }
      &__progress-complete{
        height: 5px;
        background-color: $border-block-com-fr;
      }
      &__progress-percent{
        height: 5px;
        border-radius: 10px;
        background-color: $third-color-com-fr;
      }
      &__progress-percent-number{
        height: 30px;
        width: 120px;
        border: 1px solid $third-color-com-fr;
        border-radius: 15px;
        color: $custom-black-landing;
        font-family: 'MTTMILANO-BOLD', sans-serif;
        font-size: 16px;
        text-align: center;
      }
      &__block-new-version{
        min-height: 132px;
        &--consulta{
            min-height: 80px;
        }
      }
      &__block-container-new-version{
        border-color: $third-color-com-fr;
      }
      &__breadcrumb-item-new-new-version{
        color: $primary-color-com-fr;
        &--active{
            @include evaluation-header-breadcrumb-item-new-new-version-active($title-text-com-fr);
        }
      }
      &__breadcrumb-icon-new-version{
        color: $primary-color-com-fr;
      }
    }
  }
}
