@mixin protection-data-modal-block-translation-option($backgroundColor, $color){
  background-color: $backgroundColor;
  color: $color;
}

@mixin protection-data-modal-block-translation-option-selected($backgroundColor, $color){
  background-color: $backgroundColor;
  color: $color;
  z-index: 1;
  &:hover{
      cursor: default;
  }
}

.protection-data-modal{
  & .modal-content {
      height: 623px;
  }

  &__body-form{
      padding: 30px 70px 0;
  }

  &__block-fields{
      margin-top: 45px;
      &--no-top{
        margin-top: 0px;
      }
  }

  &__block-translations{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      height: 35px;
      border-bottom: 5px solid $primary-color;
  }

  &__block-translation-option{
      position: relative;
      height: 30px;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 11px;
      text-transform: uppercase;
      line-height: 16px;
      padding: 13px 1px 8px 8px;
      border: none;
      outline: none;
      text-align: left;
      margin-right: 10px;
  }

  &__triangle-block {
      width: 21px;
      height: 30px;
      position: absolute;
      top: 0px;
      right: -21px;
  }

  &__select-language-button{
      border: none;
      background: none;
      outline: none;
      margin-left: 20px;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      font-weight: normal;
      color: $primary-color;
      height: 30px;
      padding: 0;
  }

  &__icon-translate{
      font-size: 20px;
      position: relative;
      top: 5px;
      left: 4px;
  }

  &__menu-select-languages{
      width: 0px;
      padding: 0;
      margin: 0;
      border: 0px;
      inset: initial !important;
      left: 58px !important;
      top: 4px !important;
      transform: none !important;
  }

  &__icon-close-language{
      position: absolute;
      font-size: 8px;
      right: 2px;
      top: 5px;
      &:hover{
          cursor: pointer;
      }
  }
}

.compliance{
  &__dark-blue{
    .protection-data-modal {
      &__block-translations--error {
        border-color: $red;
      }

      &__block-translation-option {
        @include protection-data-modal-block-translation-option($background-grey-other, $pillow-group);

        &--selected {
          @include protection-data-modal-block-translation-option-selected($primary-color, $snow-white);
        }

        &--error {
          @include protection-data-modal-block-translation-option($background-error, $red);
        }

        &--selected-error {
          @include protection-data-modal-block-translation-option-selected($red, $snow-white);
        }
      }

      &__triangle-block {
        fill: $background-grey-other;

        &--selected {
          fill: $primary-color;
        }

        &--error {
          fill: $background-error;
        }

        &--selected-error {
          fill: $red;
        }
      }
    }
  }

  &__steel-blue{
    .protection-data-modal {
      &__block-translations {
        border-color: $third-color-2;
        &--error {
          border-color: $alert-color-2;
        }
      }

      &__block-translation-option {
        @include protection-data-modal-block-translation-option($background-grey-disabled-2, $grey-icons-2);

        &--selected {
          @include protection-data-modal-block-translation-option-selected($third-color-2, $primary-color-2);
        }

        &--error {
          @include protection-data-modal-block-translation-option($background-error-2, $alert-color-2);
        }

        &--selected-error {
          @include protection-data-modal-block-translation-option-selected($alert-color-2, $white-special-2);
        }
      }

      &__triangle-block {
        fill: $background-grey-disabled-2;

        &--selected {
          fill: $third-color-2;
        }

        &--error {
          fill: $background-error-2;
        }

        &--selected-error {
          fill: $alert-color-2;
        }
      }

      &__select-language-button {
        color: $border-hover-2;
      }
    }
  }

  &__uk-steel-blue{
    .protection-data-modal {
      &__block-translations {
        border-color: $third-color-2;
        &--error {
          border-color: $alert-color-2;
        }
      }

      &__block-translation-option {
        @include protection-data-modal-block-translation-option($background-grey-disabled-2, $grey-icons-2);

        &--selected {
          @include protection-data-modal-block-translation-option-selected($third-color-2, $primary-color-2);
        }

        &--error {
          @include protection-data-modal-block-translation-option($background-error-2, $alert-color-2);
        }

        &--selected-error {
          @include protection-data-modal-block-translation-option-selected($alert-color-2, $white-special-2);
        }
      }

      &__triangle-block {
        fill: $background-grey-disabled-2;

        &--selected {
          fill: $third-color-2;
        }

        &--error {
          fill: $background-error-2;
        }

        &--selected-error {
          fill: $alert-color-2;
        }
      }

      &__select-language-button {
        color: $border-hover-2;
      }
    }
  }

  &__icam-red{
    .protection-data-modal {
      &__block-translations{
        border-color: $primary-color-icam;
        &--error {
          border-color: $alert-color-icam;
        }
      }
     

      &__block-translation-option {
        @include protection-data-modal-block-translation-option($primary-color-icam, $title-text-icam);

        &--selected {
          @include protection-data-modal-block-translation-option-selected($border-block-icam, $snow-white);
        }

        &--error {
          @include protection-data-modal-block-translation-option($red-task-20-icam, $alert-color-icam);
        }

        &--selected-error {
          @include protection-data-modal-block-translation-option-selected($alert-color-icam, $snow-white);
        }
      }

      &__triangle-block {
        fill: $primary-color-icam;

        &--selected {
          fill: $border-block-icam;
        }

        &--error {
          fill: $red-task-20-icam;
        }

        &--selected-error {
          fill: $alert-color-icam;
        }
      }
    }
  }

  &__gm-law{
    .protection-data-modal {
      &__block-translations--error {
        border-color: $alert-color-gm;
      }

      &__block-translation-option {
        @include protection-data-modal-block-translation-option($border-block-gm, $snow-white);

        &--selected {
          @include protection-data-modal-block-translation-option-selected($primary-color-gm, $title-text-gm);

        }

        &--error {
          @include protection-data-modal-block-translation-option($red-task-20-gm, $alert-color-gm);
        }

        &--selected-error {
          @include protection-data-modal-block-translation-option-selected($alert-color-gm, $snow-white);
        }
      }

      &__triangle-block {
        fill: $border-block-gm;

        &--selected {
          fill: $primary-color-gm;
        }

        &--error {
          fill: $red-task-20-gm;
        }

        &--selected-error {
          fill: $alert-color-gm;
        }
      }
    }
  }
  &__com-de{
    .protection-data-modal {
      &__block-translations--error {
        border-color: $alert-color-com-de;
      }

      &__block-translation-option {
        @include protection-data-modal-block-translation-option($border-block-com-de, $snow-white);

        &--selected {
          @include protection-data-modal-block-translation-option-selected($primary-color-com-de, $title-text-com-de);

        }

        &--error {
          @include protection-data-modal-block-translation-option($red-task-20-com-de, $alert-color-com-de);
        }

        &--selected-error {
          @include protection-data-modal-block-translation-option-selected($alert-color-com-de, $snow-white);
        }
      }

      &__triangle-block {
        fill: $border-block-com-de;

        &--selected {
          fill: $primary-color-com-de;
        }

        &--error {
          fill: $red-task-20-com-de;
        }

        &--selected-error {
          fill: $alert-color-com-de;
        }
      }
    }
  }
  &__com-fr{
    .protection-data-modal {
      &__block-translations--error {
        border-color: $alert-color-com-fr;
      }

      &__block-translation-option {
        @include protection-data-modal-block-translation-option($border-block-com-fr, $snow-white);

        &--selected {
          @include protection-data-modal-block-translation-option-selected($primary-color-com-fr, $title-text-com-fr);

        }

        &--error {
          @include protection-data-modal-block-translation-option($red-task-20-com-fr, $alert-color-com-fr);
        }

        &--selected-error {
          @include protection-data-modal-block-translation-option-selected($alert-color-com-fr, $snow-white);
        }
      }

      &__triangle-block {
        fill: $border-block-com-fr;

        &--selected {
          fill: $primary-color-com-fr;
        }

        &--error {
          fill: $red-task-20-com-fr;
        }

        &--selected-error {
          fill: $alert-color-com-fr;
        }
      }
    }
  }
}
