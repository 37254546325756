@mixin front-login-input-block-icon($backgroundColor, $textColor) {
  background-color: $backgroundColor;
  color: $textColor;
}

@mixin front-login-input-block-text($borderColor, $textColor, $textPlaceholderColor) {
  border-color: $borderColor;
  color: $textColor;
  &::-webkit-input-placeholder {
      color: $textPlaceholderColor;
  }
}
@mixin front-login-input-block-text-focus($borderColor, $backgroundColor) {
  &:focus {
      border-color: $borderColor;
      background-color: $backgroundColor;
  }
}

@mixin front-login-login-button($primaryColor, $hoverTextColor) {
  border: 3px solid $primaryColor;
  background-color: transparent;
  color: $primaryColor;
  font-family: MTTMilano-Bold, sans-serif;
  &:hover{
      background-color: $primaryColor;
      color: $hoverTextColor;
  }
}

@mixin front-login-remember-pass($primaryColor) {
  color: $primaryColor;
  font-size: 14px;
  text-decoration: underline;
  &:hover {
      color: $primaryColor;
      text-decoration: underline;
  }
}

@mixin front-login-information-contact-email($primaryColor) {
  color: $primaryColor;
  &:hover, &:focus, &:visited {
      color: $primaryColor;
  }
}

.front-login {
  // display: table;
  // height: 937px;
  overflow: auto;
  width: 100%;
  min-height: 100%;
  position: relative;
  padding-bottom: 200px;
  & .no-permissions {
      padding: 0 49px;
  }
  &__container {
      margin: 48px 0 20px;
      display: flex;
      justify-content: center;
  }

  &__header {
      width: 100%;
      display: block;
  }

  &__header-steel-blue{
      position: relative;
      height: 95px;
      border-bottom: 1px solid $secondary-color-2;
      padding: 23px 248px;
  }

  &__go-home {
      margin-top: 18px;
      float: left;
      margin-left: 254px;
  }

  &__go-shop {
      font-family: MTTMilano, sans-serif;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $snow-white;
      position: absolute;
      right: 254px;
      top: 13px;
      &:hover, &:focus, &:visited, &:active {
          text-decoration: none;
          color: $snow-white;
      }
  }

  &__icon-color {
      color: $primary-color;
  }

  &__go-shop-icon {
      font-size: 27px;
      position: relative;
      top: 5px;
      padding-right: 11px;
  }

  &__text-header {
      color: $secondary-color-gm;
      margin-top: 9px;
      font-size: 9px;
      font-family: "MTTMilano-Medium", sans-serif;
  }

  &__products-block {
      display: block;
      overflow: hidden;
      width: 100%;
      height: 100%;
  }

  &__login-block {
      // margin: 48px auto 49px;
      display: block;
      width: 450px;
      min-height: 501px;  //JRSJ 21/07/2020
      border: 1px solid $pillow-group;
      background-color: $snow-white;

      &--landing {
          min-height: 461px;
      }

      &--info {
          min-height: 420px;
      }

  }

  &__login-block-img {
      display: block;
      margin: 28px 0;
      text-align: center;

      &--with-error {
          margin-bottom: 7px;
      }

      &--reset-pass {
          margin-bottom: 20px;
      }

      &--unexpected-error {
          margin-bottom: 24px;
      }
      &--custom{
          height: 50px;
          width: auto;
          margin:  0 auto 39px;
      }
      &--custom-no-public{
          margin-bottom: 0px;
      }
  }

  &__logo-img{
      height: 60px;
  }

  &__forgot-password {
      height: 266px;
  }

  &__title-restricted {
      font-family: MTTMilano, sans-serif;
      font-size: 24px;
      color: $primary-color;
      text-align: center;
      &--custom{
          color: $custom-black-landing;
          margin-bottom: 34px;
      }
      &--custom-no-public{
          margin-bottom: 13px;
          margin-top: 32px;
      }
  }

  &__login-form {
      display: block;
      width: 100%;
      padding: 0 48px 0;
  }

  &__block-input-with-error{
      position: relative;
  }

  &__input-block {
      display: block;
      width: 100%;
      height: 45px;
      position: relative;
      margin-top: 15px;
      &--custom{
          height: 50px;
          width: 359px;
          border: 1px solid $custom-border-grey;
          background-color: $snow-white;
          box-shadow: 0 1px 4px 0 $modal-help-box;
      }
  }

  &__input-block-icon {
      height: 45px;
      width: 13%;
      background-color: $background-grey-other;
      font-size: 21px;
      color: $primary-color;
      padding: 10px 0 0 0;
      text-align: center;
      float: left;
      position: relative;
      &--custom{
          height: 48px;
          background-color: $custom-border-grey;
          color: $custom-black-landing;
          width: 48px;
          font-size: 29px;
          padding-top: 7px;
      }
  }

  &__input-block-text {
      float: left;
      height: 45px;
      width: 87%;
      padding-top: 0px;
      padding-left: 12px;
      color: $modal-info-hours;
      font-family: MTTMilano, sans-serif;
      font-size: 16px;
      font-weight: 500;
      border: 1px solid $front-login-input;
      outline: none;
      &:-webkit-autofill {
          box-shadow: inset 0 0 0 100px $snow-white;
      }

      &::placeholder {
          color: $modal-info-hours;
          font-size: 15px;
          font-family: MTTMilano, sans-serif;
          font-style: normal;
          padding: 0;
      }

      &--custom{
          height: 48px;
          border-color: transparent;
          width: calc(100% - 48px);
          &::-webkit-input-placeholder{
              padding: 0px;
          }
      }
  }

  &__block-info-reset-pass{
      text-align: center;
      min-height: 164px;
  }

  &__input-icon {
      font-size: 34px;
      display: block;
  }

  &__unexpected-text {
      font-size: 24px;
      font-weight: 500;
      font-family: MTTMilano, sans-serif;
      color: $primary-color;
      text-align: center;
      margin-top: 33px;
      margin-bottom: 31px;
      line-height: 25px;
  }

  &__unexpected-message {
      font-size: 16px;
      font-family: MTTMilano, sans-serif;
      font-weight: 500;
      text-align: left;
      color: $front-reset-pass;
      line-height: 19px;
      padding: 0px 46px;
      margin-bottom: 0;
  }

  &__input-error-text {
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      border: 0;
      font-weight: 600;
      background-color: transparent;
      color: $reset-text-color;
      margin-left: 1px;
      margin-top: -5px;

      &--error-all {
          padding-left: 47px;
          margin-top: 36px;
          margin-bottom: 0px;
      }
  }

  &__input-error-icon {
      color: $reset-text-color;
      position: absolute;
      margin-left: -24px;
      top: 14px;
      font-size: 16px;
  }

  &__remember-block {
      width: 100%;
      display: block;
      overflow: hidden;
      margin-top: 56px;
      margin-bottom: 0px;
  }

  &__remember-check-box {
      height: 20px;
      width: 20px;
      border: 1px solid $primary-color;
      background-color: $snow-white;
      position: relative;
      display: inline-block;
      cursor: pointer;
      margin-bottom: 0;
  }

  &__checkbox-hidden {
      visibility: hidden;
  }

  &__checkbox-icon {
      position: absolute;
      top: 1px;
      left: 1px;
      font-weight: bold;
      font-size: 16px;
      color: $primary-color;
  }

  &__remember-text {
      display: inline-block;
      position: relative;
      margin: 0;
      color: $front-reset-pass;
      font-family: MTTMilano, sans-serif;
      font-weight: 500;
      font-size: 13px;
      padding-left: 9px;
  }

  &__remember-pass {
      font-family: MTTMilano-Bold, sans-serif;
      float: right;
      color: $primary-color;
      font-size: 16px;
      cursor: pointer;

      &:hover {
          color: $primary-color;
          text-decoration: none;
      }

  }

  &__login-button {
      height: 42px;
      width: 100%;
      border: none;
      text-transform: uppercase;
      font-family: MTTMilano, sans-serif;
      font-size: 13px;
      letter-spacing: 1px;
      margin-top: 23px;
      outline: none;
      &--continue-generate{
          margin-top: 40px;
      }
      &--continue-reset{
          margin-top: 0;
      }
      &--landing-login{
          margin-top: 20px;
      }
  }

  &__text {
      margin-bottom: -10px;
      color: $front-reset-pass;
      font-family: MTTMilano, sans-serif;
      &--custom{
          color:$custom-black-landing;
      }
  }

  &__message {
      font-size: 18px;
      color: $concept-blue;
      font-family: MTTMilano, sans-serif;
      width: calc(100% - 60px);
      margin: 20px 30px 0;
      &--with-button{
          margin-top: 7px;
          margin-bottom: 22px;
      }
      &--external-login{
          margin-top: 17px;
          margin-bottom: 10px;
      }
  }

  &__first-text {
      margin: 0;
  }

  &__information-block {
      height: 95px;
      width: 100%;
      background-color: $background-grey-other;
      margin-top: 45px;
      text-align: center;
      padding: 20px 26px 26px;
      &--reset-pass {
          margin-top: 69px;
      }
      &--login-uk {
          margin-top: 42px;
      }
      &--generate-pass{
          margin-top: 39px;
      }
      &--error-aspx{
          margin-top: 81px;
      }
      &--reset-error {
          margin-top: 70px;
      }
  }

  &__information-question {
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-weight: normal;
      margin: 0 0 4px;
      font-size: 21px;

      &--custom{
          color: $custom-black-landing;
          font-size: 24px;
          text-align: center;
      }
  }

  &__information-contact {
      color: $concept-blue;
      font-family: MTTMilano, sans-serif;
      font-weight: normal;
      font-size: 18px;

      &--custom{
          color: $custom-black-landing;
          font-size: 16px;
          text-align: center;
          padding-top: 3px;
      }
  }

  &__information-contact-email {
      position: relative;
      color: $primary-color;
      &:hover, &:focus, &:visited {
          cursor: pointer;
          color: $primary-color;
          text-decoration: none;
      }
  }

  &__links-block {
      position: absolute;
      top: 785px;
      height: 28px;
      width: 484px;
      line-height: 0;
      text-align: center;
      margin: 67px auto 0;
      font-family: MTTMilano, sans-serif;
  }

  &__link {
      color: $primary-color;
  }

  &__link-icon {
      font-size: 6px;
      color: $primary-color;
      position: relative;
      bottom: 2px;
  }

  &__header-login {
      position: relative;
      display: block;
      margin: 0 auto;
      width: 100%;
  }

  &__reset-pass-title {
      color: $primary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 26px;
      font-weight: 500;
      text-align: center;
      margin: 0px 0px 30px;
      &--generate-password{
          margin-bottom: 12px;
          line-height: 24px;
      }
  }

  &__reset-pass-info {
      color: $front-reset-pass;
      font-family: MTTMilano, sans-serif;
      font-size: 16px;
      font-weight: 500;
      margin: 20px 0;
      line-height: 17px;
      min-height: 34px;
      &--reset {
          margin-top: 8px;
          margin-bottom: -10px;
      }
  }

  &__user-error {
      color: $reset-text-color;
      font-size: 12px;
      margin-top: 11px;
      position: absolute;
  }

  &__send-pasword-ok {
      height: 291px;
      padding-top: 28px;
  }

  &__check {
      font-size: 34px;
      color: $advice-confirm;
      margin-left: 46%;
  }

  &__review-email {
      color: $primary-color;
      font-size: 24px;
      font-family: MTTMilano, sans-serif;
      text-align: center;
      font-weight: 500;
      padding-top: 32px;
  }

  &__message-email {
      font-family: MTTMilano, sans-serif;
      font-size: 17px;
      font-weight: 500;
      line-height: 19px;
      width: 400px;
      margin-left: 35px;
      margin-right: 35px;
      margin-top: 52px;
  }

  &__contact-login {
      display: block;
      width: 100%;
      padding: 0 48px 0;
      margin-bottom: 30px;
      position: relative;
      margin-top: 51px;
      &--custom{
          margin-top: 10px;
      }
      &--custom-no-public{
          margin-bottom: 31px;
      }
  }

  &__error-message {
      text-align: center;
      color: $primary-color;
      margin-top: 60px;
      &--error-login{
          margin-top:0;
      }
      &--external-login{
          margin-top: 35px;
      }

      &--custom{
          color: $custom-black-landing;
          margin-top: 35px;
      }
  }

  &__try-again-button{
      color: $snow-white;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 13px;
      text-align: center;
      width: calc(100% - 96px);
      height: 42px;
      border: none;
      outline: none;
      background: $primary-color;
  }
  &__error-message-icon{
      font-size: 141px;
  }

  &__link-external-web{
      text-decoration: underline;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 14px;
      &:hover{
          cursor: pointer;
      }
  }
  &__no-customer-block{
      margin-top: 23px;
      text-align: center;
      font-family: MTTMilano, sans-serif;
      font-size: 16px;
      color: $primary-color-2;
  }
  &__no-customer-request-info{
      color: $border-hover-2;
      text-decoration: underline;
      &:hover{
          cursor: pointer;
          color: $border-hover-2;
      }
  }
  &__enter-new-password{
      color: $primary-color-2;
      font-family: MTTMilano, sans-serif;
      font-size: 16px;
      line-height: 12px;
      margin: 0;
      &--hidden{
          visibility: hidden;
      }
  }
  &__text-no-security{
      color: $red-error-uk;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      font-weight: 500;
      position: absolute;
      margin: 0;
      max-width: 350px;
      padding-top: 12px;
      padding-left: 3px;
  }
  &__block-generate-pass-ok{
      text-align: center;
      margin: 42px 53px 0;
  }
  &__info-text{
      font-size: 18px;
      font-family: MTTMilano, sans-serif;
      color: $concept-blue;
      margin: 18px 0 18px;
      line-height: 20px;
      letter-spacing: -1px;
  }
  &__separator-info{
      margin: 0 4px;
  }
  /////////////////////////////////////////
  /////////////Custom/////////////////////
  ///////////////////////////////////////
  &__custom-login-container{
      display: block;
      width: 100%;
  }
  &__custom-login-block{
      height: 348px;
      width: 460px;
      border-radius: 4px;
      background-color: $snow-white;
      box-shadow: 0 4px 20px 0 $attachment-custom-grey-opacity;
      margin: 200px auto 0;
      padding-top: 25px;
      position: relative;
      &--no-public{
          // height: 434px;
          height: 460px;
          padding: 33px 10px 10px;
      }
      &--no-public-monitoring{
          height: 348px;
          width: 460px;
      }
  }
  &__custom-login-button{
      height: 38px;
      width: 359px;
      border-radius: 4px;
      background-color: $custom-secondary-color;
      box-shadow: 0 1px 1px 0 $modal-help-box;
      color: $snow-white;
      border: none;
      margin-top: 31px;
      outline: none;
      text-transform: uppercase;
      // &--dark-blue{
      //     background-color: $primary-color;
      // }
      // &--steel-blue{
      //     background-color: $primary-color-2;
      // }

      &--error{
          margin-top: 15px;
          margin-bottom: 31px;
      }
      &:hover{
          background-color: $custom-secondary-color !important;
          color: $snow-white !important;
          border-color: $custom-secondary-color !important;
      }
  }
  &__custom-information-block{
      height: 111px;
      // width: 100%;
      border-radius: 8px;
      background-color: $background-grey;
      border: none;
      text-align: center;
      padding: 20px 26px 26px;
      width: 440px;
      position: absolute;
      bottom: 8px;
  }

  &__error-message-icon-custom{
      font-size: 84px;
  }

  &__message-custom {
      font-size: 16px;
      color: $custom-black-landing;
      font-family: MTTMilano, sans-serif;
      margin-top: 20px;
      width: 80%;
      margin-left: 10%;
      height: 32px;
      line-height: 16px;
      &--with-button{
          margin-top: -9px;
          margin-bottom: 0;
          height: auto;
          color: $custom-black-landing;
          font-size: 14px;
      }
  }
  &__footer{
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 200px;
  }

  /////////////////////////////////////////
  /////////////Fin Custom/////////////////////
  ///////////////////////////////////////
}

.compliance{
&__dark-blue {
  & .front-login{
      &__header{
          height: 60px;
          background-color: $primary-color;
      }
      &__input-icon {
          &--error{
              color: $reset-text-color;
          }
          &--ok{
              color: $advice-confirm;
          }
      }
      &__input-block-text{
          &:focus {
              box-shadow: inset 0 0 0 100px $snow-white, 0 0 8px $input-block-focus;
          }
      }
      &__login-button{
        background-color: $primary-color;
        color: $snow-white;
      }
      &__link-external-web{
          color: $primary-color;
          &:hover, &:focus, &:visited{
              color: $primary-color;
          }
      }
  }
}

&__steel-blue{
    & .front-login{
        margin-bottom: 130px;
        &__go-home {
            margin-top: 27px;
            margin-left: 16px;
        }
        &__input-icon {
            margin-top: 30px;
            &--error{
                color: $alert-color-2;
            }
            &--ok{
                color: $border-hover-2;
            }
        }
        &__unexpected-text {
            color: $primary-color-2;
        }
        &__unexpected-message {
            color: $primary-color-2;
        }
        &__login-block{
            border-color: $third-color-2;
        }
        &__error-message{
            color: $primary-color-2;
        }
        &__message{
            color: $grey-icons-2;
        }

        &__link-external-web{
            color: $border-hover-2;
            &:hover, &:focus, &:visited{
                color: $border-hover-2;
            }
        }
        &__information-block{
            background-color: $third-color-2;
        }
        &__information-question, &__information-contact{
            color: $primary-color-2;
        }
        &__information-contact-email{
            @include front-login-information-contact-email($border-hover-2);
        }
    }
}

&__uk-steel-blue{
  & .front-login{
      background-color: $background-grey-uk;
      padding-bottom: 60px;
      &__header{
          height: 95px;
          background-color: $snow-white;
          border-bottom: 1px solid $secondary-color-2;
      }
      &__go-home {
          margin-top: 27px;
          margin-left: 16px;
      }
      &__input-icon {
          margin-top: 30px;
          &--error{
              color: $alert-color-2;
          }
          &--ok{
              color: $border-hover-2;
          }
      }
      &__unexpected-text {
          color: $primary-color-2;
      }
      &__unexpected-message {
          color: $primary-color-2;
      }
      &__login-block{
          box-shadow: $box-shadow-v3;
          border: none;
          border-top: 1px solid transparent;
      }
      &__input-block-icon{
          @include front-login-input-block-icon($primary-color-2, $white-special-2);
      }
      &__input-block-text{
          @include front-login-input-block-text($secondary-color-2, $text-field-color-2, $text-field-color-2);
          @include front-login-input-block-text-focus($border-hover-2, $third-color-2);
          &--error{
              border-color: $alert-color-2;
              background-color: $background-other-error-2;
          }
      }
      &__remember-check-box{
          border-color: $border-hover-2;
      }
      &__checkbox-icon{
          color: $border-hover-2;
      }
      &__remember-text{
          color: $primary-color-2;
      }
      &__remember-pass{
          @include front-login-remember-pass($border-hover-2);
      }
      &__login-button{
          @include front-login-login-button($border-hover-2, $snow-white);
      }
      &__link-external-web{
          color: $border-hover-2;
          &:hover, &:focus, &:visited{
              color: $border-hover-2;
          }
      }
      &__information-block{
          background-color: $third-color-2;
          &--reset-info{
              margin-top: 42px;
          }
      }
      &__information-question, &__information-contact{
          color: $primary-color-2;
      }
      &__information-contact-email{
          @include front-login-information-contact-email($border-hover-2);
      }
      &__reset-pass-title {
          color: $primary-color-2;
      }
      &__reset-pass-info {
          color: $primary-color-2;
      }
      &__error-message-icon{
          color: $primary-color-2;
      }
      &__info-text{
          color: $grey-icons-2;
      }
      &__footer{
          height: 60px;
      }
  }
}

&__icam-red{
  & .front-login{
      background-color: $background-grey-icam;
      &__header{
          box-shadow: $box-shadow-icam;
          height: 95px;
          background-color: $snow-white;
      }
      &__go-home {
          margin-top: 11px;
          padding: 0;
      }

      &__input-icon {
          margin-top: 30px;
          &--error{
              color: $alert-color-icam;
          }
          &--ok{
              color: $green-task-icam;
          }
      }
      &__unexpected-text {
          color: $custom-black-landing;
      }
      &__unexpected-message {
          color: $custom-black-landing;
      }
      &__login-block{
          box-shadow: $box-shadow-icam;
          border: none;
          border-top: 1px solid transparent;
      }
      &__input-error-text{
          color: $alert-color-icam;
      }
      &__input-block-icon{
          @include front-login-input-block-icon($primary-color-icam, $snow-white);
      }
      &__input-block-text{
          @include front-login-input-block-text($border-block-icam, $text-field-color-icam, $secondary-color-icam);
          @include front-login-input-block-text-focus($primary-color-icam, $hover-block-icam);
          &--error{
              border-color: $alert-color-icam;
              background-color: $red-task-20-icam;
          }
      }
      &__remember-check-box{
          border-color: $primary-color-icam;
      }
      &__checkbox-icon{
          color: $primary-color-icam;
      }
      &__remember-text{
          color: $custom-black-landing;
      }
      &__remember-pass{
          @include front-login-remember-pass($primary-color-icam);
      }
      &__login-button{
          @include front-login-login-button($primary-color-icam, $snow-white);
      }
      &__error-message{
          color: $custom-black-landing;
      }
      &__message{
          color: $custom-black-landing;
      }
      &__information-block{
          background-color: $third-color-icam;
      }
      &__information-question, &__information-contact{
          color: $custom-black-landing;
      }
      &__information-contact-email{
          @include front-login-information-contact-email($primary-color-icam);
      }
      &__reset-pass-title {
          color: $custom-black-landing;
      }
      &__reset-pass-info {
          color: $custom-black-landing;
      }
      &__user-error {
          color: $alert-color-icam;
      }
  }
}

&__gm-law{
  & .front-login{
      background-color: $background-grey-gm;
      &__header{
          box-shadow: $box-shadow-gm;
          height: 95px;
          background-color: $snow-white;
      }
      &__logo-header {
          margin-top: 18px;
      }
      &__go-home {
          margin-top: 11px;
          padding: 0;
      }
      &__input-icon {
          margin-top: 30px;
          &--error{
              color: $alert-color-gm;
          }
          &--ok{
              color: $green-task-gm;
          }
      }
      &__unexpected-text {
          color: $custom-black-landing;
      }
      &__unexpected-message {
          color: $custom-black-landing;
      }
      &__login-block{
          box-shadow: $box-shadow-gm;
          border: none;
          border-top: 1px solid transparent;
      }
      &__input-error-text{
          color: $alert-color-gm;
      }
      &__input-block-icon{
          @include front-login-input-block-icon($primary-color-gm, $snow-white);
      }
      &__input-block-text{
          @include front-login-input-block-text($border-block-gm, $text-field-color-gm, $secondary-color-gm);
          @include front-login-input-block-text-focus($primary-color-gm, $hover-block-gm);
          &--error{
              border-color: $alert-color-gm;
              background-color: $red-task-20-gm;
          }
      }
      &__remember-check-box{
          border-color: $primary-color-gm;
      }
      &__checkbox-icon{
          color: $primary-color-gm;
      }
      &__remember-text{
          color: $custom-black-landing;
      }
      &__remember-pass{
          @include front-login-remember-pass($primary-color-gm);
      }
      &__login-button{
          @include front-login-login-button($primary-color-gm, $snow-white);
      }
      &__error-message{
          color: $custom-black-landing;
      }
      &__message{
          color: $custom-black-landing;
      }
      &__information-block{
          background-color: $third-color-gm;
      }
      &__information-question, &__information-contact{
          color: $custom-black-landing;
      }
      &__information-contact-email{
          @include front-login-information-contact-email($primary-color-gm);
      }
      &__reset-pass-title {
          color: $custom-black-landing;
      }
      &__reset-pass-info {
          color: $custom-black-landing;
      }
      &__user-error {
          color: $alert-color-gm;
      }
  }
}
&__com-de{
  & .front-login{
      background-color: $background-grey-com-de;
      &__header{
          box-shadow: $box-shadow-com-de;
          height: 95px;
          background-color: $snow-white;
      }
      &__logo-header {
          margin-top: 18px;
      }
      &__go-home {
          margin-top: 11px;
          padding: 0;
      }
      &__input-icon {
          margin-top: 30px;
          &--error{
              color: $alert-color-com-de;
          }
          &--ok{
              color: $green-task-com-de;
          }
      }
      &__unexpected-text {
          color: $custom-black-landing;
      }
      &__unexpected-message {
          color: $custom-black-landing;
      }
      &__login-block{
          box-shadow: $box-shadow-com-de;
          border: none;
          border-top: 1px solid transparent;
      }
      &__input-error-text{
          color: $alert-color-com-de;
      }
      &__input-block-icon{
          @include front-login-input-block-icon($primary-color-com-de, $snow-white);
      }
      &__input-block-text{
          @include front-login-input-block-text($border-block-com-de, $text-field-color-com-de, $secondary-color-com-de);
          @include front-login-input-block-text-focus($primary-color-com-de, $hover-block-com-de);
          &--error{
              border-color: $alert-color-com-de;
              background-color: $red-task-20-com-de;
          }
      }
      &__remember-check-box{
          border-color: $primary-color-com-de;
      }
      &__checkbox-icon{
          color: $primary-color-com-de;
      }
      &__remember-text{
          color: $custom-black-landing;
      }
      &__remember-pass{
          @include front-login-remember-pass($primary-color-com-de);
      }
      &__login-button{
          @include front-login-login-button($primary-color-com-de, $snow-white);
      }
      &__error-message{
          color: $custom-black-landing;
      }
      &__message{
          color: $custom-black-landing;
      }
      &__information-block{
          background-color: $third-color-com-de;
          padding: 20px 4px 26px;
      }
      &__information-question, &__information-contact{
          color: $custom-black-landing;
      }
      &__information-contact-email{
          @include front-login-information-contact-email($primary-color-com-de);
      }
      &__reset-pass-title {
          color: $custom-black-landing;
      }
      &__reset-pass-info {
          color: $custom-black-landing;
      }
      &__user-error {
          color: $alert-color-com-de;
      }
  }
}
&__com-fr{
  & .front-login{
      background-color: $background-grey-com-fr;
      &__header{
          box-shadow: $box-shadow-com-fr;
          height: 95px;
          background-color: $snow-white;
      }
      &__logo-header {
          margin-top: 18px;
      }
      &__go-home {
          margin-top: 11px;
          padding: 0;
      }
      &__input-icon {
          margin-top: 30px;
          &--error{
              color: $alert-color-com-fr;
          }
          &--ok{
              color: $green-task-com-fr;
          }
      }
      &__unexpected-text {
          color: $custom-black-landing;
      }
      &__unexpected-message {
          color: $custom-black-landing;
      }
      &__login-block{
          box-shadow: $box-shadow-com-fr;
          border: none;
          border-top: 1px solid transparent;
      }
      &__input-error-text{
          color: $alert-color-com-fr;
      }
      &__input-block-icon{
          @include front-login-input-block-icon($primary-color-com-fr, $snow-white);
      }
      &__input-block-text{
          @include front-login-input-block-text($border-block-com-fr, $text-field-color-com-fr, $secondary-color-com-fr);
          @include front-login-input-block-text-focus($primary-color-com-fr, $hover-block-com-fr);
          &--error{
              border-color: $alert-color-com-fr;
              background-color: $red-task-20-com-fr;
          }
      }
      &__remember-check-box{
          border-color: $primary-color-com-fr;
      }
      &__checkbox-icon{
          color: $primary-color-com-fr;
      }
      &__remember-text{
          color: $custom-black-landing;
      }
      &__remember-pass{
          @include front-login-remember-pass($primary-color-com-fr);
      }
      &__login-button{
          @include front-login-login-button($primary-color-com-fr, $snow-white);
      }
      &__error-message{
          color: $custom-black-landing;
      }
      &__message{
          color: $custom-black-landing;
      }
      &__information-block{
          background-color: $third-color-com-fr;
          padding: 20px 4px 26px;
      }
      &__information-question, &__information-contact{
          color: $custom-black-landing;
      }
      &__information-contact-email{
          @include front-login-information-contact-email($primary-color-com-fr);
      }
      &__reset-pass-title {
          color: $custom-black-landing;
      }
      &__reset-pass-info {
          color: $custom-black-landing;
      }
      &__user-error {
          color: $alert-color-com-fr;
      }
  }
}
&--public {
  .front-login{
      &__header{
          height: auto;
          background-color: transparent;
      }
  }
}
}


@media (max-width: $max-width-smartphone) {
  .front-login {
      height: auto;

      &__custom-login-button {
          width: 100%;
      }

      &__input-block {
          &--custom {
              width: 100%;
          }
      }

      &__custom-login-block {
          width: 100%;
          margin-top: 0;
      }

      &__custom-information-block {
          width: 100%;
          position: unset;
      }

      &__contact-login {
          &--custom {
              padding-left: 10px;
              padding-right: 10px;
          }
      }
  }
}
