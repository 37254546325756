@mixin users-admin-edit-button-user-management($backgroundColor, $color, $backgroundColorSelected, $colorSelected) {
  background-color: $backgroundColor;
  color: $color;
  &--selected{
      background-color: $backgroundColorSelected;
      color: $colorSelected;
      z-index: 1;
      &:hover{
          cursor: default;
      }
  }
}

.users-admin-edit {
  margin: 20px 30px 15px;
  position: relative;
  &__title {
      float: left;
      color: $subcontainer-ground;
      margin: 0 0 0 10px;
      font-size: 12px;
      text-transform: uppercase;
      font-family: MTTMilano-Bold, sans-serif;
      padding-left: 25px;
      position: relative;
  }

  &__icon-title{
      font-size: 20px;
      position: absolute;
      left: 0;
      top: -6px;
  }

  &__title2 {
      clear: both;
      margin-left: 25px;
      color: $primary-color;
      font-size: 12px;
      text-transform: uppercase;
      font-family: MTTMilano-Bold, sans-serif;
      position: relative;
      bottom: 2px;
  }

  &__close-link {
      color: $primary-color;
      float: right;
      position: absolute;
      right: 0;
      top: 0;
      background-color: transparent;
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
  }

  &__header-block{
      display: inline-block;
  }

  &__content {
      margin: 65px auto 70px;
      &--margin-50{
          margin-bottom: 50px;
      }
      &--user-management{
          margin: 25px auto 50px;
          width: 100%;
          position: relative;
          float: left;
      }
  }

  &__buttons {
      float: right;
      & button {
          margin-left: 5px;
      }
  }

  &__block-buttons-management{
      border-bottom: 5px solid;
  }

  &__button-user-management{
      position: relative;
      width: 140px;
      height: 30px;
      border: none;
      outline: none;
      text-transform: uppercase;
      font-size: 12px;
      font-family: MTTMilano-Bold, sans-serif;
      line-height: 16px;
      text-align: left;
      padding-left: 22px;
      margin-right: 5px;
  }

  &__triangle-block {
      width: 21px;
      height: 30px;
      position: absolute;
      top: 0px;
      right: -21px;
  }

  &__block-user-management{
      position: relative;
      float: left;
      border: 1px solid;
      border-top: none;
      padding: 11px 10px 10px;
      width: 100%;
  }
}

.compliance{
  &__dark-blue{
    .users-admin-edit {
      &__block-buttons-management{
          border-color: $primary-color;
      }
      &__button-user-management{
          @include users-admin-edit-button-user-management($background-grey-other, $pillow-group, $primary-color, $snow-white);
      }
      &__triangle-block {
          fill: $background-grey-other;
          &--selected{
              fill: $primary-color;
          }
      }
      &__block-user-management{
          border-color: $background-grey-other;
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue{
    .users-admin-edit {
      &__title {
          color: $grey-icons-2;
      }
      &__title2 {
          color: $primary-color-2;
      }
      &__close-link {
          color: $border-hover-2;
      }
      &__block-buttons-management{
          border-color: $third-color-2;
      }
      &__button-user-management{
          @include users-admin-edit-button-user-management($background-grey-disabled-2, $grey-icons-2, $third-color-2, $primary-color-2);
      }
      &__triangle-block {
          fill: $background-grey-disabled-2;
          &--selected{
              fill: $third-color-2;
          }
      }
      &__block-user-management{
          border-color: $background-highlight-text-2;
      }
    }
  }
  &__icam-red{
    .users-admin-edit {
      &__title {
          color: $secondary-color-icam;
          font-size: 12px;
      }
      &__title2 {
          color: $custom-black-landing;
      }
      &__close-link {
          color: $primary-color-icam;
      }
      &__block-buttons-management{
          border-color: $third-color-icam;
      }
      &__button-user-management{
          @include users-admin-edit-button-user-management($background-grey-disabled-icam, $text-field-color-icam, $third-color-icam, $primary-color-icam);
      }
      &__triangle-block {
          fill: $background-grey-disabled-icam;
          &--selected{
              fill: $third-color-icam;
          }
      }
      &__block-user-management{
          border-color: $border-block-icam;
      }
    }
  }
  &__gm-law{
    .users-admin-edit {
      &__title {
          color: $secondary-color-gm;
          font-size: 12px;
      }
      &__title2 {
          color: $custom-black-landing;
      }
      &__close-link {
          color: $primary-color-gm;
      }
      &__block-buttons-management{
          border-color: $third-color-gm;
      }
      &__button-user-management{
          @include users-admin-edit-button-user-management($background-grey-disabled-gm, $text-field-color-gm, $third-color-gm, $primary-color-gm);
      }
      &__triangle-block {
          fill: $background-grey-disabled-gm;
          &--selected{
              fill: $third-color-gm;
          }
      }
      &__block-user-management{
          border-color: $border-block-gm;
      }
    }
  }
  &__com-de{
    .users-admin-edit {
      &__title {
          color: $secondary-color-com-de;
          font-size: 12px;
      }
      &__title2 {
          color: $custom-black-landing;
      }
      &__close-link {
          color: $primary-color-com-de;
      }
      &__block-buttons-management{
          border-color: $third-color-com-de;
      }
      &__button-user-management{
          @include users-admin-edit-button-user-management($background-grey-disabled-com-de, $text-field-color-com-de, $third-color-com-de, $primary-color-com-de);
      }
      &__triangle-block {
          fill: $background-grey-disabled-com-de;
          &--selected{
              fill: $third-color-com-de;
          }
      }
      &__block-user-management{
          border-color: $border-block-com-de;
      }
    }
  }
  &__com-fr{
    .users-admin-edit {
      &__title {
          color: $secondary-color-com-fr;
          font-size: 12px;
      }
      &__title2 {
          color: $custom-black-landing;
      }
      &__close-link {
          color: $primary-color-com-fr;
      }
      &__block-buttons-management{
          border-color: $third-color-com-fr;
      }
      &__button-user-management{
          @include users-admin-edit-button-user-management($background-grey-disabled-com-fr, $text-field-color-com-fr, $third-color-com-fr, $primary-color-com-fr);
      }
      &__triangle-block {
          fill: $background-grey-disabled-com-fr;
          &--selected{
              fill: $third-color-com-fr;
          }
      }
      &__block-user-management{
          border-color: $border-block-com-fr;
      }
    }
  }
}

@media (max-width: 1260px) {
  .users-admin-edit {
      margin: 20px 5px 15px;
  }
}
