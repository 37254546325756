.client-evaluation {
  &__body{
      width: 100%;
      position: relative;
      margin-bottom: 1px;
  }

  &__container{
      max-width: 1420px;
      margin: 0 auto;
      padding: 0 10px;
  }

  &__block-list{
      height: auto;
      min-height: 650px;
      padding: 10px 10px 26px;
      &--with-legalform{
          min-height: 610px;
      }
  }

  &__body-container{
      width: 800px;
      margin: 35px auto 0;
  }

  &__buttons-block {
      display: block;
      margin: 75px 50px 0px;
      right: 0;
      text-align: right;
      &--advice-message{
          margin-top: 10px;
      }
  }
}

@media (max-width: 1022px) {
  .client-evaluation {
      &__body-container{
          width: calc(100% - 80px);
          margin: 30px auto 0;
      }
  }
}
