.expanded-textarea {
  & .modal-dialog {
      & .modal-content {
          width: 820px;
          height: 449px;
          padding: 10px;
          margin: 0 auto;
      }
  }
  &--notification-personalized{
      & .modal-dialog {
          & .modal-content {
              width: 100%;
              margin-left: 0;
              height: 564px;
          }
      }
  }
  &__input-block {
      height: 428px;
      overflow: auto;
      &:hover {
          & .common__delete-content-icon {
              display: block;
          }
      }
  }

  &__delete-content-icon {
      display: none;
      font-size: 10px;
      // color: $primary-color;
      position: absolute;
      z-index: 10;
      right: 14px;
      bottom: 13px;
      float: none;

      &:hover {
          cursor: pointer;
      }
  }

  &__notification-personalized{
      height: 542px;
  }
  &__rich-editable-editor {
      // & div.mce-tinymce {
      //     border: none;
      // }
  }
  &__rich-editable-icon {
      top: 6px;
      right: 6px;
  }
}

.compliance{
  &__steel-blue{
    .expanded-textarea{
      &__delete-content-icon{
          color: $border-hover-2;
      }
    }
  }

  // &__uk-steel-blue{
  //   .expanded-textarea{
  //   }
  // }

  &__icam-red{
    .expanded-textarea{
      &__delete-content-icon{
          color: $primary-color-icam;
      }
    }
  }
  &__gm-law{
    .expanded-textarea{
      &__delete-content-icon{
          color: $primary-color-gm;
      }
    }
  }
  &__com-de{
    .expanded-textarea{
      &__delete-content-icon{
          color: $primary-color-com-de;
      }
    }
  }
  &__com-fr{
    .expanded-textarea{
      &__delete-content-icon{
          color: $primary-color-com-fr;
      }
    }
  }

  &__landing-custom{
    .expanded-textarea{
      &__input-block{
        border-color: $custom-border-grey;
        background-color: $snow-white;
        box-shadow: 0 1px 4px 0 $modal-help-box;
        margin-bottom: 15px;
        position: relative;

        &:hover {
            border-color: $custom-black-landing;
            background: none;
        }
      }
      &__delete-content-icon{
          font-size: 12px;
          color: $custom-secondary-color;
      }
    }
  }
}

@media (max-width: 920px) {
  .expand-textarea .modal-dialog .modal-content {
      width: 100%;
      margin: 0;
  }
}

// @media (max-width: $max-width-smartphone) { //Smartphone
//   .expanded-textarea .modal-dialog .modal-content {
//       width: 100%;
//       margin-left: 0;
//   }
// }
