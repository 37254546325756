.customize-field-formula {
  &__formula-block {
      display: block;
      width: 100%;
      &__formula-config{
          display: inline-block;
      }
  }

  &__field-bool{
      text-align: left;
      &--bottom{
          margin-bottom: 24px;
      }
  }

  &__formula-col {
      display: block;
      width: 33.33333%;
      float: left;
  }

  &__symbol-list {
      margin: 0;
      list-style: none;
      padding: 7px 13px;
  }

  &__symbol-item {
      display: inline-block;
      width: 29px;
      height: 36px;
      position: relative;
      background: $primary-color;
      color: $snow-white;
      text-align: center;
      overflow: hidden;

      &:hover {
          cursor: pointer;
      }

      &[disabled] {
          background: $secondary-color;
          cursor: default;
      }
  }

  &__symbol-icon {
      position: relative;
      top: 11px;
  }

  &__result-block {
      width: 100%;
      min-height: 31px;
      background-color: $background-grey-other;
      display: inline-block;
      margin-top: 16px;
      padding: 8px 10px 10px 10px;

  }

  &__icon-equal {
      display: inline-block;
      color: $primary-color;
      font-size: 21px;
      margin: -4px 5px 0px 5px;
  }

  &__icon-close {
      display: inline-block;
      color: $primary-color;
      font-size: 10px;
      margin: 2px 0px 0px 8px;
      cursor: pointer;

      &[disabled] {
          color: $secondary-color;
          cursor: default;
      }
  }

  &__icon-undo {
      display: inline-block;
      color: $primary-color;
      font-size: 17px;
      margin-top: -2px;
      cursor: pointer;
      &[disabled] {
          color: $secondary-color;
          cursor: default;
      }
  }

  &__label-block{
      padding-left: 0;
      margin: 0 0 10px;
  }

  &__icon-configure{
      cursor: pointer;
      float: right;
      color:$primary-color;
      font-size: 25px;
      &[disabled]{
          color: $secondary-color;
          cursor: default;
      }

  }

  &__message-required{
      visibility: hidden;
      color: $red;
      font-family: Arial, sans-serif;
      font-size: 14px;
      margin: 2px;
      &--visible{
          visibility: visible;
      }
  }

  &__result-text-title {
      display: block;
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      opacity: 50%;
      font-weight: bold;
      text-transform: uppercase;
      &--required{
          opacity: 100%;
          color: $red !important;
      }

  }

  &__result-text {
      color: $primary-color;
      font-family: Arial, sans-serif;
      font-size: 14px;
      display: inline-block;
      position: relative;
      margin: 0;
      top: 3px;
      padding: 2px 3px;

      &--required{
          opacity: 100%;
          color: $red !important;
      }
      &--required-list{
          opacity: 100%;
          color: $red !important;
          background: transparent;
          border: none;
          width: 100%;
          padding: 1px 0 0 10px;
      }
  }
  &__result-text-symbol{
      font-weight: bold;
  }

  &__block-result-text {
      position: relative;
      float: left;
  }

  &__button-add {
      color: $primary-color;
      float: right;
      font-size: 27px;
      position: absolute;
      &[disabled]{
          opacity: 0.2;
      }

  }

  &__group-body-help {
      display: inline-block;
      width: 100%;
      margin-bottom:20px;
  }

  &__list-enconding-selected{
      list-style: none;
      color: $secondary-color;
      padding-left: 0;
      margin: 0;
  }

  &__element-list-encoding{
      padding: 0 0 0 10px;
      margin-bottom: 10px;
      border: 1px solid $subcontainer-border;
      height: 30px;
      list-style-type: none;
      &:hover {
          & .customize-field-formula__option-menu {
              visibility: visible;
          }
          & .customize-field-formula__button-item-drag {
              right: 31px;
          }
          & .customize-field-formula__encoding-color-pill {
              position: relative;
              right: 20px;
          }
      }
  }

  &__block-right-element{
      padding: 3px 0;
      height: 28px;
      float: right;
  }

  &__encoding-block-left{
      float: left;
      width: 65%;
      min-width: 422px;
  }

  &__econding-block-buttons{
      float: right;
      font-size: 12px;
      position: absolute;
      right: 10px;
      bottom: 20px;
      text-align: right;
      margin: 0;
      padding-left: 0;
      list-style: none;
      line-height: 0;
  }

  &__encoding-colors-palette{
      float: right;
      width: 205px;
      padding: 0;
  }

  &__encoding-color-pill {
      display: inline-block;
      width: 38px;
      height: 22px;
      border-radius: 15px;
      &--list{
          margin-right: 15px;
      }
  }

  &__encoding-operator-field, &__encoding-value-field {
      float: left;
      width: 23.5%;
      padding-left: 2%;
      box-sizing: content-box;

      &:first-child {
          padding-left: 0;
      }
  }

  &__encoding-text-field {
      float: left;
      position: relative;
      width: 100%;
  }

  &__encoding-element-color-list{
      margin-left: 3px;
      cursor: pointer;
      &.selected {
          cursor: default;

          &:after {
              content: "";
              display: block;
              position: relative;
              top: 7px;
              left: 6px;
              width: 8px;
              height: 8px;
              border-radius: 10px;
              background-color: $snow-white;
          }
      }
  }

  &__option-menu{
      z-index: 10;
      background: $option-menu-grey;
      top: -6px;
      width: auto;
      // height: 100%;
      background-color: $background-grey-other;
      border-radius: 15px 0 0 15px;
      padding: 6px 0 0 15px;
      position: absolute;
      right: 0;
      overflow: hidden;
      height: 30px;
      visibility: hidden;
  }

  &__button-item-drag{
      font-size: 20px;
      color: $primary-color;
      border: none;
      background: transparent;
      outline: none;
      padding: 0;
      position: relative;
      bottom: 3px;
  }

  &__input-icon-menu{
      font-size: 26px;
      color: $primary-color;
      position: relative;
      bottom: 4px;

  }

  &__table-display{
      display: table;
      position: relative;
      float: left;
      width: calc(100% - 112px);
      height: 28px;
  }

  &__cell-block{
      position: relative;
      float: left;
      width: 100%;
      max-width: 100%;
      height: 17px;
  }

  &__selected-list-item-actions{
      display: inline-block;
      position: relative;
      top: 2px;
      vertical-align: top;
      height: 18px;
      font-size: 18px;
  }
}
.compliance{
  &__gm-law{
      .customize-field-formula {
          &__result-block {
          background-color: $background-grey-disabled-gm;
           }
          &__icon-configure{
           color: $primary-color-gm;
          }
           &__result-text-title {
           color: $text-field-color-gm;
          }
          &__result-text {
           color:$custom-black-landing;
          }
          &__button-add {
          color: $primary-color-gm;
          }
          &__option-menu{
           background: $primary-color-gm;
                      background-color: $primary-color-gm;
          }
          &__button-item-drag{
          color: $primary-color-gm;
          }
          &__input-icon-menu{
          color: $primary-color-gm;
          }
      }
  }
  &__com-de{
    .customize-field-formula {
        &__result-block {
        background-color: $background-grey-disabled-com-de;
         }
        &__icon-configure{
         color: $primary-color-com-de;
        }
         &__result-text-title {
         color: $text-field-color-com-de;
        }
        &__result-text {
         color:$custom-black-landing;
        }
        &__button-add {
        color: $primary-color-com-de;
        }
        &__option-menu{
         background: $primary-color-com-de;
         background-color: $primary-color-com-de;
        }
        &__button-item-drag{
        color: $primary-color-com-de;
        }
        &__input-icon-menu{
        color: $primary-color-com-de;
        }
    }
 }
 &__com-fr{
  .customize-field-formula {
      &__result-block {
      background-color: $background-grey-disabled-com-fr;
       }
      &__icon-configure{
       color: $primary-color-com-fr;
      }
       &__result-text-title {
       color: $text-field-color-com-fr;
      }
      &__result-text {
       color:$custom-black-landing;
      }
      &__button-add {
      color: $primary-color-com-fr;
      }
      &__option-menu{
       background: $primary-color-com-fr;
       background-color: $primary-color-com-fr;
      }
      &__button-item-drag{
      color: $primary-color-com-fr;
      }
      &__input-icon-menu{
      color: $primary-color-com-fr;
      }
  }
 }
}

@media (max-width: 1876px) {
  .customize-field-formula{
      &__encoding-colors-palette{
          float: left;
      }
      &__encoding-block-left {
          width: 100%;
          min-width: 100%;
      }
  }
}
