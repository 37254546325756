@mixin rich-editor-tiny($borderColor, $backgroundColor, $backgroundHover, $colorText) {
  .tox{
    border-color: $borderColor;
    &-menu{
      border-color: $borderColor;
    }
    &-toolbar__primary{
        background-color: $backgroundColor;
    }
    &-tinymce{
        border-radius: 0px;
        border: none;
    }
    & .tox-collection--list {
      max-height: 170px !important;
      width: 300px;
      border-radius: 0;
      & .tox-collection__item{
          &--active{
            background-color: transparent;
          }
          border-radius: 0;
          color: $colorText;
          &:hover{
              background-color: $backgroundHover;
              cursor: pointer;
              .tox-collection__item-label{
                  color: inherit;
              }
          }
          .tox-collection__item-label{
              color: $colorText;
          }
      }
    }
  }
  .rich-editor{
    border: 1px solid $borderColor;
    &--white{
      & .tox-toolbar__primary{
        border-bottom: none;
        background-color: transparent;
      }
    }
    &--rich-text{
      border: none;
      & .tox-toolbar__primary{
        border-bottom: none;
      }
    }
    &--task{
      border: none;
    }
  }
}


.rich-editor{
    height: 100%;
    &__button-modal{
      display: none;
      width: 0;
      height: 0;
      border: none;
      position: absolute;
    }
    & .tox-tinymce{
        border-radius: 0px;
        border: none;
    }
    & .tox-editor-header{
        box-shadow: none !important;
        padding: 0 !important;
    }
    & .tox-toolbar__primary{
        border-bottom: 1px solid $result-search-quote-color;
        background-color: $background-color-states;
    }
    & .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type){
        border-right: 1px solid #d9d9d9;
    }
    & .tox-toolbar__group{
        padding: 0 3px;
        height: 36px;
    }
    & .tox-tbtn{
        margin: 0;
    }
    & .tox-icon.tox-tbtn__icon-wrap{
        & i{
          font-size: 18px;
        }
    }
    & .tox-tbtn--bespoke{
        background: transparent;
    }
}

.compliance{
  &__dark-blue{
    @include rich-editor-tiny($result-search-quote-color, $background-color-states, $background-color-states, $secondary-color);
    .rich-editor{
        // border: 1px solid $result-search-quote-color;
        &--task{
          & .tox-toolbar__primary{
              border-bottom: 5px solid $primary-color;
          }
        }
    }
  }
  &__steel-blue, &__uk-steel-blue{
    @include rich-editor-tiny($border-hover-2, $background-grey-disabled-2, $block-hover-2, $primary-color-2);
    // .rich-editor{
    //     border: 1px solid $border-hover-2;
    //     & .tox-toolbar__primary{
    //         border-bottom: 1px solid $border-hover-2;
    //         background-color: $background-grey-disabled-2;
    //     }
    // }
  }
  &__icam-red{
    @include rich-editor-tiny($border-block-icam, $background-grey-icam, $hover-block-icam, $custom-black-landing);
    // .rich-editor{
    //     border: 1px solid $border-block-icam;
    //     & .tox-toolbar__primary{
    //         border-bottom: 1px solid $border-block-icam;
    //         background-color: $background-grey-icam;
    //     }
    // }
  }
  &__gm-law{
    @include rich-editor-tiny($border-block-gm, $background-grey-gm, $hover-block-gm, $custom-black-landing);
    // .rich-editor{
    //     border: 1px solid $border-block-gm;
    //     & .tox-toolbar__primary{
    //         border-bottom: 1px solid $border-block-gm;
    //         background-color: $background-grey-gm;
    //     }
    // }
  }
  &__com-de{
    @include rich-editor-tiny($border-block-com-de, $background-grey-com-de, $hover-block-com-de, $custom-black-landing);
    // .rich-editor{
    //     border: 1px solid $border-block-gm;
    //     & .tox-toolbar__primary{
    //         border-bottom: 1px solid $border-block-gm;
    //         background-color: $background-grey-gm;
    //     }
    // }
  }
  &__com-fr{
    @include rich-editor-tiny($border-block-com-fr, $background-grey-com-fr, $hover-block-com-fr, $custom-black-landing);
    // .rich-editor{
    //     border: 1px solid $border-block-gm;
    //     & .tox-toolbar__primary{
    //         border-bottom: 1px solid $border-block-gm;
    //         background-color: $background-grey-gm;
    //     }
    // }
  }
  &__landing-custom{
    .rich-editor{
        border: 1px solid $custom-border-grey;
        & .tox-toolbar__primary{
            border-bottom: 1px solid $custom-border-grey;
            background-color: $background-grey;
        }
    }
  }
}

