@mixin color($color){
  color: $color;
}

@mixin documentation-list-icon-multiple-disabled($color){
    color: $color;
    &:hover {
        cursor: default;
    }
}

.documentation-list {
    text-align: center;
    min-height: 600px;
    font-size: 12px;
    z-index: 10;
    position: relative;
    float: left;

    &__block-body{
        &--big-window {
            padding: 0 10px;
        }
    }

    &__dropzone {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 24;
        background-color: $box-comment-border-03;
    }

    &__dropzone-icon {
        font-size: 101px;
        color: $primary-color;
        position: relative;
        top: 195px;
    }

    &__dropzone-text {
        font-family: MTTMilano, sans-serif;
        color: $primary-color;
        font-size: 19px;
        text-align: center;
        margin-top: 209px;
    }

    &__block {
        text-align: center;
        padding: 0 0 2px 0px;
        margin-bottom: 2px;
        background-color: $snow-white;
        // width: calc(100% - 70px);
        width: 100%;
        position: relative;
        float: left;
        display: block;
        min-height: 502px;
        overflow-y: hidden;
        overflow-x: auto;
        font-size: 12px;

        &--padding-1 {
            padding-bottom: 180px;
        }

        &--opacity {
            opacity: 0.5;
        }
    }

    &__block-action {
        width: 70px;
        min-height: 490px;
        position: absolute;
        right: 20px;
        background-color: $snow-white;

        &--litle-margin {
            right: 10px;
        }
    }

    &__block-repeat-action {
        width: 100%;
        display: block;
        position: relative;
        min-height: 450px;
    }

    &__table {
        text-align: left;
        display: block;
        float: left;
        position: relative;
        height: 45px;
        padding-top: 14px;
        padding-right: 10px;

        &--multiple-selection {
            width: 40px;
            padding-top: 12px;
        }

        &--format {
            width: 75px;
            padding-left: 22px;
            padding-right: 0px;
        }

        &--title {
            width: 310px;
        }

        &--source {
            width: 220px;
        }

        &--creation-date {
            width: 100px;
        }

        &--author {
            width: 160px;
        }

        &--modification-date {
            width: 105px;
        }

        &--state {
            width: 105px;
        }

        &--state-process {
            width: 170px;
        }

        &--confidential {
            width: 30px;
            padding-right: 0px;
            text-align: center;
        }

        &--certified {
            width: 30px;
            padding-right: 0px;
        }
    }

    &__table-icon {
        color: $primary-color;
        font-size: 20px;
        position: relative;
        bottom: 3px;

        &--folder {
            font-size: 24px;
        }

        &--angle {
            font-size: 18px;
            bottom: 5px;
        }
    }

    &__options-button {
        border: none;
        background-color: transparent;
        float: right;
        outline: none;
        color: $primary-color;
        font-size: 27px;
        margin-right: -5px;
    }

    &__multiple-switch {
        position: relative;
        top: 10px;
    }

    &__menu-block {
        width: calc(50% - 110px);
        position: relative;
        float: left;

        &--right {
            text-align: right;
            float: right;
        }
    }

    &__menu-block-center {
        width: 220px;
        position: relative;
        float: left;
        text-align: center;
        height: 36px;
    }

    &__block-multiple-options {
        height: 36px;
        width: 220px;
        background-color: $background-grey-other;
        border-radius: 20px;
        margin: 0 auto;

        &--trash {
            width: 101px;
        }
    }

    &__block-header-buttons {
        display: inline-block;
    }

    &__list-menu-expanded {
        width: 220px;
        border: 1px solid $primary-color;
        background-color: $snow-white;
        position: absolute;
        padding: 0;
        border-radius: 0px;
        float: none;
        z-index: 1001;
        inset: auto !important;
        transform: none !important;
        left: -198px !important;
        top: 5px !important;
        &--submenu{
            left: -405px !important;
        }
    }

    &__fixed-aux {
        display: block;
        width: 60px;
        height: 55px;
        position: absolute;
        float: right;
        right: 0px;
    }

    &__action-fixed {
        position: relative;
        margin-left: 10px;
        background-color: $snow-white;
        height: 45px;
        color: $primary-color;
        font-size: 27px;
        width: 60px;

        &--header {
            height: 40px;
            border-bottom: none;
            background-color: $background-grey-other;
            text-align: center;
        }
    }

    &__menu-action {
        background-color: $background-grey-other;
        height: 45px;
        color: $primary-color;
        font-size: 27px;
        width: 60px;
        border: none;
        outline: none;
        &:before {
            bottom: 2px;
            position: relative;
        }
        &--disabled{
          opacity: 0.5,
        }
    }

    &__table-container {
        min-width: 1535px;

        &--no-has-processes {
            min-width: 1365px;
        }
    }

    &__table-body {
        width: 100%;
        display: block;
        position: relative;
        min-height: 450px;
    }

    &__miga {
        height: 100%;
        width: calc(100% - 70px);
        display: block;
        background-color: $primary-color;
        position: relative;
        color: $snow-white;
        float: left;
        text-align: left;
        overflow: auto;
        padding-bottom: 10px;
    }

    &__symbol-minus {
        position: relative;
        float: left;
        bottom: 2px;
        font-size: 14px;
        margin: 0 2px 0 3px;
    }

    &__block-breadcrumbs {
        position: relative;
        float: left;
        width: calc(100% - 51px);
    }

    &__element-breadcrumb {
        font-size: 12px;
        opacity: 0.5;
        text-transform: uppercase;
        font-family: MTTMilano, sans-serif;
        height: 100%;
        padding-top: 15px;
        display: inline-block;

        &:hover {
            cursor: pointer;
        }
    }

    &__text-breadcrumb {
        display: inline-block;
        margin: 0;
    }

    &__icon-mark {
        position: relative;
        float: left;
        height: 100%;
        padding-top: 12px;
        font-size: 20px;
        margin-left: 11px;
        margin-right: 20px;
        opacity: 0.5;
    }

    &__bloque-miga {
        width: 100%;
        height: 45px;
        display: inline-block;
        margin-bottom: 5px;
        position: relative;
    }

    &__title-menu-option {
        background-color: $primary-color;
        margin: 0;
        text-align: center;
        font-size: 12px;
        color: $snow-white;
        font-family: MTTMilano-Bold, sans-serif;
        padding-top: 12px;
        list-style: none;
        height: 45px;
    }

    &__icon-confidential {
        color: $primary-color;
        font-size: 22px;
        position: relative;
        bottom: 4px;

        &:hover {
            cursor: pointer;
        }
    }

    &__box-select-list {
        border-radius: 0px;
        border: none;
        padding: 0;
        overflow: hidden;
        position: absolute;
        margin: 0;
        width: 168px;
        max-height: 194px;
        transform: none !important;
        inset: auto !important;
        top: unset !important;
        left: unset !important;
        right: 34px !important;

        &--normal {
            top: 6px !important;
        }

        &--up-dropdown {
            bottom: 7px !important;
        }
    }

    &__icon-certified {
        margin-left: 5px;
        color: $primary-color;
        font-size: 22px;
        position: relative;
        bottom: 4px;
    }

    &__height-tab {
        height: 45px;
        display: block;
        width: 100%;
        border-bottom: 1px solid $column-filter-shadow-2;
        &:hover {
            background-color: $background-grey;
        }
        &--anonymatum{
            background-color: $background-grey;
        }
    }

    &__first-column-icon {
        width: 20px;
        position: relative;
        float: left;
    }

    &__first-column-text {
        float: left;
        height: 30px;
        padding: 0px 5px 0 5px;
        width: 75%;
        width: calc(100% - 26px);
    }

    &__trash-panel {
        background-color: $other-gray-03;
        height: 50px;
        position: relative;
        width: 100%;
    }

    &__trash-panel-back-button {
        padding: 0;
        border: none;
        outline: none;
        left: 14px;
        background: none;
        margin-top: 10px;
        position: relative;
    }

    &__trash-panel-deleted-amount {
        border: 1px solid $delete-amount;
        background-color: $background-grey;
        opacity: 0.7;
        color: $primary-color;
        font-family: MTTMilano-Bold, sans-serif;
        font-size: 12px;
        padding: 2px 6px;
        position: relative;
        right: 20px;
        top: 10px;
    }

    &__trash-panel-delete-icon {
        top: 6px;
        position: relative;
        right: 13px;
        display: inline-block;
        font-size: 30px;
        color: $primary-color;
        &:hover {
            cursor: pointer;
        }
    }

    &__trash-panel-text {
        font-family: MTTMilano, sans-serif;
        font-size: 12px;
        text-align: center;
        opacity: 0.7;
        top: 16px;
        position: relative;
        display: inline-block;
    }

    &__documentation-header {
        display: block;
        width: 100%;
        height: 50px;
        text-align: left;
        position: relative;
    }

    &__table-header {
        width: 18%;
        text-align: left;
        display: block;
        float: left;
        position: relative;
        padding-top: 10px;

        &--multiple-selection {
            width: 40px;
            padding-top: 7px;
        }

        &--format {
            width: 75px;
            padding-right: 0px;
        }

        &--title {
            width: 310px;
        }

        &--source {
            width: 220px;
        }

        &--creation-date {
            width: 100px;
        }

        &--author {
            width: 160px;
        }

        &--modification-date {
            width: 105px;
        }

        &--state {
            width: 105px;
        }

        &--state-process {
            width: 170px;
        }

        &--confidential {
            width: 30px;
        }

        &--certified {
            width: 30px;
        }
    }

    &__label-multiple {
        margin: 0;
        position: relative;
        height: 20px;
        width: 100%;

        &--icam-query {
            width: 30px;
            float: left;
            left: -8px;
            margin-right: 2px;
            bottom: 3px;
            z-index: 1;
        }
    }

    &__input-multiple {
        width: 20px;
        height: 20px;
        opacity: 0;
        outline: none;
        margin: 0 0 0 10px !important;
    }

    &__icon-multiple {
        font-size: 20px;
        color: $primary-color;
        position: absolute;
        top: 0;
        left: 10px;
        &:hover {
            cursor: pointer;
        }
        &--disabled {
            @include documentation-list-icon-multiple-disabled($background-grey-disabled-gm);
        }
    }

    &__icon-more-expanded {
        font-size: 10px;
        position: absolute;
        right: -2px;
        bottom: 17px;
    }

    &__menu-tooltip-multiple {
        width: 140px;
        min-width: 140px;
        border: 1px solid $primary-color;
        border-top: 5px solid $primary-color;
        border-radius: 0;
        margin: 0;
        padding: 0;
        top: calc(100% - 2px);
        left: -50px;

        &::after {
            content: '';
            border-top: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $primary-color;
            border-left: 5px solid transparent;
            left: 67.5px;
            position: absolute;
            top: -15px;
        }
    }

    &__option-menu-multiple {
        padding: 0px 6px;

        &:hover {
            background-color: $background-grey-other;
        }
    }

    &__list-menu-option-multiple {
        border: 0;
        background: 0;
        width: 100%;
        line-height: 0;
        text-align: left;
        outline: 0;
        padding: 7px 4px;
        color: $no-primary-blue;
        font-family: Arial, sans-serif;
        font-size: 11px;
        height: 29px;
        border-bottom: 1px solid $column-filter-shadow-2;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &--last {
            border-bottom: 1px solid transparent;
        }
    }

    &__arrow-back-text {
        color: $primary-color;
        font-family: MTTMilano-Bold, sans-serif;
        font-size: 11px;
        text-transform: uppercase;
    }

    &__button-box-none {
        color: $primary-color;
        border: none;
        padding: 0;
        margin: 8px 10px;
        width: 20px;
        height: 20px;
        outline: none;
        background-color: transparent;
        font-size: 20px;
    }
    &__column-with-icon{
      width: calc(100% - 20px);
      float: left;
    }
}

.compliance{
  &__dark-blue{
      .documentation-list {
          &--no-border{
              border: 1px dotted $primary-color;
          }
          &__block-body {
              padding: 5px 10px 0;
          }
          &__table-icon {
              color: $primary-color;
          }
          &__list-menu-expanded {
              left: -194px !important;
              &--submenu{
                  left: -401px !important;
              }
              &--no-border{
                border-color: transparent;
              }
          }
          &__fixed-aux {
              background-color: $background-grey-other;
          }
          &__action-fixed {
              &--header {
                  background-color: $background-grey-other;
                  color: $primary-color;
              }
          }
          &__miga {
              background-color: $primary-color;
              color: $snow-white;
          }
          &__icon-confidential {
              color: $primary-color;
          }
          &__height-tab {
              &:hover {
                  background-color: $background-color-states;
              }
              &--selected{
                  background-color: $background-color-states;
              }
              &--folder{
                  background-color: $main-col-table;
                  &:hover {
                      background-color: $primary-color;
                      color: $snow-white;
                      .common__col-text,
                      .documentation-list__table-icon--folder,
                      .documentation-list__table-icon--angle,
                      .documentation-list__icon-confidential,
                      .documentation-list__col-text-folder {
                          color: $snow-white;
                      }
                  }
              }
              &--folder-selected {
                  background-color: $primary-color;
                  color: $snow-white;
                  .common__col-text,
                  .documentation-list__table-icon--folder,
                  .documentation-list__table-icon--angle,
                  .documentation-list__icon-confidential,
                  .documentation-list__col-text-folder {
                      color: $snow-white;
                  }
                  .list-in-dropdown__cell-block-list{
                    .common__col-text{
                      color: $secondary-color;
                    }
                  }
              }
          }
          &__trash-panel-deleted-amount {
              background-color: $background-color-states;
          }
          &__table-header {
              color: $primary-color;
          }
          &__icon-multiple {
              &--error{
                  color: $red;
              }
              &--disabled{
                  @include documentation-list-icon-multiple-disabled($subcontainer-ground);

              }
          }
          &__col-text-folder {
              color: $primary-color;
              font-family: MTTMilano-Bold, sans-serif;
          }
          &__arrow-back-text {
              color: $primary-color;
          }
      }
  }
  &__steel-blue,
  &__uk-steel-blue{
      .documentation-list {
          &--no-border{
              border: 1px solid $third-color-2;
          }
          &__block-body {
              padding: 10px 20px 0;
          }
          &__dropzone {
              background-color: $block-hover-2;
              opacity: 0.8;
          }
          &__dropzone-icon {
              @include color($primary-color-2);
          }
          &__dropzone-text {
              @include color($primary-color-2);
          }
          &__table-icon {
              color: $border-hover-2;
          }
          &__options-button {
              color: $border-hover-2;
          }
          &__block-multiple-options {
              background-color: $third-color-2;
              border: 1px solid $secondary-color-2;
          }
          &__list-menu-expanded {
              border-color: $third-color-2;
              &--no-border{
                border-color: transparent;
              }
          }
          &__fixed-aux {
              background-color: $third-color-2;
          }
          &__action-fixed {
              &--header {
                  background-color: $third-color-2;
              }
          }
          &__menu-action {
              background-color: $third-color-2;
              color: $border-hover-2;
              &:focus {
                  outline: none;
              }
          }
          &__miga {
              background-color: $primary-color-2;
              color: $white-special-2;
              opacity: 1;
          }
          &__element-breadcrumb {
              opacity: 1;
          }
          &__icon-mark {
              opacity: 1;
          }
          &__title-menu-option {
              background-color: $primary-color-2;
              color: $white-special-2;
          }
          &__icon-confidential {
              color: $border-hover-2;
          }
          &__icon-certified {
              color: $border-hover-2;
          }
          &__height-tab {
              border-bottom: 1px solid $secondary-color-2;
              &:hover {
                  background-color: $block-hover-2;
              }
              &--selected{
                  border-bottom: 1px solid $secondary-color-2;
                  background-color: $block-hover-2;
              }
              &--folder{
                  background-color: $primary-color-2;
                  color: $white-special-2;
                  .common__col-text,
                  .documentation-list__table-icon--folder,
                  .documentation-list__table-icon--angle,
                  .documentation-list__icon-confidential,
                  .documentation-list__col-text-folder {
                      color: $white-special-2;
                  }
                  .list-in-dropdown__cell-block-list{
                    .common__col-text{
                      color: $primary-color-2;
                    }
                  }
              }
          }
          &__trash-panel {
              background-color: $third-color-2;
              color: $border-hover-2;
              font-family: MTTMilano-Bold, sans-serif;
              margin: 0 10px;
              width: calc(100% - 40px);
              position: relative;
              top: -1px;
          }
          &__trash-panel-deleted-amount {
              color: $primary-color-2;
              background-color: transparent;
              border: 1px solid $border-shadow-3;
          }
          &__trash-panel-delete-icon {
              color: $border-hover-2;
          }
          &__trash-panel-text {
              font-family: 'MTTMilano-Bold', sans-serif;
              color: $text-field-color-2;
          }
          &__table-header {
              color: $border-hover-2;
              font-family: MTTMilano, sans-serif;
          }
          &__icon-multiple {
              color: $border-hover-2;
              &--error{
                  color: $alert-color-2;
              }
              &--disabled{
                  @include documentation-list-icon-multiple-disabled($grey-icons-2);
              }
          }
          &__col-text-folder {
              color: $primary-color-2;
              font-weight: bold;
          }
          &__arrow-back-text {
              color: $border-hover-2;
              font-weight: normal;
          }
          &__button-box-none {
              color: $border-hover-2;
          }
      }
  }
  &__icam-red{
      .documentation-list {
          &__block-body {
              padding: 10px 20px 0;
          }
          &__dropzone {
              background-color: $hover-block-icam;
              opacity: 0.8;
          }
          &__dropzone-icon {
              @include color($custom-black-landing);
          }
          &__dropzone-text {
              @include color($custom-black-landing);
          }
          &__table-icon {
              color: $primary-color-icam;
          }
          &__options-button {
              color: $primary-color-icam;
          }
          &__block-multiple-options {
              background-color: $primary-color-icam;
              border: 1px solid $primary-color-icam;
          }
          &__list-menu-expanded {
              border-color: $primary-color-icam;
              &--no-border{
                border-color: transparent;
              }
          }
          &__fixed-aux {
              background-color: $background-grey-disabled-icam;
          }
          &__action-fixed {
              &--header {
                  background-color: $background-grey-disabled-icam;
              }
          }
          &__menu-action {
              background-color: $background-grey-disabled-icam;
              color: $primary-color-icam;
              &:focus {
                  outline: none;
              }
          }
          &__table-container {
              min-width: 1510px;
          }
          &__miga {
              background-color: $custom-black-landing;
              color: $snow-white;
              opacity: 1;
          }
          &__title-menu-option {
              background-color: $primary-color-icam;
          }
          &__icon-confidential {
              color: $primary-color-icam;
          }
          &__icon-certified {
              color: $primary-color-icam;
          }
          &__height-tab {
              border-bottom: 1px solid $border-block-icam;
              &:hover {
                  background-color: $hover-block-icam;
              }
              &--selected{
                  border-bottom: 1px solid $border-block-icam;
                  background-color: $hover-block-icam;
              }
              &--folder{
                  background-color: $border-block-icam;
                  &:hover {
                      background-color: $hover-block-icam;
                      color: $custom-black-landing;
                      .common__col-text,
                      .documentation-list__table-icon--folder,
                      .documentation-list__table-icon--angle,
                      .documentation-list__icon-confidential,
                      .documentation-list__col-text-folder {
                          color: $primary-color-icam;
                      }
                  }
              }
              &--folder-selected{
                  background-color: $border-block-icam;
                  color: $custom-black-landing;
                  .common__col-text,
                  .documentation-list__table-icon--folder,
                  .documentation-list__table-icon--angle,
                  .documentation-list__icon-confidential,
                  .documentation-list__col-text-folder {
                      color: $custom-black-landing;
                  }
              }
          }
          &__trash-panel {
              background-color: $third-color-icam;
              color: $primary-color-icam;
              font-family: MTTMilano-Bold, sans-serif;
              margin: 0 10px;
              width: calc(100% - 40px);
              position: relative;
              top: -1px;
          }
          &__trash-panel-deleted-amount {
              color: $custom-black-landing;
              background-color: transparent;
              border: 1px solid $primary-color-icam;
          }
          &__trash-panel-delete-icon {
              color: $primary-color-icam;
          }
          &__trash-panel-text {
              font-family: 'MTTMilano-Bold', sans-serif;
              color: $modal-question-icam;
          }
          &__icon-multiple {
              color: $primary-color-icam;
              &--disabled{
                  @include documentation-list-icon-multiple-disabled($background-grey-disabled-icam);
              }
          }
          &__col-text-folder {
              color: $custom-black-landing;
              font-weight: bold;
          }
          &__arrow-back-text {
              color: $primary-color-icam;
              font-weight: normal;
          }
          &__button-box-none {
              color: $snow-white;
          }
      }
  }
  &__gm-law{
      .documentation-list {
          &__block-body {
              padding: 10px 20px 0;
          }
          &__dropzone {
              background-color: $hover-block-gm;
              opacity: 0.8;
          }
          &__dropzone-icon {
              @include color($custom-black-landing);
          }
          &__dropzone-text {
              @include color($custom-black-landing);
          }
          &__table-icon {
              color: $primary-color-gm;
          }
          &__options-button {
              color: $primary-color-gm;
          }
          &__block-multiple-options {
              background-color: $primary-color-gm;
              border: 1px solid $primary-color-gm;
          }
          &__list-menu-expanded {
              border-color: $primary-color-gm;
              &--no-border{
                border-color: transparent;
              }
          }
          &__fixed-aux {
              background-color: $background-grey-disabled-gm;
          }
          &__action-fixed {
              &--header {
                  background-color: $background-grey-disabled-gm;
              }
          }
          &__menu-action {
              background-color: $background-grey-disabled-gm;
              color: $primary-color-gm;
              &:focus {
                  outline: none;
              }
          }
          &__miga {
              background-color: $custom-black-landing;
              color: $snow-white;
              opacity: 1;
          }
          &__title-menu-option {
              background-color: $primary-color-gm;
          }
          &__icon-confidential {
              color: $primary-color-gm;
          }
          &__icon-certified {
              color: $primary-color-gm;
          }
          &__height-tab {
              border-bottom: 1px solid $border-block-gm;
              &:hover {
                  background-color: $hover-block-gm;
              }
              &--selected {
                  border-bottom: 1px solid $border-block-gm;
                  background-color: $hover-block-gm;
              }
              &--folder {
                  background-color: $border-block-gm;
                  &:hover {
                      background-color: $hover-block-gm;
                      color: $custom-black-landing;

                      .common__col-text, .documentation-list__table-icon--folder, .documentation-list__table-icon--angle, .documentation-list__icon-confidential, .documentation-list__col-text-folder {
                          color: $primary-color-gm;
                      }
                  }
              }
              &--folder-selected {
                  background-color: $border-block-gm;
                  color: $custom-black-landing;

                  .common__col-text, .documentation-list__table-icon--folder, .documentation-list__table-icon--angle, .documentation-list__icon-confidential, .documentation-list__col-text-folder {
                      color: $custom-black-landing;
                  }
              }
          }
          &__trash-panel {
              background-color: $third-color-gm;
              color: $primary-color-gm;
              font-family: MTTMilano-Bold, sans-serif;
              margin: 0 10px;
              width: calc(100% - 40px);
              position: relative;
              top: -1px;
          }
          &__trash-panel-deleted-amount {
              color: $custom-black-landing;
              background-color: transparent;
              border: 1px solid $primary-color-gm;
          }
          &__trash-panel-delete-icon {
              color: $primary-color-gm;
          }
          &__trash-panel-text {
              font-family: 'MTTMilano-Bold', sans-serif;
              color: $modal-question-gm;
          }

          &__icon-multiple {
              color: $primary-color-gm;
              &--disabled {
                  @include documentation-list-icon-multiple-disabled($background-grey-disabled-gm);
              }
          }
          &__col-text-folder {
              color: $custom-black-landing;
              font-weight: bold;
          }
          &__arrow-back-text {
              color: $primary-color-gm;
              font-weight: normal;
          }
          &__button-box-none {
              color: $snow-white;
          }
      }
  }
  &__com-de{
    .documentation-list {
        &__block-body {
            padding: 10px 20px 0;
        }
        &__dropzone {
            background-color: $hover-block-com-de;
            opacity: 0.8;
        }
        &__dropzone-icon {
            @include color($custom-black-landing);
        }
        &__dropzone-text {
            @include color($custom-black-landing);
        }
        &__table-icon {
            color: $primary-color-com-de;
        }
        &__options-button {
            color: $primary-color-com-de;
        }
        &__block-multiple-options {
            background-color: $primary-color-com-de;
            border: 1px solid $primary-color-com-de;
        }
        &__list-menu-expanded {
            border-color: $primary-color-com-de;
            &--no-border{
              border-color: transparent;
            }
        }
        &__fixed-aux {
            background-color: $background-grey-disabled-com-de;
        }
        &__action-fixed {
            &--header {
                background-color: $background-grey-disabled-com-de;
            }
        }
        &__menu-action {
            background-color: $background-grey-disabled-com-de;
            color: $primary-color-com-de;
            &:focus {
                outline: none;
            }
        }
        &__miga {
            background-color: $custom-black-landing;
            color: $snow-white;
            opacity: 1;
        }
        &__title-menu-option {
            background-color: $primary-color-com-de;
        }
        &__icon-confidential {
            color: $primary-color-com-de;
        }
        &__icon-certified {
            color: $primary-color-com-de;
        }
        &__height-tab {
            border-bottom: 1px solid $border-block-com-de;
            &:hover {
                background-color: $hover-block-com-de;
            }
            &--selected {
                border-bottom: 1px solid $border-block-com-de;
                background-color: $hover-block-com-de;
            }
            &--folder {
                background-color: $border-block-com-de;
                &:hover {
                    background-color: $hover-block-com-de;
                    color: $custom-black-landing;

                    .common__col-text, .documentation-list__table-icon--folder, .documentation-list__table-icon--angle, .documentation-list__icon-confidential, .documentation-list__col-text-folder {
                        color: $primary-color-com-de;
                    }
                }
            }
            &--folder-selected {
                background-color: $border-block-com-de;
                color: $custom-black-landing;

                .common__col-text, .documentation-list__table-icon--folder, .documentation-list__table-icon--angle, .documentation-list__icon-confidential, .documentation-list__col-text-folder {
                    color: $custom-black-landing;
                }
            }
        }
        &__trash-panel {
            background-color: $third-color-com-de;
            color: $primary-color-com-de;
            font-family: MTTMilano-Bold, sans-serif;
            margin: 0 10px;
            width: calc(100% - 40px);
            position: relative;
            top: -1px;
        }
        &__trash-panel-deleted-amount {
            color: $custom-black-landing;
            background-color: transparent;
            border: 1px solid $primary-color-com-de;
        }
        &__trash-panel-delete-icon {
            color: $primary-color-com-de;
        }
        &__trash-panel-text {
            font-family: 'MTTMilano-Bold', sans-serif;
            color: $modal-question-com-de;
        }

        &__icon-multiple {
            color: $primary-color-com-de;
            &--disabled {
                @include documentation-list-icon-multiple-disabled($background-grey-disabled-com-de);
            }
        }
        &__col-text-folder {
            color: $custom-black-landing;
            font-weight: bold;
        }
        &__arrow-back-text {
            color: $primary-color-com-de;
            font-weight: normal;
        }
        &__button-box-none {
            color: $snow-white;
        }
    }
  }
  &__com-fr{
    .documentation-list {
        &__block-body {
            padding: 10px 20px 0;
        }
        &__dropzone {
            background-color: $hover-block-com-fr;
            opacity: 0.8;
        }
        &__dropzone-icon {
            @include color($custom-black-landing);
        }
        &__dropzone-text {
            @include color($custom-black-landing);
        }
        &__table-icon {
            color: $primary-color-com-fr;
        }
        &__options-button {
            color: $primary-color-com-fr;
        }
        &__block-multiple-options {
            background-color: $primary-color-com-fr;
            border: 1px solid $primary-color-com-fr;
        }
        &__list-menu-expanded {
            border-color: $primary-color-com-fr;
            &--no-border{
              border-color: transparent;
            }
        }
        &__fixed-aux {
            background-color: $background-grey-disabled-com-fr;
        }
        &__action-fixed {
            &--header {
                background-color: $background-grey-disabled-com-fr;
            }
        }
        &__menu-action {
            background-color: $background-grey-disabled-com-fr;
            color: $primary-color-com-fr;
            &:focus {
                outline: none;
            }
        }
        &__miga {
            background-color: $custom-black-landing;
            color: $snow-white;
            opacity: 1;
        }
        &__title-menu-option {
            background-color: $primary-color-com-fr;
        }
        &__icon-confidential {
            color: $primary-color-com-fr;
        }
        &__icon-certified {
            color: $primary-color-com-fr;
        }
        &__height-tab {
            border-bottom: 1px solid $border-block-com-fr;
            &:hover {
                background-color: $hover-block-com-fr;
            }
            &--selected {
                border-bottom: 1px solid $border-block-com-fr;
                background-color: $hover-block-com-fr;
            }
            &--folder {
                background-color: $border-block-com-fr;
                &:hover {
                    background-color: $hover-block-com-fr;
                    color: $custom-black-landing;

                    .common__col-text, .documentation-list__table-icon--folder, .documentation-list__table-icon--angle, .documentation-list__icon-confidential, .documentation-list__col-text-folder {
                        color: $primary-color-com-fr;
                    }
                }
            }
            &--folder-selected {
                background-color: $border-block-com-fr;
                color: $custom-black-landing;

                .common__col-text, .documentation-list__table-icon--folder, .documentation-list__table-icon--angle, .documentation-list__icon-confidential, .documentation-list__col-text-folder {
                    color: $custom-black-landing;
                }
            }
        }
        &__trash-panel {
            background-color: $third-color-com-fr;
            color: $primary-color-com-fr;
            font-family: MTTMilano-Bold, sans-serif;
            margin: 0 10px;
            width: calc(100% - 40px);
            position: relative;
            top: -1px;
        }
        &__trash-panel-deleted-amount {
            color: $custom-black-landing;
            background-color: transparent;
            border: 1px solid $primary-color-com-fr;
        }
        &__trash-panel-delete-icon {
            color: $primary-color-com-fr;
        }
        &__trash-panel-text {
            font-family: 'MTTMilano-Bold', sans-serif;
            color: $modal-question-com-fr;
        }

        &__icon-multiple {
            color: $primary-color-com-fr;
            &--disabled {
                @include documentation-list-icon-multiple-disabled($background-grey-disabled-com-fr);
            }
        }
        &__col-text-folder {
            color: $custom-black-landing;
            font-weight: bold;
        }
        &__arrow-back-text {
            color: $primary-color-com-fr;
            font-weight: normal;
        }
        &__button-box-none {
            color: $snow-white;
        }
    }
  }
}

@media (min-width: 1599px){
.documentation-list{
    &__table-container{
        &--close{
            // min-width: 1720px;
            min-width: 1790px;
        }
        &--close-multiple{
            // min-width: 1760px;
            min-width: 1830px;
        }
        &--multiple{
            // min-width: 1505px;
            min-width: 1575px;
        }
        &--no-has-processes.documentation-list__table-container{
            &--close{
                // min-width: 1625px;
                min-width: 1695px;
            }
            &--close-multiple{
                // min-width: 1625px;
                min-width: 1695px;
            }
            &--multiple{
                // min-width: 1335px;
                min-width: 1405px;
            }
        }
    }
    &__table, &__table-header{
        &--format-close{
            width: 80px;
        }
        &--title{
            width: 280px;
        }
        &--source-close{
            width: 260px;
        }
        &--creation-date-close{
            width: 120px;
        }
        &--author-close {
            width: 180px;
        }
        &--modification-date-close{
            width: 120px;
        }
        &--state-close {
            width: 150px;
        }
        &--state-process-close {
            width: 260px;
        }
    }
}
.compliance{
  &__icam-red{
    .documentation-list{
      &__table-container{
        &--close{
            // min-width: 1700px;
            min-width: 1770px;
        }
        &--close-multiple{
            // min-width: 1740px;
            min-width: 1810px;
        }
        &--multiple{
            // min-width: 1480px;
            min-width: 1550px;
        }
      }
    }
  }
}
}

@media (min-width: 1367px) and (max-width: 1598px){
.documentation-list{
    &__table-container{
        // min-width: 1375px;
        min-width: 1445px;
        &--close{
            // min-width: 1490px;
            min-width: 1560px;
        }
        &--close-multiple{
            // min-width: 1530px;
            min-width: 1600px;
        }
        &--multiple{
            // min-width: 1415px;
            min-width: 1485px;
        }
        &--no-has-processes.documentation-list__table-container{
            // min-width: 1195px;
            min-width: 1265px;
            &--close{
                // min-width: 1270px;
                min-width: 1340px;
            }
            &--close-multiple{
                // min-width: 1310px;
                min-width: 1380px;
            }
            &--multiple{
                // min-width: 1235px;
                min-width: 1305px;
            }
        }
    }
    &__table, &__table-header{
        &--format-close{
            width: 80px;
        }
        &--title{
            width: 200px;
            // min-width: 20%;
        }
        &--source{
            width: 200px;
        }
        &--source-close{
            width: 220px;
        }
        &--creation-date-close{
            width: 110px;
        }
        &--author-close {
            width: 170px;
        }
        &--modification-date-close{
            width: 110px;
        }
        &--state-close {
            width: 120px;
        }
        &--state-process {
            width: 180px;
        }
        &--state-process-close {
            width: 220px;
        }
    }
}
.compliance{
  &__icam-red{
    .documentation-list{
      &__table-container{
        min-width: 1420px;
        &--close{
            // min-width: 1470px;
            min-width: 1540px;
        }
        &--close-multiple{
            // min-width: 1510px;
            min-width: 1580px;
        }
        &--multiple{
            // min-width: 1390px;
            min-width: 1460px;
        }
      }
    }
  }
}
}

@media (max-width: 1366px){
.documentation-list{
    &__table-container{
        // min-width: 1375px;
        min-width: 1445px;
        &--close{
            // min-width: 1375px;
            min-width: 1445px;
        }
        &--close-multiple, &--multiple{
            // min-width: 1415px;
            min-width: 1485px;
        }
        &--no-has-processes.documentation-list__table-container{
            // min-width: 1195px;
            min-width: 1265px;
            &--close{
                // min-width: 1195px;
                min-width: 1265px;
            }
            &--close-multiple, &--multiple{
                // min-width: 1235px;
                min-width: 1305px;
            }
        }
    }
    &__table, &__table-header{
        &--title{
            width: 200px;
        }
        &--source{
            width: 200px;
        }
        &--state-process {
            width: 180px;
        }
    }
}
.compliance{
  &__icam-red{
    .documentation-list{
      &__table-container{
        // min-width: 1345px;
        min-width: 1415px;
        &--close{
            // min-width: 1345px;
            min-width: 1415px;
        }
        &--close-multiple, &--multiple{
            // min-width: 1385px;
            min-width: 1455px;
        }
      }
    }
  }
}
}
