.box-protection-data {
  &__custom-header-title{
      width: calc(100% - 52px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }

  &__block-form-info{
          padding: 22px 25px 15px 39px;
  }

  &__header-info{
      color: $custom-black-landing;
      font-family: 'lato_bold', sans-serif;
      font-size: 11px;
      line-height: 15px;
      text-transform: uppercase;
      border-bottom: 2px solid $border-modal;
      padding: 0 0 6px 4px;
      margin-bottom: 14px;
  }

  &__text-info{
      padding-right: 3px;
      color: $attachment-custom-grey-other;
      font-family: 'lato_regular', sans-serif;
      font-size: 13px;
      line-height: 23px;
      & a{
          text-decoration: underline;
          color: $custom-color-3B92D6;
      }
  }
}
