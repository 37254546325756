.schedule-next-event{
  position: relative;
  float: left;
  width: 100%;
  height: 125px;
  border: 4px solid $result-search-quote-color;
  margin: 20px 0 0;
  padding: 17px 20px 0;
  &__block-header-panel{
      display: inline-block;
      width: 100%;
  }
  &__icon-header{
      position: relative;
      float: left;
      font-size: 30px;
      color: $pillow-group;
  }
  &__title-header-panel{
      position: relative;
      float: left;
      top: 5px;
      margin: 0;
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 20px;
      max-width: calc(100% - 64px);
      padding-left: 13px;
  }
  &__button-open-window{
      position: relative;
      float: right;
      background-color: transparent;
      border: none;
      outline: none;
      color: $primary-color;
      font-size: 30px;
      padding: 0;
  }

  &__block-body-panel{
      padding-left: 44px;
      position: relative;
      float: left;
      width: 100%;
      line-height: 15px;
  }
  &__block-clients-repeat{
      margin-bottom: 9px;
      border-left: 1px solid $background-grey-other;
      padding: 4px 0 2px 9px;
      position: relative;
      left: -9px;
      min-height: 50px;
  }
  &__title-info-schedule{
      color: $primary-color;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
  }

  &__icon-info-schedule{
      font-size: 19px;
      margin-left: 4px;
  }
  &__text-info-schedule{
      color: $pillow-group;
      font-family: MTTMilano, sans-serif;
      font-size: 13px;
      line-height: 18px;
      margin: 0;
  }
  &__text-ubication-info-schedule{
      font-family: MTTMilano-Bold, sans-serif;
  }
}

.compliance{
  // &__dark-blue{
  //   .schedule-next-event{
  //   }
  // }
  &__steel-blue{
    .schedule-next-event{
      border-color: $background-highlight-text-2;
      &__icon-header{
          color: $primary-color-2;
      }
      &__title-header-panel{
          color: $primary-color-2;
      }
      &__button-open-window{
          color: $border-hover-2;
      }
      &__block-clients-repeat{
          border-color: $background-grey-disabled-2;
      }
      &__title-info-schedule{
          color: $primary-color-2;
      }
      &__text-info-schedule{
          color: $grey-icons-2;
      }
    }
  }
  &__icam-red{
    .schedule-next-event{
      border: none;
      background-color: $snow-white;
      box-shadow: $box-shadow-icam;
      &__icon-header{
          color: $title-text-icam;
      }
      &__title-header-panel{
          color: $title-text-icam;
      }
      &__button-open-window{
          color: $primary-color-icam;
      }
      &__block-clients-repeat{
          border-color: $border-block-icam;
      }
      &__title-info-schedule{
          color: $custom-black-landing;
      }
      &__text-info-schedule{
          color: $text-field-color-icam;
      }
      &__text-ubication-info-schedule{
          color: $text-field-color-icam;
      }
    }
  }
  &__gm-law{
    .schedule-next-event{
      border: none;
      background-color: $snow-white;
      box-shadow: $box-shadow-gm;
      &__icon-header{
          color: $title-text-gm;
      }
      &__title-header-panel{
          color: $title-text-gm;
      }
      &__button-open-window{
          color: $primary-color-gm;
      }
      &__block-clients-repeat{
          border-color: $border-block-gm;
      }
      &__title-info-schedule{
          color: $custom-black-landing;
      }
      &__text-info-schedule{
          color: $text-field-color-gm;
      }
      &__text-ubication-info-schedule{
          color: $text-field-color-gm;
      }
    }
  }
  &__com-fr{
    .schedule-next-event{
      margin: 0px;
      border: none;
      background-color: $snow-white;
      box-shadow: $box-shadow-com-fr;
      &__icon-header{
          color: $title-text-com-fr;
      }
      &__title-header-panel{
          color: $title-text-com-fr;
      }
      &__button-open-window{
          color: $primary-color-com-fr;
      }
      &__block-clients-repeat{
          border-color: $border-block-com-fr;
      }
      &__title-info-schedule{
          color: $custom-black-landing;
      }
      &__text-info-schedule{
          color: $text-field-color-com-fr;
      }
      &__text-ubication-info-schedule{
          color: $text-field-color-com-fr;
      }
    }
  }
  &__com-de{
    .schedule-next-event{
      border: none;
      background-color: $snow-white;
      box-shadow: $box-shadow-com-de;
      &__icon-header{
          color: $title-text-com-de;
      }
      &__title-header-panel{
          color: $title-text-com-de;
      }
      &__button-open-window{
          color: $primary-color-com-de;
      }
      &__block-clients-repeat{
          border-color: $border-block-com-de;
      }
      &__title-info-schedule{
          color: $custom-black-landing;
      }
      &__text-info-schedule{
          color: $text-field-color-com-de;
      }
      &__text-ubication-info-schedule{
          color: $text-field-color-com-de;
      }
    }
  }
}
