.process-record {
    margin-top: 35px;

    &__icon-arrow{
        position: relative;
        top: 1px;
        left: 1px;
    }

    &__block-transitions {
        list-style-type: none;
        padding: 0 0 10px 0;
        color: $primary-color;
        margin: 56px 0 5px 20px;
        font-size: 12px;
        position: relative;
    }

    &__element-transition {
        height: 55px;
        border-left: 1px solid $element-transition;
    }

    &__block-position-element{
        position: relative;
        bottom: 6px;
    }

    &__state-element {
        border: 2px solid $subcontainer-next;
        border-radius: 120px;
        padding-top: 2px;
        padding-left: 23px;
        padding-right: 23px;
        height: 23px;
        color: $secondary-color;
        margin-left: 10px;
        white-space: nowrap;
        display: inline-block;
        vertical-align: middle;
        max-width: calc(100% - 35px);
        overflow: hidden;
        text-overflow: ellipsis;

        &--current {
            color: $snow-white;
            border-color: $state-element-current;
            background-color: $state-element-current;
        }
        &--custom{
            font-family: 'MTTMilano-bold', sans-serif;
            font-size: 10px;
            padding: 2px 7px;
            margin-left: 0;
            position: relative;
            height: 21px;
        }
    }

    &__circle-icon{
        position: relative;
        right: 7px;
    }

    &__block-date-time {
        margin-top: 5px;
        margin-left: 35px;
    }

    &__date-text {
        font-weight: bold;
    }

    &__read-more-block{
        margin-left: 30px;
    }
    ////////////////////////////////////////////
    //////////////Custom///////////////////////
    //////////////////////////////////////////
    &__custom-block-transitions{
        list-style-type: none;
        padding: 0 0 10px 0;
        color: $primary-color;
        margin: 20px 0 0;
        font-size: 12px;
        position: relative;
    }
    &__custom-date-block-position{
        height: 54px;
        border-left: 2px solid $custom-secondary-color;
        margin-left: 22px;
        padding: 24px 0px 0 13px;
    }
    &__custom-date-text{
        color: $attachment-custom-grey;
        font-family: 'lato_regular', sans-serif;
        font-size: 13px;
        font-weight: bold;
    }
    &__custom-time-text{
        color: $attachment-custom-grey;
        font-family: 'lato_regular', sans-serif;
        font-size: 13px;
        margin-left: 4px;
    }
    &__custom-read-more-block{
        margin-left: 30px;
        color: $custom-black-landing;
    }
}
