.documentation-report-list{
    min-height: 600px;
    &__table{
        padding: 55px 20px 10px;
        width: 100%;
        display: block;
        position: relative;
        float: left;
    }

    &__table-body {
        width: 100%;
        display: block;
        position: relative;
        height: 495px;
        overflow-x: hidden;
        overflow-y: auto;
        &--more-elements{
            width: calc(100% + 10px);
        }
    }

    &__table-col {
        display: table-cell;
        vertical-align: middle;
        padding-right: 7px;
        max-width: 0;
        &--top-1{
            position: relative;
            top: 1px;
        }
        &--top-2{
            position: relative;
            top: 2px;
        }
        &--title{
            width: 32%;
            padding-left: 5px;
        }
        &--author{
            width: 32%;
        }
        &--date{
            width: calc(36% - 80px);
        }
        &--actions{
            width: 80px;
            min-width: 80px;
        }
    }

    &__list-menu-block{
        width: 207px;
        border: 1px solid $primary-color;
        background-color: $snow-white;
        position: absolute;
        padding: 0;
        z-index: 2;
        border-radius: 0px;
        float: none;
        inset: auto !important;
        transform: none !important;
        top: 0px !important;
        left: -147px !important;
    }
  }
  .compliance{
    &__dark-blue{
        .documentation-report-list{
            &__list-menu-block{
                border-color: $primary-color;
            }
        }
    }
    &__steel-blue,
    &__uk-steel-blue{
        .documentation-report-list{
            &__list-menu-block{
                border-color: $third-color-2;
                border-top-width: 2px;
            }
        }
    }
    &__icam-red{
        .documentation-report-list{
            &__list-menu-block{
                border-color: $primary-color-icam;
                border-top-width: 2px;
            }
        }
    }
    &__gm-law{
        .documentation-report-list{
            &__list-menu-block{
                border-color: $primary-color-gm;
                border-top-width: 2px;
            }
        }
    }
    &__com-de{
      .documentation-report-list{
          &__list-menu-block{
              border-color: $primary-color-com-de;
              border-top-width: 2px;
          }
      }
    }
    &__com-fr{
      .documentation-report-list{
          &__list-menu-block{
              border-color: $primary-color-com-fr;
              border-top-width: 2px;
          }
      }
    }
  }
