.reports {
  &__block-global {
      margin-bottom: 39px;
  }

  &__block-fold {
      margin-bottom: 20px;
  }

  &__block-title-report {
      position: relative;
      float: left;
      height: 16px;
      max-width: calc(100% - 329px);
      margin-top: 7px;
  }

  &__global-header {
      margin-top: 9px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px;
  }

  &__global-header-title {
      display: flex;
      font-weight: bold;
  }

  &__global-header-title-icon {
      font-size: 25px;
      padding-top: 2px;
  }

  &__global-header-title-text {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      font-family: MTTMilano, sans-serif;
  }

  &__global-header-title-name {
      font-size: 17px;
      text-transform: uppercase;
  }

  &__global-header-title-description {
      font-size: 14px;
      opacity: 0.75;
  }

  &__global-header-buttons-container {
      display: flex;
  }

  &__global-header-button {
      display: flex;
      align-items: center;
      height: 50px;
      background-color: transparent;
      border: 2px solid;
      padding: 13px 20px;

      &--next {
          margin-left: 10px;
      }
  }

  &__global-header-button-icon {
      font-size: 25px;
  }

  &__global-header-button-text {
      font-size: 10px;
      font-family: MTTMilano-Bold, sans-serif;
      text-transform: uppercase;
      padding-left: 10px;
      padding-top: 1px;
  }

  &__principal-report {
      background-color: $background-grey;
      padding: 15px 14px 15px 38px;
      color: $primary-color;
      height: 70px;
  }

  &__principal-report-info {
      width: 40%;
      font-size: 12px;
      display: inline-block;
      // position: relative;
      // top: 8px;
  }

  &__principal-report-info-text {
      position: relative;
      float: left;
      top: -3px;
  }

  &__principal-report-title {
      font-family: MTTMilano, sans-serif;
      //margin: 0;
      font-style: italic;
      font-size: 14px;
      // margin-top: 5px;
      line-height: 13px;
      margin-bottom: 6px;
  }

  &__principal-report-name {
      font-family: Arial, sans-serif;
      font-weight: bold;
      line-height: 12px;
      margin-bottom: 6px;
  }

  &__principal-report-status {
      font-family: Arial, sans-serif;
      font-weight: bold;
      font-size: 10px;
      line-height: 9px;
      text-transform: uppercase;
      margin: 0;
      position: relative;
  }

  &__icon-certified {
      color: $primary-color;
      font-size: 15px;
      position: absolute;
      margin-left: 5px;
      bottom: -4px;
  }

  &__principal-report-options {
      display: block;
      float: right;
      padding: 10px;
      background-color: $background-grey-other;
      border: 1px solid $menu-icons-border;
      border-radius: 50px;
      border-radius: 20px 0 0 20px;
  }

  &__icon-document {
      float: left;
      font-size: 25px;
      margin-right: 8px;
      top: 8px;
      position: relative;
  }

  &__item {
      display: block;
      float: left;
      margin-left: 15px;
      &--none{
        position: relative;
        top: 3px;
      }

      &:hover {
          cursor: pointer;
      }
  }

  &__item-text {
      font-size: 9px;
      font-weight: bold;
      text-transform: uppercase;
      float: right;
      position: relative;
      top: 3px;
      margin: 0;
      &--short{
          width: 39px;
      }
  }

  &__item-icon {
      font-size: 17px;
      margin-right: 5px;

  }

  &__down {
      margin-top: 40px;
      display: inline-block;
      color: $primary-color;

      &:hover {
          cursor: pointer;
      }

      &--icon {
          font-size: 13px;
      }

  }

  &__down-text {
      margin-left: 4px;
      margin-right: 7px;
      text-transform: uppercase;
      font-size: 10px;
      font-family: MTTMilano, sans-serif;
      font-weight: bold;
      display: inline-block;
      position: relative;
      top: -2px;
  }

  &__access-icon {
      font-size: 30px;
      float: right;
      position: relative;
      left: 11px;
      bottom: 2px;
      color: $primary-color-icam;

      &:hover {
          cursor: pointer;
      }
  }

  &__new-version {
      color: $snow-white;
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      background: $delete-amount;
      margin-left: 11px;
      padding: 4px 6px;

      &--concept-tab {
          position: relative;
          bottom: 4px;
          margin-left: 4px;
      }
      &--concept-tab-report-record{
        position: relative;
        margin-left: 4px;
        bottom: 0;
        top: 10px;
      }
      &--report-incident-list{
        position: relative;
        top: 10px;
      }

  }

  &__block-icon-template {
      position: relative;
      float: left;
  }

  &__block-confident-report {
      position: absolute;
      left: 10px;
      top: -12px;
  }

  &__block-bool-fold {
      margin-top: 10px;
  }
}

.compliance{
  &__dark-blue{
      .reports {
          &__global-header {
              background-color: $subcontainer-border;
          }
          &__global-header-title {
              color: $primary-color;
          }
          &__global-header-button {
              border-color: $primary-color;
              color: $primary-color;
              &:hover {
                  background-color: $primary-color;
                  color: $snow-white;
              }
          }
          &__principal-report {
              background-color: $background-color-states;
          }
          &__icon-certified {
              color: $primary-color;
          }
      }
  }
  &__steel-blue{
      .reports {
          &__global-header {
            background-color: $background-grey-disabled-2;
          }
          &__global-header-title {
              color: $primary-color-2;
          }
          &__global-header-button {
            border-color: $border-hover-2;
            color: $border-hover-2;

            &:hover {
                background-color: $border-hover-2;
                color: $snow-white;
            }
          }
          &__global-header-title-description {
              color: $text-field-color-2;
          }
          &__principal-report {
              background-color: $background-grey-disabled-2;
              color: $primary-color-2;
          }
          &__principal-report-title {
              color: $text-field-color-2;
          }
          &__icon-certified {
              color: $border-hover-2;
          }
          &__principal-report-options {
              background-color: $third-color-2;
              border-color: $third-color-2;
          }
          &__item-icon {
              color: $border-hover-2;
          }
          &__down {
            color: $primary-color-2;
          }
      }
  }
  &__uk-steel-blue{
      .reports {
          &__icon-certified {
              color: $border-hover-2;
          }
      }
  }
  &__icam-red{
      .reports {
          &__icon-certified {
              color: $primary-color-icam;
          }
      }
  }
  &__gm-law{
      .reports {
          &__block-global {
              box-shadow: 0 0 7px 1px $border-buttons-gm;
          }
          &__global-header {
              background-color: $third-color-gm;
              height: 74px;
          }
          &__global-header-title {
              color: $custom-black-landing;
          }
          &__global-header-title-description {
              opacity: 1;
              color: $text-field-color-gm;
          }
          &__global-header-button {
              display: block;
              border-color: transparent;
              color: $custom-black-landing;
              padding: 0;
           }
          &__global-header-button-icon {
              color: $primary-color-gm;
              float: right;
          }
          &__global-header-button-text {
              margin-right: 7px;
              text-transform: none;
              font-size: 16px;
          }
          &__principal-report {
              background-color: $hover-block-gm;
              color: $custom-black-landing;
          }
          &__principal-report-title {
              color: $text-field-color-gm;
          }
          &__icon-certified {
              color: $primary-color-gm;
          }
          &__principal-report-options {
              background-color: $primary-color-gm;
              border-color: $primary-color-gm;
              color: $snow-white;
          }

          &__down {
              color: $primary-color-gm;
          }
          &__down-text {
              margin: 0;
              color: $custom-black-landing;
          }
          &__box-shadow {
              display: block;
              box-shadow: 0 0 7px 1px $border-buttons-gm;
          }
          &__new-version{
            &--report-incident-list{
                position: relative;
                top: 5px;
            }
          }
      }
  }
  &__com-de{
    .reports {
        &__block-global {
            box-shadow: 0 0 7px 1px $border-buttons-com-de;
        }
        &__global-header {
            background-color: $third-color-com-de;
            height: 74px;
        }
        &__global-header-title {
            color: $custom-black-landing;
        }
        &__global-header-title-description {
            opacity: 1;
            color: $text-field-color-com-de;
        }
        &__global-header-button {
            display: block;
            border-color: transparent;
            color: $custom-black-landing;
            padding: 0;
         }
        &__global-header-button-icon {
            color: $primary-color-com-de;
            float: right;
        }
        &__global-header-button-text {
            margin-right: 7px;
            text-transform: none;
            font-size: 16px;
        }
        &__principal-report {
            background-color: $hover-block-com-de;
            color: $custom-black-landing;
        }
        &__principal-report-title {
            color: $text-field-color-com-de;
        }
        &__icon-certified {
            color: $primary-color-com-de;
        }
        &__principal-report-options {
            background-color: $primary-color-com-de;
            border-color: $primary-color-com-de;
            color: $snow-white;
        }

        &__down {
            color: $primary-color-com-de;
        }
        &__down-text {
            margin: 0;
            color: $custom-black-landing;
        }
        &__box-shadow {
            display: block;
            box-shadow: 0 0 7px 1px $border-buttons-com-de;
        }
    }
  }
  &__com-fr{
    .reports {
        &__block-global {
            box-shadow: 0 0 7px 1px $border-buttons-com-fr;
        }
        &__global-header {
            background-color: $third-color-com-fr;
            height: 74px;
        }
        &__global-header-title {
            color: $custom-black-landing;
        }
        &__global-header-title-description {
            opacity: 1;
            color: $text-field-color-com-fr;
        }
        &__global-header-button {
            display: block;
            border-color: transparent;
            color: $custom-black-landing;
            padding: 0;
         }
        &__global-header-button-icon {
            color: $primary-color-com-fr;
            float: right;
        }
        &__global-header-button-text {
            margin-right: 7px;
            text-transform: none;
            font-size: 16px;
        }
        &__principal-report {
            background-color: $hover-block-com-fr;
            color: $custom-black-landing;
        }
        &__principal-report-title {
            color: $text-field-color-com-fr;
        }
        &__icon-certified {
            color: $primary-color-com-fr;
        }
        &__principal-report-options {
            background-color: $primary-color-com-fr;
            border-color: $primary-color-com-fr;
            color: $snow-white;
        }

        &__down {
            color: $primary-color-com-fr;
        }
        &__down-text {
            margin: 0;
            color: $custom-black-landing;
        }
        &__box-shadow {
            display: block;
            box-shadow: 0 0 7px 1px $border-buttons-com-fr;
        }
    }
  }
}
