.customize-field-text{
  &__field-text{
      margin-top: 12px;
  }
  &__field-bool{
      height: 30px;
      &--no-error{
          margin-bottom: 101px;
      }
      &--no-height{
          height: 0;
      }
  }
  &__switch-block{
    display: flex;
  }
  &__switch-button-inline{
    display: block;
    margin-right: 19px;
  }
}
