.rappid{
    width: 867px;
    height: 537px;
    &__from-view{
        // width: 1510px;
        // height: 677px;
        width: 100%;
        height: 614px;
    }
    &__box-container{
        position: relative;
    }
    &__box-container-text{
        text-anchor: middle;
        dominant-baseline: middle;
    }
    &__state-box-link{
      background-color: #fff;
      width: 24px;
      border-radius: 50%;
      position: relative;
      top: 1px;
      height: 25px;
      &:hover{
        & .rappid__tooltip-date-text{
            visibility: visible;
        }
      }
    }
    &__state-box-text{
      font-size: 11px;
      text-align: center;
      width: 100%;
      // margin-top: 11px;
      padding: 18px 5px 4px;
      font-weight: bold;
      line-height: 1;
      color: $custom-black-landing;
    }
    &__state-box{
       width: 100%;
       height: 30px;
       padding: 0 2px 4px 0;
       display: flex;
       flex-direction: row;
       align-items: flex-end;
       justify-content: flex-end;
    }
    &__state-box-icon{
        float: right;
        padding: 0 2px;
        &--green{
            color: $green;
            font-size: 17px;
            &.lf-icon-step-done{
              font-size: 24px;
              padding: 0;
            }
        }
        &--blue{
            font-size: 17px;
            color: $primary-color;
            &:hover{
              cursor: pointer;
            }
        }
        &--gray{
            font-size: 17px;
            color: $other-gray;
            &:hover{
              cursor: default;
            }
        }
    }

    &__tooltip-date-text {
      visibility: hidden;
      position: absolute;
      top: 15px;
      left: 22px;
      white-space: nowrap;
      border-radius: 12px;
      padding: 8px 18px;
      line-height: 1;
      font-family: Arial, sans-serif;
      font-size: 10px;
      color: $primary-color;
      background-color: $link-data-text;
      &--show{
        visibility: visible;
      }
  }
}

.compliance{
  &__dark-blue{
    .rappid{
      &__state-box-text{
        color: $primary-color;
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue{
    .rappid{
      &__state-box-icon{
          &--green{
              color: $border-hover-2;
          }
          &--blue{
              color: $border-hover-2;
          }
          &--gray{
              color: $other-gray-special;
          }
      }
    }
  }
  &__icam-red{
    .rappid{
      &__tooltip-date-text {
        color: $custom-black-landing;
        background-color: $hover-block-icam;
      }
      &__state-box-icon{
          &--green{
              color: $primary-color-icam;
          }
          &--blue{
              color: $primary-color-icam;
          }
          &--gray{
              color: $secondary-color-icam;
          }
      }
    }
  }
  &__gm-law{
    .rappid{
      &__tooltip-date-text {
        color: $custom-black-landing;
        background-color: $hover-block-gm;
      }
      &__state-box-icon{
          &--green{
              color: $primary-color-gm;
          }
          &--blue{
              color: $primary-color-gm;
          }
          &--gray{
              color: $secondary-color-gm;
          }
      }
    }
  }
  &__com-de{
    .rappid{
      &__tooltip-date-text {
        color: $custom-black-landing;
        background-color: $hover-block-com-de;
      }
      &__state-box-icon{
          &--green{
              color: $primary-color-com-de;
          }
          &--blue{
              color: $primary-color-com-de;
          }
          &--gray{
              color: $secondary-color-com-de;
          }
      }
    }
  }
  &__com-fr{
    .rappid{
      &__tooltip-date-text {
        color: $custom-black-landing;
        background-color: $hover-block-com-fr;
      }
      &__state-box-icon{
          &--green{
              color: $primary-color-com-fr;
          }
          &--blue{
              color: $primary-color-com-fr;
          }
          &--gray{
              color: $secondary-color-com-fr;
          }
      }
    }
  }
}
