.record-audio-modal{
  &__window{
    & .modal-dialog{
      width: 609px;
        & .modal-content{
            height: 609px;
            width: 609px;
            margin: 0 auto;
        }
    }
    &--landing{
      & .modal-dialog{
        width: 600px;
          & .modal-content{
            height: 545px;
            width: 600px;
            margin: 0 auto;
            border-radius: 4px;
          }
      }

    }
  }

  &__block-body{
    width: 100%;
    padding: 0 60px;
    display: block;
    overflow: hidden;
  }
  &__title{
    display: block;
    width: 460px;
    margin: 0 auto 20px;
  }
  &__description-top{
    margin-top: 18px;
  }
  &__distortion-block{
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }
  &__accept-terms-icon{
    font-size: 15px;
    color: $primary-color;
    float: left;
    &:hover{
      cursor: pointer;
    }

    &--landing{
      font-size: 12px;
      color: #000000;
    }

    &--no-editable{
      color: #aaaaaa;
      &:hover{
        cursor: default;
      }
    }

    &--error{
      color: #C43741;
    }

    &--landing-no-editable{
      color: #898989;
      &:hover{
        cursor: default;
      }
    }

    &--landing-error{
      color: #C10F0B;
    }
  }
  &__accept-terms-text{
    margin: 0;
    color: #001978;
    font-family: MTTMilano;
    font-size: 12px;
    font-weight: bold;
    padding-left: 25px;
    &--check{
      color: #001978;
      padding-left: 0px;
    }
    &--required{
      color: $alert-color-2;
      padding-left: 0px;
    }
    &--no-editable{
      color: #565656;
    }
    &--landing{
      color: #000000;
      font-family: 'lato_regular';
      font-size: 12px;
      padding-left: 14px;
      font-weight: normal;
    }

    &--landing-no-editable{
      color: #898989;
    }
    &--check-landing{
      color: #898989;
      font-family: 'lato_regular';
      font-size: 12px;
      font-weight: normal;
    }
    &--landing-error{
      color: #C10F0B;
    }

  }
  &__voice-dropdown{
    box-sizing: border-box;
    height: 30px;
    width: 160px;
    border: 1px solid #CCD1E4;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    gap: 0;
    align-items: center;
    justify-content: space-between;
    &--open{
      border-color: #001978;
    }
    &--disabled{
      border: 1px solid #CCD1E4;
      background-color: #F4F4F4;
    }
    &--landing{
      border-color: #D8D8D8;
    }
    &--landing-open{
      border-color: #000000;
    }
    &--landing-disabled{
      background: #F4F4F4;
      border-color: #989898;
      &:hover{
        cursor: default;
      }
    }
  }
  &__voice-dropdown-block{
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    &--right{
      justify-content: center;
      padding-right: 6px;
    }
  }

  &__voice-button{
    border: 1px solid #001978;
    border-radius: 50%;
    background: none;
    width: 21px;
    height: 21px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: #001978;
    margin: 0 8px;
    padding: 0;
    &--disabled{
      border: 1px solid #AAAAAA;
      color: #AAAAAA;
    }
    &--list{
      margin-left: 4px;
    }
    &--landing{
      border-color: #000000;
      color: #000000;
    }
    &--landing-disabled{
      border-color: #989898;
      color: #989898;
    }

  }

  &__voice-selection-text{
    color: #565656;
    font-family: Arial;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    &--landing{
      color: #898989;
      font-family: Lato;
      font-size: 12px;
    }
  }

  &__separator-vertical{
    box-sizing: border-box;
    height: 20px;
    width: 1px;
    background: #CCD1E4;
    border-width: 0;
    margin: 0 8px 0 0;
    opacity: 1;
    &--landing{
      background: #989898
    }
  }
  &__voice-dropdown-icon{
    font-size: 16px;
    color: #001978;
    &:hover{
      cursor: pointer;
    }
    &--disabled{
      color: #AAAAAA;
      &.hover{
        cursor: default;
      }
    }
    &--landing{
      color: #000000;
    }
    &--landing-disabled{
      color: #989898;
    }

  }
  &__voice-list-block{
    margin-top: 141px;
    border: 1px solid #CCD1E4;
    border-top: 1px solid #001978;
    box-sizing: border-box;
    height: 112px;
    width: 160px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0px rgba(39,52,78,0.4);
    border-radius: 2px;
    padding: 0 6px;
    left: -1px;
    &--landing{
      border-color:#000000;
    }
  }
  &__list-option{
    height: 25px;
    position: relative;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    height: 30px;
    &:hover{
      background-color: #F2F3F8;
      cursor: pointer;
      .record-audio-modal__select-icon{
        opacity: 1;
      }
      .record-audio-modal__list-option-name--landing{
        color:#000000;
      }
    }
  }
  &__option-box{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 108px;
  }
  &__list-option-name{
    font-family: MTTMilano;
    font-size: 13px;
    font-weight: 500;
    color: #001978;
    margin: 0 16px 0 0;
    &--landing{
      color: #898989;
      font-family: Lato;
      font-size: 12px;
    }
  }
  &__select-icon{
    font-size: 16px;
    color: #001978;
    opacity: 0;
    &--landing{
      color: #000000;
    }
  }

  &__record-box{
    min-height: 210px;
    width: 460px;
    margin: 31px auto 83px;
    background-color: $background-color-states;
    display: block;
    overflow: hidden;
    padding: 0 22px;
    &--apply-distorsion{
      text-align: center;
      padding-top: 42px;
    }
    &--no-discard{
      margin-bottom: 120px;
    }
    &--landing{
      margin: 23px auto 36px;
      background-color: #F4F4F4;
    }
    &--no-discard-landing{
      margin-bottom: 72px;
    }
    &--show-disclaimer{
      margin-bottom: 0;
    }
  }
  &__record-button-block{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  &__record-button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $primary-color;
    border-color: transparent;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 21px 116px 7px;
    &--disabled{
      background-color: #AAAAAA;
    }
    &--disabled-landing{
      background-color: #AAAEB0;
    }
  }
  &__record-button-icon{
    font-size: 25px;
    color: $snow-white;
  }
  &__record-button-text{
    color: $primary-color;
    font-family: MTTMilano;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 13px;

    &--landing{
      color: #000000;
      font-family: 'lato_regular';
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      text-align: center;
    }
  }
  &__time-record-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__record-count{
    height: 19px;
    width: 56px;
    background-color: $subcontainer-ground;
    border-radius: 15px;
    text-align: center;
    color: $snow-white;
    font-family: MTTMilano-bold;
    font-size: 12px;
    padding-top: 1px;
    &--active{
      background:#77D166;
    }
    &--landing{
      background: #898989;
    }
    &--landing-active{
      background: #417505;
    }
  }
  &__record-background-line{
    height: 4px;
    width: 100%;
    background: #AAAAAA;
    border-radius: 5px;
    position: relative;
    &--active{
      background:#77D166;
      margin: 0;
      position: absolute;
      z-index: 10;
    }
    &--field{
      width: 200px;
      margin: 0 20px;
    }
    &--landing{
      background: #898989;
    }
    &--landing-active{
      margin: 0;
      position: absolute;
      z-index: 10;
      background: #417505;
    }
  }
  &__record-max-time{
    color: #565656;
    font-family: MTTMilano-bold;
    font-size: 12px;
    text-align: right;
    &--active{
      color: #898989;
    }
  }

  &__warning-box{
    height: 55px;
    width: 460px;
    background-color: rgba(241, 188, 62, 0.2);
    margin: 10px auto 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &--landing{
      height: 42px;
      background-color: rgba(207, 119, 0, 0.15);
      margin-bottom: 24px;
    }
  }
  &__warning-icon{
    color: #F1BC3E;
    font-size: 35px;
    margin: 0 14px 0 20px;
    &--landing{
      font-size: 30px;
      color: #CF7700;
    }
  }
  &__warning-text{
    margin: 0;
    color: #565656;
    font-family: Arial;
    font-size: 12px;
    &--landing{
      color: #000000;
      font-family: "MTTMilano";
      font-size: 13px;
    }
  }
  &__warning-close{
    color: #565656;
    font-size: 15px;
    margin-left: 104px;
    margin-right: 10px;
    &:hover{
      cursor: pointer;
    }
  }

  &__buttons-block{
    margin: 0px 70px 0;
    display: block;
    text-align: right;
    &--warning-box{
      margin-top: 14px;
    }
  }
  &__not-show{
    visibility: hidden;
    width: 0;
    height: 0;
  }
  &__discard-voice-button{
    color: #3B92D6;
    font-family: "MTTMilano";
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    border-color: transparent;
    background: none;
    border-bottom: 1px solid #3B92D6;
    padding: 0;
    margin:14px 0 16px;
    outline: none;
    &--landing{
    color: #3B92D6;
    font-family: "lato_bold";
    font-size: 13px;
    font-weight: normal;
    text-align: center;
    }
  }
  &__apply-changes-icon{
    font-size: 50px;
    color: #001978;

  }
  &__apply-changes-text{
    color: #565656;
    font-family: MTTMilano;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin: 15px 0 0;
    &--landing{
      color: #000000;
      font-family: Lato;
      font-size: 13px;
      font-weight: bold;
    }
  }
  &__animation{
    margin-top: 26px;
    animation:spin 2000ms infinite ease-in-out;
    &--landing{
      margin-top: 13px;
    }
  }
  &__description-top-landing{
    color: #000000;
    font-family: 'lato_regular';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 10px;
  }
  &__label-check-landing{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 18px 0px;
  }
  &__check-distorsion-box{
    padding: 21px 0 18px;
  }
}
@keyframes spin {
  100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
  }
}

.compliance{
  &__gm-law{
      .record-audio-modal{
          &__accept-terms-icon, &__apply-changes-icon{
            color: #133371;
            &--no-editable{
              color: #858E94
            }
          }

          &__record-button{
            background-color: #133371;
            &--disabled{
              background: #858E94;
            }
          }

          &__accept-terms-text, &__record-button-text{
              color: #000000;
              &--no-editable{
                color: #858E94
              }
          }
          &__record-count, &__record-background-line{
            background: #858E94;
            &--active{
              background: #1AB349;
            }
          }
          &__record-max-time{
            color: #858E94;
          }
          &__discard-voice-button{
            color: #3B92D6;
          }
          &__warning-box{
            background-color: rgba(240,191,41, 0.2);
          }
          &__warning-icon, &__warning-text, &__warning-close{
            color:#000000;
          }
      }
  }
}
@media (max-width: $max-width-smartphone) { //Smartphone
  .record-audio-modal {
    &__window--landing{
      & .modal-dialog{
        width: calc(100% - 40px);
        margin: 10px auto;
        & .modal-content{
          width: 100%;
        }
      }
    }
    &__block-body{
      padding: 0 9px;
    }
    &__record-box{
      width: 280px;
    }
    &__record-button{
      margin: 21px 0 7px;
    }
    &__record-box{
      &--no-discard-landing{
        margin-bottom: 27px;
      }
    }
    &__buttons-block {
      margin: 0px 9px 0;
    }
    &__warning-box{
      &--landing{
        margin-left: 7px;
        margin-right: 7px;
        width: calc(100% - 14px);
      }
    }
    &__record-background-line{
      &--field{
        width: 200px;
        margin-left: 12px;
        flex: 1 0 0;
      }
    }
  }
}