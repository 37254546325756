.new-custom-report-identification {
  &__block-body {
      max-width: 800px;
      margin: auto;
  }

  &__product-selection {
      width: 100%;
  }
}
