@mixin pagination-control-pagination($primaryColor, $secondaryColor, $thirdColor){
  &__prev-next-button,
  &__page-arrow{
    color: $secondaryColor;
  }
  & .pagination{
      .page-item {
        .page-link {
          color: $thirdColor;
        }
        .page-number {
          &:hover{
            color: $primaryColor;
          }
        }
        &.active .page-link {
          & .page-number{
            color: $primaryColor;
            border-bottom-color: $secondaryColor;
          }
        }
        &.disabled{
          .page-link {
            .pagination-control__page-arrow {
              color: $thirdColor;
            }
          }
        }
      }
  }
}

.pagination-control {
  display: inline-block;
  &__prev-next-button{
    border: none;
    outline: none;
    background-color: transparent;
    color: $primary-color;
  }
  &__page-arrow{
    font-size: 18px;
    color: $primary-color;
  }
  .pagination {
    margin: 0;
    align-items: center;
    justify-content: center;
    .page-item {
      display: flex;
      align-items: center;
      .page-link {
        border: none;
        color: $subcontainer-ground;
        font-size: 11px;
        font-weight: bold;
        display: flex;
        padding: 0;
        margin: 0;
        background-color: transparent;
        &:hover{
          background-color: transparent;
        }
        &:focus{
          outline: none;
          background-color: transparent;
          box-shadow: none;
        }
        .page-number {
          padding: 0 5px;
          margin: 0 5px;
          font-size: 11px;
          &:hover{
            color: $primary-color;
          }
        }
      }

      &.active .page-link {
        background-color: transparent;
        border-bottom: none;
        margin-top: 3px;
        &:hover{
          cursor: default;
        }
        & .page-number{
          color: $primary-color;
          background-color: unset;
          border-bottom: 3px solid $primary-color;
        }
      }
      &.disabled{
        &:hover{
          cursor: not-allowed;
        }
        .page-link {
          background-color: transparent;
          &:hover{
            cursor: not-allowed;
          }

          .pagination-control__page-arrow {
            color: $subcontainer-ground;
            background-color: unset;
          }
        }
      }
    }
  }
}

.compliance{
  &__steel-blue,
  &__uk-steel-blue{
    .pagination-control {
      @include pagination-control-pagination($primary-color-2, $border-hover-2, $secondary-color-2);
    }
  }
  &__icam-red{
    .pagination-control {
      @include pagination-control-pagination($custom-black-landing, $primary-color-icam, $subcontainer-ground);
    }
  }
  &__gm-law{
    .pagination-control {
      @include pagination-control-pagination($custom-black-landing, $primary-color-gm, $subcontainer-ground);
    }
  }
  &__com-de{
    .pagination-control {
      @include pagination-control-pagination($custom-black-landing, $primary-color-com-de, $subcontainer-ground);
    }
  }
  &__com-fr{
    .pagination-control {
      @include pagination-control-pagination($custom-black-landing, $primary-color-com-fr, $subcontainer-ground);
    }
  }
  &__landing-custom{
    .pagination-control {
      @include pagination-control-pagination($custom-black-landing, $custom-black-landing, $subcontainer-ground);
    }
  }
}
