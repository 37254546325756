.field-type-date {
  &__month-table {
    width: 100%;

    &--month {
      margin-bottom: 6px;
    }
  }

  &__custom-date-picker {
    background-color: transparent;
    margin: 0px 15px 11px;
  }

  &__custom-day-table-header {
    height: 43px;
    position: relative;
  }

  &__custom-month-title {
    color: $custom-black-landing;
    font-family: 'lato_regular', sans-serif;
    font-size: 13px;
    border: 0;
    background: 0;
    outline: none;
    position: relative;
    text-transform: uppercase;
    // bottom: 7px;
    // left: -16px;
  }

  &__previous-button {
    padding: 0 4px 0 5px;
    color: $custom-black-landing;
    border: none;
    background: none;
    outline: none;
    position: relative;
    font-size: 20px;

    &--from-to-calendar {
      font-size: 15px;
    }

    &--landing-custom {
      color: $custom-black-landing;
    }

    &--month {
      position: relative;
      bottom: 5px;
      left: -2px;
    }
  }

  &__next-button {
    padding: 0;
    color: $custom-black-landing;
    border: none;
    background: none;
    outline: none;
    position: relative;
    font-size: 20px;

    &--from-to-calendar {
      font-size: 15px;
    }

    &--landing-custom {
      color: $custom-black-landing;
    }

    &--month {
      position: relative;
      bottom: 5px;
      left: 110px;
    }

    &--year {
      position: relative;
      bottom: 5px;
      left: 60px;
    }
  }

  &__no-dropdown-box {
    transform: translate(0, 48px) !important;
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }

  &__input-icon-alert {
    right: 31px;
  }

  &__input-icon-alert-with-clock {
    right: 57px;
  }

  &__input-icon-calendar {
    right: 31px;
  }
  // &__input-icon-clock{
  //     top: -19px;
  // }
  &__block-header-calendar {
    height: 34px;
  }

  &__block-arrows-calendar {
    position: absolute;
    right: 0px;
    top: 9px;
  }

  &__month-button {
    font-family: Arial, sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    height: 37px;
    width: 119px;
    // width: calc(100% - 10px);
    border: 1px solid;
    background-color: transparent;
    margin: 5px;

    &--landing-custom {
      font-family: 'lato_regular', sans-serif;
      height: 49px;
      border-color: $background-grey;
      color: $custom-black-landing;

      &-current {
        background: $custom-border-grey;
        color: $custom-black-landing;
        border: 2px solid transparent;
      }

      &-active {
        background-color: $custom-black-landing;
        color: $snow-white;
      }

      &:hover {
        background: $custom-border-grey;
        color: $custom-black-landing;
      }
    }
  }

  &__year-button {
    height: 37px;
    width: 67px;
    // width: calc(100% - 10px);
    border: 1px solid;
    background-color: transparent;
    font-family: Arial, sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    margin: 5px;

    &--landing-custom {
      font-family: 'lato_regular', sans-serif;
      height: 49px;
      border-color: $background-grey;
      color: $custom-black-landing;

      &-current {
        background: $custom-border-grey;
        color: $custom-black-landing;
        border: 2px solid transparent;
      }

      &-active {
        background-color: $custom-black-landing;
        color: $snow-white;
      }

      &:hover {
        background: $custom-border-grey;
        color: $custom-black-landing;
      }
    }
  }

  &__calendar-dropdown {
    top: 48px;
    left: -1px;
    height: 290px;
    width: 371px;
    padding: 0;
    margin-top: 0;
    border-top-width: 1px;
    &--from-to{
      transform: translate(-1px, 48px) !important;
      height: 290px;
    }
  }

  &__block-time-picker {
    position: initial;
  }

  &__error-in-field {
    font-family: Arial, sans-serif;
    font-size: 11px;

    &--landing-custom {
      color: $advice-close;
      font-family: 'lato_regular', sans-serif;
    }
  }

  &__text-error {
    margin-bottom: 7px;
  }
  //////////////////////////////////////////////
  ///////////////////Custom////////////////////
  ////////////////////////////////////////////
  // &__input-icon-clock-custom{
  //     top: -21px;
  // }

  &__delete-content-icon {
    bottom: 6px;
    right: 8px;
  }
  //////////////////////////////////////////////
  ///////////////////Fin Custom////////////////////
  ////////////////////////////////////////////
  &__datepicker-box {
    position: absolute;
    z-index: 20;
    background: #fff;
    border-radius: 0;
    border: 1px solid $primary-color;
    inset: auto !important;
    transform: none !important;
    top: 100% !important;
    left: -1px !important;
    width: 403px;
  }

  &__header-date-picker {
    display: flex;
    width: 100%;
  }

  &__arrow-buttons {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: flex-end;

    &--from-to-calendar {
      width: 17%;
    }
  }

  // &__month-now {
  //   color: $primary-color;
  //   font-family: MTTMilano, sans-serif;
  //   font-size: 12px;
  //   line-height: 17px;
  //   border: 0;
  //   background: none;
  //   text-transform: uppercase;
  //   font-weight: bold;
  //   margin: 9px 0 0 5px;
  //   width: 50%;
  //   float: left;
  // }
}

.compliance{
  &__dark-blue{
    .field-type-date{
      &__next-button, &__previous-button{
        color: $primary-color;
      }
      &__year-button, &__month-button{
        color: $concept-blue;
        border-color: $result-search-quote-color;
        &-current{
            border: 2px solid $primary-color;
        }
        &-active{
            background-color: $primary-color;
            color: $snow-white;
        }
        &:hover{
            background-color: $background-color-states;
            color: $concept-blue;
        }
      }
      &__error-in-field{
        color: $red;
      }
    }
  }
  &__steel-blue{
    .field-type-date{
      &__next-button, &__previous-button{
        color: $border-hover-2;
      }
      &__year-button, &__month-button{
        border-color: $secondary-color-2;
        color: $border-bottom-2;
        &-current{
            border: 2px solid $border-hover-2;
        }
        &-active{
            background-color: $border-hover-2;
            border-color: $border-hover-2;
            color: $snow-white;
        }
        &:hover{
            background-color: $third-color-2;
            color: $border-bottom-2;
        }
      }
      &__error-in-field{
        color: $alert-color-2;
      }
    }
  }
  &__uk-steel-blue{
    .field-type-date{
    }
  }
  &__icam-red{
    .field-type-date{
      &__next-button, &__previous-button{
        color: $primary-color-icam;
      }
      &__year-button, &__month-button{
        border-color: $border-block-icam;
        color: $text-field-color-icam;
        &-current{
            border: 2px solid $border-block-icam;
        }
        &-active{
            background-color: $primary-color-icam;
            border-color: $primary-color-icam;
            color: $snow-white;
        }
        &:hover{
            background-color: $hover-block-icam;
            border-color: $hover-block-icam;
            color: $text-field-color-icam;
        }
    }
    }
  }
  &__gm-law{
    .field-type-date{
      &__next-button, &__previous-button{
        color: $primary-color-gm;
      }
      &__year-button, &__month-button{
        border-color: $border-block-gm;
        color: $text-field-color-gm;
        &-current{
            border: 2px solid $border-block-gm;
        }
        &-active{
            background-color: $primary-color-gm;
            border-color: $primary-color-gm;
            color: $snow-white;
        }
        &:hover{
            background-color: $hover-block-gm;
            border-color: $hover-block-gm;
            color: $text-field-color-gm;
        }
      }
    }
  }
  &__com-de{
    .field-type-date{
      &__next-button, &__previous-button{
        color: $primary-color-com-de;
      }
      &__year-button, &__month-button{
        border-color: $border-block-com-de;
        color: $text-field-color-com-de;
        &-current{
            border: 2px solid $border-block-com-de;
        }
        &-active{
            background-color: $primary-color-com-de;
            border-color: $primary-color-com-de;
            color: $snow-white;
        }
        &:hover{
            background-color: $hover-block-com-de;
            border-color: $hover-block-com-de;
            color: $text-field-color-com-de;
        }
      }
    }
  }
  &__com-fr{
    .field-type-date{
      &__next-button, &__previous-button{
        color: $primary-color-com-fr;
      }
      &__year-button, &__month-button{
        border-color: $border-block-com-fr;
        color: $text-field-color-com-fr;
        &-current{
            border: 2px solid $border-block-com-fr;
        }
        &-active{
            background-color: $primary-color-com-fr;
            border-color: $primary-color-com-fr;
            color: $snow-white;
        }
        &:hover{
            background-color: $hover-block-com-fr;
            border-color: $hover-block-com-fr;
            color: $text-field-color-com-fr;
        }
      }
    }
  }
  &__landing-custom{
    .field-type-date{
      &__datepicker-box {
        border-color: $custom-black-landing;
      }
    }
  }
}

@media (max-width: $max-width-smartphone) { //Smartphone
  .field-type-date {
      &__custom-date-picker {
          margin: 0 0 0 0;
          width: 100%;
      }

      &__custom-month-title {
          left: 0px;
      }
  }
}
