@import '../../../../assets/scss/colors';
.input-number{
  &__days-box{
      float: left;
      box-sizing: border-box;
      border: 1px solid $result-search-quote-color;
      overflow: hidden;
      margin: 0 5px;
      position: relative;
      height: 30px;
      width: 60px;
      bottom: 2px;
  }
  &__input-days{
      border: none;
      background: none;
      height: 29px;
      width: 42px;
      outline: none;
      padding: 5px 0 5px 5px;
      font-size: 12px;
  }
  &__amount-arrow{
      position: absolute;
      right: 1px;
      color: $primary-color;
      background: 0;
      border: 0;
      width: 12px;
      padding: 0;
      height: 12px;
      outline: none;
      &--up{
          right: 5px;
          font-size: 10px;
          top: 2px;
      }
      &--down{
          right: 5px;
          font-size: 10px;
          bottom: 1px;
      }
      &--disabled{
          color: $attach-icon-grey;
          &:hover{
              cursor: default;
          }
      }
  }
}

.compliance{
  &__dark-blue{
    .input-number{
      &__days-box{
        &:hover:not([disabled]){
            background-color: $background-color-states;
            border-color: $primary-color;
        }
        &--disabled{
          background-color: $background-grey;
        }
      }
    }
  }
  &__steel-blue{
    .input-number{
      &__amount-arrow{
        color: $border-hover-2;
        &--disabled{
          color: $grey-icons-2;
        }
      }
      &__days-box{
        border-color: $secondary-color-2;
        &:hover:not([disabled]) {
            background-color: $third-color-2;
            border-color: $border-hover-2;
        }
        &--disabled{
                 border-color: $secondary-color-2;
                 background-color: $background-grey-disabled-2;
        }
      }
    }
  }
  &__uk-steel-blue{
    .input-number{
      &__days-box{
        border-color: $secondary-color-2;
        &:hover:not([disabled]) {
            background-color: $third-color-2;
            border-color: $border-hover-2;
        }
        &--disabled{
                 border-color: $secondary-color-2;
                 background-color: $background-grey-disabled-2;
        }
      }
    }
  }
  &__icam-red{
    .input-number{
      &__days-box{
        border-color: $border-block-icam;
        &:hover:not([disabled]) {
            border-color: $primary-color-icam;
            background-color: $hover-block-icam;
        }

        &--disabled{
          border-color: $border-block-icam;
          background-color: $background-grey-disabled-icam;
        }
      }
      &__amount-arrow{
        color: $primary-color-icam;
        &--disabled{
          color: $secondary-color-icam;
          opacity: 0.7;
        }
      }
    }
  }
  &__gm-law{
    .input-number{
      &__days-box{
        border-color: $border-block-gm;
        &:hover:not([disabled]) {
            border-color: $primary-color-gm;
            background-color: $hover-block-gm;
        }
        &--disabled{
            border-color: $border-block-gm;
            background-color: $background-grey-disabled-gm;
        }
      }
      &__amount-arrow{
        color: $primary-color-gm;
        &--disabled{
          color: $secondary-color-gm;
          opacity: 0.7;
        }
      }
    }
  }
  &__com-de{
    .input-number{
      &__days-box{
        border-color: $border-block-com-de;
        &:hover:not([disabled]) {
            border-color: $primary-color-com-de;
            background-color: $hover-block-com-de;
        }
        &--disabled{
            border-color: $border-block-com-de;
            background-color: $background-grey-disabled-com-de;
        }
      }
      &__amount-arrow{
        color: $primary-color-com-de;
        &--disabled{
          color: $secondary-color-com-de;
          opacity: 0.7;
        }
      }
    }
  }
  &__com-fr{
    .input-number{
      &__days-box{
        border-color: $border-block-com-fr;
        &:hover:not([disabled]) {
            border-color: $primary-color-com-fr;
            background-color: $hover-block-com-fr;
        }
        &--disabled{
            border-color: $border-block-com-fr;
            background-color: $background-grey-disabled-com-fr;
        }
      }
      &__amount-arrow{
        color: $primary-color-com-fr;
        &--disabled{
          color: $secondary-color-com-fr;
          opacity: 0.7;
        }
      }
    }
  }
}
