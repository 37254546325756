@mixin list-in-dropdown-block-participant($firstColor) {
  border: 1px solid $firstColor;
  border-top-width: 2px;
  padding-bottom: 0px;
}

@mixin list-in-dropdown-cell-block-list-responsible($backgroundColor) {
  background-color: $backgroundColor;
  height: 30px;
  padding: 8px 2px 7px 7px;
}

.list-in-dropdown{
  &__block-participant{
      width: 100%;
      position: relative;
      height: 100%;
      float: left;
  }
  &__icon-close {
      font-size: 10px;
      margin-right: 5px;
      margin-top: 5px;
      float: right;
      color: $primary-color;
      &:hover{
          cursor: pointer;
      }
  }
  &__title-box{
      font-family: Arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      padding: 5px 10px 2px 10px;
      text-align: center;
      width: 156px;
      margin: 0 5px 20px;
  }

  &__icon-group{
      font-size: 30px;
      color: $primary-color;
      text-align: center;
      display: block;
      &--little{
          font-size: 20px;
          margin: 3px 0 8px;
      }
  }
  &__title-participants{
      margin-top: -4px;
      display: block;
      text-transform: uppercase;
  }
  &__select-list-item{
      overflow-y: auto;
      overflow-x: hidden;
      width: calc(100% - 5px);
      max-height: 105px;
      float: right;
      position: relative;
      padding: 0;
      padding-left: 5px;
      padding-right: 0px;
      margin-right: 5px;
  }

  &__select-item{
      margin: 0;
      font-family: Arial, sans-serif;
      font-size: 11px;
      font-weight: bold;
      color: $secondary-color;
      overflow-y: auto;
      overflow-x: hidden;
      text-transform: capitalize;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }

  &__cell-block-list{
      position: relative;
      height: 25px;
      padding: 5px 2px 5px 7px;
  }
}

.compliance{
  &__dark-blue{
    .list-in-dropdown{
      &__block-participant{
        border: 1px solid $primary-color;
        padding-bottom: 10px;
      }
      &__icon-close {
        color: $primary-color;
      }
      &__title-box{
        color: $primary-color;
        border-bottom: 3px solid $primary-color;
      }
      &__icon-group{
        color: $primary-color;
      }
      &__cell-block-list{
        &--responsible{
            @include list-in-dropdown-cell-block-list-responsible($background-grey);
        }
      }
    }
  }
  &__steel-blue, &__uk-steel-blue{
    .list-in-dropdown{
      &__block-participant{
        @include list-in-dropdown-block-participant($third-color-2);
      }
      &__icon-close {
        color: $border-hover-2;
      }
      &__title-box{
        color: $primary-color-2;
        border-bottom: 1px solid $third-color-2;
      }
      &__icon-group{
        color: $border-hover-2;
      }
      &__select-list-item{
        margin: 0;
        width: 100%;
      }
      &__select-item{
        color: $border-hover-2;
      }
      &__cell-block-list{
        &--responsible{
          @include list-in-dropdown-cell-block-list-responsible($background-grey-disabled-2);
        }
      }
    }
  }
  &__icam-red{
    .list-in-dropdown{
      &__block-participant{
        @include list-in-dropdown-block-participant($primary-color-icam);
      }
      &__icon-close {
        color: $primary-color-icam;
      }
      &__title-box{
        color: $custom-black-landing;
        border-bottom: 1px solid $third-color-icam;
      }
      &__icon-group{
        color: $primary-color-icam;
      }
      &__select-list-item{
        margin: 0;
        width: 100%;
      }
      &__select-item{
        color: $primary-color-icam;
      }
      &__cell-block-list{
        &--responsible{
          @include list-in-dropdown-cell-block-list-responsible($background-grey-disabled-icam);
        }
      }
    }
  }
  &__gm-law{
    .list-in-dropdown{
      &__block-participant{
        @include list-in-dropdown-block-participant($primary-color-gm);
      }
      &__icon-close {
        color: $primary-color-gm;
      }
      &__title-box{
        color: $custom-black-landing;
        border-bottom: 1px solid $third-color-gm;
      }
      &__icon-group{
        color: $primary-color-gm;
      }
      &__select-list-item{
        margin: 0;
        width: 100%;
      }
      &__select-item{
        color: $primary-color-gm;
      }
      &__cell-block-list{
        &--responsible{
          @include list-in-dropdown-cell-block-list-responsible($background-grey-disabled-gm);
        }
      }
    }
  }
  &__com-de{
    .list-in-dropdown{
      &__block-participant{
        @include list-in-dropdown-block-participant($primary-color-com-de);
      }
      &__icon-close {
        color: $primary-color-com-de;
      }
      &__title-box{
        color: $custom-black-landing;
        border-bottom: 1px solid $third-color-com-de;
      }
      &__icon-group{
        color: $primary-color-com-de;
      }
      &__select-list-item{
        margin: 0;
        width: 100%;
      }
      &__select-item{
        color: $primary-color-com-de;
      }
      &__cell-block-list{
        &--responsible{
          @include list-in-dropdown-cell-block-list-responsible($background-grey-disabled-com-de);
        }
      }
    }
  }
  &__com-fr{
    .list-in-dropdown{
      &__block-participant{
        @include list-in-dropdown-block-participant($primary-color-com-fr);
      }
      &__icon-close {
        color: $primary-color-com-fr;
      }
      &__title-box{
        color: $custom-black-landing;
        border-bottom: 1px solid $third-color-com-fr;
      }
      &__icon-group{
        color: $primary-color-com-fr;
      }
      &__select-list-item{
        margin: 0;
        width: 100%;
      }
      &__select-item{
        color: $primary-color-com-fr;
      }
      &__cell-block-list{
        &--responsible{
          @include list-in-dropdown-cell-block-list-responsible($background-grey-disabled-com-fr);
        }
      }
    }
  }
}
