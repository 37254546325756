@mixin  y-x-ticks($strokeColor, $activeStrokeColor){
  & .tick {
      & line {
          stroke-width: 1px;
          stroke: $strokeColor;
      }
  }
  & .tick-active {
      & line {
          stroke-width: 1px;
          stroke: $activeStrokeColor;
      }
  }

}
.line-graph {
  display: block;
  height: 400px;
  position: relative;
  &__axis-tick-text{
      &--hidden{
          display: none;
      }
  }

  &__axis-title{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
  }

  &__line-graph-container {
      margin-left: 50px;
  }

  &__line {
      fill: none;
      stroke-width: 2;
  }

  &__dot {
      &--more-than{
          fill: transparent;
          stroke: transparent;
      }
      &:hover {
          cursor: pointer;
      }
  }

  &__legend-box {
      width: 30px;
      height: 22px;
      font-family: 'MTTMilano', sans-serif;
      font-size: 12px;
      text-align: center;
      padding-top: 4px;
      margin: 0 auto;
      &:hover{
          cursor: pointer;
      }
  }

  &__legend-block{
      &--more-than{
          display: none;
      }
      &--active{
          display: block;
      }
  }

  &__x-ticks,
  &__y-ticks {
      & path {
          stroke-width: 1px;
          stroke: $column-filter-shadow-005;
      }
      & .tick{
        & text{
          display: none;
        }
      }
  }

  &__tooltip-box {
      position: absolute;
      margin: 2px 5px 6px 0px;
      z-index: 11;
      display: none;
  }

  &__tooltip-block {
      min-height: 302px;
      width: 310px;
      background: $snow-white;
      border: 1px solid;
      box-shadow: 0 0 10px 10px $line-graph-shadow;
      padding: 4px 24px 5px 24px;
  }

  &__tooltip-triangle {
      left: 125px;
      top: -24px;
      position: relative;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $snow-white;
      border-left: 10px solid transparent;
      height: 0;
      width: 0;
      z-index: 70;
  }

  &__tooltip-graph-close {
      position: relative;
      z-index: 1;
      float: right;
      font-size: 12px;
      &:hover {
          cursor: pointer;
      }
  }

  &__tooltip-header-block {
      margin: 0;
      display: block;
      position: relative;
  }

  &__tooltip-graph-title {
      font-family: 'MTTMilano', sans-serif;
      font-size: 18px;
      font-weight: 500;
      margin-top: 7px;
      border-bottom: 1px solid ;
      padding-bottom: 3px;
      margin-bottom: 0;
      text-transform: capitalize;

  }

  &__tooltip-details-block {
      width: 100%;
      padding: 6px 0;
      display: block;
      border-bottom: 1px solid;
      overflow: hidden;
  }

  &__tooltip-details-item {
      display: block;
      width: 100%;
  }

  &__tooltip-details-item-left {
      display: block;
      width: 65%;
      float: left;
  }

  &__tooltip-details-dot {
      font-size: 10px;
      padding-right: 10px;
      float: left;
      position: relative;
      top: 4px;
  }

  &__tooltip-details-title {
      font-family: "MTTMilano-Bold", sans-serif;
      font-size: 12px;
      display: table;
  }

  &__tooltip-details-item-right {
      width: 35%;
      float: right;
      font-family: 'MTTMilano-Bold', sans-serif;
      font-size: 12px;
      text-align: right;
      padding-right: 10px;
  }

  &__tooltip-details-text-right {
      padding-left: 10px;
  }

  &__details-totals-block {
      height: 50px;
      padding-top: 13px;
      border-bottom: 1px solid ;
      &--no-show {
          display: none;
      }
  }

  &__details-icon-communication {
      font-size: 20px;
      float: left;
  }

  &__details-totals-text {
      font-family: 'MTTMilano', sans-serif;
      font-size: 14px;
      font-weight: 500;
      margin: 5px 0 0 25px;
      &--uppercase {
          text-transform: uppercase;
      }
      &--top {
          margin-top: 2px;
      }
  }
}

.compliance{
  &__dark-blue{
    .line-graph{
      &__details-totals-text,
      &__details-icon-communication,
      &__tooltip-graph-close{
        color: $primary-color;
      }
      &__details-totals-block,
      &__tooltip-details-block{
        border-bottom-color: $result-search-quote-color;
      }
      &__tooltip-details-item-right{
        color: $concept-blue;
      }
      &__tooltip-details-title{
        color: $attach-icon-grey;
        margin-bottom: 1px;
      }
      &__tooltip-details-dot{
        color: $background-grey-other;
      }
      &__tooltip-graph-title{
        border-bottom-color: $primary-color;
        color: $primary-color;
      }
      &__tooltip-block{
        border-color: $result-search-quote-color;
      }
      &__x-ticks,
      &__y-ticks{
            @include y-x-ticks($column-filter-shadow-005, $primary-color)
      }
      &__axis-tick-text{
        color: $background-grey-other;
        font-family: MTTMilano, sans-serif;
        font-size: 13px;
      }
      &__axis-title{
        fill: $background-grey-other;
      }
      &__line{
        stroke: $other-special-white;
      }
      &__dot{
        fill: $other-special-white;
        stroke: $snow-white;
        &.active{
            fill: $primary-color;
            stroke: $primary-color;
        }
      }
      &__legend-box{
        color: $primary-color;
        background: $background-grey-other;
        &.active{
            background: $primary-color;
            color: $background-grey-other;
        }
      }
    }
  }

  &__steel-blue{
    .line-graph{
      &__details-totals-text,
      &__details-icon-communication,
      &__tooltip-details-item-right,
      &__tooltip-graph-close{
        color: $border-hover-2;
      }
      &__details-totals-block,
      &__tooltip-details-block{
        border-bottom-color: $third-color-2;
      }
      &__tooltip-details-title{
        color: $primary-color-2;
        margin-bottom: 6px;
      }
      &__tooltip-details-dot{
        color: $third-color-2;
      }
      &__tooltip-graph-title{
        border-bottom-color: $third-color-2;
        color: $border-hover-2;
      }
      &__tooltip-block{
        border-color: $third-color-2;
      }
      &__x-ticks,
      &__y-ticks {
        @include y-x-ticks($column-filter-shadow-005, $border-hover-2)
      }
      &__axis-tick-text{
        color: $white-special-2;
        font-family: Arial, sans-serif;
        font-size: 10px;
      }
      &__axis-title{
        opacity: 0.5;
        fill: $white-special-2;
      }
      &__line{
        stroke: $other-special-white;
      }
      &__dot{
        fill: $other-special-white;
        stroke: $snow-white;
        &.active{
            fill: $border-hover-2;
            stroke: $border-hover-2;
        }
      }
      &__legend-box{
        color: $primary-color-2;
        background: $third-color-2;
        &.active{
            background: $border-hover-2;
            color: $white-special-2;
        }
      }
    }
  }

  &__icam-red{
    .line-graph{
      &__details-totals-text,
      &__details-icon-communication,
      &__tooltip-details-item-right,
      &__tooltip-graph-close{
        color: $primary-color-icam;
      }
      &__details-totals-block,
      &__tooltip-details-block{
        border-bottom-color: $primary-color-icam;
      }
      &__tooltip-details-title{
        color: $custom-black-landing;
        margin-bottom: 6px;
      }
      &__tooltip-details-dot{
        color: $border-block-icam;
      }
      &__tooltip-graph-title{
        border-bottom-color: $third-color-icam;
        color: $primary-color-icam;
      }
      &__tooltip-block{
        border-color: $result-search-quote-color;
      }
      &__x-ticks,
      &__y-ticks{
        @include y-x-ticks($black-opacity, $primary-color-icam)
      }
      &__axis-tick-text{
        color: $text-field-color-icam;
        font-family: Arial, sans-serif;
        font-size: 10px;
      }
      &__axis-title{
        opacity: 0.5;
        fill: $text-field-color-icam;
      }
      &__line {
        stroke: $custom-black-landing;
      }
      &__dot{
        fill: $custom-black-landing;
        stroke: $custom-black-landing;
        &.active{
            fill: $primary-color-icam;
            stroke: $primary-color-icam;
        }
      }
      &__legend-box{
        color: $custom-black-landing;
        background: $border-block-icam;
        &.active{
            background: $primary-color-icam;
            color: $snow-white;
        }
      }
    }
  }
  &__gm-law{
    .line-graph{
      &__details-totals-text,
      &__details-icon-communication,
      &__tooltip-details-item-right,
      &__tooltip-graph-close{
        color: $primary-color-gm;
      }
      &__details-totals-block,
      &__tooltip-details-block{
        border-bottom-color: $primary-color-gm;
      }
      &__tooltip-details-title{
        color: $custom-black-landing;
        margin-bottom: 6px;
      }
      &__tooltip-details-dot{
        color: $border-block-gm;
      }
      &__tooltip-graph-title{
        border-bottom-color: $third-color-gm;
        color: $primary-color-gm;
      }
      &__tooltip-block{
        border-color: $result-search-quote-color;
      }
      &__x-ticks,
      &__y-ticks {
        @include y-x-ticks($black-opacity, $primary-color-gm)
      }
      &__axis-tick-text{
        color: $text-field-color-gm;
        font-family: Arial, sans-serif;
        font-size: 10px;
      }
      &__axis-title{
        opacity: 0.5;
        fill: $text-field-color-gm;
      }
      &__line {
        stroke: $primary-color-gm;
      }
      &__dot{
        fill: $primary-color-gm;
        stroke: $primary-color-gm;
        &.active{
            fill: $primary-color-gm;
            stroke: $primary-color-gm;
        }
      }
      &__legend-box{
        color: $custom-black-landing;
        background: $border-block-gm;
        &.active{
            background: $primary-color-gm;
            color: $snow-white;
        }
      }
    }
  }
  &__com-de{
    .line-graph{
      &__details-totals-text,
      &__details-icon-communication,
      &__tooltip-details-item-right,
      &__tooltip-graph-close{
        color: $primary-color-com-de;
      }
      &__details-totals-block,
      &__tooltip-details-block{
        border-bottom-color: $primary-color-com-de;
      }
      &__tooltip-details-title{
        color: $custom-black-landing;
        margin-bottom: 6px;
      }
      &__tooltip-details-dot{
        color: $border-block-com-de;
      }
      &__tooltip-graph-title{
        border-bottom-color: $third-color-com-de;
        color: $primary-color-com-de;
      }
      &__tooltip-block{
        border-color: $result-search-quote-color;
      }
      &__x-ticks,
      &__y-ticks {
        @include y-x-ticks($black-opacity, $primary-color-com-de)
      }
      &__axis-tick-text{
        color: $text-field-color-com-de;
        font-family: Arial, sans-serif;
        font-size: 10px;
      }
      &__axis-title{
        opacity: 0.5;
        fill: $text-field-color-com-de;
      }
      &__line {
        stroke: $primary-color-com-de;
      }
      &__dot{
        fill: $primary-color-com-de;
        stroke: $primary-color-com-de;
        &.active{
            fill: $primary-color-com-de;
            stroke: $primary-color-com-de;
        }
      }
      &__legend-box{
        color: $custom-black-landing;
        background: $border-block-com-de;
        &.active{
            background: $primary-color-com-de;
            color: $snow-white;
        }
      }
    }
  }
  &__com-fr{
    .line-graph{
      &__details-totals-text,
      &__details-icon-communication,
      &__tooltip-details-item-right,
      &__tooltip-graph-close{
        color: $primary-color-com-fr;
      }
      &__details-totals-block,
      &__tooltip-details-block{
        border-bottom-color: $primary-color-com-fr;
      }
      &__tooltip-details-title{
        color: $custom-black-landing;
        margin-bottom: 6px;
      }
      &__tooltip-details-dot{
        color: $border-block-com-fr;
      }
      &__tooltip-graph-title{
        border-bottom-color: $third-color-com-fr;
        color: $primary-color-com-fr;
      }
      &__tooltip-block{
        border-color: $result-search-quote-color;
      }
      &__x-ticks,
      &__y-ticks {
        @include y-x-ticks($black-opacity, $primary-color-com-fr)
      }
      &__axis-tick-text{
        color: $text-field-color-com-fr;
        font-family: Arial, sans-serif;
        font-size: 10px;
      }
      &__axis-title{
        opacity: 0.5;
        fill: $text-field-color-com-fr;
      }
      &__line {
        stroke: $primary-color-com-fr;
      }
      &__dot{
        fill: $primary-color-com-fr;
        stroke: $primary-color-com-fr;
        &.active{
            fill: $primary-color-com-fr;
            stroke: $primary-color-com-fr;
        }
      }
      &__legend-box{
        color: $custom-black-landing;
        background: $border-block-com-fr;
        &.active{
            background: $primary-color-com-fr;
            color: $snow-white;
        }
      }
    }
  }
}

/////////////////////////////////
//inc icam - MADiaz
