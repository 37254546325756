.change-state-modal{
  &.modal {
    .moda-dialog{
      width: 80%;
      margin: 0 auto;
    }
    .modal-content {
      width: 100%;
      border-radius: 0px;
      height: inherit;
    }
  }
  &__body-container{
      display: block;
      overflow: hidden;
      width: 100%;
      padding: 39px 50px 48px 50px;
  }
}
