.notification-modal{
  &__body{
      color: $custom-black-landing;
      padding: 19px 71px 0px;
      font-family: Arial, sans-serif;
      font-size: 12px;
      overflow: auto;
      margin: 10px;
      max-height: 437px;
  }
  &__button-modal{
      right: 10px;
      bottom: 55px;
  }
}

.compliance{
  &__landing-custom{
    .notification-modal {
        &__body{
            font-family: 'lato_regular', sans-serif;
            font-size: 14px;
            padding-left: 61px;
            padding-right: 61px;
        }
    }
  }
}

@media (max-width: $max-width-smartphone) { //Smartphone
  .notification-modal {
      &__custom .modal-dialog .modal-content {
          width: auto;
          height: auto;
      }
      &__close-modal-custom{
          display: block;
      }
      &__button-print{
          &--custom{
              width: 90%;
              margin: 0 21px;
          }
      }
      &__button-modal{
          margin-top: 10px;
          margin-bottom: 20px;
      }
  }
  .compliance{
    &__landing-custom{
      .notification-modal {
          &__body{
              padding: 36px 10px 30px 16px;
          }
      }
    }
  }
}

@media print {
  .notification-modal {
      &__body {
          padding: 0;
          overflow: visible;
          &--custom{
            font-family: Arial, sans-serif;
          }
      }
  }
}
