.role-error-modal{
  & .modal-dialog {
      & .modal-content {
          height: auto;
          min-height: 474px;
      }
  }

  &__modal-body{
      background-color: $red;
      width: 100%;
      min-height: 223px;
      position: relative;
      padding: 70px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  &__modal-icon-block{
      width: 20%;
      height: 100%;
  }

  &__modal-text-block {
      width: 80%;
      height: 100%;
      padding-right: 45px;
  }

  &__modal-body-text{
      font-size: 18px;
      color: $snow-white;
      font-family: MTTMilano, sans-serif;
      text-align: left;
      margin: 0;
  }

  &__buttons-block{
      position: absolute;
      right: 60px;
      bottom: 20px;
  }

  &__container-block{
      display: block;
      width: calc(100% - 80px);
      padding: 0px;
      margin: 17px 10px 50px 70px;
      padding-right: 60px;
      overflow: auto;
  }

  &__setting-title-box{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      margin-bottom: 15px;
      padding: 0 0 10px 2px;
      color: $primary-color;
      border-bottom: 1px solid $primary-color;
  }

  &__setting-info{
      color: $subcontainer-ground;
      font-family: MTTMilano, sans-serif;
      font-size: 14px;
      font-style: italic;
      margin-bottom: 20px;
      padding-left: 2px;
  }

  &__role-user-block{
      padding: 10px 10px 15px;
      margin-top: 20px;
      display: inline-block;
      width: 100%;
      border: 1px solid $result-search-quote-color;
      color: $primary-color;
  }

  &__block-name-user{
      font-family: MTTMilano-Bold, sans-serif;
      font-size: 12px;
  }

  &__text-name-user{
      position: relative;
      bottom: 3px;
      padding-left: 3px;
      text-transform: uppercase;
  }

  &__text-name-product{
      bottom: 3px;
      position: relative;
      margin-left: 3px;
      color: $pillow-group;
  }

  &__icon-lead{
      font-size: 20px;
  }

  &__client-role-block{
      padding: 10px 0 0 27px;
      display: inline-block;
      width: 100%;;
  }

  &__client-role-repeat{
      padding-left: 18px;
      position: relative;
  }

  &__icon-client-list{
      font-size: 10px;
      position: absolute;
      left: 0;
      top: 5px;
      color: $primary-color;
      background-color: $primary-color;
  }

  &__client-name-list{
      color: $secondary-color;
      font-family: MTTMilano, sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
  }
}

.compliance{
  &__dark-blue{
    .role-error-modal{
      &__modal-body{
          width: calc(100% - 22px);
          margin: 11px;
          padding: 70px 0;
      }
    }
  }
  &__steel-blue,
  &__uk-steel-blue{
    .role-error-modal{
      &__modal-body{
          background-color: $alert-color-2;
      }
      &__modal-body-text{
          color: $white-special-2;
          font-size: 20px;
      }
      &__setting-title-box{
          color: $primary-color-2;
          border-bottom: 1px solid $primary-color-2;
      }
      &__setting-info{
          color: $grey-icons-2;
      }
      &__role-user-block{
          border-color: $background-highlight-text-2;
          color: $primary-color-2;
      }
      &__text-name-product{
          color: $primary-color-2;
      }
      &__icon-client-list{
          color: $primary-color-2;
          background-color: $primary-color-2;
      }
      &__client-name-list{
          color: $primary-color-2;
      }
    }
  }
  &__icam-red{
    .role-error-modal{
      &__modal-body{
          background-color: $alert-color-icam;
      }
      &__modal-body-text{
          color: $snow-white;
          font-size: 20px;
      }
      &__setting-title-box{
          color: $primary-color-icam;
          border-bottom: 1px solid $primary-color-icam;
      }
      &__setting-info{
          color: $secondary-color-icam;
      }
      &__role-user-block{
          border-color: $primary-color-icam;
          color: $primary-color-icam;
      }
      &__text-name-product{
          color: $title-text-icam;
      }
      &__icon-client-list{
          color: $primary-color-icam;
          background-color: $primary-color-icam;
      }
      &__client-name-list{
          color: $custom-black-landing;
      }
    }
  }
  &__gm-law{
    .role-error-modal{
      &__modal-body{
          background-color: $alert-color-gm;
      }
      &__modal-body-text{
          color: $snow-white;
          font-size: 20px;
      }
      &__setting-title-box{
          color: $primary-color-gm;
          border-bottom: 1px solid $primary-color-gm;
      }
      &__setting-info{
          color: $secondary-color-gm;
      }
      &__role-user-block{
          border-color: $primary-color-gm;
          color: $primary-color-gm;
      }
      &__text-name-product{
          color: $title-text-gm;
      }
      &__icon-client-list{
          color: $primary-color-gm;
          background-color: $primary-color-gm;
      }
      &__client-name-list{
          color: $custom-black-landing;
      }
    }
  }
  &__com-de{
    .role-error-modal{
      &__modal-body{
          background-color: $alert-color-com-de;
      }
      &__modal-body-text{
          color: $snow-white;
          font-size: 20px;
      }
      &__setting-title-box{
          color: $primary-color-com-de;
          border-bottom: 1px solid $primary-color-com-de;
      }
      &__setting-info{
          color: $secondary-color-com-de;
      }
      &__role-user-block{
          border-color: $primary-color-com-de;
          color: $primary-color-com-de;
      }
      &__text-name-product{
          color: $title-text-com-de;
      }
      &__icon-client-list{
          color: $primary-color-com-de;
          background-color: $primary-color-com-de;
      }
      &__client-name-list{
          color: $custom-black-landing;
      }
    }
  }
  &__com-fr{
    .role-error-modal{
      &__modal-body{
          background-color: $alert-color-com-fr;
      }
      &__modal-body-text{
          color: $snow-white;
          font-size: 20px;
      }
      &__setting-title-box{
          color: $primary-color-com-fr;
          border-bottom: 1px solid $primary-color-com-fr;
      }
      &__setting-info{
          color: $secondary-color-com-fr;
      }
      &__role-user-block{
          border-color: $primary-color-com-fr;
          color: $primary-color-com-fr;
      }
      &__text-name-product{
          color: $title-text-com-fr;
      }
      &__icon-client-list{
          color: $primary-color-com-fr;
          background-color: $primary-color-com-fr;
      }
      &__client-name-list{
          color: $custom-black-landing;
      }
    }
  }
}
